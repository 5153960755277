export class backupbasic {
    solution() {
        return 'backupbasic'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-backup'
    }

    logotipo() {
        return require('./assets/logotipos/logo_backupbasic.svg').default
    }
    titulo() {
        return <>Amplie a <b>segurança dos dados</b> da organização com o LiveCloud Backup Basic</>
    }

    chamada() {
        return <>
            A perda de dados pode ser devastadora para qualquer negócio. Nossa solução de backup em nuvem é operada, suportada e monitorada por nossa equipe de especialistas, minimizando riscos de perda de informações e tempo de inatividade.
        </>
    }

    image() {
        return require('./assets/imgs/imagem_backupbasic.png').default
    }

    detalhes() {
        return <>
            <h2>Com LiveCloud Backup Basic, a organização protege os dados, tornando-os disponíveis quando precisar.</h2>
            <h3>Por que escolher LiveCloud Backup Basic?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_backupbasic.png').default} />
                </div>
                <ul>
                    <li><b>Backup em ambiente de alta disponibilidade:</b> Seus dados estão sempre seguros e acessíveis.</li>
                    <li><b>Implantação, suporte, operação e monitoramento:</b> Deixe a gestão de backup conosco e foque no que realmente importa para o seu negócio.</li>
                    <li><b>Backup e Restore granular de arquivos:</b> Recupere exatamente o que você precisa, quando precisa.</li>
                    <li><b>Recuperação de dados via solicitação pelo portal LiveCloud:</b> Tenha o controle nas suas mãos e recupere seus dados de forma rápida e fácil.</li>
                    <li><b>Tempo mínimo para disponibilização dos dados após a solicitação:</b> Seus dados disponíveis em até 1 hora.</li>
                </ul>
            </div>
            <div className="box"><b>Oferecemos um serviço de backup em nuvem estruturado, com jobs periódicos de backup e testes de restore.</b> Através do Portal LiveCloud, a organização pode acompanhar atividades, status, agendar novos procedimentos, solicitar restores e demais necessidades vinculadas aos serviços.</div>
            <h3>Níveis de serviço</h3>
            <p>Oferecemos um <b>SLA de início de 45 minutos</b> e um <b>SLO de fim de 4 horas</b>, proporcionando a organização tranquilidade de que os serviços estarão disponíveis quando precisar.</p>
            <h3>Não perca mais tempo</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como LiveCloud Backup Basic pode proteger os dados vitais do negócio.</p>            
        </>
    }
}
export default backupbasic;