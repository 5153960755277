import React, { useEffect, useMemo, useState } from 'react';
import { PiCurrencyCircleDollar, PiPiggyBank } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { useSelector, useDispatch } from 'react-redux';
import { FinOpsSavingPlanReserveService } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserve';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { UserState } from '../../../../store/reducers/userReducer';
import { SavingPlanReserveModel } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import { OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

interface SavingAndReserverProps {
    type: string
    setLoading: (loading: boolean) => void
}

export const SavingAndReserveInfoCard: React.FC<SavingAndReserverProps> = ({type, setLoading}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const [savingsCard, setSavingsCard] = useState<OnOffModel>({ on: 0, off: 0 });
    const [reservesCard, setReservesCard] = useState<OnOffModel>({ on: 0, off: 0 });
    const [savingsData, setSavingsData] = useState<SavingPlanReserveModel[]>([]);
    const [reservasData, setReservasData] = useState<SavingPlanReserveModel[]>([]);

    const translationsFilter = useMemo(() => {
        return {
            plan: {
                label: "Plano",
                type: "text",
            },
            alocation: {
                label: "Alocação",
                type: "multiSelect",
            },
            service: {
                label: "Serviço",
                type: "multiSelect",
            },
            scope: {
                label: "Escopo",
                type: "multiSelect",
                topDropdown: true
            },
            region: {
                label: "Região",
                type: "multiSelect",
                topDropdown: true
            },
            // expired_date: {
            //     label: "Expira em",
            //     type: "date",
            // },
        };
    },[])



    const columns = useMemo(() => {
        return [   
            {
                field: "plan", headerName: "Provedor/Plano", width: "30%", align: 'left', renderCell: (row: SavingPlanReserveModel) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                            <Tooltip arrow title={row.provider === 'Azure' ? 'Microsoft Azure' : 'Amazon AWS'}>
                                <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                    {row.provider === 'Azure' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                                </span>
                            </Tooltip>
                            <Tooltip arrow title={row.plan}>
                                <span
                                    className='overflowEllipsis ml-4'
                                >
                                    {row.plan}
                                </span>
                            </Tooltip>
                        </div>
                    )
                }
            },
            { field: "type", headerName: "Tipo", width: "10%", align: 'left', },
            {
                field: "alocation", headerName: "Alocação", width: "12%", align: 'left',
                renderCell: (row: SavingPlanReserveModel) => {
                    // Função para formatar adequadamente a alocação
                    const formatAlocation = (value) => {
                        // Padrão para encontrar valores monetários com ou sem texto adicional
                        const pattern = /^(R\$)\s*(\d+[.,]?\d*)\s*(.*)$/;
                        const match = value?.match(pattern);
    
                        if (match) {
                            // Formata apenas a parte numérica mantendo qualquer texto adicional
                            const numberPart = parseFloat(match[2].replace(',', '.'));
                            const formattedNumber = numberPart.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            });
                            return `${formattedNumber}${match[3]}`.trim(); // Reconstrói sem adicionar novamente o R$                        
    
                        }
    
                        // Retorna o valor original se não corresponder ao padrão esperado
                        return value;
                    };
    
                    const formattedAlocation = formatAlocation(row.alocation);
                    return <Tooltip title={row.alocation} arrow><div className='overflowEllipsis'>{formattedAlocation}</div></Tooltip>;
                }
            },
    
            {
                field: "service", headerName: "Serviço", width: "12%", align: 'left',
                renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.service} arrow><div className='overflowEllipsis'>{row.service}</div></Tooltip>
            },
            {
                field: "scope", headerName: "Escopo", width: "18%", align: 'left',
                renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.scope} arrow><div className='overflowEllipsis'>{row.scope}</div></Tooltip>
            },
            {
                field: "region", headerName: "Região", width: "12%", align: 'left',
                renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.region} arrow><div className='overflowEllipsis'>{row.region}</div></Tooltip>
            },
            {
                field: "commitment", headerName: "Compromisso", width: "10%",
                renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.commitment} arrow><div className='overflowEllipsis'>{row.commitment}</div></Tooltip>
            },
            {
                field: "use", headerName: "Utilização", width: "10%", align: 'center',
                renderCell: (row: SavingPlanReserveModel) => `${(row.use)}%`
            },
            {
                field: "expired_date", headerName: "Expira em", width: "10%", align: 'left',
                renderCell: (row: SavingPlanReserveModel) => moment(row.expiredDate).year() === 1 ? "-" : moment(row.expiredDate).format('DD/MM/YYYY')
            },
        ]
    },[])
    

    useEffect(() =>{
        const getSavingsAndReserves = async () => {
            setIsLoading(true)
            try {
                const SavingsPlanReserveService = new FinOpsSavingPlanReserveService({})
                const savingsReservesData = await SavingsPlanReserveService.retrieveList();
                const savingsData  : SavingPlanReserveModel[] = [];
                const reservasData : SavingPlanReserveModel[] = [];
                const resultSavings = { on: 0, off: 0 };
                const resultReserves = { on: 0, off: 0 };
                // Passe por todos os itens e categorize os dados
                if(savingsReservesData.length > 0) {
                    savingsReservesData.forEach(item => {
                        if (item.type.includes("Savings Plan")) {
                            savingsData.push(item);
                            if (item.status.includes("Ativo")) {
                                resultSavings.on += 1;
                            } else {
                                resultSavings.off += 1;
                            }
                        } else if (item.type.includes("Reserva")) {
                            reservasData.push(item);
                            if (item.status.includes("Ativo")) {
                                resultReserves.on += 1;
                            } else {
                                resultReserves.off += 1;
                            }
                        }
                    });
                }
        
                // Atualize o estado com os dados processados
                setSavingsData(savingsData);
                setSavingsCard(resultSavings);
                setReservasData(reservasData);
                setReservesCard(resultReserves);
                
            } catch (error) {
                console.error("Error:", error);
            } finally{
                setIsLoading(false)
            }
        };
        getSavingsAndReserves()
    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() =>{
        if(cmsEnvironment.enviromentFilter === type  && !isLoading) {
            let payload = {
                enviromentFilter: type,
                columns: columns,
                rowsList: type === 'Reservas' ? reservasData : savingsData,
                rowsListFiltered: type === 'Reservas' ? reservasData : savingsData,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter
            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            setLoading(false)
        }
    } ,[savingsCard, reservesCard, cmsEnvironment.enviromentFilter, type, columns, reservasData, savingsData, translationsFilter, dispatch, setLoading, isLoading]) 

    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: type,
            columns: columns,
            rowsList: type === 'Reservas' ? reservasData : savingsData,
            rowsListFiltered: type === 'Reservas' ? reservasData : savingsData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        setLoading(false)

    } 


    return(
        <LcLoading loading={isLoading}  >
            <IconValue 
                icon={type === 'Reservas' ?<PiCurrencyCircleDollar size={28} /> :<PiPiggyBank size={28} /> }
                value={type === 'Reservas' ? `${(reservesCard?.on + reservesCard.off)}` : `${savingsCard?.on + savingsCard.off} `}
                color={type === 'Reservas' ?"#DA43F2" : "#00DBFF"}
                onClick={handleClickEnviroment}
                balonSelected={(cmsEnvironment.enviromentFilter === type)}
                description={'Ativos'}
            />
        </LcLoading>
    );
}
