import api, { APIClient } from "../api";
import Moment from 'moment';

export class BackupGoldService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetCards(selectedDate: string) {
        const url = this.endpoint + `/api/BackupGold/GetSummaryData?selectedDate=${selectedDate}`;
        return api({
            method: 'get',
            url,

        });
    }
    async GetDailyChart(selectedDate: string) {
        const url = this.endpoint + `/api/BackupGold/GetDailyEvolution?selectedDate=${selectedDate}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetWeeklyChart(selectedDate: string) {
        const url = this.endpoint + `/api/BackupGold/GetWeeklyEvolution?selectedDate=${selectedDate}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetJobCards(selectedDate: string) {
        const url = this.endpoint + `/api/BackupGold/GetJobCards?selectedDate=${selectedDate}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetMonthlyChart(selectedDate: string) {
        const url = this.endpoint + `/api/BackupGold/GetMonthlyEvolution?selectedDate=${selectedDate}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetHistory(start: string, end: string) {
        const url = this.endpoint + `/api/BackupGold/Historico?start=${start}&end=${end}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetCommCellBackupInfo(start: string, end: string) {
        const url = this.endpoint + `/api/BackupGold/GetCommCellBackupInfo?start=${start}&end=${end}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetHistoryPaginated(start: string, end: string, paginationToken: any, limit: number) {
        // JSON.stringify(paginationToken);
        const body = JSON.stringify(paginationToken);
        const encondedString = window.btoa(body);
        const url = this.endpoint + `/api/BackupGold/HistoricoPaginated?start=${start}&end=${end}&paginationToken=${encondedString}&limit=${limit}&refresh=${Math.random()}`;

        return api({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async GetVaults() {
        const url = this.endpoint + `/api/BackupGold/Cofre?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        }).then((response: any) => {
            let vaults = response.data.map((Vault: { CofreNumber: string, TaskName: string, BackupDateTime: any }, index: number) => {
                return {
                    id: index,
                    CofreNumber: Vault.CofreNumber,
                    TaskName: Vault.TaskName,
                    BackupDateTime: Moment(Vault.BackupDateTime).format('DD/MM/YYYY hh:mm:ss'),
                }
            });
            return vaults;
        });
    }
    async GetSchedules() {
        const url = this.endpoint + `/api/BackupGold/Agenda?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
}