import React, { useEffect, useState } from 'react'; 
import {  FormControl } from '@material-ui/core';
import { VendorService } from '../../../services/vendorService';
import { VendorCredential } from '../Model/VendorCredential';
import { RecordProp } from '../Model/RecordVendor';
import VendorCredentialItemList from '../VendorCredentialItem/List'; 
import { useSnackbar } from 'notistack';
import { PPForm2, PPIconLink, PPModal } from 'processor-plataform-ui';
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcCheckLabelValueList from '../../../components/Form/LcCheckLabelValueList';
import LcLoading from '../../../components/Generic/LcLoading';
import { AzureSubscription } from '../Model/AzureSubscription'; 

const VendorCredentialEdit: React.FC<RecordProp> = (props) => {
    const ServiceInstance = new VendorService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [functions, setFunctions] = useState<any>({ ...ServiceInstance.ActionsBaseFunctions(Save, props.parentSelectionCallback, null) });
    const [record, setRecord] = useState<VendorCredential>(ServiceInstance.emptyRecord);
    const [loading, setLoading] = useState<boolean>(false);
    const [subscriptionsAWS, setSubscriptionsAWS] = useState<any[]>([]);
    const [subscriptionsAWSListSelected, setSubscriptionsAWSListSelected] = useState<any[]>([]);
    const [providerSubscriptions, setProviderSubscriptions] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, [props, record, providerSubscriptions]);

    async function loadData() {
        setLoading(true);
        setSubscriptionsAWS([]);
        try {
            if (props.record.cloudOSProviderId == 17 && user.ClientGroupSelected != 999999999)
                await getAccountsFromCustomer();

            if (props.record != null
                && props.record.id > 0
                && (record.id != props.record.id)) {
                RetrieveRecord(props.record.id);
            } else if (props.record != null && props.record.id == 0 && props.record.id != record.id) {
                setRecord({ ...ServiceInstance.emptyRecord });
            }

            if (props.record.id == 0) {
                setFunctions({ ...ServiceInstance.ActionsBaseFunctions(() => { Save(record) }, props.parentSelectionCallback, null) });
            } else {
                setFunctions({
                    ...ServiceInstance.ActionsBaseFunctions(() => { Save(record) },
                        props.parentSelectionCallback,
                        () => {
                            if (props.parentDeleteActionCallback != undefined) {
                                props.parentDeleteActionCallback([props.record.id]);
                                props.parentRetrieveListCallback != undefined && props.parentRetrieveListCallback();
                            }
                        })
                });
            }

        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }

    async function saveAccountFromCustomerSelected(accounts) {
        try {
            await ServiceInstance
                .SaveAccountsFromCustomer({
                    subscriptions: accounts.map(ir => {
                        return ir.id.toString() as string;
                    })
                });

            setSubscriptionsAWSListSelected(accounts.map(ir => {
                return { id: ir, label: ir };
            }));
        } catch (error) {
            console.error(error);
        }
    };

    const groupBy = (array, key) => {

        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    async function getAccountsFromCustomer() {
        try {
            var response = await ServiceInstance
                .GetAccountsFromCustomer();

            if (!response)
                return;

            if (!response.data)
                return;

            setSubscriptionsAWS(response.data.map(ir => {
                return { id: ir, label: ir };
            }));

            var response = await ServiceInstance
                .GetActiveSubscritpionsFromProvider();

            if (!response)
                return;

            if (!response.data)
                return;

            var array = Object.keys(groupBy(response.data, "subscriptionId"));
            if (array.length == 0) {
                return;
            }

            setSubscriptionsAWSListSelected(array.map(ir => {
                return { id: ir, label: ir };
            }));
        } catch (error) {
            console.error(error);
        }

    };

    async function RetrieveRecord(id: number) {
        try {
            var response = await ServiceInstance
                .Retrieve(id);
            setRecord(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    async function Save(item: VendorCredential) {
        if (item.id && item.id > 0) {
            //Update
            enqueueSnackbar(`A atualização foi iniciada, aguarde um momento.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false
            })
        } else {
            //create
            if (props.providers?.find(provider => provider == item.cloudOSProviderId)) {
                enqueueSnackbar(`Já existe este provedor, adicionar chave/licença ao existente`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
                setRecord(ServiceInstance.emptyRecord)
                if (props.parentSelectionCallback) {
                    props.parentSelectionCallback();
                }
                return false
            }

            enqueueSnackbar(`A criação foi iniciada, aguarde um momento.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false
            })
        }

        try {

            if (props.record.cloudOSProviderId == 16 && providerSubscriptions && providerSubscriptions.length > 0) {
                try {
                    await ServiceInstance.SaveSubscriptionList(providerSubscriptions);

                    enqueueSnackbar(`Lista de subscrições salva`, {
                        variant: 'info',
                        preventDuplicate: true,
                        persist: false
                    });

                } catch (error) {

                    enqueueSnackbar(`Erro ao salvar lista de subscrições`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false
                    })

                }
            }

            let response = await ServiceInstance.Save(item);

            if (props.parentRetrieveListCallback != null) {
                enqueueSnackbar(`Seus dados foram enviados, o processo ocorrerá em background`, {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false
                })

                setTimeout(() => {
                    enqueueSnackbar(`Dados salvos com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false
                    })
                }, 500);

                setTimeout(() => {
                    enqueueSnackbar(`Em algumas páginas será necessário realizar a atualização manual. A sincronização dos dados pode levar até 1 hora.`, {
                        variant: 'info',
                        preventDuplicate: true,
                        persist: false
                    })
                }, 800);

                setRecord({ ...ServiceInstance.emptyRecord });
            }
        } catch (error: any) {
            let errorMessageFull: string = error.response.data;
            // if (RegExp('Não foi').test(errorMessageFull)) {
            enqueueSnackbar(errorMessageFull.trim(), {
                variant: 'error',
                preventDuplicate: true,
                persist: false
            })
            //}
            let errorMessage: string = error.response.data.split('|')[1];
            if (RegExp('Escolha um provedor valido').test(errorMessage))
                enqueueSnackbar(`Escolha um provedor valido`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
            else
                if (RegExp('Já existe este provedor').test(errorMessage))
                    enqueueSnackbar(`Já existe este provedor, adicionar chave/licença ao existente`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false
                    })
                else
                    enqueueSnackbar(`Falha ao executar a ação`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false
                    })
        } finally {
            if (props.parentSelectionCallback) {
                props.parentSelectionCallback();
            }
        }
    };

    function updateData(item: any) {
        let rec = { ...record };
        rec.vendorCredentialItem = [...item];
        setRecord({ ...rec });
    }

    const [avaibleProvidersForm, setAvailableProvidersForm] = useState<any[]>();

    useEffect(() => {
        if (props.providers && props.providers.length > 0) {
            let provTrue: any[] = [];
            let provFalse: any[] = [];

            ServiceInstance.enumProviders.map((msgTemplate) => {
                if (!props.providers?.find(provider => provider == msgTemplate.value)) {
                    provTrue.push({ ...msgTemplate, disabled: false })
                } else {
                    provFalse.push({ ...msgTemplate, disabled: true })
                }
            });

            let prov = provTrue.sort((a, b) => a.label.localeCompare(b.label, 'pt-BR'))
                .concat(provFalse.sort((a, b) => a.label.localeCompare(b.label, 'pt-BR')));

            setAvailableProvidersForm(prov);
        }
    }, [props.providers]);

    const closeAction = (funcSet) => {
        return funcSet.map((func: any) => func.id == '58d530a1-7f35-42cd-88dd-ba17eba4b6d5' && func.func())
    }

    const fields: PPFormInput[] = [
        {
            title: 'Provider',
            name: 'cloudOSProviderId',
            type: 'dropdown',
            rows: 5,
            required: true,
            options: avaibleProvidersForm ?? [{ value: 0, label: '' }],
            value: props.record && props.record.cloudOSProviderId ? props.record.cloudOSProviderId : 0,
            readOnly: props.record && props.record.cloudOSProviderId ? true : false
        }
    ]

    const handleChangeRecord = (form: any) => {
        let newRecord = ServiceInstance.emptyRecord;
        form.map(field => {
            newRecord[field.name] = field.value
        })
        newRecord.clientGroupId = user.ClientGroupSelected!;
        setRecord(newRecord);
    }

    const recordSave = (record: any) => {
        setRecord(record);
    }

    const subscriptionSave = (subscriptionList: AzureSubscription[]) => {
        setProviderSubscriptions(subscriptionList)
    }

    useEffect(() => {
    }, [providerSubscriptions])

    return (
        <PPModal
            visible={props.visible ?? false}
            title={'Assinatura ' + ServiceInstance.getProviderName(record.cloudOSProviderId)}
            onClose={() => {
                closeAction(functions.functionsEdit);
                setRecord(ServiceInstance.emptyRecord);
            }}
            size="large"
        >
            <div className="topbar">
                <div className="functions" style={{ display: 'flex', flexDirection: 'row', marginLeft: '1rem', marginBottom: '1rem', alignItems: 'flex-end' }}>
                    {record &&
                        <div style={{ width: '90%' }} >
                            <PPForm2
                                fields={fields}
                                onChange={(form) => handleChangeRecord(form)}
                                functions={{ hideFunctions: true }}
                            />
                        </div>
                    }
                    {
                        functions.functionsEdit && functions.functionsEdit
                            .filter((x: any) => x.disabled != true)
                            .map((func, index) => {
                                return func.id != '58d530a1-7f35-42cd-88dd-ba17eba4b6d5' &&
                                    <PPIconLink id={func.id} key={index} icon={func.icon} tooltip={func.tooltip} onClick={() => func.func()} />
                            })
                    }
                </div>
            </div>
            <FormControl variant="outlined" fullWidth>
                <LcLoading loading={loading} label="Carregando assinatura...">
                    {!loading && subscriptionsAWS
                        && subscriptionsAWS.length > 0
                        && (props.record.cloudOSProviderId == 17 && user.ClientGroupSelected != 999999999)
                        && <LcCheckLabelValueList
                            onChange={saveAccountFromCustomerSelected}
                            labelField="label"
                            keyField="id"
                            title='Assinaturas disponiveis'
                            list={subscriptionsAWS}
                            selectedList={subscriptionsAWSListSelected}
                            search={true}
                        />}
                    {!loading && record
                        && (props.record.cloudOSProviderId != 17 || user.ClientGroupSelected == 999999999)

                        && <div style={{ display: (props.record.id == record.id) ? "block" : "none" }}>
                            {
                                <VendorCredentialItemList
                                    providerSubscriptions={subscriptionSave}
                                    record={record}
                                    setRecord={recordSave}
                                    parentSelectionCallback={updateData} />
                            }
                        </div>}
                </LcLoading>
            </FormControl>
        </PPModal>
    );
}

export default VendorCredentialEdit;