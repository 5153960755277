import React, { createContext, FunctionComponent, useState, useRef, useContext } from 'react';

interface PivotFunctions {
    viewMode: string;
    setViewMode: (viewMode: string) => void;
    openTable: React.MutableRefObject<(() => void) | null>;
    openTree: React.MutableRefObject<(() => void) | null>;
    openChart: React.MutableRefObject<(() => void) | null>;
    openPivotConfiguration: React.MutableRefObject<(() => void) | null>;
    csvPivotConfiguration: React.MutableRefObject<(() => void) | null>;
    customPivotConfiguration: React.MutableRefObject<(() => void) | null>;
    openExternalSettings: React.MutableRefObject<(() => void) | null>;
    setOpenPivotConfiguration: (func: (() => void) | null) => void;
    setCSVPivotConfiguration: (func: (() => void) | null) => void;
    setCustomPivotConfiguration: (func: (() => void) | null) => void;
    setOpenExternalSettings: (func: (() => void) | null) => void;
    setOpenTable: (func: (() => void) | null) => void;
    setOpenTree: (func: (() => void) | null) => void;
    setOpenChart: (func: (() => void) | null) => void;
}

const defaultPivotFunctions: PivotFunctions = {
    viewMode: "Tabela",
    setViewMode: () => { },
    openTable: { current: null },
    openTree: { current: null },
    openChart: { current: null },
    openPivotConfiguration: { current: null },
    csvPivotConfiguration: { current: null },
    customPivotConfiguration: { current: null },
    openExternalSettings: { current: null },
    setOpenPivotConfiguration: () => { },
    setCSVPivotConfiguration: () => { },
    setCustomPivotConfiguration: () => { },
    setOpenExternalSettings: () => { },
    setOpenTable: () => { },
    setOpenTree: () => { },
    setOpenChart: () => { }
};

const PivotContext = createContext<PivotFunctions>(defaultPivotFunctions);

export const usePivotFunctions = (): PivotFunctions => {
    return useContext(PivotContext);
};

export const PivotProvider: FunctionComponent = ({ children }) => {
    const [viewMode, setViewMode] = useState<string>("Tabela");
    const openTable = useRef<(() => void) | null>(null);
    const openTree = useRef<(() => void) | null>(null);
    const openChart = useRef<(() => void) | null>(null);
    const openPivotConfiguration = useRef<(() => void) | null>(null);
    const csvPivotConfiguration = useRef<(() => void) | null>(null);
    const customPivotConfiguration = useRef<(() => void) | null>(null);
    const openExternalSettings = useRef<(() => void) | null>(null);

    const setOpenPivotConfiguration = (func: (() => void) | null) => {
        openPivotConfiguration.current = func;
    };

    const setCSVPivotConfiguration = (func: (() => void) | null) => {
        csvPivotConfiguration.current = func;
    };

    const setCustomPivotConfiguration = (func: (() => void) | null) => {
        customPivotConfiguration.current = func;
    };

    const setOpenExternalSettings = (func: (() => void) | null) => {
        openExternalSettings.current = func;
    };

    const setOpenTable = (func: (() => void) | null) => {
        openTable.current = func;
    };

    const setOpenTree = (func: (() => void) | null) => {
        openTree.current = func;
    };

    const setOpenChart = (func: (() => void) | null) => {
        openChart.current = func;
    };

    return (<PivotContext.Provider value={{
        viewMode,
        setViewMode,
        openTable,
        openTree,
        openChart,
        openPivotConfiguration,
        csvPivotConfiguration,
        customPivotConfiguration,
        openExternalSettings,
        setOpenPivotConfiguration,
        setCSVPivotConfiguration,
        setCustomPivotConfiguration,
        setOpenExternalSettings,
        setOpenTable,
        setOpenTree,
        setOpenChart
    }}>
        {children}
    </PivotContext.Provider>);
};