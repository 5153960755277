import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout/Layout";

import { PiArrowSquareOutLight, PiCubeLight, PiCurrencyCircleDollarLight, PiDatabaseLight, PiHardDrivesLight, PiMonitorLight, PiPiggyBankLight } from "react-icons/pi";

import { useHistory } from "react-router-dom";
import PPDropDown from "../../../components/PPDropDown";
import ReactECharts from "echarts-for-react";
import { useQuery } from "react-query";
import {
    FinOpsAdvanceFilterService,
    FinOpsDashBoard,
} from "../../../services/finOps/finOpsService";
import moment from "moment";
import FinOpsMapManagerService, {
    FinOpsAllocationRule,
} from "../../../services/finOpsManagerService";
import LcLoading from "../../../components/Generic/LcLoading";
import LcNoData from "../../../components/Generic/LcNoData";
import { FinOpsChartBuilder } from "../../../services/finOps/FinOpsChartBuilder";
import TopConcomsuption from "./TopConsuption";
import TopConsuption from "./TopConsuption";
import FinOpsNestedCostCenterCosts from "../../finOps/finOpsNestedCostCenterCosts";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "../../../store/reducers/userReducer";
import AlertConsuption from "./AlertConsuption";
import Recomendations from "./Recomendations";
import IconValue from "../../../components/Layout/Dashboard/IconValue";
import ActivityLog from "./ActivityLog";
import Websites from "./Websites";
import HigherConsumption from "./HigherConsumption";
import StorageGraph from "./StorageGraph";
import {
    KipConsumptionModel,
    OnOffModel,
    schedulesModel,
} from "../../../services/cmsPremier/cmsPremierModel";
import { CmsPremierService } from "../../../services/cmsPremier/cmsPremierService";
import NextScheduleAutomation from "./NextScheduleAutomation";
import LCDashboard, {
    Card,
    PositionCard,
} from "../../../components/Data/Dashboard/LCDashboard";
import DatabaseList from "./DatabasesList";
import WebSitesResumo from "../../websites/resumo";
import { WebSitesService } from '../../../services/websites/index';
import { MenuState } from "../../../store/reducers/menuReducer";
import { FinOpsSavingPlanReserveService } from "../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserve";
import { PiArrowSquareOut, PiChartBar, PiCube, PiCurrencyCircleDollar, PiDatabase, PiHardDrives, PiMonitor, PiPiggyBank } from "react-icons/pi";
import { RiExternalLinkLine } from "react-icons/ri";
import MessageService from "../../../services/AIx/messages/MessageService";
import LcIconLink from "../../../components/Generic/LcIconLink";
import AIxChatComponent from "../../LiveWatch/Insights/AIxChatComponent";

const beginOfMonth = () => {
    const currentDate = new Date();

    // Get the day of the month
    const dayOfMonth = currentDate.getDate();
    let result = false;
    if (dayOfMonth > 6) {
        result = false;
    } else {
        result = true;
    }
    return result;
};
interface ResumeCMSProps {
    isHome?: boolean;
    qtdGroups?: number;
}

const ResumeCMS: React.FC<ResumeCMSProps> = (props) => {
    const messageService = new MessageService({});
    const [assistantid, setAssistantid] = useState<string>("");
    const [AIXAgentVisible, setAIXAgentVisible] = useState(false);

    const finOpsMapManagerService = new FinOpsMapManagerService(props);
    const user = useSelector<RootState, UserState>((state) => state.user);
    const menu = useSelector<RootState, MenuState>((state) => state.menu);
    const dispatch = useDispatch();
    const CmsService = new CmsPremierService(props);
    const SavingsPlanReserveService = new FinOpsSavingPlanReserveService(props)
    const [month, setMonth] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(beginOfMonth() ? "priormonth" : "currentmonth");
    const [loading, setLoading] = useState<boolean>(false);
    const [rankType, setRankType] = useState<string>("resource");
    const [daysRemaining, setDaysRemaining] = useState<any>();
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>("");
    const [selectedContractId, setSelectedContractId] = useState<any>("");
    const [graphGroupType, setGraphGroupType] = useState<string>("all");
    const [confirmation, setConfirmation] = useState(false);
    const [loadingVMs, setLoadingVMs] = useState(false);
    const [loadingDatabase, setLoadingDatabase] = useState(false);
    const [loadingStorage, setLoadingStorage] = useState(false);
    const [loadingContainer, setLoadingContainer] = useState(false);
    const [loadingSavingsReserves, setLoadingSavingsReserves] = useState(false);
    const [noData, setNoData] = useState(false);
    const [refresh, setRefresh] = useState<any>("");
    const [resourceConsumptionKpi, setResourceConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [serviceConsumptionKpi, setServiceConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [costCenterConsumptionKpi, setCostCenterConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [costCenters, setCostCenters] = useState<any[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [notificacoes, setNotificacoes] = useState<any[]>([]);
    const [vmsData, setVmsData] = useState<OnOffModel>();
    const [databasesData, setDatabasesData] = useState<OnOffModel>();
    const [storageCard, setStorageCard] = useState<number>(0);
    const [containersCard, setContainersCard] = useState<{ on: number, off: number }>({ on: 0, off: 0 });
    const [savingsCard, setSavingsCard] = useState<OnOffModel>({ on: 0, off: 0 });
    const [reservesCard, setReservesCard] = useState<OnOffModel>({ on: 0, off: 0 });
    const [isWebSiteData, setIsWebSiteData] = useState<boolean>(false);
    const webSitesService = new WebSitesService(props);

    const getWebsitesClassicInfo = () => {
        webSitesService.GetWebSites()
            .then(response => {
                const webSitesRetrieved = response.data;
                setIsWebSiteData(webSitesRetrieved && webSitesRetrieved.length > 0);
            })
            .catch(error => {
                console.error("Ocorreu um erro:", error);
                setIsWebSiteData(false);
            });
    }

    useEffect(() => {
        getWebsitesClassicInfo();
        getAssitantData();
    }, [user.ClientGroupSelected, user.refreshFlag,])

    const getAssitantData = async () => {
        setAssistantid("");
        if (user && user.ClientGroupSelected) {
            var assistantid = await messageService.getAssistantId("17", user.ClientGroupSelected?.toString(), "1");
            setAssistantid(assistantid);
        }
    }
    const defaultFilter: FinOpsAllocationRule = {
        id: 0,
        order: 1,
        default: false,
        description: "Filtro",
        conditions: [
            {
                idCondition: 0,
                field: "",
                operation: "contains",
                filter: "",
                logicOperator: "",
            },
        ],
        costCenters: [],
        contracts: [],
    };

    const [filterConditions, setFilterConditions] =
        useState<FinOpsAllocationRule>(defaultFilter);

    const history = useHistory();

    const chartConsumoLast = useRef<ReactECharts>(null);

    const onChagePeriod = (selected: any) => {
        const firstFieldValue = selected.fields[0]?.value;
        const firstFieldName = selected.fields[0]?.name;

        if (firstFieldName == "month") {
            setYear(firstFieldValue.split("-")[1]);
            setMonth(firstFieldValue.split("-")[0]);
            setTipoDePeriodo(firstFieldName);
        } else {
            setYear("0");
            setMonth("0");
            setTipoDePeriodo(firstFieldValue);
        }
    };

    const rankTypeOptions = [
        { label: "Centro de Custo", value: "costCenter" },
        { label: "Serviço", value: "service" },
        { label: "Recurso", value: "resource" },
    ];

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const lineChartOption = (dados: any, max: number = 1, min: number = 1) => {
        return {
            align: "center",
            grid: {
                left: "3%",
                right: "3%",
                bottom: "3%",
                top: "3%",
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: "category",
                data: dados.map((data: any) => {
                    return data.dia;
                }),
            },
            yAxis: {
                show: false,
                type: "value",
            },
            series: [
                {
                    showSymbol: true,
                    symbol: (value, params) => {
                        return params.dataIndex == dados.length - 1 ? "circle" : "none";
                    },
                    symbolSize: 7,
                    data: dados.map((data: any) => {
                        return data.valor;
                    }),
                    type: "line",
                    silent: true, // Desativa os eventos do mouse para esta série
                },
            ],
            visualMap: {
                show: false,
                inRange: {
                    color: ["#93CE07", "#ff2100"],
                },
            },
        };
    }

    const GetVMsData = async () => {
        setLoadingVMs(true);
        try {
            const vmData = await CmsService.getKPIVMs();
            const result = vmData.reduce(
                (acc, curr) => {
                    if (curr.status.includes("running")) {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setVmsData(result);
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingVMs(false);
    }

    const GetAKSClusters = async () => {
        setLoadingContainer(true);
        try {
            const vmData = await CmsService.getAKSClusters();
            //debugger;
            const result = vmData.reduce(
                (acc, curr) => {
                    if (curr.status.includes("running") || curr.status.includes("Succeeded")) {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setContainersCard(result);
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingContainer(false);
    }

    const getSavingsAndReserves = async () => {
        setLoadingSavingsReserves(true);
        try {
            const savingsReservesData = await SavingsPlanReserveService.retrieveList();
            const resultSavings = savingsReservesData.reduce(
                (acc, curr) => {
                    if (curr.type.includes("Savings Plan")) {
                        if (curr.status.includes("Ativo"))
                            acc.on += 1;
                        else
                            acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setSavingsCard(resultSavings);

            const resultReserves = savingsReservesData.reduce(
                (acc, curr) => {
                    if (curr.type.includes("Reserva")) {
                        if (curr.status.includes("Ativo"))
                            acc.on += 1;
                        else
                            acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setReservesCard(resultReserves);
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingSavingsReserves(false);
    }

    /*
    const [loadingDatabase, setLoadingDatabase] = useState(false);
    const [loadingStorage, setLoadingStorage] = useState(false);
    const [loadingContainer, setLoadingContainer] = useState(false);
    */

    const getDatabasesData = async () => {
        setLoadingDatabase(true)
        try {
            const databases = await CmsService.getKPIDatabases();
            const result = databases.reduce(
                (acc, curr) => {
                    if (curr.status === "Online") {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setDatabasesData(result);
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingDatabase(false)
    }

    const getStoragesData = async () => {
        setLoadingStorage(true)
        try {
            const data = await CmsService.getKPIStorages();
            const latestDate = data.reduce((max, item) => item.date > max ? item.date : max, data[0]?.date);
            const filteredData = data.filter(item => item.date === latestDate);
            const result = filteredData.reduce(
                (acc, curr) => {
                    acc.total += curr.count;
                    return acc;
                },
                { total: 0 }
            );
            setStorageCard(result.total);
        } catch (error) {

            console.error("Error:", error);
        }
        setLoadingStorage(false)
    }

    useEffect(() => {
        Promise.all([
            retrieveCostCenters(),
            retrieveContracts(),
            loadData(),
            GetVMsData(),
            GetAKSClusters(),
            getDatabasesData(),
            getStoragesData(),
            getSavingsAndReserves(),
        ]);
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        setRefresh(
            user.ClientGroupSelected +
            tipoDePeriodo +
            month +
            year +
            JSON.stringify(filterConditions) +
            selectedCostCenter +
            selectedContractId
        );
    }, [
        user.ClientGroupSelected,
        filterConditions,
        tipoDePeriodo,
        selectedCostCenter,
        selectedContractId,
    ]);

    const handleChange = (event) => {
        const { name, value } = event;

        // Limpe o gráfico apenas se os dropdowns relacionados ao gráfico "Consumo mensal" forem alterados
        if (name !== "rankType") {
            chartConsumoLast.current?.getEchartsInstance().clear();
        }
        if (name == "rankType") setRankType(value);

        if (name == "graphGroupType") {
            setGraphGroupType(value);
        }
    };

    async function retrieveReportKPIs(refresh): Promise<FinOpsDashBoard> {
        try {
            let d = moment();
            let currentDay = d.get("date");
            let daysInMonth = d.daysInMonth();
            let daysRemaining = daysInMonth - currentDay;
            setDaysRemaining(daysRemaining);
            const finOpsService = new FinOpsAdvanceFilterService(props);
            let kpisLocal = await finOpsService.GetKPIByYearMonthLastPeriod(
                tipoDePeriodo,
                selectedCostCenter,
                selectedContractId,
                graphGroupType,
                filterConditions,
                Number(month),
                Number(year)
            );
            //console.log(kpisLocal);
            if (kpisLocal.length > 0) {
                kpisLocal[0].dataGroupbyTotal &&
                    kpisLocal[0].dataGroupbyTotal.forEach((rowlast) => {
                        rowlast.series = rowlast.series.sort((a: any, b: any) => {
                            return a.date > b.date ? 1 : -1;
                        });
                    });
                setResourceConsumptionKpi(
                    kpisLocal[0].resourceConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption &&
                                (kpi.consumption * 100) / kpisLocal[0].totalConsumption,
                        };
                    })
                );

                setCostCenterConsumptionKpi(
                    kpisLocal[0].costCenterConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption && (kpi.consumption * 100) / kpisLocal[0].totalConsumption
                        };
                    })
                );

                setServiceConsumptionKpi(
                    kpisLocal[0].serviceConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption &&
                                (kpi.consumption * 100) / kpisLocal[0].totalConsumption,
                        };
                    })
                );

                if (kpisLocal[0]) {
                    if (
                        kpisLocal[0].dailyConsumption.length == 0 &&
                        kpisLocal[0].dailyProviderConsumption.length == 0 &&
                        tipoDePeriodo.toLocaleLowerCase() == "currentMonth"
                    ) {
                        setConfirmation(true);
                    }
                }
                setLoading(false);
                return kpisLocal[0] as unknown as FinOpsDashBoard;
            } else {
                setNoData(true);
            }
        } catch (e) { }
        setLoading(false);
        return {} as FinOpsDashBoard;
    }

    //const {
    //    data: kpi,
    //    isLoading: isLoadingKPIs,
    //    refetch: refetchReportKPI,
    //    isRefetching: isRefetchingKPIs,
    //} = useQuery<FinOpsDashBoard>(
    //    ["kpi", refresh],
    //    async () => {
    //        return await retrieveReportKPIs(refresh);
    //    },
    //    {
    //        keepPreviousData: true,
    //        enabled: true,
    //        staleTime: 7200000,
    //        onSuccess: () => {
    //            console.log("onSuccess");
    //            setLoading(false);
    //        },
    //        optimisticResults: true,
    //    }
    //);

    const [kpi, setKPI] = useState<FinOpsDashBoard | null>(null);
    const [isLoadingKPIs, setIsLoadingKPIs] = useState(true);
    const [isRefetchingKPIs, setIsRefetchingKPIs] = useState(false); // Adicione a variável isRefetchingKPIs aqui

    //const refresh = /* Defina o valor de refresh conforme necessário */;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fazer a chamada à função retrieveReportKPIs aqui
                const result = await retrieveReportKPIs(refresh);
                setKPI(result);
                setIsLoadingKPIs(false);
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
                setIsLoadingKPIs(false);
            }
        };
        fetchData();
    }, [refresh]);

    async function retrieveCostCenters() {
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService(props);
            let costCenterResponse =
                await finOpsMapManagerService.LoadCostCentersByUserId();
            let costCenters = [
                { label: "(Todos)", value: "" },
                ...costCenterResponse.map((u) => {
                    return {
                        label: u.name,
                        value: u.id,
                    };
                }),
            ];

            setCostCenters(costCenters);
        } catch (e) {
            console.warn(e);
        }
    }
    async function retrieveContracts() {
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService(props);

            var contracts = await finOpsMapManagerService.LoadContracts();

            contracts = contracts.map((u) => {
                return {
                    label: u.contractName,
                    value: u.contractId,
                };
            });

            let contractsLocal = [{ label: "(Todos)", value: "" }, ...contracts];
            setContracts(contractsLocal);
        } catch (e) { }
    }
    async function loadData() {
        setLoading(true)
        try {

            var response = await finOpsMapManagerService.LoadProcessedAlertApproved();

            var list = response.alerts;
            var notificationList = [];

            if (list.filter((x) => x.send == true).length != 0) {
                notificationList = list
                    .filter((x) => x.send == true)
                    .map((c) => {
                        var recordResult = {} as any;
                        try {
                            recordResult.id = c.id;
                            recordResult.alertIcon =
                                c.conditions[0].condition == "budget"
                                    ? "ppi ppi-bell"
                                    : c.conditions[0].comparator == "mais"
                                        ? "ppi ppi-arrow-up"
                                        : "ppi ppi-arrow-down";
                            recordResult.alertClientGroup = {
                                id: response.clientGroupId,
                                description: "Processor",
                            };
                            recordResult.alertDescription = c.description;
                            recordResult.alertDate = c.date ? c.date : moment().toISOString();

                            recordResult.alertCostCenter = c.costCenters;
                            recordResult.alertContracts = c.contracts;
                            recordResult.alertResources = c.resources;
                            recordResult.alertServices = c.services;

                            recordResult.alertPriorPeriodTotal =
                                c.priorPeriodTotal.toLocaleString("pt-BR", {
                                    style: "decimal",
                                    maximumFractionDigits: 2,
                                });
                            recordResult.alertPeriodTotal = c.periodTotal.toLocaleString(
                                "pt-BR",
                                { style: "decimal", maximumFractionDigits: 2 }
                            );
                            recordResult.alertAchieved = c.achieved;
                            recordResult.alertDifference = c.difference;
                            recordResult.budgetLimit = c.budgetLimit;
                            if (c.conditions && c.conditions.length > 0 && c.conditions[0]) {
                                recordResult.alertVariation =
                                    c.cloudCostEffectiveVariation.toLocaleString("pt-BR", {
                                        style: "decimal",
                                        maximumFractionDigits: 2,
                                    }) + "%";
                                recordResult.alertCondition =
                                    c.conditions[0].condition == "variacao" ? " Variação" : "N/A";

                                // Verificar se a propriedade 'percentage' está presente e não é null
                                if (c.conditions[0].percentage != null) {
                                    recordResult.alertPercentage =
                                        (c.conditions[0] ? "+" : "-") +
                                        c.conditions[0].percentage.toLocaleString("pt-BR", {
                                            style: "decimal",
                                            maximumFractionDigits: 2,
                                        }) + "%";
                                } else {
                                    // Tratar o caso em que percentage é null ou undefined
                                    recordResult.alertPercentage = "N/A"; // Ou qualquer outro valor padrão
                                }

                                recordResult.alertCompatator =
                                    c.conditions[0].comparator == "mais" ? "Mais" : "Menos";
                            }

                            recordResult.alertPeriod = "Últimos";

                            try {
                                recordResult.alertPeriodValue = c.period.periodValue;
                            } catch (ec) {
                                console.error(ec);
                            }

                            recordResult.alertPeriodUnit = "dias";
                            recordResult.alertPeriodStartDate = moment(new Date()).subtract(
                                c.period.periodValue + 2,
                                "days"
                            );
                            recordResult.alertPeriodEndDate = moment(new Date()).subtract(
                                2,
                                "days"
                            );
                            recordResult.alertScope = c.escope;

                            if (c.conditions[0].condition == "variacao") {
                                recordResult.alertHistory = {
                                    history: c.history.map((x) => {
                                        return {
                                            dia: x.dia + "/" + x.mes + "/" + x.ano,
                                            valor: x.total,
                                        };
                                    }),
                                    dataDoAlerta:
                                        c.history && c.history.length > 0
                                            ? new Date(
                                                c.history[0].dia +
                                                "/" +
                                                c.history[0].mes +
                                                "/" +
                                                c.history[0].ano
                                            ).toLocaleDateString()
                                            : "-",
                                };
                            } else if (c.conditions[0].condition == "budget") {
                                recordResult.alertHistory = {
                                    history: c.history.map((x) => {
                                        return {
                                            dia: x.dia + "/" + x.mes + "/" + x.ano,
                                            valor: x.accTotal,
                                        };
                                    }),
                                    dataDoAlerta:
                                        c.history && c.history.length > 0
                                            ? new Date(
                                                c.history[0].dia +
                                                "/" +
                                                c.history[0].mes +
                                                "/" +
                                                c.history[0].ano
                                            ).toLocaleDateString()
                                            : "-",
                                };
                            }
                        } catch (e) {
                            setLoading(false);
                            console.error(`Ocorreu um erro: ${e}`);
                        }
                        return recordResult;
                    });
            }

            let sortedNotificationList = notificationList.sort(
                (element1: any, element2: any) => {
                    return element1.alertDate < element2.alertDate ? 1 : -1;
                }
            );

            setNotificacoes(sortedNotificationList);
        } catch (error) {
            setLoading(false);
            console.warn(error);
            setNotificacoes([]);
        }
    }

    const gridConfig = {
        recomendacoes: {
            id: 1,
            grid: { ColSpan: 4, col: 1, RowSpan: 2, row: 1 }
        },
        vms: {
            id: 3,
            grid: { ColSpan: 2, col: 5, RowSpan: 1, row: 1 }
        },
        databases: {
            id: 4,
            grid: { ColSpan: 2, col: 5, RowSpan: 1, row: 2 }
        },
        storages: {
            id: 5,
            grid: { ColSpan: 2, col: 7, RowSpan: 1, row: 1 }
        },
        containers: {
            id: 6,
            grid: { ColSpan: 2, col: 7, RowSpan: 1, row: 2 }
        },
        savings: {
            id: 8,
            grid: { ColSpan: 2, col: 9, RowSpan: 1, row: 1 }
        },
        reservas: {
            id: 2,
            grid: { ColSpan: 2, col: 9, RowSpan: 1, row: 2 }
        },
        agendamentos: {
            id: 7,
            grid: { ColSpan: 3, col: 11, RowSpan: 1, row: 1 }
        },
        alertas: {
            id: 9,
            grid: { ColSpan: 3, col: 11, RowSpan: 1, row: 2 }
        },
        topconsumos: {
            id: 10,
            grid: isWebSiteData ?
                { ColSpan: 5, col: 1, RowSpan: 2, row: 3 } :
                { ColSpan: 6, col: 1, RowSpan: 2, row: 3 }
        },
        consumos: {
            id: 11,
            grid: isWebSiteData ?
                { ColSpan: 5, col: 6, RowSpan: 2, row: 3 } :
                { ColSpan: 7, col: 7, RowSpan: 2, row: 3 }
        },
        websites: {
            id: 12,
            grid: isWebSiteData ?
                { ColSpan: 3, col: 11, RowSpan: 2, row: 3 } :
                { ColSpan: 0, col: 0, RowSpan: 0, row: 0, hidden: true }
        },
    }

    //homerainbow
    const positions5Groups: Card[] = [
        //Recomendações
        {
            id: 1,
            position: { row: 5, col: 1, ColSpan: 2, RowSpan: 3, hidden: false },

        },
        {
            id: 2, //reservas
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: 3, col: 1, hidden: false },

        },
        {
            id: 3, //VMs
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: 1, col: 1, hidden: false },

        },
        {
            id: 4, //Databases   
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: 1, col: 2, hidden: false },

        },
        {
            id: 5, //Storage
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: 2, col: 1, hidden: false },

        },
        {
            id: 6, //Containers
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: 2, col: 2, hidden: false },

        },
        {
            //proximo agendamento
            id: 7, hideHeader: true,
            position: { row: 4, col: 1, ColSpan: 2, RowSpan: 1, hidden: false },

        },
        {
            //savings
            id: 8,
            hideHeader: true,
            position: { row: 3, col: 2, ColSpan: 1, RowSpan: 1, hidden: false },

        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)

            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },

        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },

        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },

        },
    ];

    var positions3Groups: Card[] = [
        //Recomendações
        {
            id: 1,
            position: { ColSpan: 2, RowSpan: 3, row: 3, col: 1, hidden: false },

        },
        {
            id: 2, //Armazenamento

            position: { ColSpan: 3, RowSpan: 2, row: 1, col: 4, hidden: true },

        },
        {
            id: 3, //VMs

            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 7, hidden: false },

        },
        {
            id: 4, //Databases   

            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 7, hidden: false },

        },
        {
            id: 5, //Storage

            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 9, hidden: false },

        },
        {
            id: 6, //Containers

            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 9, hidden: false },

        },
        {
            id: 7,
            position: { row: 1, col: 1, ColSpan: 2, RowSpan: 1, hidden: false },

        },
        {
            id: 8,
            position: { row: 2, col: 11, ColSpan: 3, RowSpan: 1, hidden: true },

        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)

            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },

        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },

        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },

        },
    ];

    var positions2Groups: Card[] = [
        //Recomendações
        {
            id: 1,
            position: { ColSpan: 3, RowSpan: 2, row: 1, col: 1, hidden: false },
        },
        {
            id: 2, //Armazenamento
            position: { ColSpan: 3, RowSpan: 2, row: 1, col: 4, hidden: true },
        },
        {
            id: 3, //VMs
            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 7, hidden: true },
        },
        {
            id: 4, //Databases   
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 7, hidden: true },
        },
        {
            id: 5, //Storage
            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 9, hidden: true },
        },
        {
            id: 6, //Containers
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 9, hidden: true },
        },
        {
            id: 7,
            position: { row: 1, col: 1, ColSpan: 2, RowSpan: 1, hidden: false },
        },
        {
            id: 8,
            position: { row: 2, col: 11, ColSpan: 3, RowSpan: 1, hidden: true },
        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)
            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },
        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },
        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },
        },
    ];

    function getConfigHome(
        cardId: number | undefined,
        qtdGroups: number
    ): Card {
        var Id = cardId as number;
        positions2Groups = positions5Groups;
        positions3Groups = positions5Groups;
        if (qtdGroups <= 2) {
            return positions2Groups.filter((c) => c.id == Id).length > 0 ? positions2Groups.filter((c) => c.id == Id)[0] : {} as unknown as Card;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter((c) => c.id == Id).length > 0 ? positions3Groups.filter((c) => c.id == Id)[0] : {} as unknown as Card;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter((c) => c.id == Id).length > 0 ? positions3Groups.filter((c) => c.id == Id)[0] : {} as unknown as Card;
        }
        return {} as unknown as Card;
    }

    const navigateToAutomations = () => {
        const location = history.location.pathname;
        history.push(location.replace('resume', 'automacoes'))
    }

    const navigateToEnvironment = () => {
        const location = history.location.pathname;
        const cmsType = location.includes('premier') ? 1 : 0;
        history.push(location.replace('resume', `environment/${cmsType}`))
    }

    const cards: Card[] = [
        {// 1. Recomendações
            id: gridConfig.recomendacoes.id,
            type: "Custom",
            position: gridConfig.recomendacoes.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => !props.isHome ? <PiArrowSquareOutLight color='#173192' onClick={navigateToEnvironment} /> : undefined,
            title: () => "Recomendações",
            customContentRender: () => <div><Recomendations isHome={props.isHome} /></div>
        },
        {// 2. VMs
            id: gridConfig.vms.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.vms.grid,
            showFocusButton: false,
            title: () => "VMs",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'VMs' } }) }} />,
            customContentRender: () => {
                return (
                    <LcLoading loading={loadingVMs}  >
                        {(
                            <>
                                <IconValue isHome={props.isHome}
                                    icon={<PiMonitorLight size={32} />}
                                    value={vmsData ? `${vmsData?.on}/${vmsData?.off + vmsData?.on}` : '0/0'}
                                    color="var(--color-primary-magenta)"
                                    description={props.isHome ? 'VMs' : 'Ativas/Total'}
                                />
                            </>
                        )}
                    </LcLoading>
                );
            },
        },
        {// 3. Databases
            id: gridConfig.databases.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.databases.grid,
            showFocusButton: false,
            title: () => "Databases",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Databases' } }) }} />,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingDatabase}  >
                        {(
                            <>
                                {!focused ? <IconValue isHome={props.isHome}
                                    icon={<PiDatabaseLight size={32} />}
                                    value={databasesData ? `${databasesData?.on}` : '0'}
                                    color="var(--color-secondary-green-light)"
                                    description={props.isHome ? 'Databases' : 'Ativas'}
                                /> : <DatabaseList focus={focused}></DatabaseList>}
                            </>

                        )}
                    </LcLoading>
                );
            },
        },
        {// 4. Storage
            id: gridConfig.storages.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.storages.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Storage' } }) }} />,
            title: () => "Storage",
            customContentRender: () => {
                return (
                    <LcLoading loading={loadingStorage}>
                        {(
                            <>
                                <IconValue isHome={props.isHome}
                                    icon={<PiHardDrivesLight size={32} />}
                                    value={`${storageCard ? storageCard : 0}`}
                                    color="var(--color-secondary-purple-bright)"
                                    description={'Storages'}
                                />
                            </>
                        )}
                    </LcLoading>
                );
            },
        },
        {// 5. Containers
            id: gridConfig.containers.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.containers.grid,
            showFocusButton: false,
            title: () => "Containers",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Containers' } }) }} />,
            customContentRender: () => {
                return (
                    <LcLoading loading={loadingContainer}>
                        {(
                            <>
                                <IconValue isHome={props.isHome}
                                    icon={<PiCubeLight size={32} />}
                                    value={`${containersCard.on}/${(containersCard.on + containersCard.off)}`}
                                    color="var(--color-secondary-green-dark)"
                                    description={props.isHome ? 'Containers' : 'Ativas/Total'}
                                />
                            </>
                        )}
                    </LcLoading>
                );
            },
        },
        {// 6. Savings plans
            id: gridConfig.savings.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.savings.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Savings plans' } }) }} />,
            title: () => "Savings plans",
            customContentRender: () => {
                return (
                    <LcLoading loading={loadingSavingsReserves}  >
                        {(
                            <>
                                <IconValue isHome={props.isHome}
                                    icon={<PiPiggyBankLight size={32} />}
                                    value={savingsCard ? `${savingsCard?.on}` : '0'}
                                    color="var(--color-secondary-cyan)"
                                    description={props.isHome ? 'Savings Plans' : 'Ativos'}
                                />
                            </>
                        )}
                    </LcLoading>
                );
            }
        },
        {// 7. Reservas
            id: gridConfig.reservas.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.reservas.grid,
            showFocusButton: false,
            title: () => "Reservas",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Reservas' } }) }} />,
            customContentRender: () => {
                return (
                    <LcLoading loading={loadingSavingsReserves}  >
                        {(
                            <>
                                <IconValue isHome={props.isHome}
                                    icon={<PiCurrencyCircleDollarLight size={32} />}
                                    value={reservesCard ? `${reservesCard?.on}` : '0'}
                                    color="var(--color-primary-pink)"
                                    description={props.isHome ? 'Reservas' : 'Ativas'}
                                />
                            </>
                        )}
                    </LcLoading>
                );
            }
        },
        {// 8. Próximo agendamento
            id: gridConfig.agendamentos.id,
            cssClass: props.isHome ? 'no-padding' : undefined,
            showFocusButton: false,
            hideHeader: false,
            ticketButtonLabel: !props.isHome ? "Ir para automações" : undefined,
            ticketButton: !props.isHome ? () => {
                return <PiArrowSquareOutLight color='#fff' onClick={navigateToAutomations} />;
            } : undefined,
            bgColor: props.isHome ? "#fff" : "var(--color-primary-purple)",
            title: () => {
                return (
                    props.isHome ?
                        <div className="lc-title ellipsis">Próximo agendamento</div>
                        :
                        <div style={{ color: "white" }} className="lc-title ellipsis">Agendamentos</div>
                );
            },
            type: "Custom",
            position: gridConfig.agendamentos.grid,
            customContentRender: () => {
                return <NextScheduleAutomation isHome={props.isHome} />;
            },
        },
        {// 9. Alerta de consumo
            id: gridConfig.alertas.id,
            type: "Custom",
            position: gridConfig.alertas.grid,
            ticketButtonLabel: notificacoes?.length > 0 ? "Ir para alertas" : undefined,
            ticketButton: () => {
                return (
                    notificacoes?.length > 0 ?
                        <PiArrowSquareOutLight
                            onClick={() => {
                                history.push("/finops/alertasNotificacao")
                            }}
                        />
                        :
                        undefined
                );
            },
            title: () => {
                return <div className="lc-title ellipsis">Alerta de consumo</div>;
            },
            customContentRender: () => {
                const hasAlerts = notificacoes?.length > 0;
                const alertDescription = hasAlerts
                    ? notificacoes[0].alertDescription
                    : "";

                // Fixed check to ensure that all properties exist and 'history' is an array
                const chartOptions = hasAlerts && notificacoes[0].alertHistory && Array.isArray(notificacoes[0].alertHistory.history)
                    ? lineChartOption(notificacoes[0].alertHistory.history, 1, 0)
                    : [];

                return (
                    <LcLoading loading={loading}>
                        <AlertConsuption
                            hasAlerts={hasAlerts}
                            chartOptions={chartOptions}
                            alertDescription={alertDescription}
                        />
                    </LcLoading>
                );
            },
        },
        {// 10. Top consumos (%)
            id: gridConfig.topconsumos.id,
            type: "Custom",
            showFocusButton: !props.isHome,
            position: gridConfig.topconsumos.grid,
            title: () => {
                return (
                    <>
                        {
                            <div className={"lc-segment-title-row-resume"}>
                                <div className={"lc-title ellipsis"}>
                                    Top consumos (%)
                                </div>
                                <PPDropDown
                                    name="rankType"
                                    options={rankTypeOptions}
                                    defaultValue={rankTypeOptions.find(
                                        (option) => option.value == rankType
                                    )}
                                    value={rankTypeOptions.find(
                                        (option) => option.value == rankType
                                    )}
                                    onChange={(event) =>
                                        handleChange({ ...event, name: "rankType" })
                                    }
                                    width="120px"
                                />
                            </div>
                        }
                    </>
                );
            },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingKPIs || isRefetchingKPIs || loading}>
                        <TopConsuption
                            //key={kpi}
                            kpi={kpi}
                            resourceConsumptionKpi={resourceConsumptionKpi}
                            costCenterConsumptionKpi={costCenterConsumptionKpi}
                            serviceConsumptionKpi={serviceConsumptionKpi}
                            rankType={rankType}
                            rankTypeOption={rankTypeOptions.find((option) => option.value === rankType)?.label}
                        />
                    </LcLoading>
                );
            },
        },
        {// 11. Consumos(%)
            id: gridConfig.consumos.id,
            type: "Custom",
            showFocusButton: !props.isHome,
            position: isWebSiteData ? gridConfig.consumos.grid : gridConfig.consumos.grid,
            customContentRender: (focused?: boolean) => {
                return (
                    <HigherConsumption
                        costCenters={costCenters}
                        contracts={contracts}
                        selectedContract={selectedContractId}
                        selectedCostCenter={selectedCostCenter}
                        tipoDePeriodo={tipoDePeriodo}
                        filter={filterConditions}
                        focused={focused}
                    />
                );
            },
        },
        {// 12. Websites
            id: gridConfig.websites.id,
            type: "Custom",
            position: isWebSiteData ? { ColSpan: 3, RowSpan: 2, row: 3, col: 11, } : gridConfig.websites.grid,
            showFocusButton: false,
            title: () => "Websites",
            cssClass: 'overflow-hidden',
            customContentRender: () => <WebSitesResumo isHome />
        }
    ];

    useEffect(() => {
        if (menu && menu.selectedItem) {
            let isCMSMenuSelected = [
                "1dbdb5d1-64ff-4100-8bbc-77ad70874e9e", // corporate
                "5cdc40b0-f7da-4cc7-86be-a477315d733b", // enterprise
                "3911a37e-8334-4078-a497-2120514a1699"  // premier
            ].includes(menu.selectedItem.id);

            isCMSMenuSelected && loadData();
            isCMSMenuSelected && dispatch({ type: 'MENU_VISIBLE', payload: false });
        }
    }, [menu.selectedItem])

    var assitantToolBaricon = [{
        icon: <LcIconLink icon='ppi ppi-lightning-bolt'
            id='aiLCChamados'
            size='small'
            degrade
            onClick={() => { }}
            tooltip={"LiveWatch AI"}
            tooltipPosition='right' />,
        onClick: () => {
            setAIXAgentVisible(!AIXAgentVisible)
        }, tooltip: `LiveWatch AI`
    }];

    return props.isHome ? (
        <LCDashboard
            isHomeType
            grid={[7, 2]}
            cards={
                props.qtdGroups && props.qtdGroups > 0
                    ? cards.map((card, index) => {
                        if (props.qtdGroups) {
                            const posi = getConfigHome(card.id, props.qtdGroups);
                            if (posi !== undefined) {
                                card.position = posi.position;
                                if (posi.hideHeader) {
                                    card.hideHeader = posi.hideHeader;
                                }
                            }
                        }
                        return card;
                    })
                    : []
            }
        />
    ) : (
        <Layout
            pageTitle="Resumo"
            periodSelection={periodSelection}
            functionsGeneric={assistantid && assistantid.length > 0 ? [{
                icon: (
                    <LcIconLink
                        icon='ppi ppi-lightning-bolt'
                        id='aiLCChamados'
                        size='small'
                        degrade
                        tooltip="CMS AI"
                        tooltipPosition="right"
                    />
                ),
                onClick: () => setAIXAgentVisible(!AIXAgentVisible), // Corrigido para alterar o estado corretamente
                tooltip: "CMS AI"
            }] : []}
        >
            <LCDashboard
                cards={cards}
                rightSidePanel={assistantid && assistantid.length > 0 && AIXAgentVisible ? {
                    title: 'LiveCloud AI',
                    titleClass: 'ai-gradient-text',
                    pinned: false,
                    hiddenPin: false,
                    show: AIXAgentVisible,
                    focusable: true,
                    content: () => (
                        <LcLoading loading={loading}>
                            <div style={{ alignSelf: 'center' }}>
                                <AIxChatComponent eventChat={(_event) => { }} assistantId={assistantid} assistantName={"LiveWatch"} />
                            </div>
                        </LcLoading>
                    ),
                } : undefined}
            />
        </Layout>
    );
};

export default ResumeCMS;
