import { Toolbar } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LcIconLink from '../../../components/Generic/LcIconLink';
import Layout from '../../../components/Layout/Layout';
import FinOpsMapManagerService, { FinOpsAllocationGroup, FinOpsAllocationRule, FinOpsAllocationRuleCondition, FinOpsAllocationRuleContract, ProcessedContractAllocation } from '../../../services/finOpsManagerService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { PPGrid, PPIconLink, PPNotification, PPModal } from 'processor-plataform-ui/dist';
import './index.css'
import LcTable from '../../../components/Data/LcTable';
import Moment from 'moment';
import Confirmation from '../../../components/Dialog/Confirmation';
import AllocationContracts from './contractsEdit';
import { NotificationCenterService } from '../../../services/notificationCenter/notificationService';
import { PiArrowDownLight, PiArrowLeftLight, PiArrowUpLight, PiClockClockwiseLight, PiCopyLight, PiEyeLight, PiPencilSimpleLight, PiPlayLight, PiPlusLight, PiTrashLight } from 'react-icons/pi';

export interface FinOpsValidate {
    name: string;
    validateMessage: string;
    valid: boolean;
    ruleId: number;
    costCenterId: number;
    conditionId: number;
}

const FinOpsAllocationContract: React.FC = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector<RootState, UserState>((state) => state.user);
    const finOpsMapManagerService = new FinOpsMapManagerService(props)
    const [allocationVersionLoading, setAllocationVersionsLoading] = useState<boolean>(false);
    const [allocationHistoryVersionLoading, setAllocationHistoryVersionLoading] = useState<boolean>(false);
    const [contracts, setContracts] = useState<any[]>([]);
    const [tableRules, setTableRules] = useState<FinOpsAllocationRule[]>([]);
    const [allocationResultLoading, setAllocationResultLoading] = useState<boolean>(false);
    const [parentContract, setParentContract] = useState<number>(0);
    const [allocationResultShow, setAllocationResultShow] = useState<boolean>(false);
    const [contractResultAllocation, setContractResultAllocation] = useState<ProcessedContractAllocation[]>([]);
    const [versionShow, setVersionShow] = useState(false);
    const [viewVersion, setViewVersion] = useState(false);
    const [versionTableRules, setVersionTableRules] = useState<FinOpsAllocationRule[]>([]);
    const [validations, setValidations] = useState<FinOpsValidate[]>([]);
    const [allocationVersionsData, setAllocationVersionsData] = useState<any[]>([]);
    const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
    const [confirmExecuteVisible, setConfirmExecuteVisible] = useState(false);
    const [confirmRestoreVisible, setRestoreVisible] = useState(false);
    const [ruleToRemove, setRuleToRemove] = useState<FinOpsAllocationRule>();
    const [version, setVersion] = useState<{ name: string, lastModified: string }>();
    const [executionSuccess, setExecutionSuccess] = useState(false);
    const [ruleToRestore, setRuleToRestore] = useState();

    const emptyRecordCostCenter: FinOpsAllocationRuleContract = {
        idContractRule: 1,
        idContract: '',
        contract: '',
        percentage: 100,
        percentageLocked: false,
    };

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 1,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: 'and'
    };

    const emptyRecord: FinOpsAllocationRule = {
        id: 0,
        order: 1,
        default: false,
        description: "",
        conditions: [emptyRecordCondition],
        costCenters: [],
        contracts: [emptyRecordCostCenter]
    };

    const [createRule, setCreateRule] = useState<FinOpsAllocationRule | undefined>(undefined);
    const [infoRule, setInfoRule] = useState<FinOpsAllocationRule | undefined>(undefined);
    const [editRule, setEditRule] = useState<FinOpsAllocationRule | undefined>(undefined);

    const defaultRecord: FinOpsAllocationRule = {
        id: 99999,
        order: 1,
        default: true,
        description: 'Regra padrão',
        conditions: [{
            idCondition: 1,
            field: 'ResourceID',
            operation: 'notEqual',
            filter: '*',
            logicOperator: 'and'
        }],
        costCenters: [],
        contracts: [{
            idContractRule: 1,
            idContract: '',
            contract: '',
            percentage: 100,
        }]
    };

    const [versionRecord, setVersionRecord] = useState<FinOpsAllocationGroup>(
        {
            id: 0,
            clientGroupId: 0,
            month: 0,
            year: 0,
            endDate: null,
            startDate: null,
            version: '',
            type: '',
            name: '',
            updatedByUser: '',
            updatedByUserName: '',
            updatedByUserEmail: '',
            updatedAt: '',
            rules: []
        });


    const notification = new NotificationCenterService(props);
    const dispatch = useDispatch();
    const sendMessage = (content: string, context: "success" | "warning" | "danger" | "info") => {
        let msg = {
            subject: "Alerta",
            content,
            context,
        };
        notification.PostInterfaceNotification(dispatch, msg);
    }

    async function retrieveData() {
        setAllocationVersionsLoading(true);
        setTableRules([]);
        setVersionRecord(
            {
                id: 0,
                clientGroupId: 0,
                month: 0,
                year: 0,
                endDate: null,
                startDate: null,
                version: '',
                type: '',
                name: '',
                updatedByUser: '',
                updatedByUserName: '',
                updatedByUserEmail: '',
                updatedAt: '',
                rules: []
            });
        retrieveContracts();
        try {
            var resultBase = await finOpsMapManagerService.GetAllocationVersionsContract();
            setAllocationVersionsData(resultBase);
            var result = resultBase.map((u) => {
                return {
                    "label": u.name.split('/')[3].split('.')[0],
                    "value": u.name.split('/')[3].split('.')[0]
                };
            });
            await loadRuleGroup(result[0].value, false);
        } catch (e) {
            sendMessage("Ops! Parece que os dados não estão prontos, favor tentar mais tarde.", "warning");
            console.warn(e);
        } finally {
            setAllocationVersionsLoading(false);
        }
    }

    async function retrieveContracts(): Promise<any> {
        setAllocationVersionsLoading(true);
        try {
            var resultBase = await finOpsMapManagerService.LoadContracts();
            var result = resultBase.sort(
                (element1: any, element2: any) => {
                    return element1.contractName.localeCompare(element2.contractName, 'pt-br');
                }
            ).map((u) => {
                return {
                    "label": u.contractName,
                    "value": u.contractId.toString()
                };
            });
            setContracts([...result]);
            //var idParent = resultBase.filter(c => c.parent == 0)[0].id;
            //setParentContract(idParent);
        } catch (e) {
            console.error(e);
        }
        setAllocationVersionsLoading(false);
    }

    useEffect(() => {
        retrieveData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    async function downRankRule(rule) {
        var rulesBelow = tableRules.filter(c => c.order > rule.order);
        let max = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            max = Math.min.apply(null, ids);
        } else {
            return;
        }
        tableRules.filter(c => c.order == max).map(item => {
            item["order"] = rule.order;

        });

        rule["order"] = max;
        setTableRules([...tableRules]);
        saveRuleGroup();
    }

    function disableDownRankRule(rule: any) {
        let defaultr = tableRules.filter(object => object.default != true)
        return Math.max.apply(null, defaultr.map(object => { return object.order })) == rule.order
    }

    async function upRankRule(rule) {

        var rulesBelow = tableRules.filter(c => c.order < rule.order);
        let min = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            min = Math.max.apply(null, ids);
        } else {
            return;
        }
        tableRules.filter(c => c.order == min).map(item => {
            item["order"] = rule.order;

        });

        rule["order"] = min;
        setTableRules([...tableRules]);
        saveRuleGroup();
    }

    function duplicateRuleRecord(item?: any) {
        var newRecord = JSON.parse(JSON.stringify(item));
        newRecord.description = item.description + ' - Cópia';
        let max = 0;
        let maxorder = 0;
        let rules = tableRules.filter(rule => rule.default != true)

        if (tableRules.length > 0) {
            max = Math.max.apply(null, rules.map(object => {
                return object.id;
            }));
        }

        newRecord.id = max + 1;

        if (rules.length > 0) {
            const idOrders = rules.map(object => {
                return object.order;
            });
            maxorder = Math.max.apply(null, idOrders);
        }

        if (maxorder == 0) {
            newRecord.order = 1;
            if (item != undefined) {
                maxorder = item.order;
                var items = tableRules.filter(x => x.order >= item.order);
                items.forEach(row => {
                    row.order = row.order + 1;
                });
            }
        } else {
            if (item != undefined) {
                newRecord.order = item.order + 1;
                maxorder = item.order + 1;
                var items = tableRules.filter(x => x.order > item.order);
                items.forEach(row => {
                    row.order = maxorder + 1;
                    maxorder++
                });
            }
        }

        tableRules.push({ ...newRecord });
        setTableRules([...tableRules]);
        saveRuleGroup();
    }

    function addRuleRecord(item?: any) {
        var newRecord = emptyRecord;
        let maxorder = 0;
        let table = tableRules.map(r => { return { ...r } });
        let rules = table.filter(rule => rule.default != true);

        if (rules.length > 0) {
            const idOrders = rules.map(object => {
                return object.order;
            });
            maxorder = Math.max.apply(null, idOrders);
        }

        if (maxorder == 0) {
            newRecord.order = 1;
            if (item != undefined) {
                maxorder = item.order;
                var items = table.filter(x => x.order >= item.order);
                items.forEach(row => {
                    row.order = row.order + 1;
                });
            }
        } else {
            if (item != undefined) {
                newRecord.order = item.order + 1;
                maxorder = item.order + 1;
                var items = table.filter(x => x.order > item.order);
                items.forEach(row => {
                    row.order = maxorder + 1;
                    maxorder++
                });
            }
        }
        setCreateRule({ ...newRecord });
    }

    function removeRecord(item) {
        let minOrder = 0;
        let result = tableRules.filter(x => x.id != item.id);
        if (result.length > 0) {
            const idOrders = tableRules.map(object => {
                return object.order;
            });
            minOrder = Math.min.apply(null, idOrders);
        }

        result.forEach(row => {
            row.order = minOrder;
            minOrder++;
        });

        setTableRules([...result]);
        saveRuleGroup(result)
    }

    async function saveRuleGroup(ruleGroup?: FinOpsAllocationRule[]) {
        versionRecord.rules = ruleGroup ? ruleGroup : tableRules;
        versionRecord.startDate = new Date().toLocaleDateString();

        if (!validateRules()) {
            alertValidateError();
            return;
        }
        setAllocationVersionsLoading(true);
        try {
            await finOpsMapManagerService.SaveFinOpsAllocationContract(versionRecord);

        } catch (e) {

        }
        await retrieveData();
        setAllocationVersionsLoading(false);
    }

    async function loadRuleGroup(versionName: string, versionTable: boolean) {
        versionTable ?
            setAllocationHistoryVersionLoading(true)
            :
            setAllocationVersionsLoading(true)

        try {
            var resultData = await finOpsMapManagerService.LoadAllocationContract(versionName);
            if (resultData.rules.filter(c => c.default == true).length == 0) {
                var newDefault = { ...defaultRecord };

                if (parentContract > 0) {
                    newDefault.contracts[0].idContract = parentContract.toString();
                }
                resultData.rules.push(newDefault);
            }
            if (versionTable != true) {
                setVersionRecord(resultData);
                setTableRules([...resultData.rules]);
            }

            versionTable ?
                setAllocationHistoryVersionLoading(false)
                :
                setAllocationVersionsLoading(false)
            return resultData.rules;
        } catch (e) {

            console.warn(e)
        }
        versionTable ?
            setAllocationHistoryVersionLoading(false)
            :
            setAllocationVersionsLoading(false)
    }

    function alertValidateError() {
        validations.map((item) => {
            enqueueSnackbar(item.validateMessage, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        });
    }

    function validateRules() {
        var validationsChecks: FinOpsValidate[] = [];

        if (!(versionRecord.rules.length > 0)) {
            validationsChecks.push({
                name: 'rules',
                validateMessage: 'Deve possuir pelo menos uma regra cadastrada.',
                valid: false,
                ruleId: 0,
                costCenterId: 0,
                conditionId: 0
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.description.length > 0)) {
                    validationsChecks.push({
                        name: 'description',
                        validateMessage: 'Todas as regras devem possuir uma descrição.',
                        valid: false,
                        ruleId: item.id,
                        costCenterId: 0,
                        conditionId: 0
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.conditions.length > 0)) {
                    validationsChecks.push({
                        name: 'conditions',
                        validateMessage: 'Todas as regras devem possuir ao menos uma condição.',
                        valid: false,
                        ruleId: item.id,
                        costCenterId: 0,
                        conditionId: 0
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.conditions.length > 0)) {
                    item.conditions.map(itemCondition => {
                        if (!(itemCondition.field.length > 0))
                            validationsChecks.push({
                                name: 'conditions',
                                validateMessage: 'Todas as condições devem possuir um campo selecionado.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: 0,
                                conditionId: itemCondition.idCondition
                            });
                        if (!(itemCondition.logicOperator.length > 0))
                            validationsChecks.push({
                                name: 'logicOperator',
                                validateMessage: 'Todas as condições devem possuir um operador lógico selecionado.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: 0,
                                conditionId: itemCondition.idCondition
                            });
                        if (!(itemCondition.operation.length > 0))
                            validationsChecks.push({
                                name: 'operation',
                                validateMessage: 'Todas as condições devem possuir um operador de comparação selecionado.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: 0,
                                conditionId: itemCondition.idCondition
                            });

                        if (!(itemCondition.filter.length > 0))
                            validationsChecks.push({
                                name: 'filter',
                                validateMessage: 'Todas as condições devem possuir um valor para comparação.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: 0,
                                conditionId: itemCondition.idCondition
                            });
                    });

                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.contracts.length > 0)) {
                    validationsChecks.push({
                        name: 'contracts',
                        validateMessage: 'Todas as regras devem possuir ao menos um centro de custo cadastrado.',
                        valid: false,
                        ruleId: item.id,
                        costCenterId: 0,
                        conditionId: 0
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    item.contracts.map(itemContract => {
                        if (!(itemContract.idContract?.length > 0)) {
                            validationsChecks.push({
                                name: 'idContract',
                                validateMessage: 'Todos os centros de custo nas regras devem possuir um centro de custo selecionado.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: itemContract.idContractRule,
                                conditionId: 0
                            });
                        }
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    item.contracts.map(itemContract => {
                        if (!(itemContract.percentage > 0)) {
                            validationsChecks.push({
                                name: 'percentage',
                                validateMessage: 'Todos os centros de custo nas regras devem possuir um valor maior que zero na sua porcentagem.',
                                valid: false,
                                ruleId: item.id,
                                costCenterId: itemContract.idContractRule,
                                conditionId: 0
                            });
                        }
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    var totalPercentage = 0;
                    item.contracts.map(itemContract => {
                        totalPercentage += itemContract.percentage;
                    });
                    if ((totalPercentage != 100)) {
                        validationsChecks.push({
                            name: 'percentage',
                            validateMessage: 'O total da porcentagem cadastrada nos centros de custo para uma regra deve ser igual a 100 %.',
                            valid: false,
                            ruleId: item.id,
                            costCenterId: 0,
                            conditionId: 0
                        });
                    }

                }
            });
        }
        setValidations(validationsChecks);
        return validationsChecks.length == 0;

    }

    async function refreshAllocationContractParquet() {
        var month = moment().month() + 1;
        var year = moment().year();

        setAllocationResultLoading(true);
        try {
            var resulBaseAllocation = await finOpsMapManagerService.RefreshAllocationCostCenterParquet(month, year);
            setContractResultAllocation(resulBaseAllocation.centrosdeCusto);
        } catch (e) {
            console.log(e);
        }

        setAllocationResultLoading(false);
        setExecutionSuccess(!executionSuccess)
        setAllocationResultShow(true);
    }

    async function handleVersionView(version: any) {
        var offSet = new Date(version.lastModified).getTimezoneOffset();
        let value = version.name.split('/')[3].split('.')[0]
        let lastModified = Moment(new Date(version.lastModified)).subtract(offSet, 'minutes').format("DD/MM/YYYY, HH:mm")
        setVersion({ name: value, lastModified: lastModified });

        let result = await loadRuleGroup(value, true)

        if (result != undefined) {
            setViewVersion(true)
            setVersionTableRules(result)
        }
    }

    async function restoreRules(version: any) {
        try {
            let result = await loadRuleGroup(version.name.split('/')[3].split('.')[0], true);
            await saveRuleGroup(result);
            setVersionShow(false)
            retrieveData();
        } catch (e) {
            console.error(e);
        }
    }

    const columns = [
        {
            field: 'name', headerName: 'Versão', width: '45%',
            renderCell: (row: any) => {
                return row.name.split('/')[3].split('.')[0]
            }
        },
        {
            field: 'lastModified', headerName: 'Data / Hora', width: '15%', align: "right",
            renderCell: (row: any) => {
                var offSet = new Date(row.lastModified).getTimezoneOffset();
                return Moment(new Date(row.lastModified)).subtract(offSet, 'minutes').format("DD/MM/YYYY  HH:mm")
            }
        },
        {
            field: '', headerName: '', width: `${user.IsAdm ? '10%' : '15%'}`, showOnHover: true, align: "center",
            renderCell: (row: any) => {
                return <>
                    <LcIconLink
                        icon='ppi ppi-eye'
                        tooltip="Visualizar"
                        onClick={() => handleVersionView(row)} />
                    <LcIconLink
                        icon='ppi ppi-reply'
                        tooltip="Restaurar"
                        onClick={() => { setRestoreVisible(true); setRuleToRestore(row); }} />
                </>


            }
        },
        {
            field: 'metadata', headerName: 'Alterado por', width: '30%',
            renderCell: (row: any) => {
                return row.metadata && row.metadata.UpdatedByUserName ? row.metadata.UpdatedByUserName : 'Sem informação';
            }
        },

    ]

    const handleSideModal = (type: string, rule: FinOpsAllocationRule) => {
        switch (type) {
            case 'edit':
                let orderedRule = { ...rule };
                orderedRule.conditions.sort((a, b) => a.idCondition - b.idCondition);
                orderedRule.costCenters.sort((a, b) => a.idCostCenterRule - b.idCostCenterRule);
                setEditRule(orderedRule);

                setInfoRule(undefined)
                break
            case 'info':
                setInfoRule({ ...rule })
                setEditRule(undefined)
                break
        }
    }

    const handleRemoveConfirmation = (text?: string) => {
        return (<> Tem certeza que deseja excluir a regra <b>{text}</b>?</>)
    }

    const handleRestoreConfirmataion = (text?: string) => {
        return (<> Deseja restaurar as regras para a versão <b>{text}</b>?</>)
    }

    const getToolBarFunctions = () => {
        return <>
            <LcIconLink icon={<PiClockClockwiseLight/>} onClick={() => setVersionShow(!versionShow)} tooltip="Histórico de versões" disabled={allocationResultLoading} />
            <LcIconLink icon={<PiPlayLight/>} onClick={() => setConfirmExecuteVisible(true)} tooltip={allocationResultLoading ? "Aguarde a conclusão da execução" : "Executar imediatamente"} disabled={allocationResultLoading} />
            {
                tableRules.length == 1 &&
                <LcIconLink icon={<PiPlusLight/>} onClick={() => addRuleRecord(emptyRecord)} tooltip="Nova regra" />
            }
        </>;
    }

    return (
        <Layout
            pageTitle="Alocação de contratos"
            row={!(versionShow || allocationVersionLoading || allocationResultLoading) ? getToolBarFunctions() : undefined}
            loading={allocationVersionLoading}
        >

            <PPNotification
                message={'A execução das regras de alocação foi concluída'}
                severity="info"
                verticalPosition={'botton'}
                horizontalPosition={'center'}
                valueAppear={executionSuccess}
                closeNotify={() => { setExecutionSuccess(!executionSuccess) }}
            />

            <Confirmation
                title="Confirme"
                text={handleRemoveConfirmation(ruleToRemove?.description)}
                confirm={() => { setConfirmRemoveVisible(false); removeRecord(ruleToRemove) }}
                close={() => { setConfirmRemoveVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmRemoveVisible}
            />

            <Confirmation
                title="Confirme"
                text={`As regras são executadas automaticamente à noite. Deseja antecipar para agora? `}
                confirm={() => { setConfirmExecuteVisible(false); refreshAllocationContractParquet(); }}
                close={() => { setConfirmExecuteVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmExecuteVisible}
            />

            <Confirmation
                title="Confirme"
                text={handleRestoreConfirmataion(ruleToRemove?.description)}
                confirm={() => { setRestoreVisible(false); restoreRules(ruleToRestore); }}
                close={() => { setRestoreVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmRestoreVisible}
            />

            <PPModal
                visible={allocationResultShow}
                onClose={() => { setAllocationResultShow(false); }}
                title={'Resultado alocação de centros de custos'}
            >
                <PPGrid container spacing={2} justify="center" alignItems='center'>
                    {contractResultAllocation && contractResultAllocation.map((item, index) => {
                        return <PPGrid key={index} item sm={12} md={12} lg={12} justify="center" alignItems='center'>
                            {item.contract} : {item.total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </PPGrid>
                    })}
                </PPGrid>
            </PPModal>

            <PPModal
                loading={allocationHistoryVersionLoading}
                size='large'
                visible={versionShow}
                onClose={() => { setVersionShow(false); retrieveData() }}
                title="Histórico de versões"
            >
                {
                    viewVersion ?
                        <>
                            {
                                version &&
                                <div className="row">
                                    <LcIconLink
                                        icon={<PiArrowLeftLight/>}
                                        onClick={() => { setViewVersion(false); }}
                                        tooltip="Voltar"
                                        tooltipPosition='right' />
                                    <b>{version.name}</b> &nbsp;
                                    {`(${version.lastModified})`}
                                </div>
                            }

                            {
                                versionTableRules.sort((a, b) => a.order > b.order ? 1 : -1).map(
                                    (item, index) => {
                                        return (
                                            <div key={index} className='descriptionDiv default'>
                                                <div onClick={() => { handleSideModal('info', item) }} className='dleft'>
                                                    {item.order}. {item.description}
                                                </div>
                                                <div className='right'>
                                                    <Toolbar disableGutters>
                                                        <LcIconLink
                                                            tooltip='Detalhes'
                                                            tooltipPosition='right'
                                                            size={'medium'}
                                                            icon={<PiEyeLight/>}
                                                            onClick={() => { handleSideModal('info', item) }} />
                                                    </Toolbar>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </>
                        :
                        <>
                            <LcTable
                                data={allocationVersionsData}
                                columns={columns}
                                height="400px"
                            />
                        </>
                }
            </PPModal>

            {
                createRule &&
                <AllocationContracts
                    onCancelAction={() => { setCreateRule(undefined); }}
                    onSaveGroup={() => { retrieveData(); setCreateRule(undefined); }}
                    ruleGroup={tableRules}
                    rule={createRule}
                    originalRuleString={JSON.stringify(createRule)}
                    contracts={contracts}
                    readOnly={false}
                />

            }
            {
                editRule &&
                <AllocationContracts
                    onCancelAction={() => { setEditRule(undefined); }}
                    onSaveGroup={() => { retrieveData(); setEditRule(undefined); }}
                    ruleGroup={tableRules}
                    rule={editRule}
                    originalRuleString={JSON.stringify(editRule)}
                    contracts={contracts}
                    readOnly={false} />
            }

            {
                infoRule &&
                <AllocationContracts
                    readOnly={true}
                    onCancelAction={() => { setInfoRule(undefined) }}
                    ruleGroup={tableRules}
                    rule={infoRule}
                    contracts={contracts}
                />
            }

            <div style={{ overflowX: 'hidden', overflowY: 'auto', height: 'auto', maxHeight: 'calc(100vh-200px)' }} >
                {tableRules.sort((a, b) => a.order > b.order ? 1 : -1).map(
                    (item, index) => {
                        return (
                            <div key={index}>
                                {item && item.default == true ?
                                    <div className='descriptionDiv default'>
                                        <div onClick={() => { handleSideModal('info', item) }} className='dleft'>
                                            {item.order}.  {item.description}
                                        </div>
                                    </div>
                                    :
                                    <div className='descriptionDiv default'>
                                        <div onClick={() => { handleSideModal('info', item) }} className='dleft'>
                                            {item.order}.  {item.description}
                                        </div>
                                        <div className='right'>
                                            <Toolbar disableGutters>
                                                <LcIconLink size={'medium'} icon={<PiPlusLight/>} onClick={() => { addRuleRecord(item); }} tooltip="Adicionar regra" />
                                                <LcIconLink size={'medium'} icon={<PiCopyLight/>} onClick={() => { duplicateRuleRecord(item); }} tooltip="Duplicar" />
                                                <i className='ppi ppi-divider-vertical lci-5x text-grey' />
                                                <LcIconLink size={'medium'} icon={<PiPencilSimpleLight/>} onClick={() => { handleSideModal('edit', item) }} tooltip="Editar regra" />
                                                <LcIconLink size={'medium'} icon={<PiTrashLight/>} onClick={() => {
                                                    setConfirmRemoveVisible(true);
                                                    setRuleToRemove(item);
                                                }} tooltip="Excluir regra" />
                                                <i className='ppi ppi-divider-vertical lci-5x text-grey' />
                                                <LcIconLink disabled={Math.min.apply(null, tableRules.map(object => { return object.order; })) == item.order} size={'medium'} icon={<PiArrowUpLight/>} onClick={() => { upRankRule(item); }} tooltip="Mover acima" />
                                                <LcIconLink disabled={disableDownRankRule(item)} size={'medium'} icon={<PiArrowDownLight/>} onClick={() => { downRankRule(item); }} tooltip="Mover abaixo" tooltipPosition='right' />
                                            </Toolbar>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }
                )}
            </div>

        </Layout >
    );
};


export default FinOpsAllocationContract;