import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import LcLoading from '../../components/Generic/LcLoading';

import Layout from '../../components/Layout/Layout';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../store/reducers/filterReducer';

import AdminPoliciesService from './../../services/adminPoliciesService';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import LcTooltip from '../../components/Generic/LcTooltip';
import LcToggle from '../../components/Form/LcToggle';
import Confirmation from '../../components/Dialog/Confirmation';
import LCDashboard from '../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { Tooltip } from '@material-ui/core';
import LcIconLink from '../../components/Generic/LcIconLink';
import HeyHoNewRequest, { FormDataHeyho } from '../../components/Generic/HeyHoNewRequest';


const AdminPolicies: React.FC = (props) => {
    const service = new AdminPoliciesService(props);

    interface PolicyFilter {
        size: number,
        // term: string,
        column: string,
        direction: string,
        displayName: string,
        subscriptionName: string,
        effectType: string,
        status: number,
        compliance: any
    }

    const emptyPolicyFilter = {
        size: 20,
        // term: "",
        column: "",
        direction: "asc",
        displayName: "",
        subscriptionName: "",
        effectType: "",
        status: 0,
        compliance: undefined
    }

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const colors = ["#b1bfa5", "#E64646", "#A5BF3E"];
    const icons = ["lci lci-exclamation-circle", "ppi ppi-x", "ppi ppi-check"];

    const [politicasmessage, setPoliticasmessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [policiesList, setPoliciesList] = useState<any[]>([]);
    const [policiesListFiltered, setPoliciesListFiltered] = useState<any[]>([]);
    const [visiblePoliciesList, setVisiblePoliciesList] = useState<any[]>([]);
    const [filterPolice, setFilterPolice] = useState<PolicyFilter>(emptyPolicyFilter);
    const [toActivateList, setToActivateList] = useState<any[]>([]);
    const [modalVisibleHeyHoCall, setModalVisibleHeyHoCall] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormDataHeyho>({ title: '', description: '', gtmType: 0 });
    const [open, setOpen] = useState(false);
    const [operationtype, setOperationtype] = useState('');
    const { enqueueSnackbar } = useSnackbar();


    const [policiesLength, setPoliciesLength] = useState<number>(0);

    //Filter states    
    const [filter_displayName, setFilter_displayName] = useState<string>('');
    const [filter_subscriptionName, setFilter_subscriptionName] = useState<string>('');
    const [filter_status, setFilter_status] = useState<string>('');
    const [filter_compliance, setFilter_compliance] = useState<string>('');

    // Função para determinar o texto do tooltip com base no valor de compliance
    const getTooltipCompliance = (compliance) => {
        if (compliance === 0)
            return 'Inativa';

        if (compliance === 1)
            return 'Não conforme';

        if (compliance === 2)
            return 'Conforme';

        return 'Desconhecido';
    };

    // Função para determinar o texto do tooltip com base no valor de status
    const getTooltipStatus = (status) => {
        return status > 0 ? 'Ativa' : 'Inativa'
    }

    function loadData() {
        setPoliciesList([]);
        setPoliciesListFiltered([]);
        setIsLoading(true);

        // service.RetrieveList()
        service.RetrievePolicyList()
            .then(response => {
                const sortedData = response.data
                    .sort((a, b) => b.displayName.localeCompare(a.displayName))
                    .sort((a, b) => a.status - b.status)
                    .map(pol => {
                        pol['statusTooltip'] = getTooltipStatus(pol.status);
                        return pol;
                    })
                setPoliciesList(sortedData);
                setPoliciesListFiltered(sortedData);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        loadData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    function AsyncOperationAlert(operationresult: any) {
        if (operationresult && operationresult.length > 0 && operationresult.filter((x: any) => x.error == true).length == 0) {
            enqueueSnackbar(`Problema na operação de ${operationtype}.`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        }
        else {
            if (operationtype == "aplicação") {
                enqueueSnackbar(`As políticas foram aplicadas e estão sendo iniciadas.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            } else {
                enqueueSnackbar(`As políticas atribuídas foram deletas.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            }
        }
        setToActivateList([]);
    }

    const onChange = (change: any, data: any, row: any) => {
        let indexAction = 0;
        let policiesListZ: any[] = [];
        policiesListZ.push(row);
        let arrIDs: any[] = [];
        arrIDs.push(data.id);
        if (change.checked)
            indexAction = 1;
        functions.functionsList[indexAction].func(arrIDs, policiesListZ);
    }

    const getComplianceStatus = (row: any) => {
        if (row.compliance == 0)
            return '#bcbcbc';

        if (row.compliance == 1)
            return '#dc291e';

        if (row.compliance == 2)
            return '#3ebb3f';
    }

    const getStatus = (row: any) => {
        if (row.status == 0)
            return '#bcbcbc';

        if (row.status > 0)
            return '#3ebb3f';
    }

    const createTicket = (row?: any) => {
        setFormData({
            title: `Implementação de políticas no ambiente de nuvem`,
            description: `Olá, gostaria de implementar esta política no meu ambiente de nuvem: ${row.displayName} \r\nID da política: ${row.id} \r\nSubscrição: ${row.subscription} (${row.subscriptionName})`,
            gtmType: 17
        })
        setModalVisibleHeyHoCall(true);
    }

    const columns = [
        {
            field: 'displayName', headerName: 'Política', width: "50%",
            renderCell: (row: any) => <div className="columnCell">{row.displayName}</div>
        },
        { field: 'subscriptionName', headerName: 'Assinatura', width: "20%" },
        { field: 'effectType', headerName: 'Tipo', width: "10%" },
        {
            field: 'status', headerName: 'Status', width: "10%", sort: false,
            renderCell: (row: any) => <span style={{ color: getStatus(row) }}>{row.status > 0 ? 'Ativa' : 'Inativa'}</span>
        },
        {
            field: 'compliance', headerName: 'Conformidade', width: "10%", overflow: "visible", sort: false,
            renderCell: (row: any) => <span style={{ color: getComplianceStatus(row) }}>{getTooltipCompliance(row.compliance)}</span>
        },
        {
            field: 'implementation', width: "3%", overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return (
                    <LcIconLink
                        icon="ppi ppi-headset"
                        tooltip={row.status == 0 ? "Implemente a política" : "Política já implementada"}
                        disabled={row.status > 0}
                        tooltipPosition='right'
                        onClick={() => createTicket(row)}
                    />
                )
            }
        }
    ];

    let handleConfirmClose = (deleteConfirm: boolean, toActivateList: any) => {
        if (deleteConfirm) {
            enqueueSnackbar('A aplicação foi iniciada.', {
                variant: 'warning',
                preventDuplicate: true,
                persist: false,
            })
            service.Active(toActivateList as any[])
                .then((e) => {
                    e &&
                        AsyncOperationAlert(e);
                })
                .catch(console.warn)
                .then(() => {
                    loadData();
                });
        }

        setOpen(false);
    };

    let handleConfirmDeleteClose = (deleteConfirm: boolean, toActivateList: any) => {
        if (deleteConfirm) {
            enqueueSnackbar('A deleção da atribuição foi iniciada.', {
                variant: 'warning',
                preventDuplicate: true,
                persist: false,
            })
            service.DeletePolicyAssignment(toActivateList as any[])
                .then((e) => {
                    e && AsyncOperationAlert(e);
                })
                .catch(console.warn)
                .then(() => {
                    setIsLoading(false);
                    loadData();
                });
        }

        setOpen(false);
    };

    let functions = {
        functionsList: [
            { // 1. Delete
                title: 'Delete',
                icon: "lci lci-checkbox",
                func: (ids: any[], list: any[]) => {
                    setOperationtype('deleção');
                    let messagePOlicies = '';
                    setToActivateList([]);
                    ids && ids.map((itemID) => {
                        list && list.filter(x => x.id == itemID).map((pol) => {
                            if (pol.compliance != 0) {
                                if (messagePOlicies.length > 0) {
                                    messagePOlicies += ',';
                                }
                                messagePOlicies += pol.displayName;
                                toActivateList?.push(pol);
                                setToActivateList(toActivateList);
                            }
                        });
                    });
                    setPoliticasmessage(messagePOlicies);
                    if (messagePOlicies.length > 0) {
                        setOpen(true);
                    } else {
                        enqueueSnackbar("Nenhuma politica selecionada é possível remover a atribuição.", {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        })
                    }
                    // loadData();
                }
            },
            { // 2. Novo
                title: 'Novo',
                icon: "lci lci-checkbox-on",
                func: (ids: any[], list: any[]) => {
                    setOperationtype('aplicação');
                    let messagePolicies = '';
                    setToActivateList([]);
                    ids && ids.map((itemID) => {
                        list && list.filter(x => x.id == itemID).map((pol) => {
                            if (pol.compliance == 0) {
                                if (messagePolicies.length > 0) {
                                    messagePolicies += ',';
                                }
                                messagePolicies += pol.displayName;
                                toActivateList?.push(pol);
                                setToActivateList(toActivateList);
                            }
                        });
                    });
                    setPoliticasmessage(messagePolicies);
                    if (messagePolicies.length > 0) {
                        setOpen(true);
                    } else {
                        enqueueSnackbar("Nenhuma politica selecionada esta pendente de aplicação.", {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        })
                    }
                    //loadData();
                }
            }
        ]
    };

    // useEffect(() => {
    //     var { displayName, subscriptionName, status, compliance } = filterPolice;

    //     var notEmpty = displayName !== "" || subscriptionName !== "" || status > 0 || compliance > 0;

    //     var filtered = [...policiesList];
    //     if (notEmpty) {
    //         if (displayName !== "")
    //             filtered = filtered.filter((p: any) => p.displayName.toLowerCase().indexOf(displayName) > -1)
    //         if (subscriptionName !== "")
    //             filtered = filtered.filter((p: any) => p.subscriptionName.toLowerCase().indexOf(subscriptionName) > -1)
    //         if (!isNaN(status) && status > 0) {
    //             if (status === 1)
    //                 filtered = filtered.filter((p: any) => p.status > 0)
    //             if (status === 2)
    //                 filtered = filtered.filter((p: any) => p.status === 0)
    //         }
    //         if (!isNaN(compliance) && compliance > 0)
    //             filtered = filtered.filter((p: any) => p.compliance === compliance)
    //     }

    //     var sorted = filtered.sort((a: any, b: any) => {
    //         if (filterPolice.direction == 'asc') return (a[filterPolice.column] > b[filterPolice.column]) ? 1 : -1;
    //         else return (a[filterPolice.column] > b[filterPolice.column]) ? -1 : 1;
    //     });

    //     setPoliciesListFiltered(sorted);
    //     setVisiblePoliciesList(filtered.slice(0, filterPolice.size));

    // }, [filterPolice]);



    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        const actualSize = {
            visibleData: visiblePoliciesList.length, // Use to sort only data that has been loaded on screen
            allData: policiesListFiltered.length // Use to sort all data, will invoke "loadMore" many times
        }
        setFilterPolice({ ...filterPolice, column: sort.column, direction: sort.direction, size: size > 0 ? size : actualSize.visibleData })
    };

    const loadMore = () => {
        const newSize = visiblePoliciesList.length + filterPolice.size;
        setVisiblePoliciesList(policiesListFiltered.slice(0, newSize));
    };


    // Quando a lista filtrada ou o tamanho da página muda, atualize a lista visível
    useEffect(() => {
        setVisiblePoliciesList(policiesListFiltered.slice(0, filterPolice.size));
    }, [policiesListFiltered, filterPolice.size]);

    // Quando o filtro é aplicado, atualize a lista filtrada
    useEffect(() => {
        applyFilter();
    }, [filterPolice, policiesList]);



    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const _filterAdvanced = {
        fields: [
            { label: 'Política', name: 'displayName', type: 'text' },
            { label: 'Assinatura', name: 'subscriptionName', type: 'text' },
            { label: 'Tipo', name: 'effectType', type: 'text' },
            {
                label: 'Status', name: 'status', type: 'select', options: [
                    { value: '', label: 'Todos' },
                    { value: 1, label: 'Ativas' },
                    { value: 2, label: 'Inativas' }
                ]
            },
            {
                label: 'Conformidade', name: 'compliance', type: 'select',
                options: [
                    { value: '', label: 'Todos' },
                    { value: 0, label: 'Inativa' },
                    { value: 1, label: 'Não Conforme' },
                    { value: 2, label: 'Conforme' }
                ],
            }
        ],
        onChange: (_filter: any, size: number) => setFilterPolice({
            ...filterPolice,
            size: size,
            // term: _filter[0].value.toLowerCase(),
            displayName: _filter[0].value.toLowerCase(),
            subscriptionName: _filter[1].value.toLowerCase(),
            effectType: _filter[2].value.toLowerCase(),
            status: parseInt(_filter[3].value),
            compliance: parseInt(_filter[4].value)
        }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }


    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (

                    <LcLoading loading={isLoading} loadingType='Helix'>
                        <LcInfiniteTable
                            loading={isLoading}
                            columns={columns}
                            rows={visiblePoliciesList}
                            filter={_filterAdvanced}
                            size={policiesListFiltered.length}
                            disableFilterModal={true}
                            loadMore={loadMore}
                            onSortChange={onSortChange}
                            status={getStatus}
                            tooltipContentColumn='statusTooltip'
                            density={pref.data?.density || "medium"}
                            hidetopBar
                            noDataMessage='Não há dados para serem exibidos no momento.'
                        />
                    </LcLoading>
                )
            }

        }
    ]


    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={_filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (policiesList.length > 0) {
            let { displayName, subscriptionName, status, effectType, compliance } = filterPolice;

            let notEmpty = displayName !== "" || subscriptionName !== "" || effectType !== "" || status > 0 || compliance >= 0;
            let filtered = [...policiesList];

            if (notEmpty) {
                if (displayName !== "")
                    filtered = filtered.filter((p: any) => p.displayName.toLowerCase().includes(displayName));
                if (subscriptionName !== "")
                    filtered = filtered.filter((p: any) => p.subscriptionName.toLowerCase().includes(subscriptionName));
                if (!isNaN(status) && status > 0) {
                    if (status === 1)
                        filtered = filtered.filter((p: any) => p.status > 0);
                    if (status === 2)
                        filtered = filtered.filter((p: any) => p.status === 0);
                }
                if (effectType !== "")
                    filtered = filtered.filter((p: any) => p.effectType.toLowerCase().includes(effectType));
                if (!isNaN(compliance) && compliance >= 0) {
                    filtered = filtered.filter((p: any) => p.compliance === compliance);
                }
            }

            var sorted = filtered.sort((a: any, b: any) => {
                if (filterPolice.direction == 'asc') return (a[filterPolice.column] > b[filterPolice.column]) ? 1 : -1;
                else return (a[filterPolice.column] > b[filterPolice.column]) ? -1 : 1;
            });

            isFilterActive = filtered.length !== policiesList.length;
            setPoliciesListFiltered(sorted);
            setPoliciesLength(filtered.length);
            setVisiblePoliciesList(sorted.slice(0, filterPolice.size));
            dispatch(setFilterActive(filtered.length !== policiesList.length));
        }

    }, [filterPolice, policiesList, dispatch]);


    useEffect(() => {
        applyFilter();
    }, [applyFilter, filterPolice]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (updatedFilters) => {
        const displayNameFilter = updatedFilters.find(f => f.name === 'displayName')?.value || '';
        const subscriptionNameFilter = updatedFilters.find(f => f.name === 'subscriptionName')?.value || '';
        const effectTypeFilter = updatedFilters.find(f => f.name === 'effectType')?.value || '';
        const statusFilter = updatedFilters.find(f => f.name === 'status')?.value || '';
        const complianceFilter = updatedFilters.find(f => f.name === 'compliance')?.value;

        // Se complianceFilter é uma string vazia (que significa "Todos"), converta-a para undefined
        const complianceValue = complianceFilter === '' ? undefined : parseInt(complianceFilter, 10);

        setFilterPolice({
            ...filterPolice,
            displayName: displayNameFilter.toLowerCase(),
            subscriptionName: subscriptionNameFilter.toLowerCase(),
            effectType: effectTypeFilter.toLowerCase(),
            status: parseInt(statusFilter, 10),
            compliance: complianceValue
        });
    };

    return (
        <Layout pageTitle="Políticas"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: policiesListFiltered.length
            }}
        >
            {
                toActivateList && politicasmessage &&
                <Confirmation
                    display={open}
                    textBtnOk='Confirmar'
                    textBtnCancel='Cancelar'
                    title={`Confirmar ${operationtype}`}
                    text={`Você está solicitando a ${operationtype} de políticas (${politicasmessage}) em seu ambiente. Uma vez confirmada sua execução não poderá ser desfeita. Lembre-se que poderá haver impactos relevantes e até mesmo eventual indisponibilidade dependendo da solicitação realizada. Em caso de dúvidas abra um chamado em requisições para que possamos fazer isto por você`}
                    confirm={(rconfirm: any) => {
                        // 
                        if (operationtype == "aplicação")
                            handleConfirmClose(rconfirm, toActivateList);
                        else if (operationtype == "deleção")
                            handleConfirmDeleteClose(rconfirm, toActivateList);
                    }}
                    close={() => setOpen(false)}
                />
            }

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
            <HeyHoNewRequest enebleIA formData={formData} openModal={modalVisibleHeyHoCall} onClose={() => setModalVisibleHeyHoCall(false)} needAtachmente />

        </Layout>
    );
}

export default AdminPolicies;