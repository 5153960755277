import api, { APIClient } from "../api";
import { Host, HostSummaryResponse, Incident, Item, PivotLwData } from "./LiveWatchnsightModels";

class LiveWatchInsightService extends APIClient {
    constructor(props: any) {
        super(props);
    }

    async getHosts(): Promise<Host[]> {
        console.log('getHosts')
        const response = await api.post<Host[]>(`${process.env.REACT_APP_BFFENDPOINT}/api/` + 'LiveWatchInsights/group/hosts');
        console.log('getHosts')
        console.log(response.data)
        return response.data;
    }

    async getPivot(groupId: number): Promise<PivotLwData[]> {
        console.log('getPivots')
        const response = await api.post<PivotLwData[]>(`${process.env.REACT_APP_BFFENDPOINT}/api/` + 'LiveWatchInsights/hosts/pivot');
        console.log('getPivot')
        console.log(response.data)
        return response.data;//.filter(x => x.hostgroup == groupId.toString());
    }

    async getHostItems(groupId: number, hostId: number): Promise<Item[]> {
        console.log('getHostItems')
        console.log({ group_id: groupId, host_id: hostId })
        const response = await api.post<Item[]>(`${process.env.REACT_APP_BFFENDPOINT}/api/` + 'LiveWatchInsights/group/hosts/items', { host_id: hostId });
        console.log('getHostItems')
        console.log(response.data)
        return response.data;
    }

    async getIncidents(timePeriodDays: number, severity: number[]): Promise<Record<string, Incident[]>> {
        console.log('getIncidents')
        const response = await api.post<Record<string, Incident[]>>(`${process.env.REACT_APP_BFFENDPOINT}/api/` + 'LiveWatchInsights/group/incidents/subgroup');
        console.log('getIncidents')
        console.log(response.data)
        return response.data;
    }

    async getHostSummary(): Promise<HostSummaryResponse> {
        console.log('getHostSummary')
        const response = await api.post<HostSummaryResponse>(`${process.env.REACT_APP_BFFENDPOINT}/api/` + 'LiveWatchInsights/hosts/resume');
        console.log('getHostSummary')
        console.log(response.data)
        return response.data;
    }
}

export default new LiveWatchInsightService({});

