import api, { APIClient } from "../api";


export interface AlertBaselineLimitObj {
    Id: number
    PercentageAlert: number | string | null
    PercentageAlertOld: number | null
    SipContractId: number  | null
    UpdatedAt: Date
    LastAlertSend: Date | null
    NumeroSip: number | null
    Subject: string
    Body: string
    InternalContactsEmail: string
    isProcessor : boolean
    isManualSend: boolean,
    isCostAlert: boolean
}


export default class AlertBaselineLimitsService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    

    async GetListAlertLimits() {

        const url = `${this.endpoint}/api/AlertBaselineLimits/GetAllAlerts`;

        return api({
            method: 'get',
            url,
        })
    }

    async SaveRecord(recordObj) {
        const url = `${this.endpoint}/api/AlertBaselineLimits/SaveAlert`;

        const data = JSON.stringify(recordObj)

        return api({
            method: 'post',
            url,
            headers: {
                'Content-Type': 'application/json-patch+json'
            },
            data: data
        })
    }

    async Delete(id) {
        const url = `${this.endpoint}/api/AlertBaselineLimits/Delete/${id}`;

        return api({
            method: 'delete',
            url,
        })
    }

    async Update(recordObj) {
        const url = `${this.endpoint}/api/AlertBaselineLimits/UpdateAlert`;

        const data = JSON.stringify(recordObj)

        return api({
            method: 'put',
            url,
            headers: {
                'Content-Type': 'application/json-patch+json'
            },
            data: data
        })
    }
}

