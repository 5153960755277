import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import LcLoading from '../../components/Generic/LcLoading';
import { useSelector } from 'react-redux';
import { RootState, Store } from '../../store';
import { UserState } from '../../store/reducers/userReducer';

import { FilterObjectSend, IFilter, IOptions, ResourceSipContractService } from '../../services/resourceSipContractProcessor/resourceSipContractService';
import SideModal from '../../components/Layout/SideModal/SideModal';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import ReactDOM from 'react-dom';
  
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import LCPivotTable from '../../components/Data/pivotTable';
import LcCheckBox from '../../components/Form/LcCheckBox';
import { Button, IconButton } from '@material-ui/core';
import { PortalUsersService } from '../../services/portalUsers/portalUsers';

const ResourceSipContract: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const [IsLoading, setIsLoading] = useState<boolean>(false);
    const [pivotTableFilter, setPivotTableFilter] = useState<any>();
    const [IsLoadingContractRedirect, setIsLoadingContractRedirect] = useState<boolean>(false);
    const [sendResource, setSendResource] = useState<object[]>();
    const [data, setData] = useState<any[]>([]);

    const [dataVisible, setDataVisible] = useState<any[]>([]);
    const [selectedContract, setSelectedContract] = useState<any>({});
    const [serverFilter, setServerFilter] =
        useState<{ clientId: any, isLinked: any, search: any; }>
            ({ clientId: user.ClientGroupSelected, isLinked: false, search: '' });
    const [showLinked, setShowLinked] = useState<boolean>(false);
    const [checkedAddContract, setCheckedAddContract] = useState<boolean>(true);
    const [checkedSetContract, setCheckedSetContract] = useState<boolean>(false);
    const [hasFilter, setHasFilter] = useState<boolean>(false);
    const [contractsSideModal, setContractsSideModal] = useState<any[]>([]);
    const [isVisibleSideModalContractRedirect, setIsVisibleSideModalContractRedirect] = useState<boolean>(false);
    const [selectedResource, setSelectedResource] = useState<any[]>([]);
    const [month, setMonth] = useState<string>(((new Date()).getMonth()).toString());
    const [year, setYear] = useState<string>((new Date()).getFullYear().toString());
    const GetData = () => {
        
        setIsLoading(true);
        setPivotTableFilter(null);
        const service = new ResourceSipContractService(props);
        service.GetCloudResourceView(year, month)
            .then(response => {
                
                setData(response.data);
            })
            .catch(console.warn)
            .then(() => {
                
                setIsLoading(false);
            });
    };
    useEffect(() => {
        GetData();
    }, []);

    useEffect(() => {

        GetData();
    }, [user.ClientGroupSelected, user.refreshFlag, month]);

    const redirectToContract = (contract) => {
        


        setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect);
        
        const service = new ResourceSipContractService(props);
        service.RedirectResourceToContract(pivotTableFilter, contract.id, checkedSetContract, checkedAddContract, year, month)
            .then(response => {
                
                enqueueSnackbar(`Os recursos serão processados em segundo plano`, {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                GetData();
            }).catch(error => {

                enqueueSnackbar(`Erro ao mudar contrato do recurso do contrato`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                });
                console.log(error);
            });
    };

    const functions = [
        {
            icon: 'lci lci-folder-add',
            tooltip: 'Vincular',
            disabled: selectedResource.length < 1 || showLinked,
            func: () => {
                if (!pivotTableFilter) {
                    enqueueSnackbar(`Nenhum filtro foi aplicado ao recursos.`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    return;
                }

                const service = new ResourceSipContractService(props);
                setSendResource(selectedResource);
                
                setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect);

                setIsLoadingContractRedirect(true);
                service.GetContracts()
                    .then(response => {
                        setContractsSideModal(response);
                    })
                    .catch(error => {
                        enqueueSnackbar(`Erro ao buscar contrato`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                        });


                        console.log(error);
                    }).then(() => {
                        setIsLoadingContractRedirect(false);
                    });
            }
        }
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    return (
        <Layout pageTitle="Recursos" periodSelection={periodSelection}>
            <LcLoading loading={IsLoading}>
                <div className="topbar row">
                    <div className="functions row" style={{ display: 'flex' }}>
                        <div style={{ height: 'calc(5vh)', overflow: 'hidden' }}>
                            <div className="item">
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    {
                                        functions &&
                                        <div style={{ display: 'flex ', alignItems: 'left', justifyContent: 'flex-start' }}>

                                            {
                                                functions.map((func, index) => {
                                                    return <LcIconLink
                                                        tooltipPosition={index == 0 ? 'left' : undefined}
                                                        disabled={false}
                                                        key={index}
                                                        icon={func.icon}
                                                        tooltip={func.tooltip}
                                                        onClick={() => { func.func(); }} />;
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LCPivotTable
                    onSetFilter={(s) => { setPivotTableFilter(s); }}
                    data={data}
                />
            </LcLoading>
            <SideModal width="35rem" header='Contratos' visible={isVisibleSideModalContractRedirect} onClose={() => { setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect); setSendResource(undefined); }}>
                <LcLoading loading={IsLoadingContractRedirect} label="Carregando contratos, aguarde">
                    <div className="lc-checklist" style={{ height: 'calc(10vh)' }}>
                        <div className="list scrollable-v" style={{ overflow: 'hidden' }}>
                            <div className="item">
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <LcCheckBox checked={checkedAddContract} onChange={(d) => { setCheckedSetContract(!d.checked); setCheckedAddContract(d.checked); }} title={"Adicionar ao contrato"} name={"Compartilhar contrato"} />
                                    </div>
                                    <div style={{ width: '50%', display: 'flex ', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <LcCheckBox checked={checkedSetContract} onChange={(d) => { setCheckedSetContract(d.checked); setCheckedAddContract(!d.checked); }} title={"Migrar para o contrato"} name={"Migrar contrato"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lc-checklist" >
                        <div className="list scrollable-v" style={{ height: 'calc(65vh)', overflow: 'auto' }}>
                            {
                                contractsSideModal.map((row, index) => {
                                    return (
                                        <div key={index} className="item">
                                            <LcCheckBox checked={row == selectedContract} onChange={(d) => { setSelectedContract(row); }} title={""} name={""} />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                <span>{row.contractName}</span>
                                                <div style={{ width: '170px', display: 'flex ', alignItems: 'center', justifyContent: 'flex-end' }}> Contrato: {row.contractId}

                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div  >
                        <div style={{ height: 'calc(10vh)', overflow: 'hidden', marginTop: '10px' }}>
                            <div className="item">
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <div style={{ width: '50%', display: 'flex ', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Button className="lc-button bg-primary mr-5" size="large" onClick={() => { redirectToContract(selectedContract); }}>
                                            Salvar
                                        </Button>
                                    </div>
                                    <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <Button className="lc-button bg-primary mr-5" size="large" onClick={() => { setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect); setSendResource(undefined); }}>
                                            Fechar
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </LcLoading>
            </SideModal>
        </Layout >
    );
};

export default ResourceSipContract;