import React, { useEffect, useState } from "react";
import styles from "./SaaSUsersDetail.module.css";
import { SaaSLicencasViewModel, SaaSLicencesModel, SaasUserService } from "../../../../services/SaasUserService";
import { Grid, LinearProgress, Typography, Icon } from "@material-ui/core";
import { InputField, SelectField } from "../../../../components/Form/Input";
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { UserState } from "../../../../store/reducers/userReducer";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import LcInfiniteTable from "../../../../components/Data/LcInfiniteTable";
import Confirmation from "../../../../components/Dialog/Confirmation";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LcTable from "../../../../components/Data/LcTable";
import { SaaSGroupMembersService } from "../../../../services/saasGroupMembersService";
import PPDropDown from "../../../../components/PPDropDown";
import { LiaExchangeAltSolid } from "react-icons/lia";
import LcTooltip from "../../../../components/Generic/LcTooltip";
import PPCheckBox from "../../../../components/Form/PPCheckBox";
import LcLoading from "../../../../components/Generic/LcLoading";

const UserDetail: React.FC<any> = (props: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUserProp = props.user;
    const user = useSelector<RootState, UserState>((state) => state.user);
    const service = new SaasUserService(props);
    const [counter, setCounter] = useState<number>(0);
    const [rolesTable, setRolesTable] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [filteredRolesTable, setFilteredRolesTable] = useState<any[]>([]);
    //==================================================== 

    const [assignedSubscription, setAssignedSubscription] = useState<any[]>([]);
    const [notAssignedSubscription, setNotAssignedSubscription] = useState<any[]>([]);
    //====================================================

    const [currentUser, setCurrentUser] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUser, setLoadingUser] = useState<boolean>(false);
    const [originRoles, setOriginRoles] = useState<any[]>([]);

    async function GetUserMemberOf() {
        setGroups([]);
        setLoading(true);
        try {
            var response = await service.GetUserMemberOf(currentUserProp.id);
            setGroups(response.data);
        } catch (e) {
            console.log(e);

            setGroups([]);
        }
    }

    function GetUser() {
        setLoadingUser(true);
        service.GetUserDetail(currentUserProp.id)
            .then(response => {
                setCurrentUser(response.data);
                setLoadingUser(false);
                if (props.typeForm == "licenses") {
                    return;
                }
                if (props.typeForm == "memberOf") {
                    return;
                }
            }).catch(error => {
                console.log(error);
                setLoadingUser(false);
                return
            });
    }
    async function executeDetails() {
        setLoading(true);
        await Promise.all([GetUser(), GetSkus(), GetUserMemberOf()]);
        setLoading(false);
    }

    useEffect(() => {
        executeDetails();
    }, [currentUserProp, counter]);

    async function saveChange(ids: any[]) {
        setAssignedSubscription([]);
        setNotAssignedSubscription([]);
        setLoading(true);

        let role = rolesTable
        let rolesEdited: any[] = []
        ids.forEach(id => {
            let row = role.find(x => x.id == id)
            rolesEdited.push({ ...row, checked: !row.checked })
            role = role.filter(x => x.id != id).concat({ ...row, checked: !row.checked })
        });

        let newRoleObj: SaaSLicencasViewModel = {
            userId: props.guid,
            nomeUsuario: currentUserProp.mail,
            licencas: role.map((r: any) => {
                return {
                    skuId: r.sku,
                    description: r.description,
                    isChecked: r.checked,
                    isLicensed: r.checked
                } as SaaSLicencesModel
            }),
            currentLocation: country.acronyms,
        };
        try {

            let result = await service.SendLicence(newRoleObj);

            rolesEdited.forEach(element => {
                setTimeout(() => {
                    enqueueSnackbar(`Software ${element.description} ${element.checked ? 'aplicada' : 'retirada'} `, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    })
                }, 100);
            });

        } catch (error: any) {
            setTimeout(() => {
                enqueueSnackbar(`${error.response.data}`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })

            }, 100);

        }
        setTimeout(() => {
            setCounter((counter + 1))
        }, 15000);
        // executeDetails();
    }

    const status = (row: any) => {
        return row.prepaid > 0 ? 'success' : 'danger';
    }

    const rolesColumns = [
        { field: 'description', headerName: 'Descrição', width: "65%" },
        { field: 'consumed', headerName: "Em uso", width: "15%" },
        { field: 'prepaid', headerName: "Disponível", width: "20%" },
        {
            field: '',
            width: "24px",
            renderCell: (row: any) => {

                if (row.toChange) {
                    return (
                        <LcIconLink
                            icon={`lci lci-checkbox-on`}
                            color='var(--color-secondary-lime)'
                            onClick={() => { changeRow(row.id, row.checked) }}
                            disabled={row.units <= 0 && row.checked}
                            size={"small"}
                        />
                    )
                } else {

                    return (
                        <LcIconLink
                            icon={`lci lci-checkbox${row.checked ? '-on text-lime' : ' text-info'}`}
                            onClick={() => { changeRow(row.id, row.checked) }}
                            disabled={row.units <= 0 && !row.checked}
                            size={"small"}
                        />
                    )
                }
            },
        }
    ];

    const groupsColumns = [
        { field: 'displayName', headerName: 'Descrição', width: "75%" },
        {
            field: 'mailEnabled', headerName: 'Tipo', width: "23%", renderCell: (row: any) => {
                var Type = getGroupTypeName(row);
                return (<div>{Type}</div>);
            }
        },
    ];

    async function GetSkus() {

        if (props.typeForm != "licenses") {
            return;
        }

        setAssignedSubscription([])
        setNotAssignedSubscription([])

        enqueueSnackbar(`Preparando softwares para aplicação`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
        try {
            var result = await service
                .GetSubcribeSKU(currentUserProp.id);
            let data = result.data;

            let mappedData = data.map((o: any, i: string) => {
                return {
                    id: i,
                    sku: o.skuId,
                    description: o.description.length > 48 ? `${o.description.slice(0, 47)}...` : o.description,
                    checked: (o.isChecked),
                    units: o.prepaid - o.consumed,
                    consumed: o.consumed,
                    prepaid: o.prepaid,
                    toChange: false,
                    tooltipInfo: o.prepaid > 0 ? `${o.prepaid - o.consumed} subscrições disponíveis` : `Subscrições esgotadas!`
                }
            })

            mappedData = mappedData.sort((a, b) => {
                return a.description > b.description ? -1 : 1
            })

            setRolesTable(mappedData)
            setFilteredRolesTable(mappedData);

            let assigned = mappedData.filter(x => x.checked)
            setAssignedSubscription(assigned)

            let notAssigned = mappedData.filter(x => x.checked == false && !(x.description.toLowerCase().includes("free") || x.description.toLowerCase().includes("trial")))
            setNotAssignedSubscription(notAssigned)

            let modeledRole: any[] = [];
            data.map((row: any) => {
                modeledRole.push({
                    SkuId: row.skuId,
                    Description: row.description,
                    IsChecked: row.isChecked,
                    ChekedOld: row.isChecked,
                    IsLicensed: row.isLicensed,
                    Consumed: row.consumed,
                    Prepaid: row.prepaid,
                });
            });

            let list: any[] = [];
            modeledRole.map((r: any) => {
                list.push({
                    value: `${r.Description}: ${r.Prepaid - r.Consumed} un`,
                    selected: r.IsChecked.includes("checked=checked")
                        ? true
                        : false,
                });
            });
            setOriginRoles(modeledRole);

        } catch (e) {

        }
    }

    const [country, setCountry] = useState<{
        acronyms: string;
        countryName: string;
    }>({
        acronyms: "BR",
        countryName: "Brazil",
    });

    const options = service.options.map((item: any) => ({
        value: item.value,
        label: item.label,
    }));

    const handleChangePPDropDown = (selectedOption: any) => {
        setCountry({
            ...country,
            acronyms: selectedOption.value,
            countryName: selectedOption.label,
        });
    };

    //PASSWORD'S FUNCTIONS
    const [changePassword, setChangePassword] = useState<string>("");
    const [confirmPassword, setConfimPassword] = useState<string>("");
    const [forceLoginPassword, setForceLoginPassword] = useState<boolean>(false);

    const updatePassword = () => {
        if (user.ClientGroupSelected) {

            if (changePassword == "" || confirmPassword == "") {

                enqueueSnackbar(`Ambos os campos devem ser preenchidos corretamente`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            if (changePassword != confirmPassword) {

                enqueueSnackbar(`Senhas não correspondem`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            var hasUppercase = /[A-Z]/;
            if (!hasUppercase.test(changePassword)) {

                enqueueSnackbar(`A senha deve conter ao menos uma letra maiúscula`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            var hasNumber = /\d/;
            if (!hasNumber.test(changePassword)) {

                enqueueSnackbar(`A senha deve conter números`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            if (!format.test(changePassword)) {

                enqueueSnackbar(`A senha deve ter caracteres especiais`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            if (changePassword.length < 8) {
                enqueueSnackbar(`A senha deve conter ao menos 8 caracteres`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                return;
            }

            let saaSUserPasswordModel = {
                "id": props.guid,
                "password": confirmPassword,
                "forceChangePasswordNextSignIn": forceLoginPassword,
                "forceChangePasswordNextSignInWithMfa": forceLoginPassword,
                "oDataType": ""
            };

            service
                .ChangePassword(saaSUserPasswordModel)
                .then((result) => {
                    setChangePassword("");
                    setConfimPassword("");
                    setForceLoginPassword(false);
                    enqueueSnackbar(`Senha atualizada com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    })

                    enqueueSnackbar(`Por padrão do software o usuário receberá uma notificação`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    })
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao alterar senha.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    })

                });
        }
    };

    const onChangePassword = (field: any) => {
        setChangePassword(field.value);
    };
    const onChangeConfirmPassword = (field: any) => {
        setConfimPassword(field.value);
    };
    const onChangeForceLogin = (field: any) => {
        setForceLoginPassword(field.target.checked);
    };

    const functionsRowGroups = [
        {
            label: 'Remover',
            func: (row: any) => {
                var Type = getGroupType(row);

                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <Confirmation
                                display={true}
                                title={`Remover ${currentUserProp.mail} do grupo:`}
                                text={`${row.displayName}`}
                                confirm={async () => {
                                    onClose();
                                    enqueueSnackbar(`Grupo esta sendo removido... `, { variant: 'info', preventDuplicate: true, persist: false, anchorOrigin: { vertical: 'bottom', horizontal: 'center', }, });
                                    var saas = new SaaSGroupMembersService(props);
                                    try {
                                        await saas.RemoveUserGroupLink(currentUserProp.id, row.id, Type);

                                        enqueueSnackbar(`Grupo removido com êxito.`, { variant: 'success', preventDuplicate: true, persist: false, anchorOrigin: { vertical: 'bottom', horizontal: 'center', }, });
                                    } catch (e) {
                                        enqueueSnackbar(`Não foi possivel remover o grupo.`, { variant: 'error', preventDuplicate: true, persist: false, anchorOrigin: { vertical: 'bottom', horizontal: 'center', }, });
                                    }
                                    let newcounter = counter + 1;
                                    setCounter(newcounter);
                                }}
                                close={() => { onClose(); }}
                            ></Confirmation>);
                    }
                });
            }
        }
    ];

    const AddToArray = (array: any[], element: any) => {
        let _array: any[] = array.concat(element)

        var descriptionSorted = _array.sort(
            (element1: any, element2: any) => {
                return element1.description.localeCompare(element2.description, 'pt-br')
            }
        )

        var onchangeSorted = descriptionSorted.sort((element1, element2) => {
            if (element1.toChange < element2.toChange) return 1;
            if (element1.toChange > element2.toChange) return -1;
            return 0;
        })

        return onchangeSorted
    }


    const changeRow = (rowId: any, assigned: boolean) => {
        if (assigned) {
            let row = assignedSubscription.find(x => x.id == rowId)
            row = (row) ? row : notAssignedSubscription.find(x => x.id == rowId)

            let assigned = assignedSubscription.filter(x => x.id != rowId)

            let r = !row.toChange ?
                AddToArray(notAssignedSubscription, { ...row, toChange: !row.toChange })
                :
                AddToArray(assignedSubscription, { ...row, toChange: !row.toChange })
                ;

            if (!row.toChange) {
                setAssignedSubscription(assigned)

                setNotAssignedSubscription(r)
            } else {

                setAssignedSubscription(r)

                setNotAssignedSubscription(notAssignedSubscription.filter(x => x.id != rowId))
            }

        } else {
            let row = notAssignedSubscription.find(x => x.id == rowId)
            row = (row) ? row : assignedSubscription.find(x => x.id == rowId)

            let notAssigned = notAssignedSubscription.filter(x => x.id != rowId)

            let r = !row.toChange ?
                AddToArray(assignedSubscription, { ...row, toChange: !row.toChange })
                :
                AddToArray(notAssignedSubscription, { ...row, toChange: !row.toChange })
                ;

            if (!row.toChange) {
                setNotAssignedSubscription(notAssigned)

                setAssignedSubscription(r)
            } else {
                setNotAssignedSubscription(r)

                setAssignedSubscription(assignedSubscription.filter(x => x.id != rowId))
            }
        }
    }

    const cancel = () => {
        if (props.cancelFunction) {
            props.cancelFunction()
        }
    }

    const SaveChangesRowsLicenses = () => {
        let assigned = assignedSubscription.filter(x => x.toChange == true)
        let notAssigned = notAssignedSubscription.filter(x => x.toChange == true)
        // if (!assigned && !notAssigned) return;

        assigned.length > 0 && saveChange(assigned.map((e) => { return e.id }).concat(notAssigned.map((e) => { return e.id })))
        notAssigned.length > 0 && saveChange(assigned.map((e) => { return e.id }).concat(notAssigned.map((e) => { return e.id })))
    }

    return (<>

        {loading && <Grid container justifyContent="center" alignItems="center">
            <LinearProgress />
        </Grid>
        }
        {
            <div style={{ height: 'calc(100% - 40px)', maxHeight: '800px' }}>
                {
                    props.typeForm == "information" && (
                        <>
                            {(
                                <LcLoading loading={loadingUser}>
                                    <div>
                                        <InputField disable={true}
                                            value={currentUser.givenName}
                                            label="Nome"
                                            name="givenName"
                                            placeHolder="Nome"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.surname}
                                            label="Sobrenome"
                                            name="surname"
                                            placeHolder="Sobrenome"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.displayName}
                                            label="Nome de Exibição"
                                            name="displayName"
                                            placeHolder="Nome de exibição"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.mail}
                                            label="E-mail"
                                            name="alias"
                                            placeHolder="nome@dominio"
                                            icon="lci lci-at-symbol"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.department}
                                            label="Departamento"
                                            name="deparamento"
                                            placeHolder="Departamento"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.jobTitle}
                                            label="Cargo"
                                            name="cargo"
                                            placeHolder="Cargo"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.state}
                                            label="Estado"
                                            name="estado"
                                            placeHolder="Estado"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.streetAddress}
                                            label="Endereço"
                                            name="endereco"
                                            placeHolder="Endereço"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.postalCode}
                                            label="CEP"
                                            name="cep"
                                            placeHolder="CEP"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.city}
                                            label="Cidade"
                                            name="cidade"
                                            placeHolder="Cidade"
                                            type="text"
                                            updateParentConst
                                        />
                                        <InputField disable={true}
                                            value={currentUser.country}
                                            label="País"
                                            name="pais"
                                            placeHolder="País"
                                            type="text"
                                            updateParentConst
                                        />
                                    </div>
                                </LcLoading>
                            )}
                        </>
                    )}

                {props.typeForm == "memberOf" &&
                    <>
                        {groups && groups.length > 0 && <>
                            <LcTable
                                functionsRow={functionsRowGroups}
                                columns={groupsColumns}
                                data={groups}
                                filterFields={[{ label: 'Pesquisa', name: 'displayName', type: 'text' }]}
                                height={groups.length < 9 ? '300px' : ''}
                            />
                        </>}
                    </>}

                {props.typeForm == "password" && (
                    <>
                        <label style={{ fontSize: '12px', marginRight: '5px' }}>Digite a nova senha</label>
                        <LcTooltip
                            trigger="hover"
                            position="left"
                            content="A senha deve conter, no mínimo, oito caracteres, uma letra maiúscula, um caractere especial e um número."
                        >
                            <i className='lci lci-information-circle' />
                        </LcTooltip>
                        <InputField
                            onChange={onChangePassword}
                            value={changePassword}
                            icon="lci lci-lock-open"
                            name="password"
                            type="password"
                        ></InputField>
                        <InputField
                            onChange={onChangeConfirmPassword}
                            value={confirmPassword}
                            icon="lci lci-lock-open"
                            name="confirm_password"
                            type="password"
                            label="Repetir a nova senha"
                        ></InputField>
                        <span className={styles.passwordRequirementText}></span>
                        <div style={{ padding: '10px 0 10px 0' }}>
                            <PPCheckBox
                                title="Trocar a senha no próximo login"
                                onChange={onChangeForceLogin}
                                checked={forceLoginPassword}
                            />
                        </div>

                        <Grid  >
                            <Grid  >
                                <button className="lc-button bg-info mr-6" type="button" onClick={updatePassword}>Salvar</button>
                            </Grid>
                        </Grid>
                    </>
                )}
                {props.typeForm == "licenses" && (
                    <div style={{ minHeight: '100%' }}>
                        {
                            (
                                <LcLoading loading={loading}>
                                    {
                                        rolesTable &&
                                        <div style={{ height: '80%', width: '100%' }}>
                                            <div className="row between w-100p">
                                                <div className="w-100p pl-5 pb-5 ml-5 mb-5">
                                                    <div className={styles.licensePanelLabel}>Atribuir subscrição</div>
                                                    <div className="card-frame bordered">

                                                        <LcInfiniteTable
                                                            loading={loading}
                                                            columns={rolesColumns}
                                                            rows={notAssignedSubscription}
                                                            size={notAssignedSubscription.length}
                                                            status={status}
                                                            fontSize='small'
                                                            height="250px"
                                                            tooltipContentColumn='tooltipInfo'
                                                        />
                                                    </div>
                                                </div>
                                                <LiaExchangeAltSolid size="36" color="#999" />
                                                <div className="w-100p pr-5 pb-5 mr-5 mb-5">
                                                    <div className={styles.licensePanelLabel}>Subscrições atribuídas</div>
                                                    <div className="card-frame bordered">

                                                        <LcInfiniteTable
                                                            loading={loading}
                                                            columns={rolesColumns}
                                                            rows={assignedSubscription}
                                                            size={assignedSubscription.length}
                                                            status={status}
                                                            fontSize='small'
                                                            height="250px"
                                                            tooltipContentColumn='tooltipInfo'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row between mb-7" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ width: '400px' }}>
                                                    <PPDropDown
                                                        options={service.options.map((item: any) => ({
                                                            value: item.value,
                                                            label: item.label,
                                                        }))}
                                                        defaultValue={{ label: 'Brazil', value: 'BR' }}
                                                        value={service.options.find(option => option.value == country.acronyms)}
                                                        onChange={handleChangePPDropDown}
                                                        name="acronyms"
                                                        title="Local do usuário que receberá a atribuição de subscrições"
                                                        menuPlacement="top"
                                                    //inputId="outlined-age-native-simple"
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    }
                                    {
                                        rolesTable &&
                                        <div style={{ display: 'block' }}>
                                            <button className="lc-button bg-info mr-6" type="button" onClick={SaveChangesRowsLicenses} disabled={loading} style={{ float: 'left' }}>Salvar</button>
                                            <button className="lc-button bg-gray" type="button" onClick={cancel} style={{ float: 'right' }}>Cancelar</button>
                                        </div>
                                    }

                                </LcLoading>
                            )}
                    </div>
                )}
            </div>}
    </>);
};

export default UserDetail;

function getGroupTypeName(row: any) {
    var Type = "Office 365";
    if (row.groupTypes != null && row.groupTypes.includes("Unified") && row.mailEnabled && !row.securityEnabled)
        Type = "Office 365";

    if ((row.groupTypes == null || row.groupTypes.length == 0) && !row.mailEnabled && row.securityEnabled)
        Type = "Segurança";

    if ((row.groupTypes == null || row.groupTypes.length == 0) && row.mailEnabled && row.securityEnabled)
        Type = "E-mail ativo de segurança";

    if ((row.groupTypes == null || row.groupTypes.length == 0) && row.mailEnabled && !row.securityEnabled)
        Type = "Grupo de distribuição";
    return Type;
}

function getGroupType(row: any) {
    var Type = 1;
    if (row.groupTypes != null && row.groupTypes.includes("Unified") && row.mailEnabled && !row.securityEnabled) {
        Type = 1;
    }
    else if ((row.groupTypes == null || row.groupTypes.length == 0) && !row.mailEnabled && row.securityEnabled) {
        Type = 2;
    }
    else if ((row.groupTypes == null || row.groupTypes.length == 0) && row.mailEnabled && row.securityEnabled) {
        Type = 3;
    }
    else if ((row.groupTypes == null || row.groupTypes.length == 0) && row.mailEnabled && !row.securityEnabled) {
        Type = 4;
    }
    return Type;
}
