// Define os tipos de acoes
// Define os tipos de acoes
export const SET_FILTER_ACTIVE = 'SET_FILTER_ACTIVE';

// Cria a interface para o tipo de estado do seu filtro
export interface FilterState {
    isFilterActive: boolean;
}

// Define o estado inicial
const initialState: FilterState = {
    isFilterActive: false,
};

// acoes
export interface SetFilterActiveAction {
    type: typeof SET_FILTER_ACTIVE;
    payload: boolean;
}

// Action creators
export const setFilterActive = (isActive: boolean): SetFilterActiveAction => ({
    type: SET_FILTER_ACTIVE,
    payload: isActive,
});

// Reducer
export const filterReducer = (
    state = initialState,
    action: SetFilterActiveAction
): FilterState => {
    switch (action.type) {
        case SET_FILTER_ACTIVE:
            return {
                ...state,
                isFilterActive: action.payload,
            };
        default:
            return state;
    }
};
