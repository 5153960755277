export class finops {
    solution() {
        return 'finops'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-finops'
    }

    logotipo() {
        return require('./assets/logotipos/logo_finops.svg').default
    }
    titulo() {
        return <>Transforme a Maneira de <b>gerenciar gastos em nuvem</b></>
    }

    chamada() {
        return <><b>LiveCloud FinOps</b> é mais do que apenas uma ferramenta de gerenciamento de custos. É uma prática que une tecnologia e finanças, permitindo que a organização tome <b>decisões baseadas em dados</b> e otimize gastos com serviços em nuvem.</>
    }

    image() {
        return require('./assets/imgs/imagem_finops.png').default
    }

    detalhes() {
        return <>
            <h2>Gostaria de ter uma visão clara e detalhada de onde o dinheiro está indo e como otimizar custos?</h2>
            <p><b>Apresentamos LiveCloud FinOps, a solução que a organização precisa para assumir o controle dos custos em nuvem.</b></p>
            <h3>Por que escolher LiveCloud FinOps?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_finops.png').default} />
                </div>
                
                <ul>
                    <li><b>Visibilidade total:</b> Uma visão gráfica e unificada dos Centros de Custo, Recursos e Serviços, além da evolução diária e mensal de consumo.</li>
                    <li><b>Decisões baseadas em dados:</b> Com relatórios atualizados e acessados com frequência, suas decisões serão sempre orientadas pelo valor comercial da nuvem.</li>
                    <li><b>Responsabilidade financeira:</b> Crie uma cultura de responsabilidade financeira em toda a organização, com todos se apropriando do uso da nuvem.</li>
                    <li><b>Otimização de custos:</b> Aproveite o modelo de custo variável da nuvem para otimizar custos e aumentar o controle dos orçamentos previstos.</li>
                    <li><b>Gestão multicloud:</b> Gerencie recursos de nuvem de forma centralizada, independentemente do provedor de nuvem que a organização esteja usando.</li>
                </ul>
            </div>
            <div className="box">Com LiveCloud FinOps, a organização terá <b>maior visibilidade e transparência no consumo de serviços de nuvem</b>, potencializando investimentos e promovendo uma cultura de responsabilidade financeira para todos.</div>
            <h3>Não perca mais tempo</h3>
            <p>Não deixe que os custos de nuvem não gerenciados atrapalhem o crescimento do negócio. Com o LiveCloud FinOps, a organização pode assumir o controle de seus gastos em nuvem e otimizar investimentos.</p>
            <p>Entre em contato conosco hoje mesmo para descobrir como o LiveCloud FinOps pode transformar a gestão de custos em nuvem do negócio.</p>            
        </>
    }
}
export default finops;