import moment from "moment";

import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { FinOpsDashBoard, GraphItem, MonthBudget } from "./finOpsService";
import { formatDecimal, formatNumber, getSymbolByCurrency } from "../currency";

import { defaultTooltipConfig } from '../../components/Generic/eChartsConfig';


const screenWidth = window.innerWidth;
let fontSize = screenWidth <= 1400 ? 10 : 12;


export class FinOpsChartBuilder {
    constructor(props: any) { }

    formatPercentLC = (value: number | string | null) => {
        if (value == null || value == 'NaN' || isNaN(Number(value))) return '0,00%';

        const numValue = Number(value);
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numValue);
        return formattedAmount;
    }

    formatNumberLC = (value: number | string | null) => {
        if (value == null || value == 'NaN' || isNaN(Number(value))) return '0,00';

        const numValue = Number(value);
        // billions and above
        if (numValue >= 1.0e+9) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+9));
            return formattedAmount + "B";
        }
        // millions
        else if (numValue >= 1.0e+6) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+6));
            return formattedAmount + "M";
        }
        // thousands
        else if (numValue >= 1.0e+3) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+3));
            return formattedAmount + "k";
        }
        else {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue));
            return formattedAmount;
        }
    }

    getChartColor = (data: any[], index: number) => {
        return data[index];
    };

    cutValueV = (value: number, kpi: any) => {
        let baseValue = Math.max(...kpi.costCenterConsumption.map(c => c.consumption));

        var divideBy = 1;
        if (baseValue <= 10000) {
            divideBy = (1)
        } else if (baseValue <= 1000000) {
            divideBy = (1000)
        } else if (baseValue <= 100000000) {
            divideBy = (1000000)
        }

        //M�todo para reduzir valores para n�o quebar o layout
        return (value / divideBy);
    }

    //Budgets centros de custos
    getConsumptionCostCenterChart = (data: any[], colored: any[], kpi: any, showbackgroud?: boolean) => {

        var part = data.slice(0, 5);

        const maxValue = Math.max(...part.map(item => item.percent));

        return (
            {
                grid: {
                    top: '2%',
                    left: '3%',
                    right: '0%',
                    bottom: '0%',
                    height: '100%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                    show: false,
                    axisTick: { show: false },
                    boundaryGap: [0, 1],
                    min: 0,
                    //max: math.max.apply(data.map(item => {
                    //    return item.percent;
                    //})),
                    max: 100,
                    scale: true
                },
                yAxis: {
                    type: 'category',
                    boundaryGap: true,
                    inverse: true,
                    axisTick: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        width: 188,
                        show: true,
                        overflow: 'truncate',
                        ellipsis: '...',
                        margin: 0,
                        fontSize: 12,
                        position: 'left',
                        fontFamily: 'Ubuntu',
                        padding: [0, 4, 0, 0]
                    },
                    data: part.map((item: any) => {
                        return (item.name);
                    })
                },
                tooltip: {
                    trigger: 'axis',
                    ...defaultTooltipConfig,
                    formatter: (params) => {
                        // Custom tooltip formatter to display labels and values
                        const index = params[0].dataIndex;
                        return `${this.formatNumberLC(part[index].totalCost)} do budget: ${this.formatNumberLC(part[index].budget)}`;
                    }
                },
                series: [
                    {
                        // Série de fundo para representar o valor máximo de 100%
                        type: 'bar',
                        barWidth: 10,
                        barGap: '-100%', // Isso faz com que a barra de fundo fique atrás da barra principal
                        data: part.map((item) => {
                            return (
                                {
                                    value: 100,
                                    costCenterId: item.id,
                                    name: item.name
                                });

                        }), // Sempre 100% para representar o fundo
                        itemStyle: {
                            color: 'rgba(220, 220, 220, 0.8)' // Cor de fundo
                        }
                    },
                    {
                        type: 'bar',
                        showBackground: showbackgroud,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        barWidth: 10,
                        label: {
                            show: true,
                            position: 'start',
                            fontSize: 10,
                            fontFamily: 'Ubuntu',
                            color: '#2B304F',
                            margin: [0, 0, 0, 0],
                            offset: [0, -15],
                            formatter: (params: any) => {
                                const percentual = params.value / 100; // Convertendo para formato decimal
                                const formattedPercentual = percentual.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                return formattedPercentual;
                            }
                        },
                        data: part.map((item: any, index: number) => {
                            let newValue = (item.totalCost / item.budget) * 100; // Calcula a porcentagem
                            return (
                                {
                                    value: newValue,
                                    itemStyle: { color: newValue > 100 ? '#DC291E' : newValue > 75 ? '#F6DE00' : '#3EBB3F' },//cor de variaveis de css de status
                                    costCenterId: item.id,
                                    name: item.name
                                });
                        }),
                    },
                ]
            }
        );
    };

    //Top consumos
    getChartColorTreeMap = (colored: any[], index: number) => {
        const palette = [            
            '#8C40E3',  // substituindo var(--color--primary--purple)
            '#2E1956',  // substituindo var(--color--primary--purple-dark)
            '#471FCC',  // substituindo var(--color--primary--blue)
            
            '#AB19E7',  // substituindo var(--color--primary--magenta)
            '#966CF3',  // substituindo var(--color--primary--purple-light)
            '#DA43F2',  // substituindo var(--color--primary--pink)
            '#9FA3FF',  // substituindo var(--color--secondary--purple-bright)
            '#00DBFF',  // substituindo var(--color--secondary--cyan)
            //'#C9FF46',  // substituindo var(--color--secondary--lime)
            '#255A4B',  // substituindo var(--color--secondary--green-dark)
            '#34C38F',  // substituindo var(--color--secondary--green-light)
            '#000000',  // substituindo var(--color--secondary--black)
            //'#255A4B',  // substituindo var(--color--secondary--mint)
            
        ];
    
        // Retorna a cor da paleta com base no índice. 
        // Se o índice for maior que o tamanho da paleta, ele vai ciclar.
        return palette[index % palette.length];
    };
    
    getConsumptionChart = (data: any[], colored: any[], kpi: any, showbackgroud?: boolean, rankTypeOption?: any) => {
        return (
            {
                legend: {
                    show: false  // Explicitly hide the legend
                },
                tooltip: {
                    show: true,
                    trigger: 'item',
                    ...defaultTooltipConfig,
                    formatter: (params: any) => {
                        return `${params.name}\n<b>${this.formatNumberLC(params.value)}</b>`;
                    }
                },
                series: [
                    {
                        breadcrumb: {
                            left: 'center',
                            show: true,
                            itemStyle: {
                                color: 'var(--background-lightest)', // Cor de fundo dos itens do breadcrumb
                                textStyle: {
                                    color: 'var(--text-default)', // Cor do texto
                                    fontFamily: 'Ubuntu',
                                    fontSize: 10,
                                }
                            },
                            emphasis: {
                                itemStyle: {
                                    color: 'var(--background-light)', // Cor de fundo dos itens do breadcrumb ao passar o mouse
                                    textStyle: {
                                        color: 'var(--text-default)', // Cor do texto
                                        fontFamily: 'Ubuntu',
                                        fontSize: 10,
                                    }
                                }
                            }
                        },
                        name: rankTypeOption,
                        type: 'treemap',
                        right: 0,
                        left: 0,
                        top: 0,
                        bottom: 20,
                        data: data && data.length > 0 ? data.map((item: any, index: number) => {
                            return {
                                name: item.label,
                                value: item.consumption,
                                itemStyle: { color: this.getChartColorTreeMap(colored, index) }
                            };
                        }) : [],
                        leafDepth: 2,
                        levels: [
                            {
                                itemStyle: {
                                    borderColor: '#FFF',
                                    borderWidth: 1,
                                    gapWidth: 1,
                                    cursor: 'zoom-in',
                                },
                                upperLabel: {
                                    show: true,
                                    height: 12,
                                    fontFamily: 'Ubuntu',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: 'var(--text-default)',
                                    formatter: (params: any) => {
                                        return `${this.formatNumberLC(params.value)}`;
                                    }
                                },
                            },
                            {
                                itemStyle: {
                                    borderColor: '#fff',
                                    borderWidth: 1,
                                    fontSize: 10,
                                    fontFamily: 'Ubuntu',
                                    gapWidth: 0,
                                    cursor: 'zoom-in',
                                },
                                emphasis: {
                                    itemStyle: {
                                        borderColor: '#fff',
                                        cursor: 'zoom-in',
                                    }
                                }
                            }
                        ],
                        label: {
                            fontSize: 10,
                            fontFamily: 'Ubuntu',
                            color: '#FFF',
                            show: true,
                            formatter: (params: any) => {
                                return `${params.name}\n${this.formatNumberLC(params.value)}`;
                            }
                        }
                    }
                ]
            }
        );
    };
    

    getBudgetChart = (currentMonth: boolean, valueMonth: number, budget: number, monthLabel: string, tooltipContent: Function) => {
        // debugger;
        //   var valueMonth = (currentMonth ? kpi?.totalConsumption : kpi?.lastMonthTotalConsumption);
        // valueMonth = valueMonth ? valueMonth : 0;
        //  var baseline = kpi?.baseline;
        //  baseline = baseline ? baseline : 0;

        return (
            {
                tooltip: {
                    trigger: 'item',
                    ...defaultTooltipConfig,
                    position: function (point, params, dom, rect, size) {
                        return [point[0] + -90, point[1] + 15];
                    },
                    formatter: function (params) {
                        return params.name == 'inactive' ? '' : // ou pode retornar 'undefined' ou 'null' dependendo do comportamento desejado.
                            tooltipContent(valueMonth, budget, monthLabel, true); // Caso contrário, retorne o formato padrão para a tooltip
                        // `<div>
                        //     <b>${moment(monthLabel, 'DD/MM/YYYY').format('MMMM')}</b>
                        //     <br/>Consumidos <b>${budget ? getSymbolByCurrency("BRL") : 'R$'} ${formatDecimal(valueMonth)}</b>
                        //     <br/>de um total de <b> ${budget ? getSymbolByCurrency("BRL") : 'R$'} ${budget ? (budget.toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 })) : 0.00}</b>
                        // </div>`;
                    }
                },
                series: [
                    {
                        type: 'gauge',
                        z: 2,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: currentMonth ? '#9FA3FF' : '#255A4B'
                        },
                        progress: {
                            show: true,
                            width: 25,
                        },
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: (budget ? budget * 1 : valueMonth),
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 25,
                                color: [[1, '#E2E6F3']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false
                        },
                        title: {
                            offsetCenter: [0, '-20%'],
                            fontSize: 20
                        },
                        anchor: {
                            show: false
                        },
                        detail: {
                            fontSize: 16,
                            fontFamily: 'Ubuntu',
                            offsetCenter: [0, '-5%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                var budgetDivisor = budget > 0 ? budget : 1;
                                var cost = (value * 100) / budgetDivisor;
                                // cost = cost ? cost : 0;
                                // Arredonda o valor
                                // var roundedCost = Math.round(cost);
                                return budget > 0 ? (cost.toString().split(".")[0]) + '%' : '';
                            },
                            color: '#2B304F'
                        },
                        data: [{ value: valueMonth }]
                    },
                    {
                        type: 'gauge',
                        z: 3,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: '#255A4B'
                        },
                        progress: {
                            show: true,
                            width: 25,
                        },
                        startAngle: (((budget * 180) / (valueMonth * 1))) > 0 ? (180 - ((budget * 180) / (valueMonth * 1))) : 180,
                        endAngle: (((budget * 180) / (valueMonth * 1))) > 0 ? (180 - ((budget * 180) / (valueMonth * 1))) : 180,
                        min: 0,
                        max: valueMonth,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                color: [[0, '#E2E6F3']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            distance: -2,
                            length: 29,
                            lineStyle: {
                                width: 4,
                                color: valueMonth > budget ? "black" : 'transparent'
                            }
                        },
                    },
                    // {
                    //     type: 'gauge',
                    //     z: 3,
                    //     stack: 'graph',
                    //     pointer: {
                    //         show: false
                    //     },
                    //     itemStyle: {
                    //         color: '#F3C944'
                    //     },
                    //     progress: {
                    //         show: true,
                    //         width: 25,
                    //     },
                    //     startAngle: (((valueMonth * 180) / (budget * 1))) < 180 ? (180 - ((valueMonth * 180) / (budget * 1))) : 0,
                    //     endAngle:  (((valueMonth * 180) / (budget * 1))) < 180 ? (180 - ((valueMonth * 180) / (budget * 1))) : 0,
                    //     min: 0,
                    //     max: budget,
                    //     splitNumber: 1,
                    //     axisLine: {
                    //         lineStyle: {
                    //             width: 0,
                    //             color: [[1, '#E2E6F3']]
                    //         }
                    //     },
                    //     axisTick: {
                    //         show: false
                    //     },
                    //     axisLabel: {
                    //         show: false,
                    //     },
                    //     splitLine: {
                    //         distance: -2,
                    //         length: 29,
                    //         lineStyle: {
                    //             width: 4,
                    //             color: "white"
                    //         }
                    //     },
                    // },
                    {
                        type: 'pie',
                        radius: '75%',  // ajusta para o tamanho desejado
                        center: ['50%', '50%'],  // mantém no centro
                        startAngle: 180,
                        endAngle: 0,
                        avoidLabelOverlap: false,
                        data: [
                            { value: 0.5, name: 'active' },
                            { value: 0.5, name: 'inactive' }
                        ],
                        itemStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            cursor: (params) => {
                                if (params.name == 'inactive') {
                                    return 'default';
                                }
                                return 'pointer';
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                color: 'transparent',
                                borderColor: 'transparent',
                                cursor: 'default'
                            }
                        },
                        label: { show: false },
                        labelLine: { show: false },
                    },
                ]
            }
        );
    };

    getRecordWithMaxMonthAfterDate = (baselineHistory: MonthBudget[], inputDate: string): MonthBudget[] => {
        if (!baselineHistory || baselineHistory.length === 0) {
            return [] as unknown as MonthBudget[];
        }

        const inputDateTime = new Date(inputDate).getTime();
        let maxDateRecord: MonthBudget | null = null;
        let maxDate = new Date(0); // Initialize with a very old date

        for (const record of baselineHistory) {
            const recordDate = new Date(record.month + '-01');
            if (recordDate.getTime() <= inputDateTime && recordDate > maxDate) {
                maxDate = recordDate;
                maxDateRecord = record;
            }
        }
        if (maxDateRecord == null) {
            return [] as unknown as MonthBudget[];
        }
        return [maxDateRecord] as MonthBudget[];
    };

    getBudgetLastPeriodsChart = (data: any, kpi: any, date: any, budgetCurrentLast: number) => {
        const formattedDate = moment(date, 'MMM/YYYY').format('MMM/YYYY');
        var valueMonth = data;
        valueMonth = valueMonth ? valueMonth : 0;
        var baseline = budgetCurrentLast;
        baseline = baseline ? baseline : 0;

        return (
            {
                tooltip: {
                    trigger: 'item',
                    ...defaultTooltipConfig,
                    padding: [5, 10],
                    position: function (point, params, dom, rect, size) {
                        return [point[0] + -90, point[1] + 15];
                    },
                    formatter: function (params) {
                        if (params.name == 'inactive') {
                            return '';  // ou pode retornar 'undefined' ou 'null' dependendo do comportamento desejado.
                        }
                        // Caso contrário, retorne o formato padrão para a tooltip
                        return `<div>
             <b>${formattedDate}</b>
            <br/>Consumidos <b>${kpi ? getSymbolByCurrency("BRL") : 'R$'} ${formatDecimal(valueMonth)}</b>
            <br/>de um total de <b> ${kpi ? getSymbolByCurrency("BRL") : 'R$'} ${baseline ? (baseline.toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 })) : 0.00}</b>
        </div>`;
                    }
                },
                series: [
                    {
                        type: 'gauge',
                        z: 2,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: '#9FA3FF'
                        },
                        progress: {
                            show: true,
                            width: 20,
                        },
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: baseline,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 20,
                                color: [[1, '#E2E6F3']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false
                        },
                        title: {
                            offsetCenter: [0, '-20%'],
                            fontSize: 14
                        },
                        anchor: {
                            show: false
                        },
                        detail: {
                            fontSize: 13,
                            fontFamily: 'Ubuntu',
                            offsetCenter: [0, '-5%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                var baselineV = baseline ? baseline : value;
                                var cost = (value * 100) / baselineV;
                                cost = cost ? cost : 0;
                                // Arredonda o valor
                                // var roundedCost = Math.round(cost);
                                return baseline > 0 ? (cost.toString().split(".")[0]) + '%' : value.toString().split(".")[0] + '%';
                                // return roundedCost.toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 0, minimumFractionDigits: 0 }) + '%';
                            },
                            color: '#2E1956'
                        },
                        data: [{ value: data }]
                    },
                    {
                        type: 'gauge',
                        z: 3,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: '#255A4B'
                        },
                        progress: {
                            show: true,
                            width: 20,
                        },
                        startAngle: (((baseline * 180) / (valueMonth * 1))) > 0 ? (180 - ((baseline * 180) / (valueMonth * 1))) : 180,
                        endAngle: (((baseline * 180) / (valueMonth * 1))) > 0 ? (180 - ((baseline * 180) / (valueMonth * 1))) : 180,
                        min: 0,
                        max: baseline,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                color: [[0, 'transparent']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            distance: -2,
                            length: 29,
                            lineStyle: {
                                width: 4,
                                color: valueMonth > baseline ? "black" : 'transparent'
                            }
                        },
                    },
                    // {
                    //     type: 'gauge',
                    //     z: 3,
                    //     stack: 'graph',
                    //     pointer: {
                    //         show: false
                    //     },
                    //     itemStyle: {
                    //         color: '#F3C944'
                    //     },
                    //     progress: {
                    //         show: true,
                    //         width: 25,
                    //     },
                    //     startAngle: (180 - ((data * 180) / (baseline * 1.2))),
                    //     endAngle: (180 - ((data * 180) / (baseline * 1.2))),
                    //     min: 0,
                    //     max: baseline,
                    //     splitNumber: 1,
                    //     axisLine: {
                    //         lineStyle: {
                    //             width: 0,
                    //             color: [[1, '#E2E6F3']]
                    //         }
                    //     },
                    //     axisTick: {
                    //         show: false
                    //     },
                    //     axisLabel: {
                    //         show: false,
                    //     },
                    // splitLine: {
                    //     distance: -2,
                    //     length: 29,
                    //     lineStyle: {
                    //         width: 4,
                    //         color: "white"
                    //     }
                    // },
                    // },
                    {
                        type: 'pie',
                        radius: '75%',  // ajusta para o tamanho desejado
                        center: ['50%', '50%'],  // mantém no centro
                        startAngle: 180,
                        endAngle: 0,
                        avoidLabelOverlap: false,
                        data: [
                            { value: 0.5, name: 'active' },
                            { value: 0.5, name: 'inactive' }
                        ],
                        itemStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            cursor: (params) => {
                                if (params.name == 'inactive') {
                                    return 'default';
                                }
                                return 'pointer';
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                color: 'transparent',
                                borderColor: 'transparent',
                                cursor: 'default'
                            }
                        },
                        label: { show: false },
                        labelLine: { show: false },
                    },
                ]
            }
        );
    };

    getAreaConsumptionChart = (currentMonthData: GraphItem[],
        lastMonthData: GraphItem[],
        fullMonth: boolean,
        kpi: FinOpsDashBoard) => {
        try {

            let corAnterior;
            let corAtual;
            let xAxisData;
            let lastMonthConsumptionX = 0;
            var seriesDataCurrent;
            var seriesDataForecast;
            ({ corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData }
                = this.getProjectionArea(currentMonthData, lastMonthData, fullMonth));

            if (!(currentMonthData.length > 0)) {
                return {};
            }

            var nowMoment = moment(currentMonthData[0].label, 'DD/MM/YYYY');

            const yearV = nowMoment.year();
            const monthV = nowMoment.month() + 1;

            let previous = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').add(-1, "M").format('MMMM') + (kpi.lastMonthDailyConsumption.length > 0 ? '' : ' (sem dados)');
            let actual = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').format('MMMM') + (kpi.dailyConsumption.length > 0 ? '' : ' (sem dados)')

            previous = previous && previous.length > 0 ? previous.charAt(0).toUpperCase() + previous.slice(1) : "";
            actual = actual && actual.length > 0 ? actual.charAt(0).toUpperCase() + actual.slice(1) : "";

            let baseValue = 0;
            if (kpi.totalConsumption > 1000000)
                baseValue = Math.max(...kpi.costCenterConsumption.filter(c => c.consumption != null)
                    .map(c => c.consumption != null ? c.consumption : 0))
            else
                baseValue = kpi.totalConsumption;

            //const screenWidth = window.innerWidth;
            //let fontSize = screenWidth <= 1400 ? 12 : 14; //* Ajuste os tamanhos conforme necessário            
            var formatNumberLCFunction = this.formatNumberLC;
            var resultado: EChartsOption = ({
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    order: 'seriesDesc',
                    backgroundColor: '#f1f5fb',
                    borderWidth: 0,
                    textStyle: {
                        fontSize: 11,
                        fontFamily: 'Ubuntu',
                        color: 'var(--text-default)',
                    },
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: 'var(--color-primary-purple-dark)',
                            textStyle: {
                                fontSize: 11,
                                fontFamily: 'Ubuntu'                            
                            },
                        },                        
                    },
                    valueFormatter: (value) => { return this.formatNumberLC(value) },
                    formatter: function (params) {
                        let output = `<div style="display: flex; justify-content: space-between;"><span><b>${params[0].name}</b></span><span>R$ </span></div>`;
                        for (let param of params) {
                            if (param.data > 0) {
                                output += `<div style="display: flex; justify-content: space-between;gap:10px;"><span style="max-width:200px; text-overflow: ellipsis; overflow: hidden; text-wrap: nowrap;">${param.marker} ${param.seriesName}</span> <span><b>${formatDecimal(param.data)}</b></span></div>`;
                            }
                        }
                        return output;
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: 'var(--text-light)',
                    },
                    inactiveColor: '#ededed',
                    inactiveBorderColor: '#ededed',
                    data: [{ name: previous, itemStyle: { color: corAnterior }, lineStyle: { width: 0 } }, { name: actual, itemStyle: { color: corAtual }, lineStyle: { width: 0 } }]
                },
                xAxis: {
                    axisLabel: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: 'var(--text-light)',
                        interval: fullMonth ? 7 : 5,
                        showMaxLabel: true
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    axisTick: { show: false },
                    type: 'category',
                    axisLine: {
                        symbol: "none",
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    nameLocation: 'middle',
                    boundaryGap: false,

                    data: xAxisData,
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    splitNumber: 3,
                    axisLabel: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: 'var(--text-light)',
                        formatter: function (value: number) {
                            return formatNumberLCFunction(value);
                        }
                    }
                },
                series: [
                    {
                        colorBy: 'series',
                        name: previous,
                        showSymbol: false,
                        smooth: true,
                        blendMode: 'darken',
                        z: 1,
                        data: lastMonthData.map((item: GraphItem) => {
                            lastMonthConsumptionX = lastMonthConsumptionX + Number(item.consumption);
                            return lastMonthConsumptionX;
                        }),
                        type: 'line',
                        lineStyle: {
                            width: 0
                        },
                        areaStyle: {
                            color: corAnterior,
                            opacity: 1
                        },
                        itemStyle: {
                            color: corAnterior
                        }
                    },
                    {
                        colorBy: 'series',
                        name: actual,
                        z: 3,
                        smooth: true,
                        blendMode: 'darken',
                        showSymbol: false,
                        //data: currentMonthData.map((item: GraphItem, index: number) => {
                        //    currentMonthConsumption = currentMonthConsumption + Number(item.consumption);
                        //    return (moment().unix() < moment(currentMonthData[index].label, 'DD/MM/YYYY').unix() ? '0' : this.cutValue(currentMonthConsumption, kpi));
                        //}),
                        data: seriesDataCurrent,
                        type: 'line',
                        lineStyle: {
                            width: 0
                        },
                        areaStyle: {
                            color: corAtual,
                            opacity: 0.9
                        },
                        itemStyle: {
                            color: corAtual
                        }
                    },
                    {
                        // forecast data series
                        name: `${actual} (Projeção)`,
                        z: 2,
                        smooth: false,

                        blendMode: 'darken',
                        showSymbol: false,
                        data: seriesDataForecast.map(value => (isNaN(Number(value)) || value == 'NaN') ? null : value),
                        type: 'line',
                        lineStyle: {
                            type: 'dashed',
                            width: 2
                        },
                        areaStyle: {
                            color: corAtual,
                            opacity: 0.4
                        },
                        itemStyle: {
                            color: corAtual
                        },
                    },
                ]
            });

            return resultado;
        } catch (e) {
            return ([]);
        }
    };

    getProjectoMax(currentMonthData: GraphItem[], lastMonthData: GraphItem[], fullMonth: boolean, lastPeriodoApplied: boolean = false) {
        //debugger;
        let corAnterior;
        let corAtual;
        let xAxisData;
        var seriesDataCurrent;
        var seriesDataForecast;
        ({ corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData } = this.getProjectionArea(currentMonthData, lastMonthData, fullMonth));
        if (lastPeriodoApplied && currentMonthData.length > 0) {

            var currentMonthConsumptionC = 0;
            var maxIndexSeriesDataCurrentC = 0;
            var lastMonthDataCurrentV = currentMonthData.map((item: GraphItem, index: number) => {
                currentMonthConsumptionC = currentMonthConsumptionC + Number(item.consumption);
                maxIndexSeriesDataCurrentC++;
                return currentMonthConsumptionC;
            });

            return currentMonthConsumptionC / currentMonthData.length;
        }

        if (seriesDataForecast && seriesDataForecast.length >= 1) {
            return seriesDataForecast[seriesDataForecast.length - 1];
        } else {
            var currentMonthConsumptionC = 0;
            var maxIndexSeriesDataCurrentC = 0;
            var lastMonthDataCurrentV = currentMonthData.map((item: GraphItem, index: number) => {
                currentMonthConsumptionC = currentMonthConsumptionC + Number(item.consumption);
                maxIndexSeriesDataCurrentC++;
                return currentMonthConsumptionC;
            });
            return currentMonthConsumptionC;
        }
    }

    getProjectionArea(currentMonthData: GraphItem[], lastMonthData: GraphItem[], fullMonth: boolean) {
        let corAnterior = "#255A4B";
        let corAtual = "#9FA3FF";
        let xAxisData = ['Nenhum'];
        var seriesDataForecast: (number | null | string)[] = []
        let seriesDataCurrent = [0.00];
        seriesDataForecast = [0.00];

        if (!currentMonthData)
            return { corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData };


        if (!(currentMonthData.length > 0))
            return { corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData };


        let currentMonthConsumption = 0;
        currentMonthData = currentMonthData ? currentMonthData.filter(item => item.dateOnly != "0001-01-01T00:00:00") : [];

        let label = currentMonthData[currentMonthData.length - 1].label;

        let currentDateMoment = moment(label, 'DD/MM/YYYY');

        let currentDate = currentDateMoment.toDate();
        let currentMonth = new Date();
        let currentDay = currentDate.getDate();

        if (!(currentDate.getMonth() == currentMonth.getMonth()
            && currentDate.getFullYear() == currentMonth.getFullYear())) {
            xAxisData =
                currentMonthData.length >= lastMonthData.length ?
                    currentMonthData.map((item: any) => {
                        return (moment(item.label, 'DD/MM/YYYY').format('DD'));
                    })
                    :
                    lastMonthData.map((item: any) => {
                        return (moment(item.label, 'DD/MM/YYYY').format('DD'));
                    });
            var maxIndexSeriesDataCurrentC = 0;
            var currentMonthConsumptionC = 0;
            seriesDataCurrent = currentMonthData.map((item: GraphItem, index: number) => {
                currentMonthConsumptionC = currentMonthConsumptionC + Number(item.consumption);
                maxIndexSeriesDataCurrentC++;
                return currentMonthConsumptionC;
            });

            return { corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData };
        } else {
            var shiftDays = 1;
            let daysInCurrentMonth = moment().daysInMonth();
            let actualData = currentMonthData.filter((item: GraphItem) => moment(item.label, 'DD/MM/YYYY').date() <= (currentDay - shiftDays));
            let forecastData: GraphItem[] = [];


            if (forecastData.length == 0 && actualData.length > 0 && lastMonthData.length > 0) {
                for (let day = 1; day <= (currentDay - shiftDays); day++) {
                    let label = moment().date(day).format('DD/MM/YYYY');
                    forecastData.push({
                        label: label,
                        consumption: null,
                        dateOnly: null,
                        extraLabel: "",
                        cost: 0
                    });
                }

                let allData = actualData.concat(lastMonthData.slice(actualData.length, lastMonthData.length));
                for (let day = currentDay; day <= daysInCurrentMonth; day++) {
                    let label = moment().date(day).format('DD/MM/YYYY');
                    let weekday = moment(label, 'DD/MM/YYYY').date();
                    let sameWeekdayData = allData.filter(item => moment(item.label, 'DD/MM/YYYY').date() == weekday);

                    if (weekday == 31 && sameWeekdayData.length == 0) {
                        sameWeekdayData.push(allData[allData.length - 1])
                        sameWeekdayData.push(allData[allData.length - 2])
                    }

                    let forecastConsumption =
                        sameWeekdayData.reduce((sum, item) => sum + Number(item.consumption), 0) / sameWeekdayData.length;

                    forecastData.push({
                        label: label,
                        consumption: forecastConsumption,
                        dateOnly: null,
                        extraLabel: "",
                        cost: 0
                    });
                }
            }

            var maxIndexSeriesDataCurrent = 0;
            seriesDataCurrent = actualData.slice(0, actualData.length)
                .map((item: GraphItem, index: number) => {
                    currentMonthConsumption = currentMonthConsumption + Number(item.consumption);
                    maxIndexSeriesDataCurrent++;
                    return currentMonthConsumption;
                });
            var forecastMonthConsumption = 0.00;
            seriesDataForecast = forecastData.map((item: GraphItem, index: number): number | null | string => {
                if ((maxIndexSeriesDataCurrent - 1) == index) {
                    forecastMonthConsumption = currentMonthConsumption;
                }
                forecastMonthConsumption = forecastMonthConsumption + Number(item.consumption);
                if (forecastMonthConsumption == 0.00) {
                    return null;
                }
                return forecastMonthConsumption;
            });
            let xAxisData;

            xAxisData = fullMonth ?
                forecastData.length >= lastMonthData.length ?
                    forecastData.map((item: any) => {
                        return (moment(item.label, 'DD/MM/YYYY').format('DD'));
                    })
                    :
                    lastMonthData.map((item: any) => {
                        return (moment(item.label, 'DD/MM/YYYY').format('DD'));
                    })
                :
                currentMonthData.slice(0, (new Date().getDate() - 1)).map((item: any) => {
                    return (moment(item.label, 'DD/MM/YYYY').format('DD'));
                });
            return { corAnterior, corAtual, xAxisData, seriesDataCurrent, seriesDataForecast, currentMonthData };
        }
    }

    getLineConsumptionChart = (currentMonthTotalConsumption: any,
        lastMonthTotalConsumption: any,
        fullMonth: boolean, kpi: FinOpsDashBoard, isHome?: boolean, title?: string) => {

        var monthsLabel = '';
        if (!(currentMonthTotalConsumption.length > 0)) {
            return {};
        }

        var nowMoment = moment(currentMonthTotalConsumption[0].label, 'DD/MM/YYYY');

        const yearV = nowMoment.year();
        const monthV = nowMoment.month() + 1;

        let previous = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').add(-1, "M").format('MMMM') + (kpi.lastMonthDailyConsumption.length > 0 ? '' : ' (sem dados)');
        let actual = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').format('MMMM') + (kpi.dailyConsumption.length > 0 ? '' : ' (sem dados)')

        previous = previous && previous.length > 0 ? previous.charAt(0).toUpperCase() + previous.slice(1) : "";
        actual = actual && actual.length > 0 ? actual.charAt(0).toUpperCase() + actual.slice(1) : "";

        let baseValue = 0;
        if (kpi.totalConsumption > 1000000)
            baseValue = Math.max(...kpi.costCenterConsumption.filter(c => c.consumption != null).map(c => c.consumption != null ? c.consumption : 0))
        else
            baseValue = kpi.totalConsumption;

        var divideBy = 1;
        if (baseValue <= 10000) {
            divideBy = (1)
        } else if (baseValue <= 1000000) {
            divideBy = (1000)
        } else if (baseValue <= 100000000) {
            divideBy = (1000000)
        }

        const seriesArray = [
            ...(!isHome ? [{
                name: previous,
                type: 'line',
                data: lastMonthTotalConsumption.map((item: any) => item.consumption),
                itemStyle: {
                    color: '#255A4B',
                    fontSize: fontSize + 'px',
                },
                showSymbol: false,
            }] : []),
            {
                name: actual,
                type: 'line',
                data: currentMonthTotalConsumption.map((item: any) => item.consumption),
                itemStyle: {
                    color: '#9FA3FF'
                },
                lineStyle: {
                    width: isHome ? 3 : 2
                },
                showSymbol: false,
            }
        ];
        var formatNumberLCFunction = this.formatNumberLC;
        if (actual)
            return ({
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#f1f5fb',
                    borderWidth: 0,
                    textStyle: {
                        fontSize: 11,
                        fontFamily: 'Ubuntu',
                        color: '#2B304F',
                    },
                    axisPointer: {
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    valueFormatter: (value) => { return this.formatNumberLC(value) },
                    formatter: function (params) {
                        let output = `<div style="display: flex; justify-content: space-between;"><span><b>${params[0].name}</b></span><span>R$ </span></div>`;
                        for (let param of params) {
                            if (param.data > 0) {
                                output += `<div style="display: flex; justify-content: space-between;gap:10px;"><span style="max-width:200px; text-overflow: ellipsis; overflow: hidden; text-wrap: nowrap;">${param.marker} ${param.seriesName}</span> <span><b>${formatDecimal(param.data)}</b></span></div>`;
                            }
                        }
                        return output;
                    }
                },
                legend: {
                    show: !isHome,
                    top: 0,
                    left: 'center',
                    textStyle: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: '#8b90a3',
                    },
                    data: [previous, actual],
                    inactiveColor: '#ededed',
                    inactiveBorderColor: '#ededed',
                },
                title: {
                    text: title,
                    right: "0",
                    top: "0",
                    textStyle: {
                        fontSize: 11,
                        fontWeight: 'normal',
                        color: '#8b90a3',
                        fontFamily: 'Ubuntu',
                    },
                    backgroundColor: title ? '#ffffff90' : 'transparent',
                    borderRadius: [10],
                    padding: [4, 8, 4, 8],
                    itemGap: 8
                },
                grid: isHome ?
                    {
                        left: 0,
                        right: 0,
                        bottom: '10px',
                        top: !title ? '5px' : '25px',
                        containLabel: true
                    } :
                    {
                        // Configurações padrão da grid
                        left: '3%',
                        right: '3%',
                        bottom: '3%',
                        containLabel: true
                    },
                xAxis: {
                    show: true,
                    axisLabel: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: '#8b90a3',
                        interval: fullMonth ? 7 : 5,
                        showMaxLabel: true
                    },
                    offset: isHome ? 5 : undefined,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    axisTick: { show: false },
                    type: 'category',
                    nameLocation: 'middle',
                    boundaryGap: false,
                    data: fullMonth ?
                        currentMonthTotalConsumption.map((day: any) => {
                            return isHome ? moment(day.label, 'DD/MM/YYYY').format('DD MMM') : moment(day.label, 'DD/MM/YYYY').format('DD');
                        })
                        :
                        currentMonthTotalConsumption.slice(0, moment().date()).map((day: any) => {
                            return isHome ? moment(day.label, 'DD/MM/YYYY').format('DD MMM') : moment(day.label, 'DD/MM/YYYY').format('DD');
                        })
                },
                yAxis: {
                    show: true,
                    axisLabel: {
                        fontSize: fontSize + 'px',
                        fontFamily: 'Ubuntu',
                        color: '#8b90a3',
                        margin: 5,
                        formatter: function (value: number) {
                            return formatNumberLCFunction(value);
                        },
                    },
                    type: 'value',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    splitNumber: 3,
                },
                series: seriesArray
            });
    };

    getBarConsumptionChart = (tipoDePeriodo: any, cores: any,
        kpi: any, type?: "bar" | "stack" | "line",
        focused?: boolean, groupType?: string) => {

        //const titleAmount = '';
        let dataGroupList: any[] = [];
        if (groupType == "resource") {
            dataGroupList = kpi?.dataGroupbyResource;
        }

        if (groupType == "service") {
            dataGroupList = kpi?.dataGroupbyService;
        }

        if (groupType == "costCenter") {
            dataGroupList = kpi?.dataGroupbyCostCenter;
        }

        if (groupType == "all") {
            dataGroupList = kpi?.dataGroupbyTotal;
        }

        var _dataGroupLast = kpi && dataGroupList && dataGroupList.length > 0
            ?
            dataGroupList.filter(d => d.group != "Outros")
            :
            [];

        if (groupType != "Total") {
            _dataGroupLast.length > 0 &&
                _dataGroupLast.push(dataGroupList.filter(d => d.group == "Outros")[0]);
        }

        var formatNumberLCFunction = this.formatNumberLC;
        return ({
            animation: (tipoDePeriodo === "last180Days" || tipoDePeriodo === "last365Days") && groupType === "resource" ? false : true,
            color: cores,
            tooltip: {
                triggerOn: (tipoDePeriodo === "last180Days" || tipoDePeriodo === "last365Days") && groupType === "resource" ? "click" : "mousemove",
                trigger: 'axis',
                order: 'valueDesc',
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 11,
                    fontFamily: 'Ubuntu',
                    color: 'var(--text-default)',
                },
                axisPointer: {
                    label: {
                        backgroundColor: '#6a7985'
                    }
                },
                //valueFormatter: (value) => { return formatDecimal(value) },
                valueFormatter: (value) => { return this.formatNumberLC(value) },
                formatter: function (params) {
                    let output = `<div style="display: flex; justify-content: space-between;"><span><b>${params[0].name}</b></span><span>R$ </span></div>`;
                    for (let param of params) {
                        if (param.data > 0) {
                            output += `<div style="display: flex; justify-content: space-between;gap:10px;"><span style="max-width:200px; text-overflow: ellipsis; overflow: hidden; text-wrap: nowrap;">${param.marker} ${param.seriesName}</span> <span><b>${formatDecimal(param.data)}</b></span></div>`;
                        }
                    }
                    return output;
                }
            },
            grid: {
                left: '1%',
                right: '3%',
                bottom: tipoDePeriodo.includes("month") ? '24%' : '5%',
                top: tipoDePeriodo.includes("month") ? '3%' : '7%',
                containLabel: true
            },
            xAxis: {
                axisLabel: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    interval: tipoDePeriodo.includes("30") ? focused ? 0 : 2 : undefined,
                    showMaxLabel: true,
                    fontSize: fontSize + 'px',
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dashed'
                    },
                },
                axisTick: {
                    alignWithLabel: true
                },
                type: 'category',
                axisLine: {
                    symbol: "none",
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                nameLocation: 'middle',
                boundaryGap: true,
                data: kpi && _dataGroupLast && _dataGroupLast && _dataGroupLast.length > 0 ?
                    _dataGroupLast[0].series.map((item: any) => {
                        if (tipoDePeriodo.includes("month"))
                            return (moment(item.date.split('T')[0]).format('MMM D'));
                        return item ? (moment(item.date.split('T')[0]).format('MMM/yyyy')) : [];
                    })
                    : []
            },
            yAxis: {
                type: 'value',
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed'
                    },
                },
                splitNumber: 5,
                axisLabel: {
                    fontSize: fontSize + 'px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    formatter: function (value: number) {
                        return formatNumberLCFunction(value);
                    },
                    //interval: 2
                }
            },
            series:
                _dataGroupLast
                    .map((itemGroup, index) => {
                        return {
                            colorBy: 'series',
                            stack: type == "stack" ? 'x' : undefined,
                            name: itemGroup && itemGroup.group || 'nd',
                            type: type ? (type == "bar" || type == "stack" ? "bar" : "line") : 'bar',
                            data: itemGroup ? itemGroup.series
                                .sort((element1, element2) => {
                                    return (element1.date).valueOf() - (element2.date).valueOf()
                                })
                                .map((item: any) => {
                                    return (item.total)
                                }) : [],
                            showSymbol: false,
                            color: itemGroup && itemGroup.group == "Outros" ? "#aaa" : cores[index]
                        };
                    })
        });
    };
}