import { Grid } from '@material-ui/core';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'; 

//Redux
import { useSelector } from 'react-redux';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer'; 
import { TreeItem, TreeView } from '@material-ui/lab';
import UserService from '../../../services/userService';
import PivotTableWebix from '../../../components/webix';

const HealthCheckCustomerResume: React.FC = (props) => {

    const userservice = new UserService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [jobs, setJobs] = useState<any[]>([]);
    const [dateTimeSync, setDateTimeSync] = useState<any[]>([]);
    const [scopeHC, setScopeHC] = useState<any[]>([]);
    const [eventsDetails, setEventsDetails] = useState<any[]>([]);
    const [rows, setRows] = useState<string[]>(['Group Name', 'Storage Policy', 'Instance', 'Jobid']);//
    const [cols, setCols] = useState<string[]>([]);//
    const [servers, setServers] = useState<any[]>([]);
    const [agentTypes, setAgentTypes] = useState<any[]>([]);
    const [backupTypes, setBackupTypes] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modeView, setModeView] = useState<string>("Tabela");
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>({} as Array<{ [K: string]: string }>);

    const [statePivotTableUIProps, setStatePivotTableUIProps] = useState<object>();

    async function retrieveDataFromBackupDynamo() {

        try {
            var currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
            var currentYear = new Date().getFullYear().toString();
            var currentDay = new Date().getDay().toString().padStart(2, '0');
            setIsLoadingJobs(true);

            if (month) {
                currentMonth = month
            }
            if (year) {
                currentYear = year
            }
            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }
            // 
            if (user.ClientGroupSelected && currentMonth && currentYear) {
                // 
                const filterDate = [currentYear, currentMonth, currentDay].join('');
               
                var response = (await userservice.GetHealthRobotEventGetbyPartitionReport(filterDate));
                setDateTimeSync(response[0]["sincronizacao"]);
         
                let jobsResponse = response ? response : [];
                setDataPivot(jobsResponse);

                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.log(error);
        };

    }

    useEffect(() => {
        retrieveDataFromBackupDynamo();

    }, [month, user.ClientGroupSelected, year, user.refreshFlag, refresh]);


    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
        ],
        customPeriod: {
            label: 'Filtro',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'date',
        onChange: onChagePeriod
    };




    return (
        <Layout pivot
        >
            <LcLoading label="Carregando..." loading={isLoadingJobs}>
                {
                    dataPivot && dataPivot.length > 0 &&
                    <>
                        <div style={{ width: '100%', height: '25px', backgroundColor: '#e0ecfe', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ color: '#2E1956', fontSize: '10pt' }}>
                                Abaixo é apresentado o detalhamento de todos os jobs executados no mês
                            </span>
                        </div>
                        <div  >
                            {dataPivot && dataPivot.length > 0 &&
                                <PivotTableWebix
                                    idView={'dataPivot'}
                                    key={'dataPivot'} data={dataPivot}
                                    fields={[
                                        { id: 'situacao', value: "Situação", type: "text" },
                                        { id: 'categoria', value: "Categoria", type: "text" },
                                        { id: 'group', value: "Group", type: "text" },
                                        { id: 'cliente', value: "Cliente", type: "text" },
                                        { id: 'provedor', value: "Provedor", type: "text" },
                                        { id: 'mensagemSituacao', value: "Mensagem", type: "text" },
                                        { id: 'dataSincronizacao', value: "Sincronização", type: "text" },
                                        { id: 'ocorreuBloqueio', value: "Dados foram atualizados", type: "text" },
                                        { id: 'ultimoDadoValido', value: "Ultima validação", type: "text" },
                                        { id: 'dataVencimento', value: "Vigência assinatura", type: "text" }
                                    ]}
                                    structure={{
                                        rows: ["situacao", "provedor", "categoria"],
                                        columns: ["dataSincronizacao"],
                                        values: [{ name: "mensagemSituacao", operation: ["count"] }]
                                    }}
                                />}

                        </div>
                    </>
                }
            </LcLoading>

        </Layout>
    );
};

export default HealthCheckCustomerResume;