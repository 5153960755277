import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { EndpointSecurityService } from '../../../services/eSecurity/endpointSecurityService';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import { LinearProgress, Grid } from '@material-ui/core';
import LcSegment from '../../../components/Generic/LcSegment';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcNoData from '../../../components/Generic/LcNoData';

const EndpointSecurityUsage: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    const [usageChart, setUsageChart] = useState<any>(null);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingUsageChart, setIsLoadingUsageChart] = useState(true);

    const [devices, setDevices] = useState<any[]>([]);

    const usageChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        tooltips: {
            mode: 'index',
            intersect: false
        },
        scales: {
            xAxes: [{
                stacked: false,
                id: "bar-x-axis1",
                barThickness: 40,
            }, {
                display: false,
                stacked: false,
                id: "bar-x-axis2",
                barThickness: 70,
                type: 'category',
                categoryPercentage: 0.8,
                barPercentage: 0.9,
                gridLines: {
                    offsetGridLines: true
                },
                offset: true
            }],
            yAxes: [{
                stacked: false,
                ticks: {
                    beginAtZero: true
                },
            }]
        }
    };

    const handleClickChart = (element: any) => {

        const endpointSecurityService = new EndpointSecurityService(props);

        endpointSecurityService.GetDevices()
            .then(response => {
                setDevices(response);
                console.debug(response);
            })
            .catch(error => {
                console.debug(error);
            });
    }
    const columnsDevice = [
        {
            field: 'name',
            headerName: 'Nome',
            width: '70%'
        },
        {
            field: 'isActive',
            headerName: 'Ativo',
            width: '10%',
            renderCell: (row: any) => {
                return row.status ? 'Ativo' : 'Inativo';
            }
        },
        {
            field: 'status',
            headerName: 'Estado',
            width: '10%'
        },
        {
            field: 'groupName',
            headerName: 'Grupo',
            width: '10%'
        },
    ]
    const functionGeneric = [
        {
            id: '9e73a54d-2c40-4408-b8be-706963c12ae9',
            icon: 'ppi ppi-arrow-left',
            tooltip: 'Voltar',
            func: () => {
                setDevices([]);
            }
        }
    ]
    const rowStatus = (row: any) => {
        return row.status ? 'success' : 'danger';
    }







    useEffect(() => {
        setIsLoadingUsageChart(true);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const searchDate = [year, month].join('');
            const endpointSecurityService = new EndpointSecurityService(props);
            endpointSecurityService.GetCards(searchDate)
                .then(response => {
                    if (response.data) {
                        if (response.data.totalDevices > 0 && response.data.totalDevicesContracted > 0) {
                            let usageChart = {
                                labels: ["Subscrições contratadas / alocadas"],
                                datasets: [
                                    {
                                        label: "Alocadas",
                                        backgroundColor: "rgba(255, 65, 54, 0.6)",
                                        borderWidth: 1,
                                        data: [response.data.totalDevices]
                                    },
                                    {
                                        label: "Contratadas",
                                        backgroundColor: "rgba(0, 117, 219, 0.6)",
                                        borderWidth: 1,
                                        data: [response.data.totalDevicesContracted]
                                    }
                                ]
                            };
                            setUsageChart(usageChart);
                        }
                    }
                })
                .catch(error => {
                    console.warn(error);
                })
                .then(() => {
                    setIsLoadingUsageChart(false)
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag, month, year]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    return (
        <Layout pageTitle="Endpoint security" periodSelection={periodSelection}  >
            {
                devices.length == 0 ?
                    <LcSegment title="Subscrições contratadas e alocadas" >
                        <LcLoading label="Carregando gráfico..." loading={isLoadingUsageChart}>
                            <Grid container justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12} xl={12} >
                                {
                                    usageChart ?
                                        <Bar
                                            width={500}
                                            height={250}
                                            data={usageChart}
                                            options={usageChartOptions}
                                            onElementsClick={handleClickChart}
                                        />
                                        :                                        
                                        <LcNoData />
                                }
                            </Grid>
                        </LcLoading>
                    </LcSegment>
                    :
                    <LcInfiniteTable
                        rows={devices}
                        columns={columnsDevice}
                        loadMore={() => { }}
                        size={devices.length}
                        functionGeneric={functionGeneric}
                        status={rowStatus}
                    />
            }
        </Layout>
    );
};

export default EndpointSecurityUsage;


