import React, { useState } from 'react';
import './index.css';
import { InputField } from '../../../../components/Form/Input';

interface AuthenticationModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (selectedAuth: string) => void;
}

const AuthenticationModal: React.FC<AuthenticationModalProps> = ({ isVisible, onClose, onSave }) => {
    const [authType, setAuthType] = useState<'None' | 'API Key' | 'OAuth'>('None');
    const [apiKey, setApiKey] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');
    const [authorizationUrl, setAuthorizationUrl] = useState<string>('');
    const [tokenUrl, setTokenUrl] = useState<string>('');
    const [scope, setScope] = useState<string>('');

    const handleSave = () => {
        onSave(authType); // Chama a função onSave com o tipo de autenticação selecionado
        onClose();
    };

    const handleInputChange = (e: { name: string, value: string }) => {
        switch (e.name) {
            case 'apiKey':
                setApiKey(e.value);
                break;
            case 'clientId':
                setClientId(e.value);
                break;
            case 'clientSecret':
                setClientSecret(e.value);
                break;
            case 'authorizationUrl':
                setAuthorizationUrl(e.value);
                break;
            case 'tokenUrl':
                setTokenUrl(e.value);
                break;
            case 'scope':
                setScope(e.value);
                break;
            default:
                break;
        }
    };

    if (!isVisible) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Autenticação</h2>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="None"
                            checked={authType === 'None'}
                            onChange={(e) => setAuthType(e.target.value as 'None')}
                        />
                        Nenhum
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="API Key"
                            checked={authType === 'API Key'}
                            onChange={(e) => setAuthType(e.target.value as 'API Key')}
                        />
                        Chave de API
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="OAuth"
                            checked={authType === 'OAuth'}
                            onChange={(e) => setAuthType(e.target.value as 'OAuth')}
                        />
                        OAuth
                    </label>
                </div>

                {authType === 'API Key' && (
                    <InputField
                        label="Chave de API"
                        name="apiKey"
                        value={apiKey}
                        type="text"
                        onChange={handleInputChange}
                        placeHolder="Insira sua Chave de API"
                    />
                )}

                {authType === 'OAuth' && (
                    <>
                        <InputField
                            label="ID do Cliente"
                            name="clientId"
                            value={clientId}
                            type="text"
                            onChange={handleInputChange}
                            placeHolder="Insira seu ID do Cliente"
                        />
                        <InputField
                            label="Segredo do Cliente"
                            name="clientSecret"
                            value={clientSecret}
                            type="text"
                            onChange={handleInputChange}
                            placeHolder="Insira seu Segredo do Cliente"
                        />
                        <InputField
                            label="URL de Autorização"
                            name="authorizationUrl"
                            value={authorizationUrl}
                            type="text"
                            onChange={handleInputChange}
                            placeHolder="Insira a URL de Autorização"
                        />
                        <InputField
                            label="URL de Token"
                            name="tokenUrl"
                            value={tokenUrl}
                            type="text"
                            onChange={handleInputChange}
                            placeHolder="Insira a URL de Token"
                        />
                        <InputField
                            label="Escopo"
                            name="scope"
                            value={scope}
                            type="text"
                            onChange={handleInputChange}
                            placeHolder="Insira o Escopo"
                        />
                    </>
                )}

                <div className="modal-buttons">
                    <button className="pp-button" onClick={onClose}>Cancelar</button>
                    <button className="pp-button" onClick={handleSave}>Salvar</button>
                </div>
            </div>
        </div>
    );
};

export default AuthenticationModal;
