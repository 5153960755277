import React, { useState } from 'react';
import { PPForm2, PPModal } from 'processor-plataform-ui';
import { HeyHoService } from '../../services/heyHoService';
import { useSnackbar } from 'notistack';

import "./index.css"
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import Attachment from './Attachment';
import LcLoading from '../../components/Generic/LcLoading';

interface CustomFormProps {
    data?: any,
    ticketId: Number,
    handleform?: any,
    visible: boolean,
    onClose: Function,
    parentCallbackSave?: Function;
}
interface InputProp {
    name: string;
    value: string
}

const DetailForm: React.FC<CustomFormProps> = (props) => {
    const constructLiveWatchServiceGroupService = (): HeyHoService => {
        return new HeyHoService(props);
    };
    const [visible, setVisible] = useState(props.visible);
    const [anexos, setAnexos] = useState<any[]>();
    const { enqueueSnackbar } = useSnackbar();
    const [commentText, setCommentText] = useState<InputProp[]>([{name: '', value: ''}]);
    const [loading, setLoading] = useState<boolean>(false);

    function saveRecord(formData: InputProp[]) {
        if (formData && formData.length > 0 && formData[0].value as string && formData[0].value.trim() !== '') {
            setLoading(true)
            let data = {
                id: props.ticketId,
                Descricao: formData[0].value,
                Anexo: anexos ? anexos.map(x => { return x }) : undefined
            };
            props && props.parentCallbackSave && props.parentCallbackSave(data);

        }
        else {
            enqueueSnackbar(`Preencher campos obrigatórios.`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        }

    }

    const fieldsForm : PPFormInput[] = [
        {
            title: 'Detalhes',
            name: 'Descricao',
            type: 'textarea',
            rows: 5,
            required: true
        }
    ]

    const removeFile = (fileName: string) => {
        const files_ = anexos && anexos.filter(item => item.name !== fileName);
        setAnexos(files_);
    };


    return (
        <PPModal
            title={`Ticket: ${props.ticketId} Adicionar comentário`}
            visible={visible}
            onClose={() => { setVisible(false); setLoading(false); if (props.onClose) { props.onClose(); } }}
            >
                <LcLoading loading={loading}>
                    <PPForm2
                        fields={fieldsForm}
                        onChange={setCommentText}
                        functions={
                            {
                                submitButton: {
                                    hide: true,
                                    label: 'Ok',
                                },
                            }
                        }
                    >
                        <Attachment anexos={anexos} removeFile={(e) => removeFile(e)} setAnexos={setAnexos} visible={true}/>
                    </PPForm2>
                    <div className="lc-buttons space-between">
                        <button className='pp-button bg-info ml-3' id={'form_adicionarcomentario_salvar_button'}onClick={() => saveRecord(commentText)}>Salvar</button>
                        <button className='pp-button bg-gray mr-3' id={'form_adicionarcomentraio_cancelar_button'}onClick={() => props.onClose() }>Cancelar</button>
                    </div>
                </LcLoading>
            
        </PPModal >);
}

export default DetailForm;