import axios, { AxiosResponse } from 'axios';
import { APIClient } from '../api';

interface EAAdicionaisOnPremise {
	eaSubscriptionName: string;
	country: string;
	valueUnit: number;
	quantidade: number;
}

class EAAdicionaisOnPremiseService extends APIClient {
	constructor(props: any) {
		super(props);
	}
	async deleteEAAdicionaisOnPremise(data: EAAdicionaisOnPremise): Promise<boolean> {
		try {
			const response: AxiosResponse<boolean> = await axios.post(`${this.endpoint}/api/SaaSSubscription/DeleteEAAdicionaisOnPremise`, data);
			return response.data;
		} catch (error) {
			console.error('Error deleting EAAdicionaisOnPremise:', error);
			throw error;
		}
	}

	async saveEAAdicionaisOnPremise(data: EAAdicionaisOnPremise): Promise<boolean> {
		try {
			const response: AxiosResponse<boolean> = await axios.post(`${this.endpoint}/api/SaaSSubscription/SaveEAAdicionaisOnPremise`, data);
			return response.data;
		} catch (error) {
			console.error('Error saving EAAdicionaisOnPremise:', error);
			throw error;
		}
	}

	async queryEAAdicionaisOnPremise(): Promise<EAAdicionaisOnPremise[]> {
		try {
			const response: AxiosResponse<EAAdicionaisOnPremise[]> = await axios.post(`${this.endpoint}/api/SaaSSubscription/QueryEAAdicionaisOnPremise`);
			return response.data;
		} catch (error) {
			console.error('Error querying EAAdicionaisOnPremise:', error);
			throw error;
		}
	}
}

export default new EAAdicionaisOnPremiseService({});
