import React, { useCallback, useEffect, useState } from 'react';
//Componentes
import LcLoading from '../../../components/Generic/LcLoading';
//Serviços
import { FinOpsService } from '../../../services/finOps/finOpsService';
import { formatDecimal } from '../../../services/currency';
//Service
import { CostCenterService, CostCenterWithBudget } from '../../../services/costCenter/costCenterService';
import moment from 'moment';
import "./index.css"
//IMport do css do PPTable para componente adaptado
import '../../../components/Data/PPTable/index.css';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';

import { BudgetTable } from './BudgetTable';

interface BudgetProps {
    onEdit: (cc: string) => void,
    visibleMode: number,
    showInactives: boolean
}

export interface StrucureModel {
    cc: string
    show: boolean
    children: any[]
    active: boolean
}

interface BudgtDataModel {
    ccId: string
    value: number
    date: string
    total: number
}


const getBudget = (month: string, budgetData: any) => {
    if (!budgetData) return 0;
    let budgetMensal = budgetData.monthBudget?.find(mb => mb.month === month)?.value;
    let val = budgetMensal !== undefined ? budgetMensal : (budgetData.budget || 0);
    return val || 0;
}

const calcPercent = (value: number, base: number) => {
    return (base === 0) ? 'N/A' : ((value / base) * 100).toFixed(1) + '%';
}

export const Budget: React.FC<BudgetProps> = ({onEdit, showInactives, visibleMode }) => {
    //Services
    const [loading, setLoading] = useState<boolean>(false);
    const [budgetData, setBudgetData] = useState<StrucureModel[]>([]);
    const [ccs, setCCs] = useState<string[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [ccData, setCCData] = useState<CostCenterWithBudget[]>([]);

    const fetchCostCenterData = useCallback(async () => {
        const costCenterService = new CostCenterService({});
        try {
            const { data } = await costCenterService.GetTreeDataWithBudget();
            const filteredCCData = showInactives ? data : data.filter(cc => cc.active);
            const ccs = filteredCCData.map(cc => cc.id.toString());
            return {filteredCCData, ccs};
        } catch (error) {
            console.error("Erro ao buscar os centros de custo:", error);
            throw error;
        }
    },[showInactives]);

    const fetchAndProcessBudgetData = async () => {
        const finOpsService = new FinOpsService({});
        try {
            const response = await finOpsService.LoadBudgetvsUsagebyGroupFull();
            const budgetData: BudgtDataModel[] = response.map(b => ({
                ccId: b.parent.title,
                value: b.parent.value,
                date: b.parent.date.substring(0, 7),
                total: b.total
            }));
            return budgetData;
        } catch (error) {
            console.error("Erro na busca pelo consumo:", error);
            throw error;
        }
    };

    const buildCollumns = (_budgetData: BudgtDataModel[]) => {
        let months: any[] = [...new Set(_budgetData.map(b => b.date))].sort((a: any, b: any) => { return a > b ? 1 : -1 });
        let columns: any[] = [{
            field: 'name', headerName: 'Créditos e consumo', width: "25%",
            renderCell: (row) => {
                if (row.name.includes('.'))
                    return <span style={{ marginLeft: '20px' }}>{row.name.replace('.', '')}</span>
                else
                    return <strong>{row.name}</strong>
            }
        }];
        months.forEach(m => {
            columns.push(
                {
                    field: m,
                    headerName: moment(m.replace('-', '') + '01', "YYYYMMDD").format("MMM[/]YY"),
                    width: `${75 / m.length}%`,
                    align: "right"
                });
        })
        setColumns(columns);
    }

    const buildBudgetRows = useCallback((ccs: string[], filteredCCData: CostCenterWithBudget[] , _budgetData: BudgtDataModel[]) => {
        let months: any[] = [...new Set(_budgetData.map(b => b.date))].sort((a: any, b: any) => { return a > b ? 1 : -1 });
        try {
            let structured: any[] = [];
            ccs.forEach(cc => {
                let thisCC: any = {
                    cc,
                    show: false,
                    children: [],
                    active: filteredCCData.find(costcenter => costcenter.id === parseInt(cc))?.active
                };
                let newRowA: any = { name: "Créditos acumulados", show: false };
                let newRowA1: any = { name: "Créditos no começo do período" };
                let newRowA2: any = { name: "Novos créditos" };
                let newRowB: any = { name: "Consumo" };
                let newRowC: any = { name: "Saldo acumulado" };
                let sobra = 0;
                months.forEach(m => {

                    let budget = getBudget(m, filteredCCData.find(ccd => ccd.id.toString() === cc));
                    let value = _budgetData.find(b => b.ccId === cc && b.date === m)?.value || 0;
                    var calcBudget = budget + sobra;
                    var calcValue = value;
                    var calcSaldo = (calcBudget - calcValue);

                    newRowA[m] = visibleMode === 0 ? formatDecimal(calcBudget) : calcPercent(calcBudget, budget);
                    newRowA1[m] = visibleMode === 0 ? formatDecimal(sobra) : calcPercent(sobra, budget);
                    newRowA2[m] = visibleMode === 0 ? formatDecimal(budget) : calcPercent(budget, budget);
                    newRowB[m] = visibleMode === 0 ? formatDecimal(calcValue) : calcPercent(calcValue, budget);
                    newRowC[m] = visibleMode === 0 ? formatDecimal(calcSaldo) : calcPercent(calcSaldo, budget);

                    sobra = calcSaldo;
                });

                thisCC.children.push(newRowA, newRowA1, newRowA2, newRowB, newRowC);
                structured.push(thisCC);
            });
            setBudgetData(structured);

        } catch (error) {
            throw new Error('Erro ao montar as colunas' + error)
        } finally{
            setLoading(false)
        }
    },[visibleMode])


    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const { filteredCCData, ccs } = await fetchCostCenterData()
            const _budgetData =  await fetchAndProcessBudgetData()
            setCCData(filteredCCData);
            setCCs(ccs);
            Promise.all([
                buildCollumns(_budgetData),
                buildBudgetRows(ccs, filteredCCData, _budgetData)
            ])
        }
        loadData();
    }, [buildBudgetRows, fetchCostCenterData])

    const cards = [{
        title: () => {
            return <div className="lc-title ellipsis">Orçamento</div>
        },
        type: 'Custom', position: { ColSpan: 12, RowSpan: 4, row: 1, col: 1 },
        customContentRender: () =>
            <BudgetTable ccs={ccs}
                ccData={ccData} 
                loading={loading} 
                visibleMode={visibleMode} 
                budgetData={budgetData} 
                onEdit={onEdit} 
                columns={columns} 
                setBudgetData={setBudgetData} 
            />
    }]

    return(
        <LcLoading loading={loading} loadingType='Helix'>
            <LCDashboard cards={cards} />
        </LcLoading>
    );
}