import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupSaasServiceV2, CloudAllyResponseTask, CloudAllyResponseTaskData, TypeResponse } from '../../../services/backupSaas/backupSaasServiceV2';
import styles from "./index.module.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';


import exchangeLogo from '../icons/exchange-logo.svg'
import sharepointLogo from '../icons/sharepoint-logo.svg'
import teamsLogo from '../icons/teams-logo.svg'
import onedriveLogo from '../icons/onedrive-logo.svg'
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcNoData from '../../../components/Generic/LcNoData';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}


const BackupSaasResumo: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);
    const service = new BackupSaasServiceV2(props);
    //By default
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20)
    //Records
    const [rows, setRows] = useState<CloudAllyResponseTask>(service.GetEmptyRecordTask());
    const [isLoadingUserChart, setIsLoadingUserChart] = useState<boolean>(true);
    const [userChart, setUserChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [notFoundCredential, setNotFoundCredential] = useState<string>('')

    useEffect(() => {
        setIsLoadingTable(true)
        setIsLoadingUserChart(true)
        service.GetTasks(page, pageSize).then(result => {

            const response: CloudAllyResponseTask = result.data;
            if (result.status == 200) {
                if (service.IsNullReturn(response, TypeResponse.CloudAllyResponseTask)) {
                    setNotFoundCredential('Credencial não encontrada, revise suas assinaturas e tente novamente.')
                    setIsLoadingTable(false)
                    return;
                } else {
                    setNotFoundCredential('')
                    setRows(response)
                    setIsLoadingTable(false)
                }
            }
        }).finally(() => { setIsLoadingTable(false) });;

    }, [user.ClientGroupSelected, user.refreshFlag, page, pageSize])

    useEffect(() => {
        if (service.IsNullReturn(rows, TypeResponse.CloudAllyResponseTask)) return;

        const dataChart: DoughnutChartValue = {
            title: "Quantidade de usuários por solução",
            labels: [],
            values: []
        };

        rows.data && rows.data.map((element: CloudAllyResponseTaskData) => {
            if (element != null && element != undefined) {
                dataChart.labels.push(service.GetFromForSource(element.source))
                dataChart.values.push(typeof element.numOfEntities == 'number' ? element.numOfEntities : parseInt(element.numOfEntities))
            }
        })
        setUserChart(dataChart)
        setIsLoadingUserChart(!isLoadingUserChart)
    }, [rows])


    const GetImageBySourceName = (sourceName) => {
        let iconName = service.GetPathIcon(sourceName)
        switch (iconName) {
            case "exchange-logo":
                return (
                    <img src={exchangeLogo} height={36} width={36} />
                )
                break;

            case "onedrive-logo":
                return (
                    <img src={onedriveLogo} height={36} width={36} />
                )
                break;

            case "sharepoint-logo":
                return (
                    <img src={sharepointLogo} height={36} width={36} />
                )
                break;

            case "teams-logo":
                return (
                    <img src={teamsLogo} height={36} width={36} />
                )
                break;

            default:
                return (
                    <img src='/' height={36} width={36} />
                )
                break;
        }
    }

    const cards = rows.data && rows.data.length > 0 ? rows.data.map((e: CloudAllyResponseTaskData, index) => {
        return {
            type: 'Custom', hideHeader: true, position: { ColSpan: 10, RowSpan: 1, row: index + 1, col: 2 },
            customContentRender: () => <>{
                <div className={styles.infoCardRow}>
                    <div className={styles.iconBlock}>{GetImageBySourceName(e.source)}</div>
                    <div className={styles.titleBlockContainer}>
                        <div className={styles.titleAlias}>{e.alias}</div>
                        <div className={styles.titleSource}>
                            {service.GetFromForSource(e.source)}
                        </div>
                        <div className={styles.titleStatus} style={
                            {
                                color: service.GetFromForStatusColor(e.status),
                                backgroundColor: service.GetFromForStatusBgColor(e.status)
                            }
                        }>
                            <i className={service.GetFromForStatusIcon(e.status)}></i> {service.GetFromForStatus(e.status)}
                        </div>
                    </div>
                    <div></div>
                    <div className={styles.infoBlockContainer}>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoBlockLabel}>Total</span>
                            <span className={styles.infoBlockContent}>{e.numOfEntities}</span>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoBlockLabel}>Ativos</span>
                            <span className={styles.infoBlockContent}>{e.numOfBilledEntities}</span>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoBlockLabel}>Tamanho total</span>
                            <span className={styles.infoBlockContent}>{service.bytesToSize(e.size)}</span>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoBlockLabel}>Último snapshot</span>
                            <span className={styles.infoBlockContent}>{e.snapshotDate?.toString() ?? "-"}</span>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoBlockLabel}>Próximo backup</span>
                            <span className={styles.infoBlockContent}>{service.GetTraslatedNextBackup(e.nextBackup)}</span>
                        </div>
                    </div>
                </div>
            }</>
        }
    }) : [];

    const dashboardRightSidePanel = {
        title: '',
        pinned: false,
        show: false,
        content: () => {
            return <div> {/* Filter settings... */} </div>;
        }
    }

    return (
        <Layout pageTitle="Resumo">
            <LcLoading loading={isLoadingTable}>
                {notFoundCredential != '' ?
                    <LcNoData label={notFoundCredential} />
                    :
                    <LCDashboard cards={cards} rightSidePanel={dashboardRightSidePanel} />
                }
            </LcLoading>
        </Layout>
    );
};

export default BackupSaasResumo;