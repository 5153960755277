import React from 'react';
import './index.css'; // Importar um arquivo CSS para estilizar os componentes

const categories = [
    {
        name: "Cloud Managed Services",
        items: ["Cloud Corporate", "Cloud Enterprise", "Cloud Premier"],
    },
    {
        name: "Proteção de Dados",
        items: ["Backup Corporate", "Backup Enterprise", "Retenção", "Disaster Recovery"],
    },
    {
        name: "Softwares",
        items: ["Basic", "Premium", "SaaS Backup", "Softwares.com.br"],
    },
    {
        name: "Aplicações de Negócios",
        items: ["Dimee", "Doone", "Hey!Ho!", "Receeba", "Mérito"],
    },
    {
        name: "Segurança",
        items: ["EDR", "LiveWatch", "SecureEndPoint", "Firewall", "Identity Access", "Patch Manager"],
    },
    {
        name: "FinOps",
        items: ["FinOps", "Max Software", "Max Outsource"],
    },
];
 


const Category = ({ name, items }) => {
    return (
        <div className="news-category">
            <h3 className="news-category-title">{name}</h3>
            <ul className="news-category-list">
                {items.map(item => (
                    <li key={item} className="news-category-item">{item}</li>
                ))}
            </ul>
        </div>
    );
};

const News: React.FC = () => {
      return (
    <article className="news-article">
      <header className="news-header">
        <h1 className="news-title">Atualização nas Categorias de Soluções</h1> 
      </header>
      <section className="news-content">
        <p className="news-introduction">Para tornar nossas soluções mais intuitivas e fáceis de navegar, agrupamos as soluções em seis categorias distintas:</p>
        <div className="news-categories">
          {categories.map(category => (
            <Category key={category.name} name={category.name} items={category.items} />
          ))}
        </div>
        <p className="news-additional-info">O botão "Meu LiveCloud" na barra superior esquerda também exibe as soluções contratadas.</p>
      </section>
      <footer className="news-footer">
        <p className="news-objective">Nosso objetivo com essas mudanças é melhorar sua experiência e oferecer uma interface mais clara e intuitiva. Há mais novidades por vir nos próximos meses, onde teremos major releases.</p>
        <p className="news-contact">Estamos sempre à disposição para qualquer dúvida ou preocupação que você possa ter. Sinta-se à vontade para entrar em contato conosco.</p>
        <p className="news-thanks">Agradecemos pela sua parceria e esperamos que goste da nova experiência no LiveCloud!</p>
        <p className="news-sign-off">Atenciosamente </p>
      </footer>
    </article>
  );
};

export default News;
