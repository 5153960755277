import { Grid, makeStyles } from '@material-ui/core';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

//Redux
import { useSelector } from 'react-redux';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { TreeItem, TreeView } from '@material-ui/lab';
import UserService from '../../../services/userService';
import { green } from '@material-ui/core/colors';
import { PiFunnelLight } from 'react-icons/pi';

const BarTreeItem = (props) => {
    const barStyles: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        height: '30px',
        position: 'relative', // Add position relative
        fontFamily: 'Ubuntu, sans-serif', // Set the desired font family
        fontSize: '14px', // Set the desired font size
        lineHeight: '1.4', // Set the desired line height 
        paddingLeft: `${props.level * 20}px`, // Indent based on level 
        borderBottom: '1px solid #ccc', // Add horizontal border
        borderRadius: '4px', // Make borders round
        boxShadow: props.level == 0 ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.1)', // Add shadow to indicate level difference

    };

    const borderStyles: CSSProperties = {
        position: 'absolute',
        left: 0,
        top: 'calc(50% - 0.5px)', // Position the border in the middle
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
    };

    return (
        <TreeItem
            {...props}
            sx={{
                '& .MuiTreeItem-label': {
                    paddingLeft: '0', // Remove default padding
                },
            }}
            label={
                <div style={barStyles}>
                    {props.level > 0 && <div style={borderStyles} />} {/* Show border for levels > 0 */}

                    {props.label}
                </div>
            }
        />
    );
};

const HealthCheckActiveService: React.FC = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            color: '#3c4146',
            '& .MuiTreeItem-root': {
                '&:hover > .MuiTreeItem-content': {
                    backgroundColor: '#f5f5f5',
                },
                '&:focus > .MuiTreeItem-content': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiTreeItem-content': {
                padding: theme.spacing(1),
                '& .MuiTreeItem-label': {
                    fontWeight: 'inherit',
                    color: 'inherit',
                },
            },
            '& .MuiTreeItem-group': {
                marginLeft: 15,
                '& .MuiTreeItem-content': {
                    paddingLeft: 20,
                },
            },
        },
        successIcon: {
            color: 'green',
        },
    }));
    const classes = useStyles();
    const userservice = new UserService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [jobs, setJobs] = useState<any[]>([]);
    const [scopeHC, setScopeHC] = useState<any[]>([]);
    const [eventsDetails, setEventsDetails] = useState<any[]>([]);
    const [rows, setRows] = useState<string[]>(['Group Name', 'Storage Policy', 'Instance', 'Jobid']);//
    const [cols, setCols] = useState<string[]>([]);//
    const [servers, setServers] = useState<any[]>([]);
    const [agentTypes, setAgentTypes] = useState<any[]>([]);
    const [backupTypes, setBackupTypes] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modeView, setModeView] = useState<string>("Tabela");
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>({} as Array<{ [K: string]: string }>);

    const [dateTimeSync, setDateTimeSync] = useState<any[]>([]);
        async function retrieveDataFromBackupDynamo() {
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));
        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            var currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
            var currentYear = new Date().getFullYear().toString();
            var currentDay = new Date().getDay().toString().padStart(2, '0');
            setIsLoadingJobs(true);

            if (month) {
                currentMonth = month
            }

            if (year) {
                currentYear = year
            }

            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }
            // 
            if (user.ClientGroupSelected && currentMonth && currentYear) { 
                const filterDate = [currentYear, currentMonth, currentDay].join('');            
                var responseRer = (await userservice.GetMonitoringAPIbyPartitionReport()); 
                let jobsResponseRer = responseRer ? responseRer : [];   
                setScopeHC(jobsResponseRer);
                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.log(error);
        };

    }

    useEffect(() => {
        retrieveDataFromBackupDynamo();

    }, [month, user.ClientGroupSelected, year, user.refreshFlag, refresh]);


    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
        ],
        customPeriod: {
            label: 'Filtro',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'date',
        onChange: onChagePeriod
    };

    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([
        { field: 'Situação', filter: true },
        { field: 'Agente', filter: true },
        { field: 'SubCliente', filter: true },
        { field: 'Tipo', filter: true }
    ]);


    return (
        <Layout
            pageTitle="Histórico"
            lastUpdate={dateTimeSync.toLocaleString()}
            // functionsGeneric={[{ icon: <PiFunnelLight />, onClick: () => setModalVisible(!modalVisible), tooltip: "Filtro" }]}
            // row={
            //     <LcDropDown
            //         element={<span>{modeView} <i className="ppi ppi-chevron-down" /></span>}
            //         position="right"
            //     > 
            //     </LcDropDown>
            // }
        >
            <LcLoading loading={isLoadingJobs} loadingType='Helix'>
                {
                    scopeHC && scopeHC.length > 0 &&
                    <>
                        <div style={{ width: '100%', height: '25px', backgroundColor: '#e0ecfe', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ color: '#2E1956', fontSize: '10pt' }}>
                                A última atualização dos dados abaixo ocorreu em: {dateTimeSync.toLocaleString()}
                            </span>
                        </div>

                        <div  >
                            <TreeView
                                classes={classes}
                                aria-label="file system navigator"
                                defaultCollapseIcon={<i className="ppi ppi-chevron-down" />}
                                defaultExpandIcon={<i className="ppi ppi-chevron-right" />}
                                style={{ flexGrow: 1, overflowY: 'auto', fontWeight: 600, fontSize: 18 }}

                            >
                                {scopeHC.map((node, index) => (
                                    <BarTreeItem aria-expanded={true} nodeId={node.group}
                                        label={<>
                                            {Array.from(node.children).every(child => child && child["situacao"] == 'Sucesso') && <i style={{
                                                color: 'rgba(85, 163, 98, 1)',
                                                fontSize: '24px',
                                                position: 'relative',
                                                WebkitFontSmoothing: 'antialiased',
                                                MozOsxFontSmoothing: 'grayscale', textRendering: 'optimizeLegibility',
                                            }} className="ppi lci lci-dot-check" />}
                                            {!Array.from(node.children).every(child => child && child["situacao"] == 'Sucesso') && <i style={{
                                                color: 'rgba(205, 74, 69, 1)', fontSize: '24px', position: 'relative', WebkitFontSmoothing: 'antialiased',
                                                MozOsxFontSmoothing: 'grayscale', textRendering: 'optimizeLegibility',
                                            }} className="lci lci-dot-x" />}
                                            {node.group}</>} key={index}>
                                        {node.children && node.children.map((child, childIndex) => (
                                            <BarTreeItem nodeId={(node.groupId + child.serviceId)} label={<> {child.situacao == 'Sucesso' && <i style={{
                                                color: 'rgba(85, 163, 98, 1)', fontSize: '24px', position: 'relative', WebkitFontSmoothing: 'antialiased',
                                                MozOsxFontSmoothing: 'grayscale', textRendering: 'optimizeLegibility',
                                            }} className="ppi lci lci-dot-check" />} {child.situacao != 'Sucesso' && <i className="lci lci-dot-x" style={{
                                                color: 'rgba(205, 74, 69, 1)', fontSize: '24px', position: 'relative', WebkitFontSmoothing: 'antialiased',
                                                MozOsxFontSmoothing: 'grayscale', textRendering: 'optimizeLegibility',
                                            }} />} {child.service}</>} key={(node.groupId + child.serviceId + childIndex)}>
                                            </BarTreeItem>
                                        ))}
                                    </BarTreeItem>
                                ))}
                            </TreeView>
                        </div>
                    </>
                }
            </LcLoading>

        </Layout>
    );
};

export default HealthCheckActiveService;