import React from 'react';
import styles from './IcebreakerQuestions.module.css';

interface IcebreakerQuestionProps {
    question: string;
    onClick: (question: string) => void;
}

const IcebreakerQuestion: React.FC<IcebreakerQuestionProps> = ({ question, onClick }) => {
    return (
        <div className={styles.question} onClick={() => onClick(question)}>
            {question}
        </div>
    );
};

export default IcebreakerQuestion;
