import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useTheme } from '@mui/material/styles';

interface Step {
	content: any;
}

interface Props {
	/**
	 * Contents to be displayed on each step
	 */
	steps: Step[];
	/**
	 * If defined, automatically advances every X seconds
	 */
	autostep?: number;
	/**
	 * If set, hide the stepper buttons and make the dots clickable
	 */
	hideSteppers?: boolean;
	/**
	 * If set, adjusts the dots sizes and position in relation to the content
	 */
	dotsSize?: 'small' | 'medium' | 'large';
	dotsLeftMargin?: number | string;
	dotsRightMargin?: number | string;
}

const LcMobileStepper: React.FC<Props> = ({ steps, autostep, hideSteppers, dotsLeftMargin, dotsRightMargin, dotsSize }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = steps.length;
	const arrowSize = '1.5rem';
	const dotsSizeConfig = { small: 8, medium: 10, large: 12 };

	useEffect(() => {
		if (autostep) {
			const interval = setInterval(() => {
				setActiveStep((prevActiveStep) =>
					prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1
				);
			}, autostep * 1000);

			return () => clearInterval(interval);
		}
	}, [autostep, maxSteps]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => (prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1));
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1));
	};

	const handleDotClick = (step: number) => {
		setActiveStep(step);
	};

	return (
		<Box sx={{ width: '100%', position: 'relative', height: '100%' }}>
			<Box sx={{ height: 'calc(100% - 24px)', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
				{steps[activeStep].content && steps[activeStep].content !== null ? steps[activeStep].content : <></>}
			</Box>
			{
				hideSteppers ?
					<>
						<MobileStepper
							sx={{ display: 'none' }}
							steps={maxSteps}
							position="static"
							activeStep={activeStep}
							nextButton={<></>}
							backButton={<></>}
						/>
						<Box sx={{ display: 'flex', justifyContent: 'left', position: 'absolute', bottom: 8, left: dotsLeftMargin ?? 0, right: dotsRightMargin ?? 0 }}>
							{Array.from({ length: maxSteps }).map((_, index) => (
								<Box
									key={index}
									sx={{
										width: dotsSize ? dotsSizeConfig[dotsSize] : 8,
										height: dotsSize ? dotsSizeConfig[dotsSize] : 8,
										borderRadius: '50%',
										backgroundColor: activeStep === index ? "#2E1956" : "#9FA3FF",
										margin: '0 4px',
										cursor: 'pointer',
									}}
									onClick={() => handleDotClick(index)}
								/>
							))}
						</Box>
					</>
					:
					<MobileStepper
						variant="dots"
						steps={maxSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button size="small" onClick={handleNext}>
								{theme.direction === 'rtl' ? (
									<MdKeyboardArrowLeft size={arrowSize} />
								) : (
									<MdKeyboardArrowRight size={arrowSize} />
								)}
							</Button>
						}
						backButton={
							<Button size="small" onClick={handleBack}>
								{theme.direction === 'rtl' ? (
									<MdKeyboardArrowRight size={arrowSize} />
								) : (
									<MdKeyboardArrowLeft size={arrowSize} />
								)}
							</Button>
						}
					/>
			}
		</Box>
	);
};

export default LcMobileStepper;
