import Moment from 'moment';
import api, { APIClient } from "../api";

export class BackupSilverService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    GetCards(mesAno: string) {
        const url = this.endpoint + `/api/BackupSilver/GetSummaryData?selectedDate=${mesAno}`;
        return api({
            method: 'get',
            url,
        });
    }
    GetDailyChart(mesAno: string) {
        const url = this.endpoint + `/api/BackupSilver/GetDailyChartData?selectedDate=${mesAno}`;
        return api({
            method: 'get',
            url,
        });
    }
    GetMonthlyChart() {
        const url = this.endpoint + `/api/BackupSilver/GetMonthlyChartData`;
        return api({
            method: 'get',
            url,
        });
    }
    GetGroups(mesAno: string) {
        const url = this.endpoint + `/api/BackupSilver/GetGroupsData?selectedDate=${mesAno}`;
        return api({
            method: 'get',
            url,
        });
    }
    GetHistory(start: string, end: string) {
        const url = this.endpoint + `/api/BackupSilver/History?start=${start}&end=${end}`;
        return api({
            method: 'get',
            url,
        }).then((response: any) => {
            if (!(response && response.data)) {
                return;
            }
            let backupSilverHistory = response.data
                .sort((a, b) => { return a.startTime < b.startTime ? 1 : -1 })
                .map((Item: { activityId: any; duration: any; endTime: any; entityFriendlyName: any; startTime: any; status: any; vault: any; }, index: number) => {
                    return {
                        id: index,
                        status: Item.status,
                        startTime: Item.startTime,
                        endTime: Item.endTime,
                        entityFriendlyName: Item.entityFriendlyName,
                        vault: Item.vault
                    }
                });
            return backupSilverHistory;
        });
    }
}