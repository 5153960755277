import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LcDialog from '../../../components/Generic/LcDialog'
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { DisasterRecoveryService } from '../../../services/disasterRecovery/disasterRecoveryService';
import { Grid, LinearProgress, Button } from '@material-ui/core';
import { InputField, SelectField } from '../../../components/Form/Input';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useDebounce } from 'use-debounce/lib';

interface Disaster {
    idDisasterRecovery?: number,
    data?: string,
    acao?: string,
    responsavel?: string,
    status?: number,
    idCliente?: number,
    observacao?: string,
    isDeleted?: boolean,
    clientGroupId?: number,
    statusName?: string,
}

const PlanosDRHistorico: React.FC = (props) => {
    const [plans, setPlans] = useState<any[]>([]);
    const [filteredPlans, setFilteredPlans] = useState<any[]>([]);
    const [visiblePlans, setVisiblePlans] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const [selected, setSelected] = useState<number>(0);
    const [formText, setFormText] = useState<string>("");
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [disaster, setDisaster] = useState<Disaster | undefined>();
    const [actualPage, setActualPage] = useState<number>(0);
    const [isLoading, seiIsLoading] = useState<boolean>(true);
    const [ValueSearch, setValueSearch] = React.useState<string>('');
    const [ValueSearchDebounce] = useDebounce(ValueSearch, 500);
    const user = useSelector<RootState, UserState>(state => state.user);

    useEffect(() => {
        seiIsLoading(true);
        setFilteredPlans([])
        setPlans([]);

        if (user.ClientGroupSelected) {
            const disasterRecoveryService = new DisasterRecoveryService(props);
            disasterRecoveryService.GetPlansExceptByStatusName('Disponível')
                .then((plans: any) => {
                    let mappedArray: any[] = [];
                    plans.map((_e: any) => {
                        let fullDate = _e.data.toString().split('-')
                        let formatedDate = `${fullDate[2]}/${fullDate[1]}/${fullDate[0]}`

                        mappedArray.push(
                            {
                                acao: _e.acao,
                                data: formatedDate,
                                id: _e.id,
                                idDisasterRecovery: _e.idDisasterRecovery,
                                responsavel: _e.responsavel,
                                statusName: _e.statusName
                            }
                        )
                    })

                    setFilteredPlans(mappedArray)
                    setPlans(mappedArray);
                    setVisiblePlans(mappedArray);
                    seiIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    // useEffect(() => {
    //     if (plans.length > 0) {
    //         let localRecords = plans;
    //         let arrayFiltered: any[] = [];
    //         (plans.length > 0 ? plans : localRecords).filter(item => item.data.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
    //                                                                                                 || item.acao.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
    //                                                                                                 ||  item.responsavel.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
    //                                                                                                 || item.statusName.toLowerCase().includes(ValueSearchDebounce.toLowerCase())).map(plan => {
    //             arrayFiltered.push(plan);
    //         })
    //         setFilteredPlans(arrayFiltered);
    //     }
    // }, [ValueSearchDebounce]);

    useEffect(() => {
        var filtered = plans.filter((p: any) =>
            filter.term == '' ||
            p.acao.toLowerCase().indexOf(filter.term) > -1 ||
            p.responsavel.toLowerCase().indexOf(filter.term) > -1 ||
            p.statusName.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'desc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredPlans(filtered);
        setVisiblePlans(filtered.slice(0, filter.size));
    }, [filter, filteredPlans.length > 0]);

    const filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    }

    const columns = [
        { field: 'data', headerName: 'Data', width: "15%" },
        { field: 'acao', headerName: 'Ação', width: "35%" },
        { field: 'responsavel', headerName: 'Responsável', width: "35%" },
        { field: 'statusName', headerName: 'Status', width: "15%" },

        // {
        //     field: '',
        //     headerName: '',
        //     sortable: false,
        //     width: 70,
        //     renderCell: (params: any) => 
        //     (
        //         <LcDropDown element={<LcIconLink icon="lci lci-dots-vertical" />} position="right" top={params.row.id as number > (4 + (actualPage * 10)) }>
        //             <span className="item" onClick={() => handleEdit(params.row as any)}>Editar</span>
        //             <hr />
        //             <span className="item" onClick={() => showDialog(params.row.idDisasterRecovery)}>Excluir</span>
        //         </LcDropDown>
        //     ),
        // }
    ];

    function downloadFile() {
        const disasterRecoveryService = new DisasterRecoveryService(props);
        const FileDownload = require("js-file-download");
        disasterRecoveryService.DownloadFile().then(response => { FileDownload(response.data, "disaster_recovery_plan.pdf") }).catch(error => { console.log(error) });
    };

    function handleEdit(item: Disaster) {
        let aux: Disaster = {
            idDisasterRecovery: item.idDisasterRecovery,
            data: item.data,
            acao: item.acao,
            responsavel: item.responsavel,
            statusName: item.statusName,
        };
        setFormText("Editar");
        setDisaster(aux);
        setModalVisible(true);
    };

    function handleCreate() {
        let aux: Disaster = {};
        setFormText("Criar");
        setDisaster(aux);
        setModalVisible(!modalVisible);
    };

    function handleChange(event: any) {
        const { name, value } = event;
        let _disaster: any = disaster;
        _disaster[name] = value;
        setDisaster(_disaster);
    };

    function handleChangeSelect(event: any) {
        const { value } = event;
        let _disaster: any = disaster;
        _disaster['status'] = value;
        setDisaster(_disaster);
    };

    function deleteDR(id: number) {
        const disasterRecoveryService = new DisasterRecoveryService(props);
        disasterRecoveryService.DeleteDR(id)
            .then(response => {
                setDialogVisible(false);
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
                window.location.reload();
            });
    };

    function disagreeFunction() {
        setDialogVisible(false);
    };

    function showDialog(id: number) {
        setSelected(id);
        setDialogVisible(true);
    };

    const statusOptions = [
        { name: "Disponivel", value: '1' },
        { name: "Sucesso", value: '2' },
        { name: "Em Andamento", value: '3' },
        { name: "Agendado", value: '4' },
        { name: "Falha", value: '5' },
        { name: "Cancelado", value: '6' }
    ];

    function editDR() {
        let aux: Disaster = {
            idDisasterRecovery: disaster && disaster.idDisasterRecovery,
            data: disaster && disaster.data,
            acao: disaster && disaster.acao,
            responsavel: disaster && disaster.responsavel,
            statusName: disaster && disaster.statusName,
            status: disaster && disaster.status,
            clientGroupId: 492,
        };

        const disasterRecoveryService = new DisasterRecoveryService(props);
        disasterRecoveryService.EditDR(aux)
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    };

    function createDR() {
        let aux: Disaster = {
            idDisasterRecovery: disaster && disaster.idDisasterRecovery,
            data: disaster && disaster.data,
            acao: disaster && disaster.acao,
            responsavel: disaster && disaster.responsavel,
            statusName: disaster && disaster.statusName,
            status: disaster && disaster.status,
            clientGroupId: 492,
        };
        const disasterRecoveryService = new DisasterRecoveryService(props);
        disasterRecoveryService.CreateDR(aux)
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    };

    const functions = [
        // {
        //     title: 'Dowload DR',
        //     icon: 'lci lci-download',
        //     func: (ids:any) => {
        //         downloadFile()
        //     }
        // },
        // {
        //     title: 'Criar',
        //     icon: 'ppi ppi-plus',
        //     func: (ids:any) => {
        //         handleCreate()
        //     }
        // }
    ]

    const handlePageChange = (pageData: any) => {
        setActualPage(pageData.page);
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisiblePlans(filteredPlans.slice(0, size))
        else setVisiblePlans([...visiblePlans, ...filteredPlans.slice(visiblePlans.length, visiblePlans.length + 10)])
    };

    return (
        <Layout pageTitle="Disaster Recovery"  >
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <LcInfiniteTable
                        loading={isLoading}
                        columns={columns}
                        rows={visiblePlans}
                        filter={filterAdvanced}
                        size={filteredPlans.length}
                        functionGeneric={functions}
                        loadMore={loadMore}
                        onSortChange={onSortChange} />
                    {/* <LcDialog title="Confirmar exclusão?" visible={dialogVisible} agreeFunction={() => deleteDR(selected)} disagreeFunction={disagreeFunction}></LcDialog> */}
                </Grid>
            </Grid>
            {modalVisible &&
                <SideModal visible={modalVisible} header={formText} onClose={() => { setDisaster(undefined); setModalVisible(false); }}>
                    <InputField name="responsavel" value={disaster?.responsavel} type="text" label="Responsável" onChange={handleChange}> </InputField>
                    <InputField name="data" value={disaster?.data} type="date" label="Data" onChange={handleChange}> </InputField>
                    <InputField name="acao" value={disaster?.acao} type="text" label="Ação" onChange={handleChange}> </InputField>
                    <SelectField name="status" label="Status" options={statusOptions} onChange={handleChangeSelect}></SelectField>
                    <Grid container direction="column" justify="flex-end" alignItems="flex-end" style={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained" color="primary" onClick={formText == "Editar" ? editDR : createDR} style={{ marginRight: '20px' }}>
                                {formText}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => { setModalVisible(false); }}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </SideModal>
            }
        </Layout>
    );
};

export default PlanosDRHistorico;