import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { EndpointSecurityService } from '../../../services/eSecurity/endpointSecurityService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import history from '../../../history';
import SideModal from '../../../components/Layout/SideModal/SideModal';

const EndpointSecurityResumo: React.FC = (props) => {
    const [cards, setCards] = useState<any>();
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingCards, setIsLoadingCards] = useState(true);

    const [modalDispositivosVisible, setModalDispositivosVisible] = useState<boolean>(false);
    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
    const [groups, setGroups] = useState<string[]>([]);

    const user = useSelector<RootState, UserState>(state => state.user);

    useEffect(() => {
        setIsLoadingCards(true);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const searchDate = [year, month].join('');
            const endpointSecurityService = new EndpointSecurityService(props);
            endpointSecurityService.GetCards(searchDate)
                .then(response => {
                    if (response.data) {
                        let cards = response.data;
                        setCards(cards);
                        setIsLoadingCards(false);
                    } else {
                        setCards([])
                        setIsLoadingCards(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setCards([]);
                    setIsLoadingCards(false);
                });
        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const showGroupDevice = () => {
        setModalDispositivosVisible(true);
        setLoadingGroups(true);
        const endpointSecurityService = new EndpointSecurityService(props);
        endpointSecurityService.GetDevices()
            .then(response => {
                let distinct: string[] = [];
                response &&
                    response.forEach((x: any) => {
                        if (distinct.indexOf(x.groupName) == -1)
                            distinct.push(x.groupName)
                    })
                setGroups(distinct.sort());
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                setLoadingGroups(false);
            });
    }

    return (
        <Layout pageTitle="Endpoint security" periodSelection={periodSelection}  >
            <LcLoading label="Carregando cards..." loading={isLoadingCards}>
                {
                    (cards != undefined || cards != null) && (
                        <LCFlexContainer direction="row" justify="center" alignItems="center" spacing={3}>
                            <LCFlexItem xs={12} sm={12} md={12} lg={3}>
                                <IconValueCardRow onClick={() => { history.push("/EndpointSecurity/Assinaturas") }} title="Subscrições alocadas" icon="lci lci-collection" value={cards.totalDevices || 0} color="#E273C5"></IconValueCardRow>
                            </LCFlexItem>
                            <LCFlexItem xs={12} sm={12} md={12} lg={3}>
                                <IconValueCardRow onClick={cards.totalEvents && cards.totalEvents > 0 ? () => { cards.totalEvents && cards.totalEvents > 0 && showGroupDevice() } : undefined} title="Grupos de dispositivos" icon="lci lci-server" value={cards.totalDeviceGroups || 0} color="#8684EF"></IconValueCardRow>
                            </LCFlexItem>
                            <LCFlexItem xs={12} sm={12} md={12} lg={3}>
                                <IconValueCardRow title="Eventos" onClick={() => { history.push("/EndpointSecurity/Tipos") }} icon="lci lci-database" value={cards.totalEvents || 0} color="#F3C944"></IconValueCardRow>
                            </LCFlexItem>
                            <LCFlexItem xs={12} sm={12} md={12} lg={3}>
                                <IconValueCardRow onClick={() => { history.push("/EndpointSecurity/Assinaturas") }} title="Subscrições contratadas" icon="lci lci-briefcase" value={cards.totalDevicesContracted || 0} color="#F28B40"></IconValueCardRow>
                            </LCFlexItem>
                        </LCFlexContainer>
                    )
                }
            </LcLoading>

            <SideModal header="Grupos de dispositivos" onClose={() => { setModalDispositivosVisible(false) }} visible={modalDispositivosVisible}>
                <LcLoading loading={loadingGroups} label="Carregando grupos">
                    {
                        groups.length > 0 ?
                            groups.map((g, i) => {
                                return <div className="text-secondary font-lg" key={i}>
                                    <i className="lci lci-cube lci-1x text-primary" /> {g}
                                </div>
                            })
                            :
                            <div className="centralized">
                                <i className="lci lci-exclamation text-warning" />
                                Nenhum grupo de dispositivos encontrado
                            </div>
                    }
                </LcLoading>
            </SideModal>


        </Layout>
    );
};

export default EndpointSecurityResumo;