import React, { useEffect, useState } from 'react';  // Substitua pelo caminho correto para o componente de tabela dinâmica
import PivotTableWebix from '../../../../components/webix';
import LiveWatchInsightService from '../../../../services/LiveWatchInsightService/LiveWatchInsightService';
import LcLoading from '../../../../components/Generic/LcLoading';

interface PivotLwData {
    hostgroup: string;
    host: string;
    "item.name": string;
    "item.lastvalue": string;
    "item.units": string;
}

interface DataPivotProps {
    hostId: Number;
}

const HostCardPivot: React.FC<DataPivotProps> = ({ hostId }) => {
    const [dataPivotLW, setDataPivotLW] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                // Assuming groupId is known and fixed for the example
                const groupId = 1; // Replace with actual groupId if needed
                const items = await LiveWatchInsightService.getPivot(groupId);
                setDataPivotLW(items);
            } catch (err) {
                setError('Failed to load data');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [hostId]);

    return (
        <LcLoading loading={loading}>
            <PivotTableWebix
                styleVision={{ position: 'fixed', top: '90px' }}
                idView={'dataPivot'}
                key={'dataPivot'}
                data={dataPivotLW}
                style={{ marginTop: '-10px' }}
                fields={[
                    { id: 'hostgroup', value: "Grupo de Hosts", type: "text" },
                    { id: 'host', value: "Host", type: "text" },
                    { id: 'item.name', value: "Nome do Item", type: "text" },
                    { id: 'item.lastvalue', value: "Último Valor do Item", type: "text" },
                    { id: 'item.units', value: "Unidades do Item", type: "text" }
                ]}
                structure={{
                    rows: ["hostgroup", "host", 'item.name', 'item.lastvalue', 'item.units']
                }}
            />
        </LcLoading>
    );
};

export default HostCardPivot;
