import React, { useEffect, useState } from 'react';

import { PPInput } from 'processor-plataform-ui';
import { FinOpsService } from '../../../services/finOps/finOpsService';
import { CostCenterService } from '../../../services/costCenter/costCenterService';

import { filterDecimalInput, formatDecimal } from '../../../services/currency';
import moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';

interface budgetPerMonthProps {
    clientGroupId?: number;
    ccId: number;
    onChange: any;
}

const BudgetPerMonth: React.FC<budgetPerMonthProps> = (props) => {
    const { clientGroupId, ccId, onChange } = props;

    const service = new CostCenterService(props);
    const finOpsService = new FinOpsService(props);

    const [monthBudgetFull, setMonthBudgetFull] = useState<{ month: string; value: number }[]>([]);
    const [loading, setLoading] = useState(false);

    const convertValue = (value: any) => {
        return value ? parseFloat(value).toFixed(2) : null;
    };

    useEffect(() => {
        const getDataWithBudget = async () => {
            try {
                setLoading(true);
                const [treeDataWithBudget, budgetUsageByGroup] = await Promise.all([
                    service.GetTreeDataWithBudget(),
                    finOpsService.LoadBudgetvsUsagebyGroupFull(clientGroupId)
                ]);
    
                const months = [...new Set(budgetUsageByGroup.map(b => b.parent.date.substring(0, 7)))].sort();
                const mounted: any[] = [];
                const mountedAux: any[] = [];
    
                for (let i = Math.max(0, months.length - 12); i < months.length; i++) {
                    const m = months[i];
                    const monthBudgetData = treeDataWithBudget?.data.find(mb => mb.id === ccId)?.monthBudget;
                    const monthValue = monthBudgetData?.find(mb => mb.month === m)?.value;
                    const previousMonth = months[i - 1];
                    const defaultValue = mounted.find(mb => mb.month === previousMonth)?.value || 0;
    
                    const monthObj = {
                        month: m,
                        value: convertValue(monthValue),
                        costCenterId: monthBudgetData?.[0]?.costCenterId
                    };
    
                    mountedAux.push(monthObj);
                    mounted.push({ month: m, value: convertValue(monthValue || defaultValue) });
                }
    
                setMonthBudgetFull(mounted);
                CheckAndUpdatedEmptyBudget(mounted, mountedAux);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
    
        getDataWithBudget();
    }, [clientGroupId, ccId]);
    

    const CheckAndUpdatedEmptyBudget = async (mounted, monthsBudget) => {
        monthsBudget.filter(element => element.value === null)
        .map(async upt => {
            const budget = mounted.find(mount => mount.month === upt.month);
            if (budget.value) {
                await service.CreateOrUpdateBudget(monthsBudget[0].costCenterId, parseFloat(budget.value), budget.month);
            }
        });
    }

    useEffect(() => {
        onChange(monthBudgetFull);
    }, [monthBudgetFull]);

    return (
        <> {loading ? (
                <LcLoading loading={loading} label="Carregando..." />
            ) : (
                monthBudgetFull.map((m, i) => {
                    return (
                        <div key={i} className="row space-between">
                            <span className="text-capitalize">
                                {moment(m.month.replace('-', '') + '01', 'YYYYMMDD').format('MMM[/]YY')}
                            </span>
                            <PPInput
                                type="text"
                                decoration="R$ "
                                value={m.value ? formatDecimal(m.value) : '0.00'}
                                name={m.month}
                                onChange={(event) => {
                                    let { value, name } = event.target;
                                    setMonthBudgetFull(
                                        monthBudgetFull.map((mbf) => {
                                            return mbf.month == name
                                                ? { ...mbf, value: !value ? null : filterDecimalInput(value) as any }
                                                : mbf;
                                        })
                                    );
                                }}
                                width="calc(100% - 75px)"
                            />
                        </div>
                    );
                })
            )}
        </>  
    );
};

export default BudgetPerMonth;
