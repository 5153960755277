export class saasbasic {
    solution() {
        return 'saasbasic'
    }

    site() {
        return 'https://www.processor.com.br/saas'
    }

    logotipo() {
        return require('./assets/logotipos/logo_saasbasic.svg').default
    }
    titulo() {
        return <>Descubra o <b>poder da nuvem</b> com SaaS Basic</>
    }

    chamada() {
        return <>Com SaaS Basic, a organização obtém acesso a uma gama de recursos e ativos tecnológicos de softwares fornecidos como serviço para tecnologias Microsoft e outras tecnologias relevantes de mercado.</>
    }

    image() {
        return require('./assets/imgs/imagem_saasbasic.png').default
    }

    detalhes() {
        return <>
            <h2>Pronto para levar a organização ao próximo nível?</h2>
            <p><b>SaaS Basic é a solução de entrada perfeita para organizações que desejam utilizar software como serviço e ter acesso a histórico de compras, notas fiscais, custos e informações básicas sobre o objeto contratado.</b></p>
            <h3>Por que escolher SaaS Basic?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_saasbasic.png').default} />
                </div>
                <ul>
                    <li><b>Flexibilidade:</b> Ativação rápida e flexível com softwares sempre atualizados.</li>
                    <li><b>Economia:</b> Pague apenas pelo que usa, sem a necessidade de propriedade do software.</li>
                    <li><b>Produtividade:</b> Reduza a dependência de conhecimentos tecnológicos especializados.</li>
                    <li><b>Acesso remoto e mobilidade:</b> Acesse seus aplicativos e dados de qualquer lugar, a qualquer momento.</li>
                    <li><b>Otimização de custos:</b> Otimize os custos iniciais e pague apenas pelo que usar.</li>
                    <li><b>Manutenção e atualizações automáticas:</b> Sempre tenha acesso à versão mais recente do software.</li>
                </ul>
            </div>
            {/* <div className="box">SaaS Basic é mais do que apenas uma solução de software como serviço. É uma maneira de <b>impulsionar a eficiência operacional</b>, melhorar a colaboração e levar a organização para o próximo nível.</div> */}
            <h3>Não perca tempo!</h3>
            <p>A era digital está aqui e a nuvem é o futuro. Não fique para trás. Descubra como SaaS Basic pode transformar a organização. Entre em contato conosco hoje mesmo para saber mais.</p>
        </>
    }
}
export default saasbasic;