import React, { useEffect, useState, ReactNode } from 'react';

import './index.css';

interface Props {
    disabled?: boolean,
    expanded?: boolean,
    title?: ReactNode,
    titleRight?: ReactNode,
    marginBotton?: "5" | "10" | "15",
    hover?: boolean
    onExpand?: Function
}

const PPExpand: React.FC<Props> = (props) => {

    const [expanded, setExpanded] = useState(props.expanded);

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded])

    useEffect(() => {
        props.onExpand && expanded &&
            props.onExpand();
    }, [expanded])

    const existChildren = props.children!.toString().length > 0;

    return (
        <div className={`pp-expand${expanded ? ' expanded' : ''}${props.marginBotton ? ` mb-${props.marginBotton}` : ''}`}>
            {
                props.title &&
                <div className={`header${props.titleRight || props.hover ? " hover" : ""}`} onClick={(event: any) => { existChildren && setExpanded(!expanded) }}>
                    <div className={`title${existChildren ? "" : " disabled"}`}  style={{color: props.disabled ? '#2E1956ad' : '', padding: props.hover ? '5px' : '' }}>
                        {
                            existChildren
                                ?
                                <i className='ppi ppi-chevron-up' />
                                :
                                <span style={{ marginLeft: '25px' }}>&nbsp;</span>
                        }
                        <span>{props.title}</span>
                    </div>
                    {
                        props.titleRight &&
                        <div style={{color: props.disabled ? '#2E1956ad' : '' }} className='functions'>{props.titleRight}</div>
                    }
                </div>
            }

            <div className={`body${expanded ? ' expanded' : ''}`}>
                <div className={`content`} style={{ marginLeft: props.title ? "28px" : "0" }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default PPExpand;