import { Grid, makeStyles } from '@material-ui/core';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

//Redux
import { useSelector } from 'react-redux';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { TreeItem, TreeView } from '@material-ui/lab';
import UserService from '../../../services/userService';
import { green } from '@material-ui/core/colors';
import HomeService from '../../../services/homeService';
import PivotTableWebix from '../../../components/webix';

const ViewGTMContratoLiveCloudMetricaService: React.FC = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            color: '#3c4146',
            '& .MuiTreeItem-root': {
                '&:hover > .MuiTreeItem-content': {
                    backgroundColor: '#f5f5f5',
                },
                '&:focus > .MuiTreeItem-content': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiTreeItem-content': {
                padding: theme.spacing(1),
                '& .MuiTreeItem-label': {
                    fontWeight: 'inherit',
                    color: 'inherit',
                },
            },
            '& .MuiTreeItem-group': {
                marginLeft: 15,
                '& .MuiTreeItem-content': {
                    paddingLeft: 20,
                },
            },
        },
        successIcon: {
            color: 'green',
        },
    }));
    const classes = useStyles();
    const homeService = new HomeService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [jobs, setJobs] = useState<any[]>([]);
    const [scopeHC, setScopeHC] = useState<any[]>([]);
    const [eventsDetails, setEventsDetails] = useState<any[]>([]);
    const [rows, setRows] = useState<string[]>(['Group Name', 'Storage Policy', 'Instance', 'Jobid']);//
    const [cols, setCols] = useState<string[]>([]);//
    const [servers, setServers] = useState<any[]>([]);
    const [agentTypes, setAgentTypes] = useState<any[]>([]);
    const [backupTypes, setBackupTypes] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modeView, setModeView] = useState<string>("Tabela");
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>({} as Array<{ [K: string]: string }>);

    const [dateTimeSync, setDateTimeSync] = useState<any[]>([]);
    async function retrieveDataFromBackupDynamo() {
    
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));
        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            setIsLoadingJobs(true); 

            var responseRer = (await homeService.GetViewGTMContratoLiveCloudMetrica());
            let jobsResponseRer = responseRer ? responseRer : [];
            setDataPivot(jobsResponseRer);
            setIsLoadingJobs(false); 

        } catch (error) {
            console.log(error);
        };
    }

    useEffect(() => {
        retrieveDataFromBackupDynamo();
    }, [month, user.ClientGroupSelected, year, user.refreshFlag, refresh]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
        ],
        customPeriod: {
            label: 'Filtro',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'date',
        onChange: onChagePeriod
    };

    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([
        { field: 'Situação', filter: true },
        { field: 'Agente', filter: true },
        { field: 'SubCliente', filter: true },
        { field: 'Tipo', filter: true }
    ]);

    return (
        <Layout pivot
        > 
            <LcLoading label="Carregando..." loading={isLoadingJobs}>
                {
                    dataPivot && dataPivot.length > 0 &&
                    <>
                        <div style={{ width: '100%', height: '25px', backgroundColor: '#e0ecfe', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ color: 'var(--text-default)', fontSize: '10pt' }}>
                                Abaixo é apresentado o detalhamento de todos os jobs executados no mês
                            </span>
                        </div>
                        <div  >
                            {dataPivot && dataPivot.length > 0 &&
                                <PivotTableWebix
                                    idView={'dataPivot'}
                                    key={'dataPivot'} data={dataPivot}
                                    fields={[
                                        { "id": "contrato", "value": "Contract Number", "type": "number" },
                                        { "id": "contrato_descricao", "value": "Contract Description", "type": "text" },
                                        { "id": "situacao_contrato", "value": "Contract Status", "type": "text" },
                                        { "id": "valor_contrato", "value": "Contract Value", "type": "number" },
                                        { "id": "unidade_medida", "value": "Measurement Unit", "type": "number" },
                                        { "id": "codigo_cliente", "value": "Customer Code", "type": "number" },
                                        { "id": "cnpj", "value": "CNPJ", "type": "text" },
                                        { "id": "codigo_grupo_cliente", "value": "Customer Group Code", "type": "number" },
                                        { "id": "grupo_cliente_descricao", "value": "Customer Group Description", "type": "text" },
                                        { "id": "gtm_codigo", "value": "GTM Code", "type": "number" },
                                        { "id": "gtm_nome", "value": "GTM Name", "type": "text" },
                                        { "id": "gtm_status", "value": "GTM Status", "type": "text" },
                                        { "id": "gtmitem_codigo", "value": "GTM Item Code", "type": "number" },
                                        { "id": "gtmitem_nome", "value": "GTM Item Name", "type": "text" },
                                        { "id": "gtmitem_status", "value": "GTM Item Status", "type": "text" },
                                        { "id": "gtm_metrica_codigo", "value": "GTM Metric Code", "type": "number" },
                                        { "id": "gtm_metrica_nome", "value": "GTM Metric Name", "type": "text" },
                                        { "id": "gtm_metrica_status", "value": "GTM Metric Status", "type": "text" },
                                        { "id": "data_criacao_metrica", "value": "Metric Creation Date", "type": "text" },
                                        { "id": "cliente_razao_social", "value": "Client Legal Name", "type": "text" },
                                        { "id": "ven_Codigo_Vendedor", "value": "Seller Code", "type": "number" },
                                        { "id": "groupSid", "value": "Group SID", "type": "text" },
                                        { "id": "tileId", "value": "Tile ID", "type": "number" }
                                    ]}
                                    structure={{
                                        rows: ["contrato", "gtm_nome", "gtm_codigo", "gtmitem_nome", "gtmitem_codigo", "gtm_metrica_nome", "gtm_metrica_codigo"],
                                        values: [{ name: "gtmitem_status", operation: ["count"] }]
                                    }}
                                />}

                        </div>
                    </>
                }
            </LcLoading>

        </Layout>
    );
};

export default ViewGTMContratoLiveCloudMetricaService;