import React, { useEffect, useState } from 'react';

import './index.css';

type Position = "left" | "center" | "right" | "top" | 'fullright';
type Trigger = "click" | "hover";

interface Props {
    content?: React.ReactNode,
    position?: Position,
    top?: boolean,
    trigger?: Trigger,
    delay?: number,
    width?: string,
    height?: string
}

const LcTooltip: React.FC<Props> = (props) => {

    const [visible, setVisible] = useState(false);
    const [tooltipWidth, setTooltipWidth] = useState(0);

    var timer;
    const overEvent = () => {
        if (props.delay) {
            clearTimeout(timer);
            timer = setTimeout(() => setVisible(true), props.delay);
        } else
            setVisible(true);
    }

    const onLeave = () => {
        clearTimeout(timer);
        setVisible(false);
    }

    const onScroll = () => {
        setVisible(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll, true);
        return () => {
            window.removeEventListener('scroll', onScroll, true);
        }
    }, [])

    return (
        <div
            className={`lc-tooltip${props.top ? ' top' : ''}`}
            ref={(event: any) => { event && event.offsetWidth && setTooltipWidth(event.offsetWidth) }}
            onClick={() => (!props.trigger || props.trigger === 'click') && setVisible(!visible)}
            onMouseOver={() => props.trigger === 'hover' && overEvent()}
            onMouseOut={() => onLeave()}
            onMouseLeave={() => onLeave()}
            style={{ width: props.width, height: props.height }}>
            {
                !props.top &&
                props.children
            }
            {
                visible && props.content &&
                <div className={`body ${props.position || ''}`} style={{ width: tooltipWidth + 'px' }}>
                    <span className="arrow" />
                    <div className="content">
                        {props.content}
                    </div>
                </div>
            }
            {
                props.top &&
                props.children
            }
        </div>
    );
}

export default LcTooltip;