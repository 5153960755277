import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { Bar } from 'react-chartjs-2';
import { BackupSilverService } from '../../../services/backupSilver/backupSilverService';
import { Grid } from '@material-ui/core';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import "./index.css";
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { RepeatOneSharp } from '@material-ui/icons';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import BarChart from '../../../components/Data/Dashboard/BarChart';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiArchiveBoxLight, PiArchiveLight, PiDatabaseLight } from 'react-icons/pi';
/*import { Card, PositionCard } from '../../../contexts/CardContext';*/

function getReferenceDate(data: string) {
    let lastUpdatedDate: string = data;
    let date = new Date(lastUpdatedDate);
    return date.toLocaleString();
}
interface BackupSilverResumoProps {
    isHome?: boolean,
    qtdGroups?: number
}

const BackupSilverResumo: React.FC<BackupSilverResumoProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [summaryData, setSummaryData] = useState<any>();
    const [cardNovos, setCardsNovos] = useState<Card[]>([{
        id: 1,
        type: 'Custom',
        bgColor: props.isHome ? '#F1F5FB' : undefined,
        hideHeader: props.isHome,
        position: {
            ColSpan: 6,
            RowSpan: 1, row: 1, col: 1
        },
        title: () => {
            return <>{
                <div className="lc-title ellipsis">Maior volume</div>
            }</>
        },
        customContentRender: () => {
            return <IconValue isHome={props.isHome} maximumFractionDigits={2} minimumFractionDigits={2} description={props.isHome ? "Maior volume" : undefined} formatValue={true} title="Maior volume" tooltip="Maior volume do período" icon="lci lci-database" value={summaryData && summaryData.ConsumedStorage ? summaryData.ConsumedStorage : 0} unity="TB" color="#E273C5">
            </IconValue >
        }
    },
    {
        type: 'Custom',
        id: 2,
        position: { ColSpan: 6, RowSpan: 1, row: 1, col: 7 },
        title: () => {
            return <>{
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title'} style={{ fontSize: '20px' }}>
                        Espaço contratado
                    </div>
                </div>
            }</>
        },
        customContentRender: () => {
            return <IconValue maximumFractionDigits={1} formatValue={true} title="Espaço contratado" icon="lci lci-archive" value={summaryData && summaryData.ProvisionedStorage ? summaryData.ProvisionedStorage : 0} unity="TB" color="#8684EF">
            </IconValue >
        }
    },
    {
        type: 'Custom',
        id: 3,
        position: {
            ColSpan: 4,
            RowSpan: 2, row: 2, col: 1
        },
        title: () => {
            return <>{
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title'} style={{ fontSize: '20px' }}>
                        Volume corrente em TB
                    </div>
                </div>
            }</>
        },
        customContentRender: () => {
            return <LcLoading loading={isLoadingGroupChart}>
                {groupsChart && !errorGroupChart ? <DoughNutEChart ArrayData={groupsChart}></DoughNutEChart>
                    :
                    <LcNoData />
                }
            </LcLoading>
        }
    },
    {
        type: 'Custom',
        id: 4,
        position: {
            ColSpan: 4,
            RowSpan: 2, row: 2, col: 1
        },
        title: () => {
            return <>{
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title'} style={{ fontSize: '20px' }}>
                        Volume corrente em TB
                    </div>
                </div>
            }</>
        },
        customContentRender: () => {
            return <LcLoading loading={isLoadingGroupChart}>
                {groupsChart && !errorGroupChart ? <DoughNutEChart ArrayData={groupsChart}></DoughNutEChart>
                    :
                    <LcNoData />
                }
            </LcLoading>
        }
    },
    {
        type: 'Custom',
        id: 5,
        position: {
            ColSpan: 4,
            RowSpan: 2, row: 2, col: 5
        },
        showFocusButton: !props.isHome,
        title: () => {
            return <>{
                <div className="lc-title ellipsis">Consumo diário</div>
            }</>
        },
        customContentRender: () => {
            return <LcLoading loading={isLoadingGroupChart}>
                {
                    dailyChart && !errorDailyChart ?
                        <BarChart data={dailyChart} showline={true}></BarChart>
                        :
                        <LcNoData />
                }
            </LcLoading>
        }
    },
    {
        type: 'Custom',
        id: 6,
        position: {
            ColSpan: 4,
            RowSpan: 2, row: 2, col: 9
        },
        title: () => {
            return <>{
                <div className="lc-title ellipsis">Maior volume</div>
            }</>
        },
        customContentRender: () => {
            return <LcLoading loading={isLoadingGroupChart}>
                {
                    monthlyChart && !errorMonthlyChart ?
                        <BarChart data={monthlyChart} showline={true}></BarChart>
                        :
                        <LcNoData />
                }
            </LcLoading>
        }
    },
    {
        type: 'Custom',
        id: 7,
        position: {
            ColSpan: 11,
            RowSpan: 1, row: 4, col: 1
        },
        title: () => {
            return <>{
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title'} style={{ fontSize: '20px' }}>

                    </div>
                </div>
            }</>
        },
        customContentRender: () => {
            return <LcInfiniteTable
                loading={isLoadingTable}
                columns={columns}
                rows={groups}
                size={groups.length}
                loadMore={loadMore}
                onSortChange={onSortChange} />
        }
    }]);
    const [groups, setGroups] = useState<any[]>([]);
    const [groupsChart, setGroupsChart] = useState<any>();

    const [dailyChart, setDailyChart] = useState<any>();
    const dailyChartOptions: any = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }],
        }
    };

    const [monthlyChart, setMonthlyChart] = useState<any>();
    const monthlyChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                { ticks: { beginAtZero: true } }
            ]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {

                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        }
    };

    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [errorCards, setErrorCards] = useState(false);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isLoadingGroupChart, setIsLoadingGroupChart] = useState(true);
    const [errorGroupChart, setErrorGroupChart] = useState(false);
    const [isLoadingDailyChart, setIsLoadingDailyChart] = useState(true);
    const [errorDailyChart, setErrorDailyChart] = useState(false);

    const [isLoadingMonthlyChart, setIsLoadingMonthlyChart] = useState(true);
    const [errorMonthlyChart, setErrorMonthlyChart] = useState(false);
    const [referenceDate, setReferenceDate] = useState<string>();

    useEffect(() => {
        setIsLoadingCards(true);
        setIsLoadingTable(true);
        setIsLoadingGroupChart(true);
        setIsLoadingDailyChart(true);
        setIsLoadingMonthlyChart(true);

        const selectedDate = [month, year].join('');
        const backupSilverService = new BackupSilverService(props);

        Promise.all([
            backupSilverService.GetCards(selectedDate),
            backupSilverService.GetDailyChart(selectedDate),
            backupSilverService.GetMonthlyChart(),
            backupSilverService.GetGroups(selectedDate)
        ])
            .then(([cardsResponse, dailyChartResponse, monthlyChartResponse, groupsResponse]) => {
                if (!(cardsResponse && cardsResponse.data)) {
                    setIsLoadingCards(false);
                    setErrorCards(true);
                    return;
                }
                if (cardsResponse.data && cardsResponse.data.length > 0) {
                    let summaryData = {
                        ProvisionedStorage: cardsResponse.data[0].provisionedStorage,
                        ConsumedStorage: cardsResponse.data[0].consumedStorage + Number.EPSILON
                    };
                    setSummaryData(summaryData);
                    setReferenceDate(getReferenceDate(cardsResponse.data[0].referenceDate));
                    setErrorCards(false);
                } else {
                    let summaryData = {
                        ProvisionedStorage: 0,
                        ConsumedStorage: 0
                    };
                    setSummaryData(summaryData);
                    setReferenceDate(new Date().toLocaleString());
                    setErrorCards(true);
                }

                if (!(dailyChartResponse && dailyChartResponse.data) || dailyChartResponse.data.chartValues.length == 0) {
                    setErrorDailyChart(true);
                    setIsLoadingDailyChart(false);
                    return;
                }

                let dailyChart = {
                    labels: dailyChartResponse.data.chartLabels ? dailyChartResponse.data.chartLabels : '',
                    datasets: [
                        {
                            type: 'line',
                            label: "Consumo diário",
                            data: dailyChartResponse.data.chartValues,
                            borderColor: "#0074D9",
                            backgroundColor: '#0074d9',
                            fill: false
                        },
                        {
                            data: dailyChartResponse.data.chartValues,
                            label: "Consumo diário",
                            borderColor: "#0074D9",
                            backgroundColor: '#a5bf3e',
                            fill: false
                        }
                    ]
                };
                const dailyChartJoined = dailyChartResponse.data.chartLabels.map((name, index) => ({
                    name: name,
                    value: dailyChartResponse.data.chartValues[index]
                }));
                setDailyChart(dailyChartJoined);
                setErrorDailyChart(false);
                setIsLoadingDailyChart(false);

                if (!(monthlyChartResponse && monthlyChartResponse.data) || monthlyChartResponse.data.chartValues.length == 0) {
                    setIsLoadingMonthlyChart(false);
                    setErrorMonthlyChart(true);
                    return;
                }

                let monthlyChart = {
                    data: {
                        labels: monthlyChartResponse.data.chartLabels,
                        datasets: [{
                            data: monthlyChartResponse.data.chartValues,
                            label: "Consumo mensal",
                            borderColor: "#fff",
                            backgroundColor: '#a5bf3e',
                            fill: false
                        }],
                    },
                    infoStartDate: monthlyChartResponse.data.chartLabels[0]
                }; const monthlyChartJoined = monthlyChartResponse.data.chartLabels.map((name, index) => ({
                    name: name,
                    value: monthlyChartResponse.data.chartValues[index]
                }));
                setMonthlyChart(monthlyChartJoined);

                if (!(groupsResponse && groupsResponse.data)) {
                    setErrorGroupChart(true);
                    setIsLoadingGroupChart(false);
                    return;

                }
                let groups = groupsResponse.data.reduce((acc, item) => {
                    // Verifica se o grupo já existe no acumulador
                    const existingGroup = acc.find(group => group.GroupName === item.groupName);

                    if (existingGroup) {
                        // Se o grupo já existe, soma o consumo
                        existingGroup.Consume = (parseFloat(existingGroup.Consume.replace(',', '.')) + item.consume).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    } else {
                        // Se o grupo não existe, adiciona ao acumulador
                        acc.push({
                            id: acc.length,
                            GroupName: item.groupName,
                            Consume: item.consume.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        });
                    }

                    return acc;
                }, []);

                setGroups(groups);
                setIsLoadingTable(false);

                let groupsChart = {
                    title: 'Volume corrente em TB',
                    labels: groups.map((Item: { GroupName: string }) => {
                        return Item.GroupName
                    }),
                    values: groups.map((item: any) => {
                        return parseFloat(item.Consume.replace(",", "."))
                    })
                };
                var arrayDataGroupUsageChart = groups.map((item) => {
                    return { label: item.GroupName, value: parseFloat(item.Consume.replace(",", ".")) }
                });
                setGroupsChart(arrayDataGroupUsageChart);
                setErrorGroupChart(false);
                setIsLoadingGroupChart(false);
            })
            .catch(error => {
                console.log(error);
                // Handle error for all promises
                // ...
            })
            .finally(() => {
                setIsLoadingCards(false);
                setIsLoadingTable(false);
                setIsLoadingGroupChart(false);
                setIsLoadingDailyChart(false);
                setIsLoadingMonthlyChart(false);
            });
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }

        if (user.ClientGroupSelected && month && year) {
            setIsLoadingCards(true);
            setIsLoadingTable(true);
            setIsLoadingGroupChart(true);
            setIsLoadingDailyChart(true);
            setIsLoadingMonthlyChart(true);

            const selectedDate = [month, year].join('');
            const backupSilverService = new BackupSilverService(props);

            backupSilverService.GetMonthlyChart()
                .then(monthlyChartResponse => {
                    if (!(monthlyChartResponse && monthlyChartResponse.data) || monthlyChartResponse.data.chartValues.length == 0) {
                        setIsLoadingMonthlyChart(false);
                        setErrorMonthlyChart(true);
                        return;
                    }

                    let monthlyChart = {
                        data: {
                            labels: monthlyChartResponse.data.chartLabels,
                            datasets: [{
                                data: monthlyChartResponse.data.chartValues,
                                label: "Consumo mensal",
                                borderColor: "#fff",
                                backgroundColor: '#a5bf3e',
                                fill: false
                            }],
                        },
                        infoStartDate: monthlyChartResponse.data.chartLabels[0]
                    }; const monthlyChartJoined = monthlyChartResponse.data.chartLabels.map((name, index) => ({
                        name: name,
                        value: monthlyChartResponse.data.chartValues[index]
                    }));
                    setMonthlyChart(monthlyChartJoined);
                    setErrorMonthlyChart(false);
                    setIsLoadingMonthlyChart(false);
                })
                .catch(error => {
                    let monthlyChart = {
                        data: {
                            labels: [],
                            datasets: [{
                                data: [],
                                label: "Consumo mensal",
                                borderColor: "#fff",
                                fill: false
                            }],
                        },
                        infoStartDate: ''
                    };
                    setErrorMonthlyChart(true);
                    setMonthlyChart(monthlyChart);
                    setIsLoadingMonthlyChart(false);
                    console.log(error);
                });

            backupSilverService.GetGroups(selectedDate)
                .then(groupsResponse => {

                })
                .catch(error => {
                    let groupsChart = {
                        title: 'Volume utilizado na última execução',
                        labels: [],
                        values: []
                    };
                    setErrorGroupChart(true);
                    setGroupsChart(groupsChart);
                    setIsLoadingGroupChart(false);
                    setIsLoadingTable(false);
                    console.log(error);
                });

        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    useEffect(() => {
        setCardsNovos([{
            id: 1,//Maior volume
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                row: 1, col: 1, ColSpan: 6,
                RowSpan: 1,
            },
            infoContent: () => {
                return <span>Maior volume do período</span>
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Maior volume</div>
                }</>
            },
            customContentRender: () => {
                return <IconValue isHome={props.isHome} maximumFractionDigits={2} minimumFractionDigits={2} description={props.isHome ? "Maior volume do período" : undefined} formatValue={true} title="Maior volume" icon={<PiDatabaseLight size={props.isHome ? 24 : 32}/>} value={summaryData && summaryData.ConsumedStorage ? summaryData.ConsumedStorage : 0} unity="TB" color="var(--color-primary-pink)">
                </IconValue >
            }
        },
        {
            type: 'Custom',
            id: 2,//Espaço contratado
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                row: 1, col: 7, ColSpan: 6,
                RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Espaço contratado</div>
                }</>
            },
            customContentRender: () => {
                return <IconValue isHome={props.isHome} maximumFractionDigits={1} formatValue={true} description={props.isHome ? "Espaço contratado" : undefined} title="Espaço contratado" icon={<PiArchiveLight size={props.isHome ? 24 : 32}/>} value={summaryData && summaryData.ProvisionedStorage ? summaryData.ProvisionedStorage : 0} unity="TB" color="var(--color-primary-purple)">
                </IconValue >
            }
        },
        {
            type: 'Custom',
            id: 3,//Volume corrente em TB
            hideHeader: props.isHome,
            position: {
                ColSpan: 4,
                RowSpan: 2, row: 2, col: 1
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Volume corrente em TB</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingGroupChart}>
                    {groupsChart && !errorGroupChart ? <DoughNutEChart title={props.isHome ? 'Volume corrente em TB' : undefined} legendOrientation={props.isHome ? 'horizontal' : 'vertical'} ArrayData={groupsChart}></DoughNutEChart>
                        :
                        <LcNoData size="small" />
                    }
                </LcLoading>
            }
        },
        {
            type: 'Custom',
            id: 4,
            position: {
                ColSpan: 4,
                RowSpan: 2, row: 2, col: 5
            },
            showFocusButton: !props.isHome,
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Consumo diário</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingGroupChart} >
                    {
                        dailyChart && !errorDailyChart ?
                            <BarChart data={dailyChart} showline={true}></BarChart>
                            :
                            <LcNoData />
                    }
                </LcLoading>
            }
        },
        {
            type: 'Custom',
            id: 5,//maior consumo mensal
            hideHeader: props.isHome,
            position: {
                ColSpan: 4,
                RowSpan: 2, row: 2, col: 9
            },
            showFocusButton: !props.isHome,
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Maior consumo mensal</div>
                }</>
            },
            customContentRender: () => {
                return <div className="h-100p" >
                    <LcLoading loading={isLoadingGroupChart} >
                        {
                            monthlyChart && !errorMonthlyChart ?
                                <BarChart title={props.isHome ? 'Maior consumo mensal em TB' : undefined} suffixY={props.isHome ? 'TB' : ''} data={monthlyChart} showline={true}></BarChart>
                                :
                                <LcNoData />
                        }
                    </LcLoading>
                </div>
            }
        },
        {
            id: 6,
            type: 'Custom',
            position: {
                ColSpan: 12,
                RowSpan: 1, row: 4, col: 1
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis"> </div>
                }</>
            },
            customContentRender: () => {
                return <LcInfiniteTable
                    loading={isLoadingTable}
                    columns={columns}
                    rows={groups}
                    size={groups.length}
                    loadMore={loadMore}
                    onSortChange={onSortChange} />

            }
        }]);
    }, [groupsChart])
    const columns = [
        { field: 'GroupName', headerName: 'Grupos', width: "85%" },
        {
            field: 'Consume', headerName: 'Consumo', width: "15%",
            renderCell: (row: any) => {
                var temp = (row.Consume) ? row.Consume.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 }) + 'TB' : "0 TB";
                return temp;
            }
        }
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const loadMore = (size?: number) => {
        if (size) setGroups(groups.slice(0, size));
        else setGroups([...groups.slice(groups.length, groups.length + 10)])
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setGroups(groups.sort((a: any, b: any) => {
            if (sort.direction == 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
            else return (a[sort.column] > b[sort.column]) ? -1 : 1;
        }))
        setGroups(groups.slice(0, size))
    };

    const positions5Groups: Card[] = [
        {
            id: 1,//Maior volume
            position: {
                row: 3, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            }
        },
        {
            id: 2,//Espaço contratado

            position: {
                row: 4, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        }, {
            id: 3,//Volume corrente em TB
            position: {
                row: 5, col: 1, ColSpan: 2,
                RowSpan: 2, hidden: false
            },

        }, {
            id: 4,
            position: {
                row: 2, col: 1, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 5,
            position: {
                row: 1, col: 1, ColSpan: 2,
                RowSpan: 2, hidden: false
            },

        },
        {
            id: 6,

            position: {
                row: 2, col: 9, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 7,

            position: {
                row: 4, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        }, {
            id: 8,


            position: {
                row: 5, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        },
        {
            id: 9,


            position: {
                row: 6, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        }];
    const positions3Groups: Card[] = [
        {
            id: 1,
            position: {
                row: 3, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            }
        },
        {
            id: 2,

            position: {
                row: 1, col: 5, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 3,

            position: {
                row: 1, col: 9, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 4,


            position: {
                row: 2, col: 1, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 5,
            position: {
                row: 1, col: 1, ColSpan: 2,
                RowSpan: 2, hidden: false
            },

        },
        {
            id: 6,

            position: {
                row: 2, col: 9, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 7,

            position: {
                row: 4, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        }, {
            id: 8,


            position: {
                row: 5, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        },
        {
            id: 9,


            position: {
                row: 6, col: 1, ColSpan: 1,
                RowSpan: 1, hidden: false
            },

        }];
    const positions2Groups: Card[] = [{
        id: 1,
        position: {
            row: 3, col: 1, ColSpan: 2,
            RowSpan: 1, hidden: false
        }
    },
    {
        id: 2,

        position: {
            row: 1, col: 5, ColSpan: 4,
            RowSpan: 1, hidden: true
        },

    }, {
        id: 3,

        position: {
            row: 1, col: 9, ColSpan: 4,
            RowSpan: 1, hidden: true
        },

    }, {
        id: 4,


        position: {
            row: 3, col: 2, ColSpan: 1,
            RowSpan: 3, hidden: false
        },

    }, {
        id: 5,
        position: {
            row: 1, col: 1, ColSpan: 2,
            RowSpan: 2, hidden: false
        },

    },
    {
        id: 6,

        position: {
            row: 2, col: 9, ColSpan: 4,
            RowSpan: 2, hidden: true
        },

    }, {
        id: 7,

        position: {
            row: 4, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    }, {
        id: 8,


        position: {
            row: 5, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    },
    {
        id: 9,


        position: {
            row: 6, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    }];
    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id == Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }


    return (
        <>
            {!props.isHome ? <Layout
                pageTitle="Resumo"
                periodSelection={periodSelection}
            >
                <LCDashboard cards={cardNovos} rightSidePanel={{
                    title: '',
                    content: () => '',
                    pinned: false,
                    show: false
                }} />
            </Layout> :
                <LCDashboard isHomeType grid={[6, 2]} cards={props.qtdGroups && props.qtdGroups > 0 ? cardNovos.map((card, index) => {
                    if (props.qtdGroups) {
                        var posi = positionHome(card.id, props.qtdGroups);
                        if (posi !== undefined) {
                            card.position = posi;
                        }
                    }
                    return card;
                }) : []} rightSidePanel={{
                    title: '',
                    content: () => '',
                    pinned: false,
                    show: false
                }} />
            }
        </>
    );
};

export default BackupSilverResumo;