import React, { useEffect, useState } from "react";
import "./index.css";

interface Props {
    model: any,
    level: number,
    onClickRestore?: Function
}

const TreeView: React.FC<Props> = (props) => {

    const [open, setOpen] = useState<boolean>(false);
    function isFolder() {
        return props.model.Children &&
            props.model.Children.length;
    }
    function toggle() {
        if (isFolder()) {
            setOpen(!open);
        }
    }
    function currentIcon() {
        switch (props.level) {
            case 0:
                return "lci lci-archive";
            case 1:
                return "lci lci-folder";
            case 2:
                return "lci lci-desktop-computer";
            case 3:
                return "lci lci-document-duplicate";
            default:
                return "lci lci-folder";
        }
    }
    function checkLevel() {
        return props.level >= 2;
    }
    const onClickTreeView = (text: string) => {

        if (props.onClickRestore ) {
            props.onClickRestore(text);
        }
    }
    return (
        <div className="treeView">
            <li>
                <div style={{ height: '2rem' }}
                    onClick={toggle}>
                    <div style={{ paddingBottom: '2rem' }}>
                        <i className={currentIcon()} />
                        {props.model.Label}
                        {isFolder() &&
                            <span>{open ? '[-]' : '[++]'}</span>
                        }
                        {checkLevel() &&
                            <span><a className="link text-link" href="#" onClick={() => onClickTreeView(props.model.Label)} style={{ paddingLeft: '1rem' }} >Restaurar</a></span>
                        }
                    </div>
                </div>
                {open && isFolder() &&
                    <ul>
                        {props.model.Children.map((Item: any, index: number) => {
                            return <TreeView onClickRestore={props.onClickRestore} key={index} model={Item} level={props.level + 1}></TreeView>
                        })
                        }
                    </ul>
                }
            </li>
        </div>
    )
}

export default TreeView;