import React from 'react';

const style = {
    display: 'inline-block', height: '1px', width: '100%', backgroundColor: 'rgba(0,0,0,.2)'
}

const HorizontalDivisor: React.FC = () => {

    return (
        <div style={style}></div>
    );
}

export default HorizontalDivisor;