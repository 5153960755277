import React, { useState } from 'react';

//Layout
import Layout from '../../components/Layout/Layout';

import './index.css';

import LcTabMenu from '../../components/Generic/LcTabMenu';

import TabIcons from './tabIcons';
import TabMessages from './TabMessages';
import TabOthers from './tabOthers';
import TabPref from './tabPref';
import TabDropDown from './tabDropDown';
import TabExpand from './tabExpand';
import TabForm from './tabForm';
import TabGrid from './tabGrid';
import TabInput from './tabInput';

const Devops: React.FC = () => {

    const [selectedTab, setSelectedTab] = useState(0);

    const functionGeneric = [
        {
            icon: 'lci lci-star',
            tooltip: "Função azul",
            onClick: () => { }
        },
        {
            icon: 'lci lci-cog',
            tooltip: "Função verde",
            onClick: () => { }
        },
        {
            icon: 'lci lci-floppy',
            tooltip: "Função vermelha",
            onClick: () => { }
        },
        {
            icon: 'ppi ppi-light-bulb',
            tooltip: "Função amarela",
            onClick: () => { }
        }
    ]

    return (
        <Layout pageTitle="Devops teste" functionsGeneric={functionGeneric} >

            <LcTabMenu items={[
                { label: "Icons", onClick: () => { setSelectedTab(0) } },
                { label: "Messages", onClick: () => { setSelectedTab(1) } },
                { label: "Others", onClick: () => { setSelectedTab(2) } },
                { label: "Pref", onClick: () => { setSelectedTab(3) } },
                { label: "DropDown", onClick: () => { setSelectedTab(4) } },
                { label: "Expand", onClick: () => { setSelectedTab(5) } },
                { label: "Input", onClick: () => { setSelectedTab(6) } },
                { label: "Form", onClick: () => { setSelectedTab(7) } },
                { label: "Grid", onClick: () => { setSelectedTab(8) } }
            ]} activeItem={selectedTab} />

            {selectedTab == 0 && <TabIcons />}
            {selectedTab == 1 && <TabMessages />}
            {selectedTab == 2 && <TabOthers />}
            {selectedTab == 3 && <TabPref />}
            {selectedTab == 4 && <TabDropDown />}
            {selectedTab == 5 && <TabExpand />}
            {selectedTab == 6 && <TabInput />}
            {selectedTab == 7 && <TabForm />}
            {selectedTab == 8 && <TabGrid />}

        </Layout>
    );
};

export default Devops;