export interface MessagesState {
    lastUpdate: string
    messages: any[],
    alerts: any[],
    size: number
}

const initialState = {
    lastUpdate: '',
    messages: [],
    alerts: [],
    size: 10
}

type Action = { type: string, payload: any }

export const messagesReducer = (state: MessagesState = initialState, action: Action) => {
    switch (action.type) {
        case 'MSG_UPDATE':
            return { ...state, messages: action.payload };
        case 'MSG_UPDATE_LAST_TIME':
            return { ...state, lastUpdate: action.payload };
        case 'MSG_UPDATE_ALERT':
            return { ...state, alerts: [...action.payload] };
        case 'MSG_ADD_ALERT':
            return { ...state, alerts: [...state.alerts, action.payload] };
        case 'MSG_SIZE':
            return { ...state, size: action.payload };
        default:
            return state;
    }
}