import { LinearProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import AccessProfile from './pages/accessProfile/main';
import AdminPolicies from './pages/adminPolicies';
import AdminRecommendations from './pages/adminRecommendations/index';
import BackupGoldAgendamentos from './pages/backupGold/agendamentos';
import BackupGoldCofre from './pages/backupGold/cofre/';

import BackupGoldHistoricoNew from './pages/backupGold/historicoNew';
import BackupGoldResumo from './pages/backupGold/resumo';
import BackupSaasAgendamentos from './pages/backupSaas/agendamentos';
import BackupSaasHistorico from './pages/backupSaas/historico';
import BackupSaasRestauracao from './pages/backupSaas/restauracao';
import BackupSaasResumo from './pages/backupSaas/resumo';
import BackupSilverHistory from './pages/backupSilver/historico';
import BackupSilverResumo from './pages/backupSilver/resumo';
import Devops from './pages/Devops';
import CostCenter from './pages/costCenter'
import CostCenterSaaS from './pages/SaaS/CostCenter';
import GTMConfiguration from './pages/gtmConfiguration';

import PlanosDR from './pages/disasterRecovery/planosDR';
import PlanosDRHistorico from './pages/disasterRecovery/planosDRHistorico';

import BancoDeDados from './pages/eBiz/bancoDeDados';

import GenericError from './pages/Errors/generic';
import Error404 from './pages/Errors/Error404';
import EndpointSecurityEvents from './pages/eSecurity/events';
import EndpointSecurityResumo from './pages/eSecurity/resumo';
import EndpointSecurityTypes from './pages/eSecurity/tipos';
import EndpointSecurityUsage from './pages/eSecurity/subscriptions';
import FileRepository from './pages/fileRepository';
import FileSyncResumo from './pages/fileSync/resumo';
import FinancialManagement from './pages/FinancialManagement';
import InvoicesAdm from './pages/FinancialManagement/InvoicesAdm';
import SubscriptionBycountryNoCCReport from './pages/EA/SubscriptionBycountryNoCCReport';
import SubscriptionBycountryNoCCPivot from './pages/EA/SubscriptionBycountryNoCCPivot';
import SubscriptionBycountryReport from './pages/EA/SubscriptionBycountryReport';
import HeyHoList from './pages/Heyho/List';
import Home from './pages/Home';
import SlaSlo from './pages/SlaSlo';

import Hosting from './pages/hosting';
import LCTech from './pages/LCTech/List';
import CSPSecurityScore from './pages/CSP/securityScore';

import LiveWatchResumo from './pages/LiveWatch/Resumo';
import LiveWatchDashboard from './pages/LiveWatch/Dashboard';
import LiveWatchServiceGroups from './pages/LiveWatch/ServiceGroups';
import ManageOfficeStatus from './pages/manageOfficeStatus';
import CustomerIndicatorSummary from './pages/maxOutSource/customerIndicatorSummary';
import DashboardGestaoAVista from './pages/maxOutSource/dashboardGestaoAVista';

import NotificationCenter from './pages/notificationCenter';
import PoliciesList from './pages/Policies/List';
import PortalUsers from './pages/portalUsers/main';
import CostCenterDashBoard from './pages/relatorios/centroDeCusto';
import ArquivosEmRetencao from './pages/retencao/arquivosEmRetencao';
import RetencaoResumo from './pages/retencao/resumo';
import SolicitacoesDeAcesso from './pages/retencao/solicitacoesDeAcesso';
import SaasDashBoard from './pages/SaaS/dashboard';
import CSPDashBoard from './pages/CSP/dashboard';
import UnusedLicenses from './pages/SaaS/unusedLicenses';
import SaaSUser from './pages/SaaS/Users/main/index';
import SaaSAtividades from './pages/SaaS/atividades';
import SaaSSubscriptions from './pages/SaaS/subscriptionsOffice';
import BackupSaasSV2Resumo from './pages/backupSaaSV2/resumo';
import BackupSaasSV2Historico from './pages/backupSaaSV2/historico'
import SaaSGroupList from './pages/SaasGroup/List';
import Servicos from './pages/Servicos';
import VendorCredentialErroWarnning from './pages/VendorCredential/VendorCredentialErroWarnning';
import VendorCredentialHome from './pages/VendorCredential/VendorCredentialHome';
import EA from './pages/EA/VinSubscriptionToEA'
import EaBudgets from './pages/EA/budget'
import EaBudgetsByLicense from './pages/EA/budget/groupByLicense'
import WebSitesResumo from './pages/websites/resumo';
import Insights from './pages/SaaS/Insights';
import InsightsAdmin from './pages/SaaS/InsightsAdmin';
import InsightsHosting from './pages/SaaS/InsightsHosting';
import AlertBaselineLimits from './pages/alertBaselineLimit';
import StorageAccount from './pages/retencao/StorageAccount';
import ResumeInfoHistory from './pages/infoHistory';

//Rotas administrativas
import Menu from './pages/Administration/Menu';
import Nps from './pages/Administration/Nps';

import { RecentsService } from './services/recentsService';
import { RootState } from './store';
import { UserState } from './store/reducers/userReducer';

import ReleaseNotes from './pages/releaseNotes';
import SaaSDashBoardUserSoftware from './pages/SaaS/dashboardUserSoftware';
import EADashBoard from './pages/EA/dashboard';
import ReactGA from 'react-ga';
import ResourceSipContract from './pages/resourceSipContract';
import EndpointDetectionResponseEvents from './pages/endpointDetectionResponse/events';
import EndpointDetectionResponseCollectors from './pages/endpointDetectionResponse/collectors';
import EndpointDetectionResponseResume from './pages/endpointDetectionResponse/resume';
import ResourceDistribution from './pages/resourceDistribution';
import EndpointDetectionResponseExceptions from './pages/endpointDetectionResponse/exceptions';
import LCTechUsage from './pages/LCTech/Usage';
import ResourceCostCenter from './pages/resourceCostCenter';
import FinOpsMapManagerComponent from './pages/finOps/mapManager';
import FinOpsAllocationManagerCostCenterComponent from './pages/finOps/allocationManagerCostCenter';
import FinOpsAllocationManagerContractComponent from './pages/finOps/allocationManagerContract';

import ScheduleManagement from './pages/Administration/scheduleManagement';
import FinOpsAnalyticDashBoard from './pages/finOps/centroDeCusto';
import FinOpsAlertas from './pages/finOps/alertas';
import NotasFiscais from './pages/FinancialManagement/NotasFiscais';
import FinOpsDashboardPeriods from './pages/finOps/dashboardPeriods';
import AlertasNotificacao from './pages/finOps/alertasNotificacoes';
import SendNotification from './pages/notificationCenter/sendNotification';
import AlertasAceite from './pages/finOps/alertasAprova';
import InsightsFinOps from './pages/finOps/aInformacao/aInformacao';
import FinOpsAllocationContract from './pages/finOps/newAllocationManagerContract';
import BillingReviewPivot from './pages/finOps/billingReviewPivot';
import { FinOpsRecommendations } from './pages/finOps/recomendations';
import { RecommendationsInsights } from './pages/finOps/insights';
import BackupGoldHistoricoTree from './pages/backupGold/tree';
import CustomerReviewPivot from './pages/finOps/customerReview';
import BackupGoldJobs from './pages/backupGold/jobs';
import HealthRobotEvents from './pages/Administration/HealthCheckEvents';
import HealthCheckCustomerResume from './pages/Administration/HealthCheckCustomerResume';
import HealthCheckActiveService from './pages/Administration/HealthCheckActiveService';
import DashboardHosting from './pages/Dashboards/Hosting';
import DashboardInvoice from './pages/Dashboards/Invoice';
import Marketing from './pages/Marketing';
import FinOpsResume from './pages/finOps/resume';
import BackupGoldResume from './pages/backupGold/resume';
import CSPResume from './pages/CSP/resume';
import CentroDeCustoPivot from './pages/relatorios/centroDeCustoPivot';
import PositionsTable from './pages/EA/positionsTable';
import GlobalLicenseManager from './pages/EA/globalLicenseManager';
import LicenseControlManagerDetails from './pages/EA/licenseControlManagerDetails';
import LicenseControlManager from './pages/EA/licenseControlManager';
import Automations from './pages/CMS/Automations';
import FinOpsAlertasVersaoDois from './pages/finOps/alertasEdit';
import ResumeCMS from './pages/CMS/ResumeCSM';
import HomeRainbow from './pages/HomeRainbow';
import SecOps from './pages/SecOps';
import ResumePathManager from './pages/PatchManager/ResumePathManager';
import SystemListPatchManager from './pages/PatchManager/SystemListPatchManager';
import PatchListPathManager from './pages/PatchManager/PatchListPathManager';
import FinopsSavingPlanAndReserveResume from './pages/finOps/SavingPlanAndReserveResume';
import ViewGTMContratoLiveCloudMetricaService from './pages/Administration/ViewGTMContratoLiveCloudMetricaService';
import SoftwaresHubWizardForm from './pages/SaaS/wizard';
import LiveWatchInsights from './pages/LiveWatch/Insights';
import LLMAssistantForm from './pages/LLMAssistant';
import LLMAssistant from './pages/LLMAssistant';
import ActivityCMS from './pages/CMS/Activity';
import LcLoading from './components/Generic/LcLoading';
import AuditLog from './pages/AuditLog';
import ADUsers from './pages/EA/ADUsers';

history.listen((location, action) => {

    try {
        let instanceService = new RecentsService();
        instanceService.updateRecents(location);
        ReactGA.pageview(location.pathname + location.search);
    } catch (e) {

    }
})

const AppRoutes: React.FC = (props) => {
    const [loadingScreen, setLoadingScreen] = useState<boolean>(true);
    const [vendorCredentialsWizardRequired, setVendorCredentialsWizardRequired] = useState<boolean>(false);
    const user = useSelector<RootState, UserState>(state => state.user);
    //  Carrega dados do usuario
    useEffect(() => {
        if (user && user.VendorStatusList) {

            //setVendorCredentialsWizardRequired
            if (user.VendorStatusList.filter(x => x.status == 'Correto').length == user.VendorStatusList.length) {
                setVendorCredentialsWizardRequired(false);
            }
            else {
                setVendorCredentialsWizardRequired(true);
            }
        }

    }, [user.ClientGroupSelected, history, user.refreshFlag]);

    useEffect(() => {
        setTimeout(() => {
            setLoadingScreen(false);
        }, 3000);
    }, []);

    if (user && user.ClientGroupSelected && user.ClientGroupSelected > 0) {
        ReactGA.initialize('UA-55551235-4', { gaOptions: { siteSpeedSampleRate: 100 } });
        const email = user.email;
        const customerName = user.ClientGroupList.find(client => client.defaultClientGroup == true).descriptionClientGroup;
        ReactGA.set({ dimension2: email });
        ReactGA.set({ dimension3: customerName });
        ReactGA.pageview("/");
    }

    if (user && user.ClientGroupSelected && user.ClientGroupSelected > 0 && vendorCredentialsWizardRequired == false) {

        return (
            <Router history={history}  >
                <Switch>
                    <Route component={Home} path="/solucoes" exact />
                    <Route component={HomeRainbow} path="/" exact />
                    <Route component={SecOps} path="/SecOps" exact />
                    <Route component={ResumeInfoHistory} path="/infoHistory" exact />

                    <Route component={SlaSlo} path="/slaslo" exact />
                    <Route component={Hosting} path="/hosting/index/:type" />
                    <Route component={Hosting} key="cms-premier" path="/cms/premier/environment/:type" exact />
                    <Route component={Hosting} key="cms-enterprise" path="/cms/enterprise/environment/:type" exact />
                    <Route component={Hosting} key="cms-corporate" path="/cms/corporate/environment/:type" exact />
                    <Route component={AdminRecommendations} path="/admin/recommendations" exact />
                    <Route component={AdminRecommendations} path="/finops/recommendations" exact />
                    <Route component={AdminPolicies} path="/admin/policies" exact />
                    <Route component={DashboardGestaoAVista} path="/MaxOutSource/DashboardGestaoAVista" exact />
                    <Route component={CustomerIndicatorSummary} path="/MaxOutSource/ResumoIndicadorCliente" exact />
                    <Route component={BackupGoldResumo} path="/Backups/BackupTree" />
                    <Route component={BackupGoldResume} path="/Backups/resume" />
                    <Route component={BackupGoldJobs} path="/Backups/BackupJobs" />
                    <Route component={BackupGoldHistoricoNew} path="/Backups/HistoricoDynamo" />
                    <Route component={BackupGoldHistoricoTree} path="/Backups/Tree" />
                    <Route component={BackupGoldCofre} path="/Backups/BackupCofresDynamo" />
                    <Route component={BackupGoldAgendamentos} path="/Backups/Agendamentos" />
                    <Route component={BackupSaasHistorico} path="/BackupSaaS/Historico" />
                    <Route component={BackupSaasResumo} path="/BackupSaaS/Resumo" />
                    <Route component={BackupSaasAgendamentos} path="/BackupSaaS/Agendamentos" />
                    <Route component={FileSyncResumo} path="/FileSync/Resumo" />
                    <Route component={BackupSilverResumo} path="/BackupSilver/Resumo" />
                    <Route component={BackupSilverResumo} path="/filesync/backupsilver" />
                    <Route component={EndpointSecurityResumo} path="/EndpointSecurity/Resumo" />
                    <Route component={EndpointSecurityEvents} path="/EndpointSecurity/Eventos" />
                    <Route component={EndpointSecurityUsage} path="/EndpointSecurity/Assinaturas" />
                    <Route component={EndpointSecurityTypes} path="/EndpointSecurity/Tipos" />
                    <Route component={BackupSaasRestauracao} path="/BackupSaaS/Restauracao" />
                    <Route component={RetencaoResumo} path="/retencao/resumo" />
                    <Route component={ArquivosEmRetencao} path="/retencao/arquivosEmRetencao" />
                    <Route component={SolicitacoesDeAcesso} path="/retencao/solicitacoesDeAcesso" />
                    <Route component={WebSitesResumo} path="/WebSites" />
                    <Route component={CSPDashBoard} path="/CSP/Resumo" />
                    <Route component={CSPResume} path="/CSP/Resume" />
                    <Route component={CSPResume} path="/SaaS/Resume" />
                    <Route component={SaasDashBoard} path="/SaaS/Resumo" />
                    <Route component={EADashBoard} path="/EA/Resumo" />
                    <Route component={ResourceSipContract} path="/ResourceSipContract" exact />
                    <Route component={ResourceCostCenter} path="/ResourceCostCenter" exact />
                    <Route component={ResourceDistribution} path="/ResourceDistribution" exact />
                    <Route component={SaaSAtividades} path="/SaaS/AtividadesSaaS" />
                    <Route component={SaaSAtividades} path="/csp/Atividades" />
                    <Route component={ReleaseNotes} path="/ReleaseNotes" />
                    <Route component={SaaSSubscriptions} path="/SaaS/SubscrptionsSaaS" />
                    <Route component={SaaSSubscriptions} path="/csp/Subscriptions" />
                    <Route component={CentroDeCustoPivot} path="/costcenterdashboard/analitico/:type" />
                    <Route component={CostCenterDashBoard} path="/costcenterdashboard/:type" />
                    <Route component={FinOpsAnalyticDashBoard} path="/finops/analitico" />
                    <Route component={BillingReviewPivot} path="/finops/billingreviewpivot" />
                    <Route component={CustomerReviewPivot} path="/customer/gtmreviewpivot" />
                    <Route component={FinOpsAlertasVersaoDois} path="/FinOps/Alertas" />
                    <Route component={FinOpsAlertasVersaoDois} path="/FinOps/AlertasV2" />
                    <Route component={AlertasNotificacao} path="/FinOps/AlertasNotificacao" />
                    <Route component={AlertasAceite} path="/FinOps/AlertasAceite" />
                    <Route component={FinOpsRecommendations} path="/FinOps/AdvancedRecommendations/cost" />
                    <Route component={FinOpsRecommendations} path="/FinOps/AdvancedRecommendations/security" />
                    <Route component={RecommendationsInsights} path="/FinOps/RecommendationsInsights" />
                    {/* <Route component={Automations} path="/FinOps/Automacoes" /> */}
                    <Route component={Automations} key="cms-corporate" path="/cms/corporate/automacoes" exact />
                    <Route component={Automations} key="cms-enterprise" path="/cms/enterprise/automacoes" exact />
                    <Route component={Automations} key="cms-premier" path="/cms/premier/automacoes" exact />
                    <Route component={SaaSDashBoardUserSoftware} path="/saasdashboardusersoftware" />
                    <Route component={PlanosDR} path="/DisasterRecovery/PlanosDR" />
                    <Route component={PlanosDRHistorico} path="/DisasterRecovery/PlanosDRHistorico" />
                    <Route component={SaaSGroupList} path="/SaaSGrupos" />
                    <Route component={SaaSGroupList} path="/csp/groups" />
                    <Route component={Devops} path="/devops" />
                    <Route component={Servicos} path="/services" />
                    <Route component={SaaSUser} exact path="/SaaS" />
                    <Route component={SaaSUser} exact path="/csp/users" />
                    <Route component={BackupSilverHistory} path="/backupsilver/historico" />
                    <Route component={FinancialManagement} exact path="/FinancialManagement" />
                    <Route component={InvoicesAdm} path="/administration/invoices" />
                    <Route component={SubscriptionBycountryReport} path="/FinancialManagement/SubscriptionBycountryReport" />
                    <Route component={SubscriptionBycountryReport} path="/eas/SubscriptionBycountryReport" />
                    <Route component={SubscriptionBycountryNoCCReport} path="/FinancialManagement/SubscriptionBycountryNoCCReport" />
                    <Route component={SubscriptionBycountryNoCCReport} path="/eas/SubscriptionBycountryNoCCReport" />
                    <Route component={SubscriptionBycountryNoCCPivot} path="/FinancialManagement/SubscriptionBycountryNoCCPivot" />
                    {/* <Route component={ADUsers} path="/eas/users" exact /> */}
                    <Route component={UnusedLicenses} exact path="/SaaSUnusedLicenses" />
                    <Route component={VendorCredentialHome} path="/VendorCredential" />
                    <Route component={NotificationCenter} path="/NotificationCenter" />
                    <Route component={BancoDeDados} path="/BancoDeDados" />
                    <Route component={LCTech} path="/CSP/ResumoCSP" exact />
                    <Route component={LCTech} path="/saas/ResumoCSP" exact />
                    <Route component={LCTech} path="/eas/ResumoCSP" exact />
                    <Route component={PositionsTable} path="/eas/PositionsTable" exact />
                    <Route component={GlobalLicenseManager} path="/eas/globallicensemanager" exact />
                    <Route component={LicenseControlManagerDetails} path="/eas/licensecontrolmanagerdetails" exact />
                    <Route component={LicenseControlManager} path="/eas/licensecontrolmanager" exact />
                    <Route component={LCTechUsage} path="/CSP/LCTechUsage" />
                    <Route component={PortalUsers} path="/Usuarios" />
                    <Route component={PoliciesList} path="/configuration/policies" />
                    <Route component={HeyHoList} path="/Chamados" />
                    <Route component={HeyHoList} path="/Agendamentos/Criar" />
                    <Route component={AccessProfile} path="/Perfis" exact />
                    <Route component={AccessProfile} path="/admin/Perfis" exact />
                    <Route component={ManageOfficeStatus} path="/ManageOfficeStatus" exact />
                    <Route component={ManageOfficeStatus} path="/eas/ManageOfficeStatus" exact />
                    <Route component={ManageOfficeStatus} path="/csp/ManageOfficeStatus" exact />P
                    <Route component={FileRepository} path="/FileRepository" exact />
                    <Route component={EA} path="/VinSubscriptionToEA" exact />
                    <Route component={EaBudgets} path="/eaBudgets" exact />
                    <Route component={EaBudgets} path="/eaBudgetsByUser" exact />
                    <Route component={EaBudgetsByLicense} path="/eaBudgetsByLicense" exact />
                    <Route component={EaBudgetsByLicense} path="/eaBudgetsByLicenseAndUser" exact />
                    <Route component={FinOpsResume} path="/FinOps/Resume" exact />
                    <Route component={SoftwaresHubWizardForm} path="/eas/wizard" exact />
                    <Route component={FinopsSavingPlanAndReserveResume} path="/FinOps/SavingAndReserve" exact />
                    <Route component={FinOpsMapManagerComponent} path="/finops/manager" exact />
                    <Route component={FinOpsAllocationManagerCostCenterComponent} path="/finops/manager/allocation/costcenter" exact />
                    <Route component={FinOpsAllocationManagerCostCenterComponent} path="/admin/manager/allocation/costcenter" exact />
                    <Route component={FinOpsAllocationManagerContractComponent} path="/finops/manager/allocation/contract" exact />
                    <Route component={FinOpsAllocationContract} path="/finops/manager/allocation/newcontract" exact />
                    <Route component={CostCenter} path="/CostCenter" exact />
                    <Route component={CostCenter} path="/finops/costcenter" exact />
                    <Route component={CostCenter} path="/hosting/costcenter" exact />
                    <Route component={CostCenter} path="/webpresence/costcenter" exact />
                    <Route component={CostCenter} key="cms-premier" path="/cms/premier/costcenter" exact />
                    <Route component={CostCenter} key="cms-enterprise" path="/cms/enterprise/costcenter" exact />
                    <Route component={CostCenter} key="cms-corporate" path="/cms/corporate/costcenter" exact />
                    <Route component={CostCenterSaaS} path="/CostCenterSaaS" exact />
                    <Route component={LiveWatchInsights} path="/livewatchinsights" exact />
                    <Route component={LiveWatchResumo} path="/LiveWatchResumo" exact />
                    <Route component={LiveWatchDashboard} path="/MonitoringDashboard" exact />
                    <Route component={LiveWatchDashboard} path="/livewatch/MonitoringDashboard" exact />
                    <Route component={LiveWatchDashboard} path="/hosting/MonitoringDashboard" exact />
                    <Route component={LiveWatchDashboard} path="/disasterrecovery/MonitoringDashboard" exact />
                    <Route component={LiveWatchDashboard} path="/webpresence/MonitoringDashboard" exact />
                    <Route component={LiveWatchDashboard} path="/bizapps/MonitoringDashboard" exact />
                    <Route component={EndpointDetectionResponseResume} path="/EndpointDetectionResponse/Resume" exact />
                    <Route component={EndpointDetectionResponseEvents} path="/EndpointDetectionResponse/Events" exact />
                    <Route component={EndpointDetectionResponseCollectors} path="/EndpointDetectionResponse/Collectors" exact />
                    <Route component={EndpointDetectionResponseExceptions} path="/EndpointDetectionResponse/Exceptions" exact />
                    <Route component={LiveWatchDashboard} path="/MonitoringDashboard/:type" exact />
                    <Route component={LiveWatchServiceGroups} path="/ServiceGroups" exact />
                    <Route component={GTMConfiguration} path="/GTMConfiguration" exact />
                    <Route component={AuditLog} path="/AuditLog" exact />
                    {/* <Route component={LLMAssistantForm} path="/aix/assistants/form" exact /> */}
                    <Route component={LLMAssistant} path="/aix/assistants" exact />
                    <Route component={BackupSaasSV2Resumo} path="/BackupSaasV2/Resumo" exact />
                    <Route component={BackupSaasSV2Historico} path="/BackupSaasV2/Historico" exact />
                    <Route component={Insights} path="/SaaS/Insights" exact />
                    <Route component={InsightsAdmin} path="/InsightsAdmin" exact />
                    <Route component={InsightsHosting} path="/InsightsHosting/:type" />
                    <Route component={Menu} path="/administration/menu" exact />
                    <Route component={ViewGTMContratoLiveCloudMetricaService} path="/administration/viewgtm" exact />
                    <Route component={Nps} path="/administration/nps" exact />
                    <Route component={AlertBaselineLimits} path="/AlertBaselineLimits" exact />
                    <Route component={StorageAccount} path="/retencao/storageaccount" exact />
                    <Route component={CSPSecurityScore} path="/CSP/SecurityScore" exact />
                    <Route component={CSPSecurityScore} path="/SaaS/SecurityScore" exact />
                    <Route component={FinOpsDashboardPeriods} path="/FinOps/Dashboard" exact />
                    <Route component={FinOpsDashboardPeriods} path="/FinOps/DashboardNewPeriods" exact />
                    <Route component={InsightsFinOps} path="/FinOps/AInformacao" exact />
                    <Route component={ScheduleManagement} path="/administration/ScheduleManagement" exact />
                    <Route component={NotasFiscais} path="/notasFiscais" exact />
                    <Route component={SendNotification} path="/administration/SendNotification" exact />
                    <Route component={HealthRobotEvents} path="/administration/HealthRobotEvents" exact />
                    <Route component={HealthCheckCustomerResume} path="/administration/HealthCheckCustomerResume" exact />
                    <Route component={HealthCheckActiveService} path="/administration/HealthCheckActiveService" exact />
                    <Route component={DashboardHosting} path="/dashboard/hosting" exact />
                    <Route component={DashboardInvoice} path="/dashboard/invoice" exact />
                    <Route component={ResumeCMS} key="cms-premier" path="/cms/premier/resume" exact />
                    <Route component={ResumeCMS} key="cms-enterprise" path="/cms/enterprise/resume" exact />
                    <Route component={ResumeCMS} key="cms-corporate" path="/cms/corporate/resume" exact />
                    <Route component={ActivityCMS} key="cms-premier" path="/cms/premier/activity" exact />
                    <Route component={ActivityCMS} key="cms-enterprise" path="/cms/enterprise/activity" exact />
                    <Route component={ActivityCMS} key="cms-corporate" path="/cms/corporate/activity" exact />
                    <Route component={ResumePathManager} path="/patchmanager/resume" exact />
                    <Route component={SystemListPatchManager} path="/patchmanager/systems" exact />
                    <Route component={PatchListPathManager} path="/patchmanager/patches" exact />
                    {/* Rota de marketing */}
                    <Route component={Marketing} path="/marketing/:gtm" exact />
                    <Route component={Error404} path="*" exact />

                </Switch>
            </Router>
        )
    } else if (user && user.ClientGroupSelected && user.ClientGroupSelected > 0 && vendorCredentialsWizardRequired && vendorCredentialsWizardRequired == true) {
        if (user.IsAdm) {
            return (<Router history={history}  >
                <Switch>
                    <Route component={VendorCredentialErroWarnning} path="/" exact />
                </Switch>
            </Router>);
        } else {
            return (<Router history={history}  >
                <Switch>
                    <Route component={GenericError} path="/" exact />
                </Switch>
            </Router>);
        }
    }
    else {
        return (
            <LcLoading loading={loadingScreen} loadingType='Helix' />
        );
    }
}
export default AppRoutes;