import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { DialogContentText, Grid, LinearProgress, ListItem } from '@material-ui/core';

import { v4 as uuidv4 } from "uuid";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/index';
import { UserState } from '../../store/reducers/userReducer';
import { MenuState } from '../../store/reducers/menuReducer';

import LcOrganizationChart from "./LcOrgChartCostCenter/orgChart";

import { CostCenterService } from '../../services/costCenter/costCenterService'

//Img Fluxo
import ImgFluxoCSP from '../../assets/images/Fluxos/csp.png';
import history from '../../history';
import { SaasService } from '../../services/saas/saasService';

import SideModal from '../../components/Layout/SideModal/SideModal';
import LcCheckList from '../../components/Form/LcCheckList';

import { useSnackbar } from 'notistack';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import LcLoading from '../../components/Generic/LcLoading';
import LcDropDown from '../../components/Generic/LcDropDown';
import PPExpand from '../../components/Generic/PPExpand';
import Confirmation from '../../components/Dialog/Confirmation';
import { PPIconLink, PPInput, PPModal } from 'processor-plataform-ui';
import { FinOpsService } from '../../services/finOps/finOpsService';
import moment from 'moment';
import { filterDecimalInput, formatCurrency, formatDecimal } from '../../services/currency';
import LcTable from '../../components/Data/LcTable';

import BudgetPerMonth from './BudgetPerMonth';
import LcToggle from '../../components/Form/LcToggle';
import LCDashboard from '../../components/Data/Dashboard/LCDashboard';
import FinOpsMapManagerService, { CostCenterModel, TreeCostCenter } from '../../services/finOpsManagerService';
import JSONDigger from "json-digger";
import { useHistory } from 'react-router-dom';
import { PiCurrencyCircleDollarLight, PiDotsThree, PiDotsThreeVertical, PiPencilSimpleLight, PiPercentLight, PiPlusLight, PiUserPlusLight, PiUsersThreeLight } from 'react-icons/pi';
import { Budget } from './Budget';

export interface CostCenterNode {
    active: boolean;
    baseline: number;
    baselineUndefined: boolean
    budget: number;
    children: CostCenterNode[];
    cliendtGroupId: number;
    id: number;
    name: string;
    nodeContent: string;
    qtdSubscription: number;
    relationship: string
    tag: string;
}

interface CostCenterUser {
    costCenterID: number;
    email: string;
    id: number;
    name: string;
    userId: number | null
    inheritedCostCenter?: number;
    selected?: boolean

}
interface CostCenterUserSelected {
    costCenterID: number;
    email: string;
    id: number;
    text: string;
    value: number
    selected?: boolean
    inheritedCostCenter?: number;
    extraText?: string;

}
interface TreeItem {
    id: number;
    "Nome do centro de custo": string;
}

interface RecordItem {
    id: number;
    name: string;
    parentName: string;
    parentId: number;
    costCenterId: number;
    budget: number;
    qtdSubscription: number;
    isMain: boolean;
    active: boolean;
    children: any[];
}

interface NewCostCenterModel {
    ID: number | string;
    Parent: number;
    Main: boolean;
    Name: string;
    active: boolean;
    ParentName: string;
    QtdSubscription: number;
    Budget: string;
    ClientGroupId?: number;
}

const CostCenter: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const service = new CostCenterService(props);
    const finOpsService = new FinOpsService(props);
    const finOpsMapManagerService = new FinOpsMapManagerService(props)

    const historyNav = useHistory();
    const [refresh, setRefresh] = useState<boolean>(true)
    const [sideModalVisibleUsuario, setSideModalVisibleUsuario] = useState<boolean>(false)
    const [sideModalVisibleRecurso, setSideModalVisibleRecurso] = useState<boolean>(false)
    const [tree, setTree] = useState<any[]>([])
    const filterDefault = { size: 20, term: "", column: "", direction: "asc" };
    const [value, setValue] = useState<number>(0); //0 Gráfico   1 Árvore  2 Orçamento/consumo

    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<RecordItem[]>([])
    // const [filterRecords, setFilterRecords] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const [costCenterUser, setCostCenterUser] = useState<any[]>([])
    // const [filterCostCenterUser, setFilterCostCenterUser] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const [costCenterResource, setCostCenterResource] = useState<any[]>([])
    const [filteredCostCenterResource, setFilteredCostCenterResource] = useState<any[]>([])
    const [visibleCostCenterResource, setVisibleCostCenterResource] = useState<any[]>([]);
    const [filterCostCenterResource, setFilterCostCenterResource] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const [cloudResource, setCloudResource] = useState<any[]>([])
    const [filteredCloudResource, setFilteredCloudResource] = useState<any[]>([])
    const [visibleCloudResource, setVisibleCloudResource] = useState<any[]>([]);
    const [filterCloudResource, setFilterCloudResource] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const [costCenterAspNetUser, setCostCenterAspNetUser] = useState<any[]>([])
    const [toggleAspNetUser, setToggleAspNetUser] = useState<any[]>([])
    const [isLoadingUser, setIsLoadingUser] = useState(true)
    const [isLoadingResouce, setIsLoadingResource] = useState<boolean>(true)
    const [isLoadingAddResource, setIsLoadingAddResource] = useState<boolean>(true)
    const [isVisibleAddResource, setIsVisibleAddResource] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const [isVisibleEditCostCenter, setIsVisibleEditCostCenter] = useState<boolean>(false)
    const currentRowDefault: CostCenterModel = {
        id: 0,
        parentId: 0,
        costCenterId: 0,
        isMain: false,
        name: '',
        active: true,
        parentName: '',
        qtdSubscription: 0,
        budget: 0,
        clientGroupId: 0
    }
    const [currentRow, setCurrentRow] = useState<CostCenterModel>(currentRowDefault)
    const [CcName, setCcName] = useState<string>('')
    const [CcBudget, setCcBudget] = useState<string>()
    const [months, setMonths] = useState<string[]>([]);
    const [monthBudget, setMonthBudget] = useState<any[]>([]);
    const [monthBudgetFull, setMonthBudgetFull] = useState<{ month: string, value: number }[]>([]);
    const [showInactives, setShowInactives] = useState<boolean>(false)
    const isMounted = useRef(false);

    //Budget separado
    const [saveMode, setSaveMode] = useState(1); //1 - budget padrão, -1 - budget mensal

    const [selectedCostCenter, setSelectedCostCenter] = useState<any>([])
    const [treeData, setTreeData] = useState<CostCenterModel[]>([])

    const [selectedUsersToAdd, setselectedUsersToAdd] = useState<CostCenterUserSelected[]>([]);
    const [isAddingUsers, setIsAddingUsers] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [linkedCostCenterUser, setLinkedCostCenterUser] = useState<any[]>([]);
    const [costCentersWithAllocations, setCostCentersWithAllocations] = useState<number[]>([]);
    const [rulesByCostCenter, setRulesByCostCenter] = useState<any[]>([]);
    const [currentCostCenterRules, setCurrentCostCenterRules] = useState<any[]>([]);
    const [viewDisableModal, setViewDisableModal] = useState<boolean>(false);
    const [isDisabling, setIsDisabling] = useState<boolean>(true);
    const [typeSideModal, setTypeSideModal] = useState<string>('');
    const [currentNode, setCurrentNode] = useState<CostCenterModel>(currentRowDefault);
    const [costCenterChildrens, setCostCenterChildrens] = useState<CostCenterNode[]>([])
    const [canRemove, setCanRemove] = useState<boolean>(false)
    const [selectedUserForRemove, setSelectedUserForRemove] = useState<CostCenterUserSelected[]>([])
    const [emailsUsersAddRemoved, setEmailsUserAddRemoved] = useState<string[]>([])
    const [budgetViewMode, setBudgetViewMode] = useState(0);
    const [ccSelected, setCCSelected] = useState<any>();
    const [budgetHistoric, setBudgetHistoric] = useState<any[]>([]);
    const [budgetHistoricModalV, setBudgetHistoricModalV] = useState(false);
    const [loadingHistoric, setLoadingHistoric] = useState(false);
    const [newCcName, setNewCcName] = useState<string>('')
    const [newCcBudget, setNewCcBudget] = useState<string>('0')
    const [isVisibleAddUser, setIsVisibleAddUser] = useState<boolean>(false)
    const [expanded, setExpanded] = useState(true);
    const [ds, setDS] = useState(filterDefault);
    const dsDigger = new JSONDigger(ds, "id", "children");
    const dispatch = useDispatch()
    //Colunas que serão utilizadas na montagem do chart

    useEffect(() => {
        LoadDataCostCemter();
        async function LoadDataCostCemter() {
            setRefresh(true);

            if (user.ClientGroupSelected) {
                setFilteredRecords([]);

                try {
                    var result = await service.GetTreeData()
                    if (!showInactives)
                        filterActives(result.data)
                    setTreeData(result.data);
                    let { array, _records } = formatTree(result.data, 0);
                    setDS(result.data)
                    setTree(array);
                    setRecords(_records);
                    setFilteredRecords(_records);
                    await mapAllocatedCostCenters(_records);
                    setRefresh(false);
                } catch (e) {
                    console.warn(e)
                }

                try {
                    var response = await finOpsService.LoadBudgetvsUsagebyGroupFull(user.ClientGroupSelected);
                    let allMonths = response.map(b => b.parent.date.substring(0, 7));
                    let months = [...new Set(allMonths)].sort((a, b) => { return a > b ? 1 : -1; });
                    months = months.slice(months.length - 12, months.length);
                    setMonths(months);
                } catch (e) {
                    console.warn(e)
                }

                try {
                    var responseTreeDataWithBudget = await service.GetTreeDataWithBudget();
                    setMonthBudget(responseTreeDataWithBudget.data);
                } catch (e) {
                    console.warn(e)
                }
            }
        }

        // Verifica quais centros de custo possuem regras de alocação vinculadas
        async function mapAllocatedCostCenters(costCenterData: any[]) {
            var costCenterIds = costCenterData.map(x => x.costCenterId)
            try {
                var resulBase = await finOpsMapManagerService.GetAllocationVersionsCostCenter();
                var result = resulBase.map((u) => {
                    return {
                        "label": u.name.split('/')[3].split('.')[0],
                        "value": u.name.split('/')[3].split('.')[0]
                    };
                });
                var resultData = await finOpsMapManagerService.LoadAllocationCostCenter(result[0].value);
                var allocatedCostCenterIds: any[] = [];

                resultData.rules.forEach(rule => {
                    let ruleCCIds = rule.costCenters.map(cc => parseInt(cc.idCostCenter))
                    allocatedCostCenterIds = [...allocatedCostCenterIds, ...ruleCCIds]
                });
                allocatedCostCenterIds = [...new Set(allocatedCostCenterIds)];
                var allocatedCostCenters = costCenterIds.filter(cc => allocatedCostCenterIds.includes(cc))
                setCostCentersWithAllocations(allocatedCostCenters);

                var mappedAllocationRules: any[] = [];
                costCenterIds.forEach((ccId) => {
                    if (!mappedAllocationRules[ccId])
                        mappedAllocationRules[ccId] = [];

                    resultData.rules.forEach(rule => {
                        if (rule.costCenters.find(cc => cc.idCostCenter == ccId)) {
                            mappedAllocationRules[ccId].push(rule);
                            return;
                        }
                    });
                });
                setRulesByCostCenter(mappedAllocationRules);

            } catch (e) {
                console.warn("Erro" + e)
            }
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    function filterActives(node) {
        const filteredChildren = node.children.filter(child => child.active == true);
        node.children = filteredChildren;

        if (filteredChildren.length > 0) {
            filteredChildren.forEach(filterActives);
        }
    }
    //Formata retorno do BFF (model CostCenterHierarchyStructure) para Data aceito pelo chart
    function formatTree(row: any, index: number) {
        const array: TreeItem[] = [];
        const _records: RecordItem[] = [];

        function formatSingleItem(item: any, parentId: number | undefined) {
            array.push({
                id: item.id,
                "Nome do centro de custo": item.name
            });

            _records.push({
                id: array.length,
                name: item.name,
                parentName: parentId ? array.find(x => x.id === parentId)?.["Nome do centro de custo"] || "" : "",
                parentId: parentId || 0,
                costCenterId: item.id,
                budget: item.budget,
                qtdSubscription: item.qtdSubscription,
                isMain: item.isMain,
                active: item.active,
                children: item.children
            });

            if (item.children && item.children.length > 0) {
                item.children.forEach((child: any) => {
                    formatSingleItem(child, item.id);
                });
            }
        }

        formatSingleItem(row, undefined);

        return { array, _records };
    }

    const vincularUsuario = (rowId: any) => {
        let row = records.find(x => x.id === rowId)
        setCostCenterChildrens(getChildrensNode(row))
        setSelectedCostCenter(row)
        carregarUsuarios(row.costCenterId);
    }

    const carregarUsuarios = async (costCenterId: number) => {
        const domainsExcluded = ["@processor.com.br", "@gotobiz.com.br", "@testesportal.onmicrosoft.com"]
        setIsLoadingUser(true)
        await service.GetCostCenterUsers(costCenterId)
            .then(result => {
                if (result.status === 200) {
                    let sortedData = result.data.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });
                    let userListToggle = result.data.map((item: CostCenterUser) => {
                        return {
                            text: item.name,
                            extraText: item.inheritedCostCenter ? `(Herdado de ${records.find(cc => cc.costCenterId === item.inheritedCostCenter).name})` : null,
                            value: item.id,
                            email: item.email,
                            CostCenterId: item.costCenterID,
                            inheritedCostCenter: item.inheritedCostCenter,
                        }
                    });
                    setCostCenterUser((domainsExcluded.some((dominio) => user.email?.includes(dominio))) ?
                        sortedData : filterDomainEmail(sortedData))
                    setLinkedCostCenterUser((domainsExcluded.some((dominio) => user.email?.includes(dominio))) ?
                        userListToggle : filterDomainEmail(userListToggle))
                }

            })
        try {
            var resultCostCenterAspNetUsers =
                await service.GetCostCenterAspNetUsers(costCenterId);
            let userListToggle = resultCostCenterAspNetUsers.data.map((item: CostCenterUser) => {
                return {
                    text: item.name,
                    value: item.id,
                    email: item.email,
                    selected: false
                }
            });
            setToggleAspNetUser((domainsExcluded.some((dominio) => user.email?.includes(dominio))) ?
                userListToggle : filterDomainEmail(userListToggle));
            setCostCenterAspNetUser((domainsExcluded.some((dominio) => user.email?.includes(dominio))) ?
                resultCostCenterAspNetUsers.data : filterDomainEmail(resultCostCenterAspNetUsers.data))

            setSideModalVisibleUsuario(!sideModalVisibleUsuario)
        } catch (e) {
            console.error(e)
        }
        setIsLoadingUser(false);
    }

    const filterDomainEmail = (list) => {
        const domainsExcluded = ["@processor.com.br", "@gotobiz.com.br", "@testesportal.onmicrosoft.com"];

        // Filtrar o array de objetos
        const arrayFiltrado = list.filter((objeto) => {
            // Verificar se o campo "email" não contém nenhum dos domínios excluídos
            return !domainsExcluded.some((dominio) => objeto.email.includes(dominio))
        });

        return arrayFiltrado;
    }

    const convertValue = (value: any) => {
        return value ? parseFloat(value).toFixed(2) : null;
    }

    const editar = (ccId: any) => {
        let row = records.find(x => x.costCenterId == ccId);

        //Montar dados de orçamento mensal
        let mounted: any[] = [];
        months.forEach(m => {
            let _toAdd = {
                month: m,
                value: convertValue(monthBudget.find(mb => mb.id == ccId)?.monthBudget.find(mb => mb.month == m)?.value)
            };
            mounted.push(_toAdd);
        })

        setMonthBudgetFull(mounted);

        if (row) {
            setTypeSideModal('edit')
            setCcBudget(row.budget)
            setCcName(row.name)
            setCurrentRow(row)
            setIsVisibleEditCostCenter(!isVisibleEditCostCenter)
        }
    }

    const functionsRow = [
        {
            label: 'Habilitar/Desabilitar',
            func: async (row: CostCenterModel) => {

                let tempRecords = filteredRecords as any;
                let rowTemp = tempRecords.find(x => x.id == row.id);
                let children = tempRecords.filter(x => x.parentId == row.costCenterId)

                if (!rowTemp) return

                let costCenterHasActiveChildren = children.length > 0 && children.find(child => child.active == true) != undefined
                let costCenterHasAllocationRule = costCentersWithAllocations.includes(rowTemp.costCenterId);

                if (costCenterHasActiveChildren) {
                    enqueueSnackbar(`Centro de custo com filhos ativos não pode ser desativado!`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                    return
                }

                if (costCenterHasAllocationRule) {
                    setIsDisabling(true);
                    setCurrentCostCenterRules(rulesByCostCenter[row.costCenterId]);
                    setViewDisableModal(true);
                    return;
                }

                if (!window.confirm(`Deseja ${rowTemp.active ? "des" : "h"}abilitar este centro de custo?`))
                    return

                enqueueSnackbar('A atualização do centro de custo foi iniciada, aguarde.', {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false,
                })

                let costCenterSend = {
                    id: row.costCenterId,
                    parent: row.parentId,
                    main: row.isMain,
                    name: row.name,
                    parentName: row.parentName,
                    qtdSubscription: row.qtdSubscription,
                    budget: row.budget as number,
                    clientGroupId: user.ClientGroupSelected ?? 0,
                    active: !row.active
                }

                try {
                    var result = await service.UpdateCostCenter(costCenterSend);
                    if (result.status == 200) {
                        updateTree()
                        enqueueSnackbar('A atualização do centro de custo foi realizada com sucesso.', {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                        onCloseCostCenter()
                    }
                } catch (error) {
                    console.warn(error)
                    enqueueSnackbar('Erro ao atualizar centro de custo.', {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    })
                }
            },
            hidden: false,
            disabled: false
        },
        {
            label: 'Excluir',
            func: (row: any) => {

                let tempRecords = filteredRecords as any;
                let rowTemp = tempRecords.find(x => x.id == row.id);
                let children = tempRecords.filter(x => x.parentId == row.costCenterId)

                if (!rowTemp) return

                let costCenterHasActiveChildren = children.length > 0 && children.find(child => child.active == true) != undefined
                let costCenterHasAllocationRule = costCentersWithAllocations.includes(rowTemp.costCenterId);

                if (costCenterHasActiveChildren) {
                    enqueueSnackbar(`Centro de custo com filhos ativos não pode ser excluído!`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                    return
                }

                if (costCenterHasAllocationRule) {
                    setIsDisabling(false);
                    setCurrentCostCenterRules(rulesByCostCenter[row.costCenterId]);
                    setViewDisableModal(true);
                    return;
                }

                if (!window.confirm("Deseja excluir este centro de custo?"))
                    return

                service.DeleteCostCenter(rowTemp.costCenterId).then(result => {
                    if (result.status == 200) {
                        updateTree()
                        enqueueSnackbar("Centro de custo excluído com sucesso", {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                }).catch(error => {
                    console.warn(error)
                    enqueueSnackbar("Erro ao excluir centro de custo", {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                })
            },
            hidden: false,
            disabled: false
        }

    ]


    const onCloseSideModalUser = () => {
        setCostCenterUser([])
        setSideModalVisibleUsuario(!sideModalVisibleUsuario)
        setIsLoadingUser(true)
    }

    const functionsRowResource = [
        {
            label: 'Remover recurso',
            func: (row: any) => {

                let rowTemp = costCenterResource.find(x => x.id == row.id)

                if (!rowTemp) return

                let resp = window.confirm("Deseja excluir este centeo de custo?")

                if (!resp) return

                service.RemoveCostCenterResource(rowTemp.id as number).then(result => {
                    if (result.status == 200) {
                        setCloudResource([])
                        setFilteredCloudResource([])
                        setCostCenterResource([])
                        setFilteredCostCenterResource([])
                        setIsLoadingResource(true)
                        setIsVisibleAddResource(false)
                        setIsLoadingAddResource(true)
                        setSideModalVisibleRecurso(false)
                        setSideModalVisibleRecurso(!sideModalVisibleRecurso)
                        alert("Recurso do centro de custo excluído com sucesso")
                    }
                }).catch(error => {
                    alert('Erro ao excluir recurso')
                })
            }
        }
    ]

    const functionsGenericResource = [
        {
            title: 'Adicionar recurso',
            icon: 'lci lci-document-add',
            tooltip: "Adicionar recurso",
            func: () => { setIsVisibleAddResource(!isVisibleAddResource) }
        }
    ];

    const columnsResource = [
        { field: 'id', headerName: 'Id', width: "20%" },
        { field: 'groupName', headerName: 'Grupo', width: "35%" },
        { field: 'resourceName', headerName: 'Nome', width: "45%" }
    ];

    const _filterAdvancedResource = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilterCostCenterResource({ ...filterCostCenterResource, size: size, term: _filter[0].value.toLowerCase() })
    }

    const onCloseSideModalResource = () => {
        setSideModalVisibleRecurso(!sideModalVisibleRecurso)
        setFilteredCloudResource([])
        setFilteredCostCenterResource([])
        setCostCenterResource([])
        setIsLoadingResource(true)
    }


    const setToogledUsers = (data: any) => {
        let selected: CostCenterUserSelected[] = data.filter(x => x.selected === true);
        let emails: string[] = selected.map(i => i.email);
        setEmailsUserAddRemoved(emails)
        let usersToAdd: any[] = [];
        selected.forEach(element => {

            let user = costCenterAspNetUser.find(x => x.id === element.value);
            let costCenterUser = {
                UserID: user.id,
                Name: user.name,
                email: user.email,
                CostCenterID: selectedCostCenter.costCenterId,
            }
            usersToAdd.push(costCenterUser);
            if (costCenterChildrens.length > 0) {
                costCenterChildrens.forEach(childCostCenter => {
                    let user = costCenterAspNetUser.find(x => x.id === element.value);
                    let costCenterUser = {
                        UserID: user.id,
                        Name: user.name,
                        email: user.email,
                        CostCenterID: childCostCenter.id,
                        inheritedCostCenter: selectedCostCenter.costCenterId,
                    }
                    usersToAdd.push(costCenterUser);
                });
            }
        });
        setselectedUsersToAdd(usersToAdd);
    }

    const setUsersToRemove = (data: any) => {
        let selected = data.filter(x => x.selected === true);
        let emails: string[] = selected.map(i => i.email);
        setEmailsUserAddRemoved(emails)
        let users: CostCenterUser[] = [];
        setSelectedUserForRemove(selected);

        selected.forEach(async element => {
            let user: CostCenterUser = costCenterUser.find(x => x.id === element.value);
            users.push(user);
        });
        users.find((can: CostCenterUser) => can.inheritedCostCenter) ? setCanRemove(false) : setCanRemove(true)
    }
    const addUserToCC = async () => {
        setIsAddingUsers(true)
        let removeArray: number[] = []
        const promises = costCenterChildrens.map(async (childRemove) => {
            const resp = await service.GetCostCenterUsers(childRemove.id);
            const childUser = resp.data.find(user => emailsUsersAddRemoved.includes(user.email));
            if (childUser) {
                removeArray.push(childUser.id)
            }
        })
        try {
            await Promise.all(promises);
            removeArray.length > 0 && await service.RemoveCostCenterUsers(removeArray)
            await service.AddCostCenterUser(selectedUsersToAdd)
                .then(result => {
                    if (result.status == 200) {
                        setEmailsUserAddRemoved([])
                        setIsLoadingUser(!isLoadingUser)
                        setCostCenterUser([])
                        setIsVisibleAddUser(!isVisibleAddUser)
                        setSideModalVisibleUsuario(!sideModalVisibleUsuario)
                        setIsAddingUsers(false)
                        enqueueSnackbar("Usuário(s) vinculado(s)", {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                }).catch(error => {
                    console.error(error)
                    setIsAddingUsers(false)
                    enqueueSnackbar("Erro ao vincular usuário(s) do centro de custo", {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                });
        } catch (e) {
            console.warn(e)
            setIsAddingUsers(false)
            enqueueSnackbar("Erro ao vincular usuário(s) do centro de custo", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        }
    };

    const allremoved = async () => {
        let usersToRemove: any[] = [];
        for (const element of selectedUserForRemove) {
            const user: CostCenterUser = costCenterUser.find(x => x.id === element.value);
            usersToRemove.push(user.id);
        }
        const promises = costCenterChildrens.map(async (childRemove) => {
            const resp = await service.GetCostCenterUsers(childRemove.id);
            const childUser = resp.data.filter(user => emailsUsersAddRemoved.includes(user.email));
            if (childUser) {
                let idsRemoved = [...childUser].map(i => i.id)
                usersToRemove.push([...idsRemoved]);
            }
        });
        await Promise.all(promises);
        return [].concat(...usersToRemove)
    }

    const removeUserFromCC = async () => {
        setIsLoadingUser(true)
        try {
            let ids = await allremoved()
            ids.length > 0 && await service.RemoveCostCenterUsers(ids).then(result => {
                if (result.status == 200) {
                    setEmailsUserAddRemoved([])
                    enqueueSnackbar("Usuário(s) removido(s)", {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                    setCostCenterUser([])
                    setSideModalVisibleUsuario(!sideModalVisibleUsuario)
                    setIsLoadingUser(false)
                    setOpen(false)
                }
            });
        } catch (e) {
            console.warn(e)
            enqueueSnackbar("Erro ao remover usuário do centro de custo", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
            setSideModalVisibleUsuario(!sideModalVisibleUsuario)
            setIsLoadingUser(false)
            setOpen(false)
        }
    }

    const onCloseSideModalAddUser = () => { setIsVisibleAddUser(false) }

    const onCloseSideModalAddResource = () => {
        setIsLoadingAddResource(true)
        setFilteredCloudResource([])
        setIsVisibleAddResource(!isVisibleAddResource)
    }

    const functionsRowAddResource = [
        {
            label: 'Adicionar',
            func: async (row: any) => {
                //ToDo
                let rowTemp = cloudResource.find(x => x.id === row.id)

                if (!rowTemp) return

                let costCenterResource = [
                    {
                        CloudResourceID: rowTemp.id,
                        CostCenterID: selectedCostCenter.costCenterId,
                        Name: rowTemp.resourceName,
                        CloudResourceCloudID: rowTemp.cloudResourceId
                    }
                ]
                try {
                    var result = await service.AddCostCenterResource(costCenterResource);
                    setCloudResource([])
                    setFilteredCloudResource([])
                    setCostCenterResource([])
                    setFilteredCostCenterResource([])
                    setIsLoadingResource(true)
                    setIsVisibleAddResource(false)
                    setIsLoadingAddResource(true)
                    setSideModalVisibleRecurso(false)
                    enqueueSnackbar("Centro de custo vinculado ao recurso", {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                } catch (error) {
                    console.warn(error)
                    enqueueSnackbar("Erro ao vincular centro de custo ao recurso", {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
            }
        }
    ]

    const _filterAdvancedCloudResource = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilterCloudResource({ ...filterCloudResource, size: size, term: _filter[0].value.toLowerCase() })
    }

    const updateTree = async (costCenter: NewCostCenterModel | null = null) => {
        if (records.length == 0) return;

        setRefresh(true)

        if (user.ClientGroupSelected) {
            setFilteredRecords([]);
            try {
                var result = await service.GetTreeData();
                if (!showInactives)
                    filterActives(result.data)
                setTreeData(result.data);
                // formatOriginalTree(result.data, true, 0)
                let { array, _records } = formatTree(result.data, 0);
                setTree(array)
                setRecords(_records)
                setFilteredRecords(_records)
                costCenter && getUsersByCenterCost(costCenter, result.data)
                var response = await service.GetTreeDataWithBudget();
                setMonthBudget(response.data);
            } catch (e) {

            }

            setRefresh(false);
        }
    }

    const onChangeInput = (event) => {

        const { value, name } = event.target;

        switch (name) {
            case "costCenter_name":
                setCcName(value)
                break;
            case "costCenter_budget":
                setCcBudget(filterDecimalInput(value))
                break;
        }
    }

    const updateCostCenter = async () => {
        if (saveMode == -1) {
            var currentBudgetHistory: any[] = [];
            try {
                let result = await service.GetTreeDataWithBudget();
                if (result.status == 200)
                    currentBudgetHistory = result.data.map(d => d.monthBudget).flat();
            } catch (error) {
                console.warn(error);
            }

            monthBudgetFull.find(b => b.value) ?
                enqueueSnackbar('A atualização do centro de custo foi iniciada, aguarde.', {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false,
                })
                :
                enqueueSnackbar('Não há dados para serem salvos', {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })

            monthBudgetFull
                // .filter(b => b.value)
                .forEach(async (mb, index) => {
                    try {
                        let parsedValue = Number(mb.value) || 0.00;
                        let hasHistory = currentBudgetHistory.length > 0;
                        let hasChanged = hasHistory && !(currentBudgetHistory.some(cbh =>
                            cbh.costCenterId === currentRow.costCenterId && cbh.value === parsedValue && cbh.month === mb.month
                        ));
                        if (!hasHistory || hasChanged)
                            await service.CreateOrUpdateBudget(currentRow.costCenterId, parsedValue, mb.month);
                    } catch (error) {
                        console.warn(error);
                    }
                    if (monthBudgetFull.filter(b => b.value).length - 1 == index) {
                        updateTree();
                        enqueueSnackbar('A atualização do centro de custo foi realizada com sucesso.', {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                        onCloseCostCenter();
                    }
                })

        } else {
            enqueueSnackbar('A atualização do centro de custo foi iniciada, aguarde.', {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })

            let costCenterSend = {
                ID: currentRow.costCenterId,
                Parent: currentRow.parentId,
                Main: false,
                Name: CcName,
                Active: currentRow.active,
                ParentName: currentRow.parentName,
                QtdSubscription: currentRow.qtdSubscription,
                Budget: CcBudget,
                ClientGroupId: user.ClientGroupSelected
            }

            try {

                var result = await service.UpdateCostCenter(costCenterSend);
                if (result.status == 200) {
                    let budgetMsg = "";
                    var budget = {
                        id: currentRow.costCenterId,
                        value: Number(CcBudget) || 0.00,
                        month: moment().format("YYYY-MM")
                    }
                    var resultBudget = await service.CreateOrUpdateBudget(budget.id, budget.value, budget.month);

                    if (resultBudget.status == 200)
                        budgetMsg = " e do histórico de orçamentos";

                    updateTree()
                    enqueueSnackbar(`A atualização do centro de custo${budgetMsg} foi realizada com sucesso.`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    })
                    onCloseCostCenter()
                }
            } catch (error) {
                console.error(error)
                enqueueSnackbar('Erro ao atualizar centro de custo.', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
            }

        }


    }

    const onCloseCostCenter = () => {
        setCcBudget('0');
        setSaveMode(1)
        setCcName('');
        setCurrentRow(currentRowDefault);
        setMonthBudgetFull([]);
        setIsVisibleEditCostCenter(false);
    }

    //#region Verifica se há GTM CSP
    const saasService = new SaasService(props);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);
    useEffect(() => {
        GetbyClientGroupIdAndTileId();

        async function GetbyClientGroupIdAndTileId() {
            if (menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
                try {
                    var response = await saasService.GetbyClientGroupIdAndTileId('13');
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                } catch (error) {
                    console.warn(error);
                }
            }

        }
    }, [menu.selectedItem])
    //#endregion

    const onSortChangeResource = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterCostCenterResource({ ...filterCostCenterResource, column: sort.column, direction: sort.direction, size: size })
    };

    const onSortChangeCloudResource = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterCloudResource({ ...filterCloudResource, column: sort.column, direction: sort.direction, size: size })

    };

    const loadMoreResource = (size?: number) => {
        if (size) setVisibleCostCenterResource(filteredCostCenterResource.slice(0, size))
        else setVisibleCostCenterResource([...visibleCostCenterResource, ...filteredCostCenterResource.slice(visibleCostCenterResource.length, visibleCostCenterResource.length + 10)])
    };

    const loadMoreCloudResource = (size?: number) => {
        if (size) setVisibleCloudResource(filteredCloudResource.slice(0, size))
        else setVisibleCloudResource([...visibleCloudResource, ...filteredCloudResource.slice(visibleCloudResource.length, visibleCloudResource.length + 10)])
    };

    const formatValue = (obj: CostCenterModel) => {
        var valor = (obj != undefined) ? obj.budget : 0;
        valor = valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        var nome = (obj != undefined) ? obj.name : "";
        var formatedValue = "$ " + valor + " - " + nome;

        return formatedValue;
    }

    const ReadHistoricByCostCenter = async (costCenter: any) => {
        setCCSelected(costCenter);
        setBudgetHistoricModalV(true);
        setLoadingHistoric(true);
        try {
            var response = await service.ReadHistoricByCostCenter(costCenter.costCenterId);
            let _data = response.data;
            _data = _data
                .sort((a, b) => { return a.when > b.when ? -1 : 1; })
                .sort((a, b) => { return a.month > b.month ? 1 : -1; });

            setBudgetHistoric(_data);
        } catch (e) {
            console.error(e);
        }
        setLoadingHistoric(false);
    }

    const handleOnChangeShowInactive = (e) => {
        setShowInactives(e.checked)
    }

    useEffect(() => {
        if (isMounted)
            updateTree()
    }, [showInactives])

    useEffect(() => {
        isMounted.current = true;
    }, [])

    const add = (row: CostCenterModel) => {
        setTypeSideModal("create");
        setCurrentNode(row);
        setIsVisibleEditCostCenter(!isVisibleEditCostCenter);
    };

    const navigateToAllocationRules = () => {
        historyNav.push('/finops/manager/allocation/costcenter')
    }

    const functions = (obj: any) => {
        return (
            <div className="sequence">
                <LcIconLink icon={<PiPlusLight />} tooltip="Adicionar novo centro de custo" onClick={() => { obj.active === true && add(obj) }} />
                <LcIconLink icon={<PiUsersThreeLight />} tooltip="Usuários Vinculados" onClick={() => { obj.active === true && vincularUsuario(obj.id); }} />
                <LcIconLink icon={<PiPencilSimpleLight />} tooltip="Editar" onClick={() => { obj.active == true && editar(obj.costCenterId) }} />
                {
                    functionsRow &&
                    <>
                        <LcDropDown disabled={obj.active == false} element={<LcIconLink icon={<PiDotsThreeVertical />} />} position="right" >
                            {
                                obj.active == true ?
                                    <>
                                        <div className="item" onClick={() => { ReadHistoricByCostCenter(obj) }}>Histórico</div>
                                        {
                                            functionsRow.map((f, i) => {
                                                if (!f.hidden) {
                                                    let disabled = (f.disabled) || false;
                                                    return (<div key={i} className={`item${disabled ? ' disabled' : ''}`} onClick={() => { !disabled && f.func(obj) }}>{f.label}</div>)
                                                }
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                        <div className='item' onClick={() => { functionsRow[0].func(obj) }}>{functionsRow[0].label}</div>
                                    </>
                            }
                        </LcDropDown>
                    </>
                }
            </div>
        )
    }


    const funcaoTree = (itemPai: CostCenterModel, idPai: number) => {
        return (
            <div key={idPai}>
                {
                    (itemPai.active && showInactives) ?
                        <>
                            {
                                (records.filter(item => item.parentId == itemPai.costCenterId).length > 0)
                                    ?
                                    <PPExpand
                                        expanded={expanded}
                                        title={formatValue(itemPai)}
                                        titleRight={functions(itemPai)}
                                        disabled={!itemPai.active}
                                    >
                                        {
                                            records.filter(item => item.parentId == itemPai.costCenterId).map((itemFilho, idFilho) => funcaoTree(itemFilho, idFilho))
                                        }
                                    </PPExpand>
                                    :
                                    <div className="bg-hover" style={{ color: itemPai.active ? 'var(--text-default)' : "#2e1956ad", marginLeft: '30px', display: "flex", alignItems: "center", fontWeight: "bolder" }}>
                                        <span style={{ width: '100%' }}> {formatValue(itemPai)} </span>
                                        {functions(itemPai)}
                                    </div>
                            }
                        </>
                        :
                        <>
                            {
                                (itemPai.active && records.filter(item => item.parentId == itemPai.costCenterId).length > 0)
                                    ?
                                    <PPExpand
                                        expanded={expanded}
                                        title={formatValue(itemPai)}
                                        titleRight={functions(itemPai)}
                                    >
                                        {
                                            records.filter(item => item.parentId == itemPai.costCenterId).map((itemFilho, idFilho) => funcaoTree(itemFilho, idFilho))
                                        }
                                    </PPExpand>
                                    :
                                    <div className="bg-hover" style={{ color: itemPai.active ? 'var(--text-default)' : "#2e1956ad", marginLeft: '30px', display: "flex", alignItems: "center", fontWeight: "bolder" }}>
                                        <span style={{ width: '100%' }}> {formatValue(itemPai)} </span>
                                        {functions(itemPai)}
                                    </div>
                            }
                        </>

                }
            </div>

        )
    }

    const handleUpdateBudgetMonth = (e) => {
        let newMonthBudgetfull = [...monthBudgetFull];
        e.forEach(budget => {
            newMonthBudgetfull.map(mbf => mbf.month == budget.month ? mbf.value = budget.value : mbf);
        });
        setMonthBudgetFull(newMonthBudgetfull);
    }

    const addChild = async () => {
        enqueueSnackbar(
            "A criação do centro de custo foi iniciada, aguarde a atualização dos dados",
            {
                variant: "success",
                preventDuplicate: true,
                persist: false,
            }
        );
        setIsVisibleEditCostCenter(!isVisibleEditCostCenter);
        setTypeSideModal('');

        let param = currentNode.costCenterId;
        let newNode = {
            id: uuidv4(),
            name: newCcName,
            baseline: 0,
            nodeContent: "",
            tag: newCcName,
            baselineUndefined: false,
            budget: newCcBudget,
            qtdSubscription: 0,
            children: [],
            clientGroupId: user.ClientGroupSelected,
        }

        await dsDigger.addChildren(param, [newNode]);
        await dsDigger.findParent(newNode.id).then((_r) => {
            const parentNode = _r;
            const node = newNode;

            let costCenterSend: NewCostCenterModel = {
                ID: 0,
                Parent: parentNode.id,
                Main: false,
                Name: node.name,
                ParentName: parentNode.name,
                Budget: newCcBudget,
                QtdSubscription: node.qtdSubscription,
                ClientGroupId: user.ClientGroupSelected,
                active: true
            }
            service
                .UpdateCostCenter(costCenterSend)
                .then((result) => {
                    if (result.status == 200) {
                        updateTree(costCenterSend)
                        setNewCcName("");
                        setNewCcBudget("0");
                        enqueueSnackbar(
                            "A atualização do centro de custo foi realizada com sucesso.",
                            {
                                variant: "success",
                                preventDuplicate: true,
                                persist: false,
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar("Erro ao atualizar centro de custo.", {
                        variant: "error",
                        preventDuplicate: true,
                        persist: false,
                    });
                });
        });

        setDS({ ...dsDigger.ds });
    };

    const getChildrensNode = (nodeData: CostCenterNode) => {
        let result: CostCenterNode[] = [];
        if (nodeData.children && nodeData.children.length > 0) {
            result = nodeData.children.slice(); // Copia os children atuais
            // Itera sobre os children e, se tiverem childrens próprios, os adiciona ao resultado
            for (const child of nodeData.children) {
                result = result.concat(getChildrensNode(child));
            }
        }
        return result;
    }

    const getUsersByCenterCost = async (costCenter: any, tree: CostCenterNode) => {
        let filterCC = findCostCenterById([tree], costCenter)
        let costCenterUsers = await service.GetCostCenterUsers(costCenter.Parent)
        let usersForNewCC = filterCC && await service.GetCostCenterAspNetUsers(filterCC.id);
        let usersToAdd: any[] = [];
        if (filterCC && costCenterUsers.data.length > 0 && usersForNewCC?.data.length > 0) {
            usersForNewCC?.data.forEach(users => {
                let user = costCenterUsers?.data.find(userCC => userCC.email === users.email)
                if (user) {
                    let addUsers = {
                        UserID: users.id,
                        name: user.name,
                        email: user.email,
                        CostCenterID: filterCC?.id,
                        inheritedCostCenter: costCenter.Parent,
                    }
                    usersToAdd.push(addUsers)
                }
            })
            usersToAdd.length > 0 &&
                await service.AddCostCenterUser(usersToAdd)
                    .then(result => {
                        enqueueSnackbar("Usuário(s) vinculado(s) ao novo centro de custo", {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }).catch(error => {
                        console.error(error)
                        enqueueSnackbar("Erro ao vincular usuário(s) ao novo centro de custo", {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                    });

        } else {
            enqueueSnackbar("Erro ao vincular usuário(s) ao novo centro de custo", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
        }
    }

    const findCostCenterById = (tree: CostCenterNode[], costCenter: any): CostCenterNode | undefined => {
        const queue = tree;
        while (queue.length > 0) {
            const currentNode = queue.shift();
            if (currentNode) {
                if (currentNode.name.toLowerCase() === costCenter.Name.toLowerCase() && currentNode.budget === Number(costCenter.Budget)) {
                    return currentNode;
                }
                if (currentNode.children && currentNode.children.length > 0) {
                    queue.push(...currentNode.children);
                }
            } else {
                return undefined;

            }
        }
    }

    return (
        <Layout
            pageTitle="Centro de custo"
            row={
                <>
                    {
                        value == 2 &&
                        <LcIconLink
                            icon={budgetViewMode == 0 ? <PiCurrencyCircleDollarLight /> : <PiPercentLight />}
                            size="small"
                            onClick={() => setBudgetViewMode(budgetViewMode == 1 ? 0 : 1)}
                            tooltip={budgetViewMode == 0 ? "Mostrar porcentagens" : "Mostrar valores"}
                        />
                    }
                    <LcToggle toggled={showInactives} onChange={handleOnChangeShowInactive} title="Inativos" name='Inativos' color={'var(--primary'} />

                    <LcDropDown
                        element={<span>{service.cardsItens.find(c => c.value == value)?.label} <i className="ppi ppi-chevron-down" /></span>}
                        position="right"
                    >
                        {
                            service.cardsItens.map((ci, i) => {
                                return <div key={i} className={`item${ci.value == value ? " disabled" : ""}`}
                                    onClick={() => {
                                        ci.value != value &&
                                            setValue(ci.value);
                                    }}

                                >{ci.label}</div>
                            })
                        }
                    </LcDropDown>

                </>
            }

            dots={value == 1 && <>
                <div style={{ color: expanded == true ? "#abaeb9" : "#000" }} className={`item${expanded == true ? ' disabled' : ""}`} onClick={() => setExpanded(true)}><i className='ppi ppi-chevron-double-down' /> Expandir tudo</div>
                <div style={{ color: expanded == false ? "#abaeb9" : "#000" }} className={`item${expanded == false ? ' disabled' : ""}`} onClick={() => setExpanded(false)}><i className='ppi ppi-chevron-double-up' /> Recolher tudo</div>
            </>}
        >
            {
                showFluxoCSP ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo.
                            Caso precise de ajuda abra uma requisição, clicando
                            <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <>
                        {tree.length > 0 && !refresh ?
                            <>
                                {
                                    value === 0 &&
                                    <LcOrganizationChart editUsers={(nodeData: CostCenterNode) => {
                                        setSideModalVisibleUsuario(true);
                                        setSelectedCostCenter(records.find(x => x.costCenterId === nodeData.id))
                                        setCostCenterChildrens(getChildrensNode(nodeData))
                                        carregarUsuarios(nodeData.id);

                                    }} data={treeData} updateData={updateTree} showDisabled={showInactives} allocated={costCentersWithAllocations} />
                                }
                                {
                                    value === 1 &&
                                    <>
                                        <PPModal
                                            title={`${isDisabling ? "Desabilitar" : "Excluir"} Centro de Custo`}
                                            onClose={() => setViewDisableModal(false)}
                                            visible={viewDisableModal}
                                            functions={[
                                                {
                                                    label: "Abrir Regras de Alocação",
                                                    onClick: navigateToAllocationRules
                                                },
                                                {
                                                    label: "Cancelar",
                                                    onClick: () => setViewDisableModal(false),
                                                    context: "grey"
                                                }
                                            ]}
                                        >
                                            <DialogContentText>
                                                <p>As seguintes Regras de Alocação estão vinculadas a este Centro de Custo, impedindo sua {isDisabling ? "desativação" : "exclusão"}.</p>
                                                <ul style={{ margin: '15px' }}>
                                                    {
                                                        currentCostCenterRules.map(rule => (
                                                            <li><strong>{rule.description}</strong></li>
                                                        ))
                                                    }
                                                </ul>
                                                Você precisará desassociar estas regras antes de seguir com esta operação.
                                            </DialogContentText>
                                        </PPModal>
                                        <LCDashboard
                                            cards={[{
                                                title: () => {
                                                    return <div className="lc-title ellipsis">Árvore - Centro de custos </div>
                                                },
                                                type: 'Custom', position: { ColSpan: 12, RowSpan: 4, row: 1, col: 1 },
                                                customContentRender: () => {
                                                    return <div className="chart-container">
                                                        <div className="chart-content scrollable-v">
                                                            {
                                                                filteredRecords
                                                                    .sort((a: any, b: any) => (a.id > b.id) ? 1 : - 1)
                                                                    .slice(0, 1)
                                                                    .map((itemPai: any, idPai: number) => funcaoTree(itemPai, idPai))

                                                            } </div> </div>
                                                }
                                            }]} />
                                    </>


                                }
                                {
                                    value === 2 &&
                                    <Budget onEdit={event => editar(event)} visibleMode={budgetViewMode} showInactives={showInactives} />
                                }
                            </>
                            :
                            <LcLoading loading={refresh} loadingType='Helix' />
                        }

                        <PPModal size='medium' title="Usuários vinculados" onClose={onCloseSideModalUser} visible={sideModalVisibleUsuario}
                            loading={isLoadingUser}
                            functions={[
                                {
                                    label: " Remover selecionado(s)", onClick: () => setOpen(true), disabled: !(selectedUserForRemove.length !== 0 && canRemove),
                                    tooltip: !canRemove ? "Usuário(s) selecionado não pode ser removido porque é Herdado de outro Centro de custo" : selectedUserForRemove.length > 0 ? '' :
                                        "Necessario selecionar pelo menos um usuário"

                                }
                            ]}
                        >
                            <LcIconLink icon={<PiUserPlusLight />} tooltip="Vincular Usuário(s)"
                                tooltipPosition="left" onClick={() => { setIsVisibleAddUser(true) }} />

                            <LcCheckList title="Vinculados" height="100%" list={linkedCostCenterUser}
                                toggleAll={true} search={true}
                                onChange={setUsersToRemove}
                            />

                            <Confirmation
                                confirm={removeUserFromCC}
                                close={setOpen}
                                text='Deseja realmente remover este(s) usuário(s)?'
                                display={open}
                            />

                            <PPModal loading={isAddingUsers} size='500px' title="Vincular usuário" onClose={onCloseSideModalAddUser} visible={isVisibleAddUser}
                                functions={[
                                    { label: (isAddingUsers ? <LinearProgress /> : "Vincular selecionados"), onClick: () => addUserToCC() }
                                ]}
                            >
                                <LcCheckList height="100%" title="Disponíveis" list={toggleAspNetUser}
                                    toggleAll={true} search={true}
                                    onChange={setToogledUsers}
                                />

                            </PPModal>

                        </PPModal>

                        <SideModal header="Recursos vinculados" onClose={onCloseSideModalResource} visible={sideModalVisibleRecurso}>

                            <LcInfiniteTable
                                loading={isLoadingResouce}
                                functionsRow={functionsRowResource}
                                onSelectChange={setToogledUsers}
                                columns={columnsResource}
                                rows={visibleCostCenterResource}
                                filter={_filterAdvancedResource}
                                size={filteredCostCenterResource.length}
                                loadMore={loadMoreResource}
                                onSortChange={onSortChangeResource} />

                            <SideModal header="Vincular recurso" onClose={onCloseSideModalAddResource} visible={isVisibleAddResource}>
                                <LcInfiniteTable
                                    loading={isLoadingAddResource}
                                    functionsRow={functionsRowAddResource}
                                    columns={columnsResource}
                                    rows={visibleCloudResource}
                                    filter={_filterAdvancedCloudResource}
                                    size={filteredCloudResource.length}
                                    functionGeneric={functionsGenericResource}
                                    loadMore={loadMoreCloudResource}
                                    onSortChange={onSortChangeCloudResource} />

                            </SideModal>
                        </SideModal>

                        <PPModal
                            title={
                                typeSideModal === "edit"
                                    ? "Editar centro de custo"
                                    : "Criar centro de custo"
                            }
                            onClose={onCloseCostCenter}
                            visible={isVisibleEditCostCenter}
                            functions={[
                                {
                                    label: typeSideModal === "edit" ? "Salvar" : "Criar",
                                    onClick: typeSideModal === "edit" ? updateCostCenter : addChild,
                                },
                                {
                                    label: saveMode === 1 ? "Histórico" : "Modo Padrão",
                                    onClick: () => setSaveMode(saveMode * -1),
                                    context: "info",
                                    tooltip: saveMode === 1 ? "Editar orçamento separado por mês" : "Editar orçamento padrão do centro de custo",
                                    hidden: typeSideModal !== 'edit'
                                }
                            ]}
                        >
                            {currentRow && typeSideModal === 'edit' &&
                                <div className='form gap-tiny mr-3'>
                                    <PPInput
                                        title='Nome do centro de custo'
                                        value={CcName} name="costCenter_name"
                                        type="text"
                                        onChange={onChangeInput}
                                        placeholder="Insira um novo nome ao centro de custo"
                                        required
                                    />
                                    {
                                        saveMode === 1 ?
                                            <PPInput
                                                title='Valor de orçamento (período atual)'
                                                value={CcBudget ? formatDecimal(Number(CcBudget)) : '0.00'}
                                                decoration="R$ "
                                                name="costCenter_budget"
                                                type="text"
                                                placeholder="Digite apenas números"
                                                onChange={onChangeInput}
                                                required
                                            />
                                            :
                                            <BudgetPerMonth clientGroupId={user.ClientGroupSelected} ccId={currentRow.costCenterId} onChange={handleUpdateBudgetMonth} />
                                    }

                                </div>
                            }
                            {
                                typeSideModal === 'create' &&
                                <div className='form gap-tiny mr-3'>
                                    <PPInput
                                        title='Nome do centro de custo'
                                        value={newCcName}
                                        name="newCostCenterName"
                                        type="text"
                                        onChange={(e) => setNewCcName(e.target.value)}
                                        placeholder="Insira um novo nome ao centro de custo"
                                        required
                                    />

                                    {
                                        saveMode === 1 ?
                                            <PPInput
                                                title='Valor de orçamento (período atual)'
                                                value={newCcBudget ? formatDecimal(Number(newCcBudget)) : '0.00'}
                                                decoration="R$ "
                                                name="newCostCenterBudget"
                                                type="text"
                                                placeholder="Digite apenas números"
                                                onChange={(e) => { setNewCcBudget(filterDecimalInput(e.target.value)) }}
                                                required
                                            />
                                            :
                                            <BudgetPerMonth clientGroupId={user.ClientGroupSelected} ccId={currentRow.costCenterId} onChange={handleUpdateBudgetMonth} />
                                    }

                                </div>

                            }

                        </PPModal>
                    </>
            }

            <PPModal
                visible={budgetHistoricModalV}
                onClose={() => {
                    setCCSelected(null);
                    setBudgetHistoric([]);
                    setBudgetHistoricModalV(false)
                    setSaveMode(1)
                }}
                title={`Histórico do orçamento ${ccSelected?.name || 'x'}`}
                size='large'
                loading={loadingHistoric}
            >
                {
                    budgetHistoric.length > 0 ?
                        <LcTable
                            data={budgetHistoric}
                            columns={[
                                {
                                    field: 'month', headerName: 'Mês', width: '20%',
                                    renderCell: (row: any) => {
                                        return moment(row.month + '-01').format("MMM/YYYY");
                                    }
                                },
                                {
                                    field: 'oldValue', headerName: 'Valor antigo', width: '20%',
                                    renderCell: (row: any) => {
                                        return row.oldValue != null ? formatCurrency(row.oldValue) : 'ND';
                                    }
                                },
                                {
                                    field: 'newValue', headerName: 'Valor novo', width: '20%',
                                    renderCell: (row: any) => {
                                        return row.newValue != null ? formatCurrency(row.newValue) : 'ND';
                                    }
                                },
                                { field: 'who', headerName: 'Responsável', width: '20%' },
                                {
                                    field: 'when', headerName: 'Data', width: '20%',
                                    renderCell: (row: any) => {
                                        return moment(row.when).add('hours', -3).format("DD/MM/YYYY HH:MM:SS");
                                    }
                                },
                            ]}
                        />
                        :
                        <div className='centralized row'>
                            <i className="lci lci-exclamation lci-2x text-warning" />
                            <span className="font-sm">Não há histórico para este centro de custo</span>
                        </div>
                }
            </PPModal>

        </Layout>
    );
};

export default CostCenter;