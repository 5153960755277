import React, { useEffect, ChangeEvent, useState, useRef } from 'react';

import './index.css';
import { Button, Card, CardActions, CardContent, LinearProgress, FormGroup, Input,  NativeSelect } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Label } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Layout from '../../../components/Layout/Layout';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import DynamicForm from '../../../components/Layout/DynamicForm/DynamicForm';
import LiveWatchServiceGroupService from '../../../services/LiveWatchServiceGroupService';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { PiDotsThreeVertical } from 'react-icons/pi';

function getCurrentDate() {
    let date = new Date();
    let fullYear = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = date.getDate();
    let formatedDate = fullYear + "-" + month + "-" + day;

    return formatedDate;
}

const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

interface LiveWatchServiceProps {
    serviceGroupId?: number;
    serviceId?: number;
}

const LiveWatchTemplate: React.FC<LiveWatchServiceProps> = (props) => {
    let serviceGroupId = props.serviceGroupId;
    let serviceId = props.serviceId;
    const constructLiveWatchServiceGroupService = (): LiveWatchServiceGroupService => {
        return new LiveWatchServiceGroupService(props);
    }
    const initTemplate = async () => {
        let template = await ServiceInstance.template(ServiceInstance.emptyRecord, true);
        setTemplate && setTemplate(template);
    }
    const [ServiceInstance, setServiceInstance] = useState<LiveWatchServiceGroupService>(constructLiveWatchServiceGroupService());
    useSingleton(() => {
        setServiceInstance && setServiceInstance(constructLiveWatchServiceGroupService());
        initTemplate();
    });

    const [counter, setCounter] = useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingTable, setIsLoadingTable] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snack, setSnack] = React.useState<any>({});
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        members: false,
        record: ServiceInstance.emptyRecord
    };

    const [ListData, setListData] = useState<any[]>([]);
    const [ListDataFiltered, setListDataFiltered] = useState<any[]>([]);
    const [visibleListData, setVisibleListData] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 25, term: "", column: "", direction: "asc" });
    const [filterReload, setFilterReload] = useState<boolean>(false);

    const [record, setRecord] = useState(emptyRecordMessage);
    const [template, setTemplate] = useState<any>({
        title: '',
        Fields: []
    });

    const columns = [
        { field: 'name', headerName: 'Nome', width: "50%" },
        { field: 'description', headerName: 'Descrição', width: "50%" },
        {
            field: 'settings', with: '38px', overflow: "visible",
            renderCell: (row: any) => {
                return (
                    <LcDropDown element={<LcIconLink icon={<PiDotsThreeVertical />} />} position="right">
                        <span className="item" onClick={() => selecionadoEditar(row)}>Editar</span>
                        <span className="item" onClick={() => selecionadoDeletar(row.id)}>Excluir</span>
                    </LcDropDown>
                );
            }
        }
    ];

    const ADD = async () => {
        let addRecord = { ...emptyRecordMessage };
        addRecord.record = { ...ServiceInstance.emptyRecord };
        let template = await ServiceInstance.template(ServiceInstance.emptyRecord, false);
        setTemplate && setTemplate(template);
        addRecord.edit = false;
        addRecord.add = true;
        setRecord({ ...addRecord });
    };

    const onSearch = {
        inputType: 'text',
        eventOnSearch: (e: any) => { }
    };

    let functions = {
        functionsList: [
            {
                title: 'Novo',
                tooltip: 'Novo',
                icon: 'ppi ppi-plus',
                func: (ids: []) => {
                    ADD();
                },
                minSelectedRowNumber: 0,
                disabled: false
            },
        ],
        functionsEdit: [{
            title: 'Salvar',
            tooltip: 'Salvar',
            icon: 'lci lci-check',
            func: (item: any) => {
                setIsLoading(true);
                item.serviceGroupId = serviceGroupId;
                item.serviceId = serviceId;
                ServiceInstance.SalvarMonitoringTemplate(item).then((items: any) => {
                    enqueueSnackbar(`Template criado com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                    setRecord({ ...emptyRecordMessage });
                }).finally(() => { resetData(); setIsLoading(false); });
            },
            disabled: false,
            skipvalidation: false
        }, {
            title: 'Fechar',
            tooltip: 'Fechar',
            icon: 'lci lci-x',
            func: () => {
                setRecord({ ...emptyRecordMessage });
            },
            disabled: false,
            skipvalidation: true
        }]
    };

    const selecionadoEditar = (item: any) => {
        if (item != null) {
            EDIT(item);
        }
        else {
            setRecord({ ...emptyRecordMessage })
        }
    };

    const selecionadoDeletar = (id: any) => {
        setIsLoading(true);
        if (!window.confirm("Confirmar a exclusão dos selecionados."))
            return;

        ServiceInstance.DeleteMonitoringTemplate(id).then((items: any) => {
            enqueueSnackbar(`Template removido com sucesso`, {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            });
            setRecord({ ...emptyRecordMessage });
        }).finally(() => { resetData(); setIsLoading(false); });
    }

    const EDIT = (item: any) => {
        let addRecord = { ...emptyRecordMessage };
        addRecord.edit = true;
        addRecord.add = true;
        addRecord.detail = false;
        addRecord.record = item;
        setRecord({ ...addRecord });
    };

    const handleDeleteClose = (deleteConfirm: boolean) => {
        if (deleteConfirm == true) {

        }

        if (deleteConfirm == false) {

        }
        setOpen(false);
    };
    const handleActiveClickOpen = () => {
        setOpen(true);
    };

    function loadData() {

        setIsLoadingTable(true);
        ServiceInstance.GetService(serviceGroupId, serviceId).then((tt: any) => {

            setListData(tt.data.monitoringTemplates);
            setListDataFiltered(tt.data.monitoringTemplates);
            setIsLoadingTable(false);
            setFilterReload(true);
        }).catch((tt) => { });
    }

    useEffect(() => {
        loadData();
    }, [counter, serviceId]);

    const [handleform, setHandleform] = useState<any>({});

    function resetData() {
        var counterPlus = counter + 1;
        setCounter(counterPlus);
    }

    const filterAdvanced = {
        fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    };

    useEffect(() => {
        var filtered = ListData.filter((p: any) =>
            filter.term == '' ||
            p.name?.toLowerCase().indexOf(filter.term) > -1 ||
            p.description?.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilterReload(false);
        setListDataFiltered(filtered);
        setVisibleListData(filtered.slice(0, filter.size));
    }, [filter, filterReload]);


    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleListData(ListDataFiltered.slice(0, size))
        else {
            setVisibleListData([...visibleListData, ...ListDataFiltered.slice(visibleListData.length, visibleListData.length + 10)])
        }
    };

    const interfaceTyped: { [key: string]: any } = { ...record.record };
    return (
        <>
            {
                (record.edit != true && record.add != true) && ListData &&
                <LcInfiniteTable
                    loading={isLoadingTable}
                    columns={columns}
                    functionGeneric={functions.functionsList}
                    rows={visibleListData}
                    filter={filterAdvanced}
                    size={ListDataFiltered.length}
                    loadMore={loadMore}
                    onSortChange={onSortChange} />
            }
            <DialogConfirmForm onCloseEvent={handleDeleteClose}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content='' />
            {
                record && (record.add || record.edit) &&
                <DynamicForm loading={isLoading} header={(record != null && record.record != null) ?
                    `Template ${interfaceTyped[template.title]}` : 'Template'}
                    functions={functions.functionsEdit} data={record.record}
                    template={template} visible={true} EditForm={true}
                    onClose={() => { setRecord(emptyRecordMessage); resetData(); }} />
            }
        </>
    );
}

export default LiveWatchTemplate;