import React from 'react';
import BackupGoldResume from '../../../../../pages/backupGold/resume';
import RetencaoResumo from '../../../../../pages/retencao/resumo';
import BackupSilverResumo from '../../../../../pages/backupSilver/resumo';

interface DataCardProps {
    GTMs: string[];
    QtdGroups: number;
}

const DataCard: React.FC<DataCardProps> = ({ GTMs, QtdGroups }) => {
    const renderComponentByGTM = () => {
        if (GTMs.includes('backuppremium')) {
            return <BackupGoldResume isHome={true} qtdGroups={QtdGroups} />;
        } else if (GTMs.includes('backupbasic')) {
            return <BackupSilverResumo isHome={true} qtdGroups={QtdGroups} />;
        } else if (GTMs.includes('retencao')) {
            return <RetencaoResumo isHome={true} qtdGroups={QtdGroups} />;
        } else if (GTMs.includes('disasterrecovery')) {            
            return null;
        } else {
            return <BackupGoldResume isHome={true} qtdGroups={QtdGroups} />; // default
        }
    }

    return (
        <>
            {renderComponentByGTM()}
        </>
    );
}

export default DataCard;