export class firewall {
    solution() {
        return 'firewall'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-fwaas'
    }

    logotipo() {
        return require('./assets/logotipos/logo_firewall.svg').default
    }
    titulo() {
        return <>Proteja os ativos digitais com o LiveCloud Firewall as a Service</>
    }

    chamada() {
        return <>Oferece recursos de <b>firewall de última geração</b> para organizações de todos os tamanhos, com a flexibilidade de ser implantado como firewall de próxima geração e/ou gateway de VPN.</>
    }

    image() {
        return require('./assets/imgs/imagem_firewall.png').default
    }

    detalhes() {
        return <>
            <h2>Pronto para levar a segurança da organização para o próximo nível?</h2>
            <p><b>LiveCloud FWaaS é uma solução de segurança altamente escalável que protege contra ameaças cibernéticas com alto desempenho, eficácia e visibilidade.</b></p>
            <h3>Por que escolher LiveCloud FWaaS?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_firewall.png').default} />
                </div>
                <ul>
                    <li><b>Proteção avançada:</b> Oferecemos análise do tráfego de rede em camada 7, inspeção de tráfego SSL e gestão de políticas/regras de segurança.</li>
                    <li><b>Visibilidade completa:</b> Tenha uma visão completa do tráfego de rede e receba até dois relatórios do ambiente por mês, sob demanda.</li>
                    <li><b>Segurança as a Service:</b> Nosso serviço identifica e bloqueia ameaças automaticamente, oferecendo proteção de perímetro para o seu ambiente.</li>
                    <li><b>Reconhecimento e controle de aplicações:</b> Com LiveCloud FWaaS, a organização tem controle total sobre as aplicações e o tráfego de rede.</li>
                </ul>
            </div>
            <div className="box">LiveCloud FWaaS é mais do que apenas um firewall. É uma <b>solução completa de segurança</b> que protege os ativos digitais e ajuda a manter a organização segura.</div>
            <h3>Interface gerencial única</h3>
            <p>Com LiveCloud, a organização tem uma interface gerencial única para acompanhar os serviços contratados, monitorar níveis de serviço, gerenciar e acompanhar consumo, abrir chamados, além de visualizar contratos e faturas para ter mais transparência e assertividade nos recursos utilizados em ambiente multinuvem.</p>            
            <h3>Pronto para dar o próximo passo?</h3>
            <p>Não deixe a segurança da organização ao acaso. Entre em contato conosco hoje mesmo e descubra como LiveCloud Firewall as a Service pode proteger os ativos digitais e impulsionar o sucesso do negócio.</p>     
        </>
    }
}
export default firewall;