import api, { APIClient } from "../api";

export class FinancialManagementService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    formatPrice(value: number) {
        let val = (value / 1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    async GetContratos() {
        const url = this.endpoint + `/api/FinancialManagement/GetCotracts/`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data.map((item: any, index: number) => {
                return {
                    id: index,
                    ...item
                }
            });
        });
    }

    async GetFabricantes() {
        const url = this.endpoint + `/api/FinancialManagement/GetFabricantes/`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data.map((item: any, index: number) => {
                return {
                    id: index,
                    ...item
                }
            });
        });
    }

    async GetClientGroupContractFinancial(month: any, year: any) {
        const url = this.endpoint + `/api/FinancialManagement/GetClientGroupContractFinancial?month=${month}&year=${year}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            if (response && response.data && response.data.length > 0) {
                return response.data.map((item: any, index: number) => {
                    return {
                        id: index,
                        ...item
                    }
                });
            }
        });
    }

    async GetClients() {
        const url = this.endpoint + `/api/FinancialManagement/GetClientsByGroupId`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetClientsFromContract(ContractId: string) {
        const url = this.endpoint + `/api/FinancialManagement/GetLiveCloudClientsByContract/${ContractId}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async GetInvoices(ContractId: string, ClientId: string) {
        const url = this.endpoint + `/api/FinancialManagement/${ClientId}/Contract/${ContractId}/Invoices`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }
    async GetInvoiceFilePDF(InvoiceId: string, RPSID: string) {
        const url = this.endpoint + `/api/FinancialManagement/retrieveNFEFromFileSharePDF?InvoiceId=${InvoiceId}&rpsid=${RPSID}`;
        return api({
            method: 'get',
            url, responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf',
            }
        }).then((data) => {

            const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/octet-stream' }));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            const fileName = `${InvoiceId}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            if (error.response.data) {
                let errorText = this.ab2str(error.response.data);
                console.warn(errorText);
                return errorText;
            }
        });
    }

    ab2str = (buf: number[]) => {
        return String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(buf)));
    }

    async GetInvoiceFileXML(InvoiceId: string, RPSID: string) {
        const url = this.endpoint + `/api/FinancialManagement/retrieveNFEFromFileShareXML?InvoiceId=${InvoiceId}&rpsid=${RPSID}`;
        return api({
            method: 'get',
            url, responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/XML',
            }
        }).then((data) => {

            const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/octet-stream' }));
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            const fileName = `${InvoiceId}.xml`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }

    async GetCompleteInvoiceDetails(ClientId: string, InvoiceId: string, SalesOrderId: string) {
        const url = this.endpoint + `/api/FinancialManagement/${ClientId}/Invoice/${InvoiceId}/SalesOrder/${SalesOrderId}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            if (response.data) {
                if (response.data.parcelNF) {
                    response.data.parcelNF = response.data.parcelNF.map((item: any, index: number) => {
                        return {
                            id: index,
                            ...item
                        }
                    });
                }
                if (response.data.itensNF) {
                    response.data.itens = response.data.itensNF.map((item: any, index: number) => {
                        const itemNF = item;
                        return {
                            id: itemNF.product.id,
                            description: itemNF.product.description,
                            qtd: itemNF.qtd,
                            unitValue: this.formatPrice(itemNF.totalValue / itemNF.qtd),
                            totalValue: this.formatPrice(itemNF.totalValue)
                        }
                    });
                }

                return response.data;
            }
        });
    }

    async GetInvoicesByClientContractYear(ContractId: string, ClientId: string, year: string) {
        const url = this.endpoint + `/api/FinancialManagement/${ClientId}/Contract/${ContractId}/EnvoicesByYear/${year}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data.map((item: any, index: number) => {
                return {
                    id: index,
                    ...item
                }
            });
        });
    }

    async GetInvoicesByClientGroupContractYear(year: Number) {
        const url = this.endpoint + `/api/FinancialManagement/EnvoicesByYear/${year}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data.map((item: any, index: number) => {
                return {
                    id: index,
                    ...item
                }
            });
        });
    }

    async GetInvoicesAdm(where: string, order?: string, skip?: number, take?: number) {
        const url = this.endpoint + `/api/FinancialManagement/GetInvoicesAdm?x=${where ? `&where=${where}` : ''}${order ? `&order=${order}` : ''}${skip ? `&skip=${skip}` : ''}${take ? `&take=${take}` : ''}`;
        return api({
            method: 'get',
            url,
        });
    }

}