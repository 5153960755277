import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import Marketing from '../../components/Marketing';

import './index.css';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { MenuState } from '../../store/reducers/menuReducer';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

const Home: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const history = useHistory();

    const [selectedSolution, setSelectedSolution] = useState<string | null>(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const handleSolutionClick = (solution: string) => {
        setSelectedSolution(solution);
    };

    const handleCloseClick = () => {
        setSelectedSolution(null);
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    const [groups, setGroups] = useState<any[]>([]);
    useEffect(() => {
        const solutions = menu.items.find(i => i.id === '6F96AEFB-3676-4995-9512-A15A6C617E1E');
        if (solutions) {
            let _s = solutions.children;
            // Agrupar as soluções por "group"
            let groupedSolutions = _s.reduce((groups, solution) => {
                let group = JSON.parse(solution.extraData).group;
                if (group != "None") {
                    if (!groups[group]) {
                        groups[group] = [];
                    }
                    groups[group].push(solution);
                }
                return groups;
            }, {});

            // Converter o objeto em um array e ordenar os grupos de acordo com a ordem desejada
            let _groups = Object.keys(groupedSolutions).sort((a, b) => {
                let indexA = groupedSolutions[a][0].menuOrder;
                let indexB = groupedSolutions[b][0].menuOrder;
                return indexA - indexB;
            }).map(group => {
                return {
                    title: group,
                    items: groupedSolutions[group]
                        .sort((a, b) => a.menuOrder - b.menuOrder) // Ordenar as soluções por "menuOrder"
                        .map(e => {
                            return {
                                name: e.name,
                                route: e.children.length > 0 ? e.children.sort((a, b) => a.menuOrder - b.menuOrder)[0].route : e.route,
                                permitido: e.permitido,
                                sufixo: JSON.parse(e.extraData).sufixo,
                                solution: JSON.parse(e.extraData).solution,
                                group: group
                            }
                        })
                };
            });
            setGroups(_groups);
        }
    }, [menu])
    //#region New Helper

    return (
        <Layout pageTitle="Início">

            <div className='homegrid'>
                <div className="groups">
                    {
                        groups.map((g, i) => {
                            return <div key={i} className={`group ${'group_' + i}`} >
                                <div className="header">
                                    <div className="imagem-gtm"> </div>

                                    <div className="title">
                                        {g.title}
                                    </div>
                                </div>

                                <div className="items scrollable-v">
                                    {
                                        g.items.map((t, i) => {
                                            const handleMouseOver = () => {
                                                if (!t.permitido) {
                                                    setTimeoutId(setTimeout(() => {
                                                        handleSolutionClick(t.solution);
                                                    }, 2500)); // 2500 milissegundos = 2,5 segundos
                                                }
                                            };

                                            const handleMouseOut = () => {
                                                if (timeoutId) {
                                                    clearTimeout(timeoutId);
                                                    setTimeoutId(null);
                                                }
                                            };

                                            return <div key={i} className={`item link${t.permitido ? '' : ' blocked'}`}
                                                onClick={() => {
                                                    return t.permitido ?
                                                        t.route.toString().includes("http") > 0
                                                            ?  window.open(t.route, '_blank')
                                                            : history.push("/" + t.route)
                                                        :
                                                        handleSolutionClick(t.solution);
                                                }}
                                                onMouseOver={handleMouseOver}
                                                onMouseOut={handleMouseOut}
                                            >
                                                {t.name}
                                                <small>{t.sufixo}</small>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>
                        })
                    }
                </div>
            </div>
            <Marketing selectedSolution={selectedSolution} onSolutionClose={handleCloseClick} />

            <style dangerouslySetInnerHTML={{
                __html: `
                        /* estilos para a layout */                                            
                        .container > .toolbar {
                            display: none !important;
                         }                        
                        body .container > .page {
                            height: calc(100vh) !important;
                        }
                        .group.group_6 {
                            display: none !important;
                        }                    
                `}} />
        </Layout >
    )
}
export default Home;
