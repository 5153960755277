import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';

import AlertBaselineLimitsService, { AlertBaselineLimitObj } from '../../services/alertBaselineLimit/alertBaselineLimitService'

import { useSelector } from 'react-redux'
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { useDebounce } from 'use-debounce/lib';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import { useSnackbar } from 'notistack';
import LcIconLink from '../../components/Generic/LcIconLink';
import SideModal from '../../components/Layout/SideModal/SideModal';
import { InputField } from '../../components/Form/Input';
import LcCheckBox from '../../components/Form/LcCheckBox';
import { Subject } from '@material-ui/icons';
import LcLoading from '../../components/Generic/LcLoading';
import { parse } from 'querystring';
import DialogConfirmForm from '../../components/Layout/Dialog/DialogConfirmForm';
import { isTSModuleDeclaration } from '@babel/types';



const AlertBaselineLimits: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecord, setFilteredRecord] = useState<any[]>([])
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
    const [visibleFilteredRecord, setVisibleFilteredRecord] = useState<any[]>([])
    const [selectedRow, setSelectedRow] = useState<AlertBaselineLimitObj | any>(undefined)
    const [isVisibleSideModalDetails, setIsVisibleSideModalDetails] = useState<boolean>(false)
    const [isVisibleSideModalCreate, setIsVisibleSideModalCreate] = useState<boolean>(false)
    const [isVisibleSideModalEdit, setIsVisibleSideModalEdit] = useState<boolean>(false)
    const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(false)
    const [idToDelete, setIdToDelete] = useState<number>(0)

    const { enqueueSnackbar } = useSnackbar();

    const emptyRecord: AlertBaselineLimitObj = {
        Body: '',
        InternalContactsEmail: '',
        LastAlertSend: new Date(),
        NumeroSip: null,
        PercentageAlert: 0,
        PercentageAlertOld: 0,
        SipContractId: null,
        Subject: '',
        UpdatedAt: new Date(),
        Id: 0,
        isCostAlert: false,
        isManualSend: false,
        isProcessor: false
    };
    const [recordSend, setRecordSend] = useState<AlertBaselineLimitObj>(emptyRecord)

    const [columns, setColumns] = useState<any[]>([
        { field: 'subject', headerName: 'Assunto', width: "40%" },
        { field: 'internalContactsEmail', headerName: 'Contato', width: "30%" },
        {
            field: 'percentageAlert', headerName: 'Alerta (%)', width: "15%",
            renderCell: (row: any) => {
                return <>{(row.percentageAlert as number * 100)}%</>
            }
        },
        {
            field: 'updatedAt', headerName: 'Atualizado ´ha', width: "15%",
            renderCell: (row: any) => {
                const date = new Date(row.updatedAt)

                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
                const year = date.getUTCFullYear().toString().padStart(2, '0')
                const hour = date.getHours().toString().padStart(2, '0')
                const minutes = date.getMinutes().toString().padStart(2, '0')

                return <p style={{ fontSize: 'small' }}>{day}/{month}/{year} - {hour}:{minutes}</p>
            }
        },
        {
            field: 'lastAlertSend', headerName: 'Ultimo envio', width: "15%",
            renderCell: (row: any) => {
                const date = new Date(row.lastAlertSend)

                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
                const year = date.getUTCFullYear().toString().padStart(2, '0')
                const hour = date.getHours().toString().padStart(2, '0')
                const minutes = date.getMinutes().toString().padStart(2, '0')

                return <>{day}/{month}/{year} - {hour}:{minutes}</>
            }
        },
        {
            field: 'isProcessor', headerName: 'Processor', width: "15%",
            renderCell: (row: any) => {
                const isProcessor = row.isProcessor ? true : false;
                if (isProcessor) {
                    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <i style={{ fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', width: '25', height: '25', fontSize: '1.3rem', color: 'rgb(165, 191, 62)' }} className="ppi ppi-check" />
                    </div>
                } else {
                    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <i style={{ fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', width: '25', height: '25', fontSize: '1.3rem', color: 'rgb(243, 201, 68)' }} className="ppi ppi-x" />
                    </div>

                }

            }
        },
        {
            field: 'isManualSend', headerName: 'Envio manual', width: "15%",
            renderCell: (row: any) => {
                const isManualSend = row.isManualSend ? true : false;
                if (isManualSend) {
                    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <i style={{ fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', width: '25', height: '25', fontSize: '1.3rem', color: 'rgb(165, 191, 62)' }} className="ppi ppi-check" />
                    </div>
                } else {
                    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <i style={{ fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', width: '25', height: '25', fontSize: '1.3rem', color: 'rgb(243, 201, 68)' }} className="ppi ppi-x" />
                    </div>

                }

            }
        },
    ])
    const [reload, setReload] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 25, term: "", column: "", direction: "asc" });

    const alertBaselineLimitService = new AlertBaselineLimitsService(props);

    useEffect(() => {
        setIsLoadingData(true)
        setRecords([]);
        setFilteredRecord([]);

        alertBaselineLimitService.GetListAlertLimits().then(response => {
            let obj = JSON.parse(response.data)
            setRecords(obj)
            setFilteredRecord(obj)
            setIsLoadingData(false)
        }).catch(error => {
            console.warn(error)
            setRecords([])
            setFilteredRecord([])
            setIsLoadingData(false)
        })


    }, [refresh, user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {
        var filtered = records.filter((p: any) =>
            filter.term == '' ||
            p.name.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });


        setFilteredRecord(filtered);
        setVisibleFilteredRecord(filtered.slice(0, filter.size));
    }, [filter, filteredRecord.length > 0]);

    const functions = [
        {
            id: "a84da730-d813-4200-a551-d93706d00e2e",
            title: 'Criar perfil',
            tooltip: "Criar",
            icon: 'ppi ppi-plus',
            func: () => {
                setIsVisibleSideModalCreate(!isVisibleSideModalCreate)
            }
        }
    ]

    const functionsRow = [
        {
            label: 'Detalhes',
            func: (row: any) => showDetails(row)
        },
        {
            label: 'Editar',
            func: (row: any) => EditRecord(row)
        },
        {
            label: 'Deletar',
            func: (row: any) => DeleteRecord(row.id)
        }
    ]

    const filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleFilteredRecord(filteredRecord.slice(0, size))
        else {
            setVisibleFilteredRecord([...visibleFilteredRecord, ...filteredRecord.slice(visibleFilteredRecord.length, visibleFilteredRecord.length + 10)])
        }
    };

    const showDetails = (row: any) => {
        setSelectedRow(row)
        setIsVisibleSideModalDetails(!isVisibleSideModalDetails)
    }

    const onChangeHandle = (field: any) => {
        switch (field.name) {
            case "subject":
                setRecordSend({ ...recordSend, Subject: field.value })
                break;

            case "numeroSip":
                setRecordSend({ ...recordSend, NumeroSip: field.value })
                break;

            case "internalContactsEmail":
                setRecordSend({ ...recordSend, InternalContactsEmail: field.value })
                break;

            case "body":
                setRecordSend({ ...recordSend, Body: field.value })
                break;

            case "isProcessor":
                setRecordSend({ ...recordSend, isProcessor: field.checked })
                break;

            case "isManualSend":
                setRecordSend({ ...recordSend, isManualSend: field.checked })
                break;

            case "isCostAlert":
                setRecordSend({ ...recordSend, isCostAlert: field.checked })
                break;

            case "percentageAlert":

                var number = (field.value as number / 100).toString()
                setRecordSend({ ...recordSend, PercentageAlert: parseFloat(number) })
                break;

            case "sipContractId":
                setRecordSend({ ...recordSend, SipContractId: field.value })
                break;

            default:
                break;
        }
    }

    const SendRecord = (method: 'save' | 'update') => {
        const service = new AlertBaselineLimitsService(props);

        switch (method) {
            case 'save':
                service.SaveRecord(
                    { ...recordSend, LastAlertSend: null, UpdatedAt: new Date() }
                ).then(response => {
                    if (response.status == 200) {
                        enqueueSnackbar(`Registro criado com sucesso`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                        setIsVisibleSideModalCreate(!isVisibleSideModalCreate)
                        setRecordSend(emptyRecord)
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    console.warn(err)
                    enqueueSnackbar(`Erro ao tentar criar o registro`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                })
                break;

            case 'update':

                service.Update(
                    { ...recordSend, LastAlertSend: null, UpdatedAt: new Date() }
                ).then(response => {
                    if (response.status == 200) {
                        enqueueSnackbar(`Registro editado com sucesso`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                        setIsVisibleSideModalEdit(!isVisibleSideModalEdit)
                        setRecordSend(emptyRecord)
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    console.warn(err)
                    enqueueSnackbar(`Erro ao tentar editar o registro`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                })
                break;

            default:
                break;
        }

    }

    const DeleteRecord = (id) => {
        setIdToDelete(id as number)
        setOpen(!open)
    }

    const EditRecord = (row: any) => {
        setIsVisibleSideModalEdit(!isVisibleSideModalEdit)

        setRecordSend(
            {
                Body: row.body,
                PercentageAlertOld: row.percentageAlert,
                Id: row.id,
                InternalContactsEmail: row.internalContactsEmail,
                LastAlertSend: row.lastAlertSend,
                NumeroSip: row.numeroSip,
                PercentageAlert: row.percentageAlert,
                SipContractId: row.sipContractId,
                Subject: row.subject,
                UpdatedAt: row.updatedAt,
                isCostAlert: row.isCostAlert,
                isManualSend: row.isManualSend,
                isProcessor: row.isProcessor
            }
        )
        setSelectedRow(row)
        setTimeout(() => {
            setIsLoadingEdit(false)
        }, 500);
    }

    const handleConfirmClose = (handledResponse: any) => {
        const service = new AlertBaselineLimitsService(props);
        setOpen(false)
        if (handledResponse) {
            service.Delete(idToDelete).then(response => {
                if (response.status == 200) {
                    enqueueSnackbar(`Registro deletado com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    })
                    setRefresh(!refresh)
                }
            }).catch(err => {
                console.warn(err)
                enqueueSnackbar(`Erro ao tentar deletar o registro`, {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })
            })
        }
    }

    return (
        <Layout pageTitle="Alerta de baseline">
            <LcInfiniteTable
                loading={isLoadingData}
                columns={columns}
                functionsRow={functionsRow}
                functionGeneric={functions}
                rows={visibleFilteredRecord}
                filter={filterAdvanced}
                size={filteredRecord.length}
                loadMore={loadMore}
                onSortChange={onSortChange}
            />

            <DialogConfirmForm onCloseEvent={handleConfirmClose}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Deseja excluir o alerta?' content='Esta operação é permanente e não pode ser revertida, deseja continuar?'>
            </DialogConfirmForm>

            <SideModal visible={isVisibleSideModalDetails} header={`Detalhes`} onClose={() => { setIsVisibleSideModalDetails(false); setSelectedRow(undefined) }}>
                {selectedRow &&
                    <div>
                        <p><b>Assunto:</b> {selectedRow.subject}</p>
                        <br />
                        <p><b>Corpo do aviso:</b></p>
                        <p>{selectedRow.body}</p>
                    </div>
                }
            </SideModal>

            <SideModal visible={isVisibleSideModalCreate} header={`Criação de alerta`} onClose={() => { setIsVisibleSideModalCreate(false) }}>
                <InputField onChange={onChangeHandle} label="Assunto" required type="text" name="subject" />
                <InputField onChange={onChangeHandle} label="SIP Id" type="text" name="sipContractId" />
                <InputField onChange={onChangeHandle} label="Número SIP" type="text" name="numeroSip" />
                <InputField onChange={onChangeHandle} label="Contato de email" required type="text" name="internalContactsEmail" />
                <InputField onChange={onChangeHandle} label="Corpo da mensagem" required type="text" name="body" />
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <InputField onChange={onChangeHandle} type="number" label="Porcentagem do alerta (%)" name="percentageAlert" required />
                </div>
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <LcCheckBox onChange={onChangeHandle} title="Processor" name="isProcessor" />
                    <LcCheckBox onChange={onChangeHandle} title="Envio manual" name="isManualSend" />
                    <LcCheckBox onChange={onChangeHandle} title="Alerta de custo" name="isCostAlert" />
                </div>
                <br />
                <button className="lc-button bg-primary" onClick={() => { SendRecord('save') }}>Salvar</button>

            </SideModal>

            <SideModal visible={isVisibleSideModalEdit} header={`Edição de alerta`} onClose={() => { setIsVisibleSideModalEdit(false) }}>

                <LcLoading loading={isLoadingEdit} label="Carregando..">
                    <InputField value={selectedRow?.subject} onChange={onChangeHandle} label="Assunto" required type="text" name="subject" />
                    <InputField value={selectedRow?.sipContractId} onChange={onChangeHandle} label="SIP Id" type="text" name="sipContractId" />
                    <InputField value={selectedRow?.numeroSip} onChange={onChangeHandle} label="Número SIP" type="text" name="numeroSip" />
                    <InputField value={selectedRow?.internalContactsEmail} onChange={onChangeHandle} label="Contato de email" required type="text" name="internalContactsEmail" />
                    <InputField value={selectedRow?.body} onChange={onChangeHandle} label="Corpo da mensagem" required type="text" name="body" />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <InputField value={selectedRow?.percentageAlerteader} onChange={onChangeHandle} type="number" label="Porcentagem do alerta (%)" name="percentageAlert" required />
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <LcCheckBox checked={selectedRow?.isProcessor} onChange={onChangeHandle} title="Processor" name="isProcessor" />
                        <LcCheckBox checked={selectedRow?.isManualSend} onChange={onChangeHandle} title="Envio manual" name="isManualSend" />
                        <LcCheckBox checked={selectedRow?.isCostAlert} onChange={onChangeHandle} title="Alerta de custo" name="isCostAlert" />
                    </div>
                    <br />
                    <button className="lc-button bg-primary" onClick={() => { SendRecord('update') }}>Salvar alterações</button>

                </LcLoading>

            </SideModal>
        </Layout>
    )
}

export default AlertBaselineLimits;