import api, { APIClient } from "./api";

export default class ReleaseNotesService extends APIClient { 

    constructor(props:any){
      super(props);
    }

    async GetAllWikiSubPages() {
        const url = this.endpoint + `/api/ReleaseNotes/GetAllWikiSubPages`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }    

    async GetWikiContentPage(version: string, relaseNotes: number = 0, path: string) {
        const url = this.endpoint + `/api/ReleaseNotes/GetWikiContentPage/${version}?releaseNotes=${relaseNotes}&path=${path? path : ''}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async GetRelesaseNotesReading(version: string) {
        const url = this.endpoint + `/api/ReleaseNotes/GetReleaseNotesReading/${version}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async Create(version: string) {
        const url = this.endpoint + `/api/ReleaseNotes/Create/${version}`;
        return api({
            method: 'post',
            url,
        }).then((response) => {
            return response.data;
        });
    }
}