import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import VendorCredentialList from './List';

const VendorCredentialHome: React.FC = () => {
  return (
    <Layout pageTitle="Assinaturas"  >
      <VendorCredentialList></VendorCredentialList>
    </Layout >
  );
};

export default VendorCredentialHome;