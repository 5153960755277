
export function getSymbolByCurrency(currency: string) {
    switch (currency) {
        case 'ALL':
            return 'Lek';
        case 'AFN':
            return '؋';
        case 'ARS':
            return '$';
        case 'AWG':
            return 'ƒ';
        case 'AUD':
            return '$';
        case 'AZN':
            return '₼';
        case 'BSD':
            return '$';
        case 'BBD':
            return '$';
        case 'BYN':
            return 'Br';
        case 'BZD':
            return 'BZ$';
        case 'BMD':
            return '$';
        case 'BOB':
            return '$b';
        case 'BAM':
            return 'KM';
        case 'BWP':
            return 'P';
        case 'BGN':
            return 'лв';
        case 'BRL':
            return 'R$';
        case 'BND':
            return '$';
        case 'KHR':
            return '៛';
        case 'CAD':
            return '$';
        case 'KYD':
            return '$';
        case 'CLP':
            return '$';
        case 'CNY':
            return '¥';
        case 'COP':
            return '$';
        case 'CRC':
            return '₡';
        case 'HRK':
            return 'kn';
        case 'CUP':
            return '₱';
        case 'CZK':
            return 'Kč';
        case 'DKK':
            return 'kr';
        case 'DOP':
            return 'RD$';
        case 'XCD':
            return '$';
        case 'EGP':
            return '£';
        case 'SVC':
            return '$';
        case 'EUR':
            return '€';
        case 'FKP':
            return '£';
        case 'FJD':
            return '$';
        case 'GHS':
            return '¢';
        case 'GIP':
            return '£';
        case 'GTQ':
            return 'Q';
        case 'GGP':
            return '£';
        case 'GYD':
            return '$';
        case 'HNL':
            return 'L';
        case 'HKD':
            return '$';
        case 'HUF':
            return 'Ft';
        case 'ISK':
            return 'kr';
        case 'INR':
            return '₹';
        case 'IDR':
            return 'Rp';
        case 'IRR':
            return '﷼';
        case 'IMP':
            return '£';
        case 'ILS':
            return '₪';
        case 'JMD':
            return 'J$';
        case 'JPY':
            return '¥';
        case 'JEP':
            return '£';
        case 'KZT':
            return 'лв';
        case 'KPW':
            return '₩';
        case 'KRW':
            return '₩';
        case 'KGS':
            return 'лв';
        case 'LAK':
            return '₭';
        case 'LBP':
            return '£';
        case 'LRD':
            return '$';
        case 'MKD':
            return 'ден';
        case 'MYR':
            return 'RM';
        case 'MUR':
            return '₨';
        case 'MXN':
            return '$';
        case 'MNT':
            return '₮';
        case 'MNT':
            return 'د.إ';
        case 'MZN':
            return 'MT';
        case 'NAD':
            return '$';
        case 'NPR':
            return '₨';
        case 'ANG':
            return 'ƒ';
        case 'NZD':
            return '$';
        case 'NIO':
            return 'C$';
        case 'NGN':
            return '₦';
        case 'NOK':
            return 'kr';
        case 'OMR':
            return '﷼';
        case 'PKR':
            return '₨';
        case 'PAB':
            return 'B/.';
        case 'PYG':
            return 'Gs';
        case 'PEN':
            return 'S/.';
        case 'PHP':
            return '₱';
        case 'PLN':
            return 'zł';
        case 'QAR':
            return '﷼';
        case 'RON':
            return 'lei';
        case 'RUB':
            return '₽';
        case 'SHP':
            return '£';
        case 'SAR':
            return '﷼';
        case 'RSD':
            return 'Дин.';
        case 'SCR':
            return '₨';
        case 'SGD':
            return '$';
        case 'SBD':
            return '$';
        case 'SOS':
            return 'S';
        case 'KRW':
            return '₩';
        case 'ZAR':
            return 'R';
        case 'LKR':
            return '₨';
        case 'SEK':
            return 'kr';
        case 'CHF':
            return 'CHF';
        case 'SRD':
            return '$';
        case 'SYP':
            return '£';
        case 'TWD':
            return 'NT$';
        case 'THB':
            return '฿';
        case 'TTD':
            return 'TT$';
        case 'TRY':
            return '₺';
        case 'TVD':
            return '$';
        case 'UAH':
            return '₴';
        case 'AED':
            return 'د.إ';
        case 'GBP':
            return '£';
        case 'USD':
            return 'U$';
        case 'UYU':
            return '$U';
        case 'UZS':
            return 'лв';
        case 'VEF':
            return 'Bs';
        case 'VND':
            return '₫';
        case 'YER':
            return '﷼';
        case 'ZWD':
            return 'Z$';
        default:
            return 'R$';
    }
}

export function formatDecimal(value: number, locales?: string) {
    return new Intl.NumberFormat(locales || "pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

export function filterDecimalInput(value: string) {
    let r = value;
    r = r.replace(/\D/g, "");
    r = r || "0";
    r = r.substring(0, r.length - 2) + '.' + (r.length == 1 ? '0' : '') + r.substring(r.length - 2);
    return r
}

export function formatCurrency(value: number, locales?: string) {
    return Intl.NumberFormat(locales || "pt-BR", { style: 'currency', currency: 'BRL' }).format(value);
}

export function formatNumber(value: number, locales?: string) {
    return new Intl.NumberFormat(locales || "pt-BR").format(value);
}