import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

// Define a type for the file structure
type FileNode = {
    key: string;
    label: string;
    size: number | null;
    lastModified: string | null;
    storageClass: string | null;
    children: FileNode[];
    urlDownload?: string;
    clientAccessHeyHo: boolean;
};

// Define the type for the props expected by the component
export type LatestFileProps = {
    fileTree: FileNode;
};

const LatestFileDownload = ({ fileTree }: LatestFileProps) => {
    const [latestFileUrl, setLatestFileUrl] = useState<string>('');
    const [latestFileUrlName, setLatestFileUrlName] = useState<string>('');
    const [repositoryFile, setRepositoryFile] = useState<FileNode>();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();


    useEffect(() => {
        // //debugger;
        // Flatten the file tree into an array of files
        const flattenFiles = (node: FileNode, array: FileNode[] = []): FileNode[] => {
            try {
                if (node.children && node.children.length > 0) {
                    node.children.forEach((child) => flattenFiles(child, array));
                } else {
                    if (node.urlDownload) {
                        array.push(node);
                    }
                }
                return array;
            } catch (e) {
                return [];
            }
        };
        // Sort the files by lastModified in descending order
        const sortedFiles = flattenFiles(fileTree).sort((a, b) => {
            const dateA = a.lastModified ? new Date(a.lastModified) : new Date(0);
            const dateB = b.lastModified ? new Date(b.lastModified) : new Date(0);
            return dateB.getTime() - dateA.getTime();
        });

        // Get the URL of the latest file
        if (sortedFiles.length > 0) {
            setRepositoryFile(sortedFiles[0]);
            setLatestFileUrl(sortedFiles[0].urlDownload!);
            setLatestFileUrlName(sortedFiles[0].key);
        }
    }, [fileTree]);

    const handleDownloadFile = async (file: FileNode) => {
        if(file.storageClass === "DEEP_ARCHIVE") {
            enqueueSnackbar(`Para baixar o arquivo ${file.label} é preciso abrir um chamado.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            });
            dispatch({ type: 'MENU_NEW_REQUEST', payload: true });
        }else {
            window.open(file.urlDownload, '_blank')
        }
    }

    return (
        <div style={{
            minWidth: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }}>
            {latestFileUrl ? (
                <a rel="noopener noreferrer" className='link text-link' onClick={() => repositoryFile && handleDownloadFile(repositoryFile)}>
                    {latestFileUrlName}
                </a>
            ) : (
                <span style={{ fontSize: '12px' }}>Sem dados.</span>
            )}
        </div>
    );
};

export default LatestFileDownload;