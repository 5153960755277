import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { SaaSGroupService } from '../../../services/saasGroupService';
import DetailForm from '../../VendorCredential/DetailForm';
import SaaSGroupMembersListLinked from '../Members/ListLinked';
import { useSnackbar } from 'notistack';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import LcIconLink from '../../../components/Generic/LcIconLink';
import LcTooltip from '../../../components/Generic/LcTooltip';

//Img Fluxo
import ImgFluxoCSP from '../../../assets/images/Fluxos/csp.png';
import { SaasService } from '../../../services/saas/saasService';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { PPForm2, PPModal } from 'processor-plataform-ui';
import LcLoading from '../../../components/Generic/LcLoading';
import { CacheManagerService } from '../../../services/cacheManagerService';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import PPFormWithWizard from '../../../components/Form/PPFormModal';
import { SaaSGroup } from '../Model/GroupSaaS';
import { PiCloudLight, PiHardDrivesLight, PiInfoLight, PiPlusLight, PiUsersThreeLight } from 'react-icons/pi';

const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}
interface DataCollection {
    canCreate: boolean;
    canSetMembers: boolean;
    description: string;
    displayName: string;
    domain: any;
    groupTypes: any;
    id: string;
    mail: string;
    mailEnabled: boolean;
    mailNickname: string
    onPremisesDomainName: any;
    onPremisesSyncEnabled: any;
    securityEnabled: boolean;
    type: number;
    typeName: string;
    types: ArrayTypes[]
}

interface ArrayTypes {
    id: number;
    text: string;
    type: number
}

const SaaSGroupList: React.FC = (props) => {

    // const constructLiveWatchServiceGroupService = (): SaaSGroupService => {
    //     return new SaaSGroupService(props);
    // }

    const CacheManager_Service = new CacheManagerService(props);

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ServiceInstanceSaaSGroup: SaaSGroupService = new SaaSGroupService(props);
    const [emptyRecordMessageB, setEmptyRecordMessageB] = React.useState({ detail: false, edit: false, add: false, members: false, record: ServiceInstanceSaaSGroup.emptyRecord });


    const [functionsEdit, setFunctionsEdit] = useState<any>({
        ...ServiceInstanceSaaSGroup.ActionsBaseFunctions((item: any) => { Save(item) }, () => { setRecord({ ...{ detail: false, edit: false, add: false, members: false, record: ServiceInstanceSaaSGroup.emptyRecord } }) }, null)
    });
    const [handleform, setHandleform] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [displayName, setDisplayName] = useState<any>();
    const [onPremiseWarning, setOnPremiseWarning] = useState<boolean>(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [idsToDelete, setIdsToDelete] = useState<string[]>(['']);
    const [listData, setListData] = useState<DataCollection[]>([]);
    const [fullDataRowCount, setFullDataRowCount] = useState<number>(0);
    const [visibleRows, setVisibleRows] = useState<number>(25);
    const [record, setRecord] = useState({ detail: false, edit: false, add: false, members: false, record: ServiceInstanceSaaSGroup.emptyRecord });
    const [filter, setFilter] = useState<any>();
    const [template, setTemplate] = useState<any>();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [openError, setOpenError] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<string>("");

    const history = useHistory();

    const refreshLastUpdate = () => {
        CacheManager_Service.LastSyncUpdate("LiveCloudSyncSaasService", "SaaSGroupConsolidateds")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    useEffect(() => {
        setListData([]);
        async function retrieveData() {
            let f = ServiceInstanceSaaSGroup.getFilter();
            f = { ...f, start: 0, size: visibleRows };
            setFilter(f);
        }
        setTimeout(() => retrieveData(), 300);
        refreshLastUpdate();

    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {

        async function retrievePagedDataRequest() {
            if (filter) {
                setLoading(true);

                ServiceInstanceSaaSGroup.RetrieveList(filter)
                    .then(response => {
                        setFullDataRowCount(response.data.recordCount);
                        filter.start === 0 ? setListData(response.data.dataCollection) : setListData([...listData, ...response.data.dataCollection]);
                    })
                    .catch((error) => {
                        setListData([]);
                        console.warn(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
        setTimeout(() => retrievePagedDataRequest(), 300);
    }, [filter])

    const initTemplate = async () => {
        setEnableCreate(false);
        async function executeUseEffect() {
            enqueueSnackbar("Verificação das informações sobre as subscrições em andamento", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            });
            await ServiceInstanceSaaSGroup.initialize();

            let EnumDomains: any[] = await ServiceInstanceSaaSGroup.getDomains();
            setEnableCreate((EnumDomains.length > 0) ? true : false);
            if (EnumDomains.length == 0) {
                enqueueSnackbar('Não foram encontradas domínios válidos, entre em contato com o suporte', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
            } else {
                enqueueSnackbar("O cadastro de grupo para a organização está em conformidade", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            }
            // let templateS = { ...(await ServiceInstanceSaaSGroup.template(false)) };
            let templateS = { ...(await ServiceInstanceSaaSGroup.getFormFieldsData()) };
            setTemplate(templateS);
        }
        setTimeout(() => executeUseEffect(), 300);
    }

    useSingleton(() => {
        // setServiceInstanceSaaSGroup && setServiceInstanceSaaSGroup(new SaaSGroupService(props));
        initTemplate();
    })

    useEffect(() => { initTemplate() }, [user.ClientGroupSelected])

    const deletLinha = (row: any) => {
        let ids: any[] = [];
        ids.push(row.id);
        DeleteButtonCall(ids);
    }

    const listMembersLinha = (id: any) => {
        let ids: any[] = [];
        ids.push(id);
        if (ids != undefined && ids != null && ids.length > 0) {
            ids.map(id => { showMembers(id); });
        }
    }

    const loadMore = (size?: number) => {
        if (size) {
            setVisibleRows(size);
            setListData([]);
            //Se tem "size" é a primeira chamada com a quantidade de linhas calculada
            let f = ServiceInstanceSaaSGroup.getFilter();
            f = { ...f, size: size };
            setFilter(f);
        } else {
            setFilter({ ...filter, start: listData.length, size: 10 });
        }
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setListData([]);
        setFilter({ ...filter, start: 0, size: size, ordered: sort.column, direction: sort.direction });
    }

    const columns = [
        { field: 'displayName', headerName: 'Nome do grupo', width: '42%' },
        { field: 'mailNickname', headerName: 'Email', width: '42%' },
        { field: 'typeName', headerName: 'Tipo', width: '16%' },
        {
            field: 'onPremisesSyncEnabled', headerName: 'Origem', width: '84px', overflow: 'visible', align: 'center',
            renderCell: (row: any) => {
                return (
                    <LcTooltip trigger="hover" content={(row.onPremisesSyncEnabled as boolean) ? "OnPremise" : "Cloud"}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>                            
                            {
                                row.onPremisesSyncEnabled ? (
                                    <PiHardDrivesLight size={20} />
                                ) : (
                                    <PiCloudLight size={20} />
                                )
                            }
                        </div>
                    </LcTooltip>
                )
            }
        },
        {
            field: '',
            width: '120px',
            overflow: 'visible',
            headerName: 'Ações',
            align: 'center',
            sort: false,
            renderCell: (row: any) => {
                let actions = [
                    {
                        label: 'Detalhes',
                        icon: <PiInfoLight />,
                        func: (row: any) => {
                            selecionadoEditar(row.id);
                        }
                    },
                    {
                        label: 'Membros',
                        icon: <PiUsersThreeLight />,
                        func: (row: any) => {
                            listMembersLinha(row.id.toString())
                        }
                    }
                ]

                return (
                    <>
                        {
                            actions.map((r, k) => {
                                return (
                                    <LcIconLink
                                        key={k}
                                        icon={r.icon}
                                        tooltip={r.label}
                                        onClick={() => { r.func(row); }}
                                        size="small"
                                    />
                                )
                            })
                        }
                    </>
                );
            },
        },
    ];

    const functionsRow = [
        {
            label: 'Excluir',
            func: deletLinha,
            disabled: (row: any) => { return row.onPremisesSyncEnabled },
        }
    ]

    const functionsGeneric = [
        {
            title: 'Novo',
            tooltip: 'Criar grupo',
            icon: <PiPlusLight />,
            onClick: () => { setShowModal(true); ADD(); },
            disabled: !enableCreate
        }
    ]

    const handleDeleteClickOpen = () => {
        setOpen(true);
    };

    const handleDeleteClose = async (deleteConfirm: boolean) => {
        if (deleteConfirm) {
            idsToDelete.map((iddel) => { Delete(iddel); });
            setIdsToDelete([]);
        } else {
            setIdsToDelete([]);
        }
        setOpen(false);
    };

    const DeleteButtonCall = async (ids: any[]) => {
        setIdsToDelete(ids);
        handleDeleteClickOpen();
        record.detail = false;
        setListData([]);
        setFilter({ ...filter, start: 0, size: visibleRows });
    }

    const showMembers = async (id: any) => {
        var selectedRows = listData.filter(x => x.id === id);

        if (selectedRows.length > 0) {
            let addRecord = { ...emptyRecordMessageB };
            addRecord.record = { ...selectedRows[0] };
            addRecord.members = true;
            setRecord({ ...addRecord });
        }
    }

    function Save(item: any) {
        setIsLoadingForm(true);
        messageFlow("Criação do grupo");
        console.log(item)
        ServiceInstanceSaaSGroup.Save(item)
            .then((items: any) => {
                setIsLoadingForm(false); setLoading(false);
                setRecord({ ...emptyRecordMessageB });
                enqueueSnackbar('Grupo criado com sucesso', {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
                setListData([]);
                setFilter({ ...filter, start: 0, size: visibleRows });
            }).catch((error) => {
                setOpenError(true);
                enqueueSnackbar(`Problema ao criar grupo. ${error.response.data}`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
            })
            .then(() => { setIsLoadingForm(false); setLoading(false); setRecord({ ...emptyRecordMessageB }); })
            .finally(() => { setShowModal(false) })
    }

    const messageFlow = (action: string) => {
        enqueueSnackbar(` ${action} foi iniciada, aguarde alguns instantes`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        // setTimeout(() => enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'info',
        //     preventDuplicate: true,
        //     persist: false,
        //     autoHideDuration: 5000,
        // }), 5000);
        // setTimeout(() => loading && enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'info',
        //     preventDuplicate: true,
        //     persist: false,
        // }), 13000);
        // setTimeout(() => loading && enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'info',
        //     preventDuplicate: true,
        //     persist: false,
        // }), 20000);
    }

    const Delete = async (id: string) => {
        setLoading(true);
        messageFlow("Remoção de grupo");
        listData.filter(x => x.id === id).map((itemDel) => {
            ServiceInstanceSaaSGroup.Delete(itemDel.id, itemDel.type).then(items => {
                enqueueSnackbar('Grupo removido com sucesso', {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
                setListData([]);
                setFilter({ ...filter, start: 0, size: visibleRows });
            }).catch((err) => {
                setOpenError(true);
                enqueueSnackbar('Erro na remoção de grupo', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
            });
        });
    }

    const ADD = async () => {
        setDisplayName("Criação de grupo")
        let addRecord = {
            record: ServiceInstanceSaaSGroup.emptyRecord,
            edit: false,
            add: true,
            detail: false,
            members: false,
        };
        setRecord(addRecord);
        setFunctionsEdit({
            ...ServiceInstanceSaaSGroup.ActionsBaseFunctions((NewRecord: any) => { Save(NewRecord) },
                () => { setRecord({ ...emptyRecordMessageB }) }, null)
        });
    }

    const EDIT = async (item: any) => {
        setShowModal(true);
        // setIsLoadingForm(true);
        // setRecord(emptyRecordMessageB);
        // let addRecord = { ...emptyRecordMessageB };
        let addRecord = {
            record: item,
            edit: true,
            add: false,
            detail: false,
            members: false,
        };
        setRecord(addRecord);
        let template = ServiceInstanceSaaSGroup.getFormFieldsData(true)
        setTemplate(template);
        setFunctionsEdit({
            ...ServiceInstanceSaaSGroup.ActionsBaseFunctions(null,
                () => { setRecord({ ...emptyRecordMessageB }) }, () => { setRecord({ ...emptyRecordMessageB }) })
        });

        // setIsLoadingForm(false);
    }

    const handleChange = (form: any) => {
        if (form.validated) {
            let newGroup = record.record;
            form.data.map(field => {
                if (field.value != undefined)
                    newGroup[field.name] = field.value;
            })
            Save(newGroup)
        } else {
            enqueueSnackbar('Validar campos do formulário', {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
        }
    }

    const selecionadoEditar = async (childData: any) => {
        if (childData != null) {
            var item = listData.filter(x => x.id == childData)[0];
            setDisplayName(item.displayName)
            setOnPremiseWarning(item.onPremisesSyncEnabled)
            EDIT(item);
        }
    }

    //#region Verifica se há GTM CSP
    const saasService = new SaasService(props);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    const handleClose = () => {
        setOpenError(false);
    }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const card: Card[] = [
        {
            id: 1,
            hideHeader: true,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <>
                        {
                            showFluxoCSP ?
                                <div className="lc-segment">
                                    <div className="body">
                                        Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                        fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                                        <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                                    </div>
                                </div>
                                :
                                <LcInfiniteTable
                                    loading={loading}
                                    columns={columns}
                                    rows={listData}
                                    loadMore={loadMore}
                                    filter={filterAdvanced}
                                    size={fullDataRowCount}
                                    functionsRow={functionsRow}
                                    onSortChange={onSortChange}
                                    density={pref.data?.density || "high"}
                                    disableFilterModal
                                />
                        }
                    </>
                )
            }
        }
    ];

    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'filtro', type: 'text' },
        ],
        onChange: (f: any, _size: number) => {
            setListData([]);

            setFilter({
                ...filter,
                start: 0,
                size: _size,
                filter: f[0].value,
            });
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={(updatedFilter, size) => {
                    filterAdvanced.onChange(updatedFilter, size || 10);
                }}
            />
        );
    };


    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    //#region Handlers do novo modal de formulário de Grupos
    const handleExitCreateGroup = () => {
        setShowModal(false);
        setRecord({ ...record, edit: false, add: false, record: ServiceInstanceSaaSGroup.emptyRecord });
        setFilter({ ...filter });
        closeSnackbar();
        setDisplayName(undefined);
    }

    const handleCreateGroup = async (data) => {
        setIsLoadingForm(true);
        messageFlow("Criação do grupo");

        let newGroup: SaaSGroup = record.record;
        Object.keys(data).map(field => {
            if (data[field] != undefined)
                newGroup[field] = data[field];
        })
        console.log(newGroup)
        try {
            var result = await ServiceInstanceSaaSGroup.Save(newGroup);
            setIsLoadingForm(false); setLoading(false);
            setRecord({ ...emptyRecordMessageB });
            enqueueSnackbar('Grupo criado com sucesso', {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            })
            setListData([]);
            setFilter({ ...filter, start: 0, size: visibleRows });
        } catch (e) {
            var error = e as { response: { data: any } };
            setOpenError(true);
            error && error.response && enqueueSnackbar(`Problema ao criar grupo. ${error.response.data}`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false
            })
        }

        setIsLoadingForm(false);
        setLoading(false);
        setRecord({ ...emptyRecordMessageB });
        setShowModal(false);

    }

    //#endregion

    return (
        <Layout
            pageTitle="Gestão de Grupos"
            functionsGeneric={functionsGeneric}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: fullDataRowCount
            }}
            lastUpdate={lastUpdate}
        >
            {
                <Snackbar
                    key={'error'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openError}
                    autoHideDuration={4000}
                    TransitionProps={{ onExited: handleClose }}
                >
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        action={
                            <div>
                                <Button color="secondary" size="large" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>
                                    <i className="lci lci-hand" style={{ color: '#611A15' }} />
                                </Button>
                                <IconButton aria-label="close"
                                    color="secondary"
                                    onClick={handleClose}
                                >
                                    x
                                </IconButton>
                            </div>
                        }
                    >
                        Houve um problema na comunicação com o fabricante. Por favor tente mais tarde. Caso precise podemos fazer isto por você através de uma requisição.
                    </Alert>
                </Snackbar>}
            {
                showFluxoCSP ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <>
                        <DialogConfirmForm onCloseEvent={handleDeleteClose}
                            open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
                        </DialogConfirmForm>

                        <PPModal
                            visible={record.detail}
                            onClose={() => { setRecord({ ...record, detail: false }); closeSnackbar(); }}
                            title={record.record.displayName}
                        >
                            <DetailForm
                                data={record.record}
                                template={template}
                                handleform={handleform}
                                visible={record.detail == true}
                                onClose={() => { setRecord({ ...record, detail: false }); closeSnackbar(); }}
                            />
                        </PPModal>

                        {/* <PPModal
                            visible={showModal}
                            onClose={() => {
                                setShowModal(false);
                                setRecord({ ...record, edit: false, add: false, record: ServiceInstanceSaaSGroup.emptyRecord });
                                setFilter({ ...filter });
                                closeSnackbar();
                                setDisplayName(undefined);
                            }}
                            title={displayName || "Criar grupo"}
                        >
                            {template && (record.edit || record.add) && !isLoadingForm ?
                                <>
                                    {
                                        onPremiseWarning &&
                                        <div style={{ display: 'flex', padding: '10px', background: '#f1f5fb', marginBottom: '5px' }}>
                                            <i className="ppi ppi-information-circle"></i>
                                            <div style={{ marginLeft: '5px', fontWeight: '350', fontSize: '14px' }}>
                                                Você só pode gerenciar este grupo em seu ambiente local. Use Ferramentas
                                                do "Centro de Administração do Exchange" ou de "grupos e usuários do Active
                                                Directory" para editar ou excluir este grupo.
                                            </div>
                                        </div>
                                    }
                                    <PPForm2
                                        fields={template}
                                        submitting={isLoadingForm}
                                        onSubmit={handleChange}
                                        functions={{
                                            hideFunctions: record.edit
                                        }}
                                    />
                                </>
                                :
                                <LcLoading loading={isLoadingForm} />
                            }
                        </PPModal> */}
                        {template && (record.edit || record.add) ?
                            <>
                                {
                                    onPremiseWarning &&
                                    <div style={{ display: 'flex', padding: '10px', background: '#f1f5fb', marginBottom: '5px' }}>
                                        <i className="ppi ppi-information-circle"></i>
                                        <div style={{ marginLeft: '5px', fontWeight: '350', fontSize: '14px' }}>
                                            Você só pode gerenciar este grupo em seu ambiente local. Use Ferramentas
                                            do "Centro de Administração do Exchange" ou de "grupos e usuários do Active
                                            Directory" para editar ou excluir este grupo.
                                        </div>
                                    </div>
                                }
                                <PPFormWithWizard
                                    mode={record.add ? "create" : 'edit'}
                                    type={record.add ? "wizard" : 'fieldset'}
                                    title={displayName}
                                    size="large"
                                    show={showModal}
                                    data={record.add ? {} : record.record}
                                    formFields={template}
                                    onOpen={() => { }}
                                    onSave={handleCreateGroup}
                                    onCancel={handleExitCreateGroup}
                                    onClose={handleExitCreateGroup}
                                // btnSaveTextLabel='Criar grupo'
                                />
                            </>
                            :
                            <LcLoading loading={isLoadingForm} />
                        }

                        <PPModal
                            visible={record.members}
                            size="large"
                            title={record.record.displayName}
                            onClose={() => { setRecord({ ...record, members: false }); setFilter({ ...filter }); closeSnackbar(); }}
                        >
                            <SaaSGroupMembersListLinked
                                handleform={handleform}
                                data={record.record} template={template}
                                visible={record.members}
                                onClose={() => { setRecord({ ...record, members: false }); setFilter({ ...filter }); closeSnackbar(); }}
                            />
                        </PPModal>
                    </>
            }

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
        </Layout >
    )
}

export default SaaSGroupList;