import { PPExpand } from 'processor-plataform-ui';
import React from 'react';
import ppicons from './ppicons.json';
import ppficons from './ppficons.json';
import icons from './icons.json';

const TabIcons: React.FC = () => {
    return (
        <div className='iconspackage'>

            <PPExpand title="LC Icons">
                <div className="icons">
                    {icons.map((icon, index) => (
                        <div key={index} className="icon">
                            <i className={icon} />
                            <span className="name">{icon}</span>
                        </div>
                    ))}
                </div>
            </PPExpand>

            <PPExpand title="LC File Icons">

                <div className="icons">
                    {ppficons.map((icon, index) => (
                        <div key={index} className="icon">
                            <i className={icon} />
                            <span className="name">{icon}</span>
                        </div>
                    ))}
                </div>
            </PPExpand>

            <PPExpand title="PP Icons">
                <div className="icons">
                    {ppicons.map((icon, index) => (
                        <div key={index} className="icon">
                            <i className={icon} />
                            <span className="name">{icon}</span>
                        </div>
                    ))}
                </div>
            </PPExpand>
        </div>
    );
}

export default TabIcons;