export class erp {
    solution() {
        return 'erp'
    }

    site() {
        return 'https://www.processor.com.br/nucleo-erp'
    }

    logotipo() {
        return require('./assets/logotipos/logo_erp.svg').default
    }

    titulo() {
        return <>Tenha uma solução de <b>gestão ágil</b> e adaptável às mudanças de seus negócios        </>
    }

    chamada() {
        return <>Solução modular e integrada de gestão empresarial para pequenas e médias empresas. Versátil e adequada para diversos segmentos, como indústrias, serviços, distribuição, construção, metalurgia, saúde, química, alimentos e bebidas.</>
    }

    image() {
        return require('./assets/imgs/imagem_erp.png').default
    }

    detalhes() {
        return <>
            <h2>Preparado para levar sua gestão ao próximo nível?</h2>
            <p><b>O Núcleo ERP pode revolucionar a administração da sua empresa, integrando processos e melhorando a eficiência operacional.</b></p>
            <h3>Por que escolher o Núcleo ERP</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_erp.png').default} />
                </div>
                <ul>
                    <li><b>Flexibilidade:</b> Implementação rápida e flexível com sistemas sempre atualizados.</li>
                    <li><b>Economia:</b> Pague apenas pelo que usar, sem a necessidade de grandes investimentos iniciais em software.</li>
                    <li><b>Produtividade:</b> Reduza a necessidade de conhecimentos tecnológicos especializados, focando no sucesso do seu negócio.</li>
                    <li><b>Suporte especializado:</b> Nossa equipe altamente qualificada e certificada está pronta para atender às demandas mais complexas.</li>
                    <li><b>Integração e colaboração:</b> Facilita a troca de dados e informações entre diferentes departamentos, promovendo a colaboração interna.</li>
                    <li><b>Melhor tomada de decisão:</b> Com nossas soluções, gestores podem tomar decisões mais informadas e baseadas em dados.</li>
                    <li><b>Eficiência operacional aprimorada:</b> Nossas soluções ajudam a otimizar e automatizar processos empresariais, aumentando a eficiência.</li>
                    <li><b>Acesso remoto e mobilidade:</b> Permita que seus colaboradores acessem dados e aplicações de qualquer lugar, a qualquer hora.</li>
                    <li><b>Otimização de custos:</b> Maximize seus investimentos iniciais, pagando somente pelo que utiliza.</li>
                </ul>
            </div>
            <div className="box">O Núcleo ERP é mais do que uma solução de software - é <b>a chave para desbloquear o verdadeiro potencial da sua empresa</b>.</div>
            <h3>Não perca tempo!</h3>
            <p>Entre em contato conosco hoje mesmo e descubra como o Núcleo ERP pode transformar a gestão da sua empresa.</p>         
        </>
    }
}
export default erp;
