import React, { useEffect, useState } from 'react';
import IconValue from '../../Dashboard/IconValue';
import LcLoading from '../../../Generic/LcLoading';
import ManageOfficeStatusService from '../../../../services/manageOfficeStatus/manageOfficeStatus';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { UserState } from '../../../../store/reducers/userReducer';
import { PiCheckCircleLight, PiInfoLight, PiWarningCircleLight } from 'react-icons/pi';
import LcTooltip from '../../../Generic/LcTooltip';

interface HealthCheckProps {
    isHome?: boolean;
}

export interface IconColorResult {
    color: string;
    icon: React.ReactNode;
}

export function getIconAndColorSecure(value: number, isHome: boolean): IconColorResult {
    const iconSize = isHome ? 24 : 32;

    if (value === 1) {
        return {
            color: "var(--status-green)", // Verde
            icon: <PiCheckCircleLight size={iconSize} />
        };
    } else if (value === 2) {
        return {
            color: 'var(--status-red)', // Vermelho
            icon: <PiWarningCircleLight size={iconSize} />
        };
    } else {
        return {
            color: 'var(--status-yellow)', // Amarelo
            icon: <PiInfoLight size={iconSize} />
        };
    }
}

export const HealthCheckCard: React.FC<HealthCheckProps> = ({ isHome = false }) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const serviceManagedOfficeStatus = new ManageOfficeStatusService({});
    const [recordsMonitoramentoStatusSuccess, setRecordsMonitoramentoStatusSuccess] = useState<number>(0);
    const [recordsMonitoramentoStatusError, setRecordsMonitoramentoStatusError] = useState<number>(0);
    const [recordsMonitoramentoStatusWarning, setRecordsMonitoramentoStatusWarning] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const statusEnum = [
        { statusNuvem: 'Investigating', statusLC: 'warning' },
        { statusNuvem: 'ServiceDegradation', statusLC: 'warning' },
        { statusNuvem: 'ServiceInterruption', statusLC: 'danger' },
        { statusNuvem: 'RestoringService', statusLC: 'warning' },
        { statusNuvem: 'ExtendedRecovery', statusLC: 'warning' },
        { statusNuvem: 'InvestigationSuspended', statusLC: 'warning' },
        { statusNuvem: 'ServicesRestored', statusLC: 'success' },
        { statusNuvem: 'FalsePositive', statusLC: 'success' },
        { statusNuvem: 'PostIncidentReportPublished', statusLC: 'success' },
        { statusNuvem: 'ServiceOperational', statusLC: 'success' },
    ];

    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await serviceManagedOfficeStatus.GetServices();
                let data = response.data;
                let mappedData: any[] = data.map((e: any) => {
                    const date = new Date(e.statusTime);
                    const formatted = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                    return {
                        id: e.id,
                        workloadDisplayName: e.workloadDisplayName,
                        workload: e.workload,
                        credentialAlias: e.credentialAlias,
                        statusTime: formatted,
                        status: statusEnum.find(x => x.statusNuvem === e.status)?.statusLC || 'info'
                    };
                });

                let sortedArray = mappedData.sort((a, b) => a.status.localeCompare(b.status) || a.workloadDisplayName.localeCompare(b.workloadDisplayName));

                setRecordsMonitoramentoStatusSuccess(sortedArray.filter(v => v.status === 'success').length);
                setRecordsMonitoramentoStatusWarning(sortedArray.filter(v => v.status === "warning").length);
                setRecordsMonitoramentoStatusError(sortedArray.filter(v => v.status !== "warning" && v.status !== "success").length);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        setLoading(true);
        getServices();
    }, [user.refreshFlag, user.ClientGroupSelected]);

    return (
        <LcLoading loading={loading}>
            <div style={{ minWidth: '100%', display: 'flex' }}>
                <div style={{ minWidth: '30%', display: 'flex', flexDirection: 'column', height: '70%', alignItems: 'center' }}>
                    {isHome ? (
                        <LcTooltip content="Serviços Ativos" position='center' trigger='hover'>
                            <IconValue
                                isHome={isHome}
                                cssClass={'office-services'}
                                icon={getIconAndColorSecure(1, isHome).icon}
                                color={getIconAndColorSecure(1, isHome).color}
                                value={recordsMonitoramentoStatusSuccess}
                                maximumFractionDigits={0}
                                formatValue={true}
                                colorValue='#2B304F'
                            />
                        </LcTooltip>
                    ) : (
                        <IconValue
                            isHome={isHome}
                            cssClass={'office-services'}
                            icon={getIconAndColorSecure(1, isHome).icon}
                            color={getIconAndColorSecure(1, isHome).color}
                            value={recordsMonitoramentoStatusSuccess}
                            maximumFractionDigits={0}
                            formatValue={true}
                            colorValue='#2B304F'
                        />
                    )}
                    {!isHome && <p style={{ fontSize: '11px', color: '#2B304F', textAlign: 'center', marginTop: '2px' }}>Ativos</p>}
                </div>

                <div style={{ marginLeft: '15px', marginRight: '15px', minWidth: '30%', display: 'flex', flexDirection: 'column', height: '70%', alignItems: 'center' }}>
                    {isHome ? (
                        <LcTooltip content="Serviços com Atenção" position='center' trigger='hover'>
                            <IconValue
                                isHome={isHome}
                                cssClass={'office-services'}
                                icon={getIconAndColorSecure(3, isHome).icon}
                                color={getIconAndColorSecure(3, isHome).color}
                                value={recordsMonitoramentoStatusWarning}
                                maximumFractionDigits={0}
                                formatValue={true}
                                colorValue='#2B304F'
                            />
                        </LcTooltip>
                    ) : (
                        <IconValue
                            isHome={isHome}
                            cssClass={'office-services'}
                            icon={getIconAndColorSecure(3, isHome).icon}
                            color={getIconAndColorSecure(3, isHome).color}
                            value={recordsMonitoramentoStatusWarning}
                            maximumFractionDigits={0}
                            formatValue={true}
                            colorValue='#2B304F'
                        />
                    )}
                    {!isHome && <p style={{ fontSize: '11px', color: '#2B304F', textAlign: 'center', marginTop: '2px' }}>Com atenção</p>}
                </div>

                <div style={{ minWidth: '30%', display: 'flex', flexDirection: 'column', height: '70%', alignItems: 'center' }}>
                    {isHome ? (
                        <LcTooltip content="Indisponíveis" position='center' trigger='hover'>
                            <IconValue
                                isHome={isHome}
                                cssClass={'office-services'}
                                icon={getIconAndColorSecure(2, isHome).icon}
                                color={getIconAndColorSecure(2, isHome).color}
                                value={recordsMonitoramentoStatusError}
                                maximumFractionDigits={0}
                                formatValue={true}
                                colorValue='#2B304F'
                            />
                        </LcTooltip>
                    ) : (
                        <IconValue
                            isHome={isHome}
                            cssClass={'office-services'}
                            icon={getIconAndColorSecure(2, isHome).icon}
                            color={getIconAndColorSecure(2, isHome).color}
                            value={recordsMonitoramentoStatusError}
                            maximumFractionDigits={0}
                            formatValue={true}
                            colorValue='#2B304F'
                        />
                    )}
                    {!isHome && <p style={{ fontSize: '11px', color: '#2B304F', textAlign: 'center', marginTop: '2px' }}>Indisponíveis</p>}
                </div>
            </div>
            {isHome && <div className="small-description">Monitoramento dos serviços Office</div>}
        </LcLoading>
    );

};