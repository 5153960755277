interface Option {
    label: string;
    value: string | number;
}
export class EAEnums {
    public static readonly monthOpt:Option[] = [
        {
            label: 'Janeiro',
            value: 1
        },
        {
            label: 'Favereiro',
            value: 2
        },
        {
            label: 'Mar�o',
            value: 3
        },
        {
            label: 'Abril',
            value: 4
        },
        {
            label: 'Maio',
            value: 5
        },
        {
            label: "Junho",
            value: 6
        },
        {
            label: "Julho",
            value: 7
        },
        {
            label: "Agosto",
            value: 8
        },
        {
            label: "Setembro",
            value: 9
        },
        {
            label: "Outubro",
            value: 10
        },
        {
            label: "Novembro",
            value: 11
        },
        {
            label: "Dezembro",
            value: 12
        }
    ];

    public static readonly countryOpt: Option[] = [
        {
            label: 'Afeganist�o',
            value: 'AF'
        },
        {
            label: '�frica do Sul',
            value: 'ZA'
        },
        {
            label: 'Alb�nia',
            value: 'AL'
        },
        {
            label: 'Alemanha',
            value: 'DE'
        },
        {
            label: 'Andorra',
            value: 'AD'
        },
        {
            label: 'Angola',
            value: 'AO'
        },
        {
            label: 'Anguila',
            value: 'AI'
        },
        {
            label: 'Ant�rtica',
            value: 'AQ'
        },
        {
            label: 'Ant�gua e Barbuda',
            value: 'AG'
        },
        {
            label: 'Ar�bia Saudita',
            value: 'SA'
        },
        {
            label: 'Arg�lia',
            value: 'DZ'
        },
        {
            label: 'Argentina',
            value: 'AR'
        },
        {
            label: 'Arm�nia',
            value: 'AM'
        },
        {
            label: 'Aruba',
            value: 'AW'
        },
        {
            label: 'Austr�lia',
            value: 'AU'
        },
        {
            label: '�ustria',
            value: 'AT'
        },
        {
            label: 'Azerbaij�o',
            value: 'AZ'
        },
        {
            label: 'Bahamas',
            value: 'BS'
        },
        {
            label: 'Bahrein',
            value: 'BH'
        },
        {
            label: 'Bangladesh',
            value: 'BD'
        },
        {
            label: 'Barbados',
            value: 'BB'
        },
        {
            label: 'Belarus',
            value: 'BY'
        },
        {
            label: 'B�lgica',
            value: 'BE'
        },
        {
            label: 'Belize',
            value: 'BZ'
        },
        {
            label: 'Benin',
            value: 'BJ'
        },
        {
            label: 'Bermudas',
            value: 'BM'
        },
        {
            label: 'Bol�via',
            value: 'BO'
        },
        {
            label: 'B�snia e Herzegovina',
            value: 'BA'
        },
        {
            label: 'Botsuana',
            value: 'BW'
        },
        {
            label: 'Brasil',
            value: 'BR'
        },
        {
            label: 'Brunei',
            value: 'BN'
        },
        {
            label: 'Bulg�ria',
            value: 'BG'
        },
        {
            label: 'Burkina Faso',
            value: 'BF'
        },
        {
            label: 'Burundi',
            value: 'BI'
        },
        {
            label: 'But�o',
            value: 'BT'
        },
        {
            label: 'Cabo Verde',
            value: 'CV'
        },
        {
            label: 'Camar�es',
            value: 'CM'
        },
        {
            label: 'Camboja',
            value: 'KH'
        },
        {
            label: 'Canad�',
            value: 'CA'
        },
        {
            label: 'Cazaquist�o',
            value: 'KZ'
        },
        {
            label: 'Chade',
            value: 'TD'
        },
        {
            label: 'Chile',
            value: 'CL'
        },
        {
            label: 'China',
            value: 'CN'
        },
        {
            label: 'Chipre',
            value: 'CY'
        },
        {
            label: 'Cingapura',
            value: 'SG'
        },
        {
            label: 'Col�mbia',
            value: 'CO'
        },
        {
            label: 'Comores',
            value: 'KM'
        },
        {
            label: 'Cor�ia do Norte',
            value: 'KP'
        },
        {
            label: 'Cor�ia do Sul',
            value: 'KR'
        },
        {
            label: 'Costa do Marfim',
            value: 'CI'
        },
        {
            label: 'Costa Rica',
            value: 'CR'
        },
        {
            label: 'Cro�cia',
            value: 'HR'
        },
        {
            label: 'Cuba',
            value: 'CU'
        },
        {
            label: 'Cura�ao',
            value: 'CW'
        },
        {
            label: 'Dinamarca',
            value: 'DK'
        },
        {
            label: 'Djibouti',
            value: 'DJ'
        },
        {
            label: 'Dominica',
            value: 'DM'
        },
        {
            label: 'Egito',
            value: 'EG'
        },
        {
            label: 'El Salvador',
            value: 'SV'
        },
        {
            label: 'Emirados �rabes Unidos',
            value: 'AE'
        },
        {
            label: 'Equador',
            value: 'EC'
        },
        {
            label: 'Eritreia',
            value: 'ER'
        },
        {
            label: 'Eslov�quia',
            value: 'SK'
        },
        {
            label: 'Eslov�nia',
            value: 'SI'
        },
        {
            label: 'Espanha',
            value: 'ES'
        },
        {
            label: 'Estados Federados da Micron�sia',
            value: 'FM'
        },
        {
            label: 'Estados Unidos da Am�rica',
            value: 'US'
        },
        {
            label: 'Est�nia',
            value: 'EE'
        },
        {
            label: 'Eswatini',
            value: 'SZ'
        },
        {
            label: 'Eti�pia',
            value: 'ET'
        },
        {
            label: 'Fiji',
            value: 'FJ'
        },
        {
            label: 'Filipinas',
            value: 'PH'
        },
        {
            label: 'Finl�ndia',
            value: 'FI'
        },
        {
            label: 'Fran�a',
            value: 'FR'
        },
        {
            label: 'Gab�o',
            value: 'GA'
        },
        {
            label: 'G�mbia',
            value: 'GM'
        },
        {
            label: 'Gana',
            value: 'GH'
        },
        {
            label: 'Ge�rgia',
            value: 'GE'
        },
        {
            label: 'Ge�rgia do Sul e as Ilhas Sandwich do Sul',
            value: 'GS'
        },
        {
            label: 'Gibraltar',
            value: 'GI'
        },
        {
            label: 'Gr�cia',
            value: 'GR'
        },
        {
            label: 'Grenada',
            value: 'GD'
        },
        {
            label: 'Gronel�ndia',
            value: 'GL'
        },
        {
            label: 'Guadalupe',
            value: 'GP'
        },
        {
            label: 'Guam',
            value: 'GU'
        },
        {
            label: 'Guatemala',
            value: 'GT'
        },
        {
            label: 'Guernsey',
            value: 'GG'
        },
        {
            label: 'Guiana',
            value: 'GY'
        },
        {
            label: 'Guiana Francesa',
            value: 'GF'
        },
        {
            label: 'Guin�',
            value: 'GN'
        },
        {
            label: 'Guin� Equatorial',
            value: 'GQ'
        },
        {
            label: 'Guin�-Bissau',
            value: 'GW'
        },
        {
            label: 'Haiti',
            value: 'HT'
        },
        {
            label: 'Honduras',
            value: 'HN'
        },
        {
            label: 'Hong Kong',
            value: 'HK'
        },
        {
            label: 'Hungria',
            value: 'HU'
        },
        {
            label: 'I�men',
            value: 'YE'
        },
        {
            label: 'Ilha Bouvet',
            value: 'BV'
        },
        {
            label: 'Ilha da Reuni�o',
            value: 'RE'
        },
        {
            label: 'Ilha de Man',
            value: 'IM'
        },
        {
            label: 'Ilha de Natal',
            value: 'CX'
        },
        {
            label: 'Ilha Norfolk',
            value: 'NF'
        },
        {
            label: 'Ilhas �land',
            value: 'AX'
        },
        {
            label: 'Ilhas Cayman',
            value: 'KY'
        },
        {
            label: 'Ilhas Cocos',
            value: 'CC'
        },
        {
            label: 'Ilhas Cook',
            value: 'CK'
        },
        {
            label: 'Ilhas Faroe',
            value: 'FO'
        },
        {
            label: 'Ilhas Malvinas',
            value: 'FK'
        },
        {
            label: 'Ilhas Marianas do Norte',
            value: 'MP'
        },
        {
            label: 'Ilhas Marshall',
            value: 'MH'
        },
        {
            label: 'Ilhas Menores Distantes dos Estados Unidos',
            value: 'UM'
        },
        {
            label: 'Ilhas Pitcairn',
            value: 'PN'
        },
        {
            label: 'Ilhas Salom�o',
            value: 'SB'
        },
        {
            label: 'Ilhas Turcas e Caicos',
            value: 'TC'
        },
        {
            label: 'Ilhas Virgens (Reino Unido)',
            value: 'VG'
        },
        {
            label: 'Ilhas Virgens dos Estados Unidos',
            value: 'VI'
        },
        {
            label: '�ndia',
            value: 'IN'
        },
        {
            label: 'Indon�sia',
            value: 'ID'
        },
        {
            label: 'Ir�	',
            value: 'IR'
        },
        {
            label: 'Iraque',
            value: 'IQ'
        },
        {
            label: 'Irlanda',
            value: 'IE'
        },
        {
            label: 'Isl�ndia',
            value: 'IS'
        },
        {
            label: 'Israel',
            value: 'IL'
        },
        {
            label: 'It�lia',
            value: 'IT'
        },
        {
            label: 'Jamaica',
            value: 'JM'
        },
        {
            label: 'Jap�o',
            value: 'JP'
        },
        {
            label: 'Jersey',
            value: 'JE'
        },
        {
            label: 'Jord�nia',
            value: 'JO'
        },
        {
            label: 'Kiribati',
            value: 'KI'
        },
        {
            label: 'Kosovo',
            value: 'XK'
        },
        {
            label: 'Kuwait',
            value: 'KW'
        },
        {
            label: 'Laos',
            value: 'LA'
        },
        {
            label: 'Lesotho',
            value: 'LS'
        },
        {
            label: 'Let�nia',
            value: 'LV'
        },
        {
            label: 'L�bano',
            value: 'LB'
        },
        {
            label: 'Lib�ria',
            value: 'LR'
        },
        {
            label: 'L�bia',
            value: 'LY'
        },
        {
            label: 'Liechtenstein',
            value: 'LI'
        },
        {
            label: 'Litu�nia',
            value: 'LT'
        },
        {
            label: 'Luxemburgo',
            value: 'LU'
        },
        {
            label: 'Macau',
            value: 'MO'
        },
        {
            label: 'Madagascar',
            value: 'MG'
        },
        {
            label: 'Mal�sia',
            value: 'MY'
        },
        {
            label: 'Malau�',
            value: 'MW'
        },
        {
            label: 'Maldivas',
            value: 'MV'
        },
        {
            label: 'Mali',
            value: 'ML'
        },
        {
            label: 'Malta',
            value: 'MT'
        },
        {
            label: 'Marrocos',
            value: 'MA'
        },
        {
            label: 'Martinica',
            value: 'MQ'
        },
        {
            label: 'Maur�cio',
            value: 'MU'
        },
        {
            label: 'Maurit�nia',
            value: 'MR'
        },
        {
            label: 'Mayotte',
            value: 'YT'
        },
        {
            label: 'M�xico',
            value: 'MX'
        },
        {
            label: 'Mianmar',
            value: 'MM'
        },
        {
            label: 'Vaticano',
            value: 'VA'
        },
        {
            label: 'Mo�ambique',
            value: 'MZ'
        },
        {
            label: 'Mold�via',
            value: 'MD'
        },
        {
            label: 'Mong�lia',
            value: 'MN'
        },
        {
            label: 'Montenegro',
            value: 'ME'
        },
        {
            label: 'Montserrat',
            value: 'MS'
        },
        {
            label: 'Nam�bia',
            value: 'NA'
        },
        {
            label: 'Nauru',
            value: 'NR'
        },
        {
            label: 'Nepal',
            value: 'NP'
        },
        {
            label: 'Nicar�gua',
            value: 'NI'
        },
        {
            label: 'N�ger',
            value: 'NE'
        },
        {
            label: 'Nig�ria',
            value: 'NG'
        },
        {
            label: 'Niue',
            value: 'NU'
        },
        {
            label: 'Norte da Maced�nia',
            value: 'MK'
        },
        {
            label: 'Noruega',
            value: 'NO'
        },
        {
            label: 'Nova Caled�nia',
            value: 'NC'
        },
        {
            label: 'Nova Zel�ndia',
            value: 'NZ'
        },
        {
            label: 'Om�',
            value: 'OM'
        },
        {
            label: 'Ouvido e Ilhas McDonald	',
            value: 'HM'
        },
        {
            label: 'Pa�ses Baixos',
            value: 'NL'
        },
        {
            label: 'Pa�ses Baixos Caribenhos',
            value: 'BQ'
        },
        {
            label: 'Palau',
            value: 'PW'
        },
        {
            label: 'Palestina',
            value: 'PS'
        },
        {
            label: 'Panam�',
            value: 'PA'
        },
        {
            label: 'Papua Nova Guin�',
            value: 'PG'
        },
        {
            label: 'Paquist�o',
            value: 'PK'
        },
        {
            label: 'Paraguai',
            value: 'PY'
        },
        {
            label: 'Peru',
            value: 'PE'
        },
        {
            label: 'Polin�sia Francesa',
            value: 'PF'
        },
        {
            label: 'Pol�nia',
            value: 'PL'
        },
        {
            label: 'Porto Rico',
            value: 'PR'
        },
        {
            label: 'Portugal',
            value: 'PT'
        },
        {
            label: 'Principado de M�naco',
            value: 'MC'
        },
        {
            label: 'Qatar',
            value: 'QA'
        },
        {
            label: 'Qu�nia',
            value: 'KE'
        },
        {
            label: 'Quirguizist�o',
            value: 'KG'
        },
        {
            label: 'Reino Unido',
            value: 'GB'
        },
        {
            label: 'Rep�blica Centro-Africana',
            value: 'CF'
        },
        {
            label: 'Rep�blica Democr�tica do Congo',
            value: 'CD'
        },
        {
            label: 'Rep�blica do Congo',
            value: 'CG'
        },
        {
            label: 'Rep�blica Dominicana',
            value: 'DO'
        },
        {
            label: 'Rom�nia',
            value: 'RO'
        },
        {
            label: 'Ruanda',
            value: 'RW'
        },
        {
            label: 'R�ssia',
            value: 'RU'
        },
        {
            label: 'Saara Ocidental',
            value: 'EH'
        },
        {
            label: 'Samoa',
            value: 'WS'
        },
        {
            label: 'Samoa Americana',
            value: 'AS'
        },
        {
            label: 'Santa Helena, Ascens�o e Trist�o da Cunha',
            value: 'SH'
        },
        {
            label: 'Santa L�cia',
            value: 'LC'
        },
        {
            label: 'Santo Bartolomeu',
            value: 'BL'
        },
        {
            label: 'S�o Marino',
            value: 'SM'
        },
        {
            label: 'S�o Martinho',
            value: 'MF'
        },
        {
            label: 'S�o Pedro e Miquelon',
            value: 'PM'
        },
        {
            label: 'S�o Tom� e Pr�ncipe',
            value: 'ST'
        },
        {
            label: 'S�o Vicente e as Granadinas',
            value: 'VC'
        },
        {
            label: 'Senegal',
            value: 'SN'
        },
        {
            label: 'Serra Leoa',
            value: 'SL'
        },
        {
            label: 'S�rvia',
            value: 'RS'
        },
        {
            label: 'Seychelles',
            value: 'SC'
        },
        {
            label: 'Sint Maarten',
            value: 'SX'
        },
        {
            label: 'S�ria',
            value: 'SY'
        },
        {
            label: 'Som�lia',
            value: 'SO'
        },
        {
            label: 'Sri Lanka',
            value: 'LK'
        },
        {
            label: 'St. Kitts e Nevis',
            value: 'KN'
        },
        {
            label: 'Sud�o',
            value: 'SD'
        },
        {
            label: 'Sud�o do Sul',
            value: 'SS'
        },
        {
            label: 'Su�cia',
            value: 'SE'
        },
        {
            label: 'Su��a',
            value: 'CH'
        },
        {
            label: 'Suriname',
            value: 'SR'
        },
        {
            label: 'Svalbard e Jan Mayen',
            value: 'SJ'
        },
        {
            label: 'Tail�ndia',
            value: 'TH'
        },
        {
            label: 'Taiwan',
            value: 'TW'
        },
        {
            label: 'Tajiquist�o',
            value: 'TJ'
        },
        {
            label: 'Tanz�nia',
            value: 'TZ'
        },
        {
            label: 'Tcheca',
            value: 'CZ'
        },
        {
            label: 'Territ�rio Brit�nico do Oceano �ndico',
            value: 'IO'
        },
        {
            label: 'Territ�rios Franceses do Sul e Ant�rtico',
            value: 'TF'
        },
        {
            label: 'Timor Leste',
            value: 'TL'
        },
        {
            label: 'Togo',
            value: 'TG'
        },
        {
            label: 'Tokelau',
            value: 'TK'
        },
        {
            label: 'Tonga',
            value: 'TO'
        },
        {
            label: 'Trinidad e Tobago',
            value: 'TT'
        },
        {
            label: 'Tun�sia',
            value: 'TN'
        },
        {
            label: 'Turcomenist�o',
            value: 'TM'
        },
        {
            label: 'Turquia',
            value: 'TR'
        },
        {
            label: 'Tuvalu',
            value: 'TV'
        },
        {
            label: 'Ucr�nia',
            value: 'UA'
        },
        {
            label: 'Uganda',
            value: 'UG'
        },
        {
            label: 'Uruguai',
            value: 'UY'
        },
        {
            label: 'Uzbequist�o',
            value: 'UZ'
        },
        {
            label: 'Vanuatu',
            value: 'VU'
        },
        {
            label: 'Vaticano',
            value: 'VA'
        },
        {
            label: 'Venezuela',
            value: 'VE'
        },
        {
            label: 'Vietn�',
            value: 'VN'
        },
        {
            label: 'Wallis e Futuna',
            value: 'WF'
        },
        {
            label: 'Z�mbia',
            value: 'ZM'
        },
        {
            label: 'Zimb�bue',
            value: 'ZW'
        },

    ];
}
export default EAEnums;
