export class heyho {
    solution() {
        return 'heyho'
    }

    site() {
        return 'https://www.gotobiz.com.br/gestao-de-servicos-e-atividades-heyho'
    }

    logotipo() {
        return require('./assets/logotipos/logo_heyho.svg').default
    }
    titulo() {
        return <>Otimize a jornada de excelência de atendimento com Hey!Ho!</>
    }

    chamada() {
        return <>Solução que gerencia as atividades, os níveis de serviços e fluxos de trabalho das organizações, possibilitando o controle preciso e sistemático dos processos em formato digital.</>
    }

    image() {
        return require('./assets/imgs/imagem_heyho.png').default
    }

    detalhes() {
        return <>
            <h2>Imagine um mundo onde a tecnologia trabalha para a organização, não contra ela. Onde cada aplicação, cada servidor, cada dispositivo está sob controle.</h2>
            <p><b>Esse é o mundo que o Hey!Ho! pode criar.</b></p>
            <h3>Por que escolher Hey!Ho!?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_heyho.png').default} />
                </div>
                <ul>
                    <li><b>Suporte:</b> Hey!Ho! oferece uma visão completa de todo o histórico do chamado e fluxo de atendimento, facilitando a resolução e cumprimento de SLA contratada. Isso significa que a organização sempre terá o controle do que está acontecendo.</li>
                    <li><b>Flexibilidade:</b> Com a flexibilidade de ser uma solução escalar e flexível que pode ser implantada inicialmente em uma área e, posteriormente ser expandida no restante da organização, o Hey!Ho! se adapta às necessidades da organização.</li>
                    <li><b>Decisão:</b> Com uma visão gerencial unificada orientada a dados, o Hey!Ho! proporciona maior agilidade no processo de tomada de decisão. É a informação certa, na hora certa, para a decisão certa.</li>
                    <li><b>Experiência do cliente:</b> O Hey!Ho! ajuda a melhorar a experiência do cliente, elevando os índices de eNPS e NPS. Isso significa que a organização pode oferecer um serviço de qualidade superior aos seus clientes.</li>                    
                </ul>
            </div>
            <div className="box">Com o Hey!Ho!, a organização ganha uma solução que gerencia as atividades, os níveis de serviços e fluxos de trabalho, possibilitando o <b>controle preciso e sistemático dos processos em formato digital</b>. Isso significa maior eficiência e alta performance.</div>            
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato para obter mais informações sobre como Hey!Ho! pode transformar a forma como a organização gerencia a tecnologia.</p>            
        </>
    }
}
export default heyho;