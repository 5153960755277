import { createStore, combineReducers } from 'redux';
import { MSalState, rootReducerMSAL } from './msalRedux';
import { menuReducer, MenuState } from './reducers/menuReducer';
import { userReducer, UserState } from './reducers/userReducer';
import { messagesReducer, MessagesState } from './reducers/messagesReducer';
import { prefReducer, PrefState } from './reducers/prefReducer';
import { webPresenceReducer, WebPresence } from './reducers/userReducer';
import { filterReducer, FilterState } from './reducers/filterReducer';
import { AnalyticalCostCenterState, analyticalReducer } from './reducers/analyticalReducer';
import { PatchManagerState, patchManagerReducer,  } from './reducers/patchManagerReducer';
import { CMSEnvironmenetReducer, CMSEnvironmenetState } from './reducers/CmsEnvironmentReducer';
import { CMSRecomendationReducer, CMSRecomendationState } from './reducers/CmsRecomendationReducer';

//export const Store = createStore(menuReducer);

export const rootReducer = combineReducers({
    menu: menuReducer,
    user: userReducer,
    msgs: messagesReducer,
    pref: prefReducer,
    msalstate: rootReducerMSAL,
    webPresenceReducer: webPresenceReducer,
    filter: filterReducer,
    analytical: analyticalReducer,
    patchManager: patchManagerReducer,
    cmsEnvironment: CMSEnvironmenetReducer ,
    cmsRecomendations: CMSRecomendationReducer
})

export const Store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export interface RootState {
    menu: MenuState,
    user: UserState,
    msgs: MessagesState,
    pref: PrefState,
    msalstate: MSalState,
    webPresenceReducer: WebPresence,
    filter: FilterState;
    analytical: AnalyticalCostCenterState;
    patchManager: PatchManagerState;
    cmsEnvironment:CMSEnvironmenetState ;
    cmsRecomendations: CMSRecomendationState
}
