import React, { Fragment, useEffect, useState } from 'react';

import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

//Components
import LcSegment from '../../../components/Generic/LcSegment';
import LcLoading from '../../../components/Generic/LcLoading';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import { /*PPDropDown,*/ PPModal, PPInput } from 'processor-plataform-ui';
import { formatCurrency, formatDecimal } from '../../../services/currency';
import PPDropDown from "../../../components/PPDropDown";


//services
import { FinOpsAdvanceFilterService, GroupReport } from '../../../services/finOps/finOpsService';

interface DashboardListGroupByProps {
    tipoDePeriodo: string;
    selectedContract: string;
    selectedCostCenter: string;
    costCenters: any[];
    contracts: any[];
    filter: any;
    hideHeader?: boolean
    openLevel?: number,
}

const FinOpsDashboardListGroupByComponent: React.FC<DashboardListGroupByProps> = (props) => {
    //Redux
    const user = useSelector<RootState, UserState>((state) => state.user);

    const [finOpsMapManagerService] = useState<FinOpsAdvanceFilterService>(new FinOpsAdvanceFilterService(props));

    const [finopsData, setFinopsData] = useState<GroupReport[]>([]);

    const [groupbyParam, setGroupbyParam] = useState<string>("costcenter");
    const [costCenters, setCostCenters] = useState<any[]>(props.costCenters);
    const [selectedCompareOption, setSelectedCompareOption] = useState('');
    const [selectedContract, setSelectedContract] = useState<any>(props.selectedContract);
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>(props.selectedCostCenter);
    const [openLevel, setOpenLevel] = useState<number>(props.openLevel || 0)
    const [filterText, setFilterText] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(props.tipoDePeriodo);
    const [filter, setFilter] = useState<any>(props.filter);

    const [groupByMagin, setGroupByMargin] = useState("costcenter");
    const [finopsDataMargin, setFinopsDataMargin] = useState<any[]>([]);
    const [finopsDMLoading, setFinopsDMLoading] = useState(false);

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter])

    useEffect(() => {
        setTipoDePeriodo(props.tipoDePeriodo);
    }, [props.tipoDePeriodo])

    useEffect(() => {
        setCostCenters(props.costCenters);
    }, [props.costCenters])

    useEffect(() => {
        setSelectedContract(props.selectedContract);
    }, [props.selectedContract])

    useEffect(() => {
        setSelectedCostCenter(props.selectedCostCenter);
    }, [props.selectedCostCenter])

    async function retrieveUsagebyGroup(tipoDePeriodoParm: string) {
        setFinopsData([]);
        setLoading(true);

        finOpsMapManagerService
            .GetLoadUsagebyGroup(
                tipoDePeriodoParm,
                groupbyParam,
                selectedContract,
                selectedCostCenter,
                filter
            )
            .then(response => {
                setFinopsData(response);
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    }

    async function retrieveUsagebyGroupTaxDescritive(tipoDePeriodoParm: string) {
        setFinopsDMLoading(true);
        finOpsMapManagerService
            .GetLoadUsagebyGroupTaxDescritive(
                tipoDePeriodoParm,
                groupByMagin,
                selectedCostCenter,
                selectedContract,
                filter
            )
            .then(response => {
                setFinopsDataMargin(response.map(d => { return { ...d, visible: false } }));
            })
            .catch(console.warn)
            .then(() => {
                setFinopsDMLoading(false);
            })
    }

    useEffect(() => {
        tipoDePeriodo != undefined &&
            retrieveUsagebyGroup(tipoDePeriodo);
    }, [tipoDePeriodo, groupbyParam, filter, selectedContract, selectedCostCenter, user.ClientGroupSelected]);

    const dateOptions = [
        { label: 'Últimos 7 dias', value: 'last7Days' },
        { label: 'Últimos 30 dias', value: 'last30Days' },
        { label: 'Últimos 3 meses', value: 'last90Days' },
        { label: 'Últimos 6 meses', value: 'last180Days' },
        { label: 'Últimos 12 meses', value: 'last365Days' },
        { label: 'Este mês', value: 'currentMonth' },
        { label: 'Mês anterior', value: 'priorMonth' },
        { label: 'Dois meses atrás', value: 'prior2Months' },
    ];

    const jsonFinOpsGroupBy = [

        {
            label: "Por centro de custo",
            value: "costcenter"
        },
        {
            label: "Por centro de custo / serviço",
            value: "costcenter_service"
        },
        {
            label: "Por centro de custo / recurso",
            value: "costcenter_resource"
        },
        {
            label: "Por serviço",
            value: "service"
        },
        {
            label: "Por serviço / centro de custo",
            value: "service_costcenter"
        },
        {
            label: "Por serviço / recurso",
            value: "service_resource"
        }
    ];

    useEffect(() => {
        tipoDePeriodo &&
            retrieveUsagebyGroupTaxDescritive(tipoDePeriodo);
    }, [groupByMagin, filter, tipoDePeriodo, selectedContract])

    var compareOptions = [
        { label: 'Todos os valores', value: '' },
        { label: 'Valores que aumentaram', value: 'greaterThan' },
        { label: 'Valores que diminuíram', value: 'lesserThan' }
    ];

    const margemTypeOptions = [
        { label: 'Por serviço', value: 'service' },
        { label: 'Por centro de custo', value: 'costcenter' }
    ];

    const calcDiference = (before: number, now: number) => {
        let _before = Math.round(Number(before) * 100) / 100;
        let _now = Math.round(Number(now) * 100) / 100;

        if (_before > _now) {
            return <span>{formatDecimal(_before - _now)}<i className="ppi ppi-arrow-down text-success text-danger" /></span>
        } else if (_now > _before) {
            return <span>{formatDecimal(_now - _before)}<i className="ppi ppi-arrow-up text-danger" /></span>
        } else {
            return <span>{formatDecimal(0)}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        }
    }

    const [finopsDetailSort, setFinopsDetailSort] = useState({ col: 'value', dir: -1 });
    const [finopsDetailData, setFinopsDetailData] = useState<any[]>([]);
    const [finopsDetailDataLoading, setFinopsDetailDataLoading] = useState(false);
    const loadNewData = () => {
        setFinopsDetailDataLoading(true);
        finOpsMapManagerService.GetLoadUsagebyGroupCostCenterDetail(
            tipoDePeriodo!,
            groupbyParam,
            selectedContract,
            '',
            filterText,
            selectedCostCenter || '0',
            openLevel,
            filter
        )
            .then(response => {
             
                setFinopsDetailData(response.data);
            })
            .catch(console.warn)
            .then(() => {
                setFinopsDetailDataLoading(false);
            })
    }
    useEffect(loadNewData, [tipoDePeriodo, groupbyParam, filterText, selectedContract,
        selectedCostCenter, user.ClientGroupSelected, filter]);

    const [finopsDetailModalVisible, setFinopsDetailModalVisible] = useState(false);

    const mountTreeViewFinOps = (data, top10?: boolean) => {
        return <>
            {
                data && data.length > 0 &&
                data
                    .sort((a, b) => { return Number(a.value) > Number(b.value) ? -1 : 1 })
                    .sort((a, b) => {
                        return finopsDetailSort.col == 'title' ?
                            a[finopsDetailSort.col] > b[finopsDetailSort.col] ? (1 * finopsDetailSort.dir) : (-1 * finopsDetailSort.dir)
                            :
                            finopsDetailSort.col == "difference" ?
                                (parseFloat(a.value) - parseFloat(a.last)) > (parseFloat(b.value) - parseFloat(b.last)) ? (1 * finopsDetailSort.dir) : (-1 * finopsDetailSort.dir)
                                :
                                parseFloat(a[finopsDetailSort.col]) > parseFloat(b[finopsDetailSort.col]) ? (1 * finopsDetailSort.dir) : (-1 * finopsDetailSort.dir)
                    })
                    .map((d, i) => {
                        let _children = (d.children && d.children.length > 0) ? d.children : undefined;
                        let _data = (d.data && d.data.length > 0) ? d.data : undefined;

                        return <Fragment key={i}>
                            <div className='finops-row link' onClick={() => {
                                d.expanded = !d.expanded;
                                setFinopsDetailData([...finopsDetailData]);
                            }}
                            >
                                <div className="label text-darker" style={{ marginLeft: !(_children || _data) ? '13px ' : '0' }} >
                                    {
                                        (_children || _data) &&
                                        <i className={`ppi ppi-chevron-${d.expanded ? 'down' : 'right'}`} />
                                    }
                                    {mountIcon(d.type)} {d.title}
                                </div>
                                <div className={`row values${d.expanded || data.find(x => x.expanded) ? ' text-darker' : ''}`}>
                                    <span className='row w-100 right'>{formatDecimal(Number(d.last))}</span>
                                    <span className='row w-100 right'>{formatDecimal(Number(d.value))}</span>
                                    <span className='row w-100 right'>{calcDiference(Number(d.last), Number(d.value))}</span>
                                </div>
                            </div>
                            {
                                (d.expanded) &&
                                <div className='ml-6 striped'>
                                    {
                                        _data && _data
                                            .sort((a, b) => { return Number(a.value) > Number(b.value) ? 1 : -1 })

                                            .sort((a, b) => {
                                                return finopsDetailSort.col == 'title' ?
                                                    a[finopsDetailSort.col] > b[finopsDetailSort.col] ? (-1 * finopsDetailSort.dir) : (1 * finopsDetailSort.dir)
                                                    :
                                                    finopsDetailSort.col == "difference" ?
                                                        (parseFloat(a.value) - parseFloat(a.last)) > (parseFloat(b.value) - parseFloat(b.last)) ? (1 * finopsDetailSort.dir) : (-1 * finopsDetailSort.dir)
                                                        :
                                                        parseFloat(a[finopsDetailSort.col]) > parseFloat(b[finopsDetailSort.col]) ? (1 * finopsDetailSort.dir) : (-1 * finopsDetailSort.dir)
                                            })
                                            .map((e, i) => {
                                                let changeType = Number(e.value) > Number(e.last) ? 'greaterThan' : Number(e.last) > Number(e.value) ? 'lesserThan' : '';
                                                let rowVisible = selectedCompareOption == '' ? true : selectedCompareOption == changeType;

                                                return rowVisible && <div className='finops-row' key={i}>
                                                    <div className="label text-darker ellipsis">
                                                        {mountIcon(e.type)} {e.title}
                                                    </div>
                                                    <div className={`row values${d.children.expanded ? ' text-darker' : ''}`}>
                                                        <span className='row w-100 right'>{formatDecimal(Number(e.last))}</span>
                                                        <span className='row w-100 right'>{formatDecimal(Number(e.value))}</span>
                                                        <span className='row w-100 right'>{calcDiference(Number(e.last), Number(e.value))}</span>
                                                    </div>
                                                </div>
                                            })
                                    }
                                    {mountTreeViewFinOps(d.children, top10)}
                                </div>
                            }

                        </Fragment>
                    })
            }
        </>
    }

    const mountIcon = (type: string) => {
        switch (type) {
            case 'costcenter':
                return <i className='ppi ppi-currency-dollar text-success' title='Centro de Custo' />
            case 'service':
                return <i className='ppi ppi-service text-primary' title='Serviço' />
            case 'resource':
                return <i className='ppi ppi-cog text-danger' title='Recurso' />
        }
    }

    return (
        <>
            <PPModal
                size='80%'
                visible={finopsDetailModalVisible}
                onClose={setFinopsDetailModalVisible}
                title={`Todos os recursos. Total: ${finopsData && finopsData.length > 0 && finopsData[0].total && formatDecimal(finopsData[0].total)}`}
                functions={[{ label: 'Fechar', onClick: () => setFinopsDetailModalVisible(false) }]}
            >
                <div className='row mb-7'>
                    <PPDropDown
                        title="Agrupar por"
                        name="groupbyParam"
                        options={jsonFinOpsGroupBy}

                        defaultValue={jsonFinOpsGroupBy.find(option => option.value == groupbyParam)}
                        value={jsonFinOpsGroupBy.find(option => option.value == groupbyParam)}

                        onChange={(event) => { setGroupbyParam(event.value); }}
                        disabled={finopsDetailDataLoading}
                        required
                        hideFilter
                        width='300px'

                    />
                    <PPDropDown
                        title='Período'
                        name="date"
                        options={dateOptions}

                        defaultValue={dateOptions.find(option => option.value == tipoDePeriodo)}
                        value={dateOptions.find(option => option.value == tipoDePeriodo)}

                        onChange={event => {
                            setSelectedCompareOption('');
                            setTipoDePeriodo(event.value);
                        }}
                        width='190px'
                        required
                        hideFilter
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Contrato"
                        name="contracts"
                        options={props.contracts}

                        defaultValue={props.contracts.find(option => option.value == selectedContract)}
                        value={props.contracts.find(option => option.value == selectedContract)}

                        onChange={event => {
                            setSelectedContract(parseInt(event.value))
                        }}

                        width="190px"
                        required
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Centro de custo"
                        name="idCostCenter"
                        options={costCenters}

                        defaultValue={costCenters.find(option => option.value == selectedCostCenter)}
                        value={costCenters.find(option => option.value == selectedCostCenter)}

                        onChange={event => setSelectedCostCenter(event.value)}
                        width='190px'
                        required
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Mostrar apenas"
                        name="showOnly"
                        options={compareOptions}

                        defaultValue={compareOptions.find(option => option.value == selectedCompareOption)}
                        value={compareOptions.find(option => option.value == selectedCompareOption)}

                        onChange={event => { setSelectedCompareOption(event.value); }}
                        width='190px'
                        required
                        hideFilter
                        disabled={
                            finopsDetailDataLoading ||
                            (tipoDePeriodo == 'last90Days' || tipoDePeriodo == 'last180Days' || tipoDePeriodo == 'last365Days')
                        }
                    />
                    <PPInput
                        title="Filtros"
                        name="filters"
                        type='text'
                        placeholder='Serviço ou recurso'
                        width='190px'
                        onKeyUp={event => { if (event.key == "Enter") { setFilterText(event.currentTarget.value); } }}
                        disabled={finopsDetailDataLoading}
                    />
                </div>
                <div className='finops-row mr-6'>
                    <div className="label text-darker link" onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'title', dir: finopsDetailSort.col == 'title' ? (finopsDetailSort.dir * -1) : 1 })}>
                        Descrição
                        {finopsDetailSort.col == 'title' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir == 1 ? 'down' : 'up'} text-warning`} />}
                    </div>
                    <div className="row values">
                        <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'last', dir: finopsDetailSort.col == 'last' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Anterior
                            {finopsDetailSort.col == 'last' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir == 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                        <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'value', dir: finopsDetailSort.col == 'value' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Atual
                            {finopsDetailSort.col == 'value' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir == 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                        <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'difference', dir: finopsDetailSort.col == 'difference' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Diferença
                            {finopsDetailSort.col == 'difference' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir != 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                    </div>
                </div>
                <div className='scrollable-v' style={{ maxHeight: '300px' }}>
                    <LcLoading loading={finopsDetailDataLoading} label='Carregando dados...'>
                        {mountTreeViewFinOps(finopsDetailData)}
                    </LcLoading>
                </div>

            </PPModal>

            <LcSegment centralized >
                <div className="row full between mb-5">
                    <div className={'lc-title ellipsis'}>Maiores consumos (R$)</div>
                    <div className="row link text-link" onClick={() => { setFinopsDetailModalVisible(true) }}>
                        {
                            !loading &&
                            <>
                                Total
                                {finopsData && finopsData.length == 0 ? '0,00' : ''} {finopsData && finopsData.length > 0 && finopsData[0].total && formatDecimal(finopsData[0].total)}
                                <i className='ppi ppi-arrow-down ppi-2x ml-3 text-primary' />
                            </>
                        }
                    </div>
                    <PPDropDown
                        name="groupbyParam"
                        options={jsonFinOpsGroupBy}

                        defaultValue={jsonFinOpsGroupBy.find(option => option.value == groupbyParam)}
                        value={jsonFinOpsGroupBy.find(option => option.value == groupbyParam)}

                        onChange={(event) => { setGroupbyParam(event.value); }}
                        disabled={loading}
                        required
                        width='300px'
                    />
                </div>

                <LcLoading loading={loading} label="Carregando..." >
                    <div className='finops-row mr-6' style={{ width: '65%' }}>
                        <div className="label text-darker link" onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'title', dir: finopsDetailSort.col == 'title' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Descrição
                            {finopsDetailSort.col == 'title' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir != 1 ? 'down' : 'up'} text-warning`} />}
                        </div>
                        <div className="row values">
                            <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'last', dir: finopsDetailSort.col == 'last' ? (finopsDetailSort.dir * -1) : 1 })}>
                                Anterior
                                {finopsDetailSort.col == 'last' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir != 1 ? 'down' : 'up'} text-warning`} />}
                            </span>
                            <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'value', dir: finopsDetailSort.col == 'value' ? (finopsDetailSort.dir * -1) : 1 })}>
                                Atual
                                {finopsDetailSort.col == 'value' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir != 1 ? 'down' : 'up'} text-warning`} />}
                            </span>
                            <span className='row w-100 right text-darker link' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'difference', dir: finopsDetailSort.col == 'difference' ? (finopsDetailSort.dir * -1) : 1 })}>
                                Diferença
                                {finopsDetailSort.col == 'difference' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir != 1 ? 'down' : 'up'} text-warning`} />}
                            </span>
                        </div>
                    </div>
                    <LcLoading loading={finopsDetailDataLoading} label='Carregando dados...'>
                        <div style={{ width: '65%' }}>
                            {mountTreeViewFinOps(finopsDetailData, true)}
                        </div>
                    </LcLoading>
                </LcLoading>

            </LcSegment >

            {
                (user.ClientGroupSelected == 428 && finopsDataMargin.find(fdm => fdm.tax > 0)) &&
                <LcSegment centralized >
                    <div className="row full between mb-5">
                        <div className="lc-title ellipsis">Composição dos valores</div>
                        {
                            finopsDataMargin.length > 0 &&
                            <>
                                <span>Total custos cloud {formatCurrency(finopsDataMargin[0].cost || 0)}</span>
                                <span>Total serviços {formatCurrency(finopsDataMargin[0].margin || 0)}</span>
                                <span>Total impostos {formatCurrency(finopsDataMargin[0].tax || 0)}</span>
                            </>
                        }

                        <div className={'lc-segment-title-filter'}>
                            <PPDropDown
                                name="margemType"                                
                                options={margemTypeOptions}

                                defaultValue={margemTypeOptions.find(option => option.value == groupByMagin)}
                                value={margemTypeOptions.find(option => option.value == groupByMagin)}

                                onChange={(event) => { setGroupByMargin(event.value) }}

                                disabled={loading}
                                required
                                width='300px'
                            />
                        </div>

                    </div>

                    <LcLoading loading={finopsDMLoading} label="Carregando a composição dos valores..." >
                        {
                            (!finopsDataMargin || finopsDataMargin.length == 0) ?
                                <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                                    <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: 14 }}>
                                                {`Não há dados para o período selecionado.`}
                                            </span>
                                        </div>
                                    </LCFlexItem>
                                </LCFlexContainer>
                                :
                                <div className={'lc-group-body scrollable-v'} >
                                    {
                                        finopsDataMargin
                                            .map((item, key) => {
                                                return (
                                                    <div key={key}>
                                                        <div className={`lc-group-header-segments link`} onClick={() => {
                                                            item.visible = !item.visible;
                                                            setFinopsDataMargin([...finopsDataMargin])
                                                        }}>
                                                            <div className="row">
                                                                <div className={'lc-group-header-segments-button'}>
                                                                    <i className="ppi ppi-chevron-down-alt link" />
                                                                </div>
                                                                <div className={'lc-group-header-segments-title'}>{item.parent.title}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className={'lc-group-header-segments-money'}>{formatDecimal(item.parent.value)}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`bodyGroup ${!item.visible ? 'collapsed' : ''}`}>
                                                            <div className={'lc-group-row'} >
                                                                <div className={'lc-group-row-inner'}>
                                                                    <div className={'lc-group-row-inner-text'}>Custos cloud</div>
                                                                    <div className={'lc-group-row-inner-money'}>
                                                                        {formatCurrency(item.parent.cost || 0)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'lc-group-row'} >
                                                                <div className={'lc-group-row-inner'}>
                                                                    <div className={'lc-group-row-inner-text'}>Nossos serviços</div>
                                                                    <div className={'lc-group-row-inner-money'}>
                                                                        {formatCurrency(item.parent.margin || 0)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'lc-group-row'} >
                                                                <div className={'lc-group-row-inner'}>
                                                                    <div className={'lc-group-row-inner-text'}>Impostos</div>
                                                                    <div className={'lc-group-row-inner-money'}>
                                                                        {formatCurrency(item.parent.tax || 0)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                </div>
                        }
                    </LcLoading>
                </LcSegment >
            }
        </>
    );
};

export default FinOpsDashboardListGroupByComponent;