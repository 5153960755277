import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LcDialog from '../../../components/Generic/LcDialog'
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { DisasterRecoveryService } from '../../../services/disasterRecovery/disasterRecoveryService';
import { Grid, LinearProgress, Button } from '@material-ui/core';
import { FileField, InputField, SelectField } from '../../../components/Form/Input';
import { useSnackbar } from 'notistack';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useDebounce } from 'use-debounce/lib';
import LcLoading from '../../../components/Generic/LcLoading';

interface Disaster {
    idDisasterRecovery?: number,
    data?: string,
    acao?: string,
    responsavel?: string,
    status?: number,
    idCliente?: number,
    observacao?: string,
    isDeleted?: boolean,
    clientGroupId?: number,
    statusName?: string,
}

const PlanosDR: React.FC = (props) => {
    const [plans, setPlans] = useState<any[]>([]);
    const [filteredPlans, setFilteredPlans] = useState<any[]>([]);
    const [visiblePlans, setVisiblePlans] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const [selected, setSelected] = useState<number>(0);
    const [formText, setFormText] = useState<string>("");
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [disaster, setDisaster] = useState<any>();
    const [actualPage, setActualPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [ValueSearch, setValueSearch] = React.useState<string>('');
    const [ValueSearchDebounce] = useDebounce(ValueSearch, 500);
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
    const [file, setFile] = useState<any>();
    const [showFormFile, setShowFormFile] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();


    const user = useSelector<RootState, UserState>(state => state.user);
    useEffect(() => {
        setIsLoading(true);
        setPlans([]);
        setFilteredPlans([]);
        if (user.ClientGroupSelected) {
            const disasterRecoveryService = new DisasterRecoveryService(props);
            disasterRecoveryService.GetPlansByStatusName('Disponível')
                .then((plans: any) => {
                    let mappedArray: any[] = [];
                    plans.map((_e: any) => {
                        let fullDate = _e.data.toString().split('-')
                        let formatedDate = `${fullDate[2]}/${fullDate[1]}/${fullDate[0]}`

                        mappedArray.push(
                            {
                                acao: _e.acao,
                                data: formatedDate,
                                id: _e.id,
                                idDisasterRecovery: _e.idDisasterRecovery,
                                responsavel: _e.responsavel,
                                statusName: _e.statusName
                            }
                        )
                    })
                    setFilteredPlans(mappedArray)
                    setPlans(mappedArray);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag, refresh]);

    useEffect(() => {
        if (plans.length > 0) {
            setIsLoading(true);
            let localRecords = plans;
            let arrayFiltered: any[] = [];
            (plans.length > 0 ? plans : localRecords).filter(item => item.data.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
                || item.acao.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
                || item.responsavel.toLowerCase().includes(ValueSearchDebounce.toLowerCase())
                || item.statusName.toLowerCase().includes(ValueSearchDebounce.toLowerCase())).map(plan => {
                    arrayFiltered.push(plan);
                })
            setFilteredPlans(arrayFiltered);
            setIsLoading(false);
        }
    }, [ValueSearchDebounce]);


    useEffect(() => {
        var filtered = plans.filter((p: any) =>
            filter.term == '' ||
            p.acao.toLowerCase().indexOf(filter.term) > -1 ||
            p.responsavel.toLowerCase().indexOf(filter.term) > -1 ||
            p.statusName.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredPlans(filtered);
        setVisiblePlans(filtered.slice(0, filter.size));
    }, [filter, filteredPlans.length > 0]);

    const functionsRow = [
        { label: 'Editar', func: handleEdit, fullRowAsParam: true },
        { label: 'Excluir', func: showDialog, fullRowAsParam: true }
    ]

    const filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (filter: any, size: number) => setFilter({ ...filter, size: size, term: filter[0].value.toLowerCase() })
    }

    const columns = [
        { field: 'data', headerName: 'Data', width: "15%" },
        { field: 'acao', headerName: 'Ação', width: "35%" },
        { field: 'responsavel', headerName: 'Responsável', width: "35%" },
        { field: 'statusName', headerName: 'Status', width: "15%" },
    ];

    function downloadFile() {
        const disasterRecoveryService = new DisasterRecoveryService(props);
        const FileDownload = require("js-file-download");
        disasterRecoveryService.DownloadFile().then(response => { FileDownload(response.data, "disaster_recovery_plan.pdf") }).catch(error => { console.log(error) });
    };

    function handleEdit(item: any) {
        let date = item.data ? item.data.split('/') : "";
        const status = statusOptions.find(x => x.name == item.statusName)

        let aux = {
            idDisasterRecovery: item.idDisasterRecovery,
            data: date[2] + '-' + date[1] + '-' + date[0],
            acao: item.acao,
            responsavel: item.responsavel,
            status: status && status.value,
        };
        setFormText("Editar");
        setDisaster(aux);
        setModalVisible(true);
        setIsLoadingModal(false)
    };

    function handleCreate() {
        let aux = {};
        setFormText("Criar");
        setDisaster(aux);
        setModalVisible(!modalVisible);
        setIsLoadingModal(false)
    };

    function handleUpload() {
        setUploadModalVisible(!uploadModalVisible);
        setShowFormFile(!showFormFile)
    };

    function handleChange(event: any) {
        const { name, value } = event;
        let _disaster: any = disaster;
        _disaster[name] = value;
        setDisaster(_disaster);
    };

    function handleChangeSelect(event: any) {
        const { value } = event;
        let _disaster: any = disaster;
        _disaster['status'] = value;
        setDisaster(_disaster);
    };

    function deleteDR(id: number) {
        const disasterRecoveryService = new DisasterRecoveryService(props);
        disasterRecoveryService.DeleteDR(id)
            .then(response => {
                window.setTimeout(() => setRefresh(!refresh), 3000)
            })
            .catch(error => {
                console.log(error);
                setRefresh(!refresh);
            });
        setDialogVisible(false);
        setIsLoading(true);
    };

    function disagreeFunction() {
        setDialogVisible(false);
    };

    function showDialog(item: Disaster) {
        setSelected(item.idDisasterRecovery ? item.idDisasterRecovery : 0);
        setDialogVisible(true);
    };

    const statusOptions = [
        { name: "Disponível", value: '1' },
        { name: "Sucesso", value: '2' },
        { name: "Em Andamento", value: '3' },
        { name: "Agendado", value: '4' },
        { name: "Falha", value: '5' },
        { name: "Cancelado", value: '6' }
    ];

    function editDR() {
        setIsLoadingModal(true);
        let date = disaster.data ? disaster.data.split('-') : "";
        let dateFormatted = date[1] + "/" + date[2] + "/" + date[0];
        let aux = {
            idDisasterRecovery: disaster && disaster.idDisasterRecovery,
            data: disaster && dateFormatted,
            acao: disaster && disaster.acao,
            responsavel: disaster && disaster.responsavel,
            status: disaster && parseInt(disaster.status),
            clientGroupId: parseInt(user.ClientGroupSelected ? user.ClientGroupSelected?.toString() : ""),
        };
        const disasterRecoveryService = new DisasterRecoveryService(props);
        disasterRecoveryService.EditDR(aux)
            .then(response => {
                setIsLoadingModal(false);
                setDisaster(undefined);
                setModalVisible(false);
                setRefresh(!refresh);

                enqueueSnackbar(`Registro salvo com sucesso.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            })
            .catch(error => {
                console.log(error);
                enqueueSnackbar(`Ocorreu um erro ao atualizar o registro.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                setIsLoadingModal(false);
                setDisaster(undefined);
                setModalVisible(false);
                setRefresh(!refresh);
            });
    };

    function createDR() {
        if (!disaster.responsavel || !disaster.data || !disaster.acao || !disaster.status) {
            enqueueSnackbar(`Por favor preencha todos os campos`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
        } else {
            setIsLoadingModal(true);
            let aux: Disaster = {
                idDisasterRecovery: disaster && disaster.idDisasterRecovery,
                data: disaster && disaster.data,
                acao: disaster && disaster.acao,
                responsavel: disaster && disaster.responsavel,
                statusName: disaster && disaster.statusName,
                status: disaster && disaster.status,
                clientGroupId: parseInt(user.ClientGroupSelected ? user.ClientGroupSelected?.toString() : ""),
            };
            const disasterRecoveryService = new DisasterRecoveryService(props);
            disasterRecoveryService.CreateDR(aux)
                .then(response => {
                    setIsLoadingModal(false);
                    setDisaster(undefined);
                    setModalVisible(false);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoadingModal(false);
                    setDisaster(undefined);
                    setModalVisible(false);
                    setRefresh(!refresh);
                });
        }
    };

    const functions = [
        {
            id: "b4399d34-9d99-4095-bc28-d5d6b993d603",
            title: 'Upload DR',
            icon: 'lci lci-download lci-rotate-180',
            tooltip: 'Upload de instruções',
            func: (ids: any) => handleUpload()
        },
        {
            id: "016224d5-e632-4b1c-a0fe-4fbb6e39dd8f",
            title: 'Dowload DR',
            icon: 'lci lci-download',
            tooltip: 'Download de instruções',
            func: (ids: any) => downloadFile()
        },
        {
            id: "9aa565af-ede4-4000-8a18-ec8dee679cac",
            title: 'Criar',
            icon: 'ppi ppi-plus',
            tooltip: 'Criar',
            func: (ids: any) => handleCreate()
        }
    ]

    const handlePageChange = (pageData: any) => {
        setActualPage(pageData.page);
    }

    const onChangeFileField = (fieldOpt: any) => {
        setFile(fieldOpt)
    }

    const toBinary = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async function convertAttachment(anexo: any, toBinary: (file: Blob) => Promise<unknown>) {
        for (var i = 0; i < anexo.length; i++) {
            var fileBinary: any = {};
            var reponseBinary = await toBinary(anexo[i]);
            fileBinary.baseBinary = reponseBinary;
            fileBinary.fileName = anexo[i].name;
            fileBinary.contentType = anexo[i].type;
            if (anexo[i].anexosBinary == null) {
                anexo[i].anexosBinary = [];
            }
            anexo[i].anexosBinary = (fileBinary);
        }
    }

    const sendFile = async (e: any) => {
        e.preventDefault()
        if (!file[0]) return
        resetData()
        enqueueSnackbar(`O documento está sendo preparado para envio. Aguarde`, {
            variant: 'warning',
            preventDuplicate: true,
            persist: false,
        });
        await convertAttachment(file, toBinary)
        const disasterRecoveryService = new DisasterRecoveryService(props);
        const formFile = new FormData()
        formFile.append("formFile", file[0])
        disasterRecoveryService.PostFile(formFile).then(result => {
            if (result.status == 200) {
                enqueueSnackbar(`O documento foi enviado com sucesso.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            }
        }).catch(error => {
            console.log(error)
            enqueueSnackbar(`Ocorreu um erro ao enviar o arquivo`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        })
    }

    const resetData = () => {
        setUploadModalVisible(!uploadModalVisible);
        setShowFormFile(!showFormFile)
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisiblePlans(filteredPlans.slice(0, size))
        else {
            setVisiblePlans([...visiblePlans, ...filteredPlans.slice(visiblePlans.length, visiblePlans.length + 10)])
        }
    };

    return (
        <Layout pageTitle="Disaster Recovery"  >
            <LcInfiniteTable
                loading={isLoading}
                columns={columns}
                rows={visiblePlans}
                filter={filterAdvanced}
                size={filteredPlans.length}
                functionGeneric={functions}
                functionsRow={functionsRow}
                loadMore={loadMore}
                onSortChange={onSortChange} />

            <LcDialog title="Confirmar exclusão?" visible={dialogVisible} agreeFunction={() => deleteDR(selected)} disagreeFunction={disagreeFunction}></LcDialog>

            <SideModal visible={modalVisible}
                header={formText}
                onClose={() => {
                    setDisaster(undefined);
                    setModalVisible(false);
                    setTimeout(() => {
                        setIsLoadingModal(true)
                    }, 1000);
                }
                }>
                <LcLoading loading={isLoadingModal}>
                    <>
                        <InputField name="responsavel" value={disaster?.responsavel} type="text" label="Responsável" onChange={handleChange}> </InputField>
                        <InputField name="data" value={disaster?.data} type="date" label="Data" onChange={handleChange}> </InputField>
                        <InputField name="acao" value={disaster?.acao} type="text" label="Ação" onChange={handleChange}> </InputField>
                        <SelectField name="status" label="Status" value={disaster?.status} options={statusOptions} onChange={handleChangeSelect}></SelectField>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <button id="48894a4e-db9f-4c2e-8bc0-4a94231457fb" className="lc-button bg-primary" onClick={formText == "Editar" ? editDR : createDR}>
                                {formText}
                            </button>

                            <button id="51b37180-2473-4db1-87b4-7ccf0972e8a7" className="lc-button" onClick={() => { setModalVisible(false); }}>
                                Cancelar
                            </button>
                        </div>
                    </>

                </LcLoading>
            </SideModal>

            <SideModal header="Upload de instruções" visible={uploadModalVisible} onClose={() => { setUploadModalVisible(!uploadModalVisible); setShowFormFile(!showFormFile) }}>
                {showFormFile &&
                    <form onSubmit={sendFile}>
                        <FileField name="formFile" type="file" onChange={onChangeFileField} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                            <button id="d01fcc74-5bc1-4075-b8af-b89d782a83fb" className="lc-button bg-primary">
                                Enviar
                            </button>
                        </div>
                    </form>
                }
            </SideModal>
        </Layout>
    );
};

export default PlanosDR;

function enqueueSnackbar(arg0: string, arg1: { variant: string; preventDuplicate: boolean; persist: boolean; }) {
    throw new Error('Function not implemented.');
}
