import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import api from "./api";
import jwt_decode from "jwt-decode";
import history from '../history';
//Redux
import { Store } from '../store/index';
import { UserState } from '../store/reducers/userReducer';
import { AxiosResponse } from 'axios';
import { SaaSGroupService } from './saasGroupService';
import { CacheManagerService } from './cacheManagerService';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

declare global {
    interface Window {
        dataLayer: any; // 👈️ turn off type checking
    }
}

// Msal Configurations
const config = {
    auth: {
        authority: process.env.REACT_APP_AZURE_AUTHORITY || '',
        clientId: process.env.REACT_APP_CLIENTID || '',
        redirectUri: process.env.REACT_APP_REDIRECTURI || '',
        validateAuthority: false,
        cloudDiscoveryMetadata: '{"tenant_discovery_endpoint":"https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration","api-version":"1.1","metadata":[{"preferred_network":"login.microsoftonline.com","preferred_cache":"login.windows.net","aliases":["login.microsoftonline.com","login.windows.net","login.microsoft.com","sts.windows.net"]},{"preferred_network":"login.partner.microsoftonline.cn","preferred_cache":"login.partner.microsoftonline.cn","aliases":["login.partner.microsoftonline.cn","login.chinacloudapi.cn"]},{"preferred_network":"login.microsoftonline.de","preferred_cache":"login.microsoftonline.de","aliases":["login.microsoftonline.de"]},{"preferred_network":"login.microsoftonline.us","preferred_cache":"login.microsoftonline.us","aliases":["login.microsoftonline.us","login.usgovcloudapi.net"]},{"preferred_network":"login-us.microsoftonline.com","preferred_cache":"login-us.microsoftonline.com","aliases":["login-us.microsoftonline.com"]}]}',
        authorityMetadata: '{"token_endpoint":"https://login.microsoftonline.com/common/oauth2/v2.0/token","token_endpoint_auth_methods_supported":["client_secret_post","private_key_jwt","client_secret_basic"],"jwks_uri":"https://login.microsoftonline.com/common/discovery/v2.0/keys","response_modes_supported":["query","fragment","form_post"],"subject_types_supported":["pairwise"],"id_token_signing_alg_values_supported":["RS256"],"response_types_supported":["code","id_token","code id_token","id_token token"],"scopes_supported":["openid","profile","email","offline_access"],"issuer":"https://login.microsoftonline.com/{tenantid}/v2.0","request_uri_parameter_supported":false,"userinfo_endpoint":"https://graph.microsoft.com/oidc/userinfo","authorization_endpoint":"https://login.microsoftonline.com/common/oauth2/v2.0/authorize","http_logout_supported":true,"frontchannel_logout_supported":true,"end_session_endpoint":"https://login.microsoftonline.com/common/oauth2/v2.0/logout","claims_supported":["sub","iss","cloud_instance_name","cloud_instance_host_name","cloud_graph_host_name","msgraph_host","aud","exp","iat","auth_time","acr","nonce","preferred_username","name","tid","ver","at_hash","c_hash","email"],"tenant_region_scope":null,"cloud_instance_name":"microsoftonline.com","cloud_graph_host_name":"graph.windows.net","msgraph_host":"graph.microsoft.com","rbac_url":"https://pas.windows.net"}'
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: ['User.Read']
}

const authenticationParametersAuthoirize = {
    scopes: ['User.Read'],
    prompt: 'consent'
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: process.env.REACT_APP_REDIRECTURI
}

const authProviderMSal = new MsalAuthProvider(config, authenticationParameters, options)

export const authProvider = {

    providerMSal: authProviderMSal,
    getAuthToken: function () {
        var LiveCloud_tokenAccess = sessionStorage && sessionStorage.getItem("LiveCloud_tokenAccess");
        return LiveCloud_tokenAccess != null;
    },
    LoginMSSO: async function () {
        authProviderMSal.login();
    },
    LoginEmail: async function (email, token) {
        try {
            var response = await authProvider.ValidateTokenEmailLogin(email, token);
            console.debug('Validate', response);
            return await authProvider.GetTokenLCByEmail(email, response.data.clientGroupId, token);

        } catch (error) {
            console.error(error);
            return false;
        }
    },
    AuthorizeOrganization: async function () {
        authProviderMSal.setAuthenticationParameters(authenticationParametersAuthoirize);

        authProviderMSal.login()
    },
    GetAccessToken: function () {
        authProviderMSal.getAccessToken()
            .catch(error => {
                console.log(error);
            });
    },
    GetTokenLC: async function (accountInfo: any) {

        if (accountInfo.jwtAccessToken) {
            try {
                let token = accountInfo.jwtAccessToken;
                sessionStorage.setItem("LiveCloud_AuthMS", token);
                var debounceFlag = sessionStorage.getItem("RegisterUserAfterLogin");
                if (!debounceFlag) {
                    sessionStorage.setItem("RegisterUserAfterLogin", "True");
                    if (sessionStorage.getItem("isShowAsClientActive") === "true") {
                        var response = await api({
                            method: 'post',
                            url: `${process.env.REACT_APP_BFFENDPOINT}/Account/RegisterUserAfterLoginNew?showAsClient=true`,
                            headers: { 'Authorization': `Bearer ${token}` }
                        })
                        sessionStorage.removeItem("RegisterUserAfterLogin");
                        let currentClientGroupSelected = parseInt(sessionStorage.getItem("ClientGroupSelected") + "");
                        let tokenLC = response && response.data.token && jwt_decode(response.data.token) as UserState;
                        let defaultClientGroupSelected = parseInt(tokenLC.ClientGroupSelected + "");
                        currentClientGroupSelected != null && currentClientGroupSelected > 0 && currentClientGroupSelected != defaultClientGroupSelected ?
                            await this.Renew(currentClientGroupSelected) : this.SaveUserSession(response);
                    } else {
                        var response = await api({
                            method: 'post',
                            url: `${process.env.REACT_APP_BFFENDPOINT}/Account/RegisterUserAfterLoginNew`,
                            headers: { 'Authorization': `Bearer ${token}` }
                        })
                        sessionStorage.removeItem("RegisterUserAfterLogin");


                        let currentClientGroupSelected = parseInt(sessionStorage.getItem("ClientGroupSelected") + "");
                        let tokenLC = response && response.data.token && jwt_decode(response.data.token) as UserState;
                        let defaultClientGroupSelected = parseInt(tokenLC.ClientGroupSelected + "");
                        currentClientGroupSelected != null && currentClientGroupSelected > 0 && currentClientGroupSelected != defaultClientGroupSelected ?
                            await this.Renew(currentClientGroupSelected) : this.SaveUserSession(response);
                    }
                }
            } catch (e) {
                sessionStorage && sessionStorage.clear();
                window.location.reload()
            }
        }
    },
    Renew: async function (CustomerId: number) {
        let token = sessionStorage.getItem("LiveCloud_AuthMS");
        try {
            //debugger;
            if (sessionStorage.getItem("isShowAsClientActive") === "true") {
                var response = await api.request({
                    method: 'get',
                    url: `${process.env.REACT_APP_BFFENDPOINT}/Account/RenewTokenNew?idClienteSelected=${CustomerId}&showAsClient=true`,
                    headers: { 'Authorization': `Bearer ${token}` },
                    withCredentials: false
                });

                this.SaveUserSession(response);
            } else {
                var response = await api.request({
                    method: 'get',
                    url: `${process.env.REACT_APP_BFFENDPOINT}/Account/RenewTokenNew?idClienteSelected=${CustomerId}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                    withCredentials: false
                });

                this.SaveUserSession(response);
            }
        } catch (e) {
            sessionStorage && sessionStorage.clear();
            window.location.reload()
        }
    },
    LogOutMSSO: function () {
        authProviderMSal.logout();
    },
    SaveUserSession: async function (response: AxiosResponse<any>) {
        //debugger;
        sessionStorage.setItem("LiveCloud_AuthLC", response.data.token);
        var decoded = response.data && response.data.token && jwt_decode(response.data.token) as UserState;
        if (decoded && decoded.ClientGroupSelected && decoded.ClientGroupSelected > 0) {
            try {
                if (decoded.IsAdm == "True") {
                    decoded.IsAdm = true; 
                } else { decoded.IsAdm = false; }

                retrieveHistory(response);
                decoded.Photo = response.data.photo;
                decoded.VendorStatusList = response.data.vendorStatus;
                decoded.Menus = response.data.menus
                sessionStorage.setItem("UserEmail", decoded.email + "");
                sessionStorage.setItem("ClientGroupSelected", decoded.ClientGroupSelected + "");
                sessionStorage.setItem("ClientGroupSelectedDescription", decoded.ClientGroupSelectedDescription + "");
                sessionStorage.setItem("ClientGroupList", response.data.clientGroupList + "");

                window.dataLayer = window.dataLayer || []
                if ((sessionStorage.UserEmail || sessionStorage.UserEmail || sessionStorage.UserEmail) != null || undefined) {
                    window.dataLayer.push({
                        'event': 'userData',
                        'user_email': sessionStorage.getItem('UserEmail'),
                        'economic_group_id': sessionStorage.getItem('ClientGroupSelected'),
                        'economic_group': sessionStorage.getItem('ClientGroupSelectedDescription')
                    })
                }
                if (window.dataLayer && window.location.host == 'livecloud.processoronline.com.br') {
                    window.dataLayer.push({
                        'gtm.start': new Date().getTime(), event: 'gtm.js'
                    });
                }

                decoded.ClientGroupList = response.data.clientGroupList.sort((a: any, b: any) => { return (a.defaultClientGroup || a.descricao < b.descricao) ? -1 : (!a.defaultClientGroup || a.descricao > b.descricao) ? 1 : 0 });
                decoded.Permission = response.data.permission;
            }
            catch (e) {
            }
            Store && Store.dispatch({ type: 'USER_UPDATE', payload: decoded });
            Store && Store.dispatch({ type: 'AAD_AUTHENTICATED_STATE_CHANGED', payload: "Authenticated" });
            Store && Store.dispatch({ type: 'TOKENLC', payload: "Authenticated" });
            const path = window.location.pathname;
            if (path.includes("solucoes")) {
                return;// history.push('/solucoes');
            }
            if (!(decoded.IsAdm && (decoded.IsAdm == "True" ||  decoded.IsAdm == true))) {
                history.push('/');
            }

        }
    },
    GenerateTokenEmailLogin: async function (email: string) {

        const url: string = `${process.env.REACT_APP_BFFENDPOINT}/Account/LoginWithEmailGenerateToken?email=${email}`;
        return await api.request({
            method: 'post',
            url: url
        });
    },
    ValidateTokenEmailLogin: async function (email: string, token: string) {
        const url: string = `${process.env.REACT_APP_BFFENDPOINT}/Account/LoginWithEmailValidateToken?email=${email}&token=${token}`;
        return await api.request({
            method: 'post',
            url: url
        });
    },
    GetTokenLCByEmail: async function name(email: string, clientgroup: number, token: string) {
        try {
            sessionStorage.setItem("LiveCloud_AuthMS", token);
            sessionStorage.setItem("LiveCloud_tokenAccess", token);
            var debounceFlag = sessionStorage.getItem("RegisterUserAfterLogin");
            if (!debounceFlag) {
                sessionStorage.setItem("RegisterUserAfterLogin", "True");
                try {
                    var response = await api.request({
                        method: 'post',
                        url: `${process.env.REACT_APP_BFFENDPOINT}/Account/RegisterUserAfterLoginNew?email=${email}&clientgroup=${clientgroup}&token=${token}`,
                        headers: { 'Authorization': `Bearer ${token}` },
                        withCredentials: false
                    });

                    await this.SaveUserSession(response);
                    return true;
                } catch (e) {
                    sessionStorage.removeItem("RegisterUserAfterLogin");
                    return false;
                }
            }
        } catch (e) {
            return false;
        }
    }
}

export const configProvider = config;

async function retrieveHistory(response: AxiosResponse<any>) {
    try {
        let instanceCacheManagerService = new CacheManagerService({});
        response = await instanceCacheManagerService.RetrieveHistoryPin();
        let array = response.data;
        sessionStorage.setItem("LiveCloud_Favoritos", JSON.stringify(array));

        response = await instanceCacheManagerService.RetrieveHistoryRecent();
        array = response.data;

        sessionStorage.setItem("LiveCloud_Recentes", JSON.stringify(array));

    } catch (e) {
    }
}
