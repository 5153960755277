import api, { APIClient } from "../api";

export interface LastBackupDates {
    o365CONTACTS: number,
    o365MAIL: number,
    o365CALENDAR: number,
    o365TASKS: number,
    o365NOTES: number,
    mS365GROUP_CONVERSATIONS: number,
    mS365GROUP_META: number,
    mS365GROUP_SITE: number,
    mS365GROUP_CALENDAR: number,
    mS365TEAM_CHANNELS: number,
    onedrive: number,
    sharepoinT_MULTI: number
}

export interface EntityDetail {
    id: string,
    userId: string,
    taskId: string,
    entity: string,
    status: string,
    lastBackupDates: LastBackupDates[],
    fullName: string,
    progress: null | any,
    extId: string
}

export interface CloudAllyResponseTaskData {
    id: string,
    account: string,
    type: string,
    source: string,
    userId: string,
    domain?: string | null,
    status: string,
    region: string,
    alias: string,
    backupTaskId?: null | string,
    index: boolean,
    snapshotDate?: null | string | Date,
    nextBackup: string,
    progress?: null | any,
    size: number,
    createDate: string | Date,
    numOfBilledEntities: number,
    numOfEntities: string,
    multiEntitiesTask: boolean,
    lastBackupDates: LastBackupDates
}

export interface CloudAllyResponseTaskData_WithEntities extends CloudAllyResponseTaskData {
    entities: EntityDetail[],
}

export interface CloudAllyResponseTask {
    page: number,
    totalPages: number,
    total: number,
    nextPageToken: null | undefined | string,
    data: CloudAllyResponseTaskData[] | null | undefined
}

export interface CloudAllyUserBackupStatus {
    subSource: string,
    status: string,
    error: null | string,
    errFAQLink: null | string
}

export interface CloudAllyUserStatusDetail {
    userId: string,
    taskId: string,
    source: string,
    entityName: string,
    lastBackupDate?: null | string | Date,
    lastBackupAttemptDate: string,
    backupDuration: number,
    size: number,
    backupStatus: CloudAllyUserBackupStatus[]
}

export interface CloudAllyResponseUserStatus {
    page: number,
    totalPages: number,
    total: number,
    nextPageToken: null | undefined | string,
    data: CloudAllyUserStatusDetail[] | null | undefined
}

export interface CloudAllyUserStatusDetail_DataGrid extends CloudAllyUserStatusDetail {
    fullName: string,
    mail: string,
    sizeLabel: string,
    id: number
}

export enum TypeResponse {
    CloudAllyResponseTask = 1,
    CloudAllyResponseUserStatus = 2,
    CloudAllyResponseTaskData_WithEntities = 3
}

export class BackupSaasServiceV2 extends APIClient {

    constructor(props: any) {
        super(props);
    }

    public FromForSource = [
        { id: 1, from: 'MS365', for: 'Microsoft Exchange', icon: 'exchange-logo' },
        { id: 2, from: 'SHAREPOINT_MULTI', for: 'Microsoft SharePoint', icon: 'sharepoint-logo' },
        { id: 3, from: 'ONEDRIVE', for: 'Microsoft OneDrive', icon: 'onedrive-logo' },
        { id: 4, from: 'MS365GROUPS', for: 'Microsoft Teams', icon: 'teams-logo' }
    ];

    public FromForStatus = [
        { id: 1, color: "darkgreen", bgcolor: "lightgreen", icon: "ppi ppi-check", from: 'ACTIVE', for: 'Sucesso' },
        { id: 2, color: "#8b90a3", bgcolor: "#e0e1e6", icon: "ppi ppi-minus", from: 'NOTACTIVE', for: 'Não configurado' },
        { id: 3, color: "#e273c5", bgcolor: "#f4c8e8", icon: "lci lci-clock", from: 'PENDING', for: 'Agendado' },
        { id: 4, color: "#8b90a3", bgcolor: "#e0e1e6", icon: "lci lci-pause", from: 'PAUSED', for: 'Em pausa' },
        { id: 5, color: "#8b90a3", bgcolor: "#e0e1e6", icon: "lci lci-radiobtn", from: 'SKIPPED', for: 'Ignorado' },
        { id: 6, color: "#f3c944", bgcolor: "#f9e5a4", icon: "lci lci-clipboard-copy", from: 'DISPATCHED', for: 'Expedido' },
        { id: 7, color: "#f3c944", bgcolor: "#f9e5a4", icon: "lci lci-terminal", from: 'QUEUED', for: 'Em fila' },
        { id: 8, color: "#59bac5", bgcolor: "#cae9ec", icon: "lci lci-cog", from: 'INPROCESS', for: 'Processando' },
        { id: 9, color: "#f3c944", bgcolor: "#f9e5a4", icon: "lci lci-archive", from: 'ARCHIVED', for: 'Arquivado' },
        { id: 10, color: "#8b90a3", bgcolor: "#e0e1e6", icon: "lci lci-bookmark-alt", from: 'ON_PREMISE', for: 'Local' }
    ];

    public EmptyRecordTask: CloudAllyResponseTask = {
        page: 0,
        totalPages: 0,
        total: 0,
        nextPageToken: null,
        data: null
    };

    public EmptyRecordUser: CloudAllyResponseUserStatus = {
        page: 0,
        totalPages: 0,
        total: 0,
        nextPageToken: null,
        data: null
    };


    async GetTasks(page, pagesize) {
        const url = this.endpoint + `/api/BackupSaas/Customer/Tasks/Page/${page}/PageSize/${pagesize}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetUserStatus(page, pagesize) {
        const url = this.endpoint + `/api/BackupSaas/Customer/UserStatus/Page/${page}/PageSize/${pagesize}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetUserDetails(taskId) {
        const url = this.endpoint + `/api/BackupSaas/Customer/Task/${taskId}`;
        return api({
            method: 'get',
            url,
        });
    }

    public GetFromForSource(from) {
        let _for = this.FromForSource.find(x => x.from == from)
        if (!_for) return ''
        return _for?.for.toString()
    }
    public GetFromForStatus(from) {
        let _for = this.FromForStatus.find(x => x.from == from)
        return _for?.for.toString()
    }
    public GetFromForStatusIcon(from) {
        let _for = this.FromForStatus.find(x => x.from == from)
        return _for?.icon
    }
    public GetFromForStatusColor(from) {
        let _for = this.FromForStatus.find(x => x.from == from)
        return _for?.color
    }
    public GetFromForStatusBgColor(from) {
        let _for = this.FromForStatus.find(x => x.from == from)
        return _for?.bgcolor
    }
    public bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes <= 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }
    public GetPathIcon(source) {
        let _for = this.FromForSource.find(x => x.from == source)
        return _for?.icon?.toString()
    }
    public GetLastBackupFormated(dateInt: number) {
        if (!dateInt) return '-'
        let y = dateInt.toString().substring(0, 4)
        let m = dateInt.toString().substring(4, 6)
        let d = dateInt.toString().substring(6, 8)

        let h = dateInt.toString().substring(8, 10)
        let mm = dateInt.toString().substring(10, 12)

        return `${d}/${m}/${y}  ${h}:${mm}`
    }
    public GetTraslatedNextBackup(text) {
        let array = text.split(' ')
        let number: any = null;
        let menos = ''
        let plural = ''
        array.map((element) => {
            let n = parseInt(element)
            if (n) {
                number = n
                if (number > 1) {
                    plural = 's'
                }
            }
            if (element == 'less') {
                menos = 'menos de '
            }
        })
        return `Em ${menos}${number} hora${plural}`
    }
    public FormatDate(date: Date) {

        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getUTCMonth() as number + 1).toString().padStart(2, '0')
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')

        const fullDate = `${day}/${month}/${year} - ${hours}:${minutes}`

        return fullDate
    }

    IsNullReturn(record: CloudAllyResponseTask | CloudAllyResponseUserStatus, type: TypeResponse) {
        switch (type) {
            case 1:
                if (JSON.stringify(this.EmptyRecordTask) == JSON.stringify(record)) return true
                else return false
            case 2:
                if (JSON.stringify(this.EmptyRecordUser) == JSON.stringify(record)) return true
                else return false
            default:
                return true;
        }
    }

    GetEmptyRecordTask() {
        let _emptyRecord = this.EmptyRecordTask;
        return _emptyRecord;
    }
}