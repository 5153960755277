import api, { APIClient } from "./api";

export interface TileControlViewModel {
    alertStyle: string,
    backgroundColor: string,
    clientName: string,
    color: string,
    consumption: string,
    consumptionPercent: number,
    cssClass: string,
    description: string,
    enabled: false,
    extraData: string,
    extraDataUnit: string,
    extraDataUnitDescription: string,
    faIcon: string,
    hired: string,
    href: string,
    hrefComposition: { action: string, controller: string, args: object },
    iconColor: string,
    id: number,
    productType: number,
    props: object,
    slm: [],
    subtitle: string,
    tileControlType: 0,
    title: string,
    unit: string,
    value: string,
    vendorPartNumber: string
}

export interface SolicitationProducts {
    total: number,
    product: string,
    slaAtendimento: number,
    slaConclusao: number
}

export interface ManagedServiceHome {
    clientGroupId: number,
    referenceDate: string,
    slmAvailability: number,
    slmCapacity: number,
    chamadosFHC: number,
    chamadosHC: number,
    quantidadeHoras: number,
    slaFinal: number,
    slaInicial: number,
    totalChamadosLCBackup: number,
    totalChamadosOutros: number,
    totalChamadosPMS: number,
    totalChamadosSaaS: number,
    valorHora: number,
    totalChamadosAbertos: number,
    totalChamadosFechadas: number,
    totalChamadosCanceladas: number,
    solicitationProducts: SolicitationProducts[],
    office365ServicesStatus: []
    office365ServicesStatusMonthAvg: number,
    chamadosAbertosPelaProcessor: number,
    chamadosAbertosPeloCliente: number,
    rotinasDesenvolvidas: number,
    totalchamadosAbertosDepartamento: number
    totalchamadosFechadosDepartamento: number
}

export interface DashboardGrafana {
    id: number,
    url: string,
    friendlyName: string,
    isDefault: boolean
}

export default class HomeService extends APIClient { 
    constructor(props:any) {
        super(props);
    }               

    async GetTileInfo(month: string, year: string) {
        const url = this.endpoint + `/api/Home/GetTileInfo/${month}/${year}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        }).catch(error => {
            console.log(error);
        });
    }

    async GetManagedServiceHomeDataByIdAndYear(year: string) {
        const url = this.endpoint + `/api/Home/ManagedServiceHome/${year}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data;
        }).catch(error => {
            console.debug(error);
        });
    }

    async GetViewGTMContratoLiveCloudMetrica() {
        const url = this.endpoint + `/Account/GetViewGTMContratoLiveCloudMetrica`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    async GetManagedServiceHomeDataByIdMonthAndYear(month: string, year: string) {
        const url = this.endpoint + `/api/Home/ManagedServiceHome/${month}/${year}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            return response.data;
        });
    }

    async GetGrafanaDashboard() {
        const url = this.endpoint + `/api/Home/GetDashboard?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        }).then(response => {
            return response.data;
        });
    }

    async GetGrafanaDashboardWithUrl(dashUrl: string) {
        const url = this.endpoint + `/api/Home/GetDashboardWithUrl?refresh=${Math.random()}`;
        const body = JSON.stringify(dashUrl);
        return api({
            method: 'post',
            url,
            data: body,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}