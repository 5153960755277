import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { Bar } from 'react-chartjs-2';
import { BackupSaasService } from '../../../services/backupSaas/backupSaasService';
import { Grid, LinearProgress } from '@material-ui/core';
import "./index.css";
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import Doughnut from '../../../components/Layout/Dashboard/Doughnut';
import LcLoading from '../../../components/Generic/LcLoading';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { CacheManagerService } from '../../../services/cacheManagerService';

function getUpdatedAt(data: string) {
    let UpdatedAt: string = data;
    let date = new Date(UpdatedAt);

    return date.toLocaleString();
}

function GroupBy(array: any[], key: string) {
    let arr = array
        .reduce((hash, obj) => {
            if (obj[key] == undefined) return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    return Object.keys(arr).map(function (arrKey) {
        let array: any[] = [];
        array.push(arr[arrKey]);
        return { key: arrKey, value: array };
    });
}

interface DoughnutData {
    title: string,
    labels: string[],
    values: number[]
}

const BackupSaasResumo: React.FC = (props) => {
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [summaryData, setSummaryData] = useState<any>();
    const [groups, setGroups] = useState<any[]>([]);
    const [groupsFiltered, setGroupsFiltered] = useState<any[]>([]);
    const [visibleGroups, setVisibleGroups] = useState<any[]>([]);
    const [groupUsageChart, setGroupUsageChart] = useState<DoughnutData>({
        labels: [],
        title: '',
        values: []
    });
    const [monthlyUsageChart, setMonthlyUsageChart] = useState<any>();

    const monthlyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 1 });
                }
            }
        }
    };

    const cacheManagerService = new CacheManagerService(props);

    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("ProcessorSyncBackupSaas", "BackupSaaSSummary")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isLoadingGroupChart, setIsLoadingGroupChart] = useState(true);
    const [isLoadingMonthlyChart, setIsLoadingMonthlyChart] = useState(true);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [UpdatedAt, setUpdatedAt] = useState<string>();
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 1, term: "", column: "", direction: "asc" });
    const [lastUpdate, setLastUpdate] = useState<string>("");


    useEffect(() => {
        setIsLoadingCards(true);
        setIsLoadingTable(true);
        setIsLoadingGroupChart(true);
        setIsLoadingMonthlyChart(true);
        setGroupsFiltered([]);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const searchDate = [year, month, '01'].join('-');
            const backupSaasService = new BackupSaasService(props);

            backupSaasService.GetCards(searchDate)
                .then(response => {

                    if (response.data) {
                        let id = 0;

                        let summaryData = {
                            UsersCount: response.data.usersCount,
                            JobsCount: response.data.jobsCount,
                            SnapshotCount: response.data.snapshotCount,
                            TotalSizeTb: response.data.totalSizeTb,

                        };

                        let groups = response.data.items.map((Item: { name: string, treesizeTb: number }) => {
                            return {
                                id: id++,
                                type: Item.name,
                                consume: Item.treesizeTb
                            }
                        });

                        let groupUsageChart: DoughnutData = {
                            title: 'Espaço alocado em TB',
                            labels: groups.map((Item: { type: string }) => {
                                return Item.type
                            }),
                            values: groups.map((Item: { consume: number }) => {
                                return Item.consume
                            }),
                        };

                        setGroupUsageChart(groupUsageChart);
                        setSummaryData(summaryData);
                        setGroups(groups);
                        setGroupsFiltered(groups);
                        setUpdatedAt(getUpdatedAt(response.data.updatedAt));

                    } else {

                        let summaryData = {
                            UsersCount: 0,
                            JobsCount: 0,
                            SnapshotCount: 0,
                            TotalSizeTb: 0,
                        };

                        let groupUsageChart: DoughnutData = {
                            labels: [],
                            values: [],
                            title: ''
                        };

                        setGroupUsageChart(groupUsageChart);
                        setSummaryData(summaryData);
                        setUpdatedAt(new Date().toLocaleString());
                    }

                    backupSaasService.GetMonthlyChart()
                        .then(response => {
                            if (response.data.length == 0) {
                                setMonthlyUsageChart(undefined)
                                setIsLoadingMonthlyChart(false)
                                return
                            }

                            if (response.data) {
                                response.data.forEach((Item: { consolidatedYearMonth: string }, index: number) => {
                                    let date = new Date(parseInt(Item.consolidatedYearMonth.substring(0, 4)), (parseInt(Item.consolidatedYearMonth.substring(4, 6)) - 1), 1);
                                    response.data[index].UpdatedAt = (date.getMonth() + 1).toString().padStart(2, "0") + "/" + date.getFullYear().toString().substring(2, 4);
                                    response.data[index].consolidatedYearMonth = date;
                                });
                                response.data.sort((a: any, b: any) => a.consolidatedYearMonth - b.consolidatedYearMonth);
                                let grouped = GroupBy(response.data, 'UpdatedAt');
                                let monthlyChart = {
                                    labels: grouped.map((Item) => {
                                        return Item.key
                                    }),
                                    datasets: [{
                                        data: grouped.map((Item) => {
                                            return Item.value.reduce((a, b) => a + (b[0]["totalSizeTb"] || 0), 0);
                                        }),
                                        label: "Consumo mensal",
                                        borderColor: "#3e95cd",
                                        fill: false
                                    },
                                    {
                                        type: 'line',
                                        data: grouped.map((Item) => {
                                            return Item.value.reduce((a, b) => a + (b[0]["totalSizeTb"] || 0), 0);
                                        }),
                                        label: "Consumo mensal",
                                        borderColor: "#3e95cd",
                                        fill: false
                                    }]
                                };
                                setMonthlyUsageChart(monthlyChart);
                                setIsLoadingMonthlyChart(false);
                            } else {
                                let monthlyChart = {
                                    labels: [],
                                    datasets: [{
                                        data: [],
                                        label: "Consumo mensal",
                                        borderColor: "#3e95cd",
                                        fill: false
                                    },]
                                };
                                setMonthlyUsageChart(monthlyChart);
                                setIsLoadingMonthlyChart(false);
                            }


                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setIsLoadingCards(false);
                    setIsLoadingTable(false);
                    setIsLoadingGroupChart(false);
                    setIsLoadingMonthlyChart(false);
                });
            refreshLastUpdate();
        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    useEffect(() => {
        var filtered = groups
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setGroupsFiltered(filtered);
        setVisibleGroups(filtered.slice(0, filter.size));
    }, [filter, groupsFiltered.length > 0]);

    const columns = [
        { field: 'type', headerName: 'Grupos', width: "50%" },
        {
            field: 'consume', headerName: 'TB/dia', width: "50%",
            renderCell: (row: any) => parseFloat(row.consume).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 1 })
        }
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleGroups(groupsFiltered.slice(0, size))
        else setVisibleGroups([...visibleGroups, ...groupsFiltered.slice(visibleGroups.length, visibleGroups.length + 10)])
    };

    return (
        <Layout pageTitle="Resumo" periodSelection={periodSelection} lastUpdate={lastUpdate}>
            <div className="bksaasresume" >
                <LcLoading loading={isLoadingCards}>
                    <LCFlexContainer spacing={2}>
                        <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                            <IconValueCardRow title="Usuários" icon="lci lci-desktop-computer" value={summaryData && summaryData.UsersCount} color="#E273C5"></IconValueCardRow>
                        </LCFlexItem>
                        <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                            <IconValueCardRow title="Snapshots" icon="lci lci-document-duplicate" value={summaryData && summaryData.SnapshotCount} color="#8684EF"></IconValueCardRow>
                        </LCFlexItem>
                        <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                            <IconValueCardRow title="Jobs" icon="lci lci-clipboard-check" value={summaryData && summaryData.JobsCount} color="#F3C944"></IconValueCardRow>
                        </LCFlexItem>
                        <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                            <IconValueCardRow maximumFractionDigits={1} formatValue={true} title="Espaço consumido" icon="lci lci-server" value={summaryData && summaryData.TotalSizeTb} unity="TB" color="#F28B40"></IconValueCardRow>
                        </LCFlexItem>
                    </LCFlexContainer>
                </LcLoading>

                <LCFlexContainer justify="center" spacing={2}>
                    <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                        <LcLoading loading={isLoadingGroupChart}>
                            <div className="lc-segment" style={{ height: '100%' }}>
                                <div className="title">Espaço Alocado em TB</div>
                                <Doughnut maximumFractionDigits={1} fullHeigth={true} formatNumber={true} data={groupUsageChart} />
                            </div>
                        </LcLoading>
                    </LCFlexItem>
                    <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                        <LcLoading loading={isLoadingMonthlyChart}>
                            <div className="lc-segment" style={{ height: '100%' }}>
                                <div className="title">Consumo mensal</div>
                                {monthlyUsageChart ?
                                    <Bar height={170} data={monthlyUsageChart} options={monthlyUsageChartOptions}></Bar>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '1rem', alignItems: 'center' }}>
                                        <i className="lci lci-exclamation text-warning" style={{ marginRight: '3px', fontSize: '10.5pt' }} />
                                        <span className="font-sm">Não há informações no período</span>
                                    </div>
                                }
                            </div>
                        </LcLoading>
                    </LCFlexItem>
                </LCFlexContainer>

                <LcLoading loading={isLoadingTable}>
                    <LcInfiniteTable
                        loading={isLoadingTable}
                        columns={columns}
                        rows={visibleGroups}
                        size={groupsFiltered.length}
                        loadMore={loadMore}
                        onSortChange={onSortChange} />
                </LcLoading>
            </div>
        </Layout>
    );
};

export default BackupSaasResumo;