import axios from "axios";
import api, { APIClient } from "../api";
import { RecomentationsModel, OnOffModel, logActivitiesModel, schedulesModel, webSitesModel, AksClustersModel } from "./cmsPremierModel";

// const mockApi = axios.create({
//     baseURL: 'http://localhost:3001', // Defina uma baseURL fictícia
//   });
export interface DatabaseItem {
    provider: string;
    name: string;
    resourceId: string;
    engine: string;
    status: string;
    createdTime: string;
    endpointAddress: string;
    endpointPort: number;
    allocatedStorage: number;
    backupRetentionPeriod: number;
    availabilityZone: string;
    multiAZ: boolean;
    storageType: string;
    region: string;
};
export class CmsPremierService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    baseURL = 'http://localhost:3000';

    getRecomendations = async (): Promise<RecomentationsModel> => {

        const url = `${this.endpoint}/api/CMSPremier/GetCMSRecommentations`;
        return (await api({
            method: 'get',
            url,
        })).data;

    }

    getKPIVMs = async () => {

        const url = `${this.endpoint}/api/CMSPremier/GetKPIVMs`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);

    }

    getAKSClusters = async ():Promise<AksClustersModel[]>  => {
        const url = `${this.endpoint}/api/CMSPremier/GetAKSClusters`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getVms = async (): Promise<OnOffModel> => {

        const url = `${this.endpoint}/api/CMSPremier/VMs`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);

    }

    getAllVms = async () => {

        const url = `${this.endpoint}/api/CMSPremier/GetVMs`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);

    }

    getKPIDatabases = async () => {

        const url = `${this.endpoint}/api/CMSPremier/GetKPIDatabases`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getKPIStorages = async () => {
        //setStorageCard
        const url = `${this.endpoint}/api/CMSPremier/GetKPIStorages`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getStorages = async () => {
        //setStorageCard
        const url = `${this.endpoint}/api/CMSPremier/GetStorages`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getStoragesFull = async () => {
        //setStorageCard
        const url = `${this.endpoint}/api/CMSPremier/GetStoragesFull`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getDatabases = async (): Promise<DatabaseItem[]> => {

        const url = `${this.endpoint}/api/CMSPremier/getDatabases`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getDatabasesFull = async () => {

        const url = `${this.endpoint}/api/CMSPremier/getDatabasesFull`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getWebsitesData = async (): Promise<webSitesModel> => {
        const url = `${this.endpoint}/api/CMSPremier/Websites`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    getLogActivities = async () => {
        const url = `${this.endpoint}/api/CMSPremier/GetByClientGroupLog`;
        return JSON.parse((await api({
            method: 'get',
            url,
        })).data);
    }

    getSchedulesData = async (): Promise<schedulesModel[]> => {
        const url = `${this.endpoint}/api/Automations/RetrieveList`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

}