import React, { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import './index.css';
import { PPInput, PPModal, PPTextArea, PPTextField, PPTooltip } from 'processor-plataform-ui';
import { AccordionDetails, Box, Button, Step, StepButton, StepConnector, StepIconProps, StepLabel, Stepper, TextField, Theme, Tooltip, Typography, createStyles, makeStyles, withStyles } from '@material-ui/core';
import PPDropDown from '../../PPDropDown';
import PPCheckBox from '../PPCheckBox';
import LcLoading from '../../Generic/LcLoading';
import LcAccordion from '../../Generic/LcAccordion';
import { AiOutlineExclamationCircle, AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import LCDashboard from '../../Data/Dashboard/LCDashboard';
import Layout from '../../Layout/Layout';
import { FaHatWizard } from 'react-icons/fa';

export interface RootResponse {
    message: string
    name: string
    stack: string
    config: Config
    code: string
    status: number
}

export interface Config {
    transitional: Transitional
    adapter: string[]
    transformRequest: any[]
    transformResponse: any[]
    timeout: number
    xsrfCookieName: string
    xsrfHeaderName: string
    maxContentLength: number
    maxBodyLength: number
    env: Env
    headers: Headers
    method: string
    url: string
    data: string
    withCredentials: boolean
}

export interface Transitional {
    silentJSONParsing: boolean
    forcedJSONParsing: boolean
    clarifyTimeoutError: boolean
}

export interface Env { }

export interface Headers {
    Accept: string
    "Content-Type": string
    "Access-Control-Allow-Origin": string
    Authorization: string
    AuthorizationLC: string
    PathRoute: string
}

export interface Option {
    label: string,
    value: string | number,
    disabled?: boolean
}

export interface ConsultaCEP {
    cep: string,
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: string,
    gia: string,
    ddd: string,
    siafi: string
}
export interface FieldValidation {
    regex: RegExp,
    regexMsg: string
}

export interface Field {
    type: "text" | "password" | "number" | "CEP" | "checkbox" | "dropdown" | "dropdown-multi" | "textarea",
    name: string,
    label: string,
    required: boolean,
    hidden: boolean,
    disabled: boolean,
    options?: Option[],
    addressFieldFromCEP?: "logradouro" | "bairro" | "localidade" | "uf",
    tooltip?: string,
    validation?: FieldValidation,
    width?: number | string,
    blockEdit?: boolean,
    position?: "bottom" | "auto" | "top";
    handleMenuOpen?: (inputValue: string) => Promise<any[]>,
}
/*Para completar a configuração da solução Software Hub, siga os passos a seguir: */
export interface FieldGroup {
    label?: string,

    accordion: boolean,
    description?: () => ReactNode,
    onStepSave?: (StepData) => void,
    onStepValidation?: (StepData) => Promise<stepValidationResponse>,
    fields: Field[]
}
export interface stepValidationResponse {
    skipStep: boolean,
    validationPassed: boolean,
    log: string
}

export interface FormFields {
    id?: string,
    fieldgroups: FieldGroup[]
}

interface FormModalProps {
    isModal?: boolean,
    descriptionSubTitle?: string,
    mode: "create" | "edit" | "view",
    type: "fieldset" | "wizard",
    title: string,
    desc?: string,
    size: "small" | "medium" | "large",
    show: boolean,
    data: object,
    activeStep?: number,
    formFields: FormFields,
    onOpen: () => void | Promise<void>,
    onSave: (data: any) => void,
    onCancel: () => void,
    onClose: () => void
    closeDescription?: () => ReactNode,
}

const PPFormWithWizard: React.FC<FormModalProps> = (props) => {
    const [dirtyFields, setDirtyFields] = useState({});
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCheckData, setLoadingCheckData] = useState<boolean>(false);
    const [loadingCEP, setLoadingCEP] = useState<boolean>(false);
    const [formData, setFormData] = useState<object>(props.data);
    const [stepValidationData, setStepValidationData] = useState<any>({});
    const [errorData, setErrorData] = useState<string>("");
    const [labelProps, setLabelProps] = useState<{
        optional?: React.ReactNode;
        error?: boolean;
    }>({});
    const [formValidation, setFormValidation] = useState<{ valid: boolean, help: string }[]>([]);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [timeoutToken, setTimeoutToken] = useState<NodeJS.Timeout>();
    const [activeStep, setActiveStep] = useState<number>(props.activeStep ? props.activeStep : 0);


    const validate = () => {
        if (props.formFields.fieldgroups && props.formFields.fieldgroups.length > activeStep) {
            var onStepValidation = props.formFields.fieldgroups[activeStep].onStepValidation;
            if (onStepValidation) {
                setLoadingCheckData(true);
                onStepValidation({}).then(resultValidation => {
                    setLoadingCheckData(false);
                    if (resultValidation.skipStep == true) {
                        setFormIsValid(true);
                        setActiveStep((prev) => prev + 1);
                        return;
                    } else {
                        setStepValidationData((resultValidation));
                    }
                });
            }
        }

        let _allFields: Field[] = [];
        let _formValidation = { ...formValidation };

        props.formFields.fieldgroups.forEach(g => g.fields.forEach(f => _allFields.push(f)));

        _allFields.forEach((field: Field, fi: number) => {
            // Initialize validation status for the field
            _formValidation[field.name] = { valid: true, help: "" };

            // Only validate if the field is dirty or if the form is being submitted (which we'll check later)
            if (dirtyFields[field.name] || props.mode === 'edit') {
                let isValid = true;
                let helpText = "";

                // Check regex validation if applicable
                if (formData[field.name] && field.validation) {
                    const regex = new RegExp(field.validation.regex);
                    isValid = regex.test(String(formData[field.name]));
                    helpText = isValid ? "" : field.validation.regexMsg;
                }

                // Check required fields
                if (field.required && !formData[field.name]) {
                    isValid = false;
                    helpText = "Campo obrigatório!";
                }

                _formValidation[field.name] = { valid: isValid, help: helpText };
            }
        });

        // Check if form is overall valid
        let _allValidations = Object.values(_formValidation);
        let _allValid = _allValidations.map(fvk => fvk['valid']).reduce((a, b) => { return a && b }, true);
        // let _allValid = Object.values(_formValidation).every(val => val.valid);

        setFormIsValid(_allValid);
        setFormValidation(_formValidation);
    };

    const handleFormSave = () => {
        // Mark all fields as dirty and validate
        const newDirtyFields = props.formFields.fieldgroups.reduce((acc, group) => {
            group.fields.forEach(field => {
                acc[field.name] = true;
            });
            return acc;
        }, {});

        setDirtyFields(newDirtyFields);

        // You might need to delay validation until state update completes
        setTimeout(() => {
            validate();
            // If form is valid, proceed with save
            if (formIsValid) {
                props.onSave(formData);
                handleModalClose();
            } else {
                alert("Please correct the errors before saving.");
            }
        }, 0);
    };

    useEffect(validate, [formData, activeStep]);

    const validateCurrentStep = () => {
        const currentFields = props.formFields.fieldgroups[activeStep]?.fields || [];
        let _formValidation = { ...formValidation };

        currentFields.forEach((field) => {
            _formValidation[field.name] = { valid: true, help: "" };

            if (field.validation && field.required) {
                const regex = new RegExp(field.validation.regex);
                const isValid = regex.test(String(formData[field.name]));
                _formValidation[field.name] = {
                    valid: isValid,
                    help: isValid ? "" : field.validation.regexMsg
                };
            }

            if (field.required && !formData[field.name]) {
                _formValidation[field.name] = { valid: false, help: field.validation ? field.validation.regexMsg : "Campo obrigatório!" };
            }
        });

        const stepIsValid = currentFields.every(field => _formValidation[field.name]?.valid);
        setFormValidation(_formValidation);
        return stepIsValid;
    };

    const handleWizardStepNext = async (onStepSave?: Function, onStepValidation?: Function) => {
        if (onStepValidation) {
            var resultValidation = await onStepValidation();
            if (resultValidation.skipStep == true) {
                var propsNames = resultValidation.stepData && Object.keys(resultValidation.stepData);
                propsNames.map(item => {
                    formData[item] = propsNames[item];
                });
                setActiveStep((prev) => prev + 1);
            } else {
                setStepValidationData((resultValidation));
            }
            return;
        }

        if (onStepSave) {
            labelProps.error = false;
            labelProps.optional = undefined;
            try {
                await onStepSave(formData);
            } catch (e: any) {
                setErrorData(e);
                if (e != null && e.length > 0) {
                    labelProps.optional = (
                        <Typography variant="caption" color="error">
                            Alert message
                        </Typography>
                    );
                    labelProps.error = true;
                }
            }
            setLabelProps({ ...labelProps });
            return;
        }

        if (validateCurrentStep()) {
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
        setDirtyFields(prev => ({ ...prev, [e.target.name]: true }));
    };

    const handleDropdownFieldChange = (e: { label: string, selected: boolean, value: any }, fieldName: string) => setFormData(prev => ({ ...prev, [fieldName]: e.value }));

    const handleCEPChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const cepAPI = axios.create({ baseURL: 'https://viacep.com.br/ws/' });

        const fillAddress = (logradouro: string, bairro: string, localidade: string, uf: string) => {
            props.formFields.fieldgroups.forEach(fg => {
                fg.fields.forEach(f => {
                    if (f.addressFieldFromCEP && f.addressFieldFromCEP == 'logradouro')
                        setFormData(prev => ({ ...prev, [f.name]: logradouro }))
                    if (f.addressFieldFromCEP && f.addressFieldFromCEP == 'bairro')
                        setFormData(prev => ({ ...prev, [f.name]: bairro }))
                    if (f.addressFieldFromCEP && f.addressFieldFromCEP == 'localidade')
                        setFormData(prev => ({ ...prev, [f.name]: localidade }))
                    if (f.addressFieldFromCEP && f.addressFieldFromCEP == 'uf')
                        setFormData(prev => ({ ...prev, [f.name]: uf }))
                })
            })
        }

        handleFieldChange(e);

        var _cep = Number(String(e.target.value).replace(/[^0-9]/, ''));

        if (_cep !== null && _cep > 0 && _cep.toString().length == 8) {
            timeoutToken !== undefined && clearTimeout(timeoutToken);
            setLoadingCEP(true);
            let _token = setTimeout(() => {
                cepAPI.get(`${_cep}/json`)
                    .then(result => {
                        if (result.status == 200 && !result.data.hasOwnProperty('erro')) {
                            let _data: ConsultaCEP = result.data;
                            fillAddress(_data.logradouro, _data.bairro, _data.localidade, _data.uf);
                        }
                    })
                    .catch(error => console.error(error))
                    .then(() => setLoadingCEP(false));
            }, 3000);
            setTimeoutToken(_token);
        } else {
            fillAddress("", "", "", "");
            setLoadingCEP(false)
        }
    }

    const handleFormCancel = () => {
        setFormData({});
        setFormValidation([]);
        setFormIsValid(false);
        setActiveStep(0);
        props.onCancel();
    }

    const handleModalClose = () => {
        setFormData({});
        setFormValidation([]);
        setFormIsValid(false);
        setActiveStep(0);
        props.onClose();
    }

    useEffect(() => {
        const execute = async () => {
            setLoading(true);
            await props.onOpen();
            setLoading(false);
        }
        props.show && execute();
    }, [props.show]);

    useEffect(() => {
        console.log(labelProps);
    }, [labelProps]);

    useEffect(() => {
        if (props.mode === 'edit')
            setFormData(props.data)
    }, [props.data]);

    const handleWizardStepBack = () => setActiveStep((prev) => prev - 1);

    const renderWizardFormReview = () => {
        let _allFields: Field[] = [];
        props.formFields.fieldgroups.forEach(g => g.fields.forEach(f => _allFields.push(f)));

        return <>
            {
                _allFields.map((field: Field, fi: number) => {
                    let _value: any = formData[field.name];
                    if (field.type === 'password') {
                        _value = '*****';
                    }
                    if (field.type === 'checkbox') {
                        _value = _value == true ? 'Sim' : 'Não';
                    }
                    var displayValue = field.options && field.options.length > 0 ? field.options.filter(x => x.value == _value)[0].label : _value;

                    return (
                        <li key={fi}>
                            <span><strong>{field.label}</strong>: {displayValue}</span>
                        </li>
                    )
                })
            }
        </>
    }

    const ColorlibConnector = withStyles({
        alternativeLabel: {
            top: 22,
        },
        active: {
            '& $line': {
                // backgroundImage:
                //     'linear-gradient( 95deg,rgb(0,255,0) 0%, rgb(0,255,255) 100%)',
                background: '#173192',
            },
        },
        completed: {
            '& $line': {
                // backgroundImage:
                //     'linear-gradient( 95deg,rgb(0,255,0) 0%, rgb(0,255,255) 100%)',
                background: '#173192',
            },
        },
        line: {
            padding: '0px',
            height: 2,
            border: 0,
            backgroundColor: '#CCCCCF',
            borderRadius: 1,
            zIndex: 0
        },
    })(StepConnector);

    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: '#EBEBEB',
            zIndex: 100,
            color: '#2B304F',
            width: 36,
            height: 36,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
        },
        active: {
            // backgroundImage: 'linear-gradient(192deg, #0FF 6.38%, #0F0 92.76%)',
            background: '#173192',
            color: '#fff',
        },
        completed: {
            // backgroundImage: 'linear-gradient(192deg, #0FF 6.38%, #0F0 92.76%)',
            background: '#173192',
            color: '#fff',
        },
    });

    function ColorlibStepIcon(props: StepIconProps) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;
        return (<div>
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            > {completed ? <Check /> : <div  >{props.icon} </div>}
            </div>
        </div>);
    }

    const useStepLabelStyles = makeStyles(theme => ({
        label: {
            // Sobrescreve diretamente a classe do label
            '&.MuiStepLabel-label': {
                color: '#8B90A3',
                fontWeight: 'normal',
                fontSize: '12px',
                fontFamily: "Ubuntu",
            }
        },
        labelActive: {
            '&.MuiStepLabel-label.MuiStepLabel-active': {
                color: '#8B90A3',
                fontWeight: 'bold',
            }
        },
        labelCompleted: {
            '&.MuiStepLabel-label.MuiStepLabel-completed': {
                color: '#173192',
            }
        },
    }));



    const stepLabelStyles = useStepLabelStyles(); // Chama o hook para obter os estilos


    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };

    const customComponent = (htmlString) => {
        return <div dangerouslySetInnerHTML={createMarkup(htmlString)} />;
    };

    const wizardInfo = (recordCall) => {
        if (recordCall !== undefined && recordCall) {
            return <div className="wizard-info-container">
                <AiOutlineInfoCircle className="wizard-info-icon" color="#2E1956" width="24px" height="24px" />
                <div className="wizard-info-text">
                    <div className="DashboardTitulo" > Instruções </div>
                    <div className="contentInformation" >{customComponent(recordCall())}</div>
                </div>
            </div>
        }
        return <div></div>;
    };

    const errorInfo = (error) => {
        if (error !== undefined && error) {
            return <div className="wizard-info-container">
                <AiOutlineWarning className="wizard-info-icon" color="red" width="24px" height="24px" />
                <div className="wizard-info-text">
                    <div className="DashboardTitulo" > Problemas </div>
                    <div className="contentInformation" >{customComponent(error)}</div>
                </div>
            </div>
        }
        return <div></div>;
    };

    const renderForm = () => {
        if (props.type === "fieldset")
            return props.formFields.fieldgroups.map((group: FieldGroup, fsi: number) => {
                return group.accordion ? (
                    <LcAccordion key={fsi} title={group.label} visible={true}>
                        <AccordionDetails className='formModalFieldset'>
                            {renderFields(group.fields)}
                        </AccordionDetails>
                    </LcAccordion>
                ) : (
                    <Box component='fieldset' key={fsi} className='formModalFieldset '>
                        {group.label && <legend className='formModalFieldsetTitle'>{group.label}</legend>}
                        {renderFields(group.fields)}
                    </Box>
                )
            });

        if (props.type === "wizard" && labelProps)
            return <>
                <Stepper activeStep={activeStep} connector={<ColorlibConnector />} >
                    {
                        props.formFields.fieldgroups.map((group: FieldGroup, fgi: number) => {
                            return (
                                <Step id={fgi.toString()} itemID={fgi.toString()} key={`${fgi}_${group.label}`}>
                                    <StepLabel
                                        {...labelProps}
                                        key={`${fgi}_${group.label}`}
                                        id={fgi.toString()}
                                        itemID={fgi.toString()}
                                        classes={{
                                            label: stepLabelStyles.label, // Estilo padrão
                                            active: stepLabelStyles.labelActive, // Estilo para passo ativo
                                            completed: stepLabelStyles.labelCompleted // Estilo para passo completado
                                        }}
                                        StepIconComponent={ColorlibStepIcon}
                                    >
                                        {group.label}
                                    </StepLabel>
                                </Step>
                            );
                        })
                    }
                    <Step key="stepper_completed">
                        <StepLabel
                            classes={{
                                label: stepLabelStyles.label, // Estilo padrão
                                active: stepLabelStyles.labelActive, // Estilo para passo ativo
                                completed: stepLabelStyles.labelCompleted // Estilo para passo completado
                            }}
                            StepIconComponent={ColorlibStepIcon}
                        >
                            Revisar e Salvar
                        </StepLabel>
                    </Step>
                </Stepper>
                <div className='formModalWizardFormArea'>
                    {
                        activeStep < props.formFields.fieldgroups.length &&
                        <div className='formModalWizardFieldGroup scrollable-v'>
                            {renderFields(props.formFields.fieldgroups[activeStep].fields)}
                        </div>
                    }

                    {
                        activeStep == props.formFields.fieldgroups.length &&
                        <div className='formModalWizardReviewArea scrollable-v'>
                            <div className='formModalWizardReviewDesc'> Revise os dados e confirme antes de salvar! Caso deseje alterar algo, clique em "Voltar".</div>
                            <div>
                                <ul>{renderWizardFormReview()}</ul>
                            </div>
                        </div>
                    }
                    <div className='infoWizardFieldGroup' style={{ fontSize: 'Inter' }}>
                        <LcLoading loading={loadingCheckData} label="Realizando checagem de dados já cadastrados, etapa poder ser auto-completada...">
                        </LcLoading>
                        {errorData && <> {errorData !== undefined
                            ? errorInfo(errorData)
                            : <></>}
                        </>}
                        {stepValidationData && <> {stepValidationData !== undefined
                            ? <div>{stepValidationData && stepValidationData.log && stepValidationData.log}</div>
                            : <></>}
                        </>}
                        {props.formFields && props.formFields.fieldgroups[activeStep] && typeof props.formFields.fieldgroups[activeStep].description === 'function'
                            && <> {props.formFields.fieldgroups[activeStep].description !== undefined
                                ? wizardInfo(props.formFields.fieldgroups[activeStep].description)
                                : <></>}
                            </>}
                    </div>
                    <div className='formModalWizardButtons'>
                        <div>
                            <button
                                className="lc-button bg-gray"
                                type="button"
                                hidden={activeStep === 0}
                                disabled={activeStep === 0}
                                onClick={handleWizardStepBack}
                                style={{ float: 'left' }}
                            >  Voltar
                            </button>
                        </div>
                        <div>
                        </div>
                        <div>
                            <button
                                className="lc-button bg-info mr-6"
                                type="button"
                                hidden={activeStep === props.formFields.fieldgroups.length}
                                disabled={activeStep === props.formFields.fieldgroups.length}
                                onClick={() => { handleWizardStepNext(props.formFields.fieldgroups[activeStep].onStepSave, props.formFields.fieldgroups[activeStep].onStepValidation); }}
                                style={{ float: 'right' }}
                            > Próximo
                            </button>
                            {
                                activeStep == props.formFields.fieldgroups.length && <div className="lc-button bg-info mr-6">
                                    <button className="lc-button bg-info mr-6" type="button" onClick={handleFormSave} disabled={!formIsValid} style={{ float: 'right' }}>
                                        Concluir
                                    </button>
                                </div>
                            }
                            {formIsValid}
                        </div>
                    </div>
                </div >
            </>
    }

    const renderFields = (fields: Field[]) => {
        return fields.map((field: Field, fi: number) => {
            return (
                <div>
                    {/* <PPTooltip content={String(field.tooltip)} trigger='hover'> */}
                    {
                        ["text", "password", "number"].includes(field.type) &&
                        <PPInput
                            key={`${fi}_${field.name}`}
                            type={field.type as "text" | "password" | "number"}
                            name={field.name}
                            title={field.label}
                            value={formData[field.name] as string || ''}
                            hidden={field.hidden}
                            disabled={field.disabled || (props.mode === "edit" && field.blockEdit)}
                            required={field.required}
                            onChange={handleFieldChange}
                            width={field.width}
                            error={field.required && formValidation[field.name] ? {
                                show: !formValidation[field.name].valid as boolean,
                                help: formValidation[field.name].help as string
                            } : { show: false, help: null }
                            }
                        />
                    }
                    {
                        field.type == "CEP" &&
                        <LcLoading loading={loadingCEP} label="Buscando CEP...">
                            <PPInput
                                key={`${fi}_${field.name}`}
                                type="number"
                                name={field.name}
                                title={field.label}
                                value={formData[field.name] as number}
                                hidden={field.hidden}
                                disabled={field.disabled || (props.mode === "edit" && field.blockEdit)}
                                required={field.required}
                                onChange={handleCEPChange}
                                width={field.width}
                                error={field.required && formValidation[field.name] ? {
                                    show: !formValidation[field.name].valid as boolean,
                                    help: formValidation[field.name].help as string
                                } : { show: false, help: null }
                                }
                            />
                        </LcLoading>
                    }
                    {
                        field.type == "textarea" &&
                        <PPTextArea
                            key={`${fi}_${field.name}`}
                            name={field.name}
                            title={field.label}
                            value={formData[field.name] as string}
                            hidden={field.hidden}
                            disabled={field.disabled || (props.mode === "edit" && field.blockEdit)}
                            required={field.required}
                            onChange={handleFieldChange}
                            width={String(field.width)}
                            error={field.required && formValidation[field.name] ? {
                                show: !formValidation[field.name].valid as boolean,
                                help: formValidation[field.name].help as string
                            } : { show: false, help: null }
                            }
                        />
                    }
                    {
                        field.type == "checkbox" &&
                        <PPCheckBox
                            key={`${fi}_${field.name}`}
                            name={field.name}
                            title={field.label}
                            checked={formData[field.name] as boolean}
                            disabled={field.disabled || (props.mode === "edit" && field.blockEdit)}
                            onChange={handleFieldChange}
                        />
                    }
                    {
                        field.type == "dropdown" &&
                        <PPDropDown
                            key={`${fi}_${field.name}`}
                            name={field.name}
                            title={field.label}
                            handleMenuOpen={field.handleMenuOpen}
                            value={field.options?.find(o => o.value === formData[field.name] as string)}
                            options={field.options ?? []}
                            disabled={field.disabled || (props.mode === "edit" && field.blockEdit)}
                            required={field.required}
                            onChange={(e) => handleDropdownFieldChange(e, field.name)}
                            width={`${field.width}px`}
                            maxMenuHeight={80}
                            error={field.required && formValidation[field.name] ? {
                                show: !formValidation[field.name].valid as boolean,
                                help: formValidation[field.name].help as string
                            } : { show: false, help: null }
                            }
                            menuPlacement={field.position}
                        />
                    }
                    {/* </PPTooltip> */}
                </div>
            )
        })
    }

    const card = [
        {
            bgColor: '#FFF',
            id: 1,
            title: () => {
                return (<div style={{ fontFamily: 'Ubuntu', fontSize: '16px' }}> <FaHatWizard style={{ marginRight: '4px' }} />{props.title} </div>)
            },
            type: 'Custom',
            position: { ColSpan: 1, RowSpan: 2, row: 1, col: 1 },
            customContentRender: () => {
                return (<div style={{ minHeight: '100%' }}>
                    <LcLoading loading={loading} label="Carregando dados do formulário, aguarde...">
                        {props.desc && <div className="formModalDescription">{props.desc}</div>}
                        {renderForm()}
                    </LcLoading>
                </div>
                )
            }
        }
    ];

    return (props.isModal == false ?
        <LCDashboard
            grid={[1, 1]}
            cards={card}
            rightSidePanel={{
                title: 'Filtros',
                pinned: false,
                show: false,
                focusable: false,
                content: () => { return <></>; },
            }}
        /> :
        <PPModal title={props.title} visible={props.show} onClose={handleModalClose} size={props.size} functions={[
            { label: "Salvar", onClick: handleFormSave, disabled: !formIsValid, hidden: props.type === "wizard" },
            { label: "Cancelar", onClick: handleFormCancel, disabled: false, hidden: props.type === "wizard" }
        ]}>
            <LcLoading loading={loading} label="Carregando dados do formulário, aguarde...">
                {props.desc && <div className="formModalDescription">{props.desc}</div>}
                {renderForm()}
            </LcLoading>
        </PPModal>
    );
}

export default PPFormWithWizard;