import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { NotificationCenterService, NotificationModel, convertToNotification } from '../../../services/notificationCenter/notificationService';
import moment from 'moment';
import LcIconLink from '../../Generic/LcIconLink';
import LcToggle from '../../Form/LcToggle';
import { TbPinned, TbPinnedOff } from 'react-icons/tb';
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from 'react-icons/io5';
import LcLoading from '../../Generic/LcLoading';

interface NotificationsProps {
    visible: boolean;
    onClose: (visible: boolean) => void;
}

export interface Notification {
    notificationDateTime: string; // ISO string format
    userEmail: string;
    title: string;
    subTitle: string;
    content: string;
    isRead: boolean;
    sendEmail: boolean;
    alertHeyHo: boolean;
    importance?: number; // Nullable field
    notificationId: string;
    url: string;
    observation: string;
    pinned: boolean;
    applicationId?: string; // Nullable field
    context: string;
    expiredDate?: string; // Nullable field, ISO string format
}

const NotificationsComponent: React.FC<NotificationsProps> = ({ visible, onClose }) => {
    moment.locale('pt-br');

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();
    const serviceNotification = new NotificationCenterService({});
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const [showAllMessages, setShowAllMessages] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [hasMore, setHasMore] = useState(false);

    const queryClient = useQueryClient();
    const pageSize = 10;

    const fetchNotifications = async (page: number, showAll: boolean, searchText: string) => {
        if (showAll) {
            return await serviceNotification.GetReadNotificationsPaged(page, pageSize, searchText);
        }
        var notification = await serviceNotification.GetNotifications(searchText);
        dispatch({ type: 'MSG_UPDATE', payload: notification || [] });

        return notification;
    };

    useEffect(() => {
        queryClient.refetchQueries();
    }, [showAllMessages])

    const { data: initialData, isLoading, isFetching, refetch } = useQuery(
        ['notifications', showAllMessages, search],
        () => fetchNotifications(1, showAllMessages, search),
        {
            refetchInterval: 5 * 60 * 1000, // 5 minutes
            onSuccess: (data) => {
                setNotifications(data || []);
                setPageNumber(1);
                setHasMore(data?.length === pageSize);
            }
        }
    );

    const { data: nextPageData } = useQuery(
        ['notifications', showAllMessages, pageNumber, search],
        () => fetchNotifications(pageNumber, showAllMessages, search),
        {
            enabled: pageNumber > 1,
            keepPreviousData: true,
            onSuccess: (data) => {
                if (data && data.length > 0) {
                    setNotifications(prev => [...prev, ...data]);
                    setHasMore(data.length === pageSize);
                } else {
                    setHasMore(false);
                }
            }
        }
    );
    const markAsReadButton = useMutation(
        async (msg: Notification) => {
            const updatedMessage: Notification = { ...msg, isRead: true };
            await serviceNotification.PostNotifications(updatedMessage);
            return updatedMessage;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications', showAllMessages, search]);
            }
        }
    );

    const markAsRead = useMutation(
        async (msg: Notification) => {
            const updatedMessage: Notification = { ...msg, isRead: true };
            await serviceNotification.PostNotifications(updatedMessage);
            return updatedMessage;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications', showAllMessages, search]);
            }
        }
    );

    const checkExpiredDate = (date: string | null) => {
        if (!date) return false;
        const expiredDate = new Date(date).getTime();
        const now = Date.now();
        return now > expiredDate;
    };

    const observeMessage = (msg: Notification) => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        markAsRead.mutate(msg);
                    }, 10000); // Change the time (5000 ms = 5 seconds) as needed
                }
            });
        });

        const messageElement = document.getElementById(`message-${msg.notificationId}`);
        if (messageElement) {
            observer.observe(messageElement);
        }

        return () => {
            if (messageElement) {
                observer.unobserve(messageElement);
            }
        };
    };

    const handleScroll = useCallback(() => {
        if (!scrollRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !isLoading) {
            setPageNumber(prev => prev + 1);
        }
    }, [hasMore]);

    const handleLoadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber(prev => prev + 1);
        }
    };

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        if (notifications.length > 0) {
            notifications.forEach(msg => {
                if (!msg.isRead) {
                    observeMessage(convertToNotification(msg));
                }
            });
        }
    }, [notifications]);

    let timerClose: NodeJS.Timeout;
    const handleMouseLeave = () => {
        clearTimeout(timerClose);
        timerClose = setTimeout(() => onClose(false), 2000);
    };

    return (
        <div
            ref={scrollRef}
            className={`notifications${visible ? ' visible' : ''}`}
            onMouseOver={() => clearTimeout(timerClose)}
            onMouseLeave={handleMouseLeave}
        >
            <div className="header">
                <div className="title">Notificações</div>
                <div className="functions">
                    <LcIconLink icon="ppi ppi-search" color={search.length > 0 ? "orange" : undefined} onClick={() => {
                        setNotifications([]);
                        setSearchVisible(!searchVisible);
                    }} />
                </div>
            </div>
            <div className={`search${searchVisible ? ' visible' : ''}`}>
                <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Termo de busca"
                    value={search}
                />
                <i className="ppi ppi-x link" onClick={() => setSearch('')} />
            </div>
            <div className="subheader">
                <LcToggle toggled={showAllMessages} onChange={(e) => {
                    setShowAllMessages(!showAllMessages);
                    setNotifications([]);
                }} title="Ver lidas" />
            </div>
            <div className="body">
                {isLoading || isFetching ? (
                    <LcLoading />
                ) : (
                    <>
                        {notifications
                         //   .filter(m => !m.isRead)
                            .map((m, i) => (
                                <div key={i} id={`message-${m.notificationId}`} className={`message${m.isRead ? ' read' : ''}`}>
                                    <div className="header">
                                        <div className="title">
                                            {m.subTitle}
                                        </div>
                                    </div>
                                    <div className="when">
                                        {moment(m.notificationDateTime).format('DD/MM/YYYY HH:mm:ss')}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: m.content }} />
                                    <div style={{ width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }} onClick={() => { markAsReadButton.mutate(convertToNotification(m)); }}>
                                        {m.isRead ? (
                                            <IoCheckmarkDoneOutline />
                                        ) : (
                                            <IoCheckmarkOutline />
                                        )}
                                    </div>
                                </div>
                            ))}
                        {(isLoading || isFetching) && <LcLoading />}
                    </>
                )}
                {hasMore && !isLoading && (
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <button onClick={handleLoadMore} className="load-more-link">
                            Carregar mais
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsComponent;
