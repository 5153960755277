import React, { useCallback, useEffect, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import EventsGraph from '../resumeEvent/EventsGraph';
import { ResumeEvent, devicesEvent } from '../resumeEvent/Models';
import { groupBy } from '.';
import LcNoData from '../../../components/Generic/LcNoData';

interface EventClassificationChartModel {
    loadingEventsBlock: boolean;
    periodSelected: string;
    resumeEventReport: ResumeEvent;
    isHome: boolean;
    isSecOpsPage?: boolean;
    setChartRefEvents: (ref: any) => void;
}


export const EventClassificationChart: React.FC<EventClassificationChartModel> = ({
    loadingEventsBlock,
    periodSelected,
    resumeEventReport,
    isHome,
    isSecOpsPage,
    setChartRefEvents
}) => {

    const [eventsChart, setEventsChart] = useState<any>();

    const getClassificationChart = useCallback((resumeEventReport: devicesEvent[]) => {
        const ress = groupBy(resumeEventReport, "classification");
        const sortedEvents = resumeEventReport.sort((a, b) => b.classificationRank - a.classificationRank);
        const ressColor = [...new Set(sortedEvents.map(g => g.color))];
    
        return Object.keys(ress).map((t, index) => {
            const filteredEvents = sortedEvents.filter(b => b.classification === t);
            const value = filteredEvents.length > 0
                ? filteredEvents.reduce((sum, event) => sum + event.count, 0)
                : 0;
    
            return {
                name: t,
                value,
                itemStyle: {
                    color: ressColor[index]
                }
            };
        });
    },[])

    const filterArrayPerDate = useCallback((array: devicesEvent[], period: number) => {
        const today = new Date();
        const daysAgoDate = new Date(today);
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0);

        return array.filter(data => {
            return 'lastSeen' in data && data.lastSeen && new Date(data.lastSeen) >= daysAgoDate && new Date(data.lastSeen) <= today;
        });
    }, []);

    useEffect(() => {
        if (!resumeEventReport || !periodSelected) return;
    
        const daysMap = {
            'last7Days': 7,
            'last30Days': 30,
            'last90Days': 90,
            'alltime': null
        };
    
        const days = daysMap[periodSelected as keyof typeof daysMap];
    
        if (days === undefined) {
            console.warn(`Unexpected period value: ${periodSelected}`);
            return;
        }
    
        const events = days === null ? resumeEventReport.devices : filterArrayPerDate(resumeEventReport.devices, days);
        setEventsChart(getClassificationChart(events));

    }, [resumeEventReport, periodSelected, getClassificationChart, filterArrayPerDate]);



    return(
        <LcLoading loading={loadingEventsBlock}>
            {eventsChart ? (
                <EventsGraph
                    onRef={setChartRefEvents}
                    data={eventsChart}
                    legendOrientation={
                    isHome || isSecOpsPage
                        ? "horizontal"
                        : "vertical"
                    }
                    title={
                    isHome || isSecOpsPage
                        ? "Eventos no momento"
                        : undefined
                    }
                />
            )
            :
            (
                <LcNoData />
            )}
        </LcLoading>
    )
}
