import React from 'react';
import { OperacialSystemEvent } from '../../resumeEvent/Models';


interface Props {
    totalEventsBlock: number;
    resumeReport: OperacialSystemEvent[];
    eventImg?: string;
    dispositivosImg?: string
    topEvents?: any;
    topEventsUsers?: any;
    workstation?: any;
    IOT?: any;
    servers?: any;
    period?: string;
    mostTargetSelected?: string

}

const ReportResumePDF: React.FC<Props> = (props) => {
    const states = [...new Set(props.resumeReport.map(item => item.state))];
    const osFamilies = [...new Set(props.resumeReport.map(item => item.osFamily))];
    const initialValue = 0;
    const totalColectors = props.resumeReport.reduce((accumulator, currentValue) => accumulator + currentValue.count, initialValue)
    return (
        <div style={{marginLeft: '5px'}}>
            <h2>1. Eventos </h2>
            <h3 style={{marginTop: '-10px'}}>Total de eventos bloqueados: {props.totalEventsBlock}</h3>
            <div style={{display: 'flex', width: '100%', height: '150px', alignItems: 'center', textAlign: 'center', justifyContent: 'center', 
                marginTop: props.totalEventsBlock > 1000 ?'20px' :'50px', marginBottom:props.totalEventsBlock > 1000 ?'10px': '50px'}}>
                <img src={props.eventImg} width={'55%'} alt='Gráfico de eventos'/>
            </div>
            <h3 style={{marginTop:'0px'}}>Principais alvos {props.mostTargetSelected === 'user'? 'dispositivos' : 'coletores'}</h3>    
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', width: '90%', marginLeft:'20px', height: 'auto'}}>
                <table style={{width:'100%',borderCollapse: 'collapse'}}>
                <thead style={{marginBottom: '5px', fontSize:'16px'}}>
                    <tr style={{borderBottom: '1px solid #d3d3d3'}}>
                    <th style={{textAlign: 'left'}} >Classificação</th>
                    <th style={{textAlign: 'left'}} >Dispositivo</th>
                    <th style={{textAlign: 'left'}} >Quantidade</th>
                    </tr>
                </thead>
                <tbody>
                    {props.topEventsUsers.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                        <td  style={{borderBottom: '1px solid #d3d3d3'}} rowSpan={item.topDevices.length}>{item.classification}</td>
                        <td>{item.topDevices[0].device}</td>
                        <td>{item.topDevices[0].totalCount}</td>
                        </tr>
                        {item.topDevices.slice(1).map((device, idx) => (
                        <tr key={idx} className={idx % 2 === 0 ? 'colored' : ''}>
                            <td>{device.device}</td>
                            <td>{device.totalCount}</td>
                        </tr>
                        ))}
                    </React.Fragment>
                    ))}
                </tbody>
                </table>
            </div>
            {
                props.topEventsUsers.length > 5 &&
                <div style={{height: props.totalEventsBlock > 500 ? '150px' : '200px'}}></div> 
            }

            <div style={{display: 'flex',flexDirection: 'column', alignItems: 'left', width: '100%',  marginTop: props.totalEventsBlock <= 200 && props.topEventsUsers.length > 5  ? '80px': '20px'}}>
                <h3>Top processos por classificação</h3>
                <div style={{ display: 'flex',flexDirection: 'column',  height: 'auto', alignItems: 'center', justifyContent:'center', width: '90%', marginLeft: '20px'}}>
                    <table style={{width:'100%', height: 'auto', borderCollapse: 'collapse'}} className="tabela">
                        <thead style={{marginBottom: '10px', fontSize:'16px'}}>
                            <tr style={{borderBottom: '1px solid #d3d3d3'}}>
                            <th style={{textAlign: 'left'}}>Classificação</th>
                            <th style={{textAlign: 'left'}}>Processo</th>
                            <th style={{textAlign: 'left'}}>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.topEvents.map((item, index) => (
                            <tr key={index} className={index % 2 !== 0 ? 'colored' : ''}>
                                <td>{item.classification}</td>
                                <td>{item.process}</td>
                                <td>{item.count}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{pageBreakBefore: props.topEventsUsers.length <= 5 ?'always': 'auto', marginTop: props.topEventsUsers.length <= 5  ? '120px' :'30px'}}>
                <h2>2. Dispositivos(Coletores)</h2>
                <h3 style={{marginTop: '-10px'}}>Total de dispositivos: {totalColectors}</h3>
                <div style={{display: 'flex', width: '100%', height: props.totalEventsBlock > 60 ? '170px': '140px', alignItems: 'center', textAlign: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={props.dispositivosImg} width={'50%'} alt='Gráfico de dispositivos'/>
                </div>
                <div style={{marginTop: '40px'}}>
                    <h3>Dispositivos por sistema operacional</h3>
                    <table  style={{width:'90%',marginLeft: '20px', height: '100px', borderCollapse: 'collapse'}}>
                        <thead  style={{marginBottom: '10px', fontSize:'16px'}}>
                            <tr style={{borderBottom: '1px solid #d3d3d3', height: '30px'}}>
                            <th></th> {/* Célula vazia para o canto superior esquerdo */}
                            {states.map(state => (
                                <th style={{textAlign: 'left'}} key={state}>{state}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {osFamilies.map(osFamily => (
                            <tr key={osFamily}>
                                <td>{osFamily}</td>
                                {states.map(state => {
                                const intersection = props.resumeReport.find(item => item.osFamily === osFamily && item.state === state);
                                return (
                                    <td style={{textAlign: 'left', height: '10px', width: '120px',}} key={state}>{intersection ? intersection.count : 0}</td>
                                );
                                })}
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h2>3. Ambiente Protegido</h2>
                <div >
                    <table  style={{width:'90%', marginLeft: '20px', height: '50px', borderCollapse: 'collapse'}}>
                        <thead  style={{marginBottom: '10px', fontSize:'16px',}}>
                            <tr style={{borderBottom: '1px solid #d3d3d3', height: '30px'}}>
                                <th></th>
                                <th></th>
                                <th></th>
                            {['Em uso', 'Total'].map((state) => (
                                <th style={{ borderBottom: '1px solid #d3d3d3', textAlign: 'left',}} key={state}>{state}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width: '64%'}} >Workstations</td>
                                <td></td>
                                <td></td>
                                <td style={{textAlign: 'left', height: '10px'}} >{props.workstation.inUse}</td>
                                <td style={{textAlign: 'left', height: '10px'}}>{props.workstation.sip}</td>

                            </tr>
                            <tr>
                                <td style={{ width: '64%'}}>Servers</td>
                                <td></td>
                                <td></td>
                                <td style={{textAlign: 'left', height: '10px'}} >{props.servers.inUse}</td>
                                <td style={{textAlign: 'left', height: '10px'}} >{props.servers.sip}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '64%'}} >IOT</td>
                                <td></td>
                                <td></td>
                                <td style={{textAlign: 'left', height: '10px'}} >{props.IOT.inUse}</td>
                                <td style={{textAlign: 'left', height: '10px'}} >{props.IOT.sip}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) 
    
}

export default ReportResumePDF;