import api, { APIClient } from "../api";

export default class ManageOfficeStatusService extends APIClient {

    constructor(props:any){
        super(props);
    }

    async GetServices() {
        const url = this.endpoint + `/api/ManageOfficeStatus/GetAllCurrentStatus?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }

    async GetAllMessage(){
        const url = this.endpoint + `/api/ManageOfficeStatus/GetAllMessages`;
        return api({
            method: 'get',
            url
        });
    }

    async GetAllMessagesByWorkLoad(workload: string){
        const url = this.endpoint + `/api/ManageOfficeStatus/GetAllMessagesByWorkLoad/${workload}`;
        return api({
            method: 'get',
            url
        });
    }    
}