import { User } from "../../pages/portalUsers/main";
import api, { APIClient } from "../api";

export class PortalUsersService extends APIClient {
    state = {
        data: {
            Clients: [] as any[],
            Profiles: [] as any[],
            LinkedClients: [] as any[],
            DefaultClient: { label: '', value: 0, selected: false },
            template: {}
        }
    };

    constructor(props: any) {
        super(props);
    }

    async initialize(disabled: boolean) {
        await this.retrieveCustomers();
        await this.getTemplate(disabled);
    }

    async initializeEdit(disabled: boolean, userId: string) {
        await Promise.all([
            this.retrieveUserProfiles(userId),
            this.retrieveDefaultCustomer(userId),
            this.retrieveLinkedCustomers(userId),
            this.retrieveEditCustomers()
        ]);
        await this.getTemplate(disabled);
    }

    ActionsBaseFunctions(createAction: any, CloseActions: any, DeleteActions: any) {
        let arrayActions = {
            functionsEdit: [] as ButtonEventGrid[]
        };

        if (createAction) {
            arrayActions.functionsEdit.push({
                id: "a2d8df1c-6612-4d04-90e0-a106f2fae13c",
                title: 'Salvar',
                icon: 'lci lci-check',
                func: (item: any) => {
                    createAction(item);
                },
                disabled: false,
                skipvalidation: false
            });
        }

        if (CloseActions) {
            arrayActions.functionsEdit.push({
                id: "3b5e7268-0323-4064-b87b-6336d830ea90",
                title: 'Fechar',
                icon: 'lci lci-x',
                tooltip: 'Voltar',
                func: () => {
                    if (CloseActions) {
                        CloseActions();
                    }
                },
                disabled: false,
                skipvalidation: true
            });
        }

        return arrayActions;
    }

    async removeUserById(userId: string) {
        var result = await this.deleteUser(userId);
        return result;
    }

    async retrieveUserProfiles(userId) {
        var result = await this.GetListClientGroupsPerfis();
        var resultUser = await this.GetPerfisByUser(userId);

        let data = result.data;
        let array: any[];
        array = data.map((item: any) => {
            return {
                value: item.id,
                label: `${item.nome}${item.isTemplate ? ' (Template)' : ''}`,
                selected: resultUser.data && resultUser.data.map(up => up.clientGroupPerfilId).includes(item.id)
            };
        })

        var sortedArray: { value: any; label: any; }[] = array.sort((element1, element2) => {
            if (element1.label > element2.label) {
                return 1;
            }

            if (element1.label < element2.label) {
                return -1;
            }

            return 0;
        });

        const stateData = { ...this.state };
        stateData.data.Profiles = sortedArray;
        this.setState({ stateData });
    }

    async retrieveCustomers() {
        if (this.state.data.Clients && this.state.data.Clients.length > 0) {
            return this.state.data.Clients;
        }

        var result = await this.getClientActive();

        let data = result.data;
        let array: any[];
        array = data.map((item: any) => {
            return {
                value: item.id,
                label: item.description
            };
        })

        var sortedArray: { value: any; label: any; }[] = array.sort((element1, element2) => {
            if (element1.label > element2.label) {
                return 1;
            }

            if (element1.label < element2.label) {
                return -1;
            }

            return 0;
        });

        const stateData = { ...this.state };
        stateData.data.Clients = sortedArray;
        this.setState({ stateData });
    }

    async retrieveEditCustomers() {

        var result = await this.getClientActive();

        let data = result.data;
        let array: any[];
        array = data.map((item: any) => {
            return {
                value: item.id,
                label: item.description,
                selected: this.state.data.LinkedClients.find(y => y.value == item.id) ? true : false
            };
        })

        var sortedArray: { value: any; label: any; }[] = array.sort((element1, element2) => {
            if (element1.label > element2.label) {
                return 1;
            }

            if (element1.label < element2.label) {
                return -1;
            }

            return 0;
        });

        const stateData = { ...this.state };
        stateData.data.Clients = sortedArray;
        this.setState({ stateData });
    }

    async retrieveDefaultCustomer(userId: string) {
        var result = await this.GetDefaultUserById(userId);
        let data = result.data;
        let obj = {
            value: data.clientGroupId,
            label: data.descricao,
            selected: true
        }
        const stateData = { ...this.state };
        stateData.data.DefaultClient = obj;
        this.setState({ stateData });
    }

    async retrieveLinkedCustomers(userId: string) {
        var result = await this.GetAllClientsByUserId(userId);
        let data = result.data;
        let array: any[];
        array = data.map((item: any) => {
            return {
                value: item.clientGroupId,
                label: item.descricao,
                selected: true
            };
        })

        const stateData = { ...this.state };
        stateData.data.LinkedClients = array;
        this.setState({ stateData });
    }

    public emptyRecord: User = {
        "userName": '',
        "changePwdFirstLogin": true,
        "name": '',
        "email": '',
        "emailConfirmed": false,
        "phoneNumber": '',
        "phoneNumberConfirmed": false,
        "twoFactorEnabled": false,
        "lockoutEnabled": false,
        "accessFailedCount": 0,
        "isConfirmed": false,
        "dateEdited": (new Date()).toISOString(),
        "successfulLoginCount": 0,
        "dateCreated": (new Date()).toISOString(),
        "active": false,
        "isDeleted": false,
        "idDashboardPadrao": 0,
        "recebeuWelcomeKit": false,
        "permiteEnvioEmail": true,
        "idiomaPadrao": 'pt',
        "gmtPadrao": "E. South America Standard Time",
        "isAdministrator": false,
        "isMultiOrganization": false
    };

    retrieveFormTemplate(disabled: boolean) {
        const url = this.endpoint + `/api/PortalUser/RetrieveFormTemplate`

        return api({
            method: 'post',
            url,
            data: disabled,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getTemplate(disabled: boolean) {
        var response = await this.retrieveFormTemplate(disabled);
        const itemTemplate = {
            title: 'client',
            Fields: [{
                edit: true,
                row: 1, col: 1, colspan: 2, rowspan: 77,
                name: 'name',
                type: 'text',
                title: 'Nome',
                required: {
                    required: 'Nome é obrigatório',
                    value: true,
                    pattern: {
                        value: /^(.{1,100})$/,
                        message: 'Nome deve ter no maximo 100 caracteres'
                    }
                },
                disabled: disabled
            }, {
                edit: true,
                row: 1, col: 1, colspan: 2, rowspan: 1,
                name: 'userName',
                type: 'text',
                title: 'Login',
                required: {
                    required: 'Login é obrigatório',
                    value: true,
                    pattern: {
                        value: /^(.{1,100})$/,
                        message: 'Nome deve ter no maximo 100 caracteres'
                    }
                },
                disabled: disabled
            }, {
                edit: true,
                row: 1, col: 1, colspan: 2, rowspan: 1,
                name: 'email',
                type: 'text',
                title: 'E-mail',
                required: {
                    required: 'E-mail é obrigatório',
                    value: true,
                    pattern: {
                        value: /^(.{1,100})$/,
                        message: 'Nome deve ter no maximo 100 caracteres'
                    }
                },
                disabled: disabled
            }, {
                edit: true,
                row: 1, col: 1, colspan: 2, rowspan: 1,
                name: 'phoneNumber',
                type: 'text',
                title: 'Telefone',
                disabled: disabled
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'idiomaPadrao',
                type: 'select',
                title: 'Idioma padrão',
                required: {

                    value: true,
                    required: 'Idioma padrão é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0) {
                        return true;
                    }
                    return false;
                },
                options: this.idiomas
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'gmtPadrao',
                type: 'select',
                title: 'GMT Padrão',
                required: {

                    value: true,
                    required: 'GMT Padrão é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0) {
                        return true;
                    }
                    return false;
                },
                options: this.GMTS
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'client',
                type: 'select',
                multiselect: true,
                title: 'Cliente',
                required: {

                    value: true,
                    required: 'Cliente é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0) {
                        return true;
                    }
                    return false;
                },
                options: this.state.data.Clients != null ? this.state.data.Clients : []
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'clientDefault',
                type: 'select',
                multiselect: false,
                title: 'Cliente padrão',
                required: {

                    value: true,
                    required: 'Cliente padrão é obrigatório, selecionar clientes antes'
                },
                disabled: disabled,
                denpendentFieldFilter: {
                    relatedNameField: 'client',
                    filter: function (data) {
                        let iAmAnArray = Array.isArray(data.client);
                        if (!iAmAnArray && data.client != undefined) {
                            return stateData.data.Clients.filter(x => x.value == data.client);
                        } else if (iAmAnArray && data.client != undefined) {
                            if (!(data.client.length >= 0)) {
                                return [];//stateData.data.Clients;
                            }

                            var clientes = data.client as any;
                            if (clientes && clientes.length) {
                                if (typeof clientes[0] == "number") {
                                    return stateData.data.Clients.filter((c: any) => {
                                        return c.selected;
                                    });
                                } else {
                                    return stateData.data.Clients.filter(c => {
                                        return clientes.find(cl => cl == c.value.toString())
                                    });
                                }
                            }
                        } else {
                            return stateData.data.Clients.filter(x => x.value == stateData.data.DefaultClient.value);
                        }
                    }
                },
                options: this.state.data.Clients != null ? this.state.data.Clients : []
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'isAdministrator',
                type: 'checkbox',
                title: 'Administrador',
                required: {
                    value: false,
                    required: null// 'Administrador é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0) {
                        return true;
                    }
                    return false;
                }
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'isMultiOrganization',
                type: 'checkbox',
                title: 'Multiorganizacional',
                required: {
                    value: false,
                    required: null// 'Administrador é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0) {
                        return true;
                    }
                    return false;
                }
            }, {
                edit: false,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'active',
                type: 'checkbox',
                title: 'Ativo',
                required: {
                    value: false,
                    required: null// 'Administrador é obrigatório'
                },
                disabled: disabled
            }]
        };

        const stateData = { ...this.state };

        if (response && response.data) {
            try {
                const list = response.data;
                let TemplateFields = itemTemplate.Fields.filter((child) => {
                    return (list.includes(child.name));
                });

                itemTemplate.Fields = TemplateFields;
                stateData.data.template = itemTemplate;
                this.setState({ stateData });

            } catch (e) {

            }
        }
    }

    async getFormData(disabled: boolean) {

        var response = await this.retrieveFormTemplate(disabled);

        return {
            idiomas: this.idiomas,
            gtms: this.GMTS,
            clients: this.state.data.Clients != null ? this.state.data.Clients : [],
            formFilter: response && response.data || []
        }
    }


    public async template(disabled: boolean) {

        const stateData = { ...this.state };
        return stateData.data.template;
    }

    public async data() {

        const stateData = { ...this.state };
        return stateData.data;
    }



    getUsers() {
        const url = this.endpoint + `/api/PortalUser/GetUsersByClientGroup?refresh=${Math.random()}`
        return api({
            method: 'get',
            url,
        });
    }

    getActiveUsers() {
        const url = this.endpoint + `/api/PortalUser/GetActiveUsersByClientGroup?refresh=${Math.random()}`
        return api({
            method: 'get',
            url,
        });
    }

    getInactiveUsers() {
        const url = this.endpoint + `/api/PortalUser/GetInactiveUsersByClientGroup?refresh=${Math.random()}`
        return api({
            method: 'get',
            url,
        });
    }

    createUser(body: any) {
        const url = this.endpoint + `/api/PortalUser/CreateUser`
        var data = JSON.stringify(body);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    editUser(user: any) {
        const url = this.endpoint + `/api/PortalUser/UpdateUser`
        var data = JSON.stringify(user);
        return api({
            method: 'put',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    deleteUser(guidUser: string) {
        const url = this.endpoint + `/api/PortalUser/DeleteUserById?guidUser=${guidUser}`
        return api({
            method: 'delete',
            url
        })
    }

    getClientActive() {
        let status = true;
        const url = this.endpoint + `/api/PortalUser/GetClientGroupByStatus?status=${status}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        })
    }

    GetListClientGroupsPerfis() {
        const url = `${this.endpoint}/api/ClientGroupPerfil`;

        return api({
            method: 'get',
            url,
        })
    }

    GetPerfisByUser(userId: string) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetPerfilByUserId/${userId}`;
        return api({
            method: 'get',
            url,
        })
    }

    GetDefaultUserById(userId: any) {
        const url = this.endpoint + `/api/PortalUser/GetClientByUserId?userId=${userId}&refresh=${Math.random()}`
        return api({
            method: 'get',
            url
        })
    }

    GetAllByUserId(userId: any) {
        const url = this.endpoint + `/api/PortalUser/GetAllByUserId?userId=${userId}&refresh=${Math.random()}`
        return api({
            method: 'get',
            url
        });
    }

    GetAllClientsByUserId(userId: any) {
        const url = this.endpoint + `/api/PortalUser/GetAllClientsByUserId?userId=${userId}&refresh=${Math.random()}`
        return api({
            method: 'get',
            url
        });
    }
    public idiomas = [
        { value: "pt", label: `${'Português'}` },
        { value: "es", label: "Espanhol" },
        { value: "en", label: "Inglês" }];

    public GMTS =
        [
            { value: "UTC-11", label: "(UTC-11:00) Coordinated Universal Time-11" },
            { value: "Aleutian Standard Time", label: "(UTC-10:00) Aleutian Islands" },
            { value: "Hawaiian Standard Time", label: "(UTC-10:00) Hawaii" },
            { value: "Marquesas Standard Time", label: "(UTC-09:30) Marquesas Islands" },
            { value: "Alaskan Standard Time", label: "(UTC-09:00) Alaska" },
            { value: "UTC-09", label: "(UTC-09:00) Coordinated Universal Time-09" },
            { value: "Pacific Standard Time (Mexico)", label: "(UTC-08:00) Baja California" },
            { value: "UTC-08", label: "(UTC-08:00) Coordinated Universal Time-08" },
            { value: "Pacific Standard Time", label: "(UTC-08:00) Pacific Time (US &amp; Canada)" },
            { value: "US Mountain Standard Time", label: "(UTC-07:00) Arizona" },
            { value: "Mountain Standard Time (Mexico)", label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
            { value: "Mountain Standard Time", label: "(UTC-07:00) Mountain Time (US &amp; Canada)" },
            { value: "Yukon Standard Time", label: "(UTC-07:00) Yukon" },
            { value: "Central America Standard Time", label: "(UTC-06:00) Central America" },
            { value: "Central Standard Time", label: "(UTC-06:00) Central Time (US &amp; Canada)" },
            { value: "Easter Island Standard Time", label: "(UTC-06:00) Easter Island" },
            { value: "Central Standard Time (Mexico)", label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey" },
            { value: "Canada Central Standard Time", label: "(UTC-06:00) Saskatchewan" },
            { value: "SA Pacific Standard Time", label: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco" },
            { value: "Eastern Standard Time (Mexico)", label: "(UTC-05:00) Chetumal" },
            { value: "Eastern Standard Time", label: "(UTC-05:00) Eastern Time (US &amp; Canada)" },
            { value: "Haiti Standard Time", label: "(UTC-05:00) Haiti" },
            { value: "Cuba Standard Time", label: "(UTC-05:00) Havana" },
            { value: "US Eastern Standard Time", label: "(UTC-05:00) Indiana (East)" },
            { value: "Turks And Caicos Standard Time", label: "(UTC-05:00) Turks and Caicos" },
            { value: "Paraguay Standard Time", label: "(UTC-04:00) Asuncion" },
            { value: "Atlantic Standard Time", label: "(UTC-04:00) Atlantic Time (Canada)" },
            { value: "Venezuela Standard Time", label: "(UTC-04:00) Caracas" },
            { value: "Central Brazilian Standard Time", label: "(UTC-04:00) Cuiaba" },
            { value: "SA Western Standard Time", label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
            { value: "Pacific SA Standard Time", label: "(UTC-04:00) Santiago" },
            { value: "Newfoundland Standard Time", label: "(UTC-03:30) Newfoundland" },
            { value: "Tocantins Standard Time", label: "(UTC-03:00) Araguaina" },
            { value: "E. South America Standard Time", label: "(UTC-03:00) Brasilia" },
            { value: "SA Eastern Standard Time", label: "(UTC-03:00) Cayenne, Fortaleza" },
            { value: "Argentina Standard Time", label: "(UTC-03:00) City of Buenos Aires" },
            { value: "Greenland Standard Time", label: "(UTC-03:00) Greenland" },
            { value: "Montevideo Standard Time", label: "(UTC-03:00) Montevideo" },
            { value: "Magallanes Standard Time", label: "(UTC-03:00) Punta Arenas" },
            { value: "Saint Pierre Standard Time", label: "(UTC-03:00) Saint Pierre and Miquelon" },
            { value: "Bahia Standard Time", label: "(UTC-03:00) Salvador" },
            { value: "UTC-02", label: "(UTC-02:00) Coordinated Universal Time-02" },
            { value: "Mid-Atlantic Standard Time", label: "(UTC-02:00) Mid-Atlantic - Old" },
            { value: "Azores Standard Time", label: "(UTC-01:00) Azores" },
            { value: "Cape Verde Standard Time", label: "(UTC-01:00) Cabo Verde Is." },
            { value: "UTC", label: "(UTC) Coordinated Universal Time" },
            { value: "GMT Standard Time", label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London" },
            { value: "Greenwich Standard Time", label: "(UTC+00:00) Monrovia, Reykjavik" },
            { value: "Sao Tome Standard Time", label: "(UTC+00:00) Sao Tome" },
            { value: "Morocco Standard Time", label: "(UTC+01:00) Casablanca" },
            { value: "W. Europe Standard Time", label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
            { value: "Central Europe Standard Time", label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
            { value: "Romance Standard Time", label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
            { value: "Central European Standard Time", label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
            { value: "W. Central Africa Standard Time", label: "(UTC+01:00) West Central Africa" },
            { value: "Jordan Standard Time", label: "(UTC+02:00) Amman" },
            { value: "GTB Standard Time", label: "(UTC+02:00) Athens, Bucharest" },
            { value: "Middle East Standard Time", label: "(UTC+02:00) Beirut" },
            { value: "Egypt Standard Time", label: "(UTC+02:00) Cairo" },
            { value: "E. Europe Standard Time", label: "(UTC+02:00) Chisinau" },
            { value: "Syria Standard Time", label: "(UTC+02:00) Damascus" },
            { value: "West Bank Standard Time", label: "(UTC+02:00) Gaza, Hebron" },
            { value: "South Africa Standard Time", label: "(UTC+02:00) Harare, Pretoria" },
            { value: "FLE Standard Time", label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
            { value: "Israel Standard Time", label: "(UTC+02:00) Jerusalem" },
            { value: "Kaliningrad Standard Time", label: "(UTC+02:00) Kaliningrad" },
            { value: "Sudan Standard Time", label: "(UTC+02:00) Khartoum" },
            { value: "Libya Standard Time", label: "(UTC+02:00) Tripoli" },
            { value: "Namibia Standard Time", label: "(UTC+02:00) Windhoek" },
            { value: "Arabic Standard Time", label: "(UTC+03:00) Baghdad" },
            { value: "Turkey Standard Time", label: "(UTC+03:00) Istanbul" },
            { value: "Arab Standard Time", label: "(UTC+03:00) Kuwait, Riyadh" },
            { value: "Belarus Standard Time", label: "(UTC+03:00) Minsk" },
            { value: "Russian Standard Time", label: "(UTC+03:00) Moscow, St. Petersburg" },
            { value: "E. Africa Standard Time", label: "(UTC+03:00) Nairobi" },
            { value: "Iran Standard Time", label: "(UTC+03:30) Tehran" },
            { value: "Arabian Standard Time", label: "(UTC+04:00) Abu Dhabi, Muscat" },
            { value: "Astrakhan Standard Time", label: "(UTC+04:00) Astrakhan, Ulyanovsk" },
            { value: "Azerbaijan Standard Time", label: "(UTC+04:00) Baku" },
            { value: "Russia Time Zone 3", label: "(UTC+04:00) Izhevsk, Samara" },
            { value: "Mauritius Standard Time", label: "(UTC+04:00) Port Louis" },
            { value: "Saratov Standard Time", label: "(UTC+04:00) Saratov" },
            { value: "Georgian Standard Time", label: "(UTC+04:00) Tbilisi" },
            { value: "Volgograd Standard Time", label: "(UTC+04:00) Volgograd" },
            { value: "Caucasus Standard Time", label: "(UTC+04:00) Yerevan" },
            { value: "Afghanistan Standard Time", label: "(UTC+04:30) Kabul" },
            { value: "West Asia Standard Time", label: "(UTC+05:00) Ashgabat, Tashkent" },
            { value: "Ekaterinburg Standard Time", label: "(UTC+05:00) Ekaterinburg" },
            { value: "Pakistan Standard Time", label: "(UTC+05:00) Islamabad, Karachi" },
            { value: "Qyzylorda Standard Time", label: "(UTC+05:00) Qyzylorda" },
            { value: "India Standard Time", label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
            { value: "Sri Lanka Standard Time", label: "(UTC+05:30) Sri Jayawardenepura" },
            { value: "Nepal Standard Time", label: "(UTC+05:45) Kathmandu" },
            { value: "Central Asia Standard Time", label: "(UTC+06:00) Astana" },
            { value: "Bangladesh Standard Time", label: "(UTC+06:00) Dhaka" },
            { value: "Omsk Standard Time", label: "(UTC+06:00) Omsk" },
            { value: "Myanmar Standard Time", label: "(UTC+06:30) Yangon (Rangoon)" },
            { value: "SE Asia Standard Time", label: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
            { value: "Altai Standard Time", label: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
            { value: "W. Mongolia Standard Time", label: "(UTC+07:00) Hovd" },
            { value: "North Asia Standard Time", label: "(UTC+07:00) Krasnoyarsk" },
            { value: "N. Central Asia Standard Time", label: "(UTC+07:00) Novosibirsk" },
            { value: "Tomsk Standard Time", label: "(UTC+07:00) Tomsk" },
            { value: "China Standard Time", label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
            { value: "North Asia East Standard Time", label: "(UTC+08:00) Irkutsk" },
            { value: "Singapore Standard Time", label: "(UTC+08:00) Kuala Lumpur, Singapore" },
            { value: "W. Australia Standard Time", label: "(UTC+08:00) Perth" },
            { value: "Taipei Standard Time", label: "(UTC+08:00) Taipei" },
            { value: "Ulaanbaatar Standard Time", label: "(UTC+08:00) Ulaanbaatar" },
            { value: "Aus Central W. Standard Time", label: "(UTC+08:45) Eucla" },
            { value: "Transbaikal Standard Time", label: "(UTC+09:00) Chita" },
            { value: "Tokyo Standard Time", label: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
            { value: "North Korea Standard Time", label: "(UTC+09:00) Pyongyang" },
            { value: "Korea Standard Time", label: "(UTC+09:00) Seoul" },
            { value: "Yakutsk Standard Time", label: "(UTC+09:00) Yakutsk" },
            { value: "Cen. Australia Standard Time", label: "(UTC+09:30) Adelaide" },
            { value: "AUS Central Standard Time", label: "(UTC+09:30) Darwin" },
            { value: "E. Australia Standard Time", label: "(UTC+10:00) Brisbane" },
            { value: "AUS Eastern Standard Time", label: "(UTC+10:00) Canberra, Melbourne, Sydney" },
            { value: "West Pacific Standard Time", label: "(UTC+10:00) Guam, Port Moresby" },
            { value: "Tasmania Standard Time", label: "(UTC+10:00) Hobart" },
            { value: "Vladivostok Standard Time", label: "(UTC+10:00) Vladivostok" },
            { value: "Lord Howe Standard Time", label: "(UTC+10:30) Lord Howe Island" },
            { value: "Bougainville Standard Time", label: "(UTC+11:00) Bougainville Island" },
            { value: "Russia Time Zone 10", label: "(UTC+11:00) Chokurdakh" },
            { value: "Magadan Standard Time", label: "(UTC+11:00) Magadan" },
            { value: "Norfolk Standard Time", label: "(UTC+11:00) Norfolk Island" },
            { value: "Sakhalin Standard Time", label: "(UTC+11:00) Sakhalin" },
            { value: "Central Pacific Standard Time", label: "(UTC+11:00) Solomon Is., New Caledonia" },
            { value: "Russia Time Zone 11", label: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky" },
            { value: "New Zealand Standard Time", label: "(UTC+12:00) Auckland, Wellington" },
            { value: "UTC+12", label: "(UTC+12:00) Coordinated Universal Time+12" },
            { value: "Fiji Standard Time", label: "(UTC+12:00) Fiji" },
            { value: "Kamchatka Standard Time", label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old" },
            { value: "Chatham Islands Standard Time", label: "(UTC+12:45) Chatham Islands" },
            { value: "UTC+13", label: "(UTC+13:00) Coordinated Universal Time+13" },
            { value: "Tonga Standard Time", label: "(UTC+13:00) Nuku'alofa" },
            { value: "Samoa Standard Time", label: "(UTC+13:00) Samoa" },
            { value: "Line Islands Standard Time", label: "(UTC+14:00) Kiritimati Island" }
        ]

    SaveHelperStatus(status: number) {
        const url = this.endpoint + `/api/PortalUser/SaveHelperStatus?status=${status}`;
        return api({
            method: 'POST',
            url
        });
    }

    async GetHelperStatusByUserId() {

        var LiveCloud_AuthMS = sessionStorage.getItem("LiveCloud_AuthMS");
        var LiveCloud_AuthLC = sessionStorage.getItem("LiveCloud_AuthLC");
        if (!LiveCloud_AuthMS) {
            return 1;
        }

        if (!LiveCloud_AuthLC) {
            return 1;
        }

        const url = this.endpoint + `/api/PortalUser/GetHelperStatusByUserId`;
        return (await api({
            method: 'GET',
            url
        })).data;
    }

}

export interface ButtonEventGrid {
    id?: string;
    title: string;
    tooltip?: string;
    icon?: string;
    func: any;
    disabled: boolean;
    skipvalidation: boolean;
}
