import axios, { AxiosAdapter } from 'axios';
import { useSelector } from 'react-redux';
import AppRoutes from '../routes';
import { RootState } from '../store/index';
import history from '../history'
import React from 'react';
import { UserState } from '../store/reducers/userReducer';

import * as  webix from '@xbs/webix-pro';
import "@xbs/webix-pro/webix.css";
import rateLimit from 'axios-rate-limit';

window.webix = webix;

let api = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Access-Control-Allow-Origin':'*'
    }
});//rateLimit(axios.create(), { maxRequests: 2, perMilliseconds: 300, maxRPS: 2 })
//let api = rateLimit(axios.create(), { maxRequests: 3, perMilliseconds: 500, maxRPS: 3 })

async function ChangeLocation(relocationpath: string) {

    async function setNewLoation(relocationpath: string) {
        try {
            history && history.push(relocationpath);
        } catch (e) {

        }
    }
    setNewLoation(relocationpath);
}
export class APIClient extends React.Component {

    endpoint = `${process.env.REACT_APP_BFFENDPOINT}`;
    wait = true;

    constructor(props: any) {
        super(props);
        this.endpoint = `${process.env.REACT_APP_BFFENDPOINT}`;

        var LiveCloud_AuthMS = sessionStorage.getItem("LiveCloud_AuthMS");
        var LiveCloud_AuthLC = sessionStorage.getItem("LiveCloud_AuthLC");

        api.defaults.timeout = 600000;
        api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        if (LiveCloud_AuthMS) {
            api.defaults.headers.common['Authorization'] = `Bearer ${LiveCloud_AuthMS}`;
        }

        if (LiveCloud_AuthLC) {
            api.defaults.headers.common['AuthorizationLC'] = `Bearer ${LiveCloud_AuthLC}`;
        }
        api.defaults.headers.common['PathRoute'] = window.location.pathname;
        api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        //api.defaults.maxRedirects = 0;
        api.interceptors.response.use((response) => response, (error) => {
            if (error)
                if (error.response)
                    if (error.response.status && error.response.status == 401) {
                        return Promise.reject(error);
                    }
            if (error)
                if (error.response)
                    if (error.response.status && error.response.status == 500) {
                        throw error;
                    }
            return Promise.reject(error);
        });
    }

}

export default api;