import React from 'react';

import { NotificationCenterService } from '../../services/notificationCenter/notificationService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/index';
import { MessagesState } from '../../store/reducers/messagesReducer';


const TabMessages: React.FC = (props) => {

    const msgs = useSelector<RootState, MessagesState>(state => state.msgs);

    const notification = new NotificationCenterService(props);

    const dispatch = useDispatch();

    const loadMsg = (type: 'default' | 'danger' | 'success' | 'warning' | 'info') => {

        let msg = {
            subject: "Teste Notificação",
            content: "Apenas um teste de notificação. Texto longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo longo.",
            context: type,
        };

        notification.PostInterfaceNotification(dispatch, msg);
    }

    const clearMsgs = () => {
        dispatch({ type: 'MSG_UPDATE', payload: [] });
        dispatch({ type: 'MSG_SIZE', payload: 10 });
    }


    return (<>
        <div className='row'>
            <button className="lc-button" onClick={() => loadMsg('default')}>Msg Default</button>
            <button className="lc-button" onClick={() => loadMsg('danger')}>Msg Danger</button>
            <button className="lc-button" onClick={() => loadMsg('success')}>Msg Success</button>
            <button className="lc-button" onClick={() => loadMsg('warning')}>Msg Warning</button>
            <button className="lc-button" onClick={() => loadMsg('info')}>Msg Info</button>
        </div>

        <hr />

        <div className="row">
            <button className='lc-button bg-danger' onClick={() => clearMsgs()}>Limpar</button>
        </div>
    </>
    );
}

export default TabMessages;