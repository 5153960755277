import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import './index.css';

import { NotificationCenterService } from '../../services/notificationCenter/notificationService';

//Redux
import { useDispatch } from 'react-redux';

import Layout from '../../components/Layout/Layout';

import { PPModal } from 'processor-plataform-ui';

const Marketing: React.FC = (props) => {

    const history = useHistory();
    const notification = new NotificationCenterService(props);
    const dispatch = useDispatch();

    const { gtm } = useParams<{ gtm: string }>();

    const [modalVisible, setModalVisible] = useState(false);

    const [data, setData] = useState<any[]>();

    useEffect(() => {
        try {
            let _data = require(`./Sotutions/${gtm}.json`)
            setData(_data);
        } catch (error) {
            history.push("/");
            notification.PostInterfaceNotification(dispatch, {
                subject: "Controle de acesso",
                content: "Você não tem acesso a esse produto",
                context: "warning",
            });
        }
    }, [])

    const getImage = (url: string) => {
        const image = require('./imgs/' + url);
        return image.default;
    }

    const [showModalMarketing, setShowModalMarketing] = useState(false);

    const closeModal = () => {
        setShowModalMarketing(false)
        history.push("/");
    }

    useEffect(() => {
        setTimeout(() => {
            //setShowModalMarketing(true);
        }, 3000)
    }, [])

    return <Layout pageTitle='Marketing' >
        <div className="marketing">
            {
                data && data.map((d, i) => {
                    switch (d.type) {
                        case "img":
                            return <div key={i} className='preview'>
                                <div className='image'
                                    style={{
                                        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 1)), url('${getImage(d.url)}')`
                                    }}
                                />
                            </div>
                        case "h1":
                            return <h1 key={i}>{d.content}</h1>
                        case "h2":
                            return <h2 key={i}>{d.content}</h2>
                        case "h3":
                            return <h3 key={i}>{d.content}</h3>
                        case "h4":
                            return <h4 key={i}>{d.content}</h4>
                        case "p":
                            return <p key={i}>{d.content}</p>
                        case "list":
                            return <ul key={i}>{
                                d.content.map((u, j) => <li key={j}>{u}</li>)
                            }
                            </ul>
                        case "button":
                            return <div key={i} className='block'>
                                <span onClick={() => history.push(d.url)}>{d.content}</span>
                            </div>
                    }
                })
            }

            <PPModal onClose={setModalVisible} visible={modalVisible} size='large' title={data && data.find(d => d.type == 'name').content}>
                {
                    data && data.filter(d => d.type != 'img')
                        .map((d, i) => {
                            switch (d.type) {
                                case "h1":
                                    return <h1 key={i}>{d.content}</h1>
                                case "h2":
                                    return <h2 key={i}>{d.content}</h2>
                                case "h3":
                                    return <h3 key={i}>{d.content}</h3>
                                case "h4":
                                    return <h4 key={i}>{d.content}</h4>
                                case "p":
                                    return <p key={i}>{d.content}</p>
                                case "list":
                                    return <ul key={i}>{
                                        d.content.map((u, j) => <li key={j}>{u}</li>)
                                    }
                                    </ul>
                                case "button":
                                    return <div key={i} className='block'>
                                        <span onClick={() => history.push(d.url)}>{d.content}</span>
                                    </div>
                            }
                        })
                }
            </PPModal>

            <div className={`modal-marketing${showModalMarketing ? ' visible' : ''}`}>
                <div className="shadow">
                </div>
                <div className="content">
                    <div className="header">
                        <button className='lc-button' type='button' onClick={closeModal}>
                            <i className='ppi ppi-chevron-left' /> Voltar
                        </button>
                    </div>
                    <div className="body">
                        <h1>LiveCloud Disaster Recovery</h1>
                        <p>Você sabia que a solução de Recuperação de Desastres LiveCloud oferece uma maneira eficiente e econômica de garantir a continuidade das operações de sua empresa, mesmo em face de interrupções imprevistas?</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
}

export default Marketing;