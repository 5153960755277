export interface PatchManagerState {
    OsSystemName: string;
    SeverityPatch: string
}
const initialState : PatchManagerState = {
    OsSystemName: '',
    SeverityPatch: ''
}
interface SetPatchManagerAction {
    type: string;
    payload: Partial<PatchManagerState>;
}
export const patchManagerReducer = (
    state:PatchManagerState = initialState,
    action: SetPatchManagerAction
) => {
    switch (action.type) {
        case 'OS_SYSTEM_FILTER': 
            return {
                ...state,
                ...action.payload, // Mescla o payload no estado atual, mantendo o estado imutável
            };
        case "RESET_SYSTEM_PATCH" :
            return {
                ...state,
                OsSystemName: initialState.OsSystemName,
            }
        case 'SEVERITY_PATCH_FILTER': 
            return {
                ...state,
                ...action.payload, 
            };
        case "RESET_PATCH_PATCH" :
            return {
                ...state,
                SeverityPatch: initialState.SeverityPatch,
            }
        case "RESET_ALL" :
            return {
                ...state,
                ...initialState,
            }
        default:
            return state;
    }
};