import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';

import styles from './index.module.css';
import { License, TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import EASubscriptionService from '../../../services/EASubscriptionService/EASubscriptionService';
import LcNoData from '../../../components/Generic/LcNoData';
const formatCurrency = (value: number) =>
    new Intl.NumberFormat(
        navigator.language, {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
const formatNumber = (value: number) => {
    //Carrega a formatação decimal de acordo com a lingua do browser
    return new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

const GlobalLicenseManager: React.FC = (props) => {

    let service = new TechEAEASService(props);
    let service2 = new EASubscriptionService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [ql, setQL] = useState<any[]>([]);
    const [arrayOfLicensesPositionGlobal, setArrayOfLicensesPositionGlobal] = useState<any[]>([]);
    const [arrayOfLicensesCountries, setArrayOfLicensesCountries] = useState<any[]>([]);
    const [totalGlobal, setTotalGlobal] = useState<number>(0);
    const [totalHiredGlobal, setTotalHiredGlobal] = useState<number>(0);
    const [totalGlobalQtd, setTotalGlobalQtd] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [listExtras, setListExtras] = useState<any[]>([]);

    const processDashboardData = (rawData: any[]) => {
        var totalGlobal = 0.00;
        var totalHiredGlobal = 0.00;
        var totalGlobalQtd = 0.00;
        var arrayOfLicensesPositionGlobal: { groupName: string, used: number, usedCost: number, hired: number, hiredCost: number, GAP: boolean }[] = [];

        var arrayOfLicensesCountries = rawData.map((item, index) => {

            totalGlobal += item.usedCost;
            totalHiredGlobal += item.hiredCost;
            totalGlobalQtd += item.used;
            var licenseGroups = item.licenseGroup.map((record, index) => {
                var foundRecord = false;
                arrayOfLicensesPositionGlobal.map((registro, index) => {
                    if (registro?.groupName && registro?.groupName == record.groupName) {
                        foundRecord = true;
                        registro.hired += record.hired;
                        registro.hiredCost += record.hiredCost;
                        registro.used += record.used;
                        registro.usedCost += record.usedCost;
                        registro.GAP = (record.grupoAvaliableforExecutives) ? record.hired < record.used : false;
                    }
                });

                record.GAP = (record.grupoAvaliableforExecutives) ? record.hired < record.used : false;

                if (!foundRecord) {
                    if (record.grupoAvaliableforExecutives) {

                        arrayOfLicensesPositionGlobal.push({
                            groupName: record.groupName,
                            usedCost: record.usedCost,
                            used: record.used,
                            hired: record.hired,
                            hiredCost: record.hiredCost,
                            GAP: record.GAP
                        });
                    }
                }
                return record;
            });
            var returnItem = {
                country: item.country,
                hired: item.hired,
                hiredCost: item.hiredCost,
                used: item.used,
                usedCost: item.usedCost,
                licenseGroups: licenseGroups
            };
            return returnItem;
        });
        setArrayOfLicensesCountries(arrayOfLicensesCountries);
        setTotalGlobal(totalGlobal);
        setTotalGlobalQtd(totalGlobalQtd);
        setTotalHiredGlobal(totalHiredGlobal);
        setArrayOfLicensesPositionGlobal(arrayOfLicensesPositionGlobal);
    }

    const getData = async () => {
        setIsLoading(true);
        try {
            var UsersSaasLicenseByCountryCostCenterByClientGroupId = user && await service.GetUsersSaasLicenseByCountryCostCenterByClientGroupId();
            processDashboardData(JSON.parse(UsersSaasLicenseByCountryCostCenterByClientGroupId.data));
            try {
                var responseQueryRecordAdicionais = user && await service.QueryRecordAdicionais();
                if (responseQueryRecordAdicionais && responseQueryRecordAdicionais.data && responseQueryRecordAdicionais.data.length > 0) {
                    var recordsQueryRecordAdicionaisL = responseQueryRecordAdicionais.data;
                    console.log(recordsQueryRecordAdicionaisL);
                    setListExtras(recordsQueryRecordAdicionaisL)
                    console.log(recordsQueryRecordAdicionaisL);
                }
            } catch (e) {
                console.debug(e)
            }
        } catch (error) {

            console.debug(error)
        }
        setIsLoading(false);
    }

    const getFlag = (countrycode: string) => {
        const iso3166CountryCodes = [
            "AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ",
            "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BQ", "BA", "BW", "BV", "BR",
            "IO", "BN", "BG", "BF", "BI", "CV", "KH", "CM", "CA", "KY", "CF", "TD", "CL", "CN", "CX", "CC",
            "CO", "KM", "CG", "CD", "CK", "CR", "HR", "CU", "CW", "CY", "CZ", "DK", "DJ", "DM", "DO", "EC",
            "EG", "SV", "GQ", "ER", "EE", "SZ", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA",
            "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT",
            "HM", "VA", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP",
            "JE", "JO", "KZ", "KE", "KI", "KP", "KR", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI",
            "LT", "LU", "MO", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM",
            "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE",
            "NG", "NU", "NF", "MK", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN",
            "PL", "PT", "PR", "QA", "RE", "RO", "RU", "RW", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS",
            "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "SS",
            "ES", "LK", "SD", "SR", "SJ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO",
            "TT", "TN", "TR", "TM", "TC", "TV", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VE",
            "VN", "VG", "VI", "WF", "EH", "YE", "ZM", "ZW"
        ];

        const code = countrycode.toLowerCase() == 'uk' ? 'gb' : countrycode.toLowerCase();
        const clientGroup = user.ClientGroupSelected!;

        if (iso3166CountryCodes.includes(countrycode))
            return `https://flagcdn.com/${code}.svg`;

        else if (clientGroup) {
            const image = require(`../../../assets/images/globallicensemanager/${clientGroup}/${code}.png`);
            return image.hasOwnProperty('default') ? image.default : '#';
        }

        return '#';
    };

    useEffect(() => {
        getData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    //#region Elementos do Dashboard
    const dashboardCards = [
        {
            type: "Custom",
            position: {
                RowSpan: 1,
                ColSpan: 12, row: 1, col: 1
            },

            title: () => <span >Licenças</span>,
            customContentRender: () =>
                <div className={'scrollable-h'} style={{ padding: '5px', minHeight: '90px', width: '100hw', height: '100%', display: 'flex', flexDirection: 'row' }} >
                    <div style={{ height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row' }}  >
                        {arrayOfLicensesPositionGlobal && arrayOfLicensesPositionGlobal.sort((a, b) => { return b.hired - a.hired > 0 ? 1 : -1; }).map((item) =>
                            <div style={{ width: '150px', height: 'fit-content', display: 'flex', flexDirection: 'column' }} >
                                <div style={{ color: ((item.hired - item.used > 0) ? '#0068b2' : '#EE6666') }} data-title={'Diferença:' + ((item.hired - item.used > 0) ? '+' : '') + formatNumber(item.hired - item.used)} className={styles.cardSubTitle} > {item.groupName} </div>
                                <div style={{ fontWeight: '500', fontFamily: 'Ubuntu', width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div data-title={formatCurrency(item.hiredCost)} style={{ padding: '5px', textAlign: 'right', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                                        className={styles.licenseSummaryLine}>
                                        <div style={{ fontWeight: '800', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                            {item.hired}</div>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Adquirido</div>
                                    </div>
                                    <div data-title={formatCurrency(item.usedCost)} style={{ padding: '5px', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                                        className={styles.licenseSummaryLine}>
                                        <div style={{ fontWeight: '800', height: 'auto', display: 'flex', flexDirection: 'column' }} >{item.used}</div>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Utilizado</div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
        },
        {
            type: "Custom",
            position: {
                RowSpan: 3,
                ColSpan: 12, row: 2, col: 1
            },

            title: () => <div >Acompanhamento Global</div>,
            customContentRender: () =>
                <div className={styles.globalFollowUpCard}>
                    <div className={styles.summaryContainer}>
                        <span className={styles.globalSummaryLine}>Realizado Global {totalGlobal > 0 ? formatCurrency(totalGlobal) : totalGlobalQtd}</span>
                        <span className={styles.globalSummaryLine}>{totalGlobal > 0 ? '*Com impostos' : ''}</span>
                    </div>
                    <div className={styles.followUpFlagsContainer}>
                        {
                            arrayOfLicensesCountries && arrayOfLicensesCountries.length > 0 ? arrayOfLicensesCountries.map((c, ci) =>
                                <div key={ci} className={styles.flagItem}>
                                    <img title={c.country.toUpperCase()} src={getFlag(c.country)} width="96" height="72" alt={c.country.toUpperCase()} />
                                    <div><strong>Realizado </strong></div>
                                    <div className={styles.summaryLineByCountry}> {c?.usedCost > 0 ?
                                        formatCurrency(
                                            (c?.hiredCost ?? 0) +
                                            (listExtras && listExtras.length > 0 ? listExtras.filter(v => v.country == c.country)
                                                .reduce((acc, item) => { return acc + item.valueUnit; }, 0) : 0)
                                        )
                                        : c?.used}
                                    </div>
                                    <div className={styles.summaryLineByCountry}> {totalGlobal > 0 ? '*Com impostos' : ''} </div>
                                    <div className={styles.summaryLineByCountry}> </div>
                                    <div className={`${styles.summaryLineByCountry} ${styles.GAP}`}>
                                        {c.licenseGroups && c.licenseGroups.length > 0
                                            && c.licenseGroups.filter(b => b.GAP == true).length > 0 ?
                                            <div className={`${styles.summaryLineByCountry} ${styles.GAP}`}>
                                                Principais GAPS: <br />
                                                <div className={styles.summaryLineByCountry}>
                                                    {c.licenseGroups.filter(b => b.GAP == true).slice(0, 4).map((item, index) => {
                                                        var ultimo = ((index + 1) - c.licenseGroups.filter(b => b.GAP == true).slice(0, 4).length) < 0;
                                                        return item.groupName.replace(' ', '') + (!ultimo ? '' : ', ');
                                                    })}</div> </div> : 'Sem GAPs de licenciamento'}</div>
                                </div>
                            ) : <LcNoData></LcNoData>
                        }
                    </div>
                </div>

        }
    ]

    return (
        <Layout pageTitle='Global/Licenças' >
            <LcLoading loading={isLoading} loadingType='Helix'>
                <LCDashboard cards={dashboardCards} rightSidePanel={{ title: "", content: () => { }, pinned: false, show: false }} />
            </LcLoading>
        </Layout>
    )

}

export default GlobalLicenseManager;