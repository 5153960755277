import { PPMultiSelect, PPInput, PPTextArea } from 'processor-plataform-ui';
import React, { useState } from 'react';
import LcToggle from '../../components/Form/LcToggle';

const TabForm: React.FC = () => {

    const [error, setError] = useState(false);
    const [required, setRequired] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const options = [
        { label: 'Agatha', value: '1' },
        { label: 'Alice', value: '2' },
        { label: 'Alícia', value: '3' },
        { label: 'Allana', value: '4' },
        { label: 'Amanda', value: '5' },
        { label: 'Ana', value: '6' },
        { label: 'Ana Beatriz', value: '7' },
        { label: 'Ana Cecília', value: '8' },
        { label: 'Ana Clara', value: '9' },
        { label: 'Ana Júlia', value: '10' },
        { label: 'Ana Laura', value: '11' },
        { label: 'Ana Lívia', value: '12' },
        { label: 'Ana Liz', value: '13' },
        { label: 'Ana Luiza', value: '14' },
        { label: 'Ana Sophia', value: '15' },
        { label: 'Ana Vitória', value: '16' },
        { label: 'Analu', value: '17' },
        { label: 'André', value: '18' },
        { label: 'Anthony', value: '19' },
        { label: 'Anthony Gabriel', value: '20' },
        { label: 'Antonella', value: '21' },
        { label: 'Antônia', value: '22' },
        { label: 'Antônio', value: '23' },
        { label: 'Arthur', value: '24' },
        { label: 'Arthur Gabriel', value: '25' },
        { label: 'Arthur Henrique', value: '26' },
        { label: 'Arthur Miguel', value: '27' },
        { label: 'Augusto', value: '28' },
        { label: 'Aurora', value: '29', selected: true },
        { label: 'Ayla', value: '30' },
        { label: 'Beatriz', value: '31' },
        { label: 'Benício', value: '32' },
        { label: 'Benjamin', value: '33' },
        { label: 'Bento', value: '34', disabled: true },
        { label: 'Bernardo', value: '35' },
        { label: 'Bianca', value: '36' },
        { label: 'Breno', value: '37' },
        { label: 'Bruna', value: '38' },
        { label: 'Bruno', value: '39' },
        { label: 'Bryan', value: '40' },
        { label: 'Caio', value: '41' },
        { label: 'Caleb', value: '42' },
        { label: 'Carlos Eduardo', value: '43' },
        { label: 'Carolina', value: '44' },
        { label: 'Catarina', value: '45' },
        { label: 'Cauã', value: '46' },
        { label: 'Cecília', value: '47' },
        { label: 'Clara', value: '48' },
        { label: 'Clarice', value: '49' },
        { label: 'Daniel', value: '50' },
        { label: 'Danilo', value: '51' },
        { label: 'Davi', value: '52' },
        { label: 'Davi Lucas', value: '53' },
        { label: 'Davi Lucca', value: '54' },
        { label: 'Davi Luiz', value: '55' },
        { label: 'Davi Miguel', value: '56' },
        { label: 'Eduarda', value: '57' },
        { label: 'Eduardo', value: '58' },
        { label: 'Elisa', value: '59' },
        { label: 'Eloá', value: '60' },
        { label: 'Emanuel', value: '61' },
        { label: 'Emanuelly', value: '62' },
        { label: 'Emilly', value: '63' },
        { label: 'Enrico', value: '64' },
        { label: 'Enzo', value: '65' },
        { label: 'Enzo Gabriel', value: '66' },
        { label: 'Enzo Miguel', value: '67' },
        { label: 'Erick', value: '68' },
        { label: 'Esther', value: '69' },
        { label: 'Felipe', value: '70' },
        { label: 'Fernanda', value: '71' },
        { label: 'Fernando', value: '72' },
        { label: 'Francisco', value: '73' },
        { label: 'Gabriel', value: '74' },
        { label: 'Gabriela', value: '75' },
        { label: 'Gabrielly', value: '76' },
        { label: 'Gael', value: '77' },
        { label: 'Giovanna', value: '78' },
        { label: 'Guilherme', value: '79' },
        { label: 'Gustavo', value: '80' },
        { label: 'Heitor', value: '81' },
        { label: 'Helena', value: '82' },
        { label: 'Heloísa', value: '83' },
        { label: 'Heloise', value: '84' },
        { label: 'Henrique', value: '85' },
        { label: 'Henry', value: '86' },
        { label: 'Ian', value: '87' },
        { label: 'Isaac', value: '88' },
        { label: 'Isabel', value: '89' },
        { label: 'Isabella', value: '90' },
        { label: 'Isabelly', value: '91' },
        { label: 'Isadora', value: '92' },
        { label: 'Isis', value: '93' },
        { label: 'Joana', value: '94' },
        { label: 'João', value: '95' },
        { label: 'João Gabriel', value: '96' },
        { label: 'João Guilherme', value: '97' },
        { label: 'João Lucas', value: '98' },
        { label: 'João Miguel', value: '99' },
        { label: 'João Pedro', value: '100' },
        { label: 'João Victor', value: '101' },
        { label: 'Joaquim', value: '102' },
        { label: 'José', value: '103' },
        { label: 'Júlia', value: '104' },
        { label: 'Kaique', value: '105' },
        { label: 'Kauê', value: '106' },
        { label: 'Laís', value: '107' },
        { label: 'Lara', value: '108' },
        { label: 'Larissa', value: '109' },
        { label: 'Laura', value: '110' },
        { label: 'Lavínia', value: '111' },
        { label: 'Leonardo', value: '112' },
        { label: 'Letícia', value: '113' },
        { label: 'Levi', value: '114' },
        { label: 'Lívia', value: '115' },
        { label: 'Liz', value: '116' },
        { label: 'Lorena', value: '117' },
        { label: 'Lorenzo', value: '118' },
        { label: 'Louise', value: '119' },
        { label: 'Luan', value: '120' },
        { label: 'Luana', value: '121' },
        { label: 'Lucas', value: '122' },
        { label: 'Lucas Gabriel', value: '123' },
        { label: 'Lucca', value: '124' },
        { label: 'Luiz Felipe', value: '125' },
        { label: 'Luiz Gustavo', value: '126' },
        { label: 'Luiz Henrique', value: '127' },
        { label: 'Luiz Miguel', value: '128' },
        { label: 'Luiz Otávio', value: '129' },
        { label: 'Luiza', value: '130' },
        { label: 'Luna', value: '131' },
        { label: 'Maitê', value: '132' },
        { label: 'Malu', value: '133' },
        { label: 'Manuela', value: '134' },
        { label: 'Maria-', value: '135' },
        { label: 'Maria Alice', value: '136' },
        { label: 'Maria Cecília', value: '137' },
        { label: 'Maria Clara', value: '138' },
        { label: 'Maria Eduarda', value: '139' },
        { label: 'Maria Fernanda', value: '140' },
        { label: 'Maria Flor', value: '141' },
        { label: 'Maria Helena', value: '142' },
        { label: 'Maria Heloísa', value: '143' },
        { label: 'Maria Isis', value: '144' },
        { label: 'Maria Júlia', value: '145' },
        { label: 'Maria Laura', value: '146' },
        { label: 'Maria Luiza', value: '147' },
        { label: 'Maria Sophia', value: '148' },
        { label: 'Maria Valentina', value: '149' },
        { label: 'Maria Vitória', value: '150' },
        { label: 'Mariah', value: '151' },
        { label: 'Mariana', value: '152' },
        { label: 'Marina', value: '153' },
        { label: 'Martin', value: '154' },
        { label: 'Matheus', value: '155' },
        { label: 'Maya', value: '156' },
        { label: 'Melissa', value: '157' },
        { label: 'Miguel', value: '158' },
        { label: 'Miguel Henrique', value: '159' },
        { label: 'Milena', value: '160' },
        { label: 'Mirella', value: '161' },
        { label: 'Murilo', value: '162' },
        { label: 'Nathan', value: '163' },
        { label: 'Nicolas', value: '164' },
        { label: 'Nicole', value: '165' },
        { label: 'Noah', value: '166' },
        { label: 'Oliver', value: '167' },
        { label: 'Olívia', value: '168' },
        { label: 'Otávio', value: '169' },
        { label: 'Pedro', value: '170' },
        { label: 'Pedro Henrique', value: '171' },
        { label: 'Pedro Lucas', value: '172' },
        { label: 'Pedro Miguel', value: '173' },
        { label: 'Pietra', value: '174' },
        { label: 'Pietro', value: '175' },
        { label: 'Rafael', value: '176' },
        { label: 'Rafaela', value: '177' },
        { label: 'Raul', value: '178' },
        { label: 'Rebeca', value: '179' },
        { label: 'Rodrigo', value: '180' },
        { label: 'Ruan', value: '181' },
        { label: 'Ryan', value: '182' },
        { label: 'Samuel', value: '183' },
        { label: 'Sarah', value: '184' },
        { label: 'Sophia', value: '185' },
        { label: 'Sophie', value: '186' },
        { label: 'Stella', value: '187' },
        { label: 'Théo', value: '188' },
        { label: 'Thiago', value: '189' },
        { label: 'Thomas', value: '190' },
        { label: 'Tomás', value: '191' },
        { label: 'Valentina', value: '192' },
        { label: 'Vicente', value: '193' },
        { label: 'Victor', value: '194' },
        { label: 'Victor Hugo', value: '195' },
        { label: 'Vinícius', value: '196' },
        { label: 'Vitória', value: '197' },
        { label: 'Yago', value: '198' },
        { label: 'Yasmin', value: '199' },
        { label: 'Yuri', value: '200' }
    ];

    return (<>
        <h1>Form</h1>

        <LcToggle toggled={error} onChange={event => { setError(event.checked) }} title="Error" />
        <br />
        <LcToggle toggled={required} onChange={event => { setRequired(event.checked) }} title="Obrigatório" />
        <br />
        <LcToggle toggled={readOnly} onChange={event => { setReadOnly(event.checked) }} title="Somente leitura" />

        <div className="form mt-5">

            <PPInput
                title="PPInput text"
                type="text"
                name="field_PPInput_text"
                onChange={console.debug}
                error={{ show: error, help: "Erro catastrófico" }}
                required={required}
                readOnly={readOnly}
            />
            <PPInput
                title="PPInput date"
                type="date"
                name="field_PPInput_date"
                onChange={console.debug}
                error={{ show: error, help: "Erro catastrófico" }}
                required={required}
                readOnly={readOnly}
            />
            <PPInput
                title="PPInput time"
                type="time"
                name="field_PPInput_time"
                onChange={console.debug}
                error={{ show: error, help: "Erro catastrófico" }}
                required={required}
                readOnly={readOnly}
            />
            <PPInput
                title="PPInput datetime-local"
                type="datetime-local"
                name="field_PPInput_datetime-local"
                onChange={console.debug}
                error={{ show: error, help: "Erro catastrófico" }}
                required={required}
                readOnly={readOnly}
            />
            <PPInput
                title="PPInput month"
                type="month"
                name="field_PPInput_month"
                onChange={console.debug}
                error={{ show: error, help: "Erro catastrófico" }}
                required={required}
                readOnly={readOnly}
            />
            <PPTextArea
                title='PPTextArea'
                name="field_PPTextArea"
                error={{ show: error, help: "Erro catastrófico" }}
                onChange={console.debug}
                required={required}
                readOnly={readOnly}
                rows={5}
                autoheight
            />
            <PPMultiSelect
                title='PPMultiSelect'
                name="field_PPMultiSelect"
                options={options}
                error={{ show: error, help: "Erro catastrófico" }}
                onChange={console.debug}
                required={required}
                readOnly={readOnly}
            />

        </div>


    </>);
}

export default TabForm;