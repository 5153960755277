export class backuppremium {
    solution() {
        return 'backuppremium'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-backup'
    }

    logotipo() {
        return require('./assets/logotipos/logo_backuppremium.svg').default
    }
    titulo() {
        return <><b>Proteja dados vitais do negócio</b> com LiveCloud Backup Premium</>
    }

    chamada() {
        return <>Executa de forma eficaz a conservação e restauração dos dados da organização, minimizando riscos de perda de informações e downtime. Com LiveCloud Backup Premium, os dados estão protegidos e disponíveis quando precisar.</>
    }

    image() {
        return require('./assets/imgs/imagem_backuppremium.png').default
    }

    detalhes() {
        return <>
            <h2>Já imaginou o impacto de perder dados cruciais para o negócio?</h2>
            <p><b>Nós oferecemos uma solução de guarda e proteção de dados em nuvem, operada, suportada e monitorada por nossa equipe de especialistas.</b></p>
            <h3>Por que escolher LiveCloud Backup Premium?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_backuppremium.png').default} />
                </div>
                <ul>
                    <li><b>Backup em ambiente de alta disponibilidade:</b> Seus dados estão sempre seguros e acessíveis.</li>
                    <li><b>Implantação, suporte, operação e monitoramento:</b> Deixe a gestão de backup conosco e foque no que realmente importa para o seu negócio.</li>
                    <li><b>Backup e restore granular de arquivos e aplicações:</b> Recupere exatamente o que você precisa, quando precisa.</li>
                    <li><b>Console de auto-serviço para recuperação de dados:</b> Tenha o controle nas suas mãos e recupere seus dados de forma rápida e fácil.</li>
                    <li><b>Cache local dos backups (até 7 dias):</b> Acesse rapidamente seus backups mais recentes.</li>
                    <li><b>Compressão de dados na transferência e deduplicação de dados:</b> Economize espaço de armazenamento e otimize a transferência de dados.</li>
                    <li><b>Tempo mínimo para disponibilização dos dados após a solicitação:</b> Seus dados disponíveis em até 1 hora.</li>
                </ul>
            </div>
            <div className="box"><b>LiveCloud Backup Premium desonera o negócio com investimentos em tecnologias de backup e equipes especializadas.</b> Oferecemos um serviço estruturado, com jobs periódicos de backup e testes de restore. Através do Portal LiveCloud, a organização pode acompanhar atividades, status, agendar novos procedimentos, solicitar restores e muito mais!</div>
            <h3>Níveis de serviço</h3>
            <p>Oferecemos um <b>SLA de início de 45 minutos</b> e um <b>SLO de fim de 4 horas</b>, proporcionando a organização a tranquilidade de que os serviços contratados estarão disponíveis quando precisar.</p>
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como LiveCloud Backup Premium pode proteger os dados vitais do negócio.</p>            
        </>
    }
}
export default backuppremium;