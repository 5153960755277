import React, { useState } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';

//Componentes
import { PPModal } from 'processor-plataform-ui';
import LcTooltip from '../../components/Generic/LcTooltip';
import LcIconLink from '../../components/Generic/LcIconLink';


const TabOthers: React.FC = () => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState(false);

    return (<>
        <h1>Others</h1>

        <LcTooltip content={<span>ToolTip</span>} trigger='hover' >
            <button className='lc-button bg-primary'>padrão</button>
        </LcTooltip>

        <hr />

        <LcIconLink icon='ppi ppi-cog' tooltip='dfg jndfg' tooltipPosition='left' />

        <hr />
        <p>
            Mussum Ipsum, cacilds vidis litro abertis. Quem num gosta di mim que vai caçá sua turmis!Interagi no mé, cursus quis, vehicula ac nisi.Nullam volutpat risus nec leo commodo, ut interdum diam laoreet. Sed non consequat odio.Per aumento de cachacis, eu reclamis.
        </p>
        <p>
            Diuretics paradis num copo é motivis de denguis.In elementis mé pra quem é amistosis quis leo.A ordem dos tratores não altera o pão duris.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!
        </p>
        <p>
            Pra lá , depois divoltis porris, paradis.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Suco de cevadiss, é um leite divinis, qui tem lupuliz, matis, aguis e fermentis.Casamentiss faiz malandris se pirulitá.
        </p>
        <p>
            Aenean aliquam molestie leo, vitae iaculis nisl.A ordem dos tratores não altera o pão duris.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Mé faiz elementum girarzis, nisi eros vermeio.
        </p>
        <p>
            Vehicula non. Ut sed ex eros. Vivamus sit amet nibh non tellus tristique interdum.Praesent vel viverra nisi. Mauris aliquet nunc non turpis scelerisque, eget.In elementis mé pra quem é amistosis quis leo.Praesent malesuada urna nisi, quis volutpat erat hendrerit non. Nam vulputate dapibus.
        </p>
        <p>
            In elementis mé pra quem é amistosis quis leo.Mais vale um bebadis conhecidiss, que um alcoolatra anonimis.Quem num gosta di mim que vai caçá sua turmis!Interagi no mé, cursus quis, vehicula ac nisi.
        </p>
        <p>
            Casamentiss faiz malandris se pirulitá.Nec orci ornare consequat. Praesent lacinia ultrices consectetur. Sed non ipsum felis.Sapien in monti palavris qui num significa nadis i pareci latim.Viva Forevis aptent taciti sociosqu ad litora torquent.
        </p>
        <p>
            Quem num gosta di mim que vai caçá sua turmis!Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Casamentiss faiz malandris se pirulitá.Manduma pindureta quium dia nois paga.
        </p>
        <p>
            Leite de capivaris, leite de mula manquis sem cabeça.A ordem dos tratores não altera o pão duris.Cevadis im ampola pa arma uma pindureta.Detraxit consequat et quo num tendi nada.
        </p>
        <p>
            Paisis, filhis, espiritis santis.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Suco de cevadiss deixa as pessoas mais interessantis.Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.
        </p>
        <p>
            Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.In elementis mé pra quem é amistosis quis leo.
        </p>
        <p>
            Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Mé faiz elementum girarzis, nisi eros vermeio.
        </p>
        <p>
            Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Detraxit consequat et quo num tendi nada.Per aumento de cachacis, eu reclamis.Pra lá , depois divoltis porris, paradis.
        </p>
        <p>
            Vehicula non. Ut sed ex eros. Vivamus sit amet nibh non tellus tristique interdum.Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Interagi no mé, cursus quis, vehicula ac nisi.Admodum accumsan disputationi eu sit. Vide electram sadipscing et per.
        </p>
        <p>
            Atirei o pau no gatis, per gatis num morreus.In elementis mé pra quem é amistosis quis leo.Manduma pindureta quium dia nois paga.Paisis, filhis, espiritis santis.
        </p>
        <p>
            Interagi no mé, cursus quis, vehicula ac nisi.Quem num gosta di mé, boa gentis num é.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Manduma pindureta quium dia nois paga.
        </p>
        <p>
            Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Quem num gosta di mim que vai caçá sua turmis!Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Quem num gosta di mé, boa gentis num é.
        </p>
        <p>
            Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Mé faiz elementum girarzis, nisi eros vermeio.Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!
        </p>
        <p>
            Cevadis im ampola pa arma uma pindureta.Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Suco de cevadiss deixa as pessoas mais interessantis.
        </p>
        <p>
            Posuere libero varius.Nullam a nisl ut ante blandit hendrerit.Aenean sit amet nisi.Si num tem leite então bota uma pinga aí cumpadi!Delegadis gente finis, bibendum egestas augue arcu ut est.Praesent vel viverra nisi.Mauris aliquet nunc non turpis scelerisque, eget.
        </p>
        <hr />



        <button className='lc-button bg-primary' onClick={() => setModalVisible(true)}>Abrir</button>
        <PPModal
            title='Teste de modal'
            visible={modalVisible}
            onClose={setModalVisible}
            functions={[
                { label: "Ok", onClick: () => { } },
                { label: "Cancelar", onClick: () => setModalVisible(false) }
            ]}
        >
            <p>
                Mussum Ipsum, cacilds vidis litro abertis. Quem num gosta di mim que vai caçá sua turmis!Interagi no mé, cursus quis, vehicula ac nisi.Nullam volutpat risus nec leo commodo, ut interdum diam laoreet. Sed non consequat odio.Per aumento de cachacis, eu reclamis.
            </p>
            <p>
                Diuretics paradis num copo é motivis de denguis.In elementis mé pra quem é amistosis quis leo.A ordem dos tratores não altera o pão duris.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!
            </p>
            <p>
                Pra lá , depois divoltis porris, paradis.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Suco de cevadiss, é um leite divinis, qui tem lupuliz, matis, aguis e fermentis.Casamentiss faiz malandris se pirulitá.
            </p>
            <p>
                Aenean aliquam molestie leo, vitae iaculis nisl.A ordem dos tratores não altera o pão duris.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Mé faiz elementum girarzis, nisi eros vermeio.
            </p>
            <p>
                Vehicula non. Ut sed ex eros. Vivamus sit amet nibh non tellus tristique interdum.Praesent vel viverra nisi. Mauris aliquet nunc non turpis scelerisque, eget.In elementis mé pra quem é amistosis quis leo.Praesent malesuada urna nisi, quis volutpat erat hendrerit non. Nam vulputate dapibus.
            </p>
            <p>
                In elementis mé pra quem é amistosis quis leo.Mais vale um bebadis conhecidiss, que um alcoolatra anonimis.Quem num gosta di mim que vai caçá sua turmis!Interagi no mé, cursus quis, vehicula ac nisi.
            </p>
            <p>
                Casamentiss faiz malandris se pirulitá.Nec orci ornare consequat. Praesent lacinia ultrices consectetur. Sed non ipsum felis.Sapien in monti palavris qui num significa nadis i pareci latim.Viva Forevis aptent taciti sociosqu ad litora torquent.
            </p>
            <p>
                Quem num gosta di mim que vai caçá sua turmis!Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Casamentiss faiz malandris se pirulitá.Manduma pindureta quium dia nois paga.
            </p>
            <p>
                Leite de capivaris, leite de mula manquis sem cabeça.A ordem dos tratores não altera o pão duris.Cevadis im ampola pa arma uma pindureta.Detraxit consequat et quo num tendi nada.
            </p>
            <p>
                Paisis, filhis, espiritis santis.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Suco de cevadiss deixa as pessoas mais interessantis.Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.
            </p>
            <p>
                Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.In elementis mé pra quem é amistosis quis leo.
            </p>
            <p>
                Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Mé faiz elementum girarzis, nisi eros vermeio.
            </p>
            <p>
                Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Detraxit consequat et quo num tendi nada.Per aumento de cachacis, eu reclamis.Pra lá , depois divoltis porris, paradis.
            </p>
            <p>
                Vehicula non. Ut sed ex eros. Vivamus sit amet nibh non tellus tristique interdum.Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Interagi no mé, cursus quis, vehicula ac nisi.Admodum accumsan disputationi eu sit. Vide electram sadipscing et per.
            </p>
            <p>
                Atirei o pau no gatis, per gatis num morreus.In elementis mé pra quem é amistosis quis leo.Manduma pindureta quium dia nois paga.Paisis, filhis, espiritis santis.
            </p>
            <p>
                Interagi no mé, cursus quis, vehicula ac nisi.Quem num gosta di mé, boa gentis num é.Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Manduma pindureta quium dia nois paga.
            </p>
            <p>
                Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!Quem num gosta di mim que vai caçá sua turmis!Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Quem num gosta di mé, boa gentis num é.
            </p>
            <p>
                Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Mé faiz elementum girarzis, nisi eros vermeio.Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!
            </p>
            <p>
                Cevadis im ampola pa arma uma pindureta.Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Suco de cevadiss deixa as pessoas mais interessantis.
            </p>
            <p>
                Posuere libero varius.Nullam a nisl ut ante blandit hendrerit.Aenean sit amet nisi.Si num tem leite então bota uma pinga aí cumpadi!Delegadis gente finis, bibendum egestas augue arcu ut est.Praesent vel viverra nisi.Mauris aliquet nunc non turpis scelerisque, eget.
            </p>
        </PPModal>

        <hr />

        <button className='lc-button bg-primary'
            onClick={() => {
                let _list = user.ClientGroupList.filter(c => c.defaultClientGroup);
                console.debug(_list);
                dispatch({ type: 'USER_UPDATE', payload: { ...user, ClientGroupList: _list } });
            }}
        >Limpar empresas</button>
    </>
    );
}

export default TabOthers;