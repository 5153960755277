export interface CMSRecomendationState {
    recomendationFilter: string;
    rowsList: any[],
    rowsListFiltered: any[],
    translationFilter:any
}
const initialState : CMSRecomendationState = {
    recomendationFilter: '',
    rowsList: [],
    rowsListFiltered: [],
    translationFilter: {}
}
interface SetCMSRecomendationAction {
    type: string;
    payload: Required<CMSRecomendationState>;
}
export const CMSRecomendationReducer = (
    state:CMSRecomendationState = initialState,
    action: SetCMSRecomendationAction
) => {
    switch (action.type) {
        case 'CMS_RECOMENDATION_FILTER': 
            return {
                ...state,
                ...action.payload,
            };
        case 'CMS_RECOMENDATION_FILTER_ROWS': 
            return {
                ...state,
                rowsListFiltered:action.payload,
            };
        case "RESET_" :
            return {
                ...state,
                ...initialState,
            }
        default:
            return state;
    }
};