import ReactECharts, { EChartsOption } from 'echarts-for-react';
import moment from "moment"
import { PPTooltip } from "processor-plataform-ui"
import { formatDecimal } from '../../../services/currency';
import { Info_Items_Options } from '../../../services/finOps/finOpsRecommendations';
import { defaultTooltipConfig } from '../../../components/Generic/eChartsConfig';
const getUsageBarChart = (dados: any, estimado) => {

    let avg: number = estimado ? dados.project_cpu_avg : dados.cpu_usage;
    let max: number = estimado ? dados.project_cpu_peak : dados.cpu_peak;
    let q50: number = estimado ? dados.projected_cpu_qtl_50 : dados.cpu_quantile_50;
    let q99: number = estimado ? dados.projected_cpu_qtl_99 : dados.cpu_quantile_99;

    var options: EChartsOption = ({
        tooltip: {
            triggerOn: 'mousemove',
            show: true,
            padding: 2,
            position: ['30%', '70%'],            
            ...defaultTooltipConfig,
            formatter: function (params: any) {
                let output = params.data.name + ` : ` + params.data.value
                return output
            }
        },
        xAxis: {
            min: 0,
            max: 100,
            show: false,
            type: 'value',
            splitNumber: 1
        },
        yAxis: {
            show: true,
            type: 'category',
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false
            },
        },
        series: [
            {
                showBackground: true,
                backgroundStyle: {
                    color: '#E6E6E6'
                },

                data: [
                    {
                        name: 'Percentil 99',
                        value: q99,
                        itemStyle: {
                            color: '#D35555',
                        }
                    },
                    {
                        name: 'Percentil 50',
                        value: q50,
                        itemStyle: {
                            color: '#E9B633'
                        }
                    },
                    {
                        name: 'Máximo',
                        value: max,
                        itemStyle: {
                            color: '#6D458C'
                        }
                    },
                    {
                        name: 'Médio',
                        value: avg,
                        itemStyle: {
                            color: '#59BAC5'
                        }
                    },
                ],
                type: 'bar'
            }
        ]
    })

    return options
}

const getInactivityPeriods = (period: any) => {
    let _start = moment().weekday(period.start.day_of_week).hour(period.start.hour).minute(0).format("ddd HH:mm");
    let _end = moment().weekday(period.end.day_of_week).hour(period.end.hour).minute(59).format("ddd HH:mm");
    return <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div className='capitalizeDates' >
            {_start}
        </div>
        <div style={{ width: '5%' }} className='capitalizeDates'>
            {` - `}
        </div>
        <div className='capitalizeDates'>
            {_end}
        </div>


    </div>
}

const rightsizing_instances_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '30%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: 'flavor', headerName: 'Tamanho', width: '10%',
        renderCell: (row) => {
            return <div>
                {row.flavor}<br />
                CPU: <b>{row.cpu}</b>
            </div>
        }
    },
    {
        field: 'recommended_flavor', headerName: 'Recomendação', width: '10%',
        renderCell: (row) => {
            return <div>
                {row.recommended_flavor}<br />
                CPU: <b>{row.recommended_flavor_cpu}</b>
            </div>
        }
    },
    {
        field: '', headerName: 'Uso atual de CPU', width: '15%',
        renderCell: (row) => {
            return <ReactECharts
                lazyUpdate={true}
                style={{ height: "80px", width: '210px' }}
                option={getUsageBarChart(row, false)}

            />
        }
    },
    {
        field: '', headerName: 'Uso estimado CPU', width: '15%',
        renderCell: (row) => {
            return <ReactECharts
                lazyUpdate={true}
                style={{ height: "80px", width: '210px' }}
                option={getUsageBarChart(row, true)}

            />
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '10%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const instance_generation_upgrade_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '25%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: 'flavor', headerName: 'Tamanho', width: '20%',
    },
    {
        field: 'recommended_flavor', headerName: 'Recomendação', width: '20%',
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '10%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const abandoned_instances_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '40%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: '', headerName: 'Assinatura', width: '40%',
        renderCell: (row) => {
            return <div>
                {row.pool_name}<br />
            </div>
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '10%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const volumes_not_attached_for_a_long_time_columns = [
    {
        field: 'cloud_resource_id', headerName: 'Recurso', width: '20%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.cloud_resource_id}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: '', headerName: 'Último uso', width: '20%',
        renderCell: (row) => {
            return row.last_seen_in_attached_state == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_seen_in_attached_state * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_seen_in_attached_state * 1000).fromNow()} */}

                </div>
        }
    },
    {
        field: '', headerName: 'Valores quando desanexados', width: '20%', align: 'right',
        renderCell: (row) => {
            return parseFloat(row.cost_in_detached_state).toFixed(2).toString().replace('.', ',')
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const obsolete_ips_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '20%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: '', headerName: 'Último uso', width: '20%',
        renderCell: (row) => {
            return row.last_seen_active == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_seen_active * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_seen_active * 1000).fromNow()} */}

                </div>
        }
    },
    {
        field: '', headerName: 'Valores quando não utilizado', width: '20%', align: 'right',
        renderCell: (row) => {
            return parseFloat(row.cost_not_active_ip).toFixed(2).toString().replace('.', ',')
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const instances_for_shutdown_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '30%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: '', headerName: 'Assinatura', width: '25%',
        renderCell: (row) => {
            return <div>
                {row.pool_name}<br />
            </div>
        }
    },
    {
        field: '', headerName: 'Desligar em', width: '25%', align: 'center',
        renderCell: (row) => {
            return row.inactivity_periods ? <div style={{ width: '60%' }}>
                {row.inactivity_periods.map(period => {
                    return getInactivityPeriods(period)
                })}
                <br />
            </div>
                : <> Sem dados </>
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '10%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const short_living_instances_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '40%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: 'total_cost', headerName: 'Valores R$', width: '20%', align: 'left',
        renderCell: (row) => {
            return parseFloat(row.total_cost).toFixed(2).toString().replace('.', ',')
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const insecure_security_groups_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '30%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '15%',
    },
    {
        field: 'security_group_name', headerName: 'Grupo de segurança', width: '40%', align: 'left',
    },
    {
        field: '', headerName: 'Portas abertas', width: '15%', align: 'left',
        renderCell: (row) => {
            return row.insecure_ports && row.insecure_ports.length > 0 && row.insecure_ports[0].port == "*" ?
                'Todas' :
                row.insecure_ports  && row.insecure_ports.map(port => {
                    return port.port + `/${port.protocol}`
                })
        }
    },

]

const abandoned_kinesis_streams_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '25%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '25%',
    },
    {
        field: '', headerName: 'Fragmentos provisionados', width: '25%',
    },
    {
        field: '', headerName: 'Custo por hora', width: '25%', align: 'left',
    },
]

const inactive_console_users_columns = [
    {
        field: 'user_name', headerName: 'Usuário', width: '34%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.user_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'cloud_account_name', headerName: 'Local', width: '33%',
    },
    {
        field: '', headerName: 'Último uso', width: '33%',
        renderCell: (row) => {
            return row.last_used == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_used * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_used * 1000).fromNow()} */}

                </div>
        }
    },
]

const inactive_users_columns = [
    {
        field: 'user_name', headerName: 'Usuário', width: '34%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.user_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'cloud_account_name', headerName: 'Local', width: '33%',
    },
    {
        field: '', headerName: 'Último uso', width: '33%',
        renderCell: (row) => {
            return row.last_used == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_used * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_used * 1000).fromNow()} */}
                </div>
        }
    },

]

const instance_migration_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '20%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: 'flavor', headerName: 'Tamanho', width: '20%',
    },
    {
        field: 'recommended_flavor_region', headerName: 'Recomendação', width: '20%',
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const instance_subscription_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '30%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: 'flavor', headerName: 'Tamanho', width: '20%',
    },
    {
        field: '', headerName: 'economia mensal com subscrição de um mês', width: '20%',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

    {
        field: '', headerName: 'economia mensal com subscrição de um ano', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const instances_in_stopped_state_for_a_long_time_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '40%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '15%',
    },
    {
        field: '', headerName: 'Último uso', width: '15%',
        renderCell: (row) => {
            return row.last_seen_in_attached_state == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_seen_in_attached_state * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_seen_in_attached_state * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Custo estando parado', width: '15%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },
    {
        field: '', headerName: 'Potencial economia', width: '15%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const obsolete_images_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '45%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: '', headerName: 'Criado em', width: '15%',
        renderCell: (row) => {
            return row.first_seen == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.first_seen * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.first_seen * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Último uso', width: '15%',
        renderCell: (row) => {
            return row.last_used == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_used * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_used * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Potencial economia', width: '15%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const obsolete_snapshot_chains_columns = [
    {
        field: 'cloud_resource_id', headerName: 'Recurso', width: '20%',
        renderCell: (row) => {
            return <div className='resourceName'>
                {row.cloud_resource_id}
            </div>
        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: '', headerName: 'Criado em', width: '20%',
        renderCell: (row) => {
            return row.first_seen == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.first_seen * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.first_seen * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Último uso', width: '20%',
        renderCell: (row) => {
            return row.last_used == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_used * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_used * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const obsolete_snapshots_columns = [
    {
        field: 'cloud_resource_id', headerName: 'Recurso', width: '20%',
        renderCell: (row) => {
            return <div className='resourceName'>
                {row.cloud_resource_id}
            </div>
        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: '', headerName: 'Criado em', width: '20%',
        renderCell: (row) => {
            return row.first_seen == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.first_seen * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.first_seen * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Último uso', width: '20%',
        renderCell: (row) => {
            return row.last_used == 0 ?
                "Nunca"
                :
                <div>
                    {moment(row.last_used * 1000).format('DD/MM/YYYY' +  `    ` + 'HH:mm')}
                    {/* <br />
                    {moment(row.last_used * 1000).fromNow()} */}
                </div>
        }
    },
    {
        field: '', headerName: 'Potencial economia', width: '20%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const reserved_instances_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '40%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '20%',
    },
    {
        field: '', headerName: 'Economia com compromisso mínimo', width: '20%',
        renderCell: (row) => {
            return parseFloat(row.average_saving).toFixed(2).toString().replace('.', ',')
        }
    },
    {
        field: '', headerName: 'Economia com compromisso médio', width: '20%',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const rightsizing_rds_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '25%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '25%',
    },
    {
        field: 'flavor', headerName: 'Tamanho', width: '25%',
        renderCell: (row) => {
            return <div>
                {row.flavor}<br />
                CPU: <b>{row.cpu}</b>
            </div>
        }
    },
    {
        field: 'recommended_flavor', headerName: 'Recomendação', width: '25%',
        renderCell: (row) => {
            return <div>
                {row.recommended_flavor}<br />
                {/* CPU: <b>{row.recommended_flavor_cpu}</b> */}
            </div>
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '25%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const s3_abandoned_buckets_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '20%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: '', headerName: 'Assinatura', width: '25%',
        renderCell: (row) => {
            return <div>
                {row.pool_name}<br />
            </div>
        }
    },
    {
        field: 'avg_data_size', headerName: 'Tamanho dos dados (média)', width: '15%',
        renderCell: (row) => {
            return <div>
                {row.avg_data_size.toFixed(2).toString().replace('.', ',')} MB
            </div>
        }
    },
    {
        field: 'tier_1_request_quantity', headerName: 'Solicitações de 1 nível', width: '10%',
        renderCell: (row) => {
            return <div>
                {row.tier_1_request_quantity}
            </div>
        }
    },
    {
        field: '', headerName: 'Solicitações GET', width: '10%',
        renderCell: (row) => {
            return <div>
                {row.tier_2_request_quantity}
            </div>
        }
    },
    {
        field: 'saving', headerName: 'Potencial economia', width: '10%', align: 'right',
        renderCell: (row) => {
            return formatDecimal(parseFloat(row.saving))
        }
    },

]

const s3_public_buckets_columns = [
    {
        field: 'resource_name', headerName: 'Recurso', width: '25%', overflow: 'visible',
        renderCell: (row) => {
            return <PPTooltip trigger='hover' position='left' content={`Detectado em ${moment(row.detected_at * 1000).format('DD/MM/YYYY  HH:mm')}`}  >
                <div className='resourceName'>
                    {row.resource_name}
                </div>
            </PPTooltip>

        }
    },
    {
        field: 'region', headerName: 'Local', width: '10%',
    },
    {
        field: '', headerName: 'Assinatura', width: '30%',
        renderCell: (row) => {
            return <div>
                {row.pool_name}<br />
            </div>
        }
    },
    {
        field: '', headerName: 'Políticas públicas', width: '25%',
        renderCell: (row) => {
            return row.is_public_policy ? "Sim" : 'Não'

        }
    },
    {
        field: '', headerName: 'ACLs públicos', width: '15%',
        renderCell: (row) => {
            return row.is_public_acls ? "Sim" : 'Não'

        }
    },

]

export const getRecomendationsInfos = (optimization: Info_Items_Options) => {
    switch (optimization.optimization_name) {
        case 'abandoned_instances':
            return {
                title: 'Instâncias abandonadas', columns: abandoned_instances_columns, type: 'c',
                definition: `Algumas das suas instâncias ativas foram detectadas como abandonadas (o consumo médio de CPU é inferior a ${optimization.options?.cpu_percent_threshold}% e o tráfego de rede abaixo de ${optimization.options?.network_bps_threshold} bytes/s nos últimos ${optimization.options?.days_threshold} dias).`
            }
        case 'rightsizing_instances':
            return {
                title: 'Instâncias subutilizadas', columns: rightsizing_instances_columns, type: 'c',
                definition: `Algumas instâncias ativas podem ser reduzidas/redimensionadas (com uso máximo de CPU < ${optimization.options?.metric_limit}% nos últimos ${optimization.options?.days_threshold} dias).`
            }

        case 'volumes_not_attached_for_a_long_time':
            return {
                title: 'Volumes desanexados', columns: volumes_not_attached_for_a_long_time_columns, type: 'c',
                definition: `Alguns dos volumes destacados não foram anexados por mais de ${optimization.options?.days_threshold} dia.`
            }

        case 'obsolete_ips':
            return {
                title: 'IPs obsoletos', columns: obsolete_ips_columns, type: 'c',
                definition: `Alguns IPs não foram usados ​​nos últimos ${optimization.options?.days_threshold} dias.`
            }

        case 'instances_for_shutdown':
            return {
                title: 'Instâncias para desligar', columns: instances_for_shutdown_columns, type: 'c',
                definition: `Algumas das suas instâncias têm um padrão de inatividade que permite configurar uma programação de ligar/desligar (uso médio de CPU < ${optimization.options?.cpu_percent_threshold}% e tráfego rede < 1000 bytes/s nos últimos ${optimization.options?.days_threshold} dias).`
            }

        case 'instance_generation_upgrade':
            return {
                title: 'Instâncias elegíveis para atualização de geração', columns: instance_generation_upgrade_columns, type: 'c',
                definition: 'Instâncias com a geração anterior são qualificadas para upgrade para a geração mais recente da mesma família.'
            }

        case 'short_living_instances':
            return {
                title: 'Instâncias com oportunidades "spot"', columns: short_living_instances_columns, type: 'c',
                definition: `Algumas das instâncias que você executou nos últimos ${optimization.options?.days_threshold} dias existem há menos de 6 horas e não foram criadas como instâncias spot.`
            }

        case 'insecure_security_groups':
            return {
                title: 'Instâncias com configurações inseguras - Instâncias com risco', columns: insecure_security_groups_columns, type: 's',
                definition: 'Algumas instâncias estão configuradas com grupos de segurança que possuem portas de entrada consideradas inadequadas. Considere alterar estes, restringindo-os a intervalos com IPs específicos para aumentar a segurança e/ou abra um chamado com a equipe LiveCloud.'
            }

        case 'abandoned_kinesis_streams':
            return {
                title: 'Streams do Kinesis abandonados', columns: abandoned_kinesis_streams_columns, type: 'c',
                definition: `Os Streams do Kinesis a seguir provisionaram a capacidade do Shard, mas não realizaram operações de dados nos últimos ${optimization.options?.days_threshold} dias.`
            }

        case 'inactive_console_users':
            return {
                title: 'Usuários do IAM com acesso ao console inutilizado - Potencial risco', columns: inactive_console_users_columns, type: 's',
                definition: `Os seguintes usuários ativos do IAM têm o acesso ao console ativado, mas não o usam há mais de ${optimization.options?.days_threshold} dias.`
            }

        case 'inactive_users':
            return {
                title: 'Usuários do IAM inativos - Potencial risco', columns: inactive_users_columns, type: 's',
                definition: `Os seguintes usuários do IAM estão inativos por mais de ${optimization.options?.days_threshold} dias. Considere sua exclusão.`
            }

        case 'instance_migration':
            return {
                title: 'Instâncias com oportunidades de migração', columns: instance_migration_columns, type: 'c',
                definition: 'Algumas das suas instâncias ativas podem custar menos com o mesmo tamanho em outra região geograficamente mais próxima.'
            }

        case 'instance_subscription':
            return {
                title: 'Instâncias com oportunidades de assinatura', columns: instance_subscription_columns, type: 'c',
                definition: `Algumas das suas instâncias ativas foram detectadas como consumidores de computação sustentáveis ​​(por mais de ${optimization.options?.days_threshold} dias), mas não foram cobertas por planos de assinatura ou de economia.`
            }

        case 'instances_in_stopped_state_for_a_long_time':
            return {
                title: 'Instâncias não desalocadas', columns: instances_in_stopped_state_for_a_long_time_columns, type: 'c',
                definition: `Algumas das suas instâncias estão interrompidas, mas não desalocadas, estando em execução há mais de ${optimization.options?.days_threshold} dia e ainda sendo cobradas pela nuvem. Avalie sua exclusão ou desalocação.`
            }

        case 'obsolete_images':
            return {
                title: 'Imagens obsoletas', columns: obsolete_images_columns, type: 'c',
                definition: `Algumas imagens de container não foram usadas para criação de instâncias nos últimos ${optimization.options?.days_threshold} dias. Considere sua exclusão.`
            }

        case 'obsolete_snapshot_chains':
            return {
                title: `Snapshots obsoletos "chains"`, columns: obsolete_snapshot_chains_columns, type: 'c',
                definition: `Algumas cadeias de snapshots não têm volumes de origem e imagens criadas a partir destes  não foram usadas para criação de volumes nos últimos ${optimization.options?.days_threshold} dias.`
            }

        case 'obsolete_snapshots':
            return {
                title: 'Snapshots obsoletos', columns: obsolete_snapshots_columns, type: 'c',
                definition: `Alguns snapshots do EBS não têm volumes de origem AMIs e não foram usados para criação destes nos últimos ${optimization.options?.days_threshold} dias.`
            }

        case 'reserved_instances':
            return {
                title: 'Oportunidades de instâncias reservadas', columns: reserved_instances_columns, type: 'c',
                definition: `Detectamos possíveis instâncias do como consumidores de computação sustentáveis (por mais de ${optimization.options?.days_threshold} dias), mas não foram cobertas por instâncias reservadas ou planos de economia.`
            }

        case 'rightsizing_rds':
            return {
                title: 'Instâncias RDS subutilizadas', columns: rightsizing_rds_columns, type: 'c',
                definition: 'Algumas das suas instâncias RDS estão detectadas como subutilizadas e podem ser reduzidas.'
            }

        case 's3_abandoned_buckets':
            return {
                title: 'Buckets abandonados', columns: s3_abandoned_buckets_columns, type: 'c',
                definition: `Alguns de seus buckets ativos do S3 foram detectados como abandonados (tamanho médio < ${optimization.options?.data_size_threshold}mb, qtd solicitações Tier1 < ${optimization.options?.tier_1_request_quantity_threshold} e qtd de GET < ${optimization.options?.tier_2_request_quantity_threshold} nos últimos ${optimization.options?.days_threshold} dias).`
            }

        case 's3_public_buckets':
            return {
                title: 'Buckets públicos - Potencial risco', columns: s3_public_buckets_columns, type: 's',
                definition: 'Os seguintes buckets do Amazon S3 são públicos. Certifique-se de que os buckets usam as políticas corretas e não são acessíveis publicamente, a menos que seja explicitamente exigido.'
            }

        default:
            return { title: '', columns: [], definition: '', type: '' }
    }
}
