import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { RetencaoService } from '../../../services/retencao/retencaoService';
import { Grid, LinearProgress, Button } from '@material-ui/core';
import TreeView from '../../../components/Data/retencao/treeView/index';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import { CacheManagerService } from '../../../services/cacheManagerService';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiDatabaseLight, PiFilesLight, PiUploadLight } from 'react-icons/pi';

function getUpdatedAt(data: string) {
    let UpdatedAt: string = data;
    let date = new Date(UpdatedAt);

    return moment(date).toISOString();
}


interface RetencaoResumoProps {
    isHome?: boolean,
    qtdGroups?: number
}

const RetencaoResumo: React.FC<RetencaoResumoProps> = (props) => {
    const cacheManagerService = new CacheManagerService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [cards, setCards] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [UpdatedAt, setUpdatedAt] = useState<string>();
    const [unity, setUnity] = useState<string>();
    const [errorCards, setErrorCards] = useState<boolean>(false);
    const [isDataCountOk, setIsDataCountOk] = useState<boolean>(true);
    const [isNullData, setIsNullData] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<string>("");

    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("LiveCloudSyncRetencao", "ConsumoRetencaoSAS")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    useEffect(() => {
        setErrorCards(false);
        if (user.ClientGroupSelected) {
            const retencaoService = new RetencaoService(props);

            let consumption = sessionStorage.getItem('Retenção');

            retencaoService.GetCards()
                .then(response => {
                    let cards = response.data;
                    if (cards.ultimaModificacao === null) { setIsNullData(true) } else { setIsNullData(false) }
                    let date = new Date(cards.ultimaModificacao);

                    let sizes = ["B", "KB", "MB", "GB", "TB"];

                    let len = cards.espacoConsumidoBytes; //exemplo
                    let order = 0;
                    while (len >= 1024 && order < sizes.length - 1) {
                        order++;
                        len = len / 1024;
                    }
                    let result = len.toLocaleString('en-US', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });

                    console.log(result);

                    setUnity(sizes[order]);

                    cards.ultimaModificacao = date.getDate().toString().padStart(2, "0") + "/" + (date.getMonth() + 1).toString().padStart(2, "0") + "/" + date.getFullYear();
                    cards.espacoConsumidoBytes = result;

                    if (consumption && cards.espacoConsumidoBytes === 0 && cards.espacoConsumidoBytes != consumption) {
                        setIsDataCountOk(false)
                        sessionStorage.removeItem('Retenção')
                    }

                    setCards(cards);
                    if (response.data.totalArquivos === 0 && response.data.sincronizacao === null) {
                        setErrorCards(true);
                    }
                    setUpdatedAt(getUpdatedAt(cards.sincronizacao));
                    setIsLoading(false);
                })
                .catch(error => {
                    setErrorCards(true);
                    let cards: any = { totalArquivos: 0, espacoConsumidoBytes: 0, ultimaModificacao: undefined };
                    if (consumption && cards.espacoConsumidoBytes === 0 && cards.espacoConsumidoBytes != consumption) {
                        setIsDataCountOk(false)
                        sessionStorage.removeItem('Retenção')
                    }
                    console.log(error);
                    setCards(cards);
                    setIsLoading(false);
                });
        }
        refreshLastUpdate();
    }, [user.ClientGroupSelected, user.refreshFlag]);


    const positions5Groups: Card[] = [
        {
            id: 1,//Total de arquivos
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 2,//Espaço consumido
            position: { row: 2, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 3,//Último upload
            position: { row: 3, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
    ]

    const positions3Groups: Card[] = [
        {
            id: 1,//Total de arquivos
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 2,//Espaço consumido
            position: { row: 2, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 3,//Último upload
            position: { row: 3, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
    ]

    const positions2Groups: Card[] = [
        {
            id: 1,//Total de arquivos
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 2,//Espaço consumido
            position: { row: 2, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 3,//Último upload
            position: { row: 3, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
    ]

    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id == Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }


    var cardNovos: Card[] = [
        {
            id: 1,//Total de arquivos
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                ColSpan: 4,
                RowSpan: 1, row: 1, col: 1
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Total de arquivos</div>
                }</>
            },
            customContentRender: () => {
                return <div   >
                    <IconValue isHome={props.isHome} title="Total de arquivos"
                        icon={<PiFilesLight size={props.isHome ? 24 : 32} />}
                        value={cards.totalArquivos}
                        color="var(--color-primary-pink)"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        formatValue={true}
                        description={props.isHome ? "Total de arquivos em retenção" : undefined}
                    >

                    </IconValue >
                </div>
            }
        },
        {
            type: 'Custom',
            id: 2,//Espaço consumido
            position: {
                ColSpan: 4,
                RowSpan: 1, row: 1, col: 5
            },
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Espaço consumido</div>
                }</>
            },
            customContentRender: () => {
                return <div>
                    <IconValue isHome={props.isHome}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        title="Espaço consumido"
                        icon={<PiDatabaseLight size={props.isHome ? 24 : 32} />}
                        value={cards.espacoConsumidoBytes}
                        unity={unity}
                        formatValue={true}
                        color={"var(--color-primary-purple-light)"}
                        description={props.isHome ? "Espaço consumido em retenção" : undefined}
                    >
                    </IconValue >
                </div>
            }
        },
        {
            type: 'Custom',
            id: 3,//Último upload
            position: {
                ColSpan: 4,
                RowSpan: 1, row: 1, col: 9
            },
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Último upload</div>
                }</>
            },
            customContentRender: () => {
                return <div  >
                    {isNullData ?
                        <IconValue title="Último upload" isHome={props.isHome} icon={<PiUploadLight size={props.isHome ? 24 : 32} />} value="0" color="var(--color-primary-blue)" description={props.isHome ? "Último upload" : undefined}></IconValue>
                        :
                        <IconValue title="Último upload" isHome={props.isHome} icon={<PiUploadLight size={props.isHome ? 24 : 32} />} value={cards.ultimaModificacao} color="var(--color-primary-blue)" description={props.isHome ? "Último upload" : undefined}></IconValue>
                    }
                </div>
            }
        }
    ];

    return (
        props.isHome ?
            (
                cards ? <LCDashboard
                    isHomeType grid={[3, 2]}
                    cards={props.qtdGroups && props.qtdGroups > 0 ? cardNovos.map((card, index) => {
                        if (props.qtdGroups) {
                            var posi = positionHome(card.id, props.qtdGroups);
                            if (posi !== undefined) {
                                card.position = posi;
                            }
                        }
                        return card;
                    }) : []} /> : null
            ) :
            (
                <Layout pageTitle="Retenção" lastUpdate={lastUpdate}>
                    <LcLoading loading={isLoading} label="Carregando cards...">
                        {!errorCards && cards ?
                            <LCDashboard cards={cardNovos}
                                rightSidePanel={{
                                    title: 'Filtros',
                                    pinned: false,
                                    show: false,
                                    content: () => {
                                        return <div style={{ gridRowEnd: 'span 12', gridColumnEnd: 'span 12' }}>

                                        </div>;
                                    }
                                }} /> :
                            <LcNoData />
                        }
                        <>
                            {isDataCountOk === false &&
                                <LcNoData label="As informações ainda estão sendo atualizadas e isto pode levar alguns minutos, portanto, podem existir divergências nos valores apresentados." />
                            }

                        </>
                    </LcLoading>
                </Layout>
            )
    );
};

export default RetencaoResumo;