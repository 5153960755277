import React, { useEffect, useState } from 'react';
import LcLoading from '../../../../components/Generic/LcLoading';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import LcMobileStepper from '../../../../components/Form/LcMobileStepper';
import AutomationsService from '../../../../services/automations/automationsService';
import { AutomationsScheduler } from '../../../../services/automations/automationsModel';
import { PiCalendarBlank, PiCalendarBlankLight } from 'react-icons/pi';

interface NextScheduleAutomationProps {
    isHome?: boolean;
}

const NextScheduleAutomation: React.FC<NextScheduleAutomationProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const automationSvc = new AutomationsService(props);
    const [loading, setLoading] = useState<boolean>(false);
    const [nextSchedules, setNextSchedules] = useState<AutomationsScheduler[]>()

    const getNextSchedule = async () => {
        try {
            setLoading(true)
            const response = await automationSvc.GetAutomations();
            let schedules = findNextSchedulers(response);

            if (schedules)
                setNextSchedules(schedules.slice(0, 3));

            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
            setLoading(false)
        }
    }

    useEffect(() => { getNextSchedule() }, [user.ClientGroupSelected, user.refreshFlag])

    function findNextSchedulers(automations: AutomationsScheduler[]) {
        const now = moment();
        const daysMap = { 'SUN': 0, 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6 };
        const diasDaSemanaPtBr = {
            dom: "Domingo",
            seg: "Segunda-feira",
            ter: "Terça-feira",
            qua: "Quarta-feira",
            qui: "Quinta-feira",
            sex: "Sexta-feira",
            sab: "Sábado"
        };

        const nextSchedule = (automation) => {
            const weekDays = automation.weekDaysScheduled.split('-').map(day => daysMap[day]);
            const scheduledTime = moment(automation.hourScheduled, 'HH:mm:ss');

            for (let i = 0; i <= 7; i++) {
                const day = (now.day() + i) % 7;
                if (weekDays.includes(day)) {
                    const nextDateTime = now.clone().add(i, 'days').set({
                        hour: scheduledTime.hour(),
                        minute: scheduledTime.minute(),
                        second: scheduledTime.second()
                    });
                    if (nextDateTime.isAfter(now)) {
                        return nextDateTime;
                    }
                }
            }
        }

        var nextAutomations = automations
            .map(automation => ({ ...automation, nextSchedule: nextSchedule(automation), translatedName: '' }))
            .sort((a, b) => (a.nextSchedule as any) - (b.nextSchedule as any));

        var automationTemplatesDicTrimmed = {};
        Object.keys(automationSvc.automationTemplatesDic).map(atd =>
            automationTemplatesDicTrimmed[atd.replace(/\s/g, '')] = automationSvc.automationTemplatesDic[atd]
        )

        if (nextAutomations.length > 0)
            nextAutomations.forEach(na => {
                let day = diasDaSemanaPtBr[na.nextSchedule?.format('ddd')!];
                na.nextSchedule = `${day} às ${na.nextSchedule?.format('HH')}h${na.nextSchedule?.format('mm')}` as any
                na.translatedName = automationSvc.automationDataDic[JSON.parse(na.data).Automation]
            });

        return nextAutomations;
    }

    return (
        <LcLoading loading={loading}>
            {
                nextSchedules && nextSchedules.length > 0 ?
                    <div className='card-big-number colorfull'>
                        <div>
                            {props.isHome && <div className='title-schedule'>Próximo agendamento</div>}
                            <div style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: props.isHome ? 'flex-start' : 'flex-end', overflowX: 'visible' }} className='subtitle-dash ellipsis' >
                                {!props.isHome && <PiCalendarBlankLight style={{ marginBottom: '5vh', }} size={32} />}
                                <LcMobileStepper steps={nextSchedules.map((ns, nsi) => {
                                    return {
                                        content: <div key={nsi} style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: props.isHome ? 'start' : 'end',
                                            height: props.isHome ? '80px' : '70px',
                                            width: '100%',
                                            margin: props.isHome ? '0' : '0 0.1rem 0 0rem',
                                        }}>
                                            <div title="Recurso envolvido" className={props.isHome ? 'automation-resource-home' : 'automation-resource'}>
                                                {JSON.parse(ns.data).ResourceId}
                                            </div>
                                            <div title="Nome da automação" className={props.isHome ? 'automation-name-home' : 'automation-name'} style={{ padding: '7px 0' }}>
                                                {ns['translatedName']}
                                            </div>
                                            <div title="Próxima execução" className={'automation-schedule'} style={{ color: props.isHome ? 'var(--text-light)' : '#fff' }}>
                                                {ns['nextSchedule']}
                                            </div>
                                        </div>

                                    }
                                })} autostep={15} hideSteppers dotsLeftMargin={props.isHome ? 0 : -36} dotsSize={props.isHome ? 'small' : 'large'} />
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ color: props.isHome ? 'var(--text-default)' : '#fff', fontSize: '.7rem' }}>
                        {props.isHome && <div className='title-schedule'>Próximo agendamento</div>}
                        Sem dados
                    </div>
            }
        </LcLoading>
    )
}

export default NextScheduleAutomation;