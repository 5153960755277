import axios from "axios";
import api, { APIClient } from "../api";

export class CustomerIndicatorSummaryService extends APIClient {

    constructor(props:any) {
        super(props);
    }

    formatTime(mes: string, typeValue: string) {

        mes = mes + '';
        typeValue = typeValue + '';

        if ((mes === '')) return '';

        var value = parseFloat(mes).toFixed(0);

        if (typeValue.toUpperCase() === 'FALSE') {
            if(value.includes("aN")){
                return '-'
            }
            switch (value.length) {
                case 1:
                    return "00:00:0" + value.substring(0);
                    break;
                case 2:
                    return "00:00:" + value;
                    break;
                case 3:
                    return "00:0" + value.substring(0, 1) + ":" + value.substring(1, 3);
                    break;
                case 4:
                    return "00:" + value.substring(0, 2) + ":" + value.substring(2, 4);
                    break;
                case 5:
                    return "0" + value.substring(0, 1) + ":" + value.substring(1, 3) + ":" + value.substring(3, 5);
                    break;
                case 6:
                    return value.substring(0, 2) + ":" + value.substring(2, 4) + ":" + value.substring(4, 6);
                    break;
                default:
                    return (0).toFixed(2);
            }
        }
        else {
            return ( parseFloat(mes).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 2}) === 'NaN' ) ? '-' : parseFloat(mes).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 2,minimumFractionDigits: 2});
            // var result = mes.replace(',', '.');
            // return (parseFloat(result).toFixed(2) === 'NaN') ? '-' : parseFloat(result).toFixed(2);
        }
    }

    GetSubItems(item: any) {
      return [
        { id: 1, month: 'Janeiro', value: item.january},
        { id: 2, month: 'Fevereiro', value: item.february},
        { id: 3, month: 'Março', value: item.march},
        { id: 4, month: 'Abril', value: item.april},
        { id: 5, month: 'Maio', value: item.may},
        { id: 6, month: 'Junho', value: item.june},
        { id: 7, month: 'Julho', value: item.july},
        { id: 8, month: 'Agosto', value: item.august},
        { id: 9, month: 'Setembro', value: item.september},
        { id: 10, month: 'Outubro', value: item.october},
        { id: 11, month: 'Novembro', value: item.november},
        { id: 12, month: 'Dezembro', value: item.december}
      ]
    }

    SetAverage(item: any) {
        let monthCount = 0;
        let monthSum = 0;
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        let typeValue = item.typeValue + '';
        let months = ['april', 'august', 'december', 'february', 'january', 'july', 'june', 'march', 'may', 'november', 'october', 'september'];
        months.forEach((month: any) => {
            for(var propertyName in item) {
                if(propertyName === month && item[propertyName] != null && item[propertyName] != '-'){
                    monthCount++;
                    monthSum += parseFloat(item[propertyName]);
                    if (typeValue.toUpperCase() === 'FALSE') {
                        let value = parseFloat(item[propertyName]).toFixed(0).padStart(6,"0");
                        hours += parseFloat(value.substring(0,2))
                        minutes += parseFloat(value.substring(2,4))
                        seconds += parseFloat(value.substring(4,6))
                    }
                }
             }
        });
        if (typeValue.toUpperCase() === 'FALSE') {
            seconds += ((hours * 60) * 60) + (minutes * 60); 
        }
        let result = {
            monthCount: monthCount,
            monthSum: (typeValue.toUpperCase() === 'FALSE') ?  seconds : monthSum
        };
         return result;
    }

    async GetIndicadores(year: string, month: string, tipo: string) {

        const url = this.endpoint + `/api/ResumoIndicadorCliente/RequestGetDashDados/${year}/${month}/${tipo}`;
        return api({
            method: 'get',
            url,     
        }).then((response) => {
            response.data.forEach((item: any, index: number) => {
                item.id = ++index;
                let av: any = {};
                if(item.typeValue.toString() === 'false')
                {
                    av = this.SetAverage(item);
                }
                item.goal = this.formatTime(item.goal, item.typeValue);
                item.january = this.formatTime(item.january, item.typeValue);
                item.february = this.formatTime(item.february, item.typeValue);
                item.march = this.formatTime(item.march, item.typeValue);
                item.april = this.formatTime(item.april, item.typeValue);
                item.may = this.formatTime(item.may, item.typeValue);
                item.june = this.formatTime(item.june, item.typeValue);
                item.july = this.formatTime(item.july, item.typeValue);
                item.august = this.formatTime(item.august, item.typeValue);
                item.september = this.formatTime(item.september, item.typeValue);
                item.october = this.formatTime(item.october, item.typeValue);
                item.november = this.formatTime(item.november, item.typeValue);
                item.december = this.formatTime(item.december, item.typeValue);
                item.subitems = this.GetSubItems(item);
                if(item.typeValue.toString() != 'false')
                {
                    av = this.SetAverage(item);
                }
                item.average = (av.monthSum / av.monthCount).toFixed(2);
                if(item.typeValue.toString() === 'false')
                {
                    let average = parseFloat(item.average);
                    let seconds = (average >= 60) ? (average%60) : average; 
                    let minutes = (average >= 60) ? (average/60) : 0; 
                    minutes = (minutes >= 60) ? (minutes%60) : minutes;
                    let hours = (minutes >= 60) ? (minutes/60) : 0;
                    item.average = parseFloat(hours.toFixed(0).padStart(2,"0") + minutes.toFixed(0).padStart(2,"0") + seconds.toFixed(0).padStart(2,"0")).toFixed(2)
                    item.average = this.formatTime(item.average,  item.typeValue);
                }
            });
            return response.data;
        });

    }

}