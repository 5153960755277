import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import './index.css'

// Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcLoading from '../../../components/Generic/LcLoading';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcNoData from '../../../components/Generic/LcNoData';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { PiBroomLight, PiCheckLight, PiDatabase, PiDatabaseLight, PiDevicesBold, PiDevicesLight, PiHardDrive, PiHardDrivesLight, PiLightning, PiLightningBold, PiShareNetworkLight, PiShield, PiShieldLight } from 'react-icons/pi';
import AIxChatComponent from './AIxChatComponent';
import IncidentsAccountsByMonth from './IncidentsAccountsByMonth';
import HostCardList from './HostCardList';
import moment from 'moment';
import LiveWatchInsightService from '../../../services/LiveWatchInsightService/LiveWatchInsightService';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import HostCardPivot from './HostCardPivot';
import { TbBrandDocker } from "react-icons/tb";
import { HiOutlineServer } from "react-icons/hi";
import { PiShareNetwork } from "react-icons/pi";
import { IoCloudDoneOutline } from "react-icons/io5";
import { SiNginxproxymanager } from "react-icons/si";
import { ImCheckmark2 } from "react-icons/im";
import LcIconLink from '../../../components/Generic/LcIconLink';
import MessageService from '../../../services/AIx/messages/MessageService';

interface Props {
    isHome: boolean;
    isSecOpsPage?: boolean
    qtdGroups?: number;
    secOpsQtdGroups?: number;
}
const LiveWatchInsights: React.FC<Props> = ({ isHome, qtdGroups, secOpsQtdGroups, isSecOpsPage }) => {
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPivotTools, setShowPivotTools] = useState<boolean>(false);
    const [dashData, setDashData] = useState<any>();
    const [incidentsData, setIncidentsData] = useState<Record<string, number>>({});
    const [messageLLMAIx, setMessageLLMAIx] = useState<string>("");
    const messageService = new MessageService({});
    const [assistantid, setAssistantid] = useState<string>("");
    const [monthPeak, setMonthPeak] = useState<number>(Number(moment().month()));
    const [flattenedIncidents, setFlattenedIncidents] = useState<any[]>([]);
    const [AIXAgentVisible, setAIXAgentVisible] = useState(true);
    const dashs = [
        { id: '6a5117dc-e405-4451-a862-96e62dcd62c4', name: 'Ativos de Software' }
    ];

    const [dashSelected, setDashSelected] = useState<{ id: string, name: string }>(dashs[0]);

    const positions: { group: number; cards: Card[] }[] = [
        {
            group: 2,
            cards: [
                {
                    id: 1,
                    position: { ColSpan: 1, RowSpan: 1, row:4, col: 1, hidden: false },
                },
                {
                    id: 2,
                    position: { ColSpan: 1, RowSpan: 1, row:4, col: 2, hidden: false },
                },
                {
                    id: 3,
                    position: { ColSpan: 1, RowSpan: 1, row: 5, col: 1, hidden: false },
                },
                {
                    id: 4,
                    position: { ColSpan: 1, RowSpan: 1, row: 5, col: 2, hidden: false },
                },
                {
                    id: 5,
                    position: { ColSpan: 1, RowSpan: 1, row: 6, col: 1, hidden: false },
                },
                {
                    id: 6,
                    position: { ColSpan: 1, RowSpan: 1, row: 6, col: 2, hidden: false },
                },
                {
                    id: 7,
                    position: { ColSpan: 2, RowSpan: 1, row: 1, col: 1, hidden: secOpsQtdGroups && secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 8,
                    position: { ColSpan: 2, RowSpan: 1, row: 2, col: 1, hidden: secOpsQtdGroups && secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 9,
                    position: { ColSpan: 2, RowSpan: 1, row: 3, col: 1, hidden: secOpsQtdGroups && secOpsQtdGroups > 1 ? true : false },
                },
            ],
        },
        {
            group: 6,
            cards: [
                {
                    id: 1,
                    position: { ColSpan: 1, RowSpan: 1, row: 4, col: 1, hidden: false },
                },
                {
                    id: 2,
                    position: { ColSpan: 1, RowSpan: 1, row: 4, col: 2, hidden: false },
                },
                {
                    id: 3,
                    position: { ColSpan: 1, RowSpan: 1, row: 5, col: 1, hidden: false },
                },
                {
                    id: 4,
                    position: { ColSpan: 1, RowSpan: 1, row: 5, col: 2, hidden: false },
                },
                {
                    id: 5,
                    position: { ColSpan: 1, RowSpan: 1, row: 6, col: 1, hidden: false },
                },
                {
                    id: 6,
                    position: { ColSpan: 1, RowSpan: 1, row: 6, col: 2, hidden: false },
                },
                {
                    id: 7,
                    position: { ColSpan: 2, RowSpan: 1, row: 1, col: 1, hidden: isHome },
                },
                {
                    id: 8,
                    position: { ColSpan: 2, RowSpan: 1, row: 2, col: 1, hidden: isHome },
                },
                {
                    id: 9,
                    position: { ColSpan: 2, RowSpan: 1, row: 3, col: 1, hidden: isHome },
                },
            ],
        },
    ];

    function positionHome(cardId: number | undefined, qtdGroups: number) {
        if (cardId === undefined) return undefined;
        const groupData = positions.find((group) => group.group >= qtdGroups);
        if (groupData) {
            const card = groupData.cards.find((c) => c.id === cardId);
            if (card) {
                return card.position;
            }
        }
        return undefined;
    }

    const getLiveWatchData = async () => {
        setLoading(true);
        try {
            const [summary, incidents] = await Promise.all([
                LiveWatchInsightService.getHostSummary(),
                LiveWatchInsightService.getIncidents(30, [1, 2, 3, 4, 5])
            ]);
            setDashData(summary);
            const transformedData = transformIncidentsData(incidents);
            setIncidentsData(transformedData.incidentCounts);
            setFlattenedIncidents(transformedData.flattenedIncidents);
        } catch (error) {
            console.error("Failed to fetch LiveWatch data:", error);
        } finally {
            setLoading(false);
        }
    };

    const translateSeverity = (severity: string | number): string => {
        const severityMap: { [key: number]: string } = {
            1: 'Crítico',
            2: 'Alto',
            3: 'Médio',
            4: 'Baixo',
            5: 'Informativo'
        };
        return severityMap[+severity] || 'Desconhecido';
    };

    const columns = [
        { field: 'hostname', headerName: 'Host', width: 600 },
        {
            field: 'severity',
            headerName: 'Severidade',
            renderCell: (row) => translateSeverity(row.severity), width: 100
        },
        { field: 'acknowledged', headerName: 'Informações', width: 300 },
        { field: 'problemDescription', headerName: 'Descrição', width: 300 }
    ];

    const transformIncidentsData = (incidents: Record<string, any[]>): { incidentCounts: Record<string, number>, flattenedIncidents: any[] } => {
        const incidentCounts: Record<string, number> = {};
        const flattenedIncidents: any[] = [];

        for (const [subgroup, incidentsList] of Object.entries(incidents)) {
            incidentCounts[subgroup] = incidentsList.length;
            incidentsList.forEach(incident => {
                flattenedIncidents.push({ ...incident, subgroup });
            });
        }

        return { incidentCounts, flattenedIncidents };
    };

    const getAssitantData = async () => {
        setAssistantid("");
        if (user && user.ClientGroupSelected) {
            var assistantid = await messageService.getAssistantId("4", user.ClientGroupSelected?.toString(), "1");

            setAssistantid(assistantid);
            console.log("fast_assistant_Inquery");
            var Message = await messageService.fast_assistant_Inquery(assistantid, "Faça uma recomendação de prioridade baseado nos arquivos anexados, no maximo 3 linhas, remove citation from result text");
            console.log(Message);
            console.log(Message.messages);
            console.log(Message.messages);
            Message && Message.messages && Message.messages.length > 0 && Message.messages[0].content && Message.messages[0].content.length > 0 && Message.messages[0].content[0].text && Message.messages[0].content[0].text.value && setMessageLLMAIx(Message.messages[0].content[0].text.value)
        }
    }

    useEffect(() => {
        getLiveWatchData();
        getAssitantData();
    }, [user.refreshFlag, user.ClientGroupSelected,]);

    const cards: Card[] = [
        {
            id: 1,
            type: 'Custom',
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: true,
            title: () => "Hosts",
            customContentRender: (focused?: boolean) => {
                if (focused) {
                    setShowPivotTools(focused);
                    return <HostCardPivot hostId={1} />;
                } else {
                    return (
                        <LcLoading loading={loading}>
                            {dashData?.hosts ? (
                                <IconValue
                                    title='Monitorados/Total'
                                    icon={<PiDevicesLight size={32} />}
                                    value={`${dashData?.hosts.enabled}/${dashData?.hosts.disabled + dashData?.hosts.enabled}`}
                                    color='#2E1956'
                                />
                            ) : (
                                <LcNoData size={qtdGroups && qtdGroups >= 4 ? 'xsmall' : 'small'} />
                            )}
                        </LcLoading>
                    );
                }
            }
        },
        {
            id: 3,
            type: 'Custom',
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: false,
            title: () =>
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Disponibilidade itens monitorado</div>
                </div>,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loading}>
                        {dashData?.hosts ? (
                            <IconValue
                                icon={<span style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    backgroundColor: ((dashData?.hosts.enabled * 100) / dashData?.hosts.total > 75) ? 'green' : 'red',
                                    marginLeft: '10px'
                                }}></span>}
                                value={((dashData?.hosts.enabled * 100) / dashData?.hosts.total).toFixed(2) + '%'}
                                color='#2E1956'
                            />
                        ) : (
                            <LcNoData size={qtdGroups && qtdGroups >= 4 ? 'xsmall' : 'small'} />
                        )}
                    </LcLoading>
                );
            }
        },
        {
            id: 4,
            type: 'Custom',
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 9 },
            title: () => <div className="lc-segment-title-row-resume">
                <div className="lc-title ellipsis">IA - Recomendação de prioridade</div>
            </div>,
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        <div className='row '>
                            <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px' }}></i>
                            <p style={{ wordBreak: 'break-word' }} className='ml-3'>
                                {messageLLMAIx}
                            </p>
                        </div>
                    </LcLoading>
                );
            }
        },
        //{
        //    id: 5,
        //    type: 'Custom',
        //    position: { RowSpan: 4, ColSpan: 4, row: 1, col: 9 }, showFocusButton: true,
        //    title: () =>
        //        <div className="lc-segment-title-row-resume">
        //            <div className="lc-title ellipsis">Pergunte ao LiveWatch</div>
        //        </div>,
        //    customContentRender: (focused?: boolean, handleFocusClick?: Function) =>
        //        <LcLoading loading={loading}>
        //            {dashData?.hosts ? (
        //                <ChatComponentLiveWatch eventChat={(_event) => { handleFocusClick && handleFocusClick(); }} assistantId={"asst_P7mYpWk0U2lc0nbLaJ7bWpJV"} assistantName={"LiveWatch"} />
        //            ) : (
        //                <LcNoData />
        //            )}
        //        </LcLoading>
        //},
        {
            id: 6,
            type: 'Custom',
            position: { RowSpan: 3, ColSpan: 12, row: 2, col: 1 },
            title: () => `Incidentes por tecnologia nos últimos 7 dias`,
            showFocusButton: true,
            customContentRender: (focused?: boolean, handleFocusClick?: Function) => {
                return <> {!focused ? <LcLoading loading={loading}>
                    {dashData ? (
                        <IncidentsAccountsByMonth
                            zoomCard={(category) => { handleFocusClick && handleFocusClick(); }}
                            data={incidentsData}
                            showInfo={false}
                            setMonth={setMonthPeak}
                        />
                    ) : (
                        <LcNoData />
                    )}
                </LcLoading>
                    : <LcInfiniteTable
                        rows={flattenedIncidents}
                        columns={columns}
                        loading={loading}
                        loadMore={() => { }}
                        size={Object.values(flattenedIncidents).flat().length}
                    />
                }</>
            }
        }
    ];

    const cardSecops = [
        {
            id: 1,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 1, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let storageCounts =
                    dashData && dashData.subgroups
                        ? dashData.subgroups.find((sub) =>
                            sub.subgroup.includes("BANCO DE DADOS")
                        )?.count
                        : 0;
                let RDSCount =
                    dashData && dashData.subgroups
                        ? dashData.subgroups.find((sub) => sub.subgroup.includes("RDS"))
                            ?.count
                        : 0;
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiDatabaseLight size={"1.2rem"} />}
                            value={(storageCounts + RDSCount)}
                            color={"#2E1956"}
                            description={"RDS e Banco de dados"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 2,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 1, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let ECSCount = dashData && dashData.subgroups ?
                    dashData.subgroups.find((sub) => sub.subgroup.includes("ECS"))?.count
                    : 0
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<TbBrandDocker size={"1.2rem"} />}
                            value={ECSCount}
                            color={"#2E1956"}
                            description={"ECS"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 3,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 2, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let HostingCount = dashData && dashData.subgroups ?
                    dashData.subgroups.find((sub) => sub.subgroup.includes("HOSTING"))?.count
                    : 0
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiHardDrivesLight size={"1.2rem"} />}
                            value={HostingCount}
                            color={"#2E1956"}
                            description={"Hosting"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 4,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 2, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let redeCount = dashData && dashData.subgroups ?
                    dashData.subgroups.find((sub) => sub.subgroup.includes("REDE"))?.count
                    : 0
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiShareNetworkLight size={"1.2rem"} />}
                            value={redeCount}
                            color={"#2E1956"}
                            description={"Rede"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 5,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 3, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let edrCount = dashData && dashData.subgroups ?
                    dashData.subgroups.find((sub) => sub.subgroup.includes("EDR"))?.count
                    : 0
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiShieldLight size={"1.2rem"} />}
                            value={edrCount}
                            color={"#2E1956"}
                            description={"EDR"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 6,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            position: { row: 3, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                let proxyCount = dashData && dashData.subgroups ?
                    dashData.subgroups.find((sub) => sub.subgroup.includes("PROXY"))?.count
                    : 0
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<SiNginxproxymanager size={"1.2rem"} />}
                            value={proxyCount}
                            color={"#2E1956"}
                            description={"Proxy"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 7,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",

            position: { row: 1, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiDevicesLight size={32} />}
                            value={dashData?.hosts ? dashData.hosts.enabled : 0}
                            color={"#2E1956"}
                            description={"Hosts monitorados"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 8,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            type: "Custom",
            cssClass: 'fix-card-content',
            position: { row: 3, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiDevicesLight size={32} />}
                            value={dashData?.hosts ? dashData.hosts.disabled : 0}
                            color={"#d3281d"}
                            description={"Hosts não monitorados"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
        {
            id: 9,
            hideHeader: true,
            bgColor: "rgb(241, 245, 251)",
            fontColor: "#FFFFFF",
            cssClass: 'fix-card-content',
            type: "Custom",
            position: { row: 1, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        <IconValue
                            icon={<PiCheckLight size={32} />}
                            value={dashData?.hosts ? `${(
                                (dashData.hosts.enabled * 100) /
                                dashData.hosts.total
                            ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%` : 0}
                            color={"#2ca02c"}
                            description={"Disponibilidade de negócio"}
                            isHome={isHome}
                            smalLcNoData={qtdGroups && qtdGroups >= 5 ? true : false}
                        />
                    </LcLoading>
                );
            },
        },
    ];

    return (isHome || isSecOpsPage) ? (
        <LCDashboard
            isHomeType
            cssClass={secOpsQtdGroups === 1 ? '' : 'LiveWatchHome'}
            grid={isHome ? [3, 2] : [4, 2]}
            cards={
                secOpsQtdGroups && secOpsQtdGroups > 0
                    ? cardSecops.map((card, index) => {
                        if (secOpsQtdGroups) {
                            var posi = positionHome(card.id, secOpsQtdGroups);
                            if (posi !== undefined) {
                                card.position = posi;
                            }
                        }
                        return card;
                    })
                    : []
            }
        />
    ) : (<Layout
        pageTitle="LiveWatch Insights"
        pivot={showPivotTools}
        functionsGeneric={assistantid && assistantid.length > 0 ? [{
            icon: <LcIconLink icon='ppi ppi-lightning-bolt'
                id='aiLCChamados'
                size='small'
                degrade
                onClick={() => { }}
                tooltip={"LiveWatch AI"}
                tooltipPosition='right' />,
            onClick: () => {
                setAIXAgentVisible(!AIXAgentVisible)
            }, tooltip: `LiveWatch AI`
        }] : []}
    >  <LCDashboard rightSidePanel={assistantid && assistantid.length > 0 ? {
        title: 'LiveCloud AI',
        titleClass: 'ai-gradient-text',
        pinned: true,
        hiddenPin: true,
        show: AIXAgentVisible,
        focusable: true,
        content: () => {
            return <>
                <LcLoading loading={loading}>
                    <div style={{ alignSelf: 'center' }}>
                        {dashData?.hosts ? (
                            <AIxChatComponent eventChat={(_event) => { }} assistantId={assistantid} assistantName={"LiveWatch"} />
                        ) : (
                            <LcNoData />
                        )}
                    </div>
                </LcLoading>
            </>;
        },
    } : undefined} cards={cards} />
    </Layout>);
};

export default LiveWatchInsights;
