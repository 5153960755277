import api, { APIClient } from "../api";
//vinSubscriptionToEA
export default class EASubscriptionService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetContractLicences() {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/GetContractLicences`;
        return api({
            method: 'get',
            url,
        })
    }

    async GetOfficeSubscriptions() {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/GetOfficeSubscriptions`;
        return api({
            method: 'get',
            url,
        })
    }

    async GetVinSubscriptions() {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/GetVinSubscriptions`;
        return api({
            method: 'get',
            url,
        })
    }

    async Update(EatoSubScription: any) {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/Update`;
        const data = JSON.stringify(EatoSubScription)
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        })
    }


    async CreateLink(EatoSubScriptionList: any) {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/Insert`;
        const data = JSON.stringify(EatoSubScriptionList)
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        })
    }

    async Delete(EatoSubScription: any) {
        const url = `${this.endpoint}/api/VinSubscriptionToEA/Delete`;
        const data = JSON.stringify(EatoSubScription)
        return api({
            method: 'delete',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        })
    }

    async GetLastUpdateProvider(){
        const url = `${this.endpoint}/api/VinSubscriptionToEA/GetLastUpdate`;
        return api({
            method: 'get',
            url,
        })
    }
}

