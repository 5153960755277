
export default class LanguageToolsService {

    RemoveAccents(input: string) {
        let _input = input.toLowerCase();
        _input = _input.replace(/[àáâãäå]/, "a");
        _input = _input.replace(/[èéêë]/, "e");
        _input = _input.replace(/[ìíîï]/, "i");
        _input = _input.replace(/[òóôõ]/, "o");
        _input = _input.replace(/[ùúûü]/, "u");
        _input = _input.replace(/[ñ]/, "n");
        _input = _input.replace(/[ç]/, "c");
        return _input;
    }
}
