export class cloudmanagedservicescorporate {
    solution() {
        return 'cloudmanagedservicescorporate'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-corporate'
    }

    logotipo() {
        return require('./assets/logotipos/logo_cloudmanagedservicescorporate.svg').default
    }
    titulo() {
        return <><b>Maximize a eficiência da organização</b> com Cloud Managed Services Corporate</>
    }

    chamada() {
        return <>
            Cuidamos de toda a nuvem da organização, oferecendo gestão profissional dos recursos, tecnologias, implementação ágil, simplificada e escalável, além de atualização constante do sistema, monitoramento e suporte dos serviços contratados.
        </>
    }

    image() {
        return require('./assets/imgs/imagem_cloudmanagedservicescorporate.png').default
    }

    detalhes() {
        return <>
            <h2><b>LiveCloud Cloud Managed Services Corporate</b> oferece infraestrutura e soluções como serviço em nuvem totalmente gerenciados e operados pelo nosso time de especialistas</h2>
            <h3>Por que escolher LiveCloud Cloud Managed Services Corporate?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_cloudmanagedservicescorporate.png').default} />
                </div>
                <ul>
                    <li><b>Gestão profissional e segurança:</b> Nosso serviço oferece gestão profissional dos recursos, tecnologias e processos em nuvem. Além disso, amplia a confidencialidade e integridade de dados e informações por meio de robustos processos de segurança.</li>
                    <li><b>Agilidade e simplificação:</b> Realizamos a implementação de forma ágil, simplificada e escalável, permitindo que as organizações se concentrem no crescimento do negócio.</li>
                    <li><b>Monitoramento e suporte:</b> Oferecemos monitoramento e suporte 24 x 7 dos serviços contratados, com alta disponibilidade e performance dos mesmos.</li>
                    <li><b>Expansão do negócio:</b> Nosso serviço facilita a expansão do negócio, oferecendo escalabilidade e flexibilidade.</li>
                    <li><b>Otimização de custos:</b> Com LiveCloud Cloud Managed Services Corporate, a organização pode otimizar custos com players de tecnologia e infraestrutura.</li>
                    <li><b>Atualização constante:</b> Mantemos os sistemas e tecnologias constantemente atualizados, para a organizar estar sempre à frente.</li>
                </ul>
            </div>
            <div className="box">LiveCloud Managed Services Corporate é uma <b>solução completa de hospedagem em nuvem</b>, com operação do ambiente e ações de administração proativas garantindo os níveis de serviços, disponibilidade, performance e qualidade contratada. Além disso, realizamos testes periódicos, atualizações do ambiente, manutenções e execução de atividades programadas pelo nosso time de operação.</div>
            <h3>Interface gerencial única</h3>
            <p>A LiveCloud oferece uma interface gerencial única, permitindo o acompanhamento dos serviços contratados pela organização. Essa interface pode disponibilizar painéis de administração, níveis de serviço, visões gerenciais de monitoramento, disponibilidade dos serviços, avaliação e predição de performance e consumo, solicitação de serviços, abertura de chamados e logs das principais atividades, além da evolução e demais alterações do ambiente.</p>
            <h3>Matriz de serviços</h3>
            <p>Com LiveCloud Cloud Managed Services Corporate, você tem acesso a uma matriz de serviços que inclui monitoramento (LiveWatch), operação contratada, 10 horas por mês de Professional Services, opções para FinOps, reengenharia anual de arquitetura e Segurança EDR.</p>
            <h3>Níveis de serviço</h3>
            <p>Oferecemos um SLA de início de 45 minutos e um SLO de fim de 4 horas, proporcionando a organização a tranquilidade de que os serviços estarão disponíveis quando preciso.</p>
            <h3>Não perca mais tempo</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como LiveCloud Cloud Managed Services Corporate pode transformar os negócios.</p>            
        </>
    }
}
export default cloudmanagedservicescorporate;