import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PrefState } from '../../store/reducers/prefReducer';

const TabPref: React.FC = () => {

    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    //Preferências da página
    const handlePreferenceChange = (event: any) => {
        const { name, value } = event.target;
        switch (name) {
            case "idade":
                dispatch({ type: 'PREF_UPDATE', payload: { name, value: Number(value) } })
                break;
            default:
                dispatch({ type: 'PREF_UPDATE', payload: { name, value } })
                break;
        }

    }

    return (<>
        <h1>Preferências</h1>

        <div>
            <span>Cor </span>
            <input type="text" name="cor" value={pref.data?.cor || ''} onChange={handlePreferenceChange} />
        </div>
        <div>
            <span>Idade </span>
            <input type="number" name="idade" value={pref.data?.idade || 0} onChange={handlePreferenceChange} />
        </div>
        <div>
            <span>Bairro </span>
            <input type="text" name="bairro" value={pref.data?.bairro || ''} onChange={handlePreferenceChange} />
        </div>
    </>);
}

export default TabPref;