import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
 
 

interface Props {
    data: any[];
    onSetFilter?: Function;
    aggregatorName?: string;
    vals?: string[];
    cols?: string[];
    rows?: string[];
    hiddenAttributes?: string[];
}
//        .pvtRenderers{
//    display: none;
//}
//.pvtVals{
//    display: none;
//}
class LCPivotTable extends Component<Props>  {
    data = [];
    constructor(props) {
        super(props);
        this.data = props.data;
        this.state = {};
    }

    updateState(newState) {

        this.props.onSetFilter && this.props.onSetFilter(newState.valueFilter);
        this.setState(newState);
    }

    render() {
        return this.data && this.data.length > 0 && (
          <></>
        );
    }
}
export default LCPivotTable;