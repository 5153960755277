import React from 'react';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { FaEquals } from 'react-icons/fa';

interface CostArrowProps {
    valueChange: 'up' | 'down' | 'same';
}

export const CostArrow: React.FC<CostArrowProps> = ({ valueChange }) => (
    <div className='resize-review-costarrow'>
        {valueChange === 'up' && <MdArrowUpward size={'36px'} color='#5F5DAD' />}
        {valueChange === 'down' && <MdArrowDownward size={'36px'} color='#5F5DAD' />}
        {valueChange === 'same' && <FaEquals size={'36px'} color='#5F5DAD' />}
    </div>
);
