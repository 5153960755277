export class saaspremium {
    solution() {
        return 'saaspremium'
    }

    site() {
        return 'https://www.processor.com.br/saas'
    }

    logotipo() {
        return require('./assets/logotipos/logo_saaspremium.svg').default
    }
    titulo() {
        return <><b>Liberte o potencial</b> da organização com SaaS Premium</>
    }

    chamada() {
        return <>Oferece ativação, implantação, gerenciamento, operação, suporte e monitoramento das principais tecnologias de software do mercado. Tudo isso com a qualidade e confiança da Processor.</>
    }

    image() {
        return require('./assets/imgs/imagem_saaspremium.png').default
    }

    detalhes() {
        return <>
            <h2>Pronto para levar a organização ao próximo nível?</h2>
            <p><b>SaaS Premium pode impulsionar a eficiência operacional, melhorar a colaboração e levar a organização para novas alturas.</b></p>
            <h3>Por que escolher SaaS Premium</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_saaspremium.png').default} />
                </div>
                <ul>
                    <li><b>Flexibilidade:</b> Ativação rápida e flexível com softwares sempre atualizados.</li>
                    <li><b>Economia:</b> A organização paga apenas pelo que usa, sem a necessidade de propriedade do software.</li>
                    <li><b>Produtividade:</b> Redução da dependência de conhecimentos tecnológicos especializados, permitindo que se concentrar no que realmente importa: o sucesso do negócio.</li>
                    <li><b>Suporte especializado:</b> Contamos com uma equipe altamente especializada e certificada, pronta para atender as demandas mais sofisticadas.</li>
                    <li><b>Integração e colaboração:</b> Facilitamos a troca de dados e informações entre diferentes aplicativos e sistemas, promovendo a colaboração entre equipes e departamentos.</li>
                    <li><b>Melhor tomada de decisão:</b> Com nossas soluções, os gestores podem tomar decisões mais informadas e embasadas em dados, levando a decisões mais precisas e bem-sucedidas.</li>
                    <li><b>Eficiência operacional aprimorada:</b> Nossas soluções ajudam a otimizar e automatizar os processos de negócio, resultando em uma maior eficiência operacional.</li>
                    <li><b>Acesso remoto e mobilidade:</b> Com SaaS Premium, os colaboradores podem acessar seus aplicativos e dados a partir de qualquer lugar, a qualquer momento, usando dispositivos conectados à internet.</li>
                    <li><b>Otimização de custos e investimentos iniciais:</b> Com SaaS Premium, a organização pode otimizar os custos iniciais, pagando somente pelo que usar.</li>
                </ul>
            </div>
            <div className="box">SaaS Premium é mais do que uma solução de software - é <b>a chave para desbloquear o verdadeiro potencial da organização</b>.</div>
            <h3>Não perca tempo!</h3>
            <p>Entre em contato conosco hoje mesmo e descubra como SaaS Premium pode transformar a organização.</p>         
        </>
    }
}
export default saaspremium;