import api, { APIClient } from "../api";

export class FileSyncService extends APIClient {
      
    constructor(props:any){
        super(props);
    }
    
    async GetCards(mesAno: string) {
        const url = this.endpoint + `/api/FileSync/GetTilesData?&selectedMesAno=${mesAno}`;
        return api({
            method: 'get',
            url, 
        });
    }

    async GetGroups() {
        const url = this.endpoint + `/api/FileSync/GetMonthlyData`;
        return api({
            method: 'get',
            url,   
        });
    }
    
    async GetChart(mesAno: string) {
        const url = this.endpoint + `/api/FileSync/GetDailyData?selectedMesAno=${mesAno}`;
        return api({
            method: 'get',
            url,   
        });
    }

}