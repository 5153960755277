import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LcTable from '../../../components/Data/LcTable';
import Layout from '../../../components/Layout/Layout';
import { PPIconLink, PPTooltip, PPModal } from 'processor-plataform-ui';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PrefState } from '../../../store/reducers/prefReducer';
import { UserState } from '../../../store/reducers/userReducer';
import FinOpsMapManagerService from '../../../services/finOpsManagerService';
import ReactECharts from 'echarts-for-react';
import './index.css';
import PPExpand from '../../../components/Generic/PPExpand';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PiCheckCircleLight, PiEyeLight, PiWarningCircleLight } from 'react-icons/pi';

const AlertasAceite: React.FC = (props) => {

    const dispatch = useDispatch();
    const pref = useSelector<RootState, PrefState>(state => state.pref);

    const offSet = new Date().getTimezoneOffset();

    const [modalInfos, setModalInfos] = useState<any | undefined>();

    const [modalVisible, setModalVisible] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);

    const [loading, setLoading] = useState(false);

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const finOpsMapManagerService = new FinOpsMapManagerService(props);
    const [notificacoes, setNotificacoes] = useState<any[]>([]);
    const user = useSelector<RootState, UserState>((state) => state.user);
    async function loadData() {
        try {
            var response = await finOpsMapManagerService.LoadProcessedAlert();


            var list = response.alerts;
            var notificationList = list.map(c => {
                var recordResult = {} as any;
                try {
                    recordResult.id = c.id;
                    recordResult.order = recordResult.order;
                    recordResult.alertIcon = 'ppi ppi-bell';
                    recordResult.message = "";
                    recordResult.alertClientGroup = { id: response.clientGroupId, description: 'Processor' };
                    recordResult.alertDescription = c.description;
                    // recordResult.alertDate = c.date ? c.date : new Date().toISOString();
                    recordResult.alertDate = moment().toISOString();

                    recordResult.alertCostCenter = c.costCenters;
                    recordResult.alertContracts = c.contracts;
                    recordResult.alertResources = c.resources;
                    recordResult.alertServices = c.services;
                    recordResult.alertPriorPeriodTotal = c.priorPeriodTotal;//.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                    recordResult.alertPeriodTotal = c.periodTotal;//.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                    recordResult.alertAchieved = c.achieved;
                    recordResult.alertRejected = c.rejected;
                    recordResult.alertSend = c.send;
                    recordResult.alertDifference = c.difference;
                    recordResult.budgetLimit = c.budgetLimit;

                    if (c.conditions && c.conditions.length > 0) {
                        recordResult.alertVariation = (c.cloudCostEffectiveVariation.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                        recordResult.alertCondition = c.conditions[0].condition == 'variacao' ? ' Variação' : c.conditions[0].condition == 'budget' ? 'Orçamento' : 'N/A';
                        recordResult.alertPercentage = (c.conditions[0] ? '' : '') + (c.conditions[0].percentage.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                        recordResult.alertCompatator = (c.conditions[0].comparator == 'mais' ? 'Mais' : 'Menos');
                    }

                    recordResult.alertPeriod = 'Últimos';
                    try {
                        recordResult.alertPeriodValue = c.period.periodValue;
                    } catch (ec) {
                        console.log(ec);
                    }

                    recordResult.alertPeriodUnit = 'dias';
                    recordResult.alertPeriodStartDate = moment(new Date()).subtract(c.period.periodValue + 2, "days");
                    recordResult.alertPeriodEndDate = moment(new Date()).subtract(2, "days");

                    recordResult.alertScope = c.escope;
                    if (c.conditions[0].condition == 'variacao') {
                        recordResult.alertHistory =
                        {
                            history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.total }; }),
                            dataDoAlerta: new Date().toLocaleDateString()
                        };
                    } else if (c.conditions[0].condition == 'budget') {
                        recordResult.alertHistory =
                        {
                            history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.accTotal }; }),
                            dataDoAlerta: new Date().toLocaleDateString()
                        };
                    }

                    recordResult.usersToNotify = c.usersToNotify;

                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
                return recordResult;
            })
            setNotificacoes(notificationList);
        } catch (error) {
            console.warn(error);
            setLoading(false);
            setNotificacoes([]);
        }

    }
    useEffect(() => {
        setLoading(true);

        loadData();

    }, [user.ClientGroupSelected, user.refreshFlag,])

    async function saveSendAlert(id: number) {
        await finOpsMapManagerService.SaveProcessedAlert(id, 1);
        loadData();
    }

    async function saveRejectAlert(id: number) {
        await finOpsMapManagerService.SaveProcessedAlert(id, 2);
        loadData();
    }

    const lineChartOption = (dados: any, max: number = 1, min: number = 1) => {
        return {
            align: 'center',
            xAxis: {
                show: false,
                type: 'category',
                data: dados.map((data: any) => {
                    return data.dia
                })
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [
                {
                    showSymbol: true,
                    symbol: (value, params) => {
                        return params.dataIndex == (dados.length - 1) ? "circle" : "none"
                    },
                    symbolSize: 7,
                    data: dados.map((data: any) => {
                        return data.valor
                    }),
                    type: 'line'
                }
            ],
            visualMap:
            {
                show: false,
                range: max > 0 ? [0, max] : [0, min],
                inRange: {
                    color: ['#93CE07', '#ff2100']
                },
            }
        }
    }


    const columns = [
        {
            field: 'alertIcon', headerName: '', width: '2%',
            renderCell: (row: any) => {
                return <i className={`${row.alertIcon} ppi-4x`}></i>
            }
        },
        { field: 'alertDescription', headerName: 'Alerta', width: '30%' },
        {
            field: 'alertDate', headerName: 'Data / Hora', width: '8%', align: 'right',
            renderCell: (row) => {
                return moment(row.alertDate).format("DD/MM/YYYY")
            }
        },
        {
            field: '', header: '', width: '8%', align: 'center', showOnHover: true, renderCell: (row: any) => {
                return <>
                    <LcIconLink icon={<PiEyeLight/>}
                        tooltip='Ver detalhes'
                        onClick={() => {
                            row.message = "";
                            setModalInfos(row)

                            setModalVisible(true)
                        }} />

                    {(row.alertRejected == false && row.alertSend == false) && <LcIconLink icon={<PiCheckCircleLight/>}
                        tooltip='Aceitar'
                        onClick={() => {
                            saveSendAlert(row.id);
                            row.message = "O envio desse alerta foi aceito, a notificação será enviada nos próximos minutos";
                            row.alertAchieved = true;

                            setModalInfos(row)
                            setModalVisible(true)
                        }
                        } />}

                    {(row.alertRejected == false && row.alertSend == false) && <LcIconLink icon={<PiWarningCircleLight/>}
                        tooltip='Rejeitar'
                        onClick={() => {
                            saveRejectAlert(row.id);
                            row.message = "O envio desse alerta foi rejeitado";
                            row.alertRejected = true;
                            setModalInfos(row)
                            setModalVisible(true)

                        }
                        } />}
                </>

            }
        },
        {
            field: '', headerName: 'Escopo', width: '15%', overflow: 'visible',
            renderCell: (row: any) => {
                let escope = ''
                switch (row.alertScope) {
                    case 'global':
                        escope = `Global`;
                        break;
                    case 'costCenters':
                        escope = row.alertCostCenter.map((item, index) => { return index == (row.alertCostCenter.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    case 'contracts':
                        escope = row.alertContracts.map((item, index) => { return index == (row.alertContracts.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    case 'resources/services':
                        escope = row.alertResources.map((item, index) => { return index == (row.alertResources.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    default:
                        break;
                }

                if (window.outerWidth > 1360 ? escope.length > 30 : escope.length > 40) {
                    return <PPTooltip content={escope} trigger='hover' position='left'>
                        <div style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                            {escope}
                        </div>
                    </PPTooltip >
                } else {
                    return escope;
                }
            }
        },
        {
            field: 'alertHistory', headerName: '', width: '16%', align: 'left', sort: false,
            renderCell: (row: any) => {
                return <ReactECharts showLoading={loading} lazyUpdate={true}
                    style={{ height: "80px", width: '210px' }}
                    option={lineChartOption(row.alertHistory.history, row.budgetLimit, row.alertPriorPeriodTotal)}
                />
            }
        },
        {
            field: 'alertVariation', headerName: 'Variação', width: '10%', align: 'right',
            renderCell: (row) => {
                let variation = Math.round(parseInt(row.alertVariation.split('%')[0]));
                if (variation > 0) {
                    return `+${variation}%`;
                } else {
                    return `${variation}%`;
                }
            }
        },
        {
            field: 'alertVariation', headerName: 'Variação (R$)', width: '10%', align: 'right',
            renderCell: (row) => {
                let variation = row.alertDifference.toFixed(2);
                return `R$ ${Intl.NumberFormat([], {
                    style: 'decimal', currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(variation)}`;
            }
        },
    ]

    const getStatus = (row: any) => {
        return row.alertVariation.includes('+') ? "danger" : "warning";
    }

    return (
        <Layout
            pageTitle='Notificações'
            dots={
                <>
                    <div style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "high" ? " disabled" : ""}`} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "medium" ? " disabled" : ""}`} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "low" ? " disabled" : ""}`} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: notificacoes.length
            }}
            loading={loading}
        >

            <PPModal visible={modalVisible} title={modalInfos != undefined ? modalInfos.alertDescription : ''} onClose={() => { setModalVisible(false); setModalInfos(undefined) }} >
                {modalInfos && modalInfos?.message && <div className='row'>
                    <b>{modalInfos?.message}</b>
                </div>}
                <PPExpand title='Informações do período' expanded={true}>
                    <div className='cont'>

                        <div className='row'>
                            <div className='sub'>Emitido em</div>
                            <b>{moment(modalInfos?.alertDate).format("DD/MM/YYYY")}</b> às <b>{moment(modalInfos?.alertDate).format("HH:mm")}</b>
                        </div>
                        <div className='row'>
                            <div className="sub">Válido para</div>
                            <b>{moment(modalInfos?.alertPeriodStartDate).format("DD/MM/YYYY")}</b> a <b>{moment(modalInfos?.alertPeriodEndDate).format("DD/MM/YYYY")}</b>
                        </div>

                    </div>
                </PPExpand>

                <PPExpand title='Regra de alerta' expanded={true}>
                    <div className='cont'>
                        <div className="row">
                            <div className="sub">Grupo econ.</div>
                            <b>{modalInfos?.alertClientGroup.description}</b>
                        </div>
                        <div className='row'>
                            <div className="sub">Condição</div>
                            <b> {modalInfos?.alertCondition} de {modalInfos?.alertPercentage} para {modalInfos?.alertCompatator}</b>
                        </div>
                        { }
                        <div className='row'>
                            <div className="sub">Período</div>
                            <b>{modalInfos?.alertPeriod} {modalInfos?.alertPeriodValue} {modalInfos?.alertPeriodUnit} </b>
                        </div>

                    </div>

                </PPExpand>

                {modalInfos?.alertScope.includes('resource') &&
                    <PPExpand title='Recursos' expanded={true}>
                        <div className='cont'>
                            {modalInfos.alertResources.map((item, index) => {
                                return <div className='row' key={index}>
                                    <div className='col-md-6'>
                                        {item.label}
                                    </div>
                                </div>
                            })}
                        </div>
                    </PPExpand>
                }

                {modalInfos?.alertScope.includes('resource') &&
                    <PPExpand title='Serviços' expanded={true}>
                        <div className='cont'>
                            {modalInfos.alertServices.map((item, index) => {
                                return <div className='row' key={index}>
                                    <div className='col-md-6'>
                                        {item.label}
                                    </div>
                                </div>
                            })}
                        </div>
                    </PPExpand>
                }

                {modalInfos?.alertScope.includes('costCenter') &&
                    <PPExpand title='Centros de custo' expanded={true}>
                        <div className='cont'>
                            {modalInfos.alertCostCenter.map((item, index) => {
                                return <div className='row' key={index}>
                                    <div className='col-md-6'>
                                        {item.label}
                                    </div>
                                </div>
                            })}
                        </div>
                    </PPExpand>
                }

                {modalInfos?.alertScope.includes('contract') &&
                    <PPExpand title='Contratos' expanded={true}>
                        <div className='cont'>
                            {modalInfos.alertContracts.map((item, index) => {
                                return <div className='row' key={index}>
                                    <div className='col-md-6'>
                                        {item.label}
                                    </div>
                                </div>
                            })}
                        </div>
                    </PPExpand>
                }

                {modalInfos?.usersToNotify && <PPExpand title='Usuários à notificar' expanded={true}>
                    <div className='cont'>
                        {modalInfos.usersToNotify && modalInfos.usersToNotify.length > 0 && modalInfos.usersToNotify.map((item, index) => {
                            return <div className='row' key={index}>
                                <div className='col-md-6'>
                                    {item.label}
                                </div>
                            </div>
                        })}
                    </div>
                </PPExpand>
                }

            </PPModal>
            <LcTable
                data={notificacoes}
                columns={columns}
                density={pref.data?.density || "low"}
                status={getStatus}
                filterFields={[
                    { label: "Alerta", name: "alertDescription", type: "text" }
                ]}
                filterExternal={{
                    visible: filterVisible,
                    onChange: () => setFilterVisible(!filterVisible)
                }}
            />
        </Layout >
    );
}

export default AlertasAceite;
