import { ReactNode } from "react";
export interface BFFMenu {
    id: string
    name: string ;
    idParent: any
    route: string
    isPublic: boolean
    after: string
    icon: string
    url: any
    isShopCart: boolean
    menuOrder: number
    extraData: string
    blocked: boolean
    permitido: boolean
    children: BFFMenu[]
    child: BFFMenu[]
}


export interface MenuState {
    items: BFFMenu[],
    selectedItem: any | null,
    selectedSubItem: any | null,
    alertArea: ReactNode,
    showMenu: boolean,
    showUserMenu: boolean,
    showHelper: boolean
    newRequest: boolean
}

const initialState: MenuState = {
    items: [],
    selectedItem: null,
    selectedSubItem: null,
    alertArea: undefined,
    showMenu: false,
    showUserMenu: false,
    showHelper: false,
    newRequest: false
}


type Action = { type: string, payload: any }

export const menuReducer = (state: MenuState = initialState, action: Action) => {
    switch (action.type) {
        case 'MENU_UPDATE':
            return { ...state, items: action.payload };
        case 'MENU_VISIBLE':
            return { ...state, showMenu: action.payload };
        case 'MENU_SELECTED_UPDATE':
            return { ...state, selectedItem: action.payload };
        case 'MENU_SUBSELECTED_UPDATE':
            return { ...state, selectedSubItem: action.payload };
        case 'MENU_ALERTAREA':
            return { ...state, alertArea: action.payload };
        case 'MENU_USER_VISIBLE':
            return { ...state, showUserMenu: action.payload };
        case 'MENU_HELPER_VISIBLE':
            return { ...state, showHelper: action.payload };
        case 'MENU_NEW_REQUEST':
            return { ...state, newRequest: action.payload };
        default:
            return state;
    }
}