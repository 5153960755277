import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import Iframe from 'react-iframe'
import { Grid, LinearProgress } from '@material-ui/core';
import { CostCenterService } from '../../../services/costCenter/costCenterService';
import LcLoading from '../../../components/Generic/LcLoading';
import moment from 'moment';

//Redux
import { useSelector } from 'react-redux';
import history from '../../../history'
import { UserState, WebPresence } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useParams } from 'react-router-dom';
import styles from './index.module.css';

//SadDogs
import SadDog1 from '../../../assets/images/saddog.svg';
import SadDog2 from '../../../assets/images/saddog2.svg';
import SadDog3 from '../../../assets/images/saddog3.svg';

interface Param {
    type: string;
}

function chooseSadDog(min, max) {
    switch (parseInt(Math.random() * (max - min) + min)) {
        case 1:
            return SadDog1;
            break
        case 2:
            return SadDog2;
            break;
        case 3:
            return SadDog3;
            break;
        default:
            return SadDog1;
    }
}

const CostCenterDashBoard: React.FC = (props) => {
    const { type } = useParams<Param>();
    const [gtmId, setGtmId] = useState<string>(type);
    const webPresenceData = useSelector<RootState, WebPresence>(state => state.webPresenceReducer);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [url, setUrl] = useState<any>();
    const [analitico, setAnalitico] = useState<any>((window.location.pathname.toLowerCase().indexOf("analitico") >= 0));
    const [loading, setLoading] = useState<boolean>(true);
    const [canSee, setCanSee] = useState<boolean>(true);

    useEffect(() => {
        loadData()
    }, [user.ClientGroupSelected, user.refreshFlag, analitico]);

    async function checkContractsAndCostCenters() {
        const costCenterService = new CostCenterService(props);
        var costCenters = 0;// = 
        var contracts = 0;// = 
        var canSeeLocal = true;
        try {
            var responsecostCenter = await costCenterService.RetrieveListByClientGroupAndUserId();
            costCenters = responsecostCenter.data.length
        } catch (e) {
        }

        try {
            var responseContract = await costCenterService.GetContractsByGroupId(gtmId);

            if (responseContract.data.length > 0 && !responseContract.data[0].customerDescription.includes('Nenhum contrato')) {
                contracts = responseContract.data.length
            }
        } catch (e) {
        }

        if (contracts === 0 || costCenters === 0) {
            canSeeLocal = false
        }
        setCanSee(canSeeLocal);
    }

    const loadData = async () => {

        if (user.ClientGroupSelected) {
            setLoading(true)
            try {
                const costCenterService = new CostCenterService(props);
                if (!webPresenceData.costCenterId) {
                    webPresenceData.costCenterId = 0;
                }
                var response = await costCenterService.GetUrl();//.then(response => {
                if (analitico) {
                    let urlA = `${process.env.REACT_APP_LCANGULARDASHFINOPS}` + `?user=` + user.UserId + `&clientGroupId=` + user.ClientGroupSelected + `&token=` + response.data + `&reportType=1&displayType=1&costCenter=${webPresenceData.costCenterId}` + `&gtmId=` + gtmId;
                    setUrl(urlA);
                } else {
                    let urlG = `${process.env.REACT_APP_LCANGULARDASHFINOPS}` + `?user=` + user.UserId + `&clientGroupId=` + user.ClientGroupSelected + `&token=` + response.data + `&reportType=1&displayType=0&costCenter=${webPresenceData.costCenterId}` + `&gtmId=` + gtmId;
                    setUrl(urlG);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 500);

            } catch (error) {
                setLoading(false)
                setCanSee(false);
                console.log(error);
            }
        }
    }
    useEffect(() => {
        return history.listen((location) => {
            setAnalitico((location.pathname.toLowerCase().indexOf("analitico") >= 0));
        })
    }, [history, props])

    useEffect(() => { }, [url])

    return (
        <Layout pageTitle={analitico ? 'Consumo' : 'Dash'} >
            <LcLoading loading={loading} label="Carregando...">
                {canSee ?
                    <Iframe url={url} styles={{ width: "100%", height: "calc(100% - 4px)" }} frameBorder={0} />
                    :
                    <div className={styles.errorBody}>
                        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                            <Grid item>
                                <img className={styles.sadDog} src={chooseSadDog(1, 3.9)} />
                            </Grid>
                            <Grid item justify="center">
                                <p className={styles.errorMessage}>
                                    Não conseguimos localizar informações sobre o consumo.
                                    <br />
                                    Por gentileza, contate o administrador do sistema para validar se o seu usuário já é membro de algum centro de custo
                                    <br /> ou se a sua organização já possui dados sobre consumo nesta modalidade contratada.
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                }
            </LcLoading>
        </Layout>
    );
};

export default CostCenterDashBoard;