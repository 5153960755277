import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux'

import { FilterState, setFilterActive } from '../../../../../store/reducers/filterReducer';


import { DateRangePick, SliderField } from '../../../../Form/Input';
import LcCheckBox from '../../../../Form/LcCheckBox';
import { PPInput } from 'processor-plataform-ui';
import PPDropDown from '../../../../PPDropDown';

export interface FilterField {
    label: string;
    name: string;
    type: string;
    value?: string | any;
    options?: any[];
    show?: boolean;
    min?: number;
    max?: number;
    topDropdown?: boolean;
    marks?: [{ label: string, value: number }];
}

interface FilterLcInfiniteTableProps {
    filter: FilterField[];
    onFilterChange: (updatedFilter: FilterField[], size?: number) => void;
    clearFilters?: () => void;
    size?: number;
}

export const FilterLcInfiniteTable: React.FC<FilterLcInfiniteTableProps> = ({ filter, onFilterChange, clearFilters }) => {


    const dispatch = useDispatch();
    const [localFilter, setLocalFilter] = useState<FilterField[]>(filter);
    const [resetKey, setResetKey] = useState(0); // Adiciona um estado para gerenciar a chave de reset

    const handleInputChange = (name, value) => {
        const updatedFilter = localFilter.map(f => {
            if (f.name === name) {
                return { ...f, value };
            }
            return f;
        });
        setLocalFilter(updatedFilter);
    };

    const handleClearFiltersClick = () => {
        if (clearFilters) {
            clearFilters();
            // Chama a lógica de reset do estado local e re-renderização após clearFilters ser executado
            resetLocalFiltersAndRerender();
        } else {
            handleFilterClear();
        }
    };

    const handleFilterClear = () => {
        resetLocalFiltersAndRerender();
        onFilterChange(localFilter.map(f => ({ ...f, value: f.type === 'multiSelect' ? [] : '' })));
    };

    const resetLocalFiltersAndRerender = () => {
        const resetFilter = filter.map(f => ({ ...f, value: f.type === 'multiSelect' ? [] : '' })); // Use o estado inicial de filter para reset
        setLocalFilter(resetFilter);
        setResetKey(prevKey => prevKey + 1);
        dispatch(setFilterActive(false));
        onFilterChange(resetFilter); // Propaga o estado de filtro limpo
    };

    const applyFilters = () => {
        dispatch(setFilterActive(true));
        onFilterChange(localFilter);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        applyFilters();
    };

    return (
        <form onSubmit={handleSubmit} className="right-sidepanel-filters">
            <div style={{ gridGap: '1rem' }} className="principal-filters">
                {localFilter.map((f, i) => (
                    <div key={i}>
                        {f.type === 'spanDateTime' && (
                            <DateRangePick
                                id=''
                                key={resetKey}
                                label={f.label}
                                name={f.name}
                                value={f.value}
                                onChange={e => handleInputChange(f.name, e)}
                                width='100%'
                            />
                        )}
                        {f.type === 'interval' && (
                            <SliderField
                                marks={f.marks}
                                name={f.name}
                                label={f.label}
                                min={f.min || 0}
                                max={f.max || 100}
                                value={f.value}
                                onChange={e => handleInputChange(f.name, e.target.value)}
                            />
                        )}
                        {f.type === 'checkBox' && (
                            <LcCheckBox
                                title={f.label}
                                name={f.name}
                                checked={f.value === 'true'}
                                onChange={e => handleInputChange(f.name, e.target.checked ? 'true' : 'false')}
                            />
                        )}
                        {f.type === 'select' && (
                            <PPDropDown
                               id={`form_filtros_${f.name}_select`}
                                key={resetKey}
                                menuPlacement={f.topDropdown ? 'top' : 'auto'}
                                name={f.name}
                                value={f.options?.find(option => option.value === f.value)}
                                onChange={e => handleInputChange(f.name, e.value)}
                                options={f.options || []}
                                title={f.label}
                                placeholder='Selecione...'
                            />
                        )}
                        {f.type === 'multiSelect' && (
                            <PPDropDown
                                id={'form_filtros_'+f.name+'_multiselect'}
                                key={resetKey}
                                name={f.name}
                                isMulti={true}
                                options={f.options || []}
                                onChange={e => handleInputChange(f.name, e.map(option => option.value))}
                                title={f.label}
                                placeholder='Selecione...'
                            />
                        )}
                        {f.type === 'text' && (
                            <PPInput
                                id={`form_filtros_${f.name}_text`}
                                autoComplete='off'
                                name={f.name}
                                value={f.value || ''}
                                onChange={e => handleInputChange(f.name, e.target.value)}
                                type={f.type}
                                title={f.label}
                            />
                        )}
                        {(f.type == 'date') &&
                            <PPInput
                                id={`form_filtros_${f.name}_date`}
                                name={f.name}
                                value={f.value || ''}
                                onChange={(e) => handleInputChange(f.name, e.target.value)}
                                type={f.type}
                                title={f.label} />
                        }
                    </div>
                ))}
            </div>

            <div className="space-between mt-4">
                <button type="submit" className="lc-button bg-info" id={'form_filtros_filtrar_button'}>Filtrar</button>
                <button type="button" className="lc-button"  id={'form_filtros_limpar_button'} onClick={handleClearFiltersClick}>Limpar</button>
            </div>
        </form >
    );
};