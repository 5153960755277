import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    visible: boolean,
    agreeFunction: any,
    disagreeFunction: any,
    title: string,
}

const LcDialog: React.FC<Props> = (props) => {
    return (
        <div>
            <Dialog
              open={props.visible}
              onClose={props.disagreeFunction}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.disagreeFunction} color="primary">
                  Não
                </Button>
                <Button onClick={props.agreeFunction} color="primary" autoFocus>
                  Sim
                </Button>
              </DialogActions>
            </Dialog>
        </div>
    );
}

export default LcDialog;