import api, { APIClient } from "../api";

export class DashboardGestaoAVistaService extends APIClient {

    constructor(props:any){
        super(props);
  }

  async GetIndicadores() {
    const url = this.endpoint + `/api/DashboardGestaoAVista/GetIndicadores`;
    return api({
        method: 'get',
        url,  
    }).then((response) => {
        return response.data[0];
    });    
  }

  async GetIndicadoresByCompetence(year: string, month:string) {
    const url = this.endpoint + `/api/DashboardGestaoAVista/GetIndicadoresByCompetence/${year}/${month}`;
    return await api({
        method: 'get',
        url,  
    }).then((response) => {
        return response.data[0];
    });    
  }
  
  async DataTablePlanoAcaoAjaxHandler() {
    const url = this.endpoint + `/api/DashboardGestaoAVista/DataTablePlanoAcaoAjaxHandler`;
    return api({
        method: 'get',
        url,   
    }).then((response) => {
        return response.data;
    });    
  }

  async DataTableRotinaAjaxHandler() {
    const url = this.endpoint + `/api/DashboardGestaoAVista/DataTableRotinaAjaxHandler`;
    return api({
        method: 'get',
        url,      
    }).then((response) => {
        return response.data;
    });    
  }  

}