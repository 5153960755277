import React, { useEffect, useRef, useState } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import JSONDigger from "json-digger";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import "./org.css";
import { useSnackbar } from "notistack";
import { filterDecimalInput, formatDecimal } from '../../../services/currency';
import moment from 'moment';

import LcIconLink from "../../../components/Generic/LcIconLink";
import { CostCenterService } from '../../../services/costCenter/costCenterService'

import DialogConfirmForm from "../../../components/Layout/Dialog/DialogConfirmForm";
import LcTooltip from "../../../components/Generic/LcTooltip";
import { PPInput, PPModal } from "processor-plataform-ui";
import BudgetPerMonth from "../BudgetPerMonth";
import { PiCheckCircleLight, PiPencilLight, PiPencilLineLight, PiPlusLight, PiProhibitLight, PiTrashLight, PiUsersThreeLight } from "react-icons/pi";

const EditChart = (props) => {
    //Manipulação de dados do chart via JSon Digger
    //https://github.com/dabeng/json-digger/blob/master/test/index.js

    const costCenterService = new CostCenterService(props);

    const clientGroupId = props.clientGroupId;
    const allocatedCostCenters = props.allocated;
    const orgchart = useRef();
    const service = props.service;
    const [ds, setDS] = useState(props.data);
    const dsDigger = new JSONDigger(ds, "id", "children");
    const [selectedNodes, setSelectedNodes] = useState(new Set());
    const [saveMode, setSaveMode] = useState(1); //1 - budget padrão, -1 - budget mensal
    const [monthBudgetFull, setMonthBudgetFull] = useState([{}]);
    const [newNodes, setNewNodes] = useState([
        {
            id: uuidv4(),
            name: "",
            baseline: 0,
            nodeContent: "",
            tag: "",
            baselineUndefined: false,
            budget: 0,
            children: [],
            clientGroupId: clientGroupId,
            active: true,
        },
    ]);
    const [isEditMode, setIsEditMode] = useState(true);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [currentNode, setCurrentNode] = useState();
    const [currentNodeTitle, setCurrentNodeTitle] = useState();
    const [newName, setNewName] = useState();
    const [typeSideModal, setTypeSideModal] = useState();
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [budget, setBudget] = useState(0);
    const [newBudget, setNewBudget] = useState(0);
    const [currentNodeName, setCurrentNodeName] = useState();
    const [currentNodeBudget, setCurrentNodeBudget] = useState();

    const readSelectedNode = async (nodeData) => {
        setCurrentNode(nodeData);
        setCurrentNodeName(nodeData.name);
        setCurrentNodeBudget(nodeData.budget);
        let node = new Set([nodeData]);
        await setSelectedNodes(node);
    };

    const clearSelectedNode = () => {
        setSelectedNodes(new Set());
    };

    const getNewNodes = () => {
        const nodes = [];
        for (const node of newNodes) {
            nodes.push({
                ...node,
                id: uuidv4(),
                name: newName,
                baseline: 0,
                nodeContent: "",
                tag: newName,
                baselineUndefined: false,
                budget: newBudget,
                qtdSubscription: 0,
                children: [],
                clientGroupId: clientGroupId,
            });
        }
        return nodes;
    };

    const addChildNodes = async () => {
        let param = [...selectedNodes][0].id;
        let allNodes = getNewNodes();
        await dsDigger.addChildren(param, allNodes);

        await dsDigger.findParent(allNodes[0].id).then((_r) => {
            const parentNode = _r;
            const node = allNodes[0];
            let costCenterSend = {
                ID: 0,
                Parent: parentNode.id,
                Main: false,
                Name: node.name,
                ParentName: parentNode.name,
                Budget: newBudget,
                QtdSubscription: node.qtdSubscription,
                ClientGroupId: clientGroupId,
                active: true
            };
            service
                .UpdateCostCenter(costCenterSend)
                .then((result) => {
                    if (result.status == 200) {
                        props.updateData(costCenterSend);
                        enqueueSnackbar(
                            "A atualização do centro de custo foi realizada com sucesso.",
                            {
                                variant: "success",
                                preventDuplicate: true,
                                persist: false,
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar("Erro ao atualizar centro de custo.", {
                        variant: "error",
                        preventDuplicate: true,
                        persist: false,
                    });
                });
        });

        setDS({ ...dsDigger.ds });
    };
    const editNode = async () => {
        let node = currentNode;
        node.name = currentNodeName;
        setIsVisibleModal(false);
        setCurrentNodeTitle("");
        setTypeSideModal("");

        var currentBudgetHistory = [];
        try {
            let result = await service.GetTreeDataWithBudget();
            if (result.status == 200)
                currentBudgetHistory = result.data.map(d => d.monthBudget).flat();
        } catch (error) {
            console.warn(error);
        }

        if (saveMode == -1) {

            monthBudgetFull.find(b => b.value) ?
                enqueueSnackbar('A atualização da árvore foi iniciada, aguarde.', {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false,
                })
                :
                enqueueSnackbar('Não há dados para serem salvos', {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })


            monthBudgetFull
                // .filter(b => b.value)
                .forEach(async (mb, index) => {
                    try {
                        let parsedValue = Number(mb.value) || 0.00;
                        let hasHistory = currentBudgetHistory.length > 0;
                        let hasChanged = hasHistory && !(currentBudgetHistory.some(cbh =>
                            cbh.costCenterId === currentNode.id && cbh.value === parsedValue && cbh.month === mb.month
                        ));
                        if (!hasHistory || hasChanged)
                            await costCenterService.CreateOrUpdateBudget(currentNode.id, parsedValue, mb.month);
                    } catch (error) {
                        console.warn(error);
                    }

                    if (monthBudgetFull.filter(b => b.value).length - 1 == index) {
                        enqueueSnackbar('A atualização do centro de custo foi realizada com sucesso.', {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                    }
                })
        } else {
            const updateCall = (costCenterSend) => {
                let budgetMsg = "";
                let sucessMsg = `A atualização do centro de custo${budgetMsg} foi realizada com sucesso.`;
                service.UpdateCostCenter(costCenterSend)
                    .then((result) => {
                        if (result.status == 200) {
                            var budget = {
                                id: node.id,
                                value: Number(currentNodeBudget) || 0.00,
                                month: moment().format("YYYY-MM")
                            }
                            service.CreateOrUpdateBudget(budget.id, budget.value, budget.month)
                                .then(resultBudget => {
                                    if (resultBudget.status == 200)
                                        budgetMsg = " e do histórico de orçamentos";
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        enqueueSnackbar("Erro ao atualizar centro de custo.", {
                            variant: "error",
                            preventDuplicate: true,
                            persist: false,
                        });
                    })
                    .finally(() => {
                        props.updateData();
                        enqueueSnackbar(sucessMsg,
                            {
                                variant: "success",
                                preventDuplicate: true,
                                persist: false,
                            }
                        );
                    });
            }

            const costCenterSend = {
                ID: node.id,
                Main: false,
                Name: node.name,
                active: node.active,
                Budget: currentNodeBudget,
                ClientGroupId: clientGroupId,
                QtdSubscription: node.qtdSubscription
            }

            await dsDigger.findParent(node.id)
                .then((result) => {
                    const parentNode = result;
                    if (parentNode && node) {
                        costCenterSend['Parent'] = parentNode.id;
                        costCenterSend['ParentName'] = parentNode.name;
                    }
                })
                .catch((e) => {
                    //Não tem parent, logo é root node
                    costCenterSend['Parent'] = 0;
                    costCenterSend['ParentName'] = "";
                })
                .finally(() => updateCall(costCenterSend));
        }

    };

    const onCloseSideModal = () => {
        setIsVisibleModal(!isVisibleModal);
        setSaveMode(1)
        setTypeSideModal("");
    };

    const propTypes = {
        nodeData: PropTypes.object.isRequired,
    };

    const deleteCostCenter = (confirm) => {
        if (confirm == true) {
            enqueueSnackbar(
                "A exclusão do centro de custo foi iniciada, aguarde a atualização dos dados",
                {
                    variant: "success",
                    preventDuplicate: true,
                    persist: false,
                }
            );
            if (currentNode) {
                service
                    .DeleteCostCenter(currentNode.id)
                    .then((result) => {
                        if (result.status == 200) {
                            props.updateData();
                            enqueueSnackbar(
                                "A exclusão do centro de custo foi realizada com sucesso.",
                                {
                                    variant: "success",
                                    preventDuplicate: true,
                                    persist: false,
                                }
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        enqueueSnackbar("Erro ao excluir centro de custo.", {
                            variant: "error",
                            preventDuplicate: true,
                            persist: false,
                        });
                    });
            }
        }
        setOpen(false);
    };

    const node = ({ nodeData }) => {
        const edit = () => {
            setTypeSideModal("edit");
            readSelectedNode(nodeData);
            setIsVisibleModal(!isVisibleModal);
        };
        const add = () => {
            setTypeSideModal("create");
            readSelectedNode(nodeData);
            setIsVisibleModal(!isVisibleModal);
        };

        const _remove = () => {
            setOpen(true);
            readSelectedNode(nodeData);
        };

        const toggleEnable = () => {
            // if (allocatedCostCenters.includes(nodeData.id))
            if (window.confirm(`Deseja ${nodeData.active ? "des" : "h"}abilitar este centro de custo?`)) {
                readSelectedNode(nodeData, nodeData.active = !nodeData.active);
                setSaveMode(1);
                updateCostCenter()
            }
        }

        const firstWordOverflow = nodeData.name.split(" ")[0].length >= 12;
        const fullnameOverflow = nodeData.name.length > 32;
        const disabledByHierarchy = nodeData.children.length > 0 && nodeData.children.find(x => x.active == true) != undefined;
        const disabledByAllocation = allocatedCostCenters.includes(nodeData.id);
        const disableBtnDisable = disabledByHierarchy || disabledByAllocation;
        const disableActiveTooltip = !disableBtnDisable ? (nodeData.active ? "Desabilitar centro de custo" : "Habilitar centro de custo") :
            (disabledByHierarchy ? "CC possui filhos ativos!" : (disabledByAllocation ? "CC possui regra de alocação!" : ""))
        const disableDeleteTooltip = !disableBtnDisable ? "Excluir centro de custo" :
            (disabledByHierarchy ? "CC possui filhos ativos!" : (disabledByAllocation ? "CC possui regra de alocação!" : ""))
        const hasOverflow = firstWordOverflow || fullnameOverflow;
        return (
            <div className="nodeContainer">
                <LcTooltip content={hasOverflow && nodeData.name} trigger="hover" delay={500} position="top">
                    <div className={`fullname ${nodeData.active ? '' : 'disabled'}`}>
                        <p>{hasOverflow ? `${(firstWordOverflow ? nodeData.name.slice(0, 11) : nodeData.name.slice(0, 31))}...` : nodeData.name}</p>
                    </div>
                </LcTooltip>
                <div className="footerNode">
                    <LcIconLink
                        icon={<PiPlusLight />}
                        tooltip="Adicionar novo centro de custo"
                        onClick={add}
                        size="small"
                    ></LcIconLink>
                    <LcIconLink
                        icon={<PiPencilLight />}
                        tooltip="Editar centro de custo"
                        onClick={edit}
                        size="small"
                    ></LcIconLink>
                    <LcIconLink
                        icon={nodeData.active ? <PiProhibitLight /> : <PiCheckCircleLight />}
                        tooltip={disableActiveTooltip}
                        onClick={toggleEnable}
                        size="small"
                        disabled={disableBtnDisable}
                    ></LcIconLink>
                    <LcIconLink
                        icon={<PiTrashLight />}
                        tooltip={disableDeleteTooltip}
                        onClick={_remove}
                        size="small"
                        disabled={disableBtnDisable}
                    ></LcIconLink>
                    <LcIconLink
                        icon={<PiUsersThreeLight />}
                        tooltip="Usuários vinculados"
                        onClick={() => { props.editUsers(nodeData) }}
                        size="small"
                    ></LcIconLink>
                </div>
            </div >
        )
    };
    node.propTypes = propTypes;

    const onChangeInput = (field) => {
        setCurrentNodeTitle(currentvalue => {
            return field.target.value;
        });
        setCurrentNodeName(currentvalue => { return field.target.value; });
    };

    const onChangeInputNewName = (field) => {

        setNewName(field.target.value);
    };

    const updateCostCenter = () => {
        editNode();
    };

    const createCostCenter = () => {
        enqueueSnackbar(
            "A criação do centro de custo foi iniciada, aguarde a atualização dos dados",
            {
                variant: "success",
                preventDuplicate: true,
                persist: false,
            }
        );


        addChildNodes();
        setIsVisibleModal(!isVisibleModal);
        setTypeSideModal(null);
        setNewName("");
    };

    const onChangeBudget = (field) => {
        // let fieldValue = field.target.value.replace(/[^0-9]/g, "");
        let fieldValue = filterDecimalInput(field.target.value);
        setBudget(fieldValue);
        setCurrentNodeBudget(fieldValue);
    };

    const onChangeNewBudget = (field) => {
        // let fieldValue = field.target.value.replace(/[^0-9]/g, "");
        let fieldValue = filterDecimalInput(field.target.value);
        setNewBudget(fieldValue);
    };

    const handleUpdateBudgetMonth = (e) => {
        let newMonthBudgetfull = [...monthBudgetFull];
        newMonthBudgetfull = e;
        setMonthBudgetFull(newMonthBudgetfull);
    }

    const modalFunctions = [
        {
            label: typeSideModal == "edit" ? "Salvar" : "Criar",
            onClick:
                typeSideModal == "edit" ? updateCostCenter : createCostCenter,
        },
        {
            label: saveMode == 1 ? "Histórico" : "Modo Padrão",
            onClick: () => setSaveMode(saveMode * -1),
            context: "info",
            tooltip: saveMode == 1 ? "Editar orçamento separado por mês" : "Editar orçamento padrão do centro de custo",
        }
    ];

    return (
        <>
            <DialogConfirmForm
                onCloseEvent={deleteCostCenter}
                open={open}
                confirmMessage="Sim, desejo deletar"
                abortMessage="Não, não desejo deletar."
                title="Deseja realmente deletar este centro de custo?"
                content="Está operação não pode ser revertida caso confirme a deleção. Tenha convicção para deletar o centro de custo selecionado"
            ></DialogConfirmForm>

            <OrganizationChart
                datasource={ds}
                NodeTemplate={node}
                chartClass={"myChart"}
                onClickNode={readSelectedNode}
                onClickChart={clearSelectedNode}
                zoom
            />

            <PPModal
                title={
                    typeSideModal === "edit"
                        ? "Editar centro de custo"
                        : "Criar centro de custo"
                }
                onClose={onCloseSideModal}
                visible={isVisibleModal}
                functions={typeSideModal === "edit" ? modalFunctions : modalFunctions.slice(0, 1)}
            >
                {typeSideModal === "edit" && (
                    <div className="form">
                        <PPInput
                            title="Nome do centro de custo"
                            value={currentNodeName}
                            name="nodeName"
                            type="text"
                            icon={<PiPencilLight />}
                            onChange={onChangeInput}
                            placeHolder="Insira um novo nome ao centro de custo"
                            required
                        />
                        {
                            saveMode === 1 ?
                                <PPInput
                                    title="Valor de orçamento (período atual)"
                                    value={formatDecimal(Number(currentNodeBudget))}
                                    decoration="R$ "
                                    name="budget"
                                    type="text"
                                    placeHolder="Digite apenas números"
                                    onChange={onChangeBudget}
                                    required
                                />
                                :
                                < BudgetPerMonth clientGroupId={clientGroupId} ccId={currentNode.id} onChange={handleUpdateBudgetMonth} />
                        }
                    </div>
                )}

                {typeSideModal == "create" && (
                    <div className="form">
                        <PPInput
                            title="Nome do centro de custo"
                            name="newNodeName"
                            type="text"
                            value={newName}
                            onChange={onChangeInputNewName}
                            required
                        />

                        <PPInput
                            title="Valor de Baseline"
                            value={formatDecimal(Number(newBudget))}
                            decoration="R$ "
                            name="newBudget"
                            type="text"
                            placeHolder="Digite apenas números"
                            onChange={onChangeNewBudget}
                            required
                        />
                    </div>
                )}
            </PPModal>
        </>
    );
};

export default EditChart;
