import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import Layout from '../../components/Layout/Layout';

import IconValueCardRow from '../../components/Layout/Dashboard/IconValueCardRow';

import HomeService, { TileControlViewModel } from '../../services/homeService';
import { FinancialManagementService } from '../../services/financialManagement/FinancialManagementService';

import LCFlexContainer from '../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../components/Layout/LCFlexItem';

import Moment from 'moment';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { MenuState } from '../../store/reducers/menuReducer';
import IconValueCardCol from '../../components/Layout/Dashboard/IconValueCardCol';
import LcLoading from '../../components/Generic/LcLoading';

const DashboardInvoice: React.FC = (props) => {

    const history = useHistory();
    const financialManagementService = new FinancialManagementService(props);

    const user = useSelector<RootState, UserState>(state => state.user);

    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [contratosAtivos, setContratosAtivos] = useState(0);

    const getMenuIds = (menuItems: any) => {
        let ids: any[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].children && menuItems[i].children.length > 0) {
                ids = ids.concat(getMenuIds(menuItems[i].children));
            }
            ids.push(menuItems[i].id);
        }
        return ids;
    }

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    }

    useEffect(() => {
        if (!month && !year) {
            setMonth(Moment().format('MM'));
            setYear(Moment().format('YYYY'));
        }

        if (month && year && !loading) {
            loadData();
        }

    }, [year, month, user.ClientGroupSelected, user.refreshFlag]);

    async function loadData() {
        setLoading(true);
        if (user.Permission && user.Permission.some((permissionObj: any) =>
            permissionObj.permissoesChave == "Permite_Contrato_NotaFiscal" &&
            permissionObj.permissoesValor.toLowerCase() == "sim")) {
            try {
                let response = await financialManagementService.GetClientGroupContractFinancial(month, year);
                setContratosAtivos(response.filter(i => i.contractStatus == 'ATIVO').length)
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.warn(e)
            }
        }
    }

    return <Layout pageTitle='Dashboard' periodSelection={periodSelection}>

        <div className="row">
            <div className="w-20p">
                <LcLoading loading={loading} label='Carregando notas fiscais'>
                    <IconValueCardCol
                        onClick={() => { history.push('notasFiscais') }}
                        title="Contratos (+ Notas Fiscais)"
                        icon="lci lci-document-text"
                        value={contratosAtivos}
                        color="#C23D4F">
                    </IconValueCardCol>
                </LcLoading>
            </div>
        </div>
    </Layout>;
}

export default DashboardInvoice;