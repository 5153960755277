import axios, { AxiosResponse } from "axios";
import api, { APIClient } from "../../api";
import { SavingPlanReserveModel } from "./FinOpsSavingPlanReserveModel";

export class FinOpsSavingPlanReserveService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    // getCost = async (): Promise<any[]> => {
    //     const url = `${this.endpoint}/api/CostSavings/GetCost`;
    //     return (await api({
    //         method: 'get',
    //         url,
    //     })).data;
    // }

    retrieveList = async (): Promise<SavingPlanReserveModel[]> => {
        const url = `${this.endpoint}/api/SavingsAndReserves/RetrieveList`;
        try {
            const result = await api({
                method: 'get',
                url,
            });
            return result.status === 200 ? result.data as SavingPlanReserveModel[] : [] as SavingPlanReserveModel[];;
        } catch (e) {
            console.error(`[SAVINGS PLANS] Erro ao buscar lista: ${e}`)
            return [] as SavingPlanReserveModel[];
        }
    }

}