import { Slider } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import LcToolTip from '../../Generic/LcTooltip';

//CSS
import './index.css';

interface InputProps {

    id?:string,
    label?: string,
    toolTip?: boolean,
    toolTipText?: string,
    name?: string,
    value?: string | number,
    defaultValue?: string | number,
    required?: boolean,
    type: string,
    placeHolder?: string,
    icon?: string,
    pattern?: string,
    onChange?: Function,
    onClick?: Function,
    onKeyPress?: Function,
    width?: string,
    disableAutocomplete?: boolean,
    listAutocomplete?: string,
    readonly?: boolean,
    disable?: boolean;
    className?: string,
    max?: string,
    ref?: any,
    step?: any,
    updateParentConst?: boolean,
    InputProps?: any,
    bordersOff?: boolean,
}


export const InputField: React.FC<InputProps> = (props) => {

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        props.onChange && props.onChange({ name, value });
    }

    return (
        <div className={`lc-input ${props.icon && 'icon'}`} style={{ width: props.width }}>
            {props.label &&
                <div className="Grid">
                    <span className="label">{props.label}</span>
                    {props.toolTip &&
                        <LcToolTip trigger="hover"
                            content={props.toolTipText}
                            position="center"><i className="lci lci-exclamation-circle"></i></LcToolTip>}
                </div>}
            <div className={`field ${props.icon ? 'icon' : ''}`}>
                {props.icon && <i className={props.icon}></i>}
                {props.updateParentConst ?
                    <input onKeyPress={(event) => { props.onKeyPress && props.onKeyPress(event) }}
                        onClick={(event) => { props.onClick && props.onClick(event) }} style={{ width: props.width }} step={props.step} defaultValue={props.defaultValue} required={props.required} className={`${props.bordersOff ? 'bordersOff' : ''} ${props.className}`} disabled={props.disable} readOnly={props.readonly} name={props.name} type={props.type} placeholder={props.placeHolder || ''} onChange={handleChange} autoComplete={props.disableAutocomplete ? 'off' : 'on'}
                        list={props.disableAutocomplete ? 'autocompleteOff' : props.listAutocomplete} max={props.max} value={props.value}></input>
                    :
                    <input
                    id={`${props.id}`}
                        onKeyPress={(event) => { props.onKeyPress && props.onKeyPress(event) }}
                        onClick={(event) => { props.onClick && props.onClick(event) }} style={{ width: props.width }} step={props.step} defaultValue={props.defaultValue} required={props.required} className={`${props.bordersOff ? 'bordersOff' : ''} ${props.className}`} disabled={props.disable} readOnly={props.readonly} name={props.name} type={props.type} placeholder={props.placeHolder || ''} onChange={handleChange} autoComplete={props.disableAutocomplete ? 'off' : 'on'}
                        list={props.disableAutocomplete ? 'autocompleteOff' : props.listAutocomplete} max={props.max}>
                        
                        </input>
                }
            </div>
        </div>
    );
};


export const FileField: React.FC<InputProps> = (props) => {
    const [fileName, setFile] = useState('')
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        files && setFile(files[0].name)
        props.onChange && props.onChange(files);

    }
    function handleButtonClick() {
        const fileInput = document.getElementById('file-input');
        console.log(fileInput)
        fileInput && fileInput.click();
    }

    return (
        <div className={`lc-input ${props.icon && 'icon'}`} style={{ width: props.width }}>
            <span className="label ">{props.label}</span>
            {props.icon && <i className={props.icon}></i>}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <button type='button' id={'escolherarquivo_button'} className='pp-button bg-gray w-40p' onClick={handleButtonClick}>
                    Escolher arquivo
                    <input id="file-input" name={props.name} defaultValue={props.value} type="file" placeholder={props.placeHolder || ''} onChange={handleChange}></input>
                </button>
                <span className='spanNameFile'>{fileName}</span>
            </div>
        </div>
    );
};

interface InputTextAreaProps {
    label: string,
    name: string,
    value?: string,
    rows: number,
    placeHolder?: string,
    icon?: string,
    onChange?: Function,
    width?: string,
    ref?: any,
    required?: boolean;
}

export const TextAreaField: React.FC<InputTextAreaProps> = (props) => {

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        const { name, value } = event.target;
        props.onChange && props.onChange({ name, value });
    }

    return (
        <div className={`lc-input ${props.icon && 'icon'}`} style={{ width: props.width }}>
            <span className="label">{props.label}</span>
            {props.icon && <i className={props.icon}></i>}
            <textarea
                required={props.required}
                ref={props.ref ?? props.ref}
                name={props.name}
                defaultValue={props.value}
                placeholder={props.placeHolder || ''}
                rows={props.rows}
                onChange={handleChange}
                style={{ fontFamily: 'Ubuntu' }}
            />
        </div>
    );
};

interface SelectProps {
    label?: string,
    name: string,
    options?: { value: string, name: string; }[],
    value?: string,
    values?: string[],
    placeHolder?: string,
    onChange?: Function,
    onClick?: Function,
    width?: string,
    ref?: any,
    className?: string,
    multiple?: boolean,
    required?: boolean,
    bordersOff?: boolean,
    toolTip?: boolean,
    toolTipText?: string,
}

export const SelectField: React.FC<SelectProps> = (props) => {

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        const { name, value } = event.target;
        props.onChange && props.onChange({ name, value });
    }
    function handleMultiChange(event: ChangeEvent<HTMLSelectElement>) {
        const array = Array.from(event.target.selectedOptions, option => option.value);
        props.onChange && props.onChange(array);
    }

    return (
        <div className={`lc-input`} style={{ width: props.width }}>
            {props.label &&
                <div className="Grid">
                    <span className="label">{props.label}</span>
                    {props.toolTip &&
                        <LcToolTip trigger="hover"
                            content={props.toolTipText}
                            position="center"><i className="lci lci-exclamation-circle"></i></LcToolTip>}
                </div>}
            <div className={`field ${props.multiple ? 'multi' : ''}`}>
                <select id={'selecione_a_pasta'} onClick={(event) => { props.onClick && props.onClick(event) }} style={{ width: props.width }} className={`${props.bordersOff ? 'bordersOff' : ''} ${props.className ? props.className : ''} ${props.required ? 'required' : ''} `} multiple={props.multiple ? props.multiple : false} onChange={props.multiple ? handleMultiChange : handleChange} name={props.name} defaultValue={props.value || ''} required={props.required}>
                    {!props.multiple &&
                        <option disabled value="">{props.placeHolder || '...'}</option>
                    }
                    {
                        props.options?.map((opt, index) => {
                            return <option  id={`option_select_${index+1}`}key={index} value={opt.value} selected={props.value != undefined ?
                                opt.value == props.value : props.values != undefined
                                    ? props.values.some(elem => elem == opt.value) : false}>{opt.name}</option>;
                        })
                    }
                </select>
            </div>
        </div>
    );
};

interface SliderProps {
    label: string,
    min: number,
    max: number,
    name: string,
    value?: number[],
    onChange?: Function,
    width?: string,
    marks?: { label: string, value: number; }[],
    style?: React.CSSProperties
}

export const SliderField: React.FC<SliderProps> = (props) => {

    const [value, setValue] = React.useState<number[]>([props.min, props.max]);
    const name = props.name;
    function handleChange(event: ChangeEvent<{}>, value: number | number[]) {
        setValue(value as number[]);
        props.onChange && props.onChange({ name, value });
    }

    return (
        <div className={`lc-input`} style={{ width: props.width, paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <span className="label">{props.label}</span>
            <div style={{ paddingLeft: props?.marks ? '1.2rem' : '0px' }}>
                <Slider
                    marks={props.marks}
                    getAriaLabel={() => 'Interval'}
                    value={props.value ? props.value : value}
                    valueLabelDisplay="auto"
                    name={props.name}
                    min={props.min}
                    max={props.max}
                    step={1}
                    style={props.style}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};
export interface DateRange  { startDate: string, endDate: string };
interface DateRangePickProps {
    id?:string,
    label: string,
    onChange?: Function,
    width?: string,
    name: string,
    value?: { startDate: string, endDate: string; } | DateRange,
}

export const DateRangePick: React.FC<DateRangePickProps> = (props) => {

    const dateName = props.name;
    // Define os valores iniciais com base nas propriedades ou valores padrão
    const [startDate, setStartDate] = useState(props.value?.startDate || '');
    const [endDate, setEndDate] = useState(props.value?.endDate || '');

    // Atualize o estado quando as props mudarem
    useEffect(() => {
        if (props.value) {
            setStartDate(props.value.startDate || '');
            setEndDate(props.value.endDate || '');
        }
    }, [props.value]);

    function handleChange(event: any) {
        const { name, value } = event;
        if (name === props.name + '_begin') {
            if (Date.parse(value) > Date.parse(endDate)) {
                //alert("Data inicial deve ser menor que a final");
                return false;
            }
            setStartDate(value);
            props.onChange && props.onChange({ dateName, startDate: value, endDate });
        } else if (name === props.name + '_end') {
            if (Date.parse(value) < Date.parse(startDate)) {
                //alert("Data final deve ser maior que a final");
                return false;
            }
            setEndDate(value);
            props.onChange && props.onChange({ dateName, startDate, endDate: value });
        }
    }

    return (
        <div className={`lc-input`} style={{ width: props.width }}>
            <span className="label">{props.label}</span>
            <div className="Grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '12px' }}>
                <InputField
                    id={'form_filters_date_begin'}
                    name={props.name + '_begin'}
                    width="100%"
                    type='date'
                    value={props.value?.startDate}
                    onChange={handleChange}

                />

                <InputField
                    id={'form_filters_date_end'}
                    name={props.name + '_end'}
                    width="100%"
                    type='date'
                    value={props.value?.endDate}
                    onChange={handleChange}
                />
            </div>

        </div>
    );
};