import React, { useRef } from 'react';
import { costCenterChartPalette, resourceChartPalette } from '.';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import ReactECharts from 'echarts-for-react';
import { FinOpsChartBuilder } from '../../../services/finOps/FinOpsChartBuilder';

interface TopConsumptionChartModel {
    loading: boolean;
    kpi: any;
    rankType: string;
    rankTypeOption: string | undefined;
}

export const TopConsumptionChart: React.FC<TopConsumptionChartModel> = (
    {
    kpi,
    loading,
    rankType,
    rankTypeOption
    }) => {
    const chartTopService = useRef<ReactECharts>(null);
    const chartTopResource = useRef<ReactECharts>(null);
    const chartTopCC = useRef<ReactECharts>(null);
    const finOpsChartService = new FinOpsChartBuilder({});



    return (
        <LcLoading loading={loading}>
        {
            kpi &&
                kpi.dailyConsumption &&
                kpi.dailyConsumption.length === 0 ?
                <LcNoData size="small" />
                :
                kpi &&
                <div style={{ width: '100%', height: '95%' }}>
                    <div className="chart-container">
                        <div className="chart-content">
                            {rankType === 'service' &&
                                kpi &&
                                <ReactECharts
                                    ref={chartTopService}
                                    style={{ width: '100%', height: '100%' }}
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getConsumptionChart(kpi.serviceConsumption, resourceChartPalette, kpi, false, rankTypeOption)}
                                />
                            }

                            {rankType === "resource" &&
                                kpi &&
                                <ReactECharts
                                    ref={chartTopResource}
                                    style={{ width: '100%', height: '100%' }}
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getConsumptionChart(kpi.resourceConsumption, resourceChartPalette, kpi, false, rankTypeOption)}
                                />
                            }
                            {rankType === "costCenter" &&
                                kpi &&
                                <ReactECharts
                                    ref={chartTopCC}
                                    style={{ width: '100%', height: '100%' }}
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getConsumptionChart(kpi.costCenterConsumption, costCenterChartPalette, kpi, false, rankTypeOption)}
                                />
                            }
                        </div>
                    </div>
                </div>
        }
        {kpi === undefined &&
            <LcNoData size="small" />
        }
    </LcLoading>
    )
}
