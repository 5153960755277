import React from 'react';
import Chart from '../Chart';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import moment from 'moment';
const LineChart: React.FC<{ data, showline, showDays }> = ({ data, showline, showDays = true }) => {
    const minValue = Math.min(...data.map(item => item.value));
    const maxValue = Math.max(...data.map(item => item.value));

    // Define a margin (adjust this as needed)
    const margin = 0.05; // You can adjust this margin value

    // Calculate the new minimum and maximum values for the y-axis
    const newYMin = Math.floor(minValue - minValue * margin);
    const newYMax = Math.ceil(maxValue + minValue * margin);
    const options = {
        grid: {
            top: '5%',
            bottom: '10%',
            left: '0',
            right: '0',
            containLabel: true,
        },
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            formatter: function (params) {
                const dataPoint = params[0];
                return showDays ? `${moment(dataPoint.name).format('DD/MMM/YY')}: ${dataPoint.value.toFixed(2)}` : `${moment(dataPoint.name).format('MMM/YY')}: ${dataPoint.value.toFixed(0)}`;
            },
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item.name),
            axisLabel: {
                interval: 0,
                formatter: (value: any, index: number) => {
                    // Format the timestamp as you desire (e.g., 'HH:mm:ss')
                    const formattedTime = showDays ? moment(value).format('DD/MMM/YY') : moment(value).format('MMM/YY');
                    return formattedTime;
                },
                fontSize: '12px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        yAxis: {
            type: 'value',
            min: newYMin, // Set the new minimum value
            max: newYMax, // Set the new maximum value
            axisLabel: {
                fontSize: '12px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        series: [{
            data: data.map(item => item.value),
            type: 'line',
            itemStyle: {
                color: '#273B85',
            },
        }],
    };

    // Conditionally add a line series over the bars
    //if (showline) {
    //    options.series.push({
    //        data: data.map(item => item.value),
    //        type: 'line',
    //        // smooth: true, // Optional: makes the line smooth
    //        itemStyle: {
    //            color: '#000000', // color of the line
    //        },
    //    });
    //}

    return <ReactECharts style={{ width: '100%', height: '100%' }} option={options} opts={{ renderer: 'svg', locale: 'PT-br' }} />;
};

export default LineChart;
