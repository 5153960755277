import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupSaasService } from '../../../services/backupSaas/backupSaasService';
import { Grid, LinearProgress } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

function TranslateStatus(status: string) {
    if (status == "Scheduled") {
        return "Agendado";
    } else {
        return status;
    }
}

interface Item {
    status: string,
    connector: string,
    startDate: string,
    endDate: string,
    type: string
}

const BackupSaasAgendamentos: React.FC = (props) => {

    const [schedules, setSchedules] = useState<any[]>([]);
    const [filteredSchedules, setFilteredSchedules] = useState<any[]>([]);
    const [visibleSchedules, setVisibleSchedules] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });
    const [loading, setLoading] = useState<boolean>(false);

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    useEffect(() => {
        setFilteredSchedules([]);
        if (user.ClientGroupSelected) {
            setLoading(true);
            const backupSaasService = new BackupSaasService(props);
            backupSaasService.GetSchedules()
                .then(response => {

                    let data = response.data.sort((a: any, b: any) => (a.startDate > b.startDate) ? -1 : 1)

                    if (data.length > 0) {
                        let schedules = data.map((item: Item, index: number) => {

                            return {
                                id: index,
                                Status: TranslateStatus(item.status),
                                Connector: item.connector,
                                Type: item.type,
                                StartDate: new Date(item.startDate).toLocaleString()
                            }
                        });

                        setSchedules(schedules);
                        setFilteredSchedules(schedules);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = schedules
            .filter((p: any) =>
                filter.term == '' ||
                p.Status.toLowerCase().indexOf(filter.term) > -1 ||
                p.Connector.toLowerCase().indexOf(filter.term) > -1 ||
                p.Type.toLowerCase().indexOf(filter.term) > -1 ||
                p.StartDate.toLowerCase().indexOf(filter.term) > -1
            )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredSchedules(filtered);
        setVisibleSchedules(filtered.slice(0, filter.size));
    }, [filter, filteredSchedules.length > 0]);

    const columns = [
        { field: 'Status', headerName: 'Situação', width: "25%" },
        { field: 'Connector', headerName: 'Produto', width: "25%" },
        { field: 'Type', headerName: 'Tipo', width: "25%" },
        { field: 'StartDate', headerName: 'Início', width: "25%" },
    ];


    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleSchedules(filteredSchedules.slice(0, size))
        else setVisibleSchedules([...visibleSchedules, ...filteredSchedules.slice(visibleSchedules.length, visibleSchedules.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);

    const filterAdvanced = {
        fields: [{ label: ' ', name: 'assunto', type: 'text' }],
        onChange: (filter: any, size: number) => setFilter({ ...filter, size: size, term: filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    return (
        <Layout
            cardType
            pageTitle="Agendamentos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredSchedules.length
            }}
        >
            <LcInfiniteTable
                loading={loading}
                columns={columns}
                rows={visibleSchedules}
                filter={filterAdvanced}
                size={filteredSchedules.length}
                loadMore={loadMore}
                onSortChange={onSortChange} />
        </Layout>
    )
}

export default BackupSaasAgendamentos;
