import React from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import { PiPencilSimpleLight } from 'react-icons/pi';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { formatCurrency } from '../../../services/currency';
import { AssembleBody } from './AssembleBody';
import { CostCenterWithBudget } from '../../../services/costCenter/costCenterService';
import { StrucureModel } from '.';

interface BudgetTableModel {
	ccs: string[],
	ccData: CostCenterWithBudget[],
	loading: boolean,
	visibleMode: number,
	budgetData: StrucureModel[]
	onEdit: (cc: string) => void
	columns: any[]
	setBudgetData: (budgetData: StrucureModel[]) => void
}

export const BudgetTable: React.FC<BudgetTableModel> = ({ccData, ccs, loading, visibleMode,budgetData, onEdit, columns,setBudgetData }) => {
    return(
			<div className="chart-container" >
			<div className=" scrollable-v" >
			<LcLoading loading={loading} label="Carregando..." >
				{
					ccs.length > 0 ?
						ccs.map((cc, i) => {
							let ccd = ccData.find(ccd => ccd.id.toString() === cc);
							return(
								<div key={i} style={{ color: ccd?.active ? "var(--text-default)" : "#2e1956ad" }}>
										<h5>Centro de custo: {ccd?.name}</h5>
										<div className='row'>
											<h6 >
												Orçamento mensal atual: {ccd && formatCurrency(ccd.budget)}
												{/* <i className={`ppi ppi-pencil ppi-2x ml-5 ${ccd.active ? 'link' : ''}`} onClick={() => ccd?.active ? onEdit(cc) : null} /> */}
											</h6>
											<LcIconLink icon={<PiPencilSimpleLight style={{ color: ccd?.active ? 'var(--color-primary-blue)' : 'inherit' }} />}
												tooltipPosition='left' size='small' onClick={() => ccd?.active ? onEdit(cc) : null} tooltip='Editar centro de custo' />
										</div>
										{
											!(visibleMode === 1 && ccd?.budget === 0) ?
												<>
													{
														budgetData &&
														<div className="pp-table"
															style={{ height: budgetData.find(bd => bd.cc === cc)?.show ? '175px' : '115px' }}
														>
															<div className="header">
																<div className="cells">
																	{
																		columns.map((c, i) => {
																			return <div key={i} className={`cell  ${c.align || ''}`} style={{ width: c.width, color: ccd?.active ? "var(--text-default)" : "#2e1956ad" }}>
																				<span>{c.headerName}</span>
																			</div>
																		})
																	}
																</div>
															</div>

															<div className="body highdensity">
																<AssembleBody budgetData={budgetData} columns={columns} data={budgetData.filter(bd => bd.cc === cc)[0]} setBudgetData={setBudgetData} />
															</div>
														</div>
													}
												</>
												:
												<h5>Não é possível calcular os valores em percentuais quando o orçamento está zerado</h5>
										}
									<hr />
								</div>
								

							)
						})
						:
						<div className="centralized p-7">
							<i className="lci lci-exclamation lci-2x text-warning" />
							Não foram encontrados dados de orçamento.
						</div>
				}
			</LcLoading> 
		</div> 
	</div>
	)
}
