import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LcIconLink from '../../../components/Generic/LcIconLink';
import Layout from '../../../components/Layout/Layout';
import FinOpsMapManagerService, { FinOpsAlert, FinOpsAlertsCondition, FinOpsAlertsGroup } from '../../../services/finOpsManagerService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

import './index.css';
import { DialogContentText, Toolbar } from '@material-ui/core';
import Confirmation from '../../../components/Dialog/Confirmation';
import { PortalUsersService } from '../../../services/portalUsers/portalUsers';
import AlertasEditor from './edit';
import { useSnackbar } from 'notistack';
import {  PPModal } from 'processor-plataform-ui';
import LcTooltip from '../../../components/Generic/LcTooltip';
import { PiArrowBendUpLeftLight, PiArrowDownLight, PiArrowLeftLight, PiArrowUpLight, PiClockClockwiseLight, PiCopyLight, PiEyeLight, PiPencilSimpleLight, PiPlusLight, PiTrashLight, PiWarningCircleLight } from 'react-icons/pi';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';

const FinOpsAlertasVersaoDois: React.FC = (props) => {
    const user = useSelector<RootState, UserState>((state) => state.user);

    const { enqueueSnackbar } = useSnackbar();
    const [allocationVersionLoading, setAllocationVersionsLoading] = useState<boolean>(false);
    const [allocationHistoryVersionLoading, setAllocationHistoryVersionLoading] = useState<boolean>(false);

    const [costCenters, setCostCenters] = useState<any[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [clientGroupUsers, setClientGroupUsers] = useState<any[]>([]);
    const [versionShow, setVersionShow] = useState(false);
    const [viewVersion, setViewVersion] = useState(false);
    const [alertsVersionsData, setAlertsVersionsData] = useState<any[]>([]);
    const [alertsWithDisabledCCs, setAlertsWithDisabledCCs] = useState<any[]>([]);
    const [viewAlertsWithDisabledCCs, setViewAlertsWithDisabledCCs] = useState<boolean>(false);
    const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
    const [confirmRestoreVisible, setRestoreVisible] = useState(false);
    const [version, setVersion] = useState<{ name: string, lastModified: string }>();
    const [alertsToRestore, setAlertsToRestore] = useState();
    const [alertToRemove, setAlertToRemove] = useState<FinOpsAlert | undefined>();
    const [alertsTable, setAlertsTable] = useState<FinOpsAlert[]>([]);
    const [versionAlertsTable, setVersionAlertsTable] = useState<FinOpsAlert[]>([]);
    const [savingAlerts, setSavingAlerts] = useState(false);
    const [showAddAlert, setShowAddAlert] = useState(false);
    const emptyRecordCondition: FinOpsAlertsCondition = {
        idCondition: 1,
        condition: 'variacao',
        percentage: 1,
        comparator: 'mais',
        budget: '0'
    };

    const emptyrecord: FinOpsAlert = {
        id: 0,
        description: '',
        order: 0,
        conditions: [emptyRecordCondition],
        period: {
            idPeriod: 1,
            period: 'ultimos',
            periodValue: 1,
            periodUnit: 'dias'
        },
        type: 'expenses',
        contracts: [],
        costCenters: [],
        resources: [],
        services: [],
        escope: 'global',
        frequency: 'onEventAndAfter',
        usersToNotify: []
    };

    const emptyRecordGroup: FinOpsAlertsGroup = {
        id: 0,
        clientGroupId: user.ClientGroupSelected!,
        month: 0,
        year: 0,
        endDate: null,
        startDate: null,
        version: '',
        type: '',
        name: '',
        updatedByUser: '',
        updatedByUserName: '',
        updatedByUserEmail: '',
        updatedAt: '',
        alerts: []
    }

    const [createAlert, setCreateAlert] = useState<FinOpsAlert | undefined>(undefined);
    const [infoAlert, setInfoAlert] = useState<FinOpsAlert | undefined>(undefined);
    const [editAlert, setEditAlert] = useState();
    const [versionRecord, setVersionRecord] = useState<FinOpsAlertsGroup>(emptyRecordGroup);

    const getDisabledCostCentersFromVersion = (version: any[], costCenters: any[])  => {
        let _disabledCcs = [...costCenters].filter(cc => cc.disabled);
        let _alertsWithDisabledCCs: any[] = [];
        
        version.forEach(alert => {
            let _ccs: any[] = [];
            alert.costCenters.forEach(cc => {
                if (_disabledCcs.some(cc2 => cc2.value.toString() === cc.value)) {
                    _ccs.push(cc.label);
                }
            });
            if (_ccs.length > 0) {
                _alertsWithDisabledCCs.push({ description: alert.description, CCs: _ccs });
            }
        });
        return _alertsWithDisabledCCs;
    }
    
    const retrieveAlertsByVersionName = useCallback(async (versionName: string, versionTable: boolean, costCenter: any[]) => {
        let result: any = [];
        if (versionTable) {
            setAllocationHistoryVersionLoading(true);
        }
        try {
            const finOpsMapManagerService = new FinOpsMapManagerService({})
            const response = await finOpsMapManagerService.GetFinOpsAlerting(versionName);
            result = response.alerts;
    
            let _alertsWithDisabledCCs= getDisabledCostCentersFromVersion(result, costCenter);
            setAlertsWithDisabledCCs(_alertsWithDisabledCCs);
    
            if (_alertsWithDisabledCCs.length > 0) {
                setViewAlertsWithDisabledCCs(true);
            }
    
            let utcDate = new Date(response.updatedAt);
            let offset = -0; // UTC-3, which is -180 minutes
            let lastModified = moment(utcDate).subtract(offset, 'minutes').format("DD/MM/YYYY, HH:mm");
    
            setVersion({ name: versionName, lastModified });
    
            if (versionTable) {
                setVersionAlertsTable(result);
                setAllocationHistoryVersionLoading(false);
                setViewVersion(true);
            } else {
                if (result.length === 0) setShowAddAlert(true);
                setAlertsTable(result);
                setShowAddAlert(true);
            }
        } catch (error) {
            if (versionTable) {
                setAllocationHistoryVersionLoading(false);
            }
            console.warn(error);
        } finally {
            setAllocationHistoryVersionLoading(false);
            setAllocationVersionsLoading(false);
        }
    
        return result;
    }, []);
    
    const retrieveData = useCallback(async() => {
        setAllocationVersionsLoading(true);
        setVersionRecord(
            {
                id: 0,
                clientGroupId: user.ClientGroupSelected!,
                month: 0,
                year: 0,
                endDate: null,
                startDate: null,
                version: '',
                type: '',
                name: '',
                updatedByUser: '',
                updatedByUserName: '',
                updatedByUserEmail: '',
                updatedAt: '',
                alerts: []
            });

        const [usersData ,costCenterData, constractsData, alertingVersionData ] = await Promise.all([
            retrieveClientGroupUsers(),
            retrieveCostCenters(),
            retrieveContracts(),
            getAlertingVersion()
        ])
        retrieveAlertsByVersionName(alertingVersionData[0].name.split('/')[3].split('.')[0], false, costCenterData);
        setContracts(constractsData)
        setClientGroupUsers(usersData)
        setCostCenters(costCenterData)
        setAlertsVersionsData(alertingVersionData)
        setAllocationVersionsLoading(false);
    },[retrieveAlertsByVersionName, user.ClientGroupSelected])

    useEffect(() => {
        retrieveData();
    }, [retrieveData, user.ClientGroupSelected, user.refreshFlag])

    
    const retrieveCostCenters = async(): Promise<any> => {
        try {
            const finOpsMapManagerService = new FinOpsMapManagerService({})
            var resulBase = await finOpsMapManagerService.LoadCostCenters();
            var result = resulBase.sort(
                (element1: any, element2: any) => {
                    return element1.name.localeCompare(element2.name, 'pt-br');
                }
            ).map((u) => {
                return {
                    "label": u.name,
                    "value": u.id,
                    "disabled": !u.active
                };
            })
            return result
        } catch (error) {
            console.error(`Um erro ocorreu: ${error}`)
            throw new Error(`Um erro ocorreu: ${error}`)
        }
    }

    const getAlertingVersion = async () => {
        try {
            const finOpsMapManagerService = new FinOpsMapManagerService({})
            const response = await finOpsMapManagerService.GetAlertingVersions();
            return response;
        } catch (error) {
            console.error(`Um erro ocorreu: ${error}`)
            throw new Error(`Um erro ocorreu: ${error}`)
        } finally{
            setShowAddAlert(true);
        }
    }


    const retrieveContracts =  async() => {
        try {
            const finOpsMapManagerService = new FinOpsMapManagerService({})
            var contractListAdmin = (await finOpsMapManagerService.LoadContracts()).sort(
                (element1: any, element2: any) => {
                    return element1.contractName.localeCompare(element2.contractName, 'pt-br');
                }
            ).map((u) => {
                return {
                    "label": u.contractName,
                    "value": u.contractId
                };
            })
            return contractListAdmin
        }
        catch (error) {
            console.error(`Um erro ocorreu: ${error}`)
            throw new Error(`Um erro ocorreu: ${error}`)
        }
    };


    const retrieveClientGroupUsers = async() => {
        try {
            const usersService = new PortalUsersService({});
            const response = await usersService.getActiveUsers()
            return response.data.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 })
            .map(user => { return { label: user.name, value: user.id, selected: false } });

        } catch (error) {
            console.error(`Um erro ocorreu: ${error}`)
            throw new Error(`Um erro ocorreu: ${error}`)
        } 
    }



    async function downRankAlert(alert) {

        var rulesBelow = alertsTable.filter(c => c.order > alert.order);
        let max = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            max = Math.min.apply(null, ids);
        } else {
            return;
        }
        alertsTable.filter(c => c.order === max).map(item => {
            item["order"] = alert.order;

        });

        alert["order"] = max;
        setAlertsTable([...alertsTable]);
        saveAlertGroup();
    }

    async function upRankAlert(alert) {

        var rulesBelow = alertsTable.filter(c => c.order < alert.order);
        let min = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            min = Math.max.apply(null, ids);
        } else {
            return;
        }
        alertsTable.filter(c => c.order == min).map(item => {
            item["order"] = alert.order;

        });

        alert["order"] = min;
        setAlertsTable([...alertsTable]);
        saveAlertGroup();
    }

    function duplicateRuleRecord(item?: any) {
        var newRecord = JSON.parse(JSON.stringify(item));
        newRecord.description = item.description + ' - Cópia';
        let max = 0;
        let maxorder = 0;
        let rules = alertsTable

        if (alertsTable.length > 0) {
            max = Math.max.apply(null, rules.map(object => {
                return object.id;
            }));
        }

        newRecord.id = max + 1;

        if (rules.length > 0) {
            const idOrders = rules.map(object => {
                return object.order;
            });
            maxorder = Math.max.apply(null, idOrders);
        }

        if (maxorder === 0) {
            newRecord.order = 1;
            if (item !== undefined) {
                maxorder = item.order;
                var items = alertsTable.filter(x => x.order >= item.order);
                items.forEach(row => {
                    row.order = row.order + 1;
                });
            }
        } else {
            if (item !== undefined) {
                newRecord.order = item.order + 1;
                maxorder = item.order + 1;
                var itemsnew = alertsTable.filter(x => x.order > item.order);
                itemsnew.forEach(row => {
                    row.order = maxorder + 1;
                    maxorder++
                });
            }
        }

        alertsTable.push({ ...newRecord });
        setAlertsTable([...alertsTable]);
        saveAlertGroup();
    }

    function removeRecord(item) {
        let minOrder = 0;
        let result = alertsTable.filter(x => x.id !== item.id);
        if (result.length > 0) {
            const idOrders = alertsTable.map(object => {
                return object.order;
            });
            minOrder = Math.min.apply(null, idOrders);
        }

        result.forEach(row => {
            row.order = minOrder;
            minOrder++;
        });

        setAlertsTable([...result]);
        saveAlertGroup(result)
    }

    async function saveAlertGroup(alertGroup?: FinOpsAlert[]) {
        setSavingAlerts(true);
        versionRecord.alerts = alertGroup ? alertGroup : alertsTable;
        versionRecord.updatedAt = moment().toISOString();
        versionRecord.updatedByUser = user.UserId!;
        versionRecord.updatedByUserName = user.name!;
        versionRecord.updatedByUserEmail = user.email!;
        versionRecord.month = parseInt(moment().format('M'));
        versionRecord.year = moment().year();
        versionRecord.name = `Versão:${versionRecord.month}/${versionRecord.year}`;
        try {
            const finOpsMapManagerService = new FinOpsMapManagerService({})
            finOpsMapManagerService.SaveFinOpsAlerting(versionRecord)
                .then(async response => {
                    await retrieveData();
                    setSavingAlerts(false);
                }).catch((e) => {
                    console.warn(e);
                    enqueueSnackbar(`Ocorreu um erro ao tentar salvar o alerta`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false
                    }
                    )
                })

        } catch (e) {
            await retrieveData();
            setSavingAlerts(false);
            console.warn(e);
        }
    }

    const columns = [
        {
            field: 'name', headerName: 'Alerta', width: '45%',
            renderCell: (row: any) => {
                return row.name.split('/')[3].split('.')[0]
            }
        },
        {
            field: 'lastModified', headerName: 'Data / Hora', width: '15%', align: "right",
            renderCell: (row: any) => {
                let utcDate = new Date(row.lastModified);
                let offset = -180; // São Paulo is UTC-3, which is -180 minutes
                let lastModified = moment(utcDate).utcOffset(offset).format("DD/MM/YYYY HH:mm");
                return lastModified;
            }
        },

        {
            field: '', headerName: '', width: `${user.IsAdm ? '10%' : '15%'}`, showOnHover: true, align: "center",
            renderCell: (row: any) => {
                return <>

                    <LcIconLink size="small" icon={<PiEyeLight />} tooltip='Visualizar' tooltipPosition='right' onClick={() => retrieveAlertsByVersionName(row.name.split('/')[3].split('.')[0], true, costCenters)} />
                    <LcIconLink size="small" icon={<PiArrowBendUpLeftLight />} tooltip='Restaurar' tooltipPosition='right' onClick={() => { setRestoreVisible(true); setAlertsToRestore(row); }} />
                </>
            }
        },
        {
            field: 'metadata', headerName: 'Alterado por', width: `${user.IsAdm ? '25%' : '30%'}`,
            renderCell: (row: any) => {
                return row.metadata && row.metadata.updatedByUserName ? row.metadata.updatedByUserName : 'Sem informação'
            }
        }
    ]

    const handleSideModal = (type: string, alert: any) => {
        switch (type) {
            case 'edit':
                setEditAlert({ ...alert });
                setInfoAlert(undefined);
                break;
            case 'info':
                setInfoAlert({ ...alert });
                setEditAlert(undefined);
                break;
        }
        setViewVersion(false)
        setVersionShow(false)
    }

    const handleRemoveConfirmation = (text?: string) => {
        return (<> Tem certeza que deseja excluir o alerta <b>{text}</b>?</>)
    }

    const getFunctionsGeneric = () => {
        return <>
            <LcIconLink icon={<PiClockClockwiseLight />} onClick={() => setVersionShow(!versionShow)} tooltip="Histórico de versões"  />
            {showAddAlert &&
                <LcIconLink icon={<PiPlusLight />} onClick={() => setCreateAlert(emptyrecord)} tooltip="Nova alerta" />
            }
        </>
    }

    const handleRestoreConfirmataion = (alert: any) => {
        return (<> Deseja restaurar as regras para a versão <b>{alert.name.split('/')[3].split('.')[0]}</b>?</>)
    }

    async function restoreAlerts(version: any) {
        try {
            let result = await retrieveAlertsByVersionName(version.name.split('/')[3].split('.')[0], false, costCenters);
            let _alertsWithDisabledCCs = getDisabledCostCentersFromVersion(result, costCenters);
            setAlertsWithDisabledCCs(_alertsWithDisabledCCs);
            if (_alertsWithDisabledCCs.length > 0) {
                setViewAlertsWithDisabledCCs(true);
            }
            await saveAlertGroup(result);
            setVersionShow(false)
            retrieveData();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Layout
            pageTitle="FinOps"
            row={!(versionShow || savingAlerts || allocationVersionLoading) && getFunctionsGeneric()}
            loading={savingAlerts || allocationVersionLoading}
        >

            <Confirmation
                title="Confirme"
                text={handleRemoveConfirmation(alertToRemove?.description)}
                confirm={() => { setConfirmRemoveVisible(false); removeRecord(alertToRemove) }}
                close={() => { setConfirmRemoveVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmRemoveVisible}
            />

            <Confirmation
                title="Confirme"
                text={alertsToRestore && handleRestoreConfirmataion(alertsToRestore)}
                confirm={() => { setRestoreVisible(false); restoreAlerts(alertsToRestore); }}
                close={() => { setRestoreVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmRestoreVisible}
            />

            <PPModal
                title="Alertas precisam de revisão!"
                onClose={() => setViewAlertsWithDisabledCCs(false)}
                visible={!viewVersion && viewAlertsWithDisabledCCs}
                functions={[
                    {
                        label: "Ok",
                        onClick: () => setViewAlertsWithDisabledCCs(false),
                        context: "info"
                    }
                ]}
            >
                <DialogContentText>
                    <p>Um ou mais Alertas desta versão estão vinculados a <strong>Centros de Custo desabilitados</strong>:</p>
                    <ul style={{ margin: '15px' }}>
                        {
                            alertsWithDisabledCCs.length > 0 && alertsWithDisabledCCs.map(alert => {
                                return (
                                    <li>
                                        Regra "<strong>{alert.description}</strong>":
                                        <ul>
                                            {alert.CCs.length > 0 && alert.CCs.map(cc => (<li>{cc}</li>))}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <p>Edite as Regras listadas para desvincular os Centros de Custo desabilitados.</p>
                </DialogContentText>
            </PPModal>

            {createAlert &&
                <AlertasEditor
                    onCancelAction={() => {
                        setCreateAlert(undefined);
                    }}
                    onSaveGroup={() => {
                        retrieveData();
                        setCreateAlert(undefined);
                    }}
                    alertGroup={alertsTable}
                    alert={createAlert}
                    readOnly={false}
                    costCenters={costCenters}
                    contracts={contracts}
                    clientGroupUsers={clientGroupUsers}
                >
                </AlertasEditor>
            }

            {editAlert &&
                <AlertasEditor
                    onCancelAction={() => {
                        setEditAlert(undefined);
                    }}
                    onSaveGroup={() => {
                        retrieveData();
                        setEditAlert(undefined);
                    }}
                    alertGroup={alertsTable}
                    alert={editAlert}
                    readOnly={false}
                    costCenters={costCenters}
                    contracts={contracts}
                    clientGroupUsers={clientGroupUsers}
                >
                </AlertasEditor>
            }

            {infoAlert &&
                <AlertasEditor
                    readOnly={true}
                    onCancelAction={() => { setInfoAlert(undefined); }}
                    alertGroup={alertsTable}
                    alert={infoAlert}
                    costCenters={costCenters}
                    contracts={contracts}
                    clientGroupUsers={clientGroupUsers}
                >
                </AlertasEditor>
            }

            <PPModal
                loading={allocationHistoryVersionLoading}
                size='large'
                visible={versionShow}
                onClose={() => { setVersionShow(false) }}
                title="Histórico de versões"
            >
                {
                    viewVersion ?
                        <>
                            {
                                version &&
                                <div className="row">
                                    <LcIconLink
                                        icon={<PiArrowLeftLight />}
                                        onClick={() => { setViewVersion(false); }}
                                        tooltip="Voltar"
                                        tooltipPosition='right' />
                                    <b>{version.name}</b>
                                    {` (${version.lastModified})`}
                                </div>
                            }
                            {
                                versionAlertsTable.sort((a, b) => a.order > b.order ? 1 : -1).map(
                                    (item, index) => {
                                        return (
                                            <div key={index} className='descriptionDiv default'>
                                                <div onClick={() => { handleSideModal('info', item) }} className='dleft'>
                                                    <LcTooltip content="Ordem" position="right" trigger="hover"> <span className='order'>{item.order}</span> </LcTooltip>
                                                    {item.description}
                                                </div>
                                                <div className='right'>
                                                    <Toolbar disableGutters>
                                                        <LcTooltip content='Visualizar' trigger='hover' position='right' >
                                                            <LcIconLink
                                                                size={'medium'}
                                                                icon={<PiEyeLight />}
                                                                onClick={() => { handleSideModal('info', item) }} />
                                                        </LcTooltip>
                                                    </Toolbar>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </>
                        :
                        <>
                            <LcInfiniteTable
                                loading={allocationVersionLoading}
                                rows={alertsVersionsData}
                                columns={columns}
                                className='infinitetable-with-tooltip'                                
                            />
                        </>
                }
            </PPModal>
            {
                alertsTable.sort((a, b) => a.order > b.order ? 1 : -1)
                    .map(
                        (alert: any, index) => {
                            return (
                                <div key={index}>
                                    <div className='descriptionDiv default'>
                                        <div onClick={() => { handleSideModal('info', alert) }} className='dleft'>
                                            <LcTooltip content="Ordem" position="center" trigger="hover"> <span className='order'>{alert.order}</span> </LcTooltip>
                                            {alert.description}

                                            {
                                                !viewVersion && alertsWithDisabledCCs.length > 0 && alertsWithDisabledCCs.some(el => el.description === alert.description) &&
                                                <LcTooltip content="Regra precisa de revisão!" position="center" trigger="hover">
                                                    <PiWarningCircleLight color='var(--status-red)' style={{ marginLeft: '15px' }} />
                                                </LcTooltip>
                                            }
                                        </div>
                                        <div className='right'>
                                            <Toolbar disableGutters>
                                                <LcIconLink size={'medium'} icon={<PiPlusLight />} onClick={() => { setCreateAlert({ ...emptyrecord, order: alertsTable.length }); }} tooltip="Adicionar alerta" />
                                                <LcIconLink size={'medium'} icon={<PiCopyLight />} onClick={() => { duplicateRuleRecord(alert); }} tooltip="Duplicar" />
                                                <i className='ppi ppi-divider-vertical lci-5x text-grey' />
                                                <LcIconLink size={'medium'} icon={<PiPencilSimpleLight />} onClick={() => {
                                                    handleSideModal('edit', alert);
                                                }} tooltip="Editar alerta" />
                                                <LcIconLink disabled={alertsTable.length < 2} size={'medium'} icon={<PiTrashLight />} onClick={() => { setConfirmRemoveVisible(true); setAlertToRemove(alert); }} tooltip="Excluir alerta" />
                                                <i className='ppi ppi-divider-vertical lci-5x text-grey' />
                                                <LcIconLink disabled={Math.min.apply(null, alertsTable.map(object => { return object.order; })) === alert.order} size={'medium'} icon={<PiArrowUpLight />} onClick={() => { upRankAlert(alert); }} tooltip="Mover acima" />
                                                <LcIconLink disabled={Math.max.apply(null, alertsTable.map(object => { return object.order; })) === alert.order} size={'medium'} icon={<PiArrowDownLight />} onClick={() => { downRankAlert(alert); }} tooltip="Mover abaixo" tooltipPosition='right' />
                                            </Toolbar>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )
            }
        </Layout >
    );
};

export default FinOpsAlertasVersaoDois;