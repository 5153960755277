export class disasterrecovery {
    solution() {
        return 'disasterrecovery'
    }

    site() {
        return 'https://www.gotobiz.com.br/livecloud-disaster-recovery'
    }

    logotipo() {
        return require('./assets/logotipos/logo_disasterrecovery.svg').default
    }
    titulo() {
        return <><b>Proteja o futuro do negócio</b> com LiveCloud Disaster Recovery</>
    }

    chamada() {
        return <>Oferecemos uma solução completa de recuperação de desastres, garantindo que a organização esteja sempre pronta para enfrentar qualquer eventualidade. Estamos prontos para ajudar a proteger os dados e manter as operações em andamento.</>
    }

    image() {
        return require('./assets/imgs/imagem_disasterrecovery.png').default
    }

    detalhes() {
        return <>
            <h2>Já imaginou o que aconteceria se todos os dados da organização desaparecessem de repente?</h2>
            <p><b>Com LiveCloud Disaster Recovery, as organizações não precisam mais se preocupar com isso.</b></p>
            <h3>Por que escolher LiveCloud Disaster Recovery?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_disasterrecovery.png').default} />
                </div>
                <ul>
                    <li><b>Segurança de dados:</b> Protegemos os dados com as mais avançadas tecnologias de segurança, com integridade e confidencialidade.</li>
                    <li><b>Continuidade dos negócios:</b> Minimizamos o impacto de qualquer desastre, para que as operações da organização continuem sem interrupções.</li>
                    <li><b>Suporte especializado:</b> Nossa equipe de especialistas está sempre pronta para ajudar, oferecendo suporte 24/7.</li>
                    <li><b>Economia:</b> Com LiveCloud Disaster Recovery, a organização pode economizar ao evitar perdas de dados e interrupções nas operações.</li>
                    <li><b>Conformidade:</b> Nossas soluções estão em conformidade com os padrões internacionais de excelência em cibersegurança.</li>
                </ul>
            </div>
            <div className="box">LiveCloud Disaster Recovery é mais do que uma solução de recuperação de desastres. É um auxilio para a <b>organização estar sempre pronta para enfrentar qualquer desafio</b>, protegendo seus dados e mantendo suas operações em andamento.</div>
            <h3>Não deixe para depois!</h3>
            <p>Não espere um desastre acontecer para começar a pensar em recuperação de desastres. Com LiveCloud Disaster Recovery, a organização estará mais protegida.</p> 
            <p>Entre em contato conosco hoje mesmo e descubra como podemos ajudar a proteger o futuro do negócio.</p>            
        </>
    }
}
export default disasterrecovery;