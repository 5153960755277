import { Grid } from '@material-ui/core';
import React from 'react';

import Layout from '../../../components/Layout/Layout';
import styles from './index.module.css';
import SadDog1 from '../../../assets/images/saddog.svg';
import SadDog2 from '../../../assets/images/saddog2.svg';
import SadDog3 from '../../../assets/images/saddog3.svg';

function chooseSadDog(min, max) {
    switch(parseInt(Math.random() * (max - min) + min))
    {
        case 1:
            return SadDog1;
            break
        case 2:
            return SadDog2;
            break;
        case 3:
            return SadDog3;
            break;
        default:
            return SadDog1;
    }
}

const GenericError: React.FC = () => {
    
    return (
        <Layout>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                    <img className={styles.sadDog} src={chooseSadDog(1,3.9)} />
                </Grid>
                <Grid item>
                    <h2>Ooopsss!</h2>
                </Grid>
                <Grid item>
                    <p className={styles.errorMessage}>Algo não previsto ocorreu. Já estamos cientes e iremos trabalhar no assunto.</p>
                </Grid>
            </Grid>
        </Layout>
    );
}

export default GenericError;