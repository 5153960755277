import React from "react";

import "./index.css";
import { Doughnut } from "react-chartjs-2";
import LcTooltip from "../../../Generic/LcTooltip";

type Status = "success" | "warning" | "danger";

interface GaugeProps {
  title: string,
  value: number,
  info?: string,
  onClick?: Function,
  steps?: { percent: number, status: Status }[],
  tooltipInfo?: string,
  tooltipColor?: string,
  tooltipNode?: React.ReactNode,
  fullHeigth?: boolean
  resumePage?: boolean
}

const GaugeChart: React.FC<GaugeProps> = (props) => {

  var data = {
    datasets: [
      {
        backgroundColor: props.steps && props.steps.map(i => i.status == 'success' ? '#A5BF3E' : i.status == 'warning' ? '#F3C944' : '#E64646'),
        data: props.steps && props.steps.map(i => i.percent),
        borderWidth: 5
      }]
  };

  return (
    <LcTooltip width="100%" position="top" trigger="hover" content={props.tooltipNode}>
      <div className={`gauge-chart ${props.onClick ? 'link' : ''}`} style={{ minHeight: props.resumePage ? '120px': '', height: props.fullHeigth ? '100%' : 'fit-content', padding: props.resumePage ? '.6rem' : '' }} 
      onClick={() => props.onClick && props.onClick()}>
      <div className="header">
        <div className="title" style={{fontSize: props.resumePage ? '.8rem' : ''}}>{props.title}</div>
        {
          props.tooltipInfo &&
          <LcTooltip content={props.tooltipInfo} position="left" trigger="hover">
            <i className="lci lci-information-circle" style={{color: props.tooltipColor ? props.tooltipColor : '#000000'}}></i>
          </LcTooltip>
        }
      </div>

      <div className="chart">
        <div className="body" style={{height: props.resumePage ? '75px' : '', width: props.resumePage ? '75px' : ''}}>
          <Doughnut
            data={data}
            options={{
              legend: {
                display: false,
              },
              cutoutPercentage: 75,
              circumference: Math.PI,
              rotation: Math.PI,
              tooltips: { enabled: false },
              hover: { mode: null },
            }}
            width={props.resumePage ? 75 : 160}
            height={props.resumePage ? 75 : 160}
          />
          <i className="lci lci-gauge-needle" style={{ transform: `rotate(${props.value ? 180 / 100 * props.value : 0}deg)`, top: props.resumePage ? '25px' : '', fontSize: props.resumePage ? '55px' : '' }} />
        </div>
        <span className="number" style={{fontSize: props.resumePage ? '1rem': ''}}>{props.value.toFixed(0) + "%"}</span>
        {
          props.info &&
          <span className="label" style={{fontSize: props.resumePage ? '.7rem': ''}} >{props.info}</span>
        }
      </div>
    </div>
    </LcTooltip>
  );
};

export default GaugeChart;
