export class receeba {
    solution() {
        return 'receeba'
    }

    site() {
        return 'https://www.gotobiz.com.br/receeba'
    }

    logotipo() {
        return require('./assets/logotipos/logo_receeba.svg').default
    }
    titulo() {
        return <>Transforme a <b>gestão de cobrança</b> com Receeba!</>
    }

    chamada() {
        return <>Solução inteligente que simplifica a gestão de cobrança, automatizando, humanizando e potencializando o atendimento aos clientes. Receeba! centraliza e otimiza as comunicações financeiras da organização.</>
    }

    image() {
        return require('./assets/imgs/imagem_receeba.png').default
    }

    detalhes() {
        return <>
            <h2>Receeba! - A solução inteligente para recuperação de recebíveis</h2>
            <h3>Por que escolher Receeba!?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_receeba.png').default} />
                </div>
                <ul>
                    <li><b>Comunicação omnichannel:</b> Comunique-se com os clientes para relacionamento e gestão de cobrança através de SMS, WhatsApp, e-mail e redes sociais.</li>
                    <li><b>Lembretes de vencimento:</b> Envie lembretes de vencimento de fatura para evitar atrasos de pagamento.</li>
                    <li><b>Negociações e cenários:</b> Facilite a negociação de dívidas com cenários flexíveis e personalizados.</li>
                    <li><b>Higienização de base de contatos:</b> Mantenha a base de contatos atualizada e organizada.</li>
                </ul>
            </div>
            <div className="box">Receeba! faz parte da família de <b>soluções de Hiperautomação</b>, que combina várias tecnologias de automação para criar ambientes altamente automatizados e eficientes. Isso maximiza a produtividade, reduz erros e permite foco nas atividades mais estratégicas.</div>
            <h3>Pronto para transformar a gestão de cobrança?</h3>
            <p>Descubra como Receeba! pode revolucionar a <b>recuperação de recebíveis</b> na organização. Entre em contato conosco hoje mesmo para saber mais.</p>     
        </>
    }
}
export default receeba;