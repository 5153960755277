import React from 'react';

interface Props {
    height?: string;
}


const VerticalDivisor: React.FC<Props> = (props) => {

    const style = {
        display: 'inline-block', height: props.height ? props.height : '24px', width: '1px', backgroundColor: 'rgba(0,0,0,.2)'
    }

    return (
        <span style={style}></span>
    );
}

export default VerticalDivisor;