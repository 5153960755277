import api, { APIClient } from "../api";

export interface WebSite {
    nome: string,
    isInDeployment: boolean,
    baseLineStatus: boolean,
    dashBoardURL: string,
    hostGroup: string,
    status: number
    costCenterID: number
}

export class WebSitesService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    GetWebSites() {
        const url = this.endpoint + `/api/WebSites/GetWebSites`;
        return api({
            method: 'get',
            url,

        });
    }

    GetWebSiteStatus(hostGroupName: string) {
        const url = this.endpoint + `/api/WebSites/GetHostGroupStatus/${hostGroupName}`;
        return api({
            method: 'get',
            url,
        });
    }

    GetWebSiteProblems(hostGroupName: string) {
        const url = this.endpoint + `/api/WebSites/GetHostGroupProblems/${hostGroupName}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetCards(month: string, year: string) {
        const url = this.endpoint + `/api/WebSites/GetProductLiveWatchSlaTotalSolicitation?month=${month}&year=${year}`;
        return api({
            method: 'get',
            url,
        });
    }


    async GetLiveWatchCard() {
        const url = this.endpoint + `/api/WebSites/GetCardHost`;
        return api({
            method: 'get',
            url,
        });
    }

    GetStatus(status: number, isHome: boolean = false) {
        return status < 1 ? {
            color: "darkgreen",
            bgcolor: "lightgreen",
            text: isHome ? "" : "OK",
            icon: "ppi ppi-check"
        } : {
            color: "#bf9000",
            bgcolor: "#ffd966",
            text: isHome ? "" : "Ver detalhes",
            icon: "lci lci-exclamation"
        }
    }
}