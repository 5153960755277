import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import { defaultTooltipConfig } from '../../../components/Generic/eChartsConfig';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import ReactECharts from 'echarts-for-react';
import { SaasService } from '../../../services/saas/saasService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

interface ActiveUsersPerDayChartModel {
    userPeriod: number
}

export const ActiveUsersPerDayChart: React.FC<ActiveUsersPerDayChartModel> = (
    {
        userPeriod,
    }) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState(true);
    const [optionsChart, setOptionsChart] = useState<any>({});

    const getOptionsChart = useMemo(() => (usersChart) => {
        return {
            grid: {
                top: '5%',
                left: '2%',
                right: '2%',
                bottom: '6%',
                containLabel: true
            },
            color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],

            tooltip: {
                ...defaultTooltipConfig,
                trigger: 'axis',
                formatter: function (params) {
                    return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                }
            },
            xAxis: {
                type: 'category',
                data: usersChart.labels,
                axisLabel: {
                    fontSize: '10px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
            },
            yAxis: {
                type: 'value',
                //interval: 150,
                axisLabel: {
                    fontSize: '10px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
            },
            series: usersChart.datasets,
        }
    },[])

    const UsersChartData = useMemo(() => (response: any): Chart => {
        if (response !== null) {
            let usersChart = {
                labels: response.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: 'Office365',
                        data: response.map((Item: any) => {
                            return Item.office365
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Exchange',
                        data: response.map((Item: any) => {
                            return Item.exchange
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'OneDrive',
                        data: response.map((Item: any) => {
                            return Item.oneDrive
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'SharePoint',
                        data: response.map((Item: any) => {
                            return Item.sharePoint
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Yammer',
                        data: response.map((Item: any) => {
                            return Item.yammer
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Teams',
                        data: response.map((Item: any) => {
                            return Item.teams
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            return usersChart;

        } else {
            let usersChart = {
                labels: [],
                datasets: []
            };
            return usersChart;
        }
    },[]);

    const getUsersdata = useCallback(async  (days: number) => {
        try {
            setLoading(true)
            const saasService = new SaasService({});
            var response = await saasService.GetUsersChart(days);
            let usersChart = (UsersChartData(response));
            setOptionsChart(getOptionsChart(usersChart))
        } catch (e) {
            console.error(e);
        } finally{
            setLoading(false)
        }
    },[UsersChartData, getOptionsChart])
    
    useEffect(() => {
        getUsersdata(userPeriod);
    },[getUsersdata, userPeriod, user.ClientGroupSelected, user.refreshFlag])

    return(
        <LcLoading loading={loading}>
            {Object.keys(optionsChart).length > 0 ?
                <ReactECharts
                    className="chart-medium"
                    style={{ width: '100%', height: '100%' }}
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    option={optionsChart}
                />
                :
                <LcNoData size="small" label="Sem dados no período." />
            }
        </LcLoading>
    )
}
