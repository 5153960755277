import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { SaasService } from '../../../services/saas/saasService';
import './index.css';
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import GaugeChart from '../../../components/Layout/Dashboard/gaugeChart';
import LcLoading from '../../../components/Generic/LcLoading';
import ReactDOM from 'react-dom';
 
import PivotTableWebix from '../../../components/webix';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

const SaaSDashBoardUserSoftware: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    const [sloChart, setSloChart] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [state, setState] = useState<object>();
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(Moment().format('M'));
    const [year, setYear] = useState<string>(Moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const saasService = new SaasService(props);

    useEffect(() => {
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));

        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        setSloChart(0);
        setSlaChart(0);
        setChamadosChart({ title: "", labels: [], values: [] });
        setTotalChamadosChart({ title: "", labels: [], values: [] });
        setChamadosPorOrigemChart({ title: "", labels: [], values: [] });

        if (user.ClientGroupSelected) {

            setLoading(true);

            saasService.RetrieveUserLicenses()
                .then(response => {
                    if (response.data != null) {
                        setData(response.data);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setLoading(false);
                });

        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {

        let momentDate = Moment(selected.fields[0].value).format('YYYY-M');
        let date = momentDate;
        let currentDay = '01';
        let currentMonth = date.split('-')[1];
        let currentYear = date.split('-')[0];
        setDay(currentDay);
        setMonth(currentMonth);
        setYear(currentYear);
        setSearchStartDate(`${currentYear}${currentMonth.padStart(2, '0')}${currentDay}`);
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-M') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-M') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-M') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    }

    return (
        <Layout pivot pageTitle="Resumo" periodSelection={periodSelection}>
  
                    {data && data.length > 0 && <PivotTableWebix
                        idView={'dataPivot'}
                        key={'dataPivot'} data={data}
                        structure={{
                            rows: ["software"],
                            values: [{ name: "usuario", operation: ["count"] }],
                            filters: [{ name: "usuario" }, { name: "software" }]
                        }}
                    />}   
        </Layout>
    );
};

export default SaaSDashBoardUserSoftware;