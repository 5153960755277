import api, { APIClient } from "../api";

export interface PayLoadModel {
    Prompt: string,
    WhoAreYou: string,
    ModelName: string,
    Owner: string,
    ClientGroupId: number,
    Provider: number,
    Platform: number,
    Instruction: number,
    Subscription: string
}

export default class AixCompanySevice extends APIClient {
    
    constructor(props: any) {
        super(props);
    }

    async GetInquireWithInstruction (text: string, instructionType: number, gtm: string= '', gtmItem: string = '', activeCounter: boolean = false, platform: number = 1  ) {

        const param = {
            gtm,
            gtmItem,
            activeCounter
        };
        const url = `${this.endpoint}/api/AixCompany/InquireWithInstruction?text=${text}&instructionType=${instructionType}&Platform=${platform}`;
        return (await api({
            method: 'post',
            url,
            params: param,
        })).data

    }
}