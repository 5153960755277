import React, { useCallback, useEffect, useMemo, useState } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

//Cpmponentes
import Layout from '../../../components/Layout/Layout';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcCheckList from '../../../components/Form/LcCheckList';
import { FixedSizeList as List } from 'react-window';
import PPExpand2 from '../../../components/Generic/PPExpand2';
import { PiArrowsLeftRight, PiCurrencyCircleDollar, PiEyeLight, PiEyeSlashLight, PiFunnelLight, PiUserList, PiUsers, PiUsersLight } from 'react-icons/pi';
import { FiUserCheck } from 'react-icons/fi';
import Flags from '../../../components/Data/Flags';
//Serviços
import { TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import { NotificationCenterService } from "../../../services/notificationCenter/notificationService";
import "./index.css";
import LcNoData from '../../../components/Generic/LcNoData';
import FilterPanel from '../../../components/Data/FilterPanel';
import { FilterField } from '../../../components/Data/FilterPanel/FilterField';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';

//Tools
const FormatNumber = (value: number) => {
    //Carrega a formatação decimal de acordo com a lingua do browser
    return new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

interface RowProps {
    item: any; // Substitua 'any' pelo tipo de dados correto, se conhecido
    index: number;
    isScrolling?: boolean;
    style?: React.CSSProperties;
}

const Row: React.FC<RowProps> = React.memo(({ item, index, isScrolling, style }) => {
    return (
        <div className="p-3 row-ea-home font-4x" key={'ccI' + index} style={style}>
            <div>{item.costCenter}</div>
            <div className="text-right">{item.totalCC}</div>
            <div className="text-right">$ {FormatNumber(item.totalCC * item.unitPrice)}</div>
        </div>
    );
});


const EABudget: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    //const notification = new NotificationCenterService(props);
    //var saaSGroupService = new techEAEASService(props);

    const notification = useMemo(() => new NotificationCenterService(props), [props]);
    const saaSGroupService = useMemo(() => new TechEAEASService(props), [props]);

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<any[]>([]);
    const [dataAux, setDataAux] = useState<any[]>([]);

    const [countries, setCountries] = useState<{ value: string, selected: boolean }[]>([]);
    const [softwares, setSoftwares] = useState<{ value: string, selected: boolean }[]>([]);
    const [costCenters, setCostCenters] = useState<{ value: string, selected: boolean }[]>([]);

    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [openAccordions, setOpenAccordions] = useState({});
    const [hoverLocked, setHoverLocked] = useState(false);

    useEffect(() => {
        let msg = {
            subject: "Aviso",
            content: "Todos valores e contextos aqui são estimativos e sujeitos a confirmação conforme politica vigente do detentor dos direitos das tecnologias listadas",
            context: "info"
        };
        notification.PostInterfaceNotification(dispatch, msg);
    }, [data])

    const loadData = () => {
        setLoading(true);

        if (document.location.pathname == '/eaBudgets') {
            saaSGroupService.GetSaasLicenseByCountryTotalView()
                .then(response => {
                    setData(response.data);
                    setDataAux(response.data);
                })
                .catch(console.warn)
                .then(() => {
                    setLoading(false);
                });
        }
        if (document.location.pathname == '/eaBudgetsByUser') {
            saaSGroupService.GetSaasLicenseByCountryTotalViewbyUser()
                .then(response => {
                    setData(response.data);
                })
                .catch(console.warn)
                .then(() => {
                    setLoading(false);
                });

        }
    }

    //Disctinc function
    useEffect(() => {
        // Mova operações intensivas para useEffect ou outras funções
        const _countries: { value: string, selected: boolean }[] = [];
        const _softwares: { value: string, selected: boolean }[] = [];
        const _costCenters: { value: string, selected: boolean }[] = [];

        data.forEach(license => {
            if (!_countries.some(c => c.value == license.country)) {
                _countries.push({ value: license.country, selected: true });
            }
            if (!_softwares.some(c => c.value == license.license)) {
                _softwares.push({ value: license.license, selected: true });
            }
            if (!_costCenters.some(c => c.value == license.costCenter)) {
                _costCenters.push({ value: license.costCenter, selected: true });
            }
        });

        setCountries(_countries.sort((a, b) => a.value.localeCompare(b.value)));
        setSoftwares(_softwares.sort((a, b) => a.value.localeCompare(b.value)));
        setCostCenters(_costCenters.sort((a, b) => a.value.localeCompare(b.value)));
    }, [data]);

    useEffect(loadData, [user.ClientGroupSelected, user.refreshFlag])


    const handleFilterClick = useCallback(() => {
        setFilterVisible(prev => !prev);
    }, []);

    const handleHoverLicences = useCallback(() => {
        setHoverLocked(prev => !prev);
    }, []);

    const filteredCountries = useMemo(() => {
        return countries.filter(c => c.selected);
    }, [countries]);

    const filteredSoftwares = useMemo(() => {
        return softwares.filter(s => s.selected && data.some(p => filteredCountries.some(c => p.country == c.value) && p.license == s.value));
    }, [softwares, data, filteredCountries]);

    const formattedNumber = useCallback((value) => {
        return FormatNumber(value);
    }, []);




    const handleToggleAccordion = (key, isExpanded) => {
        setOpenAccordions(prevState => ({
            ...prevState,
            [key]: isExpanded
        }));
    };

    const translationsCountryFilter = {
        country: {
            label: "Pais",
            type: "multiSelect"
        },
        license: {
            label: "Softwares",
            type: "multiSelect",
        },
        costCenter: {
            label: "Centro de custo",
            type: "multiSelect",
        },
    };
    const filterForCountry = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translationsCountryFilter).map(key => ({
                        label: translationsCountryFilter[key].label,
                        name: key,
                        type: translationsCountryFilter[key].type as FilterField['type'],
                        values: translationsCountryFilter[key].values,
                    }))}
                    data={dataAux}
                    onFilteredData={(filteredData) => { console.log(filteredData); setData(filteredData) }}
                    translations={translationsCountryFilter}
                />
            </div>
        )
    }

    const card: Card[] = [
        {
            id: 1,
            type: 'Custom',
            cssClass: 'transparetBackground',
            centeredContent: false,
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix'>
                        {
                            countries.length == 0 &&
                            //<div className="centralized">
                            //    <i className="lci lci lci-exclamation lci-2x text-warning" />
                            //    Não há dados para gerar o orçamento.
                            //</div>
                            <LcNoData label="Não há dados para gerar o orçamento." />
                        }
                        <div className="cards-ea">
                            {
                                filteredCountries.length > 0 &&
                                filteredCountries.map((c, cI) => (
                                    <div key={cI} className="card">
                                        {/* Bandeira e nome do país */}
                                        <h4 className="title-country mb-6"><Flags countryCode={c.value} /> {c.value}</h4>
                                        {
                                            filteredSoftwares.map((s, sI) => {
                                                var costcenters = data.filter(f => f.country == c.value && f.license == s.value && costCenters.find(cc => cc.selected && cc.value == f.costCenter))
                                                    .sort((a: any, b: any) => a.costCenter > b.costCenter ? 1 : -1);

                                                var totalHired = 0;
                                                var totalCC = 0;
                                                var unitPrice = 0;

                                                costcenters.forEach(o => {
                                                    totalHired = o.totalHired;
                                                    totalCC += o.totalCC;
                                                    unitPrice = o.unitPrice;
                                                });

                                                // Verificar se todos os valores são zero
                                                if (totalHired == 0 && totalCC == 0 && (totalHired - totalCC) == 0 && unitPrice == 0) {
                                                    return null; // Não renderizar nada para este software
                                                }

                                                return (
                                                    <PPExpand2
                                                        key={`${cI}-${sI}`} // Chave única combinando índices de país e software
                                                        title={<h5>{s.value}</h5>}
                                                        titleRight={
                                                            <div className="font-4x centralized-row">
                                                                <div className="hover-info">
                                                                    <div title="Previsto"><PiUsersLight size='16px' title="Previsto" className="icon-ea" color="#777" />{totalHired}</div>
                                                                    <div title="Alocado"><PiArrowsLeftRight size='16px' title="Alocado" className="icon-ea" color="#777" />{totalCC}</div>
                                                                    <div className={`${(totalHired - totalCC) < 0 ? ' text-link' : ' text-danger'}`} title="Disponível"><FiUserCheck size='16px' title="Disponível" className={`icon-ea ${(totalHired - totalCC) < 0 ? ' text-link' : ' text-danger'}`} /><b>{totalHired - totalCC <= 0 ? 0 : totalHired - totalCC}</b></div>
                                                                </div>

                                                                <PiCurrencyCircleDollar size='16px' title="Valor total" className="icon-ea" color="#777" />
                                                                <b title="Valor total">
                                                                    {formattedNumber(
                                                                        (data.find(o => o.country == c.value && o.license == s.value && o.unitPrice != null) || {}).unitPrice || 0
                                                                    )}
                                                                </b>
                                                            </div>
                                                        }
                                                        expanded={false} // Inicialmente fechado
                                                        status={(totalHired - totalCC) < 0 ? 'link' : 'danger'}
                                                        marginBotton="5"
                                                        height="40px"
                                                        className={`hoverable ${hoverLocked ? 'fixed-hover' : ''}`}
                                                        onExpand={(isExpanded) => handleToggleAccordion(`${cI}-${sI}`, isExpanded)}
                                                    >
                                                        <>
                                                            <div className="status-ea font-4x">
                                                                <div>Previsto: {totalHired} <br /><span style={{ color: '#777' }}>$ {formattedNumber(totalHired * unitPrice)}</span></div>

                                                                <div>Alocado: {totalCC} <br /> <span style={{ color: '#777' }}>$ {formattedNumber(totalCC * unitPrice)}</span></div>

                                                                <div className={`${(totalHired - totalCC) < 0 ? ' text-link' : ' text-danger'}`}>Disponível: {totalHired - totalCC <= 0 ? <>0</> : <>{totalHired - totalCC} <br /> <span style={{ color: '#777' }}>$ {formattedNumber((totalHired - totalCC) * unitPrice)}</span></>}</div>
                                                            </div>


                                                            <div className="row-ea-home cab-ea">
                                                                <div>CC</div>
                                                                <div className="text-right">Qtd</div>
                                                                <div className="text-right">Total</div>
                                                            </div>

                                                            {/* Tabela dentro do componente PPExpand2 */}
                                                            {
                                                                openAccordions[`${cI}-${sI}`] && costcenters && costcenters.length > 0 &&
                                                                <List key={`${cI}-${sI}-list`}
                                                                    height={130}
                                                                    itemCount={costcenters.length}
                                                                    itemSize={25}
                                                                    itemData={costcenters}
                                                                    className="scrollable-v"
                                                                >
                                                                    {({ index, style }) => <Row item={costcenters[index]} index={index} style={style} />}
                                                                </List>
                                                            }
                                                        </>
                                                    </PPExpand2>
                                                );
                                            })
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </LcLoading>
                )
            }
        }
    ]

    return (
        <Layout
            pageTitle="Orçamento"
            row={
                <>
                    <LcIconLink
                        icon={
                            <PiFunnelLight className={(countries.find(s => !s.selected) || softwares.find(s => !s.selected) || costCenters.find(s => !s.selected)) ? 'text-warning' : ''} />
                        }
                        tooltip="Filtro"
                        onClick={handleFilterClick}
                    />

                    <LcIconLink
                        icon={hoverLocked ? <PiEyeSlashLight /> : <PiEyeLight />}
                        onClick={handleHoverLicences}
                        tooltip={!hoverLocked ? "Ver licenças" : "Ocultar licenças"}
                    />

                </>
            }
        >

            <LCDashboard
                cards={card}
                cssClassPanel='scrollable-v'
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: filterVisible,
                    content: () => filterForCountry()
                }}
            />
            {/* {
                filterVisible &&
                <SideModal header="Filtro" onClose={setFilterVisible} visible={filterVisible}>
                    <div className="form">
                        <LcAccordion title="Países" visible={true}>
                            {
                                countries.length > 0 &&
                                <LcCheckList list={countries} toggle onChange={setCountries} search={true} searchPlaceHolder="(Procure por países)" title="Pais" />
                            }
                        </LcAccordion>
                        <LcAccordion title="Softwares" visible={false}>
                            {
                                softwares.length > 0 &&
                                <LcCheckList list={softwares} toggle onChange={setSoftwares} search={true} searchPlaceHolder="(Procure por softwares)" title="Software" />
                            }
                        </LcAccordion>
                        <LcAccordion title="Centros de Custo" visible={false}>
                            {
                                costCenters.length > 0 &&
                                <LcCheckList list={costCenters} toggle onChange={setCostCenters} search={true} searchPlaceHolder="(Procure por centros de custo)" title="Centro de Custo" />
                            }
                        </LcAccordion>
                    </div>
                </SideModal>
            }
 */}

        </Layout >
    );
}

export default EABudget;