import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

interface Savings {
	date: string,
	value: number
}

interface Props {
	data: Savings[],
	start: Date,
	end: Date
}

const WeeklySavingsEstimateChart: React.FC<Props> = (props) => {

	const defaultValue: Savings = { date: "01/01", value: 0.00 };
	const [savingsByPeriod, setSavingsByPeriod] = useState<Savings[]>([defaultValue]);
	const [selectedStartPeriod, setSelectedStartPeriod] = useState<Date>();
	const [selectedEndPeriod, setSelectedEndPeriod] = useState<Date>();

	useEffect(() => {
		// Sort the data by date
		const sortedData = [...props.data].sort((a, b) => {
			const [dayA, monthA, yearA] = a.date.split('/').map(Number);
			const [dayB, monthB, yearB] = b.date.split('/').map(Number);
			return new Date(yearA, monthA - 1, dayA).getTime() - new Date(yearB, monthB - 1, dayB).getTime();
		});

		// Convert dates to 'DD/MM' format
		const formattedData = sortedData.map(item => ({
			date: item.date.substring(0, 5), // Extract 'DD/MM' from 'DD/MM/YYYY'
			value: item.value
		}));

		setSavingsByPeriod(formattedData);
		setSelectedStartPeriod(props.start);
		setSelectedEndPeriod(props.end);
	}, [props]);

	const chartOptions = {
		grid: {
			top: 10,
			bottom: 200,
			left: 0,
			right: 20,
			containLabel: true
		},
		tooltip: {
			backgroundColor: '#f1f5fb',
			borderWidth: 0,
			textStyle: {
				fontSize: 11,
				fontFamily: 'Ubuntu',
				color: '#2B304F',
			},
			trigger: 'axis',
			axisPointer: {
				type: 'line',
				lineStyle: {
					color: '#aaa',
					type: 'dashed'
				}
			},
			formatter: function (d) {
				const brl = new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				});
				const formattedValue = brl.format(d[0].value);
				return `${d[0].name}: ${formattedValue}`;
			}
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: savingsByPeriod.map(sp => sp.date),
			axisTick: { show: false },
			axisLabel: {
				fontSize: '11px',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
				interval: (index, value) => {
					return index === 0 || index === savingsByPeriod.length - 1 || index % 3 === 0;
				},
				rotate: 45
			},
			axisLine: { show: false }
		},
		yAxis: {
			type: 'value',
			splitLine: {
				lineStyle: {
					width: 2,
					type: 'dotted',
					dashOffset: 1
				}
			},
			axisLabel: {
				fontSize: '11px',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
				formatter: (d) => `${d / 1000}k`
			},
		},
		series: [
			{
				type: 'line',
				smooth: true,
				showSymbol: true,
				symbol: 'circle',
				symbolSize: 2,
				areaStyle: {
					color: '#3ebb3f',
					opacity: 0.2
				},
				lineStyle: {
					color: '#3ebb3f'
				},
				itemStyle: {
					color: '#3ebb3f'
				},
				data: savingsByPeriod.map(sp => sp.value.toFixed(2))
			}
		]
	}

	return (
		<ReactECharts
			opts={{ renderer: 'svg', locale: 'PT-br' }}
			option={chartOptions}
		/>
	)
}

export default WeeklySavingsEstimateChart;