export class cloudmanagedservicesenterprise {
    solution() {
        return 'cloudmanagedservicesenterprise'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-enterprise'
    }

    logotipo() {
        return require('./assets/logotipos/logo_cloudmanagedservicesenterprise.svg').default
    }
    titulo() {
        return <>Alcance o <b>próximo nível de eficiência</b> com Cloud Managed Services Enterprise</>
    }

    chamada() {
        return <>Mais do que uma simples solução de hospedagem em nuvem. É uma <b>parceria estratégica</b> que ajuda a organização a tomar as melhores decisões para maximizar a eficiência e o crescimento.</>
    }

    image() {
        return require('./assets/imgs/imagem_cloudmanagedservicesenterprise.png').default
    }

    detalhes() {
        return <>
            <h2>Nosso time de operações está sempre à disposição para tornar o ambiente sempre atualizado, mantido e otimizado</h2>
            <h3>Por que escolher LiveCloud Cloud Managed Services Enterprise?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_cloudmanagedservicesenterprise.png').default} />
                </div>
                <ul>
                    <li><b>Administração compartilhada:</b> Trabalhamos em conjunto com a organização para auxiliar que as melhores decisões sejam tomadas em relação ao ambiente em nuvem.</li>
                    <li><b>Flexibilidade e escalabilidade:</b> O nosso serviço é construído para se adaptar e crescer com o negócio, oferecendo flexibilidade e escalabilidade.</li>
                    <li><b>Operação e monitoramento constante:</b> Oferecemos cobertura operacional e de monitoramento 24/7 para ampliar a disponibilidade e performance dos serviços.</li>
                    <li><b>Migração do ambiente legado:</b> Oferecemos a opção de migrar o ambiente legado para a nuvem, facilitando a transição e minimizando o tempo de inatividade.</li>
                    <li><b>Serviços profissionais:</b> A organização tem acesso a 20 horas por mês de Professional Services, para ter o suporte necessário quando precisar.</li>
                    <li><b>Matriz de serviços abrangente:</b> Nosso serviço inclui monitoramento, operação compartilhada, FinOps opcional, reengenharia semestral de arquitetura e Segurança EDR.</li>
                </ul>
            </div>
            <div className="box"><b>Monitoramos o ambiente 24/7</b> e também realizamos testes periódicos, atualizações, manutenções e atividades programadas.</div>
            <h3>Interface gerencial única</h3>
            <p>LiveCloud oferece uma interface gerencial única, permitindo o acompanhamento dos serviços contratados pela organização. Essa interface pode disponibilizar painéis de administração, níveis de serviço, visões gerenciais de monitoramento, disponibilidade dos serviços, avaliação e predição de performance e consumo, solicitação de serviços, abertura de chamados e logs das principais atividades, além da evolução e demais alterações do ambiente.</p>
            <h3>Níveis de serviço</h3>
            <p>Nós entendemos que tempo é dinheiro. É por isso que oferecemos um <b>SLA de início de 45 minutos</b> e um <b>SLO de fim de 4 horas</b>, para que os serviços contratados estejam disponíveis quando for preciso.</p>
            <h3>Não perca mais tempo, acelere o crescimento dos negócios agora!</h3>
            <p>Descubra como LiveCloud Cloud Managed Services Enterprise pode revolucionar a organização. Entre em contato conosco agora mesmo e dê o próximo passo rumo ao sucesso do negócio.</p>          
        </>
    }
}
export default cloudmanagedservicesenterprise;