import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { NotificationCenterService, NotificationModel, Notification, convertToNotification } from '../../services/notificationCenter/notificationService';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/index';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { MessagesState } from '../../store/reducers/messagesReducer';
import Moment from 'moment';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";

import ReactHtmlParser from 'react-html-parser';
const NotificationCenter: React.FC = (props) => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const msgs = useSelector<RootState, MessagesState>(state => state.msgs);
    const email: string = user.email != undefined ? user.email : '';
    const history = useHistory();

    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [filteredNotifications, setFilteredNotifications] = useState<NotificationModel[]>([]);
    const [visibleNotifications, setVisibleNotifications] = useState<NotificationModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const service = new NotificationCenterService(props);

    const fetchNotifications = async () => {
        try {
            const response = await service.GetNotifications("");
            setNotifications(response);
            setFilteredNotifications(response);
            dispatch({ type: 'MSG_UPDATE', payload: response || [] });
            dispatch({ type: 'MSG_UPDATE_LAST_TIME', payload: new Date().toISOString() });
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    }

    const loadNotifications = () => {
        setIsLoading(true);
        // if (msgs.messages.length > 0 && msgs.lastUpdate != '') {
        //     setNotifications(msgs.messages);
        //     setFilteredNotifications(msgs.messages);
        //     setIsLoading(false);
        // } else {
        fetchNotifications();
        //   }
    }

    useEffect(() => {
        loadNotifications();
    }, [msgs.messages, msgs.lastUpdate,])

    const markAsRead = (notification: Notification) => {
        notification.isRead = true;
        service.PostNotifications(notification)
            .then(() => {
                fetchNotifications();
            })
            .catch(error => {
                console.warn(error);
            });
    }

    useEffect(() => {
        const filtered = notifications.filter(notification =>
            filter.term === '' ||
            notification.title.toLowerCase().includes(filter.term) ||
            notification.content.toLowerCase().includes(filter.term) ||
            Moment(notification.notificationDateTime).format('DD/MM/YYYY').toLowerCase().includes(filter.term)
        ).sort((a, b) => {
            if (filter.direction === 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredNotifications(filtered);
        setVisibleNotifications(filtered.slice(0, filter.size));
    }, [filter, notifications]);

    const columns = [
        {
            field: 'title', headerName: 'Título', width: "20%",
            renderCell: (row: NotificationModel) => {
                return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.title}</div>;
            }
        },
        {
            field: 'content', headerName: 'Mensagem', width: "60%",
            renderCell: (row: NotificationModel) => {
                return <div style={{ overflow: 'auto', maxHeight: '100px' }} dangerouslySetInnerHTML={{ __html: row.content }}  ></div>;
            }
        },
        {
            field: 'notificationDateTime', headerName: 'Data', width: "10%",
            renderCell: (row: NotificationModel) => {
                const date = new Date(row.notificationDateTime);
                return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{date.toLocaleDateString()}</div>;
            }
        },
        {
            field: 'isRead', sort: true, width: "5%",
            renderCell: (row: NotificationModel) => {
                if (!row.isRead) return <LcIconLink icon="lci lci-eye" tooltip="Marcar como lido" color='#BEBEBE' onClick={() => { markAsRead(convertToNotification(row)) }} />
                else return <span></span>
            }
        }
    ];

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    return (
        <Layout
            pageTitle="Notificação de sistema"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible);
                },
                size: filteredNotifications.length
            }}
        >
            <LcInfiniteTable
                loading={isLoading}
                columns={columns}
                rows={visibleNotifications}
                density={pref.data?.density || "medium"}
            />
        </Layout>
    );
}

export default NotificationCenter;
