import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { RetencaoService } from '../../../services/retencao/retencaoService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import { useSnackbar } from 'notistack';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { PiDownloadLight, PiKeyLight } from 'react-icons/pi';

const SolicitacoesDeAcesso: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const retencaoService = new RetencaoService(props);

    const [table, setTable] = useState<any[]>([]);
    const [filteredTable, setFilteredTable] = useState<any[]>([]);
    const [visibleTable, setVisibleTable] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isVisibleAlertMessage, setIsVisibleAlertMessage] = useState<boolean>(false);
    const [isVisibleKey, setIsVisibleKey] = useState<boolean>(false);
    const [accessKey, setAccessKey] = useState<string>('');

    useEffect(() => {
        if (user.ClientGroupSelected) {
            setFilteredTable([]);
            retencaoService.GetTable()
                .then(response => {
                    let table = response.data.map((Item: { DataSolicitacao: any, Usuario: string, URL: string }, index: number) => {
                        const utcDate = new Date(Item.DataSolicitacao)
                        return {
                            id: index,
                            dataSolicitacao: Item.DataSolicitacao,
                            utcDate: utcDate,
                            usuario: Item.Usuario,
                            url: Item.URL,
                        }
                    });
                    setTable(table);
                    setFilteredTable(table);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setTable([]);
                    setFilteredTable([]);
                    setIsLoading(false);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = table.filter((p: any) =>
            filter.term == '' ||
            p.usuario.toLowerCase().indexOf(filter.term) > -1 ||
            p.url.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredTable(filtered);
        setVisibleTable(filtered.slice(0, filter.size));
    }, [filter, filteredTable.length > 0]);

    function downloadFile() {
        enqueueSnackbar("Baixando arquivo. Aguarde...", {
            variant: 'warning',
            preventDuplicate: true,
            persist: false,
        });
        const FileDownload = require("js-file-download");
        retencaoService.DownloadFile().then(response => { FileDownload(response.data, "GR_Acesso_Retencao.pdf") }).catch(error => { console.log(error) });
    }

    function createAccessKey() {
        enqueueSnackbar("Criação da chave iniciada, aguarde.", {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        retencaoService.CreateAccessKey()
            .then(response => {
                if (response.data) {
                    enqueueSnackbar("Criação da chave realizada com sucesso.", {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                    setAccessKey(response.data);
                    setIsVisibleKey(!isVisibleKey)
                }
            })
            .catch(error => {
                enqueueSnackbar("Erro ao criar chave de acesso.", {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                console.log(error)
            });
    };

    const columns = [
        {
            field: 'dataSolicitacao', headerName: 'Data da solicitação', width: "20%", filterable: false,
            renderCell: (row: any) => {
                const dateToFormat = new Date(row.dataSolicitacao);
                return dateToFormat.toLocaleDateString();
            }
        },
        { field: 'usuario', headerName: 'Solicitante', width: "20%" },
        {
            field: 'url', headerName: 'URL', width: "60%",
            renderCell: (row: any) => {
                const urlBase = row.url.toString().split('?')[0];
                return (<>{urlBase}*************************</>)
            }
        },
    ];

    const functions = [
        {
            title: 'Instrução de download',
            icon: <PiDownloadLight />,
            tooltip: 'Instrução de download',
            onClick: () => downloadFile()
        },
        {
            title: 'Criar chave de acesso ',
            icon: <PiKeyLight />,
            tooltip: 'Criar chave de acesso',
            onClick: () => setIsVisibleAlertMessage(!isVisibleAlertMessage)
        }
    ];



    const OnCloseEventAlertMessage = (response: any) => {
        if (response) {
            setIsVisibleAlertMessage(!isVisibleAlertMessage)
            createAccessKey()
        } else {
            setIsVisibleAlertMessage(!isVisibleAlertMessage)
        }
    }
    const OnCloseEventKey = (response: any) => {
        setIsVisibleKey(!isVisibleKey)
        setAccessKey('')

        enqueueSnackbar("Os dados estão sendo atualizados em segundo plano no provedor, isto pode levar alguns minutos, retorne novamente mais tarde.", {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        setIsLoading(true);
        setTable([]);
        setFilteredTable([]);
        retencaoService.GetTable().then(response => {
            let table = response.data.map((Item: { DataSolicitacao: any, Usuario: string, URL: string }, index: number) => {
                const utcDate = new Date(Item.DataSolicitacao)
                return {
                    id: index,
                    dataSolicitacao: Item.DataSolicitacao,
                    utcDate: utcDate,
                    usuario: Item.Usuario,
                    url: Item.URL,
                }
            });
            setTable(table);
            setFilteredTable(table);
            setIsLoading(false);
        })
            .catch(error => {
                console.log(error);
                setTable([]);
                setFilteredTable([]);
                setIsLoading(false);
            });
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleTable(filteredTable.slice(0, size))
        else setVisibleTable([...visibleTable, ...filteredTable.slice(visibleTable.length, visibleTable.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const _filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    return (
        <Layout
            cardType
            pageTitle="Solicitações de Acesso"
            functionsGeneric={functions}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredTable.length
            }}
        >

            <DialogConfirmForm
                onCloseEvent={OnCloseEventAlertMessage}
                open={isVisibleAlertMessage}
                confirmMessage='Gerar Chave'
                idConfirm="091cec99-7126-447d-bd62-91802a0e4441"
                abortMessage='Cancelar'
                idAbort="d13142b6-65bc-4ddc-9226-9834b4fd9465"
                title='Aviso de criação de chave'
                content='Esta operação criará uma chave que será exibida apenas uma única vez, anote a chave após gerá-la.'>
            </DialogConfirmForm>

            <DialogConfirmForm
                onCloseEvent={OnCloseEventKey}
                open={isVisibleKey}
                confirmMessage='Ok'
                idConfirm="e533c9e6-19b7-452e-b361-34b0fd42691a"
                abortMessage="Fechar"
                idAbort="28ebf46b-2518-4194-abcc-cbbee1a31c33"
                title='Chave gerada'
                content={`Sua chave foi gerada com sucesso. Anote e lembre-se de armazená-la de maneira segura, em caso de perca será necessário gerar uma nova chave. Chave: ${accessKey}`}>
            </DialogConfirmForm>

            <LcInfiniteTable
                loading={isLoading}
                columns={columns}
                rows={visibleTable}
                filter={_filterAdvanced}
                size={filteredTable.length}
                loadMore={loadMore}
                onSortChange={onSortChange}
                density={pref.data?.density || "high"}
            />

        </Layout>
    );
};

export default SolicitacoesDeAcesso;