// EALicenseAlarmService.ts
import api, { APIClient } from "../api";
interface EALicenseAlarm {
    email: string;
    country: string;
}

class EALicenseAlarmService extends APIClient {
    constructor(props: any) {
        super(props);
    }

    async saveEALicenseAlarm(alarm: EALicenseAlarm): Promise<boolean> {
        try {
            const response = await api.post<boolean>(`${this.endpoint}/api/SaaSSubscription/SaveEALicenseAlarm`, alarm);
            return response.data;
        } catch (error) {
            console.error('[SaveEALicenseAlarm]:', error);
            return false;
        }
    }

    async queryEALicenseAlarm(): Promise<EALicenseAlarm[]> {
        try {
            const response = await api.post<EALicenseAlarm[]>(`${this.endpoint}/api/SaaSSubscription/QueryEALicenseAlarm`);
            return response.data;
        } catch (error) {
            console.error('[QueryEALicenseAlarm]:', error);
        }
        return [] as EALicenseAlarm[];
    }

    async deleteEALicenseAlarm(alarm: EALicenseAlarm): Promise<boolean> {
        try {
            const response = await api.post<boolean>(`${this.endpoint}/api/SaaSSubscription/DeleteEALicenseAlarm`, alarm);
            return response.data;
        } catch (error) {
            console.error('[DeleteEALicenseAlarm]:', error);
            return false;
        }
    }
}

export default new EALicenseAlarmService({});
