import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import ReactECharts, { EChartsOption } from 'echarts-for-react';

import LCDashboard, { Card, PositionCard } from '../../components/Data/Dashboard/LCDashboard';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { useHistory } from 'react-router-dom';
import { FinancialManagementService } from '../../services/financialManagement/FinancialManagementService';
import LcLoading from '../../components/Generic/LcLoading';
import GaugeChartCore from '../../components/Layout/Dashboard/gaugeChartCore';
import IconValue from '../../components/Layout/Dashboard/IconValue';
import HomeService, { ManagedServiceHome } from '../../services/homeService';
import { FileRepositoryService } from '../../services/fileRepository/fileRepository';
import LatestFileDownload, { LatestFileProps } from './download';
import HeyHoNewRequest, { FormDataHeyho } from '../../components/Generic/HeyHoNewRequest';
import { FileModel } from '../fileRepository';
import { MenuState } from '../../store/reducers/menuReducer';
import { PiCheckCircleLight, PiDownloadLight, PiWarningCircleLight } from 'react-icons/pi';
import { Tooltip } from '@material-ui/core';

interface NotaFiscal {
    contractId: number;
    amount: number;
    issuanceDate: Date;
    contractVendor: string;
    contractName: string;
    status: string;
}

interface InvoiceData {
    amount: number;
    contractVendor: string;
}


interface ResumeInfoHistoryProps {
    isHome?: boolean;
    qtdGroups?: number;
    hasNF?: boolean;
}

interface IconColorResult {
    color: string;
    icon: React.ReactNode;
};


const MemoizedReactECharts = React.memo(ReactECharts);


const ResumeInfoHistory: React.FC<ResumeInfoHistoryProps> = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    //const [lastSixMonthsFormatted, setLastSixMonthsFormatted] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const [slaAtendimento, setSlaAtendimento] = useState<number>(0);
    const [sloEmConclusao, setSloEmConclusao] = useState<number>(0);
    const [totalChamadosAbertos, setTotalChamadosAbertos] = useState<number>(0);
    const [files, setFiles] = useState<LatestFileProps>({} as unknown as LatestFileProps);
    const [managedServiceHome, setManagedServiceHome] = useState<ManagedServiceHome>();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
    });
    // Calcula os últimos seis meses fora do useEffect
    const calculateLastSixMonths = () => {
        const today = moment(); // Obtém a data atual
        return [...Array(6)].map((_, i) => today.clone().subtract(i, 'months').format('MMM'));
    };

    const lastSixMonthsFormatted = useMemo(calculateLastSixMonths, []);

    const [notasFiscais, setNotasFiscais] = useState<NotaFiscal[]>([]);


    const formatBRL = useMemo(() => (value: number) => {
        return `${Intl.NumberFormat('pt-BR', {
            style: 'currency', currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)}`
    }, []);


    const formatNumberLC = useMemo(() => (value: number | string | null) => {
        if (value == null || value === 'NaN' || isNaN(Number(value))) return '0,00';

        const numValue = Number(value);
        // billions and above
        if (numValue >= 1.0e+9) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+9));
            return formattedAmount + "B";
        }
        // millions
        else if (numValue >= 1.0e+6) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+6));
            return formattedAmount + "M";
        }
        // thousands
        else if (numValue >= 1.0e+3) {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+3));
            return formattedAmount + "k";
        }
        else {
            const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue));
            return formattedAmount;
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = (year) => {
            const NFsService = new FinancialManagementService({});
            NFsService.GetInvoicesByClientGroupContractYear(year)
                .then(res => {
                    const currentYearInvoices = res.filter(nf => moment(nf.issuanceDate).year() === year);
                    setNotasFiscais(currentYearInvoices);
                })
                .catch(error => {
                    setNotasFiscais([]);
                    console.warn(error);
                }).finally(() => setLoading(false))
        };

        const fetchDataHH = async (year, month) => {
            const intanceHomeService = new HomeService({});
            let responseManagedServiceHome = await intanceHomeService.GetManagedServiceHomeDataByIdMonthAndYear(month, year);
            console.log(responseManagedServiceHome)
            setManagedServiceHome(responseManagedServiceHome);
            if (responseManagedServiceHome) {
                let attended: number = responseManagedServiceHome.slaInicial ? parseFloat(responseManagedServiceHome.slaInicial.toFixed(2)) : 100;
                setSlaAtendimento(attended);

                let completed: number = responseManagedServiceHome.slaInicial ? parseFloat(responseManagedServiceHome.slaFinal.toFixed(2)) : 100;
                setSloEmConclusao(completed);


                setTotalChamadosAbertos(responseManagedServiceHome.totalChamadosAbertos);
                // var sortedArray: SolicitationProducts[] = responseManagedServiceHome.solicitationProducts.sort((n1, n2) => {
                //     if (n1.product > n2.product) { return 1; }
                //     if (n1.product < n2.product) { return -1; }
                //     return 0;
                // });
                // const userservice = new UserService({});
                // var responseRer = (await userservice.GetMonitoringAPIbyPartitionReport());
                // console.log(responseRer)
                // // let jobsResponseRer = responseRer ? responseRer : [];
                
                const serviceFileRepositoryService = new FileRepositoryService({});
                var resultFileRepositoryService = await serviceFileRepositoryService.GetListFileRepository();
                setFiles(resultFileRepositoryService.data)


            }
        };
        // Chamada da função fetchData apenas se o ClientGroupSelected mudar
        const year = moment().year();
        const month = moment().month() + 1;
        Promise.all([
            fetchData(year),
            fetchDataHH(year, month)
        ]).finally(() => setLoading(false))
    }, [user.ClientGroupSelected]);

    const screenWidth = window.innerWidth;
    let fontSize = screenWidth <= 1400 ? 10 : 12;

    const getEChartsOption = (): EChartsOption => {
        const filteredNotasFiscais = notasFiscais.filter((invoice) => invoice.status === "CONCLUÍDO");
        const groupedInvoices: Record<string, Record<string, { amount: number; label: string; color: string }>> = {};

        const colors = [
            "#8C40E3", // Substituto para "#1629F3"
            "#AB19E7", // Substituto para "#4C5B94"
            "#2E1956", // Substituto para "#0E1E58"
            "#000000", // Substituto para "#010928"
            "#2E1956", // Substituto para "#050D39"
            "#471FCC", // Substituto para "#173192"
            "#2E1956", // Substituto para "#07144B"
            
            "#A6FFE8", // Mint, substituindo uma cor repetida
            "#8C40E3", // Substituto para "#192D76"
            "#C9FF46", // Lime, substituindo uma cor repetida
            "#966CF3", // Substituto para "#26397D"
            "#3EBB3F", // Status Green, substituindo uma cor repetida
            
            "#2E1956", // Substituto para "#08149B"
            "#AB19E7", // Substituto para "#0B1CCB"
            "#966CF3", // Substituto para "#0C1FE9"
            
            "#F6DE00", // Status Yellow, substituindo uma cor repetida
            "#DA43F2", // Substituto para "#3D4CF5"
            "#9FA3FF", // Substituto para "#515EF6"
            "#DA43F2", // Substituto para "#616DF7"
            "#DC291E", // Status Red, substituindo uma cor repetida
            "#9FA3FF", // Substituto para "#7C86F9"
            "#2E1956", // Substituto para "#071288"
            "#471FCC", // Substituto para "#014AC0"
        ];
        
        
        
        

        // Randomizando o array de cores
        //colors.sort(() => Math.random() - 0.6);

        const contractIdToColorMap = {};
        let colorIndex = 0;

        filteredNotasFiscais.forEach((invoice) => {
            if (!contractIdToColorMap.hasOwnProperty(invoice.contractId)) {
                contractIdToColorMap[invoice.contractId] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        });

        filteredNotasFiscais.forEach((invoice) => {
            const month = moment(invoice.issuanceDate).format('MMM');
            const contractId = invoice.contractId;
            const contractName = invoice.contractName;
            const contractVendor = invoice.contractVendor;
            const label = (contractName && contractName.length > 0 ? `${contractId} ${contractName}` : `${contractId} ${contractVendor}`) || `${contractId}`;

            if (!groupedInvoices[month]) {
                groupedInvoices[month] = {};
            }
            if (!groupedInvoices[month][contractId]) {
                groupedInvoices[month][contractId] = {
                    amount: 0,
                    label: label,
                    color: contractIdToColorMap[contractId], // Usar a cor do mapa baseado no contractId
                };
            }
            groupedInvoices[month][contractId].amount += invoice.amount;
        });

        const xAxisData: string[] = Object.keys(groupedInvoices).filter(month => lastSixMonthsFormatted.includes(month)).reverse();

        const contractIds: string[] = Array.from(
            new Set(filteredNotasFiscais.map((invoice) => invoice.contractId.toString()))
        );
        const seriesData = contractIds.map((contractId) => {
            const data = xAxisData.map((month) => groupedInvoices[month][contractId]?.amount || 0);
            const totalValue = data.reduce((sum, value) => sum + value, 0); // Calcula o valor total

            return {
                name: `${groupedInvoices[xAxisData[0]]?.[contractId]?.label || contractId}`, // Inclui o valor total no nome
                type: 'bar',
                stack: 'total',
                itemStyle: {
                    color: groupedInvoices[xAxisData[0]]?.[contractId]?.color,
                },
                data: data,
                totalValue: totalValue
            };
        }).filter((series) => series !== null);

        // Ordena as séries com base no valor total
        const sortedSeries = seriesData.sort((a, b) => b.totalValue - a.totalValue);

        // Cria o array final de séries (mantendo o campo 'totalValue')
        const nonZeroSeries = sortedSeries
            .filter((s) => s && s.data.some((value) => !isNaN(value) && value !== 0));


        return {
            title: {
                text: props.isHome ? "Notas fiscais no período" : undefined,
                right: "0px",
                top: "0",
                textStyle: {
                    fontSize: 11,
                    fontWeight: 'normal',
                    color: '#8b90a3',
                    fontFamily: 'Ubuntu',
                },
                backgroundColor: props.isHome ? '#ffffff90' : 'transparent',
                borderRadius: [10],
                padding: [4, 8, 4, 8],
                itemGap: 8
            },
            grid: {
                top: props.isHome ? '25px' : '5px',
                bottom: '30px',
                left: '0%',
                right: '0%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                formatter: function (params) {
                    // Ordena os params por valor, caso necessário.
                    params.sort((a, b) => b.value - a.value);

                    // Constrói o cabeçalho com o mês e o símbolo da moeda
                    const month = params[0].name;
                    let result = `<div style="margin-bottom: 10px; font-weight: bold;">${month}<span style="float: right;">R$</span><div style="clear: both;"></div></div>`;

                    // Limita a exibição aos 5 primeiros itens
                    const displayedParams = params.slice(0, 5);

                    // Adiciona cada um dos itens limitados na tooltip
                    displayedParams.forEach(function (item) {
                        const colorSpan = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${item.color};"></span>`;
                        const value = `<span style="float: right; margin-left: 15px;font-weight:bold">${formatBRL(item.value).replace('R$', '').trim()}</span>`;
                        result += `<div style="display: flex; justify-content: space-between; align-items: center;">
                   <div style="flex-grow: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-right: 10px;">
                     ${colorSpan}${item.seriesName}
                   </div>
                   ${value}
                 </div>`;
                    });

                    // Se houver mais de 5 itens, adiciona um indicativo no final
                    if (params.length > 5) {
                        result += `<div style="text-align: center; margin-top: 10px;">...e mais ${(params.length - 5)} itens</div>`;
                    }

                    return result;
                },
            },
            legend: {
                inactiveColor: '#ededed',
                inactiveBorderColor: '#ededed',
                name: '',
                type: 'scroll',  // Enable scroll (pagination)
                orient: 'horizontal',
                width: '100%',
                right: 'center',
                top: 'bottom',
                textStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 10,
                },
                itemGap: 5,
                pageButtonItemGap: 3,
                pageButtonGap: 5,
                pageButtonPosition: 'end',
                pageFormatter: '{current}/{total}',  // You can customize this
                pageIconSize: 10,
                pageIconColor: '#273b85',
                pageIcons: {
                    horizontal: [
                        'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                        'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                    ]
                },
                pageTextStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 10,
                },
                data: nonZeroSeries.map((s) => s && s.name || ''),
                icon: 'circle',
            },
            xAxis: {
                type: 'category',
                data: xAxisData,

                axisLabel: {
                    fontSize: fontSize + 'px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    fontSize: fontSize + 'px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    formatter: function (value: number) {
                        return formatNumberLC(value);
                    }
                }
            },
            series: nonZeroSeries,
        };
    };
    function getIconAndColor(value: number, unity?: string, isGoodAtZeroPercent?: boolean): IconColorResult {
        if (value === 0 && !unity) {
            return {
                color: "#2CA02C", // Verde
                icon: <PiCheckCircleLight size={24}/>
            };
        } else if (value === 0 && unity === "%" && isGoodAtZeroPercent) {
            return {
                color: "#2CA02C", // Verde
                icon: <PiCheckCircleLight size={24}/>
            };
        } else if (value === 0 && unity === "%") {
            return {
                color: "#D3281D", // Vermelho
                icon: <PiWarningCircleLight size={24}/>
            };
        } else if (value > 0 && unity === "%" && isGoodAtZeroPercent) {
            return {
                color: "#D3281D", // Vermelho
                icon: <PiWarningCircleLight size={24}/>
            };
        } else if (value >= 90 && value <= 100) {
            return {
                color: "#2CA02C", // Verde
                icon: <PiCheckCircleLight size={24}/>
            };
        } else {
            return {
                color: "#D3281D", // Vermelho
                icon: <PiWarningCircleLight size={24}/>
            };
        }
    }
    const echartsOption = useMemo(() => getEChartsOption(), [notasFiscais]);

    useEffect(() => {
        if(menu.newRequest) {
            const flattenFiles = (node: FileModel, array: FileModel[] = []): FileModel[] => {
                try {
                    if (node.children && node.children.length > 0) {
                        node.children.forEach((child) => flattenFiles(child, array));
                    } else {
                        if (node.urlDownload) {
                            array.push(node);
                        }
                    }
                    return array;
                } catch (e) {
                    return [];
                }
            };
            // Sort the files by lastModified in descending order
            const sortedFiles = flattenFiles(files.fileTree).sort((a, b) => {
                const dateA = a.lastModified ? new Date(a.lastModified) : new Date(0);
                const dateB = b.lastModified ? new Date(b.lastModified) : new Date(0);
                return dateB.getTime() - dateA.getTime();
            });
            if(sortedFiles[0].storageClass === "DEEP_ARCHIVE") {
                setFormData({
                    title: `Solicitação de download de Arquivo: ${sortedFiles[0].label}`,
                    description: `Solicito o download do Arquivo: ${sortedFiles[0].label}.\nlocalizado na pasta ${sortedFiles[0].key} `
                })
            } else {
                dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
            }
        }
    },[menu.newRequest])
 
    const enabledNotasFicais = () => {
        return user.Permission && user.Permission.find(permission => permission.permissoesChave === "Permite_Contrato_NotaFiscal" && permission.permissoesValor === "SIM")
    }

    const handleNotaFiscalClick = useCallback(() => {
        if (enabledNotasFicais()) {
            history.push("/notasFiscais");
        }
    }, [history, user.Permission]);

    const staticStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const staticOnEvents = useMemo(() => ({ 'click': handleNotaFiscalClick }), [handleNotaFiscalClick]);



    const cardsPeasant: Card[] = [
        {
            id: 2, type: 'Custom',
            position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1 },
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <div style={{ minHeight: '100%', minWidth: '100%', maxHeight: '100%', maxWidth: '100%' }}>
                    <LcLoading loading={loading}  >
                        <GaugeChartCore style={{ minHeight: '100%', minWidth: '100%', maxHeight: '80px', maxWidth: '100%' }} className='focused' value={slaAtendimento} title="SLA">
                        </GaugeChartCore>
                    </LcLoading >
                </div>
            )
        },
        {
            id: 3, type: 'Custom',
            position: { ColSpan: 2, RowSpan: 3, row: 4, col: 1 },
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <div style={{ minHeight: '100%', minWidth: '100%', maxHeight: '100%', maxWidth: '100%' }}>
                    <LcLoading loading={loading} >
                        <GaugeChartCore className='focused' style={{ minHeight: '100%', minWidth: '100%', maxHeight: '80px', maxWidth: '100%' }} value={sloEmConclusao} title="SLO">
                        </GaugeChartCore>
                    </LcLoading >
                </div>
            )
        },
        {
            id: 4, type: 'Custom',
            position: { ColSpan: 2, RowSpan: 2, row: 7, col: 1 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <div className="link" onClick={() => history.push('/Chamados')}>
                        <IconValue maximumFractionDigits={0} isHome
                            minimumFractionDigits={0}
                            formatValue={true}
                            info={"info"}
                            tooltipInfoPosition="left"
                            icon={getIconAndColor(100, '%', false).icon}
                            value={totalChamadosAbertos}
                            description={"Chamados abertos"}
                            color={getIconAndColor(100, '%', false).color} >
                        </IconValue>
                    </div>
                </LcLoading>
            )
        },
        {
            id: 4, type: 'Custom',
            position: { ColSpan: 2, RowSpan: 2, row: 9, col: 1 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <IconValue maximumFractionDigits={2}
                        minimumFractionDigits={2} isHome
                        formatValue={true}
                        info={"info"}
                        tooltipInfoPosition="left"
                        icon={<PiDownloadLight size={24}/>}
                        value={files ? LatestFileDownload(files) : 0}
                        description="Repositório de arquivos"
                        color={getIconAndColor(100, '%', false).color}  >
                    </IconValue>
                </LcLoading>
            )
        },
        {
            id: 5, type: 'Custom',
            position: { ColSpan: 2, RowSpan: 2, row: 11, col: 1 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <div className="link" onClick={() => history.push('/administration/HealthCheckActiveService')}>
                        <IconValue maximumFractionDigits={0}
                            minimumFractionDigits={0} isHome
                            formatValue={true}
                            info={"info"}
                            title="LiveCloud HealthCheck"
                            tooltipInfoPosition="left"
                            icon={getIconAndColor(100, '%', false).icon}
                            value={100}
                            description="LiveCloud HealthCheck"
                            unity="%"
                            color={getIconAndColor(100, '%', false).color}  >
                        </IconValue>
                    </div>
                </LcLoading>
            )
        }
    ]
    const cards: Card[] = [
        {
            id: 1, //notas fiscais
            hideHeader: props.isHome,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 6, row: 1, col: 1 },
            showFocusButton: false,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <MemoizedReactECharts key='nfChartHome' option={echartsOption} style={staticStyle}
                        onEvents={staticOnEvents} />
                </LcLoading>
            )
        },
        {
            id: 2, type: 'Custom',
            position: { ColSpan: 1, RowSpan: 3, row: 7, col: 1 },
            cssClass: 'no-padding',
            hideHeader: true,
            customContentRender: () => (
                <div style={{ minHeight: '100%', minWidth: '100%', maxHeight: '100%', maxWidth: '100%' }}>
                    <LcLoading loading={loading}>
                        <GaugeChartCore style={{ minHeight: '100%', minWidth: '100%', maxHeight: '80px', maxWidth: '100%' }} className='focused' value={slaAtendimento} title="SLA">
                        </GaugeChartCore>
                    </LcLoading>
                </div>
            )
        },
        {
            id: 3, type: 'Custom',
            position: { ColSpan: 1, RowSpan: 3, row: 10, col: 1 },
            cssClass: 'no-padding',
            hideHeader: true,
            customContentRender: () => (
                <div style={{ minHeight: '100%', minWidth: '100%', maxHeight: '100%', maxWidth: '100%' }}>
                    <LcLoading loading={loading} >
                        <GaugeChartCore className='focused' style={{ minHeight: '100%', minWidth: '100%', maxHeight: '80px', maxWidth: '100%' }} value={sloEmConclusao} title="SLO">
                        </GaugeChartCore>
                    </LcLoading >
                </div>
            )
        },
        {
            id: 4, type: 'Custom',
            position: { ColSpan: 1, RowSpan: 2, row: 7, col: 2 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => 
                {
                let callsInTheMonth = managedServiceHome && managedServiceHome.totalchamadosAbertosDepartamento && managedServiceHome.totalchamadosFechadosDepartamento 
                    ? `${managedServiceHome.totalchamadosFechadosDepartamento}/${managedServiceHome.totalchamadosAbertosDepartamento}` : '0/0';
                return(
                    <LcLoading loading={loading}>
                        <Tooltip  arrow title={"Chamados encerrados/abertos no mês"} >
                            <div className="link" onClick={() => history.push('/Chamados')}>
                                <IconValue 
                                    isHome
                                    info={"info"}
                                    icon={getIconAndColor(100, '%', false).icon}
                                    value={callsInTheMonth}
                                    cssClass={ managedServiceHome && managedServiceHome?.totalchamadosAbertosDepartamento > 100 ? 'bigNumber': ''}
                                    description={"Chamados encerrados/abertos no mês"}
                                    color={getIconAndColor(100, '%', false).color} >
                                </IconValue>
                            </div>
                        </Tooltip>
                    </LcLoading>
                )
            }

        },
        {
            id: 4, type: 'Custom',
            position: { ColSpan: 1, RowSpan: 2, row: 9, col: 2 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <IconValue maximumFractionDigits={2}
                        minimumFractionDigits={2} isHome
                        formatValue={true}
                        info={"info"}
                        tooltipInfoPosition="left"
                        icon={<PiDownloadLight size={24} />}
                        value={files ? LatestFileDownload(files) : 0}
                        description="Repositório de arquivos"
                        color={getIconAndColor(100, '%', false).color}  >
                    </IconValue>
                    <HeyHoNewRequest 
                        openModal={menu.newRequest}
                        formData={formData}
                        onClose={() => dispatch({ type: 'MENU_NEW_REQUEST', payload: false })}
                        />
                </LcLoading>
            )
        },
        {
            id: 5, type: 'Custom',
            position: { ColSpan: 1, RowSpan: 2, row: 11, col: 2 },
            bgColor: '#F1F5FB',
            cssClass: 'small-card',
            hideHeader: true,
            customContentRender: () => (
                <LcLoading loading={loading}>
                    <div className="link" onClick={() => history.push('/administration/HealthCheckActiveService')}>
                        <IconValue maximumFractionDigits={0}
                            minimumFractionDigits={0} isHome
                            formatValue={true}
                            info={"info"}
                            title="LiveCloud HealthCheck"
                            tooltipInfoPosition="left"
                            icon={getIconAndColor(100, '%', false).icon}
                            value={100}
                            description="LiveCloud HealthCheck"
                            unity="%"
                            color={getIconAndColor(100, '%', false).color}  >
                        </IconValue>
                    </div>
                </LcLoading>
            )
        }
    ]

    return (
        <LCDashboard isHomeType grid={[12, 2]} cards={props.hasNF ? cards : cardsPeasant} />
    );
}

export default ResumeInfoHistory;