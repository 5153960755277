import React, { useEffect, useState } from 'react';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import DialogConfirmForm from '../../../../components/Layout/Dialog/DialogConfirmForm';
import { SaaSGroupMembersService } from '../../../../services/saasGroupMembersService';
import SideModal from '../../../../components/Layout/SideModal/SideModal';
import LcCheckLabelValueList from '../../../../components/Form/LcCheckLabelValueList';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import LcLoading from '../../../../components/Generic/LcLoading';
import './index.css';
import LcTable from '../../../../components/Data/LcTable';
import { PPModal } from 'processor-plataform-ui';
interface SaasGroupProps {
    template: any,
    data?: any,
    handleform: any,
    visible?: boolean,
    EditForm?: boolean,
    onClose?: Function,
    model?: any;
    parentCallback?: Function;
    header?: string;
}
const SaaSGroupMembersListLinked: React.FC<SaasGroupProps> = (props) => {
    const ServiceInstance = new SaaSGroupMembersService(props);
    const emptyRecordMessageMembros = {
        detail: false,
        edit: false,
        add: false,
        members: false,
        record: ServiceInstance.emptyRecord
    };

    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { register, handleSubmit, watch, errors, getValues, setValue, reset } = useForm({});
    const [filterText, setFilterText] = useState<string>();
    const [inputSearch, setInputSearch] = useState('');
    const [handleform, setHandleform] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUnLinkedUserList, setLoadingUnLinkedUserList] = useState(true);
    const [visibleNewMemberForm, SetVisibleNewMemberForm] = useState(false);
    const [idsToDelete, setIdsToDelete] = useState([0]);
    const [SelectedMembersList, setSelectedMembersList] = useState<any[]>([]);
    const [ListDataBase, setListDataBase] = useState<any[]>([]);
    const [UnLinkedUserList, setUnLinkedUserList] = useState<any[]>([]);
    const [UnLinkedUserListSelected, setUnLinkedUserListSelected] = useState<any[]>([]);
    const [fullDataRowCount, setFullDataRowCount] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [fullUnLinkedUserCount, setFullUnLinkedUserCount] = useState<number>(0);
    const [record, setRecord] = useState(emptyRecordMessageMembros);
    const [filter, setFilter] = useState<string>("");
    const [openError, setOpenError] = useState<boolean>(false);
    const [onPremiseGroup, setOnPremiseGroup] = useState<boolean>(false);
    const [filterUnLinked, setFilterUnLinked] = useState({ ...ServiceInstance.filterGridDefault });

    const [ListData, setListData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleListData, setVisibleListData] = useState<any[]>([]);
    const [filterPage, setFilterPage] = useState<{ size: number, term: string, column: string, direction: string }>(
        { size: 20, term: "", column: "", direction: "asc" });
    const [filterPageReload, setFilterPageReload] = useState(false);

    const [template, setTemplate] = useState<any>({
        title: '',
        Fields: []
    });

    useEffect(() => {
        if (!props) {
            return;
        }
        if (!props.data) {
            return;
        }
        setRecord({ ...emptyRecordMessageMembros });
        RetrieveList(props.data.id, props.data.type, filter);
        setHandleform({
            register: register,
            errors: errors,
            setValue: setValue
        });
        let template = { ...ServiceInstance.template(record.record, false) };
        setTemplate(template);
        setOnPremiseGroup(props.data.onPremisesSyncEnabled);
    }, []);

    const columns = [{ field: 'displayName', headerName: 'Nome', width: "100%" }];

    const ADDNewMember = () => {
        SetVisibleNewMemberForm(true);
        RetrieveUnLinkedUserList(props.data.id, props.data.type);
    };

    let functions = {
        functionsList: [
            // {
            //     tooltip: 'Voltar',
            //     icon: 'ppi ppi-arrow-left',
            //     func: CloseActions
            // },
            {
                tooltip: onPremiseGroup ? "Grupo OnPremise!" : "Adicionar usuário",
                icon: '  lci lci-user-add',
                disabled: onPremiseGroup,
                func: ADDNewMember,
            }
        ]
    };

    function CloseActions() {
        if (props.onClose) {
            props.onClose();
        }
    }

    const handleDeleteClickOpen = () => {
        setOpen(true);
    }

    const handleDeleteClose = (deleteConfirm: boolean, idsList: any[]) => {
        if (deleteConfirm) {
            RemoveMembers(idsList);
            setIdsToDelete([]);
        } else {
            setIdsToDelete([]);
        }
        setOpen(false);
    }

    const DeleteButtonCall = (row: any) => {
        setIdsToDelete([row.id]);
        handleDeleteClickOpen();
        record.detail = false;
    }

    const handleClose = () => {
        setOpenError(false);
    }

    const RetrieveList = (id: any, type: any, filter: any) => {

        if (!id) {
            setLoadingUnLinkedUserList(false);
            setLoading(false);
            return;
        }

        if (!type) {
            setLoadingUnLinkedUserList(false);
            setLoading(false);
            return;
        }

        if (loading == false) {
            setLoadingUnLinkedUserList(true);
            setLoading(true);
            messageFlow("Buscando membros do grupo")

            setListData([]);

            ServiceInstance.RetrieveList(id, type)
                .then(response => {
                    setListData(response.data.dataCollection ? 
                        response.data.dataCollection.sort((a, b) => a.displayName.localeCompare(b.displayName)) : []);
                })
                .catch((error) => {
                    console.error(error);
                    setOpenError(true);
                })
                .then(() => {
                    setLoading(false);
                    setLoadingUnLinkedUserList(false);
                })
        }
    };

    const RetrieveUnLinkedUserList = (id: any, type: any) => {
        setLoadingUnLinkedUserList(true);
        messageFlow("Buscando membros");

        if (filterText != undefined) {
            filterUnLinked.filter = filterText as string;
        }

        ServiceInstance.RetrieveUnLinkedUserList(id, type, filterUnLinked)
            .then(response => {
                setFullUnLinkedUserCount(response.data.dataCollection.length);
                setUnLinkedUserList(response.data.dataCollection.reverse());
            })
            .catch((error: any) => {
                console.warn(error);
            })
            .then(() => {
                setLoadingUnLinkedUserList(false);
            })
    }



    const executeAttachMemberGroupLinks = async (items: any) => {
        SetVisibleNewMemberForm(false);

        var arrayOp = items.map((itemLocal) => {
            return {
                Type: props.data.type,
                GroupId: props.data.id,
                UserId: itemLocal.objectId
            }
        });


        setLoadingUnLinkedUserList(true);
        setLoading(true);
        messageFlow("Adicionando membro ao grupo");
        ServiceInstance.CreateUserGroupLinks(arrayOp)
            .then(response => {
                enqueueSnackbar(`Vinculados com sucesso.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false
                })
            })
            .catch(console.warn)
            .then(() => {
                setLoadingUnLinkedUserList(false);
                setLoading(false);
                RetrieveList(props.data.id, props.data.type, filter);
            })
    }

    const executeAttachMemberGroup = async (item: any) => {
        setLoadingUnLinkedUserList(true);
        setLoading(true);
        messageFlow("Adicionando membro ao grupo");
        ServiceInstance.CreateUserGroupLink(item.objectId, props.data.id, props.data.type)
            .then(response => {
                enqueueSnackbar(`${item.displayName} vinculado com sucesso.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                RetrieveList(props.data.id, props.data.type, filter);
            })
            .catch(console.warn)
            .then(() => {
                setLoadingUnLinkedUserList(false);
                setLoading(false);
                RetrieveList(props.data.id, props.data.type, filter);
            });
    }

    const SaveSelectedNewMember = async (items: any[]) => {
        let savedItens = [];
        setLoadingUnLinkedUserList(true);
        setLoading(true);
        setProgress(1);
        var count = items.length;
        var arrayOp: any[] = []
        items.map((item) => {
            arrayOp.push(executeAttachMemberGroup(item));
        });
        for (let promise of [arrayOp]) {
            const frer = await promise;
        }
    }

    const RemoveMembers = async (item: any[]) => {

        setLoading(true);
        setLoadingUnLinkedUserList(true);
        //
        messageFlow("Remover membro do grupo");
        ServiceInstance.RemoveUserGroupLink(item, props.data.id, props.data.type)
            .then((tt) => {
                setLoadingUnLinkedUserList(false);
                setTimeout(() => RetrieveList(props.data.id, props.data.type, filter), 3000);
                ;
            }).catch((yy) => {
                var message = yy;
                message = yy && yy.response;
                message = yy && yy.response && yy.response.data;
                enqueueSnackbar(`${message}`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                setLoading(false);
                setLoadingUnLinkedUserList(false);
            });

    }
    //adi��o de membro ao grupo 
    const messageFlow = async (action: string) => {
        enqueueSnackbar(` ${action} foi iniciado, aguarde alguns instantes`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
            autoHideDuration: 3000,
        });
        // setTimeout(() => enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'info',
        //     preventDuplicate: true,
        //     persist: false,
        //     autoHideDuration: 3000,
        // }), 5000);
        // (loading) && setTimeout(() => enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'warning',
        //     preventDuplicate: true,
        //     persist: false,
        // }), 13000);
        // setTimeout(() => (loading) && enqueueSnackbar(`${action} está em andamento, aguarde alguns instantes`, {
        //     variant: 'warning',
        //     preventDuplicate: true,
        //     persist: false,
        // }), 20000);
    }

    const Details = (item: any) => {
        reset();
        var selectedRows = ListData.filter(x => x.id == item);
        if (selectedRows.length > 0) {
            let addRecord = { ...emptyRecordMessageMembros };
            addRecord.detail = true;
            addRecord.edit = false;
            addRecord.record = selectedRows[0];
            setRecord(addRecord);
            let template = { ...ServiceInstance.template(record.record, false) };
            setTemplate(template);
        } else {
            let addRecord = { ...emptyRecordMessageMembros };
            addRecord.detail = false;
            addRecord.edit = false;
            setRecord(addRecord);
            let template = { ...ServiceInstance.template(record.record, false) };
            setTemplate(template);
        }
    }

    const selecionadoEditar = (childData: any) => {
        if (childData != null) {
            var item = ListData.filter(x => x.id == childData)[0];
        }
        else {
            setRecord({ ...emptyRecordMessageMembros })
        }
    }

    useEffect(() => {
        var filtered = ListData.filter((p: any) =>
            filterPage.term == '' ||
            p.displayName?.toLowerCase().indexOf(filterPage.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filterPage.direction == 'asc') return (a[filterPage.column] > b[filterPage.column]) ? 1 : -1;
                else return (a[filterPage.column] > b[filterPage.column]) ? -1 : 1;
            });
        setFilteredData(filtered);
        setVisibleListData(filtered.slice(0, filterPage.size));
    }, [filterPage]);

    const _filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilterPage({ ...filterPage, size: size, term: _filter[0].value.toLowerCase() })
    }

    const functionsRow = [
        { label: 'Excluir', func: DeleteButtonCall, overflow: "visible" },
    ]

    const loadMore = (size?: number) => {
        if (size) setVisibleListData(filteredData.slice(0, size));
        else setVisibleListData([...visibleListData, ...filteredData.slice(visibleListData.length, visibleListData.length + 10)])
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterPage({ ...filterPage, column: sort.column, direction: sort.direction, size: size })
    };

    return (
        <>
            <h3>{props.header}</h3>
            <LcLoading loading={loading}>
                {
                    record.edit != true && record.add != true &&
                    <LcTable
                        height="calc(100% - 38px)"
                        data={ListData}
                        columns={columns}
                        functions={functions.functionsList}
                        functionsRow={functionsRow}

                        filterFields={[{ label: 'Pesquisar', name: 'search', type: 'text' }]}
                    />
                }
            </LcLoading>

            <Snackbar
                key={'error'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openError}
                autoHideDuration={4000}
                TransitionProps={{ onExited: handleClose }}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    action={
                        <div>
                            <Button color="secondary" size="large" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>
                                <i className="lci lci-hand" style={{ color: '#611A15' }} />
                            </Button>
                            <IconButton aria-label="close"
                                color="secondary"
                                onClick={handleClose}
                            >
                                x
                            </IconButton>
                        </div>
                    }
                >
                    Houve um problema na comunicação com o fabricante. Por favor tente mais tarde. Caso precise podemos fazer isto por você através de uma requisição.
                </Alert>
            </Snackbar>

            <DialogConfirmForm onCloseEvent={(approved: boolean) => { handleDeleteClose(approved, idsToDelete); }}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
            </DialogConfirmForm>

            <PPModal
                title={'Adicionar usuários ao grupo'}
                visible={visibleNewMemberForm}
                onClose={() => { SetVisibleNewMemberForm(false); }}
                functions={[
                    {
                        label: "Adicionar selecionados",
                        onClick: () => executeAttachMemberGroupLinks(UnLinkedUserListSelected),
                        disabled: UnLinkedUserListSelected.length === 0
                    }
                ]}
            >
                <LcLoading loading={loadingUnLinkedUserList}>
                    <LcCheckLabelValueList
                        onChange={setUnLinkedUserListSelected}
                        labelField="mailNickname"
                        keyField="objectId"
                        title='Usuários Disponíveis'
                        list={UnLinkedUserList}
                        search={true}
                    />
                </LcLoading>
            </PPModal >
        </ >
    );

}

export default SaaSGroupMembersListLinked;