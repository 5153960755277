import React from 'react';
import LcInfiniteTable, { TableColumn } from '../../../components/Data/LcInfiniteTable';
import { devicesEvent } from '../resumeEvent/Models';
import { useMostTargetData } from './Hook/useMostTargetData';
import LcNoData from '../../../components/Generic/LcNoData';

interface MostTargetTableProps {
    resumeEventReport: devicesEvent[];
    mostTargetSelected: string;
    period: string;
    rowsFiltered: any[];
    filtered: boolean;
    setFilterWithPeriod: (rows: any[]) => void
}

export const MostTargetTable: React.FC<MostTargetTableProps> = ({
        resumeEventReport,
        mostTargetSelected,
        period,
        rowsFiltered,
        filtered,
        setFilterWithPeriod
    }) => {

    const { rows, groupNames, eventsFiltered } = useMostTargetData(resumeEventReport, mostTargetSelected, period, rowsFiltered, filtered);
    setFilterWithPeriod(eventsFiltered)
    const columns: TableColumn[] = [
        {
            field: 'device', headerName: 'Dispositivo/Usuário', width: '80%', align: 'left'
        },
        {
            field: 'count', headerName: 'Eventos', width: '10%', align:'left'
        }
    ];

    const getStatus = (row: any)  => {
        let classification = {
            "Suspeita": '#d3281d',
            "Maliciosa": '#dc291e',
            "Inconclusiva": '#ffa400',
            "PUP": '#ddbb7b',
        }
        return classification[row.classification]
    }

    const onSortChange = (sortData: any) => {
        const { sort } = sortData;
        let _events = { ...rows[0] };
        Object.keys(_events).forEach(device => {
            _events[device] = _events[device].sort((a: any, b: any) => {
                if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
            });
        });
    };
    return (
        <>
            {
                rows.length > 0 ?
                    <LcInfiniteTable 
                    className='mostTargetsTable'
                    height='90%'
                    rowAutoHeight
                    rows={rows} 
                    onSortChange={onSortChange}
                    columns={columns} 
                    groupName={groupNames} 
                    fontSize='medium'
                    status={getStatus}
                    collapsedGroups={true}
                    hidePagination
                    noDataMessage='Não há dados para serem exibidos no momento.'
                    />
                :
                <LcNoData label='Não há dados disponiveis neste periodo.'/>
            }
        </>
        );
}
