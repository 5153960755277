import Grid, { GridContentAlignment, GridDirection, GridItemsAlignment, GridJustification, GridSpacing } from '@material-ui/core/Grid';
import React from 'react';

interface Props {
  alignItems?: GridItemsAlignment,
  justify?: GridJustification,
  direction?: GridDirection,
  spacing: GridSpacing,
  alignContent?: GridContentAlignment,
  justifyContent?: GridJustification
}

const LCFlexContainer: React.FC<Props> = (props) => {

  return (
    <Grid container justifyContent={props.justifyContent} alignContent={props.alignContent} spacing={props.spacing} alignItems={props.alignItems}   direction={props.direction}>
      {props.children}
    </Grid>
  );

}

export default LCFlexContainer;