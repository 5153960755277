import { Grid } from '@material-ui/core';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';  
 
//Redux
import { useSelector } from 'react-redux';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer'; 
import { TreeItem, TreeView } from '@material-ui/lab';
import { PPIconLink } from 'processor-plataform-ui';
import PivotTableWebix from '../../../components/webix';
import { PiInfoLight } from 'react-icons/pi';
// the AG Grid React Component import "@finos/perspective-viewer";


const BackupGoldHistoricoNew: React.FC = (props) => {
    function getStatePivot() {
        try {
            var stringsessionStorageGet = sessionStorage.getItem("statePivotTableUIProps");
            var objstatePivotTableUIProps = JSON.parse(stringsessionStorageGet ? stringsessionStorageGet : "") as object;
            if (objstatePivotTableUIProps) {
                return objstatePivotTableUIProps;
            }
        } catch (e) {

        }

        return undefined as unknown as object;
    }
    const backupGoldService = new BackupGoldService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [jobs, setJobs] = useState<any[]>([]);
    const [rows, setRows] = useState<string[]>(['Situação', 'Agente', 'Tipo', 'SubCliente']);//
    const [cols, setCols] = useState<string[]>([]);//
    const [servers, setServers] = useState<any[]>([]);
    const [agentTypes, setAgentTypes] = useState<any[]>([]);
    const [backupTypes, setBackupTypes] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modeView, setModeView] = useState<string>("Tabela");
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [dataTree, setDataTree] = useState<any>();

    async function retrieveDataFromBackupDynamo() {
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));

        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            var currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
            var currentYear = new Date().getFullYear().toString();
            setIsLoadingJobs(true);

            if (month) {
                currentMonth = month
            }
            if (year) {
                currentYear = year
            }
            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }

            if (user.ClientGroupSelected && currentMonth && currentYear) {
                const startDate = [currentYear, currentMonth, '01'].join('-');
                const endDate = [currentYear, currentMonth, new Date(parseInt(currentYear ? currentYear :
                    new Date().getFullYear().toString()), parseInt(currentMonth ? currentMonth :
                        (new Date().getMonth() + 1).toString().padStart(2, '0')), 0).getDate().toString()].join('-');
                var response = (await backupGoldService.GetHistory(startDate, endDate));
                let jobsResponse = response ? response.data : [];
                let attListNackup = [];

                var jobsLocal = jobsResponse
                    .map((Job: {
                        id: string,
                        situacao: string,
                        systemName: string,
                        taskName: string,
                        backupType: string,
                        backupDateTime: any,
                        size: number,
                        instanceName: string,
                        vmNames: string,
                        content: string,
                        subClientName: string
                        year: number,
                        month: number,
                        day: number,
                        hour: number,
                        minute: number,
                    }, index: number) => {

                        return {
                            id: Job.id,
                            Situacao: Job.situacao,
                            SystemName: Job.systemName,
                            TaskName: Job.taskName,
                            BackupType: Job.backupType ? Job.backupType.toLowerCase() : 'N/A',
                            BackupDateTime: Job.backupDateTime ? Moment(Job.backupDateTime).format('DD/MM/YYYY') : '-',
                            Size: Number(Job.size),
                            InstanceName: Job.instanceName,
                            SubClientName: Job.subClientName,
                            VMNames: Job.vmNames,
                            content: Job.content,
                            year: Job.year,
                            month: Job.month,
                            day: Job.day,
                            hour: Job.hour,
                            minute: Job.minute,
                        }
                    });

                setJobs(jobsLocal);

                var localjobsDatatoTree = [...new Set(jobsLocal.map(x => x["SystemName"]))]
                    .map((item: any) => {
                        var recordItemServidor = {};
                        recordItemServidor["Servidor"] = item;
                        try {
                            var arrayAgents = jobsLocal.filter(c => c.SystemName == item
                            );
                            //Agent
                            var arrayAgentsGroupByProcessed = [...new Set(arrayAgents.map(x => x["TaskName"]))]
                                .map((itemAgent: any) => {
                                    var recordItemAgent = {};
                                    recordItemAgent["Agent"] = itemAgent;
                                    var arrayInstances = jobsLocal.filter(c =>
                                        c.SystemName == item &&
                                        c.TaskName == itemAgent);
                                    //Instancia

                                    var arrayInstanceGroupByProcessed = [...new Set(arrayInstances.map(x => x["InstanceName"]))]
                                        .map((itemInstance: any) => {
                                            var recordItemInstancia = {};
                                            recordItemInstancia["Instancia"] = itemInstance;

                                            var arraySubClientes = jobsLocal.filter(c =>
                                                c.SystemName == item &&
                                                c.InstanceName == itemInstance &&
                                                c.TaskName == itemAgent);
                                            //SubClientes
                                            var arraySubClientesGroupByProcessed =
                                                [...new Set(arraySubClientes.map(x => x["SubClientName"]))]
                                                    .map((itemSubCliente: any) => {
                                                        var recordItemSubCliente = {};
                                                        recordItemSubCliente["SubCliente"] = itemSubCliente;
                                                        // Tipo: item.BackupType,
                                                        var arrayBackupType = jobsLocal
                                                            .filter(c =>
                                                                c.SystemName == item &&
                                                                c.InstanceName == itemInstance &&
                                                                c.SubClientName == itemSubCliente &&
                                                                c.TaskName == itemAgent);

                                                        //Tipo
                                                        var arrayBackupTypeGroupByProcessed =
                                                            [...new Set(arrayBackupType.map(x => x["BackupType"]))]
                                                                .map((itemTipo: any) => {
                                                                    var recordItemBackupType = {};
                                                                    recordItemBackupType["Tipo"] = itemTipo;
                                                                    // Tipo: item.BackupType,
                                                                    var ContentTypes = jobsLocal
                                                                        .filter(c =>
                                                                            c.SystemName == item &&
                                                                            c.InstanceName == itemInstance &&
                                                                            c.SubClientName == itemSubCliente &&
                                                                            c.BackupType == itemTipo &&
                                                                            c.TaskName == itemAgent
                                                                        );

                                                                    var arrayVMsGroupByProcessed = [] as string[];
                                                                    [...new Set(ContentTypes.map(x => x["VMNames"]))]
                                                                        .map((itemVM: any) => {
                                                                            if (itemVM != null) {
                                                                                if (itemVM.includes(',')) {
                                                                                    itemVM.split(',').map(vmItem => arrayVMsGroupByProcessed.push(vmItem));
                                                                                }
                                                                                else { arrayVMsGroupByProcessed.push(itemVM) }
                                                                            }
                                                                            return itemVM;
                                                                        });

                                                                    if (arrayVMsGroupByProcessed != undefined && arrayVMsGroupByProcessed && arrayVMsGroupByProcessed.length > 0)
                                                                        recordItemBackupType["VMs"] = arrayVMsGroupByProcessed;

                                                                    var arrayContentsGroupByProcessed = [] as string[];
                                                                    [...new Set(ContentTypes.map(x => x["content"]))]
                                                                        .map((content: any) => {
                                                                            if (content != null) {
                                                                                if (content.includes(';')) {
                                                                                    content.split(';').map(conItem => arrayContentsGroupByProcessed.push(conItem));
                                                                                }
                                                                                else { arrayContentsGroupByProcessed.push(content); }
                                                                            }
                                                                            return content;
                                                                        });

                                                                    if (arrayContentsGroupByProcessed != undefined && arrayContentsGroupByProcessed && arrayContentsGroupByProcessed.length > 0)
                                                                        recordItemBackupType["Contents"] = arrayContentsGroupByProcessed;
                                                                    return recordItemBackupType;
                                                                });

                                                        if (arrayBackupTypeGroupByProcessed != undefined)
                                                            recordItemSubCliente["Tipos"] = arrayBackupTypeGroupByProcessed;
                                                        return recordItemSubCliente;
                                                    });
                                            if (arraySubClientesGroupByProcessed != undefined) {
                                                recordItemInstancia["SubClientes"] = arraySubClientesGroupByProcessed;
                                            }

                                            return recordItemInstancia;
                                        });

                                    if (arrayInstanceGroupByProcessed != undefined) {
                                        recordItemAgent["Instancias"] = arrayInstanceGroupByProcessed;
                                    }

                                    return recordItemAgent;
                                })

                            recordItemServidor["Agentes"] = arrayAgentsGroupByProcessed;
                        } catch (error) {
                            console.log(error);
                        };

                        return recordItemServidor;
                    });

                setDataTree(localjobsDatatoTree);
                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.log(error);
        };

    }

    useEffect(() => {
        retrieveDataFromBackupDynamo();

    }, [month, user.ClientGroupSelected, year, user.refreshFlag, refresh]);

    useEffect(() => {
        let _server: { value: string, selected: boolean }[] = [];
        let _agentType: { value: string, selected: boolean }[] = [];
        let _backupType: { value: string, selected: boolean }[] = [];

        jobs.map(job => {
            if (_server.findIndex(c => c.value == job.SystemName) == -1) {
                _server.push({ value: job.SystemName, selected: true });
            }
            if (_agentType.findIndex(c => c.value == job.TaskName) == -1) {
                _agentType.push({ value: job.TaskName, selected: true });
            }
            if (_backupType.findIndex(c => c.value == job.BackupType) == -1) {
                _backupType.push({ value: job.BackupType, selected: true });
            }
        })

        setServers(_server.sort((a, b) => a.value > b.value ? 1 : -1));
        setAgentTypes(_agentType.sort((a, b) => a.value > b.value ? 1 : -1));
        setBackupTypes(_backupType.sort((a, b) => a.value > b.value ? 1 : -1));
    }, [jobs]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([
        { field: 'Situação', filter: true },
        { field: 'Agente', filter: true },
        { field: 'SubCliente', filter: true },
        { field: 'Tipo', filter: true }
    ]);

    return (
        <Layout
            pageTitle="Histórico"
            periodSelection={periodSelection}
            pivot
            functionsGeneric={[
                {
                    icon: <PiInfoLight/>,
                    tooltip: () => {
                        return <div className="">
                            Abaixo é apresentado o detalhamento de todos os jobs executados no mês.
                        </div>
                    },
                    onClick: () => null
                },
                /*{ icon: <PiFunnelLight />, tooltip: "Filtro", onClick: () => setModalVisible(!modalVisible) },*/
                /*{ icon: "ppi ppi-adjustments", tooltip: "Configurar pivot", onClick: () => handleOpenPivotConfiguration() }*/
            ]}
            row={<>
                <PPIconLink icon='ppi ppi-filter' onClick={() => setModalVisible(!modalVisible)} tooltip="Filtro" />
                <LcDropDown
                    element={<span>{modeView} <i className="ppi ppi-chevron-down" /></span>}
                    position="right"
                >
                    <div className={`item`} onClick={() => { setModeView("Grade"); }}>Grade</div>
                    <div className={`item`} onClick={() => { setModeView("Tabela"); }}>Tabela</div>
                    {/*<div className={`item`} onClick={() => { setModeView("Arvore"); }}>Arvore</div>*/}
                </LcDropDown>
            </>
            }
        >
            <LcLoading loadingType='Helix' loading={isLoadingJobs}>
                {
                    jobs && jobs.length == 0 &&
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <small><i className="lci lci-exclamation text-warning" /> Não há informações no período</small>
                        </Grid>
                    </Grid>
                }
                {
                    jobs && jobs.length > 0 && modeView == "Grade" &&
                    <div className="lc-pivot-segments" >
                        {
                            servers && agentTypes && backupTypes &&
                            servers.map((server, serverIndex) => {
                                return (
                                    server.selected &&
                                    <div key={serverIndex} className="lc-segment primary">
                                        <span className="title">{server.value}</span>
                                        {
                                            agentTypes.map((agent, agentIndex) => {
                                                return (
                                                    agent.selected &&
                                                    jobs && server && agent && jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value).length > 0 &&

                                                    <div key={agentIndex} className="secondary">
                                                        <div className="left">
                                                            <span className="title">{agent.value}</span>
                                                            {
                                                                backupTypes.map((type, typeIndex) => {
                                                                    return (
                                                                        type.selected &&
                                                                        jobs && server && agent && type && jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value).length > 0 &&

                                                                        <div key={typeIndex} className="tertiary">
                                                                            <div className="left">
                                                                                <span className="title">{type.value}</span>
                                                                                {(() => {
                                                                                    const currentJobs = jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value);
                                                                                    if (currentJobs.length > 0) {
                                                                                        const job = jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value)[(currentJobs.length - 1)];
                                                                                        if (job) {
                                                                                            return (<span className={`info ${job.Situacao.includes("failed") ? 'text-danger' : 'text-success'}`} style={{ width: '300%' }}>{`${job.BackupDateTime}`}</span>);
                                                                                        }
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )

                            })
                        }
                    </div >
                }
                {
                    jobs && jobs.length > 0 && modeView == "Tabela" &&
                    <> 

                        <div style={{ maxWidth: "100%", maxHeight: "calc(100% - 78px)" }}>
                            {jobs && jobs.length > 0 &&
                                <PivotTableWebix
                                    key={'key'}
                                    data={jobs}
                                    fields={[
                                        { id: 'id', value: "Id", type: "text" },
                                        { id: 'Situacao', value: "Situação", type: "text" },
                                        { id: 'SystemName', value: "Servidor", type: "text" },
                                        { id: 'TaskName', value: "Agente", type: "text" },
                                        { id: 'BackupType', value: "Tipo", type: "text" },
                                        { id: 'BackupDateTime', value: "Data", type: "text" },
                                        { id: 'Size', value: "Tamanho(GB)", type: "text", prepare: value => value ? (((((value / 1024) / 1024)) / 1024)) : 0.00 },
                                        { id: 'InstanceName', value: "Instancia", type: "text" },
                                        { id: 'SubClientName', value: "SubCliente", type: "text" },
                                        { id: 'VMNames', value: "VMs", type: "text" },
                                        { id: 'content', value: "Content", type: "text" },
                                        { id: 'day', value: "Dia", type: "number" },
                                        { id: 'hour', value: "Hora", type: "number" },
                                        { id: 'minute', value: "Minuto", type: "number" },

                                    ]}
                                    structure={{
                                        rows: ['Situacao', 'TaskName', 'BackupType', 'SubClientName'],
                                        values: [{ name: "id", operation: ["count"] }],
                                        filters: [{ name: "Situacao" }, { name: "TaskName" }, { name: "SystemName" }, { name: "BackupDateTime" }]
                                    }}

                                />}
                        </div>
                    </>
                }
                {
                    modeView == "Arvore" && dataTree && <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<i className="ppi ppi-chevron-down" />}
                        defaultExpandIcon={<i className="ppi ppi-chevron-right" />}
                        style={{ flexGrow: 1, overflowY: 'auto' }}
                    >
                        <TreeItem nodeId="1" label="Servidores">

                            {dataTree.map((servidor) => {
                                return (
                                    <TreeItem nodeId={servidor["Servidor"]} label={'Servidor: ' + servidor["Servidor"]}  >
                                        {servidor["Agentes"] && (servidor["Agentes"] as any).map((agent) => {
                                            return (
                                                <TreeItem nodeId={servidor["Servidor"] + agent.Agent} label={'Agent: ' + agent.Agent}  >
                                                    {agent["Instancias"] && (agent["Instancias"] as any).map((instance) => {
                                                        return (
                                                            <TreeItem nodeId={agent.Agent + instance.Instancia} label={'Instancia: ' + instance.Instancia}  >
                                                                {instance && instance["SubClientes"] && (instance["SubClientes"] as any).map((SubCliente) => {
                                                                    return (
                                                                        <TreeItem nodeId={agent.Agent + instance.Instancia + SubCliente.SubCliente} label={'SubCliente: ' + SubCliente.SubCliente}   >
                                                                            {SubCliente["Tipos"] && (SubCliente["Tipos"] as any).map((Tipo) => {
                                                                                return (
                                                                                    <TreeItem nodeId={agent.Agent + instance.Instancia + SubCliente.SubCliente + instance.Instancia + SubCliente.SubCliente + Tipo.Tipo} label={'Tipo Backup: ' + Tipo.Tipo}  >
                                                                                        {Tipo["Contents"] && (Tipo["Contents"] as any).map((Content) => {
                                                                                            return (
                                                                                                <TreeItem nodeId={agent.Agent + instance.Instancia + SubCliente.SubCliente + Content} label={'Content: ' + Content} />
                                                                                            )
                                                                                        })}
                                                                                        {Tipo["VMs"] && (Tipo["VMs"] as any).map((VM) => {
                                                                                            return (<TreeItem nodeId={agent.Agent + instance.Instancia + SubCliente.SubCliente + VM} label={'VM: ' + VM} />)
                                                                                        })}
                                                                                    </TreeItem>)
                                                                            })}
                                                                        </TreeItem>)
                                                                })}
                                                            </TreeItem>)
                                                    })}
                                                </TreeItem>)
                                        })}
                                    </TreeItem>)
                            })}
                        </TreeItem>
                    </TreeView>}
            </LcLoading>

            <SideModal header="Filtro" visible={modalVisible} onClose={() => setModalVisible(false)}>

                <LcAccordion title="Servidores" visible={true}>
                    {
                        servers && servers.length > 0 &&
                        <LcCheckList list={servers} toggle={true} onChange={setServers} search={true} searchPlaceHolder="(Procure por servidores)" title="Servidores" />
                    }
                </LcAccordion>
            </SideModal>
        </Layout>
    );
};

export default BackupGoldHistoricoNew;