import { PP_DropDownOld } from 'processor-plataform-ui';
import React, { useState } from 'react';
import LcToggle from '../../components/Form/LcToggle';
import PPExpand from '../../components/Generic/PPExpand';

const TabDropDown: React.FC = () => {

    const [value, setValue] = useState();

    const options = [
        { label: "Azul", value: "azul" },
        { label: "Verde", value: "verde" },
        { label: "Vermelho", value: "vermelho", disabled: true },
        { label: "Amarelo", value: "amarelo" },
        { label: "Ciano", value: "ciano" },
        { label: "Magenta", value: "magenta" },
        { label: "Preto", value: "preto" },
        { label: "Marrom", value: "marrom" },
        { label: "Roxo", value: "roxo" }
    ];

    const [error, setError] = useState(false);
    const [required, setRequired] = useState(false);

    return (<>
        <h1>DropDown</h1>

        <LcToggle toggled={error} onChange={event => setError(event.checked)} title="Error" />
        <br />
        <LcToggle toggled={required} onChange={event => setRequired(event.checked)} title="Required" />

        <PPExpand title='Estados' marginBotton='15'>
            <div className="form">
                <PP_DropDownOld
                    title="Padrão"
                    onChange={event => setValue(event.target.value)}
                    value={value}
                    openTrigger="click"
                    options={options}
                />

                <PP_DropDownOld
                    title="Erro"
                    onChange={event => setValue(event.target.value)}
                    value={value}
                    openTrigger="click"
                    options={options}
                    error={{ show: false, help: "Erro catastrófico!" }}
                    required={required}
                />

                <PP_DropDownOld
                    title="Obrigatório"
                    onChange={event => setValue(event.target.value)}
                    value={value}
                    openTrigger="click"
                    options={options}
                    required={required}
                />

                <PP_DropDownOld
                    title="Desabilitado"
                    onChange={event => setValue(event.target.value)}
                    value={value}
                    openTrigger="click"
                    options={options}
                    disabled
                    required={required}
                />

                <PP_DropDownOld
                    title="Somente leitura"
                    onChange={event => setValue(event.target.value)}
                    value={value}
                    openTrigger="click"
                    options={options}
                    readOnly
                    required={required}
                />

            </div>
        </PPExpand>

        <PPExpand title='Posições'>
            <PP_DropDownOld
                title="Somente leitura"
                onChange={event => setValue(event.target.value)}
                value={value}
                openTrigger="click"
                options={options}
                required={required}
            />
            <PP_DropDownOld
                title="Somente leitura"
                onChange={event => setValue(event.target.value)}
                value={value}
                openTrigger="click"
                options={options}
                right
                required={required}
            />
            <PP_DropDownOld
                title="Somente leitura"
                onChange={event => setValue(event.target.value)}
                value={value}
                openTrigger="click"
                options={options}
                top
                required={required}
            />
            <PP_DropDownOld
                title="Somente leitura"
                onChange={event => setValue(event.target.value)}
                value={value}
                openTrigger="click"
                options={options}
                top
                right
                required={required}
            />
        </PPExpand>



    </>);
}

export default TabDropDown;