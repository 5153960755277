export interface UserState {
    ClientGroupSelected?: number,
    ClientGroupSelectedDescription?: string,
    ClientGroupList?: any,
    Permission?: any,
    VendorStatusList?: any[],
    Menus?: any[],
    Photo?: string,
    UserId?: string,
    email?: string
    exp?: string
    iat?: string
    name?: string
    nbf?: string
    role?: string,
    refreshFlag?: number,
    IsAdm?: boolean
}
export interface WebPresence {
    costCenterId?: number, 
}
export const webPresenceReducer = (state: WebPresence = {}, action: Action) => {      
    switch (action.type) {
        case 'COSTCENTERUPDATE':
            return state = action.payload;                   
        default:
            return state;
    }
}

type Action = { type: string, payload: any }

export const userReducer = (state: UserState = {}, action: Action) => {

    switch (action.type) {
        case 'USER_UPDATE':
            return state = action.payload;
        case 'USER_UPDATE_REFRESH':
            return { ...state, refreshFlag: action.payload };
        default:
            return state;
    }
}