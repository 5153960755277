import React from "react";

import LcTooltip from '../../../Generic/LcTooltip';

import "./index.css";

interface Props {
  title?: string,
  icon: string,
  value: string | number,
  unity?: string,
  description?: string,
  color: string,
  tooltip?: string,
  formatValue?: boolean,
  maximumFractionDigits?: number,
  onClick?: Function,
  withoutValue?: boolean,
  tooltipNode?: React.ReactNode
}

const IconValueCardCol: React.FC<Props> = (props) => {
  const maximumFractionDigits = props.maximumFractionDigits == null ? 2 : props.maximumFractionDigits;
  const getValue = () => {
    let original = props.value.toString();
    if (!original) return "0";

    if (original == 'undefined') return "0";

    return props.formatValue ? parseFloat(original).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits }) : original;
  };
  return (
    <LcTooltip width="100%" height="100%" position="top" trigger="hover" content={props.tooltipNode}>
      <div className={`iconvaluecardcol ${props.onClick ? 'link' : ''}`} onClick={() => { props.onClick && props.onClick() }}>
        <span className="title">{props.title}</span>
        <div className="body" style={{ color: props.color }}>
          <i className={`icon ${props.icon}`}></i>

          {!props.withoutValue &&
            <span className="value">
              {getValue()}
              <span className="unity">{props.unity}</span>
            </span>
          }

          <div className="info">
            {
              props.description &&
              <span className="label">{props.description}</span>
            }

            {
              props.tooltip &&
              <LcTooltip content={props.tooltip} trigger="hover" position="left">
                <i className="lci lci-exclamation-circle" />
              </LcTooltip>
            }
          </div>
        </div>
      </div>
    </LcTooltip>
  );
}

export default IconValueCardCol;
