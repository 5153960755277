import api, { APIClient } from "../api";

export interface AlertMsg {
    subject: string;
    content: string;
    /** 
    * 'default' | 'danger' | 'success' | 'warning' | 'info'
    */
    context: string;
}

export enum Context {
    success,
    warning,
    danger,
    info
}

export interface Notification {
    notificationDateTime: string; // ISO string format
    userEmail: string;
    title: string;
    subTitle: string;
    content: string;
    isRead: boolean;
    sendEmail: boolean;
    alertHeyHo: boolean;
    importance?: number; // Nullable field
    notificationId: string;
    url: string;
    observation: string;
    pinned: boolean;
    applicationId?: string; // Nullable field
    context: string;
    expiredDate?: string; // Nullable field, ISO string format
}

export interface NotificationSend {
    id: string;
    createdBy: string;
    createdOn: string;
    pinned: boolean;
    context: Context;
    notification: Notification;
    clientGroupIds?: number[];
    sendEmail?: boolean;
}

export interface NotificationSendFilter {
    email?: string;
    pinned?: boolean | null;
    clientGroupId?: number;
    month: number | string;
    year: number | string;
}

export interface NotificationsNotRead {
    notifications: NotificationLogCenter[];
    unreadCount: number;
}

export interface NotificationLogCenter {
    hashKey: string;
    rangeKey: string;
    notificationDateTime: Date;
    notificationDate: string;
    notificationTime: string;
    userEmail: string;
    subject: string;
    content: string;
    notificationType: string;
    applicationId: string;
    isRead: boolean;
    notificationId: string;
    version: string;
    url: string;
    observation: string;
    additionalInfo: string;
    pinned: boolean;
    context: string;
    notificationSendId: string;
    expiredDate: Date;
}

export interface NotificationModel {
    notificationDateTime: string;
    userEmail: string;
    title: string;
    subTitle: string | null;
    content: string;
    isRead: boolean;
    sendEmail: boolean;
    alertHeyHo: boolean;
    importance: string | null;
    notificationId: string;
    url: string;
    observation: string;
    pinned: boolean;
    applicationId: string | null;
    context: string;
    expiredDate: string | null;
}

export function convertToNotification(model: NotificationModel): Notification {
    return {
        notificationDateTime: model.notificationDateTime,
        userEmail: model.userEmail,
        title: model.title,
        subTitle: model.subTitle ?? '', // Convert null to empty string
        content: model.content,
        isRead: model.isRead,
        sendEmail: model.sendEmail,
        alertHeyHo: model.alertHeyHo,
        importance: model.importance ? parseInt(model.importance, 10) : undefined, // Convert string to number
        notificationId: model.notificationId,
        url: model.url,
        observation: model.observation,
        pinned: model.pinned,
        applicationId: model.applicationId ?? undefined, // Map null to undefined
        context: model.context,
        expiredDate: model.expiredDate ? model.expiredDate : undefined, // Convert string to Date
    };
}

export class NotificationCenterService extends APIClient {
    async GetNotifications(searchText: string): Promise<NotificationModel[]> {
        const url = `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationCenter/GetCustomerNotifications?searchText=${encodeURIComponent(searchText)}&refresh=${Math.random()}`;
        try {
            console.log('GetNotifications')
            console.log(url)
            var response = await api({
                method: 'get',
                url: url
            });
            console.log(response.data)
            if (response.data && response.data.notifications) {
                return response.data.notifications;
            }
        } catch (e) {
            console.warn('GetNotifications:', e);
        }
        return [] as NotificationModel[];
    }

    async GetReadNotificationsPaged(page: number, pageSize: number, searchText: string): Promise<NotificationModel[]> {
        const url = `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationCenter/GetReadNotificationsPaged?page=${page}&pageSize=${pageSize}&searchText=${encodeURIComponent(searchText)}&refresh=${Math.random()}`;
        try {
            console.log('GetReadNotificationsPaged')
            console.log(url)
            var response = await api({
                method: 'get',
                url: url
            });
            console.log(response.data)
            if (response.data && response.data.notifications) {
                return response.data.notifications;
            }
        } catch (e) {
            console.warn('GetReadNotificationsPaged:', e);
        }
        return [] as NotificationModel[];
    }

    async PostNotifications(notification: Notification) {
        console.log('PostNotifications')
        var url = `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationCenter/PostCustomerNotifications`
        console.log(url)
        console.log(notification)
        return api({
            method: 'post',
            url: url,
            data: [notification]
        });
    }

    PostInterfaceNotification(dispatch: Function, alert: AlertMsg) {
        console.log('PostInterfaceNotification')
        dispatch({
            type: 'MSG_ADD_ALERT',
            payload: {
                id: "a43423c2-db78-4567-b03f-" + Math.floor(100000000000 + Math.random() * (999999999999 - 100000)),
                subject: alert.subject,
                content: alert.content,
                notificationDateTime: new Date().toISOString(),
                isRead: false,
                pinned: false,
                context: alert.context,
                type: "alert"
            }
        });
    }

    GetNowDate() {
        let d = new Date()
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    }

    FormatDate(date: string) {
        if (date == '' || date == undefined) return '';
        let d = new Date(date)
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    }

    FormatDateNoHour(date: string) {
        if (date == '' || date == undefined) return '';
        let d = new Date(date)
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}}`
    }

    GetContextOptions() {
        return [
            { label: Context[Context.success].toString().charAt(0).toUpperCase() + Context[Context.success].toString().slice(1), value: Number(Context.success).toString(), disabled: false },
            { label: Context[Context.info].toString().charAt(0).toUpperCase() + Context[Context.info].toString().slice(1), value: Number(Context.info).toString(), disabled: false },
            { label: Context[Context.warning].toString().charAt(0).toUpperCase() + Context[Context.warning].toString().slice(1), value: Number(Context.warning).toString(), disabled: false },
            { label: Context[Context.danger].toString().charAt(0).toUpperCase() + Context[Context.danger].toString().slice(1), value: Number(Context.danger).toString(), disabled: false }
        ]
    }

    GetContextLabel(number) {
        return Context[number].toString().charAt(0).toUpperCase() + Context[number].toString().slice(1)
    }

    GetCleanFilter(month: number | string, year: number | string) {
        let filter: NotificationSendFilter = {
            month: month,
            year: year,
            clientGroupId: 0,
            email: '',
            pinned: null
        }
        return filter;
    }

    GetPinnedOptions() {
        return [
            { value: 1, name: 'Fixadas' },
            { value: 2, name: 'Não fixadas' }
        ]
    }

    async GetNotificationsSendByFilter(filter?: NotificationSendFilter) {
        let urlParams = `?month=${filter?.month}&year=${filter?.year}`;

        if (filter?.email != '' && filter?.email) {
            urlParams += `&email=${filter?.email}`;
        }
        if (filter?.pinned != null) {
            urlParams += `&pinned=${filter?.pinned}`
        }
        if (filter?.clientGroupId != 0 && filter?.clientGroupId) {
            urlParams += `&clientGroupId=${filter?.clientGroupId}`
        }
        return api({
            method: 'get',
            url: `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationSend/GetAllByFilter${urlParams}&refresh=${Math.random()}`,
        });
    }

    async GetNotificationSendByDate(year: string, month: string) {
        return api({
            method: 'get',
            url: `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationSend/GetByDate/${month}/${year}?refresh=${Math.random()}`,
        });
    }

    async GetNotificationSendGetAll() {
        return api({
            method: 'get',
            url: `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationSend/GetAll?refresh=${Math.random()}`,
        });
    }

    async Post(params: any) {
        console.log(params)
        var url = `${process.env.REACT_APP_BFFENDPOINT}/api/NotificationSend/Post`;
        console.log('url')
        return api({
            method: 'post',
            url: url,
            data: params
        });
    }
}
