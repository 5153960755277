import React from 'react';

interface ResourcePriceInfoProps {
    instanceType: any;
    instanceHourCost: number;
    instanceWeekCost: number;
    usingResize: boolean
}

export const ResourcePriceInfo: React.FC<ResourcePriceInfoProps> = ({instanceHourCost, instanceType, instanceWeekCost, usingResize}) => {
    return (
        <div className='resize-review-col'>
            <span>Tamanho {instanceType?.name ? '' : 'atual'}</span>
            <span className='resize-review-sizeline'>{instanceType.name || (<>&nbsp;</>)}</span>
            <span>{instanceType.vcpUs}</span>
            <span>{instanceType.vram}</span>
            <span>{instanceType.vDisks}</span>
            <span>R$ {instanceHourCost.toFixed(2)}</span>
            <span>R$ {instanceWeekCost.toFixed(2)}</span>
        </div>
    )
}
