import { useEffect, useState } from 'react';

import { PPGrid, PPInput, PPModal, PPTextArea } from 'processor-plataform-ui';
import React from 'react';
import PPDropDown from '../../../../components/PPDropDown';
import LcLoading from '../../../../components/Generic/LcLoading';
import { MdInfoOutline } from 'react-icons/md';
import { Badge, Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { SiMicrosoftazure } from 'react-icons/si';
import { FaAws } from 'react-icons/fa';
import moment from 'moment';
import { PiMoon, PiSun, PiWarning } from 'react-icons/pi';
import LcCheckBox from '../../../../components/Form/LcCheckBox';
import { HeyHoService } from '../../../../services/heyHoService';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import './index.css';
import { useQuery } from 'react-query';
import AutomationsService from '../../../../services/automations/automationsService';
import {
    AutomationFormData, AutomationFormFieldAccess, AutomationFormProps, AutomationFormValidation, AutomationHeyHoServiceGroup,
    AutomationHeyHoTicket, AutomationInstanceType, AutomationProvider, AutomationResource, AutomationResourceDetails, AutomationsScheduler,
    AutomationsSchedulerData, AutomationTemplate, GetCostParams, InstanceTypeListOption, ListOption, ScheduledTime
} from '../../../../services/automations/automationsModel';
import { AutomationCostDetail } from '../AutomationCostDetail';
import { SavingsPlansReservesResources } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import { FinOpsSavingPlanReserveService } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserve';
import Confirmation from '../../../../components/Dialog/Confirmation';

const AutomationForm: React.FC<AutomationFormProps> = (props) => {
    const automationsService = new AutomationsService(props);
    const savingsReservesService = new FinOpsSavingPlanReserveService(props);
    const [heyhoService] = useState<HeyHoService>(new HeyHoService(props));
    const user = useSelector<RootState, UserState>(state => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const defaultFormValidations: AutomationFormValidation[] = Object
        .keys(automationsService.emptyAutomationFormData)
        .map(field => ({ field, isValid: false, message: '' }));

    const emptyAutomationScheduler: AutomationsScheduler = {
        id: 0,
        automationId: 0,
        data: '',
        status: 0,
        weekDaysScheduled: '',
        hourScheduled: '',
        lastRun: '',
        createdAt: '',
        createdBy: '',
        statusLabel: ''
    };

    const [templates, setTemplates] = useState<AutomationTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<AutomationTemplate>();
    const [providers, setProviders] = useState<AutomationProvider[]>([]);
    const [automation, setAutomation] = useState<AutomationsScheduler>();
    const [show, setShow] = useState<boolean>(false);
    const [canRequestSchedule, setCanRequestSchedule] = useState<boolean>(!user.IsAdm);
    const [canCreateAvailability, setCanCreateAvailability] = useState<boolean>(false);
    const [canCreateResizing, setCanCreateResizing] = useState<boolean>(false);
    const [formData, setFormData] = useState<AutomationFormData>(automationsService.emptyAutomationFormData);
    const [formValidations, setFormValidations] = useState<AutomationFormValidation[]>(defaultFormValidations);
    const [formFieldAccessControl, setFormFieldAccessControl] = useState<AutomationFormFieldAccess>(automationsService.defaultAutomationFormFieldAccess);
    const [resourceDetails, setResourceDetails] = useState<AutomationResourceDetails>(automationsService.emptyAutomationResourceDetails);
    const [lastSubscriptionRequested, setLastSubscriptionRequested] = useState<string>();
    const [lastTypeRequested, setLastTypeRequested] = useState<string>();
    const [relatedAutomations, setRelatedAutomations] = useState<AutomationsScheduler[]>([]);
    const [warnNoRelatedAutomations, setWarnNoRelatedAutomations] = useState<boolean>(false);
    const [createTurnBackOnAutomation, setCreateTurnBackOnAutomation] = useState<boolean>(false);
    const [heyhoTicketObs, setHeyhoTicketObs] = useState<string>('');
    const [showFormFilterStep, setShowFormFilterStep] = useState<boolean>(true);
    const [showFormScheduleStep, setShowFormScheduleStep] = useState<boolean>(false);
    const [showFormTurnBackOnSection, setShowFormTurnBackOnSection] = useState<boolean>(false);
    const [showFormResizeReviewStep, setShowFormResizeReviewStep] = useState<boolean>(false);
    const [showRelatedAutomationsPanel, setShowRelatedAutomationsPanel] = useState<boolean>(false);
    const [showHeyhoTicketModal, setShowHeyhoTicketModal] = useState<boolean>(false);
    const [showOptimizedResourceWarning, setShowOptimizedResourceWarning] = useState<boolean>(false);
    const [resourcesReservesAndPlans, setResourcesReservesAndPlans] = useState<SavingsPlansReservesResources[]>([]);
    const [optimizedResource, setOptimizedResource] = useState<SavingsPlansReservesResources>();

    const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
    const [isSendingTicket, setIsSendingTicket] = useState<boolean>(false);

    const [usingTurnOff, setUsingTurnOff] = useState<boolean>(false);
    const [usingResize, setUsingResize] = useState<boolean>(false);
    const [usingECSResize, setUsingECSResize] = useState<boolean>(false);

    const [noResourceFound] = useState<ListOption>({ label: 'Nenhum recurso encontrado com esse nome! Considere recriar a automação.', value: -1 as number });

    const handleProps = () => {
        setTemplates(props.templates);
        setProviders(props.providers);
        setCanRequestSchedule(props.canRequestSchedule || (user.IsAdm === false));
        setCanCreateAvailability(props.canCreateAvailability || user.IsAdm === true);
        setCanCreateResizing(props.canCreateResizing || user.IsAdm === true);
        setAutomation(props.automation);
        setShow(props.show);
    };
    useEffect(handleProps, [props, user.IsAdm]);

    const filterResourceInfo = (resourceId: string) => {
        let targetResource = resourceDetails.resources.find(res => res.resourceID === resourceId);
        return targetResource ?? automationsService.emptyAutomationResource;
    };

    const filterResourceInstanceTypeInfo = (resourceId: string) => {
        let targetResource = filterResourceInfo(resourceId);
        if (targetResource.resourceID !== '') {
            let targetInstanceType = resourceDetails.instanceTypes.find(ins => ins.name === targetResource.instanceSize);
            return targetInstanceType ?? automationsService.emptyAutomationInstanceType;
        }
        return automationsService.emptyAutomationInstanceType;
    };

    const { data: resourcesBySubscription, isLoading: isLoadingResourcesBySubscription } = useQuery(
        ['resourcesBySubscription', formData.provider.value, formData.group.value, formData.subscription.value],
        () => automationsService.GetResourcesBySubscription(formData.provider.value as number,
            formData.group.value as string,
            formData.subscription.value as string)
    );

    const { data: instanceTypesBySubscription, isLoading: isLoadingInstanceTypesBySubscription } = useQuery(
        ['instanceTypesBySubscription', formData.provider.value, formData.group.value, formData.subscription.value],
        () => automationsService.GetInstanceTypesBySubscription(formData.provider.value as number,
            formData.group.value as string, formData.subscription.value as string)
    );

    const { data: costData, refetch: refetchCostData, isLoading: isLoadingCostData, isFetching: isFetchingCostData } = useQuery(
        ['costData', formData.resource.value, formData.instanceType.value],
        () => {
            const resourceInfo = filterResourceInfo(formData.resource.value as string);
            const params: GetCostParams = {
                provider: resourceInfo.resourceProvider,
                accsub: resourceInfo.resourceSubscription,
                region: resourceInfo.resourceRegion,
                serviceCode: resourceInfo.resourceProvider === 'AWS' ? 'AmazonEC2' : 'AmazonRDS',
                armSkuName: resourceInfo.instanceSize,
                compareArmSkuName: formData.instanceType.value.toString()
            };
            return automationsService.getCost(params);
        },
        {
            enabled: !!formData.resource.value && !!formData.instanceType.value
        }
    );

    const startCreationForm = () => {
        setShowFormFilterStep(true);
        setShowFormScheduleStep(false);
        setShowFormTurnBackOnSection(false);
        setShowFormResizeReviewStep(false);
        const newForm = { ...automationsService.emptyAutomationFormData };
        newForm.providers = providers.flatMap(p => p.id)
            .filter(p => automationsService.automationsProvidersDic.hasOwnProperty(p))
            .map(p => ({ label: automationsService.automationsProvidersDic[p], value: p } as ListOption));
        newForm.groups = Array.from(new Set(templates.map(t => t.groupType)))
            .filter(t => automationsService.groupsDic.hasOwnProperty(t))
            .map(t => ({ label: automationsService.groupsDic[t], value: t } as ListOption));
        newForm.time = { ...automationsService.defaultScheduledTime };
        newForm.turnBackOnTime = { ...automationsService.defaultScheduledTime };
        setFormData(newForm);
        setFormFieldAccessControl({
            ...formFieldAccessControl,
            provider: true,
            subscription: false,
            group: false,
            type: false,
            resource: true,
            instanceType: true,
            weekDays: true,
            time: true,
            turnBackOnTime: true,
            turnBackOnWeekDays: true
        });
    };


    const startEditingForm = async () => {
        if (automation) {
            const currentFormData = { ...formData };
            const currentFormValidations = [...formValidations];
            const template = templates.find(t => t.id === automation.automationId)!;
            setSelectedTemplate(template);

            const weekDays = { ...automationsService.defaultScheduledWeekDays };
            Object.keys(automationsService.defaultScheduledWeekDays).forEach(day => weekDays[day] = automation.weekDaysScheduled.indexOf(day) > -1);
            const usingResize = template.serviceType === 'RESIZE' && template.groupType !== 'Container';
            const usingECSResize = template.serviceType === 'RESIZE' && template.groupType === 'Container';
            const data = JSON.parse(automation.data) as AutomationsSchedulerData;
            const provider = { label: automationsService.automationsProvidersDic[template.cloudOSId], value: template.cloudOSId } as ListOption;
            const providerCreds = providers.find(p => p.id == provider.value) ? providers.find(p => p.id == provider.value)!.credentials : [];
            const SubscriptionId = data.SubscriptionId as string ?? data.AccountId as string;
            const SubscriptionAlias = providerCreds.length > 0 && providerCreds.find(k => k.keysSecret.includes(SubscriptionId)) ?
                providerCreds.find(k => k.keysSecret.includes(SubscriptionId))!.alias : SubscriptionId;
            const subscriptionOpt = { label: SubscriptionAlias, value: SubscriptionId } as ListOption;
            const group = { label: automationsService.groupsDic[template.groupType], value: template.groupType } as ListOption;
            const type = { label: automationsService.automationTemplatesDic[template.name][1], value: template.id } as ListOption;
            const resource = { label: data.ResourceName ?? data.ResourceId, value: data.ResourceId ?? data.ResourceName } as ListOption;
            const instanceType = { label: usingResize ? data.Size : '', value: usingResize ? data.Size : '' } as ListOption;
            const timeString = moment(automation.hourScheduled, 'HH:mm:ss').format('HH:mm');
            const [hour = '00', minute = '00'] = timeString.split(":");
            const time: ScheduledTime = { hour: hour ?? '00', minute: minute ?? '00' };

            currentFormData.provider = provider;
            currentFormData.group = group;
            currentFormData.resource = resource;
            currentFormData.type = type;
            currentFormData.instanceType = instanceType;
            currentFormData.weekDays = weekDays;
            currentFormData.time = time;

            currentFormData.subscription = subscriptionOpt;
            currentFormValidations.forEach(fv => fv.isValid = ['resource', 'instanceType', 'weekDays', 'time'].includes(fv.field) || fv.isValid);
            setUsingResize(usingResize);
            setUsingECSResize(usingECSResize);
            setShowFormFilterStep(false);
            setShowFormTurnBackOnSection(false);
            setShowFormResizeReviewStep(false);
            setCreateTurnBackOnAutomation(false);
            setShowFormScheduleStep(true);
            setFormValidations(currentFormValidations);
            const providerArg = currentFormData.provider.value as number;
            const groupArg = currentFormData.group.value as string;
            const subscriptionArg = currentFormData.subscription.value as string;
            const sameSubscription = subscriptionArg === lastSubscriptionRequested;
            const sameType = groupArg === lastTypeRequested;
            const needLoading = !sameSubscription || !sameType;

            needLoading && (await loadResourcesAndInstanceTypes(providerArg, groupArg, subscriptionArg, currentFormData));
            setFormFieldAccessControl({
                ...formFieldAccessControl,
                provider: false,
                subscription: false,
                group: false,
                type: false,
                resource: false,
                instanceType: usingResize,
                weekDays: true,
                time: true,
                turnBackOnTime: false,
                turnBackOnWeekDays: false
            });
            setFormData(currentFormData);
        }
    };

    const endForm = () => {
        setFormData({ ...automationsService.emptyAutomationFormData });
        setFormFieldAccessControl({ ...automationsService.defaultAutomationFormFieldAccess });
        setResourceDetails({ ...automationsService.emptyAutomationResourceDetails });
        setAutomation(undefined);
        setUsingTurnOff(false);
        setShowRelatedAutomationsPanel(false);
        setLastSubscriptionRequested(undefined);
        setCreateTurnBackOnAutomation(false);
        props.onClose();
    };

    const handleFormOpening = () => { show ? (automation && automation.id != 0 ? startEditingForm() : startCreationForm()) : endForm() };

    const handleProviderChange = () => {

        console.log('handleProviderChange');
        if (!automation || automation.id === 0) {
            if (formData.provider.value as number > 0) {
                const currentFormData = formData;

                const subscription = providers.find(p => p.id === formData.provider.value as number);
                let subscriptionOptions: ListOption[] = [];

                if (subscription && subscription.credentials.length > 0) {
                    subscriptionOptions = subscription.credentials.map(c => {
                        const keys = JSON.parse(c.keysSecret);
                        if (keys) {
                            if (keys.hasOwnProperty('SubscriptionId')) {
                                return { label: c.alias, value: keys['SubscriptionId'] } as ListOption;
                            }
                            if (keys.hasOwnProperty('AccountId')) {
                                return { label: c.alias, value: keys['AccountId'] } as ListOption;
                            }
                        }
                        return automationsService.emptyListOption;
                    });

                    if (subscriptionOptions.length > 0) {
                        currentFormData.subscriptions = subscriptionOptions;
                        currentFormData.subscription = subscriptionOptions.length == 1 ?
                            subscriptionOptions[0] : automationsService.emptyListOption;
                    }
                } else {
                    console.error(`[ERRO AUTOMAÇÕES] provedores: Nenhuma assinatura encontrada!`);
                }

                currentFormData.groups = Array.from(new Set(
                    templates
                        .filter(t => formData.provider.value === t.cloudOSId)
                        .map(t => t.groupType)
                ))
                    .filter(t => automationsService.groupsDic.hasOwnProperty(t))
                    .map(t => ({ label: automationsService.groupsDic[t], value: t } as ListOption));
                currentFormData.group = automationsService.emptyListOption;

                const filteredTemplates = templates.filter(t => t.cloudOSId === formData.provider.value as number);
                let typeOptions: ListOption[] = [];

                if (filteredTemplates && filteredTemplates.length > 0) {
                    typeOptions = filteredTemplates.map(t => ({ label: automationsService.automationTemplatesDic[t.name][1], value: t.id } as ListOption));
                    if (typeOptions.length > 0) {
                        currentFormData.types = typeOptions;
                        currentFormData.type = automationsService.emptyListOption;
                    }
                } else {
                    console.error(`[ERRO AUTOMAÇÕES] provedores: Nenhum modelo de automação encontrada!`);
                }
                var formFieldAccessControlToUpdate = {
                    ...formFieldAccessControl
                };

                formFieldAccessControlToUpdate.subscription = true;
                formFieldAccessControlToUpdate.group = true;

                setFormFieldAccessControl(formFieldAccessControlToUpdate);
                setFormData(currentFormData);
                setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    group: true
                });
                setFormData({
                    ...formData,
                    group: automationsService.emptyListOption,
                    type: automationsService.emptyListOption
                });

                setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    group: true
                });
                forceUpdate();
            }
        }
    };

    const handleSubscriptionChange = () => {
        const currentFormData = { ...formData };
        const providerArg = currentFormData.provider.value as number;
        const groupArg = currentFormData.group.value as string;
        const subscriptionArg = currentFormData.subscription.value as string;
        const sameSubscription = subscriptionArg === lastSubscriptionRequested;
        const sameType = groupArg === lastTypeRequested;
        const needLoading = !sameSubscription || !sameType;

        needLoading && loadResourcesAndInstanceTypes(providerArg, groupArg, subscriptionArg, currentFormData);
        // const isFormFilterStepTurn = showFormFilterStep && !showFormScheduleStep && !showFormResizeReviewStep;
        //const isFormScheduleStepTurn = !showFormFilterStep && showFormScheduleStep && !showFormResizeReviewStep;

        //if (isFormFilterStepTurn) {
        //    const currentFormData = { ...formData };
        //    const providerArg = currentFormData.provider.value as number;
        //    const groupArg = currentFormData.group.value as string;
        //    const subscriptionArg = currentFormData.subscription.value as string;
        //    const sameSubscription = subscriptionArg === lastSubscriptionRequested;
        //    const sameType = groupArg === lastTypeRequested;
        //    const needLoading = !sameSubscription || !sameType;

        //    needLoading && loadResourcesAndInstanceTypes(providerArg, groupArg, subscriptionArg, currentFormData);
        //    setShowFormFilterStep(false);
        //    setShowFormScheduleStep(true);
        //    setShowFormResizeReviewStep(false);
        //}
    };

    const handleGroupChange = () => {
        if (!automation || automation.id === 0) {
            if (formData.group.value !== '' && formData.group.value !== 0) {
                setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    type: true
                });
                let filteredTemplates = templates;

                if (formData.provider.value as number > 0 && providers.map(p => p.id).includes(formData.provider.value as number)) {
                    filteredTemplates = filteredTemplates.filter(t => formData.provider.value as number === t.cloudOSId);
                }
                if (formData.group.value as string !== '' && templates.find(t => t.groupType === formData.group.value as string)) {
                    filteredTemplates = filteredTemplates.filter(t => t.groupType === formData.group.value as string)!;
                }

                setFormData({
                    ...formData,
                    types: filteredTemplates.map(ft => ({ label: automationsService.automationTemplatesDic[ft.name][1], value: ft.id } as ListOption)),
                    type: automationsService.emptyListOption
                });
            }
        }
    };

    const handleTypeChange = () => {
        if (!automation || automation.id === 0) {
            if (formData.type.value as number > 0 && templates.find(t => t.id === formData.type.value as number)) {
                const targetTemplate = templates.find(t => t.id === formData.type.value as number)!;
                setUsingResize(targetTemplate.serviceType === 'RESIZE' && targetTemplate.groupType !== 'Container');
                setUsingECSResize(targetTemplate.serviceType === 'RESIZE' && targetTemplate.groupType === 'Container');
                setUsingTurnOff(targetTemplate.serviceType === 'STOP');
            }
        }
    };

    const handleResourceChange = () => {
        if (!automation || automation.id === 0) {
            const currentResource = resourceDetails.resources.find(res => res.resourceID === formData.resource.value) ?? automationsService.emptyAutomationResource;
            if (formData.resource.value !== '' && formData.resource.value !== 0) {
                const related = props.relatedFilter(currentResource);
                setRelatedAutomations(related);
                const optimizedResource = resourcesReservesAndPlans.find(r => r.resourceName == formData.resource.label);
                if (optimizedResource) {
                    setOptimizedResource(optimizedResource);
                    setShowOptimizedResourceWarning(true);
                }
                if (usingECSResize) {
                    const clusterServices = currentResource.clusterServices.map(cs => ({ label: cs, value: cs } as ListOption));
                    setFormData({
                        ...formData,
                        clusterServices: clusterServices
                    });
                }
                setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    instanceType: usingResize,
                    clusterService: usingECSResize,
                    clusterCount: usingECSResize,
                    weekDays: !usingResize,
                    time: !usingResize
                });

            }
        }
    };

    const handleInstanceTypeChange = () => {
        if (usingResize) {
            refetchCostData();
            setFormFieldAccessControl({
                ...formFieldAccessControl,
                weekDays: true,
                time: true
            });
        }
    };

    const validateForm = () => {
        if (automation === undefined) {
            formData !== automationsService.emptyAutomationFormData && setFormValidations([
                {
                    field: 'provider',
                    isValid: formData.provider !== automationsService.emptyListOption
                        && providers.flatMap(p => p.id).includes(formData.provider.value as number),
                    message: 'Provedor inválido'
                },
                {
                    field: 'group',
                    isValid: formData.group !== automationsService.emptyListOption
                        && formData.group.value !== '',
                    message: 'Escolha um grupo'
                },
                {
                    field: 'type',
                    isValid: formData.type !== automationsService.emptyListOption
                        && formData.type.value as number > 0,
                    message: 'Escolha uma automação'
                },
                {
                    field: 'subscription',
                    isValid: formData.subscription !== automationsService.emptyListOption
                        && formData.subscription.value !== '',
                    message: 'Escolha uma assinatura'
                },
                {
                    field: 'resource',
                    isValid: formData.resource !== automationsService.emptyListOption
                        && formData.type.value as string !== '',
                    message: 'Escolha um recurso'
                },
                {
                    field: 'instanceType',
                    isValid: !usingResize
                        || (usingResize && formData.instanceType !== automationsService.emptyListOption
                            && formData.instanceType.value as string !== ''),
                    message: 'Escolha um tamanho para redimensionar'
                },
                {
                    field: 'weekDays',
                    isValid: Object.keys(formData.weekDays).some(w => formData.weekDays[w]),
                    message: 'Escolha pelo menos um dia da semana'
                },
                {
                    field: 'turnBackOnWeekDays',
                    isValid: !createTurnBackOnAutomation
                        || (createTurnBackOnAutomation && Object.keys(formData.turnBackOnWeekDays).some(w => formData.turnBackOnWeekDays[w])),
                    message: 'Escolha pelo menos um dia da semana do religamento'
                },
                {
                    field: 'time',
                    isValid: formData.time !== automationsService.defaultScheduledTime
                        && formData.time.hour !== '' && formData.time.minute !== '',
                    message: 'Escolha uma hora para o agendamento'
                },
                {
                    field: 'turnBackOnTime',
                    isValid: !createTurnBackOnAutomation
                        || (createTurnBackOnAutomation && formData.turnBackOnTime !== automationsService.defaultScheduledTime
                            && formData.turnBackOnTime.hour !== '' && formData.turnBackOnTime.minute !== ''),
                    message: 'Escolha uma hora para o agendamento do religamento'
                },
                {
                    field: 'clusterService',
                    isValid: !usingECSResize
                        || (usingECSResize && formData.clusterService !== automationsService.emptyListOption
                            && formData.clusterService.value as string !== ''),
                    message: 'Escolha um serviço do cluster'
                },
                {
                    field: 'clusterCount',
                    isValid: !usingECSResize || (usingECSResize && formData.clusterCount > 0),
                    message: 'Escolha uma quantidade de serviços'
                }
            ]);
        }
    }

    const handleShowFieldValidationError = (field: string) => {
        const validation = formValidations.find(fv => fv.field === field);
        if (validation) {
            return {
                show: !validation.isValid,
                help: validation.message
            };
        }
    }

    const handleDropDownSearch = async (input: string, field: string) =>
        field !== 'instanceTypes' ?
            formData[field].filter((f: ListOption) => f.label.toLowerCase().indexOf(input.toLowerCase()) > -1 ? f : null) :
            formData.instanceTypes.flatMap(it => it.value).filter((f: ListOption) => f.label.toLowerCase().indexOf(input.toLowerCase()) > -1 ? f : null);

    const translateAutomationWeekDays = (days: string[]): object => {
        const automationDays = Object.keys(automationsService.automationsWeekDic);
        const translated = {};

        automationDays.forEach(day => translated[automationsService.automationsWeekDic[day][0]] = days.includes(day) ? '1' : '0');

        return translated;
    };

    const handleProvider = () => {
        if (!automation || automation.id === 0) {
            if (formData.provider.value as number > 0) {
                const currentFormData = { ...formData };
                const subscription = providers.find(p => p.id === formData.provider.value as number);
                let subscriptionOptions: ListOption[] = [];

                if (subscription && subscription.credentials.length > 0) {
                    subscriptionOptions = subscription.credentials.map(c => {
                        const keys = JSON.parse(c.keysSecret);
                        if (keys) {
                            if (keys.hasOwnProperty('SubscriptionId')) {
                                return { label: c.alias, value: keys['SubscriptionId'] } as ListOption;
                            }
                            if (keys.hasOwnProperty('AccountId')) {
                                return { label: c.alias, value: keys['AccountId'] } as ListOption;
                            }
                        }
                        return automationsService.emptyListOption;
                    });

                    if (subscriptionOptions.length > 0) {
                        currentFormData.subscriptions = subscriptionOptions;
                        currentFormData.subscription = automationsService.emptyListOption;
                    }
                } else {
                    console.error(`[ERRO AUTOMAÇÕES] provedores: Nenhuma assinatura encontrada!`);
                }

                currentFormData.groups = Array.from(new Set(
                    templates
                        .filter(t => formData.provider.value === t.cloudOSId)
                        .map(t => t.groupType)
                ))
                    .filter(t => automationsService.groupsDic.hasOwnProperty(t))
                    .map(t => ({ label: automationsService.groupsDic[t], value: t } as ListOption));
                currentFormData.group = automationsService.emptyListOption;

                const filteredTemplates = templates.filter(t => t.cloudOSId === formData.provider.value as number);
                let typeOptions: ListOption[] = [];

                if (filteredTemplates && filteredTemplates.length > 0) {
                    typeOptions = filteredTemplates.map(t => ({ label: automationsService.automationTemplatesDic[t.name][1], value: t.id } as ListOption));
                    if (typeOptions.length > 0) {
                        currentFormData.types = typeOptions;
                        currentFormData.type = automationsService.emptyListOption;
                    }
                } else {
                    console.error(`[ERRO AUTOMAÇÕES] provedores: Nenhum modelo de automação encontrada!`);
                }

                setFormData(currentFormData);
                setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    subscription: true
                });
            }
        }
    };

    const loadResourcesAndInstanceTypes = async (pArg: number, tArg: string, sArg: string, currentFormData: AutomationFormData) => {
        try {
            const resourceDetails = { ...automationsService.emptyAutomationResourceDetails };
            if (lastSubscriptionRequested !== sArg || lastTypeRequested !== tArg) {
                setIsLoadingForm(true);
                const ResourcesBySubscriptionTask = automationsService.GetResourcesBySubscription(pArg, tArg, sArg);
                const InstanceTypesBySubscriptionTask = automationsService.GetInstanceTypesBySubscription(pArg, tArg, sArg);
                const ContractsAndGTMTask = heyhoService.RetrieveContractsAndGTM();
                const SavingsReservesTask = savingsReservesService.retrieveList();

                var [
                    resourceResult,
                    instancesResult,
                    savingsResult
                ] = await Promise.all([
                    ResourcesBySubscriptionTask,
                    InstanceTypesBySubscriptionTask,
                    SavingsReservesTask,
                    ContractsAndGTMTask
                ]);

                resourceDetails.resources = resourceResult;
                resourceDetails.instanceTypes = instancesResult;

                const filteredPlans: SavingsPlansReservesResources[] = typeof savingsResult !== 'string' && savingsResult.length > 0 ?
                    savingsResult.filter(p => moment(p.expiredDate) >= moment())
                        .flatMap(p => p.resouces.map(pr => (
                            { ...pr, planName: p.plan, planType: p.type } as SavingsPlansReservesResources
                        ))
                            .filter(p => p.subscrition == sArg))
                    : [];

                const template = templates.find(t => t.id === currentFormData.type.value)!;
                const cat = automationsService.automationTemplatesDic[template.name][2];

                currentFormData.resources = resourceDetails.resources && resourceDetails.resources.length > 0 ? resourceDetails.resources
                    .filter((res: AutomationResource) => res.resourceType === currentFormData.group.value &&
                        (res.resourceType === 'DB' && cat != null ? res.resourceCategory === cat : true))
                    .map((res: AutomationResource) => ({ label: res.resourceName, value: res.resourceID } as ListOption)) : [] as ListOption[];

                currentFormData.resource = automation && automation.id > 0 && resourceDetails.resources && resourceDetails.resources.length > 0 && resourceDetails.resources.filter(r => r.resourceName === currentFormData.resource.label || r.resourceID === currentFormData.resource.label).length > 0 ?
                    {
                        label: resourceDetails.resources.find(r => r.resourceName === currentFormData.resource.label || r.resourceID === currentFormData.resource.label)!.resourceName,
                        value: resourceDetails.resources.find(r => r.resourceName === currentFormData.resource.label || r.resourceID === currentFormData.resource.label)!.resourceID
                    } as ListOption :
                    { ...automationsService.emptyListOption };

                const groupedInstanceTypes = resourceDetails.instanceTypes
                    .reduce((acc, instance) => {
                        const category = instance.category;
                        if (!acc[category]) {
                            acc[category] = [];
                        }
                        acc[category].push(instance);
                        return acc;
                    }, {});
                currentFormData.instanceTypes = Object.keys(groupedInstanceTypes)
                    .map(category => ({
                        category,
                        value: groupedInstanceTypes[category].map((ins: AutomationInstanceType) => ({ label: ins.name, value: ins.name } as ListOption))
                    } as InstanceTypeListOption))
                    .sort((a, b) => a.category.localeCompare(b.category));
                currentFormData.instanceType = automation && automation.id > 0 ? currentFormData.instanceType : automationsService.emptyListOption;

                (!automation || automation.id === 0) && setFormFieldAccessControl({
                    ...formFieldAccessControl,
                    resource: true,
                    instanceType: false,
                    clusterService: false,
                    clusterCount: false,
                    weekDays: false,
                    time: false,
                    turnBackOnWeekDays: false,
                    turnBackOnTime: false
                });

                setResourceDetails(resourceDetails);
                setResourcesReservesAndPlans(filteredPlans);
                setFormData(currentFormData);
                setLastSubscriptionRequested(sArg);
                setLastTypeRequested(tArg);
            }
        } catch (e) {

        }
        setIsLoadingForm(false)
    };

    const goToNextStep = () => {
        const isFormFilterStepTurn = showFormFilterStep && !showFormScheduleStep && !showFormResizeReviewStep;
        const isFormScheduleStepTurn = !showFormFilterStep && showFormScheduleStep && !showFormResizeReviewStep;

        if (isFormFilterStepTurn) {
            const currentFormData = { ...formData };
            const providerArg = currentFormData.provider.value as number;
            const groupArg = currentFormData.group.value as string;
            const subscriptionArg = currentFormData.subscription.value as string;
            const sameSubscription = subscriptionArg === lastSubscriptionRequested;
            const sameType = groupArg === lastTypeRequested;
            const needLoading = !sameSubscription || !sameType;

            needLoading && loadResourcesAndInstanceTypes(providerArg, groupArg, subscriptionArg, currentFormData);
            setShowFormFilterStep(false);
            setShowFormScheduleStep(true);
            setShowFormResizeReviewStep(false);
        }

        if (isFormScheduleStepTurn) {
            setShowFormFilterStep(false);
            setShowFormScheduleStep(false);
            setShowFormResizeReviewStep(usingResize);
        }

        setShowFormTurnBackOnSection(usingTurnOff);
    };

    const handleFormButtons = () => {
        const isFormFilterStepTurn = showFormFilterStep && !showFormScheduleStep && !showFormResizeReviewStep;
        const formFilterStepFields = ['provider', 'group', 'type'];

        const isFormScheduleStepTurn = !showFormFilterStep && showFormScheduleStep && !showFormResizeReviewStep;
        const formScheduleStepFields = ['subscription', 'resource', 'instanceType', 'weekDays', 'time', 'clusterService', 'clusterCount', 'turnBackOnWeekDays', 'turnBackOnTime'];

        const isFormResizeReviewStepTurn = !showFormFilterStep && !showFormScheduleStep && showFormResizeReviewStep;
        type FormBtnContext = 'grey' | 'success' | 'warning' | 'danger' | 'info' | undefined;

        const stepIsValid = (stepFields: string[]) => {
            return formValidations.filter(fv => stepFields.includes(fv.field))
                .reduce((allIsValid, field) => field.isValid && allIsValid, true);
        };

        const validationLastMessage = (stepFields: string[]) => {
            return formValidations.filter(fv => stepFields.includes(fv.field)).find(fv => !fv.isValid) ?
                formValidations.filter(fv => stepFields.includes(fv.field)).find(fv => !fv.isValid)!.message : undefined;
        };

        const formFunctions = {
            formFilterStep: [
                {
                    label: 'Cancelar',
                    onClick: () => setShow(false),
                    disabled: isLoadingForm,
                    context: 'grey' as FormBtnContext
                },
                {
                    label: 'Continuar',
                    onClick: () => goToNextStep(),
                    disabled: !stepIsValid(formFilterStepFields),
                    tooltip: validationLastMessage(formFilterStepFields),
                    context: 'info' as FormBtnContext
                }
            ],
            formScheduleStep: [
                {
                    label: automation && automation.id > 0 ? 'Cancelar' : 'Voltar',
                    onClick: () => {
                        if (automation && automation.id > 0) {
                            setShow(false);
                        } else {
                            setShowFormFilterStep(true);
                            setShowFormScheduleStep(false);
                            setShowFormResizeReviewStep(false);
                            setShowRelatedAutomationsPanel(false);
                            setShowFormTurnBackOnSection(false);
                            setCreateTurnBackOnAutomation(false);
                            setFormData({
                                ...formData,
                                resource: { ...automationsService.emptyListOption },
                                instanceType: { ...automationsService.emptyListOption },
                                weekDays: { ...automationsService.defaultScheduledWeekDays },
                                time: { ...automationsService.defaultScheduledTime },
                                clusterService: { ...automationsService.emptyListOption },
                                clusterCount: 0,
                                turnBackOnWeekDays: { ...automationsService.defaultScheduledWeekDays },
                                turnBackOnTime: { ...automationsService.defaultScheduledTime }
                            });
                        }
                    },
                    disabled: isLoadingForm,
                    context: 'grey' as FormBtnContext
                },
                {
                    label: (canRequestSchedule && !user.IsAdm ? 'Solicitar automação' : `${automation && automation.id > 0 ? 'Atualizar' : 'Criar'} automação`),
                    // onClick: () => usingResize ? goToNextStep() : canRequestSchedule && !user.IsAdm ? setShowHeyhoTicketModal(true) : saveAutomation(),
                    onClick: () => canRequestSchedule && !user.IsAdm ? setShowHeyhoTicketModal(true) : saveAutomation(),
                    disabled: (isLoadingForm || !stepIsValid(formScheduleStepFields))
                        || (automation && automation.id > 0 && formData.resource.value === -1),// desabilita se for Edição e o recurso não existir
                    tooltip: validationLastMessage(formScheduleStepFields),
                }
            ],
            formResizeReviewStep: [
                {
                    label: 'Voltar',
                    onClick: () => {
                        setShowFormFilterStep(false);
                        setShowFormScheduleStep(true);
                        setShowFormResizeReviewStep(false);
                    },
                    disabled: false,
                    context: 'grey' as FormBtnContext
                },
                {
                    label: canRequestSchedule ? 'Solicitar automação' : 'Criar automação',
                    onClick: () => saveAutomation(),
                    disabled: false
                }
            ],
            undefinedSteps: [
                {
                    label: 'Cancelar',
                    onClick: props.onClose,
                    disabled: false,
                    context: 'grey' as FormBtnContext
                }
            ],
        };

        if (isFormFilterStepTurn) {
            return formFunctions.formFilterStep;
        }

        if (isFormScheduleStepTurn) {
            return formFunctions.formScheduleStep;
        }

        if (isFormResizeReviewStepTurn) {
            return formFunctions.formResizeReviewStep;
        }

        return formFunctions.undefinedSteps;
    };

    const renderStartIcon = (time: string) => {
        const timeMoment = moment(time, 'HH:mm:ss');

        return <span style={{ marginRight: '3px' }}>
            {timeMoment.hour() >= 19 ? <PiMoon size={'16px'} color='#989BB1' /> : <PiSun size={'16px'} color='#ffa400' />}
        </span>;
    };

    const renderRelatedInfo = (data: AutomationsScheduler) => {
        const templateName = templates.find(tpl => tpl.id === data.automationId)?.name!;
        const automationDicLine = automationsService.automationTemplatesDic[templateName];
        const automationName = automationDicLine[1] ?? `Automação ${data.automationId}`;
        const automationIcon = {
            'Azure': <SiMicrosoftazure size={'14px'} color='#2E1956' />,
            'AWS': <FaAws size={'16px'} color='#2E1956' />
        }[automationDicLine[0]];
        const translatedWeekDay = translateAutomationWeekDays(data.weekDaysScheduled.split('-'));
        return (
            <CardContent style={{ padding: '10px' }}>
                <div className='automationIdColumn'>
                    <span className='automationIdIcon'>{automationIcon}</span>
                    <span className='overflowEllipsis ml-4' style={{ fontSize: '12px' }}>{automationName}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%', marginTop: '10px', marginLeft: '5px' }}>
                    <div style={{ fontSize: '12px' }}>
                        {
                            data.status >= 1 ?
                                <div className='statusEnabled'>Habilitada</div>
                                :
                                <div className='statusDisabled'>Desabilitada</div>
                        }
                    </div>
                    <div className='hourScheduledColumn'>
                        {renderStartIcon(data.hourScheduled)}
                        <div style={{ fontSize: '12px' }}>{moment(data.hourScheduled, 'HH:mm:ss').format('HH:mm')}</div>
                    </div>
                </div>
                <div className='weekDaysScheduledColumn' style={{ marginLeft: '20px', marginTop: '8px' }}>
                    {Object.keys(translatedWeekDay).map((dia, i) => {
                        return (
                            <div key={i}>
                                <Badge className={'customBadgeWeek'} badgeContent={dia} color={translatedWeekDay[dia] === '1' ? 'primary' : 'default'} />
                                <br></br>
                            </div>
                        );
                    })}
                </div>

            </CardContent>
        );
    };

    const handleRelatedPanel = () => {
        if (relatedAutomations.length === 0 || formData.resource.value === '') {
            setShowRelatedAutomationsPanel(false);
            setWarnNoRelatedAutomations(true);
            setTimeout(() => setWarnNoRelatedAutomations(false), 2000);
            return;
        }

        setWarnNoRelatedAutomations(false);
        setShowRelatedAutomationsPanel(!showRelatedAutomationsPanel);
    };

    const handleTurnBackOnSchedule = () => {
        setCreateTurnBackOnAutomation(!createTurnBackOnAutomation);
        setFormFieldAccessControl({
            ...formFieldAccessControl,
            turnBackOnWeekDays: !createTurnBackOnAutomation,
            turnBackOnTime: !createTurnBackOnAutomation
        });
        if (createTurnBackOnAutomation) {
            setFormData({
                ...formData,
                turnBackOnWeekDays: { ...automationsService.defaultScheduledWeekDays },
                turnBackOnTime: { ...automationsService.defaultScheduledTime }
            });
        }
    };

    const handleAcceptOptimizedWarning = () => {
        handleRejectOptimizedWarning();
        setShow(false);
    }

    const handleRejectOptimizedWarning = () => {
        setShowOptimizedResourceWarning(false);
        setOptimizedResource(undefined);
    }

    const toggleTicketModal = () => {
        setHeyhoTicketObs('');
        setShowHeyhoTicketModal(!showHeyhoTicketModal);
    };

    const toPascalCase = (str: string) => {
        return `${str}`
            .toLowerCase()
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w*)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3}`
            )
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    };

    const showInfo = (msg: string, type: 'default' | 'error' | 'success' | 'warning' | 'info') => {
        enqueueSnackbar(msg, { variant: type, preventDuplicate: true, persist: false });
    };

    const processAutomationToSend = (): { _automation: AutomationsScheduler, _ticket: AutomationHeyHoTicket } | string => {
        try {
            const _formData = { ...formData };
            const template = templates.find(t => t.id === formData.type.value)!;
            const turnBackOnTemplate = templates.find(t => t.cloudOSId === _formData.provider.value && t.groupType === _formData.group.value && t.serviceType === 'START');
            const current = automation ? automation : { ...emptyAutomationScheduler };
            const resourceInfo = resourceDetails.resources.find(r => r.resourceID === _formData.resource.value)!;
            const providerSet = providers.find(p => p.id === formData.provider.value)!;
            const providerKeys = providerSet.credentials.find(c => c.keysSecret.indexOf(_formData.subscription.value as string) > -1)!;
            const provider = JSON.parse(providerKeys.keysSecret);

            if (!template) {
                return 'Automação base inválida!';
            }

            const dataToParse = current.data !== '' ? current.data : template.dataSample;
            const schedulerData = { ...JSON.parse(dataToParse) };
            const turnBackOnSchedulerData = createTurnBackOnAutomation && turnBackOnTemplate ? { ...JSON.parse(turnBackOnTemplate.dataSample) } : {};

            current.automationId = template.id;
            current.data = schedulerData;
            current.hourScheduled = `${_formData.time.hour}:${_formData.time.minute}:00`;
            current.weekDaysScheduled = Object.keys(_formData.weekDays).filter(w => _formData.weekDays[w] === true).join('-');
            current.status = 1;

            const fullData = { ...automationsService.emptyAutomationsSchedulerData };
            const extractedTier = _formData.instanceTypes.find(it => it.value.includes(_formData.instanceType)) !== undefined ?
                _formData.instanceTypes.find(it => it.value.includes(_formData.instanceType))!.category : '';

            fullData.ClientName = schedulerData.ClientName === '' ?
                toPascalCase(user.ClientGroupSelectedDescription!) :
                schedulerData.ClientName;
            fullData.CloudOS = schedulerData.CloudOS;
            fullData.Automation = schedulerData.Automation;
            fullData.Region = resourceInfo.resourceRegion;
            fullData.TenantId = provider.TenantId ?? '';
            fullData.ApplicationId = provider.ApplicationId ?? '';
            fullData.SubscriptionId = provider.SubscriptionId ?? '';
            fullData.AccountId = resourceInfo.resourceSubscription;
            fullData.ResourceGroupName = resourceInfo.resourceGroup;
            fullData.ResourceName = resourceInfo.resourceName;
            fullData.ResourceId = resourceInfo.resourceType === 'DB' && template.cloudOSId === 2 ?
                resourceInfo.resourceName : resourceInfo.resourceID;
            fullData.KeyVaultCredential = provider.Key ?? provider.SecretKey ?? '';
            fullData.KeyVaultAccessKey = provider.AccessKey ?? '';
            fullData.Size = _formData.instanceType.value as string ?? '';
            fullData.AppServicePlanName = _formData.resource.label ?? '';
            fullData.WorkerSize = _formData.instanceType.value as string ?? '';
            fullData.Tier = extractedTier;
            fullData.ServerName = resourceInfo.resourceName;
            fullData.ClusterName = resourceInfo.resourceName;
            fullData.ServiceName = _formData.clusterService.value as string ?? '';
            fullData.DesiredCount = _formData.clusterCount.toString() ?? '';

            Object.keys(fullData).forEach(field => {
                if (current.data.hasOwnProperty(field)) {
                    current.data[field] = fullData[field];
                }
            });

            const service = '-';
            const svcTree: AutomationHeyHoServiceGroup[] = heyhoService.state.data.chamadoTree;
            if (canRequestSchedule && svcTree.length === 0) return 'Falha ao ler grupo de serviços HeyHo!';
            let svcGroup = svcTree.find((ct: AutomationHeyHoServiceGroup) => ct.value.indexOf(service) > -1);
            if (svcGroup === undefined) {
                svcGroup = svcTree[0];
            }

            const ticketContract = provider.DefaultContract;
            const ticketSvcTree = svcGroup.value;
            const ticketDays = Object.keys(_formData.weekDays).filter(d => _formData.weekDays[d]).map(d => automationsService.automationsWeekDic[d][1].toString());
            const ticketHours = `${_formData.time.hour}:${_formData.time.minute}`;
            const ticketResource = `${resourceInfo.resourceName} (${fullData.ResourceId!})`;
            const ticketDesc = `Automação: ${fullData.Automation}\nRecurso: ${ticketResource}\nDias: ${ticketDays}\nHorário: ${ticketHours}\nObservações: ${heyhoTicketObs}`;
            const ticketRequest: AutomationHeyHoTicket = {
                id: 0,
                contrato: ticketContract,
                titulo: 'Agendamento de Automação',
                ServiceTree: ticketSvcTree,
                dataInicio: '',
                heyHoId: 0,
                heyHoSatatus: 'Nova',
                heyHoLink: '',
                criticidade: 'Normal',
                Descricao: ticketDesc
            };

            current.data = JSON.stringify(current.data);

            return {
                _automation: current,
                _ticket: ticketRequest
            };
        } catch (e) {
            return {
                _automation: {} as AutomationsScheduler, _ticket: {} as AutomationHeyHoTicket
            };
        }
    };

    const sendTicket = async (ticket: AutomationHeyHoTicket) => {
        setIsSendingTicket(true);
        try {
            showInfo('Enviando solicitação, aguarde...', 'info');
            const result = await heyhoService.Save(ticket);

            if (result.status === 200) {
                showInfo(`Solicitação ${result.data.id} criado com sucesso.`, 'success');
            }
        } catch (e) {
            console.error(e);
            showInfo('Erro ao tentar criar solicitação!', 'error');
        }

        setIsSendingTicket(false);
        toggleTicketModal();
    };

    const saveAutomation = async () => {
        const template = templates.find(t => t.id === formData.type.value)!;
        const current = automation ? automation : { ...emptyAutomationScheduler };

        const msgSet = {
            create: {
                success: 'Automação criada com sucesso!',
                info: 'Criando automação, aguarde...',
                error: 'Erro ao criar automação: ',
                warning: 'Falha ao tentar criar automação!',
            },
            update: {
                success: 'Automação atualizada com sucesso!',
                info: 'Atualizando automação, aguarde...',
                error: 'Erro ao atualizar automação: ',
                warning: 'Falha ao tentar atualizar automação!',
            },
        };

        const sendAction = {
            create: async (a) => await automationsService.CreateAutomation(a),
            update: async (a) => await automationsService.UpdateAutomation(a)
        };

        const requestedScheduling = canRequestSchedule && !canCreateAvailability && !canCreateResizing;
        const directAvailabilityScheduling = canCreateAvailability && ['START', 'STOP'].includes(template.serviceType);
        const directResizingScheduling = canCreateResizing && template.serviceType === 'RESIZE';

        const action = current.id! > 0 ? 'update' : 'create';

        try {
            const processed = processAutomationToSend();
            if (typeof processed === 'string') {
                showInfo(`${msgSet[action]['error']}: ${processed}`, 'error');
            }

            if (typeof processed === 'object') {
                const { _automation, _ticket } = processed;

                const toSend = {
                    automationId: _automation.automationId,
                    data: _automation.data,
                    hourScheduled: _automation.hourScheduled,
                    status: _automation.status,
                    weekDaysScheduled: _automation.weekDaysScheduled
                };

                if (current.id > 0) {
                    toSend['id'] = current.id;
                }

                if (requestedScheduling) {
                    await sendTicket(_ticket);
                } else if (directAvailabilityScheduling || directResizingScheduling) {
                    showInfo(msgSet[action]['info'], 'info');
                    const result = await sendAction[action](toSend);

                    if (result.status === 200) {
                        showInfo(msgSet[action]['success'], 'success');
                    }

                    props.reloadData();
                } else {
                    showInfo('Sem permissão para criar/solicitar agendamentos!', 'warning');
                }
            }
        } catch (ex) {
            console.error(ex);
            showInfo(msgSet[action]['warning'], 'warning');
        } finally {
            props.onClose();
            setShow(false);
        }
    };

    const ticketFunctions = [
        {
            label: "Solicitar",
            onClick: saveAutomation,
            disabled: false,
            hidden: false
        },
        {
            label: "Voltar",
            onClick: toggleTicketModal,
            disabled: false,
            hidden: false
        },
    ];

    useEffect(handleProviderChange, [formData.provider]);
    useEffect(handleSubscriptionChange, [formData.subscription]);
    useEffect(handleGroupChange, [formData.provider, formData.group]);
    useEffect(handleTypeChange, [formData.type]);
    useEffect(handleResourceChange, [formData.resource]);
    useEffect(handleInstanceTypeChange, [formData.instanceType]);
    useEffect(validateForm, [formData, createTurnBackOnAutomation]);
    useEffect(handleFormOpening, [show]);
    const [key, setKey] = useState(0);
    const showCostCompare = usingResize && formData && formData.resource && formData.resource.value != -1 && formData.instanceType.value != -1;

    const forceUpdate = () => {
        setKey(key + 1);
    };
    return (
        <PPModal
            title={`${automation ? 'Editar' : 'Criar'} automação`}
            visible={show}
            onClose={props.onClose}
            functions={handleFormButtons()}
            size={showFormFilterStep ? 'medium' : 'large'}
        >
            <PPModal title={'Solicitar agendamento de automação'} visible={showHeyhoTicketModal} onClose={toggleTicketModal} functions={ticketFunctions} >
                <div className="ticketSummary">
                    <p>Ao clicar em 'Solicitar', será aberto um chamado para nossa equipe de suporte criar uma nova rotina de automação em seu ambiente cloud.<br />
                        Se desejar revisar os dados informados, selecione 'Voltar'.</p>
                    <p><strong>Observações adicionais (opcional):</strong></p>
                    <p><PPTextArea name="ticketObs" value={heyhoTicketObs} onChange={(e) => setHeyhoTicketObs(e.target.value)} /></p>
                </div>
            </PPModal>
            <div className="formStepsContainer" style={{ justifyContent: costData ? 'flex-start' : 'center', minHeight: !costData ? '50vh' : '65vh' }}>
                <div className='formFilterStep' style={{ display: showFormFilterStep ? 'block' : 'none', overflow: 'hidden' }}>
                    <PPGrid container spacing={2}>
                        <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography paragraph className='modal-text'>
                                <p>Este assistente te orientará a criar uma automação para seus recursos de nuvem.</p>
                                <p>Preencha os campos abaixo e clique em 'Continuar' para configurar a automação escolhida.</p>
                            </Typography>
                        </PPGrid>
                    </PPGrid>
                    <PPGrid container spacing={1}>

                        <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PPDropDown
                                name='provider'
                                title='Provedor'
                                required={true}
                                value={formData.provider}
                                options={formData.providers}
                                onChange={(opt) => setFormData(({ ...formData, provider: opt as ListOption }))}
                                openTrigger='click'
                                maxMenuHeight={100}
                                width='100%'
                                disabled={!formFieldAccessControl.provider}
                                handleMenuOpen={(input) => handleDropDownSearch(input, 'providers')}
                                error={handleShowFieldValidationError('provider')}
                            />
                        </PPGrid>
                        <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}></PPGrid>
                        <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PPDropDown
                                name='group'
                                title='Grupo'
                                required={true}
                                value={formData.group}
                                options={formData.groups}
                                onChange={(opt) => setFormData(({ ...formData, group: opt as ListOption }))}
                                openTrigger='click'
                                disabled={!((formData.groups && formData.groups.length > 0) && formData.provider && formData.provider.value && true)}
                                menuPlacement='top'
                                maxMenuHeight={100}
                                handleMenuOpen={(input) => handleDropDownSearch(input, 'groups')}
                                error={handleShowFieldValidationError('group')}
                            />
                        </PPGrid>
                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}></PPGrid>
                        <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PPDropDown
                                name='type'
                                title='Automação'
                                required={true}
                                value={formData.type}
                                options={formData.types}
                                onChange={(opt) => setFormData(({ ...formData, type: opt as ListOption }))}
                                openTrigger='click'
                                disabled={!formFieldAccessControl.type}
                                menuPlacement='top'
                                maxMenuHeight={100}
                                handleMenuOpen={(input) => handleDropDownSearch(input, 'types')}
                                error={handleShowFieldValidationError('type')}
                            />
                        </PPGrid>
                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}></PPGrid>
                    </PPGrid>
                </div>
                <div className='formScheduleStep' style={{ display: showFormScheduleStep ? 'flex' : 'none', alignItems: 'center' }}>
                    <LcLoading loading={isLoadingForm} label={`Carregando recursos${usingResize ? ' e tamanhos...' : '...'}`}>
                        <Confirmation
                            title={`"${optimizedResource?.resourceName}" já foi otimizado!`}
                            text={<p>Este recurso faz parte de <strong>{optimizedResource?.planType}</strong> "<em>{optimizedResource?.planName}</em>"
                                e portanto já é coberto por uma estratégia de economia. É possível que a automação gere pouco ou nenhum impacto na
                                economia já obtida! Deseja continuar?</p>}
                            display={optimizedResource && showOptimizedResourceWarning}
                            confirm={handleAcceptOptimizedWarning}
                            close={handleRejectOptimizedWarning}
                            textBtnOk='Não'
                            textBtnCancel='Sim'
                        />
                        <div style={{ display: 'flex', width: '100%', minHeight: 'inherit', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: showRelatedAutomationsPanel ? '75%' : '100%', marginRight: showRelatedAutomationsPanel ? '1rem' : '0' }}>
                                <PPGrid container spacing={2}>
                                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className='modal-text'>
                                            <p><strong>{formData.provider.label} - {formData.type.label}</strong></p>
                                            {(!automation || automation.id === 0) && <p>Escolha o recurso a ser automatizado.</p>}
                                        </Typography>
                                    </PPGrid>
                                </PPGrid>
                                <PPGrid container spacing={2}>
                                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '30%', padding: '5px' }}>
                                                {formFieldAccessControl && <PPDropDown
                                                    name='subscription'
                                                    title='Assinatura'
                                                    required={true}
                                                    value={formData.subscription}
                                                    options={formData.subscriptions}
                                                    onChange={(opt) => setFormData(({ ...formData, subscription: opt as ListOption }))}
                                                    openTrigger='click'
                                                    maxMenuHeight={100}
                                                    disabled={!(formData.subscriptions && formData.subscriptions.length > 0 && formData.provider && (Number.parseFloat(formData.provider.value.toString())) > 0)}
                                                    handleMenuOpen={(input) => handleDropDownSearch(input, 'subscriptions')}
                                                    error={handleShowFieldValidationError('subscription')}
                                                />}</div>
                                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50%', padding: '5px' }}>
                                                <PPDropDown
                                                    name='resource'
                                                    title={
                                                        <>
                                                            <div>Recurso</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <MdInfoOutline size={'16px'} style={{ verticalAlign: 'middle' }} />
                                                                <Tooltip arrow open={warnNoRelatedAutomations} title='Sem automações para esse recurso!'>
                                                                    <span className='related-resource-link' onClick={handleRelatedPanel}>Ver automações desse recurso</span>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                    }
                                                    required={true}
                                                    value={automation && formData.resource.value === -1 ? noResourceFound : formData.resource}
                                                    options={formData.resources}
                                                    onChange={(opt) => setFormData(({ ...formData, resource: opt as ListOption }))}
                                                    openTrigger='click'
                                                    maxMenuHeight={100}
                                                    disabled={!formFieldAccessControl.resource}
                                                    menuPlacement='bottom'
                                                    handleMenuOpen={(input) => handleDropDownSearch(input, 'resources')}
                                                    error={handleShowFieldValidationError('resource')}
                                                /></div>
                                            {
                                                usingResize &&
                                                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20%', padding: '5px' }}>
                                                    <PPDropDown
                                                        name='instanceType'
                                                        title='Tamanho'
                                                        required={usingResize}
                                                        value={formData.instanceType}
                                                        options={formData.instanceTypes.map((it, iit) => (
                                                            {
                                                                label: (
                                                                    <div className='optionsGroup' style={{ display: 'flex', justifyContent: 'space-between' }} key={iit}>
                                                                        <span>{it.category}</span>
                                                                        <Badge badgeContent={it.value.length.toString()} color='secondary' />
                                                                    </div>
                                                                ),
                                                                options: it.value
                                                            }
                                                        ))}
                                                        onChange={(opt) => setFormData(({ ...formData, instanceType: opt as ListOption }))}
                                                        openTrigger='click'
                                                        maxMenuHeight={100}
                                                        disabled={!formFieldAccessControl.instanceType}
                                                        handleMenuOpen={(input) => handleDropDownSearch(input, 'instanceTypes')}
                                                        error={handleShowFieldValidationError('instanceType')}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </PPGrid>
                                    {
                                        usingECSResize &&
                                        <>
                                            <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <PPDropDown
                                                    name='clusterService'
                                                    title='Serviço'
                                                    required={true}
                                                    value={formData.clusterService}
                                                    options={formData.clusterServices}
                                                    onChange={(opt) => setFormData(({ ...formData, clusterService: opt as ListOption }))}
                                                    openTrigger='click'
                                                    disabled={!formFieldAccessControl.clusterService}
                                                    menuPlacement='bottom'
                                                    maxMenuHeight={100}
                                                    handleMenuOpen={(input) => handleDropDownSearch(input, 'clusterServices')}
                                                    error={handleShowFieldValidationError('clusterService')}
                                                />
                                            </PPGrid>
                                            <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                <PPInput
                                                    name='clusterCount'
                                                    title='Quantidade'
                                                    value={formData.clusterCount && formData.clusterCount || ''}
                                                    onChange={(e) => setFormData({ ...formData, clusterCount: e.target.value })}
                                                    disabled={!formFieldAccessControl.clusterCount}
                                                    required={true}
                                                    type='text'
                                                    autoComplete='off'
                                                    pattern='[0-9]{2}'
                                                />
                                            </PPGrid>
                                        </>
                                    }
                                </PPGrid>

                                <PPGrid container spacing={2}>
                                    <PPGrid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                        <label className='form-label'>Dias da semana</label>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
                                            <LcCheckBox className='checkbox-dias' title='dom'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.SUN}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, SUN: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='seg'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.MON}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, MON: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='ter'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.TUE}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, TUE: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='qua'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.WED}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, WED: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='qui'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.THU}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, THU: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='sex'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.FRI}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, FRI: e.checked } })} />
                                            <LcCheckBox className='checkbox-dias' title='sab'
                                                disabled={!formFieldAccessControl.weekDays}
                                                checked={formData.weekDays.SAT}
                                                onChange={(e) => setFormData({ ...formData, weekDays: { ...formData.weekDays, SAT: e.checked } })} />
                                        </div>
                                    </PPGrid>
                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                        <label className='form-label'>Horário de início</label>
                                        <PPDropDown
                                            name='time-hour'
                                            required={true}
                                            options={Array.from(Array(24).keys()).map(h => {
                                                const hString = h.toString().length > 1 ? h : `0${h}`;
                                                return { label: hString, value: hString };
                                            })}
                                            value={{ label: formData.time.hour, value: formData.time.hour }}
                                            onChange={(opt) => {
                                                const curTime = { ...formData.time };
                                                curTime.hour = opt.value.toString();
                                                setFormData({ ...formData, time: curTime });
                                            }}
                                            openTrigger='click'
                                            menuPlacement='top'
                                            maxMenuHeight={100}
                                            disabled={!formFieldAccessControl.time}
                                            error={handleShowFieldValidationError('time')}
                                        />
                                    </PPGrid>
                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <label className='form-label'>{<>&nbsp;</>}</label>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>:</div>
                                    </PPGrid>
                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <label className='form-label'>{<>&nbsp;</>}</label>
                                        <PPDropDown
                                            name='time-minute'
                                            required={true}
                                            options={Array.from(Array(60).keys()).filter(h => h % 5 === 0).map(h => {
                                                const hString = h.toString().length > 1 ? h : `0${h}`;
                                                return { label: hString, value: hString };
                                            })}
                                            value={{ label: formData.time.minute, value: formData.time.minute }}
                                            onChange={(opt) => {
                                                const curTime = { ...formData.time };
                                                curTime.minute = opt.value.toString();
                                                setFormData({ ...formData, time: curTime });
                                            }}
                                            openTrigger='click'
                                            menuPlacement='top'
                                            maxMenuHeight={100}
                                            disabled={!formFieldAccessControl.time}
                                            error={handleShowFieldValidationError('time')}
                                        />
                                    </PPGrid>
                                    {
                                        showCostCompare &&
                                        <PPGrid spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12}>

                                            <LcLoading loading={isLoadingCostData || isFetchingCostData} label='Criando revisão do redimensionamento...'>
                                                {
                                                    showCostCompare && costData &&
                                                    <AutomationCostDetail resources={costData} daysOn={7} resourceDetails={resourceDetails} formData={formData} usingResize={usingResize} />
                                                }
                                            </LcLoading>
                                        </PPGrid>
                                    }
                                </PPGrid>
                                <PPGrid container spacing={1}>
                                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='formTurnBackOnSection' style={{ display: showFormTurnBackOnSection ? 'block' : 'none' }}>
                                            {
                                                usingTurnOff ?
                                                    <div style={{ display: 'flex', backgroundColor: '#ffe599', padding: '10px', borderRadius: '6px' }}>
                                                        <div style={{ margin: 'auto 12px auto 0' }}><PiWarning color='#ED9121' size={40} /></div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginRight: '12px' }}>
                                                            <Typography className='turnoff-text'>
                                                                <p>Você está automatizando <strong>Desligar</strong> um recurso.</p>
                                                                <p>Recomendamos que automatize também <strong>Religar</strong> o mesmo, evitando indisponibilidade futura.</p>
                                                                <p>Se quiser criar a automação de <strong>Religar</strong> em conjunto com esta, clique em "<em>Agendar religamento</em>".</p>
                                                            </Typography>
                                                            <div style={{ margin: 'auto 2px auto 0' }}>
                                                                <button
                                                                    className={`lc-button ${createTurnBackOnAutomation ? 'bg-gray' : 'bg-primary'}`}
                                                                    type='button'
                                                                    onClick={handleTurnBackOnSchedule}
                                                                    disabled={automation && automation.id > 0}
                                                                > {createTurnBackOnAutomation ? 'Cancelar religamento' : 'Agendar religamento'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div style={{ display: createTurnBackOnAutomation ? 'flex' : 'none', marginTop: '3px' }}>
                                                <PPGrid container spacing={6}>
                                                    <PPGrid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                        <label className='form-label'>Dias da semana do religamento</label>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
                                                            <LcCheckBox className='checkbox-dias' title='dom'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.SUN}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, SUN: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='seg'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.MON}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, MON: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='ter'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.TUE}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, TUE: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='qua'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.WED}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, WED: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='qui'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.THU}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, THU: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='sex'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.FRI}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, FRI: e.checked } })} />
                                                            <LcCheckBox className='checkbox-dias' title='sab'
                                                                disabled={!formFieldAccessControl.turnBackOnWeekDays}
                                                                checked={formData.turnBackOnWeekDays.SAT}
                                                                onChange={(e) => setFormData({ ...formData, turnBackOnWeekDays: { ...formData.turnBackOnWeekDays, SAT: e.checked } })} />
                                                        </div>
                                                    </PPGrid>
                                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                                        <label className='form-label'>Horário de início do religamento</label>
                                                        <PPDropDown
                                                            name='turnBackOnTime-hour'
                                                            required={true}
                                                            options={Array.from(Array(24).keys()).map(h => {
                                                                const hString = h.toString().length > 1 ? h : `0${h}`;
                                                                return { label: hString, value: hString };
                                                            })}
                                                            value={{ label: formData.turnBackOnTime.hour, value: formData.turnBackOnTime.hour }}
                                                            onChange={(opt) => {
                                                                const curTime = { ...formData.turnBackOnTime };
                                                                curTime.hour = opt.value.toString();
                                                                setFormData({ ...formData, turnBackOnTime: curTime });
                                                            }}
                                                            openTrigger='click'
                                                            menuPlacement='top'
                                                            maxMenuHeight={100}
                                                            disabled={!formFieldAccessControl.turnBackOnTime}
                                                            error={handleShowFieldValidationError('turnBackOnTime')}
                                                        />
                                                    </PPGrid>
                                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                        <label className='form-label'>{<>&nbsp;</>}</label>
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>:</div>
                                                    </PPGrid>
                                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <label className='form-label'>{<>&nbsp;</>}</label>
                                                        <PPDropDown
                                                            name='turnBackOnTime-minute'
                                                            required={true}
                                                            options={Array.from(Array(60).keys()).filter(h => h % 5 === 0).map(h => {
                                                                const hString = h.toString().length > 1 ? h : `0${h}`;
                                                                return { label: hString, value: hString };
                                                            })}
                                                            value={{ label: formData.turnBackOnTime.minute, value: formData.turnBackOnTime.minute }}
                                                            onChange={(opt) => {
                                                                const curTime = { ...formData.turnBackOnTime };
                                                                curTime.minute = opt.value.toString();
                                                                setFormData({ ...formData, turnBackOnTime: curTime });
                                                            }}
                                                            openTrigger='click'
                                                            menuPlacement='top'
                                                            maxMenuHeight={100}
                                                            disabled={!formFieldAccessControl.turnBackOnTime}
                                                            error={handleShowFieldValidationError('turnBackOnTime')}
                                                        />
                                                    </PPGrid>
                                                </PPGrid>

                                            </div>
                                        </div>
                                    </PPGrid>
                                </PPGrid>
                            </div>
                            <div className='related-container' style={{ display: showRelatedAutomationsPanel ? 'block' : 'none' }}>
                                <div className='related-title'>Outros agendamentos</div>
                                <div className='related-resource-label'>Recurso: <strong>{filterResourceInfo(formData.resource.value as string).resourceName}</strong></div>
                                {
                                    relatedAutomations.length > 0 && relatedAutomations.map((ra, rai) => (
                                        <Card key={rai} className='related-resource-card' style={{ borderColor: ra.status >= 1 ? '#3EBB3F' : '#DC291E' }}>
                                            {renderRelatedInfo(ra)}
                                        </Card>
                                    ))
                                }
                            </div>
                        </div>
                    </LcLoading>
                </div>
            </div>
        </PPModal>
    );
};

export default AutomationForm;

