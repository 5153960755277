import React from 'react';
import CSPResume from '../../../../../pages/CSP/resume';

interface SWCardProps {
    GTMs: string[];
    QtdGroups: number;
}

const SWCard: React.FC<SWCardProps> = ({ GTMs, QtdGroups }) => {
    //<div>
    //    <p>Soluções disponíveis:</p>
    //    <ul>
    //        {GTMs.map((solution, index) => (
    //            <li key={index}>{solution}</li>
    //        ))}
    //    </ul>
    //    <br />
    //    <p>Quantidade de grupos: {QtdGroups}</p>
    //</div>
    return (

        <CSPResume isHome={true} qtdGroups={QtdGroups}></CSPResume>
    );
}

export default SWCard;