import moment from "moment";
import { PP_DropDownOld, PPGrid, PPTextField, PPModal } from "processor-plataform-ui";
import React, { useEffect, useState } from "react";
import Confirmation from "../../../../components/Dialog/Confirmation";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import FinOpsMapManagerService, { FinOpsAllocationGroup, FinOpsAllocationRule, FinOpsAllocationRuleCondition, FinOpsAllocationRuleContract } from '../../../../services/finOpsManagerService';


import './index.css';
import { PiLockLight, PiLockOpenLight } from "react-icons/pi";

interface Props {
    rule: FinOpsAllocationRule,
    ruleGroup: FinOpsAllocationRule[],
    contracts: any[],
    readOnly: boolean,
    onSaveGroup?: Function,
    onCancelAction?: Function,
    originalRuleString?: string
}

export interface ruleValidate {
    name: string;
    validateMessage: string;
    valid: boolean;
    contractId: number;
    conditionId: number;
}

const AllocationContracts: React.FC<Props> = (props) => {
    const finOpsMapManagerService = new FinOpsMapManagerService(props);

    const [rule, setRule] = useState<FinOpsAllocationRule>(props.rule);
    const [rulesGroups, setRulesGroups] = useState<FinOpsAllocationRule[]>([]);
    const [validations, setValidations] = useState<ruleValidate[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [savingRules, setSavingRules] = useState(false);
    const [editing, setEditing] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        setRule({ ...props.rule });
        setEditing(true)
        setCanSave(false)
    }, [props.rule]);

    useEffect(() => {
        let original = props.originalRuleString;
        let current = JSON.stringify(rule);
        if (original == current) {
            setChanged(false);
            validateRule();
        } else {
            setChanged(true);
            validateRule();
        }
    }, [rule]);


    useEffect(() => { setRulesGroups(props.ruleGroup); setValidations([]) }, [props.ruleGroup]);

    const [versionRecord, setVersionRecord] = useState<FinOpsAllocationGroup>(
        {
            id: 0,
            clientGroupId: 0,
            month: 0,
            year: 0,
            endDate: null,
            startDate: null,
            version: '',
            type: '',
            name: '',
            updatedByUser: '',
            updatedByUserName: '',
            updatedByUserEmail: '',
            updatedAt: '',
            rules: []
        });

    const jsonFinOpsOperation = [
        {
            "label": "contém",
            "value": "contains"
        },
        {
            "label": "é igual a",
            "value": "equal"
        },
        {
            "label": "inicia com",
            "value": "startWith"
        },
        {
            "label": "diferente",
            "value": "notEqual"
        },
        {
            "label": "não contém",
            "value": "notContains"
        }
    ];

    const jsonFinOpsLogicOperator = [
        {
            "label": "e",
            "value": "and"
        },
        {
            "label": "ou",
            "value": "or"
        }
    ];

    const jsonFinOpsLCFilter = [
        {
            "label": "ProviderName",
            "value": "ProviderName"
        },
        {
            "label": "PayerAccountID",
            "value": "PayerAccountID"
        },
        {
            "label": "AccountId",
            "value": "AccountId"
        },
        {
            "label": "AccountName",
            "value": "AccountName"
        },
        {
            "label": "Date",
            "value": "Date"
        },
        {
            "label": "ResourceID",
            "value": "ResourceID"
        },
        {
            "label": "ResourceName",
            "value": "ResourceName"
        },
        {
            "label": "Region",
            "value": "Region"
        },
        {
            "label": "Service",
            "value": "Service"
        },
        {
            "label": "MeterCategory",
            "value": "MeterCategory"
        },
        {
            "label": "MeterSubCategory",
            "value": "MeterSubCategory"
        },
        {
            "label": "ResourceGroup",
            "value": "ResourceGroup"
        },
        {
            "label": "Tags",
            "value": "Tags"
        },
        {
            "label": "ConsumedQuantity",
            "value": "ConsumedQuantity"
        },
        {
            "label": "ResourceAdditionalInfo",
            "value": "ResourceAdditionalInfo"
        },
        {
            "label": "MeteredCost",
            "value": "MeteredCost"
        },
        {
            "label": "PublisherType",
            "value": "PublisherType"
        },
        {
            "label": "Número Contrato",
            "value": "ContractId"
        }
    ];

    const emptyRecordContract: FinOpsAllocationRuleContract = {
        idContractRule: 0,
        idContract: '',
        contract: '',
        percentage: 0,
        percentageLocked: false,
    };

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 0,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: ''
    };

    function toCamelCase(str) {
        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }

    async function getFieldAutocompleteList(fieldName: string, fieldFilter: any) {
        var month = moment().month() + 1;
        var year = moment().year();
        var service = new FinOpsMapManagerService(props);
        var result = await service.GetFieldOptions(month, year, fieldName, fieldFilter);
        return result.slice(0, 50).map((item) => { return { value: item[toCamelCase(fieldName)] }; });
    };

    function validateRule() {
        var validationsChecks: ruleValidate[] = [];

        if (!(rule.description.length > 0)) {
            validationsChecks.push({
                name: 'description',
                validateMessage: 'Dê um nome para esta regra',
                valid: false,
                contractId: 0,
                conditionId: 0
            });
        }

        if (rule.description.length > 0) {
            rulesGroups.map(rul => {
                if (rule.description == rul.description && rule.id != rul.id)
                    validationsChecks.push({
                        name: 'description',
                        validateMessage: 'Já existe uma regra com este nome',
                        valid: false,
                        contractId: 0,
                        conditionId: 0
                    });
            })
        }

        if (!(rule.conditions.length > 0)) {
            validationsChecks.push({
                name: 'conditions',
                validateMessage: 'Todas as regras devem possuir ao menos uma condição.',
                valid: false,
                contractId: 0,
                conditionId: 0
            });
        }

        if ((rule.conditions.length > 0)) {
            rule.conditions.map(itemCondition => {
                if (!(itemCondition.field.length > 0)) {
                    validationsChecks.push({
                        name: 'field',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        contractId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }

                if (!(itemCondition.logicOperator.length > 0)) {
                    validationsChecks.push({
                        name: 'logicOperator',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        contractId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }
                if (!(itemCondition.operation.length > 0)) {

                    validationsChecks.push({
                        name: 'operation',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        contractId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }

                if (!(itemCondition.filter.length > 0))
                    validationsChecks.push({
                        name: 'filter',
                        validateMessage: 'Você deve preencher este valor',
                        valid: false,
                        contractId: 0,
                        conditionId: itemCondition.idCondition
                    });
            });
        }

        if (!(rule.contracts.length > 0)) {
            validationsChecks.push({
                name: 'contracts',
                validateMessage: 'Todas as regras devem possuir ao menos um contrato cadastrado.',
                valid: false,
                contractId: 0,
                conditionId: 0
            });
        }

        if ((rule.contracts.length > 0)) {
            rule.contracts.map(itemContract => {

                if (!(parseInt(itemContract.idContract) > 0)) {
                    validationsChecks.push({
                        name: 'IdContract',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        contractId: itemContract.idContractRule,
                        conditionId: 0
                    });
                }
            });
        }

        if ((rule.contracts.length > 0)) {
            rule.contracts.map(itemContract => {
                if (!(itemContract.percentage > 0)) {
                    validationsChecks.push({
                        name: 'percentage',
                        validateMessage: 'Valor deve ser maior que zero.',
                        valid: false,
                        contractId: itemContract.idContractRule,
                        conditionId: 0
                    });
                }
            });
        }

        if ((rule.contracts.length > 0)) {
            rule.contracts.map(itemContract => {
                if (itemContract.percentage > 100) {
                    validationsChecks.push({
                        name: 'percentage',
                        validateMessage: 'Valor deve ser menor que 100.',
                        valid: false,
                        contractId: itemContract.idContractRule,
                        conditionId: 0
                    });
                }
            });
        }

        if ((rule.contracts.length > 0)) {
            var totalPercentage = 0;
            rule.contracts.map(itemContract => {
                let porcentage = itemContract.percentage.toString();
                totalPercentage += parseInt(porcentage);
            });
            if ((totalPercentage != 100)) {
                validationsChecks.push({
                    name: 'percentage',
                    validateMessage: 'O total da porcentagem cadastrada nos centros de custo para uma regra deve ser igual a 100%.',
                    valid: false,
                    contractId: 0,
                    conditionId: 0
                });
            }
        }
        ;
        setValidations(validationsChecks);
        if (validationsChecks.length == 0)
            setCanSave(true)
        else
            setCanSave(false)
        return validationsChecks.length == 0;
    }

    const handleChange = (event: any, item: any) => {
        const { name, value } = event.target;
        if (name == 'percentage') {
            item[name] = parseInt(value);
        } else {
            item[name] = value;
        }
        let newRule = rule

        if (name == 'logicOperator') {
            newRule.conditions.map(itemCondition => {
                if (itemCondition.logicOperator != '')
                    itemCondition.logicOperator = value;
            });
        }
        if (name == 'percentage' && rule.contracts.length == 2) {
            rule.contracts.map(itemContract => {
                if (itemContract.idContractRule != item.idContractRule) {
                    itemContract.percentage = 100 - value
                }
            })
        }
        setRule({ ...newRule });
        validateRule()
    }

    const contractList = (ruleContractId: any) => {
        let filtered: any[] = props.contracts;
        let aux: any[] = [];
        if (ruleContractId == '') {
            rule.contracts.map(ruleContract => {
                props.contracts.map(contract => {
                    if (ruleContract.idContract.toString() == contract.value.toString()) {
                        aux = filtered.filter(f => f.value != contract.value);
                        filtered = aux;
                    }
                });
            });
            return filtered;
        } else {
            if (rule.contracts.length > 1) {
                rule.contracts.map(ruleContract => {
                    if (ruleContract.idContract.toString() != ruleContractId.toString()) {
                        props.contracts.map(contract => {
                            if (ruleContract.idContract.toString() == contract.value.toString()) {
                                aux = filtered.filter(f => f.value != contract.value);
                                filtered = aux;
                            }
                        });
                    }
                });
                return filtered;
            } else {
                return filtered
            }

        }
    }

    function calculatePercentage(array: FinOpsAllocationRuleContract[]) {

        if (array == null)
            return [];

        if (array.length == 0)
            return array;

        var total = 100;

        let lockedValues = array.filter(item => item.percentageLocked == true);
        let unlockedValues = array.filter(item => item.percentageLocked != true);

        if (lockedValues.length > 0) {
            total -= lockedValues.reduce((accumulator, currentValue) => accumulator + currentValue.percentage, 0);
        }

        var portion = Math.floor(total / unlockedValues.length);

        portion = Math.floor(portion * 100 - portion % 1) / 100;

        for (var i = 0; i < unlockedValues.length; i++) {
            var percentage = portion;
            if (unlockedValues.length == (i + 1))
                percentage = total - (portion * (i));
            unlockedValues[i].percentage = percentage;
        }

        array = []
        array = array.concat(lockedValues, unlockedValues);
        rule.contracts = array;
        setRule({ ...rule });
        validateRule()
        return [...array];

    }

    function addConditionRecord(parentRule: any) {
        var newRecord = emptyRecordCondition;
        let max = 0;
        if (parentRule.conditions.length > 0) {
            const ids = parentRule.conditions.map(object => {
                return object.idCondition;
            });
            max = Math.max.apply(null, ids);
        }
        if (rule)
            if (parentRule.conditions.length >= 2)
                newRecord.logicOperator = rule.conditions[1].logicOperator;
            else
                newRecord.logicOperator = "and";

        newRecord.idCondition = max + 1;
        parentRule.conditions.push(newRecord);

        setRule({ ...parentRule });
        validateRule();
    }

    function addContractRecord(parentRule: any) {

        var newRecord = emptyRecordContract;
        let max = 0;
        var cloneArr = [...parentRule.contracts];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.idContractRule;
            });
            max = Math.max.apply(null, ids);
        }

        newRecord.idContractRule = max + 1;
        cloneArr.push(newRecord);

        if (rule.contracts.length > 1) {
            setRule({ ...parentRule, contracts: cloneArr })
        } else {
            calculatePercentage(cloneArr);
        }
    }

    function removeConditionRecord(item, parentRule) {
        parentRule.conditions = parentRule.conditions.filter(x => x.idCondition != item.idCondition);
        setRule({ ...parentRule });
        validateRule();
    }

    function removeContractRecord(item, parentRule) {
        parentRule.contracts = parentRule.contracts.filter(x => x.idContractRule != item.idContractRule);
        parentRule.contracts = calculatePercentage(parentRule.contracts);
        setRule({ ...parentRule });
        validateRule();
    }

    function addRuleRecord() {
        if (rule.id == 0) {
            let max = 0;
            let newRule = rule;
            let newList = rulesGroups.filter(rul => rul.id != rule.id);
            let rules = rulesGroups.filter(rule => rule.default != true);

            if (rules.length > 0) {
                max = Math.max.apply(null, rules.map(object => {
                    return object.id;
                }));
            }
            newRule.id = max + 1;
            let newOrders = newList.map(r => {
                if (r.order >= newRule.order) {
                    r.order = r.order + 1;
                }
                return r;
            })

            newOrders.push(newRule);
        } else {
            let newList = rulesGroups.filter(rul => rul.id != rule.id);
            newList.push(rule);
            setRulesGroups(newList);
            saveRuleGroup(newList)
        }
    }

    function disableLocker(isLocked?: boolean) {
        let quantConstCenter = rule.contracts.length;
        let lockedValues = rule.contracts.filter(item => item.percentageLocked == true);
        if (isLocked) {
            return false
        }
        return (quantConstCenter >= 3 && lockedValues.length >= quantConstCenter - 2) || quantConstCenter < 3
    }

    function disableAddCondition(itemCondition: FinOpsAllocationRuleCondition) {
        let disableButton = false;
        if (!(itemCondition.field.length > 0))
            disableButton = true;
        if (!(itemCondition.logicOperator.length > 0))
            disableButton = true;
        if (!(itemCondition.operation.length > 0))
            disableButton = true;
        if (!(itemCondition.filter.length > 0))
            disableButton = true;
        return disableButton;
    }

    function disableRemoveCondition(itemCondition: FinOpsAllocationRuleCondition) {
        let disableButton = false;
        let maxId = Math.max.apply(null, rule.conditions.map(object => { return object.idCondition; }))
        if (maxId == itemCondition.idCondition) {
            return false
        } else {
            if (!(itemCondition.field.length > 0))
                disableButton = true;
            if (!(itemCondition.logicOperator.length > 0))
                disableButton = true;
            if (!(itemCondition.operation.length > 0))
                disableButton = true;
            if (!(itemCondition.filter.length > 0))
                disableButton = true;
            let validationError = validations.find(validation => validation.conditionId)

            return disableButton || (validationError && rule.conditions.length <= 2);
        }
    }

    function disableAddContract(itemContract: FinOpsAllocationRuleContract) {
        let disableButton = false;
        if (itemContract.percentageLocked)
            return disableButton = true

        if (!(parseInt(itemContract.idContract) > 0)) {
            disableButton = true
        }

        if (!(itemContract.percentage > 0)) {
            disableButton = true
        }

        if (itemContract.percentage > 100) {
            disableButton = true
        }

        return disableButton
    }

    function disableRemoveContract(itemContract: FinOpsAllocationRuleContract) {
        if (rule.contracts.length == 1)
            return true


        let maxId = Math.max.apply(null, rule.contracts.map(object => { return object.idContractRule; }))

        if (maxId == itemContract.idContractRule) {
            return false
        } else {
            if (!(parseInt(itemContract.idContract) > 0))
                return true

            if (!(itemContract.percentage > 0))
                return true

            if (itemContract.percentage > 100)
                return true

            if (rule.contracts.length == 2)
                return validations.length > 0
        }

    }

    async function saveRuleGroup(ruleGroup?: FinOpsAllocationRule[]) {
        ruleGroup != undefined ? versionRecord.rules = ruleGroup : versionRecord.rules = rulesGroups;
        setSavingRules(true);
        try {
            await finOpsMapManagerService.SaveFinOpsAllocationContract(versionRecord);
            props.onSaveGroup && props.onSaveGroup()
            setEditing(false)
        } catch (e) {

        }
        setSavingRules(false);

    }

    const setTooltipContent = () => {
        if (validations.length > 0) {
            let campos = validations.find(validation => validation.validateMessage == 'Você deve preencher este valor' || validation.validateMessage == 'Selecione um campo.')
            let percentage = validations.find(validation => validation.validateMessage.includes('100'))
            let percentageZero = validations.find(validation => validation.validateMessage.includes('zero'))
            return campos && 'Há campos obrigatórios não preenchidos' || percentage && 'Total das porcentagens deve ser igual a 100%' || percentageZero?.validateMessage
        } else
            if (changed == false)
                return 'Faça alguma alteração'
    }

    const getErrorData = (conditionId, name) => {
        let data = validations.find(x => x.conditionId == conditionId && x.name == name);
        return data ? { show: true, help: data.validateMessage } : undefined;
    }

    return (
        <PPModal
            title={
                props.readOnly == true ?
                    'Detalhes da regra'
                    :
                    (props.rule.id == 0 ?
                        'Nova regra'
                        :
                        'Detalhes da regra'
                    )
            }
            visible={editing}
            size="large"
            onClose={() => {
                props.readOnly == true ?
                    props.onCancelAction && props.onCancelAction()
                    :
                    changed ?
                        setConfirmVisible(true)
                        :
                        props.onCancelAction && props.onCancelAction()
            }}
            functions={[
                { label: "Ok", onClick: () => addRuleRecord(), disabled: !canSave || savingRules || !changed, hidden: props.readOnly },
                {
                    label: "Cancelar", onClick: () => {
                        props.readOnly == true ?
                            props.onCancelAction && props.onCancelAction()
                            :
                            changed ?
                                setConfirmVisible(true)
                                :
                                props.onCancelAction && props.onCancelAction()
                    },
                    hidden: props.readOnly
                }
            ]}
        >
            <div className="mb-5">
                <div className='titulos'>
                    Nome da Regra
                </div>
                <PPTextField
                    type='text'
                    name="description"
                    required={!props.readOnly}
                    readOnly={props.readOnly}
                    value={rule.description}
                    onChange={(event) => { handleChange(event, rule); }}
                    help={validations.find(x => 'description' == x.name)?.validateMessage}
                    error={validations.find(x => 'description' == x.name) != undefined}
                />
            </div>

            <div className="mb-5">
                <div className='titulos'>
                    Condições
                </div>

                {
                    rule && rule.conditions
                        .sort((a, b) => a.idCondition > b.idCondition ? 1 : -1)
                        .map((itemCondition, index) => {
                            return (
                                <PPGrid key={index} container spacing={2}>
                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        {rule && Math.min.apply(null, rule.conditions.map(object => {
                                            return object.idCondition;
                                        })) != itemCondition.idCondition &&
                                            <PP_DropDownOld
                                                required={!props.readOnly}
                                                error={getErrorData(itemCondition.idCondition, 'logicOperator')}
                                                name="logicOperator"
                                                value={itemCondition.logicOperator}
                                                options={jsonFinOpsLogicOperator}
                                                onChange={(event) => { handleChange(event, itemCondition); }}
                                                readOnly={props.readOnly || index != 1}
                                            />
                                        }
                                    </PPGrid>

                                    <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>

                                        <PP_DropDownOld
                                            name="field"
                                            value={itemCondition.field}
                                            error={getErrorData(itemCondition.idCondition, 'field')}
                                            options={jsonFinOpsLCFilter}
                                            placeholder='(Critério)'
                                            onChange={(event) => { handleChange(event, itemCondition); }}
                                            required={!props.readOnly}
                                            readOnly={props.readOnly}
                                        />

                                    </PPGrid>


                                    <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>

                                        <PP_DropDownOld
                                            required={!props.readOnly}
                                            name="operation"
                                            error={getErrorData(itemCondition.idCondition, 'operation')}
                                            value={itemCondition.operation}
                                            options={jsonFinOpsOperation}
                                            onChange={(event) => { handleChange(event, itemCondition); }}
                                            readOnly={props.readOnly}
                                        />

                                    </PPGrid>

                                    <PPGrid item xs={props.readOnly == false ? 6 : 7} sm={props.readOnly == false ? 6 : 7} md={props.readOnly == false ? 6 : 7} lg={props.readOnly == false ? 6 : 7} xl={props.readOnly == false ? 6 : 7}>
                                        <PPTextField
                                            required={!props.readOnly}
                                            readOnly={props.readOnly}
                                            type={'text'}
                                            error={validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name) != undefined}
                                            help={validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name)?.validateMessage}
                                            name="filter"
                                            value={itemCondition.filter}
                                            onChange={(event) => { handleChange(event, itemCondition); }}
                                            placeholder='(Valor)'
                                            sugestions={(event) => getFieldAutocompleteList(itemCondition.field, event) as any}
                                        />
                                    </PPGrid>
                                    {props.readOnly == false &&
                                        <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <div className="row">
                                                <LcIconLink size={'medium'} tooltipPosition={'right'} icon={`ppi ppi-trash `}
                                                    disabled={rule.conditions.length == 1 || disableRemoveCondition(itemCondition)}
                                                    onClick={() => { removeConditionRecord(itemCondition, rule); }} />

                                                {Math.max.apply(null, rule.conditions.map(object => {
                                                    return object.idCondition;
                                                })) == itemCondition.idCondition &&
                                                    <LcIconLink
                                                        size={'medium'}
                                                        tooltipPosition={'left'}
                                                        icon={`ppi ppi-plus `}
                                                        onClick={() => { addConditionRecord(rule); validateRule() }}
                                                        tooltip="Adicionar Condição"
                                                        disabled={disableAddCondition(itemCondition)}
                                                    />
                                                }
                                            </div>

                                        </PPGrid>
                                    }
                                </PPGrid>
                            )
                        })
                }
            </div>

            <div className="mb-5">
                <div className='titulos' >Contratos</div>

                {
                    rule && rule.contracts
                        .sort((a, b) => a.idContractRule - b.idContractRule)
                        .map((itemContract, index) => {
                            return (
                                <PPGrid key={index} container spacing={2} alignItems='stretch'>

                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        {!props.readOnly && <div style={{ display: 'flex', alignItems: 'center', paddingTop: '20px', justifyContent: 'flex-end' }}>
                                            <LcIconLink size={'medium'}
                                                tooltip={itemContract.percentageLocked ? 'Liberar valor' : 'Fixar valor'}
                                                tooltipPosition={'right'}
                                                icon={itemContract.percentageLocked ? <PiLockLight/> : <PiLockOpenLight/>}
                                                disabled={disableLocker(itemContract.percentageLocked)}
                                                onClick={() => handleChange({ target: { name: 'percentageLocked', value: !itemContract.percentageLocked } }, itemContract)} />
                                        </div>}
                                    </PPGrid>

                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        < PP_DropDownOld
                                            top
                                            title="Contrato"
                                            error={getErrorData(itemContract.idContractRule, 'idContract')}
                                            key={index}
                                            name="idContract"
                                            value={itemContract.idContract}
                                            options={contractList(itemContract.idContract)}
                                            onChange={(event) => { handleChange(event, itemContract); }}
                                            required={!props.readOnly}
                                            readOnly={props.readOnly}
                                        />
                                    </PPGrid>

                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <PPTextField
                                            label="Porcentagem"
                                            error={validations.find(x => x.contractId == itemContract.idContractRule && 'percentage' == x.name) != undefined}
                                            help={validations.find(x => x.contractId == itemContract.idContractRule && 'percentage' == x.name)?.validateMessage}
                                            key={index}
                                            step='5'
                                            max='100'
                                            min='0'
                                            type={'number'}
                                            name="percentage"
                                            value={itemContract.percentage}
                                            readOnly={props.readOnly || rule.contracts.length == 1 || itemContract.percentageLocked}
                                            onChange={(event) => { handleChange(event, itemContract); }} />
                                    </PPGrid>
                                    {props.readOnly == false &&
                                        <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '20px', justifyContent: 'flex-start', width: '10rem' }}>
                                                <LcIconLink
                                                    key={index}
                                                    size={'medium'}
                                                    tooltipPosition={'right'}
                                                    icon={`ppi ppi-trash `}
                                                    disabled={disableRemoveContract(itemContract)}
                                                    onClick={() => { removeContractRecord(itemContract, rule); setValidations([]); }} />

                                                {
                                                    index == 0 && rule.contracts.length > 1 &&
                                                    <button className='lc-button bg-info' style={{ marginLeft: '0.35rem' }} onClick={() => { calculatePercentage(rule.contracts) }}> Redividir </button>
                                                }

                                                {Math.max.apply(null, rule.contracts.map(object => {
                                                    return object.idContractRule;
                                                })) == itemContract.idContractRule &&
                                                    <div style={{ paddingLeft: '7px' }}>
                                                        <LcIconLink
                                                            size={'medium'}
                                                            icon={`ppi ppi-plus `}
                                                            onClick={() => { addContractRecord(rule) }}
                                                            tooltip="Adicionar contrato"
                                                            disabled={disableAddContract(itemContract)}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </PPGrid>
                                    }
                                </PPGrid>
                            );
                        })
                }
            </div>

            {!props.readOnly &&
                <p className="text-danger font-sm">{setTooltipContent()}</p>
            }


            <Confirmation
                title="Confirme"
                text='Tem certeza que deseja perder as alterações feitas?'
                confirm={() => { setConfirmVisible(false); setEditing(false); props.onCancelAction && props.onCancelAction() }}
                close={() => { setConfirmVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmVisible}
            />

        </PPModal >

    )
};

export default AllocationContracts;