import React from 'react';
import { PPInput } from 'processor-plataform-ui';
interface InputFieldFilterProps {
  id?:string;
  name?:string;
  label: string;
  type: 'number' | 'time' | 'text' | 'date' | 'datetime-local' | 'month' | 'week' | 'email' | 'password' | 'tel' | 'url' | 'file';
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  translations?: { [key: string]: string };
}

const InputFieldFilter: React.FC<InputFieldFilterProps> = ({ label, type, value, onChange, placeholder, translations, name}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Input value changed: ${e.target.value}`); // Log para verificar a mudança de valor
    onChange(e.target.value);
  };

  return (
    <div className="input-field-filter">
      
      <PPInput
        id={`form_filtros_${name}_${type}`}
        title={label}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputFieldFilter;