import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';

import BancoDeDadosService from '../../../services/eBiz/BancoDeDadosService';


import SideModal from '../../../components/Layout/SideModal/SideModal';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { PPModal } from 'processor-plataform-ui'

interface DataBaseInterface {
  'nome': string,
  'idSource': string,
  'espacoUtilizado': string,
  'situacao': string,
  'servidor': string,
  'endPointFull': string
}

const BancoDeDados: React.FC = (props) => {

  const service = new BancoDeDadosService(props);

  const [dataBases, setDataBases] = useState<any[]>([]);
  const [dataBasesFiltered, setDataBasesFiltered] = useState<any[]>([]);
  const [VisibleDataBases, setVisibleDataBases] = useState<any[]>([]);
  const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

  const [visibleModalDetails, setVisibleModalDetails] = useState<boolean>(false);
  const [dataBase, setDataBase] = useState<DataBaseInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const user = useSelector<RootState, UserState>(state => state.user);
  const pref = useSelector<RootState, PrefState>(state => state.pref);
  const dispatch = useDispatch();

  useEffect(() => {
    async function run() {
      setIsLoading(true);
      setDataBasesFiltered([]);

      await service.GetBancoDeDados().then((response: object[]) => {
        setDataBases(response);
        setDataBasesFiltered(response);
      });

      setIsLoading(false);
    }

    run();
  }, [user.ClientGroupSelected, user.refreshFlag]);

  useEffect(() => {
    var filtered = dataBases.filter((p: any) =>
      filter.term == '' ||
      p.nome?.toLowerCase().indexOf(filter.term) > -1 ||
      p.idSource.toLowerCase().indexOf(filter.term) > -1 ||
      p.espacoUtilizado.toLowerCase().indexOf(filter.term) > -1 ||
      p.situacao.toLowerCase().indexOf(filter.term) > -1 ||
      p.servidor.toLowerCase().indexOf(filter.term) > -1
    )

    filtered = filtered.sort((a: any, b: any) => {
      if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
      else return (a[filter.column] > b[filter.column]) ? -1 : 1;
    });

    setDataBasesFiltered(filtered);
    setVisibleDataBases(filtered.slice(0, filter.size));
  }, [filter, dataBasesFiltered.length > 0]);

  const loadDatabase = (id: any) => {
    let row = dataBases.find(o => o.id = id);

    setDataBase({
      nome: row.nome,
      idSource: row.idSource,
      espacoUtilizado: row.espacoUtilizado,
      situacao: row.situacao,
      servidor: row.servidor,
      endPointFull: row.endPointFull
    });

    setVisibleModalDetails(true);
  }

  const functionsRow = [{ label: 'Detalhes', func: loadDatabase }]

  const columns = [
    { field: 'nome', headerName: 'Nome', width: "25%" },
    { field: 'idSource', headerName: 'Identificador', width: "40%" },
    { field: 'espacoUtilizado', headerName: 'Alocado (GB)', width: "10%" },
    { field: 'situacao', headerName: 'Status', width: "10%" },
    { field: 'servidor', headerName: 'Servidor', width: "15%" }
  ];



  const onSortChange = (sortData: any) => {
    const { sort, size } = sortData;
    setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
  };

  const loadMore = (size?: number) => {
    if (size) setVisibleDataBases(dataBasesFiltered.slice(0, size))
    else setVisibleDataBases([...VisibleDataBases, ...dataBasesFiltered.slice(VisibleDataBases.length, VisibleDataBases.length + 10)])
  };

  const changeDensity = (density: string) => {
    dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
  }
  const [filterVisible, setFilterVisible] = useState(false);
  const filterAdvanced = {
    fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
    onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() }),
    visible: filterVisible,
    onChangeVisibility: () => setFilterVisible(!filterVisible)
  };

  return (
    <Layout
      pageTitle="Ambiente"
      dots={
        <>
          <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
          <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
          <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
        </>
      }
      gridFilter={{
        toggleVisibility: () => {
          setFilterVisible(!filterVisible)
        },
        size: dataBasesFiltered.length
      }}
    >

      <PPModal visible={visibleModalDetails} title="Detalhes" onClose={() => setVisibleModalDetails(false)}>
        <p><b>Nome:</b> {dataBase?.nome}</p>
        <p><b>Identificador:</b> {dataBase?.idSource}</p>
        <p><b>Alocado:</b> {dataBase?.espacoUtilizado}</p>
        <p><b>Status:</b> {dataBase?.situacao}</p>
        <p><b>Servidor:</b> {dataBase?.servidor}</p>
        <p><b>URL:</b> {dataBase?.endPointFull}</p>
      </PPModal>

      <LcInfiniteTable
        loading={isLoading}
        columns={columns}
        rows={VisibleDataBases}
        filter={filterAdvanced}
        functionsRow={functionsRow}
        size={dataBasesFiltered.length}
        loadMore={loadMore}
        onSortChange={onSortChange}
        density={pref.data?.density || "high"}
      />
    </Layout>
  );
}

export default BancoDeDados;