import React, { useEffect, useState } from 'react';
import { RiCloseLine, RiFullscreenExitLine, RiFullscreenLine, RiPushpinLine, RiUnpinLine } from 'react-icons/ri';
import LcTooltip from '../../../Generic/LcTooltip';
import { createComponente } from '../ChartComponent';
import "./index.css";
import LcIconLink from '../../../Generic/LcIconLink';
import { FilterLcInfiniteTable, FilterField } from './FilterLcInfiniteTable';
import { PiArrowArcLeft, PiArrowCircleLeftLight, PiCornersInLight, PiCornersOutLight, PiInfo, PiInfoLight, PiPushPin, PiPushPinLight, PiPushPinSlashLight, PiXLight } from 'react-icons/pi';
/*import { Card } from '../../../../contexts/CardContext';*/

const DEFAULT_NUM_ROWS = 4;
const DEFAULT_NUM_COLS = 12;

export interface PositionCard {
    row: number; col: number; RowSpan: number; ColSpan: number; hidden?: boolean
}
export interface Card {
    id?: number;
    bgColor?: string;
    fontColor?: string;
    type?: string;
    position: PositionCard,
    customContentRender?: Function;
    title?: Function;
    showFocusButton?: boolean;
    hideHeader?: boolean;
    detailButton?: Function;
    infoContent?: Function;
    infoOnTop?: boolean | null;
    ticketButton?: Function;
    ticketButtonLabel?: string;
    cssClass?: string;
    centeredContent?: boolean;
}

interface DashboardProps {
    cards: Card[];
    grid?: [number, number]; // [linhas, colunas]
    isHomeType?: boolean;
    isSubGrid?: boolean;
    displayPanel?: boolean;
    rightSidePanel?: {
        id?:string,
        hiddenPin?: boolean;
        hidePinWhenFocus?: boolean;
        title: string,
        content: Function,
        pinned: boolean,
        togglePinned?: () => void;
        show: boolean,
        focusable?: boolean,
        close?: Function,
        titleClass?: string
    };
    header?: { content: Function };
    footer?: { content: Function };
    cssClass?: string;
    cssClassPanel?: string;
}

//interface RightSidePanelProps {
//    title: string;
//    content: Function;
//    pinned: boolean;
//    togglePinned?: () => void;
//    show: boolean;
//    setShow: React.Dispatch<React.SetStateAction<boolean>>;
//    setPinned: (pinned: boolean) => void;
//    numCols: number;
//    filters: FilterField[];
//    onFilterChange: (updatedFilters: FilterField[]) => void;
//    filterVisible: boolean;
//    toggleFilterVisibility: () => void;
//    hiddenPin?: boolean

//}

export interface CardProps {
    card: Card;
    index: number;
    focusedCardIndex: number | null;
    setFocusedCardIndex: (index: number | null) => void;
    numRows: number;
    numCols: number;
    isHomeType: boolean;

}

const getDynamicGridStyles = (card, isFocused, shouldHide, numRows, numCols, isHomeType) => {

    const baseStyles = {
        backgroundColor: isHomeType ? (card.bgColor || 'transparent') : (card.bgColor || '#fff'),
        color: card.fontColor || '',
        padding: isHomeType && card.bgColor ? '12px' : '',
    };

    if (isFocused) {
        return {
            ...baseStyles,
            gridRowStart: 1,
            gridColumnStart: 1,
            gridRowEnd: numRows + 1,  // span to the end of the grid
            gridColumnEnd: numCols + 1, // span to the end of the grid
            display: 'flex',
            //opacity: 1,
        };
    }

    return {
        ...baseStyles,
        gridRowStart: card.position.row,
        gridColumnStart: card.position.col,
        gridRowEnd: card.position.row + card.position.RowSpan,
        gridColumnEnd: card.position.col + card.position.ColSpan,
        //display: shouldHide ? 'flex' : 'flex',
        //opacity: shouldHide ? 0 : 1,
    };
};

const LCCard: React.FC<CardProps> = React.memo(({ card, index, focusedCardIndex, setFocusedCardIndex, numRows, numCols, isHomeType }) => {
    const isFocused = focusedCardIndex == index;
    const shouldHide = focusedCardIndex != null && focusedCardIndex != index;
    const dynamicStyles = getDynamicGridStyles(card, isFocused, shouldHide, numRows, numCols, isHomeType);
    const [wasPreviouslyFocused, setWasPreviouslyFocused] = useState(false);

    useEffect(() => {
        if (isFocused) setWasPreviouslyFocused(true);
    }, [isFocused]);

    const handleFocusClick = () => {
        setFocusedCardIndex(focusedCardIndex == index ? null : index);
    };

    const getCardClass = () => {
        if (isFocused) return 'card-focused';
        if (wasPreviouslyFocused) return 'card-unfocusing';
        if (shouldHide) return 'card-hidden';
        return 'card-reappearing';
    };

    return (
        <div className={`card-frame ${getCardClass()} ${card.cssClass || ''} ${card.hideHeader ? 'no-header' : ''}`}
            style={dynamicStyles}
        >
            {!card.hideHeader && <div className='card-frame-header-content'>
                {card.title && <div className={'title card-title'} style={{ color: card.fontColor || '' }}>{card.title()}</div>}
                <div id={`bodyCard_${index}`} className='block-icons'>
                    {card.infoContent && card.infoContent() ? (
                        <LcTooltip content={card.infoContent()} position='fullright' top={card.infoOnTop ?? undefined} trigger="hover">
                            <span className="icon">
                                <PiInfoLight />

                            </span>
                        </LcTooltip>
                    ) : null}
                    {card.showFocusButton ? (
                        <LcTooltip content={focusedCardIndex == index ? 'Voltar' : 'Ampliar'} position="right" trigger="hover">
                            <span className="icon" onClick={handleFocusClick} role="img" aria-label="focus">
                                {focusedCardIndex === index ? (
                                    <PiArrowCircleLeftLight />
                                ) : (
                                    <PiCornersOutLight />
                                )}
                            </span>
                        </LcTooltip>
                    ) : null}
                    {card.ticketButton ? (
                        <LcTooltip content={card.ticketButtonLabel} position="right" trigger="hover">
                            <span className="icon">
                                {card.ticketButton()}
                            </span>
                        </LcTooltip>
                    ) : null}
                </div>
            </div>}
            <div className={(isHomeType ? 'card-content-body-home' : 'card-content-body') + ' ' + (card.centeredContent == true || card.centeredContent == null || card.centeredContent == undefined ? 'centered' : 'fromStart')}>
                <div className='card-content-body-content' style={{ color: card.fontColor || '' }}>
                    {createComponente(card.type, card.customContentRender, focusedCardIndex === index, handleFocusClick)}
                </div>
            </div>
        </div>
    );
});

interface RightSidePanelProps {
    title: string;
    content: Function;
    pinned: boolean;
    togglePinned?: () => void;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setPinned: (pinned: boolean) => void;
    numCols: number;
    filters: FilterField[];
    onFilterChange: (updatedFilters: FilterField[]) => void;
    filterVisible: boolean;
    toggleFilterVisibility: () => void;
    hiddenPin?: boolean;
    focusable?: boolean;
    hidePinWhenFocus?: boolean;
    titleClass?: string; // New parameter to apply a CSS class to the title
}

const RightSidePanel: React.FC<RightSidePanelProps> = ({
    title,
    content,
    pinned,
    show,
    setShow,
    setPinned,
    numCols,
    filters,
    onFilterChange,
    filterVisible,
    toggleFilterVisibility,
    togglePinned,
    hiddenPin,
    hidePinWhenFocus,
    focusable = false,
    titleClass = '', // Default to an empty string if not provided
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleTogglePinned = () => {
        const newPinnedState = !pinned;
        setPinned(newPinnedState);
        if (togglePinned) {
            togglePinned();
        }
    };

    const handleToggleFocus = () => {
        setIsFocused(!isFocused);
    };

    const handleClose = () => {
        if (isFocused) {
            setIsFocused(false);
        } else {
            setShow(false);
        }
    };

    const renderPinnedIcon = () => (
        <LcIconLink
            icon={pinned ? <PiPushPinSlashLight /> : <PiPushPinLight />}
            tooltip={pinned ? 'Desafixar' : 'Fixar'}
            onClick={handleTogglePinned}
            size="medium"
        />
    )

    return (
        <>
            {show && (
                <div
                    className={`right-sidepanel ${isFocused ? 'focused' : ''} ${pinned ? 'pinned' : ''}`}
                    style={{
                        gridColumnStart: isFocused ? 1 : numCols - 2,
                        gridColumnEnd: isFocused ? numCols + 1 : undefined,
                        position: isFocused ? 'absolute' : 'relative',
                        left: isFocused ? 0 : '10px',
                        width: isFocused ? '100%' : 'auto',
                        height: isFocused ? 'calc(100vh - 80px)' : 'auto',
                        zIndex: isFocused ? 9 : '',
                    }}
                >
                    <div className="right-sidepanel-content">
                        <div className="title-sidebar-content">
                            <div className={`title-sidebar ${titleClass}`}>{title}</div>
                            <div className="title-icons">
                                {hidePinWhenFocus ? !hiddenPin && renderPinnedIcon() : !hiddenPin && !isFocused && renderPinnedIcon()}
                                {focusable && (
                                    <LcIconLink
                                        icon={isFocused ? <PiCornersInLight /> : <PiCornersOutLight />}
                                        tooltip={isFocused ? 'Sair do Foco' : 'Focar'}
                                        onClick={handleToggleFocus}
                                        size="medium"
                                    />
                                )}
                                <LcIconLink
                                    icon={<PiXLight />}
                                    onClick={handleClose}
                                    size="medium"
                                    tooltip="Fechar"
                                />
                            </div>
                        </div>
                        <div className="filter-content">
                            {content()}
                            {filterVisible && (
                                <FilterLcInfiniteTable
                                    filter={filters}
                                    onFilterChange={onFilterChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const LCDashboard: React.FC<DashboardProps> = React.memo(({
    cards: propCards,
    rightSidePanel,
    header,
    footer,
    grid = [DEFAULT_NUM_ROWS, DEFAULT_NUM_COLS],
    isHomeType = false,
    isSubGrid = false,
    cssClass = '',
    cssClassPanel = ''
}) => {

    const {
        title,
        content,
        pinned: propPinned,
        show: propShow,
        close,
        focusable = false,
        titleClass
    } = rightSidePanel ? rightSidePanel : { title: '', content: undefined, pinned: false, show: false, close: undefined, focusable: false, titleClass: '' };

    const [localNumRows, localNumCols] = grid;

    const [focusedCardIndex, setFocusedCardIndex] = useState<number | null>(null);
    const [rightSidePanelPinned, setRightSidePanelPinned] = useState<boolean>(propPinned);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(propShow);
    const [cards, setCards] = useState<Card[]>(propCards);

    // Estado para os filtros e sua visibilidade
    const [filters, setFilters] = useState<FilterField[]>([]);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);

    // Função para lidar com mudanças nos filtros
    const handleFilterChange = (updatedFilters: FilterField[]) => {
        setFilters(updatedFilters);
        // Atualize o estado de dados da LcInfiniteTable, se necessário
    };

    // Toggle para a visibilidade do filtro
    const toggleFilterVisibility = () => {
        setFilterVisible(!filterVisible);
    };


    useEffect(() => {
        setRightSidePanelPinned(propPinned);
    }, [propPinned]);

    useEffect(() => {
        setShowFilterPanel(propShow);
    }, [propShow])

    useEffect(() => {
        if (showFilterPanel == false) {
            close && close(showFilterPanel)
        }

    }, [showFilterPanel])

    useEffect(() => {
        setCards(propCards);
    }, [propCards])

    const startRow = header?.content ? 2 : 1;
    let endRowOffset;

    if (footer?.content) {
        endRowOffset = localNumCols - 1;
    } else if (header?.content) {
        endRowOffset = localNumCols - 1;
    } else {
        endRowOffset = localNumCols;
    }
    function getGridCSSClass() {
        let classes = ''; // Começa com uma string vazia
        if (isHomeType) {
            classes += ' isHomeType'; // Adiciona espaço antes para separar as classes
        }
        if (isSubGrid) {
            classes += ' isSubGrid'; // Mesmo aqui
        }
        return classes;
    }

    return (
        <div className={`lcdashboard${getGridCSSClass()} ${cssClass}`.trim()} style={{ gridTemplateColumns: `repeat(${localNumCols}, 1fr)` }}>
            <div className={`lcdashboard-cards ${cssClassPanel}`} style={{ gridColumnEnd: rightSidePanelPinned && showFilterPanel ? localNumCols - 2 : localNumCols + 1 }}>
                <div className='lcdashboard-cards-panel' style={{ gridTemplateRows: `repeat(${localNumCols}, 1fr)` }}>
                    {header?.content && <div className='lcdashboard-cards-panel-header'>{header.content()}</div>}
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRowStart: startRow, gridRowEnd: `span ${endRowOffset}` }}>
                        <div className='lcdashboard-cards-panel-body' style={{ gridTemplateRows: `repeat(${localNumRows}, 1fr)`, gridTemplateColumns: `repeat(${localNumCols}, minmax(0, 1fr))` }}>
                            {cards.filter(card => card.position && !card.position.hidden).map((card, index) => (
                                <LCCard
                                    card={card}
                                    index={index}
                                    key={`card_${index}`}
                                    focusedCardIndex={focusedCardIndex}
                                    setFocusedCardIndex={setFocusedCardIndex}
                                    numRows={localNumRows}
                                    numCols={localNumCols}
                                    isHomeType={isHomeType} />
                            ))}
                        </div>
                    </div>
                    {footer?.content && <div className='lcdashboard-cards-panel-footer' style={{ gridRowStart: localNumCols }}>{footer.content()}</div>}
                </div>
            </div>
            {rightSidePanel && content && (
                <RightSidePanel
                    title={title}
                    titleClass={titleClass}
                    content={content}
                    pinned={rightSidePanelPinned}
                    focusable={focusable}  // Agora a propriedade focusable está sendo passada corretamente
                    show={showFilterPanel}
                    setShow={setShowFilterPanel}
                    setPinned={setRightSidePanelPinned}
                    numCols={localNumCols}
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    filterVisible={filterVisible}
                    toggleFilterVisibility={toggleFilterVisibility}
                    togglePinned={rightSidePanel.togglePinned}
                    hiddenPin={rightSidePanel.hiddenPin}
                />
            )}
        </div>
    );
});

export default LCDashboard;