import { PPForm, PPForm2 } from 'processor-plataform-ui';
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import React, { createRef, useEffect, useState } from 'react';

const TabForm: React.FC = () => {

    const fields: PPFormInput[] = [
        {
            title: "Nome",
            name: "nome",
            value: "",
            type: "text",
            width: "20%",
            placeholder: "teste"
        },
        {
            title: "Rua",
            name: "rua",
            value: "Casemiro de Abreu",
            type: "text",
            width: "20%",
            required: true,
        },
        {
            title: "Número",
            name: "numero",
            value: "500",
            type: "text",
            width: "25%"
        },
        {
            title: "Bairro",
            name: "bairro",
            value: "Centro",
            type: "text",
            width: "33.3%",
            readOnly: true
        },
        {
            title: "Cidade",
            name: "cidade",
            value: "Cabo Frio",
            type: "text",
            width: "33.3%",
            disabled: true
        }
    ]

    const fieldsTypes: PPFormInput[] = [
        {
            title: "text",
            name: "text",
            type: "text",
            width: "25%",
            required: true
        },
        {
            title: "date",
            name: "date",
            type: "date",
            width: "25%",
            required: true
        },
        {
            title: "datetime-local",
            name: "datetime-local",
            type: "datetime-local",
            width: "25%",
            required: true
        },
        {
            title: "month",
            name: "month",
            type: "month",
            width: "25%",
            required: true
        },
        {
            title: "time",
            name: "time",
            type: "time",
            width: "25%",
            required: true
        },
        {
            title: "week",
            name: "week",
            type: "week",
            width: "25%",
            required: true
        },
        {
            title: "email",
            name: "email",
            type: "email",
            width: "25%",
            required: true
        },
        {
            title: "number",
            name: "number",
            type: "number",
            width: "25%",
            required: true
        },
        {
            title: "password",
            name: "password",
            type: "password",
            width: "25%",
            required: true,
            functions: [{ icon: "ppi ppi-cog", onClick: () => { }, tooltip: "teste totototo" }]
        },
        {
            title: "tel",
            name: "tel",
            type: "tel",
            width: "25%",
            required: true
        },
        {
            title: "url",
            name: "url",
            type: "url",
            width: "25%",
            required: true
        },
        {
            title: "multiselect",
            name: "multiselect",
            type: "multiselect",
            options: [{ label: "A", value: "a" }, { label: "B", value: "b" }, { label: "C", value: "c" }],
            width: "25%",
            required: true
        },
        {
            title: "dropdown",
            name: "dropdown",
            type: "dropdown",
            options: [{ label: "A", value: "a" }, { label: "B", value: "b" }, { label: "C", value: "c" }],
            width: "25%",
            required: true
        },
        {
            title: "textarea",
            type: "textarea",
            name: "textarea",
            width: "25%",
            required: true,
        }
    ]

    return (<>
        <h1>Form</h1>

        <PPForm2 fields={fieldsTypes} onSubmit={console.debug} />

        <hr />

        <PPForm2 fields={fields} onSubmit={console.debug} />

    </>);
}

export default TabForm;