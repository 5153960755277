import api, { APIClient } from "./api";


export default class LiveWatchServiceGroupService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    RetrieveList() {
        const url = this.endpoint + `/api/ServiceGroups/GetServiceGroups`;
        return api({
            method: 'get',
            url,
        });
    }

    GetServiceGroup(id: any) {
        const url = this.endpoint + `/api/ServiceGroups/GetServiceGroup/${id}`;
        return api({
            method: 'get',
            url,
        });
    }

    GetLiveWatchResume = async () => {
        const url = this.endpoint + `/api/LiveWatch/GetLiveWatchResume`;
        return (await api({
            method: 'get',
            url,
        })).data; 
    };

    GetService(id: any, serviceId: any) {
        const url = this.endpoint + `/api/ServiceGroups/${id}/GetService/${serviceId}`;
        return api({
            method: 'get',
            url,
        });
    }

    DeleteMonitoringTemplate(id: any) {
        const url = this.endpoint + `/api/ServiceGroups/DeleteMonitoringTemplate/${id}`;
        return api({
            method: 'delete',
            url,
        });
    }

    SalvarMonitoringTemplate(Template: any) {
        const url = `${this.endpoint}/api/ServiceGroups/SalvarMonitoringTemplate`;
        var response = api({
            method: 'post',
            url,
            data: Template,
        });
        return response;
    }

    SalvarService(service: any) {
        const url = `${this.endpoint}/api/ServiceGroups/SalvarService`;
        var response = api({
            method: 'post',
            url,
            data: service,
        });
        return response;
    }

    CreateServiceGroup(serviceGroup: any) {
        const url = `${this.endpoint}/api/ServiceGroups/ServiceGroup`;
        var response = api({
            method: 'post',
            url,
            data: serviceGroup,
        });
        return response;
    }

    DeleteService(id: any) {
        const url = `${this.endpoint}/api/ServiceGroups/DeleteService/${id}`;
        var response = api({
            method: 'delete',
            url
        });
        return response;
    }

    Editar(ServiceGroup: any) {
        const url = `${this.endpoint}/api/ServiceGroups/Editar`;
        var response = api({
            method: 'post',
            url,
            data: ServiceGroup
        });
        return response;
    }

    Deletar(id: any) {
        const url = `${this.endpoint}/api/ServiceGroups/Deletar?id=${id}`;
        var response = api({
            method: 'delete',
            url
        });
        return response;
    }

    public emptyRecord: any = {
        id: 0,
        name: "",
        description: "",
        services: null
    };

    public async template(record: any, disabled: boolean) {

        let item = {
            title: 'name',
            Fields: [{
                edit: true,
                row: 1,
                col: 1,
                colspan: 1,
                rowspan: 1,
                name: 'name',
                type: 'text',
                title: 'Nome',
                required: {

                    value: true,
                    required: 'Nome é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            }, {
                edit: true,
                row: 1,
                col: 2,
                colspan: 1,
                rowspan: 1,
                name: 'description',
                type: 'text',
                title: 'Descrição',
                required: {

                    value: true,
                    required: 'Descrição é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            }]
        };

        return item;
    }
}