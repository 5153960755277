export class livewatch {
    solution() {
        return 'livewatch'
    }

    site() {
        return 'https://www.processor.com.br/livewatch'
    }    

    logotipo() {
        return require('./assets/logotipos/logo_livewatch.svg').default
    }
    titulo() {
        return <>Descubra o poder do <b>monitoramento</b> com LiveWatch</>
    }

    chamada() {
        return <>Com o LiveWatch, a organização ganha uma plataforma de gerenciamento e monitoramento com pluralidade tecnológica. Isso significa monitoramento proativo e integrado dos ambientes, garantindo alta disponibilidade e prevenção a falhas.</>
    }

    image() {
        return require('./assets/imgs/imagem_livewatch.png').default
    }

    detalhes() {
        return <>
            <h2>Imagine um mundo onde a tecnologia trabalha para a organização, não contra ela. Onde cada aplicação, cada servidor, cada dispositivo está sob controle.</h2>
            <p><b>Esse é o mundo que o LiveWatch pode criar.</b></p>
            <h3>Por que escolher LiveWatch?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_livewatch.png').default} />
                </div>
                <ul>
                    <li><b>Suporte:</b> LiveWatch oferece suporte multidisciplinar e multilíngue, com alto expertise nas principais tecnologias do mercado. Isso significa que a organização sempre terá alguém para contar quando precisar.</li>
                    <li><b>Flexibilidade:</b> Com a flexibilidade de níveis de entrega e opções de contratação, LiveWatch possibilita respostas eficazes, simplifica a operação, otimizando custos e aumenta a disponibilidade dos recursos.</li>
                    <li><b>Decisão:</b> Com reportes periódicos e em tempo real para tomada de decisão, LiveWatch permite que a organização esteja sempre um passo à frente. É a informação certa, na hora certa, para a decisão certa.</li>
                    <li><b>Contingenciamento:</b> LiveWatch oferece contingenciamento de ambientes, softwares e aplicações de negócios. Isso significa que, mesmo nos momentos mais críticos, a organização pode contar com LiveWatch.</li>
                </ul>
            </div>
            <div className="box">LiveWatch possui flexibilidade de níveis de entrega e opções de contratação, possibilita respostas eficazes, simplifica a operação, otimizando custos e aumentando a disponibilidade dos recursos.</div>
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato para obter mais informações sobre como LiveWatch pode transformar a forma como a organização gerencia a tecnologia.</p>            
        </>
    }
}
export default livewatch;