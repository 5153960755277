import React, { useEffect, useState } from 'react';

//Components
import Layout from '../../../components/Layout/Layout';

 

import history from '../../../history';

//Img Fluxo
import ImgFluxo from '../../../assets/images/Fluxos/eaeas.png';

//CSS
import './index.css';

//Service
import { TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import PivotTableWebix from '../../../components/webix';

import Moment from 'moment';

const SubscriptionBycountryNoCCPivot: React.FC = (props) => {

    let instance = new TechEAEASService(props);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>([]);

    const user = useSelector<RootState, UserState>(state => state.user);

    const [state, setState] = useState<object>();

    const getData = () => {
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));

        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        setLoading(true);
        user && instance.GetSaasLicenseByCountryTotalView()
            .then(response => {
                setDataPivot(response.data.map((item: any) => {
                    return {
                        id: item.id,
                        'País': item.country,
                        'Centro de custo': item.costCenter,
                        Software: item.license,
                        Contratado: item.totalHired,
                        Usado: item.totalUsed,
                        Total: item.totalCC
                    }
                }))
            })
            .catch(error => {
                console.debug(error)
            })
            .then(() => {
                setLoading(false);
            });
    }

    useEffect(getData, [user.ClientGroupSelected, user.refreshFlag]);
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value; 
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            } 
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    return (
        <Layout pageTitle="Dashboard" pivot periodSelection={periodSelection}>

            <LcLoading label="Carregando..." loading={loading}>
                {
                    false && dataPivot.length == 0 ?
                        <div className="lc-segment">
                            <div className="body">
                                Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados');  dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                                <img src={ImgFluxo} width="100%" alt="Procedimento" />
                            </div>
                        </div>
                        :
                        <PivotTableWebix
                           mode='tree'
                                idView={'dataPivot'}
                                key={'dataPivot'} data={dataPivot} 
                                structure={{
                                    rows: ['Software'],
                                    columns: ['País'],
                                    values: [{ name: "Contratado", operation: ["max"] }],
                                    filters: [{ name: "País" }, { name: "Centro de custo" },  { name: "Software" }]
                                }} 
                            /> 
                        
                }
            </LcLoading>

        </Layout >
    );
}

export default SubscriptionBycountryNoCCPivot;