import React, { useRef, useState } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import JSONDigger from "json-digger";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import "./org.css";
import { useSnackbar } from "notistack";

import SideModal from "../../../../components/Layout/SideModal/SideModal";
import { InputField } from "../../../../components/Form/Input";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import { async } from "q";

import DialogConfirmForm from "../../../../components/Layout/Dialog/DialogConfirmForm";
import { PPModal } from 'processor-plataform-ui';

const EditChart = (props) => {
  //Manipulação de dados do chart via JSon Digger
  //https://github.com/dabeng/json-digger/blob/master/test/index.js

  const clientGroupId = props.clientGroupId;

  const orgchart = useRef();

  const service = props.service;

  const [ds, setDS] = useState(props.data);

  const dsDigger = new JSONDigger(ds, "id", "children");

  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [newNodes, setNewNodes] = useState([
    {
      id: uuidv4(),
      name: "",
      baseline: 0,
      nodeContent: "",
      tag: "",
      baselineUndefined: false,
      budget: 0,
      qtdSubsctiption: 0,
      children: [],
      clientGroupId: clientGroupId,
    },
  ]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [currentNode, setCurrentNode] = useState();
  const [currentNodeTitle, setCurrentNodeTitle] = useState();
  const [newName, setNewName] = useState();
  const [typeSideModal, setTypeSideModal] = useState();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [qtdSubsctiption, setQtdSubsctiption] = useState(0);
  const [newQtdSubsctiption, setNewQtdSubsctiption] = useState(0);

  const [currentNodeName, setCurrentNodeName] = useState();
  const [currentNodeQtdSubscripton, setCurrentNodeSubscription] = useState();

  const readSelectedNode = async (nodeData) => {
    setCurrentNode(nodeData);
    setCurrentNodeName(nodeData.name);
    setCurrentNodeSubscription(nodeData.qtdSubscription);

    let node = new Set([nodeData]);
    await setSelectedNodes(node);
  };

  const clearSelectedNode = () => {
    setSelectedNodes(new Set());
  };

  const getNewNodes = () => {
    const nodes = [];
    for (const node of newNodes) {
      nodes.push({
        ...node,
        id: uuidv4(),
        name: newName,
        baseline: 0,
        nodeContent: "",
        tag: newName,
        baselineUndefined: false,
        budget: 0,
        qtdSubsctiption: 0,
        children: [],
        clientGroupId: clientGroupId,
      });
    }
    return nodes;
  };

  const addChildNodes = async () => {
    let param = [...selectedNodes][0].id;
    let allNodes = getNewNodes();
    await dsDigger.addChildren(param, allNodes);

    await dsDigger.findParent(allNodes[0].id).then((_r) => {
      const parentNode = _r;
      const node = allNodes[0];

      let costCenterSend = {
        ID: 0,
        Parent: parentNode.id,
        Main: false,
        Name: node.name,
        ParentName: parentNode.name,
        Budget: node.budget,
        QtdSubscription: newQtdSubsctiption,
        ClientGroupId: clientGroupId,
      };
      service
        .UpdateCostCenter(costCenterSend)
        .then((result) => {
          if (result.status == 200) {
            props.updateData();
            enqueueSnackbar(
              "A atualização do centro de custo foi realizada com sucesso.",
              {
                variant: "success",
                preventDuplicate: true,
                persist: false,
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Erro ao atualizar centro de custo.", {
            variant: "error",
            preventDuplicate: true,
            persist: false,
          });
        });
    });

    setDS({ ...dsDigger.ds });
  };
  const editNode = async () => {
    let node = currentNode;
    node.name = currentNodeName;
    setIsVisibleModal(!isVisibleModal);
    setCurrentNodeTitle("");
    setTypeSideModal("");

    await dsDigger
      .findParent(node.id)
      .then((result) => {
        const parentNode = result;
        let costCenterSend;
        if (parentNode && node) {
          costCenterSend = {
            ID: node.id,
            Parent: parentNode.id,
            Main: false,
            Name: node.name,
            ParentName: parentNode.name,
            Budget: node.budget,
            QtdSubscription: currentNodeQtdSubscripton,
            ClientGroupId: clientGroupId,
          };

          service
            .UpdateCostCenter(costCenterSend)
            .then((result) => {
              if (result.status == 200) {
                props.updateData();
                enqueueSnackbar(
                  "A atualização da árvore foi realizada com sucesso.",
                  {
                    variant: "success",
                    preventDuplicate: true,
                    persist: false,
                  }
                );
              }
            })
            .catch((error) => {
              console.log(error);
              enqueueSnackbar("Erro ao atualizar centro de custo.", {
                variant: "error",
                preventDuplicate: true,
                persist: false,
              });
            });
        }
      })
      .catch((e) => {
        //Não tem parent, logo é root node
        let costCenterSend;
        costCenterSend = {
          ID: node.id,
          Parent: 0,
          Main: false,
          Name: node.name,
          ParentName: "",
          Budget: node.budget,
          qtdSubsctiption: currentNodeQtdSubscripton,
          ClientGroupId: clientGroupId,
        };

        service
          .UpdateCostCenter(costCenterSend)
          .then((result) => {
            if (result.status == 200) {
              props.updateData();
              enqueueSnackbar(
                "A atualização do centro de custo foi realizada com sucesso.",
                {
                  variant: "success",
                  preventDuplicate: true,
                  persist: false,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar("Erro ao atualizar centro de custo.", {
              variant: "error",
              preventDuplicate: true,
              persist: false,
            });
          });
      });
  };

  const onCloseSideModal = () => {
    setIsVisibleModal(!isVisibleModal);
    setTypeSideModal("");
  };

  const propTypes = {
    nodeData: PropTypes.object.isRequired,
  };

  const deleteCostCenter = (confirm) => {
    enqueueSnackbar(
      "A exclusão do centro de custo foi iniciada, aguarde a atualização dos dados",
      {
        variant: "success",
        preventDuplicate: true,
        persist: false,
      }
    );

    if (confirm == true) {
      if (currentNode) {
        service
          .DeleteCostCenter(currentNode.id)
          .then((result) => {
            if (result.status == 200) {
              props.updateData();
              enqueueSnackbar(
                "A deleção do centro de custo foi realizada com sucesso.",
                {
                  variant: "success",
                  preventDuplicate: true,
                  persist: false,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar("Erro ao deletar centro de custo.", {
              variant: "error",
              preventDuplicate: true,
              persist: false,
            });
          });
      }
    }
    setOpen(false);
  };

  const node = ({ nodeData }) => {
    const edit = () => {
      setTypeSideModal("edit");
      readSelectedNode(nodeData);
      setIsVisibleModal(!isVisibleModal);
    };
    const add = () => {
      setTypeSideModal("create");
      readSelectedNode(nodeData);
      setIsVisibleModal(!isVisibleModal);
    };

    const _remove = () => {
      setOpen(true);
      readSelectedNode(nodeData);
    };

    return (
      <>
        <div className="nodeContainer">
          <div className="fullname">
            <p>{nodeData.name}</p>
          </div>

          <div className="footerNode">
            <LcIconLink
              icon="ppi ppi-plus"
              tooltip="Adicionar novo centro de custo"
              onClick={add}
            ></LcIconLink>
            <LcIconLink
              icon="ppi ppi-pencil"
              tooltip="Editar centro de custo"
              onClick={edit}
            ></LcIconLink>
            <LcIconLink
              icon="lci lci-trash"
              tooltip="Deletar centro de custo"
              onClick={_remove}
            ></LcIconLink>
          </div>
        </div>
      </>
    );
  };
  node.propTypes = propTypes;

  const onChangeInput = (field) => {
    setCurrentNodeTitle(field.value);
    setCurrentNodeName(field.value);
  };

  const onChangeInputNewName = (field) => {
    setNewName(field.value);
  };

  const updateCostCenter = () => {
    editNode();
  };

  const createCostCenter = () => {
    enqueueSnackbar(
      "A criação do centro de custo foi iniciada, aguarde a atualização dos dados",
      {
        variant: "success",
        preventDuplicate: true,
        persist: false,
      }
    );

    addChildNodes();
    setIsVisibleModal(!isVisibleModal);
    setTypeSideModal(null);
    setNewName("");
  };

  const onChangeQtdSubscription = (field) => {
    let fieldValue = field.value.replace(/[^0-9]/g, "");
    setQtdSubsctiption(fieldValue);
    setCurrentNodeSubscription(fieldValue);
  };
  const onChangeNewQtdSubscription = (field) => {
    let fieldValue = field.value.replace(/[^0-9]/g, "");
    setNewQtdSubsctiption(fieldValue);
  };

  return (
    <>
      <DialogConfirmForm
        onCloseEvent={deleteCostCenter}
        open={open}
        confirmMessage="Sim, desejo deletar"
        abortMessage="Não, não desejo deletar."
        title="Deseja realmente deletar este centro de custo?"
        content="Está operação não pode ser revertida caso confirme a deleção. Tenha convicção para deletar o centro de custo selecionado"
      ></DialogConfirmForm>

      <OrganizationChart
        datasource={ds}
        NodeTemplate={node}
        chartClass="myChart"
        onClickNode={readSelectedNode}
        onClickChart={clearSelectedNode}
      />

      <PPModal
        title={
          typeSideModal == "edit"
            ? "Editar cento de custo"
            : "Criar cento de custo"
        }
        onClose={onCloseSideModal}
        visible={isVisibleModal}
        functions={[
          {
            label: typeSideModal == "edit" ? "Salvar" : "Criar",
            onClick:
              typeSideModal == "edit" ? updateCostCenter : createCostCenter,
          },
        ]}
      >
        {typeSideModal == "edit" && (
          <div className="form">
            <p className="inputLabelName">Nome do centro de custo</p>
            <InputField
              updateParentConst_
              value={currentNodeName}
              name="nodeName"
              type="text"
              icon="ppi ppi-pencil"
              onChange={onChangeInput}
              placeHolder="Insira um novo nome ao centro de custo"
            ></InputField>

            <p className="inputLabelName">Quantidade de subscrições</p>
            <InputField
              updateParentConst
              value={currentNodeQtdSubscripton}
              name="qtdSubscription"
              type="text"
              icon="ppi ppi-pencil"
              placeHolder="Digite apenas números"
              onChange={onChangeQtdSubscription}
            />
          </div>
        )}

        {typeSideModal == "create" && (
          <div className="form">
            <p className="inputLabelName">Nome do centro de custo</p>
            <InputField
              updateParentConst
              name="newNodeName"
              type="text"
              icon="ppi ppi-pencil"
              value={newName}
              onChange={onChangeInputNewName}
            />

            <p className="inputLabelName">Quantidade de subscrições</p>
            <InputField
              updateParentConst
              value={newQtdSubsctiption}
              name="newQtdSubscription"
              type="text"
              icon="ppi ppi-pencil"
              onChange={onChangeNewQtdSubscription}
            />
          </div>
        )}
      </PPModal>
    </>
  );
};

export default EditChart;
