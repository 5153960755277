import axios from 'axios';
import api, { APIClient } from '../../api';
import { Base64File, FileSearch, VectorStoreFileListResponse } from '../assistantsTypes';



export default class FileSearchService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    public async createVectorStore(fileSearch: FileSearch): Promise<any> {
        var url = `${this.endpoint}/api/file_search/vector_stores?refresh=${Math.random()}`;
        const response = await api.post(url, fileSearch);
        console.log(response.data)
        return response.data;
    }

    public async uploadBase64File(assistant_id: string, vectorStoreId: string, base64File: Base64File): Promise<any> {
        var url = `${this.endpoint}/api/file_search/assistant/${assistant_id}/vector_stores/${vectorStoreId}/files?refresh=${Math.random()}`;
        console.log(url)
        const response = await api.post(url, base64File);
        console.log(response.data)
        return response.data;
    }
    public async deleteBase64File(vector_store_id: string, file_id: string): Promise<any> {
        var url = `${this.endpoint}/api/file_search/vector_stores/${vector_store_id}/files/${file_id}?refresh=${Math.random()}`;
        console.log(url)
        const response = await api.delete(url);
        console.log(response.data)
        return response.data;
    }

    public async getFileSearchFiles(vector_store_id: string): Promise<any> {
        try {
            var url = `${this.baseUrl}/api/file_search/vector_stores/${vector_store_id}/list_files`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            }); 
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var responseNew = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(responseNew);
            console.log('paas GetOpenAIModels');
            // const response = await api.get();
            console.log(responseNew.data);
            console.log(responseNew.data);
            console.log(responseNew.data)
            console.log(responseNew.data.files)
            return responseNew.data;
            //var url = `${this.endpoint}/api/file_search/vector_stores/${vector_store_id}/list_files?refresh=${Math.random()}`;
            //console.log(url)
            //const response = await api.get(url);
            //console.log(response.data)
            //console.log(response.data.files)
            //return response.data;
        } catch (e) {
           // debugger;
            return [];
        }

    }
}
