import { PPLocalStorage as lh } from "processor-plataform-ui";

export interface PrefState {
    data: any
}

type Action = { type: string, payload: any }

export const prefReducer = (state: PrefState = { data: {} }, action: Action) => {
    switch (action.type) {
        case 'PREF_LOAD':
            return { ...state, data: action.payload };
        case 'PREF_UPDATE':

            const { name, value } = action.payload;
            let _data = state.data || {};
            _data[name] = value;

            //Register LH
            lh.init("userPreferences");
            let _lh = lh.load() || {};

            _lh[document.location.pathname] = _data;
            lh.save(_lh);

            return { ...state, data: _data };

        default:
            return state;
    }
}