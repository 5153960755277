import api, { APIClient } from "../api";

export interface StorageAccountItem {
    id: string;
    isAccessAllowedFromAllNetworks: boolean;
    name: string;
    regionName: string;
    resourceGroupName: string;
    ipAddressesWithAccess: IpRule[]
}

interface IpRule {
    ipAddressOrRange: string;
    action: Number | null;
}

export class RetencaoService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetCards() {
        const url = this.endpoint + `/api/Retencao/Consumo`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetTree(nextMarker: string) {
        const url = this.endpoint + `/api/Retencao/GetContainersByLevel?nextMarker=${nextMarker}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetTable() {
        const url = this.endpoint + `/api/Retencao/SASTableAjaxHandler`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetTreeWithToken(containerName: string, prefix: string, nextMarker: string | null) {
        const url = this.endpoint + `/api/Retencao/GetBlobsByLevelAndContinuationToken`;
        const data = JSON.stringify({
            containerName: containerName,
            prefix: prefix,
            nextMarker: nextMarker
        });
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }
    async DownloadFile() {
        const timestamp = new Date().getTime();
        const url = this.endpoint + `/api/Retencao/DownloadFile?t=${timestamp}`;
        return api({
            method: 'get',
            url,
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf',
            }
        });
    }
    async CreateAccessKey() {
        const url = this.endpoint + `/api/Retencao/Criar`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetStorageAccount() {
        const url = this.endpoint + `/api/StorageAccount/RetrieveList`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetStorageAccountIpsAlias(storageAccountId: string) {
        const url = this.endpoint + `/api/StorageAccount/IpAddressesWithAccess?storageId=${storageAccountId}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async PostAzureStorageAccount(storageAccount: any) {
        const url = this.endpoint + `/api/StorageAccount/AzureIpAddressesWithAccess`;
        const data = JSON.stringify(storageAccount);
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    async PostStorageAccount(ips: any) {
        const url = this.endpoint + `/api/StorageAccount/IpAddressesWithAccess`;
        const data = JSON.stringify(ips);
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

}