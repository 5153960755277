import React, { useEffect, useMemo, useState } from 'react';
import { SiAmazonaws, SiMicrosoftazure } from 'react-icons/si';
import './index.css'; // Make sure to create a corresponding CSS file
import { CmsPremierService, DatabaseItem } from '../../../../services/cmsPremier/cmsPremierService';
import LcLoading from '../../../../components/Generic/LcLoading';
import { strict } from 'yargs';
import { TbFilePower } from 'react-icons/tb';

interface DatabaseListProps {
    focus: any
}
const DatabaseList: React.FC<DatabaseListProps> = (props) => {
    const [databases, setDatabases] = useState<DatabaseItem[]>();
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        // Fetch your data here
        if (props.focus) {
            setLoading(true);
            const fetchData = async () => {
                try {
                    //debugger;
                    const CmsService = new CmsPremierService(props);
                    const data = await CmsService.getDatabases();
                    //debugger;
                    setDatabases(data);
                } catch (e) {
                    //debugger;
                }

                setLoading(false);
            };
            fetchData();
        }
    }, [props.focus]);

    const toggleSort = (column: string) => {
        setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
        setSortColumn(column);
    };
    const getIcon=(provider :string) =>
    {
        if (provider =='AWS') {
            return <SiAmazonaws></SiAmazonaws>;
          
        }
        if (provider == 'Azure') {
            return <SiMicrosoftazure></SiMicrosoftazure>
        }
        return <div>N/A</div>
    }
    const sortedDatabases = useMemo(() => {
        if (!sortColumn) {
            return databases;
        }
        if (!databases) {
            return [];
        }
        return [...databases].sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
            if (aValue < bValue) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [databases, sortColumn, sortDirection]);

    const renderSortIcon = (column: string) => {
        if (sortColumn !== column) {
            return null;
        }
        return   <i className={`ppi ppi-arrow-${sortDirection != 'asc' ? 'down' : 'up'} text-warning`} /> ;
    };

    const extractServerName = (inputPath) => {
        if (!inputPath) {
         
            return '';
        }

        const parts = inputPath.split('/');
        const databasesIndex = parts.indexOf('databases');

        if (databasesIndex === -1 || databasesIndex === 0) {
        
            return inputPath;
        }

    return (parts[databasesIndex - 1]);
    }
 
    return (<LcLoading loading={loading}>
        {databases && <div className="database-list scrollable-v">
            <div className=" database-list-header finops-row-title"> 
                <div className={'halfColumn'} onClick={() => toggleSort('name')}>Name {renderSortIcon('name')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('resourceId')}>Id Recurso {renderSortIcon('resourceId')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('resourceId')}>Servidor {renderSortIcon('resourceId')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('engine')}>Tipo {renderSortIcon('engine')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('status')}>Situação {renderSortIcon('status')}</div>
                {/*<div onClick={() => toggleSort('createdTime')}>Created Time {renderSortIcon('createdTime')}</div>*/}
                {/*<div onClick={() => toggleSort('endpointAddress')}>Endpoint Address {renderSortIcon('endpointAddress')}</div>*/}
                {/*<div onClick={() => toggleSort('endpointPort')}>Endpoint Port {renderSortIcon('endpointPort')}</div>*/}
                {/*<div onClick={() => toggleSort('allocatedStorage')}>Allocated Storage {renderSortIcon('allocatedStorage')}</div>*/}
                {/*<div onClick={() => toggleSort('backupRetentionPeriod')}>Backup Retention {renderSortIcon('backupRetentionPeriod')}</div>*/}
                {/*<div onClick={() => toggleSort('availabilityZone')}>Availability Zone {renderSortIcon('availabilityZone')}</div>*/}
                {/*<div onClick={() => toggleSort('multiAZ')}>Multi-AZ {renderSortIcon('multiAZ')}</div>*/}
                <div className={'halfColumn'} onClick={() => toggleSort('storageType')}>Tipo Storage{renderSortIcon('storageType')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('region')}>Região {renderSortIcon('region')}</div>
                <div className={'halfColumn'} onClick={() => toggleSort('provider')}>Cloud {renderSortIcon('provider')}</div>
            </div>
            <div className="database-list-body">
                {sortedDatabases && sortedDatabases.map((db, index) => (
                    <div key={index} className="database-item  finops-row">  
                        <div className={'row halfColumn'} title={db.name}>{db.name}</div>
                        <div className={'row halfColumn'} title={db.resourceId}>{db.resourceId}</div>
                        <div className={'row halfColumn'} title={extractServerName(db.resourceId)}>{extractServerName(db.resourceId)}</div>
                        <div className={'halfColumn'} title={db.engine}>{db.engine}</div>
                        <div className={'halfColumn'} title={db.status}>{db.status}</div>
                        {/*<div>{db.createdTime}</div>*/}
                        {/*<div>{db.endpointAddress}</div>*/}
                        {/*<div>{db.endpointPort}</div>*/}
                        {/*<div>{db.allocatedStorage}</div>*/}
                        {/*<div>{db.backupRetentionPeriod}</div>*/}
                        {/*<div>{db.availabilityZone}</div>*/}
                        {/*<div>{db.multiAZ ? 'Yes' : 'No'}</div>*/}
                        <div className={'halfColumn'} >{db.storageType}</div>
                        <div className={'halfColumn'} >{db.region}</div>
                        <div className={'halfColumn'} style={{ fontSize:'2vh' }}>{getIcon(db.provider)}</div>
                    </div>
                ))}
            </div>
        </div>}
    </LcLoading>
    );
};

export default DatabaseList;
