import React, { useState } from "react";

import { /*PPDropDown,*/ PPGrid, PPInput, PPModal, PPTextField } from "processor-plataform-ui";
import PPDropDown from "../../../../components/PPDropDown";
import Confirmation from "../../../../components/Dialog/Confirmation";
import LcIconLink from "../../../../components/Generic/LcIconLink";

import FinOpsMapManagerService, { FinOpsAllocationRule, FinOpsAllocationRuleCondition } from '../../../../services/finOpsManagerService';

import moment from "moment";

interface Props {
    onClose: Function,
    conditions?: FinOpsAllocationRuleCondition[],
    visible: boolean,
    onSubmit: Function
}

interface RuleValidade {
    name: string;
    validateMessage: string;
    valid: boolean;
    costCenterId: number;
    conditionId: number;
}

const ConditionFilter: React.FC<Props> = (props) => {

    var finopsMapService = new FinOpsMapManagerService(props);

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 0,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: '',
    };

    const [conditions, setConditions] = useState(props.conditions || [emptyRecordCondition]);

    const [validations, setValidations] = useState<RuleValidade[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false)

    const jsonFinOpsLogicOperator = [
        {
            label: "e",
            value: "and"
        },
        {
            label: "ou",
            value: "or"
        }
    ];
    const jsonFinOpsLCFilter = [
        {
            label: "Provedor",
            value: "ProviderName"
        },
        {
            label: "Id da conta",
            value: "AccountId"
        },
        {
            label: "Nome da Conta",
            value: "AccountName"
        },
        {
            label: "Id do recurso",
            value: "ResourceID"
        },
        {
            label: "Nome do Recurso",
            value: "ResourceName"
        },
        {
            label: "Região",
            value: "Region"
        },
        {
            label: "Serviço",
            value: "Service"
        },
        {
            label: "Categoria",
            value: "MeterCategory"
        },
        {
            label: "Subcategoria",
            value: "MeterSubCategory"
        },
        {
            label: "Grupo do recurso",
            value: "ResourceGroup"
        },
        {
            label: "Tags",
            value: "Tags"
        },
        {
            label: "Consumo",
            value: "ConsumedQuantity"
        },
        {
            label: "Informação adicional do recurso",
            value: "ResourceAdditionalInfo"
        },
        {
            label: "Custo",
            value: "MeteredCost"
        },
        {
            label: "Tipo de publicação",
            value: "PublisherType"
        },
        {
            label: "Número Contrato",
            value: "ContractId"
        },
        {
            label: "Centro de Custo",
            value: "CostCenterDescription"
        }
    ];
    const jsonFinOpsOperation = [
        {
            "label": "contém",
            "value": "contains"
        },
        {
            "label": "é igual a",
            "value": "equal"
        },
        {
            "label": "inicia com",
            "value": "startWith"
        },
        {
            "label": "diferente",
            "value": "notEqual"
        },
        {
            "label": "não contém",
            "value": "notContains"
        }
    ];

    function toCamelCase(str) {
        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }

    async function getFieldAutocompleteList(fieldName: string, fieldFilter: any) {
        var month = moment().month() + 1;
        var year = moment().year();
        var result = await finopsMapService.GetFieldOptionsResume(month, year, fieldName, fieldFilter);
        return result.slice(0, 50).map((item) => { return item[toCamelCase(fieldName)] });
    };

    function validateRule() {

        var validationsChecks: RuleValidade[] = [];

        conditions.forEach(itemCondition => {

            if (itemCondition.idCondition > 0 && itemCondition.field == '') {
                validationsChecks.push({
                    name: 'field',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }

            if (itemCondition.idCondition > 0 && itemCondition.logicOperator == '') {
                validationsChecks.push({
                    name: 'logicOperator',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }
            if (itemCondition.idCondition > 0 && itemCondition.operation == '') {
                validationsChecks.push({
                    name: 'operation',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }

            if (itemCondition.idCondition > 0 && itemCondition.filter == '')
                validationsChecks.push({
                    name: 'filter',
                    validateMessage: 'Você deve preencher este valor',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
        });

        setValidations(validationsChecks);
        return validationsChecks.length == 0;
    }

    const handleChange = (event: any, condition: FinOpsAllocationRuleCondition) => {
        const { name, value } = event;
        setConditions(conditions.map(c => { return c.idCondition != condition.idCondition ? c : { ...c, [name]: value } }));
    }


    function removeCondition(item) {
        if (item.idCondition == 0)
            setConditions([{ idCondition: 0, field: '', operation: 'contains', filter: '', logicOperator: '' }]);
        else
            setConditions(conditions.filter(c => c.idCondition != item.idCondition).map((c, i) => { return { ...c, idCondition: i } }));
    }

    function onSubmit() {
        let validated = validateRule();
        validated &&
            props.onSubmit(conditions);
    }

    const getErrorData = (conditionId, name) => {
        let data = validations.find(x => x.conditionId == conditionId && x.name == name);
        return data ? { show: true, help: data.validateMessage } : undefined;
    }

    return (
        <PPModal
            title='Filtro'
            visible={props.visible}
            size="large"
            onClose={() => { props.onClose(false) }}
        >
            <div className="mb-5 scrollable-v" style={{ minHeight: '300px' }}>
                <h5>Condições</h5>
                {
                    conditions && conditions
                        .sort((a, b) => a.idCondition > b.idCondition ? 1 : -1)
                        .map((itemCondition, index) => {
                            return (
                                <PPGrid key={index} container spacing={2}>

                                    <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        {
                                            index > 0 &&
                                            <PPDropDown
                                                required={true}
                                                error={getErrorData(itemCondition.idCondition, 'logicOperator')}
                                                openTrigger="click"
                                                name="logicOperator"
                                                /*value={itemCondition.logicOperator}*/
                                                value={jsonFinOpsLogicOperator.find(option => option.value == itemCondition.logicOperator)}
                                                options={jsonFinOpsLogicOperator}
                                                /*onChange={(event) => { handleChange(event, itemCondition); }}*/
                                                onChange={(event) => handleChange({ ...event, name: 'logicOperator' }, itemCondition)}
                                                hideFilter
                                            />
                                        }
                                    </PPGrid>

                                    <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <PPDropDown
                                            name="field"
                                            /*value={itemCondition.field}*/
                                            error={getErrorData(itemCondition.idCondition, 'field')}
                                            openTrigger="click"
                                            options={jsonFinOpsLCFilter}
                                            placeholder='(Critério)'
                                            value={jsonFinOpsLCFilter.find(option => option.value == itemCondition.field)}
                                            /*onChange={(event) => { handleChange(event, itemCondition); }}*/
                                            onChange={(event) => handleChange({ ...event, name: 'field' }, itemCondition)}
                                            required
                                        />
                                    </PPGrid>

                                    <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <PPDropDown
                                            openTrigger="click"
                                            name="operation"
                                            error={getErrorData(itemCondition.idCondition, 'operation')}
                                            /*value={itemCondition.operation}*/

                                            value={jsonFinOpsOperation.find(option => option.value == itemCondition.operation)}

                                            options={jsonFinOpsOperation}
                                            /*onChange={(event) => { handleChange(event, itemCondition); }}*/
                                            onChange={(event) => handleChange({ ...event, name: 'operation' }, itemCondition)}
                                            placeholder='(Selecione)'
                                            required
                                            hideFilter
                                        />
                                    </PPGrid>

                                    <PPGrid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                        <PPInput
                                            type="text"
                                            value={itemCondition.filter}
                                            name="filter"
                                            placeholder="(Termo)"
                                            /*onChange={(event) => { handleChange(event, itemCondition); }}*/
                                            onChange={(event) => handleChange({ ...event, name: 'filter' }, itemCondition)}
                                            error={{
                                                help: validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name)?.validateMessage,
                                                show: validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name) != undefined
                                            }}
                                            required
                                            suggestions={(event) => getFieldAutocompleteList(itemCondition.field, event) as any}
                                        />
                                    </PPGrid>

                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <div className="row">
                                            <LcIconLink
                                                size={'medium'}
                                                tooltipPosition={'right'}
                                                icon={`ppi ppi-trash `}
                                                disabled={itemCondition.idCondition == 0 && conditions.length > 1}
                                                onClick={() => { removeCondition(itemCondition) }}
                                            />
                                            <LcIconLink
                                                size={'medium'}
                                                tooltipPosition={'right'}
                                                icon={`ppi ppi-plus `}
                                                onClick={() => { setConditions([...conditions, { ...emptyRecordCondition, logicOperator: 'and', idCondition: conditions.length }]) }}
                                                tooltip="Adicionar Condição"
                                                disabled={index < conditions.length - 1}
                                            />
                                        </div>
                                    </PPGrid>

                                </PPGrid>
                            )
                        })
                }
            </div>

            <div className="row between">
                <div className="row">
                    <button className="lc-button bg-primary" type="button" onClick={() => onSubmit()}>Ok</button>
                </div>
                <div className="row">
                    <button className="lc-button bg-gray" type="button"
                        onClick={() => {
                            setConditions([{ idCondition: 0, field: '', operation: 'contains', filter: '', logicOperator: '' }]);
                        }}>Limpar</button>
                    <button className="lc-button bg-gray" type="button" onClick={() => { props.onClose(false) }}>Cancelar</button>
                </div>
            </div>

            <Confirmation
                title="Confirme"
                text='Tem certeza que deseja perder as alterações feitas?'
                confirm={() => { setConfirmVisible(false); props.onClose(false) }}
                close={() => { setConfirmVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmVisible}
            />

        </PPModal >

    )
};

export default ConditionFilter;