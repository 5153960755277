import React from "react";

import "./index.css";
import { Doughnut } from "react-chartjs-2";

interface Props {
  data: {
    title: string,
    labels: string[],
    values: number[]
  },
  formatNumber?: boolean,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
  fullHeigth?: boolean,
  spaceBetween ?:  'small' | 'medium' | 'large' ,
  cssClass?: string
}

const DoughnutChart: React.FC<Props> = (props) => {

  const colors = [
    "#5969CD", "#E273C5", "#804E3B", "#A5Bf3E", "#8684EF", "#F3C944", "#59BAC5",
    "#D5672F", "#307422", "#169D36", "#E74A67", "#C23D4F", "#D7BA45", "#B24F5D"
  ];

  var data = {
    labels: props.data.labels,
    datasets: [
      {
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: props.data.values.map(v => { return v + Number.EPSILON }),
        borderWidth: 3
      },
    ],
  };

  const maximumFractionDigits = props.maximumFractionDigits == null ? 2 : props.maximumFractionDigits;
  const minimumFractionDigits = props.minimumFractionDigits == null ? 0 : props.minimumFractionDigits;
  var totalValue = 0;
  props.data.values.map(v => (totalValue += v + Number.EPSILON));
  var formattedValue: string = '';
  formattedValue = totalValue.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits});
  return (
    <div className={props.cssClass || 'doughnut-chart'} style={{ height: props.fullHeigth ? '100%' : 'fit-content' }}>
      <div className="title">{props.data.title}</div>

      <div className={`chart`}>
        {
          props.data.values.length > 0
            ?
            <>
              <div className={`canvas ${props.spaceBetween ? `spacing-canvas-${props.spaceBetween}` : ''}`}>
                <div className="number">
                  <span>{formattedValue}</span>
                </div>
                <div className="body">
                  <Doughnut
                    data={data}
                    options={{
                      legend: {
                        display: false,
                      },
                      cutoutPercentage: 65,
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem: any, data: any) {
                            return data.datasets[0].data[tooltipItem.index].toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits });
                          },
                          title: function (tooltipItem: any, data: any) {
                            return data.labels[tooltipItem[0].index];
                          },
                        }
                      },
                    }}
                    width={160}
                    height={160}
                  />
                </div>
              </div>
              <div className={`legend ${props.spaceBetween ? `spacing-legend-${props.spaceBetween}` : ''}`}>
                <div className="body">
                  {props.data.values &&
                    props.data.values.map((item, index) => {

                      let value: string = "";
                      value = item.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits });
                      return (
                        <div className="item" key={index}>
                          <span className="badge" style={{ background: colors[index] }}>
                            {value}
                          </span>
                          <span className="name">{props.data.labels[index]}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
            :
            <small><i className="lci lci-exclamation text-warning" /> Não há informações no período</small>
        }

      </div>

    </div>
  );
};

export default DoughnutChart;