import { useState, useEffect } from 'react';
import { devicesEvent } from '../../resumeEvent/Models';

export const groupByField = (array: any[], field: string) => {
    return array.reduce((groups, object) => {
        const value = object[field];
        groups[value] = groups[value] || [];
        groups[value].push(object);
        return groups;
    }, {});
};

const filterArrayPerDate = (array: devicesEvent[], period: number, tag: string) => {
    let today = new Date();
    let daysAgoDate = new Date();
    daysAgoDate.setDate(today.getDate() - period);
    daysAgoDate.setHours(0, 0, 0, 0);
    return array.filter(data => {
        return 'lastSeen' in data && data.lastSeen && new Date(data.lastSeen) >= daysAgoDate && new Date(data.lastSeen) <= today;
    });
};

export const useMostTargetData = (
    resumeEventReport: devicesEvent[] | null,
    mostTargetSelected: string,
    period: string,
    rowsFiltered: devicesEvent[] | null,
    filtered: boolean
) => {
    const [rows, setRows] = useState<any[]>([]);
    const [groupNames, setGroupNames] = useState<string[]>([]);
    const [eventsFiltered, setEventsFiltered] = useState<devicesEvent[]>([]);

    useEffect(() => {
        if (!resumeEventReport || !period) return;
        
        
        const daysMap = {
            'last7Days': 7,
            'last30Days': 30,
            'last90Days': 90,
            'alltime': null
        };
        const days = daysMap[period as keyof typeof daysMap];
        const arrayEvent = filtered && rowsFiltered ? rowsFiltered : resumeEventReport;
        if(arrayEvent.length === 0) {
            setRows([]);
            setGroupNames([]);
            setEventsFiltered([]);
        } else {
            const collectors = days === null ? arrayEvent :
                filterArrayPerDate(arrayEvent, days, 'devices');
                setRows([groupByField(collectors, 'classification')]);
                const uniqueClassifications = [...new Set(collectors
                    .sort((a, b) => a.classificationRank - b.classificationRank)
                    .map(item => item.classification)
                    .filter(classification => !classification.toLowerCase().includes("segura")))];
                setGroupNames(uniqueClassifications as string[]);
                setEventsFiltered(collectors);
        }
    }, [period, resumeEventReport, mostTargetSelected, rowsFiltered, filtered]);

    return { rows, groupNames, eventsFiltered };
};