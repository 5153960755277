import React, { useEffect, ChangeEvent, useState } from 'react';

import { Button, Card, CardActions, CardContent,   FormGroup, Input, InputLabel, LinearProgress, NativeSelect, OutlinedInput, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Label } from '@material-ui/icons';
import SideModal from '../../components/Layout/SideModal/SideModal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { PPModal } from 'processor-plataform-ui';

interface CustomFormProps {
    template: any,
    data?: any,
    handleform: any,
    visible?: boolean,
    EditForm?: boolean,
    onClose?: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
            },
        },
    }),
);

let DetailForm: React.FC<CustomFormProps> = (props) => {
    const classes = useStyles();
    const renderOptions = (options: any[], option: any) => {
        return options.map((child) => {
            return (
                <option key={child.value} value={child.value} selected={option == child.value}>{child.label}</option>
            );
        });
    };

    let [template, setTemplate] = useState(props.template);
    let visible = false;
    if (props.visible) {
        visible = props.visible;
    }
    let { register, errors, setValue } = props.handleform;

    useEffect(() => {
        let interfaceTyped: { [key: string]: any } = { ...props.data };
        try {
            setTemplate(props.template);
            Object.keys(props.data).map((field) => {
                interfaceTyped = props.data;
                setValue(field, interfaceTyped[field]);
            });
        } catch (e) {

        }
    }, [props.data]);

    return (
        <PPModal
            title={(props.data != null) ? props.data[template.title] : ''}
            visible={visible}
            onClose={() => { if (props.onClose) { props.onClose(); } }}
        >
            <Card>
                <CardContent>
                    <div>
                        <form>
                            <div>
                                {template.Fields.filter((x: { edit: any; }) => (x.edit == true && (props.EditForm == true || props.EditForm == undefined)) || props.EditForm == false).sort((a: { row: number; }, b: { row: number; }) => {
                                    if (a.row > b.row) return 1;
                                    else if (b.row > a.row) return -1;
                                    return 0;
                                }).map((field: { type?: any; title?: any; name?: any; required?: any; validation?: any; defaultValue?: any; disabled?: any; row?: any; col?: any; colspan?: number; rowspan?: any; options?: any; }) => {

                                    let { type, title, name, required, validation, defaultValue, disabled } = field;
                                    switch (type) {
                                        case 'text':
                                            return (
                                                <FormGroup key={name} className={classes.root} >
                                                    <TextField
                                                        type='text'
                                                        label={title}
                                                        required={required.value == true}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(props.data[name] != null) ? props.data[name] : ''}
                                                        disabled={!props.EditForm}
                                                        variant="outlined"
                                                        name={name}
                                                        inputRef={register({ required: required, validate: validation })}
                                                    />
                                                </FormGroup>
                                            );

                                        case 'select':
                                            let { options } = field;
                                            return (
                                                <FormGroup key={name} className={classes.root} style={{ paddingRight: 8, paddingLeft: 8 }}  >
                                                    <InputLabel style={{ position: 'relative', top: 5, paddingLeft: 14, fontSize: 12 }} htmlFor="outlined-age-simple">
                                                        {title}
                                                    </InputLabel>
                                                    <NativeSelect input={<OutlinedInput label={title} notched={true} />} className={classes.root}
                                                        title={title}
                                                        inputProps={{ id: 'outlined-age-simple', variant: "outlined" }}
                                                        name={name}
                                                        disabled={!props.EditForm}
                                                        inputRef={register({ required: required, validate: validation })}
                                                        defaultValue={(props.data[name] != null) ? props.data[name] : ''} >
                                                        {renderOptions(options, props.data[name])}
                                                    </NativeSelect>
                                                </FormGroup>
                                            );
                                        default:
                                            return (
                                                <div>
                                                    <span>Invalid Field</span>
                                                </div>
                                            );
                                    }

                                })}
                            </div>
                        </form>
                    </div>
                </CardContent >
                <CardActions>
                    {
                        [{}].map((i) => {
                            if (props.EditForm == false) {
                                return (
                                    <Button size="small" variant="contained" color="primary" hidden={true} >Salvar</Button>);
                            }
                        })
                    }
                </CardActions>
            </Card >
        </PPModal>
    );
}

export default DetailForm;