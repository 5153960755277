import React from 'react';

interface Attachment {
    type: string;
    url?: string;
    image_file?: {
        file_content?: string;
    };
}

interface Content {
    type: string;
    image_file?: {
        file_content?: string;
    };
}

interface Message {
    attachments: Attachment[];
    content: Content[];
}

interface Base64ImageMessageProps {
    message: any;
    imageEvent?: (event: any) => void; // Atualize a definição de props para incluir zoomCard

}

const Base64ImageMessage: React.FC<Base64ImageMessageProps> = ({ message, imageEvent }) => {
 
    
    return (
        <div style={{ marginBottom: '20px' }} onClick={() => { imageEvent && imageEvent({}); }}>
            {message.outputs && message.outputs.map((output, attachmentIndex) =>
                output.type === 'image' ? (
                    <img
                        key={`attachment-${attachmentIndex}`}
                        src={output.file_content}
                        alt={`attachment-${attachmentIndex}`}
                        style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                    />
                ) : output.type === 'image_file' && output.image_file?.file_content ? (
                    <img
                        key={`attachment-${attachmentIndex}`}
                            src={output.image_file.file_content}
                        alt={`attachment-${attachmentIndex}`}
                        style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                    />
                ) : null
            )} 
            {message.attachments && message.attachments.map((attachment, attachmentIndex) =>
                attachment.type === 'image' ? (
                    <img
                        key={`attachment-${attachmentIndex}`}
                        src={attachment.url}
                        alt={`attachment-${attachmentIndex}`}
                        style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                    />
                ) : attachment.type === 'image_file' && attachment.image_file?.file_content ? (
                    <img
                        key={`attachment-${attachmentIndex}`}
                        src={attachment.image_file.file_content}
                        alt={`attachment-${attachmentIndex}`}
                        style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                    />
                ) : null
            )}
            {message.content && message.content.map((content, contentIndex) => {
                content.type === 'image_file' && console.log(content.type)
                content.type === 'image_file' &&  console.log(content.image_file)
                content.type === 'image_file' &&  console.log(content.image_file?.file_content)
                return <>{content.type === 'image_file' && content.image_file?.file_content ? (
                    <img
                        key={`content-${contentIndex}`}
                        src={content.image_file.file_content}
                        alt={`content-${contentIndex}`}
                        style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                    />
                ) : null}</>
            }
          
               
            )}
        </div>
    );
};

export default Base64ImageMessage;
