import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { Bar } from 'react-chartjs-2';
import { FileSyncService } from '../../../services/fileSync/fileSyncService';
import { Grid, LinearProgress } from '@material-ui/core';
//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcSegment from '../../../components/Generic/LcSegment';
import Doughnut from '../../../components/Layout/Dashboard/Doughnut';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

function getUpdatedAt(data: string) {
    let UpdatedAt: string = data;
    let date = new Date(UpdatedAt);

    return date.toLocaleString();
}

const FileSyncResumo: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [summaryData, setSummaryData] = useState<any>();
    const [groups, setGroups] = useState<any[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<any[]>([]);
    const [visibleGroups, setVisibleGroups] = useState<any[]>([]);
    const [groupUsageChart, setGroupUsageChart] = useState<any>();
    const [groupUsageChartNoData, setGroupUsageChartNoData] = useState<boolean>(false);
    const [monthlyChartNoData, setMonthlyChartNoData] = useState<boolean>(false);
    const [sizeChartNoData, setSizeChartNoData] = useState<boolean>(false);
    const [monthlyChart, setMonthlyChart] = useState<any>();
    const [sizeChart, setSizeChart] = useState<any>();
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isLoadingGroupChart, setIsLoadingGroupChart] = useState<boolean>(true);
    const [isLoadingSizeChart, setIsLoadingSizeChart] = useState(true);
    const [UpdatedAt, setUpdatedAt] = useState<string>();
    const filterDefault = { size: 20, term: "", column: "", direction: "asc" };
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const sizeChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.labels[tooltipItem.datasetIndex];
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }]
        }
    };

    const monthlyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }]
        }
    };

    useEffect(() => {
        setIsLoadingCards(true);
        setIsLoadingTable(true);
        setIsLoadingSizeChart(true);
        setIsLoadingGroupChart(true);
        setGroups([])
        setFilteredGroups([])
        setMonthlyChart(null)
        setSizeChart(null)
        setGroupUsageChart(null)
        setSizeChartNoData(false)
        setMonthlyChartNoData(false)
        setGroupUsageChartNoData(false)

        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const selectedDate = [month, year].join('');
            const fileSyncService = new FileSyncService(props);
            fileSyncService.GetCards(selectedDate)
                .then(response => {
                    let summaryData = {
                        TotalSyncGroups: response.data.totalSyncGroups,
                        ConsumedStorageLastTB: response.data.consumedStorageLastTB,
                        ConsumedStorageBiggestTB: response.data.consumedStorageBiggestTB,
                        ProvisionedStorageTB: response.data.provisionedStorageTB,
                    };
                    setSummaryData(summaryData);
                    setUpdatedAt(getUpdatedAt(response.data.lastSyncDateTime));
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    setIsLoadingCards(false);
                });;
            fileSyncService.GetGroups()
                .then(response => {
                    let groups = response.data.groups.map((Item: { group: string, consume: number }, index: number) => {
                        return {
                            id: index,
                            group: Item.group,
                            consume: Item.consume.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        }
                    });
                    let groupChart = {
                        labels: response.data.groups.map((Item: { group: string, consume: number }, index: number) => {
                            return Item.group
                        }),
                        values: response.data.groups.map((Item: { group: string, consume: number }, index: number) => {
                            return Item.consume
                        })
                    };
                    let monthlyChart = {
                        labels: response.data.monthlyChart.chartLabels,
                        datasets: [{
                            type: 'line',
                            label: "Consumo mensal",
                            data: response.data.monthlyChart.chartValues,
                            borderColor: "#0074D9",
                            backgroundColor: '#a5bf3e',
                            fill: false
                        },
                        {
                            label: "Consumo mensal",
                            data: response.data.monthlyChart.chartValues,
                            borderColor: "#0074D9",
                            backgroundColor: '#a5bf3e',
                            fill: false
                        }]
                    };
                    setMonthlyChart(monthlyChart);
                    setGroups(groups);
                    setFilteredGroups(groups);
                    setGroupUsageChart(groupChart);
                })
                .catch(error => {
                    console.log(error);
                    setGroupUsageChartNoData(true);
                    setMonthlyChartNoData(true);
                }).then(() => {
                    setIsLoadingTable(false);
                    setIsLoadingGroupChart(false);
                });

            fileSyncService.GetChart(selectedDate)
                .then(response => {
                    if (response.data.sizeChart.chartLabels.length > 0) {
                        let sizeChart = {
                            labels: response.data.sizeChart.chartLabels,
                            datasets: [
                                {
                                    data: response.data.sizeChart.chartValues,
                                    borderColor: "#3e95cd",
                                    backgroundColor: '#a5bf3e',
                                    fill: false
                                },
                                {
                                    type: 'line',
                                    data: response.data.sizeChart.chartValues,
                                    borderColor: "#3e95cd",
                                    backgroundColor: '#a5bf3e',
                                    fill: false,
                                }]
                        }
                        setSizeChart(sizeChart);
                    } else {
                        setSizeChartNoData(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setSizeChartNoData(true);
                }).finally(() => {
                    setIsLoadingSizeChart(false);
                });

        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    useEffect(() => {
        var filtered = groups.filter((p: any) =>
            filter.term == '' ||
            p.group.toLowerCase().indexOf(filter.term) > -1 ||
            p.consume.toLowerCase().indexOf(filter.term) > -1
        )

        filtered = filtered.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredGroups(filtered);
        setVisibleGroups(filtered.slice(0, filter.size));
    }, [filter, filteredGroups.length > 0]);

    const columns = [
        { field: 'group', headerName: 'Grupos', width: "50%" },
        {
            field: 'consume', headerName: 'Consumo em TB', width: "50%",
            renderCell: (row: any) => {
                return row.consume && parseFloat(row.consume.replace(',', '.')).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
        },
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleGroups(filteredGroups.slice(0, size))
        else setVisibleGroups([...visibleGroups, ...filteredGroups.slice(visibleGroups.length, visibleGroups.length + 10)])
    };

    return (
        <Layout pageTitle="Resumo" periodSelection={periodSelection}>
            <LCFlexContainer spacing={2}>
                <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                    <LcLoading loading={isLoadingCards} >
                        {
                            summaryData
                                ?
                                <LCFlexContainer spacing={2}>
                                    <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                                        <IconValueCardRow maximumFractionDigits={1} title="Sync groups" icon="lci lci-collection" value={summaryData.TotalSyncGroups} color="#E273C5"></IconValueCardRow>
                                    </LCFlexItem>
                                    <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                                        <IconValueCardRow maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true} title="Volume corrente" icon="lci lci-server" value={summaryData.ConsumedStorageLastTB} unity="TB" color="#8684EF"></IconValueCardRow>
                                    </LCFlexItem>
                                    <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                                        <IconValueCardRow maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true} title="Maior volume" icon="lci lci-database" value={summaryData.ConsumedStorageBiggestTB} unity="TB" color="#F3C944" tooltip="Maior volume do período"></IconValueCardRow>
                                    </LCFlexItem>
                                    <LCFlexItem xs={12} sm={6} md={4} lg={3}>
                                        <IconValueCardRow maximumFractionDigits={1} formatValue={true} title="Espaço contratado" icon="lci lci-briefcase" value={summaryData.ProvisionedStorageTB} unity="TB" color="#F28B40"></IconValueCardRow>
                                    </LCFlexItem>
                                </LCFlexContainer>
                                :
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '1rem', alignItems: 'center' }}>
                                    <i className="lci lci-exclamation lci-2x text-warning" />
                                    <span className="font-sm">Não há informações no período</span>
                                </div>
                        }
                    </LcLoading>
                </LCFlexItem>
            </LCFlexContainer>

            <LCFlexContainer spacing={2}>
                <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                    <LcSegment title="Espaço alocado em TB" loading={isLoadingGroupChart} fullHeigth={true}>
                        {groupUsageChart &&
                            <Doughnut maximumFractionDigits={2} minimumFractionDigits={2} formatNumber={true} data={groupUsageChart} />
                        }
                        {groupUsageChartNoData &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                <i className="lci lci-exclamation lci-2x text-warning" />
                                <span className="font-sm">Não há informações no período</span>
                            </div>
                        }
                    </LcSegment>
                </LCFlexItem>

                <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                    <LcSegment title="Consumo diário" loading={isLoadingSizeChart} fullHeigth={true}>
                        {sizeChart && sizeChart.datasets[0].data.length > 0 &&
                            <Bar height={170} data={sizeChart} options={sizeChartOptions}></Bar>
                        }
                        {sizeChartNoData &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                <i className="lci lci-exclamation lci-2x text-warning" />
                                <span className="font-sm">Não há informações no período</span>
                            </div>
                        }
                    </LcSegment>
                </LCFlexItem>

                <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                    <LcSegment title="Maior consumo mensal" loading={isLoadingSizeChart} fullHeigth={true}>
                        {monthlyChart &&
                            <Bar data={monthlyChart} height={170} options={monthlyUsageChartOptions}></Bar>
                        }
                        {monthlyChartNoData &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                <i className="lci lci-exclamation lci-2x text-warning" />
                                <span className="font-sm">Não há informações no período</span>
                            </div>
                        }
                    </LcSegment>
                </LCFlexItem>
            </LCFlexContainer>

            <LcInfiniteTable
                loading={isLoadingTable}
                columns={columns}
                rows={visibleGroups}
                size={filteredGroups.length}
                loadMore={loadMore}
                onSortChange={onSortChange} />

        </Layout>
    );
};

export default FileSyncResumo;