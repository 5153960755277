import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import LCDashboard from '../../components/Data/Dashboard/LCDashboard';
import { AuditLogEntry, AuditLogService, routesToWatch, copyToClipboardTweak } from '../../services/AuditLogService';
import LcInfiniteTable from '../../components/Data/LcInfiniteTable';
import { PPInput, PPModal } from 'processor-plataform-ui';
import PPDropDown from '../../components/PPDropDown';
import { PiFileTextLight } from 'react-icons/pi';
import ReactJsonView from '@microlink/react-json-view';
import LcIconLink from '../../components/Generic/LcIconLink';
import qs from 'qs';
import './index.css'
import { Tooltip } from '@material-ui/core';

interface FormValidation {
	screen: boolean,
	action: boolean,
	startDate: boolean,
	endDate: boolean,
	email: boolean
}
const defaultFormValidation: FormValidation = {
	screen: true,
	action: true,
	startDate: true,
	endDate: true,
	email: true
}

const emptyOption = { label: '', value: '' };

const AuditLog: React.FC = (props) => {

	const service = new AuditLogService(props);
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<AuditLogEntry[]>([]);

	const [screen, setScreen] = useState<{ label: string; value: string }>(emptyOption);
	const [action, setAction] = useState<{ label: string; value: string }>(emptyOption);
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [fullDetails, setFullDetails] = useState<object>();
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<FormValidation>(defaultFormValidation);

	const routeOptions = Object.keys(routesToWatch).map((screen) => ({ label: screen, value: screen, selected: false }));

	const actionOptions = routesToWatch[screen.value] ?
		Object.keys(routesToWatch[screen.value]).map((action) =>
		({
			label: action,
			value: routesToWatch[screen.value][action],
			selected: false
		})) : [];

	useEffect(copyToClipboardTweak, [showDetails])

	useEffect(() => setAction({ ...emptyOption }), [screen]);

	const fieldsAreValid = () => {
		const validationReport = { ...defaultFormValidation }

		validationReport.screen = screen.value !== '';
		validationReport.action = action.value !== '';
		validationReport.startDate = startDate !== '';
		if (endDate) {
			const today = new Date().toISOString().split('T')[0];
			validationReport.endDate = new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(today);
		}

		setFormErrors(validationReport);

		return Object.keys(validationReport)
			.reduce((allIsValid, field) => validationReport[field] && allIsValid, true);
	}

	const queryData = () => {
		setLoading(true);
		setData([]);
		if (fieldsAreValid()) {
			const validEndDate = endDate ? endDate : new Date().toISOString().split('T')[0];
			setEndDate(validEndDate);
			service.GetAuditLogs(startDate, validEndDate, email, action.value)
				.then(response => {
					let _sorted = response.sort((a: any, b: any) => { return a.accessDate > b.accessDate ? -1 : 1 });
					let _detailed = _sorted.map((entry: AuditLogEntry) => {
						return {
							...entry,
							detail: entry.httpMethod === 'GET' && entry.route.includes('?') ?
								entry.route.split('?')[1] : entry.detail
						}
					});
					setData(_detailed);
				})
				.catch(console.error)
				.finally(() => setLoading(false));
		} else
			setLoading(false);
	}

	const showFullDetails = (row: AuditLogEntry) => {
		const detail = row.httpMethod === 'GET' && row.detail.length > 0 ? qs.parse(row.detail) : JSON.parse(row.detail);
		setFullDetails(detail);
		setShowDetails(true);
	}

	const hideFullDetails = () => {
		setFullDetails(undefined);
		setShowDetails(false);
	}

	const cards = [
		{
			id: 2,
			type: 'Custom',
			hideHeader: true,
			position: { ColSpan: 12, RowSpan: 3, row: 2, col: 1 },
			customContentRender: () =>
				<LcInfiniteTable
					loading={loading}
					columns={[
						{ field: 'clientGroupName', headerName: 'Cliente', width: '15%' },
						{ field: 'userEmail', headerName: 'Usuário', width: '20%' },
						{
							field: 'accessDate', headerName: 'Data', width: '20%',
							renderCell: (row: AuditLogEntry) => new Date(row.accessDate).toLocaleDateString() + ', ' + new Date(row.accessDate).toLocaleTimeString()
						},
						{
							field: 'detail', headerName: 'Dados da ação', width: '50%', renderCell: (row: AuditLogEntry) => {
								if (row.detail == null || row.detail.length == 0)
									return '';

								if (row.detail.length > 80)
									return row.detail.substring(0, 80) + '...'

								return row.detail
							}
						},
						{
							field: 'id', width: '5%',
							renderCell: (row: AuditLogEntry) =>
								<div>
									<Tooltip arrow title='Ver dados da ação' placement='bottom-end'>
										<span>
											<LcIconLink
												size='small'
												icon={<PiFileTextLight />}
												onClick={() => showFullDetails(row)}
											/>
										</span>
									</Tooltip>
								</div>
						}
					]}
					rows={data}
					size={data.length}
					density='high'
				/>
		},
		{
			id: 1,
			type: 'Custom',
			hideHeader: true,
			position: { ColSpan: 12, RowSpan: 1, row: 1, col: 1 },
			customContentRender: () =>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					gap: '10px'
				}}>
					<div style={{ flex: 1 }}>
						<PPDropDown
							name='screen'
							title={<span style={{
								color: formErrors.screen ? '' : 'red',
								fontWeight: formErrors.screen ? '' : 'bold',
							}}>Interface</span>}
							value={screen}
							options={routeOptions}
							onChange={(opt) => setScreen(opt as { label: string; value: string })}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<PPDropDown
							name='action'
							title={<span style={{
								color: formErrors.action ? '' : 'red',
								fontWeight: formErrors.action ? '' : 'bold',
							}}>Ação</span>}
							value={action}
							options={actionOptions}
							onChange={(opt) => setAction(opt as { label: string; value: string })}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<PPInput
							name="startDate"
							title="Data inicial"
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							error={{ show: !formErrors.startDate, help: 'Data inicial inválida!' }}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<PPInput
							name="endDate"
							title="Data final"
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							error={{ show: !formErrors.endDate, help: 'Data final menor que a inicial!' }}

						/>
					</div>
					<div style={{ flex: 1 }}>
						<PPInput
							name="email"
							title="Usuário"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div>
						<button className='pp-button bg-info' onClick={queryData}>Buscar</button>
					</div>
				</div>
		}
	]

	return <Layout pageTitle='Logs de Auditoria' >
		<PPModal
			visible={showDetails}
			onClose={hideFullDetails}
			title="Dados da ação"
			size='large'
		>
			<ReactJsonView
				src={fullDetails ?? {}}
				name={null}
				onEdit={false}
				onAdd={false}
				displayDataTypes={false}
				quotesOnKeys={false}
				displayObjectSize={false}
				displayArrayKey={false}
				collapsed={false}
			/>
		</PPModal>
		<LCDashboard cards={cards} />
	</Layout>;
}

export default AuditLog;