import React, { useState, useEffect } from 'react';
import EAAdicionaisOnPremiseService from '../../../services/EASubscriptionService/EAAdicionaisOnPremiseService';
import { PPInput } from 'processor-plataform-ui';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import LcLoading from '../../../components/Generic/LcLoading';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import './index.css';

interface EAAdicionaisOnPremise {
	eaSubscriptionName: string;
	country: string;
	valueUnit: number;
	quantidade: number;
}

const EAAdicionaisOnPremiseComponent: React.FC = () => {
	const [entries, setEntries] = useState<EAAdicionaisOnPremise[]>([]);
	const [newEntry, setNewEntry] = useState<EAAdicionaisOnPremise>({
		eaSubscriptionName: '',
		country: '',
		valueUnit: 0,
		quantidade: 0,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		fetchEntries();
	}, []);

	const fetchEntries = async () => {
		setLoading(true);
		try {
			const result = await EAAdicionaisOnPremiseService.queryEAAdicionaisOnPremise();
			setEntries(result);
		} catch (error) {
			console.error('Erro ao buscar entradas:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleSave = async () => {
		setLoading(true);
		try {
			const success = await EAAdicionaisOnPremiseService.saveEAAdicionaisOnPremise(newEntry);
			if (success) {
				fetchEntries();
				setNewEntry({ eaSubscriptionName: '', country: '', valueUnit: 0, quantidade: 0 });
				setShowModal(false);
			}
		} catch (error) {
			console.error('Erro ao salvar a entrada:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async (entry: EAAdicionaisOnPremise) => {
		setLoading(true);
		try {
			const success = await EAAdicionaisOnPremiseService.deleteEAAdicionaisOnPremise(entry);
			if (success) {
				fetchEntries();
			}
		} catch (error) {
			console.error('Erro ao deletar a entrada:', error);
		} finally {
			setLoading(false);
		}
	};

	const isAddButtonDisabled = newEntry.eaSubscriptionName.trim() === '' || newEntry.country.trim() === '';

	return (
		<div>
			<LcLoading loading={loading}>
				<button className="icon-button" onClick={() => setShowModal(true)}>
					<FaPlus /> Adicionar Novo
				</button>
				<ul className="entries-list">
					{entries.map((entry, index) => (
						<li key={index} className="entry-item">
							<span>{entry.eaSubscriptionName} - {entry.country} - {entry.valueUnit} - {entry.quantidade}</span>
							<button className="icon-button" onClick={() => handleDelete(entry)}>
								<FaTrashAlt />
							</button>
						</li>
					))}
				</ul>

				<SideModal header="Adicionar Adicional On-Premise EA" visible={showModal} onClose={() => setShowModal(false)}>
					<div className="form-container">
						<PPInput
							title="Nome da Assinatura EA"
							value={newEntry.eaSubscriptionName}
							onChange={(e) => setNewEntry({ ...newEntry, eaSubscriptionName: e.target.value })}
						/>
						<PPInput
							title="País"
							value={newEntry.country}
							onChange={(e) => setNewEntry({ ...newEntry, country: e.target.value })}
						/>
						<PPInput
							title="Valor Unitário"
							type="number"
							value={newEntry.valueUnit}
							onChange={(e) => setNewEntry({ ...newEntry, valueUnit: parseFloat(e.target.value) })}
						/>
						<PPInput
							title="Quantidade"
							type="number"
							value={newEntry.quantidade}
							onChange={(e) => setNewEntry({ ...newEntry, quantidade: parseInt(e.target.value) })}
						/>
						<button
							className="icon-button"
							onClick={handleSave}
							disabled={isAddButtonDisabled}
						>
							<FaPlus /> Salvar
						</button>
					</div>
				</SideModal>
			</LcLoading>
		</div>
	);
};

export default EAAdicionaisOnPremiseComponent;
