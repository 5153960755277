import ClientGroupContract from "../pages/hosting/model/ClientGroupContract";
import api, { APIClient } from "./api";

export class HostingService extends APIClient {

  async GetDetailsGroup() {
    const url = this.endpoint + `/api/VirtualMachine/GetHostingViewResponse`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      return response.data.hostingViewResponses.map((currElement: any, index: any) => {

        const vhds = currElement.vhd?.map((secondCurrElement: any, secondIndex: any) => {
          return {
            id: secondIndex,
            nome: secondCurrElement.nome,
            tamanho: secondCurrElement.tamanho
          }
        });

        return {
          id: index,
          ...currElement,
          settings: null,
          vhd: vhds,
        };
      });
    });
  }

  async GetAllVMs(useSyncData: boolean = true) {
    const endpoint = useSyncData ? "GetHostingViewResponseByClientGroup" : "GetHostingViewResponseByClientGroupCurrentData";
    const url = this.endpoint + `/api/VirtualMachine/${endpoint}?refresh=${Math.random()}`;
    return api({
      method: 'get',
      url,
    }).then((response) => {

      return response.data.vMs.map((currElement: any, index: any) => {

        const vhds = currElement.vhd?.map((secondCurrElement: any, secondIndex: any) => {
          return {
            id: secondIndex,
            nome: secondCurrElement.nome,
            tamanho: secondCurrElement.tamanho
          }
        });

        return {
          id: index,
          ...currElement,
          settings: null,
          vhd: vhds,
          referenceDate: response.data.referenceDate
        };
      });
    });
  }

  async Connect(vmname: string, serviceName: string, instanceid: string, deploymentName: string, virtualMachineName: string, assinaturaId: number, isWindows: boolean, publicIP: string = "") {
    const url = this.endpoint + `/api/VirtualMachine/Connect?vmname=${vmname}&assinaturaId=${assinaturaId}&instanceid=${instanceid}&serviceName=${serviceName}&deploymentName=${deploymentName}&virtualMachineName=${virtualMachineName}&isWindows=${isWindows}&publicIP=${publicIP}`;
    return api({
      method: 'get',
      url,
      responseType: 'blob',

    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${vmname}.rdp`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });

  }

  Ligar(serviceName: string, instanceid: string, deploymentName: string, virtualMachineName: string, assinaturaId: number) {
    const url = this.endpoint + `/api/VirtualMachine/Ligar?serviceName=${serviceName}&instanceid=${instanceid}&deploymentName=${deploymentName}&virtualMachineName=${virtualMachineName}&assinaturaId=${assinaturaId}`;
    return api({
      method: 'get',
      url,

    })
  }

  Desligar(serviceName: string, instanceid: string, deploymentName: string, virtualMachineName: string, assinaturaId: number) {
    const url = this.endpoint + `/api/VirtualMachine/Desligar?serviceName=${serviceName}&instanceid=${instanceid}&deploymentName=${deploymentName}&virtualMachineName=${virtualMachineName}&assinaturaId=${assinaturaId}`;
    return api({
      method: 'get',
      url,
    });

  }

  async GetVirtualNets(VendorItemId: number) {
    const url = this.endpoint + `/api/VirtualNet/GetVirtualNets/${VendorItemId}`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error);
    });
  }

  async GetContracts() {
    const url = this.endpoint + `/api/ClientGroup/GetContracts`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      const contract = response.data.map((item: ClientGroupContract) => {
        return { value: item.contractId, name: `${item.contractId} - ${item.contractName}` }
      });
      return contract;
    }).catch((error) => {
      console.log(error);
    });
  }

  async RetrieveSubscriptionsSelectAzureAndArm() {
    const url = this.endpoint + `/api/VendorCredential/RetrieveSubscriptionsSelectAzureAndArm`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error);
    });
  }

  async CreateOrUpdate(newVM: any) {

    const url = `${this.endpoint}/api/VirtualMachine/CreateOrUpdate`

    const data = JSON.stringify(newVM)

    return api({
      method: 'put',
      url,
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async GetVmSizes() {
    const url = this.endpoint + `/api/VirtualMachine/GetVmSizes`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error);
    });
  }
  async GetStogare() {
    const url = this.endpoint + `/api/VirtualMachine/GetStorageStatus`;
    return api({
      method: 'get',
      url,
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error);
    });
  }

}