import { SystemDetail } from "../../../services/patchmanager/patchManagerModel";

export const translateStatus = (row) => {
    const translateStatus = {
        1: 'Ativo',
        2: "Inativo",
    };
    return translateStatus[row];
};

export const getStatusRow = (row: SystemDetail) => {
    return row.computer_live_status === 1 ? '#3ebb3f' : '#dc291e';
};

export const translateHealth = (status: number) => {
    const translateStatus = {
        0: 'Desconhecido',
        1: "Saudável",
        2: "Vulnerável",
        3: "Altamente vulnerável",
    };
    return translateStatus[status];
};

export const translateHealthColor = (status: number) => {
    const translateStatusColor = {
        4: '#ff0400', // 'Important'
        3: '#ff5400', // 'Important'
        2: "#f6de00",// 'Moderate' 
        1: "#3ebb3f",//'Low'
        0: "#cccccf",//'Unrated'
    };
    return translateStatusColor[status];
};

export const translateSeverityColor = (status) => {
    const translateStatusColor = {
        4: '#ff4400', // 'Important'
        3: '#ffa400', // 'Important'
        2: "#f6de00",// 'Moderate' 
        1: "#3ebb3f",//'Low'
        0: "#cccccf",//'Unrated'
    };
    return translateStatusColor[status];
};
export const translateScanStatusPatch = (status: number) => {
    const translateStatus = {
        226: 'Falha',
        227: 'Em andamento',
        228: 'Sucesso',
        229: 'Não verificado'
    };
    return translateStatus[status];
};

export const translateSeverityLabel = (status) => {
    const translateStatusColor = {
        4: 'Crítico'
        , 3: 'Importante'
        , 2: 'Moderado'
        , 1: 'Baixo'
        , 0: 'Não classificado'
    };
    return translateStatusColor[status];
};


const translateStatusPatch = (status: string) => {
    const translateStatus = {
        '202': 'Ausente',
        '201': "Instalado",
        '206': "Falha"
    };
    return translateStatus[status];
}
