import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import LcIconLink from '../../../components/Generic/LcIconLink';


import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';

import LcTable from '../../../components/Data/LcTable';
import { PPModal } from 'processor-plataform-ui'
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { PiListLight } from 'react-icons/pi';

const EndpointDetectionResponseExceptions: React.FC = (props) => {

    const [filteredException, setFilteredException] = useState<any[]>([]);
    const [exceptionsLength, setExceptionsLength] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedDevice, setSelectedDevice] = useState<any>(false);
    const [exception, setException] = useState<any[]>([]);
    const [modalDetails, setModalDetails] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 1, term: "", column: "", direction: "asc" });

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    //Filter states    
    const [filter_exceptionId, setFilter_exceptionId] = useState<string>('');
    const [filter_originEventId, setFilter_originEventId] = useState<string>('');
    const [filter_processName, setFilter_processName] = useState<string>('');
    const [filter_processPath, setFilter_processPath] = useState<string>('');
    const [filter_administrator, setFilter_administrator] = useState<string>('');

    useEffect(() => {
        const endpointDetectionResponseService = new EndpointDetectionResponseService(props);
        setIsLoading(true);

        if (user.ClientGroupSelected) {
            endpointDetectionResponseService &&
                endpointDetectionResponseService.GetExceptions()
                    .then(response => {
                        //Esse filtro não esta funcionando na API da forntnet EDR, estamos em contato com o fornecedor
                        //if (filter_deviceGroup && filter_deviceGroup.length > 0) {
                        //    response.data = response.data.filter(v => new RegExp(filter_deviceGroup, "i").test(v.collectorGroupName))
                        //}
                        if (response.data.length > 0) {
                            setException(response.data);
                            setFilteredException(response.data);
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                    })
                    .then(() => {
                        setIsLoading(false);
                    })
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = exception.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredException(filtered.slice(0, filter.size));
    }, [filter]);

    // filter state,name device,deviceGroup
    const filterAdvanced = {
        fields: [
            { label: 'Número da exceção', name: 'exceptionId', type: 'text' },
            { label: 'Número do evento', name: 'originEventId', type: 'text' },
            { label: 'Processo', name: 'processName', type: 'text' },
            { label: 'Caminho', name: 'processPath', type: 'text' },
            { label: 'Administrador', name: 'administrator', type: 'text' }
        ],
        onChange: (_filter: any, size: number) => {
            // setFilter({...filter, size: size, term: _filter[0].value.toLowerCase()})

            setException([])
            setFilteredException([])

            let exceptionId = _filter[0].value ? _filter[0].value : '';
            let originEventId = _filter[1].value ? _filter[1].value : '';

            setFilter_exceptionId(exceptionId)
            setFilter_originEventId(originEventId)
            user.refreshFlag = Math.random();
        }
    };

    const columns = [
        {
            field: 'exceptionId', headerName: 'Exceção', width: "7%"
        },
        {
            field: 'originEventId', headerName: 'Evento', width: "7%"
        },
        {
            field: 'alerts',
            headerName: 'Processo',
            width: "15%",
            renderCell: (row: any) => {
                const text = row.alerts && row.alerts[0] && row.alerts[0].process && row.alerts[0].process.name;
                return <span title={text} className='ellipsis'>
                    {text}
                </span>
            }
        },
        {
            field: 'alerts', headerName: 'Destino', width: "10%", renderCell: (row: any) => {
                const text = row.selectedDestinations ? row.selectedDestinations.join("\n") : "N/A"
                return <span title={text} className='ellipsis'>
                    {text}
                </span>
            }
        },
        {
            field: 'alerts',
            headerName: 'Caminho',
            width: "25%",
            renderCell: (row: any) => {
                const text = row.alerts && row.alerts[0] && row.alerts[0].process && row.alerts[0].process.path;
                // Adiciona a propriedade title com o mesmo conteúdo do texto
                return <span title={text} className='ellipsis'>
                    {text}
                </span>
            }
        },
        {
            field: 'userName',
            headerName: 'Administrador',
            width: "15%",
            truncate: true,
        },
        {
            field: 'updatedAt', headerName: 'Atualizado em', width: "12%", truncate: true,
        },
        {
            field: 'exceptionId', headerName: '', width: "5%", overflow: "visible",
            renderCell: (row: any, i) => {
                return <LcIconLink key={i} icon={<PiListLight />} size='small' tooltipPosition='inline-left' tooltip="Detalhes" onClick={() => { setSelectedDevice(row); setModalDetails(true); }} />
            }
        }
    ];

    const onSortChange = (sortData) => {
        const { sort } = sortData;

        let sortedException = [...filteredException].sort((a, b) => {
            let valueA, valueB;

            switch (sort.column) {
                case 'exceptionId':
                    valueA = a.exceptionId;
                    valueB = b.exceptionId;
                    break;
                case 'originEventId':
                    valueA = a.originEventId;
                    valueB = b.originEventId;
                    break;
                case 'alerts': // Para 'Processo', 'Destino' e 'Caminho'
                    if (sort.headerName === 'Processo') {
                        valueA = a.alerts && a.alerts[0] && a.alerts[0].process ? a.alerts[0].process.name : '';
                        valueB = b.alerts && b.alerts[0] && b.alerts[0].process ? b.alerts[0].process.name : '';
                    } else if (sort.headerName === 'Destino') {
                        valueA = a.selectedDestinations ? a.selectedDestinations.join("\n") : '';
                        valueB = b.selectedDestinations ? b.selectedDestinations.join("\n") : '';
                    } else if (sort.headerName === 'Caminho') {
                        valueA = a.alerts && a.alerts[0] && a.alerts[0].process ? a.alerts[0].process.path : '';
                        valueB = b.alerts && b.alerts[0] && b.alerts[0].process ? b.alerts[0].process.path : '';
                    }
                    break;
                case 'userName':
                    valueA = a.userName;
                    valueB = b.userName;
                    break;
                case 'updatedAt':
                    valueA = a.updatedAt;
                    valueB = b.updatedAt;
                    break;
                default:
                    valueA = '';
                    valueB = '';
            }

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return sort.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                return sort.direction === 'asc' ? valueA - valueB : valueB - valueA;
            } else {
                return 0;
            }
        });

        setFilteredException(sortedException);
    };

    // const loadMore = (size?: number) => {
    //     if (exception && size) setFilteredException(exception.slice(0, size))
    //     else exception && setFilteredException([...filteredException, ...exception.slice(filteredException.length, filteredException.length + 10)])
    // };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);

    const onChagePeriod = (selected: any) => {

    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };


    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading} loadingType='Helix' >
                        <LcInfiniteTable
                            loading={isLoading}
                            columns={columns}
                            rows={filteredException}
                            filter={filterAdvanced}
                            size={exceptionsLength}
                            disableFilterModal={true}
                            loadMore={() => { }}
                            notLoadMore={true}
                            onSortChange={onSortChange}
                            //status={reasonState_Classification}
                            density={pref.data?.density || "high"}
                            hidetopBar
                        />
                    </LcLoading>
                )
            }

        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (exception.length > 0) {
            let filtered = exception.filter(ex => {
                const matchesExceptionId = filter_exceptionId
                    ? ex.exceptionId.toString().toLowerCase().includes(filter_exceptionId.toLowerCase())
                    : true;

                const matchesOriginEventId = filter_originEventId
                    ? ex.originEventId.toString().toLowerCase().includes(filter_originEventId.toLowerCase())
                    : true;

                const matchesProcessName = filter_processName
                    ? ex.alerts && ex.alerts[0] && ex.alerts[0].process && ex.alerts[0].process.name.toString().toLowerCase().includes(filter_processName.toLowerCase())
                    : true;

                const matchesProcessPath = filter_processPath
                    ? ex.alerts && ex.alerts[0] && ex.alerts[0].process && ex.alerts[0].process.path
                        ? ex.alerts[0].process.path.toString().toLowerCase().includes(filter_processPath.toLowerCase())
                        : false
                    : true;

                const matchesAdministrator = filter_administrator
                    ? ex.userName.toString().toLowerCase().includes(filter_administrator.toLowerCase())
                    : true;

                return matchesExceptionId && matchesOriginEventId && matchesProcessName && matchesProcessPath && matchesAdministrator;
            });

            isFilterActive = filtered.length !== exception.length;
            setFilteredException(filtered);
            setExceptionsLength(filtered.length);
        }

        dispatch(setFilterActive(isFilterActive));
    }, [filter_exceptionId, filter_originEventId, filter_processName, filter_processPath, filter_administrator, exception, dispatch]);


    // UseEffect para chamar applyFilter
    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (updatedFilters) => {
        const exceptionIdFilter = updatedFilters.find(f => f.name === 'exceptionId')?.value || '';
        const originEventIdFilter = updatedFilters.find(f => f.name === 'originEventId')?.value || '';
        const processNameFilter = updatedFilters.find(f => f.name === 'processName')?.value || '';
        const processPathFilter = updatedFilters.find(f => f.name === 'processPath')?.value || '';
        const administratorFilter = updatedFilters.find(f => f.name === 'administrator')?.value || '';

        if (exceptionIdFilter !== filter_exceptionId) setFilter_exceptionId(exceptionIdFilter);
        if (originEventIdFilter !== filter_originEventId) setFilter_originEventId(originEventIdFilter);
        if (processNameFilter !== filter_processName) setFilter_processName(processNameFilter);
        if (processPathFilter !== filter_processPath) setFilter_processPath(processPathFilter);
        if (administratorFilter !== filter_administrator) setFilter_administrator(administratorFilter);
    };

    return (
        <Layout
            // periodSelection={periodSelection}
            pageTitle="Exceções"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: exceptionsLength
            }}
        >

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />

            <PPModal title={`Exceção ${selectedDevice?.exceptionId}`} onClose={() => { setModalDetails(false) }}
                visible={modalDetails} >

                <table>
                    <tr><td className='bold pr-3'>Comentários</td><td>{selectedDevice?.comment}</td></tr>
                </table>

                {
                    selectedDevice?.optionalCollectorGroups &&
                    <table>
                        {
                            selectedDevice?.optionalCollectorGroups.map((item, i) => {
                                return <tr><td className='bold pr-3'>{i == 0 ? "Grupos opcionais" : ""}</td><td>{item}</td></tr>
                            })
                        }
                    </table>
                }

                {
                    selectedDevice?.optionalUsers &&
                    <table> {selectedDevice?.optionalUsers.map((item, i) => {
                        return <tr><td className='bold pr-3'>{i == 0 ? "Usuarios opcionais" : ""}</td><td>{item}</td></tr>
                    })
                    }
                    </table>
                }

                {
                    selectedDevice?.selectedCollectorGroups &&
                    <table>
                        {
                            selectedDevice?.selectedCollectorGroups.map((item, i) => {
                                return <tr><td className='bold pr-3'>{i == 0 ? "Grupos selecionados" : ""}</td><td>{item}</td></tr>
                            })
                        }
                    </table>
                }
                {
                    selectedDevice && selectedDevice?.selectedDestinations &&
                    <table>
                        {
                            selectedDevice?.selectedDestinations.map((item, i) => {
                                return <tr><td className='bold pr-3'>{i == 0 ? "Destinos selecionados" : ""}</td><td>{item}</td></tr>
                            })
                        }
                    </table>
                }

                {
                    selectedDevice && selectedDevice?.selectedUsers &&
                    <table>
                        {
                            selectedDevice?.selectedUsers.map((item, i) => {
                                return <tr><td className='bold pr-3'>{i == 0 ? "Usuarios selecionados" : ""}</td><td>{item}</td></tr>
                            })
                        }
                    </table>
                }

            </PPModal>

        </Layout>
    );
};

export default EndpointDetectionResponseExceptions;