import { Action, createStore } from 'redux';
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import { menuReducer, MenuState } from './reducers/menuReducer';
import { userReducer, UserState } from './reducers/userReducer';

export interface MSalState {
    initializing: boolean,
    initialized: boolean,
    idToken: any | null,
    accessToken: any | null,
    accessTokenLC: any | null,
    state: AuthenticationState,
    TokenLC: boolean
};

const initialState = {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
    accessTokenLC: null,
    state: AuthenticationState.Unauthenticated,
    TokenLC: false
};

export const rootReducerMSAL = (state: MSalState = initialState, action: any) => {
    switch (action.type) {
        case AuthenticationActions.Initializing:
            return {
                ...state,
                initializing: true,
                initialized: false,
            };
        case AuthenticationActions.Initialized:
            return {
                ...state,
                initializing: false,
                initialized: true,
            };
        case AuthenticationActions.AcquiredIdTokenSuccess:
            return {
                ...state,
                idToken: action.payload,
            };
        case AuthenticationActions.AcquiredAccessTokenSuccess:
            return {
                ...state,
                accessToken: action.payload.accessToken,
            };
        case AuthenticationActions.AcquiredAccessTokenError:
            return {
                ...state,
                accessToken: null,
            };
        case AuthenticationActions.LoginSuccess:
            return {
                ...state,
                account: action.payload.account,
            };
        case AuthenticationActions.LoginError:
        case AuthenticationActions.AcquiredIdTokenError:
        case AuthenticationActions.LogoutSuccess:
            return { ...state, idToken: null, accessToken: null, account: null };
        case AuthenticationActions.AuthenticatedStateChanged:
            return {
                ...state,
                state: action.payload,
            };
        case "TOKENLC":
            return {
                ...state, TokenLC: true, initializing: true,
            };
        default:
            return state;
    }
};

//export const Store = createStore(
//    rootReducerMSAL,
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
//);
//export const basicReduxStoreMSAL = createStore(
//    rootReducerMSAL,
//    // Enable the Redux DevTools extension if available
//    /// See more: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfiblj
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
//);