import api, { APIClient } from '../api'

export default class EndpointDetectionResponseService extends APIClient {

    constructor(props: any) {
        super(props);
    }


    async GetEvents(device: string, classification: string, severity: string, firstSeenFrom: string, lastSeenFrom: string) {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RetrieveEvents?device=${device}&classification=${classification}&severity=${severity}&firstSeenFrom=${firstSeenFrom}&lastSeenFrom=${lastSeenFrom}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        });
    }

    async GetEventsFree(filter: any) {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RetrieveEvents?refresh=${Math.random()}`;
        return api({
            data: filter,
            method: 'post',
            url,

        });
    }

    async GetAllEvents() {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RetrieveAllEvents?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetStatusChart(anoMesDia: string) {
        const url = this.endpoint + `/api/EndPointSecurity/GetDeviceStatusChart?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetApplications(anoMesDia: string) {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RequestApplications?period=${anoMesDia}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetCollectors(state?: string, deviceName?: string, deviceGroup?: string) {
        let url = this.endpoint + `/api/EndpointDetectionResponse/RequestCollectors?refresh=${Math.random()}`;

        url = url + `&state=${state}`;
        url = url + `&devices=${deviceName}`;
        url = url + `&collectorGroups=${deviceGroup}`;



        return api({
            method: 'get',
            url,
        });
    }

    async GetCollectorsCSV(state?: string, deviceName?: string, deviceGroup?: string) {
        let url = this.endpoint + `/api/EndpointDetectionResponse/RequestCollectorsCSV?refresh=${Math.random()}`;

        url = url + `&state=${state}`;
        url = url + `&devices=${deviceName}`;
        url = url + `&collectorGroups=${deviceGroup}`;

        return api({
            method: 'get',
            url,
        });
    }

    async GetExceptions(state?: string, deviceName?: string, deviceGroup?: string) {
        let url = this.endpoint + `/api/EndpointDetectionResponse/RequestExceptions?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async RequestEventsReportResume() {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RequestEventsReportResume?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async RequestDestination() {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RequestDestination?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetToken() {
        const url = this.endpoint + `/api/EndpointDetectionResponse/GetToken?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        })
    }

    async GetCards(month: string, year: string) {
        const url = this.endpoint + `/api/EndpointDetectionResponse/GetProductLiveWatchSlaTotalSolicitation?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async RetrieveEndpointSecurityMetrics() {
        const url = this.endpoint + `/api/EndpointDetectionResponse/RetrieveEndpointSecurityMetrics?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

}