var editForm = function (chart, inputElement, chartService, originalValue) {
  this.nodeId = null;
  this.chart = chart;
  this.nameInput = inputElement;
  this.chartService = chartService;
  this.treeOriginalValue = originalValue;
};

editForm.prototype.init = function (obj) {
  var that = this;
  this.obj = obj;
  this.editForm = document.getElementById("editForm");
  this.nameInput = document.getElementById("nameField");
  this.cancelButton = document.getElementById("cancel");
  this.saveButton = document.getElementById("save");

  this.cancelButton.addEventListener("click", function () {
    that.hide();
  });

  function save() {
    var node = that.chart.get(that.nodeId);
    node["Nome do centro de custo"] = that.nameInput.value;
    let c = that.treeOriginalValue.find((x) => x.idArray === node.id);
    let p = that.treeOriginalValue.find((x) => x.idArray === node.pid);
    console.debug("c", c);
    console.debug("p", p);

    if (c) {
      c.name = that.nameInput.value;
      c.tag = that.nameInput.value;
      let costCenterSend;
      if (p) {
        costCenterSend = {
          ID: c.id,
          Parent: p.id,
          Main: false,
          Name: c.name,
          ParentName: p.name,
          Budget: c.budget,
          ClientGroupId: c.clientGroupId,
        };
      } else {
        costCenterSend = {
          ID: c.id,
          Parent: 0,
          Main: false,
          Name: c.name,
          ParentName: "",
          Budget: c.budget,
          ClientGroupId: c.clientGroupId,
        };
      }

      console.debug("costCenterSend", costCenterSend);

      // that.chartService.UpdateCostCenter(costCenterSend).then(result => {
      //     if(result.status = 200){
      //         that.chart.update(node);
      //     }
      // })
    } else {
      let newCostCenter;
      if (p) {
        newCostCenter = {
          ID: 0,
          Parent: p.id,
          Main: false,
          Name: that.nameInput.value,
          ParentName: p.name,
          Budget: 0,
          ClientGroupId: p.clientGroupId,
        };
        that.chartService.UpdateCostCenter(newCostCenter).then((result) => {
          if ((result.status = 200)) {
            that.chart.update(node);
          }
        });
      }
      console.debug("newCostCenter", newCostCenter);
    }

    that.hide();
  }
  this.saveButton.addEventListener("click", save);
};

editForm.prototype.show = function (nodeId) {
  this.hide();
  this.nodeId = nodeId;
  var left = document.body.offsetWidth / 2 - 150;
  this.editForm.style.display = "block";
  this.editForm.style.left = left + "px";
  var node = this.chart.get(nodeId);
  this.nameInput.value = node["Nome do centro de custo"]
    ? node["Nome do centro de custo"]
    : "";
};

editForm.prototype.hide = function () {
  this.editForm.style.display = "none";
};
