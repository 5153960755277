import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { FaDatabase, FaDocker, FaNetworkWired, FaServer } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import moment from 'moment';
import LiveWatchServiceGroupService from '../../../../services/LiveWatchServiceGroupService';

interface Props {
    data: Record<string, number>;
    showInfo: boolean;
    setMonth: Function;
    zoomCard?: (category: string) => void; // Atualize a definição de props para incluir zoomCard
}

const IncidentsAccountsByMonth: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [option, setOption] = useState<any>();
    const [infoAccount, setInfoAccount] = useState<[]>([]);

    const transformKeys = (data, clientGroup, description) => {
        const transformedData = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                let newKey = key
                    .replace(new RegExp(`^${clientGroup}-`), '')
                    .replace(new RegExp(`-${description}$`), '')
                    .replace('PROCESSOR-', '');
                transformedData[newKey] = data[key];
            }
        }
        return transformedData;
    };

    const [dataIncidentsPlataform, setDataIncidentsPlataform] = useState<Record<string, number>>();

    useEffect(() => {
        var data =
            transformKeys(
                props.data,
                user.ClientGroupSelected,
                user.ClientGroupSelectedDescription
            );
        setDataIncidentsPlataform(props.data)
        setOption(getChartOptions(data));
    }, [props]);

    const getChartOptions = (data) => {

        const values = Object.values(data);
        const categories = Object.keys(data);

        const colorMap = {
            RDS: '#4FE0FC',
            ECS: '#7B83EB',
            HOSTING: '#28A8EA',
            REDE: '#0364B8',
            'BANCO DE DADOS': '#1A9BA1',
            EDR: '#FF6347',
            PROXY: '#FC8452',
        };

        const option = {
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                top: '15%',
                left: '0%',
                right: '0%',
                bottom: '20%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: categories,
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    name: 'Value',
                    type: 'bar',
                    data: values.map((value, index) => ({
                        value,
                        itemStyle: {
                            color: colorMap[categories[index]],
                        },
                    })),
                },
            ],
            // Adiciona evento de clique no gráfico
            dataZoom: [
                {
                    type: 'inside'
                }
            ],
        };

        return option;
    };

    const handleChartClick = (params) => {
        const category = params.name;
        if (props.zoomCard) {
            props.zoomCard(category);
        }
    };
    const sizeIcons = 20;
    const icons = {
        RDS: <FaDatabase color='#4FE0FC' size={sizeIcons} />,
        ECS: <FaDocker color='#7B83EB' size={sizeIcons} />,
        HOSTING: <FaServer color='#28A8EA' size={sizeIcons} />,
        REDE: <FaNetworkWired color='#0364B8' size={sizeIcons} />,
        'BANCO DE DADOS': <FaDatabase color='#1A9BA1' size={sizeIcons} />,
        EDR: <FaServer color='#FF6347' size={sizeIcons} />,
        PROXY: <FaNetworkWired color='#FC8452' size={sizeIcons} />,
    };

    return (
        <div className='hostAccountsByMonth'>
            <div className='hostAccount'>
                {dataIncidentsPlataform && (
                    <div className='headerLogos row'>
                        {Object.keys(dataIncidentsPlataform).map((key) => (
                            <div key={key} className='row' onClick={handleChartClick}>
                                {icons[key]}
                                <div   >
                                    <h5>{dataIncidentsPlataform[key]}</h5>
                                    <p  >{key}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className='graphAccount'>
                    {option && (
                        <>
                            <ReactECharts
                                className={props.showInfo ? 'normalWidth' : 'fullwidth'}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                style={{ width: '100%', height: '85%' }}
                                option={option}
                                onEvents={{ 'click': handleChartClick }}
                            />
                        </>
                    )}
                    {<div className={`infoAccount ${props.showInfo ? 'show' : 'hidden'} `}>
                        {
                            infoAccount.length > 0 && infoAccount.map(info => {
                                return (<></>
                                    //<div key={info.} className='row mb-5 '>
                                    //    <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px', transform: 'rotate(1deg)' }}></i>
                                    //    <p className='ml-5'><strong className={info.product.toLowerCase()}>{info.product}</strong> {info.query} a quantidade de usuários em {info.porcentagem}% {`de (${info.last} para ${info.current})`}</p>
                                    //</div>
                                )
                            })

                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default IncidentsAccountsByMonth;
