import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Layout from '../../../components/Layout/Layout';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { AiOutlineApple,AiOutlineWindows } from 'react-icons/ai';
import { PiFunnelDuotone, PiFunnelLight, PiInfoLight, PiLinuxLogo, PiProhibit, PiProhibitLight, PiWarningCircleLight } from 'react-icons/pi';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { Patch, PatchDetail } from '../../../services/patchmanager/patchManagerModel';
import { PPModal } from 'processor-plataform-ui';
import LcLoading from '../../../components/Generic/LcLoading';
import FilterPanel from '../../../components/Data/FilterPanel';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcNoData from '../../../components/Generic/LcNoData';


export const translationServerity =(severity: string) => {
    const severityTranslation = {
        "Crítico": 4,
        "Importante": 3,
        "Moderado": 2,
        "Baixo": 1,
        "Não Classificado":0,
        "Não classificado":0,
    }
    return severityTranslation[severity]
}

const PatchListPathManager: React.FC = (props) => {
    const patchmanagerService = new PatchManagerService(props);
    const [rowsPatcheSelected, setRowsPatcheSelected] = useState<PatchDetail[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState<boolean>(false);
    const [showFilterPanelRowsPatches, setShowFilterPanelRowsPatches] = useState<boolean>(false);
    const [rowsPatchesFiltered, setRowsPatchesFiltered] = useState<Patch[]>([]);
    const [ clearFilter, setClearFilter ] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true)

    const { data: rowsPatches = [] } = useQuery('patches', async() => {
        setLoading(true)
        const response =  await patchmanagerService.GetPatches();
        return response;
    },{
        keepPreviousData: true, 
        refetchOnWindowFocus: false,
        onSuccess: data => {
            setRowsPatchesFiltered(data)
            setLoading(false)
        }
    } 
);

    const loadDetailsData = async (row: Patch, type?: number) => {
        setOpenModal(true);
        setLoadingModal(true);
        let detailslist = await patchmanagerService.GetAllPatchDetails(Number(row.patch_id));
        if (type !== undefined) {
            detailslist = type === 206
                ? detailslist.filter(detail => detail.deploy_remarks_args?.toLowerCase().includes("fail"))
                : detailslist.filter(detail => detail.affected_patch_status === type);
        }
        setRowsPatcheSelected(detailslist);
        setLoadingModal(false);
    };

    const ColumnsPatches = [
        { field: "patch_id", headerName: "Patch Id", width: "8%", align: 'left' },
        {
            field: "severity", headerName: "Severidade", width: "15%", align: 'left',
            renderCell: (row: Patch) => (
                <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: patchmanagerService.translateSeverityColor(row.severity) }}>
                    {patchmanagerService.translateSeverityLabel(row.severity)}
                </div>
            )
        },
        {
            field: "patch_description", headerName: "Descrição do patch", width: "40%", align: 'left',
            renderCell: (row: Patch) => <div className='overflowEllipsis'>{row.patch_description}</div>
        },
        {
            field: "installed", headerName: "Instalados", width: "8%", align: 'center',
            renderCell: (row: Patch) => (
                <div onClick={() => loadDetailsData(row, 201)} style={{ color: 'var(--text-link)', fontWeight: 400, textDecoration: 'underline', cursor: 'pointer' }}>
                    {row.installed}
                </div>
            )
        },
        {
            field: "missing", headerName: "Ausentes", width: "8%", align: 'center',
            renderCell: (row: Patch) => (
                <div onClick={() => loadDetailsData(row, 202)} style={{ color: 'var(--text-link)', fontWeight: 400, textDecoration: 'underline', cursor: 'pointer' }}>
                    {row.missing}
                </div>
            )
        },
        // {
        //     field: "failed", headerName: "Com falha", width: "8%", align: 'center',
        //     renderCell: (row: Patch) => (
        //         <div onClick={() => loadDetailsData(row, 206)} style={{ color: '#00aee1', fontWeight: 600, cursor: 'pointer' }}>
        //             {row.failed}
        //         </div>
        //     )
        // },
        {
            field: "platform_name", headerName: "Plataforma", width: "10%", align: 'left',
            renderCell: (row: any) => (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                    {row.platform_name && (
                        <LcIconLink className='notHover' icon={
                            row.platform_name.includes('Windows') ? <AiOutlineWindows size={'1rem'} />
                                : row.platform_name.includes('mac') ? <AiOutlineApple size={'1rem'} />
                                    : <PiLinuxLogo size={'1rem'} />
                        } />
                    )}
                    <span>{row.platform_name}</span>
                </div>
            )
        },
        {
            field: "patch_noreboot", headerName: "Reiniciar", width: "10%", align: 'center', overflow: "visible",
            renderCell: (row: any) => (
                ((row.patch_noreboot) > 0)
                    ? <LcIconLink size='small' icon={<PiInfoLight color='#ffb530' size={18} />} tooltip={"Necessário reiniciar: " + row.patch_noreboot} tooltipPosition='right' />
                    : <div>-</div>
            )
        },
    ];

    function capitalizeFirstLetter(str) {
        if (str.length === 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const detailsPatches = [
        {
            field: "resource_name", headerName: "Nome do Computador", width: "15%", align: 'left',
            renderCell: (row: PatchDetail) => capitalizeFirstLetter(row.resource_name)
        },
        { field: "domain_netbios_name", headerName: "Domínio", width: "20%", align: 'left' },
        { field: "os_name", headerName: "Sistema operacional", width: "30%", align: 'left' },
        { field: "patch_id", headerName: "Patch Id", width: "10%", align: 'left' },
        {
            field: "resource_health_status", headerName: "Status da instalação", width: "15%", align: 'center', overflow: 'visible',
            renderCell: (row: PatchDetail) => (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                    <p style={{ color: row.affected_patch_status=== 202 ? 'var(--status-red)' :  row.affected_patch_status === 201 ? 'var(--status-green)' : 'var(--status-yellow)' }}>
                        {
                        row.affected_patch_status === 202 ?"Não instalado"
                        : row.affected_patch_status === 201 ? 'Instalado' 
                        : "Falha"
                    }
                    </p> 
                </div>
            )
        },
        {
            field: "severity", headerName: "Severidade", width: "11%", align: 'center',
            renderCell: (row: PatchDetail) => (
                <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: patchmanagerService.translateSeverityColor(row.severity) }}>
                    {patchmanagerService.translateSeverityLabel(row.severity)}
                </div>
            )
        }
    ];

    const translationsInstalled = {
        patch_id: { label: "Id do patch", type: "multiSelect" },
        severity: {
            label: "Severidade",
            type: "multiSelect",
            values: {
            4: "Crítico",
            3: "Importante",
            2: "Moderado",
            1: "Baixo",
            0: "Não Classificado",
            },
        },
        patch_description: { label: "Descrição do patch", type: "text" },
        platform_name: { label: "Plataforma", type: "multiSelect" },
        patch_noreboot: {
            label: "Qtd sistemas para reiniciar",
            type: "multiSelect",
            topDropdown: true,
        },
    };

    const filterPatches = () => (
        <div className="right-sidepanel-filters">
            <FilterPanel
                data={rowsPatches}
                onFilteredData={(filteredData) =>{
                    setClearFilter(false); 
                    setRowsPatchesFiltered(filteredData as Patch[])
                }}
                clearFilters={() => {setClearFilter(true);setRowsPatchesFiltered(rowsPatches)}}
                translations={translationsInstalled} 
                filter={Object.keys(translationsInstalled).map(key => ({ 
                    label: translationsInstalled[key].label,
                    name: key,
                    type: translationsInstalled[key].type,
                    values: translationsInstalled[key].values,
                }))}      
            />
        </div>
    );

    const cards: Card[] = [
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => (
                <LcLoading loading={loading} loadingType='Helix' label='Carregando...' >
                    {
                        rowsPatchesFiltered.length > 0 ?
                        <LcInfiniteTable
                            rows={rowsPatchesFiltered}
                            columns={ColumnsPatches}
                            notLoadMore
                            size={25}
                            hidePagination
                            fontSize='medium'
                            status={patchmanagerService.tranleteStatusColorRow}
                        />
                        :
                        <LcNoData size="small" label='Não há dados para serem exibidos no momento.' />
                    }
                </LcLoading>
            )
        }
    ];

    const items = [
        {
            icon: clearFilter ? <PiFunnelLight color='var(--icon-default)' /> : <PiFunnelDuotone color='var(--color-secondary-lime)' className='notHover' />,
            tooltip: "Filtro",
            onClick: () => setShowFilterPanelRowsPatches(!showFilterPanelRowsPatches),
            clickble: showFilterPanelRowsPatches,
            color: clearFilter ? '' : 'var(--color-secondary-lime)'
        }
    ];
    return (
        <Layout
            pageTitle='Patches'
            functionsGeneric={items}
            row={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                    <div style={{ fontWeight: 700 }} className='size'>{rowsPatchesFiltered.length}</div>
                    &nbsp;
                    <span> Patches</span>
                </div>
            }
        >
            <LCDashboard
                cards={cards}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: showFilterPanelRowsPatches,
                    close:(e) => setShowFilterPanelRowsPatches(e),
                    content: () => filterPatches()
                }}
            />
            <PPModal visible={openModal} onClose={() => setOpenModal(false)} size='large' title="Detalhes do patch">
                <LcLoading loading={loadingModal} label='Carregando patches...'>
                    <LcInfiniteTable
                        rows={rowsPatcheSelected}
                        columns={detailsPatches}
                        loadMore={() => undefined}
                        size={rowsPatcheSelected.length}
                        density='high'
                        hidePagination
                        status={patchmanagerService.tranleteStatusColorRowDetails}
                    />
                </LcLoading>
            </PPModal>
        </Layout>
    );
};

export default PatchListPathManager;
