import React, { useCallback } from 'react';

import './index.css';

interface Props {
    toggled: boolean,
    onChange?: (event: { name?: string, checked: boolean }) => void,
    title?: string,
    name?: string,
    disabled?: boolean,
    color?: string,
}

const LcToggle: React.FC<Props> = ({ toggled, onChange, title, name, disabled, color }) => {

    const handleCheck = useCallback(() => {
        if (!disabled && onChange) {
            onChange({ name, checked: !toggled });
        }
    }, [disabled, onChange, name, toggled]);

    return (
        <div className={`input-toggle ${toggled ? 'toggled' : ''}`} onClick={handleCheck}>
            <div className="box" style={{ backgroundColor: toggled ? color : '' }}>
                <div className="dot" />
            </div>
            {title && <span className="title">{title}</span>}
        </div>
    );
}

export default React.memo(LcToggle);