import React from 'react';

import './index.css';

interface Props {
    items: { label: string, onClick: Function, disabled?: boolean }[],
    activeItem: number
}

const LcTabMenu: React.FC<Props> = (props) => {

    return (
        <div className="lc-tabmenu">
            <div className="items">
                {
                    props.items.map((i, key) => {
                        return (
                            <div key={key} className={`item${props.activeItem == key ? ' active' : ''}`} onClick={() => i.onClick()}>
                                {i.label}
                                <div className="tag" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="right">
                {props.children}
            </div>
        </div>
    );
}

export default LcTabMenu;