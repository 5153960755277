import React from "react";

import "./index.css";
import ReactECharts from 'echarts-for-react';
import "echarts/i18n/langPT-br";

import { FinOpsChartService } from "../../../../services/finOps/finOpsChartService";


interface GaugeProps {
  value: number,
  title: string
  onClick?: Function,
  style?: Object
  className?: string,
}

const GaugeChartCore: React.FC<GaugeProps> = (props) => {
  const finOpsChartService: FinOpsChartService = (new FinOpsChartService(props));
    return (
    <ReactECharts
    className={`gaugeChart ${props.className ? props.className : ''}`}
    style={props.style ? props.style : {} }
    opts={{ renderer: 'svg', locale: 'PT-br' }}
    option={finOpsChartService.getGaugeChart(props.value, props.title, props.style ? props.style : {})}
    />
    
  );
};

export default GaugeChartCore;
