import { makeStyles, Tooltip } from '@material-ui/core';
import Grid, { GridSize, GridItemsAlignment, GridJustification, GridDirection } from '@material-ui/core/Grid';
import React from 'react';

interface Props {
    alignItems?: GridItemsAlignment,
    justify?: GridJustification,
    direction?: GridDirection,
    sm?: number,
    md?: number,
    lg?: number,
    xs?: number,
    tooltip?: string,
}
const LCFlexItem: React.FC<Props> = (props) => {
    const useStyles = makeStyles(theme => ({
        arrow: {
            color: "#E2E6F3"
        },
        tooltip: {
            backgroundColor: "#E2E6F3",
            color: "#3E3F43",
            fontSize: '11px'
        }
    }));
    let classes = useStyles();
    return (
        <>
            {props.tooltip ?
                <Tooltip title={props.tooltip} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                    <Grid item sm={props.sm as GridSize} md={props.md as GridSize} lg={props.lg as GridSize} xs={props.xs as GridSize} alignItems={props.alignItems} direction={props.direction}>
                        {props.children}
                    </Grid>
                </Tooltip>
                :
                <Grid item sm={props.sm as GridSize} md={props.md as GridSize} lg={props.lg as GridSize} xs={props.xs as GridSize} alignItems={props.alignItems} direction={props.direction}>
                    {props.children}
                </Grid>
            }
        </>
    );
}

export default LCFlexItem;