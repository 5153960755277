import React, { useEffect, useState } from "react";
import { AppBar, Box, Tabs, Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import Layout from "../../../components/Layout/Layout";
import { LCTechService } from "../../../services/LCTech/LCTechService";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { UserState } from "../../../store/reducers/userReducer";
import { PrefState } from '../../../store/reducers/prefReducer';

import LcLoading from "../../../components/Generic/LcLoading";
import { TechEAEASService } from "../../../services/EASubscriptionService/techEAEASService";
import Moment from 'moment';

//Img Fluxo
import { SaasService } from "../../../services/saas/saasService";
import ImgFluxoEAEAS from "../../../assets/images/Fluxos/eaeas.png";
import ImgFluxoCSP from "../../../assets/images/Fluxos/csp.png";
import LcTable from "../../../components/Data/LcTable";
import LCPivotTable from "../../../components/Data/pivotTable";
import { useSnackbar } from 'notistack';

import { SaasUserService } from "../../../services/SaasUserService";
import exportFromJSON from "export-from-json";
import { MenuState } from "../../../store/reducers/menuReducer";
import LcDropDown from "../../../components/Generic/LcDropDown";


import PivotTableWebix from "../../../components/webix";
import { PiDownload, PiDownloadLight, PiEye, PiEyeLight, PiEyeSlashLight, PiInfoLight } from "react-icons/pi";

const ServiceInstance = new LCTechService({});

const LCTechUsage: React.FC = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const SaaS_Service = new SaasUserService(props);

    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const history = useHistory();

    const dispatch = useDispatch();

    const [consumoToUpper, setConsumoToUpper] = useState(true);

    function sCdateToJsDate(cSDate: string): Date {
        if (!cSDate) {
            return new Date();
        }

        // Parse the string directly into a Date object
        const date = new Date(Date.parse(cSDate));

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date format');
        }

        return date;
    }


    const [value, setValue] = useState(1);
    const [optionsData, setOptionsData] = useState<any>();
    const user = useSelector<RootState, UserState>((state) => state.user);

    const [loading, setLoading] = useState(false);
    const [isCSP, setIsCSP] = useState(false);
    const [month, setMonth] = useState<string>(((new Date()).getMonth() + 1).toString());
    const [year, setYear] = useState<string>((new Date()).getFullYear().toString());
    const [isSaaS, setIsSaaS] = useState(false);
    const [footer, setFooter] = useState<string>('-');
    const [lastModifiedDate, setLastModifiedDate] = useState<Date>();
    const [resourceUsagePivotRecords, setResourceUsagePivotRecords] = useState<any[]>([]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },

        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    let formato = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

    const RetrieveList = () => {
        var date = new Date();
        //      setPivotTableResourceFilter({ aggregatorName: "Sum" } as any as Pivot as PivotTableUIProps);

        !month && setMonth((date.getMonth()).toString());
        !year && setYear(date.getFullYear().toString());

        let optionsDataX: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: '2-digit' };
        setOptionsData(optionsDataX);

        setValue(1);
        setFooter('');
        setLoading(true);
        ServiceInstance.RetrieveUsage(1, consumoToUpper, month, year,)
            .then((response) => {
                setLoading(true);
                setResourceUsagePivotRecords([]);
                if (response && response.data) {
                    try {
                        setIsCSP(response.data.isCSP);
                        var sar = { lastModifiedDate: '' };

                        try {
                            if (
                                response.data.resourceUsageRecords &&
                                response.data.resourceUsageRecords.length > 0
                            ) {
                                //  setResourceUsagePivotRecords([]);
                                let resourcePivot: any[] = [];
                                response.data.resourceUsageRecords.map((resourceUsage) => {
                                    //let record = {
                                    //    "Assinatura": resourceUsage.entitlementName,
                                    //    "Grupo do Recurso": resourceUsage.resourceGroupName,
                                    //    "Recurso": resourceUsage.resourceName,
                                    //    "Categoria": resourceUsage.meterCategory,
                                    //    "SubCategoria": resourceUsage.meterSubCategory,
                                    //    "Mês Consumo": ,
                                    //    "Dia Consumo": sCdateToJsDate(resourceUsage.usageDate).getDate(),
                                    //    "Tags": resourceUsage.tag,
                                    //    "Moeda": resourceUsage.currencyCode,
                                    //    "Total": resourceUsage.totalCost && resourceUsage.totalCost.toFixed(6),
                                    //    "Unidade": resourceUsage.unitOfMeasure,
                                    //    "Quantidade": parseInt(resourceUsage.quantityUsed),
                                    //    "Nome do Medidor": resourceUsage.meterName 
                                    //};
                                    resourceUsage["month"] = sCdateToJsDate(resourceUsage.usageDate).getMonth() + 1;
                                    resourceUsage["day"] = sCdateToJsDate(resourceUsage.usageDate).getDate();
                                    resourceUsage["year"] = sCdateToJsDate(resourceUsage.usageDate).getFullYear();
                                    resourceUsage["quantityUsed"] = parseInt(resourceUsage.quantityUsed);

                                    resourcePivot.push(resourceUsage);
                                });
                                setResourceUsagePivotRecords(resourcePivot.sort((a, b) => (a.year + a.month + a.day) - (a.year + b.month + a.day)));

                            }
                        } catch (e) {
                            console.log('FooterError');
                        }

                        setValue(1);
                        try {

                            let optionsDataX: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: '2-digit' };

                            try {

                                var lastModifiedDateXZ = sCdateToJsDate(sar.lastModifiedDate);
                                if (lastModifiedDateXZ) {
                                    var date = Moment(lastModifiedDateXZ);
                                    var firstDay = new Date(date.year(), date.month(), 1);
                                    var Day = lastModifiedDateXZ;
                                    setFooter(`\n\nPeríodo de apuração: ${firstDay.toLocaleDateString('pt-BR', optionsDataX)} a ${Day.toLocaleDateString('pt-BR', optionsData)}. Valores estimados, sujeitos a validação e politicas de contabilização do vendor. Sync ${Day.toLocaleDateString('pt-BR', optionsDataX)}`);
                                }
                            } catch (e) {
                                setLastModifiedDate(new Date());
                                if (lastModifiedDate) {
                                    var date = Moment(lastModifiedDate).add(-1, 'M');
                                    var firstDay = new Date(date.year(), date.month(), 1);
                                    var Day = lastModifiedDate;
                                    setFooter(`\n\nPeríodo de apuração: ${firstDay.toLocaleDateString('pt-BR', optionsDataX)} a ${Day.toLocaleDateString('pt-BR', optionsData)}. Valores estimados, sujeitos a validação e politicas de contabilização do vendor. Sync ${Day.toLocaleDateString('pt-BR', optionsDataX)}`);
                                }
                            }

                        } catch (e) {
                            console.log('FooterError');
                        }

                    } catch (e) { console.log('FooterError'); }
                }
            })
            .catch((error) => {

                console.error(error);
            })
            .finally(() => {
                setValue(1);
                setLoading(false);
            });
    };

    const columnsResourceUsageRecords = [
        {
            field: "entitlementName", headerName: "Assinatura", width: "15%", align: "left", overflow: "auto"
        },
        {
            field: "resourceGroupName", headerName: "Grupo do Recurso", width: "15%", align: "left",
        },
        {
            field: "resourceName", headerName: "Recurso", width: "25%", align: "left", fontSize: "small",
            renderCell: (row: any) => (
                <div style={{ wordBreak: "break-word" }}>{row.resourceName}</div>
            )
        },
        {
            field: "totalCost", headerName: "Total", width: "10%", overflow: "auto",
            align: "right",
            renderCell: (row: any) => (
                <div>{(row.totalCost && row.totalCost.toLocaleString('pt-BR', formato))} </div>
            ),
        },
        {
            field: 'tags', headerName: 'Tags ', width: "25%", overflow: "auto", fontSize: "small",
            renderCell: (row: any) => {
                return <div style={{ wordBreak: "break-word" }}>{row.tags}</div>;
            }
        }
    ];

    useEffect(() => {
        if (!month && !year) {
            return;
        }
        setLoading(true);
        RetrieveList();
    }, [user, user.ClientGroupSelected, user.refreshFlag, month, consumoToUpper]);


    //#region Verifica se há licença EA/EAS
    let instance = new TechEAEASService(props);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState<boolean>(false);

    useEffect(() => {
        menu && menu.selectedItem && menu.selectedItem.id == "cedcb443-a23e-4545-904b-5d1061e718aa" &&
            instance
                .GetSaasLicenseByCountryTotalView()
                .then((response) => {
                    setShowFluxoEAEAS(response.data.length == 0);
                })
                .catch((error) => {
                    setShowFluxoEAEAS(true);
                });
    }, [menu.selectedItem]);
    //#endregion

    //#region Verifica se há licença EA/EAS
    const saasService = new SaasService(props);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id == "67aa56e4-ad63-44b0-aa27-d2d2a8665a63") {
            saasService
                .GetbyClientGroupIdAndTileId("13")
                .then((response) => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        if (menu && menu.selectedItem && menu.selectedItem.id == "F07FD64E-3766-4551-B70A-811390EEED96") setIsSaaS(true);
    }, [menu.selectedItem]);
    //#endregion
    function translateKey(key: string): string {
        const translations: { [key: string]: string } = {
            "customerName": "Empresa",
            "entitlementName": "Assinatura",
            "resourceGroupName": "Grupo do Recurso",
            "resourceName": "Recurso",
            "meterCategory": "Categoria",
            "meterSubCategory": "SubCategoria",
            "tag": "Tags",
            "unitOfMeasure": "Unidade",
            "currencyCode": "Moeda",
            "meterName": "Nome do Medidor",
            "year": "Ano Consumo",
            "month": "Mês Consumo",
            "day": "Dia Consumo",
            "quantityUsed": "Quantidade",
            "totalCost": "Total"
        };

        return translations[key] || key;
    }
    const exportToCSV = () => {
        enqueueSnackbar(`Gerando arquivo`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });

        const filters = pivotCheckFilters();
        console.log(filters)
        const data = resourceUsagePivotRecords.map((record) => {
            const item: any = {};

            filters.rows.forEach((row: string) => {
                if (record.hasOwnProperty(row)) {
                    item[translateKey(row)] = record[row];
                }
            });
            filters.columns.forEach((column: string) => {
                if (record.hasOwnProperty(column)) {
                    item[translateKey(column)] = record[column];
                }
            });
            filters.values.forEach((column: any) => {
                if (record.hasOwnProperty(column.name)) {
                    item[translateKey(column.name)] = record[column.name];
                }
            });
            return item;
        });

        const fileName = 'LCTechCSPUsage';
        const exportType = 'csv';
        const withBOM = true;
        const fields = [...filters.rows.map(translateKey), ...filters.columns.map(translateKey), ...filters.values.map(val =>translateKey(val.name))];
        exportFromJSON({
            data: data,
            fields: fields,
            fileName: fileName,
            exportType: exportType,
            withBOM: withBOM,
            delimiter: ';',
        });


        enqueueSnackbar(`Arquivo gerado com sucesso`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        });
    };

    const pivotCheckFilters = () => {
        if (localStorage !== null) {
            for (let i = 0; i < localStorage.length; i++) {
                let key: string = localStorage.key(i) || '';
                let value = localStorage.getItem(key) && JSON.parse(localStorage.getItem(key) || '');
                if (key.match('CSP/LCTechUsage')) {
                    return value
                }
            }
        }
    }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);

    return (
        <Layout
            pageTitle={ServiceInstance.title}
            periodSelection={periodSelection}
            pivot
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            functionsGeneric={[{
                icon: <PiInfoLight/>,
                tooltip: () => {
                    return <div className="">
                        {
                            (footer)
                            && < div style={{ width: '90%', height: '25px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'var(--text-default)', fontSize: '8pt' }}>
                                    {footer}
                                </span>
                            </div>
                        }
                    </div>
                },
                onClick: () => null
            },
            { icon: <PiDownloadLight />, onClick: () => exportToCSV(), tooltip: "Exportar para .csv" },
            { icon: consumoToUpper ? <PiEyeLight /> : <PiEyeSlashLight />, onClick: () => setConsumoToUpper(!consumoToUpper), tooltip: consumoToUpper ? "Visualizar padrão Microsoft" : "Não visualizar padrão Microsoft" },
            ]}
        >
            <LcLoading loading={loading} loadingType="Helix" >
            {showFluxoEAEAS ? (
                <div className="lc-segment" style={{ width: '100%' }}>
                    <div className="body" style={{ width: '100%' }}>
                        Para o funcionamento completo da solução é necessário que todos os
                        passos do fluxo abaixo estejam corretamente executados, no
                        momento, não foi possível acessar todas as informações, por favor,
                        revise os passos abaixo. Caso precise de ajuda abra uma
                        requisição, clicando {" "}
                        <p
                            className="link text-link"
                            onClick={() => {
                                history.push("/Chamados");
                                dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                            }}
                        >
                            aqui
                        </p>
                        , e teremos o prazer em atender.
                        <img src={ImgFluxoEAEAS} width="100%" alt="Procedimento" />
                    </div>
                </div>
            ) : showFluxoCSP ?
                (
                    <div className="lc-segment">
                        <div className="body">
                            Para o funcionamento completo da solução é necessário que todos os
                            passos do fluxo abaixo estejam corretamente executados, no
                            momento, não foi possível acessar todas as informações, por favor,
                            revise os passos abaixo. Caso precise de ajuda abra uma
                            requisição, clicando{" "}
                            <a className="link text-link"
                                onClick={() => {
                                    history.push("/Chamados");
                                    dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                                }}
                            >
                                aqui
                            </a>
                            , e teremos o prazer em atender.
                            <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                )
                :
                (<div style={{ minWidth: '100%' }}>
                        {(resourceUsagePivotRecords != null && resourceUsagePivotRecords.length > 0) && value == 1 &&
                            <PivotTableWebix
                                style={{ minWidth: '100%' }}
                                idView={month}
                                key={month} data={resourceUsagePivotRecords}
                                fields={[
                                    { id: "customerName", value: "Empresa", type: "text" },
                                    { id: "entitlementName", value: "Assinatura", type: "text" },
                                    { id: "resourceGroupName", value: "Grupo do Recurso", type: "text" },
                                    { id: "resourceName", value: "Recurso", type: "text" },
                                    { id: "customerName", value: "Empresa", type: "text" },
                                    { id: "meterCategory", value: "Categoria", type: "text" },
                                    { id: "meterSubCategory", value: "SubCategoria", type: "text" },
                                    { id: "tag", value: "Tags", type: "text" },
                                    { id: "unitOfMeasure", value: "Unidade", type: "text" },
                                    { id: "currencyCode", value: "Moeda", type: "text" },
                                    { id: "meterName", value: "Nome do Medidor", type: "text" },
                                    { id: "year", value: "Ano", type: "text" },
                                    { id: "month", value: "Mês", type: "text" },
                                    { id: "day", value: "Dia", type: "number" },
                                    { id: "quantityUsed", value: "Quantidade", type: "number" },
                                    { id: "totalCost", value: "Total", type: "number" },
                                ]}
                                structure={{
                                    rows: ["customerName", "entitlementName", "resourceGroupName", "currencyCode"],
                                    columns: ['year', 'month'],
                                    values: [{ name: "totalCost", operation: ["sum"] }]
                                }}
                            />
                        }
                </div>
                )}
            </LcLoading>
        </Layout >
    );
};

export default LCTechUsage;
