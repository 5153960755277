import React, { useEffect, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { PiShieldCheckLight } from 'react-icons/pi';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { ExceptionModal } from '../resumeEvent/Models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

interface LiberationCardModel {
    periodSelected: string;
}

export const LiberationCard: React.FC<LiberationCardModel> = ({
    periodSelected
}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loadingExceptions, setLoadingExceptions] = useState<boolean>(true);
    const [exception, setException] = useState<ExceptionModal[]>([]);
    const [totalExcections, setTotalExcections] = useState<number>(0);

    const getExcetions = async () => {
        setLoadingExceptions(true)
        try {
            const endpointDetectionResponseService = new EndpointDetectionResponseService({});
            const response = await endpointDetectionResponseService.GetExceptions()
            setException(response.data);
            setTotalExcections(response.data.length);
        } catch (error) {
            console.error(error);
        } finally{
            setLoadingExceptions(false);
        }
    }
    
    const filterArrayPerDate = (array: ExceptionModal[] , period: number) => {
        let today = new Date();
        let daysAgoDate = new Date();
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0); // Define horas, minutos, segundos e milissegundos para 0
        let datasNosUltimosDias = array.filter(data => {
            return 'createdAt' in data && (data as ExceptionModal).createdAt && new Date((data as ExceptionModal).createdAt) >= daysAgoDate && new Date((data as ExceptionModal).createdAt) <= today;
        });
        return datasNosUltimosDias
    }

    useEffect(() => {
        getExcetions();
    },[ user.ClientGroupSelected, user.ClientGroupSelectedDescription, user.refreshFlag])

    useEffect(() => {
        if (!periodSelected) return;
    
        const daysMap = {
            'last7Days': 7,
            'last30Days': 30,
            'last90Days': 90,
            'alltime': null
        };
    
        const days = daysMap[periodSelected as keyof typeof daysMap];
    
        if (days === undefined) {
            console.warn(`Unexpected period value: ${periodSelected}`);
            return;
        }
        const exceptions = days === null ? exception : filterArrayPerDate(exception, days);
        setTotalExcections(exceptions.length)
    },[exception, periodSelected])
    
    return(
        <LcLoading loading={loadingExceptions}>
        <div style={{minHeight: '60px'}}>
            <IconValue
                icon={<PiShieldCheckLight  size={32} />}
                value={totalExcections}
                color='var(--status-green)'
            />
        </div>
    </LcLoading>
    );
}
