export class merito {
    solution() {
        return 'merito'
    }

    site() {
        return 'https://www.gotobiz.com.br/merito'
    }

    logotipo() {
        return require('./assets/logotipos/logo_merito.svg').default
    }
    titulo() {
        return <>Melhor que engajamento é ter profissionais apaixonados pelo que fazem</>
    }

    chamada() {
        return <>Mérito contribui para tornar profissionais em embaixadores da marca, aumenta a motivação das equipes, empodera profissionais e traz melhores resultados para o negócio.</>
    }

    image() {
        return require('./assets/imgs/imagem_merito.png').default
    }

    detalhes() {
        return <>
            <h2>Mérito comunica, capacita, engaja e promove a cultura viva da organização</h2>
            <h3>Por que escolher Mérito?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_merito.png').default} />
                </div>
                <ul>
                    <li>Aumenta a produtividade e eficiência operacional</li>
                    <li>Otimiza investimentos</li>
                    <li>Simplifica a transformação digital fluida</li>
                    <li>Empodera pessoas, otimiza processos e inova relações</li>
                </ul>
            </div>
            <div className="box"><b>Torne profissionais em embaixadores da marca</b> e amplie o alcance e a credibilidade da organização nas redes sociais. Divulgue campanhas de marketing, novos produtos, vagas e muito mais, multiplicando a presença digital da organização.</div>
            <h3>Características principais</h3>
            <p>Mérito combina diversos recursos como engajamento, insights, comunicação, informação, capacitação e cultura viva para potencializar o negócio.</p>            
            <h3>Com Mérito a organização pode</h3>
            <ul>
                <li><b>Estimular a força de vendas:</b> Capacita, informa e conecta para que todos possam vender mais.</li>
                <li><b>Ter um canal para centralizar a comunicação interna:</b> Com inúmeros recursos, faça a comunicação certa, para as pessoas certas na hora certa, onde a equipe estiver. O Mérito ajuda a manter todos informados.</li>
                <li><b>Promover internamente um produto:</b> Os colaboradores podem ser rapidamente capacitados para lançamentos de produtos, campanhas, atividades culturais, eventos, entre outros.</li>
            </ul>
            <h3>Não perca mais tempo</h3>
            <p>Quer saber mais? Entre em contato e descubra como Mérito pode potencializar a organização.</p>            
        </>
    }
}
export default merito;