import api, { APIClient } from "./api";

export interface PivotView {
	viewKey: string;
	viewRoute: string;
	clientGroupId: string;
	viewCSPTag: string;
	viewJson: string;
	viewLabel: string;
	ownerUserMail: string;
	ownerUserName: string;
	rowKey: string;
	partitionKey: string;
	active?: boolean;
}

export interface PivotViewRequest {
	viewLabel: string;
	newViewLabel?: string;
	viewRoute: string;
	viewCSPTag: string | null;
	viewJson: string;
	partitionKey?: any,
	rowKey?: string
	viewKey?: string
}


export default class WebixPivotTableService extends APIClient {

	async ListPivotViews(): Promise<PivotView[]> {
		try {
			const url = this.endpoint + `/api/FinOps/ListPivotViews?&refresh=${Math.random()}`;
			return (await api({ method: 'get', url, })).data;
		} catch (e) {
			return [] as PivotView[];
		}
	}

	async SavePivotView(data: PivotViewRequest) {
		try {
			const url = this.endpoint + `/api/FinOps/SavePivotView`;
			await api({ method: 'post', url, data });
		} catch (e) {
			throw (e);
		}
	}

	async DisablePivotView(key: string) {
		try {
			const url = this.endpoint + `/api/FinOps/DisablePivotView?&key=${key}`;
			await api({ method: 'delete', url });
		} catch (e) {
			throw (e);
		}
	}

}