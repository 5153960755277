import api, { APIClient } from "../api";

export class FinopsRecommendationsService extends APIClient {
    constructor(props: any) {
        super(props);
    }

    async GetRecommendationsDashboard() {
        try {
            const url = this.endpoint + `/api/FinOpsRecommendations/GetRecommendationsDashboard`

            let result = await api({
                method: 'get',
                url,
            });

            return result.data
        } catch (error) {

        }
    }

    async GetAllRecommendationsInfos() {
        try {
            const url = this.endpoint + `/api/FinOpsRecommendations/GetAllRecommendationsInfos`

            let result = await api({
                method: 'get',
                url,
            });

            return result.data
        } catch (error) {

        }
    }

    async GetRecommendation(optimizationName?: string): Promise<Info_Items_Options[]> {
        try {
            const url = this.endpoint + `/api/FinOpsRecommendations/GetRecommendation${optimizationName ? 'optimizationName=optimizationName' : ''}`
            let result = await api({
                method: 'get',
                url,
            });
            let resultList: Info_Items_Options[] = result.data
            return resultList
        } catch (error) {

        }
        return [] as Info_Items_Options[];
    }
}

export interface Info_Items_Options {
    infos?: Basic_Optimization_Info;
    optimization_name: string;
    items?: Optimization_Items[];
    options?: Optimizations_Options;
}

export interface Basic_Optimization_Info {
    optimizations_name: string;
    count: number;
    saving: number | null;
    limit: number;
}

export interface Optimizations_Options {
    optimization_name: string;
    days_threshold?: number;
    cpu_percent_threshold?: number;
    network_bps_threshold?: number;
    excluded_flavor_regex?: string;
    recommended_flavor_cpu_min?: number;
    data_size_threshold?: number;
    tier_1_request_quantity_threshold?: number;
    tier_2_request_quantity_threshold?: number;
    metric_type?: string;
    metric_limit?: number;
    insecure_ports?: InsecurePort[];
}

export interface InsecurePort {
    optimization_name: string;
    port: string;
    protocol: string;
    itemId: number;
}

export interface Optimization_Items {
    itemId: number;
    optimization_name: string;
    owner_id?: string;
    owner_name?: string;
    pool_id?: string;
    pool_name?: string;
    pool_purpose?: string;
    resource_id?: string;
    resource_name?: string;
    cloud_resource_id?: string;
    region?: string;
    cloud_account_id?: string;
    cloud_type?: string;
    is_excluded?: boolean;
    saving?: number;
    detected_at?: number;
    cloud_account_name?: string;
    average_saving?: number;
    flavor?: string;
    recommended_flavor?: string;
    saving_percent?: number;
    current_cost?: number;
    recommended_flavor_cost?: number;
    cpu?: number;
    recommended_flavor_cpu?: number;
    recommended_flavor_ram?: number;
    cpu_usage?: number;
    cpu_peak?: number;
    cpu_quantile_50?: number;
    cpu_quantile_99?: number;
    project_cpu_avg?: number;
    project_cpu_peak?: number;
    projected_cpu_qtl_50?: number;
    projected_cpu_qtl_99?: number;
    avg_data_size?: number;
    tier_1_request_quantity?: number;
    tier_2_request_quantity?: number;
    is_public_policy?: boolean;
    is_public_acls?: boolean;
    total_cost?: number;
    first_seen?: number;
    last_seen?: number;
    cost_in_detached_state?: number;
    last_seen_in_attached_state?: number;
    user_name?: string;
    user_id?: string;
    last_used?: number;
    security_group_name?: string;
    security_group_id?: string;
    cost_not_active_ip?: number;
    last_seen_active?: number;
    insecure_ports?: InsecurePort[];
    inactivity_periods?: InactivityPeriod[];
}

export interface InactivityPeriod {
    itemId: number;
    start: InactivityPeriodBase;
    end: InactivityPeriodBase;
}

export interface InactivityPeriodBase {
    day_of_week: number;
    hour: number;
}

export interface Organizations_Infos {
    name: string;
    pool_id?: string;
    is_demo?: boolean;
    currency?: string;
    cost_recomendations_count?: number;
    security_recomendations_count?: number;
    total_recomendations_count?: number;
    total_saving?: number;
    deleted_at?: number;
    id?: string;
    created_at?: number;
    organization_id?: string;
    last_run?: number;
    next_run?: number;
    last_completed?: number;
    total_consumption?: number;
}

export interface Organized_Recommendations_Infos {
    optimization_name: string;
    saving: number | null;
    count: number;
    organizations_Infos: Basic_Optimization_Info;
    optimizations_Options: Optimizations_Options;
}