import React from 'react';
import AsyncSelect from 'react-select/async';
import './index.css';
import { OptionsOrGroups, GroupBase, SingleValue } from 'react-select';
import PPDropDownProps from './PPDropDownProps';

// Custom styles function
const customStyles = (width: string, isRequired: boolean) => ({
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? 'var(--color-secondary-lime)' : '#EBEBEB',
        boxShadow: state.isFocused ? '0px 0px 18px 0px rgba(58, 79, 135, 0.2)' : null,
        borderLeft: isRequired ? '3px solid var(--color-secondary-lime)' : null,
        width: width,
    }),
    // Additional style customization
});

const PPDropDown: React.FC<PPDropDownProps> = ({
    options,
    title,
    name,
    defaultValue,
    width = '100%',
    style,
    required = false,
    disabled = false,
    isMulti = false,
    handleMenuOpen,
    ...otherProps
}) => {
    // Fetch options based on the input value
    const promiseOptions = (inputValue: string): Promise<OptionsOrGroups<any, any>> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (handleMenuOpen) {
                    // console.log(inputValue)
                    const results = handleMenuOpen(inputValue);
                    // console.log(results)
                    if (results instanceof Promise) {
                        results.then((data) => resolve(data));
                    } else {
                        resolve(results);
                    }
                } else {
                    // Ensure inputValue is defined and not null
                    if (!inputValue) return options;

                    // Create a regular expression for case-insensitive match
                    const regex = new RegExp(inputValue, 'i');
                    resolve(options.filter(option =>
                        option.label && regex.test(option.label)
                    ));
                    // Filter options and check for undefined or null labels
                    // return otherProps.options.filter(option =>
                    //     option.label && regex.test(option.label)
                    // );

                }
            }, 120);
        });
    };
    return (
        <div className="ppdropdown" style={style}>
            {title && <label htmlFor={name}>{title}</label>}
            <AsyncSelect
                loadOptions={promiseOptions}
                inputId={name}
                cacheOptions
                className="ppdropdown-container"
                value={defaultValue}
                classNamePrefix="ppdropdown"
                defaultOptions={options}
                isDisabled={disabled}
                isMulti={isMulti}
                placeholder="Selecione..."
                noOptionsMessage={({ inputValue }) => !inputValue ? "Digite para buscar..." : "Sem resultados"}
                styles={customStyles(width, required)}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral5: "#CCCCCF",
                        neutral20: "#CCC",
                        neutral30: "#9196A7",
                        neutral80: "#2E1956",
                        neutral90: "#2B304F",
                        primary25: "#EBEBEB",
                        primary50: "#EEEEEE",
                        primary: "#CCC",
                    },
                })}
                {...otherProps}
            />
        </div>
    );
};

export default PPDropDown;
