export interface AnalyticalCostCenterState {
    id?: number;
    costcenter?: string;
    service: string;
    account: string;
    region: string;
    resource: string;
    typeParam: string;
    hasConstCenterChildren?: boolean;
}
const initialState :AnalyticalCostCenterState = {
    id: 0,
    costcenter: '',
    service: '',
    account: '',
    region: '',
    resource:'',
    typeParam: '',
    hasConstCenterChildren: false
}
interface SetAnalyticalAction {
    type: string;
    payload: AnalyticalCostCenterState;
}
export const analyticalReducer = (
    state:AnalyticalCostCenterState = initialState,
    action: SetAnalyticalAction
) => {
    switch (action.type) {
        case 'ANALYTICAL_FILTER': 
            return state = action.payload;
        case "RESET_ANALYTICAL" :
            return state = initialState
        default:
            return state;
    }
};