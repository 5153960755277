import React, { StrictMode } from 'react';

import Routes from './routes';
import './assets/styles/global.css';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import 'moment/locale/pt-br';
import { PivotProvider } from './contexts/PivotContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
const queryClient = new QueryClient();

const App: React.FC = () => {
    moment.locale('pt-br');
    console.info('VERSION:14.0.0.1')
    return (
        <PivotProvider>
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    preventDuplicate
                    hideIconVariant
                    dense
                    maxSnack={4}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    classes={{ containerAnchorOriginBottomCenter: 'zIndex' }}
                >
                    <Routes />
                </SnackbarProvider>
            </QueryClientProvider>
        </PivotProvider>

    );
}

export default App;