import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { Pie, Bar } from 'react-chartjs-2';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { CacheManagerService } from '../../../services/cacheManagerService';
import { Grid, LinearProgress } from '@material-ui/core';
import "./index.css";

import LcSegment from '../../../components/Generic/LcSegment';
import Doughnut from '../../../components/Layout/Dashboard/Doughnut';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcLoading from '../../../components/Generic/LcLoading';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import moment from 'moment';




const BackupGoldResumoXXX: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const [summaryData, setSummaryData] = useState<any>();
    const [oldSummaryData, setOldSummaryData] = useState<any>();
    const [groupUsageChart, setGroupUsageChart] = useState<any>();

    const [weeklyUsageChart, setweeklyUsageChart] = useState<any>();
    const weeklyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    // callback: function(label: any, index: any, labels: any) {
                    //     return parseFloat(label).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 1});
                    // }                  
                }
            }]
        }
    };

    const [monthlyUsageChart, setMonthlyUsageChart] = useState<any>();
    const monthlyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        }
        ,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    // callback: function(label: any, index: any, labels: any) {
                    //     return parseFloat(label).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 1});
                    // }                  
                }
            }]
        }
    };

    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [jobCards, setJobCards] = useState<any>();
    const [isLoadingJobCards, setIsLoadingJobCards] = useState(true);
    const [errorJobCards, setErrorJobCards] = useState(false);
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [errorCards, setErrorCards] = useState(false);
    const [isLoadingGroupChart, setIsLoadingGroupChart] = useState(true);
    const [errorGroupChart, setErrorGroupChart] = useState<boolean>(false);
    const [isLoadingWeeklyChart, setIsLoadingWeeklyChart] = useState(true);
    const [errorWeeklyChart, setErrorWeeklyChart] = useState(false);
    const [isLoadingMonthlyChart, setIsLoadingMonthlyChart] = useState(true);
    const [errorMonthlyChart, setErrorMonthlyChart] = useState(false);
    // const [UpdatedAt, setUpdatedAt] = useState<string>();
    const [lastUpdate, setLastUpdate] = useState<string>("");

    const cacheManagerService = new CacheManagerService(props);
    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("livecloudbackupgold", "CloudOSBilling")
            .then(response => {
                setLastUpdate(new Date(response.data).toLocaleString());
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toLocaleString());
            })
    }

    useEffect(() => {
        setIsLoadingCards(true);
        setIsLoadingJobCards(true);
        setIsLoadingGroupChart(true);
        setIsLoadingWeeklyChart(true);
        setIsLoadingMonthlyChart(true);
        setErrorGroupChart(false);
        setErrorJobCards(false);
        setErrorCards(false);
        setErrorWeeklyChart(false);
        setErrorMonthlyChart(false);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const selectedDate = Moment(`${year}-${month}-01`).format('YYYY-MM-DD');
            const oldSelectedDate = Moment(selectedDate).add(-1, 'M').format('YYYY-MM-DD');
            const backupGoldService = new BackupGoldService(props);
            backupGoldService.GetCards(selectedDate)
                .then(response => {
                    let summaryData = {
                        totalSize: response.data.totalSizeData,
                        totalClients: response.data.totalClients,
                        totalAgents: response.data.totalAgents,
                        totalInstances: response.data.totalInstances,
                        totalSets: response.data.totalSets,
                        totalContracted: response.data.totalContracted,
                    };
                    let groups = response.data.chartData.map((Item: { agent: string, sizeTb: number }, index: number) => {
                        return {
                            id: index,
                            Agent: Item.agent,
                            SizeTb: Item.sizeTb,
                        }
                    });

                    let groupUsageChart = {
                        title: "Espaço alocado por tipo em TB",
                        labels: groups.map((Item: { Agent: string }) => {
                            return Item.Agent
                        }),
                        values: groups.map((Item: { SizeTb: number }) => {
                            return Item.SizeTb;
                        })
                    };
                    backupGoldService.GetJobCards(selectedDate)
                        .then(response => {
                            let jobCards = {
                                totalJobs: response.data.totalJobs,
                                succededJobsPercent: response.data.succededJobsPercent,
                                failedJobsPercent: response.data.failedJobsPercent,
                            };
                            setJobCards(jobCards);
                            if (response.data.totalJobs <= 0) {
                                setErrorJobCards(true);
                            }
                            setIsLoadingJobCards(false);
                        })
                        .catch(error => {
                            setErrorJobCards(true);
                            setIsLoadingJobCards(false);
                            console.log(error);
                        });
                    backupGoldService.GetCards(oldSelectedDate)
                        .then(response => {
                            if (response.data) {
                                let oldSummaryData = {
                                    totalSize: response.data.totalSizeData,
                                    totalClients: response.data.totalClients,
                                    totalAgents: response.data.totalAgents,
                                    totalInstances: response.data.totalInstances,
                                    totalSets: response.data.totalSets,
                                    totalContracted: response.data.totalContracted,
                                };
                                setOldSummaryData(oldSummaryData);
                                setIsLoadingCards(false);
                            };
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    setGroupUsageChart(groupUsageChart);
                    if (groupUsageChart.values.length <= 0) {
                        setErrorGroupChart(true);
                    }
                    setIsLoadingGroupChart(false);
                    setSummaryData(summaryData);
                    if (summaryData.totalAgents <= 0 && summaryData.totalClients <= 0 && summaryData.totalInstances <= 0) {
                        setErrorCards(true);
                    }
                    // setUpdatedAt(getUpdatedAt(response.data.updatedAt));

                    backupGoldService.GetWeeklyChart(selectedDate)
                        .then(response => {
                            let weeklyChart = {
                                labels: response.data.chartLabels,
                                datasets: [{
                                    data: response.data.chartValues,
                                    label: "Consumo semanal",
                                    borderColor: "#0074D9",
                                    backgroundColor: '#a5bf3e',
                                    fill: false
                                },
                                {
                                    type: 'line',
                                    label: "Consumo semanal",
                                    data: response.data.chartValues,
                                    borderColor: "#0074D9",
                                    backgroundColor: '#a5bf3e',
                                    fill: false
                                }]
                            };
                            setweeklyUsageChart(weeklyChart);
                            if (response.data.chartValues.length <= 0) {
                                setErrorWeeklyChart(true);
                            }

                            setIsLoadingWeeklyChart(false);
                        })
                        .catch(error => {
                            setErrorWeeklyChart(true);
                            setIsLoadingWeeklyChart(false);
                            console.log(error);
                        });
                    backupGoldService.GetMonthlyChart(selectedDate)
                        .then(response => {
                            let monthlyChart = {
                                labels: response.data.chartLabels,
                                datasets: [{
                                    data: response.data.chartValues,
                                    label: "Consumo mensal",
                                    borderColor: "#0074D9",
                                    backgroundColor: '#a5bf3e',
                                    fill: false
                                },
                                {
                                    type: 'line',
                                    label: "Consumo mensal",
                                    data: response.data.chartValues,
                                    borderColor: "#0074D9",
                                    backgroundColor: '#a5bf3e',
                                    fill: false
                                }]
                            };
                            setMonthlyUsageChart(monthlyChart);
                            if (response.data.chartValues.length <= 0) {
                                setErrorMonthlyChart(true);
                            }
                            setIsLoadingMonthlyChart(false);
                        })
                        .catch(error => {
                            setErrorMonthlyChart(true);
                            setIsLoadingMonthlyChart(false);
                            console.log(error);
                        });

                })
                .catch(error => {
                    setErrorCards(true);
                    setErrorGroupChart(true);
                    setErrorWeeklyChart(true);
                    setErrorMonthlyChart(true);
                    setErrorJobCards(true);
                    setIsLoadingJobCards(false);
                    setIsLoadingGroupChart(false);
                    setIsLoadingMonthlyChart(false);
                    setIsLoadingWeeklyChart(false);
                    setIsLoadingCards(false);

                    console.log(error);
                });
        }
        refreshLastUpdate();
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const columns = [
        { field: 'Agent', headerName: 'Grupos', flex: 2 },
        {
            field: 'SizeTb', headerName: 'TB/dia', flex: 2,
            renderCell: (params: any) => {
                return parseFloat(params.row.SizeTb).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
        }
    ];



    function getDelta(oldVal: any, newVal: any) {
        if ((typeof oldVal) == "string")
            oldVal = parseFloat(oldVal.replace(",", "."));
        if ((typeof newVal) == "string")
            newVal = parseFloat(newVal.replace(",", "."));
        //FFF - Função de calcula o delta entre o valor anterior e o valor atual e monta o html
        var delta = (newVal - oldVal) / oldVal;
        if (oldVal == 0 && newVal > 0) {
            //Infinity - quando não há valor anterior
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>do 0</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>Não há valor</b> no mês anterior</div> </LcDropDown>
        } else if (oldVal > 0 && newVal == 0) {
            //-100%
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>-100%</div>}> <div>Espaço consumido<br /><br /><b>0</b> Terabytes<br /><b>-100%</b> em relação ao mês anterior</div> </LcDropDown>
        } else if (oldVal == newVal) {
            //Inalterado
            return `Inalterado`
        } else if (delta > 0) {
            //Aumentou
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>+{(delta * 100).toFixed(2)}%</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>+{(delta * 100).toFixed(2)}%</b> em relação ao mês anterior</div> </LcDropDown>
        } else if (delta < 0) {
            //Diminuiu
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>{(delta * 100).toFixed(2)}%</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>{(delta * 100).toFixed(2)}%</b> em relação ao mês anterior</div> </LcDropDown>
        } else {
            return `---`
        }
    }

    return (
        <Layout pageTitle="Resumo" periodSelection={periodSelection} lastUpdate={lastUpdate}>
            <div className="bkpgoldresume">
                <LcLoading loading={isLoadingCards} label="Carregando cards...">
                    {!errorCards && summaryData ?
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true} info={getDelta(oldSummaryData?.totalSize, summaryData?.totalSize)} tooltipInfo="Em relação ao período anterior" title="Espaço consumido" tooltipInfoPosition="left" icon="lci lci-database" value={summaryData?.totalSize?.replace(",", ".")} unity="TB" color="#E273C5" tooltip="Maior consumo aferido no período"></IconValueCardRow>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow maximumFractionDigits={1} title="Espaço contratado" icon="lci lci-archive" value={summaryData?.totalContracted?.toString()} unity="TB" color="#59BAC5"></IconValueCardRow>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow title="Agentes" icon="lci lci-user" value={summaryData?.totalAgents?.toString()} color="#8684EF" tooltipInfo="Quantidade de agentes de backup instalados" info={<i className="lci lci-information-circle" style={{ color: '#0580ce', fontSize: '1rem' }} />} tooltipInfoPosition="right"></IconValueCardRow>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <small><i className="lci lci-exclamation text-warning" /> Não há informações no período</small>
                            </Grid>
                        </Grid>
                    }
                </LcLoading>

                <LCFlexContainer spacing={2}>
                    <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                        <LcLoading loading={isLoadingGroupChart} label="Carregando gráfico...">
                            {
                                groupUsageChart && !errorGroupChart ?
                                    <div className="lc-segment fullheigth">
                                        <DoughnutChart maximumFractionDigits={2} minimumFractionDigits={2} formatNumber={true} data={groupUsageChart} />
                                    </div>
                                    :
                                    <div className="lc-segment fullheigth">
                                        <div className="title">Espaço alocado por tipo em TB</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                            <i className="lci lci-exclamation lci-2x text-warning" />
                                            <span className="font-sm">Não há informações no período</span>
                                        </div>
                                    </div>
                            }
                        </LcLoading>
                    </LCFlexItem>
                    <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                        <LcLoading loading={isLoadingWeeklyChart} label="Carregando gráfico...">
                            {
                                weeklyUsageChart && !errorWeeklyChart ?
                                    <div className="lc-segment fullheigth">
                                        <div className="title">Consumo semanal</div>
                                        <Bar data={weeklyUsageChart} height={170} options={weeklyUsageChartOptions}></Bar>
                                    </div>
                                    :
                                    <div className="lc-segment fullheigth">
                                        <div className="title">Consumo semanal</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                            <i className="lci lci-exclamation lci-2x text-warning" />
                                            <span className="font-sm">Não há informações no período</span>
                                        </div>
                                    </div>
                            }
                        </LcLoading>
                    </LCFlexItem>
                    <LCFlexItem xs={12} sm={12} md={6} lg={4}>
                        <LcLoading loading={isLoadingMonthlyChart} label="Carregando gráfico...">
                            {
                                monthlyUsageChart && !errorMonthlyChart ?
                                    <div className="lc-segment fullheigth">
                                        <div className="title">Consumo mensal</div>
                                        <Bar data={monthlyUsageChart} height={170} options={monthlyUsageChartOptions}></Bar>
                                    </div>
                                    :
                                    <div className="lc-segment fullheigth">
                                        <div className="title">Consumo Mensal</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
                                            <i className="lci lci-exclamation lci-2x text-warning" />
                                            <span className="font-sm">Não há informações no período</span>
                                        </div>
                                    </div>
                            }
                        </LcLoading>
                    </LCFlexItem>
                </LCFlexContainer>
                <LcLoading loading={isLoadingJobCards} label="Carregando cards...">
                    {!errorJobCards && jobCards ?
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow title="Jobs executados" icon="lci lci-polygons" value={jobCards?.totalJobs.toString()} color="#F3C944" ></IconValueCardRow>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow title="Jobs executados com sucesso" icon="ppi ppi-check" value={jobCards?.succededJobsPercent} unity="%" color="#A5BF3E" maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true}></IconValueCardRow>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <IconValueCardRow title="Jobs executados com falha" icon="lci lci-ban" value={jobCards?.failedJobsPercent} unity="%" color="#C23D4F" maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true}></IconValueCardRow>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <small><i className="lci lci-exclamation text-warning" /> Não há informações no período</small>
                            </Grid>
                        </Grid>
                    }
                </LcLoading>
            </div>
        </Layout>
    );
};

export default BackupGoldResumoXXX;