import React, { useEffect, useMemo, useState } from 'react';
import { PiHardDrives } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { UserState } from '../../../../store/reducers/userReducer';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { Tooltip } from '@material-ui/core';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';

interface StorageInfoCardProps {
    setLoading: (loading: boolean) => void
}

export const StorageInfoCard: React.FC<StorageInfoCardProps> = ({setLoading}) => {

    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const [storageCard, setStorageCard] = useState<number>(0);
    const [storageData, setStorageData] = useState<[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const columnsStorage = useMemo(() => {
        return [
            {
                field: 'name', headerName: 'Nome', width: "30%" , renderCell: (row) =>{
                    return  (
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                            <Tooltip arrow title={row.provider !== 'aws' ? 'Microsoft Azure' : 'Amazon AWS'}>
                                <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                    {row.provider !== 'aws' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                                </span>
                            </Tooltip>
                            <span
                                className='overflowEllipsis ml-4'
                            >
                                { row.name || <i className="lci lci-divider-horizontal" />}
                            </span>
                        </div>
                        )
                }
            },
            {
                field: 'assinatura', headerName: 'Assinatura', width: "30%",
            },
            {
                field: 'location', headerName: 'Região', width: "15%",
            },
            {
                field: 'type', headerName: 'Tipo', width: "15%",
            },
            {
                field: 'size', headerName: 'Capacidade de uso GB', width: "12%", renderCell: (row:  any) => {
                    return row.size > 0 ? (row.size / (1024 ** 3)).toFixed(2) : 0
                }
            },
        ]
    },[])

    const translationsFilter = useMemo(() => {
        return {
            name: {
                label: "Nome",
                type: "text",
            },
            assinatura: {
                label: "Assinatura",
                type: "text",
            },
            location: {
                label: "Região",
                type: "multiSelect",
            },
            type: {
                label: "Tipo",
                type: "multiSelect",
                topDropdown: true
            },
            // status: {
            //     label: "Status",
            //     type: "multiSelect",
            //     topDropdown: true
            // },
        };
    },[])


    
    useEffect(() =>{
        const getStoragesData = async () => {
            setIsLoading(true)
            try {
                const CmsService = new CmsPremierService({})
                const data = await CmsService.getStoragesFull();
                const arrayRowVms: any = [
                    ...data.azure.map((azure: any) => ({
                        name: azure.name,
                        assinatura: azure.id.split('/')[2],
                        type: azure.kind,
                        location: azure.location,
                        size: azure.properties.usedCapacity,
                        provider: 'azure'
                    })),
                    ...data.aws.map((aws: any) => ({
                        name: aws.bucketName,
                        assinatura: aws.accountId,
                        location: aws.region,
                        type: aws.storageClass,
                        size: aws.size,
                        provider: 'aws'
                    }))
                ].sort((a, b) => b.name.localeCompare(a.name));
                setStorageData(arrayRowVms)
                setStorageCard(arrayRowVms.length);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoading(false)
            }
        }
        getStoragesData()
    } ,[user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {
        if(cmsEnvironment.enviromentFilter === 'Storage'  && !isLoading) {
            let payload = {
                enviromentFilter: 'Storage',
                columns: columnsStorage,
                rowsList: storageData,
                rowsListFiltered: storageData,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter
            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            setLoading(false);
        }
    },[cmsEnvironment.enviromentFilter, columnsStorage, dispatch, setLoading, storageData, translationsFilter, isLoading])
    
    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Storage',
            columns: columnsStorage,
            rowsList: storageData,
            rowsListFiltered: storageData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        setLoading(false)

    } 

    return (
        <LcLoading loading={isLoading}>
            <IconValue 
                icon={<PiHardDrives size={28} />}
                value={`${storageCard ? storageCard : 0}`}
                color="#A0A4FF"
                onClick={handleClickEnviroment}
                description='storages'
                balonSelected={(cmsEnvironment.enviromentFilter === 'Storage')}
            />
        </LcLoading>
    )
}
