import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import { FinOpsSavingPlanReserveService } from '../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserve';
import { Resouces, SavingPlanReserveModel } from '../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import moment from 'moment';
import { SiMicrosoftazure } from 'react-icons/si';
import { FaAws } from "react-icons/fa";
import { PPModal } from 'processor-plataform-ui';
import { MediaUsageChart } from './MediaUsageChart';
import PPDropDown from '../../../components/PPDropDown';
import UsedVsNotUsedChart from './UsedVsNotUsedChart';
import { Tooltip } from '@material-ui/core';
import { PiCurrencyCircleDollarLight, PiInfo, PiPiggyBankLight } from 'react-icons/pi';
import LcDropDown from '../../../components/Generic/LcDropDown';
import './index.css'

const FinopsSavingPlanAndReserveResume: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [SavingsPlanReserveData, setSavingsPlanReserveData] = useState<SavingPlanReserveModel[]>([])
    const [filteredSavingsPlanReserveData, setFilteredSavingsPlanReserveData] = useState<SavingPlanReserveModel[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [savingsPlanReserveDetails, setsavingsPlanReserveDetails] = useState<SavingPlanReserveModel>();
    const [possibleSavings, setPossibleSavings] = useState<string>('0')
    const [totalSavingsPlans, setTotalSavingsPlans] = useState<number>(0)
    const [TotalUsed, setTotalUsed] = useState<number>(0)
    const [totalReserved, setTotalReserved] = useState<number>(0)
    const [showByStatus, setShowByStatus] = useState<number>(0);
    const [totalReservas, setTotalReservas] = useState<number>(0)
    const [mediaSelected, setMediaSelected] = useState<number>(1)
    const mediaMonthOpt = [
        { label: 'Este mês', value: 1 },
        { label: 'Mês anterior', value: 2 },
        // { label: 'Todo período', value: 3 }
    ]

    useEffect(() => {
        const getSavingsPlanReserve = async () => {
            try {
                const SavingsPlanReserveService = new FinOpsSavingPlanReserveService({})
                const savingsPlanReserveResponse = await SavingsPlanReserveService.retrieveList();
                if (savingsPlanReserveResponse.length > 0) {
                    setSavingsPlanReserveData(savingsPlanReserveResponse);
                    setPossibleSavings(savingsPlanReserveResponse.reduce((somatorio, objeto) => somatorio + objeto.possibleSavings, 0).toLocaleString("pt-br", { style: "currency", currency: "BRL" }))
                    setTotalSavingsPlans(savingsPlanReserveResponse.filter(objeto => objeto.type.toLowerCase() === "savings plan" && new Date(objeto.expiredDate) > new Date()).length);
                    setTotalReservas(savingsPlanReserveResponse.filter(objeto => objeto.type.toLowerCase() === "reserva" && new Date(objeto.expiredDate) > new Date()).length);
                } else {
                    setSavingsPlanReserveData([]);
                    setPossibleSavings(0.0.toLocaleString("pt-br", { style: "currency", currency: "BRL" }))
                    setTotalSavingsPlans(0);
                    setTotalReservas(0);
                }
                setShowByStatus(0);
            } catch (error) {
                console.error(`Error: ${error}`);
                throw error;
            }
        }

        setLoading(true);
        getSavingsPlanReserve().finally(() => setLoading(false));
    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => { // Altera os dados da tela de acordo com o filtro do menu
        let _current: SavingPlanReserveModel[] = [];

        if (SavingsPlanReserveData.length > 0) {
            let _actives = SavingsPlanReserveData.filter(sp => new Date(sp.expiredDate) > new Date()) as SavingPlanReserveModel[];
            let _inactives = SavingsPlanReserveData.filter(sp => new Date() >= new Date(sp.expiredDate)) as SavingPlanReserveModel[];
            if (showByStatus === 0) // Ativos
                _current = _actives;

            if (showByStatus === 1) // Inativos
                _current = _inactives;

            if (showByStatus === 2) // Todos
                _current = SavingsPlanReserveData;

        } else
            _current = SavingsPlanReserveData;

        setFilteredSavingsPlanReserveData(_current);
        let _newSavings = _current.reduce((somatorio, objeto) => somatorio + objeto.possibleSavings, 0).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
        setPossibleSavings(_newSavings);
    }, [SavingsPlanReserveData, showByStatus]);

    const labelStatusToExpired = (status: Date) => {
        let expiredDate = new Date(status)

        if (expiredDate.getFullYear() > 1) {
            const dataAtual = new Date();
            const dataLimite30Dias = new Date();
            dataLimite30Dias.setDate(dataAtual.getDate() + 31);

            if (expiredDate < dataAtual) {
                return "Expirado";
            } else if (expiredDate <= dataLimite30Dias) {
                return "Expirando";
            } else {
                return "Ativo";
            }
        } else return "Desconhecido";
    }

    function colorStatusToExpired(data: SavingPlanReserveModel) {
        let expiredDate = new Date(data.expiredDate)

        if (expiredDate.getFullYear() > 1) {
            const dataAtual = new Date();
            const dataLimite30Dias = new Date();
            dataLimite30Dias.setDate(dataAtual.getDate() + 31);

            if (expiredDate < dataAtual) {
                return "var(--status-red)"; // Expirado
            } else if (expiredDate <= dataLimite30Dias) {
                return "var(--status-yellow)"; // Expirando
            } else {
                return "var(--status-green)"; // Ativo
            }
        } else return "var(--background-neutral)"; // Sem informação
    }

    const tranleteStatusColor = (status: Resouces) => {
        const tranlateStatusColor = {
            'important': '#ffa400',
            'critical': "#dc291e",
            'moderate': "#f6de00",
            'low': "#3ebb3f",
            'unclassified': "#cccccf",
        };
        return tranlateStatusColor[status.resouceStatus];
    }

    const handleOpenModalDetails = (row: SavingPlanReserveModel) => {
        setsavingsPlanReserveDetails(row);
        // setTotalUsed(row.resouces.reduce((accumulator, currentValue) => accumulator + currentValue.quantityUsed,
        // 0,))
        // setTotalReserved(row.resouces.reduce((accumulator, currentValue) => accumulator +( currentValue.quantityUsed + currentValue.quantityNotUsed ),
        // 0,))
        setTotalUsed(row.used)
        setTotalReserved(row.reservations)

        setOpenModal(true);
    }

    const columns = [
        {
            field: "plan", headerName: "Provedor/Plano", width: "27%", align: 'left', renderCell: (row: SavingPlanReserveModel) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                        <Tooltip arrow title={row.provider === 'Azure' ? 'Microsoft Azure' : 'Amazon AWS'}>
                            <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                {row.provider === 'Azure' ? <SiMicrosoftazure size={'14px'} color='#2E1956' /> : <FaAws size={'16px'} color='#2E1956' />}
                            </span>
                        </Tooltip>
                        <Tooltip arrow title={row.plan}>
                            <span
                                className='overflowEllipsis titleLink ml-4'
                                onClick={() => handleOpenModalDetails(row)}
                            >
                                {row.plan}
                            </span>
                        </Tooltip>
                    </div>
                )
            }
        },
        { field: "type", headerName: "Tipo", width: "10%", align: 'left', },
        {
            field: "alocation", headerName: "Alocação", width: "12%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => {
                // Função para formatar adequadamente a alocação
                const formatAlocation = (value) => {
                    // Padrão para encontrar valores monetários com ou sem texto adicional
                    const pattern = /^(R\$)\s*(\d+[.,]?\d*)\s*(.*)$/;
                    const match = value.match(pattern);

                    if (match) {
                        // Formata apenas a parte numérica mantendo qualquer texto adicional
                        const numberPart = parseFloat(match[2].replace(',', '.'));
                        const formattedNumber = numberPart.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });
                        return `${formattedNumber}${match[3]}`.trim(); // Reconstrói sem adicionar novamente o R$                        

                    }

                    // Retorna o valor original se não corresponder ao padrão esperado
                    return value;
                };

                const formattedAlocation = formatAlocation(row.alocation);
                return <Tooltip title={row.alocation} arrow><div className='overflowEllipsis'>{formattedAlocation}</div></Tooltip>;
            }
        },

        {
            field: "service", headerName: "Serviço", width: "10%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.service} arrow><div className='overflowEllipsis'>{row.service}</div></Tooltip>
        },
        {
            field: "scope", headerName: "Escopo", width: "16%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.scope} arrow><div className='overflowEllipsis'>{row.scope}</div></Tooltip>
        },
        {
            field: "region", headerName: "Região", width: "12%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.region} arrow><div className='overflowEllipsis'>{row.region}</div></Tooltip>
        },
        {
            field: "commitment", headerName: "Compromisso", width: "10%",
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.commitment} arrow><div className='overflowEllipsis'>{row.commitment}</div></Tooltip>
        },
        {
            field: "monthly_cost", headerName: "Custo Mensal (R$)", width: "18%", align: 'right',
            renderCell: (row: SavingPlanReserveModel) => row.monthlyCost.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
        },
        {
            field: "possible_savings", headerName: "Possível economia (R$)", width: "20%", align: 'right',
            renderCell: (row: SavingPlanReserveModel) => row.possibleSavings.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
        },
        {
            field: "use", headerName: "Utilização", width: "10%", align: 'center',
            renderCell: (row: SavingPlanReserveModel) => `${(row.use)}%`
        },
        {
            field: "expired_date", headerName: "Expira em", width: "10%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => moment(row.expiredDate).year() === 1 ? "-" : moment(row.expiredDate).format('DD/MM/YYYY')
        },
    ]

    const collumnsDetails = [
        { field: "subscrition", headerName: "Subscrição", width: "28%", align: 'left', },
        { field: "resourceGroup", headerName: "Grupo de recursos", width: "18%", align: 'left', },
        { field: "resourceName", headerName: "Nome do recurso", width: "28%", align: 'left', truncate: true, },
        { field: "product", headerName: "Produto", width: "15%", align: 'left', },
        { field: "quantityUsed", headerName: "Quantidade usada", width: "11%", },
    ]

    const showByStatusMenu = [
        { label: 'Ativos', value: 0 },
        { label: 'Inativos', value: 1 },
        { label: 'Ver todos', value: 2 }
    ];

    const rowMenu = (
        <>
            <LcDropDown
                element={<span>{showByStatusMenu.find(c => c.value === showByStatus)?.label} <i className="ppi ppi-chevron-down" /></span>}
                position="right"
            >
                {
                    showByStatusMenu.map((ci, i) => {
                        return <div key={i} className={`item${ci.value === showByStatus ? " disabled" : ""}`}
                            onClick={() => {
                                ci.value !== showByStatus &&
                                    setShowByStatus(ci.value);
                            }}

                        >{ci.label}</div>
                    })
                }
            </LcDropDown>

        </>
    )

    const cards: Card[] = [
        {//saving plan
            id: 1,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: false,
            title: () => "Savings plans",
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}    >
                        {
                            totalSavingsPlans ?
                                <IconValue
                                    value={totalSavingsPlans}
                                    color="var(--color-secondary-cyan)"
                                    icon={<PiPiggyBankLight size={32} />}
                                    description='ativos em seu ambiente cloud'
                                    cssClass='font-xl'
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        {//Reservas de instâncias
            id: 2,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 4 },
            showFocusButton: false,
            title: () => "Reservas de instâncias",
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {
                            totalReservas ?
                                <IconValue
                                    value={totalReservas}
                                    color="var(--color-primary-pink)"
                                    icon={<PiCurrencyCircleDollarLight size={32} />}
                                    description='ativas em seu ambiente cloud'
                                    cssClass='font-xl'
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        {//Possível economia mensal 
            id: 3,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: false,
            title: () => "Possível economia mensal ",
            infoContent: () => "Possivel economia com saving plans e reservas feitas",
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {
                            possibleSavings ?
                                <IconValue
                                    value={possibleSavings}
                                    color="var(--status-green)"
                                    icon={<PiPiggyBankLight size={32} />}
                                    description='com o uso de saving plans e reservas'
                                    cssClass='font-xl'
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        {//Média de utilização (%)
            id: 4,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 10 },
            showFocusButton: false,
            title: () => {
                return <>
                    {
                        <div className={'lc-segment-title-row-resume'} style={{ alignItems: 'flex-end' }}>
                            <div className={'lc-title ellipsis'}>Média de utilização (%) </div>
                            <PPDropDown
                                options={mediaMonthOpt}
                                defaultValue={mediaMonthOpt.find(option => option.value === mediaSelected)}
                                value={mediaMonthOpt.find(option => option.value === mediaSelected)}
                                onChange={(event) => setMediaSelected(event.value)}
                                width='112px'
                            />
                        </div>
                    }</>
            },
            customContentRender: () => {
                return <MediaUsageChart data={SavingsPlanReserveData} loading={loading} periodSelected={mediaSelected} />
            }
        },
        {//List
            id: 5,
            type: 'Custom', position: { ColSpan: 12, RowSpan: 3, row: 2, col: 1 },
            showFocusButton: false,
            hideHeader: true,
            title: () => "",
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} label="Carregando savings plans e reservas...">
                        {
                            SavingsPlanReserveData ?
                                <LcInfiniteTable rows={filteredSavingsPlanReserveData}
                                    columns={columns}
                                    size={filteredSavingsPlanReserveData.length}
                                    hidePagination
                                    fontSize='small'
                                    status={colorStatusToExpired}
                                    tooltipContentColumn='status'
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
    ]

    return (
        <Layout pageTitle='Savings/Reservas' row={rowMenu}>
            <LCDashboard
                cards={cards}
            />

            <PPModal title={"Detalhes do plano"} size='large' visible={openModal} onClose={() => setOpenModal(false)} >
                {
                    savingsPlanReserveDetails &&
                    <div className='detailsPlan'>
                        <div className="headerPlanDetails">
                            <div className='detailSavingsPlanOrReserve'>
                                <section className="inicialInfo">
                                    <div className='detailsName'>
                                        <div className='row'>
                                            <Tooltip title={savingsPlanReserveDetails.plan} arrow>
                                                <h4>{savingsPlanReserveDetails.plan}</h4>
                                            </Tooltip>
                                            <span className='bagdeStatusPlan' style={{ backgroundColor: colorStatusToExpired(savingsPlanReserveDetails) }}>
                                                {labelStatusToExpired(savingsPlanReserveDetails.expiredDate)}
                                            </span>
                                        </div>
                                        <div className='mt-4 row'>
                                            <Tooltip arrow title={savingsPlanReserveDetails.provider === 'Azure' ? 'Microsoft Azure' : 'Amazon AWS'}>
                                                <span style={{ minWidth: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {savingsPlanReserveDetails.provider === 'Azure' ? <SiMicrosoftazure size={'14px'} color='#2E1956' /> : <FaAws size={'16px'} color='#2E1956' />}
                                                </span>
                                            </Tooltip>
                                            <span className='ml-2 subTitlePlan'>{savingsPlanReserveDetails.type} para o serviço {savingsPlanReserveDetails.service} {savingsPlanReserveDetails.provider}</span>
                                        </div>
                                    </div>
                                    <div className='possible_savings'>
                                        <span className="labelGray textSmall">Possível economia mensal:</span>
                                        <p>{savingsPlanReserveDetails.possibleSavings.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</p>
                                    </div>
                                </section>
                                <section className="useDetails">
                                    <div className='percente'>
                                        <h6 className='mr-5'>Utilização:</h6>
                                        <div className='totalPercente'>
                                            <p>{((TotalUsed / totalReserved) * 100).toFixed(0)}%</p>
                                            <div style={{ width: `${((TotalUsed / totalReserved) * 100).toFixed(0)}%` }} className='usePercente'></div>
                                        </div>
                                    </div>
                                    <div className='usedAndReservedInfo'>
                                        <p><span className="labelGray mr-2">Reservadas:</span> {totalReserved} horas</p>
                                        <p ><span className="labelGray mr-2">Usadas:</span> {TotalUsed} horas</p>
                                    </div>
                                </section>
                                <section className="restDetails">
                                    <div className='rowPlanDetails'>
                                        <div className='row'>
                                            <span className="labelGray" >Custo Mensal:</span>
                                            <p>{savingsPlanReserveDetails.monthlyCost.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}  </p>

                                            <Tooltip arrow title={`Valor sem ${savingsPlanReserveDetails.type}`}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                                                    <p style={{ color: '#eb8a8c', fontSize: '11px', fontStyle: 'italic' }}>({(savingsPlanReserveDetails.monthlyCost + savingsPlanReserveDetails.possibleSavings).toLocaleString("pt-br", { style: "currency", currency: "BRL" })})</p>
                                                    <PiInfo size={'16px'} color='#2E1956' />
                                                </div>
                                            </Tooltip>

                                        </div>
                                        <div className='row'>
                                            <span className="labelGray" >Escope:</span>
                                            <Tooltip arrow title={savingsPlanReserveDetails.scope}>
                                                <p>{savingsPlanReserveDetails.scope}</p>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='rowPlanDetails'>
                                        <div className='divDetails'>
                                            <div className='row' >
                                                <span className="labelGray">Pagamento:</span>
                                                <Tooltip arrow title={savingsPlanReserveDetails.payment}>
                                                    <p>{savingsPlanReserveDetails.payment}</p>
                                                </Tooltip>
                                            </div>
                                            <div className='row mt-6'>
                                                <span className="labelGray" >Região:</span>
                                                <Tooltip arrow title={savingsPlanReserveDetails.region}>
                                                    <p>{savingsPlanReserveDetails.region}</p>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <div className='divDetails'>
                                            <div className='row'>
                                                <span className="labelGray" >Flexibilidade:</span>
                                                <Tooltip arrow title={savingsPlanReserveDetails.flexibility}>
                                                    <p>{savingsPlanReserveDetails.flexibility}</p>
                                                </Tooltip>
                                            </div>
                                            <div className='row mt-6'>
                                                <span className="labelGray" >Alocação:</span>
                                                <Tooltip arrow title={savingsPlanReserveDetails.alocation}>
                                                    <p>{savingsPlanReserveDetails.alocation}</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className='divDetails'>
                                            <div className='row'>
                                                <span className="labelGray" >Compromisso:</span>
                                                <Tooltip arrow title={savingsPlanReserveDetails.commitment}>
                                                    <p>{savingsPlanReserveDetails.commitment}</p>
                                                </Tooltip>
                                            </div>
                                            <div className='row mt-6'>
                                                <span className="labelGray" >Expira em:</span>
                                                <Tooltip arrow title={moment(savingsPlanReserveDetails.expiredDate).year() === 1 ? "-" : moment(savingsPlanReserveDetails.expiredDate).format('DD/MM/YYYY')}>
                                                    <p>{moment(savingsPlanReserveDetails.expiredDate).year() === 1 ? "-" : moment(savingsPlanReserveDetails.expiredDate).format('DD/MM/YYYY')}</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="graphDetails">
                                <UsedVsNotUsedChart data={savingsPlanReserveDetails} />
                            </div>

                        </div>
                        <div className='bodySystem'>
                            <h4>Recursos</h4>
                            <LcInfiniteTable
                                fontSize='small'
                                rows={savingsPlanReserveDetails.resouces}
                                columns={collumnsDetails}
                                loadMore={() => undefined}
                                size={savingsPlanReserveDetails.resouces.length}
                                hidePagination
                                status={tranleteStatusColor}
                                height='26vh' />
                        </div>
                    </div>
                }
            </PPModal>
        </Layout>
    );
}

export default FinopsSavingPlanAndReserveResume;