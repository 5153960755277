import React from 'react';
import { Tooltip } from '@material-ui/core';
import LcLoading from '../../../../components/Generic/LcLoading';
import LcNoData from '../../../../components/Generic/LcNoData';
import LcInfiniteTable from '../../../../components/Data/LcInfiniteTable';
import { AutomationResource, AutomationInstanceType, AutomationsScheduler, AutomationTemplate } from '../../../../services/automations/automationsModel';
import './index.css';
import { SlScreenDesktop } from 'react-icons/sl';
import { PiCloudWarningLight, PiDatabase, PiDatabaseLight, PiDesktopLight, PiInfoLight } from 'react-icons/pi';
import { TbCloudQuestion, TbInfoCircle } from 'react-icons/tb';
import { PPModal } from 'processor-plataform-ui';
import AutomationsService from '../../../../services/automations/automationsService';
import { isArray } from '@xbs/webix-pro';
import { SiMicrosoftazure } from 'react-icons/si';
import { FaAws } from 'react-icons/fa';

interface AutomationDetailsProps {
    resource: AutomationResource;
    resourceInstance: AutomationInstanceType | undefined;
    relatedAutomations: AutomationsScheduler[];
    isLoadingResource: boolean;
    showDetailsModal: boolean;
    toggleDetailsModal: () => void;
    templateData: AutomationTemplate[];
}

const AutomationDetails: React.FC<AutomationDetailsProps> = ({
    resource,
    resourceInstance,
    relatedAutomations,
    isLoadingResource,
    showDetailsModal,
    toggleDetailsModal,
    templateData,
}) => {

    const savingsMessage = 'Valores estimados de acordo com as automações ativas em seu ambiente cloud, podendo sofrer alterações com a incidência de impostos e/ou serviços.';
    const automationsService = new AutomationsService({});
    const renderResourceIcon = (resourceType: string) => {
        switch (resourceType) {
            case 'VM':
                return <PiDesktopLight size='24px' color='var(--text-default)' />;
            case 'DB':
                return <PiDatabaseLight size='24px' color='var(--text-default)' />;
            default:
                return <PiCloudWarningLight size='24px' color='var(--text-default)' />;
        }
    };

    const getLabelAutomation = (automationId: number): string[] | undefined => {

        if (templateData === undefined) {
            // Handle the case where templateData is undefined
            return undefined; // or return a default value if needed
        }

        if (!Array.isArray(templateData)) {
            // Handle unexpected templateData type
            return undefined; // or return a default value if needed
        }

        const template = templateData.find(tpl => tpl.id === automationId);

        if (!template) {
            // Handle the case where no template matches the automationId
            return undefined; // or return a default value if needed
        }

        const templateName = template.name;
        const automationDicLine = automationsService.automationTemplatesDic[templateName];

        return automationDicLine;
    };

    const renderAutomationNameAndIconForTable = (sched: AutomationsScheduler) => {
        let templateDic = getLabelAutomation(sched.automationId)
        if (templateDic) {
            let icon = {
                'Azure': <SiMicrosoftazure size={'14px'} color='#2E1956' />,
                'AWS': <FaAws size={'16px'} color='#2E1956' />
            }[templateDic[0]]
            // let { templateDic, icon } = extractAutomationTemplateFromDics(sched);
            let provider = Array.isArray(templateDic) ? templateDic[0] : '-';
            let name = Array.isArray(templateDic) ? templateDic[1] : templateDic;
            return (
                <div className='automationIdColumn'>
                    <Tooltip arrow title={provider === 'Azure' ? 'Microsoft Azure' : (provider === 'AWS' ? 'Amazon AWS' : '')}>
                        <span className='automationIdIcon'>{icon}</span>
                    </Tooltip>
                    <Tooltip arrow title={templateDic[1]}>
                        <span className='overflowEllipsis ml-4'>{name}</span>
                    </Tooltip>
                </div>
            )
        }
    }

    const translateAutomationWeekDays = (days: string): string => {
        let split = days.split('-');
        let translated = split.map(day => automationsService.automationsWeekDic[day.trim()][0])
        return translated.join('-');
    }

    const relatedColumns = [
        {
            field: 'automationId',
            headerName: 'Automação',
            width: '30%',
            align: 'left',
            renderCell: (row: AutomationsScheduler) => (
                <div className='automationIdColumn'>
                    {/* <Tooltip arrow title={row.automationId.toString()}> */}
                    <span className='overflowEllipsis ml-4'>{renderAutomationNameAndIconForTable(row)}</span>
                    {/* </Tooltip> */}
                </div>
            ),
        },
        {
            field: 'weekDaysScheduled',
            headerName: 'Dias da semana',
            width: '30%',
            align: 'left',
            renderCell: (row: AutomationsScheduler) => (
                <div className='weekDaysScheduledColumn'>
                    {translateAutomationWeekDays(row.weekDaysScheduled)}
                </div>
            ),
        },
        {
            field: 'hourScheduled',
            headerName: 'Início',
            width: '10%',
            align: 'left',
            renderCell: (row: AutomationsScheduler) => (
                <div className='hourScheduledColumn'>
                    {row.hourScheduled}
                </div>
            ),
        },
        {
            field: 'lastRun',
            headerName: 'Última execução',
            width: '15%',
            align: 'left',
            renderCell: (row: AutomationsScheduler) => (
                <div className='related-lastRunColumn'>{row.lastRun}</div>
            ),
        },
        {
            field: 'status',
            headerName: 'Status da automação',
            width: '15%',
            align: 'left',
            renderCell: (row: AutomationsScheduler) => (
                <div className='statusColumn'>
                    {row.status >= 1 ? 'Habilitada' : 'Desabilitada'}
                </div>
            ),
        },
    ];

    return (
        <PPModal title='Detalhes' visible={showDetailsModal} onClose={toggleDetailsModal} size='65%'>
            <LcLoading loading={isLoadingResource} label={'Carregando detalhes...'}>
                {resource.resourceName !== '' && resource ? (
                    <div className='details-container'>
                        <div className='details-resource-card'>
                            <div className='details-resource-card-header'>
                                <div className='details-resource-header-line'>
                                    <span>Recurso:</span>
                                </div>
                                <div className='details-resource-header-line'>
                                    <div className='details-resource-title'>
                                        {renderResourceIcon(resource.resourceType)}
                                        <span className='details-resource-name'>{resource.resourceName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='details-resource-container'>
                                <div className='details-resource-line'>
                                    <span>
                                        <label>Provedor: </label>
                                        {resource.resourceProvider}
                                    </span>
                                    <span>
                                        <label>ID: </label>
                                        {resource.resourceID.length > 40 ? (
                                            <Tooltip arrow title={resource.resourceID}>
                                                <span>{resource.resourceID.substring(0, 39)}...</span>
                                            </Tooltip>
                                        ) : (
                                            resource.resourceID
                                        )}
                                    </span>
                                    <span>
                                        <label>Conta/Assinatura: </label>
                                        {resource.resourceSubscription || '-'}
                                    </span>
                                </div>
                                <div className='details-resource-line'>
                                    <span>
                                        <label>Região: </label>
                                        {resource.resourceRegion || '-'}
                                    </span>
                                    <span>
                                        <label>Tamanho: </label>
                                        {resource.instanceSize || '-'}
                                    </span>
                                    <span>
                                        <label>vCPU: </label>
                                        {resourceInstance ? resourceInstance.vcpUs : 0}
                                    </span>
                                    <span>
                                        <label>Memória (GB): </label>
                                        {resourceInstance ? resourceInstance.vram : 0}
                                    </span>
                                    <span>
                                        <label>Disco de dados máximo: </label>
                                        {resourceInstance ? resourceInstance.vDisks : 0}
                                    </span>
                                    <span>
                                        <label>Grupo: </label>
                                        {resource.resourceGroup || '-'}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='details-related-automations'>
                            <span className='title-default'>Automações neste recurso:</span>
                            <LcInfiniteTable
                                columns={relatedColumns}
                                rows={relatedAutomations}
                                size={relatedAutomations.length}
                                hidePagination={true}
                                fontSize='small'
                                status={(row) => (row.status > 0 ? '#3ebb3f' : '#dc291e')}
                                tooltipContentColumn='statusLabel'
                            />
                        </div>
                    </div>
                ) : (
                    <div style={{ padding: '15px' }}>
                        <LcNoData label='Não foram encontradas informações adicionais sobre este recurso.' />
                    </div>
                )}
            </LcLoading>
        </PPModal>
    );
};

export default AutomationDetails;
