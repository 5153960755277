import api, { APIClient } from "../api";
import axios, { AxiosAdapter } from 'axios';
let CancelToken = axios.CancelToken;
export interface GroupSeries {
    date: string | null;
    total: number | null;
}

export interface GroupBars {
    group: string;
    series: GroupSeries[];
}
export interface GraphItemBudget {
    dateOnly: string | null;
    label: string;
    extraLabel: string;
    consumption: number | null;
    cost: number;
    budget: number;
}

export interface GraphItem {
    dateOnly: string | null;
    label: string;
    extraLabel: string;
    consumption: number | null;
    cost: number;

}

export interface FinOpsDashBoard {
    contractNumber: string;
    contractName: string;
    provider: string;
    contractCurrency: string;
    contractCurrencyLabel: string;
    baseline: number;
    totalConsumption: number;
    lastMonthTotalConsumption: number;
    totalCost: number;
    lastMonthTotalCost: number;
    lowestDate: string;
    highestDate: string;
    dailyProviderConsumption: GraphItem[];
    dailyConsumption: GraphItem[];
    lastMonthDailyConsumption: GraphItem[];
    serviceConsumption: GraphItem[];
    costCenterConsumption: GraphItem[];
    resourceConsumption: GraphItem[];
    dataGroupLast: GroupBars[];
    dataGroupbyResource: GroupBars[];
    dataGroupbyService: GroupBars[];
    dataGroupbyCostCenter: GroupBars[];
    dataGroupbyTotal: GroupBars[];
    reportCostCenterBudget: CostCenterSummary[];
    baselineHistory: CostCenterWithBudget[];
}
export interface CostCenterWithBudget {
    id: number;
    name: string;
    active: boolean | null;
    clientGroupId: number;
    budget: number;
    monthBudget: MonthBudget[];
}

export interface MonthBudget {
    id: number;
    costCenterId: number;
    value: number;
    month: string;
}
interface CostCenterSummary {
    id: number;
    name: string;
    totalCost: number;
    budget: number;
}
export interface DataGroup {
    title: string;
    value: number;
}

export interface GroupReport {
    parent: DataGroup;
    total: number;
    childs: DataGroup[];
    visible: boolean;
}
export interface DynamicFilter {
    year: number
    month: number
    userId: string
    isAdmin: boolean
    clientGroupId: number
    costCenter: string
    contractId: string
    groupFactor: string
    lastPeriod: string
    groupby: string
    compareToken: string
    filterText: string
    jsonDynamicFilter: JsonDynamicFilter
}

export interface JsonDynamicFilter {
    id: number
    order: number
    default: boolean
    description: string
    conditions: Condition[]
    costCenters: CostCenter[]
    contracts: Contract[]
}

export interface Condition {
    idCondition: number
    field: string
    filter: string
    operation: string
    logicOperator: string
}

export interface CostCenter {
    idCostCenterRule: number
    idCostCenter: string
    costCenter: string
    percentage: number
}

export interface Contract {
    idContractRule: number
    idContract: string
    contract: string
    percentage: number
}

export interface KPIFilter {
    year: number
    month: number
    costCenter: string
    contractId: string
    jsonDynamicFilter: JsonDynamicFilter
}
export interface KPILastFilter {
    lastPeriod: string
    costCenter: string
    contractId: string
    groupFactor: string
    jsonDynamicFilter: JsonDynamicFilter,
    year: number,
    month: number
}

export interface LoadUsagebyGroupFilter {
    lastPeriod: string
    costCenter: string
    contractId: string
    groupby: string
    jsonDynamicFilter: JsonDynamicFilter
    year?: number,
    month?: number
}

export interface LoadUsagebyGroupFullFilter {
    lastPeriod: string
    costCenter: string
    contractId: string
    groupby: string
    compareToken: string
    filterText: string
    jsonDynamicFilter: JsonDynamicFilter
}

export class FinOpsService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetKPIByYearMonthLastPeriod(lastPeriod: string, costCenter: string, contractId: string, graphGroupType: string): Promise<FinOpsDashBoard[]> {
        try {
            if (contractId === "0") {
                contractId = "";
            }
            const url = this.endpoint + `/api/FinOps/GetKPIByYearMonthLastPeriod?lastPeriod=${lastPeriod}&costCenter=${costCenter}&contractId=${contractId}&groupFactor=${graphGroupType}&refresh=${Math.random()}`;
            let result = await api({
                method: 'get',
                url,
            });
            return result.data as FinOpsDashBoard[];
        } catch (e) {
            return [] as FinOpsDashBoard[];
        }
    }

    async GetLoadUsagebyGroup(lastPeriod: string, groupby: string, contractId: string, costcenterId: string): Promise<GroupReport[]> {

        if (!costcenterId) {
            costcenterId = "0";
        }
        try {
            const url = this.endpoint + `/api/FinOps/LoadUsagebyGroup?lastPeriod=${lastPeriod}&groupby=${groupby}&contractId=${contractId}&costCenterID=${costcenterId}&refresh=${Math.random()}`;

            return (await api({
                method: 'get',
                url,
            })).data as GroupReport[];
        } catch (e) {
            return [] as GroupReport[];
        }
    }

    async GetLoadUsagebyGroupFull(lastPeriod: string,
        groupby: string, contractId: string,
        compareToken: string, filterText: string,
        costcenterId: string): Promise<GroupReport[]> {

        if (!costcenterId) {
            costcenterId = "0";
        }
        try {
            const url = this.endpoint + `/api/FinOps/LoadUsagebyGroupFull?lastPeriod=${lastPeriod}&groupby=${groupby}
                &contractId=${contractId}&compareToken=${compareToken}&filterText=${filterText}&costCenterID=${costcenterId}&refresh=${Math.random()}`;
            return (await api({
                method: 'get',
                url,
            })).data as GroupReport[];
        } catch (e) {
            return [] as GroupReport[];
        }
    }

    async LoadBudgetvsUsagebyGroupFull(costCenterID?: number): Promise<any[]> {
        try {
            const url = this.endpoint + `/api/FinOps/LoadBudgetvsUsagebyGroupFull?costCenterID=${costCenterID || '999999'}&refresh=${Math.random()}`;
            return (await api({
                method: 'get',
                url,
            })).data;
        } catch (e) {
            console.error(e)
            return [];
        }
    }

    async HasSyncFailure() {
        try {
            const url = this.endpoint + '/api/FinOps/HasSyncFailure'
            return (await api({
                method: 'get',
                url
            }).then((response) => {
                return response.data;
            })).catch(erro => {
                return true;
            });
        } catch (e) {
            return true
        }
    }

}

export class FinOpsAdvanceFilterService extends APIClient {


    constructor(props: any) {
        super(props);
    }

    async LoadCostCentersByUserId() {
        const url = this.endpoint + `/api/CostCenter/RetrieveListByClientGroupAndUserId?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async LoadCostCenters(): Promise<CostCenter[]> {
        const url = this.endpoint + `/api/CostCenter/RetrieveListByClientGroupId/ClientGroup?refresh=${Math.random()}`;
        return (await api({
            method: 'get',
            url
        })).data;

    }

    async LoadContracts() {
        const url = this.endpoint + `/api/FinancialManagement/GetCotracts?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }


    async GetKPIByYearMonthLastPeriod(
        _lastPeriod: string,
        _costCenter: string,
        _contractId: string,
        _graphGroupType: string,
        _filter: JsonDynamicFilter,
        year: number = 0,
        month: number = 0): Promise<FinOpsDashBoard[]> {
        // 
        if (!year ) 
            year = 0;

        if (!month)
            month = 0;
        let cancel;


        try {
            if (_contractId === "0") {
                _contractId = "";
            }
            var dynamicFilter: KPILastFilter = {
                lastPeriod: _lastPeriod,
                costCenter: _costCenter,
                contractId: _contractId,
                groupFactor: _graphGroupType,
                jsonDynamicFilter: _filter,
                year: year,
                month: month
            }
            const url = this.endpoint + `/api/FinOpsDynamicFilter/GetKPIByYearMonthLastPeriod`;
            let result = await api({
                method: 'post',
                url,
                data: dynamicFilter
            });
            return result.data as FinOpsDashBoard[];
        } catch (e) {
            // 
            return [] as FinOpsDashBoard[];
        }
    }

    async GetLoadUsagebyGroup(
        _lastPeriod: string,
        _groupby: string,
        _contractId: string,
        _costcenterId: string,
        _filter: JsonDynamicFilter,
        _year?: string,
        _month?: string,
    ): Promise<GroupReport[]> {

        if (!_costcenterId) {
            _costcenterId = "0";
        }
        try {
            var dynamicFilter: LoadUsagebyGroupFilter = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId,
                contractId: _contractId,
                groupby: _groupby,
                jsonDynamicFilter: _filter,
                month: Number(_month),
                year: Number(_year)
            };
            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadUsagebyGroup?refresh=${Math.random()}`;

            return (await api({
                method: 'post',
                url,
                data: dynamicFilter
            })).data as GroupReport[];
        } catch (e) {
            return [] as GroupReport[];
        }
    }

    async LoadCostCenterBudgetSummary(
        _lastPeriod: string,
        _groupby: string,
        _contractId: string,
        _costcenterId: string,
        _filter: JsonDynamicFilter) {

        if (!_costcenterId) {
            _costcenterId = "0";
        }

        try {
            var dynamicFilter: LoadUsagebyGroupFilter = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId,
                contractId: _contractId,
                groupby: _groupby,
                jsonDynamicFilter: _filter
            };
            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadCostCenterBudgetSummary?refresh=${Math.random()}`;

            return (await api({
                method: 'post',
                url,
                data: dynamicFilter
            })).data
        } catch (e) {
            return [];
        }
    }

    createHash(object) {
        const jsonString = JSON.stringify(object);
        return jsonString;
    }

    async GetLoadUsagebyGroupCostCenterDetail(
        _lastPeriod: string,
        _groupby: string,
        _contractId: string,
        _compareToken: string,
        _filterText: string,
        _costcenterId: string,
        _openLevel: number,
        filter: JsonDynamicFilter,
        _year?: string,
        _month?: string,
    ) {

        try {
            let _filter: any = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId,
                contractId: _contractId,
                groupby: _groupby,
                compareToken: _compareToken,
                filterText: _filterText,
                openLevel: _openLevel,
                jsonDynamicFilter: filter,
                month: Number(_month),
                year: Number(_year)
            };
            const jsonString = JSON.stringify(_filter);
            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadUsagebyGroupCostCenterDetail?jsonString=${jsonString}`;
            return (await api({
                method: 'post',
                url,
                data: _filter
            })).data;
        } catch (e) {
            return [];
        }
    }

    async LoadUsagebyGroupCostCenterDetailPercentage(
        _lastPeriod: string,
        _groupby: string,
        _contractId: string,
        _compareToken: string,
        _filterText: string,
        _costcenterId: string,
        _openLevel: number,
        filter: JsonDynamicFilter
    ) {

        try {
            let _filter: any = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId,
                contractId: _contractId,
                groupby: _groupby,
                compareToken: _compareToken,
                filterText: _filterText,
                openLevel: _openLevel,
                jsonDynamicFilter: filter
            };
            const jsonString = JSON.stringify(_filter);
            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadUsagebyGroupCostCenterDetailPercentage?jsonString=${jsonString}`;
            return (await api({
                method: 'post',
                url,
                data: _filter
            })).data;
        } catch (e) {
            return [];
        }
    }


    async GetLoadUsagebyGroupTaxDescritive(
        _lastPeriod: string,
        _groupby: string,
        _costcenterId: string,
        _contractId: string,
        _filter: JsonDynamicFilter
    ): Promise<any[]> {

        try {
            var dynamicFilter: LoadUsagebyGroupFilter = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId || '0',
                contractId: _contractId,
                groupby: _groupby,
                jsonDynamicFilter: _filter
            };

            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadUsagebyGroupTaxDescritive?refresh=${Math.random()}`;

            return (await api({
                method: 'post',
                url,
                data: dynamicFilter
            })).data as GroupReport[];
        } catch (e) {
            return [] as GroupReport[];
        }
    }

    async GetLoadUsagebyGroupFull(
        _lastPeriod: string,
        _groupby: string,
        _contractId: string,
        _compareToken: string,
        _filterText: string,
        _costcenterId: string,
        filter: JsonDynamicFilter): Promise<GroupReport[]> {

        if (!_costcenterId) {
            _costcenterId = "0";
        }

        try {
            var dynamicFilter: LoadUsagebyGroupFullFilter = {
                lastPeriod: _lastPeriod,
                costCenter: _costcenterId,
                contractId: _contractId,
                groupby: _groupby,
                compareToken: _compareToken,
                filterText: _filterText,
                jsonDynamicFilter: filter
            };
            const url = this.endpoint + `/api/FinOpsDynamicFilter/LoadUsagebyGroupFull?refresh=${Math.random()}`;
            return (await api({
                method: 'post',
                url,
                data: dynamicFilter
            })).data as GroupReport[];
        } catch (e) {
            return [] as GroupReport[];
        }
    }

    async HasSyncFailure() {
        try {
            const url = this.endpoint + '/api/FinOps/HasSyncFailure'
            return (await api({
                method: 'get',
                url
            }).then((response) => {
                return response.data;
            })).catch(erro => {
                return true;
            });
        } catch (e) {
            return true
        }
    }

}