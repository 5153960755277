
import api, { APIClient } from '../../api';
import { Assistant, AssistantsResponse, Pagination } from '../assistantsTypes';
import axios from 'axios';


export class AssistantService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    //limit = request.args.get('limit', default = 20, type = int)   # Default limit set to 20
    //offset = request.args.get('offset', default = 0, type = int)  # Default offset set to 0

    /**
 * Fetches assistants with cursor-based paging support.
 *
 * @param {number} limit - The maximum number of assistants to retrieve.
 * @param {string} order - The sort order ('asc' or 'desc').
 * @param {string | null} after - Cursor for the next page.
 * @param {string | null} before - Cursor for the previous page.
 * @returns {Promise<AssistantsResponse>} - Returns the API response.
 */
    public async getAssistants(
        limit: number = 20,
        order: string = 'desc',
        after: string | null = null,
        before: string | null = null,
        search: string | null = null,
    ): Promise<AssistantsResponse> {
        try {
            const url = `${this.baseUrl}/api/assistants/`;
            console.log(`Request URL: ${url}?limit=${limit}&order=${order}&after=${after}&before=${before}&search=${search}`);

            // Retrieve authentication tokens from session storage
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');

            // Validate authentication tokens
            if (!LiveCloud_AuthMS || !LiveCloud_AuthLC) {
                console.error('Authentication tokens are missing.');
                throw new Error('Authentication tokens are missing.');
            }

            // Create an Axios instance with default headers
            const apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: '1',
                    //  PathRoute: window.location.pathname,
                    // 'Access-Control-Allow-Origin': '*'
                    // 'Access-Control-Allow-Origin' is typically a response header and should not be set in requests
                },
                params: {
                    limit,
                    order,
                    after,
                    before,
                    search
                },
                timeout: 10000, // 10 seconds
            });

            console.log('Fetching assistants with paging:', { limit, order, after, before });

            // Make the GET request with query parameters
            const response = await apiAIX.get(url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        // PathRoute: window.location.pathname,
                        //'Access-Control-Allow-Origin': '*'
                    },
                    params: {
                        limit,
                        order,
                        after,
                        before,
                        search
                    }
                });

            console.log('Assistant API Response:', response.data);

            // Destructure and return the data from the response
            return response.data as AssistantsResponse;
        } catch (e: any) {
            console.error('Error fetching assistants:', e.message || e);
            throw e;
        }
    }

    public async createAssistant(assistant: Assistant) {
        try {
            var url = `${this.baseUrl}/api/assistants/`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    // PathRoute: window.location.pathname,
                    // 'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.post(
                url, assistant,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //  PathRoute: window.location.pathname,
                        //'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            return response.data;
        } catch (e) {
            console.log(e)
            return {};
        }
    }

    public async getAssistant(assistantId: string) {
        try {
            var url = `${this.baseUrl}/api/assistants/${assistantId}`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    //'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        //'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data.data);
            return response.data;
        } catch (e) {
            console.log(e)
            return {};
        }
    }

    public async GetOpenAIModels(): Promise<{ value: string, label: string }[]> {
        try {
            var url = `${this.baseUrl}/api/GetOpenAIModels`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    // 'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('GetOpenAIModels');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        // 'Access-Control-Allow-Origin': '*'
                    }
                }
            );

            console.log(response);
            console.log('paas GetOpenAIModels');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data);
            return response.data.map(c => { return { value: c.id, label: c.id }; });
        } catch (e) {
            console.log(e)
            return [];
        }
    }


    public async updateAssistant(assistantId: string, assistant: Assistant) {
        try {
            var url = `${this.baseUrl}/api/assistants/${assistantId}`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    //'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.post(
                url, assistant,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        //  'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data.data);
            return response.data;
        } catch (e) {
            console.error(e)
            console.log(e)
            return {};
        }
    }

    public async deleteAssistant(assistantId: string) {
        console.log(`${this.endpoint}/api/assistants/${assistantId}`);
        const response = await api.delete(`${this.endpoint}/api/assistants/${assistantId}?refresh=${Math.random()}`);
        console.log(response.data);
        return response.data;
    }
}
