import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { SaasService } from '../../../services/saas/saasService';
import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import Moment from 'moment';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import GaugeChart from '../../../components/Layout/Dashboard/gaugeChart';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

const CSPDashBoard: React.FC = (props) => { 
    const user = useSelector<RootState, UserState>(state => state.user);

    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(Moment().format('M'));
    const [year, setYear] = useState<string>(Moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const saasService = new SaasService(props);

    useEffect(() => {

        setSloChart(0);
        setSlaChart(0);
        setChamadosChart({ title: "", labels: [], values: [] });
        setTotalChamadosChart({ title: "", labels: [], values: [] });
        setChamadosPorOrigemChart({ title: "", labels: [], values: [] });

        if (user.ClientGroupSelected) {

            setLoading(true);

            saasService.GetCards(month, year)
                .then(response => {
                    if (response.data != null) {
                        setSloChart(response.data.slaConclusao === 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));

                        setSlaChart(response.data.slaAtendimento === 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));
                        let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                            (response.data.totalChamadosHC + 10)
                            : (response.data.totalChamadosFHC + 10);

                        let chamadosChart: DoughnutChartValue = {
                            title: 'Chamados por horário',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosHC);
                            chamadosChart.labels.push('Comercial');
                        }
                        if (response.data.totalChamadosFHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosFHC);
                            chamadosChart.labels.push('Não comercial');
                        }
                        setChamadosChart(chamadosChart);
                        setIsLoadingChamadosChart(false)

                        let totalChamadosChart: DoughnutChartValue = {
                            title: 'Chamados por situação',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                            totalChamadosChart.labels.push('Abertos');
                        }
                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                            totalChamadosChart.labels.push('Fechados');
                        }
                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                            totalChamadosChart.labels.push('Cancelados');
                        }
                        setTotalChamadosChart(totalChamadosChart);
                        setIsLoadingTotalChamadosChart(false)

                        let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                        let _c = clientName.split(' ')
                        let capitalized = '';
                        _c.map((e: any) => {
                            capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                        })

                        let chamadosPorOrigemChart: DoughnutChartValue = {
                            title: 'Chamados por origem',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosAbertosPeloCliente > 0) {
                            chamadosPorOrigemChart.labels.push(capitalized)
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                        }
                        if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                            chamadosPorOrigemChart.labels.push('Processor')
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                        }
                        setChamadosPorOrigemChart(chamadosPorOrigemChart)
                        setIsLoadingChamadosPorOrigemChart(false)
                    }

                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setLoading(false);
                });

        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);
     
    const onChagePeriod = (selected: any) => {

        let momentDate = Moment(selected.fields[0].value).format('YYYY-M');
        let date = momentDate;
        let currentDay = '01';
        let currentMonth = date.split('-')[1];
        let currentYear = date.split('-')[0];
        setDay(currentDay);
        setMonth(currentMonth);
        setYear(currentYear);
        setSearchStartDate(`${currentYear}${currentMonth.padStart(2, '0')}${currentDay}`);
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-M') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-M') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-M') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    }

    return (
        <Layout pageTitle="Resumo" periodSelection={periodSelection}> 
            <LCFlexContainer spacing={2}>
                <LCFlexItem xs={12} sm={6} md={3} lg={3}>
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChart steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={slaChart} title="SLA de atendimento" info="Específico da solução"></GaugeChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={3} lg={3}>
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChart steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={sloChart} title="SLO de conclusão" info="Específico da solução"></GaugeChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosChart}>
                        <DoughnutChart fullHeigth={true} data={chamadosChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingTotalChamadosChart}>
                        <DoughnutChart data={totalChamadosChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosPorOrigemChart}>
                        <DoughnutChart data={chamadosPorOrigemChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
            </LCFlexContainer>
        </Layout>
    );
};

export default CSPDashBoard;