import React from 'react';
import Chart from '../Chart';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { defaultTooltipConfig } from '../../../Generic/eChartsConfig';
const BarChart: React.FC<{ data, showline?, showBar?, seamless?: boolean, title?: string, sufixoX?: string, suffixX?: string, suffixY?: string }> = ({ data, showline = true, showBar = true, seamless = false, title, suffixX='', suffixY='' }) => {

    const screenWidth = window.innerWidth;
    let fontSize = screenWidth <= 1400 ? 10 : 10;
    
    const options = {       
        title: {
            text: title,
            right: "0",
            top: "0",
            textStyle: {
                fontSize: 11,
                fontWeight:'normal',
                color:'#8b90a3',
                fontFamily:'Ubuntu',
            },
            backgroundColor: title ? '#ffffff90' : 'transparent',
            borderRadius: [10],
            padding: [4, 8, 4, 8],
            itemGap: 8
        },
        grid: {
            top: !title ? '5px' : '25px',
            bottom: '5px',
            left: '0%',
            right: '0%',
            containLabel: true
        },
        tooltip: {
            ...defaultTooltipConfig,
            trigger: 'axis',
            formatter: function (params) {
                const dataPoint = params[0];
                return `${dataPoint.name} ${suffixX}: <b>${dataPoint.value.toFixed(2)}${suffixY}</b>`;
            },
        },
        xAxis: {
            boundaryGap: true,
            type: 'category',
            data: data.map(item => item.name),
            axisLabel: {
                fontSize: fontSize + 'px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                //interval: 1,
                formatter: function (params) {
                    return params + suffixX;
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'dashed'
                },
            },
            axisLine: {
                symbol: "none",
                lineStyle: {
                    type: 'dashed'
                }
            },
            show: true
        },
        yAxis: {
            axisLabel: {
                fontSize: fontSize + 'px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                formatter: function (params) {
                    return params + suffixY;
                },                
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                },
            },
            type: 'value',
            show: true
        },
        series: [] as {
            data: any, type: string, itemStyle: { color: string }, showSymbol?: boolean, lineStyle?: {
                color: string, width: number},
        }[],
    };

    if (showBar) {
        options.series.push({
            data: data.map(item => item.value),
            type: 'bar',
            itemStyle: {
                color: '#966CF3',
            },
            showSymbol: true
        });
    }
    // Conditionally add a line series over the bars
    if (showline) {
        options.series.push({
            data: data.map(item => item.value),
            type: 'line',
            // smooth: true, // Optional: makes the line smooth
            itemStyle: {
                color: '#2E1956',
            }, lineStyle: {
                color: '#2E1956',
                width: 3  
            },
            showSymbol: true,
        });
    }

    return <ReactECharts style={{ width: '100%', height: '100%' }} option={options} opts={{ renderer: 'svg', locale: 'PT-br' }} />;
};

export default BarChart;
