import { PP_DropDownOld, PPMultiSelect, PPTextField, PPModal } from 'processor-plataform-ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LcCheckBox from '../../../../components/Form/LcCheckBox';
import { Context, Notification, NotificationCenterService, NotificationSend } from '../../../../services/notificationCenter/notificationService';
import { PortalUsersService } from '../../../../services/portalUsers/portalUsers';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { useDispatch } from 'react-redux';

import styles from './index.module.css'

interface Props {
    callBackCancel: Function,
    visible: boolean
}

const CreateForm: React.FC<Props> = (props) => {

    //Services
    /*   const servicePortalUser = new PortalUsersService(props);
       const notificationService = new NotificationCenterService(props);
   
       //States
       const [optionsClient, setOptionsClient] = useState<{ value: string, label: string, selected?: boolean, disabled?: boolean }[]>([{ value: '-1', label: 'Selecione uma opção' }]);
       const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
       const [record, setRecord] = useState<Notification>(notificationService.GetEmptyNotification());
       const [notificationSend, setNotificationSend] = useState<NotificationSend>(notificationService.GetEmptynotificationSend());
       const [optionsSeverity, setOptionsSevferity] = useState(notificationService.GetContextOptions());
       const [severity, setSeverity] = useState(Number(Context.info).toString())
       const [isSaving, setIsSaving] = useState<boolean>(false)
   
       //Redux
       const user = useSelector<RootState, UserState>(state => state.user);
       const dispatch = useDispatch();
   
       useEffect(() => {
           setIsLoadingClients(true);
           servicePortalUser.getClientActive().then(response => {
               let options = response.data.map((element) => {
                   return {
                       value: element.id,
                       label: element.description,
                       selected: (element.id == 1),
                       disabled: false
                   }
               })
               setOptionsClient(options)
               setIsLoadingClients(false)
           }).catch(err => {
               setIsLoadingClients(false)
           })
       }, [])
   
       const setDataNotification = (value) => {
           let notification = { ...record }
           notification.content = value
   
           setRecord(notification);
       }
   
       const OnChangeClient = (valueObj: { target: { name: string, value: { value: number, label: string }[] } }) => {
           if (valueObj.target.value.length == 0) {
               setNotificationSend({ ...notificationSend, clientGroupIds: [] });
               return;
           } else {
               let notification = { ...notificationSend }
               let cgIds = valueObj.target.value.map((element: { value: number, label: string }) => {
                   return element.value
               })
               notification.clientGroupIds = cgIds;
               setNotificationSend(notification);
           }
       }
   
       const OnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
           if (!event) return
           event.preventDefault()
           switch (event.target.name) {
               case 'data':
                   setDataNotification(event.target.value)
                   break;
   
               default:
                   let notification = { ...record }
                   notification[event.target.name] = event.target.value
                   setRecord(notification)
                   break;
           }
       }
   
       const SaveRecord = () => {
           setIsSaving(true);
           let notification = {
               clientGroupIds: notificationSend.clientGroupIds,
               context: Context[notificationSend.context],
               pinned: notificationSend.pinned,
               sendEmail: notificationSend.sendEmail,
               notification: record
           };
   
           try {
               let response = notificationService.Post(notification);
               Notify('success', 'Notificação', 'A notificação foi criada com sucesso e será enviada para os usuários do cliente(s) selecionado(os).');
               setIsSaving(false)
               props.callBackCancel && props.callBackCancel();
           } catch (err) {
               console.warn(err);
               setIsSaving(false)
               Notify('danger', 'Notificação', 'Ocorreu um erro ao enviar a notificação.');
           }
       }
   
       const CancelSave = () => {
           setNotificationSend(notificationService.GetEmptynotificationSend())
           setRecord(notificationService.GetEmptyNotification())
           props.callBackCancel && props.callBackCancel();
       }
   
       const onChangeDropDown = (params) => {
           let value = Number(params.target.value);
           let notification = { ...notificationSend }
           setSeverity(value.toString())
           switch (value) {
               case 2:
                   notification.context = Context.danger
                   break;
               case 0:
                   notification.context = Context.success
                   break;
               case 1:
                   notification.context = Context.warning
                   break;
               default:
                   notification.context = Context.info
                   break;
           }
           setNotificationSend(notification)
       }
   
       const onChangeCheckBox = (checkValue: { checked: boolean, name: string }) => {
           let _n = { ...notificationSend }
           _n[checkValue.name] = checkValue.checked
           setNotificationSend(_n)
       }
   
       const onChangeDate = (event) => {
           event.preventDefault()
           let notification = { ...record }
           notification.expiredDate = event.target.value;
           setRecord(notification)
       }
   
       const functions = [
           {
               label: (
                   <>
                       {isSaving ? 'Salvando' : 'Salvar'}
                   </>
               ),
               onClick: SaveRecord,
               disabled: isSaving
           }
       ]
   
       const Notify = (type: 'default' | 'danger' | 'success' | 'warning' | 'info', title: string, content: string) => {
           let msg = {
               subject: title,
               content: content,
               context: type,
           };
           notificationService.PostInterfaceNotification(dispatch, msg);
       }
   
       const GetMaxDate = () => {
           let now = new Date();
           let date = new Date(now.getFullYear(), now.getMonth(), (now.getDate() + 180));
           return date.toISOString().split("T")[0];
       }
   
       const GetMinDate = () => {
           let now = new Date();
           return now.toISOString().split("T")[0];
       }
   */
    return (<></>)
    //     <PPModal
    //         title='Criação de notificação'
    //         visible={props.visible}
    //         onClose={CancelSave}
    //         functions={functions}>

    //         <PPTextField name={'title'} label={'Título'} required onChange={OnChange} /> <br />
    //         <PPTextField name={'content'} label={'Mensagem'} required multiline onChange={OnChange} /><br />
    //         {optionsSeverity.length > 0 && !notificationSend.pinned &&
    //             <span className={styles.severity}>
    //                 <PPDropDown
    //                     name='severity'
    //                     onChange={onChangeDropDown}
    //                     width="420px"
    //                     options={optionsSeverity}
    //                     title="Severidade"
    //                     openTrigger="click"
    //                     required
    //                     value={severity}
    //                 />
    //                 <i className={`ppi ppi-bell colorBell-${Context[notificationSend.context]}`} />
    //             </span>
    //         }
    //         <PPTextField name={'url'} label={'URL'} required onChange={OnChange} /><br />
    //         <small style={{ fontSize: '12px' }}>Clientes</small>
    //         <PPMultiSelect options={optionsClient} required onChange={OnChangeClient} name="Clientes" openTrigger="click" key={'clientGroupIds'} /><br />

    //         <span className='pinned'>
    //             <p><LcCheckBox name='pinned' title='Fixada' onChange={onChangeCheckBox} /></p>
    //             <p><LcCheckBox name='sendEmail' title='Notificar por e-mail' onChange={onChangeCheckBox} /></p>
    //             <div>
    //                 <br />
    //                 {notificationSend.pinned &&
    //                     <PPTextField required type='date' label='Data de expiração' name='expiredDate' onChange={onChangeDate} max={`${GetMaxDate()}`} min={GetMinDate()} />
    //                 }
    //             </div>
    //         </span>
    //     </PPModal>
    // );
}

export default CreateForm;