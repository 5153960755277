import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';

import VinSubscriptionService from '../../../services/EASubscriptionService/EASubscriptionService'
import LcIconLink from '../../../components/Generic/LcIconLink';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import { InputField } from '../../../components/Form/Input';

import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import { TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';

import './index.css';

import history from '../../../history';

import { useSnackbar } from 'notistack';

//Img Fluxo
import ImgFluxo from '../../../assets/images/Fluxos/eaeas.png';
import Confirmation from '../../../components/Dialog/Confirmation';
import { PPGrid, PPInput, PPModal } from 'processor-plataform-ui';
import PPCheckBox from '../../../components/Form/PPCheckBox';
import { PiMinusCircleLight, PiPencilSimpleLight, PiPlusCircleLight, PiPlusLight, PiTrashLight } from 'react-icons/pi';

const VinSubscriptionToEA: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const service = new VinSubscriptionService(props);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);

    const [sideModalTitle, setSideModalTitle] = useState<string>("")
    const [sideModalVisible, setSideModalVisible] = useState<boolean>(false);

    const [fullModalVincVisible, setFullModalVincVisible] = useState<boolean>(false);

    const [lastUpdate, setLastUpdate] = useState<{ LastUpdateSIP: Date, LastUpdateGraph: Date }>();


    //Para edição e criação
    const [alias, setAlias] = useState<string>('');
    const [withdraw, setWithdraw] = useState<boolean>(false);
    const [grupoAvaliableforExecutives, setGrupoAvaliableforExecutives] = useState<boolean>(true);
    const [unitPrice, setUnitPrice] = useState<number>(0)
    const [groupName, setGroupName] = useState<string>('')
    const dispatch = useDispatch();

    const sideModalOnClose = () => {
        setVinSubscriptionsSelected(undefined);

        setSideModalTitle('');
        setSideModalVisible(false);

        setAlias('');
        setWithdraw(false);

    }

    const loadData = () => {
        setLoading(true);
        service.GetVinSubscriptions()
            .then(response => {

                setShowFluxoEAEAS(false)

                var _data = response.data.sort((a: any, b: any) => { return a.aliasName > b.aliasName ? 1 : -1 });

                setVinSubscriptions(_data);
                setVinSubscriptionsFiltered(_data);

                setSoftwares([...new Set(_data.map(x => x.aliasName) as string[])]);
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false)
            })

        service.GetOfficeSubscriptions()
            .then(response => {
                setOfficeSubscriptions(response.data);
                setOfficeSubscriptionsFiltered(response.data);
            })
            .catch(console.warn)

        service.GetContractLicences()
            .then(response => {
                let array = response.data.map((c: any, i: number) => {
                    return {
                        ...c,
                        id: i
                    }
                })
                setContractLicenses(array);
                setContractLicensesFiltered(array);
            })
            .catch(console.warn)

        service.GetLastUpdateProvider().then(response => {
            const sipDate = new Date(response.data.lastUpdateSIP)
            const graphDate = new Date(response.data.lastUpdateGraph)
            setLastUpdate({ LastUpdateGraph: graphDate, LastUpdateSIP: sipDate })
        })
    }

    useEffect(loadData, [user.ClientGroupSelected, user.refreshFlag]);


    /**********************
    Vínculos
    ***********************/
    const [vinSubscriptions, setVinSubscriptions] = useState<any[]>([]);
    const [softwares, setSoftwares] = useState<string[]>([]);
    const [vinSubscriptionsFiltered, setVinSubscriptionsFiltered] = useState<any[]>([]);
    const [vinSubscriptionsSelected, setVinSubscriptionsSelected] = useState<any | undefined>()

    /**********************
    OfficeSubscriptions
    ***********************/
    const [officeSubscriptions, setOfficeSubscriptions] = useState<any[]>([]);
    const [officeSubscriptionsFiltered, setOfficeSubscriptionsFiltered] = useState<any[]>([]);
    const [officeSubscriptionsFilter, setOfficeSubscriptionsFilter] = useState<{ term: string, column: string, direction: string }>({ term: '', column: '', direction: 'asc' });
    const [officeSubscriptionsSelected, setOfficeSubscriptionsSelected] = useState<any[]>([]);
    const columnsOfficeSubscriptions = [
        { field: 'skuPartNumber', headerName: 'Licença do Office', width: "100%" }
    ];
    const filterOfficeSubscriptions = {
        fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
        onChange: (filter: any, size: number) => {
            let term = filter[0].value.toLowerCase();
            if (term !== "") {
                setOfficeSubscriptionsFilter({ ...officeSubscriptionsFilter, term: term });
                setOfficeSubscriptionsFiltered(
                    officeSubscriptions
                        .filter(l => { console.log(l, term); return l.skuPartNumber.toLowerCase().indexOf(term) >= 0 })
                        .sort((a: any, b: any) => {
                            return a[officeSubscriptionsFilter.column] > b[officeSubscriptionsFilter.column] ?
                                (officeSubscriptionsFilter.direction == 'asc' ? 1 : -1) : (officeSubscriptionsFilter.direction == 'asc' ? -1 : 1)
                        })
                );
            } else {
                setOfficeSubscriptionsFiltered(
                    officeSubscriptions
                        .sort((a: any, b: any) => {
                            return a[officeSubscriptionsFilter.column] > b[officeSubscriptionsFilter.column] ?
                                (officeSubscriptionsFilter.direction == 'asc' ? 1 : -1) : (officeSubscriptionsFilter.direction == 'asc' ? -1 : 1)
                        })
                )
            }
        }
    };
    const onSortChangeOfficeSubscriptions = (sortData: any) => {
        const { sort, size } = sortData;
        setOfficeSubscriptionsFilter({ ...officeSubscriptionsFilter, column: sort.column, direction: sort.direction });
        officeSubscriptions.length > 1 &&
            setOfficeSubscriptionsFiltered(
                officeSubscriptions
                    .sort((a: any, b: any) => { return a[sort.column] > b[sort.column] ? (sort.direction == 'asc' ? 1 : -1) : (sort.direction == 'asc' ? -1 : 1) })
                    .filter(l => { return (l.produto as string).toLowerCase().indexOf(officeSubscriptionsFilter.term) >= 0 })
            );
    };

    /**********************
    ContractLicenses
    ***********************/
    const [contractLicenses, setContractLicenses] = useState<any[]>([]);
    const [contractLicensesFiltered, setContractLicensesFiltered] = useState<any[]>([]);
    const [contractLicensesFilter, setContractLicensesFilter] = useState<{ term: string, column: string, direction: string }>({ term: '', column: '', direction: 'asc' });
    const [contractLicensesSelected, setContractLicensesSelected] = useState<any[]>([]);
    const columnsContractLicenses = [
        { field: 'produto', headerName: 'Recurso', width: "80%" },
        { field: 'quantidade', headerName: 'Quant.', width: "10%" }
    ];
    const filterContractLicenses = {
        fields: [{ label: 'Termo', name: 'termo', type: 'text' }],
        onChange: (filter: any, size: number) => {
            let term = filter[0].value.toLowerCase();
            setContractLicensesFilter({ ...contractLicensesFilter, term: term });
            contractLicenses.length > 0 &&
                setContractLicensesFiltered(
                    contractLicenses
                        .sort((a: any, b: any) => { return a[contractLicensesFilter.column] > b[contractLicensesFilter.column] ? (contractLicensesFilter.direction == 'asc' ? 1 : -1) : (contractLicensesFilter.direction == 'asc' ? -1 : 1) })
                        .filter(l => { return (l.produto as string).toLowerCase().indexOf(term) >= 0 })
                );
        }
    };
    const onSortChangeContractLicenses = (sortData: any) => {
        const { sort, size } = sortData;
        setContractLicensesFilter({ ...contractLicensesFilter, column: sort.column, direction: sort.direction });
        setContractLicensesFiltered(
            contractLicenses
                .sort((a: any, b: any) => { return a[sort.column] > b[sort.column] ? (sort.direction == 'asc' ? 1 : -1) : (sort.direction == 'asc' ? -1 : 1) })
                .filter(l => { return (l.produto as string).toLowerCase().indexOf(contractLicensesFilter.term) >= 0 })
        );
    };

    /*****************************/

    useEffect(() => {
        enqueueSnackbar(
            `Caso sejam feitas alterações nas informações, clique em "atualizar" para que o reprocessamento seja realizado`
            , {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
                action: key => (<span className="link" onClick={() => { closeSnackbar(key) }} >X</span>)
            })
    }, [])


    const edit = (p: any) => {

        setVinSubscriptionsSelected(p);

        setSideModalTitle(`Edição ${p.aliasName}`)
        setAlias(p.aliasName);
        setGroupName(p.groupName);
        setGrupoAvaliableforExecutives(p.grupoAvaliableforExecutives);
        setUnitPrice(p.unitPrice)
        setWithdraw(p.withdraw);

        setSideModalVisible(true);
    }

    const exclude = (p: any) => {

        service.Delete([p])
            .then(result => {

                let _old = vinSubscriptions
                let _new: any[] = []

                setVinSubscriptions([])
                enqueueSnackbar(`Exclusão realizada com êxito`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })

                _old.forEach((e: any) => {
                    if (e.id != p.id) {
                        _new.push(e)
                    }
                });
                setVinSubscriptions(_new)
            }).catch(error => {
                console.log(error)
                enqueueSnackbar(`Erro ao excluir o vínculo`, {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })
            })
            .then(() => {
                loadData();
            })
    }


    const UpdateRecord = () => {
        setLoadingForm(true);
        let EatoSubScription = {
            Id: vinSubscriptionsSelected.id,
            SkuId: vinSubscriptionsSelected.skuId,
            SKUPartNumber: vinSubscriptionsSelected.skuPartNumber,
            Easubscription: vinSubscriptionsSelected.easubscription,
            AliasName: alias,
            Withdraw: withdraw,
            UnitPrice: unitPrice,
            GroupName: groupName,
            GrupoAvaliableforExecutives: grupoAvaliableforExecutives,
            ClientGroupId: vinSubscriptionsSelected.clientGroupId
        };

        service.Update(EatoSubScription)
            .then(result => {
                enqueueSnackbar(`Registro atualizado com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
            })
            .catch(err => {
                enqueueSnackbar(`Erro ao atualizar registro`, {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })
                console.log(err);
            })
            .then(() => {
                setLoadingForm(false);
                sideModalOnClose()
                loadData();

            })
    }

    const changeWithdraw = (data: any) => {
        console.debug(data);
        service.Update({ ...data, withdraw: !data.withdraw })
            .then(result => {
                enqueueSnackbar(`Registro atualizado com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
            })
            .catch(err => {
                enqueueSnackbar(`Erro ao atualizar registro`, {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })
                console.log(err)
            }
            )
            .then(() => {
                setLoadingForm(false);
                sideModalOnClose()
                loadData();
            })
    }

    const [aliasToSend, setAliasToSend] = useState<string>()
    const [unitPriceToSend, setUnityPriceToSend] = useState<string>('0')
    const [groupNameToSend, setGroupNameToSend] = useState<string>('')
    const [grupoAvaliableforExecutivesToSend, setGrupoAvaliableforExecutivesToSend] = useState<boolean>(false)
    const [withdrawToSend, setWithdrawToSend] = useState<boolean>(false)

    const CreateLink = () => {

        if (!aliasToSend) {
            enqueueSnackbar("O campo 'Alias' está em branco", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }

        if (!groupNameToSend) {
            enqueueSnackbar("O campo 'Grupo' está em branco", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }

        if (officeSubscriptionsSelected.length != 1) {
            enqueueSnackbar("Apenas uma subscrição ser selecionada.", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }

        if (contractLicensesSelected.length != 1) {
            enqueueSnackbar("Apenas um software deve ser selecionado.", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }

        if (Number(unitPriceToSend) < 0 || !unitPriceToSend) {
            enqueueSnackbar("Valor monetário inválido.", {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }
        //  const [grupoAvaliableforExecutives, setGrupoAvaliableforExecutives] = useState<boolean>(true);
        setLoadingForm(true);
        var objectToSend = [
            {
                ClientGroupId: user.ClientGroupSelected,

                AliasName: aliasToSend,
                GroupName: groupNameToSend,
                Withdraw: withdrawToSend,
                GrupoAvaliableforExecutives: grupoAvaliableforExecutivesToSend,

                //OfficeSubscription
                SkuId: officeSubscriptionsSelected[0].skuId,
                SKUPartNumber: officeSubscriptionsSelected[0].skuPartNumber,

                //ContractLicense
                Easubscription: contractLicensesSelected[0].produto,

                //Monay to send is opcional value
                UnitPrice: unitPriceToSend
            }
        ]

        service.CreateLink(objectToSend)
            .then(result => {
                enqueueSnackbar("Vínculo criado com sucesso.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
            })
            .catch(err => {
                enqueueSnackbar("Erro ao criar vínculo.", {
                    variant: 'warning',
                    preventDuplicate: true,
                    persist: false,
                })

            })
            .then(() => {
                setLoadingForm(false);
                setFullModalVincVisible(false);
                loadData();
            })

    }

    //#region Verifica se há licença EA/EAS
    let instance = new TechEAEASService(props);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState<boolean>(false);
    useEffect(() => {
        menu && menu.selectedItem && menu.selectedItem.id == 'cedcb443-a23e-4545-904b-5d1061e718aa' &&
            instance.GetSaasLicenseByCountryTotalView()
                .then(response => {
                    setShowFluxoEAEAS(false)
                })
                .catch(error => {
                    setShowFluxoEAEAS(true);
                });
    }, [menu.selectedItem])
    //#endregion

    return (
        <Layout
            pageTitle="Vínculo entre contrato e subscrição"
            functionsGeneric={[{ icon: <PiPlusLight />, onClick: () => setFullModalVincVisible(true), tooltip: "Adicionar" }]}

        >
            {
                showFluxoEAEAS ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxo} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <>
                        {
                            !fullModalVincVisible
                                ?
                                <LcLoading loadingType='Helix' loading={loading} >



                                    {/* 
<div className="sequence">
    {lastUpdate &&
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right', fontSize: 'small' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Última atualização do provedor: {lastUpdate.LastUpdateGraph.getDate().toString().padStart(2, '0')}/{(lastUpdate.LastUpdateGraph.getMonth() + 1).toString().padStart(2, '0')}/{lastUpdate.LastUpdateGraph.getFullYear()} {lastUpdate.LastUpdateGraph.getHours().toString().padStart(2, '0')}:{lastUpdate.LastUpdateGraph.getMinutes().toString().padStart(2, '0')}</p>                
                                </div>
        </div>
    }
        </div>
        */}


                                    <div className="lc-vinsubscriptions">
                                        {
                                            softwares.map((s, i) => {
                                                var item = vinSubscriptions.find(vs => vs.aliasName == s);
                                                return (
                                                    <div key={i} className="lc-segment">

                                                        <div className="title">
                                                            <span>{s}</span>
                                                            <span>{item.groupName}</span>
                                                            {
                                                                (() => {

                                                                    return item.unitPrice ?
                                                                        <span className="cost">
                                                                            {
                                                                                new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.unitPrice || 0)
                                                                            }
                                                                        </span>
                                                                        :
                                                                        <span className="text-danger font-6x" style={{ fontWeight: '500' }}>Valor não definido</span>
                                                                })()

                                                            }
                                                        </div>
                                                        <div className="secondary">
                                                            {item.skuPartNumber}
                                                            <LcIconLink icon={<PiPencilSimpleLight />} size="small" tooltip="Editar" onClick={() => { edit(item) }} />

                                                        </div>
                                                        <table className="table">
                                                            <tbody>
                                                                {
                                                                    vinSubscriptions.filter(vs => vs.aliasName == s).map(sub => {

                                                                        return (
                                                                            <tr>
                                                                                <td style={{ width: '100%' }}>
                                                                                    <span> {sub.easubscription}</span>
                                                                                </td>
                                                                                <td className="sequence" style={{ width: '62px' }}>
                                                                                    <Confirmation confirm={() => { changeWithdraw(sub) }} text={`Esta alteração pode gerar distorções nos relatórios e orçamentos. ${!sub.withdraw ? 'Confirma a alteração da operação para "SUBTRAIR"?' : 'Confirma a alteração da operação para "SOMAR"?'}`}>
                                                                                        <LcIconLink icon={
                                                                                            !sub.withdraw ? (
                                                                                                <PiPlusCircleLight className="text-success" />
                                                                                            ) : (
                                                                                                <PiMinusCircleLight className="text-warning" />
                                                                                            )
                                                                                        } size="small" tooltip={!sub.withdraw ? 'Alterar para "SUBTRAIR"' : 'Alterar para "SOMAR"'} />
                                                                                    </Confirmation>
                                                                                    <Confirmation confirm={() => { exclude(sub) }} text="Esta alteração pode gerar distorções nos relatórios e orçamentos. Confirma a exclusão?">
                                                                                        <LcIconLink icon={<PiTrashLight />} size="small" tooltip="Excluir" />
                                                                                    </Confirmation>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/*grupoAvaliableforExecutives, setGrupoAvaliableforExecutives*/}
                                    <SideModal header={sideModalTitle} visible={sideModalVisible} onClose={sideModalOnClose}>
                                        <div className="lc-segment bg-info">
                                            Alterações poderão gerar distorções nos relatórios e orçamentos
                                        </div>
                                        <div className="form" style={{height: 'auto'}}>
                                            <LcLoading loading={loadingForm} label="Salvando registro...">
                                                <PPInput title="Alias" type="text" value={alias} onChange={event => { setAlias(event.target.value); }} />

                                                <PPInput name='group' title="Grupo" type="text" value={groupName} onChange={event => setGroupName(event.target.value)} />
                                                <PPInput name='money' title="Valor monetário" type="number" value={unitPrice} onChange={event => setUnitPrice(event.target.value)} />
                                                <PPCheckBox
                                                    title='Disponivel para relatórios executivos'
                                                    name='grupoAvaliableforExecutives'
                                                    onChange={event => { setGrupoAvaliableforExecutives(event.target.value); }}
                                                    checked={grupoAvaliableforExecutives}
                                                />
                                                <div className="commands">
                                                    <button className="lc-button bg-primary" onClick={UpdateRecord}>Salvar</button>
                                                    <button className="lc-button bg-grey" onClick={sideModalOnClose}>Cancelar</button>
                                                </div>
                                            </LcLoading>
                                        </div>

                                    </SideModal>

                                </LcLoading >
                                :
                                <PPModal
                                    title='Adicionar item'
                                    size='large'
                                    loading={loadingForm}
                                    onClose={() => { setFullModalVincVisible(false) }}
                                    visible={fullModalVincVisible}
                                    functions={[
                                        { label: "Salvar", onClick: () => CreateLink() }
                                    ]}
                                >
                                    <div style={{ overflow: 'hidden' }}>
                                        <div className="form">
                                            <div style={{ padding: '.5rem', display: 'flex' }}>
                                                <InputField width='95%' name='alias' label="Alias" type="text" onChange={event => setAliasToSend(event.value)} />
                                                <InputField width='95%' name='group' label="Grupo" type="text" value={groupNameToSend} onChange={event => setGroupNameToSend(event.value)} />
                                                <InputField width='95%' name='money' label="Valor monetário" type="number" value={unitPriceToSend} onChange={event => setUnityPriceToSend(event.value)} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0rem .5rem' }}>
                                                <PPCheckBox
                                                    title='Disponível para relatórios executivos'
                                                    name='grupoAvaliableforExecutives'
                                                    onChange={event => { setGrupoAvaliableforExecutivesToSend(event.target.value); }}
                                                    checked={grupoAvaliableforExecutivesToSend}
                                                />
                                                <LcCheckBox checked={withdrawToSend} name="withdraw" title="Subtrair" onChange={event => { setWithdrawToSend(event.checked) }} />
                                            </div>

                                        </div>

                                        <PPGrid container spacing={1}>
                                            <PPGrid item sm={6} md={6} lg={6} xl={6}>
                                                <div style={{ marginLeft: '.5rem' }} className="title">Subscrição</div>
                                                <LcInfiniteTable
                                                    loading={loading}
                                                    columns={columnsOfficeSubscriptions}
                                                    rows={officeSubscriptionsFiltered}
                                                    filter={filterOfficeSubscriptions}
                                                    size={officeSubscriptionsFiltered.length}
                                                    loadMore={() => { }}
                                                    height="30vh"
                                                    onSortChange={onSortChangeOfficeSubscriptions}
                                                    onSelectChange={setOfficeSubscriptionsSelected}
                                                />
                                            </PPGrid>
                                            <PPGrid item sm={6} md={6} lg={6} xl={6}>
                                                <div style={{ marginLeft: '.5rem' }} className="title">Software</div>
                                                <LcInfiniteTable
                                                    loading={loading}
                                                    columns={columnsContractLicenses}
                                                    rows={contractLicensesFiltered}
                                                    filter={filterContractLicenses}
                                                    size={contractLicensesFiltered.length}
                                                    loadMore={() => { }}
                                                    height="30vh"
                                                    onSortChange={onSortChangeContractLicenses}
                                                    onSelectChange={setContractLicensesSelected}
                                                />
                                            </PPGrid>

                                        </PPGrid>
                                    </div>
                                </PPModal>

                        }
                    </>
            }
        </Layout >
    );
};

export default VinSubscriptionToEA;