import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { SaasService } from '../../../services/saas/saasService';
import { defaults, Bar } from "react-chartjs-2";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';

import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcLoading from '../../../components/Generic/LcLoading';
import LcSegment from '../../../components/Generic/LcSegment';

//Fluxo
import history from '../../../history';
import ImgFluxo from '../../../assets/images/Fluxos/csp.png';
import { CacheManagerService } from '../../../services/cacheManagerService';
import LcNoData from '../../../components/Generic/LcNoData';

const SaaSSubscriptions: React.FC = (props) => {

    defaults.global.defaultFontSize = '10';

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch()
    const saasService = new SaasService(props);
    const cacheManagerService = new CacheManagerService(props);

    const [lastUpdate, setLastUpdate] = useState<string>();
    const [noData, setNoData] = useState<boolean>(false);
    const [activationsChartPC, setActivationsChartPC] = useState<any>();
    const [activationsChartMobile, setActivationsChartMobile] = useState<any>();

    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("ProcessorLiveCloudSyncSaasLicenses", "Office365ActivationCounts")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    useEffect(() => {
        setNoData(false);
        if (user.ClientGroupSelected) {
            setActivationsChartMobile(undefined)
            setActivationsChartPC(undefined)
            saasService.GetActivationChart()
                .then(response => {
                    if (response.data.includes('Object reference not set')) {
                        let defaultProprieties = {
                            labels: [],
                            datasets: []
                        }
                        setActivationsChartPC(defaultProprieties);
                        setActivationsChartMobile(defaultProprieties);
                        setNoData(true);
                        return
                    }

                    let activationsChartMobile = {
                        labels: response.data.map((Item: any) => {
                            if (!lastUpdate && Item.syncRefreshDate != null) {
                                setLastUpdate(Item.syncRefreshDate);
                            }
                            if (Item.productType.length > 15) {
                                var oldL = Item.productType;
                                var l = oldL.split(' ');
                                var newL = l[0];
                                for (var i = 1; i < l.length; i++) {
                                    if (i % 2 == 0)
                                        newL += `|${l[i]}`;
                                    else
                                        newL += ` ${l[i]}`;
                                }
                                let _r = newL.split('|')
                                _r[0] = `    ${_r[0]}`
                                return _r
                            } else {
                                return Item.productType;
                            }
                        }),
                        datasets: [
                            {
                                label: "Android",
                                data: response.data.map((Item: any) => {
                                    return Item.android;
                                }),
                                backgroundColor: "#117D3F",
                                borderColor: "#117D3F"
                            },
                            {
                                label: "iOS",
                                data: response.data.map((Item: any) => {
                                    return Item.iOS;
                                }),
                                backgroundColor: "#00B7C3",
                                borderColor: "#00B7C3",
                                order: 1
                            },
                            {
                                label: "Windows 10 Mobile",
                                data: response.data.map((Item: any) => {
                                    return Item.windows10Mobile;
                                }),
                                backgroundColor: "#BAD24B",
                                borderColor: "#BAD24B",
                                order: 2
                            }
                        ]
                    };

                    let activationsChartPC = {
                        labels: response.data.map((Item: any) => {
                            if (!lastUpdate && Item.syncRefreshDate != null) {
                                setLastUpdate(Item.syncRefreshDate);
                            }

                            if (Item.productType.length > 15) {
                                var oldL = Item.productType;
                                var l = oldL.split(' ');
                                var newL = l[0];
                                for (var i = 1; i < l.length; i++) {
                                    if (i % 2 == 0)
                                        newL += `|${l[i]}`;
                                    else
                                        newL += ` ${l[i]}`;
                                }
                                let _r = newL.split('|')
                                _r[0] = `    ${_r[0]}`
                                return _r
                            } else {
                                return Item.productType
                            }
                        }),
                        datasets: [
                            {
                                label: "Windows",
                                data: response.data.map((Item: any) => {
                                    return Item.windows;
                                }),
                                backgroundColor: "#2476BA",
                                borderColor: "#2476BA"
                            },
                            {
                                label: "Mac",
                                data: response.data.map((Item: any) => {
                                    return Item.mac;
                                }),
                                backgroundColor: "#B4009E",
                                borderColor: "#B4009E"
                            }
                        ]
                    };
                    setActivationsChartPC(activationsChartPC);
                    setActivationsChartMobile(activationsChartMobile);
                    setNoData(false);
                })
                .catch(error => {
                    console.warn(error);
                    setNoData(true);
                });
            refreshLastUpdate();
            setShowFluxoCSP(noData)
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    //#region Verifica se há licença EA/EAS
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    //Em caso de manutenção,link para o React-Chartsjs-2 https://react-chartjs-2.js.org
    //React-Chartsjs-2 utiliza chart JS https://www.chartjs.org/docs/2.9.4/

    const getChartsOptions = (stepSize: number) => {
        return {
            redraw: true,
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                labels: {
                    fontSize: 12
                },
                inactiveColor: '#ededed',
                inactiveBorderColor: '#ededed',
                display: true,
                position: 'bottom',
                onHover: (event) => {
                    event.target.style.cursor = 'pointer';
                },
                onLeave: (event) => {
                    event.target.style.cursor = 'default';
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks:
                        {
                            beginAtZero: true,
                            stepSize: stepSize
                        }
                    }]
            },
            tooltips: {
                enabled: true,
                yAlign: 'bottom',
                xAlign: 'center',
                position: 'average',
                backgroundColor: 'rgba(0,0,0,0.8)',
                titleFontColor: '#fff',
                bodyFontColor: '#fff',
                footerFontColor: '#fff',
                titleFont: { weight: 'bold' },
                titleFontSize: 12,
                bodyFontSize: 12,
                footerFontSize: 12,
                displayColors: true,
                callbacks: {
                    label: function (tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
                    }
                },
            },
            //aumentar padding top 
            layout: {
                padding: {
                    top: 40
                }
            }

        }
    }
    const onChagePeriod = (selected: any) => {
         
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    return (
        <Layout pageTitle="Ativações" lastUpdate={lastUpdate} periodSelection={periodSelection}>
            {noData && !showFluxoCSP ?                
                <LcNoData />
                :
                <>
                    {
                        showFluxoCSP ?
                            <div className="lc-segment">
                                <div className="body">
                                    Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                    fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true })}}>aqui</span>, e teremos o prazer em atender.
                                    <img src={ImgFluxo} width="100%" alt="Procedimento" />
                                </div>
                            </div>
                            :
                            <LCFlexContainer spacing={2}>

                                <LCFlexItem xs={1} sm={1} md={6} lg={6}>
                                    <LcLoading label="Carregando gráfico..." loading={activationsChartPC == null}>
                                        <LcSegment title="Ativações" fullHeigth={true}>
                                            <p style={{ fontSize: '14px', color: '#8b90a3', marginBottom: '1rem' }}>Ativações do Office para clientes/aplicativos da área de trabalho através de plataformas</p>
                                            {activationsChartPC?.datasets.length > 0 ?
                                                <Bar height={175} data={activationsChartPC} options={getChartsOptions(100)}></Bar>
                                                :
                                                <LcNoData />
                                            }
                                        </LcSegment>
                                    </LcLoading>
                                </LCFlexItem>

                                <LCFlexItem xs={1} sm={1} md={6} lg={6}>
                                    <LcLoading label="Carregando gráfico..." loading={activationsChartMobile == null}>
                                        <LcSegment title="Ativações" fullHeigth={true}>
                                            <p style={{ fontSize: '14px', color: '#8b90a3', marginBottom: '1rem' }}>Ativações do Office para aplicativos móveis em várias plataformas</p>

                                            {activationsChartMobile?.datasets.length > 0 ?
                                                <Bar height={175} data={activationsChartMobile} options={getChartsOptions(50)}></Bar>
                                                :
                                                <LcNoData />
                                            }
                                        </LcSegment>
                                    </LcLoading>
                                </LCFlexItem>

                            </LCFlexContainer>
                    }
                </>
            }
        </Layout>
    );
};

export default SaaSSubscriptions;