import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { Context, NotificationCenterService, NotificationSend, NotificationSendFilter } from '../../../services/notificationCenter/notificationService';

//Redux
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import CreatedCard from './createdCard';
import DetailsForm from './detailsForm';
import CreateForm from './createForm';

//Others
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import { PPModal } from 'processor-plataform-ui'
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const SendNotification: React.FC = (props) => {

    const notificationService = new NotificationCenterService(props);

    const [rows, setRows] = useState<NotificationSend[]>([]);
    const [visibleRows, setVisibleRows] = useState<NotificationSend[]>([]);
    const [filteredRows, setFilteredRows] = useState<NotificationSend[]>([]);

    const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
    const [createVisible, setCreateVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<NotificationSend>();
    const [filter, setFilter] = useState<NotificationSendFilter>();

    const [month, setMonth] = useState<string>(() => { return ((new Date()).getMonth() + 1).toString(); });
    const [year, setYear] = useState<string>(() => { return (new Date()).getFullYear().toString(); });
    const [filterVisible, setFilterVisible] = useState(false);

    const styleInformation: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        width: '20px',
        marginLeft: '1rem'
    }

    const column = [
        { field: 'title', headerName: 'Título', width: "35%", renderCell: (row: NotificationSend) => { return row.notification.title } },
        {
            field: 'content', headerName: 'Mensagem', width: "20%", renderCell: (row: NotificationSend) => {
                if (row.notification.content.length > 20)
                    return row.notification.content.substring(0, 20) + '...'
                else
                    return row.notification.content
            }
        },
        { field: 'createdBy', headerName: 'Criado por', width: "20%", renderCell: (row: NotificationSend) => { return row.createdBy } },
        {
            field: 'pinned', headerName: ' ', width: "25%", renderCell: (row: NotificationSend) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'right' }}>
                        <span style={styleInformation}>
                            <i className={`ppi ppi-bell size text-${row.context}`} />
                        </span>
                        <span style={styleInformation}>
                            {row.pinned &&
                                <i className={`ppi ppi-pin size`} />
                            }
                        </span>
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        if (!month && !year) {
            let m = new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
            let y = new Date().getFullYear().toString();
            setMonth(m);
            setYear(y);
            let filter = notificationService.GetCleanFilter(m, y);
            setFilter(filter);
        }

        if (month && year) {
            loadNotifications();
        }
    }, [year, month]);

    const loadNotifications = async () => {
        setIsLoading(true);
        try {
            var response = await notificationService.GetNotificationSendByDate(year, month);
            setRows(response.data)
            setFilteredRows(response.data)
            setVisibleRows(response.data.slice(0, 15))
        } catch (e) {
            console.warn(e);
        }
        setIsLoading(false);
    }

    const loadMore = (size?: number) => {
        if (size) setVisibleRows(filteredRows.slice(0, size))
        else setVisibleRows(filteredRows.slice(0, visibleRows.length + 15))
    };

    const functionsRow = [
        {
            label: 'Ver detalhes',
            func: (row: NotificationSend) => {
                setSelectedRow(row);
                setDetailsVisible(true);
            }
        }
    ];

    const functionGeneric = [
        {
            title: 'notification',
            icon: 'ppi ppi-plus',
            tooltip: "Criar notificação",
            onClick: () => {
                setCreateVisible(true);
            }
        }
    ];

    const callBackCancel = () => {
        setCreateVisible(false)
    }

    const onCloseDetailsForm = () => {
        setSelectedRow(undefined)
        setDetailsVisible(false)
    }

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
        setFilter({ ...filter, year: date.split('-')[0], month: date.split('-')[1] })
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    let filterAdvanced = {
        fields: [
            { label: 'Assunto', name: 'subject', type: 'text' },
            { label: 'Fixada', name: 'pinned', type: 'select', options: notificationService.GetPinnedOptions() },

        ],
        onChange: (f: any, size: number) => {
            let params1 = f[0].value.toLocaleLowerCase();
            let pinned = f[1].value === 0 ? null : (f[1].value === 1 ? true : (f[1].value === 2 ? false : null));

            let filteredRows = rows.filter((element) => {
                return (
                    element.createdBy.toLocaleLowerCase().includes(params1) ||
                    element.notification.title.toLocaleLowerCase().includes(params1) ||
                    element.notification.subTitle.toLocaleLowerCase().includes(params1) ||
                    element.notification.content.toLocaleLowerCase().includes(params1)
                )
            })

            if (pinned != null) {
                filteredRows = filteredRows.filter(obj => {
                    return (obj.pinned === pinned)
                })
            }

            setFilteredRows(filteredRows)
            setVisibleRows(filteredRows.slice(0, 15))
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    return (
        <Layout pageTitle="Envio de notificações"
            functionsGeneric={functionGeneric}
            periodSelection={periodSelection}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredRows.length
            }}
        >

            <DetailsForm notification={selectedRow} visible={detailsVisible} onClose={onCloseDetailsForm} />

            <CreateForm callBackCancel={callBackCancel} visible={createVisible} />

            <LcInfiniteTable
                loadMore={loadMore}
                size={rows.length}
                columns={column}
                rows={visibleRows}
                functionsRow={functionsRow}
                filter={filterAdvanced}
                loading={isLoading}
            />
        </Layout>
    );
}

export default SendNotification;