import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index';
import { BFFMenu, MenuState } from '../../store/reducers/menuReducer';
import LcTooltip from '../../components/Generic/LcTooltip';
import HomeDashboardCard from '../../components/Data/Dashboard/HomeDashboardCard';
import Marketing from '../../components/Marketing';
import { BsCloudCheck } from 'react-icons/bs';
import { PiArrowSquareOutBold, PiArrowSquareOutLight, PiListBold, PiListLight } from 'react-icons/pi';
import './index.css';
import { UserState } from '../../store/reducers/userReducer';

const HomeRainbow: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const history = useHistory();

    type MenuGroup = {
        title: string;
        children: any[];
    };

    const [selectedSolution, setSelectedSolution] = useState<string | null>(null);

    const handleSolutionClick = (solution: string) => {
        setSelectedSolution(solution);
    };

    const handleCloseClick = () => {
        setSelectedSolution(null);
    };

    const [menuGroups, setMenuGroups] = useState<MenuGroup[]>([]);
    const [doYouHaveInvoice, setDoYouHaveInvoice] = useState<boolean>(false);
    const [menuGroupsPermitidos, setMenuGroupsPermitidos] = useState<MenuGroup[]>([]);
    const excludedGroupIds = user.IsAdm ? ['3', ] : ['3', ]; //excluir group_id = '3' (Aplicações de negócios) não exibir card
    
    const groupColors = {
        '0': '#5F5DAD', //Cloud Managed Services
        '1': '#0055A8', //Proteção de dados
        '2': '#16C2F3', //Subscrições de Software
        '3': '#6E2D8E', //Aplicações de negócios
        '4': '#DC291E', //Segurança
        '5': '#59BAC5', //Gestão, Governança e Suporte
        '6': '#8B90A3' //Informações e histórico
    };

    const groupItems = (items) => {
        return items.reduce((groups, solution) => {
            let group = JSON.parse(solution.extraData).group;
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(solution);
            return groups;
        }, {});
    }

    const transformGroups = (groupedItems) => {
        return Object.keys(groupedItems)
            .sort((a, b) => {
                let indexA = groupedItems[a][0].menuOrder;
                let indexB = groupedItems[b][0].menuOrder;
                return indexA - indexB;
            })
            .map(group => ({
                title: group,
                children: groupedItems[group]
                    .sort((a, b) => a.menuOrder - b.menuOrder)
                    .map(e => {
                        let sufixo = JSON.parse(e.extraData).sufixo;
                        if (sufixo && sufixo.startsWith("- ")) {
                            sufixo = sufixo.slice(2);
                        }
                        return {
                            name: e.name,
                            route: e.children.length > 0 ? e.children.sort((a, b) => a.menuOrder - b.menuOrder)[0].route : e.route,
                            group: group,
                            group_id: JSON.parse(e.extraData).group_id,
                            permitido: e.permitido,
                            sufixo: sufixo,
                            solution: JSON.parse(e.extraData).solution
                        }
                    })
            }))
            .filter(group => !group.children.some(child => excludedGroupIds.includes(child.group_id)));
    }

    const containsFiscal = (items: BFFMenu[]): boolean => {
        for (const item of items) {
            if (item.name.includes('Notas fiscais')) {
                return true;
            }

            // Check in children and child arrays
            if ((item.children && containsFiscal(item.children)) || (item.child && containsFiscal(item.child))) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const startsWithHttps = (str) => str.startsWith('https://');
        const processMenu = () => {
            const _item = menu.items.find(i => i.id === '6F96AEFB-3676-4995-9512-A15A6C617E1E');
            if (!_item) return;

            const _solutions = _item.children.filter(c => JSON.parse(c.extraData).group !== 'None');
            const _solutionsPermitidas = _solutions.filter(c => c.permitido);

            // const isEdrPresent = _solutionsPermitidas.some(c => JSON.parse(c.extraData).solution === 'edr');

            // if (!isEdrPresent) {
            //     excludedGroupIds.push('4');
            // }
            var hasNF = containsFiscal(_solutionsPermitidas);
            setDoYouHaveInvoice(hasNF);
            if (_solutionsPermitidas.length === 0) {
                history.push('/solucoes');
            } else if (_solutionsPermitidas.length === 1) {
                const route = _solutionsPermitidas[0].children.length > 0
                    ? _solutionsPermitidas[0].children.sort((a, b) => a.menuOrder - b.menuOrder)[0].route
                    : _solutionsPermitidas[0].route;

                if (startsWithHttps(route)) {
                    history.push('/solucoes');
                } else {
                    history.push('/' + route);
                }
            } else {
                let groupedSolutions = groupItems(_solutions);
                let groupedSolutionsPermitidas = groupItems(_solutionsPermitidas);

                let _groups = transformGroups(groupedSolutions);
                let _groupsPermitidos = transformGroups(groupedSolutionsPermitidas);

                setMenuGroups(_groups);
                setMenuGroupsPermitidos(_groupsPermitidos);
            }
        };
        processMenu();
    }, [menu]);

    const onClickMenuGroupItem = (item: any) => {
        const route = item.route;
        if(item.title === "SecOps" && item.children.length > 1) {
            history.push('/SecOps');
        } 
        else if (route && route !== '#') {
            if (route.toString().includes("http")) {
                window.open(route, '_blank');
            } else {
                history.push("/" + route);
            }
        } else if (item.children && item.children[0] && item.children[0].route) {
            history.push('/' + item.children[0].route);
        }
    }

    const getTooltipPosition = (index, totalGroups) => {
        return totalGroups === 6 && index === 0 ? "left" : "right";
    };

    return (
        <Layout pageTitle="Início">
            <div className='homeflex'>
                {menuGroupsPermitidos.map((groupPermitido, i) => (
                    <div
                        className="card-home"
                        key={i}
                        style={{ backgroundImage: `linear-gradient(0deg, #FFFFFF 75%, ${groupColors[groupPermitido.children[0].group_id]} 220%)` }}
                    >
                        <div className="header">
                            <div className="group">
                                <div className="color" style={{ backgroundColor: groupColors[groupPermitido.children[0].group_id] }}> </div>
                                <div className="title" title={groupPermitido.title}>{groupPermitido.title}</div>
                            </div>
                            <div className="icons">
                                <div className="icon menu">
                                    <LcTooltip
                                        content={
                                            menuGroups.filter(group => group.title === groupPermitido.title).map((group, l) => (
                                                group.children.map((m, l) => (
                                                    <div key={l} className={`item ${m.permitido ? ' permitido' : ''}`}
                                                        onClick={() => {
                                                            return m.permitido ?
                                                                onClickMenuGroupItem(m)
                                                                :
                                                                handleSolutionClick(m.solution);
                                                        }}
                                                    >
                                                        <div className="solucao">
                                                            <div className="label">{m.name}</div>
                                                            <div className="sufixo">{m.sufixo}</div>
                                                        </div>
                                                        {m.permitido ? <BsCloudCheck size='14px' color="green" /> : null}
                                                    </div>
                                                ))
                                            ))
                                        }
                                        trigger="hover"                                        
                                        position={getTooltipPosition(i, menuGroupsPermitidos.length)}

                                    >
                                        <div className='centralized'>
                                            <PiListLight color="#2E1956" />
                                        </div>

                                    </LcTooltip>
                                </div>
                                <div className="icons">
                                    <div className="icon menu" onClick={() => { onClickMenuGroupItem(groupPermitido); }} >
                                        <LcTooltip
                                            content={
                                                <div className="item">
                                                    Ir para a solução
                                                </div>
                                            }
                                            trigger="hover"
                                            position="right"
                                        >
                                            <div className='centralized'>
                                                <PiArrowSquareOutLight color="#2E1956" />
                                            </div>
                                        </LcTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <HomeDashboardCard GroupId={groupPermitido.children[0].group_id} QtdGroups={menuGroups.length} GTMs={groupPermitido.children.map(child => child.solution)} hasNF={doYouHaveInvoice} />
                        </div>
                    </div>
                ))
                }
            </div>

            <Marketing selectedSolution={selectedSolution} onSolutionClose={handleCloseClick} />

            <style dangerouslySetInnerHTML={{
                __html: `                        
                    .container > .toolbar {display:none;}                         
                `}} />
        </Layout >

    )
}
export default HomeRainbow;