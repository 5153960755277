import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import { useSelector } from 'react-redux';
import { SaasService } from '../../../services/saas/saasService';
import { RootState } from '../../../store';
import ReactECharts from 'echarts-for-react';
import { UserState } from '../../../store/reducers/userReducer';

interface EmailBoxesChartMdel {
    userPeriod: number
}


export const EmailBoxesChart: React.FC<EmailBoxesChartMdel> = ({userPeriod}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState(false);
    const [OptionsChart, setOptionsChart] = useState<any>({});

    const getOptionsChart = useMemo(() => (emailUsageChart) => {
        return  {
            grid: {
                height: 'fit-content',
                top: '5%',
                left: '2%',
                right: '2%',
                bottom: '0%',
                containLabel: true
            },
            color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 11,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                trigger: 'axis',
                formatter: function (params) {
                    // Adding color box using item.marker and then appending series name and value
                    return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: emailUsageChart.labels,
                axisLabel: {
                    fontSize: '10px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}',
                    fontSize: '10px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
                //interval: 150                                
            },
            series: emailUsageChart.datasets,
        }
    },[])
    
    const EmailUsageChartData = useMemo(() => (response: any): Chart => {
        if (response.data != null) {
            let emailUsageChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Total",
                        data: response.data.map((Item: any) => {
                            return Item.total
                        }),
                        type: 'line',
                        smooth: true
                    },
                    {
                        name: "Ativas",
                        data: response.data.map((Item: any) => {
                            return Item.active
                        }),
                        type: 'line',
                        smooth: true
                    }
                ]
            };
            return emailUsageChart;
        } else {
            let emailUsageChart = {
                labels: [],
                datasets: []
            };
            return emailUsageChart;
        }
    },[]);

    const getEmailUsageData = useCallback( async (days: number) => {
        setLoading(true);
        try {
            const saasService = new SaasService({});
            const response = await saasService.GetEmailUsageChart(days)
            let emailUsage = (EmailUsageChartData(response));
            setOptionsChart(getOptionsChart(emailUsage))
        } catch (error) {
        console.error(error);
            
        } finally{
            setLoading(false)
        }
    },[EmailUsageChartData, getOptionsChart])

    useEffect(() => {
        getEmailUsageData(userPeriod)
    },[getEmailUsageData, userPeriod, user.ClientGroupSelected, user.refreshFlag])


  

    return (
        <LcLoading loading={loading}>
        {Object.keys(OptionsChart).length > 0 ?
            <ReactECharts
                className="chart-medium"
                style={{ width: '100%', height: '100%' }}
                opts={{ renderer: 'svg', locale: 'PT-br' }}
                option={OptionsChart}
            />
            :
            <LcNoData size="small" label="Sem dados no período." />
        }
    </LcLoading>
    );
}
