import React, { ReactNode, useEffect, useState } from 'react';

import './index.css';

interface Props {
    checked: boolean,
    disabled?: boolean,
    onChange?: Function,
    title?: string,
    className?: string,
    name?: string,
}

const PPCheckBox: React.FC<Props> = (props) => {

    const [checked, setChecked] = useState(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked,])

    const handleCheck = () => {
        !props.disabled &&
            setChecked(!checked);
    }
  
    useEffect(() => {
        console.log('name' + props.name)
        props.onChange &&
            props.onChange({ target: { name: props.name, value: checked } });
    }, [checked])

    return (
        <div className={`pp-checkbox ${props.className || ''}`} onClick={handleCheck}>
            <i className={`ppi ppi-checkbox${checked ? '-on' : ''} ${props.disabled ? 'disabled' : ''}`} />
            {
                props.title && <span className="title">&nbsp;{props.title}</span>
            }
            <input type="hidden" disabled={props.disabled} value={checked ? 'on' : ''} name={props.name}></input>
        </div>
    );
}

export default PPCheckBox;