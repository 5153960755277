import api, { APIClient } from "../api";
import Moment from 'moment';

export class BackupSaasService extends APIClient {

    constructor(props:any){
        super(props);
    }
        
    async GetHistory(start: string, end: string) {
      const url = this.endpoint + `/api/BackupSaas/History?start=${start}&end=${end}`;
      return api({
          method: 'get',
          url,
      }).then((response: any) => {
          let id = 1;
          let jobs = response.data.map((Item: {status: string, connector: string, startDate: string, endDate: string, type: string}) => {
            return {
                id: id++,
                Status: this.TranslateStatus(Item.status),
                Connector: Item.connector,
                Type: Item.type,
                StartDate: Moment(Item.startDate).format('DD/MM/YYYY hh:mm:ss'),
                EndDate: Moment(Item.endDate).format('DD/MM/YYYY hh:mm:ss')
              }    
          });
          return jobs;
      });
    }
    
    async GetSchedules() {
        const url = this.endpoint + `/api/BackupSaas/ScheduleDataTableAjaxHandler`;
        return api({
            method: 'get',
            url,
        });
    }
    
    async GetCards(selectedDate: string) {
        const url = this.endpoint + `/api/BackupSaas/GetSummaryData?selectedDate=${selectedDate}`;
        return api({
            method: 'get',
            url,
        });
    }
    
    async GetMonthlyChart() {
        const url = this.endpoint + `/api/BackupSaas/GetByHashId`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetSnapshot(selectedDate: string) {
        const url = this.endpoint + `/api/BackupSaas/GetVaultTreeData?&selectedDate=${selectedDate}`;
        return api({
            method: 'get',
            url,
        });
    }

    private TranslateStatus(status: string) {
      if(status==="done"){
          return "Completo";
      } else if (status == "failed"){
          return "Falha";
      } else if (status == "cancelled"){
          return "Completo";
      } else {
          return status;
      }
  }

}