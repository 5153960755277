import React from 'react';

import Layout from '../../components/Layout/Layout';

import { Button } from '@material-ui/core';

const Servicos: React.FC = () => {

    return (
        <Layout>
        </Layout>
    );
};

export default Servicos;