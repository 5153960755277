export class retencao {
    solution() {
        return 'retencao'
    }

    site() {
        return 'https://www.gotobiz.com.br/livecloud-retencao'
    }

    logotipo() {
        return require('./assets/logotipos/logo_retencao.svg').default
    }
    titulo() {
        return <>Transforme o <b>futuro do negócio</b> com LiveCloud Retenção</>
    }

    chamada() {
        return <>Soluções para operação, suporte e monitoramento de retenção de dados de longo prazo das organizações com segurança, escalabilidade e baixo custo. LiveCloud Retenção é a ferramenta para levar o negócio para o próximo nível.</>
    }

    image() {
        return require('./assets/imgs/imagem_retencao.png').default
    }

    detalhes() {
        return <>
            <h2>Já imaginou ter o controle total da infraestrutura de nuvem da organização, com segurança, eficiência e sem complicações?</h2>
            <p><b>Com o LiveCloud Retenção, isso é mais do que possível, é uma realidade ao seu alcance.</b></p>
            <h3>Por que escolher LiveCloud Retenção?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_retencao.png').default} />
                </div>
                <ul>
                    <li><b>Gestão à vista:</b> Uma visão completa e integrada dos serviços contratados.</li>
                    <li><b>Painéis de administração:</b> Acompanhamento do desempenho e eficiência dos serviços em tempo real.</li>
                    <li><b>Disponibilidade e performance:</b> Maior disponibilidade dos serviços e otimização do desempenho da infraestrutura de nuvem.</li>
                    <li><b>Solicitação de serviços e abertura de chamados:</b> Solicitação de novos serviços e abertura de chamados de forma rápida e eficiente.</li>
                </ul>
            </div>
            <div className="box">LiveCloud Retenção é mais do que uma solução de nuvem, é a chave para a <b>transformação digital do negócio</b>. Não perca mais tempo, dê o próximo passo na sua jornada de nuvem agora mesmo.</div>
            <h3>Acelere a transformação digital do negócio</h3>
            <p>Com LiveCloud Retenção, a organização tem acesso a uma gama completa de serviços e soluções de tecnologia gerenciados e operados por nossa equipe de especialistas. Acelere o processo de transformação digital e obtenha resultados incríveis.</p>
            <p><b>O futuro é agora e a nuvem é o caminho a seguir.</b> Com LiveCloud Retenção, a organização tem tudo o que precisa para se destacar no mercado e superar a concorrência.</p>                        
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo e descubra como LiveCloud Retenção pode transformar o negócio.</p>         
        </>
    }
}
export default retencao;