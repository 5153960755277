import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import Layout from '../../components/Layout/Layout';

import IconValueCardRow from '../../components/Layout/Dashboard/IconValueCardRow';

import HomeService, { TileControlViewModel } from '../../services/homeService';

import LCFlexContainer from '../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../components/Layout/LCFlexItem';

import Moment from 'moment';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { MenuState } from '../../store/reducers/menuReducer';

const DashboardHosting: React.FC = (props) => {

    const service = new HomeService(props);
    const history = useHistory();

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [tiles, setTiles] = useState<TileControlViewModel[]>([]);
    const [allowedMenuIds, setAllowedMenuIds] = useState<string[]>([])

    const getMenuIds = (menuItems: any) => {
        let ids: any[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].children && menuItems[i].children.length > 0) {
                ids = ids.concat(getMenuIds(menuItems[i].children));
            }
            ids.push(menuItems[i].id);
        }
        return ids;
    }

    useEffect(() => {
        setAllowedMenuIds(getMenuIds(menu.items));
    }, [menu])

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    }
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    useEffect(() => {
        if (!month && !year) {
            setMonth(Moment().format('MM'));
            setYear(Moment().format('YYYY'));
        }

        if (month && year && !isLoading) {
            setIsLoading(true);
            loadHomData(month as string, year as string)
                .then((item) => { });
        }

    }, [year, month, user.ClientGroupSelected, user.refreshFlag]);


    async function loadHomData(month: string, year: string) {
        setIsLoading(true);
        try {
            let responseGetTileControlViewModel = await service.GetTileInfo(month, year);
            setTiles(responseGetTileControlViewModel);
        } catch (e) {

        }
        setIsLoading(false);
    }

    //Para usar nos componentes - Cores padronizadas LC11
    const colors = [
        "#5969CD", "#E273C5", "#804E3B", "#A5Bf3E", "#8684EF", "#F3C944", "#59BAC5",
        "#D5672F", "#307422", "#169D36", "#E74A67", "#C23D4F", "#D7BA45", "#B24F5D"
    ];

    const handleTileUrl = (url: string) => {
        user.Menus?.forEach((itemA: any) => {
            itemA.children.forEach((itemB: any) => {
                itemB.children.forEach((itemC: any) => {
                    if (itemC.id == url) {
                        history.push(itemC.route);
                    }
                })
            })
        })
    }

    return <Layout pageTitle='Dashboard' periodSelection={periodSelection}>
        <LCFlexContainer spacing={1}>
            {
                tiles && tiles.length > 0 &&
                tiles
                    .filter(t => t.productType == 6)
                    .map((tile: TileControlViewModel, index: number) => {
                        if (tile && tile.title && !tile.enabled) {
                            sessionStorage.removeItem(tile.title)
                            let formatValue = true;
                            let valueTile = tile?.hired?.toString().replace('/', '') || tile?.consumption || '';
                            let extraDataUnitDescription = tile?.extraDataUnitDescription;
                            let unity = "";

                            let formated = tile?.consumption;
                            valueTile = formated;
                            formatValue = false;

                            tile && tile.title && sessionStorage.setItem(tile.title.toString(), valueTile)

                            if (tile.href && (allowedMenuIds.includes(tile.href.toLowerCase()) || user.IsAdm)) {
                                return (
                                    <LCFlexItem key={index} xs={12} sm={3} md={3} lg={3}>
                                        <IconValueCardRow onClick={() => { handleTileUrl(tile.href) }} maximumFractionDigits={unity == 'TB' ? 1 : 0} unity={unity} formatValue={formatValue} title={tile?.title || ''} icon={tile?.faIcon || ''} value={valueTile} description={extraDataUnitDescription || ''} color={colors[index]} />
                                    </LCFlexItem>
                                );
                            }
                        }
                    })
            }
        </LCFlexContainer>
    </Layout>;
}

export default DashboardHosting;