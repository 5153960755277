import api, { APIClient } from "../api";


export interface MenuItemInterface {
    name : string,
    icon: string,
    route: string,
    extraData: string,
    url: string,
    menuOrder: number,
    isPublic: boolean,
    isShopCart: boolean
}

export default class accessProfileService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    GetEmptyRecord() {
        var emptyRecord : MenuItemInterface = {
            name:'',
            icon: '',
            route: '',
            extraData: '',
            url: '',
            menuOrder: 0,
            isPublic: false,
            isShopCart: false
        }
        return emptyRecord;
    }

    IsNull(menu: MenuItemInterface) {
        return (JSON.stringify(this.GetEmptyRecord()) == JSON.stringify(menu))
    }

    async GetListClientGroupsPerfis() {

        const url = `${this.endpoint}/api/ClientGroupPerfil`;

        return api({
            method: 'get',
            url,
        })
    }

    async GetMemberGroupPerfis(perfilId: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetUserByPerfilId/${perfilId}`;
        return api({
            method: 'get',
            url,
        })
    }

    async GetAllUserByClientGroupId(profileId: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetAllUserByClientGroup/${profileId}`;
        return api({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
    }

    async UpdateClientGroupPerfil(obj: any) {
        const url = `${this.endpoint}/api/ClientGroupPerfil`;
        const data = JSON.stringify(obj);
        return api({
            method: 'put',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async GetProfilePermissionByClientGroupPerfil(id: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetPermissionByClientGroupPerfilId/${id}`;
        return api({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async GetPermissionByUserIdAndClientGroup() {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetPermissionsByUserIdAndClientGroupId`;
        return api({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async GetClientGroupPerfilMenu(id: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/GetMenuByClientGroupPerfilId/${id}?refresh=${Math.random()}`

        return api({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    //UpdateMenuItem
    async UpdateMenuItem(menuItem: any) {
        const url = `${this.endpoint}/api/Menu/UpdateMenuItem`;

        const data = JSON.stringify(menuItem);

        return api({
            method: 'put',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    //AddMenuItem
    async AddMenuItem(menuItem: any) {
        const url = `${this.endpoint}/api/Menu/AddMenuItem`;

        const data = JSON.stringify(menuItem);

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async SendMenuOpt(ClientGroupPerfilMenu: any, perfilId: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/SetMenuClientGroup?perfilId=${perfilId}`;

        const data = JSON.stringify(ClientGroupPerfilMenu);

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async SendPermissionOpt(permissao: any) {
        const clientGroupPerfilID = permissao[0].perfilId
        const url = `${this.endpoint}/api/ClientGroupPerfil/SetPermissionClientGroup/ClientGroupPerfilId/${clientGroupPerfilID}`

        const data = JSON.stringify(permissao)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async AddUserClientGroupPerfil(collectionUsers: any[]) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/AddMemberToClientGroupPerfil`

        const data = collectionUsers

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async DeleteClientGroupPerfil(id: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil?id=${id}`

        return api({
            method: 'delete',
            url,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async DeleteMember(userId: string, profileId: number) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/DeleteMember?userId=${userId}&profileId=${profileId}`
        return api({
            method: 'delete',
            url
        })
    }

    async DeleteMultipleMembers(profileId: number, userId: string[]) {
        const url = `${this.endpoint}/api/ClientGroupPerfil/BulkDelete/${profileId}`
        const data = JSON.stringify(userId)
        
        return api({
            method: 'patch',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async CreateProfile(profile: any) {
        const url = `${this.endpoint}/api/ClientGroupPerfil`
        const data = JSON.stringify(profile)
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async DeleteMenuItem(MenuItemId) {
        const url = `${this.endpoint}/api/Menu/DeleteMenuItem/${MenuItemId}`
        return api({
            method: 'delete',
            url
        })
    }
}

