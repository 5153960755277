import api, { APIClient } from "../api";

export class EndpointSecurityService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetCards(anoMes: string) {
        const url = this.endpoint + `/api/EndPointSecurity/GetCards?period=${anoMes}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        });
    }

    async GetEvents(anoMesDia: string) {
        const url = this.endpoint + `/api/EndPointSecurity/GetEvents?period=${anoMesDia}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        });
    }

    async GetStatusChart() {
        const url = this.endpoint + `/api/EndPointSecurity/GetDeviceStatusChart?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }

    async GetDevices() {
        const url = this.endpoint + `/api/EndPointSecurity/GetDevices?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        }).then((response) => {

            const devices = response.data.map((item: any, index: number) => {
                return {
                    id: index++,
                    ...item
                };
            });
            return devices;
        });
    }



}