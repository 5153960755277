import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { Grid, LinearProgress, Badge } from '@material-ui/core';
import "./index.css";
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';


interface SchedulesFilter {
    size: number;
    term: string;
    column: string;
    direction: string;
    SystemName: string;
    BackupName: string;
    DiasSemanaBackup: [];
    HoraAgendamento: string;
}

const BackupGoldAgendamentos: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [schedules, setSchedules] = useState<any[]>([]);
    const [filteredSchedules, setFilteredSchedules] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 25, term: "", column: "", direction: "asc" });
    const [visibleSchedules, setVisibleSchedules] = useState<any[]>([]);

    const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(true);


    const filterDefault: SchedulesFilter = {
        size: 20,
        term: "",
        column: "",
        direction: "asc",
        SystemName: "",
        BackupName: "",
        DiasSemanaBackup: [],
        HoraAgendamento: ""
    };
    const [filterSchedules, setFilterSchedules] = useState<SchedulesFilter>(filterDefault);

    useEffect(() => {
        setIsLoadingSchedules(true)
        setFilteredSchedules([]);
        const backupGoldService = new BackupGoldService(props);
        if (user.ClientGroupSelected) {
            backupGoldService.GetSchedules()
                .then(response => {
                    let schedulesData = response.data.map((Item: { diasSemanaBackup: any, systemName: string, backupName: string, horaAgendamento: string }, index: number) => {
                        return {
                            id: index,
                            DiasSemanaBackup: Item.diasSemanaBackup,
                            SystemName: Item.systemName,
                            BackupName: Item.backupName,
                            HoraAgendamento: Item.horaAgendamento
                        }
                    });
                    setSchedules(schedulesData);
                    setFilteredSchedules(schedulesData)
                    setIsLoadingSchedules(false)
                })
                .catch(error => {
                    setIsLoadingSchedules(false)
                    console.log(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    const columns = [
        { field: 'SystemName', headerName: 'Tarefa', width: "50%" },
        { field: 'BackupName', headerName: 'Tipo', width: "38%" },
        {
            field: 'DiasSemanaBackup', headerName: 'Dias da semana', width: "25%", sort: false, overflow: "visible", align: 'left',
            renderCell: (row: any) => {
                return Object.keys((row.DiasSemanaBackup as any).values).map((key) => {
                    return <div><Badge className={"customBadgeWeek"} badgeContent={key} color={((row.DiasSemanaBackup as any).values)[key] == "1" ? "primary" : "default"}></Badge><br></br></div>
                })
            },
        },
        { field: 'HoraAgendamento', headerName: 'Hora agendada', width: "10%" }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleSchedules(filteredSchedules.slice(0, size))
        else setVisibleSchedules([...visibleSchedules, ...filteredSchedules.slice(visibleSchedules.length, visibleSchedules.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const _filterAdvanced = {
        fields: [
            { label: 'Tarefa', name: 'SystemName', type: 'text' },
            { label: 'Tipo', name: 'BackupName', type: 'text' },
            {
                label: 'Dias da semana',
                name: 'DiasSemanaBackup',
                type: 'multiSelect',
                options: [
                    { label: 'dom.', value: 'dom.' },
                    { label: 'seg.', value: 'seg.' },
                    { label: 'ter.', value: 'ter.' },
                    { label: 'qua.', value: 'qua.' },
                    { label: 'qui.', value: 'qui.' },
                    { label: 'sex.', value: 'sex.' },
                    { label: 'sáb.', value: 'sáb.' },
                ],
                value: [] // Valor inicial como um array vazio
            },
        ],
        onChange: (filter: any, size: number) => setFilter({ ...filter, size: size, term: filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const card = [
        {
            id: 1,
            type: 'Custom',
            cssClass: 'page-agendamentos',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingSchedules} loadingType='Helix'>
                        <LcInfiniteTable
                            loading={isLoadingSchedules}
                            columns={columns}
                            rows={visibleSchedules}
                            filter={_filterAdvanced}
                            size={visibleSchedules.length}
                            loadMore={loadMore}
                            onSortChange={onSortChange}
                            density={pref.data?.density || "high"}
                            disableFilterModal
                        />
                    </LcLoading>
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={_filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;
        if (schedules.length > 0) {
            let filtered = schedules.filter((p: any) => {
                const matchesSystemName = filterSchedules.SystemName
                    ? p.SystemName.toLowerCase().includes(filterSchedules.SystemName)
                    : true;
                const matchesBackupName = filterSchedules.BackupName
                    ? p.BackupName.toLowerCase().includes(filterSchedules.BackupName)
                    : true;
                const matchesDiasSemanaBackup = filterSchedules.DiasSemanaBackup.length > 0
                    ? filterSchedules.DiasSemanaBackup.some(day => p.DiasSemanaBackup.values[day] === "1")
                    : true;

                return matchesSystemName && matchesBackupName && matchesDiasSemanaBackup;
            });

            var sorted = filtered.sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

            isFilterActive = filtered.length !== schedules.length;
            setFilteredSchedules(sorted);
            setVisibleSchedules(sorted);
        }

        dispatch(setFilterActive(isFilterActive));
    }, [filterSchedules, schedules, visibleSchedules, dispatch]);

    // UseEffect para chamar applyFilter
    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (updatedFilters) => {
        const systemNameFilter = updatedFilters.find(f => f.name === 'SystemName')?.value || '';
        const backupNameFilter = updatedFilters.find(f => f.name === 'BackupName')?.value || '';
        const diasSemanaBackupFilter = updatedFilters.find(f => f.name === 'DiasSemanaBackup')?.value || [];

        setFilterSchedules({
            ...filterSchedules,
            SystemName: systemNameFilter.toLowerCase(),
            BackupName: backupNameFilter.toLowerCase(),
            DiasSemanaBackup: diasSemanaBackupFilter
        });
    };

    return (
        <Layout pageTitle="Agendamentos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                size: visibleSchedules.length,
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                }
            }}
        >

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,

                    show: filterVisible,
                    content: filterSystem,
                }}
            />

        </Layout>
    );
};

export default BackupGoldAgendamentos;