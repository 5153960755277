import { AutomationResourceDetails } from "../../../../services/automations/automationsModel";
import AutomationsService from "../../../../services/automations/automationsService";

export interface ResourcePriceUnit {
    atual: boolean;
    instanceType: string;
    currencyCode: string;
    unitPrice: number;
    unit: string;
    region: string;
}


export const findMinPrice = (resources: ResourcePriceUnit[]) => {
    return Math.min(...resources.map(resource => resource.unitPrice));
};

export const filterResourceInfo = (resourceId: string, resourceDetails: AutomationResourceDetails, automationsService: AutomationsService) => {
    return resourceDetails.resources.find(res => res.resourceID === resourceId) ?? automationsService.emptyAutomationResource;
};

export const filterResourceInstanceTypeInfo = (resourceId: string, resourceDetails: AutomationResourceDetails, automationsService: AutomationsService) => {
    const targetResource = filterResourceInfo(resourceId, resourceDetails, automationsService);
    if (targetResource.resourceID !== '') {
        return resourceDetails.instanceTypes.find(ins => ins.name === targetResource.instanceSize) ?? automationsService.emptyAutomationInstanceType;
    }
    return automationsService.emptyAutomationInstanceType;
};
