export class maxoutsource {
    solution() {
        return 'maxoutsource'
    }

    site() {
        return 'https://www.gotobiz.com.br/bpo'
    }

    logotipo() {
        return require('./assets/logotipos/logo_maxoutsource.svg').default
    }
    titulo() {
        return <>Maximize a eficiência operacional da organização</>
    }

    chamada() {
        return <>Conte com uma solução que gerencia as atividades, os níveis de serviços e fluxos de trabalho, possibilitando o controle preciso e sistemático dos processos em formato digital, aumentando a eficiência e possibilitando a alta performance.</>
    }

    image() {
        return require('./assets/imgs/imagem_maxoutsource.png').default
    }

    detalhes() {
        return <>
            <h2>Imagine um mundo onde a tecnologia trabalha para a organização, não contra ela. Onde cada aplicação, cada servidor, cada dispositivo está sob controle.</h2>
            <p><b>Esse é o mundo que o Max Outsource pode criar.</b></p>
            <h3>Por que escolher Max Outsource?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_maxoutsource.png').default} />
                </div>
                <ul>
                    <li><b>Suporte:</b> Max Outsource oferece diagnósticos precisos e ágeis para a solução de problemas complexos, além de uma ampla base de conhecimento e acesso direto aos principais fabricantes de TI do mercado.</li>
                    <li><b>Flexibilidade:</b> Com a flexibilidade de contratação por volumetria e nível de serviço desejado e não por headcount, Max Outsource permite maior foco dos colaboradores internos nas atividades fim da organização.</li>
                    <li><b>Decisão:</b> Com reports periódicos e em tempo real para tomada de decisão, Max Outsource permite que a organização esteja sempre um passo à frente. É a informação certa, na hora certa, para a decisão certa.</li>
                    <li><b>Expansão do Negócio:</b> Nosso serviço facilita a expansão do negócio, oferecendo escalabilidade e flexibilidade.</li>
                    <li><b>Contingenciamento:</b> Max Outsource oferece serviços complementares que podem ser ativados, como consultoria, suporte e monitoramento. Isso significa que, mesmo nos momentos mais críticos, a organização pode contar com Max Outsource.</li>
                </ul>
            </div>
            <div className="box">Max Outsource permite aumentar a disponibilidade, produtividade e plena capacidade do ambiente tecnológico, através de uma completa estrutura de apoio que inclui <b>pessoas, processos e tecnologias.</b></div>            
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato para obter mais informações sobre como Max Outsource pode transformar a forma como a organização gerencia a tecnologia.</p>           
        </>
    }
}
export default maxoutsource;