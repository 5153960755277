import moment from "moment";

import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { GraphItem } from "./finOpsService";
import { formatDecimal, formatNumber, getSymbolByCurrency } from "../currency";
export class FinOpsChartService {

    constructor(props: any) {

    }

    getChartColor = (data: any[], index: number) => {
        return data[index];
    };

    getBaseValue = (kpi: any) => {
        let baseValue = 0;

        if (kpi.totalConsumption > 1000000)
            baseValue = Math.max(...kpi.costCenterConsumption.map(c => c.consumption))
        else
            baseValue = kpi.totalConsumption;

        return baseValue;
    }

    cutValue = (value: number, kpi: any) => {

        let baseValue = this.getBaseValue(kpi);

        var divideBy = 1;
        if (baseValue <= 10000) {
            divideBy = (1)
        } else if (baseValue <= 1000000) {
            divideBy = (1000)
        } else if (baseValue <= 100000000) {
            divideBy = (1000000)
        }

        //M�todo para reduzir valores para n�o quebar o layout
        return (value / divideBy).toFixed(2);
    }

    getConsumptionChart = (data: any[], colored: any[], kpi: any) => {
        return (
            {
                grid: {
                    top: '1%',
                    left: '20%',
                    bottom: '1%',
                    height: '33%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                    show: false,
                    axisTick: { show: false },
                    boundaryGap: [0, 1],
                    min: 0,
                    max: Math.max.apply(data.slice(0, 3).map(item => { return item.consumption })),
                    scale: true
                },
                yAxis: {
                    type: 'category',
                    boundaryGap: true,
                    inverse: true,
                    axisTick: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        width: 120,
                        show: true,
                        overflow: 'truncate',
                        ellipsis: '...',
                        margin: 40,
                        fontSize: 12,
                        position: 'left',
                        fontFamily: 'Ubuntu',
                        color: '#0580CE',
                        padding: [0, 10, 0, 0]
                    },
                    data: data.slice(0, 3).map((item: any) => {
                        return (item.label ? item.label.replace('true', '') : 'Network');
                    })
                },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 12,
                        fontFamily: 'Ubuntu',
                    },
                    valueFormatter: (value) => formatDecimal(value)
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: 10,
                        label: {
                            show: true,
                            position: 'left',
                            fontSize: 12,
                            fontFamily: 'Ubuntu',
                            color: '#2B304F',
                            margin: [0, 5, 0, 0],
                            formatter: (params: any) => formatDecimal(params.value)
                        },
                        data: data.slice(0, 3).map((item: any, index: number) => {
                            let newValue = this.cutValue(item.consumption, kpi);
                            return (
                                {
                                    value: newValue,
                                    itemStyle: { color: this.getChartColor(colored, index) }
                                });
                        })
                    },
                ]
            }
        );
    };


    getBaselineChart = (data: any, currentMonth: boolean, kpi: any,) => {

        var valueMonth = (currentMonth ? kpi?.totalConsumption : kpi?.lastMonthTotalConsumption);
        valueMonth = valueMonth ? valueMonth : 0;
        var baseline = kpi?.baseline;
        baseline = baseline ? baseline : 0;

        return (
            {
                tooltip: {
                    backgroundColor: '#E2E6F3',
                    borderWidth: 0,
                    position: 'top',
                    formatter: `<b>Baseline de ${moment(currentMonth ? kpi?.dailyConsumption[0]?.label : kpi?.lastMonthDailyConsumption[0]?.label, 'DD/MM/YYYY').format('MMMM')}</b><br/>Consumo: ${kpi ? getSymbolByCurrency("BRL") : 'R$'}  <b>${formatDecimal(valueMonth)}</b> de um total de ${kpi ? getSymbolByCurrency("BRL") : 'R$'}  <b>${baseline.toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 })}</b>`
                },
                series: [
                    {
                        type: 'gauge',
                        z: 2,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: currentMonth ? '#59BAC5' : '#F3C944'
                        },
                        progress: {
                            show: true,
                            width: 25,
                        },
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: (kpi ? kpi.baseline * 1.2 : 0),
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 25,
                                color: [[1, '#777777']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false
                        },
                        title: {
                            offsetCenter: [0, '-20%'],
                            fontSize: 30
                        },
                        anchor: {
                            show: false
                        },
                        detail: {
                            fontSize: 24,
                            fontFamily: 'Ubuntu',
                            offsetCenter: [0, '-5%'],
                            valueAnimation: true,
                            formatter: function (value) {

                                var baseline = kpi?.baseline ? kpi?.baseline : 0;
                                var cost = (value * 100) / baseline;
                                cost = cost ? cost : 0;
                                return (cost).toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%';
                            },
                            color: '#2B304F'
                        },
                        data: [{ value: data }]
                    },
                    {
                        type: 'gauge',
                        z: 3,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: '#F3C944'
                        },
                        progress: {
                            show: true,
                            width: 25,
                        },
                        startAngle: 30,
                        endAngle: 30,
                        min: 0,
                        max: kpi?.baseline,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                color: [[1, '#777777']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            distance: -2,
                            length: 29,
                            lineStyle: {
                                width: 4,
                                color: "black"
                            }
                        },
                    },
                    {
                        type: 'gauge',
                        z: 3,
                        stack: 'graph',
                        pointer: {
                            show: false
                        },
                        itemStyle: {
                            color: '#F3C944'
                        },
                        progress: {
                            show: true,
                            width: 25,
                        },
                        startAngle: (180 - ((data * 180) / (baseline * 1.2))),
                        endAngle: (180 - ((data * 180) / (baseline * 1.2))),
                        min: 0,
                        max: kpi?.baseline,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                color: [[1, '#777777']]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            distance: -2,
                            length: 29,
                            lineStyle: {
                                width: 4,
                                color: "white"
                            }
                        },
                    },

                ]
            }
        );
    };

    getGaugeChart = (data: any, title: string, style?: any) => {

        let height = Number(style.maxHeight.split('p')[0]);

        let color;
        if (data < 30) {
            color = 'var(--status-red)';
        } else if (data < 70) {
            color = 'var(--status-yellow)';
        } else {
            color = 'var(--status-green)';
        }
     
        return (
            {
                series: [
                    {
                        type: 'gauge',
                        stack: 'graph',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '65%'],
                        radius: '90%',
                        min: 0,
                        max: 100,
                        splitNumber: 1,
                        axisLine: {
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.3, 'var(--status-red)'],
                                    [0.7, 'var(--status-yellow)'],
                                    [1, 'var(--status-green)']
                                ]
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        pointer: {
                            length:   '20%' ,
                            width: 4  ,
                            offsetCenter: [0, '-55%'],

                        },
                        axisLabel: {
                            formatter: function (value) {
                                if (value === 0.875) {
                                    return 'Grade A';
                                } else if (value === 0.625) {
                                    return 'Grade B';
                                } else if (value === 0.375) {
                                    return 'Grade C';
                                } else if (value === 0.125) {
                                    return 'Grade D';
                                }
                                return '';
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        title: {
                            offsetCenter: [0, '40%'],
                            fontSize:  12,
                            fontFamily: 'Ubuntu',
                            fontWeight: '400',
                            valueAnimation: true,
                            formatter: function (value) {
                                return `{${value.toFixed(0)}%}`;
                            },
                            color: '#2E1956'
                        },

                        detail: {
                            fontSize: height * 0.2,
                            fontFamily: 'Ubuntu',
                            fontWeight: '400',
                            offsetCenter: [0, '-0%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return `${value.toFixed(0)}{unit|%}`;
                            },

                            rich: {
                                unit: {
                                    fontSize: height * 0.1
                                },
                            },

                            color: color // Use color based on data
                        },
                        data: [{
                            value: data,
                            name: title
                        }]
                    },



                ]
            }
        );
    };

    getAreaConsumptionChart = (currentMonthData: GraphItem[], lastMonthData: GraphItem[], fullMonth: boolean, monthsLabel: any, kpi: any) => {
        try {
            let lastMonthConsumption = 0;
            let currentMonthConsumption = 0;
            currentMonthData = currentMonthData ? currentMonthData : [];
            let corAnterior = "#F3C944";
            let corAtual = "#59BAC5";

            var resultado: EChartsOption = ({
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    order: 'seriesDesc',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    valueFormatter: (value) => { return formatDecimal(value) },
                },
                legend: {
                    inactiveColor: '#ededed',                    
                    inactiveBorderColor: '#ededed',
                    data: [{ name: monthsLabel.previous, itemStyle: { color: corAnterior }, lineStyle: { width: 0 } }, { name: monthsLabel.actual, itemStyle: { color: corAtual }, lineStyle: { width: 0 } }]
                },
                xAxis: {
                    axisLabel: {
                        interval: fullMonth ? 7 : 5,
                        showMaxLabel: true
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    axisTick: { show: false },
                    type: 'category',
                    axisLine: {
                        symbol: "none",
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    nameLocation: 'middle',
                    boundaryGap: false,

                    data: fullMonth ?

                        currentMonthData.length >= lastMonthData.length ?
                            currentMonthData.map((item: any) => {
                                return (moment(item.label, 'DD/MM/YYYY').format('DD/MMM'));
                            })
                            :
                            lastMonthData.map((item: any) => {
                                return (moment(item.label, 'DD/MM/YYYY').format('DD/MMM'));
                            })
                        :
                        currentMonthData.slice(0, (new Date().getDate() - 1)).map((item: any) => {
                            return (moment(item.label, 'DD/MM/YYYY').format('DD/MMM'));
                        }),

                },
                yAxis: {
                    type: 'value',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    splitNumber: 3
                },
                series: [
                    {

                        colorBy: 'series',
                        name: monthsLabel.previous,
                        showSymbol: false,
                        smooth: true,
                        blendMode: 'darken',
                        z: 1,
                        data: lastMonthData.map((item: GraphItem) => {
                            lastMonthConsumption = lastMonthConsumption + Number(item.consumption);
                            return this.cutValue(lastMonthConsumption, kpi);
                        }),
                        type: 'line',
                        lineStyle: {
                            width: 0
                        },
                        areaStyle: {
                            color: corAnterior,
                            opacity: 1
                        },
                        itemStyle: {
                            color: corAnterior
                        }
                    },
                    {

                        colorBy: 'series',
                        name: monthsLabel.actual,
                        z: 2,
                        smooth: true,
                        blendMode: 'darken',
                        showSymbol: false,
                        data: currentMonthData.map((item: GraphItem, index: number) => {
                            currentMonthConsumption = currentMonthConsumption + Number(item.consumption);
                            return (moment().unix() < moment(currentMonthData[index].label, 'DD/MM/YYYY').unix() ? '0' : this.cutValue(currentMonthConsumption, kpi));
                        }),
                        type: 'line',
                        lineStyle: {
                            width: 0
                        },
                        areaStyle: {
                            color: corAtual,
                            opacity: 0.5
                        },
                        itemStyle: {
                            color: corAtual
                        }
                    },
                ]
            });

            return resultado;
        } catch (e) {
            return ([]);
        }

    };

    getLineConsumptionChart = (currentMonthTotalConsumption: any, lastMonthTotalConsumption: any, fullMonth: boolean, monthsLabel: any, kpi: any) => {
        if (monthsLabel)
            return ({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    valueFormatter: (value) => { return formatDecimal(value) },

                },
                legend: {
                    inactiveColor: '#ededed',
                    inactiveBorderColor: '#ededed',
                    data: [monthsLabel.previous, monthsLabel.actual]
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    axisLabel: {
                        interval: fullMonth ? 7 : 5,
                        showMaxLabel: true
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    axisTick: { show: false },
                    type: 'category',
                    axisLine: {
                        symbol: "none",
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    nameLocation: 'middle',
                    boundaryGap: false,
                    data: fullMonth ?
                        currentMonthTotalConsumption.map((day: any) => {
                            return (moment(day.label, 'DD/MM/YYYY').format('DD/MMM'));
                        })
                        :
                        currentMonthTotalConsumption.slice(0, (new Date().getDate() - 1)).map((day: any) => {
                            return (moment(day.label, 'DD/MM/YYYY').format('DD/MMM'));
                        })
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    splitNumber: 3
                },
                series: [
                    {
                        name: monthsLabel.previous,
                        type: 'line',
                        data: lastMonthTotalConsumption.map((item: any) => {
                            return this.cutValue(item.consumption, kpi);
                        }),
                        itemStyle: {
                            color: '#F3C944',
                        },
                        showSymbol: false,
                    },
                    {
                        name: monthsLabel.actual,
                        type: 'line',
                        data: currentMonthTotalConsumption.map((item: any) => {
                            return this.cutValue(item.consumption, kpi);
                        }),
                        itemStyle: {
                            color: '#59BAC5'
                        },
                        showSymbol: false,
                    }
                ]
            });
    };

    getBarConsumptionChart = (tipoDePeriodo: any, cores: any, kpi: any, type?: "bar" | "stack" | "line") => {

        const _baseValue = this.getBaseValue(kpi);
        const titleAmount = _baseValue <= 1000000 ? ' mil' : _baseValue <= 100000000 ? ' milhão' : '';
        var dataGroupList: any[] = [];
        if (tipoDePeriodo == "resource") {
            dataGroupList = kpi?.dataGroupbyResource;
        }

        if (tipoDePeriodo == "service") {
            dataGroupList = kpi?.dataGroupbyService;
        }

        if (tipoDePeriodo == "costCenter") {
            dataGroupList = kpi?.dataGroupbyCostCenter;
        }

        if (tipoDePeriodo == "Total") {
            dataGroupList = kpi?.dataGroupbyTotal;
        }

        let _dataGroupLast = kpi && dataGroupList && dataGroupList.length > 0
            ?
            dataGroupList.filter(d => d.group != "Outros")
            :
            [];

        _dataGroupLast.length > 0 &&
            _dataGroupLast.push(kpi.dataGroupLast.filter(d => d.group == "Outros")[0]);

        return tipoDePeriodo.includes("last") && ({
            color: cores,
            tooltip: {
                trigger: 'axis',
                order: 'valueDesc',
                axisPointer: {
                    label: {
                        backgroundColor: '#6a7985'
                    }
                },
                valueFormatter: (value) => { return formatDecimal(value) },
                formatter: function (params) {
                    var i = 0;
                    var output = `<div class="row between"><b>${params[0].name}</b><b>R$ ${titleAmount}</b></div>`
                    var gParm = params.sort((param1, param2) => { return param1.seriesIndex < param2.seriesIndex ? 1 : -1 })
                    for (i = 0; i < gParm.length; i++) {
                        if (gParm[i].data > 0) {
                            output += gParm[i].marker + '<span style="white-space: nowrap;max-width:300px; text-overflow: ellipsis;">' + gParm[i].seriesName + '</span>' + ': ' + '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + formatDecimal(gParm[i].data) + '</span>';
                            if (i != gParm.length - 1) { // Append a <br/> tag if not last in loop
                                output += '<br/>'
                            }
                        }
                    }
                    return output;
                }
            },
            grid: {
                left: '1%',
                right: '1%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                axisLabel: {
                    interval: 0,
                    showMaxLabel: true,
                    fontSize: tipoDePeriodo.includes("30") ? window.innerWidth > 1366 ? 12 : 10 : undefined
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dashed'
                    },
                },
                axisTick: {
                    alignWithLabel: true
                },
                type: 'category',
                axisLine: {
                    symbol: "none",
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                nameLocation: 'middle',
                boundaryGap: true,
                data: kpi && dataGroupList && dataGroupList.length > 0 ?
                    dataGroupList[0].series.map((item: any) => {
                        if (tipoDePeriodo.includes("30") || tipoDePeriodo.includes("7")) {
                            return (moment(item.date.split('T')[0]).format('MMM D'));
                        }
                        return (moment(item.date.split('T')[0]).format('MMM/yyyy'));
                    })
                    : []
            },
            yAxis: {
                type: 'value',
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed'
                    },
                },
                splitNumber: 7,
                axisLabel: {
                    formatter: value => formatNumber(value)
                }
            },
            series:
                _dataGroupLast
                    .map((itemGroup, index) => {

                        return {
                            colorBy: 'series',
                            stack: type == "stack" ? 'x' : undefined,
                            name: itemGroup && itemGroup.group || 'nd',
                            type: type ? (type == "bar" || type == "stack" ? "bar" : "line") : 'bar',
                            data: itemGroup ? itemGroup.series
                                .sort((element1, element2) => {
                                    return (element1.date).valueOf() - (element2.date).valueOf()
                                })
                                .map((item: any) => {
                                    return this.cutValue(item.total, kpi)
                                }) : [],
                            showSymbol: false,
                            color: itemGroup && itemGroup.group == "Outros" ? "#aaa" : cores[index]
                        };
                    })

        });
    };

    getDougnutChart = (data: any) => {
        if (!data) {
            return '';
        }

        // Calcule o valor total
        let total = data.reduce((sum, { value }) => sum + value, 0);

        const dataLookup = data.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {});

        return {
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 4,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: true,
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(58, 79, 135, 0.4)',
                            borderWidth: 0
                        }
                    }
                }
            ]
        }
    }

    getDougnutChartWithLegend = (data: any) => {
        if (!data) {
            return '';
        }
        const screenWidth = window.innerWidth;
        let fontSize = screenWidth <= 1400 ? 10 : 11;

        //* Calcule o valor total
        let total = data.reduce((sum, { value }) => sum + value, 0);

        const dataLookup = data.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {});

        return {
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 11,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                trigger: 'item'
            },
            legend: {
                inactiveColor: '#ededed',
                inactiveBorderColor: '#ededed',
                type: 'scroll',  // Enable scroll (pagination)
                orient: screenWidth < 1100 ? 'horizontal' : 'vertical',
                right: '0%',
                top: screenWidth < 1100 ? 'bottom' : 'middle',
                textStyle: {
                    fontSize: fontSize + 'px',
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
                pageButtonItemGap: 5,
                pageButtonGap: 10,
                pageButtonPosition: 'end',
                pageFormatter: '{current}/{total}',  // You can customize this
                pageIcons: {
                    horizontal: [
                        'M0,0 L12,-12',    // up
                        'M0,12 L12,0'     // down
                    ]
                },
                //data: legendData,
                formatter: function (name) {
                    const value = dataLookup[name] || 0;  //* Pega o valor do objeto que criamos anteriormente
                    return `${name}: ${value.toLocaleString('pt-BR')}`;
                },
            },
            series: [
                {
                    type: 'pie',
                    center: screenWidth < 1100 ? ['50%', '40%'] : ['30%', '50%'],
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 4,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: true,
                        position: 'center',
                        formatter: function () {
                            return total;
                        },
                        fontSize: fontSize + 'px',
                        fontWeight: 'bold',
                        textStyle: {
                            overflow: 'truncate',
                            ellipsis: '...',
                            fontFamily: 'Ubuntu',
                        }
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(58, 79, 135, 0.4)',
                            borderWidth: 0
                        }
                    }
                }
            ]
        }
    }

    getBarChartCollectorsStacked = (data: any, isHome: boolean | undefined) => {

        const screenWidth = window.innerWidth;
        let fontSize = screenWidth <= 1400 ? 10 : 12; //* Ajuste os tamanhos conforme necessário

        // estilo de fonte dos rotulos x e y
        const axisStyle = {
            axisLabel: {
                fontSize: fontSize + 'px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        };

        const status = (label: any) => {
            switch (label) {
                case 'Online':
                    return '#81bc00';
                case 'Executando':
                    return '#81bc00';
                case 'Desabilitado':
                    return '#b3b3b3';
                case 'Desconectado':
                    return '#dc291e';
                case 'Degradado':
                    return '#ffa400';
                default:
                    return '#777777';
            }
        }

        return (
            {
                grid: { 
                    top: '10%',
                    bottom: '10%',
                    left: '0%',
                    width: '95%',
                    containLabel: isHome == true ? true : false,
                },
                tooltip: {
                    backgroundColor: '#f1f5fb',
                    borderWidth: 0,
                    textStyle: {
                        fontSize: 11,
                        fontFamily: 'Ubuntu',
                        color: '#2B304F',
                    },
                    trigger: 'axis',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: data.labels,
                        show: true,
                        ...axisStyle,
                    }
                ],
                yAxis: [
                    {
                        ...axisStyle,
                        type: 'value',
                        show: isHome != true
                    }
                ],
                series: data.datasets.map((dataset, index, array) => {
                    // Se for a última série (topo da pilha)
                    if (index == array.length - 1) {
                        return {
                            ...dataset,
                            itemStyle: {
                                color: status(dataset.name), // usando a função status para determinar a cor
                                borderRadius: [4, 4, 0, 0],
                                borderColor: '#fff',
                                borderWidth: 0
                            },
                        }
                    }
                    // Para todas as outras séries
                    return {
                        ...dataset,
                        itemStyle: {
                            color: status(dataset.name) // usando a função status para determinar a cor
                        }
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(58, 79, 135, 0.6)'
                    }
                }
            }
        )
    }

    getBarChart = (data: any, isHome: boolean | undefined) => {
        const screenWidth = window.innerWidth;

        // estilo de fonte dos rotulos x e y
        const axisStyle = {
            axisLabel: {

                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                interval: 0,
            },
        };

        return (
            {
                tooltip: {
                    backgroundColor: '#f1f5fb',
                    borderWidth: 0,
                    textStyle: {
                        fontSize: 11,
                        fontFamily: 'Ubuntu',
                        color: '#2B304F',
                    },
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    height: 'fit-content',
                    top: '10%',
                    bottom: '10%',
                    left: '0%',
                    width: '100%',
                    containLabel: isHome == true ? true : false,

                },
                xAxis: {
                    type: 'category',
                    data: data.labels,
                    show: true,
                    ...axisStyle
                    
                },
                yAxis: {
                    ...axisStyle,
                    type: 'value',
                    show: isHome != true
                },
                series: [
                    {
                        data: data.data,
                        itemStyle: {
                            color: (params) => data.colors[params.dataIndex],
                            borderRadius: [4, 4, 0, 0],
                            borderColor: '#fff',
                            borderWidth: 0,

                        },
                        type: 'bar',
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(58, 79, 135, 0.4)',
                            }
                        }

                    }
                ]
            }
        )
    }

    getBarChartCustomTooltip = (data, legend?: any) => {
        return (
            {
                tooltip: {
                    trigger: 'axis',
                    position: 'fixed',
                    hideDelay: 2000,
                    formatter: function (params) {
                        let legendDevices = legend.filter(devices => {
                            return devices.classification == params[0].name
                        })
                        let divLegend =
                            "<div>" +
                            "<div style=' display: flex;'>" +
                            "<div style='width:10px; height:10px; margin: 5px 5px 5px 0px; background-color:" + params[0].color + "'> </div> " +
                            " <p style='font-weight:bold; fontSize:12px;'>" + params[0].name + "</p>" +
                            "</div>" +
                            "<div style='display: flex; flex-direction: column;font-size: 10px; max-width: 500px;'>" +
                            legendDevices.map(dev => {
                                return (
                                    "<p style='font-size: 10px; max-width: 200px; font-weight: bold'>" + dev.device + ": " + dev.count + "</p>"
                                )
                            }) +
                            "</div>" +
                            "</div>"

                        return "<div class='tooltipChart'>" + divLegend.replaceAll(',', ' ') + "</div>"

                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    height: 'fit-content',
                    top: '15%',
                    left: '0%',
                    width: '100%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: data.labels,
                    show: true

                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: data.data,
                        itemStyle: {
                            color: (params) => data.colors[params.dataIndex],
                            borderRadius: [4, 4, 0, 0],
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        type: 'bar',
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(58, 79, 135, 0.4)',
                            }
                        }

                    }
                ]
            }
        )
    }
}