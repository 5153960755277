import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { EmailUsageUserReport, Office365EmailActivityUserDetailReport, OneDriveUsageReport, SaasService } from '../../../services/saas/saasService';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';
import ImgFluxo from '../../../assets/images/Fluxos/csp.png';
import history from '../../../history';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import PivotTableWebix from '../../../components/webix';
import { OneDrveUsageChart } from './OneDriveUsageChart';
import { EmailBoxesChart } from './EmailBoxesChart';
import { ActiveUsersPerDayChart } from './ActiveUsersPerDayChart';
import { SaasEmailActivitiesChart } from './SaasEmailActivitiesChart';
import LcLoading from '../../../components/Generic/LcLoading';

const SaaSAtividades: React.FC = () => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const [pivotViewUsers, setPivotViewUsers] = useState<boolean>(false);
    const [pivotViewEmail, setPivotViewEmail] = useState<boolean>(false);
    const [pivotViewOneDrive, setPivotViewOneDrive] = useState<boolean>(false);
    const [pivotViewBox, setPivotViewBox] = useState<boolean>(false);
    const [userPeriod, setUserPeriod] = useState<number>(7);
    const [office365ActiveUserDetail, setOffice365ActiveUserDetail] = useState<any[]>([]);
    const [graphOffice365EmailActivityDetail, setGraphOffice365EmailActivityDetail] = useState<Office365EmailActivityUserDetailReport[]>([]);
    const [oneDriveUsageAccountDetail, setOneDriveUsageAccountDetail] = useState<OneDriveUsageReport[]>([]);
    const [mailboxUsageDetail, setMailboxUsageDetail] = useState<EmailUsageUserReport[]>([]);
    const [loading,setLoading] = useState(false);
    const lastUpdate = new Date();
    const dispatch = useDispatch();
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    const periodSelection = useMemo(() => ({
        periods: [
            {
                label: 'Últimos 7 dias', text: 'Últimos 7 dias',
                fields: [
                    { name: 'last7Days', value: '7' },
                ],
                default: true
            },
            {
                label: 'Últimos 30 dias', text: 'Últimos 30 dias',
                fields: [
                    { name: 'last30Days', value: '30' }
                ],
                default: false
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: '90' }
                ],
                default: false
            },
            {
                label: 'Últimos 6 meses', text: 'Últimos 6 meses',
                fields: [
                    { name: 'last180Days', value: '180' }
                ],
                default: false
            },
        ],
        customPeriodType: 'month',
        onChange: (selected) => {setUserPeriod(Number(selected.fields[0].value))}
    }), []);

    useEffect(() => {
        setLoading(true)
        const saasService = new SaasService({});
        const getOffice365ActiveUserDetail = async (days: number) => {
            var result = await saasService.GetOffice365ActiveUserDetail(days);
            setOffice365ActiveUserDetail(result)
        }
    
        const getGraphOffice365EmailActivityDetail = async (days: number) => {
            var result = await saasService.GetGraphOffice365EmailActivityDetail(days);
            setGraphOffice365EmailActivityDetail(result);
        }
        const getOneDriveUsageAccountDetail = async (days: number) => {
            var result = await saasService.GetOneDriveUsageAccountDetail(days);
            setOneDriveUsageAccountDetail(result);
        }
        const GetMailboxUsageDetail = async (days: number) => {
            var result = await saasService.GetMailboxUsageDetail(days);
            setMailboxUsageDetail(result);
        }
        Promise.all([
            getOffice365ActiveUserDetail(userPeriod),
            getGraphOffice365EmailActivityDetail(userPeriod),
            GetMailboxUsageDetail(userPeriod),
            getOneDriveUsageAccountDetail(userPeriod),
        ]).finally(() => setLoading(false))
    }, [user.ClientGroupSelected, user.refreshFlag, userPeriod]);


    //#region Verifica se há GTM CSP
    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id === '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            const saasService = new SaasService({});
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu, menu.selectedItem])
    //#endregion

    const cards: Card[] = [
        //Usuários ativos / dia
        {
            id: 1,
            type: 'Custom',
            showFocusButton: true,
            position: { ColSpan: 6, RowSpan: 2, row: 1, col: 1 },
            title: () => <>{
                <div className="lc-title ellipsis" title="Usuários ativos / dia">Usuários ativos / dia</div>
            }</>
            ,
            customContentRender: (focused: boolean) => {
                setPivotViewUsers(focused);
                if (focused) {                    
                    return <LcLoading loading={loading}>
                        <PivotTableWebix
                            focusedStyleVision
                            idView={'dataPivotoffice365ActiveUser'}
                            key={'dataPivotoffice365ActiveUser'}
                            data={office365ActiveUserDetail}
                            style={{ marginTop: '-10px' }}
                            fields={[
                                { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                                { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                                { id: 'displayName', value: "Nome de Exibição", type: "text" },
                                { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                                { id: 'deletedDate', value: "Data de Deleção", type: "date", predicate: "date" },
                                { id: 'hasExchangeLicense', value: "Exchange", type: "number" },
                                { id: 'hasOneDriveLicense', value: "OneDrive", type: "number" },
                                { id: 'hasSharePointLicense', value: "SharePoint", type: "number" },
                                { id: 'hasSkypeForBusinessLicense', value: "Skype for Business", type: "number" },
                                { id: 'hasYammerLicense', value: "Yammer", type: "number" },
                                { id: 'hasTeamsLicense', value: "Teams", type: "number" },
                                { id: 'exchangeLastActivityDate', value: "Data da Última Atividade no Exchange", type: "date", predicate: "date" },
                                { id: 'oneDriveLastActivityDate', value: "Data da Última Atividade no OneDrive", type: "date", predicate: "date" },
                                { id: 'sharePointLastActivityDate', value: "Data da Última Atividade no SharePoint", type: "date", predicate: "date" },
                                { id: 'skypeForBusinessLastActivityDate', value: "Data da Última Atividade no Skype for Business", type: "date", predicate: "date" },
                                { id: 'yammerLastActivityDate', value: "Data da Última Atividade no Yammer", type: "date", predicate: "date" },
                                { id: 'teamsLastActivityDate', value: "Data da Última Atividade no Teams", type: "date", predicate: "date" },
                                { id: 'exchangeLicenseAssignDate', value: "Data de Atribuição da Licença Exchange", type: "date", predicate: "date" },
                                { id: 'oneDriveLicenseAssignDate', value: "Data de Atribuição da Licença OneDrive", type: "date", predicate: "date" },
                                { id: 'sharePointLicenseAssignDate', value: "Data de Atribuição da Licença SharePoint", type: "date" },
                                { id: 'skypeForBusinessLicenseAssignDate', value: "Data de Atribuição da Licença Skype for Business", type: "date", predicate: "date" },
                                { id: 'yammerLicenseAssignDate', value: "Data de Atribuição da Licença Yammer", type: "date", predicate: "date" },
                                { id: 'teamsLicenseAssignDate', value: "Data de Atribuição da Licença Teams", type: "date", predicate: "date" },
                                { id: 'assignedProducts', value: "Produtos Atribuídos", type: "text" }
                            ]}
                            structure={{
                                rows: ["displayName", "userPrincipalName"],
                                values: [
                                    { name: "hasTeamsLicense", operation: ["sum"] },
                                    { name: "hasExchangeLicense", operation: ["sum"] },
                                    { name: "hasOneDriveLicense", operation: ["sum"] },
                                    { name: "hasSharePointLicense", operation: ["sum"] },
                                    { name: "hasYammerLicense", operation: ["sum"] }
                                ]
                            }}
                        />
                    </LcLoading>
                } else {
                    return <ActiveUsersPerDayChart userPeriod={userPeriod}/>;
                }
            }
        },
        //Atividades de e-mail
        {
            id: 10, type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 1, col: 7
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="Atividades de e-mail">Atividades de e-mail</div>
            }</>
            , customContentRender: (focused: boolean) => {
                setPivotViewEmail(focused);
                if (focused) {
                    return <LcLoading loading={loading}>
                        <PivotTableWebix
                        focusedStyleVision
                        idView={'dataPivotgraphOffice365EmailActivityDetail'}
                        key={'dataPivotgraphOffice365EmailActivityDetail'}
                        data={graphOffice365EmailActivityDetail}
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                            { id: 'displayName', value: "Nome de Exibição", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'deletedDate', value: "Data de Deleção", type: "date", predicate: "date" },
                            { id: 'lastActivityDate', value: "Data da Última Atividade", type: "date", predicate: "date" },
                            { id: 'sendCount', value: "Contagem de Envio", type: "number" },
                            { id: 'receiveCount', value: "Contagem de Recebimento", type: "number" },
                            { id: 'readCount', value: "Contagem de Leitura", type: "number" },
                            { id: 'meetingCreatedCount', value: "Contagem de Reuniões Criadas", type: "number" },
                            { id: 'meetingInteractedCount', value: "Contagem de Interações em Reuniões", type: "number" },
                            { id: 'assignedProducts', value: "Produtos Atribuídos", type: "text" },
                            { id: 'reportPeriod', value: "Período do Relatório", type: "number" }
                        ]}
                        structure={{
                            rows: ["displayName", "userPrincipalName"],
                            values: [
                                { name: "sendCount", operation: ["sum"] },
                                { name: "receiveCount", operation: ["sum"] },
                                { name: "readCount", operation: ["sum"] },
                                { name: "meetingCreatedCount", operation: ["sum"] },
                                { name: "meetingInteractedCount", operation: ["sum"] }
                            ]
                        }}
                    />  </LcLoading>
                } else {
                    return <SaasEmailActivitiesChart userPeriod={userPeriod}/> 
                }
            }
        },
        //OneDrive ativas / uso
        {
            id: 11, type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 3, col: 1
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="OneDrive ativas / uso">OneDrive ativas / uso</div>
            }</>

            , customContentRender: (focused: boolean) => {
                setPivotViewOneDrive(focused);
                if (focused) {
                    return <LcLoading loading={loading}> 
                    <PivotTableWebix
                        focusedStyleVision
                        idView={'dataPivotoneDriveUsageAccountDetail'}
                        key={'dataPivotoneDriveUsageAccountDetail'}
                        data={oneDriveUsageAccountDetail}
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'siteId', value: "ID do Site", type: "text" },
                            { id: 'siteURL', value: "URL do Site", type: "text" },
                            { id: 'ownerDisplayName', value: "Nome de Exibição do Proprietário", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'lastActivityDate', value: "Data da Última Atividade", type: "date", predicate: "date" },
                            { id: 'fileCount', value: "Arquivos", type: "number" },
                            { id: 'activeFileCount', value: "Arquivos_Ativos", type: "number" },
                            { id: 'storageUsed', value: "BytesUsados", type: "number" },
                            { id: 'storageAllocated', value: "BytesAlocados", type: "number" },
                            { id: 'ownerPrincipalName', value: "Nome Principal do Proprietário", type: "text" }
                        ]}
                        structure={{
                            rows: ["ownerDisplayName", "ownerPrincipalName"],
                            values: [
                                { name: "fileCount", operation: ["sum"] },
                                { name: "activeFileCount", operation: ["sum"] },
                                { name: "storageUsed", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "storageAllocated", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                            ]
                        }}
                    /> </LcLoading>
                } else {
                    return  <OneDrveUsageChart userPeriod={userPeriod}/>
                }
            }

        },
        //Caixas - ativas / uso
        {
            id: 12,
            type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 3, col: 7
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="Caixas - ativas / uso">Caixas - ativas / uso</div>
            }</>
            , customContentRender: (focused: boolean) => {
                setPivotViewBox(focused);
                if (focused) {
                    return <LcLoading loading={loading}>
                        <PivotTableWebix
                        focusedStyleVision
                        idView={'dataPivotoffice365ActiveUserDetailmailboxUsageDetail'}
                        key={'dataPivotoffice365ActiveUserDetailmailboxUsageDetail'}
                        data={mailboxUsageDetail} // Add your data here
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Dt de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                            { id: 'displayName', value: "Nome de Exibição", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'deletedDate', value: "Dt de Exclusão", type: "date", predicate: "date" },
                            { id: 'createdDate', value: "Dt de Criação", type: "date", predicate: "date" },
                            { id: 'lastActivityDate', value: "Dt da Atividade", type: "date", predicate: "date" },
                            { id: 'itemCount', value: "Contagem_de_Itens", type: "number" },
                            { id: 'storageUsed', value: "Utilizado_(Bytes)", type: "number" },
                            { id: 'issueWarningQuota', value: "Quota_de_Aviso(Bytes)", type: "number" },
                            { id: 'prohibitSendQuota', value: "Quota_de_Proibição(Bytes)", type: "number" },
                            { id: 'prohibitSendReceiveQuota', value: "Quota_de_Proibição de Envio/Recebimento (Bytes)", type: "number" },
                            { id: 'deletedItemCount', value: "Qtd_de_Itens_Deletados", type: "number" },
                            { id: 'deletedItemSize', value: "Tamanho_Deletados(Bytes)", type: "number" },
                            { id: 'deletedItemQuota', value: "Quota_de_Itens_Deletados(Bytes)", type: "number" },
                            { id: 'hasArchive', value: "Possui_Arquivo", type: "boolean" }
                        ]}
                        structure={{
                            rows: ["displayName", "userPrincipalName"], // Adjust to the properties you want to aggregate by
                            values: [
                                { name: "itemCount", operation: ["sum"] },
                                { name: "storageUsed", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "issueWarningQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "prohibitSendQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "prohibitSendReceiveQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "deletedItemCount", operation: ["sum"] },
                                { name: "deletedItemSize", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "deletedItemQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                            ],
                        }}
                    /> </LcLoading>
                } else {
                    return <EmailBoxesChart userPeriod={userPeriod}/> 
                }
            }
        }
    ]

    return (
        <Layout pivot={pivotViewUsers || pivotViewEmail || pivotViewOneDrive || pivotViewBox} pageTitle="Atividades" lastUpdate={lastUpdate} periodSelection={periodSelection}>
            {
                showFluxoCSP ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxo} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <LCDashboard cards={cards} />
            }
        </Layout>
    );
};

export default SaaSAtividades;