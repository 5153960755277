import React, { useState, useEffect } from 'react';
import { authProvider } from './../../services/authProvider';

import './login.css';
import LogoLC from '../../assets/images/logo-livecloud.svg'
import iconAuthorize from '../../assets/images/icon-authorize.svg'

import { InputField } from './../../components/Form/Input';

import App from '../../App';
import { PPModal } from 'processor-plataform-ui';

// Extend JSX for custom element
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'spline-viewer': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { url: string };
        }
    }
}

const Login: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [LoginLC, setLoginLC] = useState<boolean>(false);
    const [enableModalEmailLogin, setEnableModalEmailLogin] = useState<boolean>(false);
    const [enableModalTokenLogin, setEnableModalTokenLogin] = useState<boolean>(false);
    const [showNewFormEmailLogin, setShowNewFormEmailLogin] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [token, setToken] = useState<string>('');

    useEffect(() => {
        // Função para carregar o script dinamicamente
        const loadSplineViewerScript = () => {
            const script = document.createElement('script');
            script.src = "https://unpkg.com/@splinetool/viewer@1.9.20/build/spline-viewer.js";
            script.type = "module";
            script.async = true;
            document.head.appendChild(script);

            // Limpeza do script ao desmontar o componente, se necessário
            return () => {
                document.head.removeChild(script);
            };
        };

        loadSplineViewerScript();
    }, []); // Dependência vazia garante que o script seja carregado uma única vez ao montar o componente

    const inputSetEmail = (data: any) => {
        setEmail(data.value);
    };

    const inputSetToken = (data: any) => {
        setToken(data.value);
    };

    function Login() {
        authProvider.LoginMSSO();
    }

    function Authorize() {
        authProvider.AuthorizeOrganization();
    }

    function GenerateTokenEmailLogin() {
        setIsLoading(true);
        authProvider.GenerateTokenEmailLogin(email)
            .then((response: any) => {
                const successMessage: string = response.data;
                setMessage('Token gerado e enviado para seu email.');
                setShowNewFormEmailLogin(true);
                setIsLoading(false);
                setEnableModalTokenLogin(true);
                setEnableModalEmailLogin(false);
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.includes('email')) {
                    let _r = error.response.data.split('R')[0];
                    const erroMessage: string = _r;
                    setMessage(erroMessage);
                    setShowNewFormEmailLogin(false);
                    setIsLoading(false);
                } else {
                    const erroMessage: string = error.message;
                    setMessage(erroMessage);
                    setShowNewFormEmailLogin(false);
                    setIsLoading(false);
                }
            });
    }

    function LoginEmail() {
        setIsLoading(true);
        authProvider.LoginEmail(email, token).then((result) => {
            if (result) {
                setLoginLC(true);
                setIsLoading(false);
            } else {
                setLoginLC(false);
                setIsLoading(false);
                setShowNewFormEmailLogin(false);
                setMessage("Erro ao realizar autenticação, tentar novamente em alguns minutos!");
                setEnableModalTokenLogin(false);
                setEnableModalEmailLogin(true);
            }
        });
    }

    return (
        <>
            {LoginLC && <App />}
            {!LoginLC && (
                <div className="container-login">
                    <PPModal
                        visible={enableModalEmailLogin}
                        title="Login com email"
                        onClose={() => { setEnableModalEmailLogin(false); }}
                        functions={[
                            { label: isLoading ? <i className='ppi ppi-loading ppi-spin ppi-3x' /> : "Gerar Token", onClick: () => GenerateTokenEmailLogin(), disabled: isLoading }
                        ]}
                    >
                        {message.length > 0 && <p className='text-danger mb-5'>{message}</p>}
                        {!showNewFormEmailLogin && <InputField name="email" value={email} onChange={inputSetEmail} placeHolder="Digite seu email..." type="email" />}
                    </PPModal>

                    <PPModal
                        visible={enableModalTokenLogin}
                        title="Login com email"
                        onClose={() => { setEnableModalTokenLogin(false); }}
                        functions={[
                            { label: isLoading ? <i className='ppi ppi-loading ppi-spin ppi-3x' /> : "Entrar", onClick: () => LoginEmail(), disabled: isLoading }
                        ]}
                    >
                        {message.length > 0 && <div><span>{message}</span><br /><br /></div>}
                        {showNewFormEmailLogin && <InputField label="Token de acesso" name="token" value={token} onChange={inputSetToken} placeHolder="XXXXXXXX" type="text" />}
                    </PPModal>

                    <div className="content-login">
                        <div className='logo-container'>
                            <div className="logolc"><img src={LogoLC} alt="LiveCloud" /></div>
                            <div className="title"><h2>A plataforma de serviços gerenciados multicloud.</h2></div>
                        </div>
                        <div className="buttons">
                            <div><button id="82220dca-e27c-4434-be67-a0c5fede2cb8" onClick={() => Login()}>Conectar</button></div>
                            <div><button id="f6dc10ef-88ab-40bd-9afc-2cce6336f35e" onClick={() => setEnableModalEmailLogin(!enableModalEmailLogin)}>Conectar com email</button></div>
                        </div>
                        <div className='authorize-container'>
                            <img src={iconAuthorize} alt="Autorizar em sua organização" />
                            <span className="authorize" onClick={() => Authorize()}>Autorizar em sua organização</span>
                        </div>
                    </div>

                    <div className="spline">
                        <spline-viewer url="https://prod.spline.design/NzD4oQ0Rh6rM2AUV/scene.splinecode"></spline-viewer>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;