import { PP_DropDownOld, PPInput, PPModal, PPMultiSelect } from 'processor-plataform-ui';
import React, { ReactNode, useEffect, useState } from 'react';

import { PortalUsersService } from '../../../services/portalUsers/portalUsers';
import PPCheckBox from '../../../components/Form/PPCheckBox';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

import { useSnackbar } from 'notistack';
import PPDropDown from '../../../components/PPDropDown';

interface FormProps {
    visible: boolean
    onClose: Function
    user: any
    loading: boolean
    infoMode?: boolean
}

const Form: React.FC<FormProps> = (props) => {

    const actualUser = useSelector<RootState, UserState>((state) => state.user);

    const service = new PortalUsersService(props);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<any>(props.user);

    const [clientList, setClientList] = useState<any[]>([]);
    const [clientDefaultList, setClientDefaultList] = useState<any[]>([]);
    const [profiles, setProfiles] = useState<any[]>([]);

    const [bodyToSend, setBodyToSend] = useState({
        user: user,
        UserClientes: [] as any,
        UserPerfis: [] as any
    });

    useEffect(() => {
        if (props.user) {
            setErrors([])
            setLoading(true);
            service.initializeEdit(false, props.user.id)
                .then(res => {
                    service.data()
                        .then(response => {

                            let _user = { ...props.user, clientDefault: response.DefaultClient.value }
                            setUser(_user);

                            setBodyToSend({
                                user: props.user,
                                UserClientes: response.LinkedClients.map(c => { return { ClientGroupId: c.value, IsDefault: response.DefaultClient.value == c.value } }) as any,
                                UserPerfis: response.Profiles.filter(p => p.selected).map(p => p.value)
                            })

                            setProfiles(response.Profiles);
                            setClientList(response.Clients);
                            setClientDefaultList(response.Clients.filter(c => c.selected) || []);

                            setLoading(false);
                        })
                })
        }

    }, [props.user])

    const onSubmit = () => {

        let _errors = checkErrors();

        if (_errors.length == 0) {

            setLoading(true)

            bodyToSend.user.id ?
                service.editUser(bodyToSend)
                    .then(response => {
                        enqueueSnackbar(response.data, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });

                    })
                    .catch(error => {
                        enqueueSnackbar(error.response.data, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                    })
                    .then(() => {
                        setLoading(false);
                        props.onClose(false);
                    })
                :
                service.createUser(bodyToSend)
                    .then(response => {
                        enqueueSnackbar(response.data, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });

                    })
                    .catch(error => {
                        enqueueSnackbar(error.response.data, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                    })
                    .then(() => {
                        setLoading(false);
                        props.onClose(false);
                    })
        } else {
            setErrors(_errors)
        }
    }

    const [errors, setErrors] = useState<any[]>([]);

    const checkErrors = () => {

        let erros: any[] = [];

        !user.name &&
            erros.push({ field: 'name', help: 'Campo obrigatório' });

        !user.userName &&
            erros.push({ field: 'userName', help: 'Campo obrigatório' });

        !user.email &&
            erros.push({ field: 'email', help: 'Campo obrigatório' });

        !user.idiomaPadrao &&
            erros.push({ field: 'idiomaPadrao', help: 'Campo obrigatório' });

        if (actualUser && actualUser.email && (actualUser.email.includes('@processor.com.br') || actualUser.email.includes('@gotobiz.com.br'))) {

            (!bodyToSend.UserClientes || bodyToSend.UserClientes.length == 0) &&
                erros.push({ field: 'client', help: 'Campo obrigatório' });

            !user.clientDefault &&
                erros.push({ field: 'clientDefault', help: 'Campo obrigatório' })
        }

        return erros;
    }

    useEffect(() => {
        setBodyToSend({ ...bodyToSend, user: user });
    }, [user])

    const onChange = (event, field) => {

        setErrors(errors.filter(e => e.field != field));

        if (field == 'client') {
            const cIds = event.map(c => c.value);
            setClientList(clientList.map(c => ({ ...c, selected: cIds.includes(c.value) })))
            setBodyToSend({ ...bodyToSend, UserClientes: event.map(c => { return { ClientGroupId: c.value, IsDefault: user.clientDefault == c.value } }) });
            setClientDefaultList(event);
            !event.find(d => d.value == user.clientDefault) &&
                setUser({ ...user, clientDefault: undefined })
        }

        else if (field == 'clientDefault') {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setBodyToSend({ ...bodyToSend, UserClientes: bodyToSend.UserClientes.map(c => ({ ...c, IsDefault: c.ClientGroupId == event.value } as any)) })
            setUser({ ...user, clientDefault: event })
        }

        else if (field == 'profiles') {
            const pIds = event.map(p => p.value);
            setProfiles(profiles.map(p => ({ ...p, selected: pIds.includes(p.value) })))
            setBodyToSend({ ...bodyToSend, UserPerfis: pIds });
        }
        else if (field == 'idiomaPadrao') {
            const { value } = event;
            setUser({ ...user, [field]: value });
            setBodyToSend({ ...bodyToSend, user: { ...bodyToSend.user, [field]: value } });
        }
        else {
            const { value } = event.target;
            setUser({ ...user, [field]: value });
            setBodyToSend({ ...bodyToSend, user: { ...bodyToSend.user, [field]: value } });
        }
    }

    const mountErrors = (field: string) => {

        let _errors = errors.filter(e => e.field == field) || undefined;

        let _error = (_errors && _errors.length > 0) ? {
            show: true,
            help: <>
                {
                    _errors.map((e, i) => { return <div key={i}><i className='ppi ppi-x text-danger' />{e.help}</div> })
                }
            </> as ReactNode
        } : undefined;

        return _error;
    }

    const onClose = () => {
        setErrors([]);
        props.onClose(false)
    }

    const idiomasOptions = [
        { label: 'Português', value: 'pt' },
        { label: 'Espanhol', value: 'es' },
        { label: 'Inglês', value: 'en' }
    ];

    return <PPModal
        title={`${props.infoMode ? 'Detalhes do' : props.user && props.user.userName ? 'Editar' : 'Criar'} usuário`}
        visible={props.visible}
        onClose={() => onClose()}
        loading={props.loading || loading}
    >
        {
            !props.infoMode ?
                <div className="form" style={{ height: '400px' }}>

                    <div className="fields scrollable-v">
                        <PPInput
                            title="Nome"
                            name='name'
                            type='text'
                            value={user?.name}
                            onChange={(e) => onChange(e, 'name')}
                            required
                            error={mountErrors('name')}
                        />
                        <PPInput
                            type='text'
                            title="Login"
                            value={user?.userName}
                            name='userName'
                            onChange={(e) => onChange(e, 'userName')}
                            required
                            error={mountErrors('userName')}
                        />
                        <PPInput
                            type='text'
                            title="E-mail"
                            value={user?.email}
                            name='email'
                            onChange={(e) => onChange(e, 'email')}
                            required
                            error={mountErrors('email')}
                        />
                        <PPInput
                            type='text'
                            title="Telefone"
                            value={user?.phoneNumber}
                            name='phoneNumber'
                            onChange={(e) => onChange(e, 'phoneNumber')}
                            error={mountErrors('phoneNumber')}
                        />
                        <PPDropDown
                            title='Idioma padrão'
                            name='idiomaPadrao'
                            value={idiomasOptions.find(i => i.value == user?.idiomaPadrao)}
                            options={idiomasOptions}
                            onChange={(o) => onChange(o, 'idiomaPadrao')}
                            required
                            error={mountErrors('idiomaPadrao')}
                        />
                        {
                            (actualUser && actualUser.email && (actualUser.email.includes('@processor.com.br') || actualUser.email.includes('@gotobiz.com.br'))) &&
                            <>
                                <PPDropDown
                                    isMulti
                                    title='Cliente'
                                    name='client'
                                    value={clientList.filter(c => c.selected)}
                                    options={clientList.sort((a, b) => { return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1 })}
                                    onChange={(o) => onChange(o, 'client')}
                                    required
                                    error={mountErrors('client')}
                                />
                                <PPDropDown
                                    title='Cliente padrão'
                                    name='clientDefault'
                                    value={clientDefaultList.find(c => c.value == user?.clientDefault)}
                                    options={clientDefaultList.sort((a, b) => { return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1 })}
                                    onChange={(o) => onChange(o, 'clientDefault')}
                                    required
                                    error={mountErrors('clientDefault')}
                                />
                                <PPDropDown
                                    isMulti
                                    title='Perfis'
                                    name='profiles'
                                    value={profiles.filter(c => c.selected)}
                                    options={profiles.sort((a, b) => { return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1 })}
                                    onChange={(o) => onChange(o, 'profiles')}
                                    required
                                    error={mountErrors('profiles')}
                                />
                                <PPCheckBox
                                    title='Administrador'
                                    name='isAdministrator'
                                    onChange={(e) => e.target.value !== user?.isAdministrator ? onChange(e, 'isAdministrator') : null}
                                    checked={user?.isAdministrator && user?.isAdministrator === true}
                                />
                                <PPCheckBox
                                    title='Multiorganizacional'
                                    name='isMultiOrganization'
                                    onChange={(e) => e.target.value !== user?.isMultiOrganization ? onChange(e, 'isMultiOrganization') : null}
                                    checked={user?.isMultiOrganization && user?.isMultiOrganization === true}
                                />
                            </>
                        }
                        <PPCheckBox
                            title='Ativo'
                            name='active'
                            onChange={(e) => e.target.value !== user?.active ? onChange(e, 'active') : null}
                            checked={user?.active && user?.active === true}
                        />

                    </div>
                    <div className="commands">
                        <button className='pp-button bg-info' type='button' onClick={() => onSubmit()}>Salvar</button>
                        <button className='pp-button bg-grey' type='button' onClick={() => props.onClose()}>Cancelar</button>
                    </div>
                </div>
                :
                <table className='pp-table'>
                    {
                        user &&
                        <tbody>
                            <tr><td className='dark'>Nome</td><td> {user.name}</td></tr>
                            <tr><td className='dark'>Login</td><td> {user.userName}</td></tr>
                            <tr><td className='dark'>E-mail</td><td> {user.email}</td></tr>
                            <tr><td className='dark'>Telefone</td><td> {user.phoneNumber}</td></tr>
                            <tr><td className='dark'>Idioma padrão</td><td> {user.idiomaPadrao}</td></tr>

                            <tr><td className='dark'>Clientes</td>
                                <td>
                                    <div className="scrollable-v" style={{ minHeight: '23px', maxHeight: '100px' }}>
                                        {clientList.filter(c => c.selected).map((d, i) => { return <p key={i}>• {d.label}</p> })}
                                    </div>
                                </td>
                            </tr>
                            <tr><td className='dark'>Cliente padrão</td><td> {clientList.find(c => c.value == user.clientDefault)?.label || 'nd'}</td></tr>
                            <tr><td className='dark'>Perfis</td>
                                <td>
                                    <div className="scrollable-v" style={{ minHeight: '23px', maxHeight: '100px' }}>
                                        {profiles.filter(c => c.selected).map((d, i) => { return <p key={i}>• {d.label}</p> })}
                                    </div>
                                </td>
                            </tr>
                            <tr><td className='dark'>Administrador</td><td> <i className={`ppi ppi-${user.isAdministrator ? 'check text-success' : 'x text-danger'} ppi-3x`} /></td></tr>
                            <tr><td className='dark'>Multiorganizacional</td><td><i className={`ppi ppi-${user.isMultiOrganization ? 'check text-success' : 'x text-danger'} ppi-3x`} /> </td></tr>
                            <tr><td className='dark'>Ativo</td><td><i className={`ppi ppi-${user.active ? 'check text-success' : 'x text-danger'} ppi-3x`} /></td></tr>
                        </tbody>
                    }
                </table>
        }
    </PPModal>;
}

export default Form;