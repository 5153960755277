import api, { APIClient } from "./api";
import { SaaSGroup } from "../pages/SaasGroup/Model/GroupSaaS";
import { PPFormInput } from "processor-plataform-ui/dist/components/PPForm2";
import { FormFields, Option } from "../components/Form/PPFormModal";
interface SaaSGroupState {
    data: {
        EnumDomains: any[];
        EnumVendorCredentials: any[];
        filterGridDefault: {
            start: number;
            size: number;
            filter: string;
            ordered: string;
            direction: string;
        };
    };
}export class SaaSGroupService extends APIClient {

    state: SaaSGroupState = {
        data: {
            EnumDomains: [],
            EnumVendorCredentials: [],
            filterGridDefault: { start: 0, size: 15, filter: '', ordered: 'DisplayName', direction: 'asc' }
        }
    };


    constructor(props: any) {
        super(props);
        this.initialize();
    }

    async initialize() {
        const stateData = { ...this.state };
        stateData.data.filterGridDefault = { start: 0, size: 15, filter: '', ordered: 'DisplayName', direction: 'asc  ' };
        await Promise.all([this.RetrieveDomains(), this.RetrieveVendorCredentials()]);
    }

    title = 'Grupo SaaS';
    async Delete(id: string, type: number) {

        const url = `${this.endpoint}/api/SaaSGrupos/Delete?id=${id}&typeGroup=${type}`;
        var response = api({
            method: 'delete',
            url
        });
        return response;
    }

    async RetrieveList(filterDataFilter: any) {
        let filterData = filterDataFilter;
        if (filterDataFilter == null || filterDataFilter == undefined) {
            filterData = this.state.data.filterGridDefault;
        }

        var filter = `?start=${filterData.start}&size=50&filter=${filterData.filter}&ordered=${filterData.ordered}&direction=${filterData.direction}`;

        const url = `${this.endpoint}/api/SaaSGrupos/RetrieveList${filter}`;
        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    async Retrieve(id: number) {
        const url = `${this.endpoint}/api/SaaSGrupos/Retrieve?id=${id}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false,
        });
        return response;
    }

    async Save(item: any) {
        if (item.id && item.id.length > 0) {
            await this.Update(item);
        } else {

            await this.Create(item);
        }
    }

    async Create(item: any) {
        const url = `${this.endpoint}/api/SaaSGrupos/Create`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;
    }

    async Update(item: any) {
        const url = `${this.endpoint}/api/SaaSGrupos/Update`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;
    }

    public emptyRecord: SaaSGroup = {
        id: "",
        canCreate: true,
        canSetMembers: true,
        onPremisesDomainName: "",
        groupTypes: [],
        displayName: "",
        mailNickname: "",
        mail: "",
        description: "",
        mailEnabled: true,
        securityEnabled: true,
        onPremisesSyncEnabled: true
    };

    grouptypes = [{ "label": "Office 365", "value": 1 }, { "label": "Segurança", "value": 2 }, { "label": "E-mail ativo de segurança", "value": 3 }, { "label": "Grupo de distribuição", "value": 4 }];
    public getDomains() {
        return this.state.data.EnumDomains;
    }
    public getFilter() {
        return this.state.data.filterGridDefault;
    }
    public getVendorCredentials() {
        return this.state.data.EnumVendorCredentials;
    }

    public async template(disabled: boolean) {
        let item = {
            title: 'displayName',
            Fields: [
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'displayName',
                    type: 'text',
                    title: 'Nome do grupo',
                    required: {
                        value: true,
                        required: 'Nome do grupo é obrigatório'
                    },
                    disabled: disabled,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    }
                },
                {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'vendorCredentialItem',
                    type: 'select',
                    title: 'Assinatura',
                    required: {

                        value: true,
                        required: 'Assinatura é obrigatório'
                    },
                    disabled: disabled,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    },
                    options: this.state.data.EnumVendorCredentials != null ? this.state.data.EnumVendorCredentials : []
                },
                {
                    edit: false,
                    row: 2,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'mailNickname',
                    type: 'text',
                    title: 'E-mail',
                    required: {

                        value: true,
                        required: 'E-mail é obrigatório',
                        pattern: {
                            value: /^\S+$/,
                            message: 'Espaços em branco não serão aceitos no E-mail'
                        }
                    },
                    disabled: disabled,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    }
                }, {
                    edit: false,
                    row: 2,
                    col: 2,
                    colspan: 1,
                    rowspan: 1,
                    name: 'domain',
                    type: 'select',
                    title: 'Domínio',
                    required: {
                        value: true,
                        required: 'Dominio é obrigatório'
                    },
                    disabled: disabled,
                    options: this.state.data.EnumDomains != null ? this.state.data.EnumDomains : []
                },
                {
                    edit: true,
                    row: 3,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'type',
                    type: 'select',
                    position: 'top',
                    title: 'Tipo',
                    required: {
                        value: true,
                        required: 'Tipo é obrigatório',
                        pattern: /^[1-9]\d*$/
                    },
                    disabled: disabled,
                    options: this.grouptypes
                },
                {
                    edit: true,
                    row: 3,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'description',
                    type: 'textArea',
                    title: 'Descrição',
                    //width: '400px',
                    disabled: disabled
                }
            ]
        };

        return item;
    }
    public async template2(data?: SaaSGroup) {
        let item: PPFormInput[] = [
            {
                name: 'displayName',
                type: 'text',
                title: 'Nome do grupo',
                required: true,
                value: data && data.displayName ? data.displayName : undefined,
                disabled: data != undefined,
            },
            {
                name: 'vendorCredentialItem',
                type: 'dropdown',
                title: 'Assinatura',
                required: true,
                options: this.state.data.EnumVendorCredentials != null ? this.state.data.EnumVendorCredentials : [],
                value: data == undefined ?
                    this.state.data.EnumVendorCredentials && this.state.data.EnumVendorCredentials[0].value != null ?
                        this.state.data.EnumVendorCredentials[0].value
                        :
                        undefined
                    :
                    undefined,
                hidden: data != undefined,
                disabled: data != undefined,
            },
            {
                name: 'mailNickname',
                type: 'text',
                title: 'E-mail',
                required: true,
                regex: {
                    exp: /^\S+$/,
                    help: 'Espaços em branco não serão aceitos no E-mail'
                },
                // width: data != undefined ? '100%' : '50%',
                value: data && data.mailNickname ? data.mailNickname : undefined,
                disabled: data != undefined,
            },
            {
                hidden: data != undefined,
                name: 'domain',
                type: 'dropdown',
                title: 'Dominio',
                required: true,
                options: this.state.data.EnumDomains != null ? this.state.data.EnumDomains : [],
                value: data == undefined ?
                    this.state.data.EnumDomains && this.state.data.EnumDomains[0].value != null ?
                        this.state.data.EnumDomains[0].value
                        :
                        undefined
                    :
                    undefined,
                // width: '50%',
                disabled: data != undefined,
            },
            {
                name: 'type',
                type: 'dropdown',                
                title: 'Tipo',
                
                regex: {
                    exp: /^[1-9]\d*$/,
                    help: 'Tipo deve ser um digito'
                },
                options: this.grouptypes,
                value: data && data.type ? data.type : undefined,
                disabled: data != undefined,
            },
            {
                name: 'description',
                type: 'textarea',
                title: 'Descrição',
                // width: '400px',
                rows: 3,
                value: data && data.description ? data.description : undefined,
                disabled: data != undefined,
            }
        ];

        return item;
    }

    public async templateEdit(disabled: boolean) {


        let item = {
            title: 'displayName',
            Fields: [
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'displayName',
                    type: 'detailtext',
                    title: 'Nome do grupo',
                    required: {

                        value: true,
                        required: 'Nome do grupo é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    }
                },
                {
                    edit: true,
                    row: 3,
                    col: 1,
                    colspan: 2,
                    rowspan: 1,
                    name: 'mailNickname',
                    type: 'detailtext',
                    title: 'E-mail',
                    required: {

                        value: true,
                        required: 'E-mail é obrigatório',
                        pattern: {
                            value: /^\S+$/,
                            message: 'Espaços em branco não serão aceitos no E-mail'
                        }
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    }
                },
                {
                    edit: true,
                    row: 4,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'description',
                    type: 'detailtextArea',
                    title: 'Descrição',
                    // width: '400px',
                    required: {

                        value: false
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0) {
                            return true;
                        }
                        return false;
                    }
                },
                {
                    edit: true,
                    row: 2,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'type',
                    type: 'select',
                    title: 'Tipo',
                    position: 'top',
                    required: {

                        value: true,
                        required: 'Tipo é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: any) {
                        if (val && val > 0) {
                            return true;
                        }
                        return false;
                    },
                    options: this.grouptypes
                }]
        };

        return item;
    }

    public getFormFieldsData = (details: boolean = false): FormFields => {
        return {
            id: "saasgroups",
            fieldgroups: [
                {
                    label: "Grupo",
                    accordion: false,
                    fields: [
                        {
                            type: "text",
                            name: 'displayName',
                            label: 'Nome do grupo',
                            required: true,
                            hidden: false,
                            disabled: false,
                            validation: {
                                regex: /^(.{1,100})$/,
                                regexMsg: "Nome do grupo deve ter no máximo 100 caracteres"
                            },
                            tooltip: "", 
                            blockEdit: details 
                        },
                        {
                            type: "dropdown",
                            name: 'vendorCredentialItem',
                            label: 'Assinatura',
                            required: true,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            options: this.state.data.EnumVendorCredentials as Option[], 
                            blockEdit: true
                        },
                        {
                            type: "text",
                            name: 'mail',
                            label: 'E-mail',
                            required: true,
                            hidden: false,
                            disabled: false,
                            validation: {
                                regex: /^\S+$/,
                                regexMsg: "Espaços em branco não serão aceitos no E-mail"
                            },
                            tooltip: "", 
                            blockEdit: details
                        },
                        {
                            type: "dropdown",
                            name: "domain",
                            label: "Domínio",
                            required: true,
                            hidden: false,
                            disabled: false,
                            options: this.state.data.EnumDomains as Option[],
                            tooltip: "",
                            validation: {
                                regex: /^(.{1,100})$/,
                                regexMsg: "Domínio deve ter no máximo 100 caracteres"
                            }, 
                            blockEdit: true
                        },
                        {
                            type: "dropdown",
                            name: 'type',
                            label: 'Tipo',
                            position:'top',
                            required: true,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            options: this.grouptypes as Option[],
                            // width: 400,
                            blockEdit: details

                        },
                        {
                            type: "textarea",
                            name: "description",
                            label: "Descrição",
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            // width: '400px',
                            blockEdit: details
                        }
                    ]
                }
            ]
        }
    }

    async RetrieveDomains() {


        if (this.state.data.EnumDomains && this.state.data.EnumDomains.length > 0)
            return

        const url = `${this.endpoint}/api/LCTech/GetDomains`;

        let response = await api({
            method: 'get',
            url
        });

        if (response != null && response.data) {
            const stateData = { ...this.state };
            stateData.data.EnumDomains = response.data;
            // this.setState({ stateData });
        }
    }

    async RetrieveVendorCredentials() {
        if (this.state.data.EnumVendorCredentials && this.state.data.EnumVendorCredentials.length > 0)
            return
        const url = `${this.endpoint}/api/SaaSGrupos/GetVendorCredentials`;

        let response = await api({
            method: 'get',
            url
        });
        if (response != null && response.data) {
            const stateData = { ...this.state };
            stateData.data.EnumVendorCredentials = response.data;
            // this.setState(stateData);
        }
    }
    ActionsBaseFunctions(createAction: any, CloseActions: any, DeleteActions: any) {
        let arrayActions = {
            functionsEdit: [] as ButtonEventGrid[]
        };

        if (createAction) {
            arrayActions.functionsEdit.push({
                id: "c51c9fc0-baba-4d86-b55a-b8c70d9ac7bc",
                title: 'Salvar',
                tooltip: 'Salvar',
                icon: 'lci lci-check',
                func: (item: any) => {
                    createAction(item);
                },
                disabled: false,
                skipvalidation: false
            });
        }

        if (CloseActions) {
            arrayActions.functionsEdit.push({
                id: "f7131360-333e-4851-bd65-a39b9d9dc839",
                title: 'Fechar',
                icon: 'ppi ppi-arrow-left',
                tooltip: 'Voltar',
                func: () => {
                    if (CloseActions) {
                        CloseActions();
                    }
                },
                disabled: false,
                skipvalidation: true
            });
        }

        return arrayActions;
    }

}

export interface ButtonEventGrid {

    id?: string;
    title: string;
    tooltip?: string;
    icon?: string;
    func: any;
    disabled: boolean;
    skipvalidation: boolean;
}
