import React, { useEffect, useState } from "react";
import { RetencaoService } from '../../../../services/retencao/retencaoService';
import LcAccordion from "../../../Generic/LcAccordion";
import LcIconLink from "../../../Generic/LcIconLink";
import HeyHoNewRequest from "../../../Generic/HeyHoNewRequest";
import { useSnackbar } from "notistack";
import { IoDocumentOutline } from "react-icons/io5";
import { FaRegFolderOpen } from "react-icons/fa";
import { HiOutlineServer } from "react-icons/hi";
interface Props {
    model: any,
}

interface RetentionFileDateil {
    Created: string;
    LastModified: string;
    Length: number;
    Name: string;
    RehydrationStatus: any;
    StandardBlobTier: string;
}
interface RetentionFile {
    ContainerName: string;
    Name: string;
    Prefix: string;
    Type: string;
    cloudBlockList: any;
    details: RetentionFileDateil
}

const TreeView: React.FC<Props> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [showMoreIsLoadingItem, setShowMoreIsLoadingItem] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openModalNewRequest, setOpenModalNewRequest] = useState<boolean>(false)
    const [submittingNewRequest, setSubmittingNewRequest] = useState<boolean>(true)
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
    });

    function isContainer() {
        return props.model.hasOwnProperty('Type') == false && props.model.hasOwnProperty('Length') == false;
    };
    function isFolder() {
        return props.model.Type == 'BlobHierarchyItem';
    };
    function isFile() {
        return props.model.Type == 'BlobItem';
    };
    // function isFileDetails() {
    //     if (!isContainer() && !isFolder() && !isFile()) {
    //         return true;
    //     }
    //     return false;
    // };
    function toggle() {
        if (isFile()) {
            setOpen(!open);
        }
        if (props.model.cloudBlockList.children.length === 0 && (isFolder() || isContainer())) {
            setOpen(!open);
            showMoreItems();
        }

        if (props.model.cloudBlockList.children.length > 0 && (isFolder() || isContainer())) {
            setOpen(!open);
        }
    };
    function isAnything() {
        if (isFolder() || isContainer() || isFile()) {
            return true
        }
        else {
            return false;
        }
    };
    function isContainerOrFolder() {
        if (isFolder() || isContainer()) {
            return true
        }
        else {
            return false;
        }
    };
    function showButtonShowMore() {
        if (showMoreIsLoadingItem === true) {
            return false;
        }

        if (props.model.cloudBlockList.blobContinuationToken === null) {
            return false;
        }
        if ((props.model.cloudBlockList.children.length % 10 ) !== 0) {
            return false;
        }

        return true;
    };
    function showMoreItems() {

        var currentItemList = props.model.cloudBlockList;

        var containerName = "";
        var prefix = "";
        var nextMarker = null;

        if (props.model.cloudBlockList.children.length > 0 && isFolder()) {
            prefix = currentItemList.children[0].Prefix;
        } else if (props.model.cloudBlockList.children.length === 0 && isFolder()) {
            prefix = props.model.Name;
        }

        if (currentItemList.blobContinuationToken != null) {
            nextMarker = currentItemList.blobContinuationToken;
        }

        if (props.model.ContainerName == undefined) {
            containerName = props.model.Name;
        } else {
            containerName = props.model.ContainerName;
        }

        setShowMoreIsLoadingItem(true);
        setIsLoading(true);

        const retencaoService = new RetencaoService(props);
        retencaoService.GetTreeWithToken(containerName, prefix, nextMarker)
            .then((response: any) => {
                props.model.cloudBlockList.blobContinuationToken = response.data.blobContinuationToken;
                if( props.model.cloudBlockList.children) 
                props.model.cloudBlockList.children =  response.data.children

                // response.data.children.forEach(function (item: any) {
                //     console.log(item)
                //     props.model.cloudBlockList.children.push(item);
                // });
                setShowMoreIsLoadingItem(false);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const handlereHydrateFile = (file: RetentionFile) => {
        if(file.details.StandardBlobTier === 'Archive') {
            enqueueSnackbar(`Para baixar o arquivo ${file.details.Name} será preciso abrir um chamado.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            });
            setOpenModalNewRequest(true);
            setFormData({
                title: `Solicitação de download de Arquivo: ${file.details.Name}`,
                description: `Solicito o download do Arquivo: ${file.details.Name}.\nlocalizado na pasta ${file.ContainerName}`
            })
        } else if(file.details.StandardBlobTier === 'Hot' || file.details.StandardBlobTier === 'Cool' ) {
            window.open(`/Retencao/DownloadBlob?containerName=${file.ContainerName}&prefix=${file.Prefix}&blobName=${file.Name}`, '_blank')
        }
    }

    useEffect(() => {
        if(!submittingNewRequest) {

            enqueueSnackbar(`Em até 15 horas o seu arquivo estará disponível`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            });
            setSubmittingNewRequest(true);
        }
    },[submittingNewRequest])

    return (
        <>
            <div className="treeView">
                <div style={{display:'flex',}}>

                    {/* <i className={isFile() ? "lci lci-document" : isFolder() ? "lci lci-folder" : isContainer() ? "lci lci-server" : ""}></i> */}

                    {isAnything() &&
                    <div onClick={toggle} className="row" style={{ alignItems: 'baseline',}}>
                        <LcAccordion 
                            title={props.model.Name} 
                            visible={open ||   props.model.cloudBlockList === null} 
                            customIcon={isFile() ?<IoDocumentOutline /> : isFolder() ?<FaRegFolderOpen />: <HiOutlineServer />} 
                            >
                            {
                                props.model.cloudBlockList === null &&
                                    <div className=" mb-1 ml-7" >
                                        <p className="my-10"><b>Tamanho em Bytes: </b> {props.model.details.Length}</p>
                                        <p className="my-10"><b>Última modificação: </b> {props.model.details.LastModified}</p>
                                        <p className="my-10"></p>
                                    </div>
                            }
                        </LcAccordion>
                            {isFile() &&
                                <>
                                    {props.model.details.StandardBlobTier === "Hot" || props.model.details.StandardBlobTier === "Cool" &&
                                        <LcIconLink color="e64646" icon={'lci lci-download'} tooltipPosition="left" tooltip="Download" onClick={() => handlereHydrateFile(props.model) } />
                                    }
                                </>
                            }
        
                            {isFile() &&
                                <>
                                    {props.model.details.RehydrationStatus === null && props.model.details.StandardBlobTier === "Archive" &&
                                        <LcIconLink icon={'lci lci-download'} tooltipPosition="left" tooltip="Download" onClick={() => handlereHydrateFile(props.model) } />
                                    }
                                </>
                            }
        
                            {isFile() &&
                                <>
                                    {props.model.details.RehydrationStatus === 1 && props.model.details.StandardBlobTier === "Archive" &&
                                        <LcIconLink  color="ffb800" icon={'lci lci-download'} tooltipPosition="left" tooltip="Download" onClick={() => handlereHydrateFile(props.model) } />
                                        
                                    }
                                </>
                            }
                    </div>
                    }


                </div>
                {isContainerOrFolder() && open &&
                    <ul>
                        {props.model.cloudBlockList.children.map((Item: any, index: number) => {
                            return <TreeView key={index} model={Item}></TreeView>
                        })
                        }
                        {showButtonShowMore() &&
                            <li style={{ listStyleType: 'none' }}>
                                <a href="#" onClick={showMoreItems}>Mostrar Mais</a>
                            </li>
                        }
                        {isLoading &&
                            <li style={{ listStyleType: 'none' }}>
                                <span>Carregando...</span>
                            </li>
                        }
                    </ul>
                }

                {isFile() && open &&
                    <ul>
                        <TreeView model={props.model.details}></TreeView>
                    </ul>
                }
            </div>
            <HeyHoNewRequest openModal={openModalNewRequest} formData={formData}  onClose={setOpenModalNewRequest} submittingRequest={setSubmittingNewRequest}/>
        </>

    )
}

export default TreeView;