import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

//Serviços
import { ResourceSipContractService } from '../../../services/resourceSipContractProcessor/resourceSipContractService';
import { SaasService } from '../../../services/saas/saasService';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

//Componentes
import LcLoading from '../../../components/Generic/LcLoading';
import { SelectField } from '../../../components/Form/Input';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { CostCenterService } from '../../../services/costCenter/costCenterService';

const InsightsAdmin: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    const saasService = new SaasService(props);
    const sipService = new ResourceSipContractService(props);

    const [loading, setLoading] = useState<boolean>(false);
    const [dashData, setDashData] = useState<{
        embedUrl: string
        requestId: string
        status: number
    } | undefined>();

    const [contracts, setContracts] = useState<any[]>();
    const [selectedContractId, setSelectedContractId] = useState<string>();

    const gtmId = 17;

    useEffect(() => {
        getContracts()
    }, [user.ClientGroupSelected]);

    const getContracts = async () => {
        setLoading(true);
        const costCenterService = new CostCenterService(props);
        var response = await costCenterService.GetUrl();

        sipService.GetContractsByGtmId(gtmId, response.data)
            .then(response => {
                setContracts(response);
                response && response.length > 0 && setSelectedContractId(response[0].value);
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (selectedContractId) {
            setLoading(true);
            setDashData(undefined);

            saasService.GetAWSDashboardEmbedUrl('060bec42-3e4b-4963-b34f-affc50c59238')
                .then(response => {
                    setDashData(response.data);
                })
                .catch(console.warn)
                .then(() => {
                    setLoading(false);
                })
        }
    }, [selectedContractId])

    useEffect(() => {
        setLoading(true);
        try {
            document.getElementsByClassName("quicksight-embedding-iframe") &&
                document.getElementsByClassName("quicksight-embedding-iframe")[0].remove();
        } catch (error) {
        }

        if (dashData) {
            var options = {
                url: dashData.embedUrl,
                container: document.getElementById("embeddingInsights"),
                parameters: {
                    ClientGroupId: user.ClientGroupSelected,
                    Contrato: selectedContractId
                },
                scrolling: "no",
                width: "100%",
                height: `${window.innerHeight - 124}px`,
                locale: "pt-BR",
                footerPaddingEnabled: true
            };
            embedDashboard(options);
            setLoading(false);
        }

    }, [dashData])

    const [sidevisible, setSideVisible] = useState<boolean>(false);

    return (
        <Layout
            pageTitle="Insight Admin"
            functionsGeneric={[{ icon: "lci lci-cog", onClick: () => { setSideVisible(!sidevisible) }, tooltip: "Escolher contrato" }]}
        >

            <div className="centralized">
                <div className={`embed-insights${loading ? ' hide' : ''}`} id="embeddingInsights" />
            </div>

            <LcLoading loading={loading} label="Carregando..." >
                {
                    !dashData &&
                    <div className="centralized">
                        <span><i className="lci lci-exclamation lci-3x text-warning" /> Não foi possível carregar o dashboard.</span>
                    </div>
                }
            </LcLoading>

            <SideModal header="Contrato" visible={sidevisible} onClose={() => { setSideVisible(!sidevisible) }} >
                <SelectField name="contracts" options={contracts} value={selectedContractId} onChange={event => {
                    setSelectedContractId(event.value);
                }}
                />
            </SideModal>
        </Layout>
    );
}

export default InsightsAdmin;