import React, { useState } from 'react';
import accessProfileService from '../../../services/accessProfile/accessProfile';
import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { InputField } from '../../../components/Form/Input';
import LcCheckBox from '../../../components/Form/LcCheckBox';

import { useSnackbar } from 'notistack';

interface AccessProfile {
    id?: number,
    clientGroupId?: number,
    nome?: string,
    ativo?: boolean,
    deletado?: boolean,
    nomeGrupoAD?: string,
    grupoDoAD?: boolean,
    syncNoAD?: boolean,
    isTemplate?: boolean
    GTMItemId?: number
    GTMId?: number
    GTMMetric?: number
}

const CreateProfile: React.FC<any> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [profile, setProfile] = useState<AccessProfile | undefined>({});
    const [syncAD, setSyncAD] = useState<boolean>(false);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);

    function handleChange(event: any) {
        const { name, value } = event;
        let _profile: any = profile;
        _profile[name] = value;
        setProfile(_profile);
    };

    function handleChangeCheckbox(event: any) {
        const { name, checked } = event;
        let _profile: any = profile;
        _profile[name] = checked;
        setProfile(_profile);
        name == 'isTemplate' ? setIsTemplate(checked) : setSyncAD(checked);

    };

    function createProfile(item: any) {

        let aux: AccessProfile = {
            id: profile && profile.id,
            clientGroupId: user.ClientGroupSelected,
            nome: profile && profile.nome,
            ativo: true,
            deletado: false,
            nomeGrupoAD: profile && profile.nomeGrupoAD,
            grupoDoAD: profile && profile.syncNoAD,
            syncNoAD: profile && profile.syncNoAD,
            isTemplate: isTemplate,
            GTMItemId: profile && profile.GTMItemId,
            GTMId: profile && profile.GTMId,
            GTMMetric: profile && profile.GTMMetric
        };
        const service = new accessProfileService(props);

        service.CreateProfile(aux)
            .then(response => {
                enqueueSnackbar("Cadastro de perfil criado.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
                props.close();
            })
            .catch(error => {
                console.log(error);
                enqueueSnackbar("O ocorreu um erro ao criar o perfil.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            });
    };

    return (
        <div className="form">
            <InputField name="nome" value={profile?.nome} type="text" label="Nome" onChange={handleChange} />
            {
                Number(user.ClientGroupSelected) == 492 &&
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <InputField width='95%' name="GTMId" value={profile?.GTMId} type="number" label="GTM" onChange={handleChange} />
                    <InputField width='95%' name="GTMItemId" value={profile?.GTMItemId} type="number" label="GTMItem" onChange={handleChange} />
                    <InputField width='100%' name="GTMMetric" value={profile?.GTMMetric} type="number" label="Metrica" onChange={handleChange} />
                </div>

            }
            <LcCheckBox checked={isTemplate} name="isTemplate" title="Template?" onChange={handleChangeCheckbox} />
            <div className="space-between">
                <button className="lc-button bg-info" onClick={createProfile}>Salvar</button>
                <button className="lc-button bg-grey" onClick={() => { props.close(); }}>Cancelar</button>
            </div>
        </div>
    )
}
export default CreateProfile;
