import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';

import AccessProfile, { MenuItemInterface } from '../../../services/accessProfile/accessProfile';
import { NotificationCenterService } from '../../../services/notificationCenter/notificationService';

import './index.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import LcIconLink from '../../../components/Generic/LcIconLink';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import { LinearProgress } from '@material-ui/core';
import Confirmation from '../../../components/Dialog/Confirmation';
import { PPTextField, PPModal } from 'processor-plataform-ui';
import PPExpand from '../../../components/Generic/PPExpand';

const Menu: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    const notification = new NotificationCenterService(props);

    const accessProfile = new AccessProfile(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
    const [menus, setMenus] = useState<any[]>([]);
    const [itemSelected, setItemSelected] = useState<MenuItemInterface>(accessProfile.GetEmptyRecord());
    const [parent, setParent] = useState<any>();
    const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<MenuItemInterface>(accessProfile.GetEmptyRecord());


    const LoadMenu = () => {
        accessProfile.GetClientGroupPerfilMenu(user.ClientGroupSelected || 0)
            .then(response => {
                setMenus(response.data);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(response => {
                setLoading(false);
            })
    }
    useEffect(LoadMenu, [])

    const handleSubmitEdit = (event: any) => {
        event.preventDefault();


        let _item = Object.assign(itemSelected);

        _item = {
            ..._item,
            name: event.target['name'].value,
            icon: event.target['icon'].value,
            route: event.target['route'].value,
            extraData: event.target['extraData'].value,
            url: event.target['url'].value,
            isPublic: event.target['isPublic'].value == 'on',
            isShopCart: event.target['isShopCart'].value == 'on',
            menuOrder: event.target['menuOrder'].value,
        }

        EditMenu(_item);
    }
    const EditMenu = (menuItem: any) => {
        setIsLoadingEdit(true);
        notification.PostInterfaceNotification(dispatch, {
            subject: "Informação",
            content: `O menu ${menuItem?.name} será processado em segundo plano`,
            context: "info",
        });
        accessProfile.UpdateMenuItem(menuItem)
            .then(response => {
                setItemSelected(accessProfile.GetEmptyRecord());
                setIsLoadingEdit(false);
                notification.PostInterfaceNotification(dispatch, {
                    subject: "Sucesso",
                    content: `Menu atualizado com sucesso`,
                    context: "success",
                });
                LoadMenu();
            })
            .catch(error => {
                let errorMessage = JSON.parse(error.response.data.split('|')[1]);
                console.warn(errorMessage.message);
                notification.PostInterfaceNotification(dispatch, {
                    subject: "Erro",
                    content: `Falha ao atualizar menu.` + `Motivo: ${errorMessage.message}`,
                    context: "danger",
                });
                setIsLoadingEdit(false);
            })
    }

    const handleSubmitCreate = (event: any) => {
        event.preventDefault();

        let _item = {
            idParent: parent.id,
            after: parent.id,
            name: event.target['name'].value,
            icon: event.target['icon'].value,
            route: event.target['route'].value,
            url: event.target['url'].value,
            extraData: event.target['extraData'].value,
            isPublic: event.target['isPublic'].value == 'on',
            isShopCart: event.target['isShopCart'].value == 'on',
            menuOrder: event.target['menuOrder'].value,
        }

        event.target.reset()
        CreateMenu(_item);
    }
    const CreateMenu = (menuItem: any) => {
        setIsLoadingCreate(true);
        notification.PostInterfaceNotification(dispatch, {
            subject: "Informação",
            content: `O menu ${menuItem?.name} será processado em segundo plano`,
            context: "info",
        });
        accessProfile.AddMenuItem(menuItem)
            .then(response => {
                setItemSelected(accessProfile.GetEmptyRecord());
                setIsLoadingCreate(false);
                notification.PostInterfaceNotification(dispatch, {
                    subject: "Sucesso",
                    content: `Menu criado com sucesso`,
                    context: "success",
                });
                LoadMenu();
            })
            .catch(error => {
                let errorMessage = JSON.parse(error.response.data.split('|')[1]);
                console.warn(errorMessage.message);
                notification.PostInterfaceNotification(dispatch, {
                    subject: "Erro",
                    content: `Falha ao criar menu.` + `Motivo: ${errorMessage.message}`,
                    context: "danger",
                });
                setIsLoadingCreate(false);
            })
            .then(() => {
                setModalCreateVisible(false);
            })
    }

    const DeleteMenu = (menuItem) => {

        notification.PostInterfaceNotification(dispatch, {
            subject: "Informação",
            content: `O menu ${itemToDelete?.name} será processado em segundo plano`,
            context: "info",
        });

        accessProfile.DeleteMenuItem(menuItem.id).then(response => {
            setShowDialogDelete(false);
            notification.PostInterfaceNotification(dispatch, {
                subject: "Sucesso",
                content: `Menu deletado com sucesso`,
                context: "success",
            });
            LoadMenu();
        }).catch(error => {
            let errorMessage = JSON.parse(error.response.data.split('|')[1]);
            console.warn(errorMessage.message);
            notification.PostInterfaceNotification(dispatch, {
                subject: "Erro",
                content: `Falha ao deletar menu.` + `Motivo: ${errorMessage.message}`,
                context: "danger",
            });
            setShowDialogDelete(false);
        })
    }

    const ShowDialog = (menuitem) => {
        setItemToDelete(menuitem)
        setShowDialogDelete(!showDialogDelete)
    }

    const onChange = (field) => {
        const { name, value } = field.target;
        let item = { ...itemSelected }
        item[name] = value;
        setItemSelected({ ...item });
    }

    const [expanded, setExpanded] = useState(false);

    return (
        <Layout
            cardType
            loading={loading}
            pageTitle='Menus'
            dots={<>
                <div style={{ color: expanded == true ? "#abaeb9" : "#000" }} className={`item${expanded == true ? ' disabled' : ""}`} onClick={() => setExpanded(true)}><i className='ppi ppi-chevron-double-down' /> Expandir tudo</div>
                <div style={{ color: expanded == false ? "#abaeb9" : "#000" }} className={`item${expanded == false ? ' disabled' : ""}`} onClick={() => setExpanded(false)}><i className='ppi ppi-chevron-double-up' /> Recolher tudo</div>
            </>}
        >

            <Confirmation
                title='Exclusão de menu'
                textBtnCancel='Não'
                textBtnOk='Sim'
                display={showDialogDelete}
                text={`Deseja realmente excluir o menu ${itemToDelete?.name}?  Esta ação não poderá ser desfeita.`}
                confirm={() => { DeleteMenu(itemToDelete) }}
                close={() => { setShowDialogDelete(false) }}
            />

            <PPModal visible={!accessProfile.IsNull(itemSelected)} title="Editar menu" onClose={() => { setItemSelected(accessProfile.GetEmptyRecord()) }} >
                <form onSubmit={handleSubmitEdit} >
                    <PPTextField type="text" required name="name" label="Nome" value={itemSelected.name} onChange={onChange} />
                    <PPTextField type="text" required name="icon" label="Ícone" value={itemSelected.icon} onChange={onChange} />
                    <PPTextField type="text" required name="route" label="Rota" value={itemSelected.route} onChange={onChange} />
                    <PPTextField type="text" required name="extraData" label="Dado extra" value={itemSelected.extraData} onChange={onChange} />
                    <PPTextField type="text" name="url" label="link" value={itemSelected.url} onChange={onChange} />
                    <PPTextField type="number" required name="menuOrder" label="Ordem" value={itemSelected.menuOrder} onChange={onChange} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LcCheckBox checked={itemSelected.isPublic} title="Público" name="isPublic" />
                        <LcCheckBox checked={itemSelected.isShopCart} title="Para comprar" name="isShopCart" />
                    </div>
                    <div className="row between">
                        <button className="lc-button bg-info" type="submit">{isLoadingEdit ? <LinearProgress /> : 'Salvar'}</button>
                        <button className="lc-button bg-grey" type="reset" onClick={() => setItemSelected(accessProfile.GetEmptyRecord())}>Fechar</button>
                    </div>
                </form>
            </PPModal>

            <PPModal visible={modalCreateVisible} title="Criar item" onClose={() => { setModalCreateVisible(false) }}>
                <form onSubmit={handleSubmitCreate}>
                    <PPTextField required type="text" name="name" label="Nome" />
                    <PPTextField required type="text" name="icon" label="Ícone" />
                    <PPTextField required type="text" name="route" label="Rota" />
                    <PPTextField required type="text" name="extraData" label="Dado extra" />
                    <PPTextField required type="text" name="url" label="Link" />
                    <PPTextField required type="number" name="menuOrder" label="Ordem" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LcCheckBox checked={false} title="Público" name="isPublic" />
                        <LcCheckBox checked={false} title="Para comprar" name="isShopCart" />
                    </div>
                    <div className="row between">
                        <button className="lc-button bg-primary" type="submit">{isLoadingCreate ? <LinearProgress /> : 'Salvar'}</button>
                        <button className="lc-button bg-grey" type="reset" onClick={() => setModalCreateVisible(false)}>Fechar</button>
                    </div>
                </form>
            </PPModal>
            {
                menus.length > 0 &&
                menus
                    .filter(m => m.role.idParent == null || m.role.idParent == 'NULL')
                    .sort((a, b) => a.role.menuOrder > b.role.menuOrder ? 1 : -1)
                    .map((m, i) => {
                        return (
                            /*<div className="scrollable-v">*/
                            <PPExpand
                                key={i}
                                expanded={expanded}
                                title={
                                    <>
                                        <i className={`${m.role.icon} text-primary mr-3`} />
                                        {
                                            m.role.menuOrder != undefined &&
                                            <span className="font-xs mr-5">{m.role.menuOrder}</span>
                                        }
                                        {m.role.name}
                                        {
                                            m.role.route != '#' &&
                                            <span className="font-xs ml-5">🔗 {m.role.route}</span>
                                        }
                                    </>
                                }
                                titleRight={
                                    <>
                                        <LcIconLink
                                            icon="ppi ppi-pencil"
                                            onClick={() => setItemSelected(m.role)}
                                            tooltip="Editar item"
                                        />
                                        <LcIconLink
                                            icon="ppi ppi-plus"
                                            onClick={() => {
                                                setParent(m.role);
                                                setModalCreateVisible(true);
                                            }}
                                            tooltip="Criar filho"
                                        />
                                        <LcIconLink
                                            icon="lci lci-trash"
                                            onClick={() => {
                                                ShowDialog(m.role);
                                            }}
                                            tooltip="Deletar"
                                        />
                                    </>
                                }
                            >
                                {
                                    menus
                                        .filter(n => n.role.idParent == m.role.id)
                                        .sort((na, nb) => na.role.menuOrder > nb.role.menuOrder ? 1 : -1)
                                        .map((n, i) => {
                                            return (
                                                <PPExpand
                                                    key={i}
                                                    expanded={expanded}
                                                    title={
                                                        <>
                                                            <i className={`${n.role.icon} text-primary mr-3`} />
                                                            {
                                                                n.role.menuOrder != undefined &&
                                                                <span className="font-xs mr-5">{n.role.menuOrder}</span>
                                                            }
                                                            {n.role.name}
                                                            {
                                                                n.role.route != '#' &&
                                                                <span className="font-xs ml-5">🔗 {n.role.route}</span>
                                                            }
                                                        </>
                                                    }
                                                    titleRight={
                                                        <>
                                                            <LcIconLink
                                                                icon="ppi ppi-pencil"
                                                                onClick={() => setItemSelected(n.role)}
                                                                tooltip="Editar item"
                                                            />
                                                            <LcIconLink
                                                                icon="ppi ppi-plus"
                                                                onClick={() => {
                                                                    setParent(n.role);
                                                                    setModalCreateVisible(true);
                                                                }}
                                                                tooltip="Criar filho"
                                                            />
                                                            <LcIconLink
                                                                icon="lci lci-trash"
                                                                onClick={() => {
                                                                    ShowDialog(n.role);
                                                                }}
                                                                tooltip="Deletar"
                                                            />
                                                        </>
                                                    }
                                                >
                                                    {
                                                        menus
                                                            .filter(o => o.role.idParent == n.role.id)
                                                            .sort((oa, ob) => oa.role.menuOrder > ob.role.menuOrder ? 1 : -1)
                                                            .map((o, i) => {
                                                                return (
                                                                    <div key={i} className="menu-tree-view row between ml-5">
                                                                        <div className="name">
                                                                            <i className={`${o.role.icon} text-primary`} />
                                                                            {
                                                                                o.role.menuOrder != undefined &&
                                                                                <span className="font-xs mr-5">{o.role.menuOrder}</span>
                                                                            }
                                                                            {o.role.name}
                                                                            {
                                                                                o.role.route != '#' &&
                                                                                <span className="font-xs ml-5">🔗 {o.role.route}</span>
                                                                            }

                                                                        </div>
                                                                        <div className="row gap-0">
                                                                            <LcIconLink
                                                                                icon="ppi ppi-pencil"
                                                                                onClick={() => setItemSelected(o.role)}
                                                                                tooltip="Editar item"
                                                                            />
                                                                            <LcIconLink
                                                                                icon="lci lci-trash"
                                                                                onClick={() => {
                                                                                    ShowDialog(o.role);
                                                                                }}
                                                                                tooltip="Deletar"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </PPExpand>
                                            )
                                        })
                                }
                            </PPExpand>
                        )
                    })
            }

        </Layout>
    );
}

export default Menu;