import React from 'react';
import ResumeCMS from '../../../../../pages/CMS/ResumeCSM';

interface CMSCardProps {
    GTMs: string[];
    QtdGroups: number;
}

const CMSCard: React.FC<CMSCardProps> = ({ GTMs, QtdGroups }) => {
    return (
        <>
             <ResumeCMS isHome={true} qtdGroups={QtdGroups} />  
        </>);
}

export default CMSCard;