import React, { useState } from "react";

import { PPTextField } from "processor-plataform-ui";
import PPDropDown from "../../../../components/PPDropDown";
import Confirmation from "../../../../components/Dialog/Confirmation";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import FinOpsMapManagerService, { FinOpsAllocationRuleCondition } from '../../../../services/finOpsManagerService';

import moment from "moment";
import { PiPlusLight, PiTrashLight } from "react-icons/pi";

interface Props {
    onClose: Function,
    conditions?: FinOpsAllocationRuleCondition[],
    visible: boolean,
    onSubmit: Function,
    showOperators?: boolean
    onClear?: Function,
}

interface RuleValidade {
    name: string;
    validateMessage: string;
    valid: boolean;
    costCenterId: number;
    conditionId: number;
}

const ConditionFilterResume: React.FC<Props> = (props) => {

    var finopsMapService = new FinOpsMapManagerService(props);

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 0,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: '',
    };

    const [conditions, setConditions] = useState(props.conditions || [emptyRecordCondition]);

    const [validations, setValidations] = useState<RuleValidade[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false)

    const jsonFinOpsLogicOperator = [
        {
            label: "e",
            value: "and"
        },
        {
            label: "ou",
            value: "or"
        }
    ];

    const jsonFinOpsLCFilter = [
        {
            label: "Provedor",
            value: "ProviderName"
        },
        {
            label: "Id da conta",
            value: "AccountId"
        },
        {
            label: "Nome da Conta",
            value: "AccountName"
        },
        {
            label: "Id do recurso",
            value: "ResourceID"
        },
        {
            label: "Nome do Recurso",
            value: "ResourceName"
        },
        {
            label: "Região",
            value: "Region"
        },
        {
            label: "Serviço",
            value: "Service"
        },
        {
            label: "Categoria",
            value: "MeterCategory"
        },
        {
            label: "Subcategoria",
            value: "MeterSubCategory"
        },
        {
            label: "Grupo do recurso",
            value: "ResourceGroup"
        },
        {
            label: "Tags",
            value: "Tags"
        },
        {
            label: "Informação adicional do recurso",
            value: "ResourceAdditionalInfo"
        },
        {
            label: "Tipo de publicação",
            value: "PublisherType"
        },
        {
            label: "Número Contrato",
            value: "ContractId"
        },
        // {
        //     label: "Centro de Custo",
        //     value: "CostCenterDescription"
        // }
    ];

    const jsonFinOpsOperation = [
        {
            "label": "contém",
            "value": "contains"
        },
        {
            "label": "é igual a",
            "value": "equal"
        },
        {
            "label": "inicia com",
            "value": "startWith"
        },
        {
            "label": "diferente",
            "value": "notEqual"
        },
        {
            "label": "não contém",
            "value": "notContains"
        }
    ];

    function toCamelCase(str) {

        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }

    async function getFieldAutocompleteList(fieldName: string, fieldFilter: any) {
        var month = moment().month() + 1;
        var year = moment().year();
        try {
            var result = await finopsMapService.GetFieldOptionsResume(month, year, fieldName, fieldFilter);
            if (result && result.length > 0) {
                // var maxLen = 50;
                // let length = result.length;
                // (length > 1 && length < 50) ? maxLen = length - 1 : length === 1 ? maxLen = 1 : maxLen = 50;
                // if (result.length < 50 && result.le) {
                //     maxLen = result.length - 1;
                // } 
                // console.log(result, maxLen)
                return result.map((item) => {
                    return {value: item[toCamelCase(fieldName)]}
                });
            } else  {
                return [];
            }
        } catch (e) {
            console.error(e);
            return [];
            
        }   

    };

    function validateRule() {

        var validationsChecks: RuleValidade[] = [];

        conditions.forEach(itemCondition => {

            if (itemCondition.idCondition > 0 && itemCondition.field === '') {
                validationsChecks.push({
                    name: 'field',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }

            if (itemCondition.idCondition > 0 && itemCondition.logicOperator === '') {
                validationsChecks.push({
                    name: 'logicOperator',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }
            if (itemCondition.idCondition > 0 && itemCondition.operation === '') {
                validationsChecks.push({
                    name: 'operation',
                    validateMessage: 'Selecione um campo.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
            }

            if (itemCondition.idCondition > 0 && itemCondition.filter === '')
                validationsChecks.push({
                    name: 'filter',
                    validateMessage: 'Você deve preencher este valor',
                    valid: false,
                    costCenterId: 0,
                    conditionId: itemCondition.idCondition
                });
        });

        setValidations(validationsChecks);
        return validationsChecks.length === 0;
    }
   
    const handleChange = (name: string, value: any, condition: FinOpsAllocationRuleCondition) => {
        if(name === 'field' && value !== condition.field) {
            condition.filter = '';
        }
        setConditions(conditions.map(c => { return c.idCondition !== condition.idCondition ? c : { ...c, [name]: value } }));
        // onSubmit();
    }

    function removeCondition(item) {
        if (item.idCondition === 0)
            setConditions([{ idCondition: 0, field: '', operation: 'contains', filter: '', logicOperator: '' }]);
        else
            setConditions(conditions.filter(c => c.idCondition !== item.idCondition).map((c, i) => { return { ...c, idCondition: i } }));
    }

    function onSubmit() {
        let validated = validateRule();
        validated &&
            props.onSubmit(conditions);
    }

    const getErrorData = (conditionId, name) => {
        let data = validations.find(x => x.conditionId === conditionId && x.name === name);
        return data ? { show: true, help: data.validateMessage } : undefined;
    }

    return (
        <div className="advanced-filters">
            {!props.showOperators && <div className='title'>   Condições avançadas </div>} 
            <div className="advanced-filters-content scrollable-v" style={props.showOperators ? {
                minWidth: '100%',
                flexDirection: 'column',
                display: 'flex',
            } : undefined}>

                {
                    conditions && conditions
                        .sort((a, b) => a.idCondition > b.idCondition ? 1 : -1)
                        .map((itemCondition, index) => {
                            return (
                                <div key={index} className="filter-condition" style={props.showOperators ? {
                                    minWidth: '100%',
                                    flexDirection: 'row',
                                    display: 'flex',padding:'5px'
                                } : undefined}>
                                    {props.showOperators && <div>
                                        {
                                            index > 0 ?
                                                <PPDropDown  
                                                width='70px' 
                                                required={true}
                                                error={getErrorData(itemCondition.idCondition, 'logicOperator')}
                                                openTrigger="click"
                                                name="logicOperator"
                                                value={jsonFinOpsLogicOperator.find(option => option.value === itemCondition.logicOperator)}
                                                options={jsonFinOpsLogicOperator}
                                                onChange={(event) => { const { name, value } = event;   handleChange('logicOperator', value, itemCondition); }}
                                                    hideFilter
                                                /> : <div style={{ paddingLeft: '70px' }}></div>
                                        }
                                    </div>} 
                                    {<PPDropDown  
                                        width='8vw'
                                        name="field"
                                        options={jsonFinOpsLCFilter}
                                        value={jsonFinOpsLCFilter.find(option => option.value === itemCondition.field) ? jsonFinOpsLCFilter.find(option => option.value === itemCondition.field) : ''}
                                        onChange={(event) => { const { name, value } = event;  handleChange('field', value, itemCondition) }}
                                        placeholder='(Critério)'
                                        menuPlacement="top"
                                        menuPosition="fixed"
                                        maxMenuHeight={200}
                                        error={getErrorData(itemCondition.idCondition, 'field')}
                                        openTrigger="click"
                                    />
                                    }

                                    {props.showOperators && <PPDropDown
                                        width='10vw'
                                        openTrigger="click"
                                        name="operation"
                                        menuPlacement="top"
                                        menuPosition="fixed"
                                        maxMenuHeight={200}
                                        error={getErrorData(itemCondition.idCondition, 'operation')}
                                        value={jsonFinOpsOperation.find(option => option.value === itemCondition.operation) }
                                        options={jsonFinOpsOperation}
                                        onChange={(event) => {    const { name, value } = event; handleChange("operation", value, itemCondition); }}
                                        required
                                        hideFilter
                                    />}
                                    <PPTextField
                                        className="termfield"
                                        type='text'
                                        error={validations.find(x => x.conditionId === itemCondition.idCondition && 'filter' === x.name) !== undefined}
                                        help={validations.find(x => x.conditionId === itemCondition.idCondition && 'filter' === x.name)?.validateMessage}
                                        name="filter"
                                        value={itemCondition.filter}
                                        onChange={(event) => { handleChange('filter', event.target.value, itemCondition) }} 
                                        placeholder="(Termo)"
                                        sugestions={(event) => getFieldAutocompleteList(itemCondition.field, event) as any}
                                    />

                                    {/* <PPInput
                                        autoComplete="off"
                                        width='20vw'
                                        type="text"
                                        value={itemCondition.filter}
                                        name="filter"
                                        placeholder="(Termo)"
                                        //onChange={(event) => { handleChange(event, itemCondition); }}
                                        onChange={(event) => {   handleChange('filter', event.target.value, itemCondition) }} 
                                        error={{
                                            help: validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name)?.validateMessage,
                                            show: validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name) != undefined
                                        }}
                                    suggestions={(event) => getFieldAutocompleteList(itemCondition.field, event) as any}
                                    /> */}
                                    <div className="row upbuttons">
                                        <LcIconLink
                                            size={'small'}
                                            tooltipPosition={'right'}
                                            icon={<PiTrashLight/>}
                                            disabled={itemCondition.idCondition === 0 && conditions.length > 1}
                                            onClick={() => { removeCondition(itemCondition) }}
                                        />
                                        <LcIconLink
                                            size={'small'}
                                            tooltipPosition={'right'}
                                            icon={<PiPlusLight/>}
                                            onClick={() => { setConditions([...conditions, { ...emptyRecordCondition, logicOperator: 'and', idCondition: conditions.length }]) }}
                                            tooltip="Adicionar Condição"
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            {!props.showOperators && <div className="row between" style={{ padding: '10px' }} >
                <div className="row">
                    <button className="lc-button bg-primary" type="button" onClick={() => onSubmit()}>Filtrar</button>
                </div>
                <div className="row">
                    <button className="lc-button bg-gray" type="button"
                        onClick={() => {
                            setConditions([{ idCondition: 0, field: '', operation: 'contains', filter: '', logicOperator: '' }]);
                            props.onClear && props.onClear()
                        }}>Limpar</button>
                    {/*<button className="lc-button bg-gray" type="button" onClick={() => { props.onClose(false) }}>Cancelar</button>*/}
                </div>

            </div>}

            <Confirmation
                title="Confirme"
                text='Tem certeza que deseja perder as alterações feitas?'
                confirm={() => { setConfirmVisible(false); props.onClose(false) }}
                close={() => { setConfirmVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmVisible}
            />
        </div>

    )
};

export default ConditionFilterResume;