import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index';
import { BFFMenu, MenuState } from '../../store/reducers/menuReducer';
import HomeDashboardCard from '../../components/Data/Dashboard/HomeDashboardCard';
import { PiArrowSquareOutBold, PiArrowSquareOutLight } from 'react-icons/pi';
import './index.css';
import { UserState } from '../../store/reducers/userReducer';
import LcTooltip from '../../components/Generic/LcTooltip';

const SecOps: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const history = useHistory();

    const [solutions, setSolutions] = useState<BFFMenu[]>([]);
    
    const groupColors = {
        '4': '#DC291E', //Segurança (SecOps)
    };

    useEffect(() => {
        const _item = menu.items.find(i => i.id === '6F96AEFB-3676-4995-9512-A15A6C617E1E');
        if (!_item) return;

        const _solutions = _item.children.filter(c => 
            JSON.parse(c.extraData).group_id === '4' && 
            c.permitido && 
            c.name !== 'SecureEndPoint' 
            && c.menuOrder !== 52 
            // && c.menuOrder !== 51
            && c.menuOrder !== 54
        );
        const sortedSolutions = _solutions.sort((a, b) => a.menuOrder - b.menuOrder);

        if (sortedSolutions.length === 0) {
            history.push('/solucoes');
        } else {
            setSolutions(sortedSolutions);
        }
    }, [menu]);

    const onClickSolutionItem = (item: BFFMenu) => {
        const route = item.route;
        if (route && route !== '#') {
            if (route.toString().includes("http")) {
                window.open(route, '_blank');
            } else {
                history.push("/" + route);
            }
        } else if (item.children && item.children[0] && item.children[0].route) {
            history.push('/' + item.children[0].route);
        }
    }

    return (
        <Layout pageTitle="SecOps">
            <div className='homeflex'>
                {solutions.map((solution, i) => (
                    <div
                        className="card-home"
                        key={i}
                        style={{ backgroundImage: `linear-gradient(0deg, #FFFFFF 75%, ${groupColors['4']} 220%)` }}
                    >
                        <div className="header">
                            <div className="group">
                                <div className="color" style={{ backgroundColor: groupColors['4'] }}> </div>
                                <div className="title" title={solution.name}>{solution.name}</div>
                            </div>
                            <div className="icons">
                                <div className="icon menu" onClick={() => { onClickSolutionItem(solution); }}>
                                    <LcTooltip
                                            content={
                                                <div className="item">
                                                    Ir para a solução
                                                </div>
                                            }
                                            trigger="hover"
                                            position="right"
                                        >
                                            <div className='centralized'>
                                                <PiArrowSquareOutLight color="#2E1956" />

                                            </div>
                                    </LcTooltip>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <HomeDashboardCard GroupId={'4'} QtdGroups={solutions.length} GTMs={[JSON.parse(solution.extraData).solution]} />
                        </div>
                    </div>
                ))}
            </div>

            <style dangerouslySetInnerHTML={{
                __html: `                        
                    .container > .toolbar {display:none;}                         
                `}} />
        </Layout>
    )
}
export default SecOps;
