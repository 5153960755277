import React, { useState, useEffect, useRef, forwardRef } from 'react';
import './ExpandingTextarea.css'; // Import the CSS file
import { PiPaperPlaneTiltLight } from 'react-icons/pi';

interface ExpandingTextareaProps {
    value: string;
    setValue: (value: string) => void;
    onSend: () => void;
}

const LLMExpandingTextarea = forwardRef<HTMLTextAreaElement, ExpandingTextareaProps>(
    ({ value, setValue, onSend }, ref) => {
        const [rows, setRows] = useState(1);
        const [isFocused, setIsFocused] = useState(false);
        
        const textareaRef = ref as React.RefObject<HTMLTextAreaElement>;

        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const textareaLineHeight = 23; // Altura da linha em pixels
            const minRows = 1;
            const maxRows = 3;

            event.target.rows = minRows; // Resetting the rows to the minimum before calculating

            const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

            if (currentRows >= maxRows) {
                event.target.rows = maxRows;
                event.target.scrollTop = event.target.scrollHeight;
            } else {
                event.target.rows = currentRows; // Set the rows attribute based on current rows
            }

            setRows(currentRows < maxRows ? currentRows : maxRows);
            setValue(event.target.value);
        };

        // Certifique-se de que o valor está sendo atualizado corretamente
        useEffect(() => {
            if (textareaRef.current) {
                const textareaLineHeight = 23;
                const minRows = 1;
                const maxRows = 3;

                textareaRef.current.rows = minRows;
                const currentRows = Math.floor(textareaRef.current.scrollHeight / textareaLineHeight);

                if (currentRows >= maxRows) {
                    textareaRef.current.rows = maxRows;
                    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
                } else {
                    textareaRef.current.rows = currentRows;
                }

                setRows(currentRows < maxRows ? currentRows : maxRows);
            }
        }, [textareaRef, value]);

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault(); // Prevents default line break
                onSend();
            }
        };

        return (
            <div
                className={`textarea-container ${isFocused ? 'focused' : ''}`}
                title="Pressione Enter para enviar, Shift + Enter para uma nova linha"
            >
                <textarea
                    ref={textareaRef}
                    rows={rows}
                    value={value} // Certifique-se de que o valor está sendo passado corretamente
                    defaultValue={value} // Certifique-se de que o valor está sendo passado corretamente
                    placeholder="Faça uma pergunta"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    className={`textarea ${rows >= 2 ? 'scrollable-v' : ''}`}
                />
                <button
                    type="button"
                    onClick={onSend}
                    className={`send-button ${isFocused ? 'focused' : ''}`}
                >
                    <PiPaperPlaneTiltLight
                        size={22}
                        className={`send-button-icon ${isFocused ? 'focused' : 'unfocused'}`}
                    />
                </button>
            </div>
        );
    }
);

export default LLMExpandingTextarea;