import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

interface Instance {
	size: string,
	value: number
}

interface Props {
	data: Instance[]
}

const WeeklyCostEstimateChart: React.FC<Props> = (props) => {

	const defaultValue: Instance = { size: "", value: 0.00 };
	const [currentInstance, setCurrentInstance] = useState<Instance>(defaultValue);
	const [desiredInstance, setDesiredInstance] = useState<Instance>(defaultValue);

	useEffect(() => {
		setCurrentInstance(props.data[0]);
		setDesiredInstance(props.data[1]);
	}, [props.data]);

	const chartOptions = {
		grid: {
			top: 20,
			bottom: 0,
			left: 0,
			right: 30,
			containLabel: true
		},
		tooltip: {
			trigger: 'item', // Shows tooltip for individual items
			backgroundColor: '#f1f5fb',
			borderWidth: 0,
			textStyle: {
				fontSize: 11,
				fontFamily: 'Ubuntu',
				color: '#2B304F',
			},
			formatter: (params) => {
				const { name, value } = params;
				return `${name}: ${value.toFixed(4)}`; // Format value to 4 decimal places
			}
		},
		xAxis: {
			type: 'category',
			data: [currentInstance.size, desiredInstance.size],
			axisTick: { show: false },
			axisLabel: {
				fontSize: '11px',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
			},
		},
		yAxis: {
			type: 'value',
			splitLine: { show: false },
			axisLabel: {
				fontSize: '11px',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
			},
		},
		series: [{
				type: 'bar',
				barWidth: '40%',
				data: [
					{
						value: currentInstance.value,
						itemStyle: { color: "#3A396A" }
					},
					{
						value: desiredInstance.value,
						itemStyle: { color: "#5F5DAD" }
					}
				]
			}]
	} 

	return (
		<>
			<ReactECharts
				style={{height: '30vh', width: '100%'}}
				opts={{ renderer: 'svg', locale: 'PT-br' }}
				option={chartOptions}
			/>
		</>
	)
}

export default WeeklyCostEstimateChart;