import api, { APIClient } from "./api";

export default class UserService extends APIClient {

    async GetMenu() {
        const url = this.endpoint + `/api/Menu/GetMenu`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetByClientGroup() {
        const url = this.endpoint + `/api/Menu/GetUsersByClientGroupId`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetListClientGroups() {
        const url = this.endpoint + `/Account/GetListClientGroups`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetHealthRobotEventGetbyPartitionReport(filterDate: string) {
        const url = this.endpoint + `/Account/HealthRobotEventGetbyPartitionReport?PartitionKey=${filterDate}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetMonitoringAPIbyPartitionReport() {
        const url = this.endpoint + `/Account/MonitoringAPIbyPartitionReport`;
        return api({
            method: 'get',
            url, 
        }).then((response) => {
            return response.data;
        });
    }
}
