import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useEffect, useState } from 'react';
import './index.css';

interface Props {
    label?: string,
    loading?: boolean,
    percentageProgress?: number,
    loadingType?: 'Orbit' | 'Helix'
}

const LcLoading: React.FC<Props> = (props) => {
    const [progress, setProgress] = useState(props.percentageProgress || 0);
    const loadingType = props.loadingType || 'Orbit';

    useEffect(() => {
        setProgress(props.percentageProgress || 0);
    }, [props.percentageProgress]);

    const renderOrbitLoading = () => (
        <div className="container-orbit"></div>
    );

    const renderHelixLoading = () => (
        <div className="container-helix">
            <div className="slice"></div>
            <div className="slice"></div>
            <div className="slice"></div>
            <div className="slice"></div>
            <div className="slice"></div>
            <div className="slice"></div>
        </div>
    );

    return (
        <>
            {
                props.loading ?
                    <div className="lc-loading fullspace centralized-row">
                        <div className="w-100p">
                            {loadingType === 'Orbit' ? renderOrbitLoading() : renderHelixLoading()}
                            {progress != 0 && progress != null && (
                                <span className="progress">{progress}%</span>
                            )}
                            <div className="title-loading">{props.label ?? ""}</div>
                        </div>
                    </div>
                    :
                    props.children
            }
        </>
    );
}

export default LcLoading;
