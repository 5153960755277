 
import React from 'react';

import './index.css';
import LcLoading from '../LcLoading';

interface Props {
    title?: string | React.ReactNode,
    loading?: boolean,
    fullHeigth?: boolean,
    height?: string,
    centralized?: boolean,
    filterControl?: React.ReactNode,
    centerData?: React.ReactNode,
    finOps?: boolean,
    shadow?: boolean
}


const LcSegment: React.FC<Props> = (props) => {
    return (
        <div className="lc-segment" style={{ minHeight: props.fullHeigth ? '100%' : 'fit-content !important',
         boxShadow: props.shadow ? '0px 0px 7px 0px #dfdfdf' : '' }}>

            {
                props.title &&
                <span className="title"
                    style={props.finOps ? { maxWidth: '50%', display: 'table-cell', width: '50%', fontFamily: 'Ubuntu', fontWeight: '500', fontSize: '1rem', color: '#8b90a3' }
                        : undefined}>{props.title}
                </span>
            }
            {
                props.centerData && <span style={{ maxWidth: '100px' }}>
                    {props.centerData}
                </span>
            }
            {
                props.filterControl &&
                <span style={{ maxWidth: '200px' }} >{props.filterControl}</span>
            }
            <div className="body" style={{ height: props.height ? props.height : 'auto' }}>
                {
                    props.loading ?
                        <div className="loading">
                            <LcLoading loading={props.loading} label="Carregando..." />
                           
                        </div>
                        :
                        <div className={`content ${props.centralized ? 'centralized' : ''}`}>
                            {props.children}
                        </div>
                }
            </div>
        </div >
    );
};

export default LcSegment;