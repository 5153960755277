import React, { useEffect, useMemo, useState } from 'react';
import { PiCube } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { AksClustersModel, OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { useDispatch, useSelector } from 'react-redux';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { Tooltip } from '@material-ui/core';

interface ContainersInfoCardProps {
    setLoading: (loading: boolean) => void
}

export const ContainersInfoCard: React.FC<ContainersInfoCardProps> = ({setLoading}) => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const [isLoading, setIsLoading] = useState(true);
    const [containersCard, setContainersCard] = useState<OnOffModel>({ on: 0, off: 0 });
    const [containersData, setContainersData] = useState<[]>([]);
    

    const translationsFilter = useMemo(() => {
        return {
            name: {
                label: "Nome",
                type: "text",
            },
            subscription: {
                label: "Assinatura",
                type: "text",
            },
            locationOrRegion: {
                label: "Região",
                type: "multiSelect",
            },
            type: {
                label: "Serviço",
                type: "multiSelect",
            },
            status: {
                label: "Situação",
                type: "multiSelect",
                values: {
                    'Active':'Ativo',
                    'Inactive':'Parado'
                },
                topDropdown: true
            },
        };
    },[])


    const columnsContainers = useMemo(() => {
        return [
            {
                field: 'name', headerName: 'Nome', width: "35%", renderCell: (row: any) =>{
                    return  (
                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                        <Tooltip arrow title={row.cloud !== 'AWS' ? 'Microsoft Azure' : 'Amazon AWS'}>
                            <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                {row.cloud !== 'AWS' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                            </span>
                        </Tooltip>
                        {/* <Tooltip arrow title={row.name}> */}
                            <span
                                className='overflowEllipsis ml-4'
                            >
                                {row.name}
                            </span>
                        {/* </Tooltip> */}
                    </div>
                    )
                }
            },
            {
                field: 'subscription', headerName: 'Assinatura', width: "25%",
            },
            {
                field: 'locationOrRegion', headerName: 'Região', width: "15%",
            },
            {
                field: 'type', headerName: 'Serviço', width: "15%",
            },
            {
                field: 'status', headerName: 'Situação', width: "10%", overflow: "visible",
                renderCell: (row: any) => {
                    return row.status === 'Active' ? <p className='text-green'>Ativo</p>: row.status === 'Succeeded' ? <p className='text-green'>Inicializado</p>  : <p className='text-danger'>Parado</p>;
                    }
            },   
        ]

    },[])

    function updateCloudServices(cloudArray) {
         // Verifique se o `cloudArray` é um array e contém objetos
        if (Array.isArray(cloudArray)) {
            // Crie um novo array para armazenar os resultados
            const result: AksClustersModel[] = [];

            // Percorra cada objeto no array
            cloudArray.forEach(cloud => {
            // Verifique se a propriedade `services` existe e é um array
            if (Array.isArray(cloud.services)) {
                // Adicione um novo objeto para cada serviço
                cloud.services.forEach(service => {
                result.push({
                    ...cloud,  // Inclui todas as propriedades do objeto `cloud`
                    name: service.name,  // Substitui a propriedade `name` com o valor do serviço
                    status: service.status  // Substitui a propriedade `status` com o valor do serviço
                });
                });
            }
            });

            return result;
        }

            return cloudArray;
        }
        
    useEffect(() => {
        const GetAKSClusters = async () => {
            try {
                setIsLoading(true)
                setLoading(true)
                const CmsService = new CmsPremierService({})
                const containerData = await CmsService.getAKSClusters();
                let containerResult = updateCloudServices(containerData)
                setContainersData(containerResult);
                const result = containerResult.reduce(
                    (acc, curr) => {
                        if (curr.status.includes("Active") || curr.status.includes("Succeeded")) {
                            acc.on += 1;
                        } else {
                            acc.off += 1;
                        }
                        return acc;
                    },
                    { on: 0, off: 0 }
                );
                setContainersCard(result);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoading(false)
            }
        }
        GetAKSClusters()
    },[user.refreshFlag, user.ClientGroupSelected, setLoading])

    useEffect(() => {
        if(cmsEnvironment.enviromentFilter === 'Containers' && !isLoading) {
            let payload = {
                enviromentFilter: 'Containers',
                columns: columnsContainers,
                rowsList: containersData,
                rowsListFiltered: containersData,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter

            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            setLoading(false)
        }
    },[cmsEnvironment.enviromentFilter, columnsContainers, containersData, dispatch, isLoading, setLoading, translationsFilter])

    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Containers',
            columns: columnsContainers,
            rowsList: containersData,
            rowsListFiltered: containersData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter

        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        setLoading(false)

    } 


    return (
        <LcLoading loading={isLoading}  >
            <IconValue
                icon={<PiCube size={28} />}
                value={`${containersCard.on}/${(containersCard.on + containersCard.off)}`}
                color="#255A4B"
                onClick={handleClickEnviroment}
                description='Ativos/Total'
                balonSelected={(cmsEnvironment.enviromentFilter === 'Containers')}
            />
        </LcLoading>
    );
}