import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LcLoading from '../../../../components/Generic/LcLoading';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import LcNoData from '../../../../components/Generic/LcNoData';
import { GraphModel } from '../../../../services/patchmanager/patchManagerModel';
import { Height } from '@material-ui/icons';

export interface DoughNutModel {
    value: number;
    name: string;
    itemStyle?: object;
}

interface Props {
    patchSummariesInstalledVsMissing?: any[];
    legendOrientation: 'horizontal' | 'vertical';
    isloading: boolean;
}

const getColorsGraph = (SOName: string): string => {
    const color = {
        'Ausentes': '#ffa400',
        'Instalados': "#3ebb3f",
    };
    return color[SOName];
}

const buildDataGraph = (data: GraphModel[]) => {
    return data.map(graph => ({
        value: graph.value,
        name: graph.name,
        itemStyle: { color: getColorsGraph(graph.name) }
    }));
}

const InstalledVsMissingGraph: React.FC<Props> = ({legendOrientation,patchSummariesInstalledVsMissing, isloading}) => {
    const user = useSelector<RootState, UserState>(state => state.user);

    const [patchInfoGraph, setPatchInfoGraph] = useState<DoughNutModel[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: boolean }>({});
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState<boolean>(isloading);
    const chartRef = useRef<ReactECharts>(null);


    const getDataGraph = useCallback(async () => {
        try {
            const patchesResponse = patchSummariesInstalledVsMissing;
            if (patchesResponse) {
                const dataGraph = buildDataGraph(patchesResponse);
                setPatchInfoGraph(dataGraph);
                const initialSelected = dataGraph.reduce((acc, item) => {
                    acc[item.name] = true;
                    return acc;
                }, {});
                setSelectedOptions(initialSelected);
                setTotal(dataGraph.reduce((sum, { value }) => sum + value, 0));
            }
        } catch (error) {
            console.error('Error:', error);
        } 
    },[patchSummariesInstalledVsMissing])

    useEffect(() => {
        getDataGraph()
    }, [user.ClientGroupSelected, user.refreshFlag, patchSummariesInstalledVsMissing, getDataGraph]);

    const handleLegendSelectChanged = useCallback((params) => {
        const selected = params.selected;
        const newSelectedOptions = { ...selectedOptions };
        Object.keys(selected).forEach(key => {
            newSelectedOptions[key] = selected[key];
        });
        
        const selectedItems = patchInfoGraph.filter(item => newSelectedOptions[item.name]);
        const newTotal = selectedItems.reduce((sum, item) => sum + item.value, 0);
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();

            chartInstance.setOption({
                legend: {
                    selected: newSelectedOptions
                },
                series: [{
                    label: {
                        formatter: newTotal.toString()
                    }
                }]
            }, false, true);
        }
    }, [selectedOptions, patchInfoGraph, chartRef]);

    const getDataOptions = useMemo(() => (patchInfoGraphData) => {
        const dataLookup = patchInfoGraphData && patchInfoGraphData.length > 0
            ? patchInfoGraphData.reduce((acc, item) => {
                acc[item.name] = item.value;
                return acc;
            }, {})
            : {};

        return {
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 11,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                trigger: 'item'
            },
            legend: {
                type: 'scroll',
                orient: legendOrientation,
                width: legendOrientation === 'vertical' ? '' : '100%',
                right: legendOrientation === 'vertical' ? '2%' : 'center',
                top: legendOrientation === 'vertical' ? 'middle' : 'bottom',
                textStyle: {
                    fontSize: 9,
                    fontFamily: 'Ubuntu',
                    color: '#000',
                },
                pageTextStyle: {
                    color: '#000',
                },
                pageButtonItemGap: 5,
                pageButtonGap: 10,
                pageButtonColor: '#000',
                pageButtonPosition: 'end',
                pageFormatter: '{current}/{total}',
                pageIcons: {
                    horizontal: [
                        'M0,0 L12,0 L6,10 Z', // up arrow
                        'M0,10 L12,10 L6,0 Z' // down arrow
                    ]
                },
                formatter: (name) => {
                    const value = dataLookup[name] || 0;
                    return `${name === 'Ausentes' ? 'Não instalados' : 'Instalados'}: ${Number(value).toLocaleString('pt-BR')}`;
                },
                selected: selectedOptions
            },
            series: [{
                type: 'pie',
                center:  window.screen.width < 1400 &&  legendOrientation === 'vertical' ? ['20%', '50%']  : legendOrientation === 'vertical' ? ['23%', '50%'] : ['50%', '50%'],
                radius: window.screen.width < 1400 ? ['40%', '60%'] : ['50%', '80%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: true,
                    position: 'center',
                    formatter: () => total,
                    fontSize: 20,
                    fontWeight: 'bold',
                    textStyle: {
                        overflow: 'truncate',
                        ellipsis: '...',
                        fontFamily: 'Ubuntu',
                    }
                },
                color: patchInfoGraphData.map(i => getColorsGraph(i.name)),
                data: patchInfoGraphData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(58, 79, 135, 0.4)',
                        borderWidth: 0
                    }
                }
            }]
        };
    },[legendOrientation, selectedOptions, total]);

    return (
        <LcLoading loading={isloading}>
            {
                patchInfoGraph.length > 0 ?
                    <ReactECharts
                        ref={chartRef}
                        key={'InstalledVsMissingGraph'}
                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        option={getDataOptions(patchInfoGraph)}
                        style={{height: '100%'}}
                        onEvents={{
                            'legendselectchanged': handleLegendSelectChanged
                        }}
                    />
                :
                <LcNoData size="small" />
            }
        </LcLoading>
    );
}

export default InstalledVsMissingGraph;
