import React, { useEffect, useState } from 'react';
import { DashboardGestaoAVistaService } from '../../../services/maxOutsource/dashboardGestaoAVistaService';
import { LinearProgress } from '@material-ui/core';
import Layout from '../../../components/Layout/Layout';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BigIconSmile from '../../../components/Layout/Dashboard/BigIconSmile/BigIconSmile';
import { InputField } from '../../../components/Form/Input';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import "./index.css";
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcLoading from '../../../components/Generic/LcLoading';

const DashboardGestaoAVista: React.FC = (props) => {

  const service = new DashboardGestaoAVistaService(props);
  const [indicators, setIndicators] = useState({
    actionPlans: '',
    client: '',
    collectingIndicators: '',
    environmentManual: '',
    executiveSummary: '',
    reportService: '',
    routines: '',
    serviceManual: '',
    slm: ''
  });

  const [modalVisiblePlanoAcao, setModalVisiblePlanoAcao] = useState<boolean>(false);
  const [modalVisibleRotina, setModalVisibleRotina] = useState<boolean>(false);

  const startYear = new Date().getFullYear().toString();
  let startMonth = (new Date().getMonth() + 1).toString();
  startMonth = startMonth.length == 1 ? ('0' + startMonth) : startMonth;

  const [year, setYear] = useState(startYear);
  const [month, setMonth] = useState(startMonth);
  const [isLoading, setIsLoading] = useState(true);

  const filterDefault = { size: 20, term: "", column: "", direction: "asc" };

  const [rotinas, setRotinas] = useState([]);
  const [filteredRotinas, setFilteredRotinas] = useState([]);
  const [visibleRotinas, setVisibleRotinas] = useState([]);
  const [filterRotinas, setFilterRotinas] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

  const [planosAcao, setPlanosAcao] = useState([]);
  const [filteredPlanosAcao, setFilteredPlanosAcao] = useState([]);
  const [visiblePlanosAcao, setVisiblePlanosAcao] = useState([]);
  const [filterPlanosAcao, setFilterPlanosAcao] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

  const [selectedPlanosAcao, setSelectedPlanosAcao] = useState<any[]>([]);
  const [selectedRotinas, setSelectedRotinas] = useState<any[]>([]);

  const user = useSelector<RootState, UserState>(state => state.user);

  const inputSetDate = (data: any) => {
    const date = data.value.split('-');
    setMonth(date[1]);
    setYear(date[0]);
  };

  useEffect(() => {
    setFilteredRotinas([]);
    setFilteredPlanosAcao([]);

    service.DataTablePlanoAcaoAjaxHandler().then((response: any) => {
      setPlanosAcao(response);
      setFilteredPlanosAcao(response);
    });
    service.DataTableRotinaAjaxHandler().then((response: any) => {
      setRotinas(response);
      setFilteredRotinas(response);
    });
  }, [user.ClientGroupSelected, user.refreshFlag]);

  useEffect(() => {
    setIsLoading(true);
    service.GetIndicadoresByCompetence(year, month).then((response: any) => {
      if (response)
        setIndicators(response);
      setIsLoading(false);
    });
  }, [month, year, user.ClientGroupSelected, user.refreshFlag]);

  useEffect(() => {
    var filtered = planosAcao.sort((a: any, b: any) => {
      if (filterPlanosAcao.direction == 'asc') return (a[filterPlanosAcao.column] > b[filterPlanosAcao.column]) ? 1 : -1;
      else return (a[filterPlanosAcao.column] > b[filterPlanosAcao.column]) ? -1 : 1;
    });

    setFilteredPlanosAcao(filtered);
    setVisiblePlanosAcao(filtered.slice(0, filterPlanosAcao.size));
  }, [filterPlanosAcao, filteredPlanosAcao.length > 0]);

  useEffect(() => {
    var filtered = rotinas.sort((a: any, b: any) => {
      if (filterRotinas.direction == 'asc') return (a[filterRotinas.column] > b[filterRotinas.column]) ? 1 : -1;
      else return (a[filterRotinas.column] > b[filterRotinas.column]) ? -1 : 1;
    });

    setFilteredRotinas(filtered);
    setVisibleRotinas(filtered.slice(0, filterRotinas.size));
  }, [filterRotinas, filteredRotinas.length > 0]);

  const columnsTablePlanoAcao = [
    { field: 'subject', headerName: 'Título', width: "60%" },
    { field: 'descriptionType', headerName: 'Tipo', width: "20%" },
    { field: 'descriptionSituation', headerName: 'Status', width: "20%" },
    // { field: 'solicitationDate', headerName: 'Data', flex: 1 },
  ];

  const functionsTablePlanoAcao = [
    {
      id: "25fd9f43-08b8-4310-8fa2-ad8c189e4e7e",
      title: 'Exportar selecionados',
      tooltip: 'Exportar selecionados',
      icon: 'lci lci-document-text',
      disabled: selectedPlanosAcao.length == 0,
      func: (ids: []) => {
        ids.map(id => {
          console.debug(id);
        })
      }
    },
    {
      id: "6a821ff5-8b80-446f-8575-a648e2813fba",
      title: 'Excluir selecionados',
      tooltip: 'Excluir selecionados',
      icon: 'lci lci-trash',
      disabled: selectedPlanosAcao.length == 0,
      func: (ids: []) => {
        ids.map(id => {
          console.debug(id);
        })
      }
    },
    {
      id: "7cdec608-cfe6-4796-9d8d-a6038454d71e",
      title: 'Ativar selecionados',
      tooltip: 'Ativar selecionados',
      icon: 'ppi ppi-check',
      disabled: selectedPlanosAcao.length == 0,
      func: (ids: []) => {
        ids.map(id => {
          console.debug(id);
        })
      }
    }
  ];

  const columnsTableRotinas = [
    { field: 'subject', headerName: 'Título', width: "80%" },
    // { field: 'descriptionType', headerName: 'Status', flex: 1 },
    { field: 'descriptionSituation', headerName: 'Status', width: "20%" },
    // { field: 'solicitationDate', headerName: 'Data', flex: 1 },
  ];

  const functionsTableRotinas = [
    {
      id: "f21ff23c-84f5-49a3-afdf-96dc844cf5af",
      title: 'Exportar selecionados',
      tooltip: 'Exportar selecionados',
      icon: 'lci lci-document-text',
      disabled: selectedRotinas.length == 0,
      func: () => alert('Ação')
    },
    {
      id: "c184b072-edfe-4f3c-b518-34dae6723bf6",
      title: 'Excluir selecionados',
      tooltip: 'Excluir selecionados',
      icon: 'lci lci-trash',
      disabled: selectedRotinas.length == 0,
      func: () => alert('Ação')
    },
    {
      id: "6e836b63-444c-4658-917d-5fee1e6c6824",
      title: 'Ativar selecionados',
      tooltip: 'Ativar selecionados',
      icon: 'ppi ppi-check',
      disabled: selectedRotinas.length == 0,
      func: () => alert('Ação')
    }
  ];

  const onChagePeriod = (selected: any) => {
    let date = selected.fields[0].value;
    setMonth(date.split('-')[1]);
    setYear(date.split('-')[0]);
  }

  const periodSelection = {
    periods: [
      {
        label: 'Este mês',
        text: 'Este mês',
        fields: [
          { name: 'month', value: Moment().format('YYYY-MM') }
        ],
        default: true
      },
      {
        label: 'Mês anterior',
        text: 'Mês anterior',
        fields: [
          { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
        ]
      },
      {
        label: 'Dois meses atrás',
        text: 'Dois meses atrás',
        fields: [
          { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
        ]
      }
    ],
    customPeriod: {
      label: 'Personalizado',
      text: 'personalizado',
      fields: [
        { name: 'month', label: 'Mês', value: '' }
      ]
    },
    customPeriodType: 'month',
    onChange: onChagePeriod
  }

  const onSortChangePlanoAcao = (sortData: any) => {
    const { sort, size } = sortData;
    setFilterPlanosAcao({ ...filterPlanosAcao, column: sort.column, direction: sort.direction, size: size })
  };

  const loadMorePlanoAcao = (size?: number) => {
    if (size) setVisiblePlanosAcao(filteredPlanosAcao.slice(0, size))
    else {
      setVisiblePlanosAcao([...visiblePlanosAcao, ...filteredPlanosAcao.slice(visiblePlanosAcao.length, visiblePlanosAcao.length + 10)])
    }
  };

  const onSortChangeRotinas = (sortData: any) => {
    const { sort, size } = sortData;
    setFilterRotinas({ ...filterRotinas, column: sort.column, direction: sort.direction, size: size })
  };

  const loadMoreRotinas = (size?: number) => {
    if (size) setVisibleRotinas(filteredRotinas.slice(0, size))
    else {
      setVisibleRotinas([...visibleRotinas, ...filteredRotinas.slice(visibleRotinas.length, visibleRotinas.length + 10)])
    }
  };


  return (
    <Layout pageTitle="Gestão à Vista" periodSelection={periodSelection}>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
        <LcLoading loading={isLoading} label="Carregando..." />
      </div>
      <div className={!isLoading ? '' : 'hide'}>
        {/* <InputField type="date" value={`${year}-${month}-01`} name="dateSearch" onChange={inputSetDate} /> */}

        <SideModal visible={modalVisiblePlanoAcao} header="Planos de Ação" onClose={() => setModalVisiblePlanoAcao(false)}>

          <LcInfiniteTable
            loading={isLoading}
            columns={columnsTablePlanoAcao}
            rows={visiblePlanosAcao}
            size={filteredPlanosAcao.length}
            functions={functionsTablePlanoAcao}
            onSelectChange={setSelectedPlanosAcao}
            onSortChange={onSortChangePlanoAcao}
            loadMore={loadMorePlanoAcao} />

        </SideModal>

        <SideModal visible={modalVisibleRotina} header="Rotinas" onClose={() => setModalVisibleRotina(false)}>

          <LcInfiniteTable
            loading={isLoading}
            columns={columnsTableRotinas}
            rows={visibleRotinas}
            size={filteredRotinas.length}
            onSelectChange={setSelectedRotinas}
            functions={functionsTableRotinas}
            onSortChange={onSortChangeRotinas}
            loadMore={loadMoreRotinas} />

        </SideModal>

        <div className="bigIcons">
          <BigIconSmile title="Sumário executivo" status={indicators.executiveSummary} icon="lci lci-emoji-happy" info=""></BigIconSmile>
          <BigIconSmile title="Manual de Atendimento" status={indicators.serviceManual} icon="lci lci-emoji-happy" info=""></BigIconSmile>
          <BigIconSmile title="Manual de Ambiente" status={indicators.environmentManual} icon="lci lci-emoji-happy" info=""></BigIconSmile>
          <BigIconSmile id="79866fa4-2b57-4b20-af3e-93301417c958" onClick={() => setModalVisiblePlanoAcao(true)} title="Planos de Ação" status={indicators.actionPlans} icon="lci lci-emoji-happy" info="Clique Para Ver Mais"></BigIconSmile>
          <BigIconSmile id="c7fccdfc-9a36-4569-8b4a-117c343c56fa" onClick={() => setModalVisibleRotina(true)} title="Rotinas" status={indicators.routines} icon="lci lci-emoji-happy" info="Clique Para Ver Mais"></BigIconSmile>
          <BigIconSmile title="Coleta de Indicadores" status={indicators.collectingIndicators} icon="lci lci-emoji-happy" info=""></BigIconSmile>
          <BigIconSmile title="SLM" status={indicators.slm} icon="lci lci-emoji-happy" info=""></BigIconSmile>
          <BigIconSmile title="Relatório de Atendimento" status={indicators.reportService} icon="lci lci-emoji-happy" info=""></BigIconSmile>
        </div>
      </div>
    </Layout>
  );
}

export default DashboardGestaoAVista;