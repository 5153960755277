import React, { useState } from 'react';

import './index.css';

interface Props {
    visible: boolean,
    text: string,
    title?: string,
    onClose?: Function,
    textBtnOk?: string,
    context?: 'success' | 'warning' | 'danger' | 'info'
}

const Alert: React.FC<Props> = (props) => {

    return (
        <div className="lcd-alert">
            {
                props.visible &&
                <div className="all" onClick={() => { props.onClose && props.onClose(false) }}>
                    <div className={`body${props.context ? ' bg-' + props.context : ''}`}>
                        {
                            props.title &&
                            <div className="title text-primary">{props.title}</div>
                        }
                        <div className="text">
                            {props.text}
                        </div>
                        <div className="actions">
                            <button className="lc-button bg-primary" onClick={() => { props.onClose && props.onClose() }}>{props.textBtnOk || 'Ok'}</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Alert;