import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { PrefState } from '../../../store/reducers/prefReducer';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable"
import moment from 'moment';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import {  PPModal } from 'processor-plataform-ui'
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import { HeyHoService } from '../../../services/heyHoService';
import { HeyHoProps } from '../../Heyho/List';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import HeyHoNewRequest, { FormDataHeyho } from '../../../components/Generic/HeyHoNewRequest';
import {  PiHeadsetLight, PiListLight } from 'react-icons/pi';
import FilterPanel from '../../../components/Data/FilterPanel';
import LcNoData from '../../../components/Generic/LcNoData';

interface Filter {
    device: string,
    process: string,
    classifications: string,
    severities: string,
    firstSeenFrom: string,
    firstSeenTo: string,
    sorting: string
}

interface Events {
    eventId: number,
    device: string,
    process: string,
    classification: string,
    classificationValue: string,
    classificationLabel: string,
    firstSeen: string,
    lastSeen: string,
    sorting: string
    severity: string,
    severityValue: string,
    severityLabel: string,
    processOwner: string
    processPath: string,
    destinations: string
}

const classifications = [
    { value: '', label: 'Todas' },
    { value: 'Malicious', label: 'Maliciosa' },
    { value: 'Suspicious', label: 'Suspeita' },
    { value: 'Inconclusive', label: 'Inconclusiva' },
    { value: 'Likely Safe', label: 'Provavelmente segura' },
    { value: 'PUP', label: 'PUP' },
    { value: 'Safe', label: 'Segura' }
];

const severities = [
    { value: '', label: 'Todas' },
    { value: 'Critical', label: 'Crítica' },
    { value: 'High', label: 'Alta' },
    { value: 'Medium', label: 'Média' }
];



const EndpointDetectionResponseEvents: React.FC<HeyHoProps> = (props) => {
    
    const [ServiceInstance] = useState<HeyHoService>(new HeyHoService(props));
    const defaultFilter: Filter = {
        device: "",
        process: "",
        classifications: "",
        severities: "",
        firstSeenFrom: "",
        firstSeenTo: "",
        sorting: ""
    }
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceInstance.emptyRecord
    };
    
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredEvents, setFilteredEvents] = useState<Events[]>([]);
    const [devicesGroup, setDevicesGroup] = useState<any[]>(['']);
    const [selectedDevice, setSelectedDevice] = useState<any>();
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [eventsLength, setEventsLength] = useState<number>(0);
    const [periodFilter, setPeriodFilter] = useState<string>('last30Days')
    const [newRequestModalVisible, setNewRequestModalVisible] = useState<boolean>(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState<boolean>(false);
    const [record, setRecord] = useState(emptyRecordMessage);
    const [allEvents, setAllEvents] = useState<any[]>([])
    const [allEventsFiltered, setAllEventsFiltered] = useState<any[]>([])
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
        gtmType: 0,
    });

    const dispatch = useDispatch();
    
    
    const fieldsForm: PPFormInput[] = [
        {
            name: 'contrato',
            type: 'dropdown',
            title: 'Contrato',
            required: true,
            options: ServiceInstance.state.data.contratos,
            // value: contratRequest
        }, {
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
        }, {
            name: 'ServiceTree',
            type: 'dropdown',
            title: 'Árvore de serviço',
            required: true,
            options: record.record.contrato >= 0 ? ServiceInstance.state.data.chamadoTree.filter((y: any) => {
                let gtmids = (ServiceInstance.state.data.contratos as any[]).find((c: any) => c.value === record.record.contrato)?.gtmids || [];
                return gtmids.includes(y.gtmid);
            }) : ServiceInstance.state.data.chamadoTree,
            // value: ServiceTreeRequest
        },
        {
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: 15,
        },
        {
            name: 'Anexo',
            type: 'file',
            title: 'Anexar arquivos: Coloque evidencias que ajudem a entender o contexto',
        }
    ]

    const noDeviceLabel = "(Dispositivo não informado)";

    const status = (row: any) => {
        switch (row.classification) {
            case 'Safe':
                return '#81bc00';
            case 'Likely Safe':
                return '#969696';
            case 'PUP':
                return '#ddbb7b';
            case 'Suspicious':
                return '#d3281d';
            case 'Malicious':
                return '#dc291e';
            case 'Inconclusive':
                return '#ffa400';
            default:
                return '#b3b3b3';
        }
    }
    const translateClassification = (classification: string) => {
        const classficiation = {
            'Suspicious':'Suspeita',
            'Malicious':'Maliciosa',
            'Inconclusive':'Inconclusiva',
            'Likely Safe':'Provavelmente segura',
            'PUP':'PUP',
            'Safe':'Segura'
        }
        return classficiation[classification]
    }

    const translateSeverity = (severity: string) => {
        const classficiation = {
            'Critical':'Crítica',
            'High':'Alta',
            'Medium':'Media',
            'Low':'Baixa'
        }
        return classficiation[severity]
        
    }
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setPeriodFilter(date);
    };

    const beginOfMonth = () => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
    
        let result = false;
    
        if (dayOfMonth > 0) {
            result = false;
        } else {
            result = true;
        }
        return result;
    
    }

    const loadData =  useCallback( async () => {
        setLoading(true);
        try {
            const endpointDetectionResponseService = new EndpointDetectionResponseService({});
            const response  = await endpointDetectionResponseService.GetAllEvents()
            if (response.data.length > 0) {
                setAllEvents(response.data)
                let _events = response.data
                    .map((item: Events) => {

                        const classification = classifications.find(c => c.value === item.classification);
                        const severity = severities.find(c => c.value === item.severity);
                        return {
                            ...item,
                            device: item.device !== "" ? item.device : noDeviceLabel,
                            classificationValue: classification?.value,
                            classificationLabel: classification?.label,
                            severityValue: severity?.value,
                            severityLabel: severity?.label
                        };
                    })
                    .sort((a: Events, b: Events) => a.eventId > b.eventId ? 1 : -1);
    
                let _groupedEvents = groupByField(_events, 'device');
                if (Object.keys(_groupedEvents).length === 0)
                    _groupedEvents = [];
    
                let _devices = Object.keys(_groupedEvents)
                    .sort((a, b) => a < b || b === noDeviceLabel || a === noDeviceLabel ? -1 : 1);
    
                setDevicesGroup(_devices);
                setFilteredEvents([_groupedEvents]);
                setEventsLength(countUniqueEvents(_events));                    
            }
            
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    },[])

    const countUniqueEvents = (loadedEvents: any): number => {
        let _allIds: any[] = [];
        if (Array.isArray(loadedEvents))
            _allIds = loadedEvents.map((le: Events) => le.eventId);
        else
            Object.keys(loadedEvents)
                .forEach(dev => loadedEvents[dev].forEach(le => _allIds.push(le.eventId)));

        let _uniqueIds = [...new Set(_allIds)];

        return _uniqueIds.length;
    }

    const groupByField = (array: any[], campo: string) => {
        const arrayCampo: object[] = [];
        return array.reduce((grupos, objeto) => {
            const valorCampo = objeto[campo];
            arrayCampo.push(objeto[campo])
            if (grupos[valorCampo]) {
                grupos[valorCampo].push(objeto);
            } else {
                grupos[valorCampo] = [objeto];
            }
            return grupos;
        }, {});
    }

    useEffect( () => {
        loadData()
    }, [ServiceInstance, loadData, user.ClientGroupSelected, user.refreshFlag])

    const filterArrayPerDate = useCallback((array: any[], period: number) => {
        const today = new Date();
        const daysAgoDate = new Date(today);
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0);

        return array.filter(data => {
            return 'lastSeen' in data && data.lastSeen && new Date(data.lastSeen) >= daysAgoDate && new Date(data.lastSeen) <= today;
        });
    }, []);


    useEffect(() => {
        if (!periodFilter) return;
    
        const daysMap = {
            'last7Days': 7,
            'last30Days': 30,
            'last90Days': 90,
            'alltime': null
        };
    
        const days = daysMap[periodFilter as keyof typeof daysMap];
    
        if (days === undefined) {
            console.warn(`Unexpected period value: ${periodFilter}`);
            return;
        }
        
        const events: any[] = days === null ? allEvents : filterArrayPerDate(allEvents, days);
        setAllEventsFiltered(events)
        let _groupedEvents = groupByField(events, 'device');
        let _devices = Object.keys(_groupedEvents);
        
        setDevicesGroup(_devices);
        setFilteredEvents([_groupedEvents]); // Certifique-se de que está passando um array de grupos corretamente
        setEventsLength(countUniqueEvents(_groupedEvents));

    }, [allEvents, periodFilter, filterArrayPerDate]);

    const handleNewRequest = (row) => {
        setNewRequestModalVisible(true);
        let _record = record.record;

        _record.titulo = `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`;
        _record.Descricao = `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`;
        fieldsForm[1].value = `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`;
        fieldsForm[3].value = `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`;

        setRecord({ ...record, record: _record })
        setFormData({
            title: `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`,
            description: `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`
        })
    }

    const columns = [
        { field: 'eventId', headerName: 'Dispositivo / ID', width: "15%" },
        { field: 'device', headerName: 'Dispositivo', width: "17%", hide: devicesGroup.length !== 0 },
        {
            field: 'process',
            headerName: 'Processo',
            width: "25%",
            truncate: true            
        },

        { field: 'classification', headerName: 'Classificação', width: "12%", renderCell: (row: any) => translateClassification(row.classification) },
        { field: 'severities', headerName: 'Criticidade', width: "12%", renderCell: (row: any) => translateSeverity(row.severity) },
        {
            field: 'firstSeen', headerName: 'Registrado em', width: "20%",
            renderCell: (row: any) => {
                return moment(row.firstSeen).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        {
            field: 'lastSeen', headerName: 'Última ocorrência', width: "20%",
            renderCell: (row: any) => {
                return moment(row.lastSeen).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        {
            field: 'newRequest', headerName: '', width: "38px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon={ <PiHeadsetLight/> }size='small' tooltip={"Novo chamado"} tooltipPosition='inline-left' onClick={() => {
                        handleNewRequest(row);
                }} />
            }
        },
        {
            field: 'id', headerName: '', width: "38px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon={<PiListLight />} size='small' tooltip={"Detalhes"} tooltipPosition='inline-left' onClick={() => {
                    setSelectedDevice(row); setDetailsModalVisible(true);
                }} />
            }
        }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;

        // Sort com os dados já carregados
        let _events = { ...filteredEvents[0] };
        Object.keys(_events).forEach(device => {
            _events[device] = _events[device].sort((a: any, b: any) => {
                if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
            });
        });
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }


    const periodSelection = {
        periods: [
            {
                label: 'Útimos 7 dias', text: 'Útimos 7 dias',
                fields: [
                    { name: 'last7Days', value: 'last7Days' },
                    
                ],
                default: beginOfMonth()
            },
            {
                label: 'Útimos 30 dias', text: 'Útimos 30 dias',
                fields: [
                    { name: 'last30Days', value: 'last30Days' }
                ],
                default: true
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: 'last90Days' }
                ],
                default: beginOfMonth()
            },
            {
                label: 'Período total', text: 'Período total',
                fields: [
                    { name: 'alltime', value: 'alltime' },
                    
                ],
                default:  beginOfMonth()
            },
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    console.log(filteredEvents)
    const card: Card[] = [
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix'>
                        {
                            filteredEvents.length === 0 ?
                            <LcNoData size="default" label='Não há dados para serem exibidos no momento.' />
                            :
                            <LcInfiniteTable
                                tooltipContentColumn={'classificationLabel'}
                                columns={columns}
                                rows={filteredEvents}
                                groupName={devicesGroup}
                                size={eventsLength}
                                loadMore={() => { }}
                                notLoadMore={true}
                                hidePagination
                                onSortChange={onSortChange}
                                status={status}
                                density={pref.data?.density || "high"}
                                noDataMessage='Não há dados para serem exibidos no momento.'
                            />
                        }

                    </LcLoading>
                )
            }

        }
    ]

    const translations = {
        eventId: {
            label: "ID do evento",
            type: "text",
        },
        process: {
            label: "Processo",
            type: "text",
        },
        classification: {
            label: "Classificação",
            type: "multiSelect",
            values:{
                "Malicious":'Malicioso',
                "Suspicious": 'Suspeito',
                "PUP":"PUP",
                "Safe": "Segura",
                "Likely Safe": "Provavelmente segura",
                "Inconclusive": "Inconclusiva"
            }
        },
        severity: {
            label: "Criticidade",
            type: "multiSelect",
            values: {
                "Critical":"Crítica",
                "High":"Alta",
                "Medium":"Média"
            }
            
        },
        firstSeen: {
            label: "Registrado a partir de",
            type: "spanDateTime",
        },
        lastSeen: {
            label: "Última ocorrência a partir de",
            type: "spanDateTime",
        },
    }

    const handleFiltered = (filtered: any) =>{
        setLoading(true)
        if(filtered.length === 0) {
            setDevicesGroup([])
            setFilteredEvents([])
            setEventsLength(0) 
        } else {
            let _groupedEvents = groupByField(filtered, 'device');
            let _devices = Object.keys(_groupedEvents);
    
            setDevicesGroup(_devices);
            setFilteredEvents([_groupedEvents]); // Certifique-se de que está passando um array de grupos corretamente
            setEventsLength(countUniqueEvents(_groupedEvents));
        }
        setLoading(false)
    }
    
    const handleClearFilter =() => {
        setLoading(true)
        let _groupedEvents = groupByField(allEventsFiltered, 'device');
        let _devices = Object.keys(_groupedEvents);
    
        setDevicesGroup(_devices);
        setFilteredEvents([_groupedEvents]); // Certifique-se de que está passando um array de grupos corretamente
        setEventsLength(countUniqueEvents(_groupedEvents));
        setLoading(false)
    }

    const filterSystem= () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    data={allEventsFiltered}
                    onFilteredData={(filteredData) => {handleFiltered(filteredData as any[]);}}
                    translations={translations}
                    clearFilters={handleClearFilter}
                />
            </div>
        )
    }


    return (
        <Layout
            periodSelection={periodSelection}
            pageTitle="Eventos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: eventsLength
            }}
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    //setShow: setFilterVisible
                }}
            />

            <PPModal title={"Detalhes " + selectedDevice?.eventId} onClose={() => { setDetailsModalVisible(false); setSelectedDevice(undefined); }} visible={detailsModalVisible} loading={!selectedDevice} >
                {
                    selectedDevice &&
                    <>
                        <p><b>Dispositivo:</b> {selectedDevice.device}</p>
                        <p><b>Classificação:</b> {selectedDevice.classificationLabel}</p>
                        <p><b>Usuário:</b> {selectedDevice.processOwner !== "" ? selectedDevice.processOwner : "(Sem informações)"}</p>
                        {/*  <p><b>Processo:</b> {selectedDevice.process}</p>*/}
                        <p><b>Caminho:</b> {selectedDevice.processPath}</p>
                        <p><b>Destinos:</b> {selectedDevice.destinations}</p>
                        <p><b>Criticidade:</b> {severities.find(s => s.value === selectedDevice.severity)?.label ?? "(Sem informações)"}</p>
                        {/*<p><b>Registrado a partir de:</b> {selectedDevice.firstSeenFrom}</p>*/}
                        {/*<p><b>Última ocorrência a partir de:</b> {selectedDevice.lastSeenFrom}</p>*/}
                    </>
                }
            </PPModal>
            <HeyHoNewRequest 
                openModal={newRequestModalVisible} 
                onClose={() => { setNewRequestModalVisible(false);} } 
                needAtachmente 
                formData={formData}
            />
        </Layout>
    );
};

export default EndpointDetectionResponseEvents;