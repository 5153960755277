import React, { useEffect, useState } from 'react';

//Components
import Layout from '../../../components/Layout/Layout';
import LcCheckList from '../../../components/Form/LcCheckList';
import { PPModal } from 'processor-plataform-ui'
import PPExpand from '../../../components/Generic/PPExpand';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

import history from '../../../history';

//Img Fluxo
import ImgFluxo from '../../../assets/images/Fluxos/eaeas.png';

//CSS
import './index.css';

//Service
import { TechEAEASService, SKUs, LicsModel, License } from '../../../services/EASubscriptionService/techEAEASService';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import FilterPanel from '../../../components/Data/FilterPanel';
import LcLoading from '../../../components/Generic/LcLoading';
import { PiArrowsDownUp, PiArrowsDownUpLight, PiFunnelLight } from 'react-icons/pi';

const SubscriptionBycountryNoCCReport: React.FC = (props) => {

    let instance = new TechEAEASService(props);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<License[]>([]);
    const [dataAux, setDataAux] = useState<License[]>([]);

    const user = useSelector<RootState, UserState>(state => state.user);

    const [itemsA, setItemsA] = useState<{ value: string, selected: boolean }[]>([]);
    const [itemsB, setItemsB] = useState<{ value: string, selected: boolean }[]>([]);
    const [itemsC, setItemsC] = useState<{ value: string, selected: boolean }[]>([]);

    //Modal Filter
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    //Order
    const [order, setOrder] = useState(["country", "license", "costCenter"]);
    const [newOrder, setNewOrder] = useState(["country", "license"]);
    const [modalOrderVisible, setModalOrderVisible] = useState(false);

    const getData = () => {
        setLoading(true);
        user && instance.GetSaasLicenseByCountryTotalView()
            .then(response => {
                setData(response.data);
                setDataAux(response.data);
            })
            .catch(error => {
                console.debug(error)
            })
            .then(() => {
                setLoading(false);
            });
    }

    useEffect(getData, [user.ClientGroupSelected, user.refreshFlag]);


    useEffect(() => {

        let _itemsA: { value: string, selected: boolean }[] = [];
        let _itemsB: { value: string, selected: boolean }[] = [];
        let _itemsC: { value: string, selected: boolean }[] = [];

        data.map(license => {
            if (_itemsA.findIndex(c => c.value == license[order[0]]) == -1) {
                _itemsA.push({ value: license[order[0]], selected: true });
            }
            if (_itemsB.findIndex(c => c.value == license[order[1]]) == -1) {
                _itemsB.push({ value: license[order[1]], selected: true });
            }
            if (_itemsC.findIndex(c => c.value == license[order[2]]) == -1) {
                _itemsC.push({ value: license[order[2]], selected: true });
            }
        })

        setItemsA(_itemsA.sort((a, b) => a.value > b.value ? 1 : -1));
        setItemsB(_itemsB.sort((a, b) => a.value > b.value ? 1 : -1));
        setItemsC(_itemsC.sort((a, b) => a.value > b.value ? 1 : -1));
    }, [data]);

    const textToLabel = (text: string) => {
        switch (text) {
            case "country":
                return "País"
            case "license":
                return "Softwares"
            case "costCenter":
                return "Centro de Custo"
        }
    }

    const translationsGerencial = {
        country: {
            label: "País",
            type: "multiSelect",
        },
        costCenter: {
            label: "Centro de custo",
            type: "multiSelect",
        },
        license: {
            label: "Softwares",
            type: "multiSelect",
        }
    };

    const filterGerencialHub = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translationsGerencial).map(key => ({
                        label: translationsGerencial[key].label,
                        name: key,
                        type: translationsGerencial[key].type,
                        values: translationsGerencial[key].values,
                    }))}
                    data={dataAux}
                    onFilteredData={(filteredData) => { console.log(filteredData); setData(filteredData as License[]); }}
                    translations={translationsGerencial}
                />
            </div>
        );
    };


    const cards: Card[] = [
        {
            id: 1,
            type: 'Custom',
            cssClass: 'transparetBackground',
            centeredContent: false,
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix'>
                        {
                            data.length == 0 ?
                                <div className="lc-segment">
                                    <div className="body">
                                        Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                        fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                                        <img src={ImgFluxo} width="100%" alt="Procedimento" />
                                    </div>
                                </div>
                                :
                                <div className='gerencialSoftwareHub' >
                                    <div className="lc-pivot-segments" >
                                        <div className="lc-segment primary" >
                                            <span className="title">TOTAL</span>
                                            {
                                                itemsB.map((s, si) => {
                                                    return (
                                                        s.selected &&
                                                        data.filter(l => l[order[1]] == s.value).length > 0 &&
                                                        <div key={si} className="secondary">

                                                            <div className="left">
                                                                <span className="title">{s.value}</span>
                                                                {(() => {
                                                                    var TotalCCTotalHired = 0;
                                                                    var TotalCCTotalUsed = 0;
                                                                    itemsA.map((c, ci) => {
                                                                        if (c.selected) {
                                                                            const CC = data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value)[0];
                                                                            TotalCCTotalHired += CC ? CC.totalHired : 0;
                                                                            var totalUsedCC = data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0)
                                                                            TotalCCTotalUsed += totalUsedCC
                                                                        }
                                                                    });
                                                                    return TotalCCTotalHired ?
                                                                        (<span className={`info ${TotalCCTotalUsed > TotalCCTotalHired ? 'text-danger' : ''}`}>{`Utilizando ${TotalCCTotalUsed} de ${TotalCCTotalHired}, diferença ${TotalCCTotalHired - TotalCCTotalUsed}`}</span>) : <span className="info">Não há licenças</span>
                                                                })()}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            itemsA.map((c, ci) => {
                                                return (
                                                    c.selected &&
                                                    <div key={ci} className="lc-segment primary">

                                                        <span className="title">{c.value}</span>
                                                        {
                                                            itemsB.map((s, si) => {
                                                                return (
                                                                    s.selected &&
                                                                    data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).length > 0 &&
                                                                    <div key={si} className="secondary">

                                                                        <div className="left">
                                                                            <span className="title">{s.value}</span>
                                                                            {(() => {
                                                                                const firstCC = data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value)[0];
                                                                                return firstCC ? (<span className={`info ${data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0) > firstCC.totalHired ? 'text-danger' : ''}`}>{`Utilizando ${data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0)} de ${firstCC.totalHired}, diferença ${firstCC.totalHired - data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0)}`}</span>) : <span className="info">Não há licenças</span>
                                                                            })()}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                        }
                    </LcLoading>
                )
            }
        }
    ]

    return (
        <Layout
            pageTitle="Softwares - visão gerencial "
            loading={false}
            functionsGeneric={
                data.length > 0 ? [
                    { icon: <PiArrowsDownUpLight />, tooltip: "Ordem", onClick: () => setModalOrderVisible(!modalOrderVisible) },
                    { icon: <PiFunnelLight />, tooltip: "Filtro", onClick: () => setModalVisible(!modalVisible) }
                ] : undefined}
        >
            <LCDashboard
                cards={cards}
                cssClassPanel='scrollable-v'
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: modalVisible,
                    content: () => filterGerencialHub()
                }}
            />

            <PPModal size='Medium' title="Ordem" visible={modalOrderVisible}
                onClose={setModalOrderVisible}
                functions={[
                    {
                        label: "Salvar", onClick: () => {
                            setModalOrderVisible(false);
                            setOrder([...newOrder, "costCenter"]);
                            getData();
                        },
                        context: "info"
                    },
                    {
                        label: "Fechar",
                        onClick: () => {
                            setModalOrderVisible(false);
                            setNewOrder(order);
                        },
                        context: "grey"
                    }
                ]}
            >
                {
                    newOrder
                        .map((o, i) => {
                            return <div key={i} className='row between mb-5'>
                                <span>{textToLabel(o)}</span>
                                <div className='row'>
                                    <LcIconLink disabled={loading || i === 0} icon='ppi ppi-arrow-up'
                                        onClick={() => { setNewOrder([newOrder[1], newOrder[0]]) }}
                                    />
                                    <LcIconLink disabled={loading || i === 1} icon='ppi ppi-arrow-down'
                                        onClick={() => { setNewOrder([newOrder[0], newOrder[1]]) }}
                                    />
                                </div>
                            </div>
                        })
                }
            </PPModal>

            {/* <PPModal title="Filtro" visible={false} onClose={() => setModalVisible(false)}>
                <PPExpand title={textToLabel(order[0])} marginBotton="15" expanded={true}>
                    {
                        itemsA.length > 0 &&
                        <LcCheckList list={itemsA} toggle={true} onChange={setItemsA} search={true} searchPlaceHolder={`(Procure por ${textToLabel(order[0])})`} title={textToLabel(order[0])} />
                    }
                </PPExpand>
                <PPExpand title={textToLabel(order[1])} marginBotton="15" expanded={false}>
                    {
                        itemsB.length > 0 &&
                        <LcCheckList list={itemsB} toggle={true} onChange={setItemsB} search={true} searchPlaceHolder={`(Procure por ${textToLabel(order[0])})`} title={textToLabel(order[1])} />
                    }
                </PPExpand>
            </PPModal> */}
        </Layout >
    );
}

export default SubscriptionBycountryNoCCReport;