export class eaeas {
    solution() {
        return 'eaeas'
    }

    site() {
        return 'https://www.processor.com.br/ea-eas'
    }

    logotipo() {
        return require('./assets/logotipos/logo_eaeas.svg').default
    }
    titulo() {
        return <>Maximize o valor dos seus <b>contratos Microsoft</b> com Software Hub</>
    }

    chamada() {
        return <><b>Software Hub</b> não é apenas uma ferramenta de gestão de subscrições. É uma solução que coloca sua organização no controle total das subscrições Microsoft, garantindo conformidade, otimização de recursos e decisões estratégicas baseadas em dados.</>
    }

    image() {
        return require('./assets/imgs/imagem_eaeas.png').default
    }

    detalhes() {
        return <>
            <h2>Quer garantir que suas licenças de software sejam usadas de forma eficiente e que sua empresa esteja sempre preparada para as revisões contratuais?</h2>
            <p>Conheça o Software Hub, a solução que sua organização precisa para maximizar o valor dos contratos Microsoft e assegurar o uso estratégico das subscrições.</p>
            <h3>Por que escolher Software Hub</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_eaeas.png').default} />
                </div>
                <ul>
                    <li><b>Visão estratégica:</b> Tenha uma visão unificada e global do uso das suas subscrições Microsoft, permitindo decisões mais inteligentes e alinhadas com a estratégia do negócio.</li>
                    <li><b>Conformidade garantida:</b> Assegure que sua empresa esteja sempre em conformidade com os contratos de licenciamento, evitando riscos e penalidades.</li>
                    <li><b>Otimização de recursos:</b> Identifique gaps e oportunidades de economia, maximizando o retorno sobre o investimento em licenças de software.</li>
                    <li><b>Preparação para o futuro:</b> Esteja sempre preparado para revisões contratuais e novas necessidades de licenciamento, garantindo que sua infraestrutura de software suporte o crescimento do negócio.</li>                    
                </ul>
            </div>
            <div className="box">Com o Software Hub, sua organização terá maior controle e transparência na <b>gestão de subscrições Microsoft</b>, impulsionando a eficiência operacional e a otimização de custos.</div>
            <h3>Não perca tempo!</h3>
            <p>Não deixe que a falta de visibilidade e controle sobre as licenças de software comprometam o desempenho e a conformidade do seu negócio. Com o Software Hub, sua empresa estará pronta para maximizar o valor dos contratos Microsoft e garantir o sucesso contínuo.</p>
        </>
    }
}
export default eaeas;