import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import LcLoading from '../../components/Generic/LcLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';

import { FilterObjectSend, IFilter, IOptions, ResourceSipContractService } from '../../services/resourceSipContractProcessor/resourceSipContractService';
import SideModal from '../../components/Layout/SideModal/SideModal';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import ReactDOM from 'react-dom';
  
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import LCPivotTable from '../../components/Data/pivotTable';
import LcCheckBox from '../../components/Form/LcCheckBox';
import { AppBar, Box, Button, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { PortalUsersService } from '../../services/portalUsers/portalUsers';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(value: any) {
    return {
        id: `full-width-tab-${value}`,
        "aria-controls": `full-width-tabpanel-${value}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value != index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value == index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ResourceDistribution: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const [IsLoading, setIsLoading] = useState<boolean>(false);
    const [pivotTableFilter, setPivotTableFilter] = useState<any>();
    const [IsLoadingContractRedirect, setIsLoadingContractRedirect] = useState<boolean>(false);
    const [sendResource, setSendResource] = useState<object[]>();
    const [data, setData] = useState<any[]>([]);
    const [dataVisible, setDataVisible] = useState<any[]>([]);
    const [selectedContract, setSelectedContract] = useState<any>({});
    const [serverFilter, setServerFilter] =
        useState<{ clientId: any, isLinked: any, search: any }>
            ({ clientId: user.ClientGroupSelected, isLinked: false, search: '' });
    const [showLinked, setShowLinked] = useState<boolean>(false);
    const [checkedIsDistributionBase, setCheckedIsDistributionBase] = useState<boolean>(false);
    const [checkedIsDistribution, setCheckedIsDistribution] = useState<boolean>(true);
    const [numberNumeroSIP, setNumberNumeroSIP] = useState<any>();
    const [numberCustomer, setNumberCustomer] = useState<any>();
    const [checkedSetContract, setCheckedSetContract] = useState<boolean>(false);
    const [hasFilter, setHasFilter] = useState<boolean>(false);
    const [contractsSideModal, setContractsSideModal] = useState<any[]>([]);
    const [isVisibleSideModalContractRedirect, setIsVisibleSideModalContractRedirect] = useState<boolean>(false);
    const [selectedResource, setSelectedResource] = useState<any[]>([]);
    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const [client, setClient] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const GetData = () => {
        setIsLoading(true)
        const servicePortalUsersService = new PortalUsersService(props);
        const service = new ResourceSipContractService(props);
        
        servicePortalUsersService.getClientActive()
            .then(response => {
                
                setClient(response.data);
            })
            .catch(console.warn)
            .then(() => {
                
            });

        service.GetCloudResourceView(year, month)
            .then(response => {
                
                setData(response.data);
            })
            .catch(console.warn)
            .then(() => {
                
                setIsLoading(false)
            });
    }

    useEffect(() => {
        setValue(0);
        GetData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        const service = new ResourceSipContractService(props);
        if (!(numberCustomer && numberCustomer.id))
            return;
        setContractsSideModal([]);
        setValue(1);
        setIsLoadingContractRedirect(true);
        service.GetContractsByClientID(numberCustomer.id)
            .then(response => {
                setContractsSideModal(response);
            }).catch(error => {
                enqueueSnackbar(`Erro ao buscar contratos`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                console.log(error)
            }).finally(() => {
                setIsLoadingContractRedirect(false)
            });
    }, [numberCustomer]);

    const SaveDistributionParameter = () => {
        
        enqueueSnackbar(`O recurso será vinculado aos parametros`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });

        setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect);
        
        const service = new ResourceSipContractService(props);
        var NumeroSIP = numberNumeroSIP && numberNumeroSIP.contractId ? numberNumeroSIP.contractId : '0';
        var Customer = numberCustomer && numberCustomer.id ? numberCustomer.id : '0';
        service.SaveDistributionParameter(pivotTableFilter,
            NumeroSIP,
            checkedIsDistributionBase,
            checkedIsDistribution,
            Customer, year, month)
            .then(response => {
                
                enqueueSnackbar(`Os recursos foram mudados de contrato com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false
                })
                GetData();
            }).catch(error => {
                enqueueSnackbar(`Erro ao mudar contrato do recurso do contrato`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
                console.log(error)
            });
    }

    const functions = [
        {
            icon: 'lci lci-folder-add',
            tooltip: 'Vincular',
            disabled: selectedResource.length < 1 || showLinked,
            func: () => {
                const service = new ResourceSipContractService(props);
                setSendResource(selectedResource);
                
                setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect);

                setIsLoadingContractRedirect(true)
                service.GetContracts()
                    .then(response => {
                        setContractsSideModal(response)
                    })
                    .catch(error => {
                        enqueueSnackbar(`Erro ao buscar contrato`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                        })
                        console.log(error)
                    }).then(() => {
                        setIsLoadingContractRedirect(false)
                    });
            }
        }
    ]
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
        user.refreshFlag = Math.random();
    }
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    return (
        <Layout pageTitle="Recursos">
            <LcLoading loading={IsLoading}>
                <div className="topbar row">
                    <div className="functions row" style={{ display: 'flex' }}>
                        <div style={{ height: 'calc(5vh)', overflow: 'hidden' }}>
                            <div className="item">
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    {
                                        functions &&
                                        <div style={{ display: 'flex ', alignItems: 'left', justifyContent: 'flex-start' }}>
                                            {
                                                functions.map((func, index) => {
                                                    return <LcIconLink
                                                        tooltipPosition={index == 0 ? 'left' : undefined}
                                                        disabled={false}
                                                        key={index}
                                                        icon={func.icon}
                                                        tooltip={func.tooltip}
                                                        onClick={() => { func.func() }} />
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LCPivotTable
                    onSetFilter={(s) => { setPivotTableFilter(s); }}
                    data={data}
                />
            </LcLoading>
            <SideModal width="35rem" header='Contratos' visible={isVisibleSideModalContractRedirect} onClose={() => { setIsVisibleSideModalContractRedirect(!isVisibleSideModalContractRedirect); setSendResource(undefined); }}>

                <div className="lc-checklist" style={{ height: 'calc(10vh)' }}>
                    <div className="list scrollable-v" style={{ overflow: 'hidden' }}>
                        <div className="item">
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <LcCheckBox checked={checkedIsDistributionBase} onChange={(d) => { setCheckedIsDistribution(!d.checked); setCheckedIsDistributionBase(d.checked) }} title={"Base para rateio"} name={"Base para rateio"} />
                                </div>
                                <div style={{ width: '50%', display: 'flex ', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <LcCheckBox checked={checkedIsDistribution} onChange={(d) => { setCheckedIsDistributionBase(!d.checked); setCheckedIsDistribution(d.checked) }} title={"Rateio"} name={"Rateio"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!checkedIsDistributionBase && <>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label={'Clientes'} {...a11yProps(0)} />
                            <Tab label={'Contratos'} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} key={0} >

                        <div className="lc-checklist" >
                            <div className="list scrollable-v" style={{ height: 'calc(45vh)', overflow: 'auto' }}>
                                <LcLoading loading={IsLoadingContractRedirect} label="Carregando clientes, aguarde">
                                    {
                                        client.map((row, index) => {
                                            return (
                                                <div key={index} className="item">
                                                    <LcCheckBox checked={row == numberCustomer} onChange={(d) => { setNumberCustomer(row); }} title={""} name={""} />
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <span>{row.description}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </LcLoading>
                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel value={value} index={1} key={1} >
                        <div className="lc-checklist" >
                            <div className="list scrollable-v" style={{ height: 'calc(45vh)', overflow: 'auto' }}>
                                <LcLoading loading={IsLoadingContractRedirect} label="Carregando contratos, aguarde">
                                    {
                                        contractsSideModal.map((row, index) => {
                                            return (
                                                <div key={index} className="item">
                                                    <LcCheckBox checked={row == numberNumeroSIP} onChange={(d) => { setNumberNumeroSIP(row); }} title={""} name={""} />
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <span>{row.contractName}</span>
                                                        <div style={{ width: '170px', display: 'flex ', alignItems: 'center', justifyContent: 'flex-end' }}> Contrato: {row.contractId}

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </LcLoading>
                            </div>
                        </div>
                    </TabPanel>
                </>
                }
                <div  >
                    <div style={{ height: 'calc(10vh)', overflow: 'hidden', marginTop: '10px' }}>
                        <div className="item">
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                <div style={{ width: '50%', display: 'flex ', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Button className="lc-button bg-primary mr-5" size="large" onClick={() => { SaveDistributionParameter(); }}>
                                        Salvar
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button className="lc-button bg-primary mr-5" size="large" onClick={() => { }}>
                                        Fechar
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </SideModal>
        </Layout >
    );
}

export default ResourceDistribution;