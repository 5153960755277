import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactECharts from 'echarts-for-react';
import LcNoData from '../../../../components/Generic/LcNoData';
import { FinOpsChartBuilder } from '../../../../services/finOps/FinOpsChartBuilder';
import { KipConsumptionModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { RootState } from "../../../../store";
import { UserState } from "../../../../store/reducers/userReducer";

interface Props {    
    kpi: any;
    resourceConsumptionKpi: KipConsumptionModel[];
    costCenterConsumptionKpi: KipConsumptionModel[];
    serviceConsumptionKpi: KipConsumptionModel[];
    rankType: string;
    rankTypeOption?: any;

}
const TopConsuption: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>((state) => state.user);
    const serviceChartPalette = ['#c9db22', '#40b910', '#0e5735', '#32730a', '#6da20a', '#8db908', '#b0d105', '#d5e802', '#fdff03'];
    const resourceChartPalette = ['#5969CD', '#8684EF', '#8684ef', '#7c6fd5', '#725bbc', '#6746a3', '#5b338b', '#4f1e73', '#42065c'];
    const costCenterChartPalette = ['#E64646', '#E07817', '#F3C944', '#d6c241', '#babb41', '#9fb243', '#86a946', '#6f9f4a', '#59944d'];
    const [finOpsChartService, setFinOpsChartService] = useState<FinOpsChartBuilder>(new FinOpsChartBuilder(props));

    const chartTopServiceCMS = useRef<ReactECharts>(null);
    const chartTopCCCMS = useRef<ReactECharts>(null);
    const chartTopResourceCMS = useRef<ReactECharts>(null);



    // Simplificando a verifica��o para dados vazios ou inexistentes
    const hasNoData = !props.kpi || !props.kpi.dailyConsumption || props.kpi.dailyConsumption.length === 0;

    // Console log para debugging
    console.log('Props kpi:', props.kpi);
    console.log('Rank Type:', props.rankType);


    useEffect(() => {
        // C�digo para atualizar o gr�fico baseado em props.kpi ou outras props relevantes
    }, [props.kpi, props.resourceConsumptionKpi, props.costCenterConsumptionKpi, props.serviceConsumptionKpi, user.ClientGroupSelected, user.refreshFlag]);



    return <>
        {hasNoData ? (
            <LcNoData size="small" />
        ) : (
            <div style={{ width: '100%', height: '95%' }}>
                <div className="chart-container">
                    <div className="chart-content">
                        {props.rankType == 'service' && <div style={{ width: '100%', height: '100%' }}>
                            <ReactECharts
                                className="chart-treemap"
                                ref={chartTopServiceCMS}
                                style={{ width: '100%', height: '100%' }}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={finOpsChartService.getConsumptionChart(props.serviceConsumptionKpi, serviceChartPalette, props.kpi, false, props.rankTypeOption)}
                            />
                        </div>
                        }

                        {props.rankType == "resource" &&
                            <div style={{ width: '100%', height: '100%' }}>
                                <ReactECharts
                                    className="chart-treemap"
                                    ref={chartTopResourceCMS}
                                    style={{ width: '100%', height: '100%' }}
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getConsumptionChart(props.resourceConsumptionKpi, resourceChartPalette, props.kpi, false, props.rankTypeOption)}
                                />
                            </div>
                        }
                        {props.rankType == "costCenter" &&
                            <div style={{ width: '100%', height: '100%' }}>
                                <ReactECharts
                                    className="chart-treemap"
                                    ref={chartTopCCCMS}
                                    style={{ width: '100%', height: '100%' }}
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getConsumptionChart(props.costCenterConsumptionKpi, costCenterChartPalette, props.kpi, false, props.rankTypeOption)}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )}
    </>

};

export default TopConsuption;