export class diime {
    solution() {
        return 'diime'
    }

    site() {
        return 'https://www.gotobiz.com.br/diime'
    }

    logotipo() {        
        return require('./assets/logotipos/logo_diime.svg').default
    }
    titulo() {
        return <>Transforme o relacionamento com o cliente com Diime!</>
    }

    chamada() {
        return <>Solução inteligente de autoatendimento digital que exponencializa o relacionamento com o cliente. Com Diime!, a organização pode oferecer uma experiência omnichannel, aumentando a produtividade e obtendo melhores resultados para o negócio.</>
    }

    image() {
        return require('./assets/imgs/imagem_diime.png').default
    }

    detalhes() {
        return <>
            <h2>Diime! - Plataforma inteligente de autoatendimento digital</h2>
            <p><b>Diime! é a solução ideal para os mais diversos segmentos, incluindo contabilidade, ensino, serviços, tecnologia, telecomunicações, varejo, imobiliário e saúde.</b></p>
            <h3>Por que escolher Diime!?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_diime.png').default} />
                </div>
                <ul>
                    <li><b>Atendimento automatizado 24x7:</b> Está sempre disponível para atender aos clientes, independentemente do horário ou do dia.</li>
                    <li><b>Integração com sistemas de ITSM:</b> Pode ser integrado com sistemas de ITSM, como Hey!Ho!, e outros sistemas em geral.</li>
                    <li><b>Comunicações humanizadas:</b> Com Diime!, a organização pode oferecer uma experiência de atendimento ao cliente personalizada e humanizada.</li>
                </ul>
            </div>
            <div className="box"><b>Diime! faz parte da família de soluções de hiperautomação da Gotobiz</b>, que combina várias tecnologias de automação para criar ambientes altamente automatizados e eficientes. A hiperautomação maximiza a produtividade, reduz erros e permite que a organização se concentre em atividades mais estratégicas.</div>            
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como Diime! pode transformar o relacionamento com o cliente.</p>            
        </>
    }
}
export default diime;