import api, { APIClient } from "./api";


export default class AdmiPoliciesService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    RetrieveList() {
        const url = this.endpoint + `/api/AdminPolicies/RetrieveList`;
        return api({
            method: 'get',
            url,
        });
    }

    RetrievePolicyList() {
        const url = this.endpoint + `/api/AdminPolicies/RetrievePolicyList`;
        return api({
            method: 'get',
            url,
        });
    }

    Active(item: any[]) {
        const url = `${this.endpoint}/api/AdminPolicies/Active`;
        var response = api({
            method: 'post',
            url,
            data: item,
        });
        return response;
    }

    DeletePolicyAssignment(item: any[]) {
        const url = `${this.endpoint}/api/AdminPolicies/DeletePolicyAssignment`;
        var response = api({
            method: 'post',
            url,
            data: item,
        });
        return response;
    }
}