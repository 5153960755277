import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import LcNoData from '../../../../components/Generic/LcNoData';

interface Props {
    hasAlerts: boolean;
    chartOptions: any;
    alertDescription: string;
}

const AlertConsuption: React.FC<Props> = (props) => {
    const chartAlert = useRef<ReactECharts>(null);
  return <>
    {props.hasAlerts ?
     (
        <div className='alerta-container w-100p'>
            <div className='alerta-content'>
                <div className='icon'>
                    <i className='lci lci-exclamation' />
                </div>
                <ReactECharts
                    ref={chartAlert}
                    lazyUpdate={true}
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    style={{ height: "100%", width: '100%' }}
                    option={props.chartOptions}
                />
            </div>
            <div className='subtitle-dash ellipsis' title={props.alertDescription}>
                {props.alertDescription}
            </div>
        </div>
    ) : (
        <LcNoData size="small" label="Sem dados no período." />
    )}
    </>;
}

export default AlertConsuption;