import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import Layout from "../../../components/Layout/Layout";
import { LCTechService } from "../../../services/LCTech/LCTechService";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { UserState } from "../../../store/reducers/userReducer";
import { PrefState } from '../../../store/reducers/prefReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';
import LcLoading from "../../../components/Generic/LcLoading";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { TechEAEASService } from "../../../services/EASubscriptionService/techEAEASService";
import history from "../../../history";
//Img Fluxo
import { SaasService } from "../../../services/saas/saasService";
import ImgFluxoEAEAS from "../../../assets/images/Fluxos/eaeas.png";
import ImgFluxoCSP from "../../../assets/images/Fluxos/csp.png";
import LcTable from "../../../components/Data/LcTable";
import LcDropDown from "../../../components/Generic/LcDropDown";
import { SelectField } from "../../../components/Form/Input";
import { NotificationCenterService } from "../../../services/notificationCenter/notificationService";
import LCDashboard, { Card } from "../../../components/Data/Dashboard/LCDashboard";
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcTooltip from "../../../components/Generic/LcTooltip";
import { SaasUserService } from "../../../services/SaasUserService";
import PivotTableWebix from "../../../components/webix";
import LcNoData from "../../../components/Generic/LcNoData";
import FilterPanel from "../../../components/Data/FilterPanel";

interface Subscritions {
    skuPartNumber: string,
    consumedUnits: number,
    prepaidUnid: number,
    partnerCount: number,
    displayName: string,
    effectiveStartDate: null,
    autoRenewEnabled: null,
    partnerBillingType: null
}

interface OnPremiseSoftware {
    softwareName: string;
    quantidade: number;
}

interface Domain {
    name: string;
}

const LCTech: React.FC = (props) => {

    const isDev = process.env.NODE_ENV == "development";
    const ServiceInstance = new LCTechService({});
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);

    // const [footer, setFooter] = useState<string>('');
    const [notificationSrvc, setNotificationSrvc] = useState(new NotificationCenterService(props));
    // const [lastModifiedDate, setLastModifiedDate] = useState<Date>();
    const [loading, setLoading] = useState(true);
    const [isCSP, setIsCSP] = useState(false);

    const [isSaaS, setIsSaaS] = useState(false);

    const [subscriptions, setSubscriptions] = useState<Subscritions[]>([]);
    const [onPremiseSofwares, setOnPremiseSofwares] = useState<any[]>([]);
    const [domains, setDomains] = useState<any[]>([]);

    const [originalSubscriptions, setOriginalSubscriptions] = useState<Subscritions[]>([]);
    const [originalOnPremiseSoftwares, setOriginalOnPremiseSoftwares] = useState<OnPremiseSoftware[]>([]);
    const [originalDomains, setOriginalDomains] = useState<Domain[]>([]);
    const SaaS_Service = new SaasUserService(props);
    const [formattedData, setFormattedData] = useState<any[]>();
    const [expandedData, setExpandedData] = useState<any[]>();
    const [loadingLicenseFormattedData, setLoadingLicenseFormattedData] = useState<boolean>();
    const [pivotlicensesFocus, setPivotlicensesFocus] = useState<boolean>(false);
    const [filterKey, setFilterKey] = useState(0);

    const loadUsers = async () => {
        try {
            setLoadingLicenseFormattedData(true);
            var expandedDataLocal = await SaaS_Service
                .GetUserServices();

            setExpandedData(expandedDataLocal.pivot);
            setFormattedData(expandedDataLocal.pivot);
        } catch (e) {
            console.error(e);
        }
        setLoadingLicenseFormattedData(false);
    };

    const RetrieveList = async () => {
        setLoading(true);
        setSubscriptions([]);
        setDomains([]);
        // let optionsData: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: '2-digit' };
        // var date = new Date();
        await ServiceInstance.Retrieve(1)
            .then((response) => {
                if (response && response.data) {
                    setIsCSP(response.data.isCSP);
                    if (
                        response.data.subscriptions &&
                        response.data.subscriptions.length > 0
                    ) {
                        setSubscriptions(response.data.subscriptions);
                        setOriginalSubscriptions([...response.data.subscriptions]);
                    }

                    if (
                        response.data.softwaresList &&
                        response.data.softwaresList.length > 0
                    ) {
                        setOnPremiseSofwares(response.data.softwaresList);
                        setOriginalOnPremiseSoftwares([...response.data.softwaresList]);
                    }

                    if (response.data.processMesssages && response.data.processMesssages.length > 0) {
                        response.data.processMesssages.map(msgTag => {
                            let msg = {
                                subject: "Cadastro incorreto",
                                content: msgTag,
                                context: "warning"
                            };
                            notificationSrvc.PostInterfaceNotification(dispatch, msg);

                        })

                    }
                    // try {
                    //     try {
                    //         var dateD = date;// sCdateToJsDate(d.lastModifiedDate);

                    //         if (dateD) {
                    //             var firstDay = new Date(dateD.getFullYear(), dateD.getMonth(), 1);
                    //             var Day = dateD;
                    //             setFooter(`Período de apuração: ${firstDay.toLocaleDateString('pt-BR', optionsData)} a ${Day.toLocaleDateString('pt-BR', optionsData)}. Valores estimados, sujeitos a validação e politicas de contabilização do vendor. Em ${Day.toLocaleDateString('pt-BR', optionsData)}`);

                    //         }
                    //     } catch (e) {
                    //         setLastModifiedDate(new Date());
                    //         if (lastModifiedDate) {
                    //             var firstDay = new Date(lastModifiedDate.getFullYear(), lastModifiedDate.getMonth(), 1);
                    //             var Day = new Date();
                    //             setFooter(`Período de apuração: ${firstDay.toLocaleDateString('pt-BR', optionsData)} a ${Day.toLocaleDateString('pt-BR', optionsData)}. Valores estimados, sujeitos a validação e politicas de contabilização do vendor. Em ${Day.toLocaleDateString('pt-BR', optionsData)}`);

                    //         }
                    //     }

                    // } catch (e) {

                    // }

                    //     setLoading(false);
                    //     setPage(0);
                    if (response.data.domains && response.data.domains.length > 0) {
                        let _domains = response.data.domains.map((domain, i) => {
                            return {
                                id: i,
                                domain: domain,
                            };
                        });
                        setDomains(_domains);
                        setOriginalDomains(_domains);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setPage(0);
                setLoading(false);
            });

    };

    const prepareDataWithTooltipInfo = (data) => {
        return data.map(item => ({
            ...item,
            tooltipInfo: `${((item.consumedUnits / item.prepaidUnid) * 100).toFixed(0)}% utilizado` // Calculando o valor para o tooltip
        }));
    }

    // Antes de renderizar o componente ou passar os dados para ele
    const preparedSubscriptions = prepareDataWithTooltipInfo(subscriptions);

    const columns = [
        {
            field: 'status',
            hide: true,
            headerName: 'Status',
            width: '150px',
            renderCell: (row) => {
                if (row && row.consumedUnits !== undefined && row.prepaidUnid !== undefined && row.prepaidUnid > 0) {
                    const percentageUsed = (row.consumedUnits / row.prepaidUnid) * 100;
                    return `${percentageUsed.toFixed(0)}% utilizado`;
                } else {
                    return 'N/A'; // Para linhas sem dados completos ou com prepaidUnid igual a 0
                }
            }
        },
        { field: "displayName", headerName: "Subscrição", width: ((isCSP) ? ((isSaaS) ? "75%" : "60%") : ((isSaaS) ? "90%" : "75%")), },
        {
            field: "prepaidUnid", headerName: "Qtd contratada total", align: "right", width: "15%",
        },
        {
            field: "consumedUnits", headerName: "Em uso geral", align: "right", width: "10%",
        },
    ];

    // const columnsDomains = [
    //     { field: "domain", headerName: "Domínio", width: "20%",  },
    //     { field: "license", headerName: "Licenças", width: "80%", 
    //         renderCell(row) {
    //         return  <div>
    //             {
    //                 row.license === 'Nenhuma' ?
    //                 <div>{row.license}</div>
    //                 :
    //                 <div style={{display: 'flex'}}>
    //                     {
    //                         Object.entries(row.license).map(([campo, valor]) => (
    //                         <div style={{padding: '5px  0px'}} key={campo}>
    //                             <p>{campo}: {valor}</p>
    //                         </div>
    //                     ))
    //                     }
    //                 </div>
    //             }
    //             </div>
    //     } 
    //     },
    //     { field: "total", headerName: "Total", width: "10%" },
    // ];

    const columnsOnpremiseSoftware = [
        { field: "softwareName", headerName: "Software", width: "60%" },
        { field: "quantidade", headerName: "Quantidade", width: "20%", align: "right", },
    ];

    useEffect(() => {
        Promise.all([loadUsers(), RetrieveList()])
    }, [user.ClientGroupSelected, user.refreshFlag]);

    //#region Verifica se há licença EA/EAS
    let instance = new TechEAEASService(props);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState<boolean>(false);

    useEffect(() => {
        !isDev && menu && menu.selectedItem && menu.selectedItem.id == "cedcb443-a23e-4545-904b-5d1061e718aa" &&
            instance
                .GetSaasLicenseByCountryTotalView()
                .then((response) => {
                    setShowFluxoEAEAS(response.data.length == 0);
                })
                .catch((error) => {
                    setShowFluxoEAEAS(true);
                });
    }, [menu.selectedItem]);
    //#endregion

    //#region Verifica se há licença EA/EAS
    const saasService = new SaasService(props);
    const [showFluxoCSP, setShowFluxoCSP] = useState(false);

    useEffect(() => {
        if (!isDev && menu && menu.selectedItem && menu.selectedItem.id == "67aa56e4-ad63-44b0-aa27-d2d2a8665a63") {
            saasService
                .GetbyClientGroupIdAndTileId("13")
                .then((response) => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        if (!isDev && menu && menu.selectedItem && menu.selectedItem.id == "F07FD64E-3766-4551-B70A-811390EEED96")
            setIsSaaS(true);
    }, [menu.selectedItem]);
    //#endregion

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);

    const styles: React.CSSProperties[] = [
        { cursor: 'default' },
        { cursor: 'default', backgroundColor: '#eee' }
    ]
    const getColorStatusRow = (row: Subscritions) => {
        let percentage = ((row.consumedUnits * 100) / row.prepaidUnid);
        if (row.consumedUnits > row.prepaidUnid) return '#d3281d';
        else if (percentage >= 90) return '#e64646';
        else if (percentage >= 70 && percentage < 90) return "#ffb800";
        else return '#a5bf3e'

    }

    const filterAdvancedAssinaturas = {
        fields: [
            { label: 'Subscrição', name: 'name', type: 'text', value: '' },
        ],
        onChange: (filter: any, size: number) => {
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };
    const filterAdvancedSoftware = {
        fields: [
            { label: 'Software', name: 'softwareName', type: 'text', value: '' },
        ],
        onChange: (filter: any, size: number) => {
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const filterAdvancedDomains = {
        fields: [
            { label: 'Domínio', name: 'name', type: 'text', value: '' },
        ],
        onChange: (filter: any, size: number) => {
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const card: Card[] = [
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                var fieldBase = [
                    { id: 'disName', value: "Usuario", type: "text" },
                    { id: 'userPrincipalName', value: "Email", type: "text" },
                    { id: 'jobTitle', value: "Cargo", type: "text" },
                    { id: 'lncs', value: "Subscrição", type: "text" },
                    { id: 'sku', value: "SKU", type: "text" },
                    { id: 'count', value: "Atribuída", type: "number" },
                    { id: 'avali', value: "Contratada", type: "number" },
                    { id: 'statusUser', value: "Status Usuário", type: "text" },
                    { id: 'domain', value: "Domínio", type: "text" }
                ];

                return (
                    <>
                        {showFluxoEAEAS
                            ?
                            <div className="lc-segment">
                                <div className="body">
                                    Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                    fornecer e/ou atualizar as credenciais de acesso conforme os
                                    passos abaixo. Caso precise de ajuda abra uma
                                    requisição, clicando{" "}
                                    <span
                                        className="link text-link"
                                        onClick={() => {
                                            history.push("/Chamados");
                                            dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                                        }}
                                    >
                                        aqui
                                    </span>
                                    , e teremos o prazer em atender.
                                    <img src={ImgFluxoEAEAS} width="100%" alt="Procedimento" />
                                </div>
                            </div>
                            : showFluxoCSP ?
                                <div className="lc-segment">
                                    <div className="body">
                                        Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                        fornecer e/ou atualizar as credenciais de acesso conforme os
                                        passos abaixo. Caso precise de ajuda abra uma
                                        requisição, clicando{" "}
                                        <span
                                            className="link text-link"
                                            onClick={() => {
                                                history.push("/Chamados");
                                                dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                                            }}
                                        >
                                            aqui
                                        </span>
                                        , e teremos o prazer em atender.
                                        <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                                    </div>
                                </div>
                                :
                                <LcLoading loadingType="Helix" loading={loading}>
                                    {page == 0 &&
                                        <LcLoading label="Carregando subscrições..." loading={loadingLicenseFormattedData}>{formattedData && formattedData.length > 0 ?
                                            <PivotTableWebix
                                                footer={false}
                                                key={"1"}
                                                idView="subscription"
                                                data={formattedData}
                                                fields={fieldBase}
                                                mode="tree"
                                                chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                                                structure={{
                                                    rows: ["lncs", "disName"],
                                                    values: [{ name: "count", operation: ["count"] }, { name: "avali", operation: ["max"] }],
                                                    filters: [{ name: "lncs" }, { name: "disName" }]
                                                }}
                                            />
                                            :
                                            <LcNoData />
                                        }</LcLoading>
                                    }
                                    {
                                        page == 4 &&
                                        <LcInfiniteTable
                                            loading={loading}
                                            columns={columns}
                                            rows={preparedSubscriptions}
                                            filter={filterAdvancedAssinaturas}
                                            size={subscriptions.length}
                                            loadMore={() => { }}
                                            density={pref.data?.density || "high"}
                                            status={getColorStatusRow}
                                            tooltipContentColumn='tooltipInfo'
                                            disableFilterModal
                                        />
                                    }
                                    {
                                        page == 1 &&
                                        <LcInfiniteTable
                                            loading={loading}
                                            columns={columnsOnpremiseSoftware}
                                            rows={onPremiseSofwares}
                                            filter={filterAdvancedSoftware}
                                            size={onPremiseSofwares.length}
                                            loadMore={() => { }}
                                            density={pref.data?.density || "high"}
                                            status={() => '#1b3a7a'}
                                            height="calc(100% - 15px)"
                                            disableFilterModal
                                        />
                                    }
                                    {
                                        page == 2 &&
                                        <LcLoading label="Carregando domínios..." loading={loadingLicenseFormattedData}>{formattedData && formattedData.length > 0 ?
                                            <PivotTableWebix
                                                footer={false}
                                                key={"2"}
                                                idView="domain"
                                                data={formattedData}
                                                fields={fieldBase}
                                                mode="tree"
                                                chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                                                structure={{
                                                    rows: ["domain", "lncs"],
                                                    values: [{ name: "count", operation: ["count"] }, { name: "avali", operation: ["max"] }],
                                                    filters: [{ name: "domain" }, { name: "lncs" }]
                                                }}
                                            />
                                            :
                                            <LcNoData />
                                        }</LcLoading>
                                    }
                                </LcLoading>
                        }
                    </>
                );
            }
        }
    ];

    const restoreOriginalData = () => {
        // Restaurando para os dados originais
        setSubscriptions([...originalSubscriptions]);
        setOnPremiseSofwares([...originalOnPremiseSoftwares]);
        setDomains([...originalDomains]);
    };
    const translationsMissing = {
        softwareName: {
            label: "Softaware",
            type: "text",
        }
    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translationsMissing).map(key => ({
                        label: translationsMissing[key].label,
                        name: key,
                        type: translationsMissing[key].type,
                        values: translationsMissing[key].values,
                    }))}
                    data={originalOnPremiseSoftwares}
                    onFilteredData={(filteredData) => setOnPremiseSofwares(filteredData)}
                    translations={translationsMissing}
                />
            </div>
        );
    };


    useEffect(() => {
        // Atualiza a chave quando o valor muda para forçar a re-renderização dos componentes de filtro
        setFilterKey(prevKey => prevKey + 1);
        dispatch(setFilterActive(false));

    }, [page, dispatch]); // Dependência no valor selecionado

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (filtersArray) => {
        const filters = filtersArray.reduce((acc, filter) => {
            acc[filter.name] = filter.value.toLowerCase();
            return acc;
        }, {});

        switch (page) {
            case 0:
                const filteredSubscriptions = originalSubscriptions.filter(sub => sub.displayName.toLowerCase().includes(filters.name));
                setSubscriptions(filteredSubscriptions);
                break;
            case 1:
                const filteredSoftwares = originalOnPremiseSoftwares.filter(soft => soft.softwareName.toLowerCase().includes(filters.softwareName));
                setOnPremiseSofwares(filteredSoftwares);
                break;
            case 2:
                const filteredDomains = originalDomains.filter(domain => domain.name.toLowerCase().includes(filters.name));
                setDomains(filteredDomains);
                break;
            default:
            // Lidar com um caso padrão, se necessário
        }

        const isFilterActive = Object.values(filters).some(val => val !== '');
        dispatch(setFilterActive(isFilterActive));
    };


    const getFilterForActiveTable = () => {
        switch (page) {
            case 0:
                return filterAdvancedAssinaturas;
            case 1:
                return filterAdvancedSoftware;
            case 2:
                return filterAdvancedDomains;
            default:
                return filterAdvancedAssinaturas;
        }
    };

    return (
        <Layout
            pivot={page === 0 || page === 2}
            hidePivotHeader={true}
            pageTitle="Consumo CSP"
            gridFilter={
                page === 1 ? {

                    toggleVisibility: () => {
                        setFilterVisible(!filterVisible)
                    },
                    size: onPremiseSofwares.length
                }
                    :
                    undefined
            }
            row={

                <LcDropDown
                    element={<span>{ServiceInstance.cardsItens.find(c => c.value == page)?.label} <i className="ppi ppi-chevron-down" /></span>}
                    position="right"
                >
                    {
                        (() => {
                            const filteredCardsItens = ServiceInstance.cardsItens
                                .filter(c => ((!isCSP && (c.value === 0 || c.value === 2 || c.value === 1)) || isCSP) && c.value !== 3);
                            return filteredCardsItens.map((d, i) => (
                                <div key={i} className="item" style={(d.value === page) ? styles[1] : styles[0]} onClick={() => setPage(d.value)}>
                                    {d.label}
                                </div>
                            ));
                        })()
                    }
                </LcDropDown>
            }
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
        </Layout>
    );
};

export default LCTech;