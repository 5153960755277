export class doone {
    solution() {
        return 'doone'
    }

    site() {
        return 'https://www.gotobiz.com.br/doone'
    }

    logotipo() {
        return require('./assets/logotipos/logo_doone.svg').default
    }
    titulo() {
        return <>Potencialize as operações e revolucione a produtividade com Doone!</>
    }

    chamada() {
        return <>Solução de automação de processos e atividades que permite à organização dar saltos de produtividade. Com Doone!, tarefas repetitivas são executadas por robôs, liberando a equipe para se concentrar em atividades mais estratégicas.</>
    }

    image() {
        return require('./assets/imgs/imagem_doone.png').default
    }

    detalhes() {
        return <>
            <h2>Doone! - Automação de processos e atividades</h2>
            <h3>Por que escolher Doone!?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_doone.png').default} />
                </div>
                <ul>
                    <li><b>Automação de processos e atividades</b> que passam a serem feitos por robôs, prevenindo erros humanos.</li>
                    <li><b>Combinação de IA e RPA</b> para maior agilidade, efetividade e inteligência na execução de processos de negócios.</li>
                    <li><b>Execuções automatizadas de atividades repetitivas</b> que exigem respostas rápidas.</li>
                    <li><b>Diversas automações predefinidas</b> para a organização otimizar tempo e aumentar a produtividade.</li>
                </ul>
            </div>
            <div className="box">Com Doone!, a organização pode <b>automatizar processos e atividades de maneira rápida e eficiente</b>, permitindo saltos de produtividade significativos.</div>
            <h3>Exemplos de automação</h3>
            <p>Doone! pode ser aplicado em diversas áreas, como Recursos Humanos, Faturamento e Relacionamento, Fiscal, Suprimentos, Financeiro e Fiscal, Jurídico, Educação, Indústria e Produção, Auditoria e Compliance, Estoque e Logística e Tecnologia da Informação.</p>            
            <h3>Não perca tempo!</h3>
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como Doone! pode transformar a produtividade da organização.</p>         
        </>
    }
}
export default doone;