import React, { useEffect, useState } from "react";

import moment from "moment";
import { DialogContentText, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import { PPGrid, PPMultiSelect, PPModal, PPInput, PPTextField } from "processor-plataform-ui";
import Confirmation from "../../../../components/Dialog/Confirmation";
import FinOpsMapManagerService, { FinOpsAlert, FinOpsAlertResource, FinOpsAlertService, FinOpsAlertsGroup, FinOpsAllocationRuleCondition } from '../../../../services/finOpsManagerService';

import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { UserState } from "../../../../store/reducers/userReducer";

import './index.css';
import { useSnackbar } from "notistack";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import PPDropDown from "../../../../components/PPDropDown";
import LcCheckBox from "../../../../components/Form/LcCheckBox";
import ConditionFilterResume from "../../resume/conditionFilter";
import LcToggle from "../../../../components/Form/LcToggle";

interface Props {
    alert: FinOpsAlert,
    alertGroup: FinOpsAlert[],
    costCenters: any[],
    contracts: any[],
    clientGroupUsers: any[],
    readOnly: boolean,
    onSaveGroup?: Function,
    onCancelAction?: Function,
}
export interface AlertValidate {
    name: string;
    validateMessage: string;
    valid: boolean;
    conditionId?: number;
}

const AlertasEditorVersaoDois: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>((state) => state.user);
    const finOpsMapManagerService = new FinOpsMapManagerService(props);
    const { enqueueSnackbar } = useSnackbar();
    const [alert, setAlert] = useState<FinOpsAlert>(props.alert);
    const [costCenters, setCostCenters] = useState<any[]>(props.costCenters);
    const [contracts, setContracts] = useState<any[]>(props.contracts);
    const [clientGroupUsers, setClientGroupUsers] = useState<any[]>(props.clientGroupUsers);
    const [resources, setResources] = useState<FinOpsAlertResource[]>([]);
    const [services, setServices] = useState<FinOpsAlertService[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [validations, setValidations] = useState<AlertValidate[]>([]);
    const [savingAlerts, setSavingAlerts] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [changed, setChanged] = useState(false);
    const originalState = props.alert;
    const [alertGroup, setAlertGroup] = useState<FinOpsAlert[]>([]);
    const [visibleBudget, setVisibleBudget] = useState<number>(0)
    const [disabledCostCenterAlert, setDisabledCostCenterAlert] = useState<boolean>(false);
    const [filterWaitingData, setFilterWaitingData] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState("");
    const [selectedConditions, setSelectedConditions] = useState<FinOpsAllocationRuleCondition[]>([{
        idCondition: 0,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: ''
    }]);

    const emptyRecordGroup: FinOpsAlertsGroup = {
        id: 0,
        clientGroupId: user.ClientGroupSelected!,
        //clientGroupId: 0,
        month: 0,
        year: 0,
        endDate: null,
        startDate: null,
        version: '',        
        type: '',
        name: '',
        updatedByUser: '',
        updatedByUserName: '',
        updatedByUserEmail: '',
        updatedAt: '',
        alerts: []
    }

    const [versionRecord, setVersionRecord] = useState<FinOpsAlertsGroup>(emptyRecordGroup);


    useEffect(() => {
        // Verifica se é um novo alerta
        if (props.alert.id === 0 || props.alert.id === undefined) {
            setAlert({
                ...props.alert,
                highPriority: true,
                ignoreSavingPlansAndResevations: true
            });
        } else {
            setAlert(props.alert);
        }
    }, [props.alert]);

    useEffect(() => {
        setAlertGroup(props.alertGroup)
    }, [props.alertGroup]);

    useEffect(() => {
        if (alert.contracts.length > 0) {
            let contratosJaSelecionados = alert.contracts;
            let listaDeContratos = contracts;
            let listaFiltrada = listaDeContratos.map(c1 => { return { ...c1, selected: contratosJaSelecionados.some(c2 => c2.value == c1.value) } })
            setContracts(listaFiltrada);
        }

        if (alert.costCenters.length > 0) {
            let centrosJaSelecionados = alert.costCenters;
            let listaDeCentros = costCenters;
            let listaFiltrada = listaDeCentros.map(c1 => { return { ...c1, selected: centrosJaSelecionados.some(c2 => c2.value == c1.value) } })
            setCostCenters(listaFiltrada);
        }

        if (alert.usersToNotify && alert.usersToNotify.length > 0) {
            let usuariosJaSelecionados = alert.usersToNotify;
            let listaDeUsuarios = clientGroupUsers.map(c1 => {
                return {
                    ...c1,
                    selected: usuariosJaSelecionados.some(c2 => c2.value === c1.value)
                };
            });
            setClientGroupUsers(listaDeUsuarios);
        }

        if (alert.services.length > 0) {
            let listaDeServicos = alert.services.map(serv => { return { ...serv, selected: true } })
            setServices(listaDeServicos);
        } else {
            let servicoVazio: FinOpsAlertService = { label: '', value: '' }
            setServices([servicoVazio]);
        }

        if (alert.resources.length > 0) {
            let listadeRecursos = alert.resources.map(serv => { return { ...serv, selected: true } })
            setResources(listadeRecursos);
        } else {
            let recursoVazio: FinOpsAlertResource = { label: '', value: '' }
            setResources([recursoVazio]);
        }

        if (alert.conditions[0].condition == 'budget') {
            let value = Number(alert.conditions[0].budget);
            setVisibleBudget(value)
        }

    }, []);

    useEffect(() => {
        let original = JSON.stringify(originalState);
        let current = JSON.stringify(alert);
        if (original == current) {
            setChanged(false);
            validateAlert();
        } else {
            setChanged(true);
            validateAlert();
        }
    }, [alert]);

    const handleChange = (name: string, value: any) => {
        let _alert = { ...alert };
        if (name == 'escope') {
            _alert.escope = value;
            _alert.resources = [];
            _alert.services = [];
            _alert.contracts = [];
            _alert.costCenters = [];
            setAlert({ ..._alert });
        } else if (name == 'type') {
            _alert.type = value;
            setAlert({ ..._alert });

        } else {
            setAlert({ ...alert, [name]: value });
        }
    }

    const handleChangeConditions = (event: any, id: number, type: string) => {
        let { value } = event;
        if (type === 'budget') {
            if (value != "") {
                setVisibleBudget((value));
            } else {
                value = "0"
                setVisibleBudget((value));
            }
            setAlert({ ...alert, conditions: alert.conditions.map(c => { return c.idCondition == id ? { ...c, [type]: (value).replaceAll(".", "").replace(",", ".") } : c }) });
        }
        else {
            value >= 0 ? setAlert({ ...alert, conditions: alert.conditions.map(c => { return c.idCondition === id ? { ...c, [type]: value, comparator: 'mais'  } : c }) }) :
            setAlert({ ...alert, conditions: alert.conditions.map(c => { return c.idCondition === id ? { ...c, [type]: value, comparator: 'menos' } : c }) })
        }
    };
    
    const handleChangePeriod = (event: any) => {
        let { name, value } = event.target;
        setAlert({ ...alert, period: { ...alert.period, [name]: value } });
    };

    const handleChangeList = (event: any) => {
        let { name, value } = event.target;
        let _alert = { ...alert };
        // let newValue = value.map(item => { return { ...item } })
        _alert = { ..._alert, [name]: value };
        setAlert(_alert);
    };

    const handleChangeText = (event: any, index: number) => {
        const { name, value } = event.target;
        let _resources = [...resources]
        let _services = [...services]

        if (name == 'resources') {
            let resource: FinOpsAlertResource = { label: value, value: value }
            _resources[index] = resource
            setResources(_resources);
        }

        if (name == 'services') {
            let service: FinOpsAlertService = { label: value, value: value }
            _services[index] = service
            setServices(_services);
        }

        let _alert = { ...alert };
        _alert.resources = [..._resources];
        _alert.services = [..._services];
        setAlert({ ..._alert });
    }

    function toCamelCase(str) {
        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    };

    async function retrieveSugestions(field: string, text: string) {
        let month = moment().month() + 1;
        let year = moment().year();
        let result = await finOpsMapManagerService.GetFieldOptions(month, year, field, text);
        let map = { ResourceName: "resources", Service: "services" }
        let selected = alert[map[field]] ? alert[map[field]].map(item => { return { value: item.value, label: item.label, selected: true } }) : [];
        let sliced = result.slice(0, 50).map((item) => { return !(selected.map(x => x.value).includes(item.value)) && { value: item[toCamelCase(field)] }; });
        return sliced;
    };

    const removeDisabledCostCenters = () => {
        let _costcenters = costCenters.map(c1 => {
            if (alert.costCenters.some(c2 => c1.value == c2.value && c1.disabled) && c1.selected)
                c1.selected = false
            return c1;
        });
        setCostCenters(_costcenters);
    }

    const removeResource = (index: number) => {
        let removable = resources.length > 0 && resources.length >= index + 1;
        let _resources = [...resources]
        if (removable) {
            _resources.splice(index, 1);
            setResources(_resources)
        }
        let _alert = { ...alert };
        _alert.resources = [..._resources];
        setAlert({ ..._alert });
    }

    const addResource = () => {
        let newResource: FinOpsAlertResource = { label: '', value: '' }
        let _alert = { ...alert };
        let _resources = [...resources, newResource];
        setResources(_resources)
        _alert.resources = [..._resources];
        setAlert({ ..._alert });
    }

    const removeService = (index: number) => {
        let removable = services.length > 0 && services.length >= index + 1;
        let _services = [...services]
        if (removable) {
            _services.splice(index, 1);
            setServices(_services)
        }
        let _alert = { ...alert };
        _alert.services = [..._services];
        setAlert({ ..._alert });
    }

    const addService = () => {
        let newService: FinOpsAlertService = { label: '', value: '' }
        let _alert = { ...alert };
        let _services = [...services, newService];
        setServices(_services)
        _alert.services = [..._services];
        setAlert({ ..._alert });
    }

    async function saveAlertGroup(alertGroups?: FinOpsAlert[]) {
        setSavingAlerts(true);
        
        versionRecord.alerts = alertGroups ? alertGroups : alertGroup;
        versionRecord.updatedAt = new Date().toISOString();
        versionRecord.updatedByUser = user.UserId!;
        versionRecord.updatedByUserName = user.name!;
        versionRecord.updatedByUserEmail = user.email!;
        versionRecord.month = new Date().getMonth() + 1;
        versionRecord.year = new Date().getFullYear();
        versionRecord.name = `Versão:${versionRecord.month}/${versionRecord.year}`;

        finOpsMapManagerService.SaveFinOpsAlerting(versionRecord)
            .then(response => {
                props.onSaveGroup && props.onSaveGroup();
                setSavingAlerts(false);
            }).catch((e) => {
                setSavingAlerts(false);
                console.warn(e);
                enqueueSnackbar(`Ocorreu um erro ao tentar salvar o alerta`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
            })
    };

    function addAlertRecord() {
        let _alert = { ...alert }
        _alert.resources = resources.filter(r => r.label != '' && r.value != '');
        _alert.services = services.filter(s => s.label != '' && s.value != '');
        setAlert({ ..._alert });

        let newList = [...alertGroup];

        if (alert.id === 0) {

            // Se for um novo alerta e os campos não foram definidos, defina-os como true
            //_alert.highPriority = _alert.highPriority === undefined ? true : _alert.highPriority;
            //_alert.ignoreSavingPlansAndResevations = _alert.ignoreSavingPlansAndResevations === undefined ? true : _alert.ignoreSavingPlansAndResevations;


            // Definindo um novo ID para o novo alerta
            let newID = alertGroup.length > 0 ? Math.max(...alertGroup.map(a => a.id)) + 1 : 1;
            _alert.id = newID;

            // Definindo a ordem do novo alerta como 1 e incrementando a ordem dos outros alertas
            newList.forEach(a => a.order++);
            _alert.order = 1;

            // Adiciona o novo alerta ao topo da lista
            newList.unshift(_alert);
        } else {
            // Atualiza um alerta existente
            let index = newList.findIndex(a => a.id === alert.id);
            if (index !== -1) {
                newList[index] = { ..._alert };
            }
        }
       
        // Salvando o grupo de alertas atualizado
        setAlertGroup(newList);
        saveAlertGroup(newList);
    }

    const conditionList = [
        { value: 'variacao', label: 'Variação do custo' },
        { value: 'budget', label: 'Budget atingido for' },
        { value: 'count', label: 'Variação da quantidade de recursos' },
    ];

    const periodList = [
        { value: 'ultimos', label: 'Últimos' },
    ];

    const periodUnit = [
        { value: 'dias', label: 'dias' },
    ];

    function validateAlert() {
        var validationsChecks: AlertValidate[] = [];

        if (alert.description === '') {
            validationsChecks.push({
                validateMessage: 'Dê um nome para este alerta',
                name: 'description',
                valid: false
            });
        }



        if (alert.description.length > 0) {
            if (alert.usersToNotify && alert.usersToNotify.length === 0) {
                validationsChecks.push({
                    validateMessage: 'Selecione pelo menos um usuário para notificar',
                    name: 'usersToNotify',
                    valid: false
                });
            }

            alertGroup.forEach(al => {
                if (alert.description === al.description && alert.id !== al.id) {
                    validationsChecks.push({
                        validateMessage: 'Já existe um alerta com este nome',
                        name: 'description',
                        valid: false
                    });
                }
            });
        }

        alert.conditions.forEach(cond => {
            if (cond.condition === 'variacao' || cond.condition === 'count') {
                if (cond.percentage < 0.009 && cond.percentage > -0.009) {
                    validationsChecks.push({
                        conditionId: cond.idCondition,
                        validateMessage: 'O percentual deve ser diferente de 0',
                        name: 'percentage',
                        valid: false
                    });
                }
            }

            if (cond.condition === 'budget') {
                if (cond.percentage < 0.009 && cond.percentage > -0.009) {
                    validationsChecks.push({
                        conditionId: cond.idCondition,
                        validateMessage: 'O percentual deve ser diferente de 0',
                        name: 'percentage',
                        valid: false
                    });
                }

                if (cond.budget && Number(cond.budget) < 0) {
                    validationsChecks.push({
                        conditionId: cond.idCondition,
                        validateMessage: 'O budget deve ser maior que 0',
                        name: 'budget',
                        valid: false
                    });
                }
            }
        });

        if (alert.escope === 'costCenters' && alert.costCenters.length === 0) {
            validationsChecks.push({
                validateMessage: 'Selecione pelo menos um centro de custo',
                name: 'costCenters',
                valid: false
            });
        }

        if (alert.escope === 'costCenters') {
            if (costCenters.some(c1 => alert.costCenters.some(c2 => c2.value === c1.value && c1.disabled))) {
                validationsChecks.push({
                    validateMessage: 'Centro de Custo inativo selecionado',
                    name: 'costCenters',
                    valid: false
                });
                setDisabledCostCenterAlert(true);
            }
        }

        if (alert.escope === 'contracts' && alert.contracts.length === 0) {
            validationsChecks.push({
                validateMessage: 'Selecione pelo menos um contrato',
                name: 'contracts',
                valid: false
            });
        }

        if (alert.escope === 'resources/services') {
            if (alert.resources.length === 0 && alert.services.length === 0) {
                validationsChecks.push({
                    validateMessage: 'Selecione pelo menos um recurso ou serviço',
                    name: 'resources/services',
                    valid: false
                });
            } else {
                if (alert.resources.some(r => r.value === '')) {
                    validationsChecks.push({
                        validateMessage: 'Recurso adicionado não pode ficar em branco',
                        name: 'resources',
                        valid: false
                    });
                }
                if (alert.services.some(s => s.value === '')) {
                    validationsChecks.push({
                        validateMessage: 'Serviço adicionado não pode ficar em branco',
                        name: 'services',
                        valid: false
                    });
                }
            }

            //if (alert.usersToNotify.length === 0) {
            //    validationsChecks.push({
            //        validateMessage: 'Selecione pelo menos um usuário para notificar',
            //        name: 'usersToNotify',
            //        valid: false
            //    });
            //}
        }

        setValidations(validationsChecks);
        setCanSave(validationsChecks.length === 0);
        return validationsChecks.length === 0;
    }


    const setTooltipContent = () => {
        if (validations.length > 0) {
            return validations.map(v => {
                return <div key={v.conditionId}>{v.validateMessage}</div>
            })
        } else
            if (changed == false)
                return 'Faça alguma alteração'
    };

    const getError = (campo: string) => {
        if (validations.length > 0) {
            return { show: validations.find(x => x.name == campo) != undefined, help: validations.find(x => x.name == campo)?.validateMessage! }
        }
        else {
            return { show: false, help: '' }
        }
    }

    const functionsMoney = [
        {
            icon: 'ppi ppi-currency-dollar',
            tooltip: '',
            onClick: () => undefined,
        }
    ]

    return (
        <PPModal
            title={
                props.readOnly == true ?
                    'Detalhes do alerta'
                    :
                    (alert.id == 0 ?
                        'Novo alerta'
                        :
                        'Detalhes do alerta'
                    )
            }
            size="large"
            visible={props.alert != undefined}
            onClose={() => {
                props.readOnly == true ?
                    props.onCancelAction && props.onCancelAction()
                    :
                    changed ?
                        setConfirmVisible(true)
                        :
                        props.onCancelAction && props.onCancelAction()
            }}
            functions={[
                {
                    label: alert.id == 0 ? 'Criar alerta' : 'Salvar alerta',
                    onClick: () => addAlertRecord(),
                    disabled: !canSave || savingAlerts || !changed,
                    hidden: props.readOnly,
                    context: "info"
                },
                {
                    label: "Cancelar", onClick: () => {
                        props.readOnly == true ?
                            props.onCancelAction && props.onCancelAction()
                            :
                            changed ?
                                setConfirmVisible(true)
                                :
                                props.onCancelAction && props.onCancelAction()
                    },
                    hidden: props.readOnly,
                    context: "warning"
                }
            ]}
        >
            <PPModal
                title="⚠️ ATENÇÃO!"
                onClose={() => {
                    setDisabledCostCenterAlert(false);
                    props.onCancelAction && props.onCancelAction()
                }}
                visible={props.readOnly == false && disabledCostCenterAlert}
                functions={[
                    {
                        label: "Desvincular Centros inativos",
                        onClick: () => {
                            removeDisabledCostCenters();
                            setDisabledCostCenterAlert(false);
                        },
                        context: "info"
                    }
                ]}
            >
                <DialogContentText>
                    <p>Esse alerta está vinculado a um ou mais Centros de Custo desativados:</p>
                    <ul style={{ margin: '15px' }}>
                        {
                            costCenters.length > 0 && costCenters.map(cc => {
                                if (cc.disabled)
                                    return (<li key={cc.value}><strong>{cc.label}</strong></li>)
                            })
                        }
                    </ul>
                    <p>Antes de salvar, será necessário <strong>remover os vínculos</strong>.</p>
                </DialogContentText>
            </PPModal>
            <div style={{ margin: '0px 0px 0rem .3rem' }}>

                <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <div className='titulos'>
                        Nome do alerta
                    </div>
                    <PPInput
                        type='text'
                        name="description"
                        required={!props.readOnly}
                        readOnly={props.readOnly}
                        value={alert.description}
                        onChange={(event) => {
                            let { name, value } = event.target;
                            handleChange(name, value);
                        }}
                    />
                </PPGrid>
            </div>
            <div style={{ margin: '0px 0px 0rem .3rem' }}>
                <PPGrid container spacing={2} alignItems='flex-start' >
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='titulos'>Escopo</div>
                        <RadioGroup
                            row
                            name="escope"
                            value={alert.escope.toString()}
                            onChange={(event) => {

                                handleChange(event.target.name, event.target.value);

                            }}
                            style={{marginLeft: '1rem'}}
                        >
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="global" control={<Radio color="primary" />} label="Global" />
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="filter" control={<Radio color="primary" />} label="Filtro personalizado" />
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="costCenters" control={<Radio color="primary" />} label="Centros de custo" />
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="contracts" control={<Radio color="primary" />} label="Contratos" />
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="resources/services" control={<Radio color="primary" />} label="Recursos / serviços" />
                        </RadioGroup>
                    </PPGrid>
                    {alert.escope == 'costCenters' &&
                        <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11} >
                            <PPMultiSelect
                                readOnly={props.readOnly}
                                error={getError(alert.escope)}
                                openTrigger="click"
                                required={!props.readOnly}
                                name={alert.escope}
                                options={costCenters}
                                onChange={(event) => {
                                    alert.escope == 'costCenters' ?
                                        costCenters.length > 0 &&
                                        handleChangeList({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.value.map(v => { return { label: v.label, value: v.value.toString() } })
                                            }
                                        })
                                        :
                                        contracts.length > 0 &&
                                        handleChangeList({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.value.map(v => { return { label: v.label, value: v.value.toString() } })
                                            }
                                        })
                                }}
                            />
                        </PPGrid>
                    }
                    {alert.escope == 'contracts' &&
                        <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11} >
                            <PPMultiSelect
                                readOnly={props.readOnly}
                                error={getError(alert.escope)}
                                openTrigger="click"
                                required={!props.readOnly}
                                name={alert.escope}
                                options={contracts}
                                onChange={(event) => {
                                    alert.escope == 'costCenters' ?
                                        costCenters.length > 0 &&
                                        handleChangeList({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.value.map(v => { return { label: v.label, value: v.value.toString() } })
                                            }
                                        })
                                        :
                                        contracts.length > 0 &&
                                        handleChangeList({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.value.map(v => { return { label: v.label, value: v.value.toString() } })
                                            }
                                        })
                                }}
                            />
                        </PPGrid>
                    }
                    {alert.escope == 'filter' &&
                        <div className="" style={{
                            minWidth: '100%',
                            flexDirection: 'column',
                            display: 'flex', paddingLeft: '10px'
                        }}>
                            <ConditionFilterResume
                                showOperators={true}
                                visible={true}
                                conditions={alert.customFilter}
                                onSubmit={data => { 
                                    handleChange('customFilter', data);
                                    //setSelectedConditions(data);
                                }}
                                onClose={() => {

                                }}
                            />
                        </div>
                    }
                    <>
                        {alert.escope == 'resources/services' &&
                            <>
                                <PPGrid item xs={10} sm={10} md={10} lg={10} xl={10} >
                                    <div className='titulos'>Recursos</div>
                                    {
                                        resources && resources.map((resourceItem, index) => {
                                            return (
                                                <PPGrid key={index} container spacing={2}>
                                                    <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11} >
                                                        <PPTextField
                                                            name="resources"
                                                            type={'text'}
                                                            onChange={(event) => { handleChangeText(event, index) }}
                                                            readOnly={props.readOnly}
                                                            required={props.readOnly ? false : (alert.resources.length == 0 && alert.services.length > 0 ? false : true)}
                                                            value={resourceItem.value}
                                                            help={getError('resources')?.help}
                                                            error={getError('resources')?.show}
                                                            sugestions={(event) => retrieveSugestions("ResourceName", event) as any}
                                                            className="inputTextField"
                                                        />
                                                    </PPGrid>
                                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                        <div className="row">
                                                            <LcIconLink size={'medium'} tooltipPosition={'right'} icon={`ppi ppi-trash `}
                                                                disabled={props.readOnly || resources.length <= 1}
                                                                onClick={() => { removeResource(index); }}
                                                                tooltip="Excluir Recurso"
                                                            />
                                                            <LcIconLink size={'medium'} tooltipPosition={'right'} icon={`ppi ppi-plus `}
                                                                disabled={props.readOnly || resources.find(r => r.value == '') != undefined}
                                                                onClick={addResource}
                                                                tooltip="Adicionar Recurso"
                                                            />
                                                        </div>
                                                    </PPGrid>
                                                </PPGrid>
                                            )
                                        })
                                    }

                                    <div className='titulos'>Serviços</div>
                                    {
                                        services && services.map((serviceItem, index) => {
                                            return (
                                                <PPGrid key={index} container spacing={2}>
                                                    <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11} >
                                                        <PPTextField
                                                            name="services"
                                                            type={'text'}
                                                            onChange={(event) => { handleChangeText(event, index) }}
                                                            readOnly={props.readOnly}
                                                            required={props.readOnly ? false : (alert.services.length == 0 && alert.resources.length > 0 ? false : true)}
                                                            value={serviceItem.value}
                                                            help={getError('services')?.help}
                                                            error={getError('services')?.show}
                                                            sugestions={(event) => retrieveSugestions("Service", event) as any}
                                                            className="inputTextField"
                                                        />
                                                    </PPGrid>
                                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                        <div className="row">
                                                            <LcIconLink size={'medium'} tooltipPosition={'right'} icon={`ppi ppi-trash `}
                                                                disabled={props.readOnly || services.length <= 1}
                                                                onClick={() => { removeService(index); }}
                                                                tooltip="Excluir Serviço"
                                                            />
                                                            <LcIconLink size={'medium'} tooltipPosition={'right'} icon={`ppi ppi-plus `}
                                                                disabled={props.readOnly || services.find(s => s.value == '') != undefined}
                                                                onClick={addService}
                                                                tooltip="Adicionar Serviço"
                                                            />
                                                        </div>
                                                    </PPGrid>
                                                </PPGrid>
                                            )
                                        })
                                    }
                                </PPGrid>
                            </>
                        }
                    </>
                </PPGrid >
            </div>
            <div style={{ margin: '0px 0px 0rem .3rem' }}>
                <PPGrid container spacing={2} alignItems='flex-start'>
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='titulos'>
                            Condição
                        </div>
                    </PPGrid>

                    {alert && alert.conditions.sort((a, b) => a.idCondition > b.idCondition ? 1 : -1)
                        .map((itemCondition, index) => {
                            return (
                                <PPGrid key={index} container spacing={2} alignItems='flex-start'>
                                    <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    </PPGrid>
                                    <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <PPDropDown
                                            name="condition"
                                            value={conditionList.find(condition => condition.value == itemCondition.condition)}
                                            options={conditionList}
                                            onChange={(event) => { handleChangeConditions(event, itemCondition.idCondition, 'condition'); }}
                                            disabled={props.readOnly}
                                            required                                            
                                        />
                                    </PPGrid>
                                    <>
                                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <div className="row" style={{ width: "290px" }}>
                                                <div style={{ width: "100px" }}>
                                                    <PPInput
                                                        style={{position: 'relative' }}
                                                        width='100px'
                                                        name="percentage"
                                                        type='number'
                                                        step='0.01'
                                                        onChange={(event) => { handleChangeConditions(event.target, itemCondition.idCondition, "percentage"); }}
                                                        readOnly={props.readOnly}
                                                        value={itemCondition.percentage}
                                                        required={!props.readOnly}
                                                    />
                                                </div>
                                                {alert.conditions[0].condition === 'variacao' && (
                                                    <>
                                                        <span>%</span>
                                                        {alert.conditions[0].comparator === 'menos' && (
                                                            <span className="text-warning font-xs ml-3" style={{ alignItems: 'baseline' }}>
                                                                Para menos
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                                {alert.conditions[0].condition === 'count' && '%'}
                                                {alert.conditions[0].condition === 'budget' && '% de'}

                                            </div>
                                        </PPGrid>
                                    </>

                                    {/*{alert.conditions[0].condition == 'variacao' &&*/}
                                    {/*    <>*/}
                                    {/*        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>*/}
                                    {/*            <PPDropDown*/}
                                    {/*                name="comparator"*/}
                                    {/*                value={conditionVarianceComparator.find(opt => opt.value == itemCondition.comparator)}*/}
                                    {/*                options={conditionVarianceComparator}*/}
                                    {/*                onChange={(event) => { handleChangeConditions(event, itemCondition.idCondition, "comparator"); }}*/}
                                    {/*                readOnly={props.readOnly}*/}
                                    {/*                required*/}
                                    {/*            />*/}
                                    {/*        </PPGrid>*/}
                                    {/*    </>*/}
                                    {/*}*/}

                                    {alert.conditions[0].condition == 'budget' && <>
                                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <div style={{ marginLeft: "-50px" }}>
                                                <PPInput
                                                    functions={functionsMoney}
                                                    name="budget"
                                                    type='text'
                                                    readOnly={props.readOnly}
                                                    required={!props.readOnly}
                                                    value={visibleBudget}
                                                    onChange={(event) => { handleChangeConditions(event.target, itemCondition.idCondition, "budget"); }}
                                                />
                                            </div>
                                        </PPGrid>
                                    </>
                                    }
                                </PPGrid>
                            )
                        })}
                </PPGrid>
            </div>
            {
                (alert.conditions[0].condition == 'variacao' || alert.conditions[0].condition == 'count') &&
                <div style={{ margin: '0px 0px 0rem .3rem' }}>
                    <PPGrid container spacing={2} alignItems='flex-start'>
                        <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='titulos'>Período</div>
                        </PPGrid>
                        <PPGrid container spacing={2} alignItems='stretch'>

                            <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            </PPGrid>

                            <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <PPDropDown
                                    name="period"
                                    value={periodList.find(opt => opt.value == alert.period.period)}
                                    options={periodList}
                                    onChange={(event) => { handleChangePeriod(event); }}
                                    disabled={props.readOnly}
                                    required
                                />
                            </PPGrid>

                            <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <PPInput
                                    name="periodValue"
                                    style={{position: 'relative',}}
                                    value={alert.period.periodValue}
                                    step='5'
                                    max='30'
                                    min='1'
                                    type={'number'}
                                    readOnly={props.readOnly}
                                    required={!props.readOnly}
                                    onChange={(event) => { handleChangePeriod(event); }}

                                />
                            </PPGrid>
                            <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <PPDropDown
                                    name="periodUnit"
                                    value={periodUnit.find(opt => opt.value == alert.period.periodUnit)}
                                    options={periodUnit}
                                    onChange={(event) => { handleChangePeriod(event); }}
                                    disabled={props.readOnly}
                                    required
                                />
                            </PPGrid>
                        </PPGrid>

                    </PPGrid >
                </div>
            }
            <div style={{ margin: '0px 0px 1rem 0px' }}>
                <PPGrid container spacing={2} alignItems='flex-start'>
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='titulos'>Usuários a notificar</div>
                    </PPGrid>
                    <PPGrid item xs={11} sm={11} md={11} lg={11} xl={11} >
                        {clientGroupUsers.length > 0 &&
                            <PPDropDown
                                isMulti
                                disabled={props.readOnly}
                                value={alert.usersToNotify}
                                menuPlacement={'auto'}
                                readOnly={props.readOnly}
                                error={getError('usersToNotify')}
                                maxMenuHeight={200}
                                openTrigger="click"
                                required={!props.readOnly}
                                name={'usersToNotify'}
                                options={clientGroupUsers}
                                onChange={(event) => {
                                    if (clientGroupUsers.length > 0) {
                                        handleChangeList({
                                            target: {
                                                name: 'usersToNotify',
                                                value: event.map(user => { return {label: user.label, value: user.value}})
                                            }
                                        })
                                    }
                                }}
                            />
                        }
                    </PPGrid>
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div  className='titulos'>Frequência de notificação</div>

                        <RadioGroup
                            row
                            name="frequency"
                            value={alert.frequency}
                            onChange={(event) => {
                                handleChange(event.target.name, event.target.value);

                            }}
                            style={{marginLeft: '1rem'}}
                        >
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="onEventAndAfter" control={<Radio color="primary" />} label="Continuamente após atingir critério" />
                            <FormControlLabel className="rdButton" disabled={props.readOnly} value="onEvent" control={<Radio color="primary" />} label="Envio unico ao atingir critério " />
                        </RadioGroup>
                    </PPGrid>
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <LcCheckBox
                            disabled={props.readOnly}
                            onChange={(event) => {
                                handleChange(event.name, event.checked);

                            }}
                            title={'Alta prioridade ou Crítico'}
                            name={'highPriority'}
                            checked={alert.highPriority === undefined ? true : alert.highPriority} // Condicional para novo ou edição  
                        />

                    </PPGrid>
                    <PPGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <LcCheckBox
                            disabled={props.readOnly}
                            onChange={(event) => {
                                handleChange(event.name, event.checked);
                            }}
                            title={'Ignorar saving plans ou reservas'}
                            name={'ignoreSavingPlansAndResevations'}
                            checked={alert.ignoreSavingPlansAndResevations === undefined ? true : alert.ignoreSavingPlansAndResevations} // Condicional para novo ou edição
                        />
                    </PPGrid>
                </PPGrid >
            </div>
            {!props.readOnly &&
                <p className="text-danger font-sm">{setTooltipContent()}</p>
            }
            <Confirmation
                title="Confirme"
                text='Tem certeza que deseja perder as alterações feitas?'
                confirm={() => {setConfirmVisible(false); props.onCancelAction && props.onCancelAction() }}
                close={() => { setConfirmVisible(false) }}
                textBtnOk='Sim'
                textBtnCancel='Não'
                display={confirmVisible}
            />
        </PPModal >

    )
};

export default AlertasEditorVersaoDois;