import api, { APIClient } from "../api";

export interface IOptions {
    options: { value: string, name: string }[],
}

export interface IFilter {
    services: { value: string, name: string }[],
    agregationGroups: { value: string, name: string }[],
    vendorCredentialsItems: { value: string, name: string }[]
}

export interface FilterObjectSend {
    clientGroupId: number,
    serviceId: number,
    credentialItemId: number,
    agregationGroupId: number,
    iDisplayLength: number,
    iDisplayStart: number,
    isLinked: boolean,
    sSearch: string,
    ordered: string,
    direction: string
}

export class ResourceSipContractService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    public ClientGroupOptions: IOptions = {
        options: [
        ]
    };

    public ContractsOptions: IOptions = {
        options: [
        ]
    }

    public CloudOSOptions: IOptions = {
        options: [
        ]
    }

    public FilterOptions: IFilter = {
        services: [{ value: '0', name: 'Todos' }],
        agregationGroups: [{ value: '0', name: 'Todos' }],
        vendorCredentialsItems: [{ value: '0', name: 'Todos' }]
    }

    public defaultFilterObj: FilterObjectSend = {
        clientGroupId: 0,
        serviceId: 0,
        credentialItemId: 0,
        agregationGroupId: 0,
        iDisplayLength: 12,
        iDisplayStart: 0,
        isLinked: true,
        sSearch: '',
        ordered: "cloudResourceId",
        direction: "asc",
    }

    async GetClients() {
        const url = this.endpoint + `/api/ResourceSipContract/SipContract/ClientGroups`;
        await api({
            method: 'get',
            url,
        }).then(response => {
            if (response.status == 200) {
                let activeClientGroups = response.data.filter(x => x.active == true)
                activeClientGroups.map((element, index) => {
                    this.ClientGroupOptions.options.push(
                        { value: element.id, name: element.description }
                    )
                })
            }
        });
        return this.ClientGroupOptions
    }

    async GetFilter() {
        const url = this.endpoint + `/api/ResourceSipContract/GetFilters`;
        await api({
            method: 'get',
            url,
        }).then(response => {
            if (response.status == 200) {

                response.data.services.map((element) => {
                    this.FilterOptions.services.push(
                        { value: element.id, name: element.serviceName }
                    )
                })
                response.data.agregationGroups.map((element) => {
                    this.FilterOptions.agregationGroups.push(
                        { value: element.id, name: element.groupName }
                    )
                })
                response.data.vendorCredentialsItems.map((element) => {
                    this.FilterOptions.vendorCredentialsItems.push(
                        { value: element.id, name: element.alias }
                    )
                })
            }
        })

        return this.FilterOptions;
    }

    public getFilterObj() {
        return this.defaultFilterObj;
    }       

    async GetCloudResourceView(year, month) {
        let url = this.endpoint + `/api/ResourceSipContract/GetCloudResourceView?year=${year}&month=${month}`;

        return api({
            method: 'get',
            url,
        })
    }

    async GetCloudResourceCostCenterView(year, month) {
        let url = this.endpoint + `/api/ResourceSipContract/GetCloudResourceCostCenterView?year=${year}&month=${month}`;

        return api({
            method: 'get',
            url,
        })
    }

    async GetContractsByClientID(ClientGroupId) {
        
        const url = this.endpoint + `/api/ResourceSipContract/ContractsByClient?ClientGroupId=${ClientGroupId}`;
        let result: any[] = []
        await api({
            method: 'get',
            url,
        }).then(response => {
            result = response.data.map((element, index) => {
                return {
                    ...element,
                    // id: index
                }
            })
        })
        return result;
    }

    async GetContracts() {
        
        const url = this.endpoint + `/api/ResourceSipContract/ContractsByClient`;
        let result: any[] = []
        await api({
            method: 'get',
            url,
        }).then(response => {
            result = response.data.map((element, index) => {
                return {
                    ...element,
                    // id: index
                }
            })
        })
        return result;
    }

    async GetCostCenter() {
        
        const url = this.endpoint + `/api/ResourceSipContract/RetrieveListByClientGroupId`;
        let result: any[] = []
        await api({
            method: 'get',
            url,
        }).then(response => {
            
            result = response.data.map((element, index) => {
                return {
                    ...element,
                    // id: index
                }
            })
        })
        return result;
    }

    async GetContractsByGtmId(gtmId: number, token?: string) {
        const url = this.endpoint + `/api/ResourceSipContract/ContractsByGtmId?gtmId=${gtmId}&token=${token}`;
        let result: any[] = []
        await api({
            method: 'get',
            url,
        }).then(response => {
            result = response.data.map((element, index) => {
                return {
                    ...element,
                    // id: index
                }
            })
        })
        return result;
    }

    async LinkResourceToContract(filter, contractToLink) {
        
        const url = this.endpoint + `/api/ResourceSipContract/SaveSipContractResource`;

        const data = JSON.stringify({ filter: filter, contract: contractToLink });

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
    }

    async RedirectResourceToContract(filter, contractToLink, checkedSetContract, checkedAddContract, year, month) {
        

        const url = this.endpoint + `/api/ResourceSipContract/RedirectSipContractResource`;
        const data = {
            filter: JSON.stringify(filter), contract: contractToLink,
            setContract: checkedSetContract, addContract: checkedAddContract,
            year: year,
            month: month
        };
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
    }

    async RedirectCostCenterResource(filter, costCenterToLink, checkedsetCostCenter, checkedAddCostCenter, year, month) {
        

        const url = this.endpoint + `/api/ResourceSipContract/RedirectCostCenterResource`;
        const data = {
            filter: JSON.stringify(filter),
            costcenter: costCenterToLink,
            setCostCenter: checkedsetCostCenter,
            addCostCenter: checkedAddCostCenter,
            year: year,
            month: month
        };
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
    }

    async SaveDistributionParameter(filter,
        contractToLink,
        isDistributionCostBase,
        isDistributionCost,
        clientId, year, month) {
        
        //public string Filter { get; set; }
        //public int ? Month { get; set; }
        //public int ? Year { get; set; }
        //public int ? CloudGroupId { get; set; }
        //public bool ? IsDistributionCostBase { get; set; }
        //public bool ? IsDistributionCost { get; set; }
        //public int ? NumeroSIP { get; set; }
        //public int ? ResourceOriginCloudGroupId { get; set; }
        const url = this.endpoint + `/api/ResourceSipContract/UpdateResourceDistributionFilter`;
        const data = {
            filter: JSON.stringify(filter),
            contract: contractToLink,

            isDistributionCostBase: isDistributionCostBase,
            isDistributionCost: isDistributionCost,
            numeroSIP: contractToLink,
            contractClientGroupId: clientId,
            year: year,
            month: month
        };

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
    }

    async GetContractOptions() {
        const url = this.endpoint + `/api/ResourceSipContract/ContractsByClient`;
        await api({
            method: 'get',
            url,
        }).then(response => {
            this.ContractsOptions.options = response.data.map((element) => {
                return {
                    name: `N° ${element.contractId} | ${element.contractName}`,
                    value: element.id
                }
            })
        }).catch(response => {

        });
        return this.ContractsOptions;
    }

    async GetResourcesByContract(SipId, Page, PageSize?) {
        const url = this.endpoint + `/api/ResourceSipContract/GetResourcesBySipId/sipId/${SipId}/skip/${Page}/pageSize/${PageSize}`;
        let result: any;


        await api({
            method: 'get',
            url,
        }).then(response => {
            response.data.cloudResourceView = response.data.cloudResourceView.map((element, index) => {
                return {
                    ...element,
                    id: index
                }
            })
            result = response
        }).catch(response => {

        });
        return result
    }

    async GetCloudOSProvider() {
        const url = this.endpoint + `/api/ResourceSipContract/GetAllOS`;
        await api({
            method: 'get',
            url,
        }).then(response => {
            this.CloudOSOptions.options = response.data.map((element) => {
                return {
                    name: element.nome,
                    value: element.id
                }
            })
        }).catch(response => {

        });
        return this.CloudOSOptions;
    }

    async GetResourcesByCloudOS(CloudOsId, Page, PageSize) {

        const url = this.endpoint + `/api/ResourceSipContract/GetResourcesByCloudOS/cloudOs/${CloudOsId}/skip/${Page}/pageSize/${PageSize}`;
        let result: any;

        await api({
            method: 'get',
            url,
        }).then(response => {
            response.data.cloudResourceView = response.data.cloudResourceView.map((element, index) => {
                return {
                    ...element,
                    id: index
                }
            })
            result = response
        }).catch(response => {

        });
        return result
    }
}