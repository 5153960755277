import { Button } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { InputField, TextAreaField } from '../../../components/Form/Input';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import style from './index.module.css'

import AccountService from '../../../services/accessProfile/accessProfile'

import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { Account } from '../main';
import { useSnackbar } from 'notistack';
interface Props {
    account: Account
    close: Function
}

const AccessProfile: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new AccountService(props)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const [records, setRecords] = useState<any[]>([]);
    const [name, setName] = useState<string>(props.account.nome);
    const [nameGroupAd, setNameGroupAd] = useState<string>(props.account.nomeGrupoAd || '')
    const [isAd, setIsAd] = useState<boolean>(false);
    const [isTemplate, setIsTemplate] = useState<boolean>(props.account.isTemplate);
    const [isActive, setIsActive] = useState<boolean>(props.account.ativo);
    const [gtmItemId, setGtmItemId] = useState<number>(props.account.gtmItemId);
    const [gtmId, setGtmId] = useState<number>(props.account.gtmId);
    const [gtmMetric, setGtmMetric] = useState<number>(props.account.gtmMetric || 0);
    const [description, setDesription] = useState<string>(props.account.description || '');

    const columns = [
        { field: 'profileName', headerName: 'Nome do Perfil', flex: 1 }
    ]
    const functions = [
        {
            title: 'Adicionar membro',
            icon: 'ppi ppi-plus',
            func: (ids: [0]) => {
                if (ids.length < 1) {
                    return
                }
                let idSelected = ids[0]
                let selectedRow = records.filter(user => user.id == idSelected)
                let obj = selectedRow[0];
            }
        }
    ]

    const saveRecord = () => {
        let clientGroupPerfil
        if (Number(user.ClientGroupSelected) == 492) {
            clientGroupPerfil = {
                id: props.account.id,
                clientGroupId: props.account.clientGroupId,
                nome: name,
                ativo: isActive,
                isTemplate: isTemplate,
                nomeGrupoAD: nameGroupAd,
                GTMItemId: gtmItemId,
                GTMId: gtmId,
                GTMMetric: gtmMetric,
                description:
                    description
            }

        } else {
            clientGroupPerfil = {
                id: props.account.id,
                clientGroupId: props.account.clientGroupId,
                nome: name,
                ativo: isActive,
                nomeGrupoAD: nameGroupAd,
                isTemplate: isTemplate,
                description: description
            }
        }
        service.UpdateClientGroupPerfil(clientGroupPerfil)
            .then(result => {
                enqueueSnackbar("Perfil de acesso atualizado com sucesso.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
                props.close()
            })
            .catch(error => {
                console.log(error);
                enqueueSnackbar("O ocorreu um erro ao atualizar o perfil.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            });

    }

    return (
        <div className='form'>
            <InputField
                onChange={(e) => setName(e.value)}
                type="text"
                name="Nome"
                label="Nome"
                readonly={Number(user.ClientGroupSelected) != 492}
                defaultValue={props.account.nome}
                value={props.account.nome}
            />

            {Number(user.ClientGroupSelected) == 492 && <LcCheckBox checked={isAd} title="Sincronizar AD" name="ad" onChange={(e) => setIsAd(e.checked)} />}
            {
                Number(user.ClientGroupSelected) == 492 ?
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputField width='95%' name="GTMId" defaultValue={props.account?.gtmId} type="number" label="GTM" onChange={(e) => setGtmId(e.value)} />
                    <InputField width='95%' name="GTMItemId" defaultValue={props.account?.gtmItemId} type="number" label="GTMItem" onChange={(e) => setGtmItemId(e.value)} />
                    <InputField width='100%' name="GTMMetric" defaultValue={props.account?.gtmMetric} type="number" label="Metrica" onChange={(e) => setGtmMetric(e.value)} />
                    <LcCheckBox checked={isTemplate} name="isTemplate" title="Template" onChange={(e) => setIsTemplate(e.checked)} />
                </div>
                     :   
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <InputField readonly={true} disable={ true} width='95%' name="GTMId" defaultValue={props.account?.gtmId} type="number" label="GTM" onChange={(e) => setGtmId(e.value)} />
                    <InputField readonly={true} disable={ true} width='95%' name="GTMItemId" defaultValue={props.account?.gtmItemId} type="number" label="GTMItem" onChange={(e) => setGtmItemId(e.value)} />
                    <InputField readonly={true} disable={true}  width='100%' name="GTMMetric" defaultValue={props.account?.gtmMetric} type="number" label="Metrica" onChange={(e) => setGtmMetric(e.value)} /> 
                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}> Descrição:</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {props.account?.description}
            </div>
            {
                Number(user.ClientGroupSelected) == 492 &&
                <button style={{ width: '30%' }} type='submit' className="lc-button bg-info" onClick={saveRecord}>Salvar Alterações</button>}

        </div>
    )
}

export default AccessProfile;