import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Layout from '../../../components/Layout/Layout';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { SaasService } from '../../../services/saas/saasService';
import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';

import Moment from 'moment';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import GaugeChart from '../../../components/Layout/Dashboard/gaugeChart';
import LcLoading from '../../../components/Generic/LcLoading';
import HomeService, { TileControlViewModel } from '../../../services/homeService';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

const SaaSDashBoard: React.FC = (props) => {

    const history = useHistory();
    const homeService = new HomeService(props);

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(Moment().format('M'));
    const [year, setYear] = useState<string>(Moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const saasService = new SaasService(props);

    useEffect(() => {

        setSloChart(0);
        setSlaChart(0);
        setChamadosChart({ title: "", labels: [], values: [] });
        setTotalChamadosChart({ title: "", labels: [], values: [] });
        setChamadosPorOrigemChart({ title: "", labels: [], values: [] });

        if (user.ClientGroupSelected) {

            setLoading(true);

            saasService.GetCards(month, year)
                .then(response => {
                    if (response.data != null) {
                        setSloChart(response.data.slaConclusao == 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));

                        setSlaChart(response.data.slaAtendimento == 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));
                        let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                            (response.data.totalChamadosHC + 10)
                            : (response.data.totalChamadosFHC + 10);

                        let chamadosChart: DoughnutChartValue = {
                            title: 'Chamados por horário',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosHC);
                            chamadosChart.labels.push('Comercial');
                        }
                        if (response.data.totalChamadosFHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosFHC);
                            chamadosChart.labels.push('Não comercial');
                        }
                        setChamadosChart(chamadosChart);
                        setIsLoadingChamadosChart(false)
                        let totalChamadosChart: DoughnutChartValue = {
                            title: 'Chamados por situação',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                            totalChamadosChart.labels.push('Abertos');
                        }
                        if (response.data.totalChamadosFechadas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                            totalChamadosChart.labels.push('Fechados');
                        }
                        if (response.data.totalChamadosCanceladas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                            totalChamadosChart.labels.push('Cancelados');
                        }
                        setTotalChamadosChart(totalChamadosChart);
                        setIsLoadingTotalChamadosChart(false)

                        let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                        let _c = clientName.split(' ')
                        let capitalized = '';
                        _c.map((e: any) => {
                            capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                        })

                        let chamadosPorOrigemChart: DoughnutChartValue = {
                            title: 'Chamados por origem',
                            labels: [],
                            values: []
                        }
                        if (response.data.totalChamadosAbertosPeloCliente > 0) {
                            chamadosPorOrigemChart.labels.push(capitalized)
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                        }
                        if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                            chamadosPorOrigemChart.labels.push('Processor')
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                        }
                        setChamadosPorOrigemChart(chamadosPorOrigemChart)
                        setIsLoadingChamadosPorOrigemChart(false)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setLoading(false);
                });

        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {

        let momentDate = Moment(selected.fields[0].value).format('YYYY-M');
        let date = momentDate;
        let currentDay = '01';
        let currentMonth = date.split('-')[1];
        let currentYear = date.split('-')[0];
        setDay(currentDay);
        setMonth(currentMonth);
        setYear(currentYear);
        setSearchStartDate(`${currentYear}${currentMonth.padStart(2, '0')}${currentDay}`);
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-M') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-M') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-M') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    }

    const [tiles, setTiles] = useState<TileControlViewModel[]>([]);
    const [allowedMenuIds, setAllowedMenuIds] = useState<string[]>([])

    const getMenuIds = (menuItems: any) => {
        let ids: any[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].children && menuItems[i].children.length > 0) {
                ids = ids.concat(getMenuIds(menuItems[i].children));
            }
            ids.push(menuItems[i].id);
        }
        return ids;
    }

    useEffect(() => {
        setAllowedMenuIds(getMenuIds(menu.items));
    }, [menu])


    useEffect(() => {
        if (!month && !year) {
            setMonth(Moment().format('MM'));
            setYear(Moment().format('YYYY'));
        }

        if (month && year && !loading) {
            setLoading(true);
            loadHomData(month as string, year as string)
                .then((item) => { });
        }

    }, [year, month, user.ClientGroupSelected, user.refreshFlag]);


    async function loadHomData(month: string, year: string) {
        setLoading(true);
        try {
            let responseGetTileControlViewModel = await homeService.GetTileInfo(month, year);
            setTiles(responseGetTileControlViewModel);
        } catch (e) {

        }
        setLoading(false);
    }

    //Para usar nos componentes - Cores padronizadas LC11
    const colors = [
        "#5969CD", "#E273C5", "#804E3B", "#A5Bf3E", "#8684EF", "#F3C944", "#59BAC5",
        "#D5672F", "#307422", "#169D36", "#E74A67", "#C23D4F", "#D7BA45", "#B24F5D"
    ];

    const handleTileUrl = (url: string) => {
        user.Menus?.forEach((itemA: any) => {
            itemA.children.forEach((itemB: any) => {
                itemB.children.forEach((itemC: any) => {
                    if (itemC.id == url) {
                        history.push(itemC.route);
                    }
                })
            })
        })
    }

    return (
        <Layout pageTitle="Resumo" periodSelection={periodSelection}>
            <LCFlexContainer spacing={1}>
                <LCFlexItem xs={12} sm={6} md={3} lg={3}>
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChart steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={slaChart} title="SLA de atendimento" info="Específico da solução"></GaugeChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={3} lg={3}>
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChart steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={sloChart} title="SLO de conclusão" info="Específico da solução"></GaugeChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosChart}>
                        <DoughnutChart fullHeigth={true} data={chamadosChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingTotalChamadosChart}>
                        <DoughnutChart data={totalChamadosChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
                <LCFlexItem xs={12} sm={6} md={6} lg={6}>
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosPorOrigemChart}>
                        <DoughnutChart data={chamadosPorOrigemChart}></DoughnutChart>
                    </LcLoading>
                </LCFlexItem>
            </LCFlexContainer>
            <LCFlexContainer spacing={1}>
                {
                    tiles && tiles.length > 0 &&
                    tiles
                        .filter(t => t.productType == 9)
                        .map((tile: TileControlViewModel, index: number) => {
                            if (tile && tile.title && !tile.enabled) {
                                sessionStorage.removeItem(tile.title)
                                let formatValue = true;
                                let valueTile = tile?.hired?.toString().replace('/', '') || tile?.consumption || '';
                                let extraDataUnitDescription = tile?.extraDataUnitDescription;
                                let unity = "";

                                let formated = tile?.consumption;
                                valueTile = formated;
                                formatValue = false;

                                tile && tile.title && sessionStorage.setItem(tile.title.toString(), valueTile)

                                if (tile.href && (allowedMenuIds.includes(tile.href.toLowerCase()) || user.IsAdm )) {
                                    return (
                                        <LCFlexItem key={index} xs={12} sm={3} md={3} lg={3}>
                                            <IconValueCardRow onClick={() => { handleTileUrl(tile.href) }} maximumFractionDigits={unity == 'TB' ? 1 : 0} unity={unity} formatValue={formatValue} title={tile?.title || ''} icon={tile?.faIcon || ''} value={valueTile} description={extraDataUnitDescription || ''} color={colors[index]} />
                                        </LCFlexItem>
                                    );
                                }
                            }
                        })
                }
            </LCFlexContainer>
        </Layout>
    );
};

export default SaaSDashBoard;