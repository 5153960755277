import React from 'react';
import EndpointDetectionResponseResume from '../../../../../pages/endpointDetectionResponse/resume';
import PatchManagerResume from '../../../../../pages/PatchManager/ResumePathManager';
import LiveWatchInsights from '../../../../../pages/LiveWatch/Insights';

interface SecCardProps {
    GTMs: string[];
    QtdGroups: number;
}

const componentFactory = (gtm: string, props: { isHome: boolean, qtdGroups: number, isSecOpsPage: boolean, secOpsQtdGroups: number }) => {
    switch (gtm) {
        case 'xdr':
            return <EndpointDetectionResponseResume {...props} />;
        case 'livewatch':
            return <LiveWatchInsights {...props} />;
        case 'patchmanager':
            return <PatchManagerResume {...props}  />;
        default:
            return 
    }
}

const SecCard: React.FC<SecCardProps> = ({ GTMs, QtdGroups }) => {
    const isSecOpsPage = window.location.pathname.toLowerCase().includes('secops');
    const isHomePage = window.location.pathname === '/';
    let result = GTMs.includes('patchmanager')
    ? [...new Set(GTMs.filter(item => item === 'xdr' || item === 'patchmanager'))]
    : [...new Set(GTMs.filter(item => item === 'xdr' || item === 'livewatch'))];
    if (result.length === 0) {
        result = [...new Set(GTMs.filter(item => item === 'xdr' || item === 'patchmanager' || item === 'livewatch'))];
    } 
    result.sort((a, b) => {
        if (a === 'xdr') return -1;
        if (b === 'xdr') return 1;
        if (a === 'patchmanager') return -1;
        if (b === 'patchmanager') return 1;
        return 0;
    });

    const props = { isHome: isHomePage, qtdGroups: QtdGroups, secOpsQtdGroups: result.length, isSecOpsPage: isSecOpsPage};
    return (
        <>
            {isSecOpsPage 
                ?  
                GTMs.map(gtm => componentFactory(gtm, props))
                : <div className='row between' style={{height: 'calc(100vh - 140px)', flexDirection: 'column'}}>
                    {result.map(gtm => componentFactory(gtm, props))}
                </div> 
                    
            } 
        </>
    );
};

export default SecCard;