import React from 'react';
import Layout from '../../components/Layout/Layout';
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';
import AssistantList from './AssistantList';

const LLMAssistant: React.FC = () => {
    return (
        <AssistantList />
    );
};

export default LLMAssistant;
