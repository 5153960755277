// Adicionar o ip do usuário a lista com checkbox, preencher o campo de ip 
// Limpar o form quando adicionar um ip
// Criar duas variaveis uma para ip e outra para alias, remover necessidade de um form

import React, { useEffect, useState } from 'react';

//Layout
import Layout from '../../../components/Layout/Layout';

//Componetes
import LcTable from '../../../components/Data/LcTable';
import LcLoading from '../../../components/Generic/LcLoading';
import LcCheckBox from '../../../components/Form/LcCheckBox';

//Serviço
import { RetencaoService, StorageAccountItem } from '../../../services/retencao/retencaoService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import LcIconLink from '../../../components/Generic/LcIconLink';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import { useSnackbar } from 'notistack';
import { LinearProgress, Grid } from '@material-ui/core';
import axios from 'axios';

import { PPInput, PPModal } from 'processor-plataform-ui';

const StorageAccount: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const retencaoService = new RetencaoService(props);

    const [loading, setLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoadingAccess, setIsLoadingAccess] = useState<boolean>(false);
    const [isLoadingAlias, setIsLoadingAlias] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [alias, setAlias] = useState<any[]>([]);
    const [form, setForm] = useState<any>({ ipToAdd: '', aliasToAdd: '', allowAccess: false });
    const [newIp, setNewIp] = useState("");
    const [newAlias, setNewAlias] = useState("");
    const [userIp, setUserIp] = useState("");
    const [upserIpExists, setUserIpExists] = useState(false);

    const [ipsToRemove, setIpsToRemove] = useState<string[]>([]);

    //Edição do controle de acesso
    const [selectedStorageAccount, setSelectedStorageAccount] = useState<StorageAccountItem | undefined>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const resetForm = () => {
        setAlias([])
        setIpsToRemove([])
        setUserIpExists(false)
        setForm({ ipToAdd: '', aliasToAdd: '', allowAccess: false })
    }

    const loadData = () => {
        setLoading(true);
        retencaoService.GetStorageAccount()
            .then(response => {
                setData(response.data.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 }));
            })
            .catch(console.warn)
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(loadData, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        axios.get("https://geolocation-db.com/json/")
            .then((response) => {
                setUserIp(response.data.IPv4);
            })
            .catch((error) => {
                console.warn(error);
            })
    }, []);

    const columns = [
        { field: 'name', headerName: 'Nome', width: "30%" },
        { field: 'regionName', headerName: 'Região', width: "30%" },
        { field: 'resourceGroupName', headerName: 'Grupo', width: "30%" },
        {
            field: 'isAccessAllowedFromAllNetworks', headerName: 'Acesso', width: "10%",
            renderCell: (row: any) => {
                return row.isAccessAllowedFromAllNetworks ? 'Livre' : 'Controlado'
            }
        }
    ]

    const functionsRow = [
        {
            label: 'Controle de acesso',
            func: (row: any) => {
                setSelectedStorageAccount(row);
                setForm({ ...form, allowAccess: row.isAccessAllowedFromAllNetworks })
                setIsLoadingAlias(true);
                retencaoService.GetStorageAccountIpsAlias(row.id)
                    .then(response => {
                        if (response.data && response.data.length > 0) {
                            setAlias(response.data);
                        }
                        setIsLoadingAlias(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setIsLoadingAlias(false);
                    })
            }
        }
    ]

    const status = (row: any) => { return row.isAccessAllowedFromAllNetworks ? 'success' : 'danger' };

    const handleSubmit = () => {
        setIsSaving(true);
        let storageAccountToUpdate = {
            Id: selectedStorageAccount!.id,
            IsAccessAllowedFromAllNetworks: form.allowAccess,
            IPAddressesWithAccess: selectedStorageAccount!.ipAddressesWithAccess.map(ips => { return { ...ips, action: null } })
        };
        let ips = selectedStorageAccount!.ipAddressesWithAccess.map(ip => {
            return ({
                storageId: storageAccountToUpdate.Id,
                ip: ip.ipAddressOrRange,
                alias: alias.find(x => x.ip == ip.ipAddressOrRange) ? alias.find(x => x.ip == ip.ipAddressOrRange).alias : form.aliasToAdd
            })
        });

        retencaoService.PostAzureStorageAccount(storageAccountToUpdate)
            .then(response => {
                retencaoService.PostStorageAccount(ips)
                    .then(response => {
                        enqueueSnackbar('Sucesso ao adicionar ips ao storage account.', {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                        setIsSaving(false);
                        setSelectedStorageAccount(undefined);
                        loadData();
                    })
                    .catch(error => {
                        console.log(error);
                        setIsSaving(false);
                        enqueueSnackbar('Erro ao adicionar ips ao storage account.', {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        })
                    });
            })
            .catch(error => {
                console.log(error);
                setIsSaving(false);
                enqueueSnackbar('Erro ao adicionar ips ao storage account na Azure.', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
            });
    }

    const removeIp = (ip: string) => {
        setIsLoading(true);
        let _selectedStorageAccount = selectedStorageAccount;
        _selectedStorageAccount!.ipAddressesWithAccess = _selectedStorageAccount!.ipAddressesWithAccess.filter((r) => r.ipAddressOrRange != ip);
        setSelectedStorageAccount({ ..._selectedStorageAccount! });
    }

    function handleChange(event: any) {
        const { name, value } = event;
        let _Form: any = form;
        _Form[name] = value;
        setForm(_Form);
    };

    const addIp = (ip: string) => {
        setIsLoading(true);
        let _selectedStorageAccount = selectedStorageAccount;
        let ips = _selectedStorageAccount!.ipAddressesWithAccess.filter((r) => r.ipAddressOrRange != ip);
        ips.push({ ipAddressOrRange: ip, action: null });
        _selectedStorageAccount!.ipAddressesWithAccess = ips;
        let _aliasToAdd = {
            storageId: selectedStorageAccount!.id,
            ip: ip,
            alias: newAlias != "" ? newAlias : "NA"
        };
        let _alias = alias;
        _alias.push(_aliasToAdd);
        setAlias(_alias);
        setSelectedStorageAccount({ ..._selectedStorageAccount! });
        setNewAlias("");
        setNewIp("");
    }

    useEffect(() => { setIsLoading(false); }, [selectedStorageAccount?.ipAddressesWithAccess]);
    useEffect(() => { setIsLoadingAccess(false); }, [form.allowAccess]);

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [filterVisible, setFilterVisible] = useState(false);

    const ipTable = [
        {
            field: '', headerName: '', width: '45%', sort: false,
            renderCell: (row) => {
                let found = alias?.find(x => x.ip == row.ipAddressOrRange);
                return found && found.alias != "" ?
                    found.alias
                    : "NA"
            }
        },
        {
            field: '', headerName: '', width: '45%', sort: false,
            renderCell: (row) => {
                return row.ipAddressOrRange;
            }
        },
        !form.allowAccess && {
            field: '', headerName: '', width: '10%', sort: false, align: 'center',
            renderCell: (row) => {
                return <LcCheckBox
                    checked={true}
                    onChange={() => {
                        let ip = ipsToRemove.find(i => i == row.ipAddressOrRange);
                        if (ip) {
                            setIpsToRemove(ipsToRemove.filter(i => i != ip));
                        } else {
                            let ipsList = ipsToRemove;
                            ipsList.push(row.ipAddressOrRange);
                            setIpsToRemove(ipsList)
                        }
                    }} />
            }
        }
    ]

    const ipTableFunctions = [
        {
            title: `Remover Ip's`,
            icon: 'ppi ppi-minus',
            tooltip: `Remover Ip's`,
            func: () => {
                ipsToRemove.map(ip => {
                    removeIp(ip);
                })
            }
        },
    ]

    const validaIp = () => {
        let regex = /^((1?\d{1,2}|2([0-4]\d|5[0-5]))\.){3}(1?\d{1,2}|2([0-4]\d|5[0-5]))(\/([1-9]|[1-2][0-9]|3[0-2]))?$|^$/;
        if (newIp == "0.0.0.0") {
            return {
                show: true,
                help: "Digite um Ip válido"
            }
        } else {
            return {
                show: newIp != "" && !regex.test(newIp),
                help: "Digite um Ip válido"
            }
        }
    }

    useEffect(() => {
        if (selectedStorageAccount?.ipAddressesWithAccess) {
            setUserIpExists(selectedStorageAccount.ipAddressesWithAccess.find(ip => ip.ipAddressOrRange == userIp) != undefined)
        }
    }, [selectedStorageAccount])

    return (
        <Layout
            cardType
            pageTitle="Storage Accouts"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: data.length
            }}
        >
            <LcLoading loading={loading} loadingType='Helix'>
                <LcTable
                    columns={columns}
                    data={data}
                    filterFields={[{ label: 'Pesquisa', name: 'name', type: 'text' }]}
                    filterExternal={{
                        visible: filterVisible,
                        onChange: () => setFilterVisible(!filterVisible)
                    }}
                    functionsRow={functionsRow}
                    status={status}
                    density={pref.data?.density || "high"}
                />
            </LcLoading>

            <PPModal
                visible={selectedStorageAccount != undefined}
                title={`Editando ${selectedStorageAccount && selectedStorageAccount.name}`}
                onClose={() => { setSelectedStorageAccount(undefined); resetForm() }}
                functions={[
                    { label: "Ok", onClick: () => { handleSubmit() }, disabled: isSaving, tooltip: isSaving ? "Salvando" : "" },
                    { label: "Cancelar", onClick: () => { setSelectedStorageAccount(undefined); resetForm() } }
                ]}
            >
                {
                    selectedStorageAccount && !isLoadingAlias ?
                        <form className="form">
                            <LcCheckBox title="Acesso permitido de qualquer IP" name="allowAccess" checked={form.allowAccess} onChange={(event) => { setIsLoadingAccess(true); handleChange({ name: event.name, value: event.checked }) }} />

                            <div className="lc-segment">

                                {
                                    selectedStorageAccount?.ipAddressesWithAccess.length <= 0 &&
                                    <span>Não há IPs definidos</span>
                                }

                                {!isLoading ?
                                    //IPs liberados
                                    <LcTable
                                        data={selectedStorageAccount.ipAddressesWithAccess}
                                        density={"high"}
                                        columns={ipTable}
                                        functions={!form.allowAccess ? ipTableFunctions : []}
                                    />
                                    : <LcLoading></LcLoading>
                                }

                                {!form.allowAccess && !isLoadingAccess ?
                                    <>
                                        <hr />
                                        <LCFlexContainer justify="flex-start" spacing={2}>
                                            <LCFlexItem xs={10} sm={10} md={10} lg={10}>
                                                <span className="lc-title">Incluir IP</span>
                                                {!upserIpExists ?
                                                    <div className='row'>
                                                        <LcCheckBox checked={upserIpExists}
                                                            onChange={(event) => { event.checked ? setNewIp(userIp) : setNewIp("") }}
                                                        />
                                                        Adicionar o seu ip {userIp}
                                                    </div>
                                                    :
                                                    <div className='row mb-5'>
                                                        Seu ip já está na lista
                                                    </div>
                                                }
                                                <PPInput type="text" title="Alias" name="aliasToAdd" value={newAlias} onChange={(event) => { setNewAlias(event.target.value) }} />
                                                <PPInput
                                                    type="text"
                                                    title="IP"
                                                    name="ipToAdd"
                                                    value={newIp}
                                                    onChange={(event) => { setNewIp(event.target.value) }}
                                                    error={validaIp()}
                                                />

                                            </LCFlexItem>
                                            <Grid item container xs={2} sm={2} md={2} lg={2} direction="row" justify="center" alignItems="flex-end">
                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                    <LcIconLink
                                                        size="big"
                                                        color="green"
                                                        tooltipPosition='right'
                                                        tooltip={validaIp().show ? validaIp().help : "Incluir"}
                                                        icon="ppi ppi-check"
                                                        disabled={validaIp().show || newIp == ""}
                                                        onClick={() => { addIp(newIp) }} />
                                                </Grid>
                                            </Grid>
                                        </LCFlexContainer>
                                    </>
                                    : <LcLoading></LcLoading>
                                }
                            </div>
                            {/*
                             <div className="space-between">
                                <button className="lc-button bg-primary" type="submit">{isSaving ? <LinearProgress size="2rem" /> : "Salvar"}</button>
                                <button className="lc-button bg-grey" type="reset" onClick={() => setSelectedStorageAccount(undefined)}>Cancelar</button>
                            </div>
                             */}
                        </form>
                        :
                        <LinearProgress />
                }

            </PPModal>

        </Layout>
    );
}

export default StorageAccount;