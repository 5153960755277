
import { GridCellValue } from "@material-ui/data-grid";
import api, { APIClient } from "./api";
import { palette } from '@material-ui/system';
import { SaaSGroup } from "../pages/SaasGroup/Model/GroupSaaS";
import { SaaSGroupUserMember } from "../pages/SaasGroup/Model/UserGroupMmbers";
export class SaaSGroupMembersService extends APIClient {

    public EnumDomains: any[] = [{}];
    public EnumVendorCredentials: any[] = [{}];
    constructor(props: any) {
        super(props);
    }
    title = 'Grupo SaaS Members';
    async Delete(id: number) {

        const url = `${this.endpoint}/api/SaaSGrupos/Delete/${id}`;
        var response = api({
            method: 'delete',
            url,
            withCredentials: false
        });
        return response;
    }

    filterGridDefault = { start: 0, size: 100000, filter: '', ordered: 'DisplayName', direction: 'asc' };

    async RetrieveList(id: any, type: any) {

        const url = `${this.endpoint}/api/SaaSMembros/RetrieveList?id=${id}&type=${type}&refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false
        });
        return response;
    }

    RetrieveUnLinkedUserList(id: any, type: any, filterDataFilter: any) {

        let filterData = filterDataFilter;
        if (filterDataFilter == null || filterDataFilter == undefined) {
            filterData = this.filterGridDefault;
        }

        var filter = `?start=${filterData.start}&size=${filterData.size}&filter=${filterData.filter}&ordered=${filterData.ordered}&direction=${filterData.direction}&groupId=${id}&type=${type}&refresh=${Math.random()}`;

        const url = `${this.endpoint}/api/SaaSMembros/RetrieveUnLinkedUserList${filter}`;
        var response = api({
            method: 'get',
            url
        });
        return response;
    }

    Retrieve(id: number) {
        const url = `${this.endpoint}/api/SaaSGrupos/Retrieve?id=${id}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false,
        });
        return response;
    }

    async RemoveUserGroupLink(id: any, groupid: any, type: any) {

        const url = `${this.endpoint}/api/SaaSMembros/Remover?userId=${id}&groupId=${groupid}&type=${type}`;
        var response = await api({
            method: 'post',
            url,
            data: null,
            withCredentials: false,
        });
        return response;
    }

    async CreateUserGroupLinks(items: any[]) {
        const url = `${this.endpoint}/api/SaaSMembros/CreateLinks`;
        var response = await api({
            method: 'post',
            url,
            data: items,
            withCredentials: false,
        });
        return response;
    }

    async CreateUserGroupLink(id: any, groupid: any, type: any) {

        const url = `${this.endpoint}/api/SaaSMembros/Create?userId=${id}&groupId=${groupid}&type=${type}`;
        var response = await api({
            method: 'post',
            url,
            data: null,
            withCredentials: false,
        });
        return response;
    }

    public emptyRecord: SaaSGroupUserMember = {

        "accountEnabled": null,
        "ageGroup": null,
        "assignedLicenses": null,
        "assignedPlans": null,
        "businessPhones": [],
        "city": null,
        "companyName": null,
        "consentProvidedForMinor": null,
        "country": null,
        "createdDateTime": null,
        "creationType": null,
        "department": null,
        "displayName": '',
        "employeeHireDate": null,
        "employeeId": null,
        "employeeOrgData": null,
        "employeeType": null,
        "externalUserState": null,
        "externalUserStateChangeDateTime": null,
        "faxNumber": null,
        "givenName": '',
        "identities": null,
        "imAddresses": null,
        "isResourceAccount": null,
        "jobTitle": '',
        "lastPasswordChangeDateTime": null,
        "legalAgeGroupClassification": null,
        "licenseAssignmentStates": null,
        "mail": '',
        "mailNickname": null,
        "mobilePhone": null,
        "officeLocation": null,
        "onPremisesDistinguishedName": null,
        "onPremisesDomainName": null,
        "onPremisesExtensionAttributes": null,
        "onPremisesImmutableId": null,
        "onPremisesLastSyncDateTime": null,
        "onPremisesProvisioningErrors": null,
        "onPremisesSamAccountName": null,
        "onPremisesSecurityIdentifier": null,
        "onPremisesSyncEnabled": null,
        "onPremisesUserPrincipalName": null,
        "otherMails": null,
        "passwordPolicies": null,
        "passwordProfile": null,
        "postalCode": null,
        "preferredLanguage": null,
        "provisionedPlans": null,
        "proxyAddresses": null,
        "showInAddressList": null,
        "signInSessionsValidFromDateTime": null,
        "state": null,
        "streetAddress": null,
        "surname": '',
        "usageLocation": null,
        "userPrincipalName": '',
        "userType": null,
        "mailboxSettings": null,
        "deviceEnrollmentLimit": null,
        "aboutMe": null,
        "birthday": null,
        "hireDate": null,
        "interests": null,
        "mySite": null,
        "pastProjects": null,
        "preferredName": null,
        "responsibilities": null,
        "schools": null,
        "skills": null,
        "appRoleAssignments": null,
        "createdObjects": null,
        "directReports": null,
        "licenseDetails": null,
        "manager": null,
        "memberOf": null,
        "oauth2PermissionGrants": null,
        "ownedDevices": null,
        "ownedObjects": null,
        "registeredDevices": null,
        "scopedRoleMemberOf": null,
        "transitiveMemberOf": null,
        "calendar": null,
        "calendarGroups": null,
        "calendars": null,
        "calendarView": null,
        "contactFolders": null,
        "contacts": null,
        "events": null,
        "inferenceClassification": null,
        "mailFolders": null,
        "messages": null,
        "outlook": null,
        "people": null,
        "photo": null,
        "photos": null,
        "drive": null,
        "drives": null,
        "followedSites": null,
        "extensions": null,
        "agreementAcceptances": null,
        "managedDevices": null,
        "managedAppRegistrations": null,
        "deviceManagementTroubleshootingEvents": null,
        "planner": null,
        "insights": null,
        "settings": null,
        "onenote": null,
        "activities": null,
        "onlineMeetings": null,
        "presence": null,
        "authentication": null,
        "joinedTeams": null,
        "teamwork": null,
        "todo": null,
        "deletedDateTime": null,
        "id": '',
        "oDataType": "#microsoft.graph.user",
        "additionalData": null
    };

    grouptypes = [{ "label": "", "value": 0 }, { "label": "Office 365", "value": 1 }, { "label": "Segurança", "value": 2 }, { "label": "E-mail ativo de segurança", "value": 3 }, { "label": "Grupo de distribuição", "value": 4 }];


    public template(record: SaaSGroupUserMember, disabled: boolean) {

        let item = {
            title: 'displayName',
            Fields: [
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'displayName',
                    type: 'text',
                    title: 'Nome do grupo',
                    required: {
                        value: true,
                        required: 'Nome do grupo é obrigatório'
                    },
                    disabled: disabled,
                    validation: function (val: string | any[]) {
                        return val.length >= 5 || 'Min Length is 5';
                    }
                }
            ]
        };

        return item;
    }

    ActionsBaseFunctions(createAction: any, CloseActions: any, DeleteActions: any) {

        let arrayActions = {
            functionsEdit: [{
                title: 'Salvar',
                icon: 'lci lci-check',
                func: () => {
                    createAction();
                },
                disabled: false
            }, {
                title: 'Fechar',
                icon: 'lci lci-x',
                func: () => {
                    if (CloseActions) {
                        CloseActions();
                    }
                },
                disabled: false
            }]
        };

        if (DeleteActions != undefined) {
            arrayActions.functionsEdit.push({
                title: 'Excluir selecionados',
                icon: 'lci lci-trash',
                func: () => {
                    DeleteActions();
                },
                disabled: false
            });
        }
        return arrayActions;
    }

}
