import moment from "moment";
import { PPGrid, PPTextField, PPModal } from "processor-plataform-ui";
import React, { useEffect, useState } from "react";
import Confirmation from "../../../../components/Dialog/Confirmation";
// import PPModal from "../../../../components/Dialog/PPModal";
import LcIconLink from "../../../../components/Generic/LcIconLink";
import FinOpsMapManagerService, { FinOpsAllocationGroup, FinOpsAllocationRule, FinOpsAllocationRuleCondition, FinOpsAllocationRuleCostCenter } from '../../../../services/finOpsManagerService';
import PPDropDown from "../../../../components/PPDropDown";

import './index.css';
import { PiLockLight, PiLockOpenLight, PiPlusLight, PiTrashLight } from "react-icons/pi";

interface Props {
    rule: FinOpsAllocationRule,
    ruleGroup: FinOpsAllocationRule[],
    costCenters: any[],
    readOnly: boolean,
    onSaveGroup?: Function,
    onCancelAction?: Function,
    originalStringRule?: string
}

export interface RuleValidade {
    name: string;
    validateMessage: string;
    valid: boolean;
    costCenterId: number;
    conditionId: number;
}

const AllocationRule: React.FC<Props> = (props) => {
    const finOpsMapManagerService = new FinOpsMapManagerService(props)

    const [rule, setRule] = useState<FinOpsAllocationRule>(props.rule);
    const [rulesGroups, setRulesGroups] = useState<FinOpsAllocationRule[]>([]);
    const [validations, setValidations] = useState<RuleValidade[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [savingRules, setSavingRules] = useState(false);
    const [editing, setEditing] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [changed, setChanged] = useState(false);
    const [disabledCostCenterAlert, setDisabledCostCenterAlert] = useState<boolean>(false);
    const [criterionCostCenter, setCriterionCostCenter] = useState<string>("");
    const [criterionTypeCostCenter, setCriterionTypeCostCenter] = useState<string>("");
    const [selectedCostCenter, setSelectedCostCenter] = useState<string>("");

    useEffect(() => {
        setRule({ ...props.rule });
        setEditing(true);
        setCanSave(false);
    }, [props.rule]);

    useEffect(() => { setRulesGroups(props.ruleGroup.map(r => { return { ...r } })); setValidations([]) }, [props.ruleGroup]);

    useEffect(() => {
        let original = props.originalStringRule;
        let current = JSON.stringify({ ...rule });
        if (original == current) {
            setChanged(false);
            validateRule();
        } else {
            setChanged(true);
            validateRule();
        }
    }, [rule]);

    const [versionRecord, setVersionRecord] = useState<FinOpsAllocationGroup>(
        {
            id: 0,
            clientGroupId: 0,
            month: 0,
            year: 0,
            endDate: null,
            startDate: null,
            version: '',
            type: '',
            name: '',
            updatedByUser: '',
            updatedByUserName: '',
            updatedByUserEmail: '',
            updatedAt: '',
            rules: []
        });

    const jsonFinOpsOperation = [
        {
            "label": "contém",
            "value": "contains"
        },
        {
            "label": "é igual a",
            "value": "equal"
        },
        {
            "label": "inicia com",
            "value": "startWith"
        },
        {
            "label": "diferente",
            "value": "notEqual"
        },
        {
            "label": "não contém",
            "value": "notContains"
        }
    ];

    const jsonFinOpsLogicOperator = [
        {
            "label": "e",
            "value": "and"
        },
        {
            "label": "ou",
            "value": "or"
        }
    ];

    const jsonFinOpsLCFilter = [
        {
            "label": "Provedor",
            "value": "ProviderName"
        },
        {
            "label": "Id do pagante",
            "value": "PayerAccountID"
        },
        {
            "label": "Id da Conta",
            "value": "AccountId"
        },
        {
            "label": "Nome da conta",
            "value": "AccountName"
        },
        {
            "label": "Data",
            "value": "Date"
        },
        {
            "label": "Id do recurso",
            "value": "ResourceID"
        },
        {
            "label": "Nome do recurso",
            "value": "ResourceName"
        },
        {
            "label": "Região",
            "value": "Region"
        },
        {
            "label": "Serviço",
            "value": "Service"
        },
        {
            "label": "Categoria",
            "value": "MeterCategory"
        },
        {
            "label": "Subcategoria",
            "value": "MeterSubCategory"
        },
        {
            "label": "Grupo do recurso",
            "value": "ResourceGroup"
        },
        {
            "label": "Tags",
            "value": "Tags"
        },
        {
            "label": "Consumo",
            "value": "ConsumedQuantity"
        },
        {
            "label": "Informação adicional do recurso",
            "value": "ResourceAdditionalInfo"
        },
        {
            "label": "Custo",
            "value": "MeteredCost"
        },
        {
            "label": "Tipo de publicação",
            "value": "PublisherType"
        },
        {
            "label": "Número contrato",
            "value": "ContractId"
        }
    ];

    const emptyRecordCostCenter: FinOpsAllocationRuleCostCenter = {
        idCostCenterRule: 0,
        idCostCenter: '',
        costCenter: '',
        percentage: 0,
        percentageLocked: false,
    };

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 0,
        field: '',
        operation: 'contains',
        filter: '',
        logicOperator: ''
    };

    function toCamelCase(str) {
        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }

    async function getFieldAutocompleteList(fieldName: string, fieldFilter: any) {
        var month = moment().month() + 1;
        var year = moment().year();
        var service = new FinOpsMapManagerService(props);
        var result = await service.GetFieldOptions(month, year, fieldName, fieldFilter);
        return result.slice(0, 50).map((item) => { return { value: item[toCamelCase(fieldName)] }; });
    };

    function validateRule() {
        var validationsChecks: RuleValidade[] = [];

        if (!(rule.description.length > 0)) {
            validationsChecks.push({
                name: 'description',
                validateMessage: 'Dê um nome para esta regra',
                valid: false,
                costCenterId: 0,
                conditionId: 0
            });
        }

        if (rule.description.length > 0) {
            rulesGroups.map(rul => {
                if (rule.description == rul.description && rule.id != rul.id)
                    validationsChecks.push({
                        name: 'description',
                        validateMessage: 'Já existe uma regra com este nome',
                        valid: false,
                        costCenterId: 0,
                        conditionId: 0
                    });
            })
        }

        if (!(rule.conditions.length > 0)) {
            validationsChecks.push({
                name: 'conditions',
                validateMessage: 'Todas as regras devem possuir ao menos uma condição.',
                valid: false,
                costCenterId: 0,
                conditionId: 0
            });
        }

        if ((rule.conditions.length > 0)) {
            rule.conditions.map(itemCondition => {
                if (!(itemCondition.field.length > 0)) {
                    validationsChecks.push({
                        name: 'field',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        costCenterId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }

                if (!(itemCondition.logicOperator.length > 0)) {
                    validationsChecks.push({
                        name: 'logicOperator',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        costCenterId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }
                if (!(itemCondition.operation.length > 0)) {

                    validationsChecks.push({
                        name: 'operation',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        costCenterId: 0,
                        conditionId: itemCondition.idCondition
                    });
                }

                if (!(itemCondition.filter.length > 0))
                    validationsChecks.push({
                        name: 'filter',
                        validateMessage: 'Você deve preencher este valor',
                        valid: false,
                        costCenterId: 0,
                        conditionId: itemCondition.idCondition
                    });
            });
        }

        if (!(rule.costCenters.length > 0)) {
            validationsChecks.push({
                name: 'costCenters',
                validateMessage: 'Todas as regras devem possuir ao menos um centro de custo cadastrado.',
                valid: false,
                costCenterId: 0,
                conditionId: 0
            });
        }

        if ((rule.costCenters.length > 0)) {
            rule.costCenters.map(itemCostCenter => {

                if (!(parseInt(itemCostCenter.idCostCenter) > 0)) {
                    validationsChecks.push({
                        name: 'idCostCenter',
                        validateMessage: 'Selecione um campo.',
                        valid: false,
                        costCenterId: itemCostCenter.idCostCenterRule,
                        conditionId: 0
                    });
                }
            });
        }

        if ((rule.costCenters.length > 0)) {
            rule.costCenters.map(itemCostCenter => {
                if (!(itemCostCenter.percentage > 0)) {
                    validationsChecks.push({
                        name: 'percentage',
                        validateMessage: 'Valor deve ser maior que zero.',
                        valid: false,
                        costCenterId: itemCostCenter.idCostCenterRule,
                        conditionId: 0
                    });
                }
            });
        }

        if ((rule.costCenters.length > 0)) {
            rule.costCenters.map(itemCostCenter => {
                // 
                if (itemCostCenter.percentage > 100) {
                    validationsChecks.push({
                        name: 'percentage',
                        validateMessage: 'Valor deve ser menor que 100.',
                        valid: false,
                        costCenterId: itemCostCenter.idCostCenterRule,
                        conditionId: 0
                    });
                }
            });
        }
        // 
        if ((rule.costCenters.length > 0)) {
            var totalPercentage = 0;
            rule.costCenters.map(itemCostCenter => {
                let porcentage = itemCostCenter.percentage.toString();
                totalPercentage += parseFloat(porcentage);
            });
            if ((totalPercentage != 100)) {
                validationsChecks.push({
                    name: 'percentage',
                    validateMessage: 'O total da porcentagem cadastrada nos centros de custo para uma regra deve ser igual a 100%.',
                    valid: false,
                    costCenterId: 0,
                    conditionId: 0
                });
            }
        }

        if ((rule.costCenters.length > 0)) {
            rule.costCenters.map(itemCostCenter => {
                if (props.costCenters.some(c1 => c1.value === itemCostCenter.idCostCenter && c1.isDisabled)) {
                    let disabledCC = props.costCenters.find(cc => cc.value === itemCostCenter.idCostCenter)?.label;
                    validationsChecks.push({
                        name: 'idCostCenter',
                        validateMessage: `"${disabledCC}" está desabilitado, escolha outro!`,
                        valid: false,
                        costCenterId: itemCostCenter.idCostCenterRule,
                        conditionId: 0
                    })
                    setDisabledCostCenterAlert(true);
                }
            });
        }
        setValidations(validationsChecks);
        if (validationsChecks.length == 0)
            setCanSave(true)
        else
            setCanSave(false)
        return validationsChecks.length == 0;
    }

    const handleChange = (event: any, item: any, type?: string) => {
        const { label, name, value } = event;
        type ? item[type] = value : item[name] = value;

        let newRule = rule

        if (label == 'logicOperator' || name == 'logicOperator') {
            newRule.conditions.map(itemCondition => {
                if (itemCondition.logicOperator != '')
                    itemCondition.logicOperator = value;
            });
        }
        if ((label == 'percentage' || name == 'percentage') && rule.costCenters.length > 1) {
            rule.costCenters.map(itemCostCenter => {
                if (itemCostCenter.idCostCenterRule != item.idCostCenterRule && !itemCostCenter.percentageLocked) {
                    let lockedCCs = rule.costCenters.filter(cc => cc.percentageLocked);
                    let lockedTotal = lockedCCs.reduce((sum, cur) => sum - cur.percentage, 100);
                    let CCsToChange = rule.costCenters.length - lockedCCs.length - 1;
                    itemCostCenter.percentage = (lockedTotal - value) / (CCsToChange > 0 ? CCsToChange : 1);
                }
            })
        }
        setRule({ ...newRule });
        validateRule()
    }

    const costCentersList = (ruleCostCenterId: any) => {
        let filtered: any[] = props.costCenters;
        let aux: any[] = [];
        if (ruleCostCenterId == '') {
            rule.costCenters.map(ruleCostCenter => {
                props.costCenters.map(costCenter => {
                    if (ruleCostCenter.idCostCenter.toString() == costCenter.value.toString()) {
                        aux = filtered.filter(f => f.value != costCenter.value);
                        filtered = aux;
                    }
                });
            });

            return filtered;
        } else {
            if (rule.costCenters.length > 1) {
                rule.costCenters.map(ruleCostCenter => {
                    if (ruleCostCenter.idCostCenter.toString() != ruleCostCenterId.toString()) {
                        props.costCenters.map(costCenter => {
                            if (ruleCostCenter.idCostCenter.toString() == costCenter.value.toString()) {
                                aux = filtered.filter(f => f.value != costCenter.value);
                                filtered = aux;
                            }
                        });
                    }
                });

                return filtered;
            } else {

                return filtered
            }
        }
    }

    function calculatePercentage(array: FinOpsAllocationRuleCostCenter[]) {

        if (array == null)
            return [];

        if (array.length == 0)
            return array;

        var total = 100;

        let lockedValues = array.filter(item => item.percentageLocked == true);
        let unlockedValues = array.filter(item => item.percentageLocked != true);

        if (lockedValues.length > 0) {
            total -= lockedValues.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.percentage.toString()), 0);
        }

        var portion = Math.floor(total / unlockedValues.length);

        portion = Math.floor(portion * 100 - portion % 1) / 100;

        for (var i = 0; i < unlockedValues.length; i++) {
            var percentage = portion;
            if (unlockedValues.length == (i + 1))
                percentage = total - (portion * (i));
            unlockedValues[i].percentage = percentage;
        }

        array = []
        array = array.concat(lockedValues, unlockedValues);
        rule.costCenters = array;
        setRule({ ...rule });
        validateRule()
        return [...array];

    }

    function addConditionRecord(parentRule: any) {
        var newRecord = emptyRecordCondition;
        let max = 0;
        if (parentRule.conditions.length > 0) {
            const ids = parentRule.conditions.map(object => {
                return object.idCondition;
            });
            max = Math.max.apply(null, ids);
        }
        if (rule)
            if (parentRule.conditions.length >= 2)
                newRecord.logicOperator = rule.conditions[1].logicOperator;
            else
                newRecord.logicOperator = "and";

        newRecord.idCondition = max + 1;
        parentRule.conditions.push(newRecord);

        setRule({ ...parentRule });
        validateRule();
    }

    function addCostCenterRecord(parentRule: any) {

        var newRecord = emptyRecordCostCenter;
        let max = 0;
        var cloneArr = [...parentRule.costCenters];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.idCostCenterRule;
            });
            max = Math.max.apply(null, ids);
        }

        newRecord.idCostCenterRule = max + 1;
        cloneArr.push(newRecord);

        if (rule.costCenters.length > 1) {
            setRule({ ...parentRule, costCenters: cloneArr })
        } else {
            calculatePercentage(cloneArr);
        }
    }

    function removeConditionRecord(item, parentRule) {
        parentRule.conditions = parentRule.conditions.filter(x => x.idCondition != item.idCondition);
        setRule({ ...parentRule });
        validateRule();
    }

    function removeCostCenterRecord(item, parentRule) {
        parentRule.costCenters = parentRule.costCenters.filter(x => x.idCostCenterRule != item.idCostCenterRule);
        parentRule.costCenters = calculatePercentage(parentRule.costCenters);
        setRule({ ...parentRule });
        validateRule();
    }

    function addRuleRecord() {
        if (rule.id == 0) {
            let max = 0;
            let newRule = rule;
            let newList = rulesGroups.filter(rul => rul.id != rule.id);
            let rules = rulesGroups.filter(rule => rule.default != true);

            if (rules.length > 0) {
                max = Math.max.apply(null, rules.map(object => {
                    return object.id;
                }));
            }
            newRule.id = max + 1;
            let newOrders = newList.map(r => {
                if (r.order >= newRule.order) {
                    r.order = r.order + 1;
                }
                return r;
            })

            newOrders.push(newRule);
            saveRuleGroup(newOrders);
        } else {
            let newList = rulesGroups.filter(rul => rul.id != rule.id);
            newList.push(rule);
            setRulesGroups(newList);
            saveRuleGroup(newList)
        }
    }

    function disableLocker(isLocked?: boolean) {
        let quantConstCenter = rule.costCenters.length;
        let lockedValues = rule.costCenters.filter(item => item.percentageLocked == true);
        if (isLocked) {
            return false
        }
        return (quantConstCenter >= 3 && lockedValues.length >= quantConstCenter - 2) || quantConstCenter < 3
    }

    function disableAddCondition(itemCondition: FinOpsAllocationRuleCondition) {
        let disableButton = false;
        if (!(itemCondition.field.length > 0))
            disableButton = true;
        if (!(itemCondition.logicOperator.length > 0))
            disableButton = true;
        if (!(itemCondition.operation.length > 0))
            disableButton = true;
        if (!(itemCondition.filter.length > 0))
            disableButton = true;
        return disableButton;
    }

    function disableRemoveCondition(itemCondition: FinOpsAllocationRuleCondition) {
        let disableButton = false;
        let maxId = Math.max.apply(null, rule.conditions.map(object => { return object.idCondition; }))
        if (maxId == itemCondition.idCondition) {
            return false
        } else {
            if (!(itemCondition.field.length > 0))
                disableButton = true;
            if (!(itemCondition.logicOperator.length > 0))
                disableButton = true;
            if (!(itemCondition.operation.length > 0))
                disableButton = true;
            if (!(itemCondition.filter.length > 0))
                disableButton = true;
            let validationError = validations.find(validation => validation.conditionId)

            return disableButton || (validationError && rule.conditions.length <= 2);
        }
    }

    function disableAddCostCenter(itemCostCenter: FinOpsAllocationRuleCostCenter) {
        let disableButton = false;
        if (itemCostCenter.percentageLocked)
            return disableButton = true

        if (!(parseInt(itemCostCenter.idCostCenter) > 0)) {
            disableButton = true
        }

        if (!(itemCostCenter.percentage > 0)) {
            disableButton = true
        }

        if (itemCostCenter.percentage > 100) {
            disableButton = true
        }

        return disableButton
    }

    function disableRemoveCostCenter(itemCostCenter: FinOpsAllocationRuleCostCenter) {
        if (rule.costCenters.length == 1)
            return true
        let maxId = Math.max.apply(null, rule.costCenters.map(object => { return object.idCostCenterRule; }))

        if (maxId == itemCostCenter.idCostCenterRule) {
            return false
        } else {
            if (!(parseInt(itemCostCenter.idCostCenter) > 0))
                return true

            if (!(itemCostCenter.percentage > 0))
                return true

            if (itemCostCenter.percentage > 100)
                return true

            if (rule.costCenters.length == 2)
                return validations.length > 0
        }

    }

    async function saveRuleGroup(ruleGroup?: FinOpsAllocationRule[]) {
        ruleGroup != undefined ? versionRecord.rules = ruleGroup : versionRecord.rules = rulesGroups;
        setSavingRules(true);
        try {
            await finOpsMapManagerService.SaveFinOpsAllocationCostCenter(versionRecord);
            props.onSaveGroup && props.onSaveGroup()
            setEditing(false)
        } catch (e) {

        }
        setSavingRules(false);

    }

    const setTooltipContent = () => {
        if (validations.length > 0) {
            let campos = validations.find(validation => ['Você deve preencher este valor', 'Selecione um campo.', 'Dê um nome para esta regra'].includes(validation.validateMessage))
            let percentage = validations.find(validation => validation.validateMessage.includes('100'))
            let percentageZero = validations.find(validation => validation.validateMessage.includes('zero'))
            return campos && 'Formulário poderá ser salvo após todos os campos obrigatórios serem preenchidos' || percentage && 'Total das porcentagens deve ser igual a 100%' || percentageZero?.validateMessage
        }
    }

    const showValidationMessages = () => {
        if (validations.length > 0) {
            let validationDic = {
                'costCenters': "Centros de Custo",
                'idCostCenter': "Centros de Custo",
                'percentage': "Porcentagem do Centro de Custo",
                'description': "Nome da regra",
                'filter': "Valor da Condição",
                'field': "Critério da Condição",
                'logicOperator': "Operador Lógico da Condição"
            }
            return validations.map((v, i) => {
                return <div key={i}><strong>{validationDic[v.name]}</strong>: {v.validateMessage}</div>
            })
        } else
            if (changed == false)
                return 'Faça alguma alteração'
    }

    const getErrorData = (conditionId, name) => {
        let data = validations.find(x => x.conditionId == conditionId && x.name == name);
        return data ? { show: true, help: data.validateMessage } : undefined;
    }

    return (
        <PPModal
            title={
                props.readOnly == true ?
                    'Detalhes da regra'
                    :
                    (props.rule.id == 0 ?
                        'Nova regra'
                        :
                        'Detalhes da regra'
                    )
            }
            visible={editing}
            size="large"
            onClose={() => {
                props.readOnly == true ?
                    props.onCancelAction && props.onCancelAction()
                    :
                    changed ?
                        setConfirmVisible(true)
                        :
                        props.onCancelAction && props.onCancelAction()
            }}
            functions={[
                {
                    label: "Salvar regra",
                    onClick: () => addRuleRecord(),
                    disabled: !canSave || savingRules || !changed,
                    hidden: props.readOnly,
                    tooltip: !changed ? "Faça alguma alteração" : setTooltipContent()
                },
                {
                    label: "Cancelar", onClick: () => {
                        props.readOnly == true ?
                            props.onCancelAction && props.onCancelAction()
                            :
                            changed ?
                                setConfirmVisible(true)
                                :
                                props.onCancelAction && props.onCancelAction()
                    },
                    hidden: props.readOnly,
                    context:"danger",
                }
            ]}
        >
            <div style={{height:'59vh'}}>
                <div className="mb-1">
                    <div className='titulos'>
                        Identificador da regra
                    </div>
                    <span style={{ fontWeight: '500' }}>Ordem:</span> <span style={{ fontWeight: '300' }}>{rule.order}</span> <span> Regra: </span><span style={{ fontWeight: '300' }}>{rule.id}</span>
                </div>
                <div className="mb-1" style={{width: '95%'}}>
                    <div className='titulos'>
                        Nome da regra
                    </div>
                    <PPTextField
                        type='text'
                        name="description"
                        required={!props.readOnly}
                        readOnly={props.readOnly}
                        value={rule.description}
                        onChange={(event) => { handleChange(event.target, rule); }}
                        help={validations.find(x => 'description' == x.name)?.validateMessage}
                        error={validations.find(x => 'description' == x.name) != undefined}
                        className=" ruleName"
                    />
                </div>

                <div className="mb-1">
                    <div className='titulos'>
                        Condições
                    </div>

                    {
                        rule && rule.conditions
                            .sort((a, b) => a.idCondition > b.idCondition ? 1 : -1)
                            .map((itemCondition, index) => {

                                return (
                                    <PPGrid key={index} container spacing={2}>
                                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            {rule && Math.min.apply(null, rule.conditions.map(object => {
                                                return object.idCondition;
                                            })) != itemCondition.idCondition &&
                                                <PPDropDown
                                                    required={!props.readOnly}
                                                    error={getErrorData(itemCondition.idCondition, 'logicOperator')}
                                                    openTrigger="click"
                                                    name="logicOperator"
                                                    value={jsonFinOpsLogicOperator.find(option => option.value == itemCondition.logicOperator)}
                                                    options={jsonFinOpsLogicOperator}
                                                    onChange={(event) => { handleChange(event, itemCondition, "logicOperator"); }}
                                                    disabled={props.readOnly || index != 1}

                                                />
                                            }
                                        </PPGrid>
                                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <PPDropDown
                                                key={'Critério'}
                                                name="field"
                                                value={jsonFinOpsLCFilter.find(option => option.value == itemCondition.field)}
                                                error={getErrorData(itemCondition.idCondition, 'field')}
                                                openTrigger="click"
                                                options={jsonFinOpsLCFilter}
                                                placeholder='(Critério)'
                                                onChange={(event) => {
                                                    setCriterionCostCenter(event.target);
                                                    handleChange(event, itemCondition, 'field');
                                                }}
                                                required={!props.readOnly}
                                                disabled={props.readOnly}
                                                maxMenuHeight={140}
                                            />

                                        </PPGrid>


                                        <PPGrid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <PPDropDown
                                                required={!props.readOnly}
                                                openTrigger="click"
                                                name="operation"
                                                error={getErrorData(itemCondition.idCondition, 'operation')}
                                                value={jsonFinOpsOperation.find(option => option.value == itemCondition.operation)}
                                                options={jsonFinOpsOperation}
                                                onChange={(event) => {
                                                    setCriterionTypeCostCenter(event.target)
                                                    handleChange(event, itemCondition, 'operation');
                                                }}
                                                disabled={props.readOnly}
                                            />
                                        </PPGrid>

                                        <PPGrid item xs={props.readOnly == false ? 5 : 6} sm={props.readOnly == false ? 5 : 6} md={props.readOnly == false ? 5 : 6} lg={props.readOnly == false ? 5 : 6} xl={props.readOnly == false ? 5 : 6}>
                                            <PPTextField
                                                required={!props.readOnly}
                                                readOnly={props.readOnly}
                                                type='text'
                                                error={validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name) != undefined}
                                                help={validations.find(x => x.conditionId == itemCondition.idCondition && 'filter' == x.name)?.validateMessage}
                                                name="filter"
                                                value={itemCondition.filter}
                                                onChange={(event) => { handleChange(event.target, itemCondition); }}
                                                placeholder='(Valor)'
                                                className="condValue"
                                                sugestions={(event) => getFieldAutocompleteList(itemCondition.field, event) as any}
                                            />
                                        </PPGrid>
                                        {props.readOnly == false &&
                                            <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <div className="row">
                                                    <LcIconLink size={'medium'} tooltipPosition={'right'} icon={<PiTrashLight/>}
                                                        disabled={rule.conditions.length == 1 || disableRemoveCondition(itemCondition)}
                                                        onClick={() => { removeConditionRecord(itemCondition, rule); }} />

                                                    {Math.max.apply(null, rule.conditions.map(object => {
                                                        return object.idCondition;
                                                    })) == itemCondition.idCondition &&
                                                        <LcIconLink
                                                            size={'medium'}
                                                            tooltipPosition={'right'}
                                                            icon={<PiPlusLight/>}
                                                            onClick={() => { addConditionRecord(rule); }}
                                                            tooltip="Adicionar Condição"
                                                            disabled={disableAddCondition(itemCondition)}
                                                        />
                                                    }
                                                </div>
                                            </PPGrid>
                                        }
                                    </PPGrid>
                                )
                            })
                    }
                </div>

                <div className="mb-1">
                    <div className='titulos' > Centros de custo</div>

                    {
                        rule && rule.costCenters
                            .sort((a, b) => a.idCostCenterRule - b.idCostCenterRule)
                            .map((itemCostCenter, index) => {
                                return (
                                    <PPGrid key={index} container spacing={2} alignItems='stretch'>

                                        <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            {!props.readOnly && <div style={{ display: 'flex', alignItems: 'center', paddingTop: '20px', justifyContent: 'flex-end' }}>
                                                <LcIconLink size={'medium'}
                                                    tooltip={itemCostCenter.percentageLocked ? 'Liberar valor' : 'Fixar valor'}
                                                    tooltipPosition={'left'}
                                                    icon={itemCostCenter.percentageLocked ? <PiLockLight/> : <PiLockOpenLight/>}
                                                    disabled={disableLocker(itemCostCenter.percentageLocked)}
                                                    onClick={() => handleChange({ name: 'percentageLocked', value: !itemCostCenter.percentageLocked }, itemCostCenter)} />
                                            </div>}
                                        </PPGrid>
                                        <PPGrid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <PPDropDown
                                                title="Centro de custo"
                                                error={getErrorData(itemCostCenter.idCostCenterRule, 'idCostCenter')}
                                                openTrigger="click"
                                                key={index}
                                                name="idCostCenter"
                                                value={costCentersList(itemCostCenter.idCostCenter).find(option => option.value == itemCostCenter.idCostCenter)}
                                                options={costCentersList(itemCostCenter.idCostCenter)}
                                                onChange={(event) => {
                                                    setSelectedCostCenter(event.target)
                                                    handleChange(event, itemCostCenter, 'idCostCenter');
                                                }}
                                                required={!props.readOnly}
                                                disabled={props.readOnly}
                                                maxMenuHeight={200}
                                                menuPlacement="top"
                                            />
                                        </PPGrid>

                                        <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <PPTextField
                                                label="Porcentagem"
                                                error={validations.find(x => x.costCenterId == itemCostCenter.idCostCenterRule && 'percentage' == x.name) != undefined}
                                                help={validations.find(x => x.costCenterId == itemCostCenter.idCostCenterRule && 'percentage' == x.name)?.validateMessage}
                                                key={index}
                                                step='1'
                                                max='100'
                                                min='0'
                                                type='number'
                                                name="percentage"
                                                className="percentField"
                                                value={itemCostCenter.percentage}
                                                readOnly={props.readOnly || rule.costCenters.length == 1 || itemCostCenter.percentageLocked}
                                                onChange={(event) => { handleChange(event.target, itemCostCenter, 'percentage'); }} />
                                        </PPGrid>
                                        {props.readOnly == false &&
                                            <PPGrid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px', justifyContent: 'flex-start', width: '10rem' }}>
                                                    <LcIconLink
                                                        key={index}
                                                        size={'medium'}
                                                        tooltipPosition={'right'}
                                                        icon={<PiTrashLight/>}
                                                        disabled={disableRemoveCostCenter(itemCostCenter)}
                                                        onClick={() => { removeCostCenterRecord(itemCostCenter, rule); setValidations([]); }} />

                                                    {
                                                        index == 0 && rule.costCenters.length > 1 &&
                                                        <button className='lc-button bg-info' style={{ marginLeft: '0.35rem' }} onClick={() => { calculatePercentage(rule.costCenters) }}> Redividir </button>
                                                    }

                                                    {Math.max.apply(null, rule.costCenters.map(object => {
                                                        return object.idCostCenterRule;
                                                    })) == itemCostCenter.idCostCenterRule &&
                                                        <div style={{ paddingLeft: '7px' }}>
                                                            <LcIconLink
                                                                size={'medium'}
                                                                icon={<PiPlusLight/>}
                                                                onClick={() => { addCostCenterRecord(rule) }}
                                                                tooltip="Adicionar centro de custo"
                                                                disabled={disableAddCostCenter(itemCostCenter)}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </PPGrid>
                                        }
                                    </PPGrid>
                                );
                            })
                    }
                </div>

                {!props.readOnly && changed &&
                    <p className="text-danger font-sm mb-7">{showValidationMessages()}</p>
                }

                <Confirmation
                    title="Confirme"
                    text='Tem certeza que deseja perder as alterações feitas?'
                    confirm={() => { setConfirmVisible(false); setEditing(false); props.onCancelAction && props.onCancelAction() }}
                    close={() => { setConfirmVisible(false) }}
                    textBtnOk='Sim'
                    textBtnCancel='Não'
                    display={confirmVisible}
                />
            </div>

        </PPModal >

    )
};

export default AllocationRule;