import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { EndpointSecurityService } from '../../../services/eSecurity/endpointSecurityService';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import LcLoading from '../../../components/Generic/LcLoading';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import LcIconLink from '../../../components/Generic/LcIconLink';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import moment from 'moment';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { title } from 'process';
import { PPModal } from 'processor-plataform-ui';


interface DevicesFilter {
    size: number;
    term: string;
    column: string;
    direction: string;
    name: string;
    groupName: string;
    status: string;    
}

const EndpointSecurityEvents: React.FC = (props) => {



    const [devices, setDevices] = useState<any[]>([]);
    const [filteredDevices, setFilteredDevices] = useState<any[]>([]);
    const [visibleDevices, setVisibleDevices] = useState<any[]>([]);
    const [IsLoadingDevices, setIsLoadingDevices] = useState<boolean>(true);
    const [statusChart, setStatusChart] = useState<any>(null);
    const [isLoadingStatusChart, setIsLoadingStatusChart] = useState(true);

    const [selectedDevice, setSelectedDevice] = useState<any>();
    const [events, setEvents] = useState<any[]>([]);
    const [modalLastEventsVisible, setModalLastEventsVisible] = useState<boolean>(false);
    
    
    const filterDefault: DevicesFilter = {
        size: 20,
        term: "",
        column: "",
        direction: "asc",
        name: "",
        groupName: "",        
        status: ""
    };
    const [filter, setFilter] = useState<DevicesFilter>(filterDefault);
    
    
    
    //const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    useEffect(() => {
        const endpointSecurityService = new EndpointSecurityService(props);

        setIsLoadingStatusChart(true);
        setIsLoadingDevices(true);
        setFilteredDevices([]);

        if (user.ClientGroupSelected) {

            endpointSecurityService.GetStatusChart()
                .then(response => {
                    if (response.data) {
                        let statusChart = {
                            //title: 'Status dos dispositivos',
                            labels: response.data.labels,
                            values: response.data.values
                        };
                        setStatusChart(statusChart);
                    }
                })
                .catch(error => {
                    console.warn(error);
                })
                .then(() => {
                    setIsLoadingStatusChart(false);
                });

            endpointSecurityService.GetDevices()
                .then((response: any[]) => {
                    let sortedData = response.sort((a: any, b: any) => { return a.status > b.status ? 1 : -1 });
                    setDevices(sortedData);
                    setFilteredDevices(sortedData);
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    setIsLoadingDevices(false);
                });

        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        const endpointSecurityService = new EndpointSecurityService(props);

        selectedDevice &&
            endpointSecurityService.GetEvents(moment().format('yyyyMM01'))
                .then(response => {
                    setEvents(response.data.filter((e: any) => {
                        return selectedDevice.name.indexOf(e.deviceName) >= 0;
                    }).slice(0, 10));
                })
                .catch(error => {
                    console.warn(error);
                });

    }, [selectedDevice])

    // useEffect(() => {
    //     var filtered = [...devices].sort((a: any, b: any) => {
    //         if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
    //         else return (a[filter.column] > b[filter.column]) ? -1 : 1;
    //     }).filter(d => d.name.toLowerCase().indexOf(filter.term) > -1);

    //     setFilteredDevices(filtered);
    //     setVisibleDevices(filtered.slice(0, filter.size));
    // }, [filter, filteredDevices.length > 0]);

    const columns = [
        { field: 'name', headerName: 'Nome do dispositivo', width: "58%" },
        { field: 'groupName', headerName: 'Nome do grupo', width: "25%" },
        { field: 'status', headerName: 'Status', width: "15%" },
        {
            field: 'id', headerName: ' ', width: "40px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon="lci lci-view-list" size='small' tooltipPosition='right' tooltip="Últimos eventos" onClick={() => { setSelectedDevice(row); setModalLastEventsVisible(true); }} />
            }
        }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    // const loadMore = (size?: number) => {
    //     if (size) setVisibleDevices(filteredDevices.slice(0, size))
    //     else setVisibleDevices([...visibleDevices, ...filteredDevices.slice(visibleDevices.length, visibleDevices.length + 10)])
    // };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [            
            { name: 'name', label: 'Nome do dispositivo', type: "text" },
            { name: 'groupName', label: 'Nome do grupo', type: "text" },
            {
                label: 'Status', name: 'status', type: 'select',
                options: [
                    { label: 'TODOS', value: '' },
                    { label: 'SECURE', value: 'SECURE' },
                    { label: 'AT RISK', value: 'AT RISK' },
                    { label: 'NOT COMPUTED', value: 'NOT COMPUTED' }
                ],
            },
        ],
        onChange: (_filter: any, size: number) => setFilter({
            ...filter,
            size: size,
            term: _filter[0].value.toLowerCase(),
        }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const cards = [
        {
            id: 1,
            type: 'Custom',
            //bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 1, row: 1, col: 1 },
            title: () => (
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title ellipsis'}>
                        Status dos dispositivos
                    </div>
                </div>
            ),
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingStatusChart}>
                        {statusChart ?
                            <DoughnutChart data={statusChart} cssClass='doughnut-chart no-shadown' />
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '1rem', alignItems: 'center' }}>
                                <i className="lci lci-exclamation lci-2x text-warning" />
                                <span className="font-sm">Não foram sincronizados dados para o período</span>
                            </div>
                        }
                    </LcLoading>
                )
            }
        },
        {
            id: 2,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 2, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingStatusChart}>
                        <LcInfiniteTable
                            //height="calc(100% - 0px)"
                            loading={IsLoadingDevices}
                            columns={columns}
                            rows={visibleDevices}
                            size={filteredDevices.length}
                            loadMore={() => { }}
                            notLoadMore={true}
                            onSortChange={onSortChange}
                            filter={filterAdvanced}
                            density={pref.data?.density || "high"}
                            disableFilterModal
                        />
                    </LcLoading >
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (devices.length > 0) {
            let filtered = devices.filter((p: any) => {
                const matchesName = filter.name
                    ? p.name.toLowerCase().includes(filter.name)
                    : true;
                const matchesGroupName = filter.groupName
                    ? p.groupName.toLowerCase().includes(filter.groupName)
                    : true;
                const matchesStatus = filter.status
                    ? p.status.toLowerCase().includes(filter.status)
                    : true;
                
                return matchesName && matchesGroupName && matchesStatus;
            });

            var sorted = filtered.sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

            isFilterActive = filtered.length !== devices.length;
            
            setFilteredDevices(sorted);
            setVisibleDevices(sorted);
        }

        dispatch(setFilterActive(isFilterActive));
    }, [filter, devices, visibleDevices, filteredDevices.length > 0, dispatch]);

    // UseEffect para chamar applyFilter
    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (updatedFilters) => {
        const nameFilter = updatedFilters.find(f => f.name === 'name')?.value || '';
        const groupNameFilter = updatedFilters.find(f => f.name === 'groupName')?.value || '';
        const statusFilter = updatedFilters.find(f => f.name === 'status')?.value || '';

        setFilter({
            ...filter,
            name: nameFilter.toLowerCase(),
            groupName: groupNameFilter.toLowerCase(),
            status: statusFilter.toLowerCase()
        });
    };

    return (
        <Layout
            pageTitle="Status dos dispositivos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredDevices.length
            }}
        >


            <LCDashboard
                cards={cards}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />

            <PPModal title="Últimos eventos" onClose={() => { setModalLastEventsVisible(false) }} visible={modalLastEventsVisible} >
                <p>{
                    selectedDevice &&
                    <span>Dispositivo: {selectedDevice.name}</span>
                }</p>
                <div className="list">
                    <table className="table" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <td>Nome</td>
                                <td>Mensagem</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                events.length > 0 &&
                                events.map((e: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{e.name}</td>
                                            <td>{e.message}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </PPModal>

        </Layout>
    );
};

export default EndpointSecurityEvents;