import api, { APIClient } from "../api";

export type Filter = {
    scheduledEventActive?: boolean,
    cacheManagerParametersActive?: boolean,
    column: string,
    direction: string,
    size: number
}

type ScheduleType = {
    id: number, 
    type: string, 
    pluralType: string 
}

export type ScheduleConfiguration = {
    id?: number
    host: string  
    rank: number 
    route: string 
    scheduledRefresh : number 
    scheduledClear : number 
    enabled : number 
    path : string 
    cacheManagerParametersID : number 
    disabledAfterDate : Date
    tokenPath: string  
    incrementPeriod : number 
    active : boolean
    scheduleType : number 
    weekDay : number 
    frequency : number 
    scheduledRunTime : Date 
    endPeriodTime : Date 
    beginPeriodTime : Date 
    event : number 
    scheduledEventID : number 
    extraJSONParameters : number 
    vendorProviderId : number 
}

export class ScheduleManagementService extends APIClient {

    constructor(props: any) {
        super(props);
        this.endpoint = this.endpoint;
    }

    private ScheduleType: ScheduleType[] = [
        { id: 1, type: 'dia', pluralType: 'dias' },
        { id: 2, type: 'hora', pluralType: 'horas' },
        { id: 3, type: 'mês', pluralType: 'meses' },
    ]

    public defaultFilter: Filter = {
        cacheManagerParametersActive: true,
        column: '',
        direction: 'asc',
        scheduledEventActive: true,
        size: 15
    }

    public Status = [
        { value: undefined, name: 'Todos' },
        { value: true, name: 'Ativos' },
        { value: false, name: 'Inativos' },
    ];

    GetTipoAgendamento(frequency, type) {
        let _type = this.ScheduleType.find(x => x.id == type)
        if (_type) {
            if (frequency > 1) {
                return _type.pluralType
            } else {
                return _type.type
            }
        } else return 'desconhecido'
    }

    GetHour(date) {
        let _date = new Date(date)
        return `${_date.getHours().toString().padStart(2, '0')}:${_date.getMinutes().toString().padStart(2, '0')}`
    }

    async GetScheduleConfiguration(activeSchedule?: boolean, activeCacheManager?: boolean) {
        let url = `${this.endpoint}/api/BackupScheduleConfiguration/GetScheduleConfiguration`;
        
        let params = {}

        if(activeSchedule != undefined) {
            params = {...params, activeScheduleEvent: activeSchedule}
        }

        if(activeCacheManager != undefined){
            params = {...params, activeCacheManagerParameters: activeCacheManager}
        }

        return api({
            method: 'get',
            url,
            params : params
        })
    }
}