import React from 'react';
import FinOpsResume from '../../../../../pages/finOps/resume';

interface FinOpsCardProps {
    GTMs: string[];
    QtdGroups: number;
}

const FinOpsCard: React.FC<FinOpsCardProps> = ({ GTMs, QtdGroups }) => {
    return (
        <>
            <FinOpsResume isHome={true} qtdGroups={QtdGroups} />
        </>
    );
}

export default FinOpsCard;