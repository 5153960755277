import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import HomeService, { DashboardGrafana } from '../../../services/homeService';

import './index.css';

interface Param {
    type: string;
}
const Dashboard: React.FC = (props) => {

    const service = new HomeService(props);
    const { type } = useParams<Param>();
    const [dashType, setDashType] = useState<string>(type);
    const [loading, setLoading] = useState<boolean>(true);
    const [dashboard, setDashboard] = useState<DashboardGrafana[]>();
    const [defaultDashboard, setDefaultDashboard] = useState<DashboardGrafana>();
    const [token, setToken] = useState<string>();

    useEffect(() => {
        if (dashType == undefined || dashType == "") {
            service.GetGrafanaDashboard()
                .then(response => {
                    setDashboard(response.items);
                    setToken(`${process.env.REACT_APP_PROXY}/landing?token=${response.token}`);
                })
                .catch(error => {
                    console.debug('Error', error);
                })
        } else {
            let url = sessionStorage.getItem("LiveWatch_Dashboard_Url");
            service.GetGrafanaDashboardWithUrl(url ? url : `${process.env.REACT_APP_PROXY}/landing`)
                .then(response => {
                    setDashboard(response.data.items);
                    setToken(`${process.env.REACT_APP_PROXY}/landing?token=${response.data.token}`);
                })
                .catch(error => {
                    console.debug('Error', error);
                })
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (
        <Layout pageTitle="Dashboard de monitoramento" >
            <LcLoading loading={loading} label="Carregando..." />
            <div className={`livewatchdashboard`}>
                {
                    token &&
                    <iframe key={`${token}`} src={token} frameBorder="0" sandbox="allow-scripts allow-forms allow-same-origin"></iframe>
                }
                {
                    !loading && !token &&
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
                        <small style={{ textAlign: 'center' }}>
                            <i className="lci lci-exclamation text-warning" /> Não há informações no período. <br></br> Entre em contato para saber mais sobre esta solução.
                        </small>
                    </div> 
                }
            </div>
        </Layout>
    );
}

export default Dashboard;