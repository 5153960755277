/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { Pie, Bar } from 'react-chartjs-2';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { CacheManagerService } from '../../../services/cacheManagerService';
import { Grid, LinearProgress } from '@material-ui/core';
import "./index.css";
import LcSegment from '../../../components/Generic/LcSegment';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcLoading from '../../../components/Generic/LcLoading';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import moment from 'moment';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import EChartsReact from 'echarts-for-react';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import BarChart from '../../../components/Data/Dashboard/BarChart';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiArchiveLight, PiCheckCircleLight, PiDatabaseLight, PiUsersLight, PiWarningCircleLight } from 'react-icons/pi';
interface BackupGoldResumeProps {
    isHome?: boolean,
    qtdGroups?: number
}

const BackupGoldResume: React.FC<BackupGoldResumeProps> = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const [summaryData, setSummaryData] = useState<any>();
    const [oldSummaryData, setOldSummaryData] = useState<any>();
    const [groupUsageChart, setGroupUsageChart] = useState<any>();
    const [weeklyUsageChart, setweeklyUsageChart] = useState<any>();
    const weeklyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    // callback: function(label: any, index: any, labels: any) {
                    //     return parseFloat(label).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 1});
                    // }                  
                }
            }]
        }
    };

    const [monthlyUsageChart, setMonthlyUsageChart] = useState<any>();
    const monthlyUsageChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var label = data.datasets[tooltipItem.datasetIndex].label;
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + parseFloat(value).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    // callback: function(label: any, index: any, labels: any) {
                    //     return parseFloat(label).toLocaleString('pt-BR', {style: "decimal", maximumFractionDigits: 1});
                    // }                  
                }
            }]
        }
    };

    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [jobCards, setJobCards] = useState<any>();
    const [isLoadingJobCards, setIsLoadingJobCards] = useState(true);
    const [errorJobCards, setErrorJobCards] = useState(false);
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [errorCards, setErrorCards] = useState(false);
    const [isLoadingGroupChart, setIsLoadingGroupChart] = useState(true);
    const [errorGroupChart, setErrorGroupChart] = useState<boolean>(false);
    const [isLoadingWeeklyChart, setIsLoadingWeeklyChart] = useState(true);
    const [errorWeeklyChart, setErrorWeeklyChart] = useState(false);
    const [isLoadingMonthlyChart, setIsLoadingMonthlyChart] = useState(true);
    const [errorMonthlyChart, setErrorMonthlyChart] = useState(false);
    const [displayDate, setDisplayDate] = useState(moment(`${new Date().getFullYear()}-${(new Date().getMonth() + 1)}-${1}`).format('MMMM'));
    // const [UpdatedAt, setUpdatedAt] = useState<string>();
    const [lastUpdate, setLastUpdate] = useState<string>("");

    const cacheManagerService = new CacheManagerService(props);
    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("livecloudbackupgold", "CloudOSBilling")
            .then(response => {
                setLastUpdate(new Date(response.data).toLocaleString());
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toLocaleString());
            })
    }
    const loadData = async () => {

        setIsLoadingCards(true);
        setIsLoadingJobCards(true);
        setIsLoadingGroupChart(true);
        setIsLoadingWeeklyChart(true);
        setIsLoadingMonthlyChart(true);
        setErrorGroupChart(false);
        setErrorJobCards(false);
        setErrorCards(false);
        setErrorWeeklyChart(false);
        setErrorMonthlyChart(false);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
            //   displayDate,
            setDisplayDate(moment(`${new Date().getFullYear()}-${(new Date().getMonth() + 1)}-${1}`).format('MMMM'));
        }
        if (user.ClientGroupSelected && month && year) {
            const selectedDate = Moment(`${year}-${month}-01`).format('YYYY-MM-DD');
            const oldSelectedDate = Moment(selectedDate).add(-1, 'M').format('YYYY-MM-DD');
            const backupGoldService = new BackupGoldService(props);

            let summaryData = {
                totalSize: "",
                totalClients: 0,
                totalAgents: 0,
                totalInstances: 0,
                totalSets: 0,
                totalContracted: 0,
            };
            const [
                responseGetCards,
                responseGetJobCards,
                responseGetCardsOld,
                responseGetWeeklyChart,
                responseGetMonthlyChart
            ] = await Promise.all([
                //responseGetCards
                backupGoldService.GetCards(selectedDate).catch(error => {
                    setErrorCards(true);
                    console.log(error);
                    return null;
                }),
                //responseGetJobCards
                backupGoldService.GetJobCards(selectedDate).catch(error => {
                    setErrorJobCards(true);
                    console.log(error);
                    return null;
                }),
                //responseGetCardsOld
                backupGoldService.GetCards(oldSelectedDate).catch(error => {
                    console.log(error);
                    return null;
                }),
                //responseGetWeeklyChart
                backupGoldService.GetWeeklyChart(selectedDate).catch(error => {
                    setErrorWeeklyChart(true);
                    console.log(error);
                    return null;
                }),
                //responseGetMonthlyChart
                backupGoldService.GetMonthlyChart(selectedDate).catch(error => {
                    setErrorMonthlyChart(true);
                    console.log(error);
                    return null;
                })
            ]);
            try {
                //var responseGetCards = await backupGoldService.GetCards(selectedDate);
                if (responseGetCards) {
                    summaryData = {
                        totalSize: responseGetCards.data.totalSizeData,
                        totalClients: responseGetCards.data.totalClients,
                        totalAgents: responseGetCards.data.totalAgents,
                        totalInstances: responseGetCards.data.totalInstances,
                        totalSets: responseGetCards.data.totalSets,
                        totalContracted: responseGetCards.data.totalContracted,
                    };

                    setSummaryData(summaryData);
                    let groups = responseGetCards.data.chartData.map((Item: { agent: string, sizeTb: number }, index: number) => {
                        return {
                            id: index,
                            Agent: Item.agent,
                            SizeTb: Item.sizeTb,
                        }
                    });
                    var arrayDataGroupUsageChart = groups.map((Item) => {
                        return { label: Item.Agent, value: Item.SizeTb }
                    });
                }
                if (responseGetJobCards) {
                    try {
                        //    var responseGetJobCards = await backupGoldService.GetJobCards(selectedDate);
                        let jobCards = {
                            totalJobs: responseGetJobCards.data.totalJobs,
                            succededJobsPercent: responseGetJobCards.data.succededJobsPercent,
                            failedJobsPercent: responseGetJobCards.data.failedJobsPercent,
                        };
                        setJobCards(jobCards);
                        if (responseGetJobCards.data.totalJobs <= 0) {
                            setErrorJobCards(true);
                        }
                        setIsLoadingJobCards(false);
                    } catch (error) {
                        setErrorJobCards(true);
                        setIsLoadingJobCards(false);
                        console.log(error);
                    }
                }
                try {
                    if (responseGetCardsOld) {
                        //  var responseGetCardsOld = await backupGoldService.GetCards(oldSelectedDate)
                        // .then(response => {
                        if (responseGetCardsOld.data) {
                            let oldSummaryData = {
                                totalSize: responseGetCardsOld.data.totalSizeData,
                                totalClients: responseGetCardsOld.data.totalClients,
                                totalAgents: responseGetCardsOld.data.totalAgents,
                                totalInstances: responseGetCardsOld.data.totalInstances,
                                totalSets: responseGetCardsOld.data.totalSets,
                                totalContracted: responseGetCardsOld.data.totalContracted,
                            };
                            setOldSummaryData(oldSummaryData);
                            //    setSummaryData(oldSummaryData);
                            setIsLoadingCards(false);
                        };
                    }
                } catch (erre) {
                    console.log(erre);
                }

                setGroupUsageChart(arrayDataGroupUsageChart);
                if (arrayDataGroupUsageChart.length <= 0) {
                    setErrorGroupChart(true);
                }
                setIsLoadingGroupChart(false);

                if (summaryData.totalAgents <= 0 && summaryData.totalClients <= 0 && summaryData.totalInstances <= 0) {
                    setErrorCards(true);
                }

                try {
                    if (responseGetWeeklyChart) {
                        //  var responseGetWeeklyChart = await backupGoldService.GetWeeklyChart(selectedDate);
                        const maxObject = responseGetWeeklyChart.data.chartValues.reduce((max, obj) => {
                            if (obj > max) {
                                return obj;
                            } else {
                                return max;
                            }
                        }, responseGetWeeklyChart.data.chartValues[0]);

                        var indexMax = responseGetWeeklyChart.data.chartValues.indexOf(maxObject);
                        if (summaryData && summaryData.totalSize) {
                            responseGetWeeklyChart.data.chartValues[indexMax] = Number(summaryData.totalSize.trim().replace(',', '.'));
                        }

                        const weeklyChartJoined = responseGetWeeklyChart.data.chartLabels.map((name, index) => ({
                            name: name,
                            value: responseGetWeeklyChart.data.chartValues[index]
                        }));
                        setweeklyUsageChart(weeklyChartJoined);
                        if (responseGetWeeklyChart.data.chartValues.length <= 0) {
                            setErrorWeeklyChart(true);
                        }

                        setIsLoadingWeeklyChart(false);
                    }
                } catch (ewe) {
                    setErrorWeeklyChart(true);
                    setIsLoadingWeeklyChart(false);
                    console.log(ewe);
                }
                try {
                    //     var responseGetMonthlyChart = await backupGoldService.GetMonthlyChart(selectedDate);
                    if (responseGetMonthlyChart) {
                        let monthlyChart = {
                            labels: responseGetMonthlyChart.data.chartLabels,
                            datasets: [{
                                data: responseGetMonthlyChart.data.chartValues,
                                label: "Consumo mensal",
                                borderColor: "#0074D9",
                                backgroundColor: '#a5bf3e',
                                fill: false
                            },
                            {
                                type: 'line',
                                label: "Consumo mensal",
                                data: responseGetMonthlyChart.data.chartValues,
                                borderColor: "#0074D9",
                                backgroundColor: '#a5bf3e',
                                fill: false
                            }]
                        };

                        const monthlyChartJoined = responseGetMonthlyChart.data.chartLabels.map((name, index) => ({
                            name: name,
                            value: responseGetMonthlyChart.data.chartValues[index]
                        }));
                        setMonthlyUsageChart(monthlyChartJoined);
                        if (responseGetMonthlyChart.data.chartValues.length <= 0) {
                            setErrorMonthlyChart(true);
                        }
                        setIsLoadingMonthlyChart(false);
                    }
                } catch (eeew) {
                    setErrorMonthlyChart(true);
                    setIsLoadingMonthlyChart(false);
                    console.log(eeew);
                }

            } catch (error) {
                setErrorCards(true);
                setErrorGroupChart(true);
                setErrorWeeklyChart(true);
                setErrorMonthlyChart(true);
                setErrorJobCards(true);
                setIsLoadingJobCards(false);
                setIsLoadingGroupChart(false);
                setIsLoadingMonthlyChart(false);
                setIsLoadingWeeklyChart(false);
                setIsLoadingCards(false);

                console.log(error);
            }
        }
        refreshLastUpdate();
    }

    useEffect(() => {
        loadData();
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
        //   displayDate,
        setDisplayDate(moment(`${date.split('-')[0]}-${(date.split('-')[1])}-${1}`).format('MMMM'));
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    function getDelta(oldVal: any, newVal: any) {
        if ((typeof oldVal) == "string")
            oldVal = parseFloat(oldVal.replace(",", "."));
        if ((typeof newVal) == "string")
            newVal = parseFloat(newVal.replace(",", "."));
        //FFF - Função de calcula o delta entre o valor anterior e o valor atual e monta o html
        var delta = (newVal - oldVal) / oldVal;
        if (oldVal == 0 && newVal > 0) {
            //Infinity - quando não há valor anterior
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>do 0</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>Não há valor</b> no mês anterior</div> </LcDropDown>
        } else if (oldVal > 0 && newVal == 0) {
            //-100%
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>-100%</div>}> <div>Espaço consumido<br /><br /><b>0</b> Terabytes<br /><b>-100%</b> em relação ao mês anterior</div> </LcDropDown>
        } else if (oldVal == newVal) {
            //Inalterado
            return `Inalterado`
        } else if (delta > 0) {
            //Aumentou
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>+{(delta * 100).toFixed(2)}%</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>+{(delta * 100).toFixed(2)}%</b> em relação ao mês anterior</div> </LcDropDown>
        } else if (delta < 0) {
            //Diminuiu
            return <LcDropDown position='right' element={<div style={{ cursor: 'pointer', fontWeight: 'bold' }}>{(delta * 100).toFixed(2)}%</div>}> <div>Espaço consumido<br /><br /><b>{newVal}</b> Terabytes<br /><b>{(delta * 100).toFixed(2)}%</b> em relação ao mês anterior</div> </LcDropDown>
        } else {
            return `---`
        }
    }

    const positions5Groups: Card[] = [
        {
            id: 1,
            position: {
                row: 3, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            }
        },
        {
            id: 2,

            position: {
                row: 1, col: 5, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 3,

            position: {
                row: 1, col: 9, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 4,
            position: {
                row: 2, col: 1, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 5,
            position: {
                row: 1, col: 1, ColSpan: 2,
                RowSpan: 2, hidden: false
            },

        },
        {
            id: 6,

            position: {
                row: 2, col: 9, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 7,

            position: {
                row: 4, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        }, {
            id: 8,


            position: {
                row: 5, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        },
        {
            id: 9,


            position: {
                row: 6, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        }];

    const positions3Groups: Card[] = [
        {
            id: 1,
            position: {
                row: 3, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            }
        },
        {
            id: 2,

            position: {
                row: 1, col: 5, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 3,

            position: {
                row: 1, col: 9, ColSpan: 4,
                RowSpan: 1, hidden: true
            },

        }, {
            id: 4,


            position: {
                row: 2, col: 1, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 5,
            position: {
                row: 1, col: 1, ColSpan: 2,
                RowSpan: 2, hidden: false
            },

        },
        {
            id: 6,

            position: {
                row: 2, col: 9, ColSpan: 4,
                RowSpan: 2, hidden: true
            },

        }, {
            id: 7,
            position: {
                row: 4, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        }, {
            id: 8,
            position: {
                row: 5, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        },
        {
            id: 9,
            position: {
                row: 6, col: 1, ColSpan: 2,
                RowSpan: 1, hidden: false
            },

        }];

    const positions2Groups: Card[] = [{
        id: 1,
        position: {
            row: 3, col: 1, ColSpan: 2,
            RowSpan: 1, hidden: false
        }
    },
    {
        id: 2,

        position: {
            row: 1, col: 5, ColSpan: 4,
            RowSpan: 1, hidden: true
        },

    }, {
        id: 3,

        position: {
            row: 1, col: 9, ColSpan: 4,
            RowSpan: 1, hidden: true
        },

    }, {
        id: 4,


        position: {
            row: 3, col: 2, ColSpan: 1,
            RowSpan: 3, hidden: false
        },

    }, {
        id: 5,
        position: {
            row: 1, col: 1, ColSpan: 2,
            RowSpan: 2, hidden: false
        },

    },
    {
        id: 6,

        position: {
            row: 2, col: 9, ColSpan: 4,
            RowSpan: 2, hidden: true
        },

    }, {
        id: 7,

        position: {
            row: 4, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    }, {
        id: 8,


        position: {
            row: 5, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    },
    {
        id: 9,


        position: {
            row: 6, col: 1, ColSpan: 1,
            RowSpan: 1, hidden: false
        },

    }];

    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id == Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }

    type IconColorResult = {
        color: string;
        icon: React.ReactElement;
    };

    function getIconAndColor(value: number, unity?: string, isGoodAtZeroPercent?: boolean): IconColorResult {
        if (value === 0 && !unity) {
            return {
                color: "var(--status-green)", // Verde
                icon: <PiCheckCircleLight size={props.isHome ? 24 : 32} />
            };
        } else if (value === 0 && unity === "%" && isGoodAtZeroPercent) {
            return {
                color: "var(--status-green)", // Verde
                icon: <PiCheckCircleLight size={props.isHome ? 24 : 32} />
            };
        } else if (value === 0 && unity === "%") {
            return {
                color: "var(--status-red)", // Vermelho
                icon: <PiWarningCircleLight size={props.isHome ? 24 : 32} />
            };
        } else if (value > 0 && unity === "%" && isGoodAtZeroPercent) {
            return {
                color: "var(--status-red)", // Vermelho
                icon: <PiWarningCircleLight size={props.isHome ? 24 : 32} />
            };
        } else if (value >= 90 && value <= 100) {
            return {
                color: "var(--status-green)", // Verde
                icon: <PiCheckCircleLight size={props.isHome ? 24 : 32} />
            };
        } else {
            return {
                color: "var(--status-red)", // Vermelho
                icon: <PiWarningCircleLight size={props.isHome ? 24 : 32} />
            };
        }
    }


    var cards: Card[] = [
        {
            id: 1,
            type: 'Custom',
            position: {
                row: 1, col: 1, ColSpan: 4, RowSpan: 1
            },            
            infoContent: !props.isHome ? () => {
                return <span>Maior consumo aferido no período</span>
            } : undefined,
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Espaço consumido</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}><div style={{ minHeight: '100%', minWidth: '100%' }}>
                    <IconValue maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        formatValue={true}
                        info={getDelta(oldSummaryData?.totalSize, summaryData?.totalSize)}
                        tooltipInfo="Em relação ao período anterior"
                        title="Espaço consumido"
                        tooltipInfoPosition="left"
                        icon={!props.isHome ? <PiDatabaseLight size={32} /> : undefined}
                        value={summaryData?.totalSize?.replace(",", ".")}
                        unity="TB"
                        cssClass={props.isHome ? "consumo" : undefined}
                        color={!props.isHome ? "var(--color-primary-pink)" : 'var(--color-primary-purple-dark)'} ></IconValue>
                </div></LcLoading>
            }
        },
        {
            id: 2,
            type: 'Custom',
            position: {
                row: 1, col: 5, ColSpan: 4, RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Espaço contratado</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}><IconValue maximumFractionDigits={1} title="Espaço contratado" icon={<PiArchiveLight size={32} />} value={summaryData?.totalContracted} unity="TB" color="var(--color-secondary-purple-bright)"></IconValue></LcLoading>
            }
        },
        {
            id: 3,
            type: 'Custom',
            position: {
                row: 1, col: 9, ColSpan: 4, RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Agentes</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}>
                    <IconValue title="Agentes" icon={<PiUsersLight size={32} />} value={summaryData?.totalAgents?.toString()} color="var(--color-primary-purple)" tooltipInfo="Quantidade de agentes de backup instalados" info={<i className="lci lci-information-circle" style={{ color: '#0580ce', fontSize: '3rem' }} />} tooltipInfoPosition="right">
                    </IconValue>
                </LcLoading>
            }
        },
        {
            id: 4,
            type: 'Custom',
            position: {
                row: 2, col: 1, ColSpan: 4, RowSpan: 2,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Espaço alocado por tipo em TB</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}><div style={{ width: '100%', height: '100%' }} >
                    <DoughNutEChart legendOrientation={props.isHome ? 'horizontal' : 'vertical'} ArrayData={groupUsageChart}></DoughNutEChart>
                </div></LcLoading>
            }
        },
        {
            id: 5,
            type: 'Custom',
            hideHeader: props.isHome,
            position: {
                row: 2, col: 5, ColSpan: 4, RowSpan: 2,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Consumo semanal em TB</div>
                }</>
            },
            customContentRender: () => {
                return <div className="w-100p h-100p">
                    <LcLoading loading={isLoadingWeeklyChart}>
                        {
                            weeklyUsageChart && !errorWeeklyChart ?
                                <BarChart data={weeklyUsageChart} title={props.isHome ? 'Consumo semanal em ' + displayDate : undefined} showline={true} suffixY='TB' suffixX=' semana' showBar={true}></BarChart>
                                :
                                <LcNoData size='small' />
                        }
                    </LcLoading>
                </div>
            }
        },
        {
            id: 6,
            type: 'Custom',
            position: {
                row: 2, col: 9, ColSpan: 4, RowSpan: 2,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Consumo mensal em {displayDate}  </div>
                }</>
            },
            customContentRender: () => {
                return <div className="w-100p h-100p">

                    <LcLoading loading={isLoadingMonthlyChart}>
                        {
                            monthlyUsageChart && !errorMonthlyChart ?
                                <BarChart data={monthlyUsageChart} showline={true} suffixY='TB' showBar={true}></BarChart>
                                :
                                <LcNoData />
                        }
                    </LcLoading>
                </div>
            }
        },
        {
            id: 7,
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                row: 4, col: 1, ColSpan: 4, RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Jobs executados</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}>
                    <IconValue isHome={props.isHome}
                        title="Jobs executados"
                        icon={<PiDatabaseLight size={props.isHome ? 24 : 32} />}
                        color={props.isHome ? "var(--color-primary-purple-dark)" : "var(--color-primary-magenta)"}
                        description={props.isHome ? "Jobs executados" : undefined}
                        value={jobCards?.totalJobs ? jobCards?.totalJobs : 0}></IconValue >
                </LcLoading>
            }
        },
        {
            id: 8,
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                row: 4, col: 5, ColSpan: 4, RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Jobs executados com sucesso</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}><IconValue isHome={props.isHome} title="Jobs executados com sucesso"
                    value={jobCards?.succededJobsPercent && jobCards?.succededJobsPercent != "NaN" ? jobCards?.succededJobsPercent : 0}
                    unity="%"
                    maximumFractionDigits={2} minimumFractionDigits={2} formatValue={true}
                    icon={getIconAndColor((jobCards?.succededJobsPercent && jobCards?.succededJobsPercent != "NaN" ? jobCards?.succededJobsPercent  : 0), "%").icon }
                    color={getIconAndColor((jobCards?.succededJobsPercent && jobCards?.succededJobsPercent != "NaN" ? jobCards?.succededJobsPercent : 0), "%").color}
                    description={props.isHome ? "Jobs executados com sucesso" : undefined}  ></IconValue></LcLoading>
            }
        },
        {
            id: 9,
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: {
                row: 4, col: 9, ColSpan: 4, RowSpan: 1,
            },
            title: () => {
                return <>{
                    <div className="lc-title ellipsis">Jobs executados com falha</div>
                }</>
            },
            customContentRender: () => {
                return <LcLoading loading={isLoadingCards}> <IconValue isHome={props.isHome} title="Jobs executados com falha"
                    icon={getIconAndColor((jobCards?.failedJobsPercent && jobCards?.failedJobsPercent != "NaN" ? jobCards?.failedJobsPercent : 0), "%", true).icon}
                    color={getIconAndColor((jobCards?.failedJobsPercent && jobCards?.failedJobsPercent != "NaN" ? jobCards?.failedJobsPercent : 0), "%", true).color}
                    value={jobCards?.failedJobsPercent && jobCards?.failedJobsPercent != "NaN" ? jobCards?.failedJobsPercent : 0}
                    unity="%"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    formatValue={true}
                    description={props.isHome ? "Jobs executados com falha" : undefined} >
                </IconValue ></LcLoading>
            }
        }
    ];

    return (<>
        {!props.isHome ? <Layout pageTitle="Resumo" periodSelection={periodSelection} lastUpdate={lastUpdate}>
            <LCDashboard cards={cards} grid={[4, 12]}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: false,
                    content: () => {
                        return <div style={{ gridRowEnd: 'span 12', gridColumnEnd: 'span 12' }}>

                        </div>;
                    }
                }} />
        </Layout> :
            <LCDashboard isHomeType grid={props.qtdGroups && props.qtdGroups < 3 ? [6, 2] : [6, 2]} cards={props.qtdGroups && props.qtdGroups > 0 ? cards.map((card, index) => {
                if (props.qtdGroups) {
                    var posi = positionHome(card.id, props.qtdGroups);
                    if (posi !== undefined) {
                        if (card) {
                            if (card.position) {
                                card.position = posi;
                            }
                        }
                    }
                }
                return card;
            }) : []}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: false,
                    content: () => {
                        return <div style={{ gridRowEnd: 'span 12', gridColumnEnd: 'span 12' }}>
                        </div>;
                    }
                }} />}
    </>
    );
};

export default BackupGoldResume;