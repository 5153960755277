import React from 'react';

// Suponho que você tenha componentes individuais para cada um dos grupos.
import CMSCard from '../HomeDashboardCard/CMSCard';
import DataCard from '../HomeDashboardCard/DataCard';
import SWCard from '../HomeDashboardCard/SWCard';
//import AppsCard from '../HomeDashboardCard/AppsCard';
import SecCard from '../HomeDashboardCard/SecCard';
import FinOpsCard from '../HomeDashboardCard/FinOpsCard';
import InfoHistoryCard from '../HomeDashboardCard/InfoHistoryCard';

interface HomeDashboardCardProps {
    GroupId: string;
    QtdGroups: number;
    GTMs: string[];
    hasNF?: boolean;
}

const HomeDashboardCard: React.FC<HomeDashboardCardProps> = ({ GroupId, QtdGroups, GTMs, hasNF }) => {
    let content;

    switch (GroupId) {
        case '0':
            content = <CMSCard GTMs={GTMs} QtdGroups={QtdGroups} />;
            break;
        case '1':
            content = <DataCard GTMs={GTMs} QtdGroups={QtdGroups} />;
            break;
        case '2':
            content = <SWCard GTMs={GTMs} QtdGroups={QtdGroups} />;
            break;
        //case '3':
        //    content = <AppsCard GTMs={GTMs} />;
        //    break;
        case '4':
            content = <SecCard GTMs={GTMs} QtdGroups={QtdGroups} />;
            break;
        case '5':
            content = <FinOpsCard GTMs={GTMs} QtdGroups={QtdGroups} />;
            break;
        case '6':
            content = <InfoHistoryCard GTMs={GTMs} QtdGroups={QtdGroups} hasNF={hasNF} />;
            break;
        default:
            content = <div>Grupo não reconhecido</div>;
    }

    return (
        <>
            {content}
        </>
    );
}

export default HomeDashboardCard;