import moment from 'moment';
export function FlatTree(data, children?: string) {

    let childrenName = children || "children";

    let result: any[] = []

    data.forEach(d => {
        let _d = { ...d };
        delete _d[childrenName];
        result = [...result, _d];
        if (d[childrenName]) {
            let c = FlatTree(d[childrenName], childrenName);
            result = [...result, ...c];
        }
    });
    return result;
} 

// Function to check if a date is in BR format (DD/MM/YYYY)
const isBRDateFormat = (dateString: string): boolean => {
    return moment(dateString, 'DD/MM/YYYY HH:mm:ss Z', true).isValid();
};

// Function to check if a date is in US format (MM/DD/YYYY HH:mm:ss Z)
const isUSDateFormat = (dateString: string): boolean => {
    return moment(dateString, 'MM/DD/YYYY HH:mm:ss Z', true).isValid();
};

// Function to convert date format based on its detected format
export function convertDateFormat(dateString: string): string | null {
    if (isBRDateFormat(dateString)) {
        // Convert from BR to US format with time and timezone
        return moment(dateString, 'DD/MM/YYYY HH:mm:ss Z').format('DD/MM/YYYY');
    } else if (isUSDateFormat(dateString)) {
        // Convert from US to BR format with time and timezone
        return moment(dateString, 'MM/DD/YYYY HH:mm:ss Z').format('DD/MM/YYYY');
    } else {
        console.log('Invalid date format.');
        return null;
    }
}; 

export function getDivider(num: number) {
    switch (true) {
        case (num <= 1000):
            return 1;

        case (num < 1000000):
            return 1000;

        case (num <= 1000000000):
            return 1000000;

        case (num <= 1000000000000):
            return 1000000000;

        default:
            return 1;
    }
}

export function formatNumber(value: number) {
    //Carrega a formata��o decimal de acordo com a lingua do browser
    return new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

export function formatCurrency(value: number) {
    return new Intl.NumberFormat(
        navigator.language, {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
}

export function getDateMinusMonths(value: number, currentDate: Date) {
    currentDate.setMonth(currentDate.getMonth() - 1);
    return currentDate;
}
