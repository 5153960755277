import { PPMultiSelect, PPTextField, PPModal } from "processor-plataform-ui";
import { useEffect, useState } from "react";
import LcLoading from "../../../../components/Generic/LcLoading";
import { NotificationCenterService, NotificationSend } from "../../../../services/notificationCenter/notificationService";

import { PortalUsersService } from "../../../../services/portalUsers/portalUsers";
import CreatedCard from "../createdCard";


interface Props {
    notification?: NotificationSend,
    onClose: Function,
    visible: boolean
}

const DetailsForm: React.FC<Props> = (props) => {
    return <></>
    /*
        const servicePortalUser = new PortalUsersService(props);
        const notificationService = new NotificationCenterService(props);
    
        const [optionsClient, setOptionsClient] = useState<{ value: string, label: string, selected?: boolean, disabled?: boolean }[]>([{ value: '-1', label: 'Selecione uma opção' }]);
        const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
    
        const isSelected = (ClientGroupId: number, allIds?: number[]) => {
            let result = false;
            if (!allIds) result = false;
            allIds && allIds.forEach(id => {
                if (ClientGroupId === id) result = true;
            });
            return result;
        }
    
        useEffect(() => {
            if (props.notification) {
                setIsLoadingClients(true);
                servicePortalUser.getClientActive().then(response => {
                    let options = response.data.map((element) => {
                        return {
                            value: element.id,
                            label: element.description,
                            selected: isSelected(element.id, props.notification?.clientGroupIds),
                            disabled: true
                        }
                    })
                    setOptionsClient(options)
                    setIsLoadingClients(false)
                }).catch(err => {
                    setIsLoadingClients(false)
                })
            }
        }, [props])
    
        return (
            <PPModal
                visible={props.visible}
                loading={isLoadingClients}
                onClose={props.onClose}
                title="Detalhes"
            >
                {props.notification &&
                    <>
                        <CreatedCard
                            pinned={props.notification?.pinned}
                            sendEmail={props.notification?.sendEmail}
                            createdOn={props.notification?.createdOn}
                            createdBy={props.notification?.createdBy}
                            context={props.notification?.context}
                        />
    
                        <PPTextField label={'Título'} required value={props.notification?.notification.title} readOnly />
                        <PPTextField label={'Sub Título'} required value={props.notification?.notification.subTitle} readOnly />
                        <PPTextField label={'Mensagem'} required value={props.notification?.notification.content} readOnly multiline />
                        <PPTextField label={'URL'} required value={props.notification?.notification.url} readOnly />
                        <PPTextField label={'Data de expiração'} required value={notificationService.FormatDateNoHour(props.notification?.notification.expiredDate)} readOnly />
                        <small style={{ fontSize: '12px' }}>Clientes</small>
                        <PPMultiSelect options={optionsClient} required onChange={() => { }} name="Clientes" openTrigger="click" key={'clientGroupIds'} />
                    </>
                }
            </PPModal>
        );*/
}

export default DetailsForm;