import React, { useState, ChangeEvent } from 'react';
import './index.css';
import ExpandableTextBox from '../../../../components/Form/PPTextAreaExplandable';
import AuthenticationModal from '../AuthenticationModal'; 
import { FaCog } from 'react-icons/fa';
import { InputField } from '../../../../components/Form/Input';

interface FormFunctionCallProps {
    isVisible: boolean;
    onClose: () => void;  // Função para fechar o modal e voltar ao EditAssistantForm
}

const FormFunctionCall: React.FC<FormFunctionCallProps> = ({ isVisible, onClose }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [authentication, setAuthentication] = useState<string>('Nenhum');
    const [schema, setSchema] = useState<string>('');
    const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<string>('');

    const handleAuthenticationSave = (selectedAuth: string) => {
        setAuthentication(selectedAuth);
        setIsModalVisible(false);
    };

    if (!isVisible) return null;

    const handleSchemaChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>) => {
        try {
            setSchema(e.target.value);
        } catch (e) {

        } 
    };

    const handlePrivacyPolicyChange = (e: { name: string, value: string }) => {
        setPrivacyPolicyUrl(e.value);
    };

    return (
        <div className="form-container">
            <div className="form-group">
                <label htmlFor="authentication">Autenticação</label>
                <div className="authentication-input" style={{ display: 'flex', alignItems: 'center' }}>
                    <InputField
                        type="text"
                        name="authentication"
                        value={authentication}
                        readonly={true}
                        onChange={() => { }} // Apenas leitura, sem necessidade de onChange 
                    />
                    <button
                        type="button"
                        onClick={() => setIsModalVisible(true)}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <FaCog size={20} />
                    </button>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="schema">Esquema</label>
                <ExpandableTextBox
                    value={schema}
                    label="Esquema"
                    onChange={handleSchemaChange}
                    placeholder="Insira seu esquema aqui..."
                    modalTitle="Detalhes do Esquema"
                    name="schema"
                />
            </div>

            <div className="form-group">
                <label htmlFor="privacyPolicyUrl">Política de Privacidade</label>
                <InputField
                    type="text"
                    name="privacyPolicyUrl"
                    value={privacyPolicyUrl}
                    onChange={handlePrivacyPolicyChange}
                    placeHolder="https://app.exemplo.com/politica-de-privacidade"
                />
            </div>

            <div className="form-buttons" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <button className="pp-button" onClick={onClose}>
                    Voltar
                </button>
                <button className="pp-button" onClick={() => { /* lógica para salvar ou executar algo */ }}>
                    Salvar
                </button>
            </div>

            <AuthenticationModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onSave={handleAuthenticationSave}
            />
        </div>
    );
};

export default FormFunctionCall;
