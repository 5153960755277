import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { WebSite, WebSitesService } from '../../../services/websites/index';
import styles from './index.module.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { PrefState } from '../../../store/reducers/prefReducer';
import { UserState } from '../../../store/reducers/userReducer';

import LcIconLink from '../../../components/Generic/LcIconLink';
import history from '../../../history';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { useSnackbar } from 'notistack';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { PiCheckCircleLight, PiDesktopLight, PiGearLight, PiWarningCircleLight } from 'react-icons/pi';


interface WebSitesResumoProps {
    isHome?: boolean;
}

const WebSitesResumo: React.FC<WebSitesResumoProps> = (props) => {
    const webSitesService = new WebSitesService(props);

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [sideModal, setSideModal] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoadingWebSites, setIsLoadingWebSites] = useState<boolean>(true);
    const [isLoadingProblems, setIsLoadingProblems] = useState<boolean>(true);
    const [isDataCountOk, setIsDataCountOk] = useState<boolean>(true);

    const filterDefault = { size: 20, term: "", column: "", direction: "asc" };

    const [webSites, setWebSites] = useState<any[]>([]);
    const [filteredWebSites, setFilteredWebSites] = useState<any[]>([]);
    const [visibleWebSites, setVisibleWebSites] = useState<any[]>([]);
    const [filterWebsites, setFilterWebsites] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);


    const [problems, setProblems] = useState<any[]>([]);
    const [filteredProblems, setFilteredProblems] = useState<any[]>([]);
    const [visibleProblems, setVisibleProblems] = useState<any[]>([]);
    const [filterProblems, setFilterProblems] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    useEffect(() => {
        retrieveData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setIsLoadingWebSites(true);
        setFilteredWebSites([]);

        let consumption = sessionStorage.getItem('Web Presence');
        webSitesService.GetWebSites()
            .then(response => {
                let id = 1;
                let webSitesRetrieved = response.data.map((Item: WebSite) => {
                    return {
                        id: id++,
                        nome: Item.nome,
                        hostGroup: Item.hostGroup,
                        status: Item.status,
                        costCenterID: Item.costCenterID,
                        isInDeployment: Item.isInDeployment,
                        dashBoardURL: Item.dashBoardURL,
                        baseLineStatus: Item.baseLineStatus
                    }
                });
                setFilteredWebSites(webSitesRetrieved);
                setWebSites(webSitesRetrieved);
                if (consumption && webSitesRetrieved.length == 0 && webSitesRetrieved.length != consumption) {
                    setIsDataCountOk(false)
                    sessionStorage.removeItem('Web Presence')
                }
                setIsLoadingWebSites(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoadingWebSites(false);
            });
    }

    const GoToDashboard = (url: string) => {
        sessionStorage.setItem("LiveWatch_Dashboard_Url", url);
        history.push('/MonitoringDashboard/1');
    };

    const GoToConsume = (cc: number, ccName: string) => {
        if (!(cc >= 0)) {
            enqueueSnackbar(`O centro de custo do baseline no ${ccName} não esta disponível para seu usuário.`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
            return;
        }
        dispatch({ type: 'COSTCENTERUPDATE', payload: { costCenterId: cc } });
        history.push('/costcenterdashboard/analitico');
    };

    const OnCloseSideModal = () => {
        setSideModal(false);
        setIsLoadingProblems(true);
        setProblems([]);
        setFilteredProblems([]);
    };

    const OpenSideModal = (hostGroupName: string) => {
        setSideModal(true);

        setShowFilterPanel(!showFilterPanel)

        setFilteredProblems([]);

        webSitesService.GetWebSiteProblems(hostGroupName)
            .then(response => {
                setProblems(response.data);
                setFilteredProblems(response.data);
                setIsLoadingProblems(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoadingProblems(false);
            });
    };

    const problemColumns = [{ field: 'description', headerName: 'Descrição', width: "50%", }];

    const columns = [
        {
            field: 'nome', headerName: 'Nome', width: "70%",
            renderCell: (row: any) => {
                return (
                    <a className='link' href={"http://" + row.nome} target='_blank' rel="noreferrer">{row.nome}</a>
                )
            }
        },
        {
            field: 'status', headerName: 'Status', width: "10%", sort: false, overflow: 'visible',
            renderCell: (row: any) => {
                if (row.isInDeployment == true) {
                    return (
                        <LcIconLink key={row.id} tooltip="Ambiente em configuração" icon={<PiGearLight/>} />
                    )
                }
                if (row.status < 1) {
                    return (
                        <LcIconLink key={row.id} color={"rgb(165, 191, 62)"} tooltip="Ok" icon={<PiCheckCircleLight /> } />
                    )
                } else {
                    return (
                        <LcIconLink key={row.id} color={"rgb(243, 201, 68)"} tooltip="Ver detalhes" icon={<PiWarningCircleLight/>} onClick={() => OpenSideModal(row.hostGroup)} />
                    )
                }
            }
        },
        {
            field: 'baseLineStatus', headerName: 'Baseline', width: "10%", sort: false, overflow: 'visible',
            renderCell: (row: any) => {
                if (row.isInDeployment == true) {
                    return (
                        <LcIconLink key={row.id} tooltip="Ambiente em configuração" icon={<PiGearLight/>} />
                    )
                }
                if (row.baseLineStatus == true) {
                    return (
                        <LcIconLink key={row.id} color={"rgb(165, 191, 62)"} tooltip="Ver detalhes" icon={<PiCheckCircleLight /> }  onClick={() => GoToConsume(row.costCenterID, row.nome)} />
                    )
                } else {
                    return (
                        <LcIconLink key={row.id} color={"rgb(243, 201, 68)"} tooltip="Ver detalhes" icon={<PiWarningCircleLight/>}  onClick={() => GoToConsume(row.costCenterID, row.nome)} />
                    )
                }
            }
        },
        {
            field: 'isInDeployment', headerName: 'Ambiente', width: "10%", sort: false, overflow: 'visible',
            renderCell: (row: any) => {
                if (row.isInDeployment == false) {
                    return (
                        <LcIconLink key={row.id} tooltip="Ver detalhes" icon={<PiDesktopLight/>} onClick={() => GoToDashboard(row.dashBoardURL)} />
                    )
                } else {
                    return (
                        <LcIconLink key={row.id} tooltip="Ambiente em configuração" icon={<PiGearLight/>} />
                    )
                }
            }
        },
    ]

    const cardHeight = filteredWebSites.length > 0 ? Math.round(6 / filteredWebSites.length) : 2;
    const dashboardCards = [
        {
            type: 'Custom',
            position: { ColSpan: 12, RowSpan: cardHeight, row: 1, col: 1 },
            title: () => <span className={styles.dashboardCardTitle}>Websites</span>,
            customContentRender: () =>
                <LcLoading loading={isLoadingWebSites} label="Carregando websites...">
                    <div className={styles.webSitesCard}>
                        {
                            filteredWebSites && filteredWebSites.map((e: WebSite, index) => {
                                return (
                                    <div className={styles.webSitesCardRow} key={index}>
                                        <div className={styles.webSitesCardRowLink}>
                                            <a href={`//${e.nome}`} target='_blank' rel="noreferrer">{e.nome}</a>
                                        </div>
                                        <div className={styles.webSitesStatus} style={
                                            {
                                                color: webSitesService.GetStatus(e.status).color,
                                                backgroundColor: webSitesService.GetStatus(e.status).bgcolor,
                                                cursor: e.status < 1 ? 'default' : 'pointer'
                                            }
                                        } onClick={() => e.status < 1 ? null : OpenSideModal(e.hostGroup)}>
                                            <i className={webSitesService.GetStatus(e.status).icon}></i> {webSitesService.GetStatus(e.status).text}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <LcIconLink
                                                key={index}
                                                size="small"
                                                icon={e.isInDeployment ? <PiGearLight/> : <PiDesktopLight/>}
                                                onClick={() => !e.isInDeployment ? GoToDashboard(e.dashBoardURL) : null}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </LcLoading>
        },
        // {
        //     type: 'Custom', ColSpan: 5, RowSpan: cardHeight, position: { row: 1, col: 7 },
        //     title: () => <span className={styles.dashboardCardTitle}>Consumo</span>,
        //     customContentRender: () => <>{
        //         <LcLoading loading={isLoadingWebSites} label="Carregando consumo...">
        //             <div className={styles.consumptionCard}>
        //                 <div className={styles.consumptionChartCol}>
        //                     <DoughnutChart data={{
        //                         title: "",
        //                         labels: ["Último mês", "Mês atual"],
        //                         values: [0, 0] // TODO: backend?
        //                     }} />
        //                 </div>
        //                 {
        //                     filteredWebSites && filteredWebSites.map((e: WebSite, index) => {
        //                         return (
        //                             <div className={styles.consumptionDetailsCol} key={index}>
        //                                 <div className={styles.consumptionDetailsPrevious}></div>
        //                                 <div className={styles.consumptionDetailsCurrent}></div>
        //                             </div>
        //                         );
        //                     })
        //                 }
        //             </div>
        //         </LcLoading>
        //     }</>
        // }
    ];



    const dashboardCardsCMS = [
        {
            type: 'Custom', position: { ColSpan: 1, RowSpan: 1, row: 1, col: 1 },
            title: () => 'Websites',
            hideHeader: true,
            customContentRender: () =>
                <LcLoading loading={isLoadingWebSites} label="Carregando websites...">
                    <div className={'scrollable-v'} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '20px 0px' }}>
                        {
                            filteredWebSites && filteredWebSites.map((e: WebSite, index) => {
                                return (
                                    <div className={styles.webSitesCardRowCMS} key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <LcIconLink
                                                tooltip={e.isInDeployment ? 'Ambiente em configuração' : 'Monitoramento' }
                                                tooltipPosition='left'
                                                key={index}
                                                size="small"
                                                icon={e.isInDeployment ? <PiGearLight/> : <PiDesktopLight/>}
                                                onClick={() => !e.isInDeployment ? GoToDashboard(e.dashBoardURL) : null}
                                            />
                                        </div>
                                        <div className={styles.webSitesCardRowLinkCMS}>
                                            <a href={`//${e.nome}`} target='_blank' rel="noreferrer" title={e.nome}>{e.nome}</a>
                                        </div>
                                        <div className={styles.webSitesStatusCMS} style={
                                            {
                                                color: webSitesService.GetStatus(e.status).color,
                                                backgroundColor: webSitesService.GetStatus(e.status).bgcolor,
                                                cursor: e.status < 1 ? 'default' : 'pointer'
                                            }
                                        } onClick={() => e.status < 1 ? null : OpenSideModal(e.hostGroup)}>
                                            <i className={webSitesService.GetStatus(e.status).icon}></i> {webSitesService.GetStatus(e.status, props.isHome).text}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </LcLoading>
        },
    ];

    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

    const dashboardRightSidePanel = {
        title: 'Avisos',
        pinned: false,
        show: showFilterPanel,
        close: setShowFilterPanel,
        hiddenPin: true,
        content: () => {
            return <LcInfiniteTable
                loading={isLoadingProblems}
                columns={problemColumns}
                rows={filteredProblems}
                size={problems.length}
                loadMore={loadMoreProblems}
                onSortChange={onSortChangeProblems} />;
        }
    }

    useEffect(() => {
        var filtered = webSites.filter((p: any) =>
            filterWebsites.term == '' ||
            p.nome?.toLowerCase().indexOf(filterWebsites.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filterWebsites.direction == 'asc') return (a[filterWebsites.column] > b[filterWebsites.column]) ? 1 : -1;
                else return (a[filterWebsites.column] > b[filterWebsites.column]) ? -1 : 1;
            });

        setFilteredWebSites(filtered);
        setVisibleWebSites(filtered.slice(0, filterWebsites.size));
    }, [filterWebsites, filteredWebSites.length > 0]);

    useEffect(() => {
        var filtered = problems.sort((a: any, b: any) => {
            if (filterProblems.direction == 'asc') return (a[filterProblems.column] > b[filterProblems.column]) ? 1 : -1;
            else return (a[filterProblems.column] > b[filterProblems.column]) ? -1 : 1;
        });

        setFilteredProblems(filtered);
        setVisibleProblems(filtered.slice(0, filterProblems.size));
    }, [filterProblems, filteredProblems.length > 0]);



    const loadMoreWebsites = (size?: number) => {
        if (size) setVisibleWebSites(filteredWebSites.slice(0, size));
        else setVisibleWebSites([...visibleWebSites, ...filteredWebSites.slice(visibleWebSites.length, visibleWebSites.length + 10)])
    };

    const loadMoreProblems = (size?: number) => {
        if (size) setVisibleProblems(filteredProblems.slice(0, size));
        else setVisibleProblems([...visibleProblems, ...filteredProblems.slice(visibleProblems.length, visibleProblems.length + 10)])
    };

    const onSortChangeWebsites = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterWebsites({ ...filterWebsites, column: sort.column, direction: sort.direction, size: size })
    };

    const onSortChangeProblems = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterProblems({ ...filterProblems, column: sort.column, direction: sort.direction, size: size })
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const _filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (_filter: any) => setFilterWebsites({ ...filterWebsites, size: 25, term: _filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }


    return props.isHome ? (
        <>
            <LCDashboard
                isHomeType
                grid={[1, 1]}
                cards={dashboardCardsCMS}
                rightSidePanel={dashboardRightSidePanel}
            />
            <style dangerouslySetInnerHTML={{
                __html: `
                        .title-icons .icons-filter:first-of-type {
                            display: none;
                         }
                `}} />
        </>

    ) : (
        <Layout pageTitle="Websites">
            {
                isDataCountOk == false &&
                <small style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <i className="lci lci-exclamation text-warning" style={{ marginTop: '2px', marginRight: '3px' }} />
                    <p>As informações ainda estão sendo atualizadas e isto pode levar alguns minutos, portanto, podem existir divergências nos valores apresentados.</p>
                </small>
            }

            <LCDashboard cards={dashboardCards} rightSidePanel={dashboardRightSidePanel} />
            {/* <LcInfiniteTable
                loading={isLoadingWebSites}
                columns={columns}
                rows={filteredWebSites}
                filter={_filterAdvanced}
                size={webSites.length}
                loadMore={loadMoreWebsites}
                onSortChange={onSortChangeWebsites}
                rowAutoHeight={true}
                density={pref.data?.density || "high"}
            /> */}
        </Layout>
    );


};

export default WebSitesResumo;