import { GridCellValue } from "@material-ui/data-grid";
import api, { APIClient } from "./api";
import { palette } from '@material-ui/system';
import Moment from "react-moment";
import React from "react";
//Assinaturas
export class CacheManagerService extends APIClient {
    public controllerpath = "/api/CacheManager";
    constructor(props: any) {
        super(props);
    }

    public title = 'Cache';
    async Refresh() {
        const url = `${this.endpoint}${this.controllerpath}/RefreshCacheData`;

        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    async LastUpdate() {
        const url = `${this.endpoint}${this.controllerpath}/UpdateAt`;

        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    async LastSyncUpdate(syncService: string, syncModel: string) {
        const url = `${this.endpoint}${this.controllerpath}/LastSyncUpdate?syncService=${syncService}&syncModel=${syncModel}`;

        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    RetrieveHistoryPin() {
        const url = `${this.endpoint}${this.controllerpath}/RetrieveHistoryPin`;

        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    RetrieveHistoryRecent() {
        const url = `${this.endpoint}${this.controllerpath}/RetrieveHistoryRecent`;

        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    async SaveHistoryRecent(saveHistoryRecent: string[]) {
        const url = `${this.endpoint}${this.controllerpath}/SaveHistoryRecent`;

        var response = api({
            method: 'post',
            url,
            data: saveHistoryRecent
        });
        return response;
    }

    async SaveHistoryPin(saveHistoryPin: string[]) {
        const url = `${this.endpoint}${this.controllerpath}/SaveHistoryPin`;

        var response = api({
            method: 'post',
            url,
            data: saveHistoryPin
        });
        return response;
    }
}
