import React, { useState, useEffect } from 'react';

import Layout from '../../components/Layout/Layout';

import DoughnutChart from '../../components/Layout/Dashboard/Doughnut';

import IconValueCardRow from '../../components/Layout/Dashboard/IconValueCardRow';
import IconValueCardCol from '../../components/Layout/Dashboard/IconValueCardCol';
import GaugeChart from '../../components/Layout/Dashboard/gaugeChart/index';
import LcLoading from '../../components/Generic/LcLoading';

import HomeService, { TileControlViewModel, ManagedServiceHome } from '../../services/homeService';
import { FinancialManagementService } from '../../services/financialManagement/FinancialManagementService';

import LCFlexContainer from '../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../components/Layout/LCFlexItem';

import Moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';

import ReleaseNotesService from '../../services/releaseNotesService';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

//NPS
import NPS from './NPS';
import accessProfileService from '../../services/accessProfile/accessProfile';
import { MenuState } from '../../store/reducers/menuReducer';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

interface SolicitationProducts {
    total: number,
    product: string,
    slaAtendimento: number,
    slaConclusao: number
}

function getChamadosPorSituacao(managedServiceHome: ManagedServiceHome): DoughnutChartValue {
    let open: number = managedServiceHome.totalChamadosAbertos;
    let closed: number = managedServiceHome.totalChamadosFechadas;
    let canceled: number = managedServiceHome.totalChamadosCanceladas;
    const chamadosPorOrigem: DoughnutChartValue = {
        title: "Chamados por situação",
        labels: [],
        values: []
    };
    if (open > 0) { chamadosPorOrigem.values.push(open); chamadosPorOrigem.labels.push('Abertos'); }
    if (closed > 0) { chamadosPorOrigem.values.push(closed); chamadosPorOrigem.labels.push('Fechados'); }
    if (canceled > 0) { chamadosPorOrigem.values.push(canceled); chamadosPorOrigem.labels.push('Cancelados'); }
    return chamadosPorOrigem;
}

function getChamadosPorTipoDeEntrega(managedServiceHome: ManagedServiceHome): DoughnutChartValue {

    const chamadosPorTipoDeEntrega: DoughnutChartValue = {
        title: "Chamados por tipo de entrega",
        labels: [],
        values: []
    };

    managedServiceHome && managedServiceHome.solicitationProducts && managedServiceHome.solicitationProducts.map((item: any) => {
        chamadosPorTipoDeEntrega.labels.push(item.product);
        chamadosPorTipoDeEntrega.values.push(item.total);
    });

    return chamadosPorTipoDeEntrega;
}

function getChamadosPorOrigem(managedServiceHome: ManagedServiceHome): DoughnutChartValue {
    let processor: number = managedServiceHome.chamadosAbertosPelaProcessor;
    let client: number = managedServiceHome.chamadosAbertosPeloCliente;
    const chamadosPorOrigem: DoughnutChartValue = {
        title: "Chamados por origem",
        labels: ["Processor", 'Cliente'],
        values: [processor, client]
    };
    return chamadosPorOrigem;
}

function getReferenceDate(data: string) {
    let lastUpdatedDate: string = data;
    let date = new Date(lastUpdatedDate);


    return date.toLocaleString();
}

function getChamadosPorHorario(managedServiceHome: ManagedServiceHome): DoughnutChartValue {
    let commercial: number = managedServiceHome.chamadosHC;
    let notCommercial: number = managedServiceHome.chamadosFHC;
    const chamadosPorHorario: DoughnutChartValue = {
        title: "Chamados por horário",
        labels: ["Comercial", 'Não comercial'],
        values: [commercial, notCommercial]
    };
    return chamadosPorHorario;
}

const SlaSlo: React.FC = (props) => {

    const service = new HomeService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch();
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [referenceDate, setReferenceDate] = useState<string>();
    const [tiles, setTiles] = useState<TileControlViewModel[]>([]);
    const [managedServiceHome, setManagedServiceHome] = useState<ManagedServiceHome>();
    const [isLoading, setIsLoading] = useState(false);
    const [economyBenefit, setEconomyBenefit] = useState<number>(0);
    const [chamadosPorSituacao, setChamadosPorSituacao] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [chamadosPorTipoDeEntrega, setChamadosPorTipoDeEntrega] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [chamadosPorOrigem, setChamadosPorOrigem] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [chamadosPorHorario, setChamadosPorHorario] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [slaAtendimento, setSlaAtendimento] = useState<number>(0);
    const [sloEmConclusao, setSloEmConclusao] = useState<number>(0);
    const [solicitationProducts, setSolicitationProducts] = useState<SolicitationProducts[]>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const financialManagementService = new FinancialManagementService(props);
    const [contratosAtivos, setContratosAtivos] = useState<number>();

    const accountService = new accessProfileService(props);
    const [allowAccessContracts, setAllowAccessContracts] = useState<boolean>(false)
    const [allowedMenuIds, setAllowedMenuIds] = useState<string[]>([])

    const getMenuIds = (menuItems: any) => {
        let ids: any[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].children && menuItems[i].children.length > 0) {
                ids = ids.concat(getMenuIds(menuItems[i].children));
            }
            ids.push(menuItems[i].id);
        }
        return ids;
    }

    useEffect(() => {
        setAllowedMenuIds(getMenuIds(menu.items));
    }, [menu])

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    }
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    useEffect(() => {
        let version = process.env.REACT_APP_LAST_VERSION || '';
        const service: ReleaseNotesService = new ReleaseNotesService(props);

    }, [user.ClientGroupSelected, user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        if (!month && !year) {
            setMonth(Moment().format('MM'));
            setYear(Moment().format('YYYY'));
        }

        if (month && year && !isLoading) {
            setIsLoading(true);
            loadHomData(month as string, year as string)
                .then((item) => { });
        }

    }, [year, month, user.ClientGroupSelected, user.refreshFlag]);


    async function loadHomData(month: string, year: string) {
        setIsLoading(true);
        user.Permission && user.Permission.map((permissionObj: any) => {
            if (permissionObj.permissoesChave == "Permite_Contrato_NotaFiscal"
                && permissionObj.permissoesValor.toLowerCase() == "sim") {
                setAllowAccessContracts(true);
            }
        })
        await Promise.all([loadTiles(), loadManagedServices()]);

        setIsLoading(false);

        async function loadManagedServices() {
            try {

                let responseManagedServiceHome = await service.GetManagedServiceHomeDataByIdMonthAndYear(month, year);

                let benefit: number = responseManagedServiceHome.quantidadeHoras * responseManagedServiceHome.valorHora;

                setEconomyBenefit(benefit / 1000);

                setChamadosPorTipoDeEntrega(getChamadosPorTipoDeEntrega(responseManagedServiceHome));

                setChamadosPorSituacao(getChamadosPorSituacao(responseManagedServiceHome));

                setChamadosPorOrigem(getChamadosPorOrigem(responseManagedServiceHome));

                setChamadosPorHorario(getChamadosPorHorario(responseManagedServiceHome));

                setReferenceDate(getReferenceDate(responseManagedServiceHome.referenceDate));

                setManagedServiceHome(responseManagedServiceHome);

            } catch (e) {

            }

        }

        async function loadTiles() {
            try {
                let responseGetTileControlViewModel = await service.GetTileInfo(month, year);
                setTiles(responseGetTileControlViewModel);
            } catch (e) {

            }

        }
    }

    async function loadClientGroupContract() {
        if (allowAccessContracts == true) {
            try {
                let response = await financialManagementService.GetClientGroupContractFinancial(month, year);

                let result: any[] = [];
                response && response.length > 0 && response.map((item: any) => {
                    let avaliable = false;

                    if (item.contractStatus == 'ATIVO') {
                        avaliable = true;
                    }
                    if (avaliable == true) {
                        result.push(item);
                    }
                });
                setContratosAtivos(result.length);
            } catch (e) {
                console.warn(e)
            }
        }
    }

    useEffect(() => {
        loadClientGroupContract()
    }, [allowAccessContracts])

    //Para usar nos componentes - Cores padronizadas LC11
    const colors = [
        "#5969CD", "#E273C5", "#804E3B", "#A5Bf3E", "#8684EF", "#F3C944", "#59BAC5",
        "#D5672F", "#307422", "#169D36", "#E74A67", "#C23D4F", "#D7BA45", "#B24F5D"
    ];

    useEffect(() => {
        if (managedServiceHome) {
            let attended: number = managedServiceHome.slaInicial ? parseFloat(managedServiceHome.slaInicial.toFixed(2)) : 0;
            setSlaAtendimento(attended);

            let completed: number = managedServiceHome.slaInicial ? parseFloat(managedServiceHome.slaFinal.toFixed(2)) : 0;
            setSloEmConclusao(completed);


            var sortedArray: SolicitationProducts[] = managedServiceHome.solicitationProducts.sort((n1, n2) => {
                if (n1.product > n2.product) { return 1; }
                if (n1.product < n2.product) { return -1; }
                return 0;
            });

            setSolicitationProducts(sortedArray)
        }

    }, [managedServiceHome]);

    const handleTileUrl = (url: string) => {
        user.Menus?.forEach((itemA: any) => {
            itemA.children.forEach((itemB: any) => {
                itemB.children.forEach((itemC: any) => {
                    if (itemC.id == url) {
                        history.push(itemC.route);
                    }
                })
            })
        })

    }

    const asRotinasDesenvolvidas = managedServiceHome && managedServiceHome.rotinasDesenvolvidas > 0;

    //#region Notas fiscais e contratos

    useEffect(() => {

    }, [user.ClientGroupSelected, user.refreshFlag])
    //#endregion

    const GetColorBySLA = (value) => {
        if (value < 30) return 'danger'
        if (value >= 30 && value < 70) return 'warning'
        if (value >= 70) return 'success'

    }

    const GetSlaNode = (dataSet) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                {dataSet.map((e, i) => {
                    return (
                        <div key={i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                            <small>
                                <i className={`lci lci-radiobtn-on text-${GetColorBySLA(e.slaAtendimento)}`} /> {e.product.replace('LiveCloud', 'LC')} - <b>{e.slaAtendimento.toFixed(0) + "%"}</b>
                            </small>
                        </div>
                    )
                })}
            </div>
        )
    }

    const GetSloNode = (dataSet) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                {dataSet.map((e, i) => {
                    return (
                        <div key={i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                            <small>
                                <i className={`lci lci-radiobtn-on text-${GetColorBySLA(e.slaConclusao)}`} /> {e.product.replace('LiveCloud', 'LC')} - <b>{e.slaConclusao.toFixed(0) + "%"}</b>
                            </small>
                        </div>
                    )
                })}
            </div>
        )
    }

    const GetHoursNode = (dataSet) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                {dataSet.map((e, i) => {
                    return (
                        <div key={i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                            <small>
                                <i className={`lci lci-radiobtn-on text-success`} /> {e.product.replace('LiveCloud', 'LC')} - <b>{e.totalHoras}h</b>
                            </small>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <Layout pageTitle="SLA/SLO" periodSelection={periodSelection} >
            <LcLoading loading={isLoading} label="Carregando..." >
                <LCFlexContainer spacing={1}>
                    <LCFlexItem xs={12} sm={6} md={asRotinasDesenvolvidas ? 2 : 3} lg={asRotinasDesenvolvidas ? 2 : 3}>
                        {slaAtendimento && slaAtendimento >= 1 && solicitationProducts ?
                            <GaugeChart tooltipNode={GetSlaNode(solicitationProducts)} fullHeigth={true} steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={slaAtendimento} tooltipInfo="Percentual de chamados atendidos dentro do prazo máximo esperado no periodo selecionado." title="SLA de atendimento" info="Geral" tooltipColor='#2E1956'></GaugeChart>
                            :
                            <GaugeChart fullHeigth={true} steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={100} tooltipInfo="Percentual de chamados atendidos dentro do prazo máximo esperado no periodo selecionado." title="SLA de atendimento" info="Geral"></GaugeChart>
                        }
                        <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                            <LcLoading loading={isLoading} label="Carregando..." />
                        </div>
                    </LCFlexItem>

                    <LCFlexItem xs={12} sm={6} md={asRotinasDesenvolvidas ? 2 : 3} lg={asRotinasDesenvolvidas ? 2 : 3} >
                        {sloEmConclusao && sloEmConclusao >= 1 && solicitationProducts ?
                            <GaugeChart tooltipNode={GetSloNode(solicitationProducts)} fullHeigth={true} steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={sloEmConclusao} tooltipInfo="Percentual de chamados concluídos dentro do prazo máximo esperado no periodo selecionado." title="SLO de conclusão" info="Geral" tooltipColor='#2E1956'></GaugeChart>
                            :
                            <GaugeChart fullHeigth={true} steps={[{ percent: 30, status: 'danger' }, { percent: 40, status: 'warning' }, { percent: 30, status: 'success' }]} value={100} tooltipInfo="Percentual de chamados concluídos dentro do prazo máximo esperado no periodo selecionado." title="SLO de conclusão" info="Geral"></GaugeChart>
                        }
                        <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                            <LcLoading loading={isLoading} label="Carregando..." />
                        </div>
                    </LCFlexItem>


                    {managedServiceHome && managedServiceHome?.quantidadeHoras >= 1 &&
                        <LCFlexItem xs={12} sm={6} md={2} lg={2}>
                            {solicitationProducts ?
                                <IconValueCardCol tooltipNode={GetHoursNode(solicitationProducts)} formatValue={true} title="Horas trabalhadas" icon="lci lci-clock" value={`${managedServiceHome?.quantidadeHoras}` || ''} unity="h" color="#A5Bf3E"></IconValueCardCol>
                                :
                                <IconValueCardCol formatValue={true} title="Horas trabalhadas" icon="lci lci-clock" value={`${managedServiceHome?.quantidadeHoras}` || ''} unity="h" color="#A5Bf3E"></IconValueCardCol>
                            }
                            <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                <LcLoading loading={isLoading} label="Carregando..." />
                            </div>
                        </LCFlexItem>
                    }


                    {economyBenefit >= 1 &&
                        <LCFlexItem xs={12} sm={6} md={2} lg={2}>
                            <IconValueCardCol formatValue={true} title="Economia (R$)" icon="lci lci-pig" value={`${economyBenefit}` || ''} unity="k" color="#8684EF"></IconValueCardCol>
                            <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                <LcLoading loading={isLoading} label="Carregando..." />
                            </div>
                        </LCFlexItem>
                    }

                    {
                        asRotinasDesenvolvidas &&
                        <LCFlexItem xs={12} sm={6} md={2} lg={2}>
                            <IconValueCardCol title="Rotinas no período" icon="lci lci-code" value={`${managedServiceHome?.rotinasDesenvolvidas}` || ''} description="Desenvolvidas" color="#59BAC5"></IconValueCardCol>
                            <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                <LcLoading loading={isLoading} label="Carregando..." />
                            </div>
                        </LCFlexItem>
                    }

                    {
                        <LCFlexItem xs={12} sm={6} md={2} lg={2}>
                            {
                                !isLoading && contratosAtivos && contratosAtivos >= 1 &&
                                <IconValueCardCol onClick={() => { history.push('notasFiscais') }}
                                    title="Contratos (+ Notas Fiscais)" icon="lci lci-document-text" value={contratosAtivos} color="#C23D4F">

                                </IconValueCardCol>
                            }
                            {
                                isLoading && <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                    <LcLoading loading={isLoading} label="Carregando..." />
                                </div>
                            }
                        </LCFlexItem>
                    }

                </LCFlexContainer>

                <LCFlexContainer spacing={1}>
                    {
                        tiles && tiles.length > 0 &&
                        tiles.map((tile: TileControlViewModel, index: number) => {
                            if (tile && tile.title && !tile.enabled) {
                                sessionStorage.removeItem(tile.title)
                                let tilesSize = tiles.filter((t: any) => { return !t.enabled }).length;
                                let formatValue = true;
                                let valueTile = tile?.hired?.toString().replace('/', '') || tile?.consumption || '';
                                let extraDataUnitDescription = tile?.extraDataUnitDescription;
                                let unity = "";

                                if (tile.productType == 13 || tile.productType == 91 || tile.productType == 9 || tile.productType == 4 || tile.productType == 6) {
                                    formatValue = false;
                                }

                                if (tile.productType == 6 || tile.productType == 8 || tile.productType == 17) {
                                    let formated = tile?.consumption;
                                    valueTile = formated;
                                    formatValue = false;
                                }

                                if (tile.productType == 26 || tile.productType == 32) {
                                    unity = "TB";
                                    valueTile = parseFloat(valueTile).toFixed(0);
                                    extraDataUnitDescription = '';
                                }

                                if (tile.productType == 7 || tile.productType == 3 || tile.productType == 31) {
                                    unity = "TB";
                                }

                                tile && tile.title && sessionStorage.setItem(tile.title.toString(), valueTile)

                                if (tile.href && (allowedMenuIds.includes(tile.href.toLowerCase()) || user.IsAdm )) {
                                    return (
                                        <LCFlexItem key={index} xs={12} sm={3} md={3} lg={3}>
                                            <IconValueCardRow onClick={() => { handleTileUrl(tile.href) }} maximumFractionDigits={unity == 'TB' ? 1 : 0} unity={unity} formatValue={formatValue} title={tile?.title || ''} icon={tile?.faIcon || ''} value={valueTile} description={extraDataUnitDescription || ''} color={colors[index]}></IconValueCardRow>
                                        </LCFlexItem>
                                    );
                                }
                            }
                        })
                    }
                </LCFlexContainer>

                <LCFlexContainer spacing={1}>
                    {chamadosPorTipoDeEntrega.values.length > 0 &&
                        <LCFlexItem xs={12} sm={12} md={12} lg={6}>
                            <DoughnutChart data={chamadosPorTipoDeEntrega} fullHeigth={true} spaceBetween='small'></DoughnutChart>
                            <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                <LcLoading loading={isLoading} label="Carregando..." />
                            </div>
                        </LCFlexItem>
                    }

                    {chamadosPorSituacao.values.length > 0 &&
                        (
                            <LCFlexItem xs={12} sm={12} md={6} lg={6}>
                                <DoughnutChart data={chamadosPorSituacao} fullHeigth={true} spaceBetween='small'></DoughnutChart>
                                <div style={{ display: 'flex', justifyContent: 'center' }} className={isLoading ? '' : 'hide'}>
                                    <LcLoading loading={isLoading} label="Carregando..." />
                                </div>
                            </LCFlexItem>
                        )
                    }
                </LCFlexContainer>

            </LcLoading>

            <NPS />

        </Layout>
    )
}

export default SlaSlo;