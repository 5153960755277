import { Tooltip } from "@material-ui/core";
import React from "react";
import "./BigIconSmile.css";

interface Props {
  id?: string;
  title: string;
  info: string;
  status: string;
  icon: string;
  onClick?: Function
}

const BigIconSmile: React.FC<Props> = (props) => {

  const onClickFunction = () => {
    if(props.onClick) {
      props.onClick();
    }
  }

  return (
    <div id={props.id} className="bigIconContent" onClick={() => onClickFunction()}>
      <div className="title">{props.title}
          { props.info.length > 0 ? (
            <Tooltip title={props.info} className="tooltip">
              <i className="lci lci-exclamation-circle"></i>
            </Tooltip>
          ) : null }
      </div>
      
        <div className="icon">
          <i className={`${props.icon} status-${props.status}`}> </i>
        </div>
       
    </div>
  );
};

export default BigIconSmile;
