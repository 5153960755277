import { GridCellValue } from "@material-ui/data-grid";
import api, { APIClient } from "./api";
import { palette } from '@material-ui/system';
import { PPFormInput } from "processor-plataform-ui/dist/components/PPForm2";

export interface IPolicies {
    id: number,
    assignmentName: string,
    idazure: string,
    json: string,
    shortName: string,
    clientGroupId: number
}

export class PoliciesService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    title = 'Policies';

    filterGridDefault = { start: 0, size: 15, filter: '', ordered: 'DisplayName', direction: 'desc' };

    async RetrieveList() {
        //     var filter = `?start=${filterData.start}&size=${filterData.size}&filter=${filterData.filter}&ordered=${filterData.ordered}&direction=${filterData.direction}`;
        const url = `${this.endpoint}/api/AdminPolicies/RetrieveListAvaliablePolicies`;
        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    //async Retrieve(id: number) {
    //    const url = `${this.endpoint}/api/AdminPolicies/Retrieve?id=${id}`;
    //    var response = api({
    //        method: 'get',
    //        url,
    //        withCredentials: false,
    //    });
    //    return response;
    //}
    async Delete(id: any) {
        const url = `${this.endpoint}/api/AdminPolicies/Delete?IDPolicy=${id}`;
        var response = api({
            method: 'delete',
            url,
        }).then((response) => { console.info(response); }).catch((err) => { console.error(err); });
        return response;
    }

    async Save(item: any) {
        if (item.id && item.id > 0) {
            await this.Update(item);
        } else {
            await this.Create(item);
        }
    }

    async Create(item: any) {
        const url = `${this.endpoint}/api/AdminPolicies/Create`;
        var response = api({
            method: 'post',
            url,
            data: item,
        })
            .then((response) => { })
            .catch((err) => { console.error(err); });
        return response;
    }

    async Update(item: any) {
        const url = `${this.endpoint}/api/AdminPolicies/Update`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;
    }

    public emptyRecord: any = {
        id: 0,
        assignmentName: "",
        idazure: "",
        json: "",
        shortName: "",
        clientGroupId: 0
    };

    grouptypes = [{ "label": "", "value": 0 }, { "label": "Office 365", "value": 1 }, { "label": "Segurança", "value": 2 }, { "label": "E-mail ativo de segurança", "value": 3 }, { "label": "Grupo de distribuição", "value": 4 }];

    public template(record: any, disabled: boolean) {

        let item = {
            title: 'assignmentName',
            Fields: [{
                edit: true,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'assignmentName',
                type: 'text',
                title: 'Police(AssignmentName)',
                required: {

                    value: true,
                    required: 'Police(AssignmentName) é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            },
            {
                edit: true,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'idazure',
                type: 'text',
                title: 'Id azure',
                required: {

                    value: true,
                    required: 'Id Azure é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            },
            {
                edit: true,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'shortName',
                type: 'text',
                title: 'Short Name',
                required: {
                    value: true,
                    required: 'Short Name é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 12) {
                        return true;
                    }
                    return false;
                },
            },
            {
                edit: true,
                row: 1,
                col: 1,
                colspan: 2,
                rowspan: 2,
                name: 'json',
                type: 'textArea',
                title: 'JSON Policy',
                required: {
                    value: true,
                    required: 'JSON é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 12) {
                        return true;
                    }
                    return false;
                },
            }]
        };

        return item;
    }
    public async template2(record?: IPolicies) {

        let item: PPFormInput[] = [
            {
                name: 'assignmentName',
                type: 'text',
                title: 'Police(AssignmentName)',
                required: true,
                value: record && record.assignmentName ? record.assignmentName : undefined
            },
            {
                name: 'idazure',
                type: 'text',
                title: 'Id azure',
                required: true,
                value: record && record.idazure ? record.idazure : undefined
            },
            {
                name: 'shortName',
                type: 'text',
                title: 'Short Name',
                required: true,
                value: record && record.shortName ? record.shortName : undefined
            },
            {
                name: 'json',
                type: 'textarea',
                title: 'JSON Policy',
                rows: 5,
                required: true,
                value: record && record.json ? record.json : undefined
            }];

        return item;
    }

    //RetrieveDomains() {
    //    const url = `${this.endpoint}/api/SaaSGrupos/GetDomains`;
    //    let lastKnownState = window.localStorage.getItem(`RetrieveDomains_${this.clientGroupId}`);
    //    //console.info(this.EnumDomains);
    //    this.EnumDomains = lastKnownState && JSON.parse(lastKnownState);
    //    if (this.EnumDomains == null || this.EnumDomains.length == 0) {
    //        api({
    //            method: 'get',
    //            url,
    //            withCredentials: false,
    //        }).then((response) => {
    //            if (response != null && response.data) {
    //                //console.error(JSON.stringify(response.data));
    //                this.EnumDomains = response.data;
    //                window.localStorage.setItem(
    //                    `RetrieveDomains_${this.clientGroupId}`,
    //                    JSON.stringify(this.EnumDomains)
    //                );
    //                console.info(JSON.stringify(this.EnumDomains));
    //            }
    //        }).finally();
    //    }
    //}

    //RetrieveVendorCredentials() {
    //    const url = `${this.endpoint}/api/SaaSGrupos/GetVendorCredentials`;
    //    let lastKnownState = window.localStorage.getItem(`RetrieveVendorCredentialsItem_${this.clientGroupId}`);
    //    console.info(this.EnumVendorCredentials);
    //    this.EnumVendorCredentials = lastKnownState && JSON.parse(lastKnownState);
    //    if (this.EnumVendorCredentials == null || this.EnumVendorCredentials.length == 0) {
    //        api({
    //            method: 'get',
    //            url,
    //            withCredentials: false,
    //        }).then((response) => {
    //            if (response != null && response.data) {
    //                console.error(JSON.stringify(response.data));
    //                this.EnumVendorCredentials = response.data;
    //                window.localStorage.setItem(
    //                    `RetrieveVendorCredentialsItem_${this.clientGroupId}`,
    //                    JSON.stringify(this.EnumVendorCredentials)
    //                );
    //                console.info(JSON.stringify(this.EnumVendorCredentials));
    //            }
    //        }).finally();
    //    }
    //}

    //ActionsBaseFunctions(createAction: any, CloseActions: any, DeleteActions: any) {

    //    let arrayActions = {
    //        functionsEdit: [{
    //            title: 'Salvar',
    //            icon: 'lci lci-check',
    //            func: (item: any) => {

    //                createAction(item);
    //            },
    //            disabled: false
    //        }, {
    //            title: 'Fechar',
    //            icon: 'lci lci-x',
    //            func: () => {
    //                if (CloseActions) {
    //                    CloseActions();
    //                }
    //            },
    //            disabled: false
    //        }]
    //    };

    //    if (DeleteActions != undefined) {
    //        arrayActions.functionsEdit.push({
    //            title: 'Excluir selecionados',
    //            icon: 'lci lci-trash',
    //            func: (item: any) => {
    //                DeleteActions();
    //            },
    //            disabled: false
    //        });
    //    }
    //    return arrayActions;
    //}

}
