import React, { useEffect, useState } from 'react';

//Services
import accessProfileService from '../../../services/accessProfile/accessProfile';

//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

//Componentes
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcCheckBox from '../../../components/Form/LcCheckBox';
import Confirmation from '../../../components/Dialog/Confirmation';
import LcLoading from '../../../components/Generic/LcLoading';
import { PPModal } from 'processor-plataform-ui'
import { useSnackbar } from 'notistack';

const EditMember: React.FC<any> = (props) => {

    const service = new accessProfileService(props)
    const profile = props.account;
    const user = useSelector<RootState, UserState>(state => state.user);

    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
    const [aspNetUser, setAspNetUser] = useState<any[]>([]);
    const [filteredAspNetUser, setFilteredAspNetUser] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingAdd, setIsLoadingAdd] = useState(true);
    const [visibleRecords, setVisibleRecords] = useState<any[]>([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const [selectedAspNetUser, setSelectedAspNetUser] = useState<any[]>([]);
    const [sideModal, setSideModal] = useState(false);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });
    const [filterAdd, setFilterAdd] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 1, term: "", column: "", direction: "asc" });

    const [membersToRemove, setMembersToRemove] = useState<Array<string>>([])
    const [disableRemoveBottom, setDisableRemoveBottom] = useState(true)
    const [openDialog, setOpenDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {

        if (user.ClientGroupSelected) {
            setIsLoading(true);
            service.GetMemberGroupPerfis(profile.id)
                .then(result => {
                    setRecords(result.data);
                    setFilteredRecords(result.data.sort((a, b) => { return a.name > b.name ? -1 : 1 }));
                })
                .catch(console.warn)
                .then(() => {
                    setIsLoading(false);
                })

            service.GetAllUserByClientGroupId(profile.id)
                .then(result => {
                    var sortedArray = result.data.sort((a, b) => { return a.name > b.name ? 1 : -1 })
                    setAspNetUser(sortedArray);
                    setFilteredAspNetUser(sortedArray);
                })
                .catch(console.warn)
                .then(() => {
                    setIsLoadingAdd(false);
                })
        }
    }, [])

    useEffect(() => {
        var filtered = records.filter((p: any) =>
            filter.term == '' ||
            p.name.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredRecords(filtered);
        setVisibleRecords(filtered.slice(0, filter.size));
    }, [filter, filteredRecords.length > 0]);
    

    useEffect(() => {
        var filtered = aspNetUser.filter((p: any) =>
            filterAdd.term == '' ||
            p.name.toLowerCase().indexOf(filterAdd.term) > -1
        ).sort((a: any, b: any) => {
            if (filterAdd.direction === 'desc') return (a[filterAdd.column] > b[filterAdd.column]) ? 1 : -1;
            else return (a[filterAdd.column] > b[filterAdd.column]) ? -1 : 1;
        });


        setFilteredAspNetUser(filtered);
    }, [filterAdd, filteredAspNetUser.length > 0]);

    useEffect(() => {
        if (membersToRemove.length > 0) setDisableRemoveBottom(false)
        else setDisableRemoveBottom(true);
    }, [membersToRemove])

    //ELEMENTOS DA GRID DE MEMBROS DO PERFIL
    const columns = [
        { field: 'name', headerName: 'Nome do membro', width: "90%" },
        {
            field: '', headerName: '', width: "10%",
            renderCell: (row: any) => {
                return <LcCheckBox
                    checked={false}
                    onChange={() => {
                        let member = membersToRemove.find(member => member == row.id)
                        if (member) {
                            let membroRemovido = membersToRemove.filter(member => member != row.id)
                            setMembersToRemove(membroRemovido)
                        } else {
                            if (membersToRemove.length <= 10) {
                                setDisableRemoveBottom(false)
                                membersToRemove.push(row.id)
                            } else {
                                alert("Limite de 10 membros por vez")
                            }
                        }
                    }} />
            }
        }
    ]

    const functions: any[] = [
        {
            title: 'Adicionar membros',
            tooltip: 'Adicionar membros',
            icon: 'lci lci-user-add',
            tooltipPosition: 'left',
            func: () => {
                setSideModal(true)
            }
        },
        {
            title: 'Remover membros',
            icon: 'lci lci-user-remove',
            tooltip: 'Remover membros',
            tooltipPosition: 'left',
            disabled: disableRemoveBottom,
            func: () => {
                setIsDeleting(true)
                service.DeleteMultipleMembers(profile.id, membersToRemove)
                    .then(response => {
                        console.debug(response.data)
                        alert(`Usuários removidos com sucesso`)
                        refreshUsers()
                        setSideModal(false)
                        props.close()
                        setMembersToRemove([])
                    })
                    .catch(error => {
                        console.debug(error);
                        alert(`Erro ao remover usuários`)
                    }).then(result => {
                        setIsDeleting(false)
                    })
            }
        },
        {
            title: 'Cancelar',
            icon: 'lci lci-x',
            tooltip: 'Cancelar',
            tooltipPosition: 'left',
            func: () => {
                if (membersToRemove.length > 0) {
                    setOpenDialog(true)
                } else {
                    refreshUsers()
                    setSideModal(false)
                    props.close()
                    setMembersToRemove([])
                }
            }
        }
    ]

    const refreshUsers = () => {
        setIsLoading(true);
        service.GetMemberGroupPerfis(profile.id).then(result => {
            setRecords(result.data);
            setIsLoading(false);
        }).catch(error => {
            console.debug(error)
        })
    }
    const columnsAspNetUser = [{ field: 'name', headerName: 'Nome do membro', width: "100%" }]

    const _filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' },],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    }
    const _filterAdvancedUser = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' },],
        onChange: (_filter: any, size: number) => {
            setFilterAdd({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
        }
    }

    const functionsAspNetUser = [
        {
            title: 'Adicionar membro',
            icon: 'ppi ppi-plus',
            tooltip: 'Adicionar membro',
            disabled: selectedAspNetUser.length == 0,
            func: () => {
                let ClientGroupPerfisUsuarios: any[] = [];

                selectedAspNetUser.map((user, index) => {
                    ClientGroupPerfisUsuarios.push(
                        {
                            Id: index,
                            IdUser: user.id,
                            ClientGroupPerfilId: profile.id
                        }
                    )
                });

                service.AddUserClientGroupPerfil(ClientGroupPerfisUsuarios).then(result => {
                    if (result.status == 200) {
                        let data = result.data
                        let r = records;
                        data.forEach((_e: any) => {
                            let _u = aspNetUser.find(x => x.id == _e.idUser)
                            r.push({
                                id: _u.id,
                                name: _u.name
                            })
                        });
                        setRecords(r)
                        refreshUsers()
                        setSideModal(false)
                        props.close()
                        enqueueSnackbar(`Usuários adicionados com sucesso`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                }).catch(error => {
                    console.debug(error)
                    enqueueSnackbar(`Erro ao adicionar usuários`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                })

            }
        }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };
    const loadMore = (size?: number) => {
        if (size)
            setVisibleRecords(filteredRecords.slice(0, 10))
        else
            setVisibleRecords([...visibleRecords, ...filteredRecords.slice(visibleRecords.length, visibleRecords.length + 10)])
    };


    const onSortChangeAdd = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterAdd({ ...filterAdd, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMoreAdd = (size?: number) => {
        if (size)
            setFilteredAspNetUser(aspNetUser.slice(0, size))
        else
            setFilteredAspNetUser([...filteredAspNetUser, ...aspNetUser.slice(filteredAspNetUser.length, filteredAspNetUser.length + 10)])

    };

    return (
        <>
            <Confirmation
                display={openDialog}
                confirm={() => { props.close() }}
                close={() => { setOpenDialog(false) }}
                title={'Alterações não salvas'}
                text="Você não salvou suas modificações. Deseja sair mesmo assim?"
                textBtnOk="Sim"
                textBtnCancel="Não"
            />

            <LcLoading loading={isDeleting}>
                <LcInfiniteTable
                    loading={isLoading}
                    columns={columns}
                    functions={functions}
                    rows={visibleRecords}
                    filter={_filterAdvanced}
                    size={filteredRecords.length}
                    loadMore={loadMore}
                    onSortChange={onSortChange}
                    height="70vh"
                />
            </LcLoading>

            <PPModal size='500px' title="Usuários disponíveis para adição" onClose={() => setSideModal(false)} visible={sideModal}>
                <LcInfiniteTable
                    loading={isLoadingAdd}
                    columns={columnsAspNetUser}
                    functions={functionsAspNetUser}
                    onSelectChange={setSelectedAspNetUser}
                    filter={_filterAdvancedUser}
                    rows={filteredAspNetUser}
                    size={aspNetUser.length}
                    loadMore={() => loadMoreAdd}
                    onSortChange={onSortChangeAdd}
                    notLoadMore={true}
                />
            </PPModal>
        </>
    )
}
export default EditMember;
