import React from 'react';
import ReactDOM from 'react-dom/client';
import { AzureAD } from 'react-aad-msal';
import App from './App';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import { authProvider } from './services/authProvider';
import { Grid, LinearProgress } from '@material-ui/core';
import { rootReducerMSAL } from './store/msalRedux';
import { Store } from './store';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import axios, { AxiosAdapter } from 'axios'; 

sessionStorage.removeItem("RegisterUserAfterLogin");

let ClearSession = false;
if (sessionStorage) {
    let msalerror = sessionStorage.getItem("msal.login.error");

    if (msalerror && msalerror.indexOf("Seamless single sign") >= 0) {
        ClearSession = true;
        sessionStorage && sessionStorage.clear();
    }
}

if (process.env.NODE_ENV == 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={Store}>
    {
        <AzureAD provider={authProvider.providerMSal} reduxStore={Store} >
            {(authentication: any) => {

                if (authentication.authenticationState == "Unauthenticated") {
                    return <Login />
                }
                else if (authentication.authenticationState == "Authenticated") {
                    if (authentication.accountInfo != null && authentication.accountInfo.jwtAccessToken != undefined) {
                        authProvider.GetTokenLC(authentication.accountInfo);

                        return <App />
                    } else if (authentication.accountInfo.jwtAccessToken == undefined) {
                        authProvider.GetAccessToken();
                    }
                }
                return (<div className='fullspace'><LinearProgress /></div>);
            }}
        </AzureAD>}
</Provider>
);
