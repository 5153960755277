import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { useSelector } from 'react-redux';

function getLegendText(item, showPercent, decimals) {
    const label = item.label || item.name; // Use item.label se existir, senão use item.name
    return !showPercent ?
        `${(label.length > 20) ? label.substring(0, 20) + '...' : label} : ${((item.value / 100) * 100).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}`
        :
        `${(label.length > 20) ? label.substring(0, 20) + '...' : label} : ${((item.value / 100) * 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
        ;
}



interface DoughNutEChartProps {
    ArrayData: any;
    decimals?: number;
    showPercent?: boolean;
    totalNumber?: number;
    legendOrientation?: 'horizontal' | 'vertical';
    title?: string;
    onRef?: Function;
    offsetPie?: { left?: number; bottom?: number; }
}



const DoughNutEChart: React.FC<DoughNutEChartProps> = ({ ArrayData, decimals = 2, showPercent = false, totalNumber = 0, legendOrientation = 'vertical', title, onRef, offsetPie }) => {
    //console.log(ArrayData);
    const chartRef = useRef<ReactECharts>(null);
    const [currentTotal, setCurrentTotal] = useState("0");
    const [selectedLegends, setSelectedLegends] = useState({});
    const [absoluteValues, setAbsoluteValues] = useState<number[]>([]);
    const user = useSelector<RootState, UserState>(state => state.user);

    const colors = useMemo(() => {
        return user.ClientGroupSelected === 152
            ? ['#5470C6', '#73C0DE', '#4A91E3', '#2D5FA8', '#167ABC', '#1E4477', '#153E90', '#0A47A1', '#083D77']
            : [
                '#8C40E3',  // var(--color-primary-purple)
                '#471FCC',  // var(--color-primary-blue)
                '#34C38F',  // var(--color-secondary-green-light)
                '#00DBFF',  // var(--color-secondary-cyan)
                '#255A4B',  // var(--color-secondary-green-dark)
                '#AB19E7',  // var(--color-primary-magenta)
                '#DA43F2'   // var(--color-primary-pink)
            ];
    }, [user.ClientGroupSelected]);

    const groupedData = useMemo(() => {
        if (!ArrayData) {
            return;
        }
        if (ArrayData.length === 0) {
            return;
        }
        return ArrayData ? ArrayData.reduce((acc, item) => {
            // Verifique se o item tem label ou name e use o que estiver disponível
            const labelName = item.label || item.name || '';

            const existingItem = acc.find(x => x.label === labelName);
            if (existingItem) {
                existingItem.value += item.value;
            } else {
                // Adicione o item ao array, garantindo que ele tenha uma propriedade label
                acc.push({ ...item, label: labelName });
            }
            return acc;
        }, []) : [];
    }, [ArrayData]);


    const data = useMemo(() => {
        return groupedData ? groupedData.sort((a, b) => (b.value - a.value > 0 ? 1 : -1)) : [];
    }, [groupedData]);

    const legendTexts = useMemo(() => {
        return data ? data.map(item => getLegendText(item, showPercent, decimals)) : [];
    }, [data, showPercent, decimals]);

    useEffect(() => {
        if (data) {
            let calculatedAbsoluteValues;
            if (showPercent) {
                calculatedAbsoluteValues = data.map(item => (item.value * totalNumber) / 100);
            } else {
                calculatedAbsoluteValues = data.map(item => item.value);
            }
            setAbsoluteValues(calculatedAbsoluteValues);
        }
    }, [data, showPercent, totalNumber]);


    useEffect(() => {
        const total = groupedData
            ? (!showPercent
                ? (ArrayData ? ArrayData.reduce((total, { value }) => total + value, 0) : 0).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                : totalNumber > 0 ? totalNumber.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) : 100)
            : "0";
        setCurrentTotal(total);
        if (!legendTexts) {
            return;
        }
        if (legendTexts.length === 0) {
            return;
        }
        const initialSelectedLegends = legendTexts.reduce((acc, legend) => {
            acc[legend] = true;
            return acc;
        }, {});

        setSelectedLegends(initialSelectedLegends);
    }, [groupedData, showPercent, totalNumber, decimals, legendTexts, ArrayData]);

    const handleLegendChange = useCallback((params) => {
        let newTotal = 0;
        const newSelectedLegends = { ...selectedLegends };

        legendTexts.forEach((legend, i) => {
            newSelectedLegends[legend] = params.selected[legend];
            if (newSelectedLegends[legend]) {
                newTotal += absoluteValues[i];
            }
        });

        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();

            // Atualize diretamente a instância do gráfico
            chartInstance.setOption({
                legend: {
                    selected: newSelectedLegends
                },
                series: [{
                    label: {
                        formatter: newTotal.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                    }
                }]
            }, false, true);
        }
    }, [decimals, selectedLegends, legendTexts, absoluteValues]);

    const legendData = useMemo(() => {
        return legendTexts;
    }, [legendTexts]);

    useEffect(() => {
        let urlImg = chartRef.current?.getEchartsInstance().getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
        });
        onRef && onRef(urlImg)
    }, [ArrayData, onRef])

    const options = useMemo(() => {
        return {
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 11,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                trigger: 'item',
                formatter: function (params) {
                    // Encontrar o valor absoluto correspondente ao item da tooltip
                    const index = legendTexts.indexOf(params.name);
                    const absoluteValue = absoluteValues[index];

                    // Formatar a tooltip com base no valor de showPercent
                    if (showPercent) {
                        return '<div>' + params.name + '</div>' +
                            ' <b>(' + absoluteValue.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) + ')</b>';
                    } else {
                        return '<div>' + params.name + '</div>' +
                            ' <b>(' + params.percent + '%)</b>';
                    }
                }
            },
            title: {
                text: title,
                right: "0",
                top: "0",
                textStyle: {
                    fontSize: 11,
                    fontWeight: 'normal',
                    color: '#8b90a3',
                    fontFamily: 'Ubuntu',
                },
                backgroundColor: title ? '#ffffff90' : 'transparent',
                borderRadius: [10],
                padding: [4, 8, 4, 8],
                itemGap: 8
            },
            grid: {

            },
            legend: {
                inactiveColor: '#ededed',
                inactiveBorderColor: '#ededed',
                name: '',
                type: 'scroll',  // Enable scroll (pagination)
                orient: legendOrientation,
                width: legendOrientation === 'vertical' ? '' : '100%',
                right: legendOrientation === 'vertical' ? '2%' : 'center',
                top: legendOrientation === 'vertical' ? 'middle' : 'bottom',
                textStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: window.innerWidth < 1650 ? '10px' : '16px',
                },
                itemGap: window.innerWidth < 1650 ? 5 : 10,
                pageButtonItemGap: legendOrientation === 'vertical' ? 5 : 3,
                pageButtonGap: legendOrientation === 'vertical' ? 10 : 5,
                pageButtonPosition: 'end',
                pageFormatter: '{current}/{total}',  // You can customize this
                pageIconSize: 10,
                pageIconColor: '#273b85',
                pageIcons: {
                    vertical: [
                        'path://M10 9L6 5l-4 4 1.4 1.4L6 7.8l2.6 2.6z',  // Chevron para baixo
                        'path://M6 9l-4-4 1.4-1.4L6 6.2l2.6-2.6L10 5z' // Chevron para cima                        
                    ],
                    horizontal: [
                        'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                        'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                    ]
                },
                pageTextStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 10,
                },
                data: legendTexts,
                formatter: (name) => name,
                selected: selectedLegends
            },
            series: [
                //   offsetPie?: { left?: number; bottom?: number; }

                {
                    type: 'pie',
                    center: legendOrientation === 'vertical' ? [(offsetPie && offsetPie.left ? `${offsetPie.left}%` : '30%'), (offsetPie && offsetPie.bottom ? `${offsetPie.bottom}%` : '50%')] : ['50%', '50%'],
                    radius: window.innerWidth < 1600 ? ['40%', '60%'] : ['30%', '50%'],
                    avoidLabelOverlap: false,
                    color: colors,
                    data: data.map((item, index) => ({
                        value: item.value,
                        name: legendTexts[index],
                        itemStyle: {
                            color: item.itemStyle?.color || colors[index % colors.length]
                        }
                    })),
                    label: {
                        show: true,
                        position: 'center',
                        formatter: `${currentTotal}`,
                        fontWeight: 'normal',
                        overflow: 'truncate',
                        ellipsis: '...',
                        fontFamily: 'Ubuntu',
                        fontSize: window.innerWidth < 1600 ? '18px' : '24px',
                    },
                    itemStyle: {
                        borderRadius: 4,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(58, 79, 135, 0.4)',
                            borderWidth: 0
                        }
                    }
                }
            ]
        };
    }, [title, legendOrientation, legendTexts, selectedLegends, colors, data, currentTotal, absoluteValues, showPercent, decimals]);

    if (!ArrayData) {
        return <div></div>;
    }

    return <ReactECharts
        ref={chartRef}
        style={{
            width: '100%',
            height: 'calc(100%)',
            minHeight: legendOrientation === 'horizontal' ? '160px' : ''
        }}
        opts={{ renderer: 'svg', locale: 'PT-br' }}
        option={options}
        notMerge={true}
        onEvents={{ 'legendselectchanged': handleLegendChange }}
    />;
}

export default DoughNutEChart