import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LcLoading from '../../../../components/Generic/LcLoading';
import { formatDecimal } from '../../../../services/currency';
import { FinOpsAdvanceFilterService, GroupReport } from '../../../../services/finOps/finOpsService';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { FinOpsNestedCostCenterCostProps } from '../../../finOps/finOpsNestedCostCenterCosts';
import PPDropDown from '../../../../components/PPDropDown';

// import { Container } from './styles';

const HigherConsumption: React.FC<FinOpsNestedCostCenterCostProps> = (props) => {
    //Redux
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [finOpsMapManagerService] = useState<FinOpsAdvanceFilterService>(new FinOpsAdvanceFilterService(props));
    const [finopsData, setFinopsData] = useState<GroupReport[]>([]);
    const [groupbyParam, setGroupbyParam] = useState<string>("service");
    const [costCenters, setCostCenters] = useState<any[]>(props.costCenters);
    const [selectedCompareOption, setSelectedCompareOption] = useState('');
    const [selectedContract, setSelectedContract] = useState<any>(props.selectedContract);
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>(props.selectedCostCenter);
    const [filterText, setFilterText] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(props.tipoDePeriodo);    
    const [filter, setFilter] = useState<any>(props.filter);

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter])
 
    useEffect(() => {
        setTipoDePeriodo(props.tipoDePeriodo);
    }, [props.tipoDePeriodo])

    useEffect(() => {
        setCostCenters(props.costCenters);
    }, [props.costCenters])

    useEffect(() => {
        setSelectedContract(props.selectedContract);
    }, [props.selectedContract])

    useEffect(() => {
        setSelectedCostCenter(props.selectedCostCenter);
    }, [props.selectedCostCenter])

    const retrieveUsagebyGroup = useCallback(async(tipoDePeriodoParm: string) => {
        setFinopsData([]);
        setLoading(true);

        finOpsMapManagerService
            .GetLoadUsagebyGroup(
                tipoDePeriodoParm,
                groupbyParam,
                selectedContract,
                selectedCostCenter,
                filter
            )
            .then(response => {
                setFinopsData(response);
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    },[])

    useEffect(() => {        
        tipoDePeriodo !== undefined &&
            retrieveUsagebyGroup(tipoDePeriodo);
    }, [tipoDePeriodo, groupbyParam, filter, user.ClientGroupSelected]);

    const dateOptions = [
        { label: 'Este mês', value: 'currentmonth' },
    ];

    const jsonFinOpsGroupBy = [
        {
            label: "Centro de custo",
            value: "costcenter"
        },
        {
            label: "Centro de custo / serviço",
            value: "costcenter_service"
        },
        {
            label: "Centro de custo / recurso",
            value: "costcenter_resource"
        },
        {
            label: "Serviço",
            value: "service"
        },
        {
            label: "Serviço / recurso",
            value: "service_resource"
        },
        {
            label: "Assinatura ",
            value: "account"
        },
        {
            label: "Assinatura / serviço",
            value: "account_service"
        },
        {
            label: "Região ",
            value: "region"
        },
        {
            label: "Região / serviço",
            value: "region_service"
        }
    ];

    var compareOptions = [
        { label: 'Todos os valores', value: '' },
        { label: 'Valores que aumentaram', value: 'greaterThan' },
        { label: 'Valores que diminuíram', value: 'lesserThan' }
    ];

    const [finopsDetailSort, setFinopsDetailSort] = useState({ col: 'value', dir: 1 });
    const [finopsDetailData, setFinopsDetailData] = useState<any[]>([]);
    const [finopsDetailDataLoading, setFinopsDetailDataLoading] = useState(false);
    const loadNewData = () => {
        setFinopsDetailDataLoading(true);
        finOpsMapManagerService.LoadUsagebyGroupCostCenterDetailPercentage(
            tipoDePeriodo!,
            groupbyParam,
            selectedContract,
            '',
            filterText,
            selectedCostCenter || '0',
            4,
            filter
        ).then(response => {
                setFinopsDetailData(response);
            })
            .catch((e) => {
                 
                console.warn(e);
            })
            .then(() => {
                setFinopsDetailDataLoading(false);
            })
    }

    useEffect(loadNewData, [tipoDePeriodo, groupbyParam, filterText, user.ClientGroupSelected, filter]);

    const calcDiference = (difference: number) => {
        if (difference > 0) {
            return <i className="ppi ppi-arrow-up text-danger" />
        } else if (difference < 0) {
            return <i className="ppi ppi-arrow-down text-success" />
        }
    };

    const mountTreeViewFinOps = (data, top10 = false) => {

        return (
            <>
                {data && data.length > 0 &&
                    data.filter(c => {
                        return (selectedCompareOption === '') ? true :
                            selectedCompareOption === 'greaterThan' ?
                                c.difference > 0 : selectedCompareOption === 'lesserThan' ? c.difference <= 0 : true
                    }).sort((a, b) => {
                        var sortReturn = 0;
                        sortReturn = (finopsDetailSort.col === 'last') ? -1 * (a.last - b.last) * finopsDetailSort.dir : 0;
                        sortReturn += (finopsDetailSort.col === 'value') ? -1 * (a.value - b.value) * finopsDetailSort.dir : 0;
                        sortReturn += (finopsDetailSort.col === 'title') ? -1 * a.title.localeCompare(b.title) * finopsDetailSort.dir : 0;

                        return sortReturn;
                    }).map((d, i) => {
                        let _children = d.children && d.children.length > 0 ? d.children : undefined;
                        let _data = d.data && d.data.length > 0 ? d.data : undefined;

                        return (
                            <Fragment key={i}>
                                <div className='finops-row'
                                    onClick={() => {
                                        d.expanded = !d.expanded;
                                        setFinopsDetailData([...finopsDetailData]);
                                    }}
                                >
                                    <div style={{ marginLeft: !(_children || _data) ? '5px ' : '0px', minWidth: '65%' }}
                                        className="cell-descricao label ellipsis">
                                        {(_children || _data) &&
                                            <i className={`ppi ppi-chevron-${d.expanded ? 'down' : 'right'}`} />}
                                        {mountIcon(d.type)} {d.title}
                                    </div>

                                    <div style={{ minWidth: '35%' }} className={`row values${d.expanded || data.find(x => x.expanded) ? ' text-darkerx' : ''}`}>
                                        {<span className='row center' style={{ minWidth: '47%', justifyContent:'end' }}>{formatDecimal(Number(d.last))}</span>}
                                        <span className='row  center' style={{ minWidth: '46%', justifyContent: 'end' }}>{formatDecimal(Number(d.value))}</span>
                                        <span className='row  center' style={{ minWidth: '10%' }}>   {calcDiference(Number(d.difference))}</span>
                                    </div>
                                </div>
                                {d.expanded &&
                                    <div className='ml-5 striped'>
                                        {_data && _data.sort((a, b) => {
                                            var sortReturn = 0;
                                            sortReturn = (finopsDetailSort.col === 'last') ? -1 * (a.last - b.last) * finopsDetailSort.dir : 0;
                                            sortReturn += (finopsDetailSort.col === 'value') ? -1 * (a.value - b.value) * finopsDetailSort.dir : 0;


                                            return sortReturn;
                                        }).filter(c => {
                                            return (selectedCompareOption === '') ? true :
                                                selectedCompareOption === 'greaterThan' ?
                                                    c.difference > 0 : selectedCompareOption === 'lesserThan' ? c.difference <= 0 : true
                                        }).map((e, i) => (
                                            <div style={{ minWidth: '95%'}} className='finops-row' key={i}>
                                                <div className="cell-descricao label ellipsis" style={{ minWidth: '65%' }}>
                                                    {mountIcon(e.type)} {e.title}
                                                </div>
                                                <div style={{ minWidth: '35%' }} className={`row values${d.children.expanded ? ' text-darker' : ''}`}>
                                                    {<span className='row right' style={{ minWidth: '46%', justifyContent: 'end' }}>{formatDecimal(Number(e.last))}</span>}
                                                    <span className='row  center' style={{ minWidth: '47%', justifyContent: 'end' }}>{formatDecimal(Number(e.value))}</span>
                                                    <span className='row  center' style={{ minWidth: '10%', }}>   {calcDiference(Number(e.difference))}</span>
                                                </div>
                                            </div>
                                        ))}
                                        {mountTreeViewFinOps(d.children, top10)}
                                    </div>
                                }
                            </Fragment>
                        );
                    })}
            </>
        );
    };

    const mountIcon = (type: string) => {
        switch (type) {
            case 'costcenter_data':
                return <i className='lci lci-currency-dollar  ' title='Centro de Custo' />
            case 'costcenter':
                return <i className='lci lci-currency-dollar text-success' title='Centro de Custo' />
            case 'service':
                return <i className='ppi ppi-cloud-server text-primary' title='Serviço' />
            case 'resource':
                return <i className='ppi ppi-chip text-danger' title='Recurso' />
        }
    }
 
    return (
        <>
            <div className="titlefix">
                <div title="Consumos (R$)" className={'lc-title ellipsis'} >Consumos (%)</div>
                <PPDropDown
                    name="groupbyParam"
                    options={jsonFinOpsGroupBy}
                    defaultValue={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                    value={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                    onChange={(event) => { setGroupbyParam(event.value); }}
                    disabled={loading}
                    width='130px'
                />
            </div>
            <LcLoading loading={loading} >
                <div className='finops-row-title' style={{ paddingLeft: '10px',paddingRight:'0px' }}>
                    <div className="cell-descricao label text-darker ellipsis"
                        style={{ minWidth: '65%' }}  onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'title', dir: finopsDetailSort.col === 'title' ? (finopsDetailSort.dir * -1) : 1 })}>
                        Descrição{finopsDetailSort.col === 'title' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                    </div>
                    <div style={{ minWidth: '35%' }} className="row values">
                        {<span className="row center text-darker" style={{ minWidth: '45%', justifyContent: 'end' }}
                            onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'last', dir: finopsDetailSort.col === 'last' ? (finopsDetailSort.dir * -1) : 1 })}>
                            {!tipoDePeriodo.includes("last") ? '%Anterior': '%Primeiro'}
                            {finopsDetailSort.col === 'last' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                        </span>}
                        <span className="row center text-darker" style={{ minWidth: '45%', justifyContent: 'end' }}
                            onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'value', dir: finopsDetailSort.col === 'value' ? (finopsDetailSort.dir * -1) : 1 })}>                            
                            {!tipoDePeriodo.includes("last") ? '%Atual' : '%Último'}
                            {finopsDetailSort.col === 'value' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                        <span className="row center text-darker" style={{ minWidth: '10%' }}
                         > 
                        </span>
                    </div>
                </div>
                <LcLoading loading={finopsDetailDataLoading} >
                    <div className='scrollable-v' >
                        {mountTreeViewFinOps(finopsDetailData.sort((a, b) => {
                            var sortReturn = 0;
                            sortReturn = (finopsDetailSort.col === 'last') ? a.last - b.last * finopsDetailSort.dir : 0;
                            sortReturn += (finopsDetailSort.col === 'value') ? a.value - b.value * finopsDetailSort.dir : 0;
                            sortReturn += (finopsDetailSort.col === 'title') ? a.title.localeCompare(b.title) * finopsDetailSort.dir : 0; 
                            return sortReturn;
                        }), true)}
                    </div>
                </LcLoading>
            </LcLoading>
        </>
    );
}

export default HigherConsumption;
        