export class patchmanager {
    solution() {
        return 'patchmanager'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-patch-manager'
    }

    logotipo() {
        return require('./assets/logotipos/logo_patchmanager.svg').default
    }
    titulo() {
        return <>Acelere a <b>segurança e eficiência do negócio</b> com LiveCloud Patch Manager</>
    }

    chamada() {
        return <>Uma equipe de especialistas para gerenciar e aplicar patches em um ambiente centralizado, simplificando o processo de atualizações em vários sistemas e centenas de aplicações de terceiros como Microsoft, Adobe, Java e muito mais.</>
    }

    image() {
        return require('./assets/imgs/imagem_patchmanager.png').default
    }

    detalhes() {
        return <>
            <h2>LiveCloud Patch Manager oferece um conjunto de ferramentas e práticas que ajudam a gerenciar e aplicar patches, atualizações e correções de segurança em sistemas operacionais e softwares</h2>
            <h3>Por que escolher LiveCloud Patch Manager?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_patchmanager.png').default} />
                </div>
                <ul>
                    <li><b>Segurança aprimorada:</b> Ao garantir que os patches e atualizações sejam aplicados em tempo hábil, LiveCloud Patch Manager protege o ambiente contra vulnerabilidades de segurança.</li>
                    <li><b>Otimização de custos:</b> Ao automatizar o processo de aplicação de patches e reduzir o risco de erros, LiveCloud Patch Manager ajuda a otimizar tempo e custos associados ao gerenciamento de patches.</li>
                    <li><b>Conformidade regulatória:</b> LiveCloud Patch Manager ajuda a manter a conformidade com as regulamentações de segurança, para que os sistemas estejam atualizados com as últimas correções de segurança.</li>
                    <li><b>Melhoria da eficiência:</b> Ao simplificar e automatizar o processo de gerenciamento de patches, LiveCloud Patch Manager melhora a eficiência do gerenciamento de sistemas e libera tempo para outras tarefas importantes de TI.</li>
                </ul>
            </div>
            <div className="box">LiveCloud Patch Manager é uma <b>solução completa para o gerenciamento de patches</b>, oferecendo desde a aplicação de patches de terceiros até a atualização de drivers e a automatização de patches. Além disso, fornece relatórios e auditorias que mostram o status de patches em todo o ambiente, permitindo que os administradores de sistemas avaliem riscos e tomem medidas preventivas.</div>
            <h3>Interface gerencial única</h3>
            <p>LiveCloud oferece uma interface gerencial única, permitindo o acompanhamento dos serviços contratados pela organização. Essa interface pode disponibilizar painéis de administração, níveis de serviço, visões gerenciais de monitoramento, disponibilidade dos serviços, avaliação e predição de performance e consumo, solicitação de serviços, abertura de chamados e logs das principais atividades, além da evolução e demais alterações do ambiente.</p>
            <h3>Não perca tempo!</h3>            
            <p>Quer saber mais? Entre em contato conosco hoje mesmo para descobrir como LiveCloud Patch Manager pode transformar o negócio.</p>            
        </>
    }
}
export default patchmanager;