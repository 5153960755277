import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { RetencaoService } from '../../../services/retencao/retencaoService';
import { Grid, LinearProgress, Button } from '@material-ui/core';
import TreeView from '../../../components/Data/retencao/treeView/index';
import LcLoading from '../../../components/Generic/LcLoading';
import styles from './index.module.css'
//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useSnackbar } from 'notistack';
import LcNoData from '../../../components/Generic/LcNoData';

const ArquivosEmRetencao: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [treeView, setTreeView] = useState<any>();
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [UpdatedAt, setUpdatedAt] = useState<string>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadingLC, setLoadingLC] = useState<boolean>(true);

    useEffect(() => {
        if (user.ClientGroupSelected) {
            const retencaoService = new RetencaoService(props);
            enqueueSnackbar("Buscando os arquivos em retenção. Aguarde um instante", {
                variant: 'warning',
                preventDuplicate: true,
                persist: false,
            });
            retencaoService.GetTree("")
                .then(response => {
                    let treeView = response.data;
                    setTreeView(treeView);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        setTimeout(() => {
            setLoadingLC(false);
        }, 3000);
    }, []);

    function showMoreContainers() {
        setIsLoadingMore(true);
        const retencaoService = new RetencaoService(props);
        retencaoService.GetTree(treeView.blobContinuationToken)
            .then(response => {
                    response.data.children.forEach(function (item: any) {
                     treeView.children.push(item);
                    });
                    treeView.blobContinuationToken = response.data.blobContinuationToken;
                    setIsLoadingMore(false);
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <Layout pageTitle="Arquivos em retenção" >
            {treeView ?
                <div className="card-page">
                    <div className='scrollable-v'>
                        {treeView.children.length > 0 ?
                            treeView.children.map((Item: any) => {
                                return <TreeView model={Item}></TreeView>
                            })
                            :
                            <LcNoData />
                        }
                        {treeView.blobContinuationToken && !isLoadingMore &&
                            <a style={{ textDecoration: 'none' }} href="#" onClick={showMoreContainers}>Mostrar Mais</a>
                        }
                    </div>
                </div>
                :
                <LcLoading loading={loadingLC} loadingType='Helix' />
            }
        </Layout>
    );
};

export default ArquivosEmRetencao;