import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import LcTable from '../../../components/Data/LcTable';
import { InputField, SelectField } from '../../../components/Form/Input';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import FinOpsMapManagerService from '../../../services/finOpsManagerService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import moment from 'moment';

interface recordMapperField {
    id: number,
    lcFieldName: string,
    lcFieldDescription: string,
    lcFieldType: string,
    awsField: string,
    azureField: string,
    azureMarketplaceField: string,
    azureReservationField: string
}

interface recordVersionMapper {
    clientGroupId: number,
    startDate: string,
    endDate: string,
    version: string,
    name: string,
    updatedbyuser: string,
    updatedAt: string,
    maps: recordMapperField[]
}

const FinOpsMapManagerComponent: React.FC = (props) => {
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [finOpsMapManagerService, setFinOpsMapManagerServicet] = useState<FinOpsMapManagerService>(new FinOpsMapManagerService(props));
    const [azureUsageFields, setAzureUsageFields] = useState();
    const [awsFields, setAwsFields] = useState();
    const [azureReservationFields, setAzureReservationFields] = useState();
    const [azureMarketplaceFields, setAzureMarketplaceFields] = useState();
    const [mapperVersionLoading, setMapperVersionsLoading] = useState<boolean>(false);
    const [recordVersionMapper, setRecordVersionMapper] = useState<recordVersionMapper>({

        clientGroupId: 0,
        startDate: '',
        endDate: '',
        version: '',
        name: '',
        updatedbyuser: '',
        updatedAt: '',
        maps: []
    });


    const [mapperVersions, setMapperVersions] = useState<any[]>([]);

    useEffect(() => {

        async function retrieveDataAzure() {
            var month = moment().month();
            var year = moment().year();
            var azureResultField = await finOpsMapManagerService.GetAzureUsageFields(month, year);
            setAzureUsageFields(azureResultField.map((e) => {
                return {
                    "name": e.name,
                    "value": e.name
                };
            }));
        }
        async function retrieveMapperVersions() {

            var azureResultField = await finOpsMapManagerService.GetMapperVersions();
            setMapperVersions(azureResultField.map((u) => {
                return {
                    "name": u.name.split('/')[2].split('.')[0],
                    "value": u.name.split('/')[2].split('.')[0]
                };
            }));
        }
        async function retrieveDataAWS() {
            var month = moment().month();
            var year = moment().year();
            var awsResultField = await finOpsMapManagerService.GetAWSFields(month, year);
            setAwsFields(awsResultField.map((e) => {
                return {
                    "name": e.name,
                    "value": e.name
                };
            }));
        }
        async function retrieveDataAzureReservation() {
            var month = moment().month();
            var year = moment().year();
            var azureReservationResultField =
                await finOpsMapManagerService.GetAzureReservationFields(month, year);
            setAzureReservationFields(azureReservationResultField.map((e) => {
                return {
                    "name": e.name,
                    "value": e.name
                };
            }));
        }
        async function retrieveDataAzureMarketplace() {
            var month = moment().month();
            var year = moment().year();
            var azureMarketplaceResultField =
                await finOpsMapManagerService.GetAzureMarketplaceFields(month, year);
            setAzureMarketplaceFields(azureMarketplaceResultField.map((e) => {
                return {
                    "name": e.name,
                    "value": e.name
                };
            }));
        }
        retrieveMapperVersions();
        retrieveDataAzure();
        retrieveDataAWS();
        retrieveDataAzureReservation();
        retrieveDataAzureMarketplace();

    }, [user.ClientGroupSelected, user.refreshFlag]);

    const emptyrecord = {
        id: 0,
        lcFieldName: '',
        lcFieldDescription: '',
        lcFieldType: '',
        awsField: '',
        azureField: '',
        azureMarketplaceField: '',
        azureReservationField: ''
    };

    const [tableFinOpsFields, setTableFinOpsFields] = useState<recordMapperField[]>([]);

    const fieldFinOpsTypes = [{
        "type": "string",
        "name": "string",
        "value": "string"
    }, {
        "type": "decimal",
        "name": "decimal",
        "value": "decimal"
    }, {
        "type": "double",
        "name": "double",
        "value": "double"
    }, {
        "type": "datetime",
        "name": "datetime",
        "value": "datetime"
    }, {
        "type": "boolean",
        "name": "boolean",
        "value": "boolean"
    }, {
        "type": "integer",
        "name": "integer",
        "value": "integer"
    }];

    function handleChange(event: any, item: any) {

        const { name, value } = event;
        item[name] = value;
        setTableFinOpsFields([...tableFinOpsFields]);
    };

    const columns = [
        {
            field: 'lcFieldName', headerName: 'LiveCloud', width: '20%', renderCell: (row: any) => {
                return <div>
                    <InputField updateParentConst={true} key={'lcFieldName'} type='text' value={row.lcFieldName}
                        name={'lcFieldName'} onChange={(event) => { handleChange(event, row); }}
                    />
                </div>;
            }
        },
        {
            field: 'lcFieldDescription', headerName: 'Descrição', width: '40%', renderCell: (row: any) => {
                return <span style={{ minWidth: '100%' }}>
                    <InputField updateParentConst={true} key={'lcFieldDescription'} type='text' value={row.lcFieldDescription}
                        name={'lcFieldDescription'} onChange={(event) => { handleChange(event, row); }}
                    />
                </span>;
            }
        },
        {
            field: 'lcFieldType', headerName: 'Tipo', width: '10%', renderCell: (row: any) => {
                return <div>
                    <SelectField name="lcFieldType" value={row.lcFieldType} options={fieldFinOpsTypes} onChange={(event) => { handleChange(event, row); }}></SelectField>
                </div>;
            }
        },
        {
            field: 'awsField', headerName: 'AWS', width: '10%', renderCell: (row: any) => {
                return <div>
                    <SelectField name="awsField" value={row.awsField} options={awsFields} onChange={(event) => { handleChange(event, row); }}></SelectField>
                </div>;
            }
        },
        {
            field: 'azureField', headerName: 'Azure', width: '10%', renderCell: (row: any) => {
                return <div>
                    <SelectField name="azureField" value={row.azureField} options={azureUsageFields} onChange={(event) => { handleChange(event, row); }}></SelectField>
                </div>;
            }
        },
        {
            field: 'azureMarketplaceField', headerName: 'Azure market', width: '10%', renderCell: (row: any) => {
                return <div>
                    <SelectField name="azureMarketplaceField" value={row.azureMarketplaceField} options={azureMarketplaceFields} onChange={(event) => { handleChange(event, row); }}></SelectField>
                </div>;
            }
        },
        {
            field: 'azureReservationField', headerName: 'Azure reserva', width: '10%', renderCell: (row: any) => {
                return <div>
                    <SelectField name="azureReservationField" value={row.azureReservationField} options={azureReservationFields} onChange={(event) => { handleChange(event, row); }}></SelectField>
                </div>;
            }
        }
    ];

    function addRecord() {
        var newRecord = { ...emptyrecord };
        let max = 0;
        if (tableFinOpsFields.length > 0) {
            const ids = tableFinOpsFields.map(object => {
                return object.id;
            });
            max = Math.max.apply(null, ids);
        }
        newRecord.id = max + 1;
        tableFinOpsFields.push({ ...newRecord });
        setTableFinOpsFields([...tableFinOpsFields]);
    }


    const functionsRow = [
        {
            label: 'Excluir',
            func: (row: any) => { removeRecord(row) }
        }
    ];


    function removeRecord(item) {

        var result = tableFinOpsFields.filter(x => x.id != item.id);
        setTableFinOpsFields([...result]);
    }

    function saveMapperGroup() {

        recordVersionMapper.maps = tableFinOpsFields;
        finOpsMapManagerService.SaveFieldMapperVersion(recordVersionMapper);
    }

    async function LoadFieldMapperVersion(versionName) {
        setMapperVersionsLoading(true);
        var resultData = await finOpsMapManagerService.LoadFieldMapperVersion(versionName);

        setRecordVersionMapper(resultData);
        setTableFinOpsFields([...resultData.maps]);
        setMapperVersionsLoading(false);
    }

    return (
        <Layout>
            <Grid item container spacing={1}>
                <Grid item  >    Versões :
                    <SelectField name="version" options={mapperVersions} onChange={(event) => { LoadFieldMapperVersion(event.value); }}></SelectField>
                </Grid>
                <Grid item  >   Mapeamento:
                    <InputField type={'text'} name="version" value={recordVersionMapper.version} onChange={(event) => { handleChange(event, recordVersionMapper); }}></InputField>
                </Grid>
                <Grid item>
                    <LcIconLink icon={`lci lci-save `} onClick={() => { saveMapperGroup(); }} tooltip="Salvar" />
                </Grid>
                <Grid item>
                    <LcIconLink icon={`ppi ppi-plus `} onClick={() => { addRecord(); }} tooltip="Adicionar" />
                </Grid>
            </Grid>
            <LcLoading loading={mapperVersionLoading} >
                <div style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(80vh)' }} >
                    <LcTable
                        data={tableFinOpsFields}
                        columns={columns}
                        functionsRow={functionsRow}
                    />
                </div>
            </LcLoading>

        </Layout>
    );
};


export default FinOpsMapManagerComponent;
