import React, { useState } from 'react';
import LcIconLink from '../../Generic/LcIconLink';

import './index.css';

interface Props {
    text: string | React.ReactNode;
    title?: string;
    confirm: Function;
    close?: Function;
    textBtnOk?: string;
    textBtnCancel?: string;
    display?: boolean
}

const Confirmation: React.FC<Props> = (props) => {

    const [visible, setVisible] = useState<boolean>(false);

    const confirm = () => {
        setVisible(false);
        props.confirm();
    }
    
    return (
        <div className="lcd-confirmation">
            <div className="element" onClick={() => { setVisible(true) }}>
                {props.children}
            </div>
            {
                (visible || props.display) &&
                <div className="all">
                    <div className="body">
                        {
                            props.title &&
                            <div className="title">
                                <span>{props.title}</span>
                                <LcIconLink icon='lci lci-x' onClick={() => { setVisible(false); props.close && props.close(); }} tooltip='Fechar' tooltipPosition='left' />
                            </div>
                        }
                        <div className="text">
                            {props.text}
                        </div>
                        <div className="actions">
                            <button className="lc-button bg-info" onClick={() => { confirm() }}>{props.textBtnOk || 'Ok'}</button>
                            <button className="lc-button bg-gray" onClick={() => { setVisible(false); props.close && props.close(); }}>{props.textBtnCancel || 'Cancelar'}</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Confirmation;