import api, { APIClient } from "../api";

export default class BancoDeDadosService extends APIClient {

  constructor(props:any){
    super(props);
  }
  
  //Tabela de unidade de medida de GibiByte para unidades convencionais
  // https://docs.aws.amazon.com/AmazonRDS/latest/APIReference/API_DBSnapshot.html
  // Contents > AllocatedStorage

  public unitMeasurement = [
    { GiB:1 , MB: 1073.74000003476 , GB:1.07374, TB:0.0010737400000347600663}
  ]

  async GetBancoDeDados() {

    const url = this.endpoint + `/api/BancoDeDados/GetBancoDeDados`;

    return api({
        method: 'get',
        url,
       
    }).then((response) => {
        return response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            ...item,
            espacoUtilizado: this.FormatAllocatedStorageToGB(item.espacoUtilizado)
          };
        });
    });

  }  

  FormatAllocatedStorageToGB(originalValue){
    let GBValue = originalValue * this.unitMeasurement[0].GB
    return GBValue.toFixed(2).toString().replace('.',',')
  }

}