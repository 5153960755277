import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { setFilterActive } from '../../../store/reducers/filterReducer';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import exportFromJSON from 'export-from-json'
import { useSnackbar } from 'notistack';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { PPModal } from 'processor-plataform-ui'
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { PiExportLight, PiListLight } from 'react-icons/pi';

const states = [
    { from: '', for: 'Todos', icon: 'lci lci-check', color: 'rgb(129,188,0)' },
    { from: 'Disconnected', for: 'Desconectado', icon: 'lci lci-stop', color: '#dc291e' },
    { from: 'Running', for: 'Online', icon: 'lci lci-play', color: 'rgb(129,188,0)' },
    { from: 'Disabled', for: 'Desabilitado', icon: 'ppi ppi-minus', color: '#BEBEBE' },
    { from: 'Degraded', for: 'Degradado', icon: 'lci lci-support', color: '#BEBEBE' },
    { from: 'Pending Reboot', for: 'Reinicialização pendente', icon: 'lci lci-information-circle', color: '#f3c944' },
    { from: 'Isolated', for: 'Isolado', icon: 'lci lci-radiobtn', color: '#BEBEBE' },
    { from: 'Expired', for: 'Expirado', icon: 'lci lci-exclamation-circle', color: 'rgb(224,11,0)' },
    { from: 'Migrated', for: 'Migrado', icon: 'ppi ppi-check', color: 'rgb(129,188,0)' },
    { from: 'Pending Migration', for: 'Migração pendente', icon: 'lci lci-clock', color: 'rgb(255,164,0)' },
]

const EndpointDetectionResponseCollectors: React.FC = (props) => {

    const endpointDetectionResponseService = new EndpointDetectionResponseService(props);
    const [filteredCollectors, setFilteredCollectors] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedDevice, setSelectedDevice] = useState<any>(undefined);
    const [collectors, setCollectors] = useState<any[]>([]);
    const [modalDetails, setModalDetails] = useState<boolean>(false);
    const filter :{ size: number, term: string, column: string, direction: string } ={ size: 1, term: "", column: "", direction: "asc" }
    const [filterVisible, setFilterVisible] = useState(false);
    const [collectorsLength, setCollectorsLength] = useState<number>(0);
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //Filter states
    // filter state,name device,deviceGroup
    const [filter_state, setFilter_state] = useState<string>('');
    const [filter_name, setFilter_name] = useState<string>('');
    const [filter_deviceGroup, setFilter_deviceGroup] = useState<string>('');
    const [groupAgroup, setGroupAgroup] = useState<string[]>([])
    const noDeviceLabel = "(Grupo não informado)";
    //Running, Disconnected, Disabled, Degraded, Pending Reboot, Isolated, Expired, Migrated or Pending Migration. 

    const reasonState_Classification = (row: any) => {
        if (row && row.state)
            switch (row.state.toLowerCase()) {
                case 'disconnected':
                    return '#dc291e';
                case 'running':
                    return '#81bc00';
                case 'disabled':
                    return '#b3b3b3';
                case 'degraded':
                    return '#ffa400';
                case 'pending reboot':
                    return '#d7af64';
                case 'isolated':
                    return '#b3b3b3';
                case 'expired':
                    return '#e14940';
                case 'migrated':
                    return '#94c626';
                case 'pending migration':
                    return '#ffb226';
                default:
                    return '#b3b3b3';
            } else return '#b3b3b3';
    }

    const groupByField = (array, campo) => {
        return array.reduce((grupos, objeto) => {
            const valorCampo = objeto[campo] || 'Grupo Indefinido'; // Ajuste para lidar com valores nulos/undefined
            grupos[valorCampo] = grupos[valorCampo] || [];
            grupos[valorCampo].push(objeto);
            return grupos;
        }, {});
    };

    const getCollectorsCSV = () => {
        enqueueSnackbar(`Gerando arquivo`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        })

        if (user.ClientGroupSelected) {
            endpointDetectionResponseService &&
                endpointDetectionResponseService.GetCollectorsCSV(filter_state, filter_name, filter_deviceGroup)
                    .then(response => {
                        const csvRows = response.data.split("|EndLine|");

                        const csvHeader = csvRows.shift();

                        const statusMapping = {
                            "Running": "Online",
                            "Degraded": "Degradado",
                            "Disabled": "Desabilitado",
                            "Disconnected": "Desconectado",
                            "Pending Reboot": "Reinicialização pendente",
                            "Isolated": "Isolado",
                            "Expired": "Expirado",
                            "Migrated": "Migrado",
                            "Pending Migration": "Migração pendente"
                        }

                        const csvData = csvRows.map((csvRow) => {
                            const fields = csvRow.split("||");
                            return {
                                "Grupo": fields[0],
                                "Nome do dispositivo": fields[1],
                                "SO": fields[2],
                                "Último login": fields[3],
                                "Status": statusMapping[fields[4]],
                                "Atualizado há": fields[5],
                            }
                        })

                        const data = csvData;
                        const fileName = 'Coletores';
                        const exportType = 'csv';
                        const withBOM = true;
                        const fields = csvHeader.split("||");
                        exportFromJSON({ data, fields, fileName, exportType, withBOM, delimiter: ';' });

                        enqueueSnackbar(`Arquivo gerado com sucesso`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        })
                    })
        }
    }

    const columns = [
        {
            field: 'collectorGroupName', headerName: 'Grupo', width: "20%", sort: false, truncate: true,
        },
        {
            field: 'name', headerName: 'Nome do dispositivo', width: "20%", sort: false, truncate: true,
        },
        {
            field: 'operatingSystem', headerName: 'SO', width: "20%", sort: false, truncate: true,
        },
        {
            field: 'loggedUsers',
            headerName: 'Último login',
            sort: false,
            width: "14%",
            renderCell: (row: any) => {
                let lastUser = row.loggedUsers[(row.loggedUsers.length - 1)];
                const text = lastUser ? lastUser : row.loggedUsers;                
                return <span title={text} className='ellipsis'>
                    {text}
                </span>
            }
        },
        {
            field: 'state', headerName: 'Status', width: "12%", overflow: "visible", sort: false, renderCell: (row: any) => {
                if (row && row.state) {
                    let state = states.find(x => x.from === row.state)
                    return state && state.for;
                } else {
                    return "";
                }
            }
        },
        {
            field: 'lastSeenTime', headerName: 'Atualizado há', width: "15%", sort: false,
            renderCell: (row: any) => {
                if (row && row.lastSeenTime) {
                    const hour = row.lastSeenTime.split(' ')[1]
                    const date = row.lastSeenTime.split(' ')[0].split('-')
                    return <span>{date[2]}/{date[1]}/{date[0]} {hour}</span>
                }
                return "";
            }
        },
        {
            field: 'id', headerName: '', width: "38px", overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return <LcIconLink icon={<PiListLight />} size='small' tooltipPosition='inline-left' tooltip="Detalhes" onClick={() => { setSelectedDevice(row); setModalDetails(true); }} />
            }
        }
    ];

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const filterAdvanced = {
        fields: [
            { label: 'Nome do Grupo', name: 'groupName', type: 'text' },
            { label: 'Nome do dispositivo', name: 'deviceName', type: 'text' },
            { label: 'Status', name: 'status', type: 'select', options: states.map(e => { return { label: e.for, value: e.from } }) },
        ],
        onChange: (_filter: any, size: number) => {
            // setFilter({...filter, size: size, term: _filter[0].value.toLowerCase()})

            setCollectors([])
            setFilteredCollectors([])

            let deviceName = _filter[0].value ? _filter[0].value : '';
            let groupName = _filter[1].value ? _filter[1].value : '';
            let status = _filter[2].value ? _filter[2].value : '';

            setFilter_state(status)
            setFilter_name(deviceName)
            setFilter_deviceGroup(groupName)
            user.refreshFlag = Math.random();
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading} loadingType='Helix' >
                        <LcInfiniteTable
                            groupName={groupAgroup}
                            loading={isLoading}
                            columns={columns}
                            rows={filteredCollectors}
                            size={collectorsLength}
                            disableFilterModal={true}
                            notLoadMore={true}
                            status={reasonState_Classification}
                            density={pref.data?.density || "high"}
                            tooltipContentColumn='stateTranslated'
                        />

                    </LcLoading>
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (collectors.length > 0) {
            let filtered = collectors.filter(collector => {
                const matchesName = filter_name ? collector.name?.toLowerCase().includes(filter_name.toLowerCase()) : true;
                const matchesGroup = filter_deviceGroup ? collector.collectorGroupName?.toLowerCase().includes(filter_deviceGroup.toLowerCase()) : true;
                const matchesState = filter_state ? collector.state?.toLowerCase() === filter_state.toLowerCase() : true;
                return matchesName && matchesGroup && matchesState;
            }).sort((a: any, b: any) => {
                if (filter.direction === 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

            if (filtered.length !== collectors.length) {
                isFilterActive = true;
            }

            if (filtered.length === 0) {
                setGroupAgroup([]);
                setFilteredCollectors([]);
                setCollectorsLength(0);
            } else {
                let colectoresGroup = groupByField(filtered, 'collectorGroupName');
                let _grupos = Object.keys(colectoresGroup).sort((a, b) => a < b || b === noDeviceLabel || a === noDeviceLabel ? -1 : 1);;

                setGroupAgroup(_grupos);
                setFilteredCollectors([colectoresGroup]); // Certifique-se de que está passando um array de grupos corretamente
                setCollectorsLength(filtered.length);
            }

            dispatch(setFilterActive(isFilterActive));            
        }

    }, [collectors, dispatch, filter_name, filter_deviceGroup, filter_state, filter.direction, filter.column]);

    const handleFilterChange = (updatedFilters) => {
        // Atualiza os estados com os valores dos filtros
        const deviceNameFilter = updatedFilters.find(f => f.name === 'deviceName')?.value || '';
        const groupNameFilter = updatedFilters.find(f => f.name === 'groupName')?.value || '';
        const statusFilter = updatedFilters.find(f => f.name === 'status')?.value || '';

        if (deviceNameFilter !== filter_name) setFilter_name(deviceNameFilter);
        if (groupNameFilter !== filter_deviceGroup) setFilter_deviceGroup(groupNameFilter);
        if (statusFilter !== filter_state) setFilter_state(statusFilter);

    };

    useEffect(() => {
        const loadData = () => {
        setIsLoading(true);
        const endpointDetectionResponseService = new EndpointDetectionResponseService({});
        endpointDetectionResponseService.GetCollectors(filter_state, filter_name, filter_deviceGroup)
            .then(response => {
                console.log("Dados recebidos do serviço: ", response.data);

                // Mapeia e adiciona a tradução dos estados antes de definir os estados
                const _collectors = response.data.map((item) => {
                    const stateInfo = states.find(state => state.from === item.state);
                    return {
                        ...item,
                        stateTranslated: stateInfo ? stateInfo.for : item.state,
                    };
                }).sort((a, b) => a.collectorGroupName > b.collectorGroupName ? 1 : -1);

                const _groupedCollectors = groupByField(_collectors, 'collectorGroupName');
                const _groups = Object.keys(_groupedCollectors)
                    .sort((a, b) => a < b || b === noDeviceLabel || a === noDeviceLabel ? -1 : 1);

                setCollectors(_collectors);
                setGroupAgroup(_groups);
                setFilteredCollectors(Object.values(_groupedCollectors));
                setCollectorsLength(_collectors.length);
            })
            .catch(error => {
                console.error(error);
            }).finally(() => setIsLoading(false) );
        };
        loadData()
    }, [filter_deviceGroup, filter_name, filter_state, user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        if (collectors.length > 0) {
            applyFilter();
        }
    }, [applyFilter, collectors]);
    
    useEffect(() => {
        // Esta função é chamada quando o componente é desmontado
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    return (
        <Layout
            // periodSelection={periodSelection}
            pageTitle="Status dos dispositivos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            functionsGeneric={[{
                icon: <PiExportLight />,
                tooltip: 'Exportar CSV',
                onClick: () => getCollectorsCSV()
            }]}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: collectorsLength
            }}
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />

            <PPModal title={`Detalhes ${selectedDevice?.name}`} onClose={() => { setModalDetails(false) }} visible={modalDetails} >
                <div className="mt-3"><b>IP:</b> {selectedDevice?.ipAddress}</div>
                <div className="mt-3"><b>Endereço MAC:</b> {selectedDevice?.macAddresses}</div>
                <div className="mt-3"><b>Versão:</b> {selectedDevice?.version}</div>
            </PPModal>
        </Layout>
    );
};

export default EndpointDetectionResponseCollectors;