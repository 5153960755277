import React, { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Button, CircularProgress } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { SaaSSubscriptionCostCenterParameters, TechEAEASService } from '../../../../services/EASubscriptionService/techEAEASService';
import { PPInput } from 'processor-plataform-ui';
  // Import your custom input component

const EAParametersForm: React.FC = () => {
    const queryClient = useQueryClient();
    const { control, handleSubmit, reset } = useForm<SaaSSubscriptionCostCenterParameters>();
    const techEAEASService = new TechEAEASService({});

    const { data, error, isLoading } = useQuery('saasSubscriptionParameter', () => techEAEASService.fetchSaaSSubscriptionParameters(), {
        onSuccess: (data) => reset(data),
    });

    const mutation = useMutation((params: SaaSSubscriptionCostCenterParameters) => techEAEASService.saveSaaSSubscriptionParameters(params), {
        onSuccess: () => {
            queryClient.invalidateQueries('saasSubscriptionParameter');
            alert('Salvo com sucesso');
        },
    });

    const onSubmit = (data: SaaSSubscriptionCostCenterParameters) => {
        mutation.mutate(data);
    };

    if (isLoading) return <CircularProgress />;
    if (error) return <div>Erro ao carregar dados</div>;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="customField"
                control={control}
                render={(field) => <PPInput {...field} type="text" placeholder="Campo Personalizado" />}
            />
            <Controller
                name="alternateCountryField"
                control={control}
                render={(field) => <PPInput {...field} type="text" placeholder="Campo Alternativo de País" />}
            />
            <Controller
                name="alternateCountryFieldRegex"
                control={control}
                render={(field) => <PPInput {...field} type="text" placeholder="Regex do Campo Alternativo de País" />}
            />
            <Controller
                name="alternateRegionField"
                control={control}
                render={(field) => <PPInput {...field} type="text" placeholder="Campo Alternativo de Região" />}
            />
            <Controller
                name="alternateRegionFieldRegex"
                control={control}
                render={(field) => <PPInput {...field} type="text" placeholder="Regex do Campo Alternativo de Região" />}
            /> 
            <div className="commands">
                <button className="lc-button bg-primary" type="submit">Salvar</button> 
            </div>
        </form>
    );
};

export default EAParametersForm;
