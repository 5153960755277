import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { WebSitesService } from '../../../services/websites/index';
import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import Moment from 'moment';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import GaugeChart from '../../../components/Layout/Dashboard/gaugeChart';
import LcLoading from '../../../components/Generic/LcLoading';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import GaugeChartCore from '../../../components/Layout/Dashboard/gaugeChartCore';
import { FinOpsChartService } from '../../../services/finOps/finOpsChartService';
import ReactECharts from 'echarts-for-react';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import LcNoData from '../../../components/Generic/LcNoData';


interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

const LiveWatchResumo: React.FC = (props) => {
    const webSitesService = new WebSitesService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const finOpsChartService: FinOpsChartService = (new FinOpsChartService(props));
    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>();
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>();
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>();
    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(Moment().format('M'));
    const [year, setYear] = useState<string>(Moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const [hostData, setHostData] = useState<{ host: number, contratado: number }>({ host: 0, contratado: 0 })
    const [isLoadingHostData, setIsLoadingHostData] = useState<boolean>(false)

    useEffect(() => {
        setSloChart(0);
        setSlaChart(0);
        setIsLoadingHostData(true)
        setIsLoadingTotalChamadosChart(true)
        setIsLoadingChamadosPorOrigemChart(true)
        setIsLoadingChamadosChart(true)
        if (user.ClientGroupSelected) {

            setLoading(true);
            webSitesService.GetCards(month, year)
                .then(response => {
                    if (response.data != null) {
                        setSloChart(response.data.slaConclusao == 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));
                        setSlaChart(response.data.slaAtendimento == 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));

                        let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                            (response.data.totalChamadosHC + 10)
                            : (response.data.totalChamadosFHC + 10);

                        let chamadosChart: DoughnutChartValue = {
                            title: 'Chamados por horário',
                            labels: [],
                            values: []
                        }
                        let newChamadosChart: any = [];

                        if (response.data.totalChamadosHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosHC);
                            chamadosChart.labels.push('Comercial');
                            let comercial = {
                                value: response.data.totalChamadosHC,
                                name: "Comercial",
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosChart.push(comercial)
                        }

                        if (response.data.totalChamadosFHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosFHC);
                            chamadosChart.labels.push('Não comercial');
                            let notComercial = {
                                value: response.data.totalChamadosFHC,
                                name: "Não comercial",
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosChart.push(notComercial)
                        }
                        setChamadosChart(newChamadosChart);
                        setIsLoadingChamadosChart(false)

                        let totalChamadosChart: DoughnutChartValue = {
                            title: 'Chamados por situação',
                            labels: [],
                            values: []
                        }
                        let newTotalChamadosChart: any = []

                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                            totalChamadosChart.labels.push('Abertos');
                            let open = {
                                value: response.data.totalChamadosAbertas,
                                name: 'Abertos',
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newTotalChamadosChart.push(open);
                        }

                        if (response.data.totalChamadosFechadas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                            totalChamadosChart.labels.push('Fechados');
                            let closed = {
                                value: response.data.totalChamadosFechadas,
                                name: 'Fechados',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newTotalChamadosChart.push(closed);
                        }

                        if (response.data.totalChamadosCanceladas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                            totalChamadosChart.labels.push('Cancelados');
                            let cancel = {
                                value: response.data.totalChamadosCanceladas,
                                name: 'Cancelados',
                                itemStyle: {
                                    color: '#804e3b'
                                }
                            }
                            newTotalChamadosChart.push(cancel);
                        }

                        setTotalChamadosChart(newTotalChamadosChart);
                        setIsLoadingTotalChamadosChart(false)

                        let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                        let _c = clientName.split(' ')
                        let capitalized = '';
                        _c.map((e: any) => {
                            capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                        })

                        let chamadosPorOrigemChart: DoughnutChartValue = {
                            title: 'Chamados por origem',
                            labels: [],
                            values: []
                        }
                        let newChamadosPorOrigem: any = []
                        if (response.data.totalChamadosAbertosPeloCliente > 0) {
                            chamadosPorOrigemChart.labels.push(capitalized)
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                            let openByClients = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: capitalized,
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosPorOrigem.push(openByClients)
                        }

                        if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                            chamadosPorOrigemChart.labels.push('Processor')
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                            let openByProc = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: 'Processor',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosPorOrigem.push(openByProc)
                        }

                        setChamadosPorOrigemChart(newChamadosPorOrigem)
                        setIsLoadingChamadosPorOrigemChart(false)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                    setIsLoadingChamadosChart(false);
                    setIsLoadingChamadosPorOrigemChart(false);
                    setIsLoadingTotalChamadosChart(false);
                });


            webSitesService.GetLiveWatchCard().then(response => {
                if (response.status == 200) {
                    
                    setHostData(
                        {
                            host: response.data.host,
                            contratado: response.data.contratado
                        }
                    )
                    setIsLoadingHostData(false)
                }
            }).catch(error => {
                setIsLoadingHostData(false)
                console.log(error);
            })
        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {
        let momentDate = Moment(selected.fields[0].value).format('YYYY-M');
        let date = momentDate;
        let currentDay = '01';
        let currentMonth = date.split('-')[1];
        let currentYear = date.split('-')[0];
        setDay(currentDay);
        setMonth(currentMonth);
        setYear(currentYear);
        setSearchStartDate(`${currentYear}${currentMonth.padStart(2, '0')}${currentDay}`);
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [{ name: 'month', value: Moment().format('YYYY-M') }],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [{ name: 'month', value: Moment().add(-1, 'M').format('YYYY-M') }]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [{ name: 'month', value: Moment().add(-2, 'M').format('YYYY-M') }]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [{ name: 'month', label: 'Mês', value: '' }]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    }
    const card = [
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 1, col: 1 },
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Consumo</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando..." loading={isLoadingHostData}>
                        <IconValue
                            icon="lci lci-desktop-computer"
                            value={`${hostData.host} de ${hostData.contratado}`}
                            color="#8684EF"
                            description={"Consumidos / Contratados"}
                            />
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 1, col: 5 },
            showFocusButton: false,
            title: () => "SLA de atendimento",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChartCore value={slaChart} title="SLA de atendimento" style={{ maxHeight: '200px', width: '100%' }}/>
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 1, col: 9 },
            showFocusButton: false,
            title: () => "SLO de conclusão",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChartCore value={sloChart} title="SLO de conclusão" style={{ maxHeight: '200px', width: '100%' }} />
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 3, col: 1},
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por horário</div>,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosChart}>
                        {
                             chamadosChart ?
                            <ReactECharts
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={finOpsChartService.getDougnutChartWithLegend(chamadosChart)}
                            />
                            :
                            <LcNoData/>

                        }
                        {/* <DoughnutChart fullHeigth data={chamadosChart}></DoughnutChart> */}
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 3, col: 5 },
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por situação</div>,
            
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingTotalChamadosChart}>
                        {
                            totalChamadosChart ?
                          <ReactECharts
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={finOpsChartService.getDougnutChartWithLegend(totalChamadosChart)}
                            />
                            :
                            <LcNoData/>
                        }
                        { /* <DoughnutChart data={totalChamadosChart}></DoughnutChart> */}
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9 },
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por origem</div>,

            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosPorOrigemChart}>
                        {
                            chamadosPorOrigemChart?
                            <ReactECharts
                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                    option={finOpsChartService.getDougnutChartWithLegend(chamadosPorOrigemChart)}
                                />
                                :
                            <LcNoData/>
                        }
                        {/* <DoughnutChart data={chamadosPorOrigemChart}></DoughnutChart> */}
                    </LcLoading>
                )
            }
        },
    ]

    return (
        <Layout pageTitle="Resumo"  periodSelection={periodSelection}>
               <LCDashboard cards={card} rightSidePanel={{
                title: '',
                content: () => '',
                pinned: false,
                show: false
            }} /> 
        </Layout>
    );
}

export default LiveWatchResumo;