import React, { useCallback, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';
import { FinOpsChartBuilder } from '../../../services/finOps/FinOpsChartBuilder';
import LcLoading from '../../../components/Generic/LcLoading';
import history from '../../../history';
import { CostCenterService } from '../../../services/costCenter/costCenterService';
import { CostCenterNode } from '../../costCenter';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { costCenterChartPalette } from '.';

interface CostCenterBudgetChartProps {
    kpi: any,
    setPageExitConfirmation: (open: boolean) => void,
    budgetCostCenter?:any,
    isLoading:  boolean,
    loadingCards: boolean[]
}

export const CostCenterBudgetChart: React.FC<CostCenterBudgetChartProps> = (
    {   
        kpi,
        budgetCostCenter,
        loadingCards,
        setPageExitConfirmation,
        isLoading

    }) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const finOpsChartService: FinOpsChartBuilder =new FinOpsChartBuilder({});
    const dispatch = useDispatch();
    const [constCentersTree, setCostCentersTree] = useState<CostCenterNode>();
    
    useEffect(()=> {
        const CenterCosthasChildren = async () => {
            try {
                const service = new CostCenterService({});
                var result = await service.GetTreeData()
                setCostCentersTree(result.data);
            } catch (error) {
                console.error("Error checking for children:", error);
                throw error;
            }
    
        }
        CenterCosthasChildren();
    },[user.ClientGroupSelected, user.refreshFlag])

    const hasChildren = useCallback((tree: CostCenterNode, targetId: number): boolean =>{
        if (tree.id === targetId) {
            return !!tree.children && tree.children.length > 0;
        }
    
        if (tree.children) {
            for (const child of tree.children) {
                if (hasChildren(child, targetId)) {
                    return true;
                }
            }
        }
    
        return false;
    },[])
    
    const handleClickCostCenterBudget = useCallback(async (chartData: any) => {
        if(constCentersTree) {
            let haschildren = hasChildren(constCentersTree, chartData.data.costCenterId);
                dispatch({
                    type: 'ANALYTICAL_FILTER', payload: {
                        id: chartData.data.costCenterId ,
                        costcenter: chartData.data.name,
                        typeParam: 'costcenter_budget',
                        hasConstCenterChildren: haschildren
                    }
                });
            setPageExitConfirmation(true);
        }
    },[constCentersTree, dispatch, hasChildren, setPageExitConfirmation])



    return (
        <LcLoading loading={isLoading}>
            {kpi && kpi.reportCostCenterBudget && (
            <>
                <div className="chart-container">
                    <div className="chart-content">
                        <ReactECharts
                            className='text-cc'
                            style={{ width: '100%', height: '100%' }}
                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                            option={finOpsChartService.getConsumptionCostCenterChart(kpi.reportCostCenterBudget, costCenterChartPalette, kpi, true)}
                            onEvents={{
                                click: handleClickCostCenterBudget
                            }}
                        />
                    </div>
                </div>
                {budgetCostCenter?.length === 1 && (
                    <div className="centralized-row mb-4">  {/* Adicionei um margin-bottom para separar da chart */}
                        <i className="font-7x lci lci-information-circle text-link mr-3"></i>
                        <span className="text-center text-primary font-4x">Cadastre centros de custos e orçamentos
                            <b className="link text-link" onClick={() => { history.push("/finops/costcenter") }}> aqui.</b>
                        </span>
                    </div>
                )}
            </>
            )}
            {!kpi && !loadingCards[3] && <>
                <div className="centralized-row">
                    <i className="font-7x lci lci-exclamation text-warning mr-3"></i>
                    <span className="text-center text-primary font-4x">Não há dados para o período selecionado.</span>
                </div></>
            }
        </LcLoading>
    )
}
