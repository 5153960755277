export class maxsoftware {
    solution() {
        return 'maxsoftware'
    }

    site() {
        return 'https://www.processor.com.br/suporte-tecnico'
    }

    logotipo() {
        return require('./assets/logotipos/logo_maxsoftware.svg').default
    }
    titulo() {
        return <>Maximize a eficiência operacional da organização</>
    }

    chamada() {
        return <>Max Software é a solução de suporte técnico que a organização precisa para manter a operação de TI otimizada e eficiente, focando no que realmente importa: o crescimento do negócio.</>
    }

    image() {
        return require('./assets/imgs/imagem_maxsoftware.png').default
    }

    detalhes() {
        return <>
            <h2>A organização já percebeu que Max Software oferece suporte técnico especializado, capaz de atender às demandas emergenciais e solucionar problemas do dia-a-dia dos ambientes de tecnologia?</h2>
            <p>Com Max Software, a organização conta com uma retaguarda técnica capacitada e multidisciplinar, focada em infraestrutura e software, pronta para atender às demandas e problemas em plataformas tecnológicas de mercado.</p>
            <h3>Por que escolher Max Software?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_maxsoftware.png').default} />
                </div>
                <ul>
                    <li><b>Profissionais qualificados:</b> Equipe técnica capacitada e multidisciplinar à disposição.</li>
                    <li><b>Ampla cobertura de atendimento:</b> Suporte amplo de infraestrutura, aplicações e plataformas tecnológicas.</li>
                    <li><b>Agilidade na solução de problemas:</b> Respostas rápidas e eficazes para manter a operação em pleno funcionamento.</li>
                    <li><b>Diagnósticos precisos:</b> Identificação exata dos problemas para uma solução efetiva.</li>
                    <li><b>Acompanhamento de chamados:</b> Monitoramento constante dos chamados para garantir a resolução.</li>
                    <li><b>Horas de consultoria:</b> Acesso a melhores práticas para possíveis correções no ambiente, evitando ocorrências.</li>
                    <li><b>Ativação de novos serviços:</b> Possibilidade de ativação de novos serviços em formatos modulares com valor diferenciado para clientes do suporte.</li>
                    <li><b>Pacotes de serviços consultivos:</b> Pacotes de serviços consultivos disponíveis para executar manutenção ou melhorias nas aplicações.</li>
                </ul>
            </div>
            <div className="box">Max Software disponibiliza <b>retaguarda técnica capacitada e multidisciplinar</b>, em geral focadas em infraestrutura/software, para o atendimento das demandas e problemas em plataformas tecnológicas de mercado.</div>            
            <h3>Pronto para levar a operação de TI para o próximo nível?</h3>
            <p>Entre em contato conosco hoje mesmo para descobrir como Max Software pode transformar a operação de TI da organização.</p>        
        </>
    }
}
export default maxsoftware;