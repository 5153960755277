import React, { useEffect, useMemo, useState } from 'react';
import { PiDatabase } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { CmsPremierService, DatabaseItem } from '../../../../services/cmsPremier/cmsPremierService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { UserState } from '../../../../store/reducers/userReducer';
import { Tooltip } from '@material-ui/core';

interface DatabasesInfoCardProps {
    setLoading: (loading: boolean) => void
}

export const DatabasesInfoCard: React.FC<DatabasesInfoCardProps> = ({setLoading}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [databasesData, setDatabasesData] = useState<OnOffModel>();
    const [databases, setDatabases] = useState<DatabaseItem[]>([]);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();

    const translationsFilter = useMemo(() => {
        return {
            servidor: {
                label: "Servidor",
                type: "text",
            },
            // dbiResourceId: {
            //     label: "Assinatura",
            //     type: "text",
            // },
            // tipo: {
            //     label: "Tipo",
            //     type: "text",
            // },
            tipoStorage: {
                label: "Tipo de Storage",
                type: "multiSelect",
            },
            status: {
                label: "Situação",
                type: "multiSelect",
                values: {
                    'available': "Disponível",
                    'Ready': "Disponível",
                    'stopped': 'Parado',
                    'starting': 'Inicializando'
                }
            },
            tipoInstancia: {
                label: "Tamanho",
                type: "multiSelect",
                topDropdown: true
            },
            regiao: {
                label: "Região",
                type: "multiSelect",
                topDropdown: true
            },
        };
    },[])
    const columnsDatabases = useMemo(() => {
    return  [
            {
                field: 'servidor', headerName: 'Servidor', width: "30%", renderCell:(row: any) => {
                    return(
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                            <Tooltip arrow title={row.provider !== 'awsdb' ? 'Microsoft Azure' : 'Amazon AWS'}>
                                <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                    {row.provider !== 'awsdb' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                                </span>
                            </Tooltip>
                            <span
                                className='overflowEllipsis ml-4'
                            >
                                {row.servidor}
                            </span>
                        </div>
                    )
                }
            },
            // {
            //     field: 'dbiResourceId', headerName: 'Id do recurso', width: "25%",
            // },
            {
                field: 'tipo', headerName: 'Tipo', width: "20%",
            },
            {
                field: 'status', headerName: 'Situação', width: "15%",
                renderCell: (row: any) => {
                    const situacaoMap = {
                        'available': <p className='text-green'>Disponível</p>,
                        'Ready': <p className='text-green'>Disponível</p>,
                        'Online': <p className='text-green'>Online</p>,
                        'stopped': <p className='text-danger'>Parado</p>,
                        'starting': <p className='text'>Inicializando</p>
                    };
                    return situacaoMap[row.status] || row.status;
                }
            },
            {
                field: 'tipoStorage', headerName: 'Tipo Storage', width: "10%",
            },
            {
                field: 'tipoInstancia', headerName: 'Tamanho', width: "15%",
            },
            {
                field: 'regiao', headerName: 'Região', width: "8%",
            },
        ] 

    },[])

    useEffect(() => {
        const getDatabasesData = async () => {
            try {
                const CmsService = new CmsPremierService({})
                const databasesFull = await CmsService.getDatabasesFull()
                let dataBaseList = addProviderToItems(databasesFull)
                setDatabases(removeDuplicatesByServer(dataBaseList))
                const result = removeDuplicatesByServer(dataBaseList).reduce(
                    (acc, curr) => {
                        if (curr.dbInstanceStatus === 'available') {
                            acc.on += 1;
                        } else {
                            acc.off += 1;
                        }
                        return acc;
                    },
                    { on: 0, off: 0 }
                );
                setDatabasesData(result);
            } catch (error) {
                console.error("Error:", error);
            } finally{
                setIsLoading(false)
            }
        }
        getDatabasesData()
    },[ user.ClientGroupSelected, user.refreshFlag])

    const addProviderToItems = (data: { [key: string]: any[] }) => {
        return Object.keys(data).flatMap(provider => 
            data[provider].map(item => {
            const { dbInstanceStatus, ...rest } = item;
                if(provider === 'awsdb') {
                    return {
                        provider, 
                        servidor: item.dbInstanceIdentifier,
                        status: dbInstanceStatus === 'Ready' ? 'available' : dbInstanceStatus,
                        regiao:item.availabilityZone,
                        tipo: item.engine,
                        tipoStorage: item.storageType,
                        tipoInstancia: item.dbInstanceClass,
                    };
                } else {
                    console.log(item)
                    return {
                        provider,
                        status: item.situacao === 'Ready' ? 'available' : item.situacao,
                        ...rest
                    }
                }
            }))
        };

    const removeDuplicatesByServer = (array) => {
            const uniqueServers = new Map();
        
            array.forEach(item => {
                if (!uniqueServers.has(item.servidor)) {
                    uniqueServers.set(item.servidor, item);
                }
            });
        
            return Array.from(uniqueServers.values());
        }

    useEffect(() =>{
        if(cmsEnvironment.enviromentFilter === 'Databases'  && !isLoading) {
            setLoading(true)
            let payload = {
                enviromentFilter: 'Databases',
                columns: columnsDatabases,
                rowsList: databases,
                rowsListFiltered: databases,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter
            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            setLoading(false)
        }
    } ,[cmsEnvironment.enviromentFilter, columnsDatabases, databases, dispatch, setLoading, translationsFilter, isLoading])
    
    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Databases',
            columns: columnsDatabases,
            rowsList: databases,
            rowsListFiltered: databases,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        setLoading(false)

    } 

    return (
        <LcLoading loading={isLoading}  >
            <IconValue 
                icon={<PiDatabase size={28} />}
                value={databasesData ? `${(databasesData?.on + databasesData.off)}` : '0'}
                color="#34c38f"
                description='Ativas'
                onClick={handleClickEnviroment}
                balonSelected={(cmsEnvironment.enviromentFilter === 'Databases')}
            /> 
        </LcLoading>
    );
}