import React from 'react';
import { AiOutlineWarning, AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineReload } from 'react-icons/ai'; // Exemplos com react-icons
import { PiArrowsClockwiseLight, PiInfoLight, PiWarning, PiWarningLight } from 'react-icons/pi';

type LcNoDataProps = {
    label?: string;
    size?: 'xsmall' | 'small' | 'default' | 'large';
    icon?: 'warning' | 'info' | 'error' | 'refresh';
    mode?: 'horizontal' | 'vertical';
    height?: 'full' | '90' | '50';
};

const LcNoData: React.FC<LcNoDataProps> = ({
    label = "Não há informações no período.",
    size = 'default',
    icon = 'warning',
    mode = 'horizontal',
    height = 'full'
}) => {
    const getIcon = () => {
        switch (icon) {
            case 'info':
                return <PiInfoLight className="text-link" />;
            case 'error':
                return <PiWarningLight className="text-danger" />;
            case 'refresh':
                return <PiArrowsClockwiseLight className="text-warning" />;
            case 'warning':
            default:
                return <PiWarningLight className="text-warning" />;
        }
    };

    const getSizeClass = () => {
        switch (size) {
            case 'xsmall':
                return ['font-4x', 'font-2x'];
            case 'small':
                return ['font-6x', 'font-4x'];
            case 'large':
                return ['font-8x', 'font-6x'];
            case 'default':
            default:
                return ['font-7x', 'font-5x'];
        }
    };

    const getHeightClass = () => {
        switch (height) {
            case '90':
                return 'h-90p';
            case '50':
                return 'h-50p';
            case 'full':
            default:
                return '';
        }
    };

    const [iconSize, labelSize] = getSizeClass();
    const marginClass = mode === 'horizontal' ? 'mr-3' : 'mb-3';

    return (
        <div className={`fullspace ${getHeightClass()}`}>
            <div className={mode === 'horizontal' ? 'centralized-row' : 'centralized'}>
                <i className={`${iconSize} ${marginClass}`}>
                    {getIcon()}
                </i>
                <span className={`text-center text-primary ${labelSize}`}>
                    {label}
                </span>
            </div>
        </div>
    );
};

export default LcNoData;
