import { GridCellValue } from "@material-ui/data-grid";
import api, { APIClient } from "../api";
import { palette } from '@material-ui/system';
import Moment from "react-moment";
import React from "react";
import { SecureScore } from "./LCTechModel";
//Assinaturas
export class LCTechService extends APIClient {
    public controllerpath = "/api/LCTech";
    constructor(props: any) {
        super(props);
    }
    public title = 'Assinaturas';
    public cardsItens = [
        { label: 'Subscrições', value: 0 },
        { label: 'Baseada em software', value: 4 },
        { label: 'Domínios', value: 2 },
        { label: 'Baseada em uso', value: 1 },
        { label: 'Software', value: 3 }];
    public cardsUsageItens = [{ label: 'Uso por recurso ', value: 0 }, { label: 'Analítico recurso', value: 1 }];
    async Retrieve(type: number) {
        const url = `${this.endpoint}${this.controllerpath}/Retrieve/${type}?refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url
        });
        return response;
    }
    async RetrieveUsage(type: number, toUpper: boolean, month: string, year: string) {
        const url = `${this.endpoint}${this.controllerpath}/RetrieveUsage?type=${type}&toUpperCase=${toUpper}&month=${month}&year=${year}&refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url
        });
        return response;
    }

    async GetSecureScores(): Promise<SecureScore> {
        const url = `${this.endpoint}${this.controllerpath}/GetSecureScores`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    async GetFraudEventsReportByClientGroupId() {
        const url = `${this.endpoint}${this.controllerpath}/GetFraudEventsReportByClientGroupId`;
        return api({
            method: 'get',
            url,
        });
    }

    getFormatedDate(date: any) {
        var dt = date;
        if (date) {
            return (<div>   <Moment format="DD/MM/YYYY">
                {dt}
            </Moment>    </div>);
        }
        return (<div>   -         </div>);
        //basically you can do all sorts of the formatting and others
    }

    getContractStatusIcon(status: any) {

        if (status === false) {
            return (<i color="error.main" className=" lci lci-exclamation-circle" ><span hidden={true}>0</span></i>);
        } else {
            return (<i color="success.main" className="  ppi ppi-check"><span hidden={true}>1</span></i>);
        }
    };
}
