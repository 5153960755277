import { FilterField } from "../../components/Data/FilterPanel/FilterField";

export interface CMSEnvironmenetState {
    enviromentFilter: string;
    columns: any[],
    rowsList: any[],
    rowsListFiltered: any[],
    funcitonGeneric?: any[],
    translationFilter:any
}
const initialState : CMSEnvironmenetState = {
    enviromentFilter: '',
    columns: [],
    rowsList: [],
    rowsListFiltered: [],
    funcitonGeneric: [],
    translationFilter: {}
}
interface SetCMSEnvironmenetAction {
    type: string;
    payload: Required<CMSEnvironmenetState>;
}
export const CMSEnvironmenetReducer = (
    state:CMSEnvironmenetState = initialState,
    action: SetCMSEnvironmenetAction
) => {
    switch (action.type) {
        case 'CMS_ENVIRONMENT_FILTER': 
            return {
                ...state,
                ...action.payload, // Mescla o payload no estado atual, mantendo o estado imutável
            };
        case 'CMS_ENVIRONMENT_FILTER_ROWS': 
            return {
                ...state,
                rowsListFiltered:action.payload, // Mescla o payload no estado atual, mantendo o estado imutável
            };
        case "RESET_" :
            return {
                ...state,
                ...initialState,
            }
        default:
            return state;
    }
};