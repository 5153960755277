import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { EndpointSecurityService } from '../../../services/eSecurity/endpointSecurityService';
import { HorizontalBar } from 'react-chartjs-2';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

import LcSegment from '../../../components/Generic/LcSegment';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcNoData from '../../../components/Generic/LcNoData';

const EndpointSecurityType: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    const [eventChart, setEventChart] = useState<any>();
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingEventChart, setIsLoadingEventChart] = useState(true);

    //Eventos
    const [events, setEvents] = useState<object[]>([]);
    const [eventsFiltered, setEventsFiltered] = useState<object[]>([]);
    const [eventsVisible, setEventsVisible] = useState<object[]>([]);

    const functionsGeneric = [
        {
            id: '9e73a54d-2c40-4408-b8be-706963c12ae9',
            icon: 'ppi ppi-arrow-left',
            tooltip: 'Voltar',
            func: () => {
                setEventsFiltered([]);
                setEventsVisible([]);
            }
        }
    ]

    const onSortChange = (data: any) => {
        const { size, sort } = data;

        setEventsVisible([]);
        setEventsFiltered(eventsFiltered.sort((a: any, b: any) => {

            return sort.direction == 'asc' ?
                a[sort.column] > b[sort.column] ? 1 : -1
                :
                a[sort.column] > b[sort.column] ? -1 : 1
        }))
        setEventsVisible(eventsFiltered.slice(0, size));
    }

    // const filter = {
    //     fields: [
    //         { label: 'Termo', name: 'term', type: 'text' }
    //     ],
    //     onChange: (f: any, h: number) => {

    //         let term = f[0].value.toLowerCase();

    //         setEventsVisible(eventsFiltered.filter((e: any) => {
    //             return (e.name ? e.name.indexOf(term) > -1 : false) ||
    //                 (e.deviceName ? e.deviceName.indexOf(term) > -1 : false) ||
    //                 (e.message ? e.message.indexOf(term) > -1 : false);
    //         }))

    //         //setEventsVisible(eventsFiltered.slice(0, h));
    //     }
    // }

    const columnsEvent = [
        {
            field: 'dateTime',
            headerName: 'Data e Hota',
            width: '10%',
            renderCell: (row: any) => {
                return new Date(row.dateTime).toLocaleDateString();
            }
        },
        {
            field: 'name',
            headerName: 'Evento',
            width: '15%',
        },
        {
            field: 'deviceName',
            headerName: 'Dispositivo',
            width: '15%',
        },
        {
            field: 'message',
            headerName: 'Mensagem',
            width: '60%',
            renderCell: (row: any) => {
                return <small>{row.message}</small>
            }
        },
    ]

    const loadMore = (size: number) => {
        if (size) {
            setEventsVisible(eventsFiltered.slice(0, size));
        } else {
            setEventsVisible([...eventsVisible, ...eventsFiltered.slice(eventsVisible.length, eventsVisible.length + 10)]);
        }
    }

    const GetEventsChartLabels = (eventGraphData: []) => {

        return eventGraphData.map((d: any) => {
            switch (d.name) {
                case 'AGENT FRAMEWORK':
                    return 'Eventos de atualização'
                case 'DEVICE CONTROL':
                    return 'Controle de dispositivos'
                case 'MALWARE PROTECTION':
                    return 'Proteção contra malware'
                case 'NETWORK IPS':
                    return 'Proteção contra intrusão'
                case 'FIREWALL':
                    return 'Firewall'
                default:
                    return d.name;
            }
        })
    }

    const handleClickChart = (model: any) => {
        switch (model.label) {
            case 'Eventos de atualização':
                setEventsFiltered(events.filter((e: any) => e.name == 'AGENT_FRAMEWORK'));
                break;
            case 'Controle de dispositivos':
                setEventsFiltered(events.filter((e: any) => e.name == 'DEVICE_CONTROL'));
                break;
            case 'Proteção contra malware':
                setEventsFiltered(events.filter((e: any) => e.name == 'MALWARE_PROTECTION'));
                break;
            case 'Firewall':
                setEventsFiltered(events.filter((e: any) => e.name == 'FIREWALL'));
                break;
            case 'Proteção contra intrusão':
                setEventsFiltered(events.filter((e: any) => e.name == 'NETWORK_IPS'));
                break;
        }
    }

    const eventChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                barPercentage: 0.8,
                categoryPercentage: 1,
                gridLines: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: true,
                    mirror: true,
                    fontSize: 16,
                    padding: -10
                }
            }],
            xAxes: [{
                gridLines: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: false
                }
            }]
        },
        legend: {
            display: false
        },
        title: {
            display: false,
        },
        onClick: function (evt, element) {
            element[0] && element[0]._model &&
                handleClickChart(element[0]._model);
        }
    }

    const endpointSecurityService = new EndpointSecurityService(props);

    useEffect(() => {
        setIsLoadingEventChart(true);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const searchDate = [year, month].join('');


            //Cards
            endpointSecurityService.GetCards(searchDate)
                .then(response => {
                    if (response.data) {
                        let eventChart = {
                            labels: GetEventsChartLabels(response.data.eventsGraph),
                            datasets: [
                                {
                                    data: response.data.eventsGraph.map((e: any) => {
                                        return e.count;
                                    }),
                                    backgroundColor: ["rgba(0, 117, 219, 0.6)", "rgba(255, 65, 54, 0.6)", "rgba(46, 204, 64, 0.6)",
                                        "rgba(255, 133, 27, 0.6)", "rgba(127, 219, 255, 0.6)", "rgba(177, 13, 201, 0.6)",
                                        "rgba(255, 220, 0, 0.6)", "rgba(0, 31, 63, 0.6)", "rgba(57, 204, 204, 0.6)",
                                        "rgba(1, 255, 112, 0.6)", "rgba(133, 20, 75, 0.6)", "rgba(240, 18, 190, 0.6)",
                                        "rgba(61, 153, 112, 0.6)", "rgba(17, 17, 17, 0.6)", "rgba(170, 170, 170, 0.6)"]
                                }
                            ]
                        };
                        setEventChart(eventChart);
                    }
                })
                .catch(error => {
                    console.warn(error);
                })
                .then(() => {
                    setIsLoadingEventChart(false);
                });

            //Events
            endpointSecurityService.GetEvents(searchDate + '01')
                .then(response => {
                    setEvents(response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag, month, year]);


    return (
        <Layout pageTitle="Tipos de eventos">
            {
                eventsFiltered.length == 0 ?
                    <LcSegment title="Eventos de segurança por tipo" loading={isLoadingEventChart}>
                        {eventChart ?
                            <HorizontalBar width={900} height={300} data={eventChart} options={eventChartOptions}></HorizontalBar>
                            :
                            <LcNoData />
                        }

                    </LcSegment>
                    : <>
                        {
                            eventsFiltered.length > 0 &&
                            <LcInfiniteTable
                                columns={columnsEvent}
                                rows={eventsVisible}
                                loadMore={loadMore}
                                size={eventsFiltered.length}
                                functionGeneric={functionsGeneric}
                                onSortChange={onSortChange}
                            //filter={filter}
                            />
                        }
                    </>
            }
        </Layout>
    );
}

export default EndpointSecurityType;