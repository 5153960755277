import React, { useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import StorageGraph from '../ResumeCSM/StorageGraph';
import moment from 'moment';
import ActivityLog from '../ResumeCSM/ActivityLog';

const ActivityCMS: React.FC<{}> = (props) => {

	const [loading, setLoading] = useState<boolean>(false);
	const [month, setMonth] = useState<string>("");
	const [year, setYear] = useState<string>("");
	const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(moment().date() <= 6 ? "priormonth" : "currentmonth");

	const onChagePeriod = (selected: any) => {
		const firstFieldValue = selected.fields[0]?.value;
		const firstFieldName = selected.fields[0]?.name;

		if (firstFieldName == "month") {
			setYear(firstFieldValue.split("-")[1]);
			setMonth(firstFieldValue.split("-")[0]);
			setTipoDePeriodo(firstFieldName);
		} else {
			setYear("0");
			setMonth("0");
			setTipoDePeriodo(firstFieldValue);
		}
	};

	const periodSelection = {
		periods: [
			{
				label: 'Este mês', text: 'Este mês',
				fields: [
					{ name: 'currentMonth', value: 'currentMonth' }
				],
				default: true
			}
		],
		customPeriodType: 'month',
		onChange: onChagePeriod
	};

	const cards: Card[] = [
		{ // 1. Log de atividades
			id: 1,
			type: "Custom",
			position: { ColSpan: 6, RowSpan: 4, row: 1, col: 1 },
			showFocusButton: true,
			title: () => "Log de atividades",
			customContentRender: (focus) => <ActivityLog focus={focus} />
		},
		{// 2. Acesso aos dados
			id: 2,
			type: "Custom",
			position: { ColSpan: 7, RowSpan: 4, row: 1, col: 7 },
			showFocusButton: true,
			title: () => "Acesso aos dados",
			customContentRender: () => <StorageGraph typePeriod={tipoDePeriodo} month={month} year={year} />
		}
	];

	return (
		<Layout pageTitle="Atividades" loading={loading} periodSelection={periodSelection}>
			<LCDashboard cards={cards} />
		</Layout>
	)
}

export default ActivityCMS;