import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { SaasUserService } from "../../../services/SaasUserService";
import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useDebounce } from 'use-debounce/lib';
import { useSnackbar } from 'notistack';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

const UnusedLicenses: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user)

    const [license, setLicense] = useState<any[]>([]);
    const [filteredLicenses, setFilteredLicenses] = useState<any[]>([]);
    const [visibleFilteredLicenses, setVisibleFilteredLicenses] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ValueSearch, setValueSearch] = React.useState<string>('');
    const [ValueSearchDebounce] = useDebounce(ValueSearch, 500);

    const [days, setDays] = useState<number>(7)
    const [filter, setFilter] = useState<{size: number, term: string, column: string, direction: string}>({size: 20, term: "", column: "", direction: "asc"});

    const successMessage = async (action: string) => {

        enqueueSnackbar(`${action}`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        })
    }

    const RequestMessage = async (action: string) => {

        enqueueSnackbar(`${action}`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        })
    }

    const errorMessage = async (action: string) => {
        enqueueSnackbar(`${action}`, {
            variant: 'error',
            preventDuplicate: true,
            persist: false,
        })
    }

    useEffect(() => {
        async function executeUseEffect() {
            setIsLoading(true);
            setFilteredLicenses([]);
            let localLicense: any[] = [];

            const service = new SaasUserService(props);
            RequestMessage("Recolhendo dados de licenciamento, pode levar alguns segundos ");
            service.GetUnusedLicenses(days).then(result => {
                let licenses: any[] = [];
                result.data.map((license: { licenseName: any; licenseCount: any }) => {
                    licenses.push({
                        id: license.licenseName + "_" + license.licenseCount,
                        licenseName: license.licenseName,
                        licenseCount: license.licenseCount
                    });
                })
                setLicense(licenses);
                setFilteredLicenses(licenses);
                setIsLoading(false);
            }).catch(error => {
                let message = error;
                message = error && error.response && error.response;
                message = error && error.response && error.response.data && error.response.data;
                errorMessage(`Erro ao buscar dados de licença ${message}`);
                console.log(error)
            });
        }

        executeUseEffect();

    }, [days, user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => { }, [ValueSearchDebounce, filteredLicenses])

    useEffect(() => {
        var filtered = license.filter((p: any) =>
            filter.term == '' ||
            p.licenseName.toLowerCase().indexOf(filter.term) > -1 ||
            p.licenseCount.toString().toLowerCase().indexOf(filter.term) > -1
        )
        .sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredLicenses(filtered);
        setVisibleFilteredLicenses(filtered.slice(0, filter.size));
    }, [filter, filteredLicenses.length > 0]); 


    const columns = [
        { field: 'licenseName', headerName: 'Software', width: "85%" },
        { field: 'licenseCount', headerName: 'Quantidade', width: "15%" },
    ]

    function filterUser(field: any) {

        let arrayFiltered: any[] = [];
        arrayFiltered = license.filter(item => item.licenseName.toLowerCase().indexOf(field.toLowerCase()) > -1);
        setFilteredLicenses(arrayFiltered);
    }

    const onSearch = {
        inputType: "text",
        eventOnSearch: (field: any) => {
            filterUser(field)
        }
    }

    const filterAdvanced = {
        fields: [
            { label: 'Assunto', name: 'assunto', type: 'text' }
        ],
        onChange: (_filter: any, size: number) => setFilter({...filter, size: size, term: _filter[0].value.toLowerCase()})
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({...filter, column: sort.column, direction: sort.direction, size: size})
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleFilteredLicenses(filteredLicenses.slice(0, size))
        else {
            setVisibleFilteredLicenses([...visibleFilteredLicenses, ...filteredLicenses.slice(visibleFilteredLicenses.length, visibleFilteredLicenses.length + 10)])
        }
    };

    return (
        <Layout pageTitle="Software não alocado"  >
            <LcInfiniteTable
                loading={isLoading}
                columns={columns}
                rows={visibleFilteredLicenses}
                filter={filterAdvanced}
                size={filteredLicenses.length}
                loadMore={loadMore}
                onSortChange={onSortChange} />
        </Layout>
    )
}
export default UnusedLicenses;