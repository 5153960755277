import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LcTable from '../../../components/Data/LcTable';
import Layout from '../../../components/Layout/Layout';
import { PPIconLink, PPTooltip, PPModal } from 'processor-plataform-ui';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PrefState } from '../../../store/reducers/prefReducer';
import FinOpsMapManagerService from '../../../services/finOpsManagerService';
import { UserState } from '../../../store/reducers/userReducer';
import PPExpand from '../../../components/Generic/PPExpand';

import ReactECharts from 'echarts-for-react';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import { Filter } from '../../accessProfile/main';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import FilterPanel from '../../../components/Data/FilterPanel';
import { PiClockClockwiseLight, PiEye, PiEyeLight } from 'react-icons/pi';

const AlertasNotificacao: React.FC = (props) => {
    const dispatch = useDispatch();
    const pref = useSelector<RootState, PrefState>(state => state.pref);

    const user = useSelector<RootState, UserState>((state) => state.user);

    const [modalInfos, setModalInfos] = useState<any | undefined>();

    const [modalVisible, setModalVisible] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);

    const [filterVisible, setFilterVisible] = useState(false);
    const finOpsMapManagerService = new FinOpsMapManagerService(props);
    const [notificacoes, setNotificacoes] = useState<any[]>([]);
    const [notificacoesAux, setNotificacoesAux] = useState<any[]>([]);
    const [history, setHistory] = useState<any[]>([]);
    const [filter, setFilter] = useState<Filter>({ size: 25, term: "", column: "alertDate", direction: "asc", isTemplate: '' });
    const [filterHistory, setFilterHistory] = useState<Filter>({ size: 25, term: "", column: "", direction: "", isTemplate: '' });
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState<boolean>(false);

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    useEffect(() => {
        var filtered = notificacoesAux.filter((p: any) =>
            filter.term === '' ||
            p.alertDescription.toLowerCase().indexOf(filter.term) > -1
        ).sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] < b[filter.column]) ? 1 : -1;
            else return (a[filter.column] < b[filter.column]) ? -1 : 1;
        });
        setNotificacoes(filtered);
    }, [filter, notificacoes.length > 0]);

    useEffect(() => {
        let filtered = history.filter((p: any) =>
            filter.term === ''

        ).sort((a, b) => {
            const dateA: any = new Date(a.dataDoAlerta);
            const dateB: any = new Date(b.dataDoAlerta);
            if (filterHistory.direction === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        setHistory(filtered)
    }, [filterHistory, history.length > 0]);

    const getTranslation = (key) => {
        const translations = {
            "variacao": "variação",
            "count": "contagem de recurso",
            "budget": "orçamento"
        };
        return translations[key] || 'N/A';
    };

    useEffect(() => {
        async function loadData() {
            setLoading(true);
            try {
                var response = await finOpsMapManagerService.LoadProcessedAlertApproved();
                //debugger;
                var list = response.alerts;
                var notificationList = [];
                if (list.filter(x => x.achieved == true).length != 0) {
                    notificationList = list.filter(x => x.achieved == true).map(c => {
                        var recordResult = {} as any;
                        try {
                            recordResult.id = c.id;
                            recordResult.alertIcon = c.conditions[0].condition == 'budget' ?
                                'ppi ppi-bell' :
                                c.conditions[0].comparator == 'mais' ?
                                    'ppi ppi-arrow-up' :
                                    'ppi ppi-arrow-down'
                            recordResult.alertClientGroup = { id: response.clientGroupId, description: 'Processor' };
                            recordResult.alertDescription = c.description;
                            recordResult.alertDate = c.date ? c.date : moment().toISOString();

                            recordResult.alertCostCenter = c.costCenters;
                            recordResult.alertContracts = c.contracts;
                            recordResult.alertResources = c.resources;
                            recordResult.alertServices = c.services;

                            recordResult.alertPriorPeriodTotal = c.priorPeriodTotal.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                            recordResult.alertPeriodTotal = c.periodTotal.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                            recordResult.alertAchieved = c.achieved;
                            recordResult.alertDifference = c.difference;
                            recordResult.budgetLimit = c.budgetLimit;
                            if (c.conditions && c.conditions.length > 0) {
                                recordResult.alertVariation = (c.cloudCostEffectiveVariation.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                                recordResult.alertCondition = getTranslation(c.conditions[0].condition);
                                recordResult.alertPercentage = (c.conditions[0] ? '+' : '-') + (c.conditions[0].percentage.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                                recordResult.alertCompatator = (c.conditions[0].percentage != 'mais' || c.conditions[0].percentage < 0 ? 'Menos' : 'Mais');
                                if (c.conditions[0].condition != "budget") {
                                    recordResult.alertPeriod = 'Últimos';
                                }
                            }



                            try {
                                if (recordResult.alertCondition != "orçamento") {
                                    recordResult.alertPeriodValue = c.period.periodValue;
                                }

                            } catch (ec) {
                                console.log(ec);
                            }
                            if (recordResult.alertCondition != "orçamento") {
                                recordResult.alertPeriodUnit = 'dias';
                                recordResult.alertPeriodStartDate = moment(new Date()).subtract(c.period.periodValue + 2, "days");
                                recordResult.alertPeriodEndDate = moment(new Date()).subtract(2, "days");
                            }

                            recordResult.alertScope = c.escope;

                            if (c.conditions[0].condition == 'variacao') {
                                recordResult.alertHistory =
                                {
                                    history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.total }; }),
                                    dataDoAlerta: new Date(c.history[0].dia + "/" + c.history[0].mes + "/" + c.history[0].ano).toLocaleDateString()
                                };
                            } else if (c.conditions[0].condition == 'budget') {
                                recordResult.alertHistory =
                                {
                                    history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.accTotal }; }),
                                    dataDoAlerta: new Date(c.history[0].dia + "/" + c.history[0].mes + "/" + c.history[0].ano).toLocaleDateString()
                                };
                            }
                        } catch (e) {
                            setLoading(false);
                            console.log(e);
                        }
                        return recordResult;
                    })
                }

                let sortedNotificationList = notificationList.sort(
                    (element1: any, element2: any) => {
                        return element1.alertDate < element2.alertDate ? 1 : -1;
                    });

                setLoading(false);
                setNotificacoes(sortedNotificationList);
                setNotificacoesAux(sortedNotificationList);
            } catch (error) {
                console.warn(error);
                setLoading(false);
                setNotificacoes([]);
            }

        }
        loadData();
    }, [user.ClientGroupSelected, user.refreshFlag,])

    const getStatus = (row: any) => {
        return row.alertVariation.includes('+') ? "danger" : "warning";
    }

    const scopeFormat = (str: string) => {
        let translator = {
            "global": "Global",
            "contracts": "Contratos",
            "costCenters": "Centros de Custo",
            "resource": "Recursos",
            "service": "Serviços"
        }
        return str && str.length > 0 && translator[str] ? translator[str] : "---"
    }

    const getAlertHistory = async (row: any) => {
        var response = await finOpsMapManagerService.LoadProcessedAlertHistory();
        var historyF: any[] = [];

        response.map(item => {
            if (item.alerts && item.alerts.find(c => c.id == row.id) && item.alerts.find(c => c.id == row.id).conditions) {
                if (item.alerts.find(c => c.id == row.id).conditions[0].condition == 'variacao') {
                    let itemHistory = item.alerts.find(c => c.id == row.id).history;
                    if (itemHistory.length > 0) {
                        historyF.push({
                            history: itemHistory.sort(
                                (element1: any, element2: any) => {
                                    return new Date(element1.date) < new Date(element2.date) ? -1 : 1;
                                }
                            ).map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.total }; }),
                            dataDoAlerta: new Date(item.alerts.find(c => c.id == row.id).date)
                        });
                    }
                } else if (item.alerts.find(c => c.id == row.id).conditions[0].condition == 'budget') {
                    let itemHistory = item.alerts.find(c => c.id == row.id).history;
                    if (itemHistory.length > 0) {
                        historyF.push({
                            history: item.alerts.find(c => c.id == row.id).history.sort(
                                (element1: any, element2: any) => {
                                    return new Date(element1.date) < new Date(element2.date) ? -1 : 1;
                                }
                            ).map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.accTotal }; }),
                            dataDoAlerta: new Date(item.alerts.find(c => c.id == row.id).date)

                        });
                    }
                }
            }
        });

        let h = historyF.sort(
            (element1: any, element2: any) => {
                return element1.dataDoAlerta < element2.dataDoAlerta ? 1 : -1;
            }
        );

        setHistory(h);
    }

    const columns = [
        {
            field: 'alertIcon', headerName: '', width: '56px', align: 'center',
            renderCell: (row: any) => {
                return <i className={`${row.alertIcon} ppi-4x`}></i>
            }
        },
        { field: 'alertDescription', headerName: 'Alerta', width: '30%' },
        {
            field: '', width: '5%', overflow: "visible", align: 'center', showOnHover: true, sort: false, renderCell: (row: any) => {
                return <>
                    <LcIconLink
                        icon={<PiEyeLight/>}
                        tooltip='Ver detalhes'
                        tooltipPosition='right'

                        onClick={() => {
                            setModalInfos(row)
                            setModalVisible(true)
                        }} />
                    <LcIconLink icon={<PiClockClockwiseLight/>}
                        tooltip='Histórico'
                        tooltipPosition='left'
                        onClick={() => {
                            getAlertHistory(row)
                                .then(item => {
                                    setModalInfos(row)
                                })
                                .catch(console.warn);
                            setHistoryModalVisible(true)
                        }} />
                </>
            }
        },
        {
            field: 'alertDate', headerName: 'ÚLTIMO ACIONAMENTO', width: '15%',
            renderCell: (row) => {
                let date = moment(row.alertDate).fromNow();
                let fuzzyDate = '';
                let hrs = `, ${moment(row.alertDate).format("H:MM")}`
                date.includes("horas") ?
                    fuzzyDate = "Hoje" + hrs :
                    date.includes("um dia") ?
                        fuzzyDate = "Ontem" + hrs :
                        fuzzyDate = date;

                return date;
            }
        },
        {
            field: '', headerName: 'CONTEXTO', width: '20%', sort: false,
            renderCell: (row: any) => {
                let escope = '';
                switch (row.alertScope) {
                    case 'global':
                        escope = `Global`;
                        break;
                    case 'costCenters':
                        escope = row.alertCostCenter.map((item, index) => { return index == (row.alertCostCenter.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    case 'contracts':
                        escope = row.alertContracts.map((item, index) => { return index == (row.alertContracts.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    case 'resources/services':
                        escope = row.alertResources.map((item, index) => { return index == (row.alertResources.length - 1) ? item.label : `${item.label}, ` }).join('');
                        break;
                    default:
                        break;
                }

                if (window.outerWidth > 1360 ? escope.length > 30 : escope.length > 40) {
                    return <PPTooltip content={escope} trigger='hover' position='left'>
                        <div style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                            {escope}
                        </div>
                    </PPTooltip >
                } else {
                    return escope;
                }

            }
        },
        {
            field: 'alertHistory', headerName: '', width: '16%', align: 'left', sort: false,
            renderCell: (row: any) => {

                if (row.alertHistory) {
                    // console.log(row.alertHistory.history);
                    // console.log(row.alertDescription);
                    return <ReactECharts showLoading={loading} lazyUpdate={true}
                        style={{ height: "80px", width: '210px' }}
                        option={lineChartOption(row.alertHistory.history, 1, 0)}
                    />
                } else {
                    return <div className="centralized" style={{ height: "80px", width: '210px' }}>
                        <i className="ppi ppi-ban ppi-3x text-grey" />
                    </div>
                }
            }
        }, {
            field: 'alertVariation', headerName: 'Variação', width: '10%', align: 'left', sort: false,
            renderCell: (row) => {
                var a = 0.00;
                var b = 0.00;
                if (row.budgetLimit > 0) {
                    a = row.budgetLimit;
                    b = Number.parseFloat(row.alertPeriodTotal.replace('.', '')) - row.budgetLimit;

                    let variation = a > 0 ? (b / a) - 1 : 100;
                    if (variation > 0) {
                        return (`+${(variation * 100).toFixed(2).replace('.', ',')}% ` + ' do Budget');
                    } else {
                        return (`${(variation * 100).toFixed(2).replace('.', ',')}%` + ' do Budget');
                    }
                } else {
                    a = Number.parseFloat(row.alertPriorPeriodTotal.replace('.', ''));
                    b = Number.parseFloat(row.alertPeriodTotal.replace('.', ''));

                    let variation = a > 0 ? (b / a) - 1 : 100;
                    if (variation > 0) {
                        return `+${(variation * 100).toFixed(2).replace('.', ',')}%`;
                    } else {
                        return `${(variation * 100).toFixed(2).replace('.', ',')}%`;
                    }
                }

            }
        },
        {
            field: 'alertVariation', headerName: 'Variação (R$)', width: '10%', align: 'left', sort: false,
            renderCell: (row) => {
                let variation = row.alertDifference.toFixed(2);
                return `R$ ${Intl.NumberFormat([], {
                    style: 'decimal', currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(variation)}`;
            }
        },
    ]

    const lineChartOption = (dados: any, max: number = 1, min: number = 1) => {

        return {
            align: 'center',
            xAxis: {
                show: false,
                // show: user.IsAdm ? true : false,
                type: 'category',
                data: dados.map((data: any) => {
                    return data.dia
                })
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [
                {
                    showSymbol: true,
                    symbol: (value, params) => {
                        return params.dataIndex == (dados.length - 1) ? "circle" : "none"
                    },
                    symbolSize: 7,
                    data: dados.map((data: any) => {
                        return data.valor
                    }),
                    type: 'line'
                }
            ],
            visualMap:
            {
                show: false,

                inRange: {
                    color: ['#93CE07', '#ff2100']
                },
            }
        }
    }

    const historyColumns = [
        {
            field: 'dataDoAlerta', headerName: 'Data / Hora', width: '20%', align: 'left',
            renderCell: (row) => {
                return <div className="date">
                    {row.dataDoAlerta && row.dataDoAlerta.toLocaleDateString('pt-br')}
                </div>
            }
        },
        {
            field: '', headerName: 'Variação', width: '80%', align: 'center', sort: false,

            renderCell: (row) => {
                return <ReactECharts
                    style={{ height: "80px", width: '210px' }}
                    lazyUpdate={true}
                    option={lineChartOption(row.history, 1, 0)}
                />
            }
        },
    ]

    const filterAdvanced = {
        fields: [{ label: "Alerta", name: "alertDescription", type: "text" }],
        onChange: (_filter: any, size: number) => {
            setFilter(
                {
                    ...filter,
                    size: size,
                    term: _filter[0].value.toLowerCase(),
                }
            )
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };
    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size });
    };

    const onSortChangeHistory = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterHistory({ ...filterHistory, column: sort.column, direction: sort.direction, size: size });
    };

    const card: Card[] = [
        {
            type: 'Custom',
            hideHeader: true,
            position: { row: 1, col: 1, ColSpan: 12, RowSpan: 12 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix' >
                        <LcInfiniteTable
                            rows={notificacoes}
                            columns={columns}
                            density={'low'}
                            status={getStatus}
                            size={notificacoes.length}
                            loadMore={() => undefined}
                            hidePagination
                            onSortChange={onSortChange}
                        // filter={filterAdvanced}          
                        />
                    </LcLoading>
                )
            }
        }
    ]
    console.log(notificacoes)
    const translations = {
        alertDescription: {
            label: "Alerta",
            type: "text",
        },
    }
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    data={notificacoesAux}
                    onFilteredData={(filteredData) => { setFiltered(true); setNotificacoes(filteredData as any[]); }}
                    translations={translations}
                    clearFilters={() => { setFiltered(false); setNotificacoes(notificacoesAux as any[]) }}
                />
            </div>
        )
    }

    return (
        <Layout
            pageTitle='Notificações'
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: notificacoes.length,
                color: filtered ? 'var(--color-secondary-lime)' : '',
                clickble: filterVisible
            }}
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    close: setFilterVisible
                }}
            />
            <PPModal visible={modalVisible} title={modalInfos != undefined ? modalInfos.alertDescription : ''} onClose={() => { setModalVisible(false); setModalInfos(undefined) }} >
                {
                    modalInfos && modalInfos?.message &&
                    <div className='row'>
                        <b>{modalInfos?.message}</b>
                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ margin: '0 0 10px 10px', fontSize: '12px', width: '20%', borderRadius: '10px', backgroundColor: 'gold', padding: '5px', textAlign: 'center' }}>
                        {
                            modalInfos?.alertScope == "resources/services" && modalInfos?.alertResources.length > 0 &&
                            <strong>{scopeFormat("resources")}</strong>
                        }
                        {
                            modalInfos?.alertScope == "resources/services" && modalInfos?.alertServices.length > 0 &&
                            <strong>{scopeFormat("services")}</strong>
                        }
                        {
                            modalInfos?.alertScope != "resources/services" &&
                            <strong>{scopeFormat(modalInfos?.alertScope)}</strong>
                        }
                    </div>
                </div>
                <PPExpand title='Informações do período' expanded={true}>
                    <div className='cont'>

                        <div className='row'>
                            <div className='sub'>Emitido em</div>
                            <b>{moment(modalInfos?.alertDate).format("DD/MM/YYYY")}</b> às <b>{moment(modalInfos?.alertDate).format("HH:mm")}</b>
                        </div>
                        <div className='row'>
                            <div className="sub">Válido para</div>
                            <b>{moment(modalInfos?.alertPeriodStartDate).format("DD/MM/YYYY")}</b> a <b>{moment(modalInfos?.alertPeriodEndDate).format("DD/MM/YYYY")}</b>
                        </div>

                    </div>
                </PPExpand>

                <PPExpand title='Regra de alerta' expanded={true}>
                    <div className='cont'>
                        <div className='row'>
                            <div className="sub">Condição</div>
                            <b> {modalInfos?.alertCondition} de { modalInfos?.alertPercentage.replace(/[+-]/g, '')} para
                                {modalInfos?.alertIcon.includes('down')? ' menos' : ' mais'}
                            </b>
                        </div>
                        <div className='row'>
                            <div className="sub">Período</div>
                            <b>{modalInfos?.alertPeriod} {modalInfos?.alertPeriodValue} {modalInfos?.alertPeriodUnit} </b>
                        </div>
                    </div>

                </PPExpand>
                {modalInfos?.alertScope && <>
                    {modalInfos?.alertScope.includes('resource') &&
                        <PPExpand title='Recursos' expanded={true}>
                            <div className='cont'>
                                {modalInfos.alertResources.map((item, index) => {
                                    return <div className='row' key={index}>
                                        <div className='col-md-6'>
                                            {item.label}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </PPExpand>
                    }

                    {modalInfos?.alertScope.includes('resource') &&
                        <PPExpand title='Serviços' expanded={true}>
                            <div className='cont'>
                                {modalInfos.alertServices.map((item, index) => {
                                    return <div className='row' key={index}>
                                        <div className='col-md-6'>
                                            {item.label}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </PPExpand>
                    }

                    {modalInfos?.alertScope.includes('costCenter') &&
                        <PPExpand title='Centros de custo' expanded={true}>
                            <div className='cont'>
                                {modalInfos.alertCostCenter.map((item, index) => {
                                    return <div className='row' key={index}>
                                        <div className='col-md-6'>
                                            {item.label}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </PPExpand>
                    }
                    {modalInfos?.alertScope.includes('contract') &&
                        <PPExpand title='Contratos' expanded={true}>
                            <div className='cont'>
                                {modalInfos.alertContracts.map((item, index) => {
                                    return <div className='row' key={index}>
                                        <div className='col-md-6'>
                                            {item.label}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </PPExpand>
                    }
                </>}
            </PPModal>
            <PPModal
                visible={historyModalVisible}
                loading={!modalInfos}
                title={modalInfos != undefined ? modalInfos.alertDescription + ' : histórico' : ''}
                onClose={() => { setHistoryModalVisible(false); setModalInfos(undefined) }}

            >
                <LcInfiniteTable
                    rows={history}
                    columns={historyColumns}
                    density={"low"}
                    height="400px"
                    loadMore={() => undefined}
                    onSortChange={onSortChangeHistory}
                    size={history.length}
                />
            </PPModal>
        </Layout >
    );
}

export default AlertasNotificacao;
