import React, { useEffect, useState } from 'react';
import LcToggle from '../../../components/Form/LcToggle'
import styles from './index.module.css'
import accessProfileService from '../../../services/accessProfile/accessProfile';

import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import LcLoading from '../../../components/Generic/LcLoading';
import LcAccordion from '../../../components/Generic/LcAccordion';

import { useSnackbar } from 'notistack';

const Permission: React.FC<any> = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const user = useSelector<RootState, UserState>(state => state.user);

    const [menuItens, setMenuItens] = useState<any[]>([]);
    const [loadingMenuItens, setLoadingMenuItens] = useState<boolean>(false);

    const [type, setType] = useState<string>(props.type);
    const [isLoadedGlobalPermission, setIsLoadedGlobalPermission] = useState<boolean>(false);
    const accountService = new accessProfileService(props);


    let account = props.account;

    const [includeNewResource, setIncludeNewResource] = useState<boolean>(false)
    const [allowCase, setAllowCase] = useState<boolean>(false)
    const [allowOperationAutomation, setAllowOperationAutomation] = useState<boolean>(false)
    const [allowConfigureUser, setAllowConfigureUser] = useState<boolean>(false)
    const [allowAudit, setAllowAudit] = useState<boolean>(false)
    const [allowChanges, setAllowChanges] = useState<boolean>(false)
    const [allowClientAccessMenuProfile, setAllowClientAccessMenuProfile] = useState<boolean>(false)
    const [allowFuncionalityProc, setAllowFuncionalityProc] = useState<boolean>(false)
    const [allowAccessContracts, setAllowAccessContracts] = useState<boolean>(false)
    const [allowAccessRepository, setAllowAccessRepository] = useState<boolean>(false)
    const [allowAccessHealthCheck, setAllowAccessHealthCheck] = useState<boolean>(false)
    const [allowAutomationsReadOnly, setAllowAutomationsReadOnly] = useState<boolean>(false)
    const [allowAutomationsScheduleByTicket, setAllowAutomationsScheduleByTicket] = useState<boolean>(false)
    const [allowAutomationsScheduleAvailability, setAllowAutomationsScheduleAvailability] = useState<boolean>(false)
    const [allowAutomationsScheduleResizing, setAllowAutomationsScheduleResizing] = useState<boolean>(false)
    const [allowAutomationsRemove, setAllowAutomationsRemove] = useState<boolean>(false)

    useEffect(() => {
        if (type == "globalPermission") {
            accountService.GetProfilePermissionByClientGroupPerfil(account.id)
                .then(result => {

                    setIncludeNewResource(result.data.some(permissionObj => permissionObj.permission.id === 2 && permissionObj.permited));
                    setAllowCase(result.data.some(permissionObj => permissionObj.permission.id === 3 && permissionObj.permited));
                    setAllowOperationAutomation(result.data.some(permissionObj => permissionObj.permission.id === 4 && permissionObj.permited));
                    setAllowConfigureUser(result.data.some(permissionObj => permissionObj.permission.id === 5 && permissionObj.permited));
                    setAllowAudit(result.data.some(permissionObj => permissionObj.permission.id === 6 && permissionObj.permited));
                    setAllowChanges(result.data.some(permissionObj => permissionObj.permission.id === 7 && permissionObj.permited));
                    setAllowClientAccessMenuProfile(result.data.some(permissionObj => permissionObj.permission.id === 8 && permissionObj.permited));
                    setAllowFuncionalityProc(result.data.some(permissionObj => permissionObj.permission.id === 9 && permissionObj.permited));
                    setAllowAccessContracts(result.data.some(permissionObj => permissionObj.permission.id === 13 && permissionObj.permited));
                    setAllowAccessRepository(result.data.some(permissionObj => permissionObj.permission.id === 14 && permissionObj.permited));
                    setAllowAccessHealthCheck(result.data.some(permissionObj => permissionObj.permission.id === 22 && permissionObj.permited));
                    setAllowAutomationsReadOnly(result.data.some(permissionObj => permissionObj.permission.id === 23 && permissionObj.permited));
                    setAllowAutomationsScheduleByTicket(result.data.some(permissionObj => permissionObj.permission.id === 24 && permissionObj.permited));
                    setAllowAutomationsScheduleAvailability(result.data.some(permissionObj => permissionObj.permission.id === 25 && permissionObj.permited));
                    setAllowAutomationsScheduleResizing(result.data.some(permissionObj => permissionObj.permission.id === 26 && permissionObj.permited));
                    setAllowAutomationsRemove(result.data.some(permissionObj => permissionObj.permission.id === 27 && permissionObj.permited));

                    setIsLoadedGlobalPermission(true)
                }).catch(error => {
                    console.log(error)
                })
        }
        if (type == "menuItens") {
            loadMenuItens();
        }
    }, [user.ClientGroupSelected, user.refreshFlag])

    const loadMenuItens = () => {
        setLoadingMenuItens(true);
        accountService.GetClientGroupPerfilMenu(account.id)
            .then(result => {

                setMenuItens(result.data)
            })
            .catch(console.warn)
            .then(() => {
                setLoadingMenuItens(false);
            })
    }

    const changePermission = (menu: any) => {

        const { name, checked } = menu;
        //no name vem o Id do item de menu

        var _menuItem = [
            {
                clientGroupPerfilId: props.account.id,
                menuId: name,
                permitido: checked
            }
        ]

        accountService.SendMenuOpt(_menuItem, props.account.id)
            .then(response => {
                enqueueSnackbar(`Item ${checked ? 'incluído no' : 'removido do'} perfil.`, {
                    variant: checked ? 'success' : 'warning',
                    preventDuplicate: true,
                    persist: false,
                });

            })
            .catch(console.warn)
            .then(() => {
                loadMenuItens();
            })
    }

    const alertMessage = (checked: any) => {
        if (checked) {
            enqueueSnackbar('Permissão adicionada com sucesso.', {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            })
        } else {
            enqueueSnackbar('Permissão removida com sucesso.', {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            })
        }
    }

    const errorAlert = () => {
        enqueueSnackbar('Erro ao modificar permissão.', {
            variant: 'error',
            preventDuplicate: true,
            persist: false,
        })
    }

    const sendPermission = (field: any) => {
        let id_role: number;
        let data: any;
        switch (field.name) {
            case '2':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setIncludeNewResource(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setIncludeNewResource(!field.checked)
                })


                break;

            case '3':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowCase(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowCase(!field.checked)
                })

                break;

            case '4':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowOperationAutomation(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowOperationAutomation(!field.checked)
                })

                break;

            case '5':

                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowConfigureUser(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowConfigureUser(!field.checked)
                })

                break;

            case '6':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAudit(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAudit(!field.checked)
                })

                break;
            case '7':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowChanges(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowChanges(!field.checked)
                })
                break;
            case '8':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowClientAccessMenuProfile(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowClientAccessMenuProfile(!field.checked)
                })

                break;
            case '9':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowFuncionalityProc(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowFuncionalityProc(!field.checked)
                })

                break;

            case '13':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]
                setAllowAccessContracts(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAccessContracts(!field.checked)
                })

                break;

            case '14':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAccessRepository(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAccessRepository(!field.checked)
                })

                break;
            case '22':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAccessHealthCheck(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAccessHealthCheck(!field.checked)
                })

                break;
            case '23':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAutomationsReadOnly(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAutomationsReadOnly(!field.checked)
                })

                break;
            case '24':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAutomationsScheduleByTicket(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAutomationsScheduleByTicket(!field.checked)
                })

                break;
            case '25':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAutomationsScheduleAvailability(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAutomationsScheduleAvailability(!field.checked)
                })

                break;
            case '26':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAutomationsScheduleResizing(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAutomationsScheduleResizing(!field.checked)
                })

                break;
            case '27':
                id_role = +field.name;

                data = [
                    {
                        permissaoId: id_role,
                        perfilId: account.id,
                        permitido: field.checked
                    }
                ]

                setAllowAutomationsRemove(field.checked)

                accountService.SendPermissionOpt(data).then(result => {
                    if (result.status == 200) {
                        alertMessage(field.checked);
                    }
                }).catch(error => {
                    console.log(error)
                    errorAlert();
                    setAllowAutomationsRemove(!field.checked)
                })

                break;
            default:
                break;
        }
    }

    return (
        <>
            {
                <div>
                    {isLoadedGlobalPermission &&
                        <>
                            <div className={styles.listPermitions}>
                                <div className="Item">
                                    <LcToggle name='2' toggled={includeNewResource} title="Permite Incluir recursos novos" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='3' toggled={allowCase} title="Permite chamados" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0} ></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='4' toggled={allowOperationAutomation} title="Permite Operação/Automação" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='5' toggled={allowConfigureUser} title="Permite Configurar usuários" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0} ></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='6' toggled={allowAudit} title="Permite acesso à auditoria" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='7' toggled={allowChanges} title="Permite aprovar mudanças" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0} ></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='8' toggled={allowClientAccessMenuProfile} title="Permite acesso ao menu de perfis de acesso ao cliente" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='9' toggled={allowFuncionalityProc} title="Habilita permissões gestão para funcionalidades internas da processor" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='13' toggled={allowAccessContracts} title="Permitir acesso a contratos e notas fiscais" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>

                                <div className="Item">
                                    <LcToggle name='14' toggled={allowAccessRepository} title="Permitir acesso ao repositório de arquivos" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='22' toggled={allowAccessHealthCheck} title="Permitir acesso ao centro de saude do sistema (Health Check)" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='23' toggled={allowAutomationsReadOnly} title="Permite visualizar automações agendadas" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='24' toggled={allowAutomationsScheduleByTicket} title="Permite solicitar criação/edição de automações via chamado" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='25' toggled={allowAutomationsScheduleAvailability} title="Permite criar/editar automações de disponibilidade" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='26' toggled={allowAutomationsScheduleResizing} title="Permite criar/editar automações de redimensionamento" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                                <div className="Item">
                                    <LcToggle name='27' toggled={allowAutomationsRemove} title="Permite remover automações agendadas" onChange={sendPermission} disabled={account.isTemplate == 'Não' && account.originTemplateId != 0}></LcToggle>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }

        </>
    )
}
export default Permission;
