import React, { useState, ReactNode, useCallback, useMemo } from 'react';
import './index.css';

interface Props {
    defaultExpanded?: boolean,
    expanded?: boolean,
    title?: ReactNode,
    titleRight?: ReactNode,
    marginBotton?: "5" | "10" | "15",
    onExpand?: Function,
    status?: string,
    height?: string,
    className?: string,
    children?: ReactNode; 
}

const getStatusRow = (status: string) => {
    switch (status) {
        case 'primary':
            return '#2E1956';
        case 'secondary':
            return '#1b3a7a';
        case 'danger':
            return '#e64646';
        case 'success':
            return '#4DC77E';
        case 'warning':
            return '#ffb800';
        case 'link':
            return '#0580ce';
        default:
            return status;
    }
}

const PPExpand2: React.FC<Props> = (props) => {
    const [expanded, setExpanded] = useState(props.defaultExpanded || false);

    const existChildren = useMemo(() => props.children!.toString().length > 0, [props.children]);

    const handleTitleClick = useCallback(() => {
        if (existChildren) {
            setExpanded(prev => !prev);
            if (props.onExpand) {
                props.onExpand(!expanded); // Passa o novo estado do acorde�o
            }
        }
    }, [existChildren, expanded, props.onExpand]);


    return (
        <div className={`pp-expand2${!expanded ? ' expanded' : ''}${props.marginBotton ? ` mb-${props.marginBotton}` : ''}`}>
            {props.title && (
                <div className={`header${props.titleRight ? " hover" : ""}${props.height ? ' height' : ''} ${props.className ? props.className : ''}`} style={{ height: props.height ? props.height : '60px', borderLeftColor: props.status && `${getStatusRow(props.status)}` }}>
                    <div className={`title${existChildren ? "" : " disabled"}`} onClick={handleTitleClick}>
                        {existChildren ? <i className='ppi ppi-chevron-up' /> : <span style={{ marginLeft: '25px' }}>&nbsp;</span>}
                        <span>{props.title}</span>
                    </div>
                    {props.titleRight && <div className='functions'>{props.titleRight}</div>}
                </div>
            )}
            <div className={`body${expanded ? ' expanded' : ''}`}>
                <div className={`content`} style={{ marginLeft: props.title ? "28px" : "0" }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default React.memo(PPExpand2);
