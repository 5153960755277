import React, { useEffect, useState } from 'react';


import { FinancialManagementService } from '../../../services/financialManagement/FinancialManagementService';
import history from '../../../history';

import { SelectField } from '../../../components/Form/Input';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import LcCheckBox from '../../../components/Form/LcCheckBox';
import LcTable from '../../../components/Data/LcTable';
import LcLoading from '../../../components/Generic/LcLoading';
import { Grid } from '@material-ui/core';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';


import { PPModal } from 'processor-plataform-ui'
import { useSnackbar } from 'notistack';
import PPDropDown from '../../../components/PPDropDown';
import Layout from '../../../components/Layout/Layout';
import { title } from 'process';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { debug } from 'console';

import './index.css';
import { PiFilePdfLight, PiFileTextLight } from 'react-icons/pi';
import { TbFileTypeXml } from 'react-icons/tb';

interface Props {
    contract: any;
    nfQuantity?: Function;
    visibleFilter: boolean
    closeFilter: Function
}

const Invoices: React.FC<Props> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const getAllYears = () => {
        let allYears: any[] = [];
        for (var index = 2019; index <= new Date().getFullYear(); index++) {
            let yearObject = {
                value: '' + index,
                label: '' + index
            }
            allYears.push(yearObject);

        }
        
        return allYears;
    }

    const [modalVisibleDetailsNF, setModalVisibleDetailsNF] = useState<boolean>(false);
    const [modalVisibleSearch, setModalVisibleSearch] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const LcOptionsListValuesYear = getAllYears();

    const [invoicesLength, setInvoicesLength] = useState<number>(0);

    const [nfSelected, setNfSelected] = useState('');

    const [mainData, setMainData] = useState({
        clientDescription: null,
        clientId: null,
        contractDescription: null,
        contractId: null
    });

    const [clientId, setClientId] = useState(props.contract.clientId);

    const [invoices, setInvoices] = useState([]);
    const [customers, setCustomers] = useState<any[]>([]);
    const [invoicesMaped, setInvoicesMaped] = useState<any[]>([]);
    const [visibleInvoicesMaped, setVisibleInvoicesMaped] = useState<any[]>([]);
    const NFDefault = {
        parcelNF: [],
        itens: [],
        licenses: [],
        client: {
            name: null,
            cnpj: null
        },
        company: {
            name: null,
            cnpj: null
        }
    }
    const [NFDetails, setNFDetails] = useState(NFDefault);

    const [filteredNFNFLicensesItens, setFilteredNFNFLicensesItens] = useState([]);
    const [visiblefilteredNFNFLicensesItens, setVisibleFilteredNFNFLicensesItens] = useState([]);

    const [filteredNFDetailsItens, setFilteredNFDetailsItens] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState<any>({});
    const [visibleFilteredNFDetailsItens, setVisibleFilteredNFDetailsItens] = useState([]);

    const [filteredNFDetailsParcelNF, setFilteredNFDetailsParcelNF] = useState([]);

    const [visibleFilteredNFDetailsParcelNF, setVisibleFilteredNFDetailsParcelNF] = useState([]);

    const filterDefault = { size: 20, term: "", year: new Date().getFullYear().toString(), column: "issuanceDate", direction: "desc" };

    const [filter, setFilter] = useState<{ size: number, term: string, year: string, column: string, direction: string }>(filterDefault);
    const [filterItens, setFilterItens] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);
    const [filterParcelNF, setFilterParcelNF] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    const service = new FinancialManagementService(props);

    const inputSetYear = (data: any) => {
        setYear(data.value);
    };

    useEffect(() => {
        setIsLoading(true);
        // setInvoicesMaped([]);
        // setVisibleInvoicesMaped([]);
        // setInvoices([]);
        // setInvoicesMaped([]);
        // setVisibleInvoicesMaped([]);
        props.closeFilter(false);

        if (!mainData.clientId && !mainData.contractId) {
            service.GetInvoices(props.contract.contractId, props.contract.clientId)
                .then((response) => {
                    setMainData(response);
                });
        }

        service.GetClientsFromContract(props.contract.contractId)
            .then((response) => {
                customers.map((e) => { return e && ({ label: e.name, value: e.id }) })
                setCustomers(response.sort((a, b) => a.name.localeCompare(b.name, 'pt-br', { sensitivity: 'base' })).map((e) => { return e && ({ label: e.name, value: e.id }) }));
                response.length == 1 && setClientId(response[0].id)
            });

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await service.GetInvoicesByClientContractYear(props.contract.contractId, clientId, year);
                const data = response.filter(i => i.status !== 'CANCELADO');
                setInvoices(data);
                setInvoicesMaped(data);
                setVisibleInvoicesMaped(data);
                props.nfQuantity && props.nfQuantity(data.length);
            } catch (error) {
                console.error("Erro ao buscar notas fiscais:", error);
                setInvoices([]);
                setInvoicesMaped([]);
                setVisibleInvoicesMaped([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [year, clientId, props.contract.contractId]);


    const applyFilter = () => {
        let isFilterActive = false;

        //props.closeFilter(false);
        var filtered = invoices.filter((p: any) => {
            return filter.term == '' ||
                p.rpsId.toString().indexOf(filter.term.toString()) > -1 ||
                p.nfseId.toString().indexOf(filter.term) > -1 ||
                p.salesOrderId.toString().indexOf(filter.term.toString()) > -1 ||
                p.amount.toString().indexOf(filter.term.toString()) > -1 ||
                p.status.toLowerCase().indexOf(filter.term) > -1
        }
        ).sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        isFilterActive = filtered.length !== invoices.length;
        dispatch(setFilterActive(isFilterActive));

        setInvoicesMaped(filtered);
        setVisibleInvoicesMaped(filtered.slice(0, filter.size));
        setInvoicesLength(filtered.length);
    };

    useEffect(() => {
        applyFilter();
    }, [filter.term, filter, invoices]);


    useEffect(() => {
        props.nfQuantity && props.nfQuantity(invoicesMaped.length);
    }, [invoicesMaped, props.nfQuantity]);


    useEffect(() => {
        var filtered = NFDetails.itens
            .sort((a: any, b: any) => {
                if (filterItens.direction == 'asc') return (a[filterItens.column] > b[filterItens.column]) ? 1 : -1;
                else return (a[filterItens.column] > b[filterItens.column]) ? -1 : 1;
            });

        setFilteredNFDetailsItens(filtered);
        setVisibleFilteredNFDetailsItens(filtered.slice(0, filterItens.size));

    }, [filterItens, filteredNFDetailsItens.length > 0]);

    useEffect(() => {
        var filtered = NFDetails.parcelNF
            .sort((a: any, b: any) => {
                if (filterParcelNF.direction == 'asc') return (a[filterParcelNF.column] > b[filterParcelNF.column]) ? 1 : -1;
                else return (a[filterParcelNF.column] > b[filterParcelNF.column]) ? -1 : 1;
            });

        setFilteredNFDetailsParcelNF(filtered);
        setVisibleFilteredNFDetailsParcelNF(filtered.slice(0, filterParcelNF.size));

    }, [filterParcelNF, filteredNFDetailsParcelNF.length > 0]);

    async function getDetailsInvoiceProduto(row: any) {
        setModalVisibleDetailsNF(true);
        setIsLoadingModal(true);

        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([])
        setFilteredNFDetailsParcelNF([])

        const ClientId = row.clientId;
        const RpsId = row.rpsId;
        const SalesOrderId = row.salesOrderId;
        service.GetCompleteInvoiceDetails(ClientId, RpsId, SalesOrderId).then((response) => { 
            if (response) {
                setNfSelected(row.nfseId);
                setNFDetails(response);
                response.itens && setFilteredNFDetailsItens(response.itens);
                response.licenses && setFilteredNFNFLicensesItens(response.licenses);
                response.parcelNF && setFilteredNFDetailsParcelNF(response.parcelNF);
                response.licenses && response.licenses.length > 0 &&
                    setSelectedProduct(response.licenses[0]);
            }

            setIsLoadingModal(false);
        });
    }

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    async function getDetailsInvoicePDF(row: any) {

        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([])
        setFilteredNFDetailsParcelNF([])

        const nfseId = row.nfseId;
        const RpsId = row.rpsId;

        service.GetInvoiceFilePDF(nfseId, RpsId).then((response) => {
            if (response?.includes("The specified resource does not exist")) {
                enqueueSnackbar(`A nota fiscal seleciona não foi encontrada para download`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
            }
        });
    }

    async function getDetailsInvoiceXML(row: any) {

        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([])
        setFilteredNFDetailsParcelNF([])

        const nfseId = row.nfseId;
        const RpsId = row.rpsId;

        service.GetInvoiceFileXML(nfseId, RpsId);
    }

    const columnsTable = [
        { field: 'rpsId', headerName: 'RPS', width: "10%", align: 'right', },
        { field: 'nfseId', headerName: 'NF-e', width: "20%", align: 'right' },
        {
            field: 'issuanceDate', headerName: 'Emissão', width: "15%", align: 'right',
            renderCell: (row: any) => {
                return <div className="date">
                    {new Date(row.issuanceDate).toLocaleDateString()}
                </div>
            }
        },
        {
            field: "amount", headerName: "Valor Total", width: "15%", align: "right",
            renderCell: (row) => {
                return row.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        },
        // { field: 'status', headerName: 'Status', width: "15%" },
        { field: 'salesOrderId', headerName: 'Pedido', width: "15%", align: 'right' },
        {
            field: '', headerName: 'PDF', width: "5%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<PiFilePdfLight color='var(--status-red)'/> } tooltip="Baixar Nota Fiscal PDF"
                    onClick={() => getDetailsInvoicePDF(row)}
                />
            }
        },
        {
            field: '', headerName: 'XML', width: "5%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<TbFileTypeXml color='var(--status-yellow)' /> } tooltip="Baixar Nota Fiscal XML"
                    onClick={() => getDetailsInvoiceXML(row)}
                />
            }
        },
        {
            field: '', headerName: 'Detalhes', width: "6%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<PiFileTextLight />} tooltip='Detalhes'
                    onClick={() => getDetailsInvoiceProduto(row)}
                />
            }
        },
    ];

    const columnsDetailsItensTable = [
        { field: 'id', headerName: 'Pn/SIP', width: "25%" },
        { field: 'description', headerName: 'Descrição', width: "35%" },
        { field: 'qtd', headerName: 'Quantidade', width: "10%", align: 'right' },
        {
            field: 'unitValue', headerName: 'Valor Unitário', width: "15%", align: 'right',
            renderCell: (row) => {
                return `R$ ${row.unitValue}`
            }
        },
        {
            field: 'totalValue', headerName: 'Valor Total', width: "15%", align: 'right',
            renderCell: (row) => {
                return `R$ ${row.totalValue}`
            }
        },
    ];

    const columnsLicenseItensColumns = [{
        field: 'dueDade', headerName: '', width: "5%",
        renderCell: (row: any) => {
            return <LcIconLink
                icon={`${row == selectedProduct ? 'ppi ppi-radiobtn-on' : 'ppi ppi-radiobtn'}`}
                color='var(--color-secondary-lime)'
                size='small'
                onClick={() => {
                    setSelectedProduct(row);
                }}
            />
        }
    },
    {
        field: 'productDescription', headerName: 'Selecione item para detalhamento', width: "85%",
        renderCell: (row) => {
            return <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setSelectedProduct(row);
                }} > {row.productDescription}</div>
        }
    },
    { field: 'qtd', headerName: 'Quantidade', width: "10%", align: 'right' }

    ];

    const columnsOffer = [
        { field: 'chargeType', headerName: 'Tipo', width: "45%" },
        {
            field: 'chargeStartDate', headerName: 'Início', width: "15%", align: "right",
            renderCell: (row: any) => {
                return <div className="date">
                    {row.chargeStartDate ?
                        new Date(row.chargeStartDate).toLocaleDateString()
                        :
                        'Sem informação'}
                </div>
            }
        },
        {
            field: 'chargeEndDate', headerName: 'Vigência estimada', width: "15%", align: "right",
            renderCell: (row: any) => {
                return <div className="date">
                    {row.chargeEndDate ?
                        new Date(row.chargeEndDate).toLocaleDateString()
                        :
                        'Sem informação'}
                </div>
            }
        },
        {
            field: 'quantity', headerName: 'Quantidade', width: "10%", align: 'right'
        },
        {
            field: '', headerName: 'Valor Unitário ', width: "15%", align: 'right',
            renderCell: (row: any) => {
                return `R$ ${Intl.NumberFormat([], {
                    style: 'decimal', currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(selectedProduct.totalValue / selectedProduct.qtd)}`
            }
        }
    ];

    var scope = {
        splitterStyle: {
            minHeight: 'calc(7vw)',
            fontSize: '11px'
        },
        splitterStyleSmall: {
            minHeight: 'calc(7vw)',
            fontSize: '11px'
        }
    };

    const onSortChangeInvoices = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size });
    };

    const loadMoreInvoices = (size?: number) => {
        if (size) setVisibleInvoicesMaped(invoicesMaped.slice(0, size))
        else {
            setVisibleInvoicesMaped([...visibleInvoicesMaped, ...invoicesMaped.slice(visibleInvoicesMaped.length, visibleInvoicesMaped.length + 10)]);
        }
    };

    const onSortChangeItens = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterItens({ ...filterItens, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMoreItens = (size?: number) => {
        if (size) setVisibleFilteredNFDetailsParcelNF(filteredNFDetailsParcelNF.slice(0, size))
        else {
            setVisibleFilteredNFDetailsParcelNF([...visibleFilteredNFDetailsParcelNF, ...filteredNFDetailsParcelNF.slice(visibleFilteredNFDetailsParcelNF.length, visibleFilteredNFDetailsParcelNF.length + 10)]);
        }
    };
    //setFilteredNFNFLicensesItens
    const onSortChangeLicensesItens = (sortData: any) => {
        if (filteredNFNFLicensesItens && filteredNFNFLicensesItens.length > 0) {
            const { sort, size } = sortData;
            setFilterItens({ ...filterItens, column: sort.column, direction: sort.direction, size: size });
        }
    };

    const loadMoreLicensesItens = (size?: number) => {
        if (filteredNFNFLicensesItens && filteredNFNFLicensesItens.length > 0) {
            if (size) setVisibleFilteredNFNFLicensesItens(filteredNFNFLicensesItens.slice(0, size));
            else {
                setVisibleFilteredNFNFLicensesItens([...visiblefilteredNFNFLicensesItens, ...filteredNFNFLicensesItens.slice(visiblefilteredNFNFLicensesItens.length, visiblefilteredNFNFLicensesItens.length + 10)]);
            }
        }
    };

    const onSortChangeParcelNF = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterParcelNF({ ...filterParcelNF, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMoreParcelNF = (size?: number) => {
        if (size) setVisibleFilteredNFDetailsItens(filteredNFDetailsItens.slice(0, size))
        else {
            setVisibleFilteredNFDetailsItens([...visibleFilteredNFDetailsItens, ...filteredNFDetailsItens.slice(visibleFilteredNFDetailsItens.length, visibleFilteredNFDetailsItens.length + 10)])
        }
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [filterVisible, setFilterVisible] = useState(false);

    useEffect(() => {
        setFilterVisible(true);
    }, [props.visibleFilter, filterVisible])

    const filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'assunto', type: 'text', value: '' },
            // Usando o estado `year` como o valor do campo de seleção
            { label: 'Ano', name: 'year', type: 'select', options: getAllYears(),id:'',  value: year },
        ],
        onChange: (_filter: any, size: number) => {
            const newYear = _filter.find(f => f.name === 'year').value || '';
            setYear(newYear); // Atualiza o estado com o ano selecionado

            setFilter({
                ...filter,
                size: size + 0.5,
                term: _filter[0].value.toLowerCase(),
                year: newYear
            });

            dispatch(setFilterActive(true));
        },
        visible: filterVisible,
        onChangeVisibility: () => { props.closeFilter(false); setFilterVisible(!filterVisible) }
    }; 

    const getStatus = (row: any) => {
        return row.status == "CONCLUÍDO" ? 'success' : 'warning';
    } 

    const handleFilterChangeWrapper = (updatedFilters) => {

        const size = 20; // Defina o tamanho conforme necessário, ou ajuste sua função para não precisar dele.

        // Chama a função handleFilter com os argumentos necessários
        filterAdvanced.onChange(updatedFilters, size);
    };


    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            title: () => {
                return (
                    <div className="lc-title ellipsis">Notas Fiscais do Contrato: {mainData.contractDescription}</div>
                );
            },
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <div className='invoice-dash-content scrollable-v'>
                        <LcLoading loading={isLoading} label="Carregando dados...">
                            {customers && customers.length > 0 &&
                                <div className='mb-6'>
                                    <PPDropDown
                                        value={customers.find(option => option.value == clientId)}
                                        name="ClientGroup"
                                        options={customers}
                                        onChange={(option) => { setClientId(option.value) }}                                        
                                        title="Razão social"
                                    />
                                </div>
                            }

                            <LcInfiniteTable
                                loading={isLoading}
                                columns={columnsTable}
                                rows={visibleInvoicesMaped}
                                filter={filterAdvanced}
                                size={invoicesLength}
                                loadMore={loadMoreInvoices}
                                onSortChange={onSortChangeInvoices}
                                density={pref.data?.density || "high"}
                                status={getStatus}
                                disableFilterModal
                                tooltipContentColumn='status'
                            />
                        </LcLoading>
                    </div>
                )
            }
        }
    ]


    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChangeWrapper}
            />
        );
    };


    return (
        <LcLoading loading={isLoading}>
            <PPModal visible={modalVisibleSearch} title="Pesquisa" onClose={() => setModalVisibleSearch(false)}>
                <SelectField label="Ano de Emissão da Nota Fiscal" name="year" options={LcOptionsListValuesYear} value={year} onChange={inputSetYear}></SelectField>
            </PPModal>

            <PPModal size='large' visible={modalVisibleDetailsNF} title={`Detalhes da Nota Fiscal ${nfSelected}`} onClose={() => setModalVisibleDetailsNF(false)}>

                {!isLoadingModal && <h4>Faturado Por: {(NFDetails != undefined)
                    ? NFDetails.company.name : "CANCELADO"}
                    | CNPJ: {(NFDetails != undefined)
                        ? NFDetails.company.cnpj : "CANCELADO"}</h4>
                }

                <LcLoading label="Carregando dados.." loading={isLoadingModal} >
                    {
                        NFDetails && NFDetails.itens && NFDetails.itens.length > 0 &&
                        <div style={scope.splitterStyleSmall}>
                            <LcInfiniteTable
                                loading={isLoadingModal}
                                columns={columnsDetailsItensTable}
                                rows={visibleFilteredNFDetailsItens}
                                size={filteredNFDetailsItens.length}
                                loadMore={loadMoreItens}
                                onSortChange={onSortChangeItens}
                            />
                        </div>
                    }
                    <div style={scope.splitterStyle}>
                        {
                            NFDetails && NFDetails.licenses && NFDetails.licenses.length > 0 &&
                            <LcInfiniteTable
                                loading={isLoadingModal}
                                columns={columnsLicenseItensColumns}
                                rows={visiblefilteredNFNFLicensesItens}
                                functions={[]}
                                size={visiblefilteredNFNFLicensesItens.length}
                                loadMore={loadMoreLicensesItens}
                                onSortChange={onSortChangeLicensesItens}
                            />
                        }

                    </div>
                    <div style={scope.splitterStyle}>
                        {
                            selectedProduct && selectedProduct.offers && selectedProduct.offers.length &&
                            <>
                                <h3 style={{ marginTop: '30px' }}>Detalhamento - {selectedProduct.productDescription}</h3>

                                <LcInfiniteTable
                                    rows={selectedProduct.offers}
                                    columns={columnsOffer}
                                    loadMore={() => { }}
                                    size={selectedProduct.offers.length}
                                />
                            </>
                        }
                    </div>
                </LcLoading>

            </PPModal>

            <LCDashboard
                cards={card}
                cssClass='invoice-dash'
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />


        </LcLoading>
    );
}

export default Invoices;