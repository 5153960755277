import React, { useEffect, useState } from 'react';

import Layout from '../../../components/Layout/Layout';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import { Filter, ScheduleConfiguration, ScheduleManagementService } from '../../../services/scheduleManagementService/scheduleManagementService';

const ScheduleManagement: React.FC = (props) => {
    const service = new ScheduleManagementService(props);

    const user = useSelector<RootState, UserState>(state => state.user);

    const [rows, setRows] = useState<ScheduleConfiguration[]>([]);
    const [filteredRows, setFilteredRows] = useState<ScheduleConfiguration[]>([]);
    const [visibleRows, setVisibleRows] = useState<ScheduleConfiguration[]>([]);
    const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>(service.defaultFilter);


    async function loadData() {
        setIsLoadingSchedules(true)
        setRows([])
        setFilteredRows([])
        await service.GetScheduleConfiguration(filter.scheduledEventActive, filter.cacheManagerParametersActive).then(response => {
            let _responseData: ScheduleConfiguration[] = response.data.map((element: ScheduleConfiguration, index: number) => {
                return {
                    ...element,
                    id: index
                }
            })

            if (filter.column != '') {
                let sorted = _responseData.sort((a: any, b: any) => {
                    if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                    else return (a[filter.column] > b[filter.column]) ? -1 : 1;
                });
                setRows(sorted)
                setFilteredRows(sorted)
            } else {
                setRows(_responseData)
                setFilteredRows(_responseData)
            }
            setIsLoadingSchedules(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [user.refreshFlag, filter]);

    const columns = [
        {
            field: 'event', headerName: 'Tarefa', width: "90%",
            renderCell: (row: any) => <>{row.event} | {row.host} | {row.path}</>
        },
        {
            field: 'active', headerName: 'Habilitado', width: "10%",
            renderCell: (row: any) => {
                if (row.active) {
                    return <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <i className='ppi ppi-check text-success'
                            style={
                                { fontSize: '24px', paddingLeft: '25px' }
                            }
                        />
                    </span>
                } else {
                    return <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <i className='lci lci-x text-danger'
                            style={
                                { fontSize: '24px', paddingLeft: '25px' }
                            }
                        />
                    </span>
                }
            }
        },
        {
            field: 'frequency', headerName: 'Frequência', width: "10%",
            renderCell: (row: any) => {
                return <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    {row.frequency}
                </span>
            }
        },
        {
            field: 'scheduleType', headerName: 'Tipo', width: "10%",
            renderCell: (row: any) => {
                return <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    {service.GetTipoAgendamento(row.frequency, row.scheduleType)}
                </span>
            }
        },
        // { field: '___', headerName: 'Última execução', width: "10%" },
        {
            field: 'scheduledRunTime', headerName: 'Próxima execução', width: "10%",
            renderCell: (row: any) => {
                return <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    {service.GetHour(row.scheduledRunTime)}
                </span>
            }
        },
        // { field: 'log', headerName: 'LOG', width: "10%" },
    ]

    const filterAdvanced = {
        fields: [
            { label: 'Agendamento', name: 'schedule', type: 'select', options: service.Status },
            { label: 'Parametros de cache', name: 'cache', type: 'select', options: service.Status }
        ],
        onChange: (_filter: any, size: number) => {
            if (_filter[0].value == '' && _filter[1].value == '') {
                let _f: Filter = {
                    ...filter,
                    cacheManagerParametersActive: true,
                    scheduledEventActive: true
                }
                setFilter(_f)
            }
            else {
                let schedule = service.Status.find(x => x.value?.toString() == _filter[0].value || x.name == _filter[0].value) as any;
                let cache = service.Status.find(x => x.value?.toString() == _filter[1].value || x.name == _filter[1].value) as any;

                let _f: Filter = {
                    ...filter,
                    cacheManagerParametersActive: cache?.value,
                    scheduledEventActive: schedule?.value
                }
                setFilter(_f)
            }
        }
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleRows(filteredRows.slice(0, size))
        else setVisibleRows([...visibleRows, ...filteredRows.slice(visibleRows.length, visibleRows.length + 15)])
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        let sorted = rows.sort((a: any, b: any) => {
            if (sort.direction == 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
            else return (a[sort.column] > b[sort.column]) ? -1 : 1;
        });
        setRows(sorted)
        setFilteredRows(sorted)
    };

    return (
        <Layout pageTitle="Agendamentos"  >
            <LcInfiniteTable
                loading={isLoadingSchedules}
                columns={columns}
                rows={rows}
                filter={filterAdvanced}
                size={filteredRows.length}
                loadMore={loadMore}
                onSortChange={onSortChange}
                fontSize='small' />
        </Layout>
    );
};

export default ScheduleManagement;