import api, { APIClient } from "../api";
import { AutomationFilterFields, AutomationFormData, AutomationFormFieldAccess, AutomationInstanceType, AutomationResource, AutomationResourceDetails, AutomationsSchedulerData, AutomationsScheduler, AutomationTableFilter, AutomationTemplate, GetCostParams, ListOption, ScheduledTime, ScheduledWeekDays, TaskItemDuration } from "./automationsModel";


export default class AutomationsService extends APIClient {

    constructor(props: any) {
        super(props);
    }
    // Valores padroes para states
    emptyAutomationsScheduler: AutomationsScheduler = {
        id: 0,
        automationId: 0,
        data: '',
        status: 0,
        weekDaysScheduled: '',
        hourScheduled: '',
        lastRun: '',
        createdAt: '',
        createdBy: '',
        statusLabel: ''
    }

    emptySchedulerFilterFields: AutomationFilterFields = {
        providerId: 0,
        groupType: '',
        automationId: '',
        resourceId: '',
        hourScheduled: [0, 23],
        status: 0
    };

    emptySchedulerFilter: AutomationTableFilter = {
        size: 20,
        column: 'status',
        direction: 'desc',
        providerId: 0,
        groupType: '',
        automationId: '',
        resourceId: '',
        hourScheduled: '0,23',
        status: 0
    };
    defaultTemplate: AutomationTemplate = {
        id: 0,
        name: '',
        cloudOSId: 0,
        dataSample: '{}',
        description: '',
        groupType: '',
        serviceType: ''
    }

    groupsDic = {
        'VM': 'Máquinas virtuais',
        'DB': 'Bancos de dados',
        'Container': 'Contêineres',
        'AppServicePlan': 'App Service Plan'
    };

    emptyAutomationsSchedulerData: AutomationsSchedulerData = {
        ClientName: '',
        KeyVaultCredential: '',
        Automation: '',
        CloudOS: '',
        Region: null,
        TenantId: null,
        ApplicationId: null,
        SubscriptionId: null,
        AccountId: null,
        ResourceGroupName: null,
        ResourceName: null,
        ResourceId: null,
        KeyVaultAccessKey: null,
        Size: null,
        AppServicePlanName: null,
        WorkerSize: null,
        Tier: null,
        ServerName: null,
        ClusterName: null,
        ServiceName: null,
        DesiredCount: null
    };



    defaultScheduledWeekDays: ScheduledWeekDays = {
        MON: false,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false
    };

    defaultScheduledTime: ScheduledTime = {
        hour: '',
        minute: ''
    };

    defaultAutomationFormFieldAccess: AutomationFormFieldAccess = {
        provider: false,
        subscription: false,
        group: false,
        type: false,
        resource: false,
        instanceType: false,
        clusterService: false,
        clusterCount: false,
        weekDays: false,
        time: false,
        turnBackOnWeekDays: false,
        turnBackOnTime: false
    };

    emptyAutomationResource: AutomationResource = {
        resourceName: '',
        resourceID: '',
        resourceType: '',
        resourceCategory: '',
        resourceProvider: '',
        resourceGroup: '',
        resourceRegion: '',
        resourceSubscription: '',
        instanceSize: '',
        clusterServices: []
    };


    emptyAutomationInstanceType: AutomationInstanceType = {
        name: '',
        category: '',
        vcpUs: 0,
        vram: 0,
        vDisks: 0,
        cost: 0
    };

    emptyAutomationResourceDetails: AutomationResourceDetails = {
        resources: [],
        instanceTypes: []
    };

    emptyListOption: ListOption = {
        value: -1,
        label: ''
    };
    emptyAutomationFormData: AutomationFormData = {
        providers: [],
        provider: { ...this.emptyListOption },
        subscriptions: [],
        subscription: { ...this.emptyListOption },
        groups: [],
        group: { ...this.emptyListOption },
        types: [],
        type: { ...this.emptyListOption },
        resources: [],
        resource: { ...this.emptyListOption },
        instanceTypes: [],
        instanceType: { ...this.emptyListOption },
        clusterServices: [],
        clusterService: { ...this.emptyListOption },
        clusterCount: 0,
        weekDays: { ...this.defaultScheduledWeekDays },
        time: { ...this.defaultScheduledTime },
        turnBackOnWeekDays: { ...this.defaultScheduledWeekDays },
        turnBackOnTime: { ...this.defaultScheduledTime }
    };
    // [provider, label, category]
    automationTemplatesDic = {
        "Start RDS": ["AWS", "Ligar banco de dados", null],
        "Stop RDS": ["AWS", "Desligar banco de dados", null],
        "Resize RDS": ["AWS", "Redimensionar banco de dados", null],
        "Start EC2": ["AWS", "Ligar máquina virtual", null],
        "Stop EC2": ["AWS", "Desligar máquina virtual", null],
        "Resize EC2": ["AWS", "Redimensionar máquina virtual", null],
        "Resize AWS ECS Service": ["AWS", "Redimensionar container ECS", null],
        "Start VM": ["Azure", "Ligar máquina virtual", null],
        "Stop VM": ["Azure", "Desligar máquina virtual", null],
        "Resize VM": ["Azure", "Redimensionar máquina virtual", null],
        "Resize SQL Database": ["Azure", "Redimensionar banco de dados SQL Server", "SQLServer"],
        "Start SQL Database": ["Azure", "Ligar banco de dados SQL Server", "SQLServer"],
        "Stop SQL Database": ["Azure", "Desligar banco de dados SQL Server", "SQLServer"],
        "Resize Azure MySQL Flexible Server": ["Azure", "Redimensionar banco de dados MySQL", "MySQL"],
        "Start Azure MySQL Flexible Server": ["Azure", "Ligar banco de dados MySQL", "MySQL"],
        "Stop Azure MySQL Flexible Server": ["Azure", "Desligar banco de dados MySQL", "MySQL"],
        "Resize Azure PostgreSQL Flexible Server": ["Azure", "Redimensionar banco de dados PostgesSQL", "Postgres"],
        "Start Azure PostgreSQL Flexible Server": ["Azure", "Ligar banco de dados PostgesSQL", "Postgres"],
        "Stop Azure PostgreSQL Flexible Server": ["Azure", "Desligar banco de dados PostgesSQL", "Postgres"],
        "Resize App Service Plan": ["Azure", "Redimensionar App Service Plan", null]
    };

    automationDataDic = {
        "StartVM": "Ligar máquina virtual",
        "StopVM": "Desligar máquina virtual",
        "ResizeAwsEcsService": "Redimensionar container",
        "ResizeVM": "Redimensionar máquina virtual",
        "ResizeSQLDatabase": "Redimensionar banco de dados",
        "StartSQLDatabase": "Ligar banco de dados",
        "StopSQLDatabase": "Desligar banco de dados",
        "ResizeAzMySQLServer": "Redimensionar banco de dados",
        "StartAzMySqlServer": "Ligar banco de dados",
        "StopAzMySqlServer": "Desligar banco de dados",
        "ResizeAzPostgreSQLServer": "Redimensionar banco de dados",
        "StartAzPostgreSqlServer": "Ligar banco de dados",
        "StopAzPostgreSqlServer": "Desligar banco de dados",
        "ResizeAppServicePlan": "Redimensionar App Service"
    };

    automationsProvidersDic = {
        1: "Azure",
        2: "AWS"
    }

    automationsWeekDic = {
        "SUN": ["dom", "Domingo"],
        "MON": ["seg", "Segunda"],
        "TUE": ["ter", "Terça"],
        "WED": ["qua", "Quarta"],
        "THU": ["qui", "Quinta"],
        "FRI": ["sex", "Sexta"],
        "SAT": ["sáb", "Sábado"],
    };
    async GetAutomations(): Promise<AutomationsScheduler[]> {
        const url = `${this.endpoint}/api/Automations/RetrieveList?refresh=${Math.random()}`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    async GetTemplates() {
        const url = `${this.endpoint}/api/Automations/GetTemplates?refresh=${Math.random()}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async GetProviders() {
        const url = `${this.endpoint}/api/Automations/GetProviders?refresh=${Math.random()}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async GetAutomationRunHistory(id: number) {
        const url = `${this.endpoint}/api/Automations/GetHistory/${id}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async RemoveAutomations(id: number) {
        const url = `${this.endpoint}/api/Automations/Remove/${id}`;
        var result = api({
            method: 'delete',
            url,
        });
        return result;
    }

    async GetResources(provider: number) {
        const url = `${this.endpoint}/api/Automations/GetResources?provider=${provider}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async GetResourcesBySubscription(provider: number, type: string, subscription: string) {
        const url = `${this.endpoint}/api/Automations/GetResourcesBySubscription?provider=${provider}&type=${type}&subscription=${subscription}`;
        const result = await api({
            method: 'get',
            url,
        })

        if (result.status == 200)
            return result.data as AutomationResource[]

        return [] as AutomationResource[]
    }

    getCost = async (params: GetCostParams): Promise<any> => {
        const url = `${this.endpoint}/api/SavingsAndReserves/GetCost`;
        try {
            const response = await api.get<any>(url, {
                params: {
                    provider: params.provider,
                    accsub: params.accsub,
                    region: params.region,
                    serviceCode: params.serviceCode,
                    armSkuName: params.armSkuName,
                    compareArmSkuName: params.compareArmSkuName,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching cost data:', error);
            throw error;
        }
    };

    GetCostKPI = async (): Promise<TaskItemDuration[]> => {
        const url = `${this.endpoint}/api/SavingsAndReserves/GetCostKPI`;
        try {
            const response = await api.get<any>(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching cost data:', error);
            throw error;
        }
    };

    async GetResourceById(provider: number, type: string, subscription: string, resourceId: string) {
        const url = `${this.endpoint}/api/Automations/GetResourceById?provider=${provider}&type=${type}&subscription=${subscription}&resourceId=${resourceId}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async GetInstanceTypesBySubscription(provider: number, type: string, subscription: string) {
        const url = `${this.endpoint}/api/Automations/GetInstanceTypesBySubscription?provider=${provider}&type=${type}&subscription=${subscription}`;
        var result = (await api({
            method: 'get',
            url,
        }))

        if (result.status == 200)
            return result.data as AutomationInstanceType[]

        return [] as AutomationInstanceType[]
    }

    async GetInstanceTypeByLabel(provider: number, type: string, subscription: string, instanceTypeLabel: string) {
        const url = `${this.endpoint}/api/Automations/GetInstanceTypeByLabel?provider=${provider}&type=${type}&subscription=${subscription}&instanceTypeLabel=${instanceTypeLabel}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }

    async CreateAutomation(newAutomation: any) {
        const url = `${this.endpoint}/api/Automations/Save`;
        return api({
            method: 'post',
            url,
            data: newAutomation,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async UpdateAutomation(editAutomation: any) {
        const url = `${this.endpoint}/api/Automations/Update`;
        return api({
            method: 'put',
            url,
            data: editAutomation,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async EnableAutomation(id: number) {
        const url = `${this.endpoint}/api/Automations/Enable/${id}`;
        return api({
            method: 'put',
            url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async DisableAutomation(id: number) {
        const url = `${this.endpoint}/api/Automations/Disable/${id}`;
        return api({
            method: 'put',
            url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async GetAuditRecords(automationId: number) {
        const url = `${this.endpoint}/api/Automations/Audit?automationId=${automationId}`;
        var result = (await api({
            method: 'get',
            url,
        }))
        return result;
    }
}