import React from 'react';
import { CostCenterService } from '../../../services/costCenter/costCenterService'

import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import './editForm.js'
import './orgChart.css'

import Chart from './org'

const LcOrganizationChart: React.FC<any> = (props) => {
    const data = props.data
    const service = new CostCenterService(props);
    const updateData = props.updateData
    const user = useSelector<RootState, UserState>(state => state.user);

    return (
        <>
            <Chart
                editUsers={props.editUsers}
                data={data}
                service={service}
                clientGroupId={user.ClientGroupSelected}
                updateData={updateData}
                showDisabled={props.showDisabled}
                allocated={props.allocated}
            ></Chart>
        </>
    );
}

export default LcOrganizationChart;