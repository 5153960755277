import api, { APIClient } from "../api";

interface Disaster {
  idDisasterRecovery?: number,
  data?: string,
  acao?: string,
  responsavel?: string,
  status?: number,
  idCliente?: number,
  observacao?: string,
  isDeleted?: boolean,
  clientGroupId?: number,
  statusName?: string,
}

export class DisasterRecoveryService extends APIClient {

    constructor(props:any){
      super(props);
    }

    async GetPlans() {
        const url = this.endpoint + `/api/DisasterRecovery/GetDRList`;
        return api({
            method: 'get',
            url, 
        });
    }

    async GetPlansByStatusName(statusName: string) {
        const url = this.endpoint + `/api/DisasterRecovery/GetDRList`;
        return api({
            method: 'get',
            url, 
        }).then((response: any) => {
            let plans: any[] = [];
            response.data.map((Item: Disaster, index: number) => {
                if(Item.statusName === statusName) {
                  plans.push({
                      id: index,
                      data: Item.data && Item.data.split('T')[0],
                      acao: Item.acao,
                      responsavel: Item.responsavel,
                      statusName: Item.statusName,
                      idDisasterRecovery: Item.idDisasterRecovery,
                  });
                }
            });
            return plans;
        });
    }
    
    async GetPlansExceptByStatusName(statusName: string) {
        const url = this.endpoint + `/api/DisasterRecovery/GetDRList`;
        return api({
            method: 'get',
            url, 
        }).then((response: any) => {
            let plans: any[] = [];
            response.data.map((Item: Disaster, index: number) => {
                if(Item.statusName != statusName) {
                  plans.push({
                      id: index,
                      data: Item.data && Item.data.split('T')[0],
                      acao: Item.acao,
                      responsavel: Item.responsavel,
                      statusName: Item.statusName,
                      idDisasterRecovery: Item.idDisasterRecovery,
                  });
                }
            });
            return plans;
        });
    }     

    async DownloadFile() {
        const url = this.endpoint + `/api/DisasterRecovery/GetDRFile`;
        return api({
            method: 'get',
            url,
            responseType: 'arraybuffer',
            headers: {                          
                'Accept': 'application/pdf',
            }
        });
    }

    async PostFile(formFile:any){
        const url = this.endpoint + `/api/DisasterRecovery/PostFile`;
        return api({
            method: 'post',
            url: url,
            data: formFile,
            headers: {                          
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    async DeleteDR(id: number){
        const url = this.endpoint + `/api/DisasterRecovery/Delete?id=${id}`;
        return api({
            method: 'post',
            url,
         
        });
    }
    
    async CreateDR(disasterRecovery: any){
        const url = this.endpoint + `/api/DisasterRecovery/Create`;
        const data = JSON.stringify(disasterRecovery);
        return api({
            method: 'post',
            url,
            data, 
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    async EditDR(disasterRecovery: any){
        const url = this.endpoint + `/api/DisasterRecovery/Edit`;
        const data = JSON.stringify(disasterRecovery);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            } 
        });
    }

}