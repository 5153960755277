import api, { APIClient } from "./api";

export interface FinOpsAlertCostCenter {
    value: string;
    label: string;
}
export interface FinOpsAlertContract {
    value: string;
    label: string;
}
export interface FinOpsAlertUsersToNotify {
    value: string;
    label: string;
}
export interface FinOpsAlertService {
    value: string;
    label: string;
}
export interface FinOpsAlertResource {
    value: string;
    label: string;
}
export interface FinOpsAlertsGroup {
    id: number;
    clientGroupId: number;
    month: number;
    year: number;
    endDate: string | null;
    startDate: string | null;
    version: string;
    type: string;
    name: string;
    updatedByUser: string;
    updatedByUserName: string;
    updatedByUserEmail: string;
    updatedAt: string;
    alerts: FinOpsAlert[];
}

export interface FinOpsAlertsCondition {
    idCondition: number;
    condition: string;
    percentage: number;
    percentageDecimal?: number;
    comparator: string;
    budget?: string
}
export interface FinOpsAlert {
    id: number;
    description: string;
    order: number;
    conditions: FinOpsAlertsCondition[];
    period: {
        idPeriod: number;
        period: string;
        periodValue: number;
        periodUnit: string;
    };
    type: string;
    escope: string;
    frequency?: string;
    highPriority?: boolean;
    ignoreSavingPlansAndResevations?: boolean;
    contracts: FinOpsAlertContract[];
    costCenters: FinOpsAlertCostCenter[];
    resources: FinOpsAlertResource[];
    services: FinOpsAlertService[];
    usersToNotify: FinOpsAlertUsersToNotify[];
    customFilter?: FinOpsAllocationRuleCondition[]
}

export interface ProcessedContractAllocation {
    contract: string;
    total: number;
}
export interface ProcessedAllocatedCostCenter {
    costCenter: string;
    total: number | null;
}

export interface FinOpsAllocationRuleCondition {
    idCondition: number;
    field: string;
    filter: string;
    operation: string;
    logicOperator: string;
}

export interface FinOpsAllocationRuleCostCenter {
    idCostCenterRule: number;
    idCostCenter: string;
    costCenter: string;
    percentage: number;
    percentageLocked?: boolean;
}

export interface FinOpsAllocationRule {
    id: number;
    default: boolean;
    order: number;
    description: string;
    conditions: FinOpsAllocationRuleCondition[];
    costCenters: FinOpsAllocationRuleCostCenter[];
    contracts: FinOpsAllocationRuleContract[];
}

export interface FinOpsAllocationRuleContract {
    idContractRule: number;
    idContract: string;
    contract: string;
    percentage: number;
    percentageLocked?: boolean;
}

export interface FinOpsAllocationGroup {
    id: number;
    clientGroupId: number;
    month: number;
    year: number;
    endDate: string | null;
    startDate: string | null;
    version: string;
    type: string;
    name: string;
    updatedByUser: string;
    updatedByUserName: string;
    updatedByUserEmail: string;
    updatedAt: string;
    rules: FinOpsAllocationRule[];
}

export interface CostCenterModel {
    id: number;
    costCenterId: number;
    parentId: number;
    isMain: boolean;
    name: string;
    parentName: string;
    clientGroupId: number | undefined;
    budget: number | string;
    qtdSubscription: number;
    active?: boolean;
}
export interface TreeCostCenter {
    idArray: number;
    name: string;
    active: boolean;
    baseline: number
    baselineUndefined: boolean,
    budget: number;
    children: TreeCostCenter[]
    clientGroupId: number | undefined;
    id: number;
    nodeContent: boolean;
    tag: string;
    pid: number | undefined
}
export default class FinOpsMapManagerService extends APIClient {

    async GetAzureMarketplaceFields(month: number, year: number) {
        const url = this.endpoint + `/api/FinOps/GetAzureMarketplaceFields?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetAzureReservationFields(month: number, year: number) {
        const url = this.endpoint + `/api/FinOps/GetAzureReservationFields?month=${month}&year=${year}&refresh=${Math.random()}`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    async refreshAllocationContractParquet(month: number, year: number): Promise<any> {
        const url = this.endpoint + `/api/FinOps/RefreshAllocationContractParquet?month=${month}&year=${year}&refresh=${Math.random()}`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    async RefreshAllocationCostCenterParquet(month: number, year: number): Promise<any> {
        const url = this.endpoint + `/api/FinOps/RefreshAllocationCostCenterParquet?month=${month}&year=${year}&refresh=${Math.random()}`;
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    async GetAWSFields(month: number, year: number) {
        const url = this.endpoint + `/api/FinOps/GetAWSFields?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetAzureUsageFields(month: number, year: number) {
        const url = this.endpoint + `/api/FinOps/GetAzureUsageFields?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async RetrieveAllContractsCompany(month: string, year: string) {
        const url = this.endpoint + `/api/FinOps/RetrieveAllContractsCompany?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetReviewCliente(month: string, year: string) {
        const url = this.endpoint + `/api/GTMConfiguration/GetReviewCliente?month=${month}&year=${year}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }
    async GetMapperVersions() {
        const url = this.endpoint + `/api/FinOps/GetMapperVersions?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async GetAllocationVersionsContract() {
        const url = this.endpoint + `/api/FinOps/GetAllocationVersionsContract?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }
    async SaveFieldMapperVersion(finOpsAllocation: any) {
        const url = this.endpoint + `/api/FinOps/SaveFieldMapperVersion`;
        return api({
            method: 'post',
            url,
            data: finOpsAllocation
        }).then((response) => {
            return response.data;
        });
    }

    async SaveFinOpsAllocationContract(finOpsAllocation: any) {
        const url = this.endpoint + `/api/FinOps/SaveFinOpsAllocationContract`;
        return api({
            method: 'post',
            url,
            data: finOpsAllocation
        }).then((response) => {
            return response.data;
        });
    }

    async LoadAllocationContract(version: string) {
        const url = this.endpoint + `/api/FinOps/LoadAllocationContract?Version=${version}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async LoadFieldMapperVersion(version: string) {
        const url = this.endpoint + `/api/FinOps/LoadFieldMapperVersion?Version=${version}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async LoadCostCenters(): Promise<CostCenterModel[]> {
        const url = this.endpoint + `/api/CostCenter/RetrieveListByClientGroupId/ClientGroup?refresh=${Math.random()}`;
        return (await api({
            method: 'get',
            url
        })).data;

    }

    async LoadCostCentersByUserId() {
        const url = this.endpoint + `/api/CostCenter/RetrieveListByClientGroupAndUserId?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }


    async LoadContracts() {
        const url = this.endpoint + `/api/FinancialManagement/GetCotracts?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }
    async GetCotractsWithGTM() {
        const url = this.endpoint + `/api/FinancialManagement/GetCotractsWithGTM?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async GetAllocationVersionsCostCenter() {
        const url = this.endpoint + `/api/FinOps/GetAllocationVersionsCostCenter?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async SaveFinOpsAllocationCostCenter(finOpsAllocation: any) {

        const url = this.endpoint + `/api/FinOps/SaveFinOpsAllocationCostCenter`;
        return api({
            method: 'post',
            url,
            data: finOpsAllocation
        }).then((response) => {
            return response.data;
        });
    }

    async LoadAllocationCostCenter(version: string): Promise<FinOpsAllocationGroup> {
        const url = this.endpoint + `/api/FinOps/GetAllocationCostCenter?Version=${version}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async GetFieldOptions(month: number, year: number, fieldName: string, fieldFilter: string) {
        try {
            const url = this.endpoint + `/api/FinOps/GetFieldOptions?year=${year}&month=${month}&fieldName=${fieldName}&fieldFilter=${fieldFilter}& refresh=${Math.random()}`;
            return (await api({
                method: 'get',
                url,
            })).data;

        } catch (e) {
            return [];
        }
    }

    async GetFieldOptionsResume(month: number, year: number, fieldName: string, fieldFilter: string) {
        try {
            const url = this.endpoint + `/api/FinOps/GetFieldOptionsResume?year=${year}&month=${month}&fieldName=${fieldName}&fieldFilter=${fieldFilter}& refresh=${Math.random()}`;
            return (await api({
                method: 'get',
                url,
            })).data;

        } catch (e) {
            return [];
        }
    }

    async SaveProcessedAlert(alertId: number, situacao: number) {
        var alertChangeRequest = { AlertId: alertId, Situacao: situacao, IdUser: "" };
        const url = this.endpoint + `/api/FinOpsAlert/SaveProcessedAlert`;

        return api({
            method: 'post',
            url,
            data: alertChangeRequest
        }).then((response) => {
            return response.data;
        });
    }

    async LoadProcessedAlert() {
        const url = this.endpoint + `/api/FinOpsAlert/LoadProcessedAlert?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async LoadProcessedAlertApproved() {
        console.log('LoadProcessedAlertApproved')
        const url = this.endpoint + `/api/FinOpsAlert/LoadProcessedAlertApproved?refresh=${Math.random()}`;
        console.log(url)
        var result = (await api({
            method: 'get',
            url
        })).data;
        console.log(result);
        return result;
    }

    async LoadProcessedAlertHistory() {
        const url = this.endpoint + `/api/FinOpsAlert/LoadProcessedAlertHistory?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }
    async GetAlertingVersions() {
        const url = this.endpoint + `/api/FinOpsAlert/GetFinOpsAlertingVersions?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        }).then((response) => {
            return response.data;
        });
    }

    async SaveFinOpsAlerting(finOpsAlerts: any) {
        const url = this.endpoint + `/api/FinOpsAlert/SaveFinOpsAlerting`;
        return api({
            method: 'post',
            url,
            data: finOpsAlerts
        }).then((response) => {
            return response.data;
        });
    }

    async GetFinOpsAlerting(version: string) {
        const url = this.endpoint + `/api/FinOpsAlert/GetFinOpsAlerting?Version=${version}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        }).then((response) => {
            return response.data;
        });
    }

    async GetFieldOptionsAlerts(month: number, year: number, fieldName: string, fieldFilter: string) {
        try {
            const url = this.endpoint + `/api/FinOps/GetFieldOptionsAlerting?year=${year}&month=${month}&fieldName=${fieldName}&fieldFilter=${fieldFilter}&refresh=${Math.random()}`;
            return (await api({
                method: 'get',
                url,
            })).data;

        } catch (e) {
            return [];
        }
    }

}