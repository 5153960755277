import api, { APIClient } from "../api";

export class FileRepositoryService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetListFileRepository(){
        const url = this.endpoint + '/api/FileRepository';
        return api({
            method: 'get',
            url
        })
    }
    
    async PostFileRepository(formFile:any, folderName:any){
        const url = this.endpoint + `/api/FileRepository/PostFileRepository/${folderName}`;
        return api({
            method: 'put',
            url: url,
            data: formFile,
            headers: {                          
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    async DownloadFile(key:string){
        const url = this.endpoint + `/api/FileRepository/Downloadfile?fileKey=${key}`;
        return (await api({
            method: 'get',
            url: url,
            responseType: 'blob', 
        })).data;
    }
}

