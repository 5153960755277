import React, { useEffect, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import EventsGraph from '../resumeEvent/EventsGraph';
import { FinOpsChartService } from '../../../services/finOps/finOpsChartService';
import ReactECharts from 'echarts-for-react';
import { Chart, CollectoresModel, OperacialSystemEvent, ResumeEvent } from '../resumeEvent/Models';
import { statusColor,status } from '.';

interface CoolectingDeviceChartModel {
    collectorsView: string;
    periodSelected: string,
    loading: boolean;
    isHome: boolean
    resumeEventReport: ResumeEvent,
    chartDispositivosGeral: any
    setChartRefDivices: (ref: any) => void
    setSystemOperation: (systemOperation: OperacialSystemEvent[]) => void
}


export const CollectingDeviceChart: React.FC<CoolectingDeviceChartModel> = (
    {
        loading,
        isHome,
        collectorsView,
        periodSelected,
        resumeEventReport,
        chartDispositivosGeral,
        setChartRefDivices,
        setSystemOperation
    }) => {
    const finOpsChartService: FinOpsChartService = (new FinOpsChartService({}));
    const [colectorStatus, setColectorStatus] = useState<any>()
    const [colectorSystem, setColectorSystem] = useState<Chart>()


    const  GetCollectoresforStatusAndOsFamily = (arrayDeObjetos: CollectoresModel[]) => {
        let statusCount = {};
        if(arrayDeObjetos &&arrayDeObjetos.length > 0) {
            arrayDeObjetos.forEach(obj => {
                let osFamily = obj.osFamily;
                let state = obj.state;
            
                let chave = `${osFamily}_${state}`;
                let estadoTraduzido = status(state);
    
                if (statusCount.hasOwnProperty(chave)) {
                    statusCount[chave].count++;
                } 
                else {
                    statusCount[chave] = {
                        count: 1,
                        osFamily: osFamily,
                        state: estadoTraduzido
                    };
                }
            });
        
            let resultado = Object.values(statusCount);
            return resultado;
        } else {
            return []
        }
    }

    const getCollectoresForStatus = (arrayDeObjetos: CollectoresModel[]) => {
        let stateCount = {};
        if(arrayDeObjetos && arrayDeObjetos.length > 0) {
            arrayDeObjetos.forEach(obj => {
                let state = obj.state;
            
                if (stateCount.hasOwnProperty(state)) {
                stateCount[state]++;
                } else {
                // Se não, inicializar a contagem para 1
                stateCount[state] = 1;
                }
            });
        // Mapear os estados para o formato desejado
        let resultado = Object.keys(stateCount).map(state => ({
            name:  status(state),
            value: stateCount[state],
            itemStyle: {
                color: statusColor(status(state))
            }
            }));
            return resultado

        } else {
            return []
        }
    }

    const getCollectorsChart = (resumeEventReport: any[]) => {

        let labels = resumeEventReport.sort((a, b) => {
            if (a.state < b.state) return -1;
            if (a.state > b.state) return 1;
            return 0;
        }).map(labels => labels.osFamily);
        let uniqueLables = [...new Set(labels)]
        let labelsDataset = resumeEventReport.sort((a, b) => {
            if (a.state < b.state) return -1;
            if (a.state > b.state) return 1;
            return 0;
        }).map(labels => labels.state);
        let uniqueLablesDataset = [...new Set(labelsDataset)]

        let collectorsOperationSystem = {
            labels: uniqueLables,
            datasets: uniqueLablesDataset.map((label) => {
                return {
                    // systemLabel: resumeEventReport.coletoresOperationalSystems,
                    type: 'bar',
                    stack: 'Ad',
                    name: label,
                    emphasis: {
                        focus: 'series'
                    },
                    data:
                        resumeEventReport.sort((a, b) => {
                            const osFamilyOrder = {
                                "Windows": 1,
                                "Windows Server": 2,
                                "Linux Server": 3
                            };

                            return osFamilyOrder[a.osFamily] - osFamilyOrder[b.osFamily];
                        }).filter(data => {
                            return data.state === label
                        }).map(i => i.count ? i.count : 0),
                    itemStyle: {
                        color: statusColor(label)
                    }
                }

            }),
        }
        
        setColectorSystem(collectorsOperationSystem);
    }

    useEffect(() => {
        if (!resumeEventReport || !periodSelected) return;
    
        const daysMap = {
            'last7Days': 7,
            'last30Days': 30,
            'last90Days': 90,
            'alltime': null
        };
    
        const days = daysMap[periodSelected as keyof typeof daysMap];
    
        if (days === undefined) {
            console.warn(`Unexpected period value: ${periodSelected}`);
            return;
        }
        const collectors = days === null ? resumeEventReport.listCollectores:
            filterArrayPerDate(resumeEventReport.listCollectores, days);
        
        setColectorStatus(getCollectoresForStatus(collectors));

        const collectorsByOsFamily = days === null ? resumeEventReport.listCollectores :
        filterArrayPerDate(resumeEventReport.listCollectores, days);
        setSystemOperation(GetCollectoresforStatusAndOsFamily(collectorsByOsFamily) as OperacialSystemEvent[])
        getCollectorsChart(GetCollectoresforStatusAndOsFamily(collectorsByOsFamily))
    },[periodSelected, resumeEventReport, setSystemOperation, collectorsView])


    const filterArrayPerDate = (array: CollectoresModel[] , period: number) => {
        let today = new Date();
        let daysAgoDate = new Date();
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0); 
        let datasNosUltimosDias = array.filter(data => {
            return 'lastSeenTime' in data && (data as CollectoresModel).lastSeenTime && new Date((data as CollectoresModel).lastSeenTime) >= daysAgoDate && new Date((data as CollectoresModel).lastSeenTime) <= today;
        });
        return datasNosUltimosDias
    }

    return (
        <LcLoading loading={loading} >
        {
            collectorsView !== 'geral' ?
                <ReactECharts 
                    style={{ minHeight: '100%', minWidth: '100%' }}
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    ref={chartDispositivosGeral}
                    option={finOpsChartService.getBarChartCollectorsStacked(colectorSystem, isHome)}
                />
                :
                <EventsGraph 
                    onRef={setChartRefDivices} 
                    data={colectorStatus}
                    legendOrientation={'vertical'}
                />
        }
        </LcLoading>
    );
}