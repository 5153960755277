export class nf {
    solution() {
        return 'nf'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-finops'
    }

    logotipo() {
        return require('./assets/logotipos/logo_livecloud.svg').default
    }
    titulo() {
        return <>Para acessar as <b>notas fiscais</b>, por gentileza, solicite permissão ao seu administrador</>
    }

    chamada() {
        return <><b>Precisa de Acesso?</b> Se você precisa consultar ou gerenciar notas fiscais mas não tem acesso, a solução é simples: Solicite ao seu administrador. Nossa interface intuitiva e segura garante que o acesso seja concedido de forma rápida e eficaz.</>
    }

    image() {
        return
    }

    detalhes() {
        return <>
            <style dangerouslySetInnerHTML={{
                __html: `
                        /* estilos para a layout */                                            
                        .arrow-more {
                            display: none;
                         }                        
                        .imagem-solucao img {
                            display: none;
                        }
                        .detalhes-solucao {
                            display: none;
                        }
                        .lc-button {
                           display: none;
                        }                        
                `}} />
        </>
    }
}

export default nf;