import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputField, SelectField } from '../../../components/Form/Input';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import FinOpsMapManagerService, { FinOpsAllocationGroup, FinOpsAllocationRule, FinOpsAllocationRuleCondition, FinOpsAllocationRuleContract, ProcessedContractAllocation } from '../../../services/finOpsManagerService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useSnackbar } from 'notistack';
import LcAccordion from '../../../components/Generic/LcAccordion';
import HorizontalDivisor from '../../../components/Generic/HorizontalDivisor';
import VerticalDivisor from '../../../components/Generic/VerticalDivisor';
import moment from 'moment';
import { PPGrid, PPModal } from 'processor-plataform-ui';
import { NotificationCenterService } from '../../../services/notificationCenter/notificationService';

export interface FinOpsValidate {
    name: string;
    validateMessage: string;
    valid: boolean;
    ruleId: number;
    contractId: number;
    conditionId: number;
}

const FinOpsAllocationManagerContractComponent: React.FC = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [finOpsMapManagerService, setFinOpsMapManagerService] = useState<FinOpsMapManagerService>(new FinOpsMapManagerService(props));
    const [allocationVersions, setAllocationVersions] = useState<any[]>([]);
    const [allocationVersionLoading, setAllocationVersionsLoading] = useState<boolean>(false);
    const [allocationResultLoading, setAllocationResultLoading] = useState<boolean>(false);
    const [allocationResultShow, setAllocationResultShow] = useState<boolean>(false);
    const [contractsResultAllocation, setContractsResultAllocation] = useState<ProcessedContractAllocation[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [contractType, setContractType] = useState<any[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<any[]>([]);
    const [tableRules, setTableRules] = useState<FinOpsAllocationRule[]>([]);

    const emptyrecord: FinOpsAllocationRule = {
        id: 0,
        order: 1,
        default: false,
        description: '',
        conditions: [],
        costCenters: [],
        contracts: []
    };

    const emptyRecordCondition: FinOpsAllocationRuleCondition = {
        idCondition: 0,
        field: '',
        operation: '',
        filter: '',
        logicOperator: ''
    };

    const [validations, setValidations] = useState<FinOpsValidate[]>([]);
    const [versionRecord, setVersionRecord] = useState<FinOpsAllocationGroup>(
        {
            id: 0,
            clientGroupId: 0,
            month: 0,
            year: 0,
            endDate: null,
            startDate: null,
            version: '',
            type: '',
            name: '',
            updatedByUser: '',
            updatedByUserName: '',
            updatedByUserEmail: '',
            updatedAt: '',
            rules: []
        });

    const emptyRecordContract: FinOpsAllocationRuleContract = {
        idContractRule: 0,
        idContract: '',
        contract: '',
        percentage: 0,
    };

    const defaultRecord: FinOpsAllocationRule = {
        id: 99999,
        order: 99999,
        default: true,
        description: 'Regra padrão',
        conditions: [{
            idCondition: 1,
            field: 'ResourceID',
            operation: 'notEqual',
            filter: '*',
            logicOperator: 'and'
        }],
        costCenters: [],
        contracts: [{
            idContractRule: 1,
            idContract: '',
            contract: '',
            percentage: 100,
        }]
    };

    const notification = new NotificationCenterService(props);
    const dispatch = useDispatch();
    const sendMessage = (content: string, context: "success" | "warning" | "danger" | "info") => {
        let msg = {
            subject: "Alerta",
            content,
            context,
        };

        notification.PostInterfaceNotification(dispatch, msg);
    }

    async function retrieveData() {
        setAllocationVersionsLoading(true);
        setTableRules([]);
        retrieveContracts();
        setAllocationVersions([]);
        setVersionRecord(
            {
                id: 0,
                clientGroupId: 0,
                month: 0,
                year: 0,
                endDate: null,
                startDate: null,
                version: '',
                type: '',
                name: '',
                updatedByUser: '',
                updatedByUserName: '',
                updatedByUserEmail: '',
                updatedAt: '',
                rules: []
            });
        setAllocationVersionsLoading(true);
        retrieveContracts();
        try {
            var resulBase = await finOpsMapManagerService.GetAllocationVersionsContract();

            var result = resulBase.map((u) => {
                return {
                    "name": u.name.split('/')[3].split('.')[0],
                    "value": u.name.split('/')[3].split('.')[0]
                };
            });

            setAllocationVersions([...result]);
            loadRuleGroup(result[0].value);
        } catch (e) {
            sendMessage("Ops! Parece que os dados não estão prontos, favor tentar mais tarde.", "warning");
            setAllocationVersionsLoading(false);
        }
    }

    useEffect(() => {
        retrieveData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    const finOpsContractType = [{
        "name": "FinOps",
        "value": "finops"
    },
    {
        "name": "Admin",
        "value": "admin"
    },
    {
        "name": "Hosting",
        "value": "hosting"
    }];

    const jsonFinOpsOperation = [
        {
            "name": "Contém",
            "value": "contains"
        },
        {
            "name": "Igual",
            "value": "equal"
        },
        {
            "name": "Inciar com",
            "value": "startWith"
        },
        {
            "name": "Diferente",
            "value": "notEqual"
        },
        {
            "name": "Não Contém",
            "value": "notContains"
        }
    ];

    const jsonFinOpsLogicOperator = [
        {
            "name": "e",
            "value": "and"
        },
        {
            "name": "ou",
            "value": "or"
        }
    ];

    const jsonFinOpsLCFilter = [
        {
            "name": "ProviderName",
            "value": "ProviderName"
        },
        {
            "name": "PayerAccountID",
            "value": "PayerAccountID"
        },
        {
            "name": "AccountId",
            "value": "AccountId"
        },
        {
            "name": "AccountName",
            "value": "AccountName"
        },
        {
            "name": "Date",
            "value": "Date"
        },
        {
            "name": "ResourceID",
            "value": "ResourceID"
        },
        {
            "name": "ResourceName",
            "value": "ResourceName"
        },
        {
            "name": "Region",
            "value": "Region"
        },
        {
            "name": "Service",
            "value": "Service"
        },
        {
            "name": "MeterCategory",
            "value": "MeterCategory"
        },
        {
            "name": "MeterSubCategory",
            "value": "MeterSubCategory"
        },
        {
            "name": "ResourceGroup",
            "value": "ResourceGroup"
        },
        {
            "name": "Tags",
            "value": "Tags"
        },
        {
            "name": "ConsumedQuantity",
            "value": "ConsumedQuantity"
        },
        {
            "name": "ResourceAdditionalInfo",
            "value": "ResourceAdditionalInfo"
        },
        {
            "name": "MeteredCost",
            "value": "MeteredCost"
        },
        {
            "name": "PublisherType",
            "value": "PublisherType"
        }
    ]

    async function refreshAllocationContractParquet() {
        var month = moment().month() + 1;
        var year = moment().year();
        setAllocationResultLoading(true);
        try {
            var resulBaseAllocation = await finOpsMapManagerService.refreshAllocationContractParquet(month, year);
            setContractsResultAllocation(resulBaseAllocation.allocationContract.contratos);
        } catch (e) {
            console.log(e);
        }
        setAllocationResultLoading(false);
        setAllocationResultShow(true);
    }

    async function retrieveContracts() {
        // 
        setAllocationVersionsLoading(true);
        try {
            setContracts([]);
            var resulBase = await finOpsMapManagerService.GetCotractsWithGTM();
            var result = resulBase.sort(
                (element1: any, element2: any) => {
                    return element1.contractName.localeCompare(element2.contractName, 'pt-br');
                }
            ).map((u) => {
                return {
                    "name": u.contractName,
                    "value": u.contractId
                };
            });

            setContracts([...result]);
            setAllocationVersionsLoading(false);
        } catch (e) {
            setContracts([]);
            setAllocationVersionsLoading(false);
        }
    }

    function handleChange(event: any, item: any) {
        const { name, value } = event;
        item[name] = value;
        setTableRules([...tableRules]);
        if (!validateRules()) {
            return;
        }
    }

    function duplicateRuleRecord(item: any | undefined) {

        var newRecord = JSON.parse(JSON.stringify(item));
        let max = 0;
        let maxorder = 0;

        if (tableRules.length > 0) {

            max = Math.max.apply(null, tableRules.map(object => {
                return object.id;
            }));
        }
        newRecord.id = max + 1;
        if (tableRules.length > 0) {
            const idOrders = tableRules.map(object => {
                return object.order;
            });
            maxorder = Math.max.apply(null, idOrders);
        }

        if (item != undefined) {
            maxorder = item.order + 1;
            var items = tableRules.filter(x => x.order > item.order);
            items.forEach(row => {
                row.order = row.order + 2;
            });
        }

        newRecord.order = maxorder + 1;

        tableRules.push({ ...newRecord });
        setTableRules([...tableRules]);
    }

    function addRuleRecord(item: any | undefined) {
        var newRecord = { ...emptyrecord };
        let max = 0;
        let maxorder = 0;

        if (tableRules.length > 0) {

            max = Math.max.apply(null, tableRules.map(object => {
                return object.id;
            }));
        }
        newRecord.id = max + 1;
        if (tableRules.length > 0) {
            const idOrders = tableRules.map(object => {
                return object.order;
            });
            maxorder = Math.max.apply(null, idOrders);
        }

        if (item != undefined) {
            maxorder = item.order + 1;
            var items = tableRules.filter(x => x.order > item.order);
            items.forEach(row => {
                row.order = row.order + 2;
            });
        }

        newRecord.order = maxorder + 1;

        tableRules.push({ ...newRecord });
        setTableRules([...tableRules]);
    }

    function addConditionRecord(parentRule: any) {
        var newRecord = { ...emptyRecordCondition };
        let max = 0;
        if (parentRule.conditions.length > 0) {
            const ids = parentRule.conditions.map(object => {
                return object.idCondition;
            });
            max = Math.max.apply(null, ids);
        }

        newRecord.logicOperator = "and";

        newRecord.idCondition = max + 1;
        parentRule.conditions.push({ ...newRecord });
        setTableRules([...tableRules]);
    }

    function addContractRecord(parentRule: any) {
        var newRecord = { ...emptyRecordContract };
        let max = 0;
        var cloneArr = [...parentRule.contracts];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.idContractRule;
            });
            max = Math.max.apply(null, ids);
        }

        newRecord.idContractRule = max + 1;
        cloneArr.push({ ...newRecord });
        parentRule.contracts = calculatePercentage(cloneArr);
        setTableRules([...tableRules]);
    }

    function removeRecord(item) {
        var result = tableRules.filter(x => x.id != item.id);
        setTableRules([...result]);
    }

    function removeConditionRecord(item, parentRule) {
        parentRule.conditions = parentRule.conditions.filter(x => x.idCondition != item.idCondition);
        setTableRules([...tableRules]);
    }

    function removeContractRecord(item, parentRule) {

        parentRule.contracts = parentRule.contracts.filter(x => x.idContractRule != item.idContractRule);
        parentRule.contracts = calculatePercentage(parentRule.contracts);
        setTableRules([...tableRules]);
    }

    async function saveRuleGroup() {
        versionRecord.rules = tableRules;
        if (!validateRules()) {
            alertValidateError();
            return;
        }

        setAllocationVersionsLoading(true);
        try {
            console.debug(JSON.stringify(versionRecord));
            await finOpsMapManagerService.SaveFinOpsAllocationContract(versionRecord);

        } catch (e) {
        }
        await retrieveData();
        setAllocationVersionsLoading(false);
    }

    async function downRankRule(rule) {
        var rulesBelow = tableRules.filter(c => c.order > rule.order);
        let max = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            max = Math.min.apply(null, ids);
        } else {
            return;
        }
        tableRules.filter(c => c.order == max).map(item => {
            item["order"] = rule.order;
        });
        rule["order"] = max;
        setTableRules([...tableRules]);
    }

    async function upRankRule(rule) {
        var rulesBelow = tableRules.filter(c => c.order < rule.order);
        let min = 0;
        var cloneArr = [...rulesBelow];
        if (cloneArr.length > 0) {
            const ids = cloneArr.map(object => {
                return object.order;
            });
            min = Math.max.apply(null, ids);
        } else {
            return;
        }
        tableRules.filter(c => c.order == min).map(item => {
            item["order"] = rule.order;

        });

        rule["order"] = min;
        setTableRules([...tableRules]);
    }

    function toCamelCase(str) {
        return str
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }

    async function getFieldAutocompleteList(fieldName: string, fieldFilter: string) {

        if (fieldFilter.length > 3) {
            var month = moment().month() + 1;
            var year = moment().year();
            var service = new FinOpsMapManagerService(props);
            var result = await service.GetFieldOptions(month, year, fieldName, fieldFilter);
            var options = result.map((item) => { return { value: item[toCamelCase(fieldName)] }; });
            setOptionsFilter(options);
        }
    }

    async function loadRuleGroup(versionName) {
        setAllocationVersionsLoading(true);
        try {
            var resultData = await finOpsMapManagerService.LoadAllocationContract(versionName);
            if (resultData.rules.filter(c => c.default == true).length == 0) {
                var newdefault = { ...defaultRecord };

                resultData.rules.push(newdefault);
            }
            setVersionRecord(resultData);
            setTableRules([...resultData.rules]);
        } catch (e) {
        }

        setAllocationVersionsLoading(false);
    }

    function alertValidateError() {
        validations.map((item) => {
            enqueueSnackbar(item.validateMessage, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        });
    }

    function validateRules() {
        var validationsChecks: FinOpsValidate[] = [];

        if (!(versionRecord.rules.length > 0)) {
            validationsChecks.push({
                name: 'rules',
                validateMessage: 'Deve possuir pelo menos uma regra cadastrada.',
                valid: false,
                ruleId: 0,
                contractId: 0,
                conditionId: 0
            });
        }
        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.description.length > 0)) {
                    validationsChecks.push({
                        name: 'description',
                        validateMessage: 'Todas as regras devem possuir uma descrição.',
                        valid: false,
                        ruleId: item.id,
                        contractId: 0,
                        conditionId: 0
                    });
                }
            });
        }
        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.conditions.length > 0)) {
                    validationsChecks.push({
                        name: 'conditions',
                        validateMessage: 'Todas as regras devem possuir ao menos uma condição.',
                        valid: false,
                        ruleId: item.id,
                        contractId: 0,
                        conditionId: 0
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.conditions.length > 0)) {
                    item.conditions.map(itemCondition => {
                        if (!(itemCondition.field.length > 0))
                            validationsChecks.push({
                                name: 'conditions',
                                validateMessage: 'Todas as condições devem possuir um campo selecionado.',
                                valid: false,
                                ruleId: item.id,
                                contractId: 0,
                                conditionId: itemCondition.idCondition
                            });
                        if (!(itemCondition.logicOperator.length > 0))
                            validationsChecks.push({
                                name: 'logicOperator',
                                validateMessage: 'Todas as condições devem possuir um operador lógico selecionado.',
                                valid: false,
                                ruleId: item.id,
                                contractId: 0,
                                conditionId: itemCondition.idCondition
                            });
                        if (!(itemCondition.operation.length > 0))
                            validationsChecks.push({
                                name: 'operation',
                                validateMessage: 'Todas as condições devem possuir um operador de comparação selecionado.',
                                valid: false,
                                ruleId: item.id,
                                contractId: 0,
                                conditionId: itemCondition.idCondition
                            });

                        if (!(itemCondition.filter.length > 0))
                            validationsChecks.push({
                                name: 'filter',
                                validateMessage: 'Todas as condições devem possuir um valor para comparação.',
                                valid: false,
                                ruleId: item.id,
                                contractId: 0,
                                conditionId: itemCondition.idCondition
                            });
                    });

                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if (!(item.contracts.length > 0)) {
                    validationsChecks.push({
                        name: 'contracts',
                        validateMessage: 'Todas as regras devem possuir ao menos um centro de custo cadastrado.',
                        valid: false,
                        ruleId: item.id,
                        contractId: 0,
                        conditionId: 0
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    item.contracts.map(itemContract => {
                        if (!(itemContract.idContract?.length > 0)) {
                            validationsChecks.push({
                                name: 'idContract',
                                validateMessage: 'Todos as linhas de contrato nas regras devem possuir um contrato selecionado.',
                                valid: false,
                                ruleId: item.id,
                                contractId: itemContract.idContractRule,
                                conditionId: 0
                            });
                        }
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    item.contracts.map(itemContract => {
                        if (!(itemContract.percentage > 0)) {
                            validationsChecks.push({
                                name: 'percentage',
                                validateMessage: 'Todos os contratos nas regras devem possuir um valor maior que zero na sua porcentagem.',
                                valid: false,
                                ruleId: item.id,
                                contractId: itemContract.idContractRule,
                                conditionId: 0
                            });
                        }
                    });
                }
            });
        }

        if ((versionRecord.rules.length > 0)) {
            versionRecord.rules.map((item) => {
                if ((item.contracts.length > 0)) {
                    var totalPercentage = 0;
                    item.contracts.map(itemContract => {
                        totalPercentage += itemContract.percentage;
                    });
                    if ((totalPercentage != 100)) {
                        validationsChecks.push({
                            name: 'percentage',
                            validateMessage: 'O total da porcentagem cadastrada nos centros de custo para uma regra deve ser igual a 100 %.',
                            valid: false,
                            ruleId: item.id,
                            contractId: 0,
                            conditionId: 0
                        });
                    }

                }
            });
        }
        setValidations(validationsChecks);
        return validationsChecks.length == 0;
    }

    function calculatePercentage(array: FinOpsAllocationRuleContract[]) {
        if (array == null)
            return [];

        if (array.length == 0)
            return array;

        var total = 100;

        var portion = Math.floor(total / array.length) * 100 / 100;

        portion = Math.floor(portion * 100 - portion % 1) / 100;

        for (var i = 0; i < array.length; i++) {
            var percentage = portion;

            if (array.length == (i + 1))
                percentage = total - (portion * (i));

            array[i].percentage = percentage;
        }

        return [...array];
    }

    return (
        <Layout loading={allocationVersionLoading}>
            <PPModal
                visible={allocationResultShow}
                onClose={(item) => { setAllocationResultShow(false); }}
                title={'Resultado alocação de contratos'}
            >
                <PPGrid container spacing={2} justify="center" alignItems='center'>
                    <PPGrid item sm={6} md={6} lg={6} xl={6} xs={6} justify="flex-start" alignItems='flex-start'>
                        <h3 style={{ margin: 0 }}> Contrato</h3>
                    </PPGrid>
                    <PPGrid item sm={6} md={6} lg={6} xl={6} xs={6} justify="flex-start" alignItems='flex-start'>
                        <h3 style={{ margin: 0 }}> Valor alocado </h3>
                    </PPGrid>
                    {contractsResultAllocation && contractsResultAllocation.map((item, index) => {
                        return <>
                            <PPGrid item key={index} sm={6} md={6} lg={6} xl={6} xs={6} justify="flex-start" alignItems='flex-start'>
                                {item.contract}
                            </PPGrid>
                            <PPGrid item key={index} sm={6} md={6} lg={6} xl={6} xs={6} justify="flex-end" alignItems='flex-end'>
                                {item.total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </PPGrid>
                        </>
                    })}
                </PPGrid>
            </PPModal>
            <Grid spacing={1}>
                <Grid item container spacing={1}>
                    <Grid item spacing={2}>
                        <div style={{ paddingTop: '5px' }}>
                            <SelectField label={`Versões`} width={'100%'} name="Version" options={allocationVersions} onChange={(event) => { loadRuleGroup(event.value); }}></SelectField>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ paddingTop: '25px' }}>
                            <LcIconLink icon={`lci lci-save `} onClick={() => { saveRuleGroup(); }} tooltip="Salvar regras" />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ paddingTop: '25px' }}>
                            <LcIconLink icon={`lci lci-plus `} onClick={() => { addRuleRecord(undefined); }} tooltip="Adicionar regra" />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ paddingTop: '25px' }}>
                            {allocationResultLoading && <LcIconLink icon={`lci lci-loading lci-spin`} onClick={() => { }} tooltip="Espere enquanto o processamento é feito" />}

                            {!allocationResultLoading && <LcIconLink icon={`lci lci-play `} onClick={() => { refreshAllocationContractParquet(); }} tooltip="Executar as regras" />}
                        </div>
                    </Grid>

                </Grid>
                <HorizontalDivisor />
                <div style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(100vh-300px)' }} >
                    {tableRules.sort((a, b) => a.order > b.order ? 1 : -1).map(
                        (item, index) => {
                            return (
                                <LcAccordion title={
                                    <Grid container xs={12} direction="row" justifyContent={'space-evenly'} alignItems={'center'} alignContent={'center'}>
                                        <Grid xs={10} direction="row" item spacing={0} justifyContent={'space-evenly'} alignItems={'center'} alignContent={'center'} >
                                            <Grid direction="row" container xs={12} alignContent={'center'} alignItems={'center'}>
                                                {item && item.default != true && <Grid item xs={1} alignContent={'center'}  > {`${item.id}.`} </Grid>}
                                                <Grid item xs={11} alignContent={'center'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    {item && item.default != true && <InputField className={validations.filter(x => x.ruleId == item.id && 'description' == x.name).length > 0 ? 'invalid' : ''} updateParentConst={true}
                                                        width={'100%'} type={'text'} name="description"
                                                        value={item.description} onChange={(event) => { handleChange(event, item); }}></InputField>
                                                    }
                                                    {item && item.default == true && item.description}  </Grid>
                                            </Grid>
                                        </Grid>
                                        {item && item.default != true && <Grid direction="row" item xs={2} alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'}  >
                                            <Grid container xs={12} alignContent={'center'}>
                                                <Grid item xs={2} alignContent={'center'}>
                                                    <LcIconLink size={'small'} icon={`lci lci-plus `} onClick={() => { addRuleRecord(item); }} tooltip="Adicionar regra" />
                                                </Grid>
                                                <Grid xs={2} item >
                                                    <LcIconLink size={'small'} icon={`lci lci-document-duplicate`} onClick={() => { duplicateRuleRecord(item); }} tooltip="Duplicar" />
                                                </Grid>
                                                <VerticalDivisor />
                                                <Grid xs={2} item >
                                                    <LcIconLink size={'small'} icon={`lci lci-trash `} onClick={() => { removeRecord(item); }} tooltip="Deletar" />
                                                </Grid>
                                                <VerticalDivisor />
                                                <Grid item xs={2}>
                                                    <LcIconLink disabled={Math.max.apply(null, tableRules.map(object => {
                                                        return object.order;
                                                    })) == item.order} size={'small'} icon={`lci lci-arrow-down`} onClick={() => { downRankRule(item); }} tooltip="Descer ordem" />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <LcIconLink size={'small'} disabled={Math.min.apply(null, tableRules.map(object => {
                                                        return object.order;
                                                    })) == item.order} icon={`lci lci-arrow-up`} onClick={() => { upRankRule(item); }} tooltip="Subir ordem" />
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                        <HorizontalDivisor />
                                    </Grid>} visible={false} >
                                    <Grid container justifyContent={'space-evenly'} alignItems={'stretch'}>
                                        <Grid container justifyContent={'space-evenly'} alignItems={'stretch'} >
                                            <Grid xs={12} item justifyContent={'space-evenly'} alignItems={'stretch'}>
                                                <Grid item xs={6}>Perfil</Grid>
                                                <Grid item xs={6}>
                                                    <SelectField
                                                        key={index} name="finOpsType"
                                                        options={finOpsContractType}
                                                        onChange={(event) => { handleChange(event, item); }}></SelectField>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} item justifyContent={'space-evenly'} alignItems={'stretch'}>
                                                <Grid item  >
                                                    {item && item.default != true && <div style={{ padding: '5px' }}>
                                                        Condições:
                                                    </div>}
                                                </Grid>
                                                <Grid item>
                                                    {item && item.default != true && <LcIconLink size={'small'} icon={`lci lci-plus `} onClick={() => { addConditionRecord(item); }} tooltip="Adicionar" />
                                                    } </Grid>
                                            </Grid>
                                            {item && item.default != true && item.conditions.map((itemCondition, index) => {
                                                return <>
                                                    <Grid container justifyContent={'space-evenly'} >
                                                        <Grid container justifyContent={'space-evenly'} alignItems={'stretch'} >
                                                            <Grid xs={1} spacing={1} item alignItems={'stretch'}>
                                                                {Math.min.apply(null, item.conditions.map(object => {
                                                                    return object.idCondition;
                                                                })) != itemCondition.idCondition &&
                                                                    <SelectField className={validations.filter(x => x.conditionId == itemCondition.idCondition && x.ruleId == item.id && 'logicOperator' == x.name).length > 0 ? 'invalid' : ''} name="logicOperator" value={itemCondition.logicOperator} options={jsonFinOpsLogicOperator}
                                                                        onChange={(event) => { handleChange(event, itemCondition); }}></SelectField>
                                                                }
                                                            </Grid>
                                                            <Grid xs={1} spacing={1} item alignItems={'stretch'}>
                                                                {Math.min.apply(null, item.conditions.map(object => {
                                                                    return object.idCondition;
                                                                })) != itemCondition.idCondition &&
                                                                    <SelectField className={validations.filter(x => x.conditionId == itemCondition.idCondition && x.ruleId == item.id && 'logicOperator' == x.name).length > 0 ? 'invalid' : ''} name="logicOperator" value={itemCondition.logicOperator} options={jsonFinOpsLogicOperator}
                                                                        onChange={(event) => { handleChange(event, itemCondition); }}></SelectField>
                                                                }
                                                            </Grid>
                                                            <Grid xs={2} item spacing={1} alignItems={'stretch'} >
                                                                <SelectField className={validations.filter(x => x.conditionId == itemCondition.idCondition && x.ruleId == item.id && 'field' == x.name).length > 0 ? 'invalid' : ''}
                                                                    name="field" value={itemCondition.field} options={jsonFinOpsLCFilter}
                                                                    onChange={(event) => { handleChange(event, itemCondition); getFieldAutocompleteList(itemCondition.field, itemCondition.filter); }}
                                                                    onClick={(event) => { getFieldAutocompleteList(itemCondition.field, itemCondition.filter); }}
                                                                ></SelectField>
                                                            </Grid>
                                                            <Grid xs={1} spacing={1} item alignItems={'stretch'}>
                                                                <SelectField className={validations.filter(x => 'operation' == x.name && x.conditionId == itemCondition.idCondition && x.ruleId == item.id).length > 0 ? 'invalid' : ''}
                                                                    name="operation" value={itemCondition.operation} options={jsonFinOpsOperation}
                                                                    onChange={(event) => { handleChange(event, itemCondition); }}></SelectField>
                                                            </Grid>
                                                            <Grid xs={7} item spacing={1} alignItems={'stretch'} >
                                                                <InputField
                                                                    onClick={(event) => { getFieldAutocompleteList(itemCondition.field, itemCondition.filter); }}
                                                                    updateParentConst={true} type={'text'} required={true}
                                                                    className={validations.filter(x => 'filter' == x.name && x.conditionId == itemCondition.idCondition && x.ruleId == item.id).length > 0 ? 'invalid' : ''}
                                                                    name="filter" value={itemCondition.filter}
                                                                    disableAutocomplete={false}
                                                                    onChange={(event) => { handleChange(event, itemCondition); getFieldAutocompleteList(itemCondition.field, event.value); }}
                                                                    listAutocomplete="options"></InputField>
                                                                {optionsFilter && <datalist id="options">
                                                                    {optionsFilter.map((item) => {
                                                                        return <option value={item.value} />;
                                                                    })}
                                                                </datalist>}
                                                            </Grid>
                                                            <Grid xs={1} item spacing={1} alignItems={'stretch'}>
                                                                <Grid xs={12} container spacing={1} alignItems={'stretch'}>
                                                                    <Grid xs={6} item spacing={1} alignItems={'stretch'}>
                                                                        <LcIconLink size={'small'} tooltipPosition={'right'} icon={`lci lci-trash `}
                                                                            onClick={() => { removeConditionRecord(itemCondition, item); }} />
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        {Math.max.apply(null, item.conditions.map(object => {
                                                                            return object.idCondition;
                                                                        })) == itemCondition.idCondition &&
                                                                            <LcIconLink size={'small'} tooltipPosition={'left'} icon={`lci lci-plus `} onClick={() => { addConditionRecord(item); }} tooltip="Adicionar Condição" />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>;
                                            })}
                                            {item && item.default == true && <Grid item>*Os recursos que não forem contemplados pelas demais regras serão alocados no seguinte contrato:</Grid>}
                                        </Grid>
                                        <Grid xs={12} item alignItems={'stretch'}>
                                            <Grid item justifyContent={'space-evenly'}  >
                                                <Grid container >
                                                    <Grid item container>
                                                        <Grid item  >
                                                            {item && item.default != true && <div style={{ padding: '3px' }}>
                                                                Contratos:
                                                            </div>}
                                                        </Grid>
                                                        <Grid item  >
                                                            <div style={{ padding: '3px' }} onClick={() => { calculatePercentage(item.contracts); }}>
                                                                {item && item.default != true && <LcIconLink iconCharacter={'%'} size={'small'} onClick={() => { calculatePercentage(item.contracts); }}
                                                                    tooltip="Recalcular porcentagem" />}
                                                            </div>
                                                        </Grid>
                                                        {item && item.default != true && <Grid justifyContent={'space-evenly'} >
                                                            <LcIconLink size={'small'} icon={`lci lci-plus `} onClick={() => { addContractRecord(item); }}
                                                                tooltip="Adicionar" />
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} item alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} spacing={1}>
                                                    {item.contracts.map((itemContract, index) => {
                                                        return (<Grid xs={12} container alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} spacing={1}>
                                                            <Grid xs={3} item spacing={1} alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                                <Grid container >Contrato:</Grid>

                                                                <Grid item container spacing={1} alignItems={'stretch'} > <Grid container >
                                                                </Grid>
                                                                    <SelectField className={validations.filter(x => x.contractId == itemContract.idContractRule && x.ruleId == item.id && 'idContract' == x.name).length > 0 ? 'invalid' : ''}
                                                                        key={index} name="idContract" value={itemContract.idContract}
                                                                        options={contracts}
                                                                        onChange={(event) => { handleChange(event, itemContract); }}></SelectField>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid xs={3} item alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} spacing={1}>
                                                                <Grid container >Porcentagem: </Grid>
                                                                <InputField className={validations.filter(x => x.contractId == itemContract.idContractRule && x.ruleId == item.id && 'percentage' == x.name).length > 0 || validations.filter(x => x.contractId == 0 && x.ruleId == item.id && 'percentage' == x.name).length > 0 ? 'invalid' : ''}
                                                                    updateParentConst={true} key={index} step="1" type={'number'} name="percentage" value={itemContract.percentage}
                                                                    onChange={(event) => {
                                                                        event.value = parseInt(event.value);
                                                                        handleChange(event, itemContract);
                                                                    }}></InputField>
                                                            </Grid>
                                                            <Grid xs={1} item spacing={1} alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                                {item && item.default != true && <Grid xs={12} container spacing={1} alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                                    <Grid xs={6} item spacing={1} alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                                        <LcIconLink key={index} size={'small'} tooltipPosition={'right'} icon={`lci lci-trash `}
                                                                            onClick={() => { removeContractRecord(itemContract, item); }} />
                                                                    </Grid>
                                                                    <Grid xs={6} item alignContent={'center'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                                        {Math.max.apply(null, item.contracts.map(object => {
                                                                            return object.idContractRule;
                                                                        })) == itemContract.idContractRule && <LcIconLink size={'small'} icon={`lci lci-plus `} onClick={() => { addContractRecord(item); }} tooltip="Adicionar centro de custo" />
                                                                        }
                                                                    </Grid>
                                                                </Grid>}
                                                            </Grid>
                                                            <Grid xs={5} item justifyContent={'space-evenly'} spacing={1}> </Grid>
                                                        </Grid>);
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </LcAccordion>);
                        })}
                </div >
            </Grid >
        </Layout >
    );
};
export default FinOpsAllocationManagerContractComponent;