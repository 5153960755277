import { Grid } from '@material-ui/core';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
 

//Redux
import { useSelector } from 'react-redux';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
 
import FinOpsMapManagerService from '../../../services/finOpsManagerService';
import PivotTableWebix from '../../../components/webix';
import { PiFunnelLight } from 'react-icons/pi';

// the AG Grid React Component

const BillingReviewPivot: React.FC = (props) => {
    function getStatePivot() {
        try {
            var stringsessionStorageGet = sessionStorage.getItem("statePivotTableUIPropsReview");
            var objstatePivotTableUIPropsReview = JSON.parse(stringsessionStorageGet ? stringsessionStorageGet : "") as object;
            if (objstatePivotTableUIPropsReview) {
                return objstatePivotTableUIPropsReview;
            }
        } catch (e) {

        }
        return undefined as unknown as object;
    }

    const finOpsManagerService = new FinOpsMapManagerService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [rows, setRows] = useState<string[]>(['Situação', 'Agente', 'Tipo', 'SubCliente']);//
    const [cols, setCols] = useState<string[]>([]);// 
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>({} as Array<{ [K: string]: string }>);
    const [statePivotTableUIPropsReview, setstatePivotTableUIPropsReview] = useState<object>();

    async function retrieveData() {
        sessionStorage.setItem("statePivotTableUIPropsReview", JSON.stringify({}));

        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            var monthDate = new Date().getMonth() ; 
            var yearDate= new Date().getFullYear();
            if(monthDate == 0){
                yearDate = yearDate-1;
                monthDate= 12;
            }
            var currentMonth = (monthDate  ).toString().padStart(2, '0');
            var currentYear = yearDate.toString();
            setIsLoadingJobs(true);
            if (month) {
                currentMonth = month
            }
            if (year) {
                currentYear = year
            }
            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }

            if (user.ClientGroupSelected && currentMonth && currentYear) {
                var response = (await finOpsManagerService.RetrieveAllContractsCompany(currentMonth, currentYear));
                 
               var data = response.map(x => {
                    x.custoUSD = Number(x.custoUSD);
                    x.custoBRL = Number(x.custoBRL);
                    x.baselineUSD = Number(x.baselineUSD);
                    x.baselineBRL = Number(x.baselineBRL);
                    x.totalValueUSD = Number(x.totalValueUSD);
                    x.totalValueBRL = Number(x.totalValueBRL);
                    x.taxesUSD = Number(x.taxesUSD);
                    x.taxesBRL = Number(x.taxesBRL);
                    return x;
                });

                setDataPivot(data);
                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.log(error);
        };
    }

    useEffect(() => {
        retrieveData();
    }, [month, year, user.ClientGroupSelected,]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
          
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]   ,   default: true
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };


    return (
        <Layout
            pivot
            pageTitle="Billing Global Review"
            periodSelection={periodSelection}
            functionsGeneric={[{ icon: <PiFunnelLight />, onClick: () => setModalVisible(!modalVisible), tooltip: "Filtro" }]}
        >
            <LcLoading label="Carregando..." loading={isLoadingJobs}>

                {
                    dataPivot && dataPivot.length > 0 &&
                    <>
                        <div style={{ width: '100%', height: '25px', backgroundColor: '#e0ecfe', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ color: 'var(--text-default)', fontSize: '10pt' }}>
                                Abaixo é apresentado o detalhamento de todos os Custos executados no mês
                            </span>
                        </div>

                        <div style={{ maxWidth: "100%", maxHeight: "calc(100% - 78px)" }}>
                            {dataPivot && dataPivot.length > 0 &&
                                <PivotTableWebix
                                    idView={'dataPivot'}
                                    key={'dataPivot'}
                                    data={dataPivot}
                                fields={[
                                    { id: "nome_Conta", value: "nome_Conta", type: "text" },
                                    { id: "contaCorporativaId", value: "contaCorporativaId", type: "text" },
                                    { id: "contaCorporativa", value: "contaCorporativa", type: "text" },
                                    { id: "clientDescription", value: "clientDescription", type: "text" },
                                    { id: "clientGroupId", value: "clientGroupId", type: "text" },
                                    { id: "numeroSIP", value: "numeroSIP", type: "text" },
                                    { id: "provedor", value: "provedor", type: "text" },
                                    { id: "currencyRate", value: "currencyRate", type: "text" },
                                    { id: "contractCurrency", value: "contractCurrency", type: "text" }, 
                                    { id: "custoUSD", value: "custoUSD", type: "number" },
                                    { id: "custoBRL", value: "custoBRL", type: "number" },
                                    { id: "totalValueBRL", value: "totalValueBRL", type: "number" },
                                    { id: "totalValueUSD", value: "totalValueUSD", type: "number" }

                                ]}
                                structure={{
                                    rows: ["provedor", "nome_Conta", "clientGroupId" ], 
                                    values: [{ name: "totalValueBRL", operation: ["sum"] },
                                        { name: "totalValueUSD", operation: ["sum"] },
                                        { name: "custoUSD", operation: ["sum"] }]
                                }}
                            />}
                        </div>
                    </>
                }
            </LcLoading>

        </Layout>
    );
};

export default BillingReviewPivot;