import React, { useEffect, ChangeEvent, useState, useRef } from 'react';

import './index.css';
import { Button, Card, CardActions, CardContent, LinearProgress, FormGroup, Input,  NativeSelect } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { DeleteSharp, FormatAlignRightTwoTone, Label } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Layout from '../../../components/Layout/Layout';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import DynamicForm from '../../../components/Layout/DynamicForm/DynamicForm';
import LiveWatchServiceGroupService from '../../../services/LiveWatchServiceGroupService';
import LiveWatchServices from '../Service';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PiDotsThreeVertical } from 'react-icons/pi';

function getCurrentDate() {
    let date = new Date();
    let fullYear = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = date.getDate();
    let formatedDate = fullYear + "-" + month + "-" + day;
    return formatedDate;
}

const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const LiveWatchServiceGroups: React.FC = (props) => {
    const constructLiveWatchServiceGroupService = (): LiveWatchServiceGroupService => {
        return new LiveWatchServiceGroupService(props);
    }
    const initTemplate = async () => {
        let template = await ServiceInstance.template(ServiceInstance.emptyRecord, true);
        setTemplate && setTemplate(template);
    }

    const [ServiceInstance, setServiceInstance] = useState<LiveWatchServiceGroupService>(constructLiveWatchServiceGroupService());

    const user = useSelector<RootState, UserState>(state => state.user);

    useSingleton(() => {
        setServiceInstance && setServiceInstance(constructLiveWatchServiceGroupService());
        initTemplate();
    });

    const [ListData, setListData] = useState<any[]>([]);
    const [ListDataFiltered, setListDataFiltered] = useState<any[]>([]);
    const [visibleListDataFiltered, setVisibleListDataFiltered] = useState<any[]>([]);
    const [counter, setCounter] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingMainTable, setIsLoadingMainTable] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snack, setSnack] = React.useState<any>({});
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 25, term: "", column: "", direction: "asc" });
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        members: false,
        record: ServiceInstance.emptyRecord
    };

    const [record, setRecord] = useState(emptyRecordMessage);
    const [template, setTemplate] = useState<any>({
        title: '',
        Fields: []
    });

    async function selecionadoDeletar(id: any) {
        setIsLoading(true);
        if (!window.confirm("Confirmar a exclusão dos selecionados."))
            return;

        ServiceInstance.Deletar(id).then((items: any) => {
            enqueueSnackbar(`Grupo de serviço removido com sucesso`, {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            });
        }).then(() => { setRecord({ ...emptyRecordMessage }); resetData(); setIsLoading(false); });
    }

    const columns = [
        { field: 'name', headerName: 'Nome', width: "30%" },
        { field: 'description', headerName: 'Descrição', width: "70%" },
        {
            field: 'settings', with: '38px', overflow: "visible",
            renderCell: (row: any) => {
                return (
                    <LcDropDown element={<LcIconLink icon={<PiDotsThreeVertical />} />} position="right">
                        <span className="item" onClick={() => selecionadoEditar(row)}>Editar</span>
                        <span className="item" onClick={() => selecionadoDeletar(row.id)}>Excluir</span>
                    </LcDropDown>
                );
            }
        }
    ];

    const onSearch = {
        inputType: 'text',
        eventOnSearch: (e: any) => { }
    };

    let functions = {
        functionsList: [
            {
                id: "9ea771a4-4744-4755-8d25-725a3744c2d8",
                title: 'Novo',
                tooltip: 'Novo',
                icon: 'ppi ppi-plus',
                func: (ids: []) => {
                    ADD();
                },
                minSelectedRowNumber: 0,
                disabled: false,
            },
        ],
        functionsEdit: [{
            id: "e4447261-2e2b-45c7-a2c8-cbc0f7c0501c",
            title: 'Salvar',
            tooltip: 'Salvar',
            icon: 'lci lci-check',
            func: (item: any) => {
                setIsLoading(true);
                ServiceInstance.CreateServiceGroup(item)
                    .then(response => {
                        if (response.status == 200) {
                            enqueueSnackbar(`Grupo de serviço criado com sucesso`, {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                            setRecord({ ...emptyRecordMessage });
                        }
                    })
                    .catch((error) => { console.log(error); })
                    .then(() => { resetData(); setIsLoading(false); });
            },
            disabled: false,
            skipvalidation: false
        },
        {
            id: "0ca20f50-02c1-4368-a27c-c22ff7549731",
            title: 'Fechar',
            tooltip: 'Fechar',
            icon: 'lci lci-x',
            func: () => {
                setRecord({ ...emptyRecordMessage });
            },
            disabled: false,
            skipvalidation: true
        }]
    };

    const selecionadoEditar = (item: any) => {
        if (item != null) {
            EDIT(item);
        }
        else {
            setRecord({ ...emptyRecordMessage })
        }
    }
    const ADD = async () => {
        let addRecord = { ...emptyRecordMessage };
        addRecord.record = { ...ServiceInstance.emptyRecord };
        let template = await ServiceInstance.template(ServiceInstance.emptyRecord, false);
        setTemplate && setTemplate(template);
        addRecord.edit = false;
        addRecord.add = true;
        setRecord({ ...addRecord });
    };

    const EDIT = async (item: any) => {
        let addRecord = record;
        let template = await ServiceInstance.template(ServiceInstance.emptyRecord, true);
        setTemplate && setTemplate(template);
        addRecord.edit = true;
        addRecord.add = false;
        addRecord.detail = false;
        addRecord.record = item;
        setRecord({ ...addRecord });
    };

    const handleDeleteClose = (deleteConfirm: boolean) => {
        if (deleteConfirm == true) {

        }

        if (deleteConfirm == false) {

        }
        setOpen(false);
    };
    const handleActiveClickOpen = () => {
        setOpen(true);
    };

    function loadData() {
        setIsLoadingMainTable(true);
        setListDataFiltered([]);
        ServiceInstance.RetrieveList().then((tt: any) => {
            setListData(tt.data);
            setListDataFiltered(tt.data);
            setIsLoadingMainTable(false);
        }).catch((tt) => { });
    }

    useEffect(() => {
        loadData();
    }, [counter, user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = ListData.filter((p: any) =>
            filter.term == '' ||
            p.name?.toLowerCase().indexOf(filter.term) > -1 ||
            p.description?.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setListDataFiltered(filtered);
        setVisibleListDataFiltered(filtered.slice(0, filter.size));
    }, [filter, ListDataFiltered.length > 0]);

    const [handleform, setHandleform] = useState<any>({});

    function resetData() {
        var counterPlus = counter + 1;
        setCounter(counterPlus);
    }

    const interfaceTyped: { [key: string]: any } = { ...record.record };

    const filterAdvanced = {
        fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleListDataFiltered(ListDataFiltered.slice(0, size))
        else {
            setVisibleListDataFiltered([...visibleListDataFiltered, ...ListDataFiltered.slice(visibleListDataFiltered.length, visibleListDataFiltered.length + 10)])
        }
    };

    return (
        <Layout pageTitle="Cadastro policies aplicáveis">
            {
                (record.edit != true && record.add != true) && ListData &&
                <LcInfiniteTable
                    loading={isLoadingMainTable}
                    columns={columns}
                    functionGeneric={functions.functionsList}
                    rows={visibleListDataFiltered}
                    filter={filterAdvanced}
                    size={ListDataFiltered.length}
                    loadMore={loadMore}
                    onSortChange={onSortChange} />
            }
            <DialogConfirmForm
                onCloseEvent={handleDeleteClose}
                open={open}
                confirmMessage='Confirmar'
                abortMessage='Cancelar'
                title='Confirmar?'
                content='' />
            {
                record && (record.add || record.edit) &&
                <DynamicForm
                    loading={isLoading}
                    header={(record != null && record.record != null) ? `Grupo de Serviço ${interfaceTyped[template.title]}` : 'Grupo de Serviço'}
                    functions={functions.functionsEdit}
                    data={record.record}
                    template={template}
                    visible={true}
                    EditForm={true}
                    onClose={() => { setRecord(emptyRecordMessage); resetData(); }} >

                    {record.record.id > 0 && <LiveWatchServices serviceGroupId={record.record.id} />}
                </DynamicForm>
            }
        </Layout >
    );
}

export default LiveWatchServiceGroups;