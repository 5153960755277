import React, { useEffect, useState } from 'react';

import './index.css';

interface Props {
    title: React.ReactNode,
    visible: boolean,
    onChange?: Function,
    backGround?: boolean,
    header?: React.ReactNode,
    children?: React.ReactNode
    customIcon?: React.ReactNode
}

const LcAccordion: React.FC<Props> = (props) => {

    const [visible, setVisible] = useState<boolean>(props.visible);

    useEffect(() => {
        setVisible(props.visible)
    },[props.visible])

    return (
        <div className={`lc-accordion ${visible ? 'visible' : ''} ${props.backGround ? 'accordionBackground' : ''}`} >
            <div className={`header ${props.backGround ? 'fontSize' : ''}`} onClick={() => { setVisible(!visible); }} >
                <div className={`chevron ${!props.customIcon ? 'rotate' : ''}` }>
                    {
                        props.customIcon ?
                        <>
                            {props.customIcon}
                        </>
                        :
                        <i className="lci lci-chevron-down-alt" />
                    }
                </div>    
                <div className="title">{props.title}</div>
            </div>
            <div className={`body ${!visible ? 'collapsed' : ''}`}>
                {props.children}
            </div>
        </div >
    );
};

export default LcAccordion;