import React from 'react';

import Layout from '../../components/Layout/Layout';

// import { Container } from './styles';

const Error404: React.FC = () => {
    return (
        <Layout>
            <p>Caminho não encontrado</p>
        </Layout>
    );
}

export default Error404;