import React, { useCallback, useEffect, useState } from 'react';
import { PiDatabaseLight, PiMonitorLight, PiPackageLight } from 'react-icons/pi';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

interface WorkEnvironmentProps {
    isHome: boolean
    isSecops: boolean
    setIot: (iot: any) => void
    setServers: (servers: any) => void
    setWorkstations: (servers: any) => void
}

export interface EnvironmentData {
    allocated: number
    inUse: number
    sip: number
}

export const WorkEnvironment: React.FC<WorkEnvironmentProps> = (
    {
        isHome, 
        isSecops,
        setIot, 
        setServers,
        setWorkstations
    }) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [serversData, setServersData] = useState<EnvironmentData>();
    const [iotData, setIotData] = useState<EnvironmentData>();
    const [workstationsData, setWorkstationsData] = useState<EnvironmentData>();
    const [loading, setLoading] = useState<boolean>(true);

    const getWorkEnviromentData =  useCallback(async() => {
        setLoading(true)
        try {
            const endpointDetectionResponseService = new EndpointDetectionResponseService({});
            const response = await endpointDetectionResponseService.RetrieveEndpointSecurityMetrics();
            if ((response && response.data)) {
                setIotData(response.data.iot);
                setIot(response.data.iot);
                setServersData(response.data.servers);
                setServers(response.data.servers);
                setWorkstationsData(response.data.workstations);
                setWorkstations(response.data.workstations);
            }
            
        } catch (error) {
            let defaultValue = {
                allocated: 0,
                inUse: 0,
                sip: 0
            }
            setIotData(defaultValue);
            setServersData(defaultValue);
            setWorkstationsData(defaultValue);
            console.error(error)
            throw error;
        } finally {
            setLoading(false);
        }
    },[setIot, setServers, setWorkstations])

    useEffect(() => {
        getWorkEnviromentData();
    },[getWorkEnviromentData, user.ClientGroupSelected, user.refreshFlag])


    return (
        <LcLoading loading={loading} >
            <div className='divWorkStations'>
            {serversData && serversData.inUse >= 0 && serversData.sip >= 0 ? (
                <IconValue
                    icon={<PiDatabaseLight size={isHome? 24 : 22}/>}
                    value={serversData ? `${serversData.inUse} / ${serversData.sip}*` : `${0} de ${0}*`}
                    color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                    cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                    description={"Servers"} isHome={isHome}
                    />
                )
                :
                <LcNoData size="small" />
            }
            {workstationsData && workstationsData.inUse >= 0 && workstationsData.sip >= 0 ?
                <IconValue
                icon={<PiMonitorLight size={isHome? 24 : 22} />}
                value={workstationsData ? `${workstationsData.inUse} / ${workstationsData.sip}*` : `${0} de ${0}*`}
                color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                description={"Workstations"} isHome={isHome}
                />
                :
                <LcNoData size="small" />
            }
        
            {iotData && iotData.inUse >= 0 && iotData.sip >= 0 ?
                    <IconValue
                        cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                        icon={<PiPackageLight size={isHome? 24 : 22} /> }
                        value={iotData ? `${iotData.inUse} / ${iotData.sip}*` : `${0} de ${0}*`}
                        color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                        description={"IoT"}
                        isHome={isHome}
                    />
                    :
                    <LcNoData size="small" />
            }
        </div>
        <p className={(isHome || isSecops) ? 'legendWorkStations' :'legendWorkStations colored'}>* Utilizado / contratado</p>
    </LcLoading>
    );
}
