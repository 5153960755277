import React from 'react';
import { Box } from '@mui/material';
import EditAssistantForm from '../EditAssistantForm';
import { Assistant } from '../../../services/AIx/assistantsTypes';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { ThreadDialog } from '../ThreadDialog';

interface AssistantEditDialogProps {
    assistant: Assistant | null;
    handleCloseEditForm: () => void;
}

const AssistantEditDialog: React.FC<AssistantEditDialogProps> = ({
    assistant,
    handleCloseEditForm,
}) => {
    if (!assistant) return (<></>);

    return (
        <div className="edit-container">
            <Box sx={{ height: 'calc(100vh - 180px)', zIndex: 100 }}>
                <LCDashboard
                    cards={[

                        {
                            type: "Custom",
                            position: {
                                RowSpan: 4,
                                ColSpan: 6,
                                row: 1,
                                col: 7,
                            },
                            showFocusButton: true,
                            title: () => <span>Chat {assistant.name}</span>,
                            customContentRender: () => (
                                <ThreadDialog
                                 
                                    assistantId={assistant.id}
                                    assistantName={assistant.name}
                                />
                            ),
                        }, {
                            type: "Custom",
                            position: {
                                RowSpan: 4,
                                ColSpan: 6,
                                row: 1,
                                col: 1,
                            },
                            showFocusButton: false,
                            title: () => <span>{assistant.id ? 'Editar' : 'Criar'}</span>,
                            customContentRender: () => (
                                <EditAssistantForm
                                    key={assistant.id}
                                    handleClose={handleCloseEditForm}
                                    assistant={assistant}
                                />
                            ),
                        },
                    ]}

                    rightSidePanel={{
                        title: "",
                        content: () => { },
                        pinned: false,
                        show: false,
                    }}
                />
            </Box>
        </div>
    );
};

export default AssistantEditDialog;
