import React from 'react';
import ReactECharts from 'echarts-for-react';
import LcNoData from '../../../../components/Generic/LcNoData';
import { SubscriptionData } from '../../../../services/saas/SaasModel';

interface Props {
    subscriptionData: SubscriptionData;
    isHome?: boolean
    onEvents?: any
    horizontal?: boolean
}

const SubscriptionComercial: React.FC<Props> = ({ subscriptionData, isHome, onEvents, horizontal }) => {
    const screenWidth = window.innerWidth;
    let fontSize = screenWidth <= 1400 ? 10 : 12;
    var subscriptions = subscriptionData.subscriptions
        .sort((a, b) => {
            let percentageA = a.consumedUnits;
            let percentageB = b.consumedUnits;
            return percentageB - percentageA;
        });

    const optsChart = {
        title: {
            text: isHome ? 'Softwares no momento' : undefined,
            right: "0",
            top: "0",
            textStyle: {
                fontSize: 11,
                fontWeight: 'normal',
                color: '#8b90a3',
                fontFamily: 'Ubuntu',
            },
            backgroundColor: isHome ? '#ffffff90' : 'transparent',
            borderRadius: [10],
            padding: [4, 8, 4, 8],
            itemGap: 8
        },
        grid: {
            top: isHome ? '40px' : '20px',
            bottom: isHome ? '50px' : '35%',
            left: '1%',
            right: '1%',
            containLabel: isHome ? false : true,
        },
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 10,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                let tooltip = "<b>" + params[0].axisValueLabel + "</b><br>";
                let consumedIndex = params.findIndex(p => p.seriesName === 'Atribuídas');
                let contractedIndex = params.findIndex(p => p.seriesName === 'Contratadas');

                if (consumedIndex !== -1 && contractedIndex !== -1) {
                    let consumedUnits = params[consumedIndex].value;
                    let contractedUnits = params[contractedIndex].value;
                    let percentage = ((consumedUnits / contractedUnits) * 100).toFixed(2);

                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
                    tooltip += "Porcentagem: " + percentage + "%";
                } else if (consumedIndex !== -1 && contractedIndex === -1) {
                    let consumedUnits = params[consumedIndex].value;
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
                } else {
                    let contractedUnits = params[contractedIndex].value;
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
                }
                return tooltip;
            }
        },
        xAxis: {
            type: 'category',
            data: subscriptions.map(item => item.displayName.trim()),
            axisLabel: {
                interval: 0,
                formatter: function (value) {
                    return value.length > 9 ? value.replace("Microsoft", "").slice(0, 9) + "..." : value;
                },
                fontSize: fontSize,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            show: true,
        },
        yAxis: {
            axisLabel: {
                fontSize: fontSize,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            show: isHome ? false : true,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                },
            },
        },
        legend: {
            data: ['Atribuídas', 'Contratadas'],
            width: '100%',
            right: 'center',

            textStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: isHome ? 9 : fontSize,
            },
            bottom: isHome ? '5px' : '18%',
            inactiveColor: '#ededed',
            inactiveBorderColor: '#ededed',
            icon: 'circle',
        },
        series: [
            {
                name: 'Atribuídas',
                type: 'bar',
                //barGap: '20%',
                itemStyle: {
                    color: '#471fcc',
                    opacity: 0.85
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    color: '#2B304F',
                    formatter: '{c}',
                    textStyle: {
                        color: '#9a9fb0', // Cor do texto
                        fontFamily: 'Ubuntu', // Fonte do texto
                        fontSize: 9, // Tamanho da fonte
                    },
                },
                data: subscriptions.map(item => item.consumedUnits)
            },
            {
                name: 'Contratadas',
                type: 'bar',
                //barGap: '-100%',
                itemStyle: {
                    color: '#9FA3FF',
                    opacity: 0.85
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1
                    }
                },
                label: {
                    show: true, // Mostra o label
                    position: 'top', // Posição do label acima da barra
                    color: '#2B304F', // Cor do texto
                    formatter: '{c}', // Formatação do conteúdo do label para mostrar o valor da barra
                    textStyle: {
                        color: '#9a9fb0', // Cor do texto
                        fontFamily: 'Ubuntu', // Fonte do texto
                        fontSize: 9, // Tamanho da fonte
                    },
                },
                data: subscriptions.map(item => item.prepaidUnid)
            },
        ],
        dataZoom: subscriptions.length > 3 ? [{
            type: 'slider',
            filterMode: 'filter',
            start: 0,
            end: ((isHome ? 3 : 5) / subscriptions.length * 100) - 5,
            height: 12,
            show: !isHome,
            bottom: '15px',
            zoomLock: true,
            showDataShadow: false,
            showDetail: false,
        }] : []
    }

    const optsUsageChartHorizontalBars = {
        title: {
            text: isHome ? 'Softwares no momento' : undefined,
            right: "0",
            top: "0",
            textStyle: {
                fontSize: 11,
                fontWeight: 'normal',
                color: '#8b90a3',
                fontFamily: 'Ubuntu',
            },
            backgroundColor: isHome ? '#ffffff90' : 'transparent',
            borderRadius: [10],
            padding: [4, 8, 4, 8],
            itemGap: 8
        },
        grid: {
            top: '10%',
            bottom: '5%',
            left: '0%',
            right: '15%',
            containLabel: true,
        },
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                let tooltip = "<b>" + params[0].axisValueLabel + "</b><br>";
                let consumedIndex = params.findIndex(p => p.seriesName === 'Atribuídas');
                let contractedIndex = params.findIndex(p => p.seriesName === 'Contratadas');

                if (consumedIndex !== -1 && contractedIndex !== -1) {
                    let consumedUnits = params[consumedIndex].value;
                    let contractedUnits = params[contractedIndex].value;
                    let percentage = ((consumedUnits / contractedUnits) * 100).toFixed(2);

                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
                    tooltip += "Porcentagem: " + percentage + "%";
                } else if (consumedIndex !== -1 && contractedIndex === -1) {
                    let consumedUnits = params[consumedIndex].value;
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
                } else {
                    let contractedUnits = params[contractedIndex].value;
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
                }
                return tooltip;
            }
        },
        yAxis: {
            type: 'category',
            data: subscriptions.map(item => item.displayName.trim()),
            axisLabel: {
                fontSize: fontSize,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                formatter: function (value) {
                    return value.length > 9 ? value.replace("Microsoft", "").slice(0, 9) + "..." : value;
                }
            },
            show: true,
            inverse: true,
        },
        xAxis: {
            type: 'value',
            show: false,
            axisLabel: {
                fontSize: fontSize,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        legend: {
            data: subscriptions.length > 0 ? ['Atribuídas', 'Contratadas'] : [],
            width: '100%',
            right: 'center',
            textStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: isHome ? 9 : fontSize,
            },
            bottom: isHome ? '5px' : '3%',
            inactiveColor: '#ededed',
            inactiveBorderColor: '#ededed',
            icon: 'circle',
        },
        series: [
            {
                name: 'Atribuídas',
                type: 'bar',
                data: subscriptions.map(item => item.consumedUnits),
                itemStyle: {
                    color: '#471fcc',  // Color scheme from optsChart
                    opacity: 0.85
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1
                    }
                },
                label: {
                    show: true,
                    position: 'right',
                    color: '#2B304F',
                    formatter: '{c}',
                    textStyle: {
                        color: '#9a9fb0',
                        fontFamily: 'Ubuntu',
                        fontSize: 9,
                    },
                },
            },
            {
                name: 'Contratadas',
                type: 'bar',
                data: subscriptions.map(item => item.prepaidUnid),
                itemStyle: {
                    color: '#9FA3FF',  // Color scheme from optsChart
                    opacity: 0.85
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1
                    }
                },
                label: {
                    show: true,
                    position: 'right',
                    color: '#2B304F',
                    formatter: '{c}',
                    textStyle: {
                        color: '#9a9fb0',
                        fontFamily: 'Ubuntu',
                        fontSize: 9,
                    },
                },
            },
        ],
        dataZoom: subscriptions.length > 3 ? [{
            type: 'slider',
            yAxisIndex: 0,
            filterMode: 'filter',
            start: 0,
            end: ((isHome ? 3 : 6) / subscriptions.length * 100) - 5,
            showDataShadow: false,
            left: '93%',
            width: 15,
            showDetail: false,
            orient: 'vertical',
            zoomLock: true,
        }] : []
    }

    // const opt = {
    //     title: {
    //         right: "0",
    //         top: "0",
    //         textStyle: {
    //             fontSize: 11,
    //             fontWeight: 'normal',
    //             color: '#8b90a3',
    //             fontFamily: 'Ubuntu',
    //         },
    //         backgroundColor:'transparent',
    //         borderRadius: [10],
    //         padding: [4, 8, 4, 8],
    //         itemGap: 8
    //     },
    //     grid: {
    //         top:  '20px',
    //         bottom:  '33%',
    //         left: '1%',
    //         right: '1%',
    //         containLabel:  true,
    //     },
    //     tooltip: {
    //         backgroundColor: '#f1f5fb',
    //         borderWidth: 0,
    //         textStyle: {
    //             fontSize: 10,
    //             fontFamily: 'Ubuntu',
    //             color: '#2B304F',
    //         },
    //         trigger: 'axis',
    //         axisPointer: {
    //             type: 'shadow'
    //         },
    //         formatter: function (params) {
    //             let tooltip = "<b>" + params[0].axisValueLabel + "</b><br>";
    //             let consumedIndex = params.findIndex(p => p.seriesName === 'Atribuídas');
    //             let contractedIndex = params.findIndex(p => p.seriesName === 'Contratadas');

    //             if (consumedIndex !== -1 && contractedIndex !== -1) {
    //                 let consumedUnits = params[consumedIndex].value;
    //                 let contractedUnits = params[contractedIndex].value;
    //                 let percentage = ((consumedUnits / contractedUnits) * 100).toFixed(2);

    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
    //                 tooltip += "Porcentagem: " + percentage + "%";
    //             }
    //             return tooltip;
    //         }
    //     },
    //     xAxis: {
    //         type: 'category',
    //         data: subscriptions.map(item => item.displayName.trim()),
    //         axisLabel: {
    //             interval: 0,
    //             formatter: function (value) {
    //                 return value.length > 9 ? value.replace("Microsoft", "").slice(0, 9) + "..." : value;
    //             },
    //             fontSize: fontSize,
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //         },
    //         show: true,
    //     },
    //     yAxis: {
    //         axisLabel: {
    //             fontSize: fontSize,
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //         },
    //         show: true,
    //         splitLine: {
    //             show: true,
    //             lineStyle: {
    //                 type: 'dashed'
    //             },
    //         },
    //     },
    //     legend: {
    //         data: ['Atribuídas', 'Contratadas'],
    //         width: '100%',
    //         right: 'center',
    //         itemGap: 20,
    //         textStyle: {
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //             fontSize: fontSize,
    //         },
    //         bottom: '15%',
    //         inactiveColor: '#ededed',
    //         inactiveBorderColor: '#ededed',
    //         icon: 'circle',
    //     },
    //     series: [
    //         {
    //             name: 'Atribuídas',
    //             type: 'bar',
    //             //barGap: '20%',
    //             itemStyle: {
    //                 color: '#471fcc',
    //                 opacity: 0.85
    //             },
    //             emphasis: {
    //                 itemStyle: {
    //                     opacity: 1
    //                 }
    //             },
    //             label: {
    //                 show: true,
    //                 position: 'top',
    //                 color: '#2B304F',
    //                 formatter: '{c}',
    //                 textStyle: {
    //                     color: '#a8acba', // Cor do texto
    //                     fontFamily: 'Ubuntu', // Fonte do texto
    //                     fontSize: 9, // Tamanho da fonte
    //                 },
    //             },
    //             data: subscriptions.map(item => item.consumedUnits)
    //         },
    //         {
    //             name: 'Contratadas',
    //             type: 'bar',
    //             //barGap: '-100%',
    //             itemStyle: {
    //                 color: '#a8acba',
    //                 opacity: 0.85
    //             },
    //             emphasis: {
    //                 itemStyle: {
    //                     opacity: 1
    //                 }
    //             },
    //             label: {
    //                 show: true, // Mostra o label
    //                 position: 'top', // Posição do label acima da barra
    //                 color: '#9fa3ff', // Cor do texto
    //                 formatter: '{c}', // Formatação do conteúdo do label para mostrar o valor da barra
    //                 textStyle: {
    //                     color: '#9a9fb0', // Cor do texto
    //                     fontFamily: 'Ubuntu', // Fonte do texto
    //                     fontSize: 9, // Tamanho da fonte
    //                 },
    //             },
    //             data: subscriptions.map(item => item.prepaidUnid)
    //         },
    //     ],
    //     dataZoom: subscriptions.length > 3 ? [{
    //         type: 'slider',
    //         start: 0,
    //         end: (( 6) / subscriptions.length * 100) - 5,
    //         height: 17,
    //         show: true,
    //         bottom: '12px'
    //     }] : []
    // };

    return (
        <>
            {subscriptionData ?
                <ReactECharts
                    notMerge={true}
                    lazyUpdate={true}
                    style={{ width: '100%', height: '100%' }} opts={{ renderer: 'svg', locale: 'PT-br' }}
                    option={horizontal ? optsUsageChartHorizontalBars : optsChart}
                    onEvents={onEvents ? onEvents : {}}
                />
                :
                <LcNoData size="small" label="Não foram encontrados dados de licenciamento." />
            }
        </>
    )
}

export default SubscriptionComercial;