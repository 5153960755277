import api, { APIClient } from "../api";

export class GTMConfigurationService extends APIClient {

    constructor(props:any){
      super(props);
    }
    
    async GetGtmConfiguration(gtmId:number) {
        const url = this.endpoint + `/api/GTMConfiguration/GetListConfiguration/GTMId/${gtmId}?refresh=${Math.random()}`;
        
        return api({
            method: 'get',
            url,
            
        });
    }

    async GetGtmByClientGroup(gtmId:number) {
        const url = this.endpoint + `/api/Agendamentos/getContractsAgendamento?GTMType=${gtmId}&refresh=${Math.random()}`;
        
        return api({
            method: 'get',
            url,
            
        });
    }

    GetWebSites(clientGroupId:number) {
        const url = this.endpoint + `/api/WebSites/WebSitesByClientGroup/ClientGroupId/${clientGroupId}?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
       
        });
    }

    async getClientActive(){
        let status = true;
        const url = this.endpoint + `/api/PortalUser/GetClientGroupByStatus?status=${status}`;
        return api({
            method: 'get',
            url,   
        }) 
    }

    async GetCostCenter(clientGroupId:number){
        const url = `${this.endpoint}/api/CostCenter/RetrieveListByClientGroupId/ClientGroupId/${clientGroupId}?refresh=${Math.random()}`
        return api({
            method: 'get',
            url,
        });
    }

    async GetHostGroups(clientGroupId:number){
        const url = `${this.endpoint}/api/GTMConfiguration/GetHostGroups/${clientGroupId}?refresh=${Math.random()}`
        return api({
            method: 'get',
            url,
        });
    }

    async SaveGTMConfiguration(body:any){
        const url = `${this.endpoint}/api/GTMConfiguration/SaveGTMConfiguration/`
        const data = JSON.stringify(body);
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }
    async DeleteGTMConfiguration(id: any) {
        const url = `${this.endpoint}/api/GTMConfiguration/DeleteGTMConfiguration?id=${id}`;
        return api({
            method: 'delete',
            url,   
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }
    async EditGTMConfiguration(body:any){
        const url = `${this.endpoint}/api/GTMConfiguration/UpdateGTMConfiguration/`
        const data = JSON.stringify(body);
        return api({
            method: 'put',
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }


}