// EALicenseAlarmComponent.tsx
import React, { useState, useEffect } from 'react';
import EALicenseAlarmService from '../../../services/EASubscriptionService/EALicenseAlarmService';
import { PPInput } from 'processor-plataform-ui';
import { FaEdit, FaPlus, FaTrashAlt, FaTimes } from 'react-icons/fa';
import LcLoading from '../../../components/Generic/LcLoading';
import './index.css'; // Custom styles
import { PiPlusLight, PiTrashLight, PiXLight } from 'react-icons/pi';

interface EALicenseAlarm {
    email: string;
    country: string;
}

const EALicenseAlarmComponent: React.FC = () => {
    const [alarms, setAlarms] = useState<EALicenseAlarm[]>([]);
    const [newAlarm, setNewAlarm] = useState<EALicenseAlarm>({ email: '', country: '' });
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editAlarm, setEditAlarm] = useState<EALicenseAlarm>({ email: '', country: '' });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchAlarms();
    }, []);

    const fetchAlarms = async () => {
        setLoading(true);
        try {
            const result = await EALicenseAlarmService.queryEALicenseAlarm();
            setAlarms(result);
        } catch (error) {
            console.error('Erro ao buscar alarmes:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const success = await EALicenseAlarmService.saveEALicenseAlarm(newAlarm);
            if (success) {
                fetchAlarms();
                setNewAlarm({ email: '', country: '' });
            }
        } catch (error) {
            console.error('Erro ao salvar o alarme:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (index: number) => {
        setEditIndex(index);
        setEditAlarm({ ...alarms[index] });
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            if (editIndex !== null) {
                const updatedAlarms = [...alarms];
                updatedAlarms[editIndex] = editAlarm;
                setAlarms(updatedAlarms);
                await EALicenseAlarmService.saveEALicenseAlarm(editAlarm);
                setEditIndex(null);
                setEditAlarm({ email: '', country: '' });
            }
        } catch (error) {
            console.error('Erro ao atualizar o alarme:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
        setEditAlarm({ email: '', country: '' });
    };

    const handleDelete = async (index: number) => {
        setLoading(true);
        try {
            const alarm = alarms[index];
            const success = await EALicenseAlarmService.deleteEALicenseAlarm(alarm);
            if (success) {
                fetchAlarms();
            }
        } catch (error) {
            console.error('Erro ao deletar o alarme:', error);
        } finally {
            setLoading(false);
        }
    };

    const isAddButtonDisabled = newAlarm.email.trim() === '' || newAlarm.country.trim() === '';

    return (
        <div>
            <LcLoading loading={loading}>
                <div className="add-alarm-row">
                    <PPInput
                        title="Email"
                        value={newAlarm.email}
                        onChange={(e) => setNewAlarm({ ...newAlarm, email: e.target.value })}
                    />
                    <PPInput
                        title="País"
                        value={newAlarm.country}
                        onChange={(e) => setNewAlarm({ ...newAlarm, country: e.target.value })}
                    />
                    <button
                        className="icon-button"
                        onClick={handleSave}
                        disabled={isAddButtonDisabled}
                    > <PiPlusLight />
                    </button>
                </div>

                <div className='' style={{color: 'var(--text-default)', marginTop: '40px'}}>
                    <h4>Alarmes</h4>
                    <ul className="alarms-list">
                        {alarms.map((alarm, index) => (
                            <li key={index} className="alarm-item">
                                {editIndex === index ? (
                                    <div className="edit-alarm">
                                        <PPInput
                                            title="Email"
                                            value={editAlarm.email}
                                            onChange={(e) => setEditAlarm({ ...editAlarm, email: e.target.value })}
                                        />
                                        <PPInput
                                            title="País"
                                            value={editAlarm.country}
                                            onChange={(e) => setEditAlarm({ ...editAlarm, country: e.target.value })}
                                        />
                                        <button className="icon-button" onClick={handleUpdate}>
                                            <PiPlusLight />
                                        </button>
                                        <button className="icon-button" onClick={handleCancel}>
                                            <PiXLight />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="view-alarm">
                                        <span>{alarm.email} - {alarm.country}</span>
                                        <div className="actions">
                                            {/*<button className="icon-button" onClick={() => handleEdit(index)}>*/}
                                            {/*    <FaEdit />*/}
                                            {/*</button>*/}
                                            <button className="icon-button" onClick={() => handleDelete(index)}>
                                                <PiTrashLight />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </LcLoading>
        </div>
    );
};

export default EALicenseAlarmComponent;
