import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.css';
import LcLoading from '../../../components/Generic/LcLoading';
import LcIconLink from '../../../components/Generic/LcIconLink';
import AixCompanySevice from '../../../services/AixCompanyService/AixCompanyService';
import { PiArrowsClockwiseLight, PiCopyLight } from 'react-icons/pi';
import { useSnackbar } from 'notistack';


interface Props {
    originalText: string;
    visible: boolean;
    handleVisible: Function;
    setText: Function;
    user: any;
    instructionType?: number;
    auxProps?: { [key: string]: any }; // Add this line to include auxProps
}
export function cleanText(text: any): string {
    // Verifica se a entrada é uma string, caso contrário, converte para uma string vazia
    if (typeof text !== 'string') {
        console.warn('Entrada não é uma string. Convertendo para string vazia.');
        text = '';
    }

    // Remove todas as tags HTML, incluindo as que têm CSS embutido
    let clean = text.replace(/<[^>]*>/g, '');

    // Substitui múltiplos espaços por um único espaço
    clean = clean.replace(/\s+/g, ' ');

    // Mantém as quebras de linha
    clean = clean.replace(/ \n/g, '\n').replace(/\n /g, '\n');

    return clean.trim();
}

const HHContentAI: React.FC<Props> = ({handleVisible, originalText,setText,user,visible,auxProps,instructionType}) => {

    const [loadingSugggestion, setLoadingSugggestion] = useState<boolean>(false);
    const [suggestion, setSuggestion] = useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const getFormatedAuxProps = useCallback(() => {
        if (!auxProps) return '';
        return Object.values(auxProps).join('\n');
    }, [auxProps]);
    
    const auxData = useMemo(() => {
        return instructionType === 1 ? getFormatedAuxProps() : "";
    }, [getFormatedAuxProps, instructionType]);
    
    const textToSend = useMemo(() => {
        const cleanedText = cleanText(originalText);
        if (auxData !== "" && auxData) {
            return cleanText(auxData) + " " + cleanedText;
        }
        return cleanedText;
    }, [auxData, originalText]);

    const getInquireWithInstruction = useCallback(async (Instruction) => {
        setLoadingSugggestion(true);
        
        try {
            const AixCompany = new AixCompanySevice({});
            let response = Instruction && await AixCompany.GetInquireWithInstruction(textToSend, Instruction);
            setSuggestion(response.message);
        } catch (error) {
            console.error(error);
        }
        
        setLoadingSugggestion(false);
    }, [user.email, textToSend]);

    useEffect(() => {
        if (visible && instructionType !== 4) {
            getInquireWithInstruction(instructionType)
        }
    }, [visible, instructionType, getInquireWithInstruction]);
    
    const copyToClipbord = () => {
        var textString = JSON
            .stringify(suggestion)
            .replaceAll("<br>", "\\n")
            .replaceAll("<br />", "\\n")
            .replaceAll("<br/>", "\\n");
        var _text = JSON.parse(textString);
        enqueueSnackbar(`Texto copiado com sucesso.`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        });
        navigator.clipboard.writeText(_text);
    };


    const getTextByInstruction = (instruction) => {
        switch (instruction) {
            case 1:
                return "Texto elaborado"
            case 2:
                return "Texto reduzido"
            case 3:
                return "Texto expandido"
            case 4:
                return "Texto formalizado"
            case 5:
                return "Texto corrigido"
            case 6:
                return "Texto comunicativo"
            case 7:
                return "Texto bem-humorado"
            case 8:
                return "Texto entusiasmado"
            case 9:
                return "Texto informativo"
            case 10:
                return "Texto profissional"
            case 11:
                return "Texto inteligente"
            case 12:
                return "Texto confiável"
            default:
                return 'Texto personalizado '
        }
    }

    return (
        <>
            <div className={`allAI   ${visible ? "open " : ""}`} >
                <div className={`contentAI scrolabble-v borderAI ${visible ? "open " : ""}`}>
                    <LcLoading loading={loadingSugggestion}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className={" row title-sugestao"}>
                                <i className="ppi ppi-lightning-bolt ppi-2x"></i>
                                <p style={{ marginLeft: "-5px" }}>{getTextByInstruction(instructionType)}</p>
                            </div>
                        </div>
                        <div className='scrollable-v'>
                            <p className="AiText" id="AiText" style={{ minHeight: "30px", maxHeight: '60px ' }}>{suggestion}</p>
                        </div>
                        <div className="action-wrapper">
                            <div className="row">
                                <LcIconLink  tooltipPosition="left" size='small' icon={<PiCopyLight/>} tooltip={"Copiar texto"} onClick={() => copyToClipbord()} />
                                <LcIconLink tooltipPosition="left" size='small' icon={<PiArrowsClockwiseLight/>} tooltip={"Nova sugestão"} 
                                    onClick={() => getInquireWithInstruction(instructionType)}
                                />
                            </div>
                            <button
                                className={"lc-button bg-info"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleVisible(false);
                                    setText(suggestion);
                                }}
                            >Usar esse texto</button>
                        </div>
                    </LcLoading>
                </div>
            </div>
        </>
    )
}

export default HHContentAI;
