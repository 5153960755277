import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { SolutionProps } from './Solutions';
import './index.css';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';


interface AdsProps {
    visibilidade?: string;
    onClose?: () => void;
    solucao: any;
}

const Ads: React.FC<AdsProps> = (props) => {
    const [exiting, setExiting] = useState(false);
    const handleContentClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const user = useSelector<RootState, UserState>(state => state.user);

    const detalhesRef = useRef<HTMLDivElement>(null);

    const bannersMktRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const dispatch = useDispatch()
    const handleDetalhesClick = () => {
        detalhesRef.current?.scrollIntoView({ behavior: 'smooth' });
        setHasScrolled(true);
    };

    const history = useHistory();

    const handleAtivarClick = () => {
        dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
        history.push("/Chamados")
    };

    const handleEspecialistasClick = () => {
        window.open(props.solucao.site() + '#contato', '_blank')
    };

    const handleCloseClick = () => {
        setExiting(true);
        setTimeout(() => {
            props.onClose?.();
            setExiting(false);
        }, 300); // Deve ser igual à duração da animação
    };

    const handleScrollClick = () => {
        if (bannersMktRef.current) {
            if (isAtBottom) {
                // Se estiver no fundo, rolar para o topo
                bannersMktRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                // Se não estiver no fundo, rolar para o fundo
                bannersMktRef.current.scrollTo({ top: bannersMktRef.current.scrollHeight, behavior: 'smooth' });
            }
            // Adicionar um pequeno atraso antes de verificar a posição de rolagem
            setTimeout(() => {
                if (bannersMktRef.current) {
                    const isBottom = bannersMktRef.current.scrollHeight - bannersMktRef.current.scrollTop == bannersMktRef.current.clientHeight;
                    setIsAtBottom(isBottom);
                    if (bannersMktRef.current.scrollTop == 0) {
                        setHasScrolled(false);
                    } else if (!hasScrolled) {
                        setHasScrolled(true);
                    }
                }
            }, 100);
        }
    };


    useEffect(() => {
        const handleScroll = () => {
            if (bannersMktRef.current) {
                const isBottom = bannersMktRef.current.scrollHeight - bannersMktRef.current.scrollTop == bannersMktRef.current.clientHeight;
                setIsAtBottom(isBottom);
                if (bannersMktRef.current.scrollTop == 0) {
                    setHasScrolled(false);
                } else if (!hasScrolled) {
                    setHasScrolled(true);
                }
            }
        };

        // Adicionar o ouvinte de evento
        bannersMktRef.current?.addEventListener('scroll', handleScroll);

        // Remover o ouvinte de evento quando o componente for desmontado
        return () => {
            bannersMktRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [hasScrolled]);



    const arrowMore = require('./Solutions/assets/arrowMore.gif').default;

    return (
        <div className={`bannersMkt scrollable-v ${props.visibilidade} ${props.solucao.solution()} ${exiting ? 'exiting' : ''}`} onClick={handleCloseClick} ref={bannersMktRef}>
            <div className="spacer"></div>
            <div className="publicidade">
                <div className="banner" onClick={handleContentClick}>

                    <div className="banner-content">

                        <div className="logotipo">
                            <img src={props.solucao.logotipo()} />
                        </div>

                        <div className="textos">
                            <h1>{props.solucao.titulo()}</h1>
                            <p>{props.solucao.chamada()}</p>

                            <button className="lc-button" onClick={handleDetalhesClick}>Saiba mais</button>

                            <button onClick={handleAtivarClick} className="lc-button bg-light">
                                Ativar
                            </button>

                        </div>

                        <div className="imagem-solucao">
                            <img src={props.solucao.image()} />
                        </div>

                        <div className="fechar link">
                            <i onClick={handleCloseClick} className="lci lci-x"></i>
                        </div>

                    </div>

                    <div className="arrow-more link">
                        <img onClick={handleDetalhesClick} src={arrowMore} alt="Saiba mais" />
                    </div>

                </div>
                <div className="detalhes-solucao" onClick={handleContentClick} ref={detalhesRef}>
                    <div className="detalhes">
                        <div>{props.solucao.detalhes()}</div>

                        <div className="btns-soluction-actions">
                            <button
                                onClick={handleEspecialistasClick}
                                className="lc-button bg-primary">
                                Fale com nossos especialistas
                            </button>

                            <button
                                onClick={handleAtivarClick}
                                className="lc-button">
                                Ativar solução
                            </button>
                            {hasScrolled && (
                                <div className="scroll-down" onClick={handleScrollClick} title={isAtBottom ? "Voltar para o topo" : "Rolar para baixo"}>
                                    <i className={`ppi ppi-chevron-double-${isAtBottom ? 'up' : 'down'}`}></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ads;