import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { Grid } from '@material-ui/core';
//Redux
import { useSelector } from 'react-redux';
import { UserState, WebPresence } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import styles from './index.module.css'

import LcOrganizationChart from "./LcOrgChartCostCenter/orgChart";

import { CostCenterService } from '../../../services/costCenter/costCenterService'

import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcLoading from '../../../components/Generic/LcLoading';

const CostCenterSaaS: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new CostCenterService(props);
    const [refresh, setRefresh] = useState<boolean>(true)
    const [sideModalVisibleUsuario, setSideModalVisibleUsuario] = useState<boolean>(false)
    const [tree, setTree] = useState<any[]>([])
    const [originalTree, setOriginalTree] = useState<any[]>([])
    const [records, setRecords] = useState<any[]>([])
    const [filteredRecords, setFilteredRecords] = useState<any[]>([])
    const [visibleFilteredRecords, setVisibleFilteredRecords] = useState<any[]>([])
    const [costCenterUser, setCostCenterUser] = useState<any[]>([])
    const [filteredCostCenterUser, setFilteredCostCenterUser] = useState<any[]>([])
    const [visibleFilteredCostCenterUser, setVisibleFilteredCostCenterUser] = useState<any[]>([])
    const [costCenterAspNetUser, setCostCenterAspNetUser] = useState<any[]>([])
    const [toggleAspNetUser, setToggleAspNetUser] = useState<any[]>([])
    const [filteredCostCenterAspNetUser, setFilteredCostCenterAspNetUser] = useState<any[]>([])
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true)
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>([])
    const [treeData, setTreeData] = useState<any>([]);
    const filterDefault = { size: 20, term: "", column: "", direction: "asc" };
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);
    const [filterUser, setFilterUser] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    //Colunas que serão utilizadas na montagem do chart
    let array: any[] = []
    let originalTreeArray: any[] = []
    let _records: any[] = [];

    useEffect(() => {
        setRefresh(true)
        setFilteredRecords([]);
        if (user.ClientGroupSelected) {
            array = []
            originalTreeArray = []
            _records = [];
            service.GetTreeData().then(result => {
                if (result.status == 200) {
                    setTreeData(result.data)
                    formatOriginalTree(result.data, true, 0)
                    formatTree(result.data, true, 0)

                    setTimeout(() => {
                        setOriginalTree(originalTreeArray)
                        setTree(array)
                        setRecords(_records)
                        setFilteredRecords(_records)
                        setRefresh(false)
                    }, 30)
                }
            })
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = records.filter((p: any) =>
            filter.term == '' ||
            p.name.toLowerCase().indexOf(filter.term) > -1 ||
            p.parentName?.toLowerCase().indexOf(filter.term) > -1 ||
            p.qtdSubsctiption?.toString().indexOf(filter.term) > -1
        )

        filtered = filtered.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredRecords(filtered);
        setVisibleFilteredRecords(filtered.slice(0, filter.size));
    }, [filter, filteredRecords.length > 0]);

    useEffect(() => {
        var filtered = costCenterUser.filter((p: any) =>
            filterUser.term == '' ||
            p.name.toLowerCase().indexOf(filterUser.term) > -1 ||
            p.email.toLowerCase().indexOf(filterUser.term) > -1 ||
            p.id.toString().indexOf(filterUser.term) > -1
        )

        filtered = filtered.sort((a: any, b: any) => {
            if (filterUser.direction == 'asc') return (a[filterUser.column] > b[filterUser.column]) ? 1 : -1;
            else return (a[filterUser.column] > b[filterUser.column]) ? -1 : 1;
        });

        setFilteredCostCenterUser(filtered);
        setVisibleFilteredCostCenterUser(filtered.slice(0, filterUser.size));
    }, [filterUser, filteredCostCenterUser.length > 0]);

    function formatOriginalTree(row: any, first: boolean, index: number) {
        if (first) {
            originalTreeArray.push(
                {
                    idArray: originalTreeArray.length,
                    name: row.name,
                    baseline: row.baseline,
                    baselineUndefined: row.baselineUndefined,
                    budget: row.budget,
                    children: row.children,
                    clientGroupId: user.ClientGroupSelected,
                    id: row.id,
                    nodeContent: row.nodeContent,
                    tag: row.tag,
                    pid: undefined
                }
            )
            formatOriginalTree(row, false, (index + 1))
        }
        else {
            if (row.children.length > 0) {
                row.children.forEach((element: any) => {
                    originalTreeArray.push(
                        {
                            idArray: originalTreeArray.length,
                            name: element.name,
                            baseline: element.baseline,
                            baselineUndefined: element.baselineUndefined,
                            budget: element.budget,
                            children: element.children,
                            clientGroupId: user.ClientGroupSelected,
                            id: element.id,
                            nodeContent: element.nodeContent,
                            tag: element.tag,
                            pid: row.id
                        }
                    )
                    formatOriginalTree(element, false, (index + 1))
                });
            } else {
                return
            }
        }
    }

    //Formata retorno do BFF (model CostCenterHierarchyStructure) para Data aceito pelo chart
    function formatTree(row: any, first: boolean, index: number) {
        if (first) {
            array.push(
                {
                    id: row.id,
                    "Nome do centro de custo": row.name
                }
            )
            _records.push(
                {
                    id: array.length,
                    name: row.name,
                    parentName: '',
                    costCenterId: row.id,
                    budget: row.budget.toFixed(0),
                    qtdSubscription: row.qtdSubscription
                }
            )
            formatTree(row, false, (index + 1))
        }
        else {
            if (row.children.length > 0) {
                row.children.forEach((element: any) => {
                    array.push(
                        {
                            id: element.id,
                            pid: row.id,
                            "Nome do centro de custo": element.name
                        }
                    )
                    _records.push(
                        {
                            id: array.length,
                            name: element.name,
                            parentName: row.name,
                            costCenterId: element.id,
                            budget: element.budget.toFixed(0),
                            qtdSubscription: element.qtdSubscription
                        }
                    )
                    formatTree(element, false, (index + 1))
                });
            } else {
                return
            }
        }
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Centro de Custo',
            width: "52%"
        },
        {
            field: 'parentName',
            headerName: 'Pertence a ',
            width: "33%"
        },
        {
            field: 'qtdSubscription',
            headerName: 'Quantidade de subscrição',
            width: "15%"
        }
    ];

    const _filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'search', type: 'text' },
        ],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    }

    const functionsRow = [
        {
            label: 'Vincular usuário',
            func: (_row: any) => {
                let row = records.find(x => x.id == _row.id)
                setSelectedCostCenter(row)

                service.GetCostCenterUsers(row.costCenterId as number).then(result => {

                    if (result.status == 200) {
                        setIsLoadingUser(false)
                        setCostCenterUser(result.data)
                        setFilteredCostCenterUser([]);
                        setFilteredCostCenterUser(result.data)
                    }
                }).catch(error => {
                    console.log(error)
                    setIsLoadingUser(false)
                })

                service.GetCostCenterAspNetUsers(row.costCenterId as number).then(result => {
                    if (result.status == 200) {
                        // let formatedData = JSON.parse(result.data)
                        let userListToggle = result.data.map((item: any) => {
                            return {
                                text: item.name,
                                value: item.id,
                                selected: false
                            }
                        });
                        setToggleAspNetUser(userListToggle);
                        setFilteredCostCenterAspNetUser(result.data)
                        setCostCenterAspNetUser(result.data)
                    }
                })
                setSideModalVisibleUsuario(!sideModalVisibleUsuario)
            }
        },
        {
            label: 'Excluir',
            func: (_row: any) => {
                //ToDo

                let row = filteredRecords.find(x => x.id == _row.id)

                if (!row) return

                let resp = window.confirm("Deseja reletar este centro de custo?")

                if (!resp) return

                service.DeleteCostCenter(row.costCenterId).then(result => {
                    if (result.status == 200) {
                        updateTree()
                        alert("Centro de custo deletado com sucesso")
                    }
                }).catch(error => {
                    console.log(error)
                    alert("Erro ao deletar centro de custo")
                })
            }
        }
    ]

    const functionsRowUser = [
        {
            label: 'Remover vínculo',
            func: (_row: any) => {
                //ToDo
                let row = costCenterUser.find(x => x.id == _row.id)

                let resp = window.confirm("Deseja realmente remover este usuário?")

                if (!resp) return;

                service.RemoveCostCenterUser(row.id as number).then(result => {
                    if (result.status == 200) {
                        alert("Usuário removido do centro de custo!")
                        setCostCenterUser([])
                        setIsLoadingUser(true)
                        setSideModalVisibleUsuario(!sideModalVisibleUsuario)
                    }
                }).catch(error => {
                    console.log(error)
                    alert("Erro ao remover usuário do centro de custo")
                    setSideModalVisibleUsuario(!sideModalVisibleUsuario)
                })
            }
        }
    ]

    const functionsGenericUser = [
        {
            title: 'Adicionar usuário',
            icon: 'lci lci-user-add',
            tooltip: "Adicionar usuário",
            func: () => { setIsVisibleAddUser(true) }
        }
    ];

    const columnsUser = [
        {
            field: 'id',
            headerName: 'Id',
            width: "15%"
        },
        {
            field: 'name',
            headerName: 'Nome',
            width: "43%"
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: "42%"
        }
    ];

    const _filterAdvancedUser = {
        fields: [
            { label: 'Pesquisar', name: 'search', type: 'text' },
        ],
        onChange: (filter: any, size: number) => setFilterUser({ ...filterUser, size: size, term: filter[0].value.toLowerCase() })

    }

    const onCloseSideModalUser = () => {
        setCostCenterUser([])
        setSideModalVisibleUsuario(!sideModalVisibleUsuario)
        setIsLoadingUser(true)
    }

    const [isVisibleAddUser, setIsVisibleAddUser] = useState<boolean>(false)
    const toggleUser = (data: any) => {
        let selected = data.filter(x => x.selected == true);
        let row: any[] = [];
        selected.forEach(element => {
            let user = costCenterAspNetUser.find(x => x.id == element.value);
            let costCenterUser = {
                UserID: user.id,
                Name: user.name,
                Email: user.email,
                CostCenterID: selectedCostCenter.costCenterId
            }
            row.push(costCenterUser);
        });
        service.AddCostCenterUser(row).then(result => {
            if (result.status == 200) {
                setIsLoadingUser(!isLoadingUser)
                setCostCenterUser([])
                setIsVisibleAddUser(!isVisibleAddUser)
                setSideModalVisibleUsuario(!sideModalVisibleUsuario)
                alert('Usuário adicionado ao centro de custo com sucesso')
            }
        }).catch(error => {
            console.log(error)
            alert('Erro para adicionar usuário ao centro de custo')
        })
    };

    const onCloseSideModalAddUser = () => {
        setIsVisibleAddUser(false)
    }

    const updateTree = () => {
        setRefresh(true)
        if (user.ClientGroupSelected) {
            array = []
            originalTreeArray = []
            _records = [];
            service.GetTreeData().then(result => {
                if (result.status == 200) {
                    setTreeData(result.data)
                    formatOriginalTree(result.data, true, 0)
                    formatTree(result.data, true, 0)

                    setTimeout(() => {
                        setOriginalTree(originalTreeArray)
                        setTree(array)
                        setRecords(_records)
                        setFilteredRecords(_records)
                        setRefresh(false)
                    }, 500)
                }
            })
        }
    }

    const onSortChangeRecords = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMoreRecords = (size?: number) => {
        if (size) setVisibleFilteredRecords(filteredRecords.slice(0, size))
        else setVisibleFilteredRecords([...visibleFilteredRecords, ...filteredRecords.slice(visibleFilteredRecords.length, visibleFilteredRecords.length + 10)])
    }; 

    const onSortChangeUser = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterUser({ ...filterUser, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMoreUser = (size?: number) => {
        if (size) setVisibleFilteredCostCenterUser(filteredCostCenterUser.slice(0, size))
        else setVisibleFilteredCostCenterUser([...visibleFilteredCostCenterUser, ...filteredCostCenterUser.slice(visibleFilteredCostCenterUser.length, visibleFilteredCostCenterUser.length + 10)])
    };

    return (
        <Layout pageTitle="Centro de custo">

            {tree.length > 0 && !refresh ?
                <>
                    <LcOrganizationChart data={treeData} updateData={updateTree}></LcOrganizationChart>
                    <LcInfiniteTable
                        loading={filteredRecords.length > 0}
                        columns={columns}
                        rows={visibleFilteredRecords}
                        filter={_filterAdvanced}
                        size={filteredRecords.length}
                        functionsRow={functionsRow}
                        loadMore={loadMoreRecords}
                        onSortChange={onSortChangeRecords} />
                </>
                :
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                    <LcLoading loading={refresh} label="Carregando..." />
                </Grid>
            } 
            <SideModal header="Usuários vinculados" onClose={onCloseSideModalUser} visible={sideModalVisibleUsuario}>
                <LcInfiniteTable
                    loading={isLoadingUser}
                    columns={columnsUser}
                    rows={visibleFilteredCostCenterUser}
                    filter={_filterAdvancedUser}
                    size={filteredCostCenterUser.length}
                    functionsRow={functionsRowUser}
                    functionGeneric={functionsGenericUser}
                    loadMore={loadMoreUser}
                    onSortChange={onSortChangeUser} />
                <SideModal header="Vincular usuário" onClose={onCloseSideModalAddUser} visible={isVisibleAddUser}>
                    <LcCheckList height="23" list={toggleAspNetUser} toggle={true} toggleAll={true} search={true} onChange={toggleUser} ></LcCheckList>
                </SideModal>
            </SideModal>
        </Layout>
    );
};

export default CostCenterSaaS;