import React from "react";

import LcTooltip from '../../../Generic/LcTooltip';

import "./index.css";

type Position = "left" | "center" | "right";

interface Props {
    title?: string,
    icon?: string,
    value?: string | number,
    smallValue?: string | number,
    color?: string,
    unity?: string,
    description?: string,
    tooltip?: string,
    info?: React.ReactNode,
    tooltipInfo?: string,
    tooltipInfoPosition?: Position
    formatValue?: boolean,
    maximumFractionDigits?: number,
    minimumFractionDigits?: number,
    onClick?: Function,
    imageIcon?: JSX.Element,
    withoutValue?: boolean,
    selected?:boolean,
    backgroundColor?: string,
    hearderStorege?: boolean
}

const IconValueCardRow: React.FC<Props> = (props) => {
    const maximumFractionDigits = props.maximumFractionDigits == null ? 2 : props.maximumFractionDigits;
    const minimumFractionDigits = props.minimumFractionDigits == null ? 0 : props.minimumFractionDigits;
    const getValue = () => {
        let original = props.value ? props.value.toString() : "";
        if (!original) return "0";

        if(original == '0') return (<>
            <small style={{color:'black', fontSize:'0.8rem', fontWeight:'normal'}}><i className="lci lci-exclamation text-warning"></i> Não há informações no período.</small>
        </>)

        if (original == 'undefined') return "0";

        return props.formatValue ? parseFloat(original).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits }) : original;
    };

    return (
        <div className={`iconvaluecardrow ${props.onClick ? 'link' : ''}`} style={{height: props.hearderStorege ? '100%': '' }} onClick={() => props.onClick && props.onClick()}>

            <div className="header">
                {props.imageIcon && props.withoutValue ?
                    <span className="title" style={{display:'flex', justifyContent:'center', width:'100%'}}>{props.title}</span>
                    :
                    <div className="title" style={{display:'flex', justifyContent: 'space-between', width:'100%', fontSize:  props.hearderStorege ? '.8rem' : ''}}>
                        <span>{props.title}</span>
                        {
                            props.hearderStorege &&
                            <span>Utilizado/Disponível</span>
                        }
                    </div>
                }
                {
                    !props.tooltipInfo && props.info
                }
                {
                    props.tooltipInfo &&
                    <LcTooltip content={props.tooltipInfo} position={props.tooltipInfoPosition ? props.tooltipInfoPosition : "center"} trigger="hover">
                        {props.info}
                    </LcTooltip>
                }
            </div>

            <div className="body" style={{ color: props.color }}>

                {props.value !='0' && props.icon &&
                    <i className={`icon ${props.icon}`} ></i>
                }

                {props.withoutValue && props.icon &&
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', fontSize: props.hearderStorege ? '30px': '56px' }}>
                        <i className={`icon ${props.icon}`}  ></i>
                    </div>
                }

                {props.imageIcon &&
                    (
                        <div style={{display:'flex',justifyContent:'center', width:'100%'}}>
                            {props.imageIcon}
                        </div>
                    )
                }

                {!props.withoutValue &&
                        <div className="right">

                        <div className="data" style={{fontSize: props.hearderStorege ? '30px' : ''}}>
                            <span>{getValue()}</span>
                            {
                                props.smallValue &&
                                <div className="unity">
                                    <span className="label">{props.smallValue}</span>
                                </div>
                            }
                            {
                                props.unity && props.value != '0' && 
                                <div className="unity">
                                    <span className="label">{props.unity}</span>
                                    {
                                        props.tooltip &&
                                        <LcTooltip content={props.tooltip} position="left" trigger="hover">
                                            <i className="lci lci-exclamation-circle"></i>
                                        </LcTooltip>
                                    }
                                </div>
                            }

                        </div>

                        {
                            props.description &&
                            <span className="description">{props.description}</span>
                        }

                    </div>
                }

                


            </div>
        </div>
    );
}

export default IconValueCardRow;
