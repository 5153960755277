import React, { useEffect, useState } from 'react';

import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { RecomentationsModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import LcLoading from '../../../../components/Generic/LcLoading';
import LcNoData from '../../../../components/Generic/LcNoData';
import LCDashboard, { Card } from '../../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../../components/Layout/Dashboard/IconValue';
import { PiArrowUp, PiLightning, PiPiggyBank, PiShieldCheck, PiThumbsUp, PiWarningCircle } from 'react-icons/pi';
import { AiOutlineThunderbolt } from 'react-icons/ai';
interface RecomendationsCMSProps {
    isHome?: boolean;
}

const Recomendations: React.FC<RecomendationsCMSProps> = (props) => {

    const CmsService = new CmsPremierService(props);
    const [recomendationsData, setRecomendationsData] = useState<RecomentationsModel>()
    const [loading, setLoading] = useState<boolean>(false)
    const user = useSelector<RootState, UserState>(state => state.user);


    const getRecomendationsData = async () => {
        try {
            setLoading(true);
            const recomendations = await CmsService.getRecomendations();
            console.log(recomendations)
            setRecomendationsData(recomendations)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
            setLoading(false)
        }
    }
    useEffect(() => {
        getRecomendationsData()
    }, [user.ClientGroupSelected, user.refreshFlag])

    var cards: Card[] = [
        {
            id: 1,
            hideHeader: true,
            bgColor: 'var(--background-lightest)',
            cssClass: 'small-card fix-card-content',
            type: "Custom",
            position: { row: 1, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return <IconValue
                    icon={<PiShieldCheck size={26} />}
                    value={recomendationsData?.security}
                    color={"var(--color-primary-blue)"}
                    description={"Segurança"} isHome={props.isHome}
                />;
            },
        },
        {
            id: 2, hideHeader: true,
            bgColor: 'var(--background-lightest)',
            cssClass: 'small-card fix-card-content',
            type: "Custom",
            position: { row: 1, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {

                return <IconValue
                    // icon={<PiLightning size={26} />}
                    icon={<AiOutlineThunderbolt size={26} />}
                    value={recomendationsData?.performance}
                    color={"var(--color-primary-blue)"}
                    description={"Performance"} isHome={props.isHome}
                />;
            },
        }, {
            id: 3, hideHeader: true,
            bgColor: 'var(--background-lightest)',
            cssClass: 'small-card fix-card-content',
            type: "Custom",
            position: { row: 2, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return <IconValue
                    icon={<PiPiggyBank size={26} />}
                    value={recomendationsData?.cost}
                    color={"var(--color-primary-blue)"}
                    description={"Custo"} isHome={props.isHome}
                />;
            },
        }, {
            id: 4, hideHeader: true,
            bgColor: 'var(--background-lightest)',
            cssClass: 'small-card fix-card-content',
            type: "Custom",
            position: { row: 2, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return <IconValue
                    icon={<PiThumbsUp size={26} />}
                    value={recomendationsData?.operationalExcellence}
                    color={"var(--color-primary-blue)"}
                    description={"Excelência operacional"} isHome={props.isHome}
                />;
            },
        }, {
            id: 5, hideHeader: true,
            bgColor: 'var(--background-lightest)',
            cssClass: 'small-card fix-card-content',
            type: "Custom",
            position: { row: 3, col: 1, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return <IconValue
                    icon={<PiArrowUp size={26} />}
                    value={recomendationsData?.highAvailability}
                    color={"var(--color-primary-blue)"}
                    description={"Alta disponibilidade"} isHome={props.isHome}
                />;
            },
        }, {
            id: 6, hideHeader: true,
            bgColor: 'var(--background-light)',
            cssClass: 'small-card fix-card-content',            
            type: "Custom",
            position: { row: 3, col: 2, ColSpan: 1, RowSpan: 1 },
            customContentRender: () => {
                return <IconValue
                    icon={<PiWarningCircle size={26} />}
                    value={recomendationsData?.policies}
                    color={"var(--color-primary-blue)"}
                    description={"Politicas"} isHome={props.isHome}
                />;
            },
        },
    ]
    return (
        <LcLoading loading={loading}>
            {recomendationsData ? <LCDashboard
                isSubGrid
                grid={[3, 2]}
                cards={cards}
            /> :
                <LcNoData size="small" />}
        </LcLoading>
    );
}

export default Recomendations;