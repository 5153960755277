import React, { useEffect, ChangeEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, Checkbox,  FormControl, FormControlLabel, FormGroup, Grid, GridSize, Input, InputLabel, LinearProgress, NativeSelect, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Label } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import LcIconLink from '../../Generic/LcIconLink';
import VerticalDivisor from '../../Generic/VerticalDivisor';
import { error } from 'console';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { ErrorMessage } from '@hookform/error-message';
import LcLoading from '../../Generic/LcLoading';

interface CustomFormProps {
    template: any,
    data?: any,
    handleform?: any,
    visible?: boolean,
    loading?: boolean,
    EditForm?: boolean,
    onClose?: Function,
    model?: any;
    parentCallback?: Function;
    functions?: {
        id?: string;
        title: string;
        icon: string;
        disabled?: boolean;
        func: Function;
        skipvalidation?: boolean;
        tooltip?: string;
    }[];
    header?: string;
    detailButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: '3px',
            },
        },
    }),
);

let DynamicForm: React.FC<CustomFormProps> = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];

        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        if (value && value.length == 1) {
            return value[0];
        }
        return value;
    };
    const classes = useStyles();
    function renderOptions(options: any[], option: any, fieldLabel: string) {
        if (options == undefined || options == null) {
            return <></>;
        }

        return <>
            {
                (options.length > 1) &&
                <option key={-1} value="-1" disabled selected >{`Selecione ${fieldLabel.toLowerCase()}`}</option>
            }
            {options && options.length > 0 &&
                options.map((child, index) => {
                    let selectedValue = 0;
                    let iAmAnArray = Array.isArray(option);
                    if (iAmAnArray) {
                        let found = option.filter(x => x == child.value);
                        if (found) {
                            selectedValue = child.value;
                        }
                        return (
                            <option key={index} value={child.value} selected={child.value != undefined && child.selected == true ? true : false} >{child.label}</option>
                        );
                    } else {
                        selectedValue = option;
                        return (
                            <option key={index} value={child.value} selected={child.value == selectedValue ? true : false} >{child.label}</option>
                        );
                    }


                })}
        </>
    };

    let loading = false;
    if (props && props.loading) {
        loading = props.loading;
    }

    let [errorCount, setErrorCount] = useState<number>(0);
    let [counter, setCounter] = useState<number>(0);
    let [template, setTemplate] = useState({ ...props.template });
    useEffect(() => { setTemplate(undefined); setTemplate({ ...props.template }) }, [props.template]);
    let visible = false;
    if (props.visible) {
        visible = props.visible;
    }
    const { register, handleSubmit, control, watch, errors, getValues, setValue,
        reset, trigger, formState } = useForm({
            mode: "all", reValidateMode: 'onChange', criteriaMode: "firstError",
            shouldFocusError: true,
        });
    const Salvar = async (event: any, func: any) => {
        if (func.skipvalidation != null && func.skipvalidation == true) {
            executeOperationOnRecord(props, getValues, trigger, handleSubmit, func);
            return;
        }
        setErrorCount(0);
        await trigger();
        await template.Fields.map(async (fieldItem: any) => {
            await trigger(fieldItem.name);
        });
        await template.Fields.map((fieldItem: any) => {
            trigger(fieldItem.name).then((a) => {
                if (errors[fieldItem.name] != null) {
                    const count = errorCount + 1;
                    setErrorCount(count);
                    enqueueSnackbar(errors[fieldItem.name].message, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
            });
        });
        handleSubmit(onSubmit, onError)();
    }

    useEffect(() => {
        let interfaceTyped: { [key: string]: any } = { ...props.data };
        try {
            setTemplate(props.template);
            Object.keys(props.data).map((field) => {
                interfaceTyped = props.data;
                setValue(field, interfaceTyped[field]);
                trigger(field);
            });
        } catch (e) { }
    }, [props.EditForm]);

    if (props == undefined || props == null) {
        return (<div></div>);
    }

    function updateTreeData(value: any, item: any) {
        setValue(item, value);
    }

    function executeOperationOnRecord(props: React.PropsWithChildren<CustomFormProps>,
        getValues: any, trigger: any, handleSubmit: any,
        func: { title: string; icon: string; disabled?: boolean | undefined; func: Function; }) {
        let interfaceTyped: { [key: string]: any; } = { ...props.data };
        let interfaceTypedNewValues: { [key: string]: any; } = { ...getValues() };
        Object.keys(getValues()).map((field) => {
            trigger(field);
            interfaceTyped[field] = interfaceTypedNewValues[field];
        });

        func.func(interfaceTyped);
    }

    const onSubmit = (dataSA: any, e: any) => {
        if (errorCount == 0) {
            let funca = props && props.functions && props.functions?.filter(x => x.skipvalidation == false)[0];
            props && props.functions && funca && executeOperationOnRecord(props, getValues, trigger, handleSubmit, funca);
        }
    };

    const onError = (errorsSA: any, e: any) => { };
    return (<> {
        loading && <Grid container justify="center" alignItems="center">
            <LcLoading loading={loading} label="Carregando..." />
        </Grid>
    }
        {!loading && <div className={`dynamic-modal ${props.visible && 'visible'}`} style={{ margin: 0, padding: 2 }}  >
            <div className="lc-datagrid" style={{ margin: 0, padding: 0 }} >
                <div className="header" style={{ margin: 0, padding: 0 }} >
                    <Typography variant="h5" color="textPrimary" component="p">{props.header}</Typography>
                </div>
                {!props.detailButton &&
                    <div className="sequence" style={{ marginBottom: 6, padding: 0 }} >
                        {
                            props.functions &&
                            props.functions.filter((x: any) => x.disabled != true).map((func, index) => {
                                return (
                                    <LcIconLink id={func.id} tooltip={func.tooltip} key={index} icon={func.icon}
                                        onClick={(event: any) => { Salvar(event, func); }}
                                    />);
                            })
                        }
                        {
                            props.functions && <VerticalDivisor />
                        }
                    </div>
                }
                <div className="body" style={{ margin: 0, padding: 0 }} >
                    <form style={{ margin: 0, padding: 0 }} >
                        <Grid container spacing={0}>
                            {template && template.Fields.filter((x: { edit: any; add?: any }) =>
                                ((x.edit == true && (props.EditForm == true || props.EditForm == undefined)) || (props.EditForm == false)))
                                .sort((a: { row: number; }, b: { row: number; }) => {
                                    if (a.row > b.row) return 1;
                                    else if (b.row > a.row) return -1;
                                    return 0;
                                })
                                .map((field: {
                                    type?: any; title?: any; name?: any; validate?: any; required?: any;
                                    defaultValue?: any; disabled?: any; row?: any; col?: any;
                                    colspan?: number; rowspan?: any; options?: any; formatData?: any; icon?: any;
                                    denpendentFieldFilter?: any, multiselect?: any
                                }, index: number) => {
                                    let { type, title, name, validate, required, defaultValue, disabled, row, col,
                                        colspan, rowspan, formatData, icon, denpendentFieldFilter, multiselect } = field;
                                    let colspanField: GridSize = 2;
                                    if (colspan != undefined) {
                                        colspanField = (colspan * 6) as GridSize;
                                    }
                                    let formatedData = (props.data[name] != null) ? props.data[name] : '';
                                    if (formatData) {
                                        formatedData = formatData(formatedData);
                                    }
                                    { !required && (required = {}) }

                                    switch (type) {
                                        case 'detailtext':
                                            return (<Grid key={`Grid${index}`} item sm={colspanField} xs={1}
                                                style={{ margin: 0, padding: 0 }} >
                                                <FormGroup key={`FormGroup${index}`} style={{ margin: 0, padding: 0 }}
                                                    className={classes.root} >
                                                    <TextField style={{ marginBottom: 2, marginTop: 3, padding: 0 }}
                                                        disabled={true}
                                                        key={name}
                                                        type='text'
                                                        label={title}
                                                        required={required.value == true}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={formatedData}
                                                        variant="outlined"
                                                        name={name}
                                                        inputRef={required && register(required)}
                                                    />
                                                </FormGroup>
                                            </Grid>);
                                            break;
                                        case 'detailtextArea': return <Grid key={`Grid${index}`} item sm={colspanField} xs={1} >
                                            <FormGroup key={`FormGroup${index}`} className={classes.root} >
                                                <TextField
                                                    disabled={true}
                                                    key={name}
                                                    type='text'
                                                    label={title}
                                                    required={required.value == true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    multiline
                                                    rows={8}
                                                    defaultValue={formatedData}
                                                    variant="outlined"
                                                    name={name}
                                                    inputRef={required && register(required)}
                                                />
                                            </FormGroup>
                                        </Grid>;
                                            break;
                                        case 'textArea':
                                            return (
                                                <Grid item sm={colspanField} xs={12} key={`Grid${index}`}>
                                                    <FormGroup key={`FormGroup${index}`}
                                                        className={classes.root} style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12 }}
                                                    >
                                                        <InputLabel key={`InputLabel${index}`} error={errors[name] != null ? true : false}
                                                            style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12, margin: 0 }}
                                                            htmlFor="outlined-age-simple">
                                                            {title}
                                                        </InputLabel>
                                                        <TextField
                                                            key={name}
                                                            style={{
                                                                position: 'relative', paddingLeft: 0, marginLeft: 10, marginRight: 10,
                                                                paddingRight: 0, paddingTop: 0,
                                                                left: '-5px', backgroundColor: '#FFFFFF', fontSize: 12
                                                            }}
                                                            type='text'
                                                            multiline
                                                            rows={8}
                                                            error={errors[name] != null ? true : false}
                                                            required={required.value == true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} size="small"
                                                            defaultValue={formatedData}
                                                            variant="standard"
                                                            name={name}
                                                            disabled={disabled}
                                                            inputRef={required && register(required)}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            ); break;

                                        case 'file':
                                            return (
                                                <Grid key={`Grid${index}`} item sm={colspanField} xs={12} >
                                                    <FormGroup key={`FormGroup${index}`}
                                                        className={classes.root} style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12 }}
                                                    >
                                                        <InputLabel key={`InputLabel${index}`} error={errors[name] != null ? true : false}
                                                            style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12, margin: 0 }}
                                                            htmlFor="outlined-age-simple">
                                                            {title}
                                                        </InputLabel>
                                                        <TextField
                                                            key={name}
                                                            style={{
                                                                position: 'relative', paddingLeft: 0, marginLeft: 10, marginRight: 10,
                                                                paddingRight: 0, paddingTop: 0,
                                                                left: '-5px', backgroundColor: '#FFFFFF', fontSize: 12
                                                            }}
                                                            type='file'
                                                            error={errors[name] != null ? true : false}
                                                            required={required.value == true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} size="small"
                                                            defaultValue={formatedData}
                                                            variant="standard"
                                                            name={name}
                                                            disabled={disabled}
                                                            helperText={errors[name] != null ? required.message : ""}
                                                            inputRef={required && register(required)}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            ); break;
                                        case 'password':
                                            { !required && (required = {}) }
                                            return (
                                                <Grid key={`Grid${index}`} item sm={colspanField} xs={12} >
                                                    <FormGroup key={`FormGroup${index}`}
                                                        className={classes.root} style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12 }}
                                                    >
                                                        <InputLabel key={`InputLabel${index}`} error={errors[name] != null ? true : false}
                                                            style={{
                                                                position: 'relative', paddingLeft: 0, marginLeft: 10, marginRight: 10,
                                                                paddingRight: 0, paddingTop: 0,
                                                                fontSize: 12, margin: 0
                                                            }}
                                                            htmlFor="outlined-age-simple">
                                                            {title}
                                                        </InputLabel>
                                                        <TextField
                                                            key={name}
                                                            style={{
                                                                position: 'relative', width: '90%',
                                                                left: '-5px', backgroundColor: '#FFFFFF',
                                                                paddingLeft: 0, paddingRight: 0,
                                                                paddingTop: 0, fontSize: 12
                                                            }}
                                                            type='password'
                                                            error={errors[name] != null ? true : false}
                                                            required={required.value == true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size="small"
                                                            defaultValue={formatedData}
                                                            variant="standard"
                                                            name={name}
                                                            inputRef={required && register(required)}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            );
                                            break;
                                        case 'checkbox':
                                            return (
                                                <Grid key={`Grid${index}`} item sm={colspanField} xs={12} style={{marginTop:8}} >
                                                    <FormControl key={`FormControl${index}`} error={errors[name] != null ? true : false}
                                                        component="fieldset" className={classes.root}
                                                        style={{ paddingRight: 8, paddingLeft: 8 }} >
                                                        <FormControlLabel
                                                            key={`FormControlLabel${index}`}
                                                            control={<Checkbox disabled={disabled}
                                                                required={required.value == true}
                                                                defaultChecked={formatedData}
                                                                name={name}
                                                                inputRef={required && register(required)}
                                                                color="primary"
                                                            />}
                                                            label={title}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            );
                                            break;
                                        case 'text':
                                            return (
                                                <Grid key={`Grid${index}`} item sm={colspanField} xs={12} style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12 }} >
                                                    <FormGroup key={`FormGroup${index}`}
                                                        className={classes.root} style={{
                                                            position: 'relative',
                                                            paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12
                                                        }}
                                                    >
                                                        <InputLabel key={`InputLabel${index}`} error={errors[name] != null ? true : false}
                                                            style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, paddingTop: 0, fontSize: 12, margin: 0 }}
                                                            htmlFor="outlined-age-simple">
                                                            {title}
                                                        </InputLabel>
                                                        <TextField
                                                            key={name}
                                                            style={{
                                                                position: 'relative',
                                                                left: '-5px', backgroundColor: '#FFFFFF',
                                                                paddingLeft: 0, marginLeft: 10, marginRight: 10,
                                                                paddingRight: 0, paddingTop: 0, fontSize: 12
                                                            }}
                                                            type='text'
                                                            error={errors[name] != null ? true : false}
                                                            required={required.value == true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} size="small"
                                                            defaultValue={formatedData}
                                                            variant="standard"
                                                            name={name}
                                                            disabled={disabled}
                                                            inputRef={required && register(required)}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            );
                                            break;
                                        case 'select': {
                                            let { options: options } = field;

                                            try {
                                                if (denpendentFieldFilter) {
                                                    options = denpendentFieldFilter.filter(getValues());
                                                }
                                                if (options && options.length == 1) {
                                                    setValue(name, options[0].value);
                                                    trigger(name);
                                                }

                                            } catch (e) {

                                            }

                                            return ((options && options.length > 0) &&
                                                <Grid key={`Grid${index}`} item sm={colspanField} xs={12}  >
                                                    <FormGroup key={`FormGroup${index}`} className={classes.root}
                                                        style={{ paddingTop: 5, transform: 'translateY(-4px)' }}  >
                                                        <InputLabel key={`InputLabel${index}`} error={errors[name] != null ? true : false}
                                                            style={{ position: 'relative', top: 0, fontSize: 12 }}
                                                            htmlFor="outlined-age-simple">
                                                            {title}
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            name={name}
                                                            rules={{
                                                                validate: () => {
                                                                    return ((required.value ? getValues(name) != "-1" && getValues(name) != "" ? true : required.required : true));
                                                                }
                                                            }}
                                                            error={errors[name] != null ? true : false} key={name}
                                                            render={(field: { onChange, value, ref }) => (
                                                                <Select
                                                                    native
                                                                    style={{
                                                                        position: 'relative',
                                                                        marginLeft: 10,
                                                                        marginRight: 10,
                                                                        left: '-2px',
                                                                        backgroundColor: '#FFFFFF',
                                                                        padding: '1px',
                                                                        fontSize: 12
                                                                    }}
                                                                    error={errors[name] != null ? true : false} key={name}
                                                                    inputRef={field.ref}
                                                                    onChange={val => {
                                                                        let values = handleChangeMultiple(val)
                                                                        setValue(name, values);
                                                                        trigger(name);
                                                                        let count = counter;
                                                                        count = count + 1;
                                                                        setCounter(count);
                                                                    }}
                                                                    disabled={disabled}
                                                                    inputProps={{
                                                                        id: 'outlined-age-simple',
                                                                        variant: "outlined",
                                                                        disabled: disabled,
                                                                        multiple: multiselect as boolean,

                                                                    }}
                                                                    value={getValues(name)}
                                                                    defaultValue={formatedData ? formatedData : undefined} >
                                                                    {renderOptions(options, props.data[name], title)}
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            );
                                        }
                                            break;
                                        default:
                                            return (
                                                <div key={`di${index}`}>
                                                    <span>Invalid Field</span>
                                                </div>
                                            );
                                    }
                                })}
                        </Grid>
                    </form>
                    {props.children && <div key={'Extra'}>{props.children}</div>}
                </div>
                {props.detailButton && <Grid className="topbar" style={{ margin: 0, padding: 0, display:'flex', justifyContent:'right' }} >
                    <Grid className="functions" style={{ padding: 0 }} >
                        {
                            props.functions &&
                            props.functions.filter((x: any) => x.disabled != true).map((func, index) => {
                                return (
                                    <button id={func.id} key={index}
                                        style={{ marginLeft: '1rem' }}
                                        onClick={(event: any) => { Salvar(event, func); }}
                                        className="lc-button bg-info" >{func.title}</button>);
                            })
                        }
                    </Grid>
                </Grid>
                }
            </div>

        </div>
        }
    </>);

}

export default DynamicForm;