import React from 'react';
import { PiCaretDownLight } from 'react-icons/pi';
import { StrucureModel } from '.';

interface AssembleBodyModel {
    data: StrucureModel,
    columns: any[]
    budgetData:StrucureModel[]
    setBudgetData: (budgetData: StrucureModel[]) => void
}

export const AssembleBody: React.FC<AssembleBodyModel> = (
    {
    data, 
    columns,
    setBudgetData,
    budgetData 
}) => {

    return (
        <>
            <div className="row">
                <div className="cells">
                    {
                        columns.map((c, i) => {
                            return <div key={i} className={`cell small  ${c.align || ''}${i === 0 ? ' bold' : ''}`} style={{ width: c.width, color: data.active ? "var(--text-default)" : "#2e1956ad" }}>
                                {data.children[0][c.field]}
                                {
                                    i === 0 &&
                                    <PiCaretDownLight
                                        size={16}
                                        className='link'
                                        color='var(--color-primary-blue)'
                                        onClick={() => {
                                            data.show = !data.show;
                                            setBudgetData([...budgetData]);
                                        }}
                                    />
                                }
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="row" style={{ display: data.show ? "" : "none" }}>
                <div className="cells">
                    {
                        columns.map((c, i) => {
                            return <div key={i} className={`cell small ${c.align || ''}${i === 0 ? ' pl-7' : ''} `} style={{ width: i === 0 ? c.width : '11.1%', color: data.active ? "var(--text-default)" : "#2e1956ad" }}>{data.children[1][c.field]}</div>
                        })
                    }
                </div>
            </div>
            <div className="row" style={{ display: data.show ? "" : "none" }}>
                <div className="cells">
                    {
                        columns.map((c, i) => {
                            return <div key={i} className={`cell small ${c.align || ''}${i === 0 ? ' pl-7' : ''}`} style={{ width: i === 0 ? c.width : '11.1%', color: data.active ? "var(--text-default)" : "#2e1956ad" }}>{data.children[2][c.field]}</div>
                        })
                    }
                </div>
            </div>
            <div className="row">
                <div className="cells">
                    {
                        columns.map((c, i) => {
                            return <div key={i} className={`cell small ${c.align || ''}${i === 0 ? ' bold' : ''}`} style={{ width: c.width, color: data.active ? "var(--text-default)" : "#2e1956ad" }}>{data.children[3][c.field]}</div>
                        })
                    }
                </div>
            </div>
            <div className="row">
                <div className="cells">
                    {
                        columns.map((c, i) => {
                            return <div key={i} className={`cell small ${c.align || ''}${i === 0 ? ' bold' : ''}`} style={{ width: c.width, color: data.active ? "black" : "#2e1956ad" }}>{data.children[4][c.field]}</div>
                        })
                    }
                </div>
            </div>
        </>
    )
}
