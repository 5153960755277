import React, { useEffect, useState } from 'react';

import './index.css';
import Layout from '../../../components/Layout/Layout';

import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { useForm } from 'react-hook-form';
import { IPolicies, PoliciesService } from '../../../services/policiesService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { PPDialog, PPForm2, PPIconLink, PPModal } from 'processor-plataform-ui';
import { useSnackbar } from 'notistack';
import LcTable from '../../../components/Data/LcTable';
import { PrefState } from '../../../store/reducers/prefReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import Confirmation from '../../../components/Dialog/Confirmation';

const PoliciesList: React.FC = (props) => {
    const ServiceInstance = new PoliciesService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [filterVisible, setFilterVisible] = useState(false);
    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [ListData, setListData] = useState<any[]>([]);
    const [FilteredListData, setFilteredListData] = useState<any[]>([]);
    const [visibleFilteredListData, setVisibleFilteredListData] = useState<any[]>([]);

    const [open, setOpen] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);

    const [policeToRemove, setPoliceToRemove] = useState<IPolicies>();

    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        members: false,
        record: ServiceInstance.emptyRecord
    };
    const [record, setRecord] = useState(emptyRecordMessage);

    const [template, setTemplate] = useState<any>({
        title: '',
        Fields: []
    });

    const [template2, setTemplate2] = useState<any>(undefined);

    const columns = [
        { field: 'assignmentName', headerName: 'Política', width: "70%" },
        { field: 'shortName', headerName: 'Nome técnico', width: "30%" },
        // {
        //     field: '',
        //     headerName: '',
        //     sortable: false,
        //     width: 70,
        //     renderCell: (params: any) => 
        //     (
        //         <LcDropDown element={<LcIconLink icon="lci lci-dots-vertical" />} position="right" >
        //             <span className="item" onClick={() => selecionadoEditar(params.row as any)}>Editar</span>
        //             <hr />
        //             <span className="item" onClick={() => DELETE(params.row)}>Excluir</span>
        //         </LcDropDown>
        //     ),
        // }
    ];

    const ADD = async () => {
        let addRecord = { ...emptyRecordMessage };
        addRecord.record = { ...ServiceInstance.emptyRecord };
        addRecord.edit = false;
        addRecord.add = true;
        setRecord({ ...addRecord });
        let template = { ...ServiceInstance.template(addRecord.record, false) };
        let template2 = await ServiceInstance.template2(addRecord.record);
        setTemplate(template);
        setTemplate2(template2);
    };

    const selecionadoEditar = (childData: IPolicies) => {
        if (childData != null) {
            var item = ListData.filter(x => x.id == childData.id)[0];
            EDIT(item);
        }
        else {
            setRecord({ ...emptyRecordMessage })
        }
    }

    const EDIT = async (item: IPolicies) => {
        let addRecord = { ...emptyRecordMessage };
        addRecord.edit = true;
        addRecord.add = true;
        addRecord.detail = false;
        addRecord.record = item;
        setRecord({ ...addRecord });
        let template = { ...ServiceInstance.template(addRecord.record, false) };
        let template2 = await ServiceInstance.template2(addRecord.record);
        setTemplate(template);
        setTemplate2(template2);
    };

    async function loadData() {
        setIsLoadingData(true);
        setFilteredListData([]);

        await ServiceInstance.RetrieveList()
            .then((tt: any) => {
                setListData(tt.data);
                setFilteredListData(tt.data)
            })
            .catch((error) => { console.warn(error) })
            .finally(() => {
                setIsLoadingData(false);
            })

    }

    useEffect(() => {
        loadData();
    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {
        loadData();
    }, [emptyRecordMessage.edit, emptyRecordMessage.add]);

    useEffect(() => {
        var filtered = ListData.filter((p: any) =>
            filter.term == '' ||
            p.assignmentName.toLowerCase().indexOf(filter.term) > -1 ||
            p.shortName.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredListData(filtered);
        setVisibleFilteredListData(filtered.slice(0, filter.size));
    }, [ListData, filter, FilteredListData.length > 0]);

    function resetData() {
        setTemplate2(undefined);
        setRecord({ ...record, record: undefined, edit: false, add: false })
    }

    const functionsRow = [
        {
            label: 'Editar',
            func: (_row: IPolicies) => {
                let row = ListData.find(x => x.id == _row.id)
                if (row) selecionadoEditar(row)
            }
        },
        {
            label: 'Excluir',
            func: (_row: IPolicies) => {
                let row = ListData.find(x => x.id == _row.id)
                setPoliceToRemove(row);
                setConfirmRemoveVisible(true);
            }
        }
    ]

    const handleSave = async (form: any) => {

        if (form.validated) {
            setIsSaving(true);
            let newPolice = record.record;
            form.data.map(field => {
                if (field.value != undefined) {
                    newPolice[field.name] = field.value
                }
            })

            await ServiceInstance.Save(newPolice)
                .then((items: any) => {
                    setRecord({ ...emptyRecordMessage });
                })
                .catch((error) => {
                    enqueueSnackbar("Ocorreu um erro ao tentar salvar a política", {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    })
                })
                .finally(() => { setIsSaving(false); resetData(); loadData() });

        } else {
            enqueueSnackbar("Válidar campos do formulário", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            })
        }
    }

    const removePolice = async (police: IPolicies) => {
        setIsRemoving(true);
        await ServiceInstance.Delete(police.id)
            .then((items: any) => { })
            .catch((error) => {
                enqueueSnackbar("Ocorreu um erro ao tentar excluir a política", {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                })
            })
            .finally(() => { setIsRemoving(false); setConfirmRemoveVisible(false); resetData(); loadData(); });

    }

    const handleRemoveConfirmation = (text?: string) => {
        return (<> Tem certeza que deseja excluir a política <b>{text}</b>?</>)
    }


    return (
        <Layout
            pageTitle="Cadastro políticas aplicáveis"
            row={
                !isLoadingData &&
                <PPIconLink icon='ppi ppi-plus' tooltip='Nova política' onClick={() => { ADD(); }} />
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: visibleFilteredListData.length
            }}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
        >
            <Confirmation
                title="Confirmar a exclusão"
                text={isRemoving ? <LcLoading loading={isRemoving} /> : handleRemoveConfirmation(policeToRemove?.assignmentName)}
                confirm={() => { if (!isRemoving) removePolice(policeToRemove!); }}
                close={() => { if (!isRemoving) setConfirmRemoveVisible(false); }}
                textBtnOk='Sim'
                textBtnCancel='Não'

                display={confirmRemoveVisible}
            />

            {
                isLoadingData ?
                    <LcLoading loading={isLoadingData} />
                    :
                    <LcTable
                        columns={columns}
                        data={visibleFilteredListData}
                        functionsRow={functionsRow}
                        filterFields={[{ label: 'Pesquisar', name: 'assignmentName', type: 'text' }]}
                        filterExternal={{
                            visible: filterVisible,
                            onChange: () => setFilterVisible(!filterVisible)
                        }}
                        density={pref.data?.density || "medium"}
                    />
            }

            {
                <PPModal
                    title={record.record && record.record.assignmentName ? record.record.assignmentName : "Criar nova política"}
                    visible={record.edit == true || record.add == true}
                    onClose={() => {
                        setTemplate2(undefined);
                        setRecord({ ...record, record: undefined, edit: false, add: false })
                    }}
                >
                    {template2 &&
                        <PPForm2
                            fields={template2}
                            onSubmit={handleSave}
                            submitting={isSaving}
                        />}
                </PPModal>
            }
        </Layout >
    );

}

export default PoliciesList;