import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { IconButton, Typography, Box } from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { Assistant, AssistantsResponse } from '../../../services/AIx/assistantsTypes';
import { AssistantService } from '../../../services/AIx/assistants/assistantsService';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import AssistantEditDialog from '../AssistantEditDialog';
import './index.css';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';

const AssistantsList: React.FC = () => {
    const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
    const [assistants, setAssistants] = useState<Assistant[]>([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [filter, setFilter] = useState<string | null>(null);
    const [filterVisible, setFilterVisible] = useState(false);

    const filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'filtro', type: 'text' },
        ],
        onChange: (f: any, _size: number) => {
            console.log(f);
            f && f.length > 0 && f[0] && setFilter(f[0].value);
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const filterSystem = () => (
        <FilterLcInfiniteTable
            filter={filterAdvanced.fields}
            onFilterChange={(updatedFilter, size) => {
                filterAdvanced.onChange(updatedFilter, size || 10);
            }}
        />
    );

    const queryClient = useQueryClient();
    const observerRef = useRef<IntersectionObserver | null>(null);
    const loadMoreRef = useRef<HTMLDivElement | null>(null); 
    const assistantService = new AssistantService({});

    // Use useInfiniteQuery for infinite scrolling
    const {
        data,
        error,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
        remove,
    } = useInfiniteQuery<AssistantsResponse, Error>(
        ['assistants', filter],
        ({ pageParam = null }) => assistantService.getAssistants(20, 'desc', pageParam, null, filter),
        {
            getNextPageParam: (lastPage) => {
                console.log(`pre filter:${filter}`)
                if (filter) {
                    console.log(`inside if filter:${filter}`)
                    return undefined; // Interrompe o carregamento de mais p�ginas quando um filtro � aplicado
                }
                console.log(`after out filter:${filter}`)
                return lastPage.has_more ? lastPage.last_id : undefined;
            },
            refetchOnWindowFocus: false,
        }
    );

    // Reseta os dados quando o filtro muda
    useEffect(() => {
        remove();
        refetch();
    }, [filter]);

    useEffect(() => {
        debugger;
        const response = data?.pages.flatMap(page => page.data);
        setAssistants(response ? response : []);
    }, [data]);

    const handleShowEditForm = (assistant: Assistant) => {
        setSelectedAssistant(assistant);
        setShowEditForm(true);
    };

    const handleAddAssistant = () => {
        setSelectedAssistant({ id: '', name: '', instructions: '', model: 'gpt-4o' } as Assistant);
        setShowEditForm(true);
    };

    const handleCloseEditForm = () => {
        setShowEditForm(false);
        setSelectedAssistant(null);
        queryClient.invalidateQueries(['assistants', filter]);
    };

    // IntersectionObserver para carregar mais dados
    useEffect(() => {
        if (isLoading || isFetchingNextPage || !hasNextPage) return;

        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                fetchNextPage();
            }
        });

        if (loadMoreRef.current) {
            observerRef.current.observe(loadMoreRef.current);
        }

        return () => {
            observerRef.current?.disconnect();
        };
    }, [isLoading, isFetchingNextPage, hasNextPage, fetchNextPage]);

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>;
    }

    const functionsGeneric = [
        {
            title: 'Novo',
            tooltip: 'Criar grupo',
            icon: 'ppi ppi-plus',
            onClick: handleAddAssistant,
            disabled: false
        }
    ];

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.name || 'N/A'}</span>,
        },
        {
            field: 'model',
            headerName: 'Model',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.model}</span>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: '40%',
            align: 'center',
            renderCell: (row: Assistant) => (
                <div className="action-buttons">
                    <IconButton
                        onClick={() => handleShowEditForm(row)}
                        title="Edit"
                        aria-label="Edit assistant"
                        className="icon-button"
                    >
                        <FaEdit />
                    </IconButton>
                </div>
            ),
        },
    ];

    const card: Card[] = [
        {
            id: 1,
            hideHeader: true,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <div style={{ display: 'flex', maxHeight: '100%', minHeight: '100%' }}>
                        { <LcLoading loading={isLoading} />}

                        {!showEditForm && !isLoading && ( 
                            <Box className="table-container" style={{ width: '100%' }}>
                                <LcInfiniteTable
                                    columns={columns}
                                    rows={assistants}
                                    loadMore={fetchNextPage}
                                    size={4000}
                                />
                            </Box>
                        )}

                        {!showEditForm && assistants.length === 0 && (
                            <Typography>No assistants found.</Typography>
                        )}

                        {showEditForm && selectedAssistant && (
                            <AssistantEditDialog
                                assistant={selectedAssistant}
                                handleCloseEditForm={handleCloseEditForm}
                            />
                        )}
                    </div>
                );
            }
        }
    ];

    return (
        <Layout
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible);
                },
            }}
            pageTitle="Assistentes"
            loading={isLoading}
            className="dashboard-layout"
            functionsGeneric={functionsGeneric}
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
        </Layout>
    );
};

export default AssistantsList;
