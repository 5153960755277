export class saasbackup {
    solution() {
        return 'saasbackup'
    }

    site() {
        return 'https://www.processor.com.br/saas-backup'
    }

    logotipo() {
        return require('./assets/logotipos/logo_saasbackup.svg').default
    }
    titulo() {
        return <>Proteja os dados da organização</>
    }

    chamada() {
        return <>SaaS Backup é uma solução hospedada na nuvem, projetada especificamente para proteger os dados nos crescentes portfólios SaaS das organizações. Com ele, a organização tem a tranquilidade de saber que os dados estão seguros contra erros humanos, exclusão, malware, ransomware e outros riscos.</>
    }

    image() {
        return require('./assets/imgs/imagem_saasbackup.png').default
    }

    detalhes() {
        return <>
            <h2>Imagine um mundo onde a perda de dados é coisa do passado e a segurança dos dados da organização não é mais uma preocupação constante.</h2>
            <p><b>Esse mundo é possível com SaaS Backup.</b></p>
            <h3>Por que escolher SaaS Backup?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_saasbackup.png').default} />
                </div>
                <ul>
                    <li><b>Proteção de dados:</b> SaaS Backup protege os dados hospedados em SaaS contra erros humanos, exclusão acidental ou intencional, malware, ransomware e outros riscos.</li>
                    <li><b>Operação e suporte:</b> A equipe da Processor opera as rotinas programadas de backup e restore para soluções em Cloud, oferecendo suporte a ambientes como Microsoft 365, SharePoint, Google Workspace Dynamics, Salesforce, Box e Dropbox.</li>
                    <li><b>Segurança:</b> A solução oferece segurança com base em criptografia no armazenamento dos dados.</li>
                </ul>
            </div>
            <div className="box">SaaS Backup é <b>mais do que uma solução de backup</b>. É uma ferramenta que permite à organização focar no que realmente importa: <b>o crescimento dos negócios.</b> Com SaaS Backup, a organização pode se antecipar aos problemas e garantir que os dados estejam sempre seguros e acessíveis.</div>
            <h3>Não espere o inesperado</h3>
            <p>Não espere que um desastre aconteça para começar a pensar em proteger os dados da organização, entre em contato e descubra como SaaS Backup pode transformar a segurança dos dados da organização.</p>           
        </>
    }
}
export default saasbackup;