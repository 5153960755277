export class cloudmanagedservicespremier {
    solution() {
        return 'cloudmanagedservicespremier'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-premier'
    }

    logotipo() {
        return require('./assets/logotipos/logo_cloudmanagedservicespremier.svg').default
    }
    titulo() {
        return <><b>Acelere os negócios</b> com Cloud Managed Services Premier</>
    }

    chamada() {
        return <>Oferecemos <b>alta disponibilidade e performance de acesso</b>, com respostas imediatas para demandas sazonais. Isso é muito mais que gerenciamento de nuvem - é um passaporte para o futuro do negócio.</>
    }

    image() {
        return require('./assets/imgs/imagem_cloudmanagedservicespremier.png').default
    }

    detalhes() {
        return <>
            <h2>Procurando por hospedagem em nuvem de alta performance e soluções gerenciadas para e-commerce, portais e soluções web?</h2>
            <p><b>LiveCloud Cloud Managed Services Premier é a escolha certa!</b></p>
            <h3>Por que escolher LiveCloud Cloud Managed Services Premier?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_cloudmanagedservicespremier.png').default} />
                </div>
                <ul>
                    <li>Hospedagem em nuvem de alta performance com escalabilidade ágil.</li>
                    <li>Opções de redundância e clusterização.</li>
                    <li>Implantação gerenciada de atualizações dos portais com garantia de continuidade dos serviços.</li>
                    <li>Operação do ambiente com cobertura 5 x 8 ou 7 x 24, conforme contratação.</li>
                    <li>Suporte para chamados vinculados a disponibilidade dos serviços, com cobertura 7 x 24.</li>
                    <li>Interface gerencial <b>LiveCloud</b> para gestão, abertura de incidentes e acompanhamento dos serviços contratados.</li>
                </ul>
            </div>
            <div className="box">Com <b>LiveCloud Cloud Managed Services Premier</b> a organização tem acesso a um conjunto completo de recursos, incluindo: Operação Contratada ou Compartilhada, Monitoramento (LiveWatch), Max Software Enterprise para LiveCloud, 20 horas por mês de Professional Services, <b>FinOps incluso</b>, Reengenharia de arquitetura semestral e Segurança EDR.</div>
            <h3>Interface gerencial única</h3>
            <p>Com <b>LiveCloud</b>, tudo o que a organização precisa está em um só lugar! Nossa interface gerencial fornece uma visão clara de todos os serviços contratados, incluindo painéis de administração, níveis de serviço, visões gerenciais de monitoramento, disponibilidade dos serviços, avaliação e predição de performance e consumo, solicitação de serviços, abertura de chamados, logs das principais atividades e evolução e demais alterações do ambiente.</p>
            <h3>Níveis de serviço</h3>
            <p>Nosso compromisso com a qualidade do serviço é comprovado por nossos Níveis de Serviço (SLA): temos um tempo de início de <b>SLA de 20 minutos</b> e um tempo de fim de <b>SLO de 1 hora</b>. Isso significa que nos esforçamos para começar a trabalhar em qualquer problema dentro de 20 minutos e tê-lo resolvido em até 1 hora. É um desempenho excepcional que mantém os serviços funcionando sem problemas.</p>
            <h3>Pronto para dar o próximo passo com LiveCloud Cloud Managed Services Premier?</h3>            
        </>
    }
}
export default cloudmanagedservicespremier;