export class identityaccess {
    solution() {
        return 'identityaccess'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-identidade-e-acesso'
    }

    logotipo() {
        return require('./assets/logotipos/logo_identityaccess.svg').default
    }
    titulo() {
        return <>Aumente a <b>segurança e eficiência do negócio</b> com LiveCloud Identidade e Acesso</>
    }

    chamada() {
        return <>Permite o gerenciamento da identificação, autenticação e autorização dos usuários através de políticas e procedimentos de controle, protegendo contra o acesso não autorizado a sistemas e dados, reduzindo o risco de violações de segurança.</>
    }

    image() {
        return require('./assets/imgs/imagem_identityaccess.png').default
    }

    detalhes() {
        return <>
            <h2>Já pensou em como seria ter total controle sobre quem tem acesso aos sistemas e recursos?</h2>
            <p><b>Com LiveCloud Identidade e Acesso, isso é mais do que possível, é uma realidade ao alcance da organização.</b></p>
            <h3>Por que escolher LiveCloud Identidade e Acesso?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_identityaccess.png').default} />
                </div>
                <ul>
                    <li><b>Segurança reforçada:</b> Com recursos como autenticação multi-fator e gerenciamento de acessos privilegiados, protegemos contra acessos não autorizados e potenciais violações de dados.</li>
                    <li><b>Eficiência operacional:</b> Com o provisionamento automatizado de usuários e a capacidade de gerenciar acessos de um local centralizado, simplificamos processos complexos e economizamos tempo para o time de TI.</li>
                    <li><b>Conformidade facilitada:</b> Com registros detalhados de atividades de usuários e acessos, tornamos mais fácil para as organizações demonstrarem conformidade com regulamentações de segurança de dados.</li>
                    <li><b>Experiência do usuário aprimorada:</b> Permitimos que os usuários acessem vários sistemas ou aplicações com um único conjunto de credenciais, melhorando a experiência do usuário enquanto mantemos a segurança.</li>
                    <li><b>Otimização de custos:</b> Ao automatizar o gerenciamento de usuários e acessos e reduzir o risco de violações de segurança, a solução pode levar a uma otimização dos custos operacionais e de conformidade.</li>
                </ul>
            </div>
            <div className="box">Com LiveCloud Identidade e Acesso, a organização tem a confiança de que as políticas sejam aplicadas em todos os ambientes, proporcionando uma <b>visão gerencial das permissões e acessos</b>, protegendo o ao reduzir possíveis vulnerabilidades.</div>
            <h3>Interface gerencial única</h3>
            <p>Oferecemos uma <b>interface gerencial única</b>, permitindo o acompanhamento dos serviços contratados pela organização. Essa interface pode disponibilizar painéis de administração, níveis de serviço, visões gerenciais de monitoramento, disponibilidade dos serviços, avaliação e predição de performance e consumo, solicitação de serviços, abertura de chamados e logs das principais atividades, além da evolução e demais alterações do ambiente.</p>
            <h3>Benefícios para a organização</h3>
            <p>Com LiveCloud Identidade e Acesso, a organização tem a segurança reforçada, melhoria da eficiência operacional, conformidade facilitada, experiência do usuário aprimorada e otimização de custos. <b>Tudo isso em uma única solução!</b></p>            
            <h3>Não perca tempo!</h3>
            <p>O futuro da segurança e eficiência do negócio está a apenas um clique de distância. Entre em contato conosco hoje mesmo para descobrir como LiveCloud Identidade e Acesso pode transformar o negócio.</p>           
        </>
    }
}
export default identityaccess;