import React from 'react';
import LcInfiniteTable from '../../components/Data/LcInfiniteTable';

import ReactECharts from "echarts-for-react"

const TabGrid: React.FC = () => {

    const data = [
        { Nfi_Codigo_NF: "0001", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0002", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0003", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0004", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0005", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0006", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0007", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0008", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0009", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0010", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0011", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0012", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" },
        { Nfi_Codigo_NF: "0013", Nfi_Codigo_NFSE: "0001", Grp_Descricao: "0001", Cli_Razao_Social: "0001" }
    ]

    const columns = [
        { field: "Nfi_Codigo_NF", headerName: "RPS", width: "25%" },
        { field: "Nfi_Codigo_NFSE", headerName: "Número Nota", width: "25%" },
        { field: "Grp_Descricao", headerName: "Grupo", width: "25%", align: "left" },
        { field: "Cli_Razao_Social", headerName: "Razão social", width: "25%" }
    ];


    return (<>
        <h1>Grid</h1>

        <LcInfiniteTable
            columns={columns}
            rows={data}
            loadMore={() => { }}
            size={data.length}
            density="medium"
        //status={(row) => { return row.Nfi_Codigo_NF == "0007" ? "#0f0" : "#66f" }}
        />

    </>);
}

export default TabGrid;