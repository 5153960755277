import React, { useEffect, useState, ChangeEvent } from 'react';

//Input
import LcToggle from '../../../components/Form/LcToggle';
import { InputField } from '../../../components/Form/Input';


import './index.css';
import LcCheckBox from '../LcCheckBox';

interface Item {
    value: string,
    selected: boolean
}

interface Props {
    title?: string,
    list?: Array<Item>,
    toggle?: boolean,
    search?: boolean,
    searchPlaceHolder?: string,
    onChange?: Function;
    labelField: string;
    keyField: string;
    selectedList?: Array<Item>;
}

const LcCheckLabelValueList: React.FC<Props> = (props) => {

    const [list, setList] = useState<Array<{ [key: string]: any }>>([]);
    const [selectedList, setSelectedList] = useState<Array<{ [key: string]: any }>>(props.selectedList ?? []);
    const [filterText, setFilterText] = useState<string>();

    useEffect(() => {
        if (props.list)
            setList(props.list);
    }, [props]);

    const toggleCheckAll = (e) => {
        const updatedList = e.checked ? [...list] : [];
        setSelectedList(updatedList);
        if (props.onChange) props.onChange(updatedList);
    }

    const toggleItem = (item: any) => {
        //// 
        var foundCount = selectedList.filter(x => x[props.keyField] == item[props.keyField]).length;
        if (foundCount == 0) {
            selectedList.push(item);
            setSelectedList([...selectedList]);
            if (props.onChange)
                props.onChange([...selectedList]);
        } else {
            setSelectedList([...selectedList.filter(x => x[props.keyField] != item[props.keyField])]);
            if (props.onChange)
                props.onChange([...selectedList.filter(x => x[props.keyField] != item[props.keyField])]);
        }


    }

    return (
        <div className="lc-checklabelvaluelist fullHeight">
            {
                props.search &&
                <div className="filter">
                    <InputField type="text" placeHolder={props.searchPlaceHolder} name="search" icon="lci lci-search" onChange={(event: any) => setFilterText(event.value)} />
                </div>
            }
            <div className="header">
                {
                    props.toggle ?
                        <LcToggle toggled={selectedList.length == list.length} onChange={(e) => { toggleCheckAll(e) }} />
                        :
                        <LcCheckBox checked={selectedList.length == list.length} onChange={(e) => { toggleCheckAll(e) }} />
                }
                <span className="title">{props.title}</span>
            </div>
            <div className="fullHeight list scrollable-v">
                {
                    list &&
                    list.map((item, index) => {
                        return (
                            (!filterText || item[props.labelField].toLowerCase().includes(filterText.toLowerCase())) &&
                            <div key={index} className="item" onClick={() => toggleItem(item)}>
                                {
                                    props.toggle ?
                                        <LcToggle toggled={selectedList.filter(x => x[props.keyField] == item[props.keyField]).length > 0} />
                                        :
                                        <LcCheckBox checked={selectedList.filter(x => x[props.keyField] == item[props.keyField]).length > 0} />
                                }
                                <label>{item[props.labelField]}</label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default LcCheckLabelValueList;