import api, { APIClient } from '../../api';
import { Thread } from '../assistantsTypes';
import axios from 'axios';

export default class ThreadService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    //public async createThread(thread: Thread) {
    //    var url = `${this.endpoint}/api/threads?refresh=${Math.random()}`
    //    console.log(url);
    //    const response = await api.post(url, thread);
    //    console.log(response.data);
    //    return response.data;
    //}

    public async createThread(thread: Thread) {
        var url = `${this.baseUrl}/api/threads/`
        console.log(url)
        const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
        const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
        let apiAIX = axios.create({
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                //     PathRoute: window.location.pathname,
                //'Access-Control-Allow-Origin': '*'
            }
        });
        console.log(url);
        const response = await apiAIX.post(url, thread, {
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                //     PathRoute: window.location.pathname,
               // 'Access-Control-Allow-Origin': '*'
            }
        });
        console.log(response.data)
        return response.data;
    }
   

    public async getThread(threadId: string) {
        var url = `${this.endpoint}/api/threads/${threadId}?refresh=${Math.random()}`
        console.log(url);
        const response = await api.get(url);
        console.log(response.data);
        return response.data;
    }

    public async updateThread(threadId: string, thread: Thread) {
        var url = `${this.endpoint}/api/threads/${threadId}?refresh=${Math.random()}`
        console.log(url);
        const response = await api.post(url, thread);
        console.log(response.data);
        return response.data;
    }

    public async deleteThread(threadId: string) {
        var url = `${this.endpoint}/api/threads/${threadId}?refresh=${Math.random()}`
        console.log(url);
        const response = await api.delete(url);
        console.log(response.data);
        return response.data;
    }

    public async getThreadsByAssistantId(assistantId: string): Promise<Thread[]> {
        var url = `${this.endpoint}/api/threads?assistantId=${assistantId}&refresh=${Math.random()}`
        console.log(url);
        const response = await api.get(url);
        console.log(response.data);
        return response.data;
    }
}
