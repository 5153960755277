import React, { useEffect, useState } from 'react';
import AutomationsService from '../../../services/automations/automationsService';
import Layout from '../../../components/Layout/Layout';
import './index.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json';
import { PrefState } from '../../../store/reducers/prefReducer';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { TbCalendarCheck, TbCloudCode, TbCloudQuestion, TbInfoCircle, TbSettingsAutomation } from 'react-icons/tb';
import LcNoData from '../../../components/Generic/LcNoData';
import LcMobileStepper from '../../../components/Form/LcMobileStepper';
import { LiaPiggyBankSolid } from 'react-icons/lia';
import PPDropDown from '../../../components/PPDropDown';
import WeeklySavingsEstimateChart from './WeeklyEstimateCharts/Savings';
import { SiKubernetes, SiMicrosoftazure } from 'react-icons/si';
import { FaAws } from 'react-icons/fa';
import { makeStyles, Badge, Tooltip } from '@material-ui/core';
import { SlScreenDesktop } from 'react-icons/sl';
import { PiCalendarCheckLight, PiClockClockwiseLight, PiCloudWarningLight, PiCodeSimpleLight, PiDatabase, PiDatabaseLight, PiDesktopLight, PiFileCsvLight, PiGearFineThin, PiGearThin, PiMoon, PiPauseCircleLight, PiPencilSimpleLight, PiPiggyBankLight, PiPlayCircleLight, PiPlusLight, PiRobotLight, PiSun } from 'react-icons/pi';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { SliderField } from '../../../components/Form/Input';
import { PPInput } from 'processor-plataform-ui';
import AutomationForm from './Form';
import { PPModal } from 'processor-plataform-ui';
import Confirmation from '../../../components/Dialog/Confirmation';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';
import {
    AutomationAudit, AutomationFilterFields, AutomationInstanceType,
    AutomationProvider, AutomationResource, AutomationRunHistory,
    AutomationsScheduler, AutomationsSchedulerData, AutomationTableFilter,
    AutomationTemplate, AutomationUserAccess, CetegorizedResource, TaskItemDuration
} from '../../../services/automations/automationsModel';
import { useQuery } from 'react-query';
import { convertDateFormat } from '../../../services/toolsService';
import AutomationDetails from './Details';

const useStyles = makeStyles(theme => ({
    customBadge: {
        marginRight: '4vh',
    },
    customBadgeWithFilter: {
        marginRight: '3.0vh',
        marginLeft: '0.4vh'
    },
}));

const Automations: React.FC = (props) => {

    // Serviços
    const automationsService = new AutomationsService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    moment.locale('pt-br');

    // Metodos de suporte e dicionarios
    const showInfo = (msg: string, type: 'default' | 'error' | 'success' | 'warning' | 'info') => {
        enqueueSnackbar(msg, { variant: type, preventDuplicate: true, persist: false });
    }

    const parseSchedulerData = (json: string) => {
        if (json && json !== '')
            return JSON.parse(json ?? '{}') as AutomationsSchedulerData;

        return automationsService.emptyAutomationsSchedulerData;
    }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: 'density', value: density } });
    }

    const userAccess: AutomationUserAccess = {
        canListAll: user.IsAdm || user.Permission.some(p =>
            p.permissoesChave == "Permite_Visualizar_Automacoes_Agendadas" && p.permissoesValor.toLowerCase() == 'sim'
        ),
        canRequestSchedule: !user.IsAdm && user.Permission.some(p =>
            p.permissoesChave == 'Permite_Agendar_Automacoes_Via_Chamado' && p.permissoesValor.toLowerCase() == 'sim'
        ),
        canCreateAvailability: user.IsAdm || user.Permission.some(p =>
            p.permissoesChave == 'Permite_Agendar_Automacoes_Disponibilidade' && p.permissoesValor.toLowerCase() == 'sim'
        ),
        canCreateResizing: user.IsAdm || user.Permission.some(p =>
            p.permissoesChave == 'Permite_Agendar_Automacoes_Redimensionamento' && p.permissoesValor.toLowerCase() == 'sim'
        ),
        canRemove: user.IsAdm || user.Permission.some(p =>
            p.permissoesChave == 'Permite_Remover_Automacoes_Agendadas' && p.permissoesValor.toLowerCase() == 'sim'
        ),
    }

    const isControlPlane = user.ClientGroupSelected?.toString() === '999999999';

    //#region P. Economia Mensal
    const savingsMessage = 'Valor estimado de acordo com as automações ativas em seu ambiente cloud, podendo sofrer alterações com a incidência de impostos e/ou serviços.';
    //#endregion

    // States
    const [allowedProviders, setAllowedProviders] = useState<AutomationProvider[]>([]);
    const [schedulers, setSchedulers] = useState<AutomationsScheduler[]>([]);
    const [scheduler, setScheduler] = useState<AutomationsScheduler>(automationsService.emptyAutomationsScheduler);
    const [schedulerToEdit, setSchedulerToEdit] = useState<AutomationsScheduler>();
    const [filteredSchedulers, setFilteredSchedulers] = useState<AutomationsScheduler[]>([]);
    const [schedulerFilter, setSchedulerFilter] = useState<AutomationTableFilter>(automationsService.emptySchedulerFilter);
    const [schedulerFilterFields, setSchedulerFilterFields] = useState<AutomationFilterFields>(automationsService.emptySchedulerFilterFields);
    const [activeSchedulerCount, setActiveSchedulerCount] = useState<number>(0);
    const [schedulerData, setSchedulerData] = useState<AutomationsSchedulerData>(automationsService.emptyAutomationsSchedulerData);
    const [nextSchedulers, setNextSchedulers] = useState<AutomationsScheduler[]>([]);
    const [templates, setTemplates] = useState<AutomationTemplate[]>([]);
    const [template, setTemplate] = useState<AutomationTemplate>(automationsService.defaultTemplate);
    const [resource, setResource] = useState<AutomationResource>(automationsService.emptyAutomationResource);
    const [resourceInstance, setResourceInstance] = useState<AutomationInstanceType>();
    const [relatedAutomations, setRelatedAutomations] = useState<AutomationsScheduler[]>([]);
    const [runHistory, setRunHistory] = useState<AutomationRunHistory[]>([]);
    const [auditRecords, setAuditRecords] = useState<AutomationAudit[]>([]);
    const [isLoadingAutomations, setIsLoadingAutomations] = useState<boolean>(false);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);
    const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingResource, setIsLoadingResource] = useState<boolean>(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
    const [isLoadingAudit, setIsLoadingAudit] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showToggleActiveConfirmation, setShowToggleActiveConfirmation] = useState<boolean>(false);
    const [showHistoricModal, setShowHistoricModal] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const [showAuditModal, setShowAuditModal] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [filterIsPinned, setFilterIsPinned] = useState<boolean>(false);
    const [savingsChartPeriod, setSavingsChartPeriod] = useState<{ label: string, value: string }>({ label: 'Esta semana', value: 'WEEK' });

    const styleClasses = useStyles();

    // Define a desativacao de campos e botoes em algumas situacoes
    const generalDisabled = {
        create: isControlPlane || isLoading,
        csvExport: isLoading,
        filter: isLoading || filteredSchedulers.length === 0,
        edit: isControlPlane || isLoadingResource || (!userAccess.canCreateAvailability && !userAccess.canCreateResizing),
        remove: isControlPlane || isLoadingResource || !userAccess.canRemove,
        toggleStatus: isControlPlane || isLoadingResource || (!userAccess.canCreateAvailability && !userAccess.canCreateResizing),
        viewDetails: !isControlPlane && !isLoadingResource && userAccess.canListAll,
    }

    // Carrega dados de agendamento de automacoes ao mudar o client ou refresh
    const loadAutomations = () => {
        const load = async () => {
            //   if (userAccess.canListAll) {
            setIsLoadingAutomations(true);
            try {
                let _schedulers = await automationsService.GetAutomations();
                setSchedulers(_schedulers);
                let _actives = _schedulers.filter((s: AutomationsScheduler) => s.status == 1);
                setActiveSchedulerCount(_actives.length);
                setNextSchedulers(findNextSchedulers(_actives));
            } catch (error) {
                console.error(`[ERRO AUTOMAÇÕES] Carregamento das automações: ${error}`)
            } finally {
                setIsLoadingAutomations(false);
            }
            ////  } else
            //  showInfo('Usuário não tem permissão para listar automações!', 'warning');
        }
        load();
    }
    useEffect(loadAutomations, [user.ClientGroupSelected, user.refreshFlag]);

    // Carrega os provedores disponiveis ao mudar o client ou refresh
    const loadProviders = () => {
        const load = async () => {
            //    if (userAccess.canListAll) {
            setIsLoadingProviders(true);
            try {
                let _providers = await automationsService.GetProviders();
                setAllowedProviders(_providers.data as AutomationProvider[]);
            } catch (error) {
                console.error(`[ERRO AUTOMAÇÕES] Checagem dos provedores: ${error}`)
            } finally {
                setIsLoadingProviders(false);
            }
            //   } else
            //       showInfo('Usuário não tem permissão para listar automações!', 'warning');
        }

        load();
    }
    useEffect(loadProviders, [user.ClientGroupSelected, user.refreshFlag]);

    // Carrega os templates de automacoes uma unica vez
    const loadTemplates = () => {
        const load = async () => {
            //   if (userAccess.canListAll) {
            try {
                setIsLoadingTemplates(true);
                let _templates = await automationsService.GetTemplates();
                setTemplates(_templates.data);
            } catch (error) {
                console.error(`[ERRO AUTOMAÇÕES] Carregamento dos templates: ${error}`)
            } finally {
                setIsLoadingTemplates(false);
            }
            //   } else
            //      showInfo('Usuário não tem permissão para listar automações!', 'warning');
        }

        load();
    }
    useEffect(loadTemplates, []);

    // Carrega o recurso a ser exibido nos detalhes
    const loadResource = (resource: CetegorizedResource) => {
        const load = async () => {
            setIsLoadingResource(true);
            try {
                let _provider = [null, 'Azure', 'AWS'].indexOf(resource.provider);
                let _groupType = resource.groupType;
                let _subscription = resource.subscription;
                let _resResult = await automationsService.GetResourcesBySubscription(_provider, _groupType, _subscription);
                let _resource: AutomationResource = _resResult.find(rd => rd.resourceName == resource.name || rd.resourceID.includes(resource.id))! ?? automationsService.emptyAutomationResource;
                let _related = filterRelatedAutomationsForForm(_resource);
                setRelatedAutomations(_related);
                if (_resource) {
                    setResource(_resource);
                    let _instanceSize = _resource.instanceSize;
                    let _instResult = await automationsService.GetInstanceTypeByLabel(_provider, _groupType, _subscription, _instanceSize);
                    let _instanceType: AutomationInstanceType = _instResult.data;
                    if (_instanceType)
                        setResourceInstance(_instanceType);
                }
            } catch (error) {
                console.error(`[ERRO AUTOMAÇÕES] Carregamento do recurso: ${error}`)
            }
            setIsLoadingResource(false);

        }

        load();
    }

    // Carrega o histórico de execução
    const loadAutomationRunHistory = () => {
        if (scheduler && scheduler.id! > 0 && showHistoricModal) {
            setIsLoadingHistory(true);
            automationsService.GetAutomationRunHistory(scheduler.id!)
                .then(result => setRunHistory(
                    result.data.sort((a, b) => a['date'] > b['date'] ? -1 : 1)
                ))
                .catch(error => console.error(error))
                .then(() => setIsLoadingHistory(false))
        }
    }
    useEffect(loadAutomationRunHistory, [showHistoricModal]);

    // Carrega as auditorias
    const loadAutomationAuditRecords = () => {
        if (scheduler && scheduler.id! > 0 && showAuditModal) {
            setIsLoadingAudit(true);
            automationsService.GetAuditRecords(scheduler.id!)
                .then(result => {
                    if (result.status == 204)
                        result.data = [];

                    setAuditRecords(
                        result.data.sort((a, b) => moment(a['timestamp']) < moment(b['timestamp']) ? -1 : 1)
                    )
                })
                .catch(error => console.error(error))
                .then(() => setIsLoadingAudit(false))
        }
    }
    useEffect(loadAutomationAuditRecords, [showAuditModal]);

    // Executa a filtragem da tabela com dados do Filtro
    const filterTableData = () => {
        let filtered = [...schedulers];

        let { providerId, groupType, automationId, resourceId, hourScheduled, status } = schedulerFilter;

        let notEmpty = providerId !== automationsService.emptySchedulerFilter.providerId
            || groupType !== automationsService.emptySchedulerFilter.groupType
            || automationId !== automationsService.emptySchedulerFilter.automationId
            || resourceId !== automationsService.emptySchedulerFilter.resourceId
            || hourScheduled !== automationsService.emptySchedulerFilter.hourScheduled
            || status !== automationsService.emptySchedulerFilter.status;

        if (notEmpty) {
            if (!isNaN(providerId) && providerId > 0)
                filtered = filtered.filter((p: AutomationsScheduler) => {
                    let _template = templates.find(t => t.id == p.automationId);
                    let id = _template?.cloudOSId ?? 0;
                    return id == providerId;
                })
            if (groupType !== '')
                filtered = filtered.filter((p: AutomationsScheduler) => {
                    let _template = templates.find(t => t.id == p.automationId);
                    let group = _template?.groupType ?? '';
                    return group == groupType;
                })
            if (automationId !== '')
                filtered = filtered.filter((p: AutomationsScheduler) => {
                    let _template = templates.find(t => t.id == p.automationId)!;
                    let _templateNameDic = automationsService.automationTemplatesDic[_template.name][1];

                    return _templateNameDic === automationId
                })
            if (resourceId !== '')
                filtered = filtered.filter((p: AutomationsScheduler) => {
                    let data = JSON.parse(p.data as string);
                    let resource = data.ResourceId ?? data.ResourceName;
                    return resource.toLowerCase().indexOf(resourceId) > -1
                })
            if (hourScheduled !== '') {
                let aHorario = hourScheduled.split(',').map((v: string, i: number) => i == 0 ? `${v}:00` : `${v}:59`)
                filtered = filtered.filter((p: AutomationsScheduler) => moment(p.hourScheduled, 'hh:mm')
                    .isBetween(moment(aHorario[0], 'hh:mm'), moment(aHorario[1], 'hh:mm'), null, '[]')
                );
            }
            if (!isNaN(status) && status > 0)
                filtered = filtered.filter((p: AutomationsScheduler) => {
                    let cond = [true, p.status == 0, p.status >= 1];
                    return cond[status];
                });
        }

        filtered = filtered.sort((a: any, b: any) => automationsService.emptySchedulerFilter.direction == 'asc' ?
            a[automationsService.emptySchedulerFilter.column] - b[automationsService.emptySchedulerFilter.column] : b[automationsService.emptySchedulerFilter.column] - a[automationsService.emptySchedulerFilter.column]
        );

        setFilteredSchedulers(filtered);
    }
    useEffect(filterTableData, [schedulers, schedulerFilter]);

    // Reune informacoes sobre um recurso usando apenas dados da automacao
    const categorizeResourceFromAutomation = (scheduler: AutomationsScheduler, iconSize: number = 16, iconColor: string = '#2E1956') => {
        let _template = templates.find(t => t.id === scheduler.automationId);
        let _data = parseSchedulerData(scheduler.data);
        let _result = { id: '', provider: '', name: '', subscription: '', groupType: '', serviceType: '', icon: <></> } as CetegorizedResource;
        let _resourceIcons = {
            'VM': <PiDesktopLight size={`${iconSize}px`} color={iconColor} />,
            'DB': <PiDatabaseLight size={`${iconSize}px`} color={iconColor} />,
            'Container': <SiKubernetes size={`${iconSize}px`} color={iconColor} />,
            'AppServicePlan': <PiCodeSimpleLight size={`${iconSize}px`} color={iconColor} />,
            'unknown': <PiCloudWarningLight size={`${iconSize}px`} color={iconColor} />
        }
        let _resourceIconTooltips = {
            'VM': 'Máquina virtual',
            'DB': 'Banco de dados',
            'unknown': 'Não identificado'
        }

        let _friendlyName = '-';
        if (_data.CloudOS == 'AWS') {
            if (_data.hasOwnProperty('ClusterName'))
                _friendlyName = _data.ClusterName! ?? '-';
            else if (_data.hasOwnProperty('ResourceId') && _data.ResourceId?.includes(":"))
                _friendlyName = _data.ResourceId.split(":")[4] ?? '-';
            else
                _friendlyName = _data.ResourceId! ?? '-';
        } else
            _friendlyName = _data.ResourceName! ?? '-';

        if (_template && _data.Automation !== '') {
            _result.id = _data.ResourceId ?? '';
            _result.provider = _data.CloudOS;
            _result.name = _friendlyName;
            _result.subscription = _data.SubscriptionId ?? _data.AccountId ?? '';
            _result.groupType = _template.groupType;
            _result.serviceType = _template.serviceType;
            _result.icon = (
                <Tooltip arrow title={_resourceIconTooltips[_template.groupType]}>
                    <span>{_resourceIcons[_template.groupType]}</span>
                </Tooltip>
            );
        }

        return _result;
    }

    // Encontra as proximas automacoes relativas a data e hora atual
    const findNextSchedulers = (automations: AutomationsScheduler[]) => {
        const now = moment();
        const daysMap = { 'SUN': 0, 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6 };

        const nextSchedule = (automation) => {
            const weekDays = automation.weekDaysScheduled.split('-').map(day => daysMap[day]);
            const scheduledTime = moment(automation.hourScheduled, 'HH:mm:ss');

            for (let i = 0; i <= 7; i++) {
                const day = (now.day() + i) % 7;
                if (weekDays.includes(day)) {
                    const nextDateTime = now.clone().add(i, 'days').set({
                        hour: scheduledTime.hour(),
                        minute: scheduledTime.minute(),
                        second: scheduledTime.second()
                    });
                    if (nextDateTime.isAfter(now)) {
                        return nextDateTime;
                    }
                }
            }
        }

        var nextAutomations = automations
            .map(automation => ({ ...automation, nextSchedule: nextSchedule(automation) }))
            .sort((a, b) => (a.nextSchedule as any) - (b.nextSchedule as any));

        if (nextAutomations.length > 0)
            nextAutomations.forEach(na => na.nextSchedule = na.nextSchedule?.format('dddd [às] HH:mm')! as any);

        return nextAutomations;
    }

    // Exibe ou esconde o modal de criacao/edicao
    const toggleFormModal = (id: number = 0) => {
        if (id > 0) {
            let schedule = schedulers.find(s => s.id == id)!;
            let templateLocal = templates.find(t => t.id == schedule.automationId)!;
            setSchedulerToEdit(schedule);
            setTemplate(templateLocal);
        } else
            setSchedulerToEdit(undefined);

        setShowForm(true);
    }

    const toggleDetailsModal = (_resource: CetegorizedResource) => {
        loadResource(_resource);
        setShowDetailsModal(true);
    }

    const toggleRunHistoryModal = (id: number) => {
        if (!showHistoricModal) {
            let _s = schedulers.find(s => s.id == id)!;
            let _t = templates.find(t => t.id == _s.automationId)!;
            setScheduler(_s);
            setTemplate(_t);
        }

        setShowHistoricModal(!showHistoricModal);
    }

    const toggleAuditModal = (id: number) => {
        if (!showAuditModal) {
            let _s = schedulers.find(s => s.id == id)!;
            let _t = templates.find(t => t.id == _s.automationId)!;
            setScheduler(_s);
            setTemplate(_t);
        }

        setShowAuditModal(!showAuditModal);
    }

    const confirmToggleStatus = (id: number) => {
        setScheduler(schedulers.find(s => s.id == id)!);
        setShowToggleActiveConfirmation(true);
    }

    const toggleAutomationStatus = () => {
        setShowToggleActiveConfirmation(false);

        // if (!confirm) return;

        if (scheduler.status == 0) {
            showInfo(`Ativando automação...`, 'info');
            automationsService.EnableAutomation(scheduler.id)
                .then(result => {
                    if (result.status == 200)
                        showInfo(`Automação ativada!`, 'success');
                })
                .catch(error => {
                    console.error(error);
                    showInfo(`Erro ao ativar automação.`, 'error');
                })
                .then(() => {
                    loadAutomations()
                })
        }


        if (scheduler.status == 1) {
            showInfo(`Desativando automação...`, 'info');
            automationsService.DisableAutomation(scheduler.id)
                .then(result => {
                    if (result.status == 200)
                        showInfo(`Automação desativada!`, 'success');
                })
                .catch(error => {
                    console.error(error);
                    showInfo(`Erro ao desativar automação.`, 'error');
                })
                .then(() => {
                    loadAutomations()
                })
        }

    }

    const handleTableSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setSchedulerFilter({ ...schedulerFilter, column: sort.column, direction: sort.direction, size: size })
    };

    const exportToCSV = () => {
        showInfo(`Gerando arquivo com todas as automações`, 'info');
        const csvData = filteredSchedulers.map((csvRow) => {
            let json: AutomationsSchedulerData = JSON.parse(csvRow.data as string)
            let dayInWeek = csvRow.weekDaysScheduled.split('-');
            let days = dayInWeek.map(day => automationsService.automationsWeekDic[day][1])
            return {
                'Automação': automationsService.automationTemplatesDic[templates.find(tpl => tpl.id == csvRow.automationId)?.name!][1],
                'Recurso': json.ResourceName || json.ResourceId,
                'Dias da semana': days.join(','),
                'Horário de início': csvRow.hourScheduled,
                'Última execução': csvRow.lastRun,
                'Status': csvRow.status === 1 ? 'Habilitada' : 'Desabilitada'
            }
        })
        const fields = ['Automação', 'Recurso', 'Dias da semana', 'Horário de início', 'Última execução', 'Status']
        const data = csvData;
        const fileName = 'Automações';
        const exportType = 'csv';
        const withBOM = true;
        exportFromJSON({ data, fields, fileName, exportType, withBOM, delimiter: ';' })
        showInfo(`Completada geração de excel de autmações`, 'success');
    }

    const renderNextSchedulers = (quantity: number) => {
        if (templates.length > 0 && nextSchedulers !== undefined && nextSchedulers.length > 0) {

            let _steps = nextSchedulers?.slice(0, quantity).map(ns => {
                let _resource = categorizeResourceFromAutomation(ns);
                return {
                    content: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            fontSize: '0.8rem',
                            width: showFilterModal && filterIsPinned ? '190px' : '200px'
                        }}>
                            <div style={{ fontWeight: '700', fontSize: '16px' }}>{_resource.name}</div>
                            <div className={'overflowEllipsis'} style={{ padding: '5px', width: '100%' }}>{automationsService.automationTemplatesDic[templates.find(tpl => tpl.id == ns?.automationId)?.name!][1]}</div>
                            <div style={{ padding: '5px', width: '100%' }}>{ns['nextSchedule']}</div>
                        </div>)
                }
            })

            return (
                <div style={{ height: '100px', width: '80%' }}>
                    <LcMobileStepper steps={_steps} autostep={15} hideSteppers />
                </div>
            )
        }

        return <></>
    }

    const extractAutomationTemplateFromDics = (sched: AutomationsScheduler) => {
        const automationsIconDic = {
            'Azure': <SiMicrosoftazure size={'14px'} color='#2E1956' />,
            'AWS': <FaAws size={'16px'} color='#2E1956' />
        }
        let _templateName = templates.find(tpl => tpl.id == sched.automationId)?.name!
        let _automationDicLine = automationsService.automationTemplatesDic[_templateName];
        return {
            templateDic: _automationDicLine ?? `Automação ${sched.automationId}`,
            icon: automationsIconDic[_automationDicLine[0]]
        }
    }

    const renderAutomationNameAndIconForTable = (sched: AutomationsScheduler) => {
        let { templateDic, icon } = extractAutomationTemplateFromDics(sched);
        let provider = Array.isArray(templateDic) ? templateDic[0] : '-';
        let name = Array.isArray(templateDic) ? templateDic[1] : templateDic;
        return (
            <div className='automationIdColumn'>
                <Tooltip arrow title={provider === 'Azure' ? 'Microsoft Azure' : (provider === 'AWS' ? 'Amazon AWS' : '')}>
                    <span className='automationIdIcon'>{icon}</span>
                </Tooltip>
                <Tooltip arrow title={templateDic[1]}>
                    <span className='overflowEllipsis ml-4'>{name}</span>
                </Tooltip>
            </div>
        )
    }

    const renderAutomationResourceNameAndIconForTable = (sched: AutomationsScheduler) => {
        let _resource = categorizeResourceFromAutomation(sched);
        let _onClick = () => generalDisabled.viewDetails && toggleDetailsModal(_resource);
        return (
            <>
                <span>{_resource.icon}</span>
                <span className='detailLink overflowEllipsis' onClick={_onClick} style={{ marginLeft: '5px' }}>
                    {_resource.name}
                </span>
            </>)
    }

    const translateAutomationWeekDays = (days: string[]): object => {
        let automationDays = Object.keys(automationsService.automationsWeekDic);
        let translated = {};

        automationDays.forEach(day => translated[automationsService.automationsWeekDic[day][0]] = days.includes(day) ? '1' : '0');

        return translated;
    }

    const renderAutomationWeekDaysForTable = (sched: AutomationsScheduler) => {
        let translated = translateAutomationWeekDays(sched.weekDaysScheduled.split('-'));
        return (
            <div className='weekDaysScheduledColumn'>
                {Object.keys(translated).map((dia, i) => {
                    return (
                        <div key={i}>
                            <Badge
                                classes={{
                                    root: showFilterModal && filterIsPinned ?
                                        styleClasses.customBadgeWithFilter :
                                        styleClasses.customBadge
                                }}
                                className={'customBadgeWeek'}
                                badgeContent={dia}
                                color={translated[dia] == '1' ? 'primary' : 'default'}
                            />
                            <br></br>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderStartIcon = (time: string) => {
        let _time = moment(time, 'HH:mm:ss');

        return <span style={{ marginRight: '3px' }}>
            {_time.hour() >= 19 ? <PiMoon size={'16px'} color='#989BB1' /> : <PiSun size={'16px'} color='#ffa400' />}
        </span>
    }

    const renderAutomationStartTimeAndIconForTable = (sched: AutomationsScheduler) => {
        return (
            <div className='hourScheduledColumn'>
                {renderStartIcon(sched.hourScheduled)}
                <div>{moment(sched.hourScheduled, 'HH:mm:ss').format('HH:mm')}</div>
            </div>
        )
    }

    const renderAutomationLastRunForTable = (sched: AutomationsScheduler) => {
        return (<div className='lastRunColumn overflowEllipsis'>{sched.lastRun}</div>)
    }

    const renderAutomationStatusForTable = (sched: AutomationsScheduler) => {
        return sched.status >= 1 ?
            <div className='statusEnabled'>Habilitada</div> :
            <div className='statusDisabled'>Desabilitada</div>
    }

    const renderAutomationCreateDateForTable = (sched: AutomationsScheduler) => {
        return (
            <div className='createdAtColumn'>
                {sched.createdAt == '-' || !sched.createdAt ? '-' : convertDateFormat(sched.createdAt)}
            </div>
        )
    }

    const renderAutomationCreatorNameForTable = (sched: AutomationsScheduler) => {
        return (<div className='createdByColumn overflowEllipsis'>{sched.createdBy}</div>)
    }

    const renderAutomationStatus = (status: number) => {
        return status >= 1 ?
            <div className='statusEnabled'>Habilitada</div> :
            <div className='statusDisabled'>Desabilitada</div>
    }

    const renderAuditModifications = (row: AutomationAudit) => {

        var _values: any = {};
        var _timestamp = row.timestamp && moment(row.timestamp).format('DD/MM/YYYY HH:mm:ss') || 'Não informado';

        if (row.hasOwnProperty('currentValues') && row.currentValues !== '') {

            let _parsed = JSON.parse(row.currentValues);
            let _current: AutomationsScheduler = Object.keys(_parsed).reduce((obj, k) => {
                obj[`${k.charAt(0).toLowerCase()}${k.slice(1)}`] = _parsed[k];
                return obj;
            }, {} as AutomationsScheduler);
            let _resource = categorizeResourceFromAutomation(_current);

            _values = {
                'Grupo': automationsService.groupsDic[_resource.groupType],
                'Automação': automationsService.automationTemplatesDic[template.name][1],
                'Recurso': _resource.name,
                'Tamanho': template.name.indexOf('Resize') > -1 ? JSON.parse(_current.data as string)!.Size : 'Sem Informações',
                'Dias da Semana': _current.weekDaysScheduled.split('-').map(d => automationsService.automationsWeekDic[d][0]).toString(),
                'Horário': moment(_current.hourScheduled, 'HH:mm:ss').format('HH:mm'),
                'Status': ['Desabilitada', 'Habilitada'][_current.status]
            };

            if (row.action == 'UPDATE') {
                delete _values['Grupo'];
                delete _values['Automação'];
                delete _values['Recurso'];
            }
        }

        return (
            <div className='auditInfoContainer'>
                <span className='auditTimestamp'>{_timestamp}</span>
                {
                    Object.keys(_values).length > 0 &&
                    <div className='auditInfo'>
                        <div className='auditInfoLabels'>
                            {Object.keys(_values).map(v => <p>{v}:</p>)}
                        </div>
                        <div className='auditInfoLabels'>
                            {Object.keys(_values).map(v => <p>{_values[v]}</p>)}
                        </div>
                    </div>
                }
            </div>
        )
    }

    const getTotalCostsByWeekDay = (taskItems: TaskItemDuration[]): { [key: string]: number } => {
        return taskItems.reduce((acc, item) => {
            if (!acc[item.weekDay]) {
                acc[item.weekDay] = 0;
            }
            // ECONOMIA: (24 horas - hrs ligada) * custo hora
            acc[item.weekDay] += (24 - item.durationHours) * item.costs;
            return acc;
        }, {} as { [key: string]: number });
    };

    const distributeCostsByDay = (weekCosts: { [key: string]: number }, year: number, month: number): { date: string, value: number }[] => {
        const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const formattedDailyCosts: { date: string, value: number }[] = [];
        let accumulatedCost = 0;

        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 29); // 29 because we include today

        for (let day = 0; day < 30; day++) {
            const date = new Date(thirtyDaysAgo);
            date.setDate(thirtyDaysAgo.getDate() + day);
            const weekDay = weekDays[date.getDay()];
            accumulatedCost += weekCosts[weekDay] || 0;
            formattedDailyCosts.push({
                date: date.toLocaleDateString('pt-BR'), // Format as dd/mm/yyyy
                value: accumulatedCost
            });
        }

        return formattedDailyCosts;
    };

    const calculateTotalMonthlyCost = (dailyCosts: { date: string, value: number }[]): number => {
        return dailyCosts[dailyCosts.length - 1].value; // The last day contains the total accumulated cost
    };

    const {
        data: resourcesCostsData,
        refetch: refetchResourcesCostsData,
        isLoading: isLoadingResourcesCostsData,
        isFetching: isFetchingResourcesCostsData
    } = useQuery(['GetCostKPI'], async () => {
        var taskItems = await automationsService.GetCostKPI();
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const weekCosts = getTotalCostsByWeekDay(taskItems);
        const dailyCosts = distributeCostsByDay(weekCosts, year, month);
        // const totalMonthlyCost = calculateTotalMonthlyCost(dailyCosts);
        return dailyCosts;
    }
    );
    useEffect(() => {
        const refetchCosts = async () => await refetchResourcesCostsData();
        refetchCosts();
    }, [user.ClientGroupSelected, user.refreshFlag])

    const filterRelatedAutomationsForForm = (resource: AutomationResource) => {
        let _filtered = schedulers.filter(s => {
            let _data = parseSchedulerData(s.data);
            var resourceID = _data.ResourceId as string;
            return _data.ResourceName == resource.resourceName
                || resource.resourceID.includes(resourceID)
        });
        return _filtered
    }

    const confirmDelete = (id: number) => {
        setScheduler(schedulers.find(s => s.id == id)!);
        setShowDeleteConfirmation(true);
    }

    const deleteAutomation = () => {
        let id = scheduler?.id!;

        setShowDeleteConfirmation(false);

        showInfo(`Excluindo automação...`, 'warning');

        automationsService.RemoveAutomations(id)
            .then(result => {

                showInfo(`Automação excluída!`, 'success');
            })
            .catch(error => {
                console.error(error);
                showInfo(`Error ao excluir automação.`, 'error');
            })
            .then(() => {
                setScheduler(automationsService.emptyAutomationsScheduler);
                loadAutomations();
            })
    }

    const historicColumns = [
        { field: 'runId', headerName: 'ID da Execução', width: '35%' },
        { field: 'date', headerName: 'Data', width: '35%', renderCell: (row: AutomationRunHistory) => moment(row.date).format('DD/MM/YYYY') },
        {
            field: 'timeStarted', headerName: 'Início', width: '35%',
            renderCell: (row: AutomationRunHistory) => row.timeStarted && moment(row.timeStarted, 'HH:mm:ss.SSS').format('HH:mm:ss') || 'Não informado'
        },
        {
            field: 'timeFinished', headerName: 'Fim', width: '35%',
            renderCell: (row: AutomationRunHistory) => row.timeFinished && moment(row.timeFinished, 'HH:mm:ss.SSS').format('HH:mm:ss') || 'Não informado'
        },
        { field: 'statusLabel', headerName: 'Status', width: '35%', renderCell: (row: AutomationRunHistory) => row.statusLabel.trim() }
    ];

    const auditColumns = [
        { field: 'version', headerName: 'Versão', width: '5%', renderCell: (row: AutomationAudit) => `${auditRecords.indexOf(row) + 1}.0` },
        { field: 'automation', headerName: 'Automação', width: '20%', renderCell: (row: AutomationAudit) => automationsService.automationTemplatesDic[template.name][1] },
        { field: 'resource', headerName: 'Recurso', width: '15%', renderCell: (row: AutomationAudit) => resource.resourceName },
        { field: 'clientUserName', headerName: 'Modificado Por', width: '20%' },
        { field: 'modification', headerName: 'Modificado Em', width: '35%', renderCell: (row: AutomationAudit) => renderAuditModifications(row) }
    ];

    const dashboardCards = [
        {//Automações
            id: 1,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: false,
            title: () => 'Automações',
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading}>
                        {
                            activeSchedulerCount > 0 ?
                                <>
                                    <IconValue
                                        value={activeSchedulerCount}
                                        color='var(--text-default)'
                                        icon={<PiGearThin size={32} />}
                                        description='ativas em seu ambiente cloud'
                                    />
                                </>
                                :
                                <LcNoData size='small' />
                        }
                    </LcLoading>
                )
            }
        },
        {//Próximos agendamentos
            id: 2,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 4 },
            showFocusButton: false,
            title: () => 'Próximos agendamentos',
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading}>
                        {
                            nextSchedulers && nextSchedulers.length > 0 ?
                                <>
                                    <IconValue
                                        value={renderNextSchedulers(3)}
                                        color='var(--text-default)'
                                        icon={<PiCalendarCheckLight size={32} />}
                                    />
                                </>
                                :
                                <LcNoData size='small' />
                        }
                    </LcLoading>
                )
            }
        },
        {//Possível economia mensal
            id: 3,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: false,
            title: () => <span className="lc-title ellipsis">Possível economia mensal</span>,
            infoContent: () => savingsMessage,
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingResourcesCostsData || isFetchingResourcesCostsData}>
                        {
                            filteredSchedulers ?
                                <>
                                    <IconValue
                                        value={resourcesCostsData
                                            ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                .format(calculateTotalMonthlyCost(resourcesCostsData))
                                            : 'R$ 0,00'}
                                        color='var(--color-secondary-green-light)'
                                        icon={<PiPiggyBankLight size={32} />}
                                        description='com o uso de automações'
                                    />
                                </>
                                :
                                <LcNoData size='small' />
                        }
                    </LcLoading>
                )
            }
        },
        {//Economia no período
            id: 4,
            type: 'Custom', position: { ColSpan: 3, RowSpan: 1, row: 1, col: 10 },
            showFocusButton: false,
            title: () => {
                return <>
                    {
                        <div className={'lc-segment-title-row-resume'} style={{ alignItems: 'flex-end' }}>
                            <div className={'lc-title ellipsis'}>Economia nos últimos 30 dias </div>
                        </div>
                    }
                </>
            },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading}>
                        <div style={{ height: '100px' }}>
                            <WeeklySavingsEstimateChart data={resourcesCostsData ? resourcesCostsData : []} start={new Date()} end={new Date()} />
                        </div>
                    </LcLoading>
                )
            }
        },
        {//List
            id: 5,
            type: 'Custom', position: { ColSpan: 12, RowSpan: 3, row: 2, col: 1 },
            showFocusButton: false,
            hideHeader: true,
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading} label='Carregando automações...'>
                        <div className='page-automacoes'>
                            <LcInfiniteTable
                                loading={isLoading}
                                columns={columns}
                                rows={filteredSchedulers}
                                size={filteredSchedulers.length}
                                hidePagination={true}
                                functionsRow={functionsRow}
                                onSortChange={handleTableSortChange}
                                fontSize='small'
                                status={(row: AutomationsScheduler) => row.status > 0 ? '#3ebb3f' : '#dc291e'}
                                tooltipContentColumn='statusLabel'
                                noDataMessage='Nenhuma automação encontrada'
                            />
                        </div>
                    </LcLoading>
                )
            }
        },
    ];

    const dashboardFilterPanel = {
        title: 'Filtros',
        pinned: false,
        togglePinned: () => setFilterIsPinned(!filterIsPinned),
        show: showFilterModal,
        close: setShowFilterModal,
        content: () => {
            const _statusOptions = [
                { value: 0, label: 'Todos' },
                { value: 1, label: 'Desabilitadas' },
                { value: 2, label: 'Habilitadas' }
            ];
            const _automationOptions = [...new Set(
                templates
                    .filter(t => schedulers.map(s => s.automationId).includes(t.id))
                    .map(t => automationsService.automationTemplatesDic[t.name][1])
            )].map((tn: string) => ({ label: tn, value: tn }));
            const _providerOptions = [
                ...allowedProviders.map((p: AutomationProvider) => ({ value: p.id, label: automationsService.automationsProvidersDic[p.id] })),
                { value: 0, label: 'Todos' }
            ].sort((a: any, b: any) => a.value - b.value);
            const _groupOptions = [
                { value: '', label: 'Todos' },
                { value: 'VM', label: 'VM' },
                { value: 'DB', label: 'DB' }
            ];
            const hourSliderMarks = [
                { label: '00:00', value: 0 },
                { label: '06:00', value: 6 },
                { label: '12:00', value: 12 },
                { label: '18:00', value: 18 },
                { label: '23:00', value: 23 }
            ];
            return (
                <div className='right-sidepanel-filters'>
                    <LcLoading loading={isLoading} label='Carregando Filtros...'>
                        <div className='principal-filters'>
                            <PPDropDown // Provedor
                                name='provider'
                                title='Provedor'
                                value={_providerOptions[schedulerFilterFields.providerId]}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, providerId: e.value })}
                                options={_providerOptions}
                                maxMenuHeight={130}
                                placeholder='Selecione...'
                                menuPlacement='top'
                            />
                            <PPDropDown // Grupo
                                name='groupType'
                                title='Grupo'
                                value={_groupOptions[schedulerFilterFields.groupType]}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, groupType: e.value })}
                                options={_groupOptions}
                                maxMenuHeight={130}
                                placeholder='Selecione...'
                                menuPlacement='top'
                            />
                            <PPDropDown // Automação
                                name='automationId'
                                title='Automação'
                                value={_automationOptions[schedulerFilterFields.automationId]}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, automationId: e.value })}
                                options={_automationOptions}
                                maxMenuHeight={130}
                                placeholder='Selecione...'
                                menuPlacement='top'
                            />
                            <PPInput // Recurso
                                autoComplete='off'
                                name='resourceId'
                                value={schedulerFilterFields.resourceId}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, resourceId: e.target.value })}
                                type='text'
                                title='Recurso'
                            />
                            <SliderField // Horário
                                name='hourScheduled'
                                label='Horário (De 00:00 às 23:59)'
                                value={schedulerFilterFields.hourScheduled}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, hourScheduled: e.value })}
                                marks={hourSliderMarks}
                                style={{ color: 'var(--color-secondary-lime)' }}
                                min={0}
                                max={23}
                            />
                            <PPDropDown // Status
                                name='status'
                                title='Status'
                                value={_statusOptions[schedulerFilterFields.status]}
                                onChange={(e) => setSchedulerFilterFields({ ...schedulerFilterFields, status: e.value })}
                                options={_statusOptions}
                                maxMenuHeight={130}
                                placeholder='Selecione...'
                                menuPlacement='top'
                            />
                        </div>
                        <div className="space-between mt-4">
                            <button
                                className='lc-button bg-info'
                                type='button'
                                onClick={() => {
                                    setSchedulerFilter({
                                        ...schedulerFilter,
                                        size: automationsService.emptySchedulerFilter.size,
                                        providerId: schedulerFilterFields.providerId,
                                        groupType: schedulerFilterFields.groupType.toUpperCase(),
                                        automationId: schedulerFilterFields.automationId,
                                        resourceId: schedulerFilterFields.resourceId.toLowerCase(),
                                        hourScheduled: schedulerFilterFields.hourScheduled.toString(),
                                        status: schedulerFilterFields.status
                                    });
                                    let _filterChanged = JSON.stringify(schedulerFilterFields) !== JSON.stringify(automationsService.emptySchedulerFilterFields);
                                    dispatch(setFilterActive(_filterChanged))
                                }}
                            >Filtrar</button>
                            <button
                                className='lc-button bg-gray'
                                type='button'
                                onClick={() => {
                                    setSchedulerFilterFields(automationsService.emptySchedulerFilterFields);
                                    setSchedulerFilter(automationsService.emptySchedulerFilter);
                                    dispatch(setFilterActive(false));
                                }}
                            >Limpar</button>
                        </div>
                    </LcLoading>
                </div>

            )
        }
    };
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const topBarFunctions = [
        {
            icon: <PiPlusLight />,
            tooltip: userAccess.canCreateAvailability || userAccess.canCreateResizing ? 'Criar automação' : 'Solicitar automação',
            onClick: () => toggleFormModal(),
            disabled: generalDisabled.create
        },
        {
            title: 'Exportar para .csv',
            icon: <PiFileCsvLight />,
            tooltip: 'Exportar CSV',
            onClick: () => exportToCSV(),
            disabled: generalDisabled.csvExport

        },
    ];

    const functionsRow = [
        {
            label: 'Auditoria',
            func: (row: any) => toggleAuditModal(row.id!)
        },
        {
            label: 'Excluir',
            func: (row: any) => confirmDelete(row.id!),
            disabled: () => generalDisabled.remove
        }
    ]

    const gridFilter = {
        toggleVisibility: () => generalDisabled.filter ? undefined : setShowFilterModal(!showFilterModal),
        size: filteredSchedulers.length
    };

    const columns = [
        {// Automação
            field: 'automationId', headerName: 'Automação', width: '17%', align: 'left',
            renderCell: renderAutomationNameAndIconForTable
        },
        {// Recurso
            field: 'resourceId', headerName: 'Recurso', width: '13.8%', align: 'left',
            renderCell: renderAutomationResourceNameAndIconForTable
        },
        {// Dias da semana
            field: 'weekDaysScheduled', headerName: 'Dias da semana', width: '15.5%', sort: false, overflow: 'visible', align: 'left',
            renderCell: renderAutomationWeekDaysForTable
        },
        {// Início
            field: 'hourScheduled', headerName: 'Início', width: '6%', align: 'left',
            renderCell: renderAutomationStartTimeAndIconForTable
        },
        {// Última execução
            field: 'lastRun', headerName: 'Última execução', width: '10%', overflow: 'visible', align: 'left',
            renderCell: renderAutomationLastRunForTable
        },
        {// Status da automação
            field: 'status', headerName: 'Status da automação', width: '11%', overflow: 'visible', align: 'left',
            renderCell: renderAutomationStatusForTable
        },
        {// Data de criação
            field: 'createdAt', headerName: 'Data de criação', width: '9%', overflow: 'visible', align: 'left',
            renderCell: renderAutomationCreateDateForTable
        },
        {// Criado por
            field: 'createdBy', headerName: 'Criado por', width: '10%', overflow: 'visible', align: 'left',
            renderCell: renderAutomationCreatorNameForTable
        },
        {// Ações
            field: 'id', headerName: 'Ações', width: '7.7%', overflow: 'visible', align: 'center',
            renderCell: (row: AutomationsScheduler) => {
                return (
                    <div className='actionColumn'>
                        <LcIconLink
                            icon={<PiPencilSimpleLight />}
                            size='small'
                            tooltipPosition='right'
                            tooltip='Editar'
                            onClick={() => toggleFormModal(row.id!)}
                            disabled={generalDisabled.edit}
                        />

                        <LcIconLink
                            icon={<PiClockClockwiseLight />}
                            size='small'
                            tooltipPosition='right'
                            tooltip='Histórico'
                            onClick={() => toggleRunHistoryModal(row.id!)}
                        />

                        <LcIconLink
                            icon={row.status == 0 ? <PiPlayCircleLight /> : <PiPauseCircleLight />}
                            size='small'
                            tooltipPosition='right'
                            tooltip={`${row.status == 0 ? 'Ativar' : 'Desativar'}`}
                            onClick={() => confirmToggleStatus(row.id!)}
                            disabled={generalDisabled.toggleStatus}
                        />
                    </div>
                )
            }
        },
    ];

    // Effects
    useEffect(() =>
        setIsLoading(isLoadingAutomations || isLoadingTemplates || isLoadingProviders)
        , [isLoadingAutomations, isLoadingTemplates, isLoadingProviders]);

    return (
        <Layout
            pageTitle='Automações'
            functionsGeneric={topBarFunctions}
            gridFilter={gridFilter}
        >
            <Confirmation
                title='Excluir a automação?'
                text='Está operação NÃO PODE SER REVERTIDA caso a confirme!'
                display={showDeleteConfirmation}
                confirm={deleteAutomation}
                close={() => setShowDeleteConfirmation(false)}
                textBtnOk='Sim, desejo excluir'
                textBtnCancel='Não, não desejo excluir'
            />

            <Confirmation
                title={`${scheduler.status == 0 ? 'Ativar' : 'Desativar'} a automação?`}
                text={`Esta automação no momento está ${scheduler.status == 0 ? 'DESABILITADA' : 'HABILITADA'}.`}
                display={showToggleActiveConfirmation}
                confirm={toggleAutomationStatus}
                close={() => setShowToggleActiveConfirmation(false)}
                textBtnOk={`Sim, desejo ${scheduler.status == 0 ? 'ativar' : 'desativar'}`}
                textBtnCancel={`Não, não desejo ${scheduler.status == 0 ? 'ativar' : 'desativar'}`}
            />

            {resource && <AutomationDetails
                templateData={templates}
                resource={resource}
                resourceInstance={resourceInstance}
                relatedAutomations={filterRelatedAutomationsForForm(resource)}
                isLoadingResource={isLoadingResource}
                showDetailsModal={showDetailsModal}
                toggleDetailsModal={() => setShowDetailsModal(false)}
            />}

            <PPModal title='Histórico de Execuções' visible={showHistoricModal} onClose={toggleRunHistoryModal} size='large' >
                <LcLoading loading={isLoadingHistory} label='Carregando histórico...' >
                    <LcInfiniteTable
                        loading={isLoadingHistory}
                        columns={historicColumns}
                        rows={runHistory}
                        size={runHistory.length}
                        loadMore={(size?: number) => { }}
                        onSortChange={(sortData) => {
                            const { sort, size } = sortData;
                            setRunHistory(runHistory.sort((a: any, b: any) => {
                                if (sort.direction == 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
                            }));
                        }}
                        density='high'
                    />
                </LcLoading>
            </PPModal>

            <PPModal title='Auditoria - Histórico de Edições' visible={showAuditModal} onClose={toggleAuditModal} size='large' >
                <LcLoading loading={isLoadingAudit} label='Carregando auditoria...' >
                    <LcInfiniteTable
                        loading={isLoadingAudit}
                        columns={auditColumns}
                        rows={auditRecords}
                        size={auditRecords.length}
                        loadMore={(size?: number) => { }}
                        onSortChange={(sortData: any) => {
                            const { sort, size } = sortData;
                            setAuditRecords(auditRecords.sort((a: any, b: any) => {
                                if (sort.direction == 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
                            }));
                        }}
                        density='high'
                    />
                </LcLoading>
            </PPModal>
            <AutomationForm
                templates={templates}
                providers={allowedProviders}
                automation={schedulerToEdit}
                relatedFilter={filterRelatedAutomationsForForm}
                show={showForm}
                onClose={() => setShowForm(false)}
                canRequestSchedule={
                    !userAccess.canCreateAvailability &&
                    !userAccess.canCreateResizing &&
                    userAccess.canRequestSchedule
                }
                canCreateAvailability={userAccess.canCreateAvailability}
                canCreateResizing={userAccess.canCreateResizing}
                reloadData={loadAutomations}
            />
            <LCDashboard cards={dashboardCards} rightSidePanel={dashboardFilterPanel} />
        </Layout>
    )
}

export default Automations;