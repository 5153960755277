import React from 'react';
import ResumeInfoHistory from '../../../../../pages/infoHistory';

interface InfoHistoryCardProps {
    GTMs: string[];
    QtdGroups: number;
    hasNF?: boolean;
}

const InfoHistoryCard: React.FC<InfoHistoryCardProps> = ({ GTMs, QtdGroups,hasNF}) => {
    return (
        <>
            <ResumeInfoHistory isHome={true} qtdGroups={QtdGroups} hasNF={hasNF} />  
        </>);
}

export default InfoHistoryCard;