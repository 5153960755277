import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupSaasService } from '../../../services/backupSaas/backupSaasService';
import { Grid, LinearProgress } from '@material-ui/core';
import TreeView from '../../../components/Data/backup/treeView/index';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import Moment from 'moment';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import HeyhoCall from '../../../components/Generic/HeyhoCall/HeyhoCall';
import LcLoading from '../../../components/Generic/LcLoading';
import { PPModal } from 'processor-plataform-ui'
import LcNoData from '../../../components/Generic/LcNoData';
import HeyHoNewRequest, { FormDataHeyho } from '../../../components/Generic/HeyHoNewRequest';

const BackupSaasRestauracao: React.FC = (props) => {
    const [snapshot, setSnapshot] = useState<any>();
    const user = useSelector<RootState, UserState>(state => state.user);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [day, setDay] = useState<string>();
    const [isLoadingTreeView, setIsLoadingTreeView] = useState(true);
    const [modalVisibleHeyHoCall, setModalVisibleHeyHoCall] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
        gtmType: 0
    });

    const onClickRestore = (text: string) => {
        setFormData({
            title: `Backup Saas - Restauração`,
            description: text,
            gtmType: 3
        });
        setModalVisibleHeyHoCall(true)
    };

    useEffect(() => {
        setIsLoadingTreeView(true);
        if (!month && !year && !day) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
            setDay(new Date().getDate().toString().padStart(2, '0'));
        }
        if (user.ClientGroupSelected && month && year && day) {
            const searchDate = [year, month, day].join('-');
            const backupSaasService = new BackupSaasService(props);
            backupSaasService.GetSnapshot(searchDate)
                .then(response => {

                    let snapshot = response.data.tree;
                    setSnapshot(snapshot);
                    // console.log(response);
                    // console.log(snapshot);
                    setIsLoadingTreeView(false);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [day, month, user.ClientGroupSelected, year, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
        setDay(date.split('-')[2]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Hoje',
                text: 'hoje',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM-DD') }
                ],
                default: true
            },
            {
                label: 'Ontem',
                text: 'ontem',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'days').format('YYYY-MM-DD') }
                ]
            },
            {
                label: 'Dois dias atrás',
                text: 'dois dias atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'days').format('YYYY-MM-DD') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Dia', value: '' }
            ]
        },
        customPeriodType: 'date',
        onChange: onChagePeriod
    };

    return (
        <Layout pageTitle="Restauração" periodSelection={periodSelection}>
            <HeyHoNewRequest  openModal={modalVisibleHeyHoCall} formData={formData} onClose={() => setModalVisibleHeyHoCall(false)} needAtachmente />
            {/* <PPModal visible={modalVisibleHeyHoCall} title="Abertura de Chamado" onClose={() => setModalVisibleHeyHoCall(false)}>
                <HeyhoCall formData={formData}></HeyhoCall>
            </PPModal> */}
            {
                !isLoadingTreeView && snapshot
                    ?
                    <>
                     
                            {snapshot.Children.length > 0
                                ?
                                <TreeView onClickRestore={onClickRestore} model={snapshot} level={0} ></TreeView>
                                :
                                <LcNoData />
                            }
                     
                    </>
                    :
                    
                        <LcLoading loading={isLoadingTreeView} label="Carregando..." />
                    
            }
        </Layout>
    )
}

export default BackupSaasRestauracao;