import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Layout from '../../../components/Layout/Layout';

import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { useSnackbar } from 'notistack';
import { HeyHoService } from '../../../services/heyHoService';
import DetailForm from '../DetailForm';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { setFilterActive } from '../../../store/reducers/filterReducer';

import { PPForm2, PPModal, PPDropMenu } from 'processor-plataform-ui';
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import LcSegment from '../../../components/Generic/LcSegment';
import moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import LcIconLink from '../../../components/Generic/LcIconLink';
import Attachment from '../Attachment';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcTooltip from '../../../components/Generic/LcTooltip';
import { AiOutlineSound } from 'react-icons/ai';
import { BsArrowsAngleContract, BsArrows, BsType } from 'react-icons/bs';

import { PiArrowLeftLight, PiCopyLight, PiDownloadLight, PiFileTextLight, PiMagicWand, PiPlusLight } from 'react-icons/pi';
import '../index.css'
import HHContentAI from '../HHContentAI';
import FilterPanel from '../../../components/Data/FilterPanel';

// function DateFormater(date: any) {
//     let options: any = {
//         year: 'numeric', month: 'numeric', day: 'numeric',
//         hour: 'numeric', minute: 'numeric', second: 'numeric',
//         hour12: false,
//         timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
//     };

//     return date && new Intl.DateTimeFormat('pt-BR', options).format(new Date(date)) as string;
// }

export interface HeyHoProps {
    Title?: string,
    Detalhes?: string,
    Servico?: number,
}

interface HeyhoRows {
    id: number;
    titulo: string;
    dataInicio: Date;
    solicitationDate: Date;
    nameBusinessArea: string | null;
    nameCustomer: string | null;
    nameServiceGroup: string | null;
    nameService: string | null;
    nameProcess: string | null;
    details: string | null;
    subject: string;
    heyHoId: number;
    heyHoSatatus: string;
    idSituacao: number;
    heyHoLink: string;
    criticidade: string;
}

const Status = [
    { value: 0, label: 'Todos' },
    { value: 1, label: 'Novos' },
    { value: 2, label: 'Andamento' },
    { value: 4, label: 'Parados' },
    { value: 5, label: 'Pausado' },
    { value: 6, label: 'Cancelado' },
    { value: 7, label: 'Concluidos' },
    { value: 8, label: 'Encerrados' },
    { value: 9, label: 'Reabertos' },
    { value: 10, label: 'Em planejamento' },
    { value: 11, label: 'Esperando solicitante' },
    { value: 12, label: 'Esperando atendente' },
    { value: 13, label: 'Esperando outra área' },
    { value: 14, label: 'Em aprovação' },
    // { value: 100, name: 'Abertos' },
    // { value: 101, name: 'Fechados' },
];

const Criticidade = [
    { value: 0, label: 'Todas' },
    { value: 1, label: 'Normal' },
    { value: 2, label: 'Média' },
    { value: 3, label: 'Alta' },
    { value: 4, label: 'Critica' }
];

const HeyHoList: React.FC<HeyHoProps> = (props) => {

    // const initTemplate = async () => {
    //     await ServiceHeyho.RetrieveContractsAndGTM();
    //     let templateLO = ServiceHeyho.getTemplate();
    //     setTemplate && setTemplate(undefined);
    //     setTemplate && setTemplate({ ...templateLO });
    // };


    const [ServiceHeyho] = useState<HeyHoService>(new HeyHoService(props))
    const [ListData, setListData] = useState<any[]>([]);
    const [ListDataFiltered, setListDataFiltered] = useState<any[]>([]);
    const [visibleListDataFiltered, setVisibleListDataFiltered] = useState<HeyhoRows[]>([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const [showDetailComment, setShowDetailComment] = useState(false);
    const [commentsDetails, setCommentsDetails] = useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [openModalNewRequest, setOpenModelNewReqeust] = useState<boolean>(false)
    const [openAIMenu, setOpenAIMenu] = useState<boolean>(false);
    const [sugestaoExp, setSugestaoExp] = useState<boolean>(false);
    const [AiSelected, setAiSelected] = useState<number>(0);
    const [AiSelectedMenu, setAiSelectedMenu] = useState<number>(0);
    const [subject, setSubject] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [updateField, setUpdateField] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);


    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const pref = useSelector<RootState, PrefState>(state => state.pref);

    const dispatch = useDispatch();
    const emptyRecordMessage = useMemo(() => ({
        detail: false,
        edit: false,
        add: false,
        record: ServiceHeyho.emptyRecord
    }), [ServiceHeyho.emptyRecord]);
    
    const [record, setRecord] = useState(emptyRecordMessage);
    const [anexos, setAnexos] = useState<File[]>([]);
    const [filterPage, setFilterPage] = useState<{
        size: number,
        term: string,
        situacao: string,
        criticidade: string,
        column: string,
        direction: string,
        filterStatus?: number,
        filterCriticidade?: any,
        start?: number,
        ordered?: string,
        filter?: string;
    }>({
        size: 20000,
        term: "",
        start: 0,
        situacao: "",
        criticidade: "",
        column: "id",
        direction: "desc",
        filterStatus: 0,
        filterCriticidade: 0,
        filter: '',
        ordered: 'id'

    });
    const [template, setTemplate] = useState<any>(ServiceHeyho.templateEdit2());
    const [templateEdit, setTemplateEdit] = useState<any>([]);
    
    const loadData = useCallback(async (filter: any) => {
        // setListData([]);
        setLoading(true);
        // setListDataFiltered([]);
        if (filter === undefined) {
            filter = "";
        }
        const ServiceHeyho = new HeyHoService({})
        await ServiceHeyho.RetrieveList(filter)
            .then((tt: any) => {
                setListData(tt.data.dataCollection);
                setListDataFiltered(tt.data.dataCollection);

            }).catch((error) => { console.error(error);  })
            .finally(() => {
                setLoading(false);
            });
    },[])
    useEffect(() => {
        loadData(filterPage)
        const ServiceHeyho = new HeyHoService({})
        ServiceHeyho.RetrieveContractsAndGTM();
        let templateLO = ServiceHeyho.getTemplate();
        setTemplate && setTemplate({ ...templateLO });
        setOpenModelNewReqeust(false);
    }, [user.refreshFlag, user.ClientGroupSelected, filterPage, loadData]);

    const ADD = useCallback(() => {
        enqueueSnackbar(`Recolhendo informações para abertura de chamados.`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        setRecord(() => ({
            record: { ...ServiceHeyho.emptyRecord },
            edit: false,
            detail: false,
            add: true
        }));
        setAnexos([]);
        setOpenModelNewReqeust(true);
    }, [ServiceHeyho.emptyRecord, enqueueSnackbar]);
    
    useEffect(() => {
        if (menu.newRequest) ADD();
    }, [ADD, menu.newRequest]);
    
    const statusColor = (row: any): any => {
        switch (row.idSituacao) {
            case 1: return "#0975D9";
            case 2: return "#FCEBC1";
            case 3: return "#FCEBC1";
            case 4: return "#2B304F";
            case 5: return "#DCDEE9";
            case 6: return "#F9D0D0";
            case 7: return "#DBF4E5";
            case 8: return "#DBF4E5";
            case 9: return "#E2F1FF";
            case 10: return "#BE8E12";
            case 11: return "#DCDEE9";
            case 12: return "#DCDEE9";
            case 13: return "#DCDEE9";
            case 14: return "#BE8E12";

            default: return "rgb(226, 230, 243)";
        }
    }

    const fontColor = (row: any): any => {
        switch (row.idSituacao) {
            case 1: return "#FFFFFF";
            case 2: return "#8F6C10";
            case 3: return "#8F6C10";
            case 4: return "#FFFFFF";
            case 5: return "#2B304F";
            case 6: return "#AF4848";
            case 7: return "#296B44";
            case 8: return "#296B44";
            case 9: return "#0975D9";
            case 10: return "#FFFFFF";
            case 11: return "#2B304F";
            case 12: return "#2B304F";
            case 13: return "#2B304F";
            case 14: return "#FFFFFF";

            default: return "#2B304F";
        }
    }

    const urgencyColor = (urgency: string): string => {
        switch (urgency) {
            case 'Normal': return '#28A745'
            case 'Media': return '#FFC107'
            case 'Alta': return '#DC3545'
            case 'Critica': return '#343A40'

            default: return '#28A745'
        }
    }

    const columns = [
        { field: 'id', headerName: 'Ticket', width: "10%" },
        { field: 'titulo', headerName: 'Assunto', width: "70%" },
        {
            field: 'heyHoSatatus', headerName: 'Situação', width: "18%",
            renderCell: (row: HeyhoRows) => {
                return (
                    <div className={"divStatusColorDisplay"} style={{ backgroundColor: statusColor(row) }}>
                        <span style={{ fontWeight: "700", color: fontColor(row) }}>{row.heyHoSatatus}</span>
                    </div >
                )
            }
        },
        {
            field: 'criticidade', headerName: 'Criticidade', width: "15%",
            renderCell: (row: HeyhoRows) => {
                return (
                    <div className={"divStatusColorDisplay"} style={{ backgroundColor: urgencyColor(row.criticidade) }}>
                        <span style={{ fontWeight: "700", color: "#fff" }}>{row.criticidade}</span>
                    </div >
                )
            }
        },

        {
            field: 'functions', headerName: '', width: "5%", overflow: "visible", align: "center",
            renderCell: (row: any) => <LcIconLink icon={<PiFileTextLight />} size="small" tooltip="Detalhes" tooltipPosition='inline-left' onClick={() => selecionadoEditar(row.id)} />
        }
    ];  

    const [counter, setCounter] = useState(0);

    async function saveComment(item: any) {
        setLoading(true);
        if (item.Anexo !== undefined) {

            item.Anexo.map(async (anexo, index) => {
                await ServiceHeyho.sendFile(anexo, item.id, 0, anexo.name, item.Descricao )
                    .then((response) => {
                        if (response.data === 201) {
                            selecionadoEditar(item.id)
                            enqueueSnackbar(`Comentário para o ticket ${item.id} criado com sucesso.`, {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                        }
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Erro ao criar comentário.`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                        console.error(error)
                    })
                    .finally(() => {
                        setShowDetailComment(false);
                        selecionadoEditar(item.id)
                    });
            })
        } else {
            ServiceHeyho.CreateComment(item)
                .then((items: any) => {
                    if (items.status === 200) {
                        enqueueSnackbar(`Comentário para o ticket ${item.id} criado com sucesso.`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao criar comentário.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error)
                }).finally(() => {
                    setShowDetailComment(false);
                    selecionadoEditar(item.id)
                });
        }

    }

    async function saveTicket(
        ServiceHeyho: HeyHoService,
        setRecord: React.Dispatch<React.SetStateAction<{ detail: boolean; edit: boolean; add: boolean; record: any; }>>,
        emptyRecordMessage: { detail: boolean; edit: boolean; add: boolean; record: any; },
        resetData: () => void
    ) {
        if (!record.record.Descricao || /^\s*$/.test(record.record.Descricao)) {
            enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Descrição detalhada"`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }
        if (!record.record.titulo || /^\s*$/.test(record.record.Descricao)) {
            enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Assunto"`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }
        let idSolicitation;
        let idUserRequester;
        setSubmitting(true);
        try {
            await ServiceHeyho.Save(record.record)
                .then(async (response) => {
                    idSolicitation = response.data.id
                    idUserRequester = response.data.idUserRequester
                    enqueueSnackbar(
                        <div style={{ display: 'flex' }}>
                            Chamado {response.data.id} criado com sucesso.
                            <LcIconLink
                                icon={<PiCopyLight/>}
                                size={'small'}
                                tooltip={'Copiar número de chamado'}
                                tooltipPosition={'inline-left'}
                                onClick={() => copyToClipbord(response.data.id)}
                            />
                        </div>
                        , {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao criar chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error);
                })
                .finally(() => {
                    setSubmitting(false);
                    resetData();
                    handleCancel()
                })

        } catch (e) {
            console.error(e);
            // setLoading(false);
        }

        await handleFile(idSolicitation, idUserRequester)
    }


    const handleFile = async (id: number, idUserRequester: number) => {
        console.log(idUserRequester)
        if (anexos.length > 0) {
            anexos.map(async (anexo) => {
                await ServiceHeyho.sendFile(anexo, id, idUserRequester,anexo.name)
                    .then((response2) => {
                        if (response2.data === 201) {
                            enqueueSnackbar(`Anexo para o ticket ${id} criado com sucesso.`, {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                        }
                        else if (response2.data === 400) {
                            enqueueSnackbar(`Erro ao anexar arquivo.`, {
                                variant: 'error',
                                preventDuplicate: true,
                                persist: false,
                            });
                        }
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Erro ao anexar arquivo.`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                        console.error(error);
                    })
            })

        }
        // setLoading(false);
    }

    const functions = {
        functionsList: [
            {
                title: 'Novo',
                tooltip: 'Novo',
                icon: <PiPlusLight/>,
                onClick: () => {
                    setAnexos([])
                    ADD();
                },
                disabled: (template && !template.Fields )||( template && template.Fields && template.Fields.length === 0)
            }
        ],
        functionsNew: [
            {
                label: 'Abrir chamado',
                context: 'primary',
                onClick: (event) => {

                    setSubmitting(true);
                    saveTicket(ServiceHeyho, setRecord, emptyRecordMessage, resetData)
                        .then(result => {
                        })
                        .catch(console.warn)
                        .finally(() => {
                            setSubmitting(false);
                        })
                }
            },
            {
                label: 'Cancelar',
                context: 'grey',
                onClick: () => {
                    setRecord({ ...emptyRecordMessage });
                    dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                }
            }
        ],
        functionsEdit: [{
            title: 'Fechar',
            icon: 'lci lci-x',
            func: () => {
                setRecord({ ...emptyRecordMessage });
            },
            disabled: false,
            skipvalidation: true
        },
        {
            title: 'Comentário',
            icon: 'lci lci-chat-alt-2',
            onClick: () => {
                ADDComment();
            },
            disabled: record.record != null && (record.record.idSituacao !== 7 && record.record.idSituacao !== 6 && record.record.idSituacao !== 8) ? false : true,
            skipvalidation: true
        }]
    };

    const selecionadoEditar = (childData: any) => {
        if (childData !== null) {
            EDIT(childData);
        }
    };



    const ADDComment = async () => {
        setLoading(true);
        setShowDetailComment(true)
        setLoading(false);
    };

    const EDIT = async (item: any) => {
        setLoading(true);
        let addRecord = { ...emptyRecordMessage };
        addRecord.edit = true;
        addRecord.add = false;
        addRecord.detail = false;
        ServiceHeyho
            .Retrieve(item)
            .then(async (response) => {
                let addRecord = { ...emptyRecordMessage };
                addRecord.edit = true;
                addRecord.add = false;
                addRecord.detail = false;
                let itemDetail = response.data.dataCollection[0];
                addRecord.record = itemDetail;
                setRecord({ ...addRecord });
                setTemplateEdit(ServiceHeyho.templateEdit2(addRecord.record));
                let resultDetails = await ServiceHeyho.GetSolicitationCommentDetails(item);
                setCommentsDetails(resultDetails.data.sort((a, b) => { let bDate: any = new Date(b.commentDate); let aDate: any = new Date(a.commentDate); return bDate - aDate })
                );

            }).catch((error) => {
                setCommentsDetails([]);
                setTemplateEdit(undefined);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDeleteClose = (deleteConfirm: boolean) => {
        setOpen(false);
    };

    const resetData = () => {
        var counterPlus = counter + 1;
        setCounter(counterPlus);
        loadData(filterPage)
    };


    useEffect(() => {
        let filtered = ListData.sort((a: any, b: any) => {
            if (filterPage.direction === 'asc') return (a[filterPage.column] > b[filterPage.column]) ? 1 : -1;
            else return (a[filterPage.column] > b[filterPage.column]) ? -1 : 1;
        });
        setListDataFiltered(filtered);
        setVisibleListDataFiltered(filtered.slice(0, filterPage.size));
    }, [ListData, filterPage]);

    const onSortChange = (sortData: any) => {
        const { sort } = sortData;
        const size = sortData.size > 0 ? sortData.size : visibleListDataFiltered.length;
        setFilterPage({ ...filterPage, column: sort.column, direction: sort.direction, size: size });
    };

    const loadMore = (size: number) => {
        if (visibleListDataFiltered.length >= size) {
            setVisibleListDataFiltered(ListDataFiltered.slice(0, size));
            
        } 
        else {

            setVisibleListDataFiltered([...visibleListDataFiltered, ...ListDataFiltered.slice(visibleListDataFiltered.length, visibleListDataFiltered.length + 10)]);
        }
    };

    const interfaceTyped: { [key: string]: any; } = { ...record.record };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [filterVisible, setFilterVisible] = useState(false);

    const filterAdvanced = {
        fields: [
            { label: 'Assunto', name: 'assunto', type: 'text', value: '' },
            { label: 'Situação', name: 'situacao', type: 'select', options: Status, value: '' },
            { label: 'Criticidade', name: 'criticidade', type: 'select', options: Criticidade, value: '', topDropdown: true }
        ],
        onChange: (filter: any, size: number) => {
            // const _situacao = Status.filter(x => x.value == filter[1].value)[0];
            // const _criticidade = Criticidade.filter(x => x.value == filter[2].value)[0];
            // const _assunto = filter[0].value;
            // let filterCreated = {
            //     ...filterPage,
            //     size: 500,
            //     term: filter[0].value.toLowerCase(),
            //     situacao: _situacao.label || '',
            //     filterStatus: _situacao.value || 0,
            //     filterCriticidade: _criticidade.value || 0,
            //     start: 0,
            //     ordered: 'id',
            //     direction: 'desc',
            //     filter: _assunto
            // };

            // loadData(filterCreated);
            // setFilterPage(filterCreated)
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    // * Campos para o PPForm2 novo da plataforma
    const fieldsForm: PPFormInput[] = [
        {
            id:'form_novochamado_assunto_field',
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
            value: subject
        },
        {
            id:'form_novochamado_descricaodetalhada_field',
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: sugestaoExp ? 4 : 7,
            value: description
        },
    ]

    const onChangeForm = (event) => {
        setRecord(prevRequest => ({
            ...prevRequest,
            record: {
                ...prevRequest.record,
                titulo: event.titulo,
                Descricao: event.Descricao
            }
        }));
        setDescription(event.Descricao)
        setSubject(event.titulo)
        setUpdateField(true)
    }

    const downloadAttachment = async (attachment) => {
        setLoadingAttachment(true)
        await ServiceHeyho.downloadAttachmentById(attachment)
            .finally(() => {
                setLoadingAttachment(false);
            })
    }

    // function formatBytes(bytes, decimals = 2) {
    //     if (bytes === 0) return '0 Bytes';

    //     const k = 1024;
    //     const dm = decimals < 0 ? 0 : decimals;
    //     const sizes = ['Bytes', 'KB', 'MB'];

    //     const i = Math.floor(Math.log(bytes) / Math.log(k));

    //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    // }

    const removeFile = (fileName: string) => {
        const files_ = anexos && anexos.filter(item => item.name !== fileName);
        setAnexos(files_);
    };

    const handleFiles = (files_: any[]) => {
        let _record = record.record;
        _record.Anexo = [...anexos, ...files_];
        setRecord({ ...record, record: _record })
        setAnexos([...anexos, ...files_]);
    }

    const copyToClipbord = (solicitationNumber: number) => {
        navigator.clipboard.writeText(solicitationNumber.toString());
    };



    const card: Card[] = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            hideHeader: true,
            title: () => {
                if (templateEdit && record.edit === true && !loading) {
                    return (
                        <div className="lc-title ellipsis">
                            Ticket: {record.record.heyHoId}
                            &nbsp;
                            Situação: {record.record.heyHoSatatus}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {(record.edit !== true && ListDataFiltered) ? (
                            <LcInfiniteTable
                                loading={loading}
                                columns={columns}
                                rows={visibleListDataFiltered}
                                filter={filterAdvanced}
                                size={visibleListDataFiltered.length}
                                loadMore={loadMore}
                                onSortChange={onSortChange}
                                density={pref.data?.density || "high"}
                                status={urgencyColor}
                                tooltipContentColumn='criticidade'
                                disableFilterModal
                            />
                        ) : (
                            templateEdit && (record.edit === true) && (
                                <>
                                    <div className={'scrollable-v'} style={{ marginBottom: '20px' }}>
                                        <PPForm2
                                            loading={loading}
                                            functions={{
                                                submitButton: {
                                                    hide: true,
                                                    label: 'Ok'
                                                },
                                            }}
                                            fields={templateEdit}
                                        />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}>
                                            {commentsDetails && commentsDetails.length > 0 && (
                                                <div className='commentDetails'>
                                                    <h4>Comentários:</h4>
                                                    <div className='comment'>
                                                        {commentsDetails.map((detail, index) => (
                                                            <LcSegment
                                                                shadow
                                                                key={index}
                                                                title={
                                                                    <div>
                                                                        {detail.nameUser}
                                                                        <br />
                                                                        <LcTooltip position='left' trigger='hover' content={moment(detail.commentDate).format('DD/MM/YYYY  HH:mm:ss')}>
                                                                            <div style={{ fontSize: '0.8rem' }}>
                                                                                {moment(detail.commentDate).fromNow()}
                                                                            </div>
                                                                        </LcTooltip>
                                                                    </div>
                                                                }
                                                            >
                                                                {detail.isAttachment ? (
                                                                    loadingAttachment ? (
                                                                        <LcLoading loading={loadingAttachment} />
                                                                    ) : (
                                                                        <>
                                                                            {detail.text ? detail.text : 'Anexo do comentário'}
                                                                            <LcIconLink icon={<PiDownloadLight />} tooltip='Fazer download do anexo' tooltipPosition='left' onClick={() => { downloadAttachment(detail.attachment) }} />
                                                                        </>
                                                                    )
                                                                ) : (
                                                                    <div className='body' dangerouslySetInnerHTML={{ __html: detail.text }} />
                                                                )}
                                                            </LcSegment>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </LcLoading>
                );
            }
        }
    ];
    const translations = {
        heyHoId: {
            label: "Ticket",
            type: "text",
        },
        subject: {
            label: "Assunto",
            type: "text",
        },
        heyHoSatatus: {
            label: "Situação",
            type: "multiSelect",
        },
        criticidade: {
            label: "Criticidade",
            type: "multiSelect",
        }

    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
            <FilterPanel
                filter={Object.keys(translations).map(key => ({ 
                    label: translations[key].label,
                    name: key,
                    type: translations[key].type,
                    values: translations[key].values,
                }))}
                data={ListData}
                onFilteredData={(filteredData) =>{ setVisibleListDataFiltered(filteredData as HeyhoRows[]);}}
                translations={translations}
            />
        </div>
        );
    };

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    // const handleFilterChange = (filtersArray) => {
    //     const filters = filtersArray.reduce((acc, filter) => {
    //         acc[filter.name] = filter.value;
    //         return acc;
    //     }, {});

    //     const _assunto = filters.assunto || '';
    //     const _situacaoValue = filters.situacao;
    //     const _criticidadeValue = filters.criticidade;

    //     const _situacao = Status.find(x => x.value === _situacaoValue)?.label || '';
    //     const _criticidade = Criticidade.find(x => x.value === _criticidadeValue)?.label || '';

    //     let filterCreated = {
    //         ...filterPage,
    //         size: 500,
    //         term: _assunto.toLowerCase(),
    //         situacao: _situacao,
    //         criticidade: _criticidade,
    //         filterStatus: _situacaoValue || 0,
    //         filterCriticidade: _criticidadeValue || 0,
    //         start: 0,
    //         ordered: 'id',
    //         direction: 'desc',
    //         filter: _assunto
    //     };

    //     setFilterPage(filterCreated);
    //     loadData(filterCreated);

    //     // Atualiza o estado do filtro para indicar se os filtros estão ativos
    //     // Verifica se há algum valor de filtro aplicado
    //     let isFilterActive = _assunto !== '' || _situacao !== '' || _criticidade !== '';

    //     dispatch(setFilterActive(isFilterActive));
    // };


    // Condição 1
    const isList = ServiceHeyho.state.data.chamadoTree && functions.functionsList;

    // Condição 2
    const isDetails = templateEdit && record.edit === true && !loading;
    
    // Condição 3
    const isAddComment = record.record?.idSituacao !== 7 && record.record?.idSituacao !== 6 && record.record?.idSituacao !== 8 && !loading;


    const functionBack = [
        {
            title: 'Voltar',
            icon: <PiArrowLeftLight />,
            tooltip: "Voltar",
            onClick: () => {
                setRecord({ ...emptyRecordMessage });
            }
        }
    ]

    const functionAddComment = [
        {
            title: 'Adicionar comentário',
            icon: 'lci lci-annotation',
            tooltip: "Adicionar comentário",
            onClick: () => ADDComment()
        }
    ]


    let functionsToUse;

    if (isList) {
        if (isDetails) {
            functionsToUse = functionBack;
            if (isAddComment) {
                functionsToUse = [...functionBack, ...functionAddComment];
            }
        } else {
            functionsToUse = functions.functionsList;
        }
    }
    const AIOpts = [
        {
            icon: <PiMagicWand />,
            text: 'Elaborar',
            value: 1
        },
        {
            icon: <BsArrowsAngleContract style={{ transform: ' rotate(45deg)' }} />,
            text: 'Reduzir',
            value: 2
        },
        {
            icon: <BsArrows />,
            text: 'Expandir',
            value: 3
        },
        {
            icon: <BsType />,
            text: 'Corrigir ortografia',
            value: 5
        },
        {
            icon: <AiOutlineSound />,
            text: 'Mudar tom ',
            submenu: true,
            value: 4
        }
        
    ]

    const changeToneOpts = {
        icon: <AiOutlineSound />,
        text: 'Mudar tom',
        subMenu: [
            {
                text: 'Comunicativo',
                value: 6
            },
            {
                text: 'Bem-humorado',
                value: 7
            },
            {
                text: 'Entusiasmado',
                value: 8
            },
            {
                text: 'Informativo',
                value: 9
            },
            {
                text: 'Profissional',
                value: 10
            },
            {
                text: 'Inteligente',
                value: 11
            },
            {
                text: 'Confiável',
                value: 12
            },
        ],
        value: 4
    }
    const handleCancel =() => {
        setRecord({ ...emptyRecordMessage }); 
        setSugestaoExp(false);
        setAiSelected(0);
        setDescription('');
        setSubject('');
        dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
        setOpenModelNewReqeust(false); 
        setUpdateField(false)
    }
    return (
        <Layout pageTitle="Chamados LiveCloud"
            // loading={loadingData}
           
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: visibleListDataFiltered.length
            }}
            functionsGeneric={functionsToUse}

        >
            
            <PPModal
                visible={openModalNewRequest}
                onClose={handleCancel}
                size={'large'}
                title={(record !== null && record.record !== null) && interfaceTyped[template.title] > 0 ?
                    `Ticket:${interfaceTyped[template.title]}` : 'Novo chamado'}
            >
                <LcLoading loading={submitting} label='Carregando...'>
                    <div className='newRequestModal scrollable-v'>
                        <PPForm2
                            fields={fieldsForm}
                            returnType="object"
                            onChange={onChangeForm}
                            submitting={submitting}
                            updateFieldsOnChangeOrigin={updateField}
                            functions={
                                {
                                    submitButton: {
                                        hide: true,
                                        label: 'Ok',
                                    },
                                }
                            }
                        >
                        <div className='AiMenu' style={{marginTop: !sugestaoExp ? '-145px' : '-90px'}}>
                                <LcIconLink
                                    icon={'ppi ppi-lightning-bolt'}
                                    id='aiLCChamados'
                                    degrade
                                    pulse
                                    onClick={() => {
                                        setOpenAIMenu(!openAIMenu)
                                    }}
                                    tooltip={!record.record?.Descricao ?  "Digite algo para habilitar o Assistente de AI" : "Assistente de AI"}
                                    tooltipPosition='right'
                                    disabled={!record.record?.Descricao}
                                    />
                        {
                            openAIMenu &&
                            <PPDropMenu
                                subItems={
                                    AiSelectedMenu === 4 &&
                                    changeToneOpts.subMenu.map(sub => {
                                        return (
                                            <div className={'item AiMenuItem'} onClick={(e) => {
                                                    setSugestaoExp(true);
                                                    setAiSelectedMenu(sub.value)
                                                    setAiSelected(sub.value)
                                                    setOpenAIMenu(false);
                                            }}>
                                                <div className="row item">
                                                    <span style={{ fontSize: '12px' }}>{sub.text}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                items={
                                <>
                                    {
                                    AIOpts.map(Ai => {
                                            return (
                                                <div className={'item AiMenuItem'} onClick={(e) => {
                                                    setAiSelectedMenu(Ai.value)
                                                    if(Ai.value !== 4) {
                                                        setAiSelected(Ai.value)
                                                        setSugestaoExp(true); 
                                                        setOpenAIMenu(false); 
                                                    }else {
                                                        AiSelectedMenu === 4 && setAiSelectedMenu(0)
                                                    }
                                                    }}>
                                                    <div className="row item ">
                                                        {Ai.icon}
                                                        <span style={{ fontSize: '12px' }}>{Ai.text}</span>
                                                    </div>
                                                    { Ai.submenu && <i className="ppi ppi-chevron-right" />}
                                                </div>
                                            )
                                        })
                                    }
                                    </>
                                }
                                onLeave={() => {setOpenAIMenu(!openAIMenu); setAiSelectedMenu(0)}}
                                    position='left'
                            />
                            }
                            </div>
                            <div className='w-100p' style={{marginTop: '-10px'}}>
                                <HHContentAI 
                                    originalText={description} 
                                    visible={sugestaoExp} 
                                    handleVisible={() => setSugestaoExp(!sugestaoExp)} 
                                    setText={(e) =>  { onChangeForm({titulo: record.record.titulo, Descricao: e})} }
                                    user={user} 
                                    instructionType={AiSelected}
                                />
                            </div>
                            <Attachment
                                anexos={anexos}
                                removeFile={(e) => removeFile(e)}
                                setAnexos={handleFiles}
                                visible={!sugestaoExp}
                            />
                        </PPForm2>
                    </div>
                    <div className="space-between pv-5">
                        <button className='pp-button bg-info ml-3' id={'form_novochamado_abrirchamado_button'}onClick={() => saveTicket(ServiceHeyho, setRecord, emptyRecordMessage, resetData)}>Abrir chamado</button>
                        <button className='pp-button bg-gray mr-3' id={'form_novochamado_cancelar_button'} onClick={handleCancel}>Cancelar</button>

                    </div>
                </LcLoading>
            </PPModal>
            {showDetailComment && (
                <DetailForm
                    ticketId={record.record.id}
                    visible={true}
                    parentCallbackSave={saveComment}
                    onClose={() => setShowDetailComment(false)}
                />
            )}
            <DialogConfirmForm onCloseEvent={handleDeleteClose}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
            </DialogConfirmForm>

            <LCDashboard
                cards={card}
                cssClass={templateEdit && (record.edit === true) ? 'hidefilterPanel' : ''}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />

        </Layout >
    );

};

export default HeyHoList;
