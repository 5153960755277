import React, { useEffect, useState } from 'react';

//Componentes
import Layout from '../../../components/Layout/Layout';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';

//Service
import { FinancialManagementService } from '../../../services/financialManagement/FinancialManagementService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { PrefState } from '../../../store/reducers/prefReducer';

import moment from 'moment';
import { useSnackbar } from 'notistack';
import { TbFileTypeXml } from 'react-icons/tb';

const InvoicesAdm: React.FC = (props) => {

    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const service = new FinancialManagementService(props);

    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [size, setSize] = useState(0);
    const [filter, setFilter] = useState({ where: '', order: '' });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setInvoices([]);
        loadData(0, 20);
    }, [filter.where, filter.order])

    const clearData = () => {
        setFilter({ where: '', order: '' });
        setInvoices([]);
        loadData(0, 20);
    }

    const loadData = (skip: number, take: number) => {
        setLoading(true);
        service.GetInvoicesAdm(filter.where, filter.order, skip, take)
            .then(response => {
                setSize(response.data[0].TotalRows);
                skip == 0
                    ?
                    setInvoices(response.data)
                    :
                    setInvoices([...invoices, ...response.data]);
            })
            .catch(console.warn)
            .then(() => setLoading(false));
    }

    const columns = [
        { field: "Nfi_Codigo_NF", headerName: "RPS", width: "5%" },
        { field: "Nfi_Codigo_NFSE", headerName: "Número Nota", width: "10%" },
        { field: "Grp_Descricao", headerName: "Grupo", width: "15%", align: "left" },
        { field: "Cli_Razao_Social", headerName: "Razão social", width: "25%" },
        { field: "Ped_Codigo_Contrato", headerName: "Contrato SIP", width: "10%" },
        { field: "Nfi_Codigo_Pedido", headerName: "Pedido", width: "10%" },
        {
            field: "Nfi_Data_Emissao", headerName: "Data emissão", width: "15%",
            renderCell: (row) => moment(row.Nfi_Data_Emissao).format("DD/MM/YYYY")
        },
        {
            field: "PDF", headerName: "PDF", width: "36px", sort: false,
            renderCell: (row, density) => {
                return row.Nfi_Codigo_NFSE &&
                    <LcIconLink size='small'
                        disabled={!row.Nfi_Codigo_NFSE}
                        icon={`lcfi lcfi-pdf ${density == 'low' ? "lcfi-2x" : density == 'medium' ? "lcfi-075x" : "lcfi-05x"}`}
                        tooltip="Baixar Nota Fiscal PDF"
                        onClick={() => service.GetInvoiceFilePDF(row.Nfi_Codigo_NFSE, row.Nfi_Codigo_NF)
                            .then((response) => {
                                if (response?.includes("The specified resource does not exist")) {
                                    enqueueSnackbar(`A nota fiscal seleciona não foi encontrada para download`, {
                                        variant: 'error',
                                        preventDuplicate: true,
                                        persist: false
                                    })
                                }
                            })
                        }
                    />
            }
        },
        {
            field: "XML", headerName: "XML", width: "36px", sort: false,
            renderCell: (row, density) => {
                return row.Nfi_Codigo_NFSE &&
                    <LcIconLink size='small'
                        icon={<TbFileTypeXml color='var(--status-yellow)' />}
                        tooltip="Baixar Nota Fiscal XML"
                        onClick={() => service.GetInvoiceFileXML(row.Nfi_Codigo_NFSE, row.Nfi_Codigo_NF)}
                    />
            }
        }
    ];

    const loadMore = (size?: number) => {
        if (size)
            loadData(0, size);
        else
            loadData(invoices.length, 10);
    };

    const [filterVisible, setFilterVisible] = useState(false);

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const mountFilter = (filter) => {
        let _filter = filter.filter(f => f.value)
            .map(f => {
                switch (f.name) {
                    case "Nfi_Codigo_NF":
                    case "Nfi_Codigo_NFSE":
                    case "Ped_Codigo_Contrato":
                    case "Nfi_Codigo_Pedido":
                        return `${f.name} = ${f.value}`
                    case "emissao_start":
                        return `Nfi_Data_Emissao >= '${f.value}T00:00:00'`
                    case "emissao_end":
                        return `Nfi_Data_Emissao <= '${f.value}T00:00:00'`
                    default:
                        return `${f.name} LIKE '%${f.value}%'`
                }
            });
        _filter = _filter.length > 0 ? "WHERE " + _filter.join(' AND ') : '';
        return _filter;
    }

    const _filterAdvanced = {
        fields: [
            { label: 'RPS', name: 'Nfi_Codigo_NF', type: 'text' },
            { label: 'Número Nota', name: 'Nfi_Codigo_NFSE', type: 'text' },
            { label: 'Grupo econômico', name: 'Grp_Descricao', type: 'text' },
            { label: 'Razão social', name: 'Cli_Razao_Social', type: 'text' },
            { label: 'Contrato SIP', name: 'Ped_Codigo_Contrato', type: 'text' },
            { label: 'Pedido', name: 'Nfi_Codigo_Pedido', type: 'text' },

            { label: 'Emissão a partir', name: 'emissao_start', type: 'date' },
            { label: 'Emissão até', name: 'emissao_end', type: 'date' }
        ],
        onChange: (filter: any, size: number) => {
            setFilter({ ...filter, where: mountFilter(filter), take: size });
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    return (
        <Layout
            pageTitle='Administração de Notas fiscais'
            dots={<>
                <div style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "high" ? " disabled" : ""}`} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                <div style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "medium" ? " disabled" : ""}`} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                <div style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density == "low" ? " disabled" : ""}`} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
            </>}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: size
            }}
            functionsGeneric={[{ icon: "ppi ppi-x", onClick: () => clearData(), tooltip: "Limpar filtro" }]}
        >

            <LcInfiniteTable
                loading={loading}
                rows={invoices}
                size={size}
                columns={columns}
                density={pref.data?.density || "medium"}
                loadMore={loadMore}
                filter={_filterAdvanced}
                onSortChange={event => setFilter({ ...filter, order: `${event.sort.column} ${event.sort.direction}` })}
            />

        </Layout>
    );
}

export default InvoicesAdm;