import React, { useEffect, useState } from 'react';

import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

import moment from 'moment';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import "echarts/i18n/langPT-br";

//Componentes
import Layout from '../../../components/Layout/Layout';
import FinOpsDashboardListGroupByComponent from '../dashboardListGroupBy';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';
import LcSegment from '../../../components/Generic/LcSegment';
import { FinOpsDashBoard, FinOpsAdvanceFilterService, GraphItem } from '../../../services/finOps/finOpsService';
import FinOpsMapManagerService, { FinOpsAllocationRule } from '../../../services/finOpsManagerService';
import { formatDecimal, getSymbolByCurrency } from '../../../services/currency';
import Alert from '../../../components/Dialog/Alert';
import ConditionFilter from './conditionFilter';
import { FinOpsChartService } from '../../../services/finOps/finOpsChartService';
import { PP_DropDownOld } from 'processor-plataform-ui';


type filter = { contracts: string | undefined, date: string | undefined, idCostCenter: string | undefined, tipoDePeriodo: string | undefined };

const FinOpsDashboardPeriods: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
    const [daysRemaining, setDaysRemaining] = useState<any>();
    const [graphType, setGraphType] = useState<string>('3');
    const [graphGroupType, setGraphGroupType] = useState<string>('all');
    const [costCenters, setCostCenters] = useState<any[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [kpi, setKPI] = useState<FinOpsDashBoard>();
    const [finOpsChartService, setFinOpsChartService] = useState<FinOpsChartService>(new FinOpsChartService(props));
    const defaultFilter: FinOpsAllocationRule = {
        id: 0,
        order: 1,
        default: false,
        description: 'Filtro',
        conditions: [{
            idCondition: 0,
            field: '',
            operation: 'contains',
            filter: '',
            logicOperator: ''
        }],
        costCenters: [],
        contracts: []
    };

    const [filterConditions, setFilterConditions] = useState<FinOpsAllocationRule>(defaultFilter);
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>('currentMonth');

    const [confirmation, setConfirmation] = useState(false);

    const [noData, setNoData] = useState(false);
    const [unitKpi, setUnitKpi] = useState("");
    const [cores, setCores] = useState<string[]>([]);

    const graphOptions = [
        { label: 'Até o momento', value: '1' },
        { label: 'No periodo', value: '2' },
        { label: 'Acumulado', value: '3' }
    ];

    const graphGroupTypeOptions = [
        { label: 'Total', value: 'all' },
        { label: 'Centro de Custo', value: 'costCenter' },
        { label: 'Serviço', value: 'service' },
        { label: 'Recurso', value: 'resource' }
    ];

    const dateOptions = [
        { label: 'Este mês', value: 'currentMonth' },
        { label: 'Mês anterior', value: 'priorMonth' },
        { label: 'Dois meses atrás', value: 'prior2Months' },
        { label: 'Últimos 7 dias', value: 'last7Days' },
        { label: 'Últimos 30 dias', value: 'last30Days' },
        { label: 'Últimos 3 meses', value: 'last90Days' },
        { label: 'Últimos 6 meses', value: 'last180Days' },
        { label: 'Últimos 12 meses', value: 'last365Days' }
    ];

    const [selectedCostCenter, setSelectedCostCenter] = useState<any>("");
    const [selectedContractId, setSelectedContractId] = useState<any>("");

    const [filter, setFilter] = useState<filter>({
        contracts: '',
        date: moment().format('YYYY-MM'),
        idCostCenter: '',
        tipoDePeriodo: ''
    });

    const serviceChartPalette = ['#c9db22', '#40b910', '#0e5735', '#32730a', '#6da20a', '#8db908', '#b0d105', '#d5e802', '#fdff03'];
    const resourceChartPalette = ['#5969CD', '#8684EF', '#8684ef', '#7c6fd5', '#725bbc', '#6746a3', '#5b338b', '#4f1e73', '#42065c'];
    const costCenterChartPalette = ['#E64646', '#E07817', '#F3C944', '#d6c241', '#babb41', '#9fb243', '#86a946', '#6f9f4a', '#59944d'];
    var Palletes = [{ graphGroup: 'service', pallete: serviceChartPalette },
    { graphGroup: 'resource', pallete: resourceChartPalette },
    { graphGroup: 'costCenter', pallete: costCenterChartPalette }]

    useEffect(() => {
        let coresvalues = Palletes.find(v => v.graphGroup == graphGroupType);
        if (coresvalues) setCores(coresvalues.pallete);
        retrieveData();

    }, [tipoDePeriodo, graphGroupType, user.ClientGroupSelected, selectedCostCenter, user.refreshFlag, selectedContractId, filterConditions]);

    useEffect(() => {
        if (kpi) {
            var nowMoment = getDateMomentTipoDePeriodo();
            const yearV = nowMoment.year();
            const monthV = nowMoment.month() + 1;

            let previous = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').add(-1, "M").format('MMMM') + (kpi.lastMonthDailyConsumption.length > 0 ? '' : ' (sem dados)');
            let actual = moment(`$01/${monthV}/${yearV}`, 'DD/MM/YYYY').format('MMMM') + (kpi.dailyConsumption.length > 0 ? '' : ' (sem dados)')

            previous = previous.charAt(0).toUpperCase() + previous.slice(1);
            actual = actual.charAt(0).toUpperCase() + actual.slice(1);

            let baseValue = 0;

            if (kpi.totalConsumption > 1000000)
                baseValue = Math.max(...kpi.costCenterConsumption.filter(c => c.consumption != null).map(c => c.consumption != null ? c.consumption : 0))
            else
                baseValue = kpi.totalConsumption;

            var divideBy = 1;
            if (baseValue <= 10000) {
                divideBy = (1)
            } else if (baseValue <= 1000000) {
                divideBy = (1000)
            } else if (baseValue <= 100000000) {
                divideBy = (1000000)
            }
            setUnitKpi(divideBy == 1000 ? ' mil' : divideBy == 1000000 ? ' milhão' : '');

            setMonthsLabel({ ... { previous, actual } });

            if (kpi.dailyConsumption.length == 0 && kpi.dailyProviderConsumption.length == 0 && tipoDePeriodo == 'currentMonth') {
                return setConfirmation(true);
            }
        }
    }, [kpi])


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFilter({ ...filter, [name]: value });
        if (name == 'graphType')
            setGraphType(value);
        if (name == 'graphGroupType')
            setGraphGroupType(value);

        if (name == 'date' && value.includes('last')) {
            setGraphGroupType(graphGroupTypeOptions[1].value)
        }
    };

    async function retrieveCostCenters() {
        setCostCenters([]);

        try {

            const finOpsMapManagerService = new FinOpsAdvanceFilterService(props);
            let costCenterResponse = await finOpsMapManagerService.LoadCostCentersByUserId();

            let costCenters = [{ label: '(Todos)', value: '' }, ...costCenterResponse.map(u => {
                return {
                    label: u.name,
                    value: u.id
                };
            })];

            setCostCenters(costCenters);

        } catch (e) {
            console.warn(e)
        }
    }

    async function retrieveContracts() {
        try {

            const finOpsMapManagerService = new FinOpsAdvanceFilterService(props);


            var contracts = await finOpsMapManagerService.LoadContracts();

            contracts = contracts.map((u) => {
                return {
                    label: u.contractName,
                    value: u.contractId
                };
            });

            let contractsLocal = [{ label: '(Todos)', value: '' }, ...contracts];
            setContracts(contractsLocal);
        } catch (e) {

        }
    }

    async function retrieveReportKPIs() {
        const finOpsService = new FinOpsAdvanceFilterService(props);
        let kpis = await finOpsService.GetKPIByYearMonthLastPeriod(tipoDePeriodo,
            selectedCostCenter,
            selectedContractId,
            graphGroupType,
            filterConditions);
        ;
        if (kpis.length > 0) {
             
            //kpis[0].dataGroupLast && kpis[0].dataGroupLast
            //    .forEach(rowlast => {
            //        rowlast.series = rowlast.series.sort((a: any, b: any) => { return a.date > b.date ? 1 : -1 });
            //    });

            setKPI(kpis[0]);
        }
        else {
            setKPI(undefined);
            setNoData(true);
        }

        let d = moment();
        let currentDay = d.get("date");
        let daysInMonth = d.daysInMonth();
        let daysRemaining = daysInMonth - currentDay;
        setDaysRemaining(daysRemaining);
    }

    async function retrieveData() {
        setKPI(undefined);
        setLoading(true);
        try {
            await Promise.all([retrieveCostCenters(), retrieveContracts(), retrieveReportKPIs()])
                .finally(() => setLoading(false))
        }
        catch (error) {
            console.warn(error);
            setLoading(false);
        }
    }

    const [monthsLabel, setMonthsLabel] = useState({ previous: "", actual: "" })

    function getDateMomentTipoDePeriodo() {
        var nowMoment = moment();

        if (tipoDePeriodo == 'priorMonth') {
            return nowMoment.add(-1, "month");
        }
        if (tipoDePeriodo == 'prior2Months') {
            return nowMoment.add(-2, "month");
        }
        return nowMoment;
    }




    const getConsumptionComparative = (totalConsumption: number, lastMonthTotalConsumption: number) => {

        let last = Number(lastMonthTotalConsumption);
        let actual = Number(totalConsumption);

        let difference = Math.abs(actual - last);

        if (actual > last) {
            let valueDiff = actual ? (difference * 100) / actual : 0;
            valueDiff = valueDiff ? valueDiff : 0;
            return (formatDecimal(valueDiff) + '% acima');
        }
        else if (actual < last) {
            let valueDiff = last ? (difference * 100) / last : 0;
            valueDiff = valueDiff ? valueDiff : 0;
            return (formatDecimal(valueDiff) + '% abaixo');
        }
        else {
            return ("igual");
        }
    };

    const [chartType, setChartType] = useState("stack");

    return (
        <Layout pageTitle='FinOps' loading={loading}>

            <Alert
                visible={confirmation}
                text={'O provedor ainda não disponibilizou dados financeiros para o mês atual, por isso vamos exibir dados do mês anterior.'}
                context={'info'}
                title={'Atenção'}
                onClose={() => {
                    setTipoDePeriodo('priorMonth'); setFilter({ ...filter, date: 'priorMonth' });
                    setConfirmation(false)
                }}
            />

            <div className='row botton'>
                <PP_DropDownOld
                    title="Período"
                    name="date"
                    options={dateOptions}
                    value={tipoDePeriodo}
                    onChange={event => {
                        setTipoDePeriodo(event.target.value)
                        handleChange(event);
                    }}
                    required
                    width='190px'
                />
                <PP_DropDownOld
                    title='Contrato'
                    name="contracts"
                    options={contracts}
                    value={selectedContractId}
                    onChange={event => {
                        setSelectedContractId(event.target.value);
                        handleChange(event);
                    }}
                    width='190px'
                    required
                />
                {
                    costCenters &&
                    <PP_DropDownOld
                        title='Centro de custo'
                        name="idCostCenter"
                        options={costCenters}
                        value={selectedCostCenter}
                        onChange={event => {
                            setSelectedCostCenter(event.target.value);
                            handleChange(event);
                        }}
                        required
                        width='190px'
                    />
                }

                <button
                    type='button'
                    className='pp-button bg-info'
                    onClick={() => {
                        setShowAdvancedFilter(true);
                    }}  >
                    Avançado
                </button>


            </div >

            <ConditionFilter
                visible={showAdvancedFilter}
                conditions={filterConditions.conditions} //Novo!!!!!!!!!!!!!!!
                onSubmit={data => {
                    setFilterConditions({ ...{ ...filterConditions, conditions: data } });
                    setShowAdvancedFilter(false);
                }}
                onClose={() => { setShowAdvancedFilter(false) }}
            />

            <div className='scrollable-v' style={{ height: 'calc(100% - 73px)', paddingTop: '10px', marginTop: '8px' }}>

                <div className='row full centralized ph-7'>
                    {
                        kpi
                            ?
                            <div className='font-5x mt-7 mb-7'>
                                <span >
                                    {filter?.date && !filter?.date.includes('prior') ?
                                        <>
                                            Desde o
                                            {` ${tipoDePeriodo.includes("last") ?
                                                moment(kpi?.lowestDate).format('D') + `º`
                                                :
                                                '1º'
                                                } `}
                                            dia de

                                        </>
                                        :
                                        <>
                                            No mês de
                                        </>
                                    }
                                    <b style={{ textTransform: "capitalize" }}>
                                        {` ${tipoDePeriodo.includes('current') ?
                                            moment().format('MMMM')
                                            :
                                            moment(kpi?.lowestDate).format('MMMM')
                                            }
                                                `}
                                    </b>
                                    , estimadamente, foram consumidos
                                    <b>{` ${kpi.contractCurrencyLabel ? getSymbolByCurrency("BRL") : 'R$'}
                                            ${kpi.totalConsumption && formatDecimal(kpi.totalConsumption)}
                                           `}</b>
                                    com serviços e impostos inclusos.
                                </span>
                            </div>
                            :
                            <div className='font-5x mt-7 mb-7'>
                                <span>
                                    {`Não há dados para o período selecionado.`}
                                </span>
                            </div>
                    }
                </div>

                <LcSegment
                    finOps
                    title={`Top 3 (${kpi ? getSymbolByCurrency("BRL") : 'R$'}${unitKpi})`}
                    loading={loading}
                    height="120px">
                    {
                        kpi &&
                            kpi.dailyConsumption &&
                            kpi.dailyConsumption.length == 0 ?
                            <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                                <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: 14 }}>
                                            {`Não há dados para o período selecionado.`}
                                        </span>
                                    </div>
                                </LCFlexItem>
                            </LCFlexContainer>
                            :
                            kpi &&
                            <LCFlexContainer spacing={1} justify="center" alignItems='center'>

                                <LCFlexItem xs={4} sm={4} md={4} lg={4}>
                                    <span><b>Serviços</b></span>
                                    {kpi &&
                                        <ReactECharts
                                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            option={finOpsChartService.getConsumptionChart(kpi.serviceConsumption, serviceChartPalette, kpi)}
                                        />
                                    }
                                </LCFlexItem>

                                <LCFlexItem xs={4} sm={4} md={4} lg={4}>
                                    <span><b>Recursos</b></span>
                                    {kpi &&
                                        <ReactECharts
                                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            option={finOpsChartService.getConsumptionChart(kpi.resourceConsumption, resourceChartPalette, kpi)}
                                        />
                                    }
                                </LCFlexItem>

                                <LCFlexItem xs={4} sm={4} md={4} lg={4}>
                                    <span><b>Centros de custo</b></span>
                                    {kpi &&
                                        <ReactECharts
                                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            option={finOpsChartService.getConsumptionChart(kpi.costCenterConsumption, costCenterChartPalette, kpi)}
                                        />
                                    }
                                </LCFlexItem>

                            </LCFlexContainer>
                    }
                    {kpi == undefined &&
                        <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                            <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                <div style={{ textAlign: 'center' }}>
                                    <span style={{ fontSize: 14 }}>
                                        {`Não há dados para o período selecionado.`}
                                    </span>
                                </div>
                            </LCFlexItem>
                        </LCFlexContainer>
                    }
                </LcSegment>


                {tipoDePeriodo.includes("Month") &&
                    <LcSegment title={
                        <div className={'lc-segment-title-row'} >
                            <div className={'lc-title'}>
                                Consumo mensal ({kpi ? getSymbolByCurrency("BRL") : 'R$'} {unitKpi})
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <PP_DropDownOld
                                    name='graphType'
                                    options={graphOptions}
                                    onChange={handleChange}
                                    value={graphType}
                                    required
                                    right
                                    hideFilter
                                    width='180px'
                                />
                            </div>
                        </div>
                    } loading={loading}
                        height="330px">

                        <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                            <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                {kpi &&
                                    <>
                                        {graphType == '1' &&
                                            <ReactECharts
                                                opts={{ renderer: 'svg', locale: 'PT-br' }}

                                                option={finOpsChartService.getLineConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, false, monthsLabel, kpi)}
                                            />
                                        }
                                        {graphType == '2' &&
                                            <ReactECharts
                                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                option={finOpsChartService.getLineConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, monthsLabel, kpi)}
                                            />
                                        }
                                        {graphType == '3' &&
                                            <ReactECharts
                                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                option={finOpsChartService.getAreaConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, monthsLabel, kpi)}
                                            />
                                        }
                                    </>
                                }
                                {kpi == undefined &&
                                    <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                                        <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                            <div style={{ textAlign: 'center' }}>
                                                <span style={{ fontSize: 14 }}>
                                                    {`Não há dados para o período selecionado.`}
                                                </span>
                                            </div>
                                        </LCFlexItem>
                                    </LCFlexContainer>
                                }
                            </LCFlexItem>
                        </LCFlexContainer>
                    </LcSegment>
                }

                {
                    tipoDePeriodo.includes("last") &&
                    <LcSegment
                        title={
                            <div className={'lc-segment-title-row'} >
                                <div className={'lc-title'}>
                                    Consumo mensal ({kpi ? getSymbolByCurrency("BRL") : 'R$'} {unitKpi})
                                </div>

                                <div className="lc-buttons" style={{ zIndex: 200 }}>
                                        <button className={`pp-button small ${chartType == 'stack' ? 'active' : ''}`} type='button'
                                            onClick={() => setChartType('stack')} ><i className="ppi ppi-view-boards ppi-2x" /></button>
                                    {
                                            (kpi && kpi.dataGroupbyTotal && kpi.dataGroupbyTotal.length > 1) &&
                                            <button className={`pp-button small ${chartType == 'bar' ? 'active' : ''}`} type='button'
                                                onClick={() => setChartType('bar')} ><i className="ppi ppi-chart-bar ppi-2x" /></button>
                                    }
                                        <button className={`pp-button small ${chartType == 'line' ? 'active' : ''}`} type='button'
                                            onClick={() => setChartType('line')} ><i className="ppi ppi-trending-up ppi-2x" /></button>
                                </div>
                                <PP_DropDownOld
                                    name='graphGroupType'
                                    options={graphGroupTypeOptions}
                                    onChange={handleChange}
                                    value={graphGroupType}
                                    required
                                    hideFilter
                                    right
                                    width='180px'
                                />
                            </div>
                        }
                        loading={loading}
                        height="330px"
                    >
                        <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                            <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                {kpi && kpi.dataGroupLast &&
                                    <ReactECharts
                                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                                        option={finOpsChartService.getBarConsumptionChart(   tipoDePeriodo, cores, kpi, chartType as any)}
                                    />
                                }
                                {kpi == undefined &&
                                    <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                                        <div style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: 14 }}>
                                                {`Não há dados para o período selecionado.`}
                                            </span>
                                        </div>
                                    </LCFlexContainer>
                                }
                            </LCFlexItem>
                        </LCFlexContainer>
                    </LcSegment>
                }

                {
                    tipoDePeriodo.includes("Month") && kpi &&
                    <div className='row full centralized ph-7'>
                        <span className='font-5x mt-7 mb-7 text-center'>
                            {`Em `}

                            {filter?.date && filter?.date == dateOptions[0].value ?
                                `${moment().format('MMMM')} `
                                :
                                `${moment(kpi.lowestDate).format('MMMM')} `
                            }

                            {
                                (moment(kpi?.lowestDate).month() == moment().month()) &&
                                <>
                                    , faltando
                                    <b>{` ${daysRemaining} `}</b>
                                    dias para o final do mês,
                                </>
                            }
                            {` o consumo total está `}
                            <b>
                                {`${getConsumptionComparative(kpi?.totalConsumption, kpi?.lastMonthTotalConsumption)}`}
                            </b>
                            {` ${kpi?.totalConsumption == kpi?.lastMonthTotalConsumption ? `ao` : `do`} consumo de ${monthsLabel.previous}, passando de `}
                            <b>
                                {`${kpi ? getSymbolByCurrency("BRL") : 'R$'}  ${kpi?.lastMonthTotalConsumption && formatDecimal(kpi?.lastMonthTotalConsumption)}`}
                            </b>
                            {` para `}
                            <b>
                                {`${kpi ? getSymbolByCurrency("BRL") : 'R$'}  ${kpi?.totalConsumption && formatDecimal(kpi?.totalConsumption)}`}
                            </b>
                            {`. Este valor representa `}
                            <b>
                                {`${formatDecimal(((kpi?.totalConsumption * 100) / kpi?.baseline) && ((kpi?.totalConsumption * 100) / kpi?.baseline))}%`}
                            </b>
                            {` do baseline mensal.`}
                        </span>
                    </div>
                }

                {tipoDePeriodo.includes("Month") &&
                    <LcSegment finOps title={<div>Comparativo do baseline <b style={{ color: '#000' }}>{`${kpi ? getSymbolByCurrency("BRL") : 'R$'} ${kpi ? formatDecimal(kpi.baseline) : 0.00} `}</b> </div>} loading={loading} height="220px" >
                        {kpi == undefined ?
                            <LCFlexContainer spacing={1} justify="center" alignItems='center'>
                                <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: 14 }}>
                                            {`Não há dados para o período selecionado.`}
                                        </span>
                                    </div>
                                </LCFlexItem>
                            </LCFlexContainer>
                            :
                            <LCFlexContainer spacing={1} justify='center' alignItems='center'>
                                {kpi && kpi.lastMonthTotalConsumption != null &&
                                    <LCFlexItem xs={6} sm={6} md={6} lg={6} justify='center' alignItems='center'>
                                        <div style={{ textAlign: 'center', marginTop: '-8px' }}>
                                            <span><b>{monthsLabel.previous}</b></span>
                                        </div>
                                        <ReactECharts
                                            style={{ height: '250px' }}
                                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            option={finOpsChartService.getBaselineChart(kpi?.lastMonthTotalConsumption, false, kpi)}
                                        />
                                    </LCFlexItem>
                                }
                                <LCFlexItem xs={6} sm={6} md={6} lg={6} justify='center' alignItems='center'>
                                    <div style={{ textAlign: 'center', marginTop: '-8px' }}>
                                        <span><b>{monthsLabel.actual}</b></span>
                                    </div>
                                    {kpi &&
                                        <ReactECharts
                                            style={{ height: '250px' }}
                                            opts={{ renderer: 'svg', locale: 'PT-br', }}
                                            option={finOpsChartService.getBaselineChart(kpi?.totalConsumption, true, kpi)}
                                        />
                                    }
                                </LCFlexItem>
                            </LCFlexContainer>
                        }
                    </LcSegment>
                }

                <FinOpsDashboardListGroupByComponent
                    costCenters={costCenters}
                    contracts={contracts}
                    selectedContract={selectedContractId}
                    selectedCostCenter={selectedCostCenter}
                    tipoDePeriodo={tipoDePeriodo}
                    filter={filterConditions}
                    openLevel={4}
                />

                {
                    kpi &&
                    <div className='row full center ph-7'>
                        <span className='font-5x mt-7 mb-7 text-center'>
                            {`Exibindo dados do contrato `}
                            <b>
                                {`${kpi?.contractName}`}
                            </b>
                            {`. Valores estimados baseados em baseline mensal `}
                            <em>pro rata</em>
                            {` para dia de uso da solução. Consumo calculado sobre a estimativa média diária relativa ao baseline de `}
                            <b>
                                {`${kpi ? getSymbolByCurrency("BRL") : 'R$'} ${kpi?.baseline && formatDecimal(kpi?.baseline)}`}
                            </b>
                            {` mensais. Atualizado até `}
                            <b>
                                {`${moment(kpi?.highestDate).format('LLL')}`}
                            </b>
                            {`.`}
                            {` Consumos apurados em dólar serão atualizados no dia do faturamento.`}
                        </span>
                    </div>
                }

            </div>

        </Layout >
    );
};

export default FinOpsDashboardPeriods;