import React, { useState } from 'react';
import { PPForm2, PPModal } from 'processor-plataform-ui';
import { useSnackbar } from 'notistack';

import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import PPFormWithWizard from '../../../components/Form/PPFormModal';
import { SaasUserService } from '../../../services/SaasUserService';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import { VendorService } from '../../../services/vendorService';

interface SoftwaresHubWizardFormProps {
    data?: any,
    handleform?: any,
    visible?: boolean,
    onClose?: Function,
    parentCallbackSave?: Function;
}


const SoftwaresHubWizardForm: React.FC<SoftwaresHubWizardFormProps> = (props) => {
    const history = useHistory();
    const SaaS_Service = new SaasUserService(props);
    const handleEditOpening = async () => { history.push('/eas/licensecontrolmanager'); }
    const handleUpdate = async () => { history.push('/eas/licensecontrolmanager'); }
    const handleExitEdit = async () => { history.push('/eas/licensecontrolmanager'); }

    return <Layout pageTitle='Wizard Software Hub'>
        {<PPFormWithWizard
            descriptionSubTitle="Para completar a configuração da solução Software Hub, siga os passos a seguir:"
            isModal={false}
            mode="edit"
            type="wizard"
            title="Wizard Software Hub"
            size="large"
            show={props.visible == true ? true : false}
            data={{}}
            formFields={SaaS_Service.getWizardEAFormFieldsData(true)}
            onOpen={handleEditOpening}
            onSave={handleUpdate}
            onCancel={handleExitEdit}
            onClose={handleExitEdit}
        />}
    </Layout>;
}

export default SoftwaresHubWizardForm;