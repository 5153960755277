import React, { useEffect, useState } from 'react';

import { PortalUsersService } from '../../../services/portalUsers/portalUsers';

import Layout from '../../../components/Layout/Layout';
import LcLoading from '../../../components/Generic/LcLoading';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import LcDropDown from '../../../components/Generic/LcDropDown';
import Form from '../Form';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import Confirmation from '../../../components/Dialog/Confirmation';
import { useSnackbar } from 'notistack';

export interface User {
    userName: string;
    changePwdFirstLogin: boolean;
    name: string;
    email: string;
    emailConfirmed: boolean;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnabled: boolean;
    accessFailedCount: number;
    isConfirmed: boolean;
    dateEdited: string;
    successfulLoginCount: number;
    dateCreated: string;
    active: boolean;
    isDeleted: boolean;
    idDashboardPadrao: number;
    recebeuWelcomeKit: boolean;
    permiteEnvioEmail: boolean;
    idiomaPadrao: string;
    gmtPadrao: string;
    isAdministrator: boolean;
    isMultiOrganization: boolean;
}


const PortalUSers: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const service = new PortalUsersService(props);

    const [loadingActive, setLoadingActive] = useState(false);
    const [activeUsers, setActiveUsers] = useState<User[]>([]);
    const [inactiveUsers, setInactiveUsers] = useState<User[]>([]);
    const [typePage, setTypePage] = useState('main');
    const [value, setValue] = useState(0);
    const [selectedUser, setSelectedUser] = useState<User>()
    const [modalNewVisible, setModalNewVisible] = useState(false);
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [dataFiltered, setDataFiltered] = useState<User[]>([]);
    const [filterVisible, setFilterVisible] = useState(false);

    const loadData = () => {
        setLoadingActive(true);

        Promise.all([service.getActiveUsers(), service.getInactiveUsers()])
            .then(([activeResponse, inactiveResponse]) => {
                const activeUsers = activeResponse.data.sort((a, b) => a.name.localeCompare(b.name));
                const inactiveUsers = inactiveResponse.data.sort((a, b) => a.name.localeCompare(b.name));
                setActiveUsers(activeUsers);
                setInactiveUsers(inactiveUsers);
            })
            .catch(error => {
                console.error("Error loading data:", error);
            })
            .finally(() => {
                setTimeout(() => {
                    setLoadingActive(false);
                }, 500)
            });
    }

    useEffect(() => {
        setValue(0)
        setFilterVisible(false);
        loadData()
    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {
        value === 0 ? setDataFiltered(activeUsers) : setDataFiltered(inactiveUsers);
    }, [value, activeUsers, inactiveUsers])

    const columns = [
        { field: 'name', headerName: 'Nome', width: "34%" },
        { field: 'email', headerName: 'E-mail', width: "33%" },
        { field: 'phoneNumber', headerName: 'Telefone', width: "33%" },
    ];

    const functionsGeneric = [
        {
            title: 'Criar',
            icon: 'ppi ppi-plus',
            tooltip: "Criar Usuário",
            onClick: () => {
                setSelectedUser(service.emptyRecord);
                setModalNewVisible(true);
            }
        }
    ]

    const functionsRowActiveUsers = [
        {
            label: 'Editar',
            func: (row: any) => {
                setSelectedUser(row);
                setModalNewVisible(true);
            }
        },
        {
            label: 'Informações',
            func: (row: any) => {
                setTypePage("info");
                setSelectedUser(row);
                setModalNewVisible(true);
            }
        },
        {
            label: 'Remover',
            func: (row: any) => {
                setSelectedUser(row);
                setConfirmRemove(true);
            }
        },
    ]

    const handleRemoveUser = () => {
        setConfirmRemove(false);
        service.removeUserById(selectedUser!['id'])
            .then(() => {
                enqueueSnackbar("Usuário removido com sucesso.", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
                loadData();
            })
            .catch(error => {
                console.error("Erro ao remover usuário: ", error);
                enqueueSnackbar(error.response.data, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
            });
    }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const filter = {
        fields: [{ label: 'Pesquisa', name: 'name', type: 'text' }],
        onChange: (fields: any[], size: number) => {
            if (fields[0].value === '') {
                value === 0 ? setDataFiltered(activeUsers) : setDataFiltered(inactiveUsers);
            } else {
                var _filtered = dataFiltered.filter(o => {
                    let match = false;

                    if (fields.length > 0 && fields[0].value) {
                        let term = fields[0].value.toLowerCase().replace('$', '');
                        if (!term) {
                            match = true;
                        } else {
                            match = columns.some(c => {
                                if (!o.hasOwnProperty(c.field)) {
                                    return false;
                                }
                                return new RegExp(term, "iu").test((o[c.field] && o[c.field].toString()));
                            });
                        }
                    } else {
                        fields[0].value = '';
                        match = true;
                    }

                    return match;
                });
                setDataFiltered(_filtered);
            }
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }
    return (

        <Layout
            cardType
            pageTitle="Usuários do portal"
            functionsGeneric={functionsGeneric}
            row={
                <LcDropDown
                    element={<span>{value == 0 ? "Ativos" : "Inativos"} <i className="ppi ppi-chevron-down" /></span>}
                    position="right"
                >
                    <div className={`item${value == 0 ? " disabled" : ""}`} onClick={() => { setValue(0) }}>Ativos</div>
                    <div className={`item${value == 1 ? " disabled" : ""}`} onClick={() => { setValue(1) }}>Inativos</div>
                </LcDropDown>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: dataFiltered.length
            }}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
        >
            {
                <LcLoading loading={loadingActive} label={value === 0 ? "Carregando usuários ativos..." : "Carregando usuários inativos..."}>
                    <Confirmation
                        title={`Remover usuário "${selectedUser?.name}"?`}
                        text={<p>Esta ação só pode ser desfeita através do recadastramento do usuário com seu email e login originais.</p>}
                        display={confirmRemove}
                        confirm={handleRemoveUser}
                        close={() => setConfirmRemove(false)}
                        textBtnOk='Sim'
                        textBtnCancel='Não'
                    />
                    <LcInfiniteTable
                        columns={columns}
                        rows={dataFiltered}
                        functionsRow={functionsRowActiveUsers}
                        filter={filter}
                        density={pref.data?.density || "high"}
                        loadMore={() => undefined}
                        size={dataFiltered.length}
                    />
                </LcLoading>
            }
            <Form
                onClose={() => {
                    setSelectedUser(service.emptyRecord);
                    setModalNewVisible(false);
                    setTypePage('main');
                    loadData();
                }}
                visible={modalNewVisible}
                user={selectedUser}
                loading={loadingActive}
                infoMode={typePage == 'info'}
            />

        </Layout>

    )
}
export default PortalUSers;
