import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { SelectField } from '../../../components/Form/Input';
import { CustomerIndicatorSummaryService } from '../../../services/maxOutsource/customerIndicatorSummaryService';

import Moment from 'moment';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcLoading from '../../../components/Generic/LcLoading';

function getAllYears() {
  let allYears: any[] = [];
  let number = 8;
  let currentYear = new Date().getFullYear();
  for (let index = currentYear; index > (currentYear - number); index--) {
    let yearObject = {
      value: '' + index,
      name: '' + index
    }
    allYears.push(yearObject);
  }
  return allYears;
}

var styles = {
  bottomIcon: {
    paddingBottom: 50
  },
}

const CustomerIndicatorSummary: React.FC = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const month = new Date().getMonth().toString();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [contract, setContract] = useState('0');

  const [indicators, setIndicators] = useState([]);
  const [indicatorsFiltered, setIndicatorsFiltered] = useState<any[]>([]);
  const [indicatorsVisible, setIndicatorsVisible] = useState<any[]>([]);

  const [indicatorsMonths, setIndicatorsMonths] = useState<{ month: string, value: string }[]>([]);
  const [indicatorsMonthsFiltered, setIndicatorsMonthsFiltered] = useState<{ month: string, value: string }[]>([]);

  const [showModalIndicatorsMonths, setShowModalIndicatorsMonths] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const filterDefault = { size: 20, term: "", column: "", direction: "asc" };
  const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);
  const [filterMonth, setFilterMonth] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

  const user = useSelector<RootState, UserState>(state => state.user);
  const pref = useSelector<RootState, PrefState>(state => state.pref);
  const dispatch = useDispatch();

  const customerIndicatorSummaryService = new CustomerIndicatorSummaryService(props);

  const LcOptionsListValuesTipo = [
    { value: '0', name: 'Contrato' },
    { value: '1', name: 'Acompanhamento' }
  ];

  const LcOptionsListValuesYear = getAllYears();

  const inputSetContract = (data: any) => {
    setContract(data.value);
  };

  const inputSetYear = (data: any) => {
    setYear(data.value);
  };

  useEffect(() => {
    setIsLoading(true);
    setIndicatorsFiltered([]);

    customerIndicatorSummaryService.GetIndicadores(year, contract, month).then((response) => {
      setIndicators(response);
      setIndicatorsFiltered(response);
      setIsLoading(false);
    });

  }, [year, contract, user.ClientGroupSelected, user.refreshFlag]);

  useEffect(() => {
    var filtered = indicators.filter((p: any) =>
      filter.term == '' ||
      p.title.toLowerCase().indexOf(filter.term) > -1
    )
      .sort((a: any, b: any) => {
        if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
        else return (a[filter.column] > b[filter.column]) ? -1 : 1;
      });

    setIndicatorsFiltered(filtered);
    setIndicatorsVisible(filtered.slice(0, filter.size));
  }, [filter, indicatorsFiltered.length > 0]);


  useEffect(() => {
    var filtered = indicatorsMonths.filter((p: any) =>
      filter.term == '' ||
      p.value.toLowerCase().indexOf(filterMonth.term) > -1 ||
      p.month.toLowerCase().indexOf(filterMonth.term) > -1
    )
      .sort((a: any, b: any) => {
        if (filterMonth.direction == 'asc') return (a[filterMonth.column] > b[filterMonth.column]) ? 1 : -1;
        else return (a[filterMonth.column] > b[filterMonth.column]) ? -1 : 1;
      });

    setIndicatorsMonthsFiltered(filtered);
  }, [filterMonth]);

  function openMonths(subitems: any[]) {
    setIndicatorsMonths(subitems);
    setIndicatorsMonthsFiltered(subitems);
    setShowModalIndicatorsMonths(true);
  }

  const columnsIndicatorsMonths = [
    { field: 'month', headerName: 'Mês', width: "50%" },
    { field: 'value', headerName: 'Valor', width: "50%" },
  ];

  const columnsTable = [
    { field: 'title', headerName: 'Título', width: "21%", headerClassName: 'font-tiny' },
    {
      field: 'statusImage', headerName: 'Status', width: "5%",
      renderCell: (row: any) => {

        return <i style={{ fontSize: '16px' }} className={row.statusImage}> </i>
      },
    },
    {
      field: 'trendImage', headerName: 'Meta', width: "7%", align: 'center',
      renderCell: (row: any) => {
        return (
          <>
            <i style={{ fontSize: '16px' }} className={row.trendImage}></i>
            <span style={{ paddingTop: 0 }} className={row.trendImage == 'ppi ppi-arrow-up status-ok' ? 'status-ok' : ''}>{row.goal}</span>
          </>
        );
      },
    },
    {
      field: 'average', headerName: 'Média', headerClassName: 'font-tiny', width: "7%", align: 'center',
      renderCell: (row: any) => {
        return <div className={row.statusImage?.includes("status-ok") ? 'status-ok' : row.statusImage?.includes("status-alert") ? 'status-alert' : ''} style={{ width: '100%', textAlign: 'right' }}>{row.average}</div>;
      }
    },
    { field: 'january', headerName: 'Jan', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.january}</div>; } },
    { field: 'february', headerName: 'Fev', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.february}</div>; } },
    { field: 'march', headerName: 'Mar', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.march}</div>; } },
    { field: 'april', headerName: 'Abr', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.april}</div>; } },
    { field: 'may', headerName: 'Mai', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.may}</div>; } },
    { field: 'june', headerName: 'Jun', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.june}</div>; } },
    { field: 'july', headerName: 'Jul', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.july}</div>; } },
    { field: 'august', headerName: 'Ago', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.august}</div>; } },
    { field: 'september', headerName: 'Set', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.september}</div>; } },
    { field: 'october', headerName: 'Out', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.october}</div>; } },
    { field: 'november', headerName: 'Nov', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.november}</div>; } },
    { field: 'december', headerName: 'Dez', headerClassName: 'font-tiny', width: "5%", align: 'center', renderCell: (row: any) => { return <div style={{ width: '100%', textAlign: 'right' }}>{row.december}</div>; } },
  ];

  const functionsTable = [
    {
      title: 'Exportar selecionados',
      icon: 'lci lci-document-text',
      func: () => {
        alert('Ação')
      }
    },
    {
      title: 'Excluir selecionados',
      icon: 'lci lci-trash',
      func: () => {
        alert('Ação')
      }
    },
    {
      title: 'Ativar selecionados',
      icon: 'ppi ppi-check',
      func: () => {
        alert('Ação')
      }
    },
    {
      title: 'Filtros',
      icon: 'lci lci-filter',
      func: () => {
        setModalVisible(!modalVisible)
      }
    }
  ];

  const onChagePeriod = (selected: any) => {
    let date = selected.fields[0].value;
    let a = date.split('-')[0]
    setYear(date.split('-')[0]);
  }
  const periodSelection = {
    periods: [
      {
        label: 'Este ano',
        text: 'Este ano',
        fields: [
          { name: 'year', value: Moment().format('YYYY') }
        ],
        default: true
      },
      {
        label: 'Ano anterior',
        text: 'Ano anterior',
        fields: [
          { name: 'year', value: Moment().add(-1, 'y').format('YYYY-MM') }
        ]
      },
      {
        label: 'Dois anos atrás',
        text: 'Dois anos atrás',
        fields: [
          { name: 'year', value: Moment().add(-2, 'y').format('YYYY-MM') },
        ]
      }
    ],
    customPeriod: {
      label: 'Personalizado',
      text: 'Personalizado',
      fields: [
        { name: 'year', label: 'Ano', value: '' }
      ]
    },
    customPeriodType: 'year',
    onChange: onChagePeriod
  };



  const filterAdvancedIndicatorsMonths = {
    fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
    onChange: (_filter: any, size: number) => setFilterMonth({ ...filterMonth, size: size, term: _filter[0].value.toLowerCase() })
  };

  const onSortChange = (sortData: any) => {
    const { sort, size } = sortData;
    setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
  };

  const loadMore = (size?: number) => {
    if (size) setIndicatorsVisible(indicatorsFiltered.slice(0, size))
    else {
      setIndicatorsVisible([...indicatorsVisible, ...indicatorsFiltered.slice(indicatorsVisible.length, indicatorsVisible.length + 10)])
    }
  };


  const onSortChangeIndicatorsMonths = (sortData: any) => {
    const { sort, size } = sortData;
    setFilterMonth({ ...filterMonth, column: sort.column, direction: sort.direction, size: size })
  };

  const loadMoreIndicatorsMonths = (size?: number) => {
    if (size) setIndicatorsMonthsFiltered(indicatorsMonths.slice(0, size))
    else {
      setIndicatorsMonthsFiltered([...indicatorsMonthsFiltered, ...indicatorsMonths.slice(indicatorsMonthsFiltered.length, indicatorsMonthsFiltered.length + 10)])
    }

  };

  const changeDensity = (density: string) => {
    dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
  }
  const [filterVisible, setFilterVisible] = useState(false);
  const filterAdvancedIndicators = {
    fields: [{ label: 'Assunto', name: 'assunto', type: 'text' }],
    onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() }),
    visible: filterVisible,
    onChangeVisibility: () => setFilterVisible(!filterVisible)
  };

  return (
    <Layout
      pageTitle="Resumo Indicadores"
      periodSelection={periodSelection}
      dots={
        <>
          <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
          <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
          <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
        </>
      }

      gridFilter={{
        toggleVisibility: () => {
          setFilterVisible(!filterVisible)
        },
        size: indicatorsFiltered.length
      }}
    >

      <SideModal visible={showModalIndicatorsMonths} header="Detalhes" onClose={() => setShowModalIndicatorsMonths(false)}>

        <LcInfiniteTable
          loading={showModalIndicatorsMonths}
          columns={columnsIndicatorsMonths}
          rows={indicatorsMonthsFiltered}
          size={indicatorsMonths.length}
          filter={filterAdvancedIndicatorsMonths}
          loadMore={loadMoreIndicatorsMonths}
          onSortChange={onSortChangeIndicatorsMonths}
        />

      </SideModal>

      <SideModal header="Filtro" visible={modalVisible} onClose={() => setModalVisible(false)}>
        <LcAccordion title="Tipo de contrato" visible={true}>
          <SelectField label="Tipo" name="type" options={LcOptionsListValuesTipo} value={contract} onChange={inputSetContract}></SelectField>
        </LcAccordion>
      </SideModal>

      <LcLoading loading={isLoading} label='Carregando...'>
        <LcInfiniteTable
          loading={isLoading}
          columns={columnsTable}
          rows={indicatorsVisible}
          size={indicatorsFiltered.length}
          filter={filterAdvancedIndicators}
          loadMore={loadMore}
          onSortChange={onSortChange}
          fontSize="tiny"
          density={pref.data?.density || "high"}
          height="calc(100% - 52px)"
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center' }}>
          <i className="lci lci-information-circle lci-2x text-info" />
          <span className="font-sm">Dados referentes ao ano de {year}</span>
        </div>
      </LcLoading>

    </Layout>
  );
}

export default CustomerIndicatorSummary;