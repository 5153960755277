import api, { APIClient } from "./api";

export interface Recommendation {
    id: string;
    name: string;
    category?: string;
    description?: string;
    impact?: string;
    properties: {
        category: string;
        impact: string;
        impactedField: string;
        impactedValue: string;
        lastUpdated: string;
        risk: string;
        shortDescription: {
            problem: string;
            solution: string;
        };
    };
}

export interface RecommendationFormatedResponse {
 
    categories: {
        description: string;
        displayDescription: string;
        icon: string;
        color: string;
        count: number;
    }[];
    itens: {
        id: string;
        name: string;
        category?: string;
        description?: string;
        impact?: string;
        properties: {
            category: string;
            impact: string;
            impactedField: string;
            impactedValue: string;
            lastUpdated: string;
            risk: string;
            shortDescription: {
                problem: string;
                solution: string;
            };
        };
        type: string;
        subscription: string;
    }[];
}

export default class AdminRecommendationService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async GetRecommendations(): Promise<RecommendationFormatedResponse> {

        const url = this.endpoint + `/api/AdminRecommendations/GetRecommentations`;
        return api({
            method: 'get',
            url,
        }).then((response) => {
            const RecommendationFormatedResponse: RecommendationFormatedResponse = response.data;
            return RecommendationFormatedResponse;
        });
    }

}