import { PPModal, PPTextArea } from 'processor-plataform-ui/dist';
import React, { ChangeEvent, useState } from 'react';

interface ExpandableTextBoxProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>) => void;
    placeholder?: string;
    modalTitle?: string;
    label?: string;
    name?: string;
}

const ExpandableTextBox: React.FC<ExpandableTextBoxProps> = ({
    value,
    onChange,
    placeholder = "Clicar para expandir",
    modalTitle = "Detalhes do Patch",
    name = "textBox",
    label = "-"
}) => {

    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleOpenModal = (): void => {
        setOpenModal(true);
    };

    const handleCloseModal = (): void => {
        setOpenModal(false);
    };

    return (
        <div>
            <PPTextArea
                rows={20}
                title={label}
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e)}
                required
                style={{
                    width: '100%',
                    height: '200px',
                    fontSize: '14px',
                }}
            /> 
            {/*<button*/}
            {/*    onClick={handleOpenModal}*/}
            {/*    style={{*/}
            {/*        position: 'relative',*/}
            {/*        right: '-95%',*/}
            {/*        bottom: '30px',*/}
            {/*        backgroundColor: '#fff',*/}
            {/*        color: 'black',*/}
            {/*        border: 'none',*/}
            {/*        borderRadius: '4px',*/}
            {/*        padding: '4px 8px',*/}
            {/*        cursor: 'pointer',*/}
            {/*        zIndex: 100,*/}
            {/*    }}*/}
            {/*><i className={('ppi ppi-expand')} />*/}
            {/*</button> */}
            {/*<PPModal*/}
            {/*    visible={openModal}*/}
            {/*    onClose={handleCloseModal}*/}
            {/*    size="large"*/}
            {/*    title={modalTitle}*/}
            {/*>  <div style={{ position: 'relative', height: '65%' }}>*/}
            {/*        <textarea name={name}*/}
            {/*            value={value}*/}
            {/*            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e)}*/}
            {/*            style={{*/}
            {/*                width: '100%',*/}
            {/*                height: '70vh',*/}
            {/*                border: '1px solid #ccc',*/}
            {/*                borderRadius: '4px',*/}
            {/*                padding: '8px',*/}
            {/*                fontSize: '14px',*/}
            {/*                fontFamily:'Ubuntu-Light',*/}
            {/*                resize: 'none', // Disable manual resizing */}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</PPModal>*/}
        </div>);
};

export default ExpandableTextBox;
