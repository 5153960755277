import React, { useEffect, useState } from 'react';
           

//Layout Padrão
import Layout from '../../components/Layout/Layout';

//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import VendorCredentialList from './List';   
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles({
    root: {
        '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
    },
});

function checkZero(data: string){
  if(data.length == 1){
    data = "0" + data;
  }
  return data;
}

const VendorCredentialErroWarnning: React.FC = () => {

    const user = useSelector<RootState, UserState>(state => state.user);
 
    const [users, setUsers] = useState<any[]>([]);
    useEffect(() => {             
        let tUsers: any[] = [];      
    }, [])    
    
    var today = new Date();
    var currentDay: string = today.getDate() + "";
    var currentMonth: string = (today.getMonth() + 1) + "";
    var currentYear: string = today.getFullYear() + "";
    var currentHour: string = today.getHours() + "";
    var currentMinutes: string = today.getMinutes() + "";
    var currentSeconds: string = today.getSeconds() + "";
    
    currentDay = checkZero(currentDay);
    currentMonth = checkZero(currentMonth);
    currentYear = checkZero(currentYear);
    currentHour = checkZero(currentHour);
    currentMinutes = checkZero(currentMinutes);
    currentSeconds = checkZero(currentSeconds);
  
    const lastUpdate = `Última Atualização ${currentDay}/${currentMonth}/${currentYear} ${currentHour}:${currentMinutes}:${currentSeconds}`;       

    return (
        <Layout pageTitle="Assinaturas pendentes do LiveCloud"   >
            <div className="red">Cadastro de assinaturas é essencial para o funcionamento do LiveCloud, revise suas assinaturas em vermelho.</div>
            <div className="red"><a href='https://docs.microsoft.com/en-us/graph/permissions-reference#directory-permissions'>Permissões necessarias para MsGraph</a></div>
            <VendorCredentialList></VendorCredentialList>
        </Layout >
    );
};

export default VendorCredentialErroWarnning;