import React, { useEffect, useState } from 'react';
import './index.css';

interface Props {
    name: string;
    question: string;
    min: number;
    max: number;
    lessText: string;
    moreText: string;
    onChange?: Function;
}

const LcVote: React.FC<Props> = (props) => {
    const [answer, setAnswer] = useState<number | null>(null);
    const [isFocused, setIsFocused] = useState<number | undefined>();

    useEffect(() => setAnswer(null), [props.question])

    useEffect(() => {
        props.onChange && props.onChange(answer);
    }, [answer]);

    const colors = [
        "#B72124", "#D62027", "#EF5222", "#F46F21", "#FAA822",
        "#FFCA27", "#ECDB11", "#DEDD37", "#C5D92D", "#AFD136", "#65B54D"
    ];

    const getColorForOption = (index: number, totalOptions: number): string => {
        const colorCount = colors.length;
        if (totalOptions <= colorCount) {
            const step = (colorCount - 1) / (totalOptions - 1);
            return colors[Math.round(index * step)];
        } else {
            return index < colorCount ? colors[index] : colors[colorCount - 1];
        }
    };

    const totalOptions = props.max - props.min + 1;

    return (
        <div className="lc-vote">
            <p className="question">{props.question}</p>
            <div className="options-container">
                <div className="options">
                    {Array.from({ length: totalOptions }, (_, i) => {
                        const customColor = getColorForOption(i, totalOptions);
                        const value = props.min + i;
                        const isActive = answer === value;
                        return (
                            <div
                                key={value}
                                className={`option ${isActive ? 'active' : ''}`}
                                onMouseEnter={() => setIsFocused(value)}
                                onMouseLeave={() => setIsFocused(undefined)}
                                style={{
                                    color: isFocused === value ? customColor : '#FFF',
                                    backgroundColor: isFocused === value ? '#FFF' : customColor,
                                    borderColor: isFocused === value ? customColor : isActive ? '#000' : 'transparent',
                                }}
                                onClick={() => setAnswer(value)}
                            >
                                {value}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="texts">
                <span className='less-text'>{props.lessText}</span>
                <span className='more-text'>{props.moreText}</span>
            </div>
            <input type="hidden" name={props.name} value={answer ?? ''} />
        </div>
    );
};

export default LcVote;