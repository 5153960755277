import PPExpand from '../../components/Generic/PPExpand';
import React from 'react';
import LcIconLink from '../../components/Generic/LcIconLink';


const TabExpand: React.FC = () => {



    return (<>
        <h1>Expand</h1>

        <PPExpand title="Default">
            Mussum Ipsum, cacilds vidis litro abertis. Si num tem leite então bota uma pinga aí cumpadi!Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!Nec orci ornare consequat. Praesent lacinia ultrices consectetur. Sed non ipsum felis.Sapien in monti palavris qui num significa nadis i pareci latim.
            Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Leite de capivaris, leite de mula manquis sem cabeça.Praesent malesuada urna nisi, quis volutpat erat hendrerit non. Nam vulputate dapibus.A ordem dos tratores não altera o pão duris.
        </PPExpand>

        <PPExpand title="Com opções"
            titleRight={<>
                <LcIconLink icon='ppi ppi-cog' />
                <LcIconLink icon='ppi ppi-star' />
                <LcIconLink icon='ppi ppi-pin' />
            </>}>
            Quem manda na minha terra sou euzis!Leite de capivaris, leite de mula manquis sem cabeça.Interagi no mé, cursus quis, vehicula ac nisi.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.
            Viva Forevis aptent taciti sociosqu ad litora torquent.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Praesent malesuada urna nisi, quis volutpat erat hendrerit non. Nam vulputate dapibus.Tá deprimidis, eu conheço uma cachacis que pode alegrar sua vidis.
        </PPExpand>

    </>);
}

export default TabExpand;