import { TransitionProps } from '@material-ui/core/transitions';
import { Button,   LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React, { useEffect } from 'react';

interface IPropsDialog {
    onCloseEvent: Function,
    open: boolean,
    title: string,
    content: string,
    confirmMessage: string,
    idConfirm?: string,
    abortMessage: string,
    idAbort?: string
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<any>,
) {
    return (<Slide direction="up" ref={ref} {...props} />);
});
const DialogConfirmForm: React.FC<IPropsDialog> = (props) => {

    useEffect(() => { }, [props]);
    return (
        <Dialog
            open={props.open}

            keepMounted
            onClose={() => {
                if (props.onCloseEvent != null) {
                    props.onCloseEvent(false);
                }
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id={props.idConfirm} onClick={() => {
                    if (props.onCloseEvent != null) {
                        props.onCloseEvent(true);
                    }
                }} color="primary">
                    {props.confirmMessage}
                </Button>
                <Button id={props.idAbort} onClick={() => {
                    if (props.onCloseEvent!=null) {
                        props.onCloseEvent(false);
                    }
                }} color="primary">
                    {props.abortMessage}
                </Button>

            </DialogActions>
        </Dialog>
    );
}

export default DialogConfirmForm;