// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import api, { APIClient } from "./api";
import { Tooltip } from "@material-ui/core";
import { BsCopy } from "react-icons/bs";
export interface AuditLogEntry {
    id: string;
    userId: string;
    clientGroupId: number;
    route: string;
    detail: string;
    httpMethod: string;
    autorizationRoles: string;
    accessDate: string;
    clientGroupName: string;
    userEmail: string;
}

// Adicionar rotas a serem monitoradas (podem ser parciais; devem ser únicas).
export const routesToWatch = {
    'Usuários do Portal': {
        'Criar': 'PortalUser/CreateUser',
        'Editar': 'PortalUser/UpdateUser',
        'Remover': 'PortalUser/DeleteUserById'
    },
    'Ambientes': {
        'Ligar VM': 'VirtualMachine/Ligar',
        'Desligar VM': 'VirtualMachine/Desligar'
    }
}

export const copyToClipboardTweak = () => {
    const wrapWithTooltip = () => {
        const clipboardElements = document.querySelectorAll('.copy-to-clipboard-container');
        clipboardElements.forEach((element) => element.setAttribute('title', 'Copiar dados'));

        const iconElements = document.querySelectorAll('.copy-icon');
        iconElements.forEach((iconElement) => {
            const svgContainer = iconElement.firstChild as HTMLElement;

            if (svgContainer) {
                ReactDOM.render(
                    <Tooltip arrow title='Copiar dados' placement='top'>
                        <BsCopy size={30} />
                    </Tooltip>,
                    svgContainer
                );
            }
        })
    };

    wrapWithTooltip();

    const observer = new MutationObserver(wrapWithTooltip);
    const config = { childList: true, subtree: true };

    const targetNode = document.querySelector('.react-json-view');
    if (targetNode)
        observer.observe(targetNode, config);

    return () => observer.disconnect();
}


export class AuditLogService extends APIClient {

    async GetAuditLogs(startDate: string, endDate: string, userEmail: string, route: string) {
        const url = `${process.env.REACT_APP_BFFENDPOINT}/api/AuditLog/GetAuditLogs?startDate=${startDate}&endDate=${endDate}&userEmail=${userEmail}&route=${route}&refresh=${Math.random()}`;
        var response = await api({
            method: 'get',
            url,
        });

        return response.data as AuditLogEntry[];
    }
}
